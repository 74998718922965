import i18n from 'helpers/i18n';
import messages from './messages';
import Spacer from 'components/Spacer';
import RouteHelpers from 'helpers/routes';
import UserAvatar from 'components/Avatar';
import { useMediaQuery } from 'react-responsive';
import useCurrentUser from 'hooks/useCurrentUser';
import { getInitials, getShortName } from 'helpers/user';
import { FlexCenter, ListItem } from '../Styled';
import { logout } from 'actions/users';
import { useDispatch } from 'react-redux';
import { destroy } from 'store/slices/user';

import {
  NavMenu,
  NavMenuButton,
  NavMenuItems,
  NavMenuItem,
  NavMenuDivider
} from '../NavMenu';

function UserMenu() {
  const currentUser = useCurrentUser();
  const isLargeDesktop = useMediaQuery({ minWidth: 1280 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isMobile = useMediaQuery({ minWidth: 640 });
  const dispath = useDispatch();

  function handleLogout() {
    logout().then(() => {
      dispath(destroy());
    });
  }

  if (isDesktop) {
    return (
      <ListItem as="div">
        <NavMenu>
          <NavMenuButton
            style={{ paddingTop: 12, paddingBottom: 12, margin: 0 }}
          >
            <UserAvatar
              src={currentUser.avatar_url}
              content={getInitials(currentUser)}
            />
            {isLargeDesktop ? (
              <>
                <Spacer axis="horizontal" size={12} />
                {getShortName(currentUser)}
              </>
            ) : null}
          </NavMenuButton>
          <NavMenuItems position="right" style={{ margin: 0, width: 192 }}>
            <NavMenuItem href={RouteHelpers.getPath('account')}>
              {i18n.ft(messages.settings)}
            </NavMenuItem>

            {currentUser.user_menu?.map((menuItem: any, idx: number) => (
              <NavMenuItem key={idx} href={menuItem.target_url}>
                {menuItem.label}
              </NavMenuItem>
            ))}

            <NavMenuDivider />
            <NavMenuItem as="button" onClick={handleLogout}>
              {i18n.ft(messages.logout)}
            </NavMenuItem>
          </NavMenuItems>
        </NavMenu>
      </ListItem>
    );
  }

  return (
    <FlexCenter>
      <UserAvatar
        src={currentUser.avatar_url}
        content={getInitials(currentUser)}
      />
      {isMobile ? (
        <>
          <Spacer axis="horizontal" size={12} />
          {getShortName(currentUser)}
        </>
      ) : null}
    </FlexCenter>
  );
}

export default UserMenu;
