const PATH = 'fe.pages.training_history';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'My Training History'
  },
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue:
      'View your coursework and download certificates of completion.'
  },
  sortBy: {
    key: `${PATH}.sort_by`,
    defaultValue: 'Sort By'
  },
  recentFirst: {
    key: `${PATH}.recent_first`,
    defaultValue: 'Most Recent First'
  },
  oldestFirst: {
    key: `${PATH}.oldest_first`,
    defaultValue: 'Oldest First'
  },
  hours: {
    key: `${PATH}.hours`,
    defaultValue: 'Learning Hours'
  },
  ceus: {
    key: `${PATH}.ceus`,
    defaultValue: 'CEUs Earned'
  },
  noTrainings: {
    key: `${PATH}.no_trainings`,
    defaultValue:
      'You have not yet completed a training in myTeachstone. When you have completed a facilitated training or an online course, you will see a list of your completed courses and certificates of completion.'
  },
  lookingMMCI: {
    key: `${PATH}.looking_for_mmci`,
    defaultValue: 'Looking for your MMCI Certificate?'
  },
  checkHere: {
    key: `${PATH}.check_here`,
    defaultValue: 'Check here'
  }
};

export default messages;
