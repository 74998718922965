import { CenterTitle, CenterSubtitle, Container } from './Styled';

interface ItemSelectProps {
  /** First row of the option to show on the select. */
  title: string;

  /** External node id associated to title. */
  titleId?: string;

  /** Second row of the option to show on the select. */
  subtitle?: string;
}

function DropdownItem({ title, titleId, subtitle }: ItemSelectProps) {
  return (
    <Container>
      <CenterTitle>
        {title}
        {titleId && (
          <span className="italic ml-1.5 font-normal">{titleId}</span>
        )}
      </CenterTitle>
      <CenterSubtitle>{subtitle}</CenterSubtitle>
    </Container>
  );
}

export default DropdownItem;
