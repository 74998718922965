const PATH = 'fe.pages.recertification_product_key';

const messages = {
  choose: {
    key: `${PATH}.choose`,
    defaultValue: 'Choose your Test'
  },
  certification: {
    key: `${PATH}.certification`,
    defaultValue: 'You’re eligible for these certifications.'
  },
  language: {
    key: `${PATH}.language`,
    defaultValue:
      'The observer reliability test for this class age level has videos available in these languages.'
  },
  confirmationModal: {
    title: {
      key: `${PATH}.confirmation_modal.title`,
      defaultValue: 'Create this test?'
    },
    cancel: {
      key: `${PATH}.confirmation_modal.cancel`,
      defaultValue: 'Cancel'
    },
    create: {
      key: `${PATH}.confirmation_modal.create`,
      defaultValue: 'Create'
    }
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  },
  next: {
    key: `${PATH}.next`,
    defaultValue: 'Next'
  }
};

export default messages;
