const PATH = 'fe.components.trainings';

const messages = {
  resultsSummary: {
    searchResults: {
      key: `${PATH}.results_summary.search_results`,
      defaultValue: 'Search Results:'
    },
    showingCount: {
      key: `${PATH}.results_summary.showing_counts`,
      defaultValue: 'Showing {{visible}} out of {{total}} results.'
    }
  }
};

export default messages;
