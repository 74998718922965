import React from 'react';
import Avatar from 'components/Avatar';
import { getInitials } from './utils';
import { IconProps } from 'semantic-ui-react';
import { Container, Details, Email, Name, Status } from './Styled';

export interface ParticipantProps {
  /** The email of the participant. */
  email: string;

  /** The name of the participant if user account exists. */
  name?: string;

  /** The avatar of the partcipant if user account exist. */
  avatar?: string;

  /** An optional node indicating the participant status. */
  status?: React.ReactNode;

  /** An optional icon to be displayed in the bottom right corner of the avatar. */
  icon?: IconProps | null;
}

const Participant: React.FC<ParticipantProps> = ({
  email,
  name,
  avatar,
  status,
  icon
}) => {
  return (
    <Container>
      <Avatar icon={icon} src={avatar} content={getInitials(email, name)} />
      <Details>
        {name && <Name>{name}</Name>}
        <Email>{email}</Email>
        {status && <Status>{status}</Status>}
      </Details>
    </Container>
  );
};

export default Participant;
