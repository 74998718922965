import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const CropFrame = styled.div`
  position: relative;
  margin: auto;
  user-select: none;
  overflow: hidden;
  background-color: #d9d9d9;
`;

export const CropCircle = styled.div`
  display: block;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  overflow: hidden;
  background-color: black;
  position: relative;
  z-index: 10;
  pointer-events: none;
`;

export const CropImage = styled.img`
  max-width: none;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const CropOverlay = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: ${p => (p.active ? 'grabbing' : 'pointer')};
`;

export const SliderInput = styled.input`
  display: block;
  margin: 0.5em auto;
  width: 230px;
  position: relative;
  cursor: pointer;
`;
