export function IconExpand() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5015 0.5C5.15831 0.5 0.00146484 5.65684 0.00146484 12C0.00146484 18.3432 5.15831 23.5 11.5015 23.5C17.8446 23.5 23.0015 18.3432 23.0015 12C23.0015 5.65684 17.8446 0.5 11.5015 0.5ZM14.7699 13.15H12.6515V15.2684C12.6515 15.7647 12.2399 16.1763 11.7436 16.1763C11.2473 16.1763 10.8357 15.7647 10.8357 15.2684V13.15H8.71725C8.22094 13.15 7.80936 12.7384 7.80936 12.2421C7.80936 11.7458 8.22094 11.3342 8.71725 11.3342H10.8357V9.21579C10.8357 8.71947 11.2473 8.30789 11.7436 8.30789C12.2399 8.30789 12.6515 8.71947 12.6515 9.21579V11.3342H14.7699C15.2662 11.3342 15.6778 11.7458 15.6778 12.2421C15.6778 12.7384 15.2662 13.15 14.7699 13.15Z"
        fill="#017EA7"
      />
    </svg>
  );
}

export function IconCollapse() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 0.5C5.1635 0.5 0 5.6635 0 12C0 18.3365 5.1635 23.5 11.5 23.5C17.8365 23.5 23 18.3365 23 12C23 5.6635 17.8365 0.5 11.5 0.5ZM16.008 12.8625H6.808C6.3365 12.8625 5.9455 12.4715 5.9455 12C5.9455 11.5285 6.3365 11.1375 6.808 11.1375H16.008C16.4795 11.1375 16.8705 11.5285 16.8705 12C16.8705 12.4715 16.491 12.8625 16.008 12.8625Z"
        fill="#017EA7"
      />
    </svg>
  );
}
