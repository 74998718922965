import styled from 'styled-components';

export const TrainingDetailContainer = styled.div<{
  color: string;
  tabIndex: number;
}>`
  width: 100%;
  background-color: #f3f7ff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: -1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  border-left: 5px solid ${p => p.color};
`;

export const TrainingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px;
`;
export const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
`;

export const Subtitle = styled.div`
  font-size: 14px;
`;

export const PillsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media screen and (min-width: 1280px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const Pill = styled.div`
  width: auto;
  background-color: #eaeff8;
  border-radius: 10px;
  display: flex;
  padding: 5px;
  margin-bottom: 10px;
  justify-content: center;

  @media screen and (min-width: 1280px) {
    width: fit-content;
  }
`;

export const PillContent = styled.div``;

export const Info = styled.button`
  background-color: ${p => p.theme.colors.white};
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  box-shadow: -1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
  border: 0;
`;

export const ActionArea = styled.div`
  padding: 10px;
`;

export const StatusArea = styled.div`
  padding: 10px;
`;
