import { createIcon } from './utils';

export const IconCheck = createIcon(
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#4A8264" />
    <g clipPath="url(#clip0_72_8413)">
      <path
        d="M13.0486 18.9026L9.24371 15.0977C9.01512 14.8691 9.01512 14.4985 9.24371 14.2699L10.0715 13.442C10.3001 13.2134 10.6708 13.2134 10.8994 13.442L13.4625 16.0052L18.9525 10.5152C19.1811 10.2866 19.5517 10.2866 19.7803 10.5152L20.6081 11.343C20.8367 11.5716 20.8367 11.9423 20.6081 12.1709L13.8764 18.9026C13.6478 19.1312 13.2772 19.1312 13.0486 18.9026Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_72_8413">
        <rect
          width="11.7073"
          height="11.7073"
          fill="white"
          transform="translate(9.07227 8.85547)"
        />
      </clipPath>
    </defs>
  </svg>
);
