import React, { useEffect } from 'react';
import * as yup from 'yup';
import messages from './messages';
import i18n from 'helpers/i18n';
import CollapsibleCard from 'components/CollapsibleCard';
import { SubmitButton } from 'components/Trainings/View/CardCheckout/Styled';
import { Button } from 'semantic-ui-react';
import AgeLevelsDropdown from 'components/dropdowns/AgeLevels';
import { Formik, Form, Input, Select, TextArea } from 'components/EnhancedForm';
import { createIntake } from 'actions/improvements';
import { RadioInput, AreaLabel } from './Styled';
import { getFilledForm, IntakeObject, IntakeForm } from './utils';
import Prompt from 'components/Improvements/Prompt';
import ImprovementLanguagesDropdown from 'components/dropdowns/ImprovementLanguages';
import { Field } from 'formik';
import ErrorLabel from 'components/EnhancedForm/ErrorLabel';
import { InformationCard } from 'pages/Improvements/EducatorProfile/Styled';
import { ImprovementArea } from 'types/api/improvement/ImprovementArea';
import { useState } from 'react';
import CardHeader from 'pages/Improvements/CardHeader';
import HideOnPrint from 'pages/Improvements/HideOnPrint';
import { useMediaQuery } from 'react-responsive';

let validationSchema = yup.object().shape({
  age_levels: yup
    .array()
    .min(1, i18n.ft(messages.ageLevelPrompt))
    .required(i18n.ft(messages.intakeFieldsRequired))
    .of(yup.number()),
  number_of_children: yup
    .string()
    .required(i18n.ft(messages.intakeFieldsRequired)),
  dual_language: yup.string().required(i18n.ft(messages.intakeFieldsRequired)),
  disabilities: yup
    .string()
    .required(i18n.ft(messages.intakeFieldsRequired))
    .nullable(),
  area_selection: yup.string().required(i18n.ft(messages.intakeFieldsRequired))
});

interface IntakeProps {
  intake: IntakeObject;
  improvementAreas: ImprovementArea[];
  handleCreateIntake: any;
  firstName: string;
  hasCompleted?: boolean;
}

function Intake({
  intake,
  handleCreateIntake,
  improvementAreas,
  firstName,
  hasCompleted
}: IntakeProps) {
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const isXl = useMediaQuery({ minWidth: 1280 });
  const is2Xl = useMediaQuery({ minWidth: 1536 }); // Tailwind sizes

  const initialState: IntakeForm = getFilledForm(intake);

  useEffect(() => {
    if (intake.id !== undefined) {
      setIsFormComplete(true);
      setIsCollapsed(true);
    }
  }, [intake.id]);

  async function handleCreateIntakeObject(intakeForm: IntakeForm) {
    if (intakeForm.dual_language === 'false') {
      intakeForm.languages = [];
    }
    if (intakeForm.disabilities === 'false') {
      intakeForm.disabilities_description = '';
    }
    const response = await createIntake(intakeForm);

    handleCreateIntake(response.data);
  }

  function handleOpenForm() {
    setIsCollapsed(!isCollapsed);
  }

  if (isCollapsed) {
    return (
      <InformationCard className="p-8 w-full flex justify-between items-center">
        <h2 className="text-xl items-center">
          <i className="check circle icon text-[#7AA03F]" />
          <span>{`${i18n.ft(messages.initialCoachingSessionWith, {
            firstName
          })}`}</span>
        </h2>
        <div className="align-self-baseline print:hidden">
          <button className="text-[#017EA7]" onClick={handleOpenForm}>
            <strong>{i18n.ft(messages.viewSessionDetails)}</strong>
          </button>
        </div>
      </InformationCard>
    );
  }

  function handleEdit() {
    setIsEdit(true);
  }

  return (
    <InformationCard
      className={HideOnPrint(isFormComplete, 'bg-white rounded-lg p-8 m-auto')}
    >
      <CardHeader
        subtitle={i18n.ft(messages.title)}
        showCTA={isFormComplete}
        onClickAction={handleOpenForm}
        showPrintButton={!isFormComplete}
      />
      <p>{i18n.ft(messages.description1)}</p>
      <p>{i18n.ft(messages.description2)}</p>
      <hr />
      <div className="grid self-center">
        <Formik
          onSubmit={handleCreateIntakeObject}
          initialValues={initialState}
          validationSchema={validationSchema}
        >
          {({ values, errors }) => (
            <Form>
              <CollapsibleCard
                title={i18n.ft(messages.conversationStarters)}
                defaultOpen
              >
                <div className="xl:flex gap-4 justify-between">
                  <TextArea
                    rows={is2Xl ? 32 : isXl ? 42 : 14}
                    name="about"
                    label={i18n.ft(messages.educatorKnowAboutYou)}
                    placeholder={i18n.ft(messages.educatorResponsePlaceholder)}
                    className="basis-2/3"
                    disabled={isFormComplete && !isEdit}
                  />
                  <div className="basis-1/3 print:break-inside-avoid">
                    <Prompt
                      className="2xl:mt-6 xl:mt-6 mb-1"
                      prompts={[
                        i18n.ft(messages.describeYourselfPrompt),
                        i18n.ft(messages.whyEducator),
                        i18n.ft(messages.lovePrompt),
                        i18n.ft(messages.proudOfEducator)
                      ]}
                    />
                    <Prompt
                      className="2xl:mt-0 mb-1"
                      prompts={[
                        i18n.ft(messages.tenureOfEducatorPrompt),
                        i18n.ft(messages.feedbackPrompt),
                        i18n.ft(messages.inspiredPrompt)
                      ]}
                    />
                  </div>
                </div>

                <div className="xl:flex gap-4 justify-between">
                  <TextArea
                    rows={is2Xl ? 21 : isXl ? 27 : 14}
                    name="successes"
                    label={i18n.ft(messages.shareYourSuccess)}
                    placeholder={i18n.ft(messages.educatorResponsePlaceholder)}
                    className="mt-4 basis-2/3 grow"
                    disabled={isFormComplete && !isEdit}
                  />
                  <Prompt
                    className="2xl:mt-10 xl:mt-10 mb-1"
                    prompts={[
                      i18n.ft(messages.howAreYou),
                      i18n.ft(messages.generalCyclePrompt),
                      i18n.ft(messages.frequencyPrompt),
                      i18n.ft(messages.communicationPrompt),
                      i18n.ft(messages.resourcesPrompt),
                      i18n.ft(messages.questionsPrompt)
                    ]}
                  />
                </div>
              </CollapsibleCard>
              <CollapsibleCard
                title={i18n.ft(messages.educatorIntake)}
                defaultOpen
              >
                <div className="xl:flex gap-4 justify-between mb-8">
                  <TextArea
                    rows={is2Xl ? 13 : isXl ? 19 : 14}
                    name="children_description"
                    label={i18n.ft(messages.tellMeAboutYourChildren)}
                    placeholder={i18n.ft(messages.educatorResponsePlaceholder)}
                    className="mt-4 basis-2/3 grow"
                    disabled={isFormComplete && !isEdit}
                  />
                  <Prompt
                    className="2xl:mt-10 xl:mt-10 mb-1"
                    prompts={[
                      i18n.ft(messages.whoAreThey),
                      i18n.ft(messages.childrenCulturalBackgrounds),
                      i18n.ft(messages.childrenEnjoyment)
                    ]}
                  />
                </div>

                <div className="xl:grid xl:grid-cols-2 xl:grid-row-1 gap-6 mb-8">
                  <Select
                    name="age_levels"
                    label={i18n.ft(messages.whatAgeAreYourChildren)}
                    placeholder={i18n.ft(messages.childrenAgePlaceholder)}
                    control={AgeLevelsDropdown}
                    multiple
                    disabled={isFormComplete}
                  />
                  <Input
                    name="number_of_children"
                    label={i18n.ft(messages.numberOfChildren)}
                    placeholder={i18n.ft(messages.numberOfChildrenPlaceholder)}
                    type="number"
                    min="1"
                    disabled={isFormComplete}
                  />
                </div>

                <div className="grid grid-cols-2 grid-row-1 gap-6 mb-8">
                  <fieldset
                    className="p-0"
                    name="dual_language"
                    aria-labelledby="dual_language"
                    disabled={isFormComplete}
                  >
                    <legend>
                      <strong>{i18n.ft(messages.dualLanguageLearners)}</strong>
                    </legend>

                    <div className="flex gap-4 mt-2">
                      <label className="flex gap-2">
                        <RadioInput
                          type="radio"
                          name="dual_language"
                          value="true"
                          checked={values.dual_language === 'true'}
                        />
                        Yes
                      </label>
                      <label className="flex gap-2">
                        <RadioInput
                          type="radio"
                          name="dual_language"
                          value="false"
                          checked={values.dual_language === 'false'}
                        />
                        No
                      </label>
                    </div>

                    {values.dual_language === 'true' && (
                      <Select
                        name="languages"
                        label={i18n.ft(messages.homeLanguages)}
                        placeholder={i18n.ft(messages.homeLanguagesPlaceholder)}
                        control={ImprovementLanguagesDropdown}
                        multiple
                        search
                        disabled={isFormComplete}
                        className="mt-4"
                      />
                    )}
                  </fieldset>

                  <fieldset
                    className="p-0"
                    name="disabilities"
                    aria-labelledby="disabilities"
                  >
                    <legend>
                      <strong>
                        {i18n.ft(messages.childrenWithDisabilities)}
                      </strong>
                    </legend>

                    <div className="flex gap-4 mt-2">
                      <label className="flex gap-2">
                        <RadioInput
                          type="radio"
                          name="disabilities"
                          value="true"
                          errors={errors.disabilities}
                          disabled={isFormComplete}
                        />
                        {i18n.ft(messages.yes)}
                      </label>
                      <label className="flex gap-2">
                        <RadioInput
                          type="radio"
                          name="disabilities"
                          value="false"
                          errors={errors.disabilities}
                          disabled={isFormComplete}
                        />
                        {i18n.ft(messages.no)}
                      </label>
                    </div>

                    {values.disabilities === 'true' && (
                      <Input
                        type="text"
                        name="disabilities_description"
                        label={i18n.ft(messages.disabilitiesDescription)}
                        placeholder={i18n.ft(messages.disabilitiesPlaceholder)}
                        className="mt-4"
                        disabled={isFormComplete && !isEdit}
                      />
                    )}
                  </fieldset>
                </div>

                <div className="xl:flex gap-4 justify-between">
                  <TextArea
                    rows={is2Xl ? 17 : isXl ? 24 : 14}
                    name="learning_settings"
                    label={i18n.ft(messages.learningSettings)}
                    placeholder={i18n.ft(messages.educatorResponsePlaceholder)}
                    className="mt-4 basis-2/3 grow"
                    disabled={isFormComplete && !isEdit}
                  />
                  <Prompt
                    className="2xl:mt-10 xl:mt-10 mb-1"
                    prompts={[
                      i18n.ft(messages.routinePrompt),
                      i18n.ft(messages.adultsPrompt),
                      i18n.ft(messages.languageSettingPrompt),
                      i18n.ft(messages.curriculumPrompt),
                      i18n.ft(messages.materialsPrompt),
                      i18n.ft(messages.anythingElsePrompt)
                    ]}
                  />
                </div>
              </CollapsibleCard>
              <CollapsibleCard
                title={i18n.ft(messages.prioritiesObjectives)}
                defaultOpen
              >
                <div className="xl:flex gap-4 justify-between">
                  <TextArea
                    rows={is2Xl ? 12 : isXl ? 16 : 14}
                    name="learning_goals"
                    label={i18n.ft(messages.learningGoals)}
                    placeholder={i18n.ft(messages.educatorResponsePlaceholder)}
                    className="mt-4 basis-2/3 grow"
                    disabled={isFormComplete && !isEdit}
                  />
                  <Prompt
                    className="2xl:mt-16 xl:mt-16 mb-1"
                    prompts={[
                      i18n.ft(messages.childrenAreaOfDevelopment),
                      i18n.ft(messages.childrenSayAndDo),
                      i18n.ft(messages.childrenSupport)
                    ]}
                  />
                </div>

                <div className="xl:flex gap-4 justify-between">
                  <TextArea
                    rows={is2Xl ? 22 : isXl ? 29 : 14}
                    name="professional_development"
                    label={i18n.ft(messages.professionalDevelopment)}
                    placeholder={i18n.ft(messages.educatorResponsePlaceholder)}
                    className="mt-4 basis-2/3 grow"
                    disabled={isFormComplete && !isEdit}
                  />
                  <Prompt
                    className="2xl:mt-10 xl:mt-10 mb-1"
                    prompts={[
                      i18n.ft(messages.classObservation),
                      i18n.ft(messages.challengingBehaviors),
                      i18n.ft(messages.engageProfessionalDevelopment),
                      i18n.ft(messages.strategiesExplore)
                    ]}
                  />
                </div>
                <label
                  className={
                    (errors.area_selection ? 'text-red-700 ' : '') +
                    ' block mt-1 mb-4 font-bold'
                  }
                >
                  {i18n.ft(messages.areaSelectionQuestion)}
                  <ErrorLabel
                    active={errors.area_selection !== undefined}
                    content={' (' + errors.area_selection + ')'}
                  />
                </label>
                <div
                  role="group"
                  aria-labelledby="area_selection"
                  className="flex md:items-stretch flex-wrap gap-4 mt-2"
                >
                  {improvementAreas.map((area, index) => {
                    return (
                      <div key={index} className="flex flex-auto">
                        <Field
                          id={`area-radio-${area.id}`}
                          type="radio"
                          name="area_selection"
                          value={area.id}
                          checked={values.area_selection === area.id.toString()}
                          errors={errors.area_selection}
                          disabled={isFormComplete}
                          className="hidden"
                        />
                        <AreaLabel
                          id={`area-label-${area.id}`}
                          htmlFor={`area-radio-${area.id}`}
                          className={'flex flex-auto gap-2'}
                        >
                          {area.name}
                        </AreaLabel>
                      </div>
                    );
                  })}
                </div>
              </CollapsibleCard>
              <SubmitButton>
                {isFormComplete && !isEdit ? (
                  <div>
                    <Button
                      type="button"
                      className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                      color="blue"
                      onClick={handleEdit}
                      disabled={hasCompleted}
                      content={i18n.ft(messages.edit)}
                    />
                  </div>
                ) : (
                  <Button
                    type="submit"
                    className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                    color="blue"
                    content={i18n.ft(messages.finish)}
                  />
                )}
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </div>
    </InformationCard>
  );
}

export default Intake;
