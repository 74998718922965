const colors = {
  red: '#B5324B',
  blue: '#00779E',
  lightBlue: '#F6FBFE',
  scaleBlue: '#017EA7',
  scaleDarkBlue: '#364A5E',
  scaleDarkGray: '#3C3F42',
  scaleLightBlue: '#E3EDFF',
  scaleLightSky: '#F6FAFF',
  scaleRosa: '#F7E0FF',
  scaleGreen: '#4A8263',
  scaleLightGreen: '#E5FDF0',
  scaleGray: '#737059',
  scaleWarning: '#C40341',
  scaleSection: '#F4F8FA',
  green: '#107A6A',
  black: '#000000',
  grey: '#767676',
  lightGrey: '#F3F3F3',
  lighterGrey: '#F9F9F9',
  brightGrey: '#EAECF1',
  darkGrey: '#4A4A4A',
  orange: '#F2711C',
  highlightYellow: '#FFF380',
  ageLevels: {
    inf: '#77923B',
    todd: '#8C0B40',
    'pre-k': '#0079C1',
    k: '#0079C1',
    'k-3': '#FFD615',
    ue: '#006C67',
    sec: '#F47B20',
    multiple: '#4F3928'
  },
  teachstoneBlue: '#008FBF',
  reliability: {
    passed: '#00779E',
    failed: '#C3C3C3',
    highlight: '#00779E',
    attempt01: '#0F7A6A',
    attempt02: '#A03AAA',
    attempt03: '#DA7E00',
    passVideo: '#7AA03F',
    noPassVideo: '#FFE178',
    failedAttempt: '#AC213A'
  },

  // TODO: Migrate top colors to new palette instead
  white: '#FFFFFF',
  neutral: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D0D5DC',
    400: '#9AA3B0',
    500: '#58697B',
    600: '#475A6D',
    700: '#34495E',
    800: '#273646',
    900: '#1F2B38'
  },
  primary: {
    50: '#EBFAFE',
    500: '#3D99C0',
    700: '#00779E'
  },

  success: {
    100: '#D9FCE9'
  },

  error: {
    100: '#FCCFD9',
    500: '#D02358'
  },

  warning: {
    50: '#FFF8E5',
    100: '#FEEEC7',
    200: '#FED990',
    300: '#FDC668',
    400: '#FFB53D',
    500: '#FF9D0B',
    600: '#DD8603',
    700: '#B66B02',
    800: '#804600',
    900: '#653501'
  },

  help: {
    50: '#FCF0FE',
    100: '#F5D5FB',
    200: '#ECB3F5',
    300: '#D273DE',
    400: '#BE53CA',
    500: '#A03AAA',
    600: '#8F2F98',
    700: '#7E2682',
    800: '#642065',
    900: '#4C104B'
  },

  // Age Levels
  inf: {
    100: '#E9FBC1',
    500: '#77923B',
    900: '#435E08'
  },
  todd: {
    100: '#FDC4DC',
    500: '#8C0B40',
    900: '#63082E'
  },
  'pre-k': {
    100: '#B3E3FF',
    500: '#0079C1',
    900: '#004166'
  },
  k: {
    100: '#B3E3FF',
    500: '#0079C1',
    900: '#004166'
  },
  'k-3': {
    100: '#FFF3BD',
    500: '#FFD615',
    900: '#A88900'
  },
  ue: {
    100: '#C5FCFB',
    500: '#006C67',
    900: '#003D3A'
  },
  sec: {
    100: '#FED4B3',
    500: '#F47B20',
    900: '#843C06'
  },
  navy: {
    500: '#385E8E'
  }
};

export default colors;
