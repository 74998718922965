import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { PARTICIPANT_STATUS } from 'types/api/TrainingParticipant';

interface InvitationStatusProps {
  status: PARTICIPANT_STATUS;
}

const StatusContainer = styled.div`
  padding: 8px 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  background-color: lightgray;
  margin-left: 16px;
  flex-shrink: 0;

  @media screen and (max-width: 700px) {
    margin-left: 0;
  }
`;

const VALID_STATUSES = ['registered', 'declined', 'incomplete'];

const InvitationStatus: React.FC<InvitationStatusProps> = ({ status }) => {
  if (!VALID_STATUSES.includes(status)) {
    return null;
  }

  return (
    <StatusContainer>
      {status === 'registered' && (
        <React.Fragment>
          <Icon name="check circle" color="green" size="large" />
          {i18n.ft(messages.registered)}
        </React.Fragment>
      )}
      {status === 'declined' && (
        <React.Fragment>
          <Icon name="times circle" color="red" size="large" />
          {i18n.ft(messages.declined)}
        </React.Fragment>
      )}
      {status === 'incomplete' && (
        <React.Fragment>
          <Icon name="times circle" color="red" size="large" />
          {i18n.ft(messages.absent)}
        </React.Fragment>
      )}
    </StatusContainer>
  );
};

export default InvitationStatus;
