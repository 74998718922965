const typography = {
  fontSize: {
    massive: '1.3rem',
    huge: '1.25rem',
    big: '1.125rem',
    large: '1rem',
    medium: '1em',
    small: '0.85rem',
    tiny: '0.7rem',
    mini: '0.6rem'
  }
};

export default typography;
