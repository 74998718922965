const PATH = 'fe.components.reliability_test.not_pass_message';

const messages = {
  message: {
    key: `${PATH}.message`,
    defaultValue: `Unfortunately you <span style="color: #AC213A">did not pass</span> the`
  }
};

export default messages;
