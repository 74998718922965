const PATH = 'fe.components.login_manager';

const messages = {
  incorrectPassword: {
    key: `${PATH}.incorrect_password`,
    defaultValue:
      'Incorrect Password: Please verify the spelling, or select Forgot Password.'
  },
  tryAgainIn: {
    key: `${PATH}.try_again_in`,
    defaultValue: 'Too many attempts. Please wait {{time}} to try again.'
  }
};

export default messages;
