import React from 'react';
import { getAgeLevel } from 'helpers/ageLevel';
import { Image, Popup } from 'semantic-ui-react';
import { AGE_LEVEL_GROUP_ABBREVIATIONS } from 'types/api/AgeLevelGroup';
import { AGE_LEVEL_ABBREVIATIONS } from 'types/api/AgeLevel';

const popupStyles: React.CSSProperties = {
  fontSize: '12px',
  fontWeight: 'bold',
  padding: '2px 4px',
  backgroundColor: 'rgba(0, 0, 0, 0.8)'
};

interface AgeLevelProps {
  /** Age Level abbreviation. */
  abbreviation:
    | 'Inf'
    | 'Todd'
    | 'Pre-K'
    | 'K'
    | 'K-3'
    | 'UE'
    | 'Sec'
    | 'PK3'
    | AGE_LEVEL_GROUP_ABBREVIATIONS;

  /** If `true`, K-3 will be replaced with Pre-K–3rd. */
  replaceK3?: boolean;
}

function AgeLevel({ abbreviation, replaceK3 }: AgeLevelProps) {
  let ageLevel;

  if (replaceK3 && ['K-3', 'PKK3'].includes(abbreviation)) {
    ageLevel = getAgeLevel('PK3');
  } else {
    ageLevel = getAgeLevel(
      abbreviation.toLowerCase() as AGE_LEVEL_ABBREVIATIONS
    );
  }

  return (
    <Popup
      basic
      inverted
      size="tiny"
      hideOnScroll
      style={popupStyles}
      mouseEnterDelay={300}
      mouseLeaveDelay={300}
      position="bottom center"
      content={ageLevel.name}
      trigger={
        <Image
          className="AgeLevel__image"
          src={ageLevel.image}
          alt={ageLevel.name}
        />
      }
    />
  );
}

export default AgeLevel;
