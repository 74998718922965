import { createIcon } from './utils';

export const IconInfo = createIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <g clip-path="url(#clip0_3810_4262)">
      <path
        d="M7.49998 0.601562C3.68908 0.601562 0.599976 3.69066 0.599976 7.50156C0.599976 11.3125 3.68908 14.4016 7.49998 14.4016C11.3109 14.4016 14.4 11.3125 14.4 7.50156C14.4 3.69066 11.3109 0.601562 7.49998 0.601562ZM7.49998 3.30156C7.99708 3.30156 8.39998 3.70446 8.39998 4.20156C8.39998 4.69866 7.99708 5.10156 7.49998 5.10156C7.00288 5.10156 6.59998 4.69866 6.59998 4.20156C6.59998 3.70446 7.00288 3.30156 7.49998 3.30156ZM8.69998 11.4016H8.09998H6.89998H6.29998V10.8016H6.89998V6.90156H6.29998V6.30156H6.89998H8.09998V6.90156V10.8016H8.69998V11.4016Z"
        fill="#385E8E"
      />
    </g>
    <defs>
      <clipPath id="clip0_3810_4262">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
