import React from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { HiddenInput, TextButton } from './Styled';

interface DownloadButtonProps {
  /** Certification data origin (onelogin or core). */
  dataSource: 'onelogin' | 'core';

  /** Certification instance ID in origin. */
  dataSourceID: number;

  /** Certificate title. */
  title: string;

  /** Certificate start date. */
  startDate: string;

  /** Certificate end date. */
  endDate: string;

  /** React Node. */
  children: React.ReactNode;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  dataSource,
  dataSourceID,
  title,
  startDate,
  endDate,
  children
}) => {
  const currentUser = useCurrentUser();

  if (dataSource === 'core') {
    return (
      <form method="post" action="/api/v2/certificates/observer">
        <HiddenInput name="title" value={title} readOnly />
        <HiddenInput name="start_date" value={startDate} readOnly />
        <HiddenInput name="end_date" value={endDate} readOnly />
        <TextButton type="submit">{children}</TextButton>
      </form>
    );
  } else {
    return (
      <TextButton
        as="a"
        href={`/api/v2/users/${currentUser.id}/certifications/${dataSourceID}.pdf`}
        download
      >
        {children}
      </TextButton>
    );
  }
};

export default DownloadButton;
