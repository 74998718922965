import { useState } from 'react';
import { CheckboxInput } from './Styled';

interface CheckBoxProps {
  id: string;
  name?: string;
  checked?: boolean;
  label: string;
  className?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function CheckBox({
  id,
  className,
  checked,
  name,
  label,
  handleChange
}: CheckBoxProps) {
  const [isChecked, setIsChecked] = useState(checked);

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    setIsChecked(event.target.checked);
    handleChange(event);
  }

  return (
    <div className="grid grid-cols-12 items-center my-8" aria-live="assertive">
      <div className="col-span-1">
        <CheckboxInput
          type="checkbox"
          id={id}
          name={name}
          className={className}
          checked={isChecked}
          onChange={e => handleCheckboxChange(e)}
        />
      </div>
      <div className="col-span-11 ml-4 md:ml-0">
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  );
}

export default CheckBox;
