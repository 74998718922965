const PATH = 'fe.components.measure.home.filters.filters_catalog';

const messages = {
  filters: {
    key: `${PATH}.filters`,
    defaultValue: 'Filters: '
  }
};

export default messages;
