const PATH = 'fe.pages.not_found';

const messages = {
  snap: {
    key: `${PATH}.snap`,
    defaultValue: 'Oh snap!'
  },
  capsOff: {
    key: `${PATH}.caps_off`,
    defaultValue: 'Someone left the caps off the markers.'
  },
  cannotDrawn: {
    key: `${PATH}.cannot_drawn`,
    defaultValue: 'This page cannot be drawn now.'
  },
  ourApologies: {
    key: `${PATH}.our_apologies`,
    defaultValue: 'The following error(s) occurred:'
  },
  pageRequested: {
    key: `${PATH}.page_requested`,
    defaultValue: 'The page you requested ({{page}}) could not be found.'
  },
  mistypedAddress: {
    key: `${PATH}.mistyped_address`,
    defaultValue:
      'You may have mistyped the address or the page may have moved.'
  },
  goToDashboard: {
    key: `${PATH}.go_to_dashboard`,
    defaultValue: 'Go to your dashboard'
  },
  needHelp: {
    key: `${PATH}.need_extra_help`,
    defaultValue: 'Or if you need extra help,'
  },
  contactUs: {
    key: `${PATH}.contact_us`,
    defaultValue: 'Contact Us'
  },
  emailUs: {
    key: `${PATH}.email_us`,
    defaultValue: 'or email us at'
  }
};

export default messages;
