import { UserMe } from 'types/api/User';

export interface FormValues {
  learning_pathway_id?: number;
  age_level?: string;
  shipping_address?: {
    first_name?: string;
    last_name?: string;
    address_1?: string;
    address_2?: string;
    country?: string;
    state?: string;
    city?: string;
    zip?: string;
  };
  birth_date?: {
    day?: number;
    month?: number;
    year?: number;
  };
  student_agreement?: boolean;
}

export interface LearningPathway {
  id: number;
  start_date: string;
  end_date: string;
}

export function yearOptions() {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = 1900; year <= currentYear; year++) {
    years.push(year);
  }

  return years.map(year => ({
    key: year,
    value: year,
    text: year
  }));
}

export const monthOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
].map((month, index) => ({
  key: index + 1,
  value: index + 1,
  text: month
}));

export const dayOptions = Array.from({ length: 31 }, (_, i) => i + 1).map(
  day => ({
    key: day,
    value: day,
    text: day
  })
);

export function learningPathwayFormatter(learningPathway: LearningPathway) {
  return {
    key: learningPathway.id,
    value: learningPathway.id,
    text: learningPathway.start_date
  };
}

export function getInitialValues(
  currentUser: UserMe,
  learningPathway?: LearningPathway
) {
  let birth_date = {};
  if (currentUser.birthday) {
    const birthDate = new Date(currentUser.birthday);
    birth_date = {
      day: birthDate.getDate(),
      month: birthDate.getMonth() + 1,
      year: birthDate.getFullYear()
    };
  }

  return {
    learning_pathway_id: learningPathway?.id,
    shipping_address: {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      address_1: currentUser.street_address_1,
      address_2: currentUser.street_address_2,
      country: currentUser.country,
      state: currentUser.region,
      city: currentUser.locality,
      zip: currentUser.postal_code
    },
    birth_date,
    student_agreement: false
  };
}
