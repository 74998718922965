import styled from 'styled-components';
import ReactTreeSelect from 'react-dropdown-tree-select';

export const TreeSelect = styled(ReactTreeSelect)`
  && {
    .dropdown {
      width: 100%;
      background-color: #ffffff;
    }

    .dropdown-trigger {
      width: 100%;
      border-radius: 4px;
      border: 1px solid rgba(34, 36, 38, 0.15);

      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        border-color: rgba(34, 36, 38, 0.35);
      }

      &[aria-expanded='true'] {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-color: #96c8da;
      }

      &.arrow.top:after,
      &.arrow.bottom:after {
        font-family: 'Dropdown';
        padding: 4px 9px 4px 8px;
        margin-right: 0;
        font-size: 12px;
        content: '\f0d7';
      }
    }

    .dropdown-content {
      width: 100%;
      box-shadow: none;
      border: 1px solid #96c8da;
      border-top: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      max-height: 500px;
      overflow-y: auto;

      .node.tree,
      .node.leaf {
        padding: 8px 4px;
      }

      .node.tree {
        display: flex;
        align-items: center;
      }

      .node.tree label,
      .node.leaf label {
        display: inline-flex;
        align-items: center;
        gap: 4px;
      }

      .node.tree .toggle {
        width: 24px;
        height: 24px;
        margin-right: 0;
        padding: 0 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
      }
    }

    .tag-list .tag-item input.search {
      border: none;
      font-family: inherit;
      padding: 2px;
      padding-left: 5px;
    }

    .tag-list .tag-item .tag {
      padding: 4px;
      color: #364a5e;
      display: inline-flex;
      gap: 4px;

      .tag-remove {
        font-style: normal;
        font-weight: 700;
        color: #000000;
      }
    }
  }
`;
