const PATH = 'fe.components.test_attempts.resources';

const messages = {
  attemptNumber: {
    key: `${PATH}.attempt_number`,
    defaultValue: 'Attempt {{number}}'
  },
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: 'Review your performance on your most recent attempt.'
  },
  logoDescription: {
    key: `${PATH}.logo_description`,
    defaultValue: 'Certification Logo'
  },
  exitTest: {
    key: `${PATH}.exit_test`,
    defaultValue: 'Exit to Dashboard'
  },
  resourcesTitle: {
    key: `${PATH}.resources_title`,
    defaultValue: 'Prepare for Success'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  },
  next: {
    key: `${PATH}.next`,
    defaultValue: 'Next'
  },
  practice: {
    key: `${PATH}.practice`,
    defaultValue: 'Practice Videos'
  },
  training: {
    key: `${PATH}.training`,
    defaultValue: 'Training Videos'
  },
  reliabilitySupport: {
    key: `${PATH}.reliability_support`,
    defaultValue: 'Reliability Support:'
  },
  reliabilitySupportContent: {
    key: `${PATH}.reliability_support_content`,
    defaultValue:
      'Access resources to help ensure your success when taking your reliability test.'
  },
  videoLibrary: {
    key: `${PATH}.video_library`,
    defaultValue: 'Video Library:'
  },
  videoLibraryContent: {
    key: `${PATH}.video_library_content`,
    defaultValue:
      'Review short Classroom video displaying examples of effective interactions for specific dimensions.'
  },
  observerVillage: {
    key: `${PATH}.observer_village`,
    defaultValue: 'Observer Village:'
  },
  observerVillageContent: {
    key: `${PATH}.observer_village_content`,
    defaultValue: 'Browse extensive resources around being a CLASS observer.'
  },
  beforeTest: {
    key: `${PATH}.before_test`,
    defaultValue:
      'Use these resources to strengthen your observer skills before beginning your reliability test.'
  }
};

export default messages;
