const PATH =
  'fe.components.reliability_test.score_report.result_section.show_first_attempt_message';

const messages = {
  attemptTip: {
    key: `${PATH}.attempt_tip`,
    defaultValue: 'Attempt tip icon'
  },
  scheduleCall: {
    key: `${PATH}.schedule_call`,
    defaultValue:
      '<a href="{{url}}">Schedule a call</a> with Reliability Support to get live help from CLASS experts to prepare you for your next attempt.'
  }
};

export default messages;
