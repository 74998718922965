import { getEducators } from 'actions/improvements';
import React, { useEffect } from 'react';
import i18n from 'helpers/i18n';
import { Navigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import useAsync from 'hooks/useAsync';
import messages from './messages';
import useCurrentUser from 'hooks/useCurrentUser';
import { ImprovementFocus } from 'types/api/improvement/ImprovementFocus';
import CoachingRosterTable from 'pages/Improvements/CoachingRoster/CoachingRosterTable';
import PrintGlobalStyle from 'components/PrintGlobalStyle';
import { CustomPrintStyle } from './Styled';
import PrintMetadata from 'pages/Improvements/PrintMetadata';

export type EducatorListData = {
  id: number;
  name: string;
  phase: string;
  focus: ImprovementFocus;
  last_completed?: Date;
  next_observation?: Date;
  last_observation?: Date;
  cycles_completed: number;
  last_updated?: Date;
};

function CoachingRoster() {
  const currentUser = useCurrentUser();
  const {
    run,
    data: educatorList,
    isPending,
    isError
  } = useAsync<EducatorListData[]>();

  useEffect(() => {
    run(getEducators());
  }, [run]);

  if (isPending) {
    return <Loader active inline="centered" />;
  }

  if (isError) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="py-10 px-8">
      <PrintGlobalStyle />
      <CustomPrintStyle />
      <PrintMetadata />
      <h1 className="print:mt-0">{i18n.ft(messages.coachingOverview)}</h1>
      <div className="bg-white grid rounded-lg py-10 px-8 mt-8 print:px-0 print:py-0 print:mt-4">
        <CoachingRosterTable
          currentUser={currentUser}
          educatorList={educatorList}
        />
      </div>
    </div>
  );
}

export default CoachingRoster;
