import * as R from 'ramda';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import AppHeader from 'components/App/Header';
import AppFooter from 'components/App/Footer';
import usePendo from 'hooks/usePendo';
import useCurrentUser from 'hooks/useCurrentUser';
import { Wrapper, AppBody } from './Styled';
import MasqueradeStatus from 'components/App/Masquerade';

export function App() {
  usePendo();
  const currentUser = useCurrentUser();
  const location = useLocation();

  if (
    R.isNil(currentUser.id) &&
    !R.test(/\/users|\/single_logout/, location.pathname)
  ) {
    return <Navigate replace to="/users/login" />;
  }

  if (
    !R.isNil(currentUser.id) &&
    R.test(/\/single_logout|\/all/, location.pathname)
  ) {
    return <Navigate replace to="/" />;
  }

  return (
    <Wrapper>
      <MasqueradeStatus />
      <AppHeader />
      <AppBody>
        <Outlet />
      </AppBody>
      <AppFooter />
    </Wrapper>
  );
}

export default App;
