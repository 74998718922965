const PATH = 'fe.components.measure.view.cycles.cycle_controls';

const messages = {
  deleteCycleLabel: {
    key: `${PATH}.delete_cycle_label`,
    defaultValue: 'Delete Cycle'
  },
  currentCycleLabel: {
    key: `${PATH}.current_cycle_label`,
    defaultValue: 'Current Cycle'
  },
  addCycleLabel: {
    key: `${PATH}.add_cycle_label`,
    defaultValue: 'Add a cycle'
  },
  goToCycleAria: {
    key: `${PATH}.goto_cycle_aria`,
    defaultValue: 'Go to cycle {{cycle}}'
  }
};

export default messages;
