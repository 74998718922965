import * as R from 'ramda';
import { useState } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import useCurrentUser from 'hooks/useCurrentUser';
import { activateProductKey } from 'actions/productKeys';
import { useNavigate } from 'react-router-dom';
import { getPostActivationData } from './utils';

function useProductKey() {
  const asyncRequest = useAsync();
  const currentUser = useCurrentUser();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  function setErrors(message: string) {
    setError(message);
    asyncRequest.setError(message);
  }

  function submit(productKey: string) {
    if (R.isNil(productKey) || R.isEmpty(productKey)) {
      setErrors(i18n.ft(messages.errors.required));
      return;
    }

    const data = {
      key: productKey,
      user: {
        email: currentUser.email,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        already_in_okta: true
      }
    };

    asyncRequest.run(activateProductKey(data), {
      onSuccess: (response: any) => {
        const { source, path, state } = getPostActivationData(response);

        if (source === 'external') {
          window.location.href = path;
        } else {
          navigate(path, { state });
        }
      },
      onError: (error: any) => {
        if (!R.isNil(error.errors?.product_key)) {
          setErrors(error.errors.product_key[0]);
        } else if (!R.isNil(error.errors?.user_token)) {
          setErrors(error.errors.user_token[0]);
        } else if (!R.isNil(error.errors?.user)) {
          setErrors(error.errors.user[0]);
        } else if (!R.isNil(error.errors?.base)) {
          setErrors(i18n.ft(messages.errors.ineligible));
        } else if (!R.isNil(error.message)) {
          setErrors(i18n.ft(messages.errors.noAvailable));
        } else {
          setErrors(i18n.ft(messages.errors.unknownError));
        }
      }
    });
  }

  return { ...asyncRequest, error, submit };
}

export default useProductKey;
