import RouteHelpers from 'helpers/routes';
import {
  ActionWrapper,
  SectionActions,
  ContainerSection as Section
} from 'components/Reports/Reliability/View/Styled';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const WhiteButton = styled(Button)`
  background-color: #3c3f42;
  border: 1px solid grey !important;
  border-radius: 5px;
  & > div {
    justify-content: center;
  }
`;

interface SubmitScoreSectionProps {
  isTablet: boolean;
}

function Footer({ isTablet }: SubmitScoreSectionProps) {
  const navigate = useNavigate();

  function handleCertificationsClick() {
    return navigate(RouteHelpers.getPath('certifications'));
  }

  return (
    <Section
      mt="8px"
      px="48px"
      py="24px"
      mx={{ _: '0px', lg: '32px' }}
      borderBottomRadius={isTablet ? '12px' : ''}
    >
      <SectionActions>
        <ActionWrapper />
        <ActionWrapper>
          <WhiteButton
            fluid
            className="!bg-white !border-blue-500"
            content={
              <div className="cursor-pointer flex items-center">
                Certifications
              </div>
            }
            onClick={handleCertificationsClick}
          />
        </ActionWrapper>
      </SectionActions>
    </Section>
  );
}

export default Footer;
