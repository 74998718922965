import styled from 'styled-components';

export const Container = styled.div`
  margin: 32px auto 0;
  max-width: 1280px;
`;

export const EmptyMessage = styled.div`
  padding: 128px 32px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${p => p.theme.colors['neutral']['500']};
`;

export const GroupContainer = styled.div`
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const GroupName = styled.div`
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 16px;
  color: ${p => p.theme.colors['neutral']['700']};

  @media screen and (min-width: 1024px) {
    padding: 0 0 16px;
  }
`;

export const LinksContainer = styled.div`
  margin: 0 0 32px;
`;
