import i18n from 'helpers/i18n';
import settings from 'helpers/settings';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Box } from 'components/Box';
import { Button } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import exitTest from 'images/reliabilityTest/exitTest.svg';
import {
  ContainerSection as Section,
  ActionWrapper,
  SectionActions,
  StyledButton
} from 'components/Reports/Reliability/View/Styled';
import {
  SecondaryActionWrapper,
  StyledWhiteButton
} from 'components/TestAttempts/Styled';
import { Status } from 'types/api/UserTest';

type URLParams = {
  id: string;
};
interface BottomSectionProps {
  attemptNumber: number;
  testAttemptId?: number;
  testType: 'reliability' | 'preparation' | 'knowledge';
  status?: Status;
}

function BottomSection({
  attemptNumber,
  testAttemptId,
  testType,
  status
}: BottomSectionProps) {
  const { id } = useRouteParams<URLParams>();
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ minWidth: 640 });
  const regionalTrainingsURL = `${settings.lookup('REACT_APP_TS_TRAININGS')}?fwp_course_type=virtual-training`;
  const reliabilitySupportURL = `${settings.lookup('REACT_APP_TS_RELIABILITY_SUPPORT')}?fwp_course_type=virtual-training`;
  const knowledgeTest = testType === 'knowledge' ? true : false;

  function getButtonContent() {
    if (knowledgeTest) {
      return i18n.ft(messages.certifications);
    }

    if (testType === 'preparation') {
      return i18n.ft(messages.continue);
    }

    if (status === 'passed') {
      return i18n.ft(messages.passAction);
    }

    if (attemptNumber === 3) {
      return i18n.ft(messages.observationTrainings);
    }

    return i18n.ft(messages.reliabilitySupport);
  }

  function handleNextAction() {
    if (knowledgeTest) {
      return navigate(RouteHelpers.getPath('certifications'));
    }

    if (testType === 'preparation') {
      if (testAttemptId) {
        return navigate(
          RouteHelpers.getPath('test-attempts', { id: testAttemptId })
        );
      }

      return navigate(RouteHelpers.getPath('app'));
    }

    if (status === 'passed') {
      return navigate(RouteHelpers.getPath('certifications'));
    }

    if (attemptNumber === 3) {
      return window.location.replace(regionalTrainingsURL);
    }

    return window.location.replace(reliabilitySupportURL);
  }

  function handleClick() {
    if (testType === 'reliability') {
      return navigate(RouteHelpers.getPath('app'));
    }
    if (!testAttemptId) {
      return navigate(RouteHelpers.getPath('certifications'));
    }

    return navigate(
      RouteHelpers.getPath('test-attempts', { id: testAttemptId })
    );
  }

  function handleReviewVideo() {
    if (testType === 'preparation') {
      return navigate(RouteHelpers.getPath('test-preparations', { id: id }));
    }

    return navigate(RouteHelpers.getPath('app'));
  }

  return (
    <Box mx={{ _: '0', lg: '48px' }} className="PrintBottomSection">
      <Section
        mt="8px"
        px="48px"
        py="24px"
        borderBottomRadius={isTablet ? '12px' : ''}
      >
        <SectionActions>
          <ActionWrapper>
            <StyledButton
              basic
              fluid
              content={
                <div className="cursor-pointer flex items-center">
                  <img
                    src={exitTest}
                    alt={i18n.ft(messages.exitTest)}
                    className="mr-5"
                  />
                  <span className="m-auto whitespace-nowrap">
                    {i18n.ft(messages.exitTest)}
                  </span>
                </div>
              }
              onClick={handleClick}
            ></StyledButton>
          </ActionWrapper>
          {testType === 'preparation' && (
            <SecondaryActionWrapper>
              <StyledWhiteButton
                fluid
                content={i18n.ft(messages.reviewVideo)}
                onClick={handleReviewVideo}
              />
            </SecondaryActionWrapper>
          )}
          <ActionWrapper>
            <Button
              fluid
              content={getButtonContent()}
              color="blue"
              as={'a'}
              onClick={handleNextAction}
              target={'_blank'}
              role="button"
              tabIndex={0}
            />
          </ActionWrapper>
        </SectionActions>
      </Section>
    </Box>
  );
}

export default BottomSection;
