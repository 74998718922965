const PATH = 'fe.components.training_catalog.create.center_select';

const messages = {
  center: {
    key: `${PATH}.center`,
    defaultValue: 'Center'
  }
};

export default messages;
