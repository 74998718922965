import styled from 'styled-components';

export const ScoreGroup = styled.div`
  margin-top: 8px;
  padding-bottom: 10px;
  background-color: #f8fcfe;
  border-radius: 8px;
  color: ${p => p.theme.colors['neutral']['700']};
`;

export const GroupTitle = styled.div<{ background?: string }>`
  background-color: ${p => p.background};
  border-radius: 18px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
`;

export const DomainTitle = styled.div<{ background?: string }>`
  background-color: ${p => p.background};
  border-radius: 18px;
  padding: 9px 16px;
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;

  padding-top: ${p => (p.background ? '12px' : '0')};
  padding-bottom: ${p => (p.background ? '12px' : '0')};

  @media screen and (min-width: 640px) {
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const ListItem = styled.li`
  margin-left: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
`;

export const Wrapper = styled.div`
  color: ${p => p.theme.colors['scaleDarkBlue']};
  padding: 12px;
  border-radius: 12px;
  box-shadow: 1px 1px 11px #d7e6ed;

  @media screen and (min-width: 640px) {
    margin-left: 56px;
    margin-right: 56px;
  }
`;

export const Totals = styled.div`
  font-family: proxima-nova;
  color: #3c3f42;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  @media screen and (min-width: 640px) {
    margin-right: 56px;
  }
`;
