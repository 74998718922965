import styled from 'styled-components';

export const Wrapper = styled.div`
  box-shadow: 0px 22px 40px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border-radius: 20px;
  padding: 16px;

  @media screen and (min-width: 640px) {
    padding: 32px 48px;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const TextDate = styled.span`
  padding: 8px 12px;
  background-color: #f4f8fa;
  border-radius: 20px;
`;

export const TextBold = styled.span`
  font-weight: 700;
`;

export const Dates = styled.div`
  display: none;

  @media screen and (min-width: 640px) {
    display: flex;
    gap: 8px;
    align-items: baseline;
  }
`;

export const DatesMobile = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
  justify-content: center;
  margin-top: 12px;

  @media screen and (min-width: 640px) {
    display: none;
  }
`;

export const DetailsTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`;

export const DetailsBottom = styled.div`
  margin-top: 16px;

  @media screen and (min-width: 640px) {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const Hours = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media screen and (min-width: 640px) {
    justify-content: flex-start;
  }
`;

export const Ceus = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 8px;

  @media screen and (min-width: 640px) {
    margin-top: 0;
    margin-left: 48px;
    justify-content: flex-start;
  }
`;

export const Circle = styled.span<{ color: string }>`
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
  background-color: ${p => p.color};
  text-align: center;
  display: inline-block;
  margin-left: 40px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 16px;

  @media screen and (min-width: 640px) {
    margin-top: 0;
    margin-left: auto;
    flex-basis: 256px;
    min-width: 256px;
  }
`;
