const PATH = 'fe.components.measure.view.footer_nav';

const messages = {
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  },
  next: {
    key: `${PATH}.next`,
    defaultValue: 'Next'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Submit'
  }
};

export default messages;
