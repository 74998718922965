import { map, split, toUpper, join, take, match } from 'ramda';

function getNameInitials(name: string): string {
  const names = split(' ', name);
  const firstCharOfNames = map(n => toUpper(n[0]), names);
  const firstTwoInitials = take(2, firstCharOfNames);
  return join('', firstTwoInitials);
}

function getEmailInitials(email: string): string {
  const onlyAlpha = match(/([a-z])/gi, email);
  const firstTwoChars = take(2, map(toUpper, onlyAlpha));
  return join('', firstTwoChars);
}

export function getInitials(email: string, name?: string): string {
  return name ? getNameInitials(name) : getEmailInitials(email);
}
