import React from 'react';
import { map } from 'ramda';
import styled from 'styled-components';
import SessionCard, { SessionCardProps } from '../SessionCard';

const Container = styled.div`
  overflow: auto;
  white-space: nowrap;
  max-width: 704px;
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

interface SesssionSelectorProps {
  /** Array of sessions. */
  sessions: SessionCardProps[];

  /** The ID of the active selected session. */
  activeSessionId?: number;

  /** Callback fired when the active session changes. */
  onChange?: (sessionId: number) => void;
}

const SessionSelector: React.FC<SesssionSelectorProps> = ({
  sessions,
  activeSessionId,
  onChange
}) => {
  return (
    <Container>
      {map(session => {
        return (
          <SessionCard
            key={session.date}
            onClick={onChange}
            active={session.id === activeSessionId}
            {...session}
          />
        );
      }, sessions)}
    </Container>
  );
};

export default SessionSelector;
