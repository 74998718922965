import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`;

export const Details = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  word-break: break-word;
`;

export const Email = styled.div`
  font-size: 14px;
  line-height: 1;
  word-break: break-all;
  margin-top: 4px;
`;

export const Status = styled.div`
  font-size: 14px;
  line-height: 1;
  word-break: break-all;
  margin-top: 6px;
`;
