import i18n from 'helpers/i18n';
import messages from './messages';

interface PassMessageProps {
  title: string;
  testType?: 'reliability' | 'preparation';
  message?: string;
}

function PassMessage({ title, testType, message }: PassMessageProps) {
  return (
    <div
      className={`${testType !== 'preparation' ? 'mt-4' : ''} mx-5 text-center`}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: message ? message : i18n.ft(messages.message)
        }}
      />
      {` ${title}`}!
    </div>
  );
}

export default PassMessage;
