import * as R from 'ramda';
import LoginForm from '../LoginForm';
import SignUpForm from '../SignUpForm';
import React, { useState } from 'react';
import { UserToken } from 'types/api/UserToken';
import RouteHelpers from 'helpers/routes';
import PhonePrompt from 'components/Users/PhonePrompt';
import ContainerWithLogo from 'components/ContainerWithLogo';

type ActionTypes = 'login' | 'register' | 'enterPhone';

interface InviteProps {
  /** User Token. */
  userToken: UserToken;
}

const Invite: React.FC<InviteProps> = ({ userToken }) => {
  const [action, setAction] = useState<ActionTypes>(
    R.isNil(userToken.user_id) ? 'register' : 'login'
  );

  function getRedirect() {
    switch (userToken.type_name) {
      case 'training_invite':
        return RouteHelpers.getPath('td-trainings-view-details', {
          id: userToken.resource_id
        });
      case 'subscription_invite':
        return RouteHelpers.getPath('user-token-invitation', {
          token: userToken.token
        });
      default:
        return RouteHelpers.getPath('users-login');
    }
  }

  function handlePhoneSuccess() {
    const redirectURL = getRedirect();
    window.location.replace(redirectURL);
  }

  function renderForm() {
    switch (action) {
      case 'login':
        return (
          <LoginForm
            userToken={userToken}
            onSignUp={() => setAction('register')}
            onSuccess={() => setAction('enterPhone')}
          />
        );
      case 'register':
        return (
          <SignUpForm
            userToken={userToken}
            onLogIn={() => setAction('login')}
            onSuccess={() => setAction('enterPhone')}
          />
        );
      case 'enterPhone':
        return <PhonePrompt onSuccess={handlePhoneSuccess} />;
      default:
        return null;
    }
  }

  return <ContainerWithLogo>{renderForm()}</ContainerWithLogo>;
};

export default Invite;
