import styled from 'styled-components';

export const Header = styled.div`
  color: ${p => p.theme.colors['neutral']['900']};
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;

  img {
    vertical-align: middle;
    margin-right: 8px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 12px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const IconWrapper = styled.div`
  margin-right: 16px;
  position: relative;
  top: 4px;
`;

export const Icon = styled.img`
  display: inline-block;
  width: 48px;
  max-width: 48px;
  max-height: 56px;
`;

export const ReminderWrapper = styled.div`
  &:not(:last-child) {
    border-bottom: solid 5px #f4f8fa;
    padding-bottom: 25px;
    margin-bottom: 24px;
  }
`;

export const TypeLabel = styled.div`
  color: ${p => p.theme.colors['neutral']['700']};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;

export const Title = styled.div`
  color: ${p => p.theme.colors['neutral']['900']};
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`;

export const Subtitle = styled.div`
  color: ${p => p.theme.colors['neutral']['500']};
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
`;

export const EmptyText = styled.div`
  font-style: italic;
  color: ${p => p.theme.colors['neutral']['700']};
`;
