import { SelectedAccount } from 'types/api/Account';

/*
 * The current_account session variable is shared across all areas, but not all areas have the same accounts available.
 * This function helps to prevent the automatic selection of the account when it doesn't belong to the area.
 */
export function getCurrentAccount(
  currentAccountId: number | undefined,
  accounts: SelectedAccount[]
): number {
  if (isCurrentAccountOnTheList(currentAccountId, accounts)) {
    return currentAccountId || 0;
  }
  return 0;
}

function isCurrentAccountOnTheList(
  currentAccountId: number | undefined,
  accounts: SelectedAccount[]
): boolean {
  if (!currentAccountId) {
    return false;
  }

  return accounts?.map(acc => acc.id).includes(currentAccountId);
}
