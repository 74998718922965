import i18n from 'helpers/i18n';
import messages from './messages';
import { REPORT_STATUS } from 'types/api/ReliabilityReport';

export function getStatusLabel(status?: REPORT_STATUS): string {
  switch (status) {
    case 'not_started':
    case 'available':
      return i18n.ft(messages.notStarted);
    case 'in_progress':
      return i18n.ft(messages.inProgress);
    case 'passed':
      return i18n.ft(messages.passed);
    case 'failed':
      return i18n.ft(messages.failed);
    default:
      return i18n.ft(messages.none);
  }
}
