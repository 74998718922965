import styled from 'styled-components';

const PageBackground = styled.div<{ borderStyle?: boolean }>`
  border-radius: ${p => (p.borderStyle ? '0;' : '32px;')};
  margin: 32px 0;
  padding: ${p => (p.borderStyle ? '0 0;' : '24px;')};
  background-color: ${p => (p.borderStyle ? '#F3F4F6;' : '#eaecf1;')};

  @media screen and (min-width: 768px) {
    padding: 48px;
    background-color: #eaecf1;
    border-radius: 32px;
  }

  @media screen and (min-width: 1280px) {
    padding: ${p => (p.borderStyle ? '24px' : '80px;')};
  }
`;

export default PageBackground;
