import React from 'react';
import PropTypes from 'prop-types';
import { Field } from './Styled';

interface TrainingFieldProps {
  icon: string;
  text: string;
  spacing?: string;
  maxWidth?: string;
  size?: string;
}

function TrainingField({
  icon,
  text,
  spacing,
  maxWidth = 'inherit',
  size = 'small'
}: TrainingFieldProps) {
  if (!text) {
    return null;
  }

  return (
    <Field spacing={spacing} maxWidth={maxWidth}>
      <Field.Icon circular name={icon} color="blue" />
      <Field.Text bold size={size} content={text} />
    </Field>
  );
}

TrainingField.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  spacing: PropTypes.string,
  maxWidth: PropTypes.string,
  size: PropTypes.string
};

TrainingField.Group = Field.Group;

export default TrainingField;
