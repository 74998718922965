import { ReactNode } from 'react';
import { Wrapper, Logo } from './Styled';
import myTeachstoneLogo from 'images/myteachstone_logo.svg';

interface ContainerWithLogoProps {
  /** A React Node. */
  children: ReactNode;

  /** If `false`, the logo will not be displayed. Defaults to `true`. */
  showLogo?: boolean;
}

/**
 * Typically used within a PageLayout
 */
function ContainerWithLogo({
  children,
  showLogo = true
}: ContainerWithLogoProps) {
  return (
    <Wrapper>
      {showLogo && <Logo alt="myTeachstone" src={myTeachstoneLogo} />}
      {children}
    </Wrapper>
  );
}

export default ContainerWithLogo;
