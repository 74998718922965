import styled from 'styled-components';

export const Wrapper = styled.nav`
  background-color: white;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: ${p => p.theme.colors['neutral']['700']};

  @media screen and (min-width: 1280px) {
    padding: 16px 56px;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const ListItem = styled.li`
  position: relative;
`;

export const VerticalDivider = styled.div`
  border-left: 1px solid #dbdbdb;
  height: 64px;
  margin-right: 12px;
`;
