import i18n from 'helpers/i18n';
import { useRef, useState } from 'react';
import messages from './messages';
import { Box } from 'components/Box';
import useAsync from 'hooks/useAsync';
import SingleAnswer from './SingleAnswer';
import RouteHelpers from 'helpers/routes';
import MultipleAnswer from './MultipleAnswer';
import ShowKTResource from './ShowKTResource';
import { useNavigate } from 'react-router-dom';
import MainContent from 'components/MainContent';
import PageWrapper from 'components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import { KnowledgeTestAttempt } from 'types/api/TestAttempt';
import { useRouteParams } from 'hooks/useRouteParams';
import InnerHeader from '../TestAttempts/InnerHeader';
import PageBackground from 'components/PageBackground';
import { URLParams } from 'components/TestAttempts/utils';
import { evaluateTestAttempt } from 'actions/testAttempts';
import exitTest from 'images/reliabilityTest/exitTest.svg';
import { getDomId } from 'pages/Testing/Reliability/utils';
import { setCurrentQuestion } from 'actions/knowledgeTests';
import hamburgerMenu from 'images/knowledgeTest/hamburgerMenu.svg';
import {
  LinkButton,
  PageHeaderWrapper,
  StyledWhiteButton
} from '../TestAttempts/Styled';
import logoCertification from 'images/reliabilityTest/logoCertification.svg';
import InstructionsModal from 'components/Modals/InstructionsModal';
import { ContainerSection as Section } from 'components/Reports/Reliability/View/Styled';
import {
  Title,
  LogoImg,
  Subtitle,
  TitleWrapper
} from 'components/PageHeader/Styled';
import {
  StyledButton,
  ContainerSection
} from 'components/Reports/Reliability/View/Styled';
import ConfirmationModal from 'components/ReliabilityTest/ConfirmationModal/ConfirmationModal';
import { ModalButton } from 'components/ReliabilityTest/ConfirmationModal/Styled';
import ModalContent from './Modal/ModalContent';
import QuestionsSummary from './Modal/QuestionsSummary';
import PageLoader from 'components/PageLoader';
import { toastError } from 'components/Toast';
import { useKnowledgeTest } from './KnowledgeTestContext';
import { focusQuestionDescription } from './utils';

function KnowledgeTestQuestions({
  attempt,
  completed_at,
  current_question,
  current_resource_id,
  expires_at,
  id,
  next_question_id,
  previous_question_id,
  title
}: KnowledgeTestAttempt) {
  const { run } = useAsync();
  const [showModal, setShowModal] = useState(false);
  const { id: urlId } = useRouteParams<URLParams>();
  const { canSubmit } = useKnowledgeTest();
  const [questionState, setQuestionState] = useState({
    nextQuestionId: next_question_id,
    previousQuestionId: previous_question_id
  });
  const isTablet = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();
  const kalturaPlayerID = getDomId(`${urlId}`);
  const [question, setQuestion] = useState(current_question);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const viewInstructionsRef = useRef<HTMLButtonElement>(null);
  const viewTestStatusRef = useRef<any>({ current: null });
  const descriptionRef = useRef<any>({ current: null });

  function nextQuestion() {
    setCurrentQuestion(id, questionState.nextQuestionId).then(
      ({ data: nextQuestion }) => {
        setQuestion(nextQuestion.current_question);
        setQuestionState({
          nextQuestionId: nextQuestion.next_question_id,
          previousQuestionId: nextQuestion.previous_question_id
        });
        // Accessibility user experience
        focusQuestionDescription();
      }
    );
  }

  function previousQuestion() {
    setCurrentQuestion(id, questionState.previousQuestionId).then(
      ({ data: previousQuestion }) => {
        setQuestion(previousQuestion.current_question);
        setQuestionState({
          nextQuestionId: previousQuestion.next_question_id,
          previousQuestionId: previousQuestion.previous_question_id
        });
        // Accessibility user experience
        focusQuestionDescription();
      }
    );
  }

  function handleClick() {
    return navigate(RouteHelpers.getPath('app'));
  }

  function getAllAnswers() {
    setShowConfirmationModal(true);
  }

  function handleSubmit() {
    setShowConfirmationModal(false);
    setIsSubmitting(true);
    if (isReadyToSubmit) {
      run(evaluateTestAttempt(Number(id)), {
        onSuccess: () => {
          setIsSubmitting(false);
          navigate(
            RouteHelpers.getPath('test-attempts-score-report', {
              id: id
            })
          );
        },
        onError: () => {
          setIsSubmitting(false);
          toastError({ description: i18n.ft(messages.error) });
        }
      });
    }
  }

  const handleViewTestStatusFocus = () => {
    const modalElement = document.getElementById('view-test-status');

    if (modalElement) {
      viewTestStatusRef.current = modalElement;
      viewTestStatusRef.current.focus();
    }
    setShowConfirmationModal(false);
  };

  if (completed_at) {
    navigate(
      RouteHelpers.getPath('test-attempts-score-report', {
        id: id
      })
    );
    return null;
  }

  if (isSubmitting) {
    return <PageLoader />;
  }

  return (
    <MainContent maxWidth={1280}>
      <ConfirmationModal
        title={
          <div className="text-center mt-0 text-[#017EA7]">
            {canSubmit ? i18n.ft(messages.sure) : i18n.ft(messages.title)}
          </div>
        }
        message={
          <QuestionsSummary
            setQuestion={setQuestion}
            setQuestionState={setQuestionState}
            setShowConfirmationModal={setShowConfirmationModal}
            setIsReadyToSubmit={setIsReadyToSubmit}
          />
        }
        dismissButtonText={i18n.ft(messages.dismiss)}
        confirmButtonText={i18n.ft(messages.submitTest)}
        isOpen={showConfirmationModal}
        onConfirm={handleSubmit}
        onCancel={() => {
          handleViewTestStatusFocus();
        }}
        onClose={() => {
          handleViewTestStatusFocus();
        }}
        knowledgeTest
        isReadyToSubmit={isReadyToSubmit}
      />
      <InstructionsModal
        title={i18n.ft(messages.modal.instructionsTitle)}
        message={<ModalContent />}
        isOpen={showModal}
        confirmButton={i18n.ft(messages.modal.dismissButton)}
        onConfirm={() => {
          viewInstructionsRef.current?.focus();
          setShowModal(false);
        }}
        onClose={() => {
          viewInstructionsRef.current?.focus();
          setShowModal(false);
        }}
      />
      <PageWrapper borderStyle>
        <PageHeaderWrapper>
          <TitleWrapper>
            <LogoImg
              src={logoCertification}
              alt={i18n.ft(messages.logoDescription)}
            />
            <Title tabIndex={0}>{title}</Title>
            <Subtitle>{i18n.ft(messages.subtitle)}</Subtitle>
          </TitleWrapper>
        </PageHeaderWrapper>

        <PageBackground borderStyle>
          <InnerHeader
            attempt={i18n.ft(messages.attempt, {
              value: attempt
            })}
            expiresOn={i18n.ft(messages.expiresOn)}
            expiresOnMobile={i18n.ft(messages.expiresOnMobile)}
            expiresAt={expires_at}
          />
          <Box mb={{ _: '24px', xl: '32px' }}>
            <Section
              mt="8px"
              px="48px"
              py="24px"
              mx={{ _: '0px', lg: '32px' }}
              isMobile
              borderTopRadius={isTablet ? '12px' : ''}
            >
              <div
                ref={viewTestStatusRef}
                id="view-test-status"
                className="flex font-semibold mb-3 cursor-pointer"
                onClick={() => setShowConfirmationModal(true)}
                tabIndex={0}
                onKeyDown={e => {
                  (e.key === 'Enter' || e.code === 'Space') &&
                    setShowConfirmationModal(true);
                }}
              >
                <img src={hamburgerMenu} alt={''} className="mr-2" />
                <span className="self-center">{`Question ${question.sequence} of 30 - ${question.category}`}</span>
                <span className="sr-only">{i18n.ft(messages.menu)}</span>
              </div>
              <div className="flex justify-center">
                <ShowKTResource
                  testAttemptId={id}
                  resourceId={current_resource_id}
                  imageURL={question.image_url}
                  isTablet={isTablet}
                  kalturaPlayerID={kalturaPlayerID}
                  videoHostId={question.video_host_id}
                />
              </div>
              <div className="flex justify-center"></div>
              <div style={{ backgroundColor: '#F9F9F9' }} className="mt-3 p-2">
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div className="text-left mt-2 font-bold" tabIndex={0}>
                    {question.video_host_id ? i18n.ft(messages.watch) : null}
                  </div>

                  <div className="">
                    <LinkButton
                      type="button"
                      onClick={() => setShowModal(true)}
                      className={`mt-2 font-bold mx-auto w-full text-left md:text-end lg:text-end${
                        isTablet ? '' : 'text-start'
                      }`}
                      ref={viewInstructionsRef}
                    >
                      {i18n.ft(messages.testInstructions)}
                    </LinkButton>
                  </div>
                </div>
                <div
                  ref={descriptionRef}
                  className="pt-4 mr-8"
                  tabIndex={0}
                  id="question-description"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: question.description
                    }}
                  ></span>
                </div>
                <div className="font-bold mt-4" tabIndex={0}>
                  {i18n.ft(
                    question.question_type === 'single'
                      ? messages.answerSingleChoice
                      : messages.answerMultipleChoice
                  )}
                </div>
                {question.question_type === 'single' ? (
                  <SingleAnswer
                    answer_options={question.answer_options}
                    category={question.category}
                    questionId={question.id}
                  />
                ) : (
                  <MultipleAnswer
                    answer_options={question.answer_options}
                    category={question.category}
                    questionId={question.id}
                  />
                )}
              </div>
            </Section>

            <ContainerSection
              mt="8px"
              px={{ _: '8px', lg: '48px' }}
              py="24px"
              mx={{ _: '0px', lg: '32px' }}
              borderBottomRadius={
                question.sequence === 30 ? '' : isTablet ? '12px' : ''
              }
            >
              <div className="mb-2 block md:flex">
                <StyledButton
                  basic
                  fluid
                  content={
                    <div className="cursor-pointer flex items-center md:mb-0">
                      <img
                        src={exitTest}
                        alt={i18n.ft(messages.exit)}
                        className="mr-5"
                      />
                      <div
                        style={{
                          textAlign: `${!isTablet ? 'center' : 'left'}`,
                          width: `${!isTablet ? '100%' : 'inherit'}`
                        }}
                      >
                        {i18n.ft(messages.exit)}
                      </div>
                    </div>
                  }
                  onClick={handleClick}
                  tabIndex={0}
                />
                <div className="mb-2 md:mb-0 md:contents">
                  <StyledWhiteButton
                    fluid
                    content={i18n.ft(messages.back)}
                    onClick={previousQuestion}
                    style={{ marginRight: '10px' }}
                    disabled={question.sequence === 1}
                    tabIndex={0}
                  />
                </div>
                <StyledWhiteButton
                  fluid
                  content={i18n.ft(messages.next)}
                  onClick={nextQuestion}
                  disabled={question.sequence === 30}
                  tabIndex={0}
                />
              </div>
            </ContainerSection>
            {question.sequence === 30 || canSubmit ? (
              <ContainerSection
                mt="8px"
                px={{ _: '8px', lg: '48px' }}
                py="24px"
                mx={{ _: '0px', lg: '32px' }}
                borderBottomRadius={isTablet ? '12px' : ''}
              >
                <div className="mb-2 block md:flex">
                  <ModalButton
                    fluid
                    content={i18n.ft(messages.submitTest)}
                    onClick={() => getAllAnswers()}
                    $isTestIncomplete={!canSubmit}
                  />
                </div>
              </ContainerSection>
            ) : null}
          </Box>
        </PageBackground>
      </PageWrapper>
    </MainContent>
  );
}

export default KnowledgeTestQuestions;
