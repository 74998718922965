import { clsx } from 'clsx';
import { IconCheck, IconError } from 'components/icons';

interface SectionStatusProps {
  /** Status to represent. */
  status: 'pending' | 'completed' | 'error';

  /** When status is completed, a number can be rendered instead of check icon. */
  statusValue?: number;
}

export function SectionStatus({ status, statusValue }: SectionStatusProps) {
  if (status === 'completed' && !statusValue) {
    return (
      <div className="ml-auto" role="status">
        <div className="sr-only">{status}</div>
        <IconCheck size="3xl" />
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="ml-auto" role="status">
        <div className="sr-only">{status}</div>
        <IconError size="3xl" />
      </div>
    );
  }

  const wrapperClasses = clsx(
    'w-[30px] h-[30px] rounded-full flex items-center justify-center',
    status === 'completed' ? 'bg-[#4A8264]' : 'bg-[#EDEFF2]'
  );

  return (
    <div className="ml-auto" role="status">
      <div className="sr-only">{status}</div>
      <div className={wrapperClasses}>
        {Boolean(statusValue) && status === 'completed' && (
          <span className="font-bold text-white">{statusValue}</span>
        )}
      </div>
    </div>
  );
}
