const PATH = 'fe.pages.measure.completed.completed_class';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Completed Observation'
  },
  returnToList: {
    key: `${PATH}.return_to_list`,
    defaultValue: 'Return to List'
  },
  downloadPDF: {
    key: `${PATH}.download_pdf`,
    defaultValue: 'Download PDF'
  },
  downloadCSV: {
    key: `${PATH}.download_csv`,
    defaultValue: 'Download CSV'
  }
};

export default messages;
