import clsx from 'clsx';
import * as R from 'ramda';
import ReactQuill, { ReactQuillProps } from 'react-quill';

type QuillModules = {
  [key: string]: any;
};

type QuillFormats = string[];

const DEFAULT_MODULES: QuillModules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'bullet' }, { list: 'ordered' }],
    ['link']
  ],
  clipboard: {
    matchVisual: false
  }
};

const DEFAULT_FORMATS: QuillFormats = [
  'bold',
  'italic',
  'underline',
  'bullet',
  'list',
  'link'
];

export interface RichTextEditorProps extends ReactQuillProps {
  /** Custom className. */
  className?: string;

  /** If `true`, the editor text can only be read. */
  readOnly?: boolean;

  /** If `true`, the editor colors will turn to error red. */
  hasError?: boolean;

  /** Text content for the input. */
  value?: string;

  /** If `true`, headings will be allowed in the toolbar. */
  headings?: boolean;
}

function RichTextEditor({ headings, ...props }: RichTextEditorProps) {
  const classes = clsx('RichTextEditor', props.className, {
    'RichTextEditor--readOnly': props.readOnly,
    'RichTextEditor--error': props.hasError
  });

  const restProps = R.omit(['className', 'error'], props);

  let modules = R.clone(DEFAULT_MODULES);
  let formats = R.clone(DEFAULT_FORMATS);

  if (headings) {
    modules.toolbar = [[{ header: 1 }], ...modules.toolbar];
    formats = ['header', ...formats];
  }

  return (
    <ReactQuill
      {...restProps}
      modules={modules}
      formats={formats}
      className={classes}
    />
  );
}

export default RichTextEditor;
