const PATH = 'fe.components.learning_resources.mark_as_important';

const messages = {
  mark: {
    key: `${PATH}.mark`,
    defaultValue: 'Mark as important'
  },
  important: {
    key: `${PATH}.important`,
    defaultValue: 'Resource is not important anymore'
  },
  failed: {
    key: `${PATH}.failed`,
    defaultValue: 'Failed to update resource'
  },
  marked: {
    key: `${PATH}.marked`,
    defaultValue: 'Resource marked as important'
  },
  unmark: {
    key: `${PATH}.unmark`,
    defaultValue: 'Important'
  }
};

export default messages;
