import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { applyCoupon } from 'actions/trainings';
import { Container, SubmitButton } from '../Styled';
import { Formik, Form, Input as InputBase } from 'components/EnhancedForm';

const Input = styled(InputBase)`
  .field.disabled {
    margin-bottom: 0 !important;
  }
`;

const initialValues = {
  coupon_code: ''
};

const validationSchema = yup.object({
  coupon_code: yup.string().required(() => i18n.ft(messages.isRequired))
});

const CouponForm = ({ trainingId, afterSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);

  function handleSubmit(values, formik) {
    setLoading(true);
    applyCoupon(trainingId, values)
      .then(({ data }) => {
        setCouponApplied(true);
        afterSubmit(data);
      })
      .catch(() => {
        formik.setFieldError('coupon_code', i18n.ft(messages.unableToApply));
      })
      .finally(() => setLoading(false));
  }

  return (
    <Container>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ submitForm }) => (
          <Form>
            <Input
              name="coupon_code"
              disabled={couponApplied}
              label={i18n.ft(messages.haveCoupon)}
              aria-label={i18n.ft(messages.couponCode)}
              placeholder={i18n.ft(messages.enterCoupon)}
            />

            {!couponApplied && (
              <SubmitButton>
                <Button
                  size="small"
                  color="blue"
                  loading={loading}
                  disabled={loading}
                  onClick={submitForm}
                  content={i18n.ft(messages.apply)}
                />
              </SubmitButton>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

CouponForm.propTypes = {
  /** The training ID. */
  trainingId: PropTypes.number.isRequired,

  /**
   * Callback fired after the form is submitted.
   * Returns a Big Commerce checkout object.
   **/
  afterSubmit: PropTypes.func.isRequired
};

export default CouponForm;
