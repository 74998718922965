import i18n from 'helpers/i18n';
import messages from './messages';
import { Input } from 'semantic-ui-react';
import { getMyEducators } from 'actions/coachings';
import { toastError } from 'components/Toast';
import { useCallback, useEffect, useState } from 'react';
import FormDropdown from 'components/FormDropdown';
import { Loader } from 'semantic-ui-react';
import { useLocationState } from 'hooks/useLocationState';
import SearchResults from 'components/LearningResources/SearchResults';
import CompletedRecommended from 'components/LearningResources/CompletedRecommended';
import ClassroomScores from 'components/LearningResources/ClassroomScores/ClassroomScores';

type LocationState = {
  educatorId?: number;
};

function Recommend() {
  const [currentQuery, setCurrentQuery] = useState('');
  const [query, setQuery] = useState('');
  const state = useLocationState<LocationState>();
  const [educators, setEducators] = useState<any>(null);
  const [selectedAgeLevel, setSelectedAgeLevel] = useState<string>('Pre-K');
  const [selectedEducator, setSelectedEducator] = useState<any>({
    educatorId: state?.educatorId || 0,
    educatorName: ''
  });

  const ageLevels = [
    { key: 1, text: 'Infant', value: 'Infant' },
    { key: 2, text: 'Toddler', value: 'Toddler' },
    { key: 3, text: 'PreK', value: 'Pre-K' },
    { key: 4, text: 'K-3', value: 'K-3' }
  ];

  const getEducatorName = useCallback(
    (id?: any) => {
      const educator = educators?.find(
        (educator: any) => educator.value === id
      );
      return educator ? educator.text : '';
    },
    [educators]
  );

  useEffect(() => {
    getMyEducators()
      .then(({ data }) => {
        const educatorOptions = data.coachings.map((educator: any) => {
          return {
            key: educator.id,
            text: educator.first_name + ' ' + educator.last_name,
            value: educator.id
          };
        });
        setEducators(educatorOptions);
      })
      .catch(error => {
        toastError(error);
      });
  }, []);

  useEffect(() => {
    if (!educators) {
      return;
    }

    if (state?.educatorId) {
      setSelectedEducator({
        educatorId: state?.educatorId,
        educatorName: getEducatorName(state?.educatorId)
      });
    } else {
      setSelectedEducator({
        educatorId: educators[0].value,
        educatorName: educators[0].text
      });
    }
  }, [state?.educatorId, getEducatorName, educators]);

  function handleChange(e: any) {
    setCurrentQuery(e.target.value);
    if (e.target.value === '') {
      setQuery('');
    }
  }

  if (!educators) {
    return <Loader active />;
  }

  return (
    <div className="mt-8">
      <div className="grid grid-cols-1 lg:grid-cols-7 gap-2 mb-8">
        <div className="col-span-1 lg:col-span-2">
          <div className="font-semibold text-black mb-1">
            {i18n.ft(messages.select)}
          </div>
          <FormDropdown
            onChange={(_, data) => {
              setSelectedEducator({
                educatorId: Number(data.value),
                educatorName: getEducatorName(data.value)
              });
            }}
            value={selectedEducator.educatorId}
            loading={false}
            options={educators}
          />
        </div>
        <div className="col-span-1 lg:col-span-2">
          <div className="font-semibold text-black mb-1">
            {i18n.ft(messages.ageLevel)}
          </div>
          <FormDropdown
            onChange={(_, data) => {
              setSelectedAgeLevel(data.value as string);
            }}
            value={selectedAgeLevel}
            loading={false}
            options={ageLevels}
          />
        </div>
        <div className="col-span-1 lg:col-span-3">
          <div className="font-semibold text-black mb-1"></div>
          <Input
            fluid
            clearable
            id="search"
            icon="search"
            className="lg:min-w-[384px] mt-6"
            onChange={handleChange}
            value={currentQuery}
            onKeyDown={(e: { key: string }) => {
              if (e.key === 'Enter') {
                setQuery(currentQuery);
              }
            }}
          />
        </div>
      </div>
      {query === '' ? (
        <>
          <CompletedRecommended
            selectedEducatorId={selectedEducator.educatorId}
          />
          <ClassroomScores
            selectedEducatorId={selectedEducator.educatorId}
            selectedEducatorName={selectedEducator.educatorName}
            ageLevel={selectedAgeLevel}
          />
        </>
      ) : (
        <SearchResults query={query} />
      )}
    </div>
  );
}

export default Recommend;
