import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { logout } from 'actions/users';
import React, { useEffect } from 'react';
import { validateToken } from 'actions/userTokens';
import { Link } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import { Loader, Icon, Button } from 'semantic-ui-react';
import Invite from 'components/Tokens/View/Invite';
import PaymentRequest from 'components/Tokens/View/PaymentRequest';
import { UserToken } from 'types/api/UserToken';
import ContainerWithLogo from 'components/ContainerWithLogo';
import { MessageContainer, Message, Container } from './Styled';
import { useDispatch } from 'react-redux';
import { destroy } from 'store/slices/user';

type URLParams = {
  token: string;
};

const View: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useRouteParams<URLParams>();
  const { run, data: userToken, isPending, isSuccess, isError } = useAsync();

  useEffect(() => {
    run(validateToken(token), {
      onSuccess: () => {
        logout().then(() => {
          dispatch(destroy());
        });
      }
    });
  }, [run, token, dispatch]);

  function isInvite(userToken: UserToken) {
    return (
      userToken.type_name === 'subscription_invite' ||
      userToken.type_name === 'training_invite'
    );
  }

  return (
    <Container>
      {isPending && (
        <Loader
          active
          size="large"
          inline="centered"
          content={i18n.ft(messages.pleaseWait)}
        />
      )}

      {isError && (
        <ContainerWithLogo>
          <MessageContainer>
            <Icon name="warning circle" color="red" size="huge" />
            <Message
              dangerouslySetInnerHTML={{
                __html: i18n.ft(messages.expiredLink)
              }}
            />
            <Button as={Link} to="/" content={i18n.ft(messages.goBack)} />
          </MessageContainer>
        </ContainerWithLogo>
      )}

      {isSuccess && isInvite(userToken) && <Invite userToken={userToken} />}

      {isSuccess && userToken.type_name === 'training_payment_request' && (
        <PaymentRequest token={userToken} />
      )}
    </Container>
  );
};

export default View;
