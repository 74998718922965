const PATH = 'fe.components.reliability_test.coding_videos.video_progress_bar';

const messages = {
  currentVideo: {
    key: `${PATH}.current_video`,
    defaultValue: 'Current Video'
  },
  videoNumber: {
    key: `${PATH}.video_number`,
    defaultValue: 'Video {{value}}'
  }
};

export default messages;
