import i18n from 'helpers/i18n';
import messages from './messages';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

export const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f8fa;
  height: 36px;
  margin-top: 8px;
  width: 100%;
  border: 0;
  font-family: inherit;
  cursor: pointer;

  label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: ${p => p.theme.colors.scaleDarkBlue};
    cursor: pointer;
    margin-right: 16px;
  }

  i {
    width: 16px;
    height: 16px;
  }
`;

function BackToTop() {
  function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Wrapper onClick={scrollTop}>
      <label>{i18n.ft(messages.backToTop)}</label>
      <Icon fitted name="arrow circle up" color="blue" />
    </Wrapper>
  );
}

export default BackToTop;
