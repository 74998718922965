import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { Box } from 'components/Box';
import { Wrapper, Title } from './Styled';
import { MetaDataOption, MetaDataOptionProps } from './MetaDataOption';
import { CycleMetaDataValue } from 'types/api/envScales/CycleMetaDataValue';
import { MetaDataCategory } from 'types/api/envScales/MetaDataCategory';
import { ObservationCycle } from 'types/api/envScales/ObservationCycle';
import { ContentAndFormat1stEditionError } from 'types/api/envScales/CyclesErrors';
import { updateCycleMetaData } from 'actions/envScales/observationCycles';
import {
  getInitialValues,
  getPayloadValues,
  SelectedValues,
  showMetaDataCategoryErrors
} from './utils';
import { ErrorMessage } from 'components/Measure/View/Styled';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import { useCyclesErrors } from 'context/CyclesErrorsContext';

interface MetaDataMultiSelectProps {
  /** Observation ID. */
  observationId: number;

  /** Cycle */
  cycle: ObservationCycle;

  /** Initial meta data values. */
  values: CycleMetaDataValue[];

  /** Meta data category. */
  category: MetaDataCategory;
}

function MetaDataMultiSelect({
  observationId,
  cycle,
  category,
  values: initialValues
}: MetaDataMultiSelectProps) {
  const { cyclesErrors, updateCycleErrors } = useCyclesErrors();
  const [touched, setTouched] = useState(false);
  const [values, setValues] = useState(
    getInitialValues(category, initialValues)
  );

  useDebouncedEffect(
    () => {
      if (!touched) {
        return;
      }

      const payload = {
        meta_data: {
          meta_data_category_id: category.id,
          values: getPayloadValues(values)
        }
      };

      updateCycleMetaData(observationId, cycle.id, payload);
    },
    1000,
    [values, touched]
  );

  const handleChange: MetaDataOptionProps['onChange'] = data => {
    let newValues = { ...values };
    const isNewPrimary = data.value.isPrimary;

    if (isNewPrimary) {
      newValues = R.mapObjIndexed(
        value => ({ ...value, isPrimary: false }),
        newValues
      );
    }

    const mergedValues = { ...newValues, [data.name]: data.value };
    setValues(mergedValues);
    validate(mergedValues);
    setTouched(true);
  };

  function validate(values: SelectedValues) {
    if (R.isEmpty(cyclesErrors)) {
      return;
    }

    const categoryChecked = Object.keys(values)
      .map(categoryName => values[categoryName].isChecked)
      .some(checked => checked);
    const categoryPrimaryChecked = Object.keys(values)
      .map(categoryName => values[categoryName].isPrimary)
      .some(checked => checked);

    let newCyclesErrors = R.clone(cyclesErrors);

    let checkedError = null;
    if (!categoryChecked) {
      checkedError = i18n.ft(messages.errors.categoryError, {
        categoryName: category.t_name
      });
    }
    (
      newCyclesErrors[cycle.cycle_number]
        .meta_data_categories as ContentAndFormat1stEditionError
    )[category.t_name].error = checkedError;

    let primaryChecked = null;
    if (!categoryPrimaryChecked) {
      primaryChecked = i18n.ft(messages.errors.primaryError, {
        categoryName: category.t_name
      });
    }
    (
      newCyclesErrors[cycle.cycle_number]
        .meta_data_categories as ContentAndFormat1stEditionError
    )[category.t_name].primary_error = primaryChecked;

    updateCycleErrors(newCyclesErrors);
  }

  return (
    <Wrapper>
      <Title>{i18n.ft(messages.type, { category: category.name })}</Title>
      <Box mt="12px">{category.description}</Box>

      {showMetaDataCategoryErrors(
        cyclesErrors,
        category.t_name,
        cycle.cycle_number
      ) && (
        <ErrorMessage>
          {showMetaDataCategoryErrors(
            cyclesErrors,
            category.t_name,
            cycle.cycle_number
          )}
        </ErrorMessage>
      )}

      <Box mt="32px">
        {category.choices.map(choice => (
          <MetaDataOption
            key={choice.id}
            choice={choice}
            value={values[choice.t_name]}
            onChange={handleChange}
          />
        ))}
      </Box>
    </Wrapper>
  );
}

export default MetaDataMultiSelect;
