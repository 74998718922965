const PATH = 'fe.components.app.header';

const messages = {
  home: {
    key: `${PATH}.home`,
    defaultValue: 'Home'
  },
  help: {
    key: `${PATH}.help`,
    defaultValue: 'Help'
  },
  openNav: {
    key: `${PATH}.open_nav`,
    defaultValue: 'Open navigation menu'
  },
  learningResources: {
    key: `${PATH}.learning_resources`,
    defaultValue: 'Learning Resources 2.0'
  }
};

export default messages;
