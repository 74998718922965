import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.colors.white};
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: -1px 2px 10px 1px rgba(0, 0, 0, 0.2);
`;

export const ActionArea = styled.button<{
  isOpen: boolean;
  onClick: () => void;
}>`
  background-color: ${({ theme, isOpen }) =>
    isOpen ? '#F9FBFF' : theme.colors.white};
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  width: 100%;
  border: 0;
  cursor: pointer;
`;

export const LoaderWrapper = styled.div`
  margin: 64px 0 48px;
`;

export const SeeMoreArea = styled.div`
  padding: 10px;
`;

export const StatusArea = styled.div`
  padding: 10px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Sessions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 50px;
  margin-top: 10px;
`;

export const SessionTitle = styled.div`
  font-weight: bold;
  background-color: #f5f5f5;
  width: fit-content;
  padding: 4px 10px;
  border-radius: 10px;
  margin-bottom: 5px;
`;

export const SessionRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
`;

export const SessionNumber = styled.div`
  font-weight: bold;
`;

export const SessionDates = styled.div`
  margin-left: 8px;
  margin-bottom: 5px;
`;

export const Participants = styled.div`
  padding: 5px 50px;
`;

export const Table = styled.div<{ allColumns: boolean }>`
  display: grid;
  grid-template-columns: ${({ allColumns }) =>
    allColumns ? '1fr 1fr 1fr 1fr' : '1fr 1fr 0.5fr'};
  min-width: 100%;
  margin-bottom: 10px;
`;

export const TableHeader = styled.div`
  font-weight: bold;
  background-color: #f5f5f5;
  width: fit-content;
  padding: 4px 15px;
  border-radius: 10px;
  text-align: left;
`;

export const TableColumn = styled.div`
  padding: 5px 15px;
  border-bottom: 1px solid #eaeaec; ;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
`;

export const CardItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardTitle = styled.div`
  font-weight: bold;
  padding: 4px 15px;
`;

export const CardDetail = styled.div`
  padding: 4px 15px;
  display: flex;
  justify-content: flex-end;
`;
export const CardReliability = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Status = styled.div`
  margin-left: 3px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  padding: 10px 0;
`;
