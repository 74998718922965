const PATH = 'fe.components.trainings.view.checkout.payment_form';

const messages = {
  paymentMethod: {
    key: `${PATH}.payment_method`,
    defaultValue: 'Payment Method'
  },
  orderedInCvent: {
    key: `${PATH}.ordered_in_cvent`,
    defaultValue: 'Ordered in Cvent'
  },
  orderedInSalesforce: {
    key: `${PATH}.ordered_in_salesforce`,
    defaultValue: 'Ordered in Salesforce'
  },
  payWithCard: {
    key: `${PATH}.pay_with_card`,
    defaultValue: 'Pay with Credit Card or Purchase Order'
  },
  projectId: {
    key: `${PATH}.project_id`,
    defaultValue: 'Project ID'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  payNow: {
    key: `${PATH}.pay_now`,
    defaultValue: 'Pay Now'
  },
  continue: {
    key: `${PATH}.continue`,
    defaultValue: 'Continue'
  },
  errors: {
    paymentId: {
      required: {
        key: `${PATH}.errors.payment_id.required`,
        defaultValue: 'Project ID is required'
      }
    }
  }
};

export default messages;
