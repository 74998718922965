import React, { useState } from 'react';
import {
  Modal,
  Header,
  Button,
  SemanticICONS,
  SemanticShorthandContent
} from 'semantic-ui-react';

interface InfoModalProps {
  /**Title for the modal */
  title: string;

  /**Content message for the modal */
  message: SemanticShorthandContent;

  /**Text associate with the right button for complete the modal action */
  confirmButton: string;

  /**Header Icon  */
  icon: SemanticICONS;

  /** Node associate with the trigger of the modal */
  triggerNode?: React.ReactNode;

  /**Function call when the confirmation is completed */
  onConfirm: () => void;

  /**true when modal is open */
  isOpen: boolean;
}

function InfoModal({
  title,
  message,
  confirmButton,
  icon,
  triggerNode,
  onConfirm,
  isOpen
}: InfoModalProps) {
  const [isLoading, setIsloading] = useState(false);

  function handleConfirmation() {
    setIsloading(true);
    onConfirm();
  }

  return (
    <Modal trigger={triggerNode} open={isOpen} closeOnDimmerClick size="small">
      <Header icon={icon} content={title} />
      <Modal.Content content={message} />
      <Modal.Actions>
        <Button
          type="button"
          onClick={handleConfirmation}
          content={confirmButton}
          color="green"
          loading={isLoading}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default InfoModal;
