import i18n from 'helpers/i18n';
import messages from './messages';
import { useLayoutEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import LanguageSelector from 'components/LanguageSelector';
import teachstoneBg from 'images/teachstone-bg.jpg';
import useCurrentUser from 'hooks/useCurrentUser';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { Wrapper, BackgroundImg } from './Styled';

function UsersPage() {
  useDocumentTitle(i18n.ft(messages.login));
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (currentUser.id) {
      const isOnboardingCompleted = Boolean(currentUser.onboarding_viewed_at);

      if (isOnboardingCompleted) {
        navigate('/', { replace: true });
      } else {
        navigate('/users/onboarding', { replace: true });
      }
    }
  }, [navigate, currentUser.id, currentUser.onboarding_viewed_at]);

  return (
    <Wrapper>
      <BackgroundImg alt="" src={teachstoneBg} />
      <LanguageSelector />
      <Outlet />
    </Wrapper>
  );
}

export default UsersPage;
