import styled from 'styled-components';
import { NavLink as BaseNavLink } from 'react-router-dom';

export const Wrapper = styled.div`
  margin-top: 8px;
  padding: 16px;
`;

export const Header = styled.h1`
  font-size: 30px;
  line-height: 36px;
  color: ${p => p.theme.colors['neutral']['900']};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const Sidebar = styled.aside`
  margin-bottom: 16px;
  border-radius: 6px;
  border: 1px solid ${p => p.theme.colors['neutral']['200']};
  background-color: ${p => p.theme.colors['white']};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 24px;
    flex-shrink: 0;
    width: 256px;
    align-self: flex-start;
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;
  margin: 24px 0;
  padding: 0 24px;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ProfileName = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: ${p => p.theme.colors['neutral']['700']};
  margin: 12px 0;
  text-align: center;
`;

export const NavWrapper = styled.nav`
  border-top: 1px solid ${p => p.theme.colors['neutral']['200']};
  padding: 8px 0;
`;

export const NavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const NavItem = styled.li``;

export const NavLink = styled(BaseNavLink)`
  display: block;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 24px;
  color: ${p => p.theme.colors['neutral']['700']};

  &.active {
    font-weight: 600;
    padding-left: 20px;
    border-left: 4px solid ${p => p.theme.colors['primary']['700']};
    background-color: ${p => p.theme.colors['primary']['50']};
  }
`;

export const MainWrapper = styled.main`
  flex-grow: 1;
`;
