const PATH = 'fe.components.trainings.view.invitation_status';

const messages = {
  registered: {
    key: `${PATH}.registered`,
    defaultValue: 'Registered'
  },
  declined: {
    key: `${PATH}.declined`,
    defaultValue: 'Declined'
  },
  absent: {
    key: `${PATH}.absent`,
    defaultValue: 'Absent'
  }
};

export default messages;
