import styled from 'styled-components';

export const Logo = styled.img`
  max-width: 300px;
  display: block;
  margin: 16px auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 16px auto;
  padding: 32px;
  border-radius: 4px;
  background-color: ${p => p.theme.colors['white']};
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;

  @media screen and (min-width: 640px) {
    padding: 44px;
  }
`;
