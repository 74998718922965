import { map } from 'ramda';
import FormDropdown from 'components/FormDropdown';
import React, { useState, useEffect } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { defaultFormatter } from 'components/dropdowns/utils';
import { getGrades } from 'actions/grades';

const Grades: React.FC = props => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<DropdownItemProps[]>([]);

  useEffect(() => {
    setLoading(true);
    getGrades().then(({ data }) => {
      setOptions(map(defaultFormatter, data.grades));
      setLoading(false);
    });
  }, []);

  return <FormDropdown loading={loading} options={options} {...props} />;
};

export default Grades;
