import axios from 'axios';
import { Pagination } from 'types/api/Pagination';
import { TrainingTemplate } from 'types/api/TrainingTemplate';

export function getTemplates(params = {}) {
  return axios.get<{
    training_templates: TrainingTemplate[];
    pagination: Pagination;
  }>('/api/v2/training_templates', { params });
}

export function getTemplatesCatalog(params = {}) {
  return axios.get<{
    training_templates: TrainingTemplate[];
    pagination: Pagination;
  }>('/api/v2/training_templates/catalog', { params });
}

export function getTemplate(id: number) {
  return axios.get(`/api/v2/training_templates/${id}`);
}

export function updateTemplate(id: number, template = {}) {
  return axios.put(`/api/v2/training_templates/${id}`, {
    training_template: template
  });
}

export function getEligibleTrainers(templateId: number) {
  return axios.get(
    `/api/v2/training_templates/${templateId}/eligible_trainers`
  );
}

export function createTemplate(template = {}) {
  return axios.post('/api/v2/training_templates', {
    training_template: template
  });
}

export function deleteTemplate(id: number) {
  return axios.delete(`/api/v2/training_templates/${id}`);
}

export function uploadBanner(id: number, banner: FormData) {
  return axios.post(`/api/v2/training_templates/${id}/upload_banner`, banner);
}
