const PATH = 'fe.components.measure.home.default_home';

const messages = {
  measurement: {
    key: `${PATH}.measurement`,
    defaultValue: 'CLASS Data Collection'
  },
  measurementDesc: {
    key: `${PATH}.measurement_desc`,
    defaultValue: 'View and enter observation data.'
  },
  noObservations: {
    key: `${PATH}.no_observations`,
    defaultValue: 'There are no {{filter}} Observations.'
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Observer, Classroom, Center Name or Job ID'
  },
  selectObservation: {
    key: `${PATH}.select_observation`,
    defaultValue: 'Select Observation status'
  },
  inProgress: {
    key: `${PATH}.in_progress`,
    defaultValue: 'In-Progress'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed'
  },
  awaiting: {
    key: `${PATH}.awaiting`,
    defaultValue: 'Awaiting Approval'
  },
  noResults: {
    key: `${PATH}.no_results`,
    defaultValue: 'No results'
  },
  csv: {
    key: `${PATH}.csv`,
    defaultValue: 'Export Data'
  },
  filteredObservations: {
    key: `${PATH}.export_filtered_observation`,
    defaultValue: 'Export Filtered Observations ({{numberCount}})'
  },
  allObservations: {
    key: `${PATH}.export_all_observations`,
    defaultValue: 'Export All Observations'
  },
  countSummary: {
    key: `${PATH}.count_summary`,
    defaultValue: '({{current}} out of {{total}} showing)'
  },
  exportObservationsTooltip: {
    key: `${PATH}.export_observations_tooltip`,
    defaultValue:
      'Download a CSV spreadsheet of all observations matching your current filters.'
  },
  countSelected: {
    key: `${PATH}.count_selected`,
    defaultValue: '{{number}} observations selected'
  },
  selectMultiple: {
    key: `${PATH}.select_multiple`,
    defaultValue: 'Select Multiple Cards'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  approveObservation: {
    key: `${PATH}.approve_observation`,
    defaultValue: 'Approve Observation'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'Invalid transition'
  },
  filter: {
    key: `${PATH}.filter`,
    defaultValue: 'Filter'
  },
  reports: {
    key: `${PATH}.reports`,
    defaultValue: 'Go to Reports'
  },
  modal: {
    warning: {
      key: `${PATH}.modal.warning`,
      defaultValue: `Are you sure you want to approve all selected observations? You can't undo this action.`
    },
    yes: {
      key: `${PATH}.modal.yes`,
      defaultValue: 'Yes, Approve all'
    }
  }
};

export default messages;
