const PATH = 'fe.components.measure.summary.submit_modal';

const messages = {
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Submit'
  },
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Is your Observation Complete?'
  },
  description: {
    key: `${PATH}.description`,
    defaultValue:
      'Once submitted you will no longer be able to make changes without administrator approval.'
  }
};

export default messages;
