import i18n from 'helpers/i18n';
import messages from './messages';
import CheckBox from 'components/CheckBox';

interface HonorCodeAgreementContentProps {
  setHonorCode: (value: boolean) => void;
}
function HonorCodeAgreementContent({
  setHonorCode
}: HonorCodeAgreementContentProps) {
  return (
    <div className="text-lg">
      <div tabIndex={0}>
        <p>{i18n.ft(messages.description)}</p>
        <div className="font-bold text-cyan-700 mt-10 mb-3">
          {i18n.ft(messages.agree)}
        </div>
        <ul className="list-disc ms-6 marker:text-orange-400">
          <li className="mb-4 mr-2">
            <div className="flex items-start ml-2">
              {i18n.ft(messages.takeTest)}
            </div>
          </li>
          <li className="mb-4 mr-2">
            <div className="flex items-start ml-2">
              {i18n.ft(messages.priorTaking)}
            </div>
          </li>
          <li className="mb-4 mr-2">
            <div className="flex items-start ml-2">
              {i18n.ft(messages.afterTaking)}
            </div>
          </li>
        </ul>
      </div>
      <CheckBox
        id={`honorCodeAgreementCheckbox`}
        label={i18n.ft(messages.checkbox)}
        handleChange={e => setHonorCode(e.target.checked)}
      />
    </div>
  );
}

export default HonorCodeAgreementContent;
