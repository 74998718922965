import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 5px;
  border: 1px solid rgba(34, 36, 38, 0.15);

  .PhoneInputCountryIcon {
    margin-left: 8px;
  }

  &&& input {
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    border: 0;
  }
`;
