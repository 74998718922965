import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { IconCheck, IconError } from 'components/icons';

interface ListItemNavLinkProps {
  /** Link URL. */
  to: string;

  /** Link name. */
  content: string;

  /** Link status. */
  status: 'pending' | 'completed' | 'error' | 'disabled';
}

export function ListItemNavLink({ to, content, status }: ListItemNavLinkProps) {
  const classes = clsx(
    'flex justify-between items-center px-3 py-2 font-bold rounded-lg text-[#364A5E] hover:cursor-pointer hover:bg-[#EDEFF2]',
    { 'pointer-events-none text-[#BBBBBF]': status === 'disabled' }
  );

  const statusIcon =
    status === 'completed' ? (
      <IconCheck />
    ) : status === 'error' ? (
      <IconError />
    ) : null;

  return (
    <li>
      <NavLink
        to={to}
        className={({ isActive }) =>
          `${classes} ${isActive ? 'bg-[#EDEFF2]' : ''}`
        }
        tabIndex={status === 'disabled' ? -1 : 0}
      >
        <span>{content}</span>
        <span>{statusIcon}</span>
      </NavLink>
    </li>
  );
}
