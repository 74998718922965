import axios from 'axios';

export function activateProductKeyV1(payload = {}) {
  return axios.post('/api/v1/product_keys', payload);
}

export function activateProductKey(payload = {}) {
  return axios.post('/api/v2/product_keys', payload);
}

export function activateProductKeyWithTestTemplate(
  testTemplateId: number,
  role: string,
  payload = {}
) {
  return axios.post(
    `/api/v2/product_keys/?test_template_id=${testTemplateId}&role=${role}`,
    payload
  );
}
