const PATH = 'fe.components.trainings.view.attendance';

const messages = {
  takeAttendance: {
    key: `${PATH}.take_attendance`,
    defaultValue: 'Take Attendance'
  },
  selectSession: {
    key: `${PATH}.select_session`,
    defaultValue: 'Select a session to take attendance'
  },
  noParticipants: {
    key: `${PATH}.no_participants`,
    defaultValue: 'There are no paid participants in this training.'
  }
};

export default messages;
