const PATH =
  'fe.components.measure.view.cycles.cycle_scores_form.indicator_score_input';

const messages = {
  low: {
    key: `${PATH}.low`,
    defaultValue: 'Low'
  },
  mid: {
    key: `${PATH}.mid`,
    defaultValue: 'Mid'
  },
  high: {
    key: `${PATH}.high`,
    defaultValue: 'High'
  },
  notApplicableDesktop: {
    key: `${PATH}.not_applicable_desktop`,
    defaultValue: 'N/A'
  },
  notApplicable: {
    key: `${PATH}.not_applicable`,
    defaultValue: 'Not Applicable'
  }
};

export default messages;
