import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';

export function getCourseDuration(course: any) {
  if (!course.end_date) {
    return null;
  }

  const startDate = moment(course.start_date);
  const endDate = moment(course.end_date);
  const durationInMonths = endDate.diff(startDate, 'months');

  if (durationInMonths <= 0) {
    return null;
  } else {
    return i18n.ft(messages.monthsOfAccess, { count: durationInMonths });
  }
}

function getEventLabel(event: string): string {
  switch (event) {
    case 'on_training_start':
      return i18n.ft(messages.uponStart);
    case 'on_training_complete':
      return i18n.ft(messages.uponCompletion);
    case 'on_training_registration':
      return i18n.ft(messages.uponRegistration);
    default:
      throw new Error(`Invalid event type: ${event}`);
  }
}

export function getCourseAccess(course: any) {
  if (!course.enrollment || !course.enrollment.synced) {
    const eventLabel = getEventLabel(course.event);
    return { enabled: false, message: eventLabel };
  }

  if (course.enrollment.suspended_at) {
    return { enabled: false, message: i18n.ft(messages.accessSuspended) };
  }

  if (course.enrollment.end_date) {
    const today = moment();
    const endDate = moment(course.enrollment.end_date);
    const accessExpired = today.isAfter(endDate);

    if (accessExpired) {
      return { enabled: false, message: i18n.ft(messages.accessExpired) };
    }
  }

  if (course.enrollment.start_date) {
    const today = moment();
    const startDate = moment(course.enrollment.start_date);
    const beforeUponStart = today.isBefore(startDate);

    if (beforeUponStart) {
      return { enabled: false, message: i18n.ft(messages.uponStart) };
    }
  }

  return { enabled: true, message: i18n.ft(messages.goToCourse) };
}
