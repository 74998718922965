import { useField } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { updateAssessmentScore } from 'actions/envScales/assessments';
import { RadioInput, PillInput, Wrapper, NativeInput } from './Styled';
import { ASSESSMENT_ITEM_SCORES } from 'types/api/envScales/AssessmentScore';

interface ScoreInputProps {
  /** Input label. */
  label: string;

  /** Input aria-label. */
  ariaLabel?: string;

  /** Input score value. */
  value: ASSESSMENT_ITEM_SCORES;

  /** Assessment ID. */
  assessmentId: number;

  /** Scale ID. */
  scaleId: number;

  /** Input style type. */
  variant?: 'radio' | 'pill';
}

function ScoreInput({
  assessmentId,
  scaleId,
  value,
  label,
  ariaLabel,
  variant = 'radio'
}: ScoreInputProps) {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const InputComponent = variant === 'pill' ? PillInput : RadioInput;

  const [field] = useField({
    name: scaleId.toString(),
    value: value.toString(),
    type: 'radio'
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    field.onChange(event);
    updateAssessmentScore(assessmentId, {
      scale_id: scaleId,
      score: Number(event.target.value) as ASSESSMENT_ITEM_SCORES
    });
  }

  return (
    <Wrapper>
      <NativeInput
        {...field}
        type="radio"
        onChange={handleChange}
        aria-label={ariaLabel || label}
      />
      <InputComponent active={Boolean(field.checked)}>
        {isTablet ? null : label}
      </InputComponent>
    </Wrapper>
  );
}

export default ScoreInput;
