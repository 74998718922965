import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { verifyPhone, sendSmsCode } from 'actions/sms';
import { Button } from 'semantic-ui-react';
import { toastSuccess } from 'components/Toast';
import {
  Formik,
  Form,
  Input,
  PreventNavigation
} from 'components/EnhancedForm';
import {
  ErrorMessage,
  ButtonWrapper,
  InputWrapper,
  TextButton
} from './Styled';
import { FormikHelpers } from 'formik';

interface SmsCodeFormProps {
  /** Callback fired when the verification code is successfully verified. */
  onSucess: () => void;
}

function SmsCodeForm({ onSucess }: SmsCodeFormProps) {
  const { run, isLoading, isError } = useAsync();
  const smsRequest = useAsync();

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    run(verifyPhone(values.code), {
      onSuccess: () => {
        formik.resetForm();
        onSucess();
      }
    });
  }

  function resendCode() {
    if (smsRequest.isLoading) {
      return;
    }

    smsRequest.run(sendSmsCode(), {
      onSuccess: () => {
        toastSuccess({ description: i18n.ft(messages.success) });
      }
    });
  }

  return (
    <Formik initialValues={{ code: '' }} onSubmit={handleSubmit}>
      <Form>
        <PreventNavigation />

        <InputWrapper>
          <Input
            label={i18n.ft(messages.enterCode)}
            name="code"
            pattern="[0-9]*"
          />
          {isError && (
            <ErrorMessage>{i18n.ft(messages.invalidCode)}</ErrorMessage>
          )}
        </InputWrapper>

        <ButtonWrapper>
          <Button
            type="submit"
            color="blue"
            disabled={isLoading}
            loading={isLoading}
          >
            {i18n.ft(messages.verify)}
          </Button>
          <TextButton type="button" onClick={resendCode}>
            {i18n.ft(messages.resendCode)}
          </TextButton>
        </ButtonWrapper>
      </Form>
    </Formik>
  );
}

export default SmsCodeForm;
