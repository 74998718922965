const PATH = 'fe.components.measure.view.cycles.meta_data_fields';

const messages = {
  errors: {
    required: {
      key: `${PATH}.required`,
      defaultValue: 'is required'
    }
  }
};

export default messages;
