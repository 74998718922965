import styled from 'styled-components';

export const Wrapper = styled.div<{ isRightTile: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 20px;
  background-color: ${p => p.theme.colors.white};
  box-shadow: 1px 1px 11px #e9ecf2;
  border-radius: 20px;
  margin-bottom: 24px;

  @media screen and (min-width: 1024px) {
    max-width: ${({ isRightTile }) => (isRightTile ? '496px' : '963px')};
  }
`;

export const TitleArea = styled.h2`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  font-size: 24px;
  line-height: 32px;
  color: #364a5e;
`;

export const IconArea = styled.div`
  display: grid;
  padding: 40px 20px;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, 130px);
  justify-content: space-evenly;
`;

export const Icon = styled.img`
  position: relative;
  top: 4px;
`;

export const ServiceImageContainer = styled.a`
  display: flex;
  flex-direction: column;
  color: #364a5e;
  align-items: center;
  gap: 4px;
`;

export const ServiceImage = styled.img`
  width: 40px;
  height: 40px;

  @media screen and (min-width: 900px) {
    width: 45px;
    height: 45px;
  }
`;

export const ServiceName = styled.div`
  text-align: center;
`;
