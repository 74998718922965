import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect, useState } from 'react';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import PageLoader from 'components/PageLoader';
import { Button, Checkbox, Icon, Select, TextArea } from 'semantic-ui-react';
import MainContent from 'components/MainContent';
import { Link, useNavigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import PageHeader from 'components/Measure/PageHeader';
import PageBackground from 'components/PageBackground';
import classVideoIcon from 'images/envScales/classVideoIcon.svg';
import VideoPlayer from 'components/Measure/ObservationVideos/VideoPlayer';
import { formatDate, NotepadIcon, ListItem, LampIcon } from './utils';
import ApproveInProgressModal from 'components/Measure/ObservationVideos/ApproveInProgressModal';
import RecommendedTime from 'components/Measure/ObservationVideos/RecommendedTime';
import { CycleTime } from 'types/api/envScales/ObservationVideo';
import { useActiveSubscriptions } from 'context/auth';
import useCurrentUser from 'hooks/useCurrentUser';
import { hasRole } from 'pages/Measure/utils';
import colors from 'styles/colors';
import { toastError } from 'components/Toast';

import ConfirmationModal, {
  CallbackProps
} from 'components/Modals/ConfirmationModal';

import {
  acceptObservationVideo,
  getObservationVideoById,
  GetObservationVideoByIdRes,
  rejectObservationVideo,
  rejectObservationVideoAsObserver
} from 'actions/envScales/observationVideos';

type URLParams = {
  id: string;
};

const OTHER_CUSTOM_REASON = '_other_custom_reason';

function View() {
  const { id } = useRouteParams<URLParams>();
  const obsVideoId = parseInt(id);
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const subscriptions = useActiveSubscriptions();
  const accountId = currentUser.current_account_id || 0;
  const [rejectReason, setRejectReason] = useState('');
  const [observerRejectReason, setObserverRejectReason] = useState('');
  const [customReason, setCustomReason] = useState('');
  const [observerCustomReason, setObserverCustomReason] = useState('');
  const [rejectErrorMessage, setRejectErrorMessage] = useState('');
  const [isInvalidReason, setIsInvalidReason] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isTermsError, setIsTermsError] = useState(false);
  const {
    run,
    data: obsVideo,
    isPending
  } = useAsync<GetObservationVideoByIdRes>();
  const [cycleTimes, setCycleTimes] = useState<CycleTime[]>([]);
  const isAccountObserver = Boolean(
    hasRole(subscriptions, accountId, 'observer')
  );
  const isAccountAdmin = Boolean(hasRole(subscriptions, accountId, 'admin'));

  const rejectReasons = [
    {
      key: 1,
      value: i18n.ft(messages.reasons.cannotHearTeacher, { locale: 'en' }),
      text: i18n.ft(messages.reasons.cannotHearTeacher)
    },
    {
      key: 2,
      value: i18n.ft(messages.reasons.cannotHearChildren, { locale: 'en' }),
      text: i18n.ft(messages.reasons.cannotHearChildren)
    },
    {
      key: 3,
      value: i18n.ft(messages.reasons.cannotSeeTeacher, { locale: 'en' }),
      text: i18n.ft(messages.reasons.cannotSeeTeacher)
    },
    {
      key: 4,
      value: i18n.ft(messages.reasons.cameraMoved, { locale: 'en' }),
      text: i18n.ft(messages.reasons.cameraMoved)
    },
    {
      key: 5,
      value: i18n.ft(messages.reasons.noCodeableCycles, { locale: 'en' }),
      text: i18n.ft(messages.reasons.noCodeableCycles)
    },
    {
      key: 6,
      value: OTHER_CUSTOM_REASON,
      text: i18n.ft(messages.reasons.other)
    }
  ];

  const observerRejectReasons = [
    {
      key: 1,
      value: i18n.ft(messages.observerReasons.alreadyCoded, { locale: 'en' }),
      text: i18n.ft(messages.observerReasons.alreadyCoded)
    },
    {
      key: 2,
      value: i18n.ft(messages.observerReasons.notFluent, { locale: 'en' }),
      text: i18n.ft(messages.observerReasons.notFluent)
    },
    {
      key: 3,
      value: i18n.ft(messages.observerReasons.coflictOfInterest, {
        locale: 'en'
      }),
      text: i18n.ft(messages.observerReasons.coflictOfInterest)
    },
    {
      key: 4,
      value: OTHER_CUSTOM_REASON,
      text: i18n.ft(messages.observerReasons.other)
    }
  ];

  useEffect(() => {
    run(
      getObservationVideoById(obsVideoId).then(response => {
        setCycleTimes(response.data?.cycle_times || []);
        return response;
      })
    );
  }, [run, obsVideoId, setCycleTimes]);

  async function handleConfirm(modal: CallbackProps) {
    const payload = {
      cycle_times: cycleTimes
    };
    try {
      await acceptObservationVideo(obsVideoId, payload);
    } catch (error) {
      showErrorMessage(error);
      modal.closeModal();
      return;
    }

    // 300ms delay before redirect to give elasticsearch time to reindex
    setTimeout(() => {
      modal.closeModal();
      navigate(RouteHelpers.getPath('measure'));
    }, 300);
  }

  async function handleReject(modal: CallbackProps) {
    const isCustomReason = rejectReason === OTHER_CUSTOM_REASON;
    const isValidReason = validateCustomReason(customReason);
    const reason = isCustomReason ? customReason : rejectReason;

    if ((isCustomReason && !isValidReason) || !reason) {
      modal.setLoading(false);
      setIsInvalidReason(true);
      return;
    }

    try {
      await rejectObservationVideo(obsVideoId, reason);
    } catch (error) {
      showErrorMessage(error);
      modal.closeModal();
      return;
    }

    // 300ms delay before redirect to give elasticsearch time to reindex
    setTimeout(() => {
      modal.closeModal();
      navigate(RouteHelpers.getPath('measure'));
    }, 300);
  }

  async function handleRejectAsObserver(modal: CallbackProps) {
    const isCustomReason = observerRejectReason === OTHER_CUSTOM_REASON;
    const isValidReason = validateCustomReason(observerCustomReason);
    const reason = isCustomReason ? observerCustomReason : observerRejectReason;

    if ((isCustomReason && !isValidReason) || !reason) {
      modal.setLoading(false);
      setIsInvalidReason(true);
      return;
    }

    try {
      await rejectObservationVideoAsObserver(obsVideoId, reason);
    } catch (error) {
      showErrorMessage(error);
      modal.closeModal();
      return;
    }

    // 300ms delay before redirect to give elasticsearch time to reindex
    setTimeout(() => {
      modal.closeModal();
      navigate(RouteHelpers.getPath('measure'));
    }, 300);
  }

  function handleReasonSelect(_: any, data: any) {
    setIsInvalidReason(false);
    setRejectReason(data.value);
  }

  function handleObserverReasonSelect(_: any, data: any) {
    setIsInvalidReason(false);
    setObserverRejectReason(data.value);
  }

  function handleCustomReason(_: any, data: any) {
    const isValid = validateCustomReason(data.value);
    setIsInvalidReason(!isValid);
    setCustomReason(data.value);
  }

  function handleObserverCustomReason(_: any, data: any) {
    const isValid = validateCustomReason(data.value);
    setIsInvalidReason(!isValid);
    setObserverCustomReason(data.value);
  }

  function validateCustomReason(value: string) {
    if (R.isEmpty(R.trim(value))) {
      setRejectErrorMessage(i18n.ft(messages.rejectModal.reasonRequired));
      return false;
    } else if (value.length > 191) {
      setRejectErrorMessage(i18n.ft(messages.rejectModal.customReasonTooLong));
      return false;
    } else {
      return true;
    }
  }

  function showErrorMessage(error: any) {
    let message = i18n.ft(messages.error);

    if (!R.isEmpty(error.response.data.errors)) {
      message =
        error.response.data.errors[Object.keys(error.response.data.errors)[0]];
    }

    toastError({
      description: message
    });
  }

  const onCycleTimesChange = (times: CycleTime[]) => setCycleTimes(times);

  if (isPending) {
    return <PageLoader />;
  }

  return (
    <MainContent maxWidth={1280}>
      <div className="mt-8 px-3 sm:px-6 lg:px-[72px]">
        <PageHeader
          icon={classVideoIcon}
          iconAlt={i18n.ft(messages.header)}
          title={i18n.ft(messages.header)}
        />

        <PageBackground>
          <div className="bg-white rounded-t-xl px-12 py-6">
            <div className="flex items-center">
              <span className="text-3xl font-bold pr-6 mr-4 border-r-2">
                {obsVideo.center_name}
              </span>
              <span className="mt-1.5 text-gray-500">
                {i18n.ft(messages.center)}
              </span>
            </div>

            <div className="flex items-center mt-2">
              <span className="font-semibold text-xl pr-6 mr-4 border-r-2">
                {obsVideo.classroom_name}
              </span>
              <span className="mt-1 text-gray-500">
                {i18n.ft(messages.classroom)}
              </span>
            </div>

            <VideoPlayer observationVideoId={obsVideoId} />

            <div className="mt-6 flex flex-col">
              <div className="flex">
                <div className="w-1/2 flex flex-col">
                  <div>
                    <Icon name="calendar alternate" />
                    <span className="ml-2 font-bold">
                      {i18n.ft(messages.capturedOn)}
                    </span>
                  </div>
                  <span className="ml-8">
                    {formatDate(obsVideo.recorded_at)}
                  </span>
                </div>
                <div className="w-1/2 flex flex-col">
                  <div>
                    <Icon name="calendar alternate" />
                    <span className="ml-2 font-bold">
                      {i18n.ft(messages.submittedOn)}
                    </span>
                  </div>
                  <span className="ml-8">
                    {formatDate(obsVideo.submitted_at)}
                  </span>
                </div>
              </div>

              <div className="flex mt-5">
                <div className="w-1/2 flex flex-col">
                  <div>
                    <Icon name="world" />
                    <span className="ml-2 font-bold">
                      {i18n.ft(messages.language)}
                    </span>
                  </div>
                  <span className="ml-8">
                    {obsVideo.primary_language || i18n.ft(messages.notSet)}
                  </span>
                </div>
                <div className="w-1/2 flex flex-col">
                  {isAccountAdmin && (
                    <>
                      <div>
                        <Icon name="world" />
                        <span className="ml-2 font-bold">
                          {i18n.ft(messages.secondaryLanguage)}
                        </span>
                      </div>
                      <span className="ml-8">
                        {obsVideo.secondary_language ||
                          i18n.ft(messages.notSet)}
                      </span>
                    </>
                  )}
                </div>
              </div>

              <div className="flex mt-5">
                {isAccountAdmin && (
                  <div className="w-1/2 flex flex-col">
                    <div>
                      <Icon name="user" />
                      <span className="ml-2 font-bold">
                        {i18n.ft(messages.enrollment)}
                      </span>
                    </div>
                    <span className="ml-8">{obsVideo.enrollment}</span>
                  </div>
                )}
              </div>
            </div>

            <hr className="rounded-full border-4 my-6" />

            <div>
              {isAccountObserver && (
                <>
                  <div>
                    <div className="font-bold flex gap-5 items-center">
                      <LampIcon color={colors.scaleBlue} />
                      <h3>{i18n.ft(messages.recommendedTimes.header)}</h3>
                    </div>
                  </div>

                  <RecommendedTime
                    editMode={false}
                    cycleTimes={cycleTimes}
                    onCycleTimesChange={onCycleTimesChange}
                    title={i18n.ft(messages.recommendedTimes.titleForObservers)}
                  />
                </>
              )}

              {isAccountAdmin && (
                <RecommendedTime
                  editMode={obsVideo.status === 'qa'}
                  cycleTimes={cycleTimes}
                  onCycleTimesChange={onCycleTimesChange}
                  title={i18n.ft(messages.recommendedTimes.title)}
                />
              )}
            </div>

            {obsVideo.status === 'in_progress' && isAccountObserver && (
              <>
                <div className="text-lg mt-8">
                  <div className="font-bold flex gap-5 items-center">
                    <NotepadIcon />
                    <span>{i18n.ft(messages.instructions)}</span>
                  </div>

                  <div className="mt-6 flex flex-col gap-4 max-w-2xl">
                    <ListItem content={i18n.ft(messages.protocol1)} />
                    <ListItem content={i18n.ft(messages.protocol2)} />
                    <ListItem content={i18n.ft(messages.protocol3)} />
                    <ListItem content={i18n.ft(messages.protocol4)} />
                    <ListItem content={i18n.ft(messages.protocol5)} />
                  </div>

                  <div className="mt-8 flex gap-5 items-center">
                    <div className="w-6 flex items-center justify-center">
                      <Checkbox
                        id="comply_with_protocol"
                        checked={agreeToTerms}
                        onChange={(_, data) => {
                          const isChecked = Boolean(data.checked);
                          setAgreeToTerms(isChecked);

                          if (isChecked) {
                            setIsTermsError(false);
                          }
                        }}
                      />
                    </div>
                    <label htmlFor="comply_with_protocol">
                      {i18n.ft(messages.agreeToComply)}
                    </label>
                  </div>

                  {isTermsError && (
                    <div className="mt-2 text-red-700">
                      {i18n.ft(messages.termsError)}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="bg-white rounded-b-xl px-12 py-8 mt-2">
            <div className="grid grid-cols-4 gap-3">
              <Button basic as={Link} to={RouteHelpers.getPath('measure')}>
                <Icon name="arrow alternate circle left outline" />
                <span>{i18n.ft(messages.returnToList)}</span>
              </Button>

              {obsVideo.status === 'qa' && (
                <>
                  <ConfirmationModal
                    icon="warning circle"
                    title={i18n.ft(messages.rejectVideo)}
                    message={
                      <div className="flex flex-col gap-4">
                        <div>{i18n.ft(messages.rejectModal.message)}</div>
                        <Select
                          value={rejectReason}
                          options={rejectReasons}
                          onChange={handleReasonSelect}
                        />
                        {rejectReason === OTHER_CUSTOM_REASON ? (
                          <TextArea
                            className="py-3 px-4 border border-neutral-200 rounded"
                            placeholder={i18n.ft(
                              messages.rejectModal.reasonPlaceholder
                            )}
                            value={customReason}
                            onChange={handleCustomReason}
                          />
                        ) : null}
                        {isInvalidReason ? (
                          <div className="text-red-500">
                            {i18n.ft(messages.rejectModal.reasonRequired)}
                          </div>
                        ) : null}
                      </div>
                    }
                    confirmButton={i18n.ft(messages.rejectVideo)}
                    onConfirm={handleReject}
                    triggerNode={
                      <Button basic className="col-start-3">
                        {i18n.ft(messages.rejectVideo)}
                      </Button>
                    }
                  />

                  <ConfirmationModal
                    icon="clipboard check"
                    title={i18n.ft(messages.approveVideo)}
                    message={i18n.ft(messages.approveModal.message)}
                    confirmColor="blue"
                    confirmButton={i18n.ft(messages.approveModal.submit)}
                    onConfirm={handleConfirm}
                    triggerNode={
                      <Button color="blue">
                        {i18n.ft(messages.approveVideo)}
                      </Button>
                    }
                  />
                </>
              )}

              {obsVideo.status === 'in_progress' && (
                <>
                  <ConfirmationModal
                    icon="warning circle"
                    title={i18n.ft(messages.rejectVideo)}
                    message={
                      <div className="flex flex-col gap-4">
                        <div>{i18n.ft(messages.rejectModal.message)}</div>
                        <Select
                          value={observerRejectReason}
                          options={observerRejectReasons}
                          onChange={handleObserverReasonSelect}
                        />
                        {observerRejectReason === OTHER_CUSTOM_REASON ? (
                          <TextArea
                            className="py-3 px-4 border border-neutral-200 rounded"
                            placeholder={i18n.ft(
                              messages.rejectModal.reasonPlaceholder
                            )}
                            value={observerCustomReason}
                            onChange={handleObserverCustomReason}
                          />
                        ) : null}
                        {isInvalidReason ? (
                          <div className="text-red-500">
                            {rejectErrorMessage}
                          </div>
                        ) : null}
                      </div>
                    }
                    confirmButton={i18n.ft(messages.rejectVideo)}
                    onConfirm={handleRejectAsObserver}
                    triggerNode={
                      <Button basic className="col-start-3">
                        {i18n.ft(messages.returnVideo)}
                      </Button>
                    }
                  />
                  <ApproveInProgressModal
                    agreeToTerms={agreeToTerms}
                    observationVideoId={obsVideoId}
                    onError={() => setIsTermsError(true)}
                  />
                </>
              )}
            </div>
          </div>
        </PageBackground>
      </div>
    </MainContent>
  );
}

export default View;
