import styled from 'styled-components';
import { Button as SemanticButton } from 'semantic-ui-react';
import { Input as InputBase } from 'components/EnhancedForm';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 530px) {
    flex-direction: column;
  }
`;

export const Input = styled(InputBase)`
  width: 290px;
  font-size: 14px;

  @media screen and (max-width: 530px) {
    width: 100%;
  }
`;

export const Button = styled(({ hasErrors, ...props }) => (
  <SemanticButton {...props} />
))`
  font-size: 14px !important;
  margin-left: 12px !important;
  margin-bottom: ${props => (props.hasErrors ? '23px' : '15px')} !important;

  @media screen and (max-width: 530px) {
    width: 100%;
    margin-left: 0 !important;
  }
`;
