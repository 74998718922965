import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import { getAccountSubscriptionDetail } from 'actions/subscriptions';
import { SubscriptionSeat } from 'types/api/SubscriptionSeat';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Loader, Popup } from 'semantic-ui-react';
import {
  DetailContainer,
  HeaderItem,
  FirstHeaderItem,
  List,
  TableWrapper,
  Container,
  FirstItem,
  Item,
  SmallDetailContainer,
  SmallContainer,
  Column,
  LoaderWrapper,
  NoUser,
  EmailName,
  ItemEmail
} from './Styled';

interface SubscriptionDetailsProps {
  subscriptionId: number;
}

function SubscriptionDetails({ subscriptionId }: SubscriptionDetailsProps) {
  const { data: subscriptionsDetail, run, isPending } = useAsync();
  const isLessThan1280px = useMediaQuery({ maxWidth: 1280 });

  useEffect(() => {
    run(getAccountSubscriptionDetail(subscriptionId));
  }, [run, subscriptionId]);

  return (
    <TableWrapper>
      {isLessThan1280px ? (
        <>
          <SmallDetailContainer>
            <FirstHeaderItem>{i18n.ft(messages.activatedBy)}</FirstHeaderItem>
            <HeaderItem>{i18n.ft(messages.activatedOn)}</HeaderItem>
          </SmallDetailContainer>
          {!isPending ? (
            <List>
              {subscriptionsDetail.length ? (
                <>
                  {subscriptionsDetail.map((subsDetail: SubscriptionSeat) => {
                    return (
                      <SmallContainer key={subsDetail.id}>
                        <FirstItem>
                          <Column>
                            <Popup
                              content={subsDetail.user.email}
                              on="hover"
                              trigger={<div>{subsDetail.user.name}</div>}
                            />
                          </Column>
                        </FirstItem>
                        <Item>
                          <Column>
                            {moment(subsDetail.created_at).format('MM/DD/YYYY')}
                          </Column>
                          <Column>
                            {moment(subsDetail.created_at)
                              .local()
                              .format('hh:mm A')}
                          </Column>
                        </Item>
                      </SmallContainer>
                    );
                  })}
                </>
              ) : (
                <NoUser>{i18n.ft(messages.noUsers)}</NoUser>
              )}
            </List>
          ) : (
            <LoaderWrapper>
              <Loader active inline="centered" />
            </LoaderWrapper>
          )}
        </>
      ) : (
        <>
          <DetailContainer>
            <FirstHeaderItem>{i18n.ft(messages.activatedBy)}</FirstHeaderItem>
            <HeaderItem>{i18n.ft(messages.email)}</HeaderItem>
            <HeaderItem>{i18n.ft(messages.activatedOn)}</HeaderItem>
            <HeaderItem>{i18n.ft(messages.time)}</HeaderItem>
          </DetailContainer>
          {!isPending ? (
            <List>
              {subscriptionsDetail.length ? (
                <>
                  {subscriptionsDetail.map((subsDetail: SubscriptionSeat) => {
                    const emailList = subsDetail.user.email.split('@');
                    return (
                      <Container key={subsDetail.id}>
                        <FirstItem>{subsDetail.user.name}</FirstItem>
                        <Popup
                          content={subsDetail.user.email}
                          on="hover"
                          trigger={
                            <ItemEmail>
                              <EmailName>{emailList[0]}</EmailName>
                              <div>@{emailList[1]}</div>
                            </ItemEmail>
                          }
                        />
                        <Item>
                          {moment(subsDetail.created_at).format('MM/DD/YYYY')}
                        </Item>
                        <Item>
                          {moment(subsDetail.created_at)
                            .local()
                            .format('hh:mm A')}
                        </Item>
                      </Container>
                    );
                  })}
                </>
              ) : (
                <NoUser>{i18n.ft(messages.noUsers)}</NoUser>
              )}
            </List>
          ) : (
            <LoaderWrapper>
              <Loader active inline="centered" />
            </LoaderWrapper>
          )}
        </>
      )}
    </TableWrapper>
  );
}

export default SubscriptionDetails;
