import i18n from 'helpers/i18n';
import messages from './messages';
import { formatCertification } from '../utils';
import { CertificationItem } from 'types/api/CertificationItemProductKey';

interface ChooseChooseLanguageProps {
  selectedCertification: CertificationItem;
}

function ConfirmationModalBody({
  selectedCertification
}: ChooseChooseLanguageProps) {
  return (
    <div>
      <div className="text-lg font-bold mr-4">
        {i18n.ft(messages.certifications)}
      </div>
      <div className="mb-4 text-lg">
        {formatCertification(selectedCertification)}
      </div>
      <div className="text-lg font-bold mr-4">{i18n.ft(messages.language)}</div>
      <div className="text-lg mr-4">{selectedCertification.language}</div>
    </div>
  );
}

export default ConfirmationModalBody;
