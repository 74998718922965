const PATH = 'fe.pages.trainings.view.checkout';

const messages = {
  selectPayment: {
    key: `${PATH}.select_payment`,
    defaultValue: 'Select a payment method'
  },
  payingFor: {
    key: `${PATH}.paying_for`,
    defaultValue: 'Paying for {{count}} participant.'
  },
  paymentCompleted: {
    key: `${PATH}.payment_completed`,
    defaultValue: 'You have successfully completed payment.'
  },
  returnToTraining: {
    key: `${PATH}.return_to_training`,
    defaultValue: 'Return to the Training'
  }
};

export default messages;
