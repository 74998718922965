const PATH = 'fe.components.measure.view.cycle.meta_data_select_one';

const messages = {
  type: {
    key: `${PATH}.type`,
    defaultValue: '{{category}} Type'
  },
  errors: {
    categoryError: {
      key: `${PATH}.errors.category_error`,
      defaultValue: 'Select one option'
    }
  }
};

export default messages;
