import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const Container = styled.div`
  max-width: 1024px;
  padding: 0 24px;
  margin: 24px auto;
  color: ${p => p.theme.colors.scaleDarkBlue};

  @media screen and (min-width: 640px) {
    margin: 32px auto;
  }

  @media screen and (min-width: 1024px) {
    margin: 48px auto;
  }
`;

export const Message = styled.div`
  margin-bottom: 16px;

  @media screen and (min-width: 640px) {
    margin-bottom: 24px;
  }
`;

export const SegmentStyle = styled(Segment)`
  @media screen and (min-width: 640px) {
    padding: 24px !important;
  }

  @media screen and (min-width: 1024px) {
    padding: 32px !important;
  }
`;
