import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { DualRow, DualRowItem } from './Styled';
import StatesDropdown from 'components/dropdowns/States';
import CountriesDropdown from 'components/dropdowns/Countries';
import { Input, Select } from 'components/EnhancedForm';

const AddressForm = ({ skipEmail }) => {
  const { values } = useFormikContext();

  return (
    <React.Fragment>
      <DualRow>
        <DualRowItem>
          <Input name="first_name" label={i18n.ft(messages.firstName)} />
        </DualRowItem>
        <DualRowItem>
          <Input name="last_name" label={i18n.ft(messages.lastName)} />
        </DualRowItem>
      </DualRow>

      {!skipEmail && <Input name="email" label={i18n.ft(messages.email)} />}

      <Input name="address1" label={i18n.ft(messages.address1)} />
      <Input name="address2" label={i18n.ft(messages.address2)} />

      <DualRow>
        <DualRowItem>
          <Select
            search
            name="country_code"
            label={i18n.ft(messages.country)}
            control={CountriesDropdown}
          />
        </DualRowItem>
        <DualRowItem>
          {values.country_code === 'US' ? (
            <Select
              search
              name="state_or_province_code"
              label={i18n.ft(messages.state)}
              control={StatesDropdown}
            />
          ) : (
            <Input
              name="state_or_province_code"
              label={i18n.ft(messages.state)}
            />
          )}
        </DualRowItem>
      </DualRow>

      <DualRow>
        <DualRowItem>
          <Input name="city" label={i18n.ft(messages.city)} />
        </DualRowItem>
        <DualRowItem>
          <Input name="postal_code" label={i18n.ft(messages.postalCode)} />
        </DualRowItem>
      </DualRow>

      <DualRow>
        <DualRowItem>
          <Input name="phone" label={i18n.ft(messages.phoneNumber)} />
        </DualRowItem>
      </DualRow>
    </React.Fragment>
  );
};

AddressForm.propTypes = {
  /** If `true`, the email will be hidden from the form. */
  skipEmail: PropTypes.bool
};

export default AddressForm;
