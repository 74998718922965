import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import PageLayout from 'components/PageLayout';
import { getTemplate } from 'actions/templates';
import React, { useState, useEffect } from 'react';
import { Header, Loader } from 'semantic-ui-react';
import { createTraining } from 'actions/trainings';
import { useNavigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import TrainingForm from 'components/TrainingCatalog/Create/TrainingForm';
import { initialCreateValues } from 'components/TrainingCatalog/Create/TrainingForm/utils';

function Create() {
  const params = useRouteParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState({});

  useEffect(() => {
    getTemplate(params.id).then(({ data }) => {
      setTemplate(data);
      setLoading(false);
    });
  }, [params.id]);

  function handleSubmit({ submit_type, ...data }, setSubmitting, resetForm) {
    createTraining(data).then(({ data }) => {
      setSubmitting(false);

      if (submit_type === 'duplicate') {
        const initialVales = initialCreateValues(template);

        resetForm({
          values: {
            ...initialVales,
            delivery_method: data.delivery_method,
            notes_title: data.notes_title,
            notes: data.notes,
            time_zone: data.time_zone
          }
        });
      } else if (submit_type === 'new') {
        navigate(RouteHelpers.getPath('td-catalog'), {
          replace: true
        });
      } else {
        navigate(
          RouteHelpers.getPath('td-trainings-view-details', { id: data.id }),
          {
            replace: true
          }
        );
      }
    });
  }

  function handleCancel() {
    navigate(RouteHelpers.getPath('td-catalog'));
  }

  if (loading) {
    return <Loader active size="large" inline="centered" />;
  }

  return (
    <PageLayout>
      <Header as="h1" size="huge">
        {i18n.ft(messages.header)}
      </Header>
      <TrainingForm
        template={template}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        submitLabel={i18n.ft(messages.submitLabel)}
      />
    </PageLayout>
  );
}

export default Create;
