const PATH = 'fe.components.trainings.view.card_checkout.address_form';

const messages = {
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  firstName: {
    key: `${PATH}.first_name`,
    defaultValue: 'First Name'
  },
  lastName: {
    key: `${PATH}.last_name`,
    defaultValue: 'Last Name'
  },
  address1: {
    key: `${PATH}.address1`,
    defaultValue: 'Address 1'
  },
  address2: {
    key: `${PATH}.address2`,
    defaultValue: 'Address 2'
  },
  country: {
    key: `${PATH}.country`,
    defaultValue: 'Country'
  },
  state: {
    key: `${PATH}.state`,
    defaultValue: 'State/Province'
  },
  city: {
    key: `${PATH}.city`,
    defaultValue: 'City'
  },
  postalCode: {
    key: `${PATH}.postal_code`,
    defaultValue: 'Postal Code'
  },
  phoneNumber: {
    key: `${PATH}.phone`,
    defaultValue: 'Phone Number'
  },
  isRequired: {
    key: `${PATH}.is_required`,
    defaultValue: '{{field}} is required'
  }
};

export default messages;
