import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useRef, useState } from 'react';
import { Button } from 'semantic-ui-react';
import ImageCropTool from '../ImageCropTool';
import { uploadAvatar } from 'actions/users';
import { useDispatch } from 'react-redux';
import { updateAvatar } from 'store/slices/user';

import {
  Title,
  Subtitle,
  NoFileMessage,
  FileErrors,
  FileError,
  HiddenInput,
  ButtonsWrapper
} from './Styled';

type FileData = {
  top_left_x: number;
  top_left_y: number;
  zoom: number;
};

interface ImageUploaderProps {
  onClose: () => void;
}

function ImageUploader({ onClose }: ImageUploaderProps) {
  const [file, setFile] = useState<File>();
  const [fileData, setFileData] = useState<FileData>();
  const [errors, setErrors] = useState<string[]>([]);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  function handleUpload() {
    if (uploadingAvatar || !file || !fileData) {
      return;
    }

    let formData = new FormData();
    formData.append('avatar', file);
    formData.append('top_left_x', String(fileData.top_left_x));
    formData.append('top_left_y', String(fileData.top_left_y));
    formData.append('zoom', String(fileData.zoom));

    setUploadingAvatar(true);
    uploadAvatar(formData).then(response => {
      dispatch(updateAvatar(response.data.avatar_url));
      setUploadingAvatar(false);
      onClose();
    });
  }

  function onFileChooseClick() {
    hiddenFileInput?.current?.click();
  }

  function onFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    let fileErrors = [];
    const maxFileSize = 3.0; // Size in megabytes
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    if (file.size / 1024 / 1024 > maxFileSize) {
      fileErrors.push(i18n.ft(messages.errors.maxFileSize));
    }

    if (!R.test(/image\/*/g, file.type)) {
      fileErrors.push(i18n.ft(messages.errors.fileType));
    }

    if (!R.isEmpty(fileErrors)) {
      setErrors(fileErrors);
    } else {
      setFile(file);
    }
  }

  function renderImageUploader() {
    return (
      <div>
        <Title>{i18n.ft(messages.uploadPhoto)}</Title>
        <Subtitle>{i18n.ft(messages.positionPhoto)}</Subtitle>

        {R.isNil(file) ? null : (
          <ImageCropTool file={file} onChange={setFileData} />
        )}

        <ButtonsWrapper>
          <Button
            fluid
            size="large"
            content={i18n.ft(messages.cancel)}
            onClick={onClose}
          />

          <Button
            fluid
            size="large"
            color="blue"
            content={i18n.ft(messages.save)}
            onClick={handleUpload}
            loading={uploadingAvatar}
            disabled={uploadingAvatar}
          />
        </ButtonsWrapper>
      </div>
    );
  }

  function renderImagePicker() {
    return (
      <div>
        <Title>{i18n.ft(messages.uploadPhoto)}</Title>
        <Subtitle>{i18n.ft(messages.choosePhoto)}</Subtitle>

        <Button
          fluid
          color="green"
          size="large"
          onClick={onFileChooseClick}
          content={i18n.ft(messages.chooseFile)}
        />

        <HiddenInput
          type="file"
          accept="image/*"
          ref={hiddenFileInput}
          onChange={onFileSelect}
        />

        {R.isEmpty(errors) ? null : (
          <FileErrors>
            {errors.map(error => (
              <FileError key={error}>{error}</FileError>
            ))}
          </FileErrors>
        )}

        <NoFileMessage>{i18n.ft(messages.noFileSelected)}</NoFileMessage>
      </div>
    );
  }

  if (R.isNil(file)) {
    return renderImagePicker();
  } else {
    return renderImageUploader();
  }
}

export default ImageUploader;
