export type EducatorInformation = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  avatar?: string;
  center: string;
  classroom: string;
};
export type Note = {
  id?: number;
  note_type?: string;
  response: string;
  created_at: Date;
};

export type ImprovementPrompt = {
  id: number;
  question: string;
  target_type: string;
  target_id: number;
  deleted_at?: string;
};

export enum NoteType {
  INITIAL_OBSERVATION = 'InitialObservation',
  IN_ACTION_NOTE = 'InActionNote',
  AREA_NOTE = 'AreaNote',
  CATEGORY_NOTE = 'CategoryNote',
  PRIORITY_NOTE = 'PriorityNote',
  FOCUS_NOTE = 'FocusNote',
  ACTION_PLAN_NOTE = 'ActionPlanNote',
  ADDITIONAL_CUES_NOTE = 'AdditionalCuesNote',
  PLAN_SUPPORTS_NOTE = 'PlanSupportsNote',
  OPTIONAL_SUMMARY_NOTE = 'OptionalSummaryNote',
  SUMMARY_NOTE = 'SummaryNote',
  OBSERVATION_NOTE = 'ObservationNote',
  REFLECT_NOTE = 'ReflectNote'
}
