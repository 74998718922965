const PATH = 'fe.components.trainings.view.location';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Training Location:'
  }
};

export default messages;
