import i18n from 'helpers/i18n';
import messages from './messages';
import InfoBox from 'components/Measure/View/InfoBox';
import ScoreSummary from 'components/Measure/View/ScoreSummary';
import MainContent from 'components/MainContent';
import { Link, Navigate } from 'react-router-dom';
import { useLocationState } from 'hooks/useLocationState';
import { useRouteParams } from 'hooks/useRouteParams';
import PageHeader from 'components/Measure/PageHeader';
import PageBackground from 'components/PageBackground';
import RouteHelpers from 'helpers/routes';
import { Icon, Button } from 'semantic-ui-react';
import CompletedCLASS from './CompletedCLASS';
import { MeasureProvider, useMeasure } from 'context/measure';
import { useActiveSubscriptions } from 'context/auth';
import useCurrentUser from 'hooks/useCurrentUser';
import { hasRole } from '../utils';
import { getAssessmentType } from 'components/Measure/Home/AssessmentCard/utils';
import { ENV_SCALES_URL } from 'constants/routes';
import PageLoader from 'components/PageLoader';
import { getSettings, GetSettingsRes } from 'actions/envScales/settings';

import {
  PageWrapper,
  Section,
  SectionFooter,
  ButtonWrapper,
  MessageWrapper,
  SuccessWrapper,
  IconWrapper
} from './Styled';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';

type URLParams = {
  id: string;
};

type LocationState = {
  isJustSubmitted?: boolean;
};

export function useDCRoles() {
  const currentUser = useCurrentUser();
  const subscriptions = useActiveSubscriptions();

  const isDCObserver = Boolean(
    currentUser.current_account_id &&
      hasRole(subscriptions, currentUser.current_account_id, 'observer')
  );

  const isDCAdmin = Boolean(
    currentUser.current_account_id &&
      hasRole(subscriptions, currentUser.current_account_id, 'admin')
  );

  const isDCSupervisor = Boolean(
    currentUser.current_account_id &&
      hasRole(subscriptions, currentUser.current_account_id, 'supervisor')
  );

  return { isDCAdmin, isDCObserver, isDCSupervisor };
}

function Completed() {
  const { assessment } = useMeasure();
  const assessmentType = getAssessmentType(assessment.assessment_template);
  const state = useLocationState<LocationState>();
  const isJustSubmitted = state?.isJustSubmitted;
  const { run, data: settings, isPending } = useAsync<GetSettingsRes>();
  const { isDCAdmin, isDCObserver, isDCSupervisor } = useDCRoles();

  useEffect(() => {
    run(getSettings(assessment.account_guid));
  }, [run, assessment.account_guid]);

  if (isPending) {
    return <PageLoader />;
  }

  const { video_enabled: isVideoObservation } = settings.account_setting;
  const requireApproval = assessment.requires_approval;

  if (assessment.status === 'open') {
    return (
      <Navigate
        replace
        to={RouteHelpers.getPath('measure-view', { id: assessment.id })}
      />
    );
  }

  if (assessment.observation) {
    return (
      <CompletedCLASS
        assessment={assessment}
        isObserver={isDCObserver}
        isAdmin={isDCAdmin}
        isSupervisor={isDCSupervisor}
        isClass2nd={assessmentType === 'class2'}
      />
    );
  }

  return (
    <MainContent maxWidth={1280}>
      <PageWrapper>
        <PageHeader
          title={
            isJustSubmitted
              ? i18n.ft(messages.reviewHeader)
              : i18n.ft(messages.completedHeader)
          }
        />

        <PageBackground>
          <InfoBox
            data={assessment}
            showButton={false}
            attachToBottom
            isAdmin={isDCAdmin}
            isSupervisor={isDCSupervisor}
            requiresApproval={requireApproval}
            isVideoObservation={isVideoObservation}
          />

          {isJustSubmitted ? (
            <Section>
              <MessageWrapper>
                <span>{i18n.ft(messages.submitted01)}</span>
                <SuccessWrapper>
                  {i18n.ft(messages.submitted02)}
                  <IconWrapper>
                    <Icon name="check circle" size="small" />
                  </IconWrapper>
                </SuccessWrapper>
              </MessageWrapper>
            </Section>
          ) : (
            <ScoreSummary
              assessmentId={assessment.id}
              infoData={assessment}
              isEditable={false}
            />
          )}

          <SectionFooter>
            <ButtonWrapper>
              <Button
                fluid
                basic
                as={Link}
                to={RouteHelpers.getPath('measure')}
              >
                <Icon name="arrow alternate circle left outline" />
                {i18n.ft(messages.return)}
              </Button>
            </ButtonWrapper>

            {!isDCObserver && (
              <>
                {assessmentType !== 'class2' && (
                  <ButtonWrapper alignRight>
                    <Button
                      fluid
                      color="blue"
                      as="a"
                      href={`${ENV_SCALES_URL}/api/v1/assessments/${assessment.id}.pdf`}
                      content={i18n.ft(messages.pdf)}
                      target="_blank"
                    />
                  </ButtonWrapper>
                )}
                <ButtonWrapper alignTop>
                  <Button
                    fluid
                    color="blue"
                    as="a"
                    href={`${ENV_SCALES_URL}/api/v1/assessments/${assessment.id}.csv`}
                    content={i18n.ft(messages.csv)}
                    target="_blank"
                  />
                </ButtonWrapper>
              </>
            )}
          </SectionFooter>
        </PageBackground>
      </PageWrapper>
    </MainContent>
  );
}

function CompletedWithProvider() {
  const { id } = useRouteParams<URLParams>();
  const assessmentId = parseInt(id);

  return (
    <MeasureProvider expanded assessmentId={assessmentId}>
      <Completed />
    </MeasureProvider>
  );
}

export default CompletedWithProvider;
