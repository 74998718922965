import { useState, useEffect } from 'react';
import { Dropdown, FormDropdownProps } from 'semantic-ui-react';
import { shouldDisplayError, BaseFieldProps } from './utils';
import { useField } from 'formik';
import { FormRowWrapper } from './Styled';
import ErrorLabel from './ErrorLabel';
import moment from 'moment-timezone';
import styled from 'styled-components';

const StyledFormRowWrapper = styled(FormRowWrapper)`
  margin-bottom: 1em;

  label {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0.25em;
  }

  & > div {
    width: calc((100% - 32px) / 3);

    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
`;

interface TimeZoneOption {
  key: string;
  text: string;
  value: string;
}

interface TimeZoneDropdownProps extends BaseFieldProps<FormDropdownProps> {}

const TimeZoneDropdown = ({
  name,
  validate,
  value,
  label,
  ...props
}: TimeZoneDropdownProps) => {
  const [field, meta, helpers] = useField({ name, validate });
  const [timeZoneOptions, setTimeZoneOptions] = useState<TimeZoneOption[]>([]);
  const isError = shouldDisplayError(meta);

  useEffect(() => {
    const timeZones = moment.tz.names();
    const options = timeZones.map(zone => {
      const offset = moment().tz(zone).format('Z');
      return {
        key: zone,
        text: `(GMT${offset}) ${zone}`,
        value: zone
      };
    });

    setTimeZoneOptions(options);
  }, []);

  return (
    <StyledFormRowWrapper>
      <label>{label}</label>
      <Dropdown
        search
        selection
        options={timeZoneOptions}
        defaultValue={field.value}
        onChange={(_e, { value }) => helpers.setValue(value)}
      />
      <ErrorLabel active={isError} content={meta.error} />

      <input
        type="hidden"
        name={name}
        value={value ?? field.value}
        aria-label={props['aria-label'] || props.label}
      />
    </StyledFormRowWrapper>
  );
};

export default TimeZoneDropdown;
