import { isEmpty } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import TestCard from '../TestCard';
import useAsync from 'hooks/useAsync';
import { Loader } from 'semantic-ui-react';
import { UserTest } from 'types/api/UserTest';
import useCurrentUser from 'hooks/useCurrentUser';
import SecondaryButton from 'components/SecondaryButton';
import ReliabilityTestCard from '../TestCard/ReliabilityTestCard';
import { filterActiveCoreTests, filterRecentTests } from './utils';
import { getUserTests } from 'actions/core/tests';
import { getUserTestsForCurrentUser as getUserReliabilityTests } from 'actions/userTests';

import {
  Container,
  ListContainer,
  ListHeader,
  ListDivider,
  HeaderContainer,
  FooterContainer
} from './Styled';

function TestList() {
  const currentUser = useCurrentUser();
  const currentUserGuid = currentUser.guid;
  const { run: testsRun, ...testsRequest } = useAsync();
  const { run: reliabilityTestsRun, ...reliabilityTestsRequest } = useAsync();

  useEffect(() => {
    testsRun(getUserTests(currentUserGuid));
    reliabilityTestsRun(getUserReliabilityTests());
  }, [testsRun, currentUserGuid, reliabilityTestsRun, currentUser.id]);

  if (testsRequest.isPending || reliabilityTestsRequest.isPending) {
    return <Loader active inline="centered" />;
  }

  const activeCoreTests = filterActiveCoreTests(testsRequest?.data || []);

  const activeTests = filterRecentTests(
    reliabilityTestsRequest.data?.user_tests || []
  );

  if (isEmpty(activeCoreTests) && isEmpty(activeTests)) {
    return null;
  }

  return (
    <Container>
      <HeaderContainer>
        <ListHeader>{i18n.ft(messages.header)}</ListHeader>
        <SecondaryButton
          content={i18n.ft(messages.needSupport)}
          icon="arrow right"
          as="a"
          href="https://teachstone.com/reliability-support"
        />
      </HeaderContainer>

      <ListContainer>
        {activeCoreTests.map(test => (
          <TestCard
            key={`${test.certificate_id}-${test.finish_by}-${test.name}`}
            test={test}
            showReportLinks
            isReliability
          />
        ))}
        {activeTests.map((reliabilityTest: UserTest, idx: number) => (
          <ReliabilityTestCard key={idx} reliabilityTest={reliabilityTest} />
        ))}
      </ListContainer>
      <FooterContainer>
        <SecondaryButton
          content={i18n.ft(messages.testExtension)}
          icon="arrow right"
          as="a"
          href="https://store.teachstone.com/two-week-extension/?showHidden=true"
        />
      </FooterContainer>
      <ListDivider />
    </Container>
  );
}

export default TestList;
