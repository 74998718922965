import { clsx } from 'clsx';

interface PaginationButtonProps {
  /** Button content. */
  children: React.ReactNode;

  /** When `true`, active styles will be applied. */
  active?: boolean;

  /** When `true`, disabled styles will be applied. */
  disabled?: boolean;

  /** Callback fired when the button is clicked. */
  onClick?: () => void;
}

export function PaginationButton({
  active,
  children,
  ...props
}: PaginationButtonProps) {
  const classess = clsx(
    'font-sans font-semibold text-sm border rounded w-8 h-8',
    // default state
    !active && 'border-[#889EBB]',
    // hover state
    !active && 'hover:text-[#6E2262] hover:border-[#6E2262]',
    // focus state
    !active &&
      'focus:outline-none focus:border-2 focus:border-[#0A9CCA] focus:text-[#6E2262]',
    // active state
    'active:bg-[#6E2262] active:text-white active:border-[#6E2262]',
    // disabled state
    'disabled:bg-[#EBEFF4] disabled:cursor-not-allowed disabled:text-[#AFBFD2] disabled:pointer-events-none',
    // when active is `true`
    active &&
      'bg-[#6E2262] text-white border-[#6E2262] focus:outline-none focus:border-2 focus:border-[#0A9CCA]'
  );

  return (
    <button className={classess} {...props}>
      {children}
    </button>
  );
}
