import styled, { css } from 'styled-components';
import { Button } from 'semantic-ui-react';
import {
  addBorderRadius,
  addSpace,
  SpaceProps,
  BorderProps
} from 'components/Box/system';

interface SectionProps {
  error?: boolean;
  shadowOnHover?: boolean;
  thinPadding?: boolean;
  isMobile?: boolean;
}

const lgMin = `${1024}px`;

export const Section = styled.section<{ sm?: boolean }>`
  padding: 24px ${p => (p.sm ? '40px' : '0px')};
  background-color: ${p => p.theme.colors.white};
  margin-bottom: 0px;

  @media screen and (min-width: 640px) {
    padding: 24px 32px;
  }

  @media screen and (min-width: ${lgMin}) {
    ${p => (p.sm ? 'padding: 24px 48px' : '')};
    margin-bottom: 5px;
  }

  @media screen and (min-width: 1024px) {
    padding: 24px 80px;
    margin: 0 48px 5px;
  }
`;

export const SubtitleSection = styled.div`
  display: flex;
  margin: 48px 0;
  color: ${p => p.theme.colors.scaleDarkBlue};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

export const Status = styled.div<{
  iconColor?: string;
  iconBackGroundColor?: string;
  size?: string;
}>`
  display: flex;
  width: ${p => (p.size ? p.size : '24px')};
  height: ${p => (p.size ? p.size : '24px')};
  border-radius: 12px;
  color: ${p => p.iconColor};
  background-color: ${p => p.iconBackGroundColor};
  text-align: center;
  vertical-align: middle;
  font-size: 24px;

  i {
    margin-right: 0;
  }

  @media screen and (min-width: 640px) {
    line-height: 20px;
  }
`;

export const ContainerSection = styled.section<
  SpaceProps & BorderProps & SectionProps
>`
  ${addSpace};
  ${addBorderRadius};
  background-color: #ffffff;

  @media screen and (max-width: 640px) {
    ${p =>
      p.isMobile &&
      css`
        padding: 10px 10px;
      `};
  }
`;

export const SectionActions = styled.div`
  display: flex;
  flex-direction: column-reverse;

  & > * + * {
    margin-bottom: 16px;
  }

  @media screen and (min-width: ${lgMin}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > * + * {
      margin: 0;
    }
  }
`;

export const ActionWrapper = styled.div`
  min-width: auto;
  display: flex;
  text-align-last: center;

  @media screen and (min-width: ${lgMin}) {
    width: fit-content;
    min-width: 215px;
  }
`;

export const StyledButton = styled(Button)`
  text-align: left !important;

  box-shadow: none !important;
  background-color: ${({ theme }) => theme.colors['white']} !important;
  color: ${({ theme }) => theme.colors['neutral']['700']} !important;
  left: -20px;
  position: relative;
  margin-left: 0px !important;

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }

  @media screen and (min-width: ${lgMin}) {
    margin-left: 19px !important;
  }
`;
