const PATH = 'fe.pages.users.logout';

const messages = {
  loggingOut: {
    key: `${PATH}.logging_out`,
    defaultValue: 'Please wait while we log you out of myTeachstone ...'
  }
};

export default messages;
