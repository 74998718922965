import i18nJS from 'i18n-js';
import { find } from 'ramda';

export function formatShippingOption(option) {
  return `${option.description} - ${i18nJS.toCurrency(option.cost)}`;
}

export function selectedShippingOption(optionId, options) {
  const shippingOption = find(option => option.id === optionId, options);
  return formatShippingOption(shippingOption);
}
