import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import RouteHelpers from 'helpers/routes';
import { useEffect, useMemo, useState } from 'react';
import { getMyEducators } from 'actions/coachings';
import { Table } from 'components/Organization/Table';
import { Loader } from 'semantic-ui-react';
import LastViewed from 'components/LearningResources/LastViewed';
import { useNavigate } from 'react-router-dom';
import arrowRightIcon from 'images/arrow_right.svg';
import Important from 'components/LearningResources/Important';

interface Educator {
  id: number;
  full_name: string;
  recommendations_count: number;
}

function MyEducators() {
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | null>(null);
  const {
    run,
    data: { coachings: educators },
    isLoading
  } = useAsync({ data: {} });

  useEffect(() => {
    run(getMyEducators());
  }, [run]);

  const tableHeaders = [
    {
      content: (
        <span className="text-black font-bold">{i18n.ft(messages.name)}</span>
      ),
      accessor: 'name',
      sortable: true
    },
    {
      content: (
        <span className="text-black font-bold">{i18n.ft(messages.total)}</span>
      ),
      accessor: 'total'
    },
    { content: '', accessor: 'view' }
  ];

  const tableData = useMemo(() => {
    if (!educators) {
      return [];
    }

    return educators.map((educator: Educator) => {
      return {
        name: educator.full_name,
        total: educator.recommendations_count,
        view: (
          <button
            className="font-bold text-[#47163F] text-base leading-6"
            onClick={() => {
              navigate(
                RouteHelpers.getPath('coach-learning-resources-recommend'),
                {
                  state: { educatorId: educator.id }
                }
              );
            }}
          >
            <div className="flex">
              <span>{`${i18n.ft(messages.view)} `}</span>
              <img className="inline ml-3" src={arrowRightIcon} alt="" />
            </div>
          </button>
        )
      };
    });
  }, [educators, navigate]);

  function handleSort(header: string) {
    if (header === sortBy && sortDir === 'asc') {
      setSortDir('desc');
    } else if (header === sortBy && sortDir === 'desc') {
      setSortBy(null);
      setSortDir(null);
    } else {
      setSortBy(header);
      setSortDir('asc');
    }
  }

  if (isLoading || !educators) {
    <Loader active />;
  }

  return (
    <>
      <div className="my-8">
        <div className="mt-10 pb-12 rounded-2xl bg-white">
          <div className="flex flex-col">
            <div>
              <h2 className="font-sans font-bold text-3xl text-black pl-2 py-4">
                {i18n.ft(messages.educators)}
              </h2>
            </div>
          </div>

          <Table
            headers={tableHeaders}
            data={tableData}
            sortBy={sortBy}
            sortDir={sortDir}
            onSort={handleSort}
          />
        </div>
      </div>

      <div className="mt-8 mb-16 px-4 md:px-0 md:py-8">
        <LastViewed />
        <Important />
      </div>
    </>
  );
}

export default MyEducators;
