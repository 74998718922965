const PATH = 'fe.components.knowledge_test.result_section.category_result';

const messages = {
  passed: {
    key: `${PATH}.passed`,
    defaultValue: 'passed'
  },
  failed: {
    key: `${PATH}.failed`,
    defaultValue: 'not passed'
  }
};

export default messages;
