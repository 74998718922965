import { SubscriptionSeat } from 'types/api/SubscriptionSeat';

export function hasRole(
  seats: SubscriptionSeat[],
  accountId: number,
  role: string
) {
  return seats.find(
    seat =>
      seat.subscription.owner_id === accountId &&
      seat.role?.name === role &&
      seat.role.product?.name === 'data_collection'
  );
}
