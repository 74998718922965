import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { ReactNode, useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Link, useMatch } from 'react-router-dom';
import { useMeasure } from 'context/measure';
import { ListItemNavLink } from './ListItemNavLink';
import { CycleActions } from './CycleActions';
import Header from '../Header';
import { useValidations } from 'components/Measure/View/ValidationsContext';
import { useMediaQuery } from 'react-responsive';
import { ResponsiveNavigation } from './ResponsiveNavigation';
import ReviewModal from 'components/Measure/View/ReviewModal';
import { useMeasureLocation } from 'components/Measure/View/useMeasureLocation';
import { getSettings } from 'actions/envScales/settings';

interface SideNavigationProps {
  /** Content to render for the page. */
  children: ReactNode;
}

export function SideNavigation({ children }: SideNavigationProps) {
  const { assessment } = useMeasure();
  const { status } = useValidations();
  const [isOpen, setIsOpen] = useState(false);
  const templateName = assessment.assessment_template.name;
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const { isDetailsPage } = useMeasureLocation();
  const [fixNumberOfCycles, setFixNumberOfCycles] = useState(true);

  useEffect(() => {
    async function fetchAccountSettings() {
      const { data } = await getSettings(assessment.account_guid);
      setFixNumberOfCycles(data.account_setting.preset_cycle_enabled);
    }

    fetchAccountSettings();
  }, [assessment.account_guid]);

  const isCyclePage = useMatch({
    path: RouteHelpers.getPath('measure-view-cycle')
  });

  const cycleActionsEnabled = isCyclePage && !fixNumberOfCycles;

  const navTitle = assessment.includes_environments
    ? i18n.ft(messages.withEnvironment, { name: templateName })
    : templateName;

  return (
    <div className="flex flex-col lg:flex-row h-full">
      <div className="lg:basis-64 shrink-0 bg-white">
        <div className="sticky top-0">
          <button
            id="sidenavlabel"
            className="w-full text-left px-4 py-2 font-bold text-white bg-[#017EA7] lg:cursor-default"
            onClick={() => setIsOpen(true)}
            tabIndex={isDesktop ? -1 : 0}
          >
            <span className="lg:hidden">
              <Icon name="caret down" />
            </span>
            <span>{navTitle}</span>
          </button>

          <ResponsiveNavigation
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
          >
            <div className="pb-5">
              <nav aria-labelledby="sidenavlabel">
                <ol className="px-1 py-4 flex flex-col gap-5">
                  <ListItemNavLink
                    to={RouteHelpers.getPath('measure-view-details', {
                      id: assessment.id
                    })}
                    content={i18n.ft(messages.details)}
                    status={status.details}
                  />

                  {assessment.includes_environments && (
                    <ListItemNavLink
                      to={RouteHelpers.getPath('measure-view-environment', {
                        id: assessment.id
                      })}
                      content={i18n.ft(messages.environment)}
                      status={status.environments}
                    />
                  )}

                  {assessment.observation?.observation_cycles.map(cycle => (
                    <ListItemNavLink
                      key={cycle.id}
                      to={RouteHelpers.getPath('measure-view-cycle', {
                        id: assessment.id,
                        cycleId: cycle.id
                      })}
                      content={i18n.ft(messages.cycle, {
                        cycle: cycle.cycle_number
                      })}
                      status={status[cycle.cycle_number]}
                    />
                  ))}

                  <ListItemNavLink
                    to={RouteHelpers.getPath('measure-examples-notes', {
                      id: assessment.id
                    })}
                    content={i18n.ft(messages.evidence)}
                    status={status.evidence}
                  />

                  <ListItemNavLink
                    to={RouteHelpers.getPath('measure-view-review', {
                      id: assessment.id
                    })}
                    content={i18n.ft(messages.summary)}
                    status={status.summary}
                  />
                </ol>
              </nav>

              <div className="px-1">
                <hr className="mt-4 mb-10 border-t-0 border-b-2" />

                {cycleActionsEnabled ? (
                  <CycleActions
                    cycleId={parseInt(isCyclePage.params.cycleId!)}
                  />
                ) : null}

                <div className="px-3">
                  <Button
                    fluid
                    basic
                    as={Link}
                    to={RouteHelpers.getPath('measure')}
                    content={i18n.ft(messages.exitObservation)}
                  />
                </div>
              </div>
            </div>
          </ResponsiveNavigation>
        </div>
      </div>

      <div className="grow">
        <Header assessment={assessment} />

        {isDetailsPage ? null : (
          <ReviewModal className="p-4 xl:px-20 pb-0 flex justify-end" />
        )}

        <div className="p-4 xl:px-20">{children}</div>
      </div>
    </div>
  );
}

export default SideNavigation;
