const PATH = 'fe.pages.test_preparations';

const messages = {
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: 'Watch and code each testing video'
  },
  logoDescription: {
    key: `${PATH}.logo_description`,
    defaultValue: 'Certification Logo'
  },
  training: {
    key: `${PATH}.training`,
    defaultValue: 'Training video'
  },
  practice: {
    key: `${PATH}.practice`,
    defaultValue: 'Practice video'
  },
  expiresOn: {
    key: `${PATH}.expires_on`,
    defaultValue: 'Expires on:'
  },
  expiresOnMobile: {
    key: `${PATH}.expires_on_mobile`,
    defaultValue: 'Exp.'
  },
  testInstructions: {
    key: `${PATH}.test_instructions`,
    defaultValue: 'View Test Instructions'
  },
  modal: {
    instructionsTitle: {
      key: `${PATH}.modal.instructions_title`,
      defaultValue: 'Instructions'
    },
    dismissButton: {
      key: `${PATH}.modal.dismiss_button`,
      defaultValue: 'Dismiss'
    }
  },
  confirmationModal: {
    title: {
      key: `${PATH}.confirmation_modal.title`,
      defaultValue: `Are you sure you're ready to submit?`
    },
    submit: {
      key: `${PATH}.confirmation_modal.submit`,
      defaultValue: `Submit`
    },
    cancel: {
      key: `${PATH}.confirmation_modal.cancel`,
      defaultValue: `Review codes`
    }
  }
};

export default messages;
