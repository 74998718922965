import RouteHelpers from 'helpers/routes';
import { useState } from 'react';
import * as R from 'ramda';
import i18n from 'helpers/i18n';
import { Link } from 'react-router-dom';
import useAsync from 'hooks/useAsync';
import { loginCMUser } from 'actions/users';
import TextButton from 'components/TextButton';
import { PageContainer, SubHeader } from './Styled';
import messages from './messages';
import { FormikHelpers } from 'formik';
import PhonePrompt from 'components/Users/PhonePrompt';
import LoginManager, { setLoginError } from 'components/LoginManager';
import ContainerWithLogo from 'components/ContainerWithLogo';

type ActionTypes = 'login' | 'enterPhone';

function CMLogin() {
  const loginCMUserRequest = useAsync();
  const [action, setAction] = useState<ActionTypes>('login');
  const [returnUrl, setReturnUrl] = useState('');

  function handleSubmit(
    values: any,
    formik: FormikHelpers<any>,
    isFormWithPassword: boolean
  ) {
    loginCMUserRequest.run(loginCMUser(values), {
      onSuccess: (data: any) => {
        const { return_url, user } = data;

        !R.isNil(return_url)
          ? setReturnUrl(return_url)
          : setReturnUrl(RouteHelpers.getPath('app'));

        user.login_phone_prompt
          ? setAction('enterPhone')
          : window.location.replace(return_url);
      },
      onError: (error: any) => {
        setLoginError(error, formik, isFormWithPassword);
        formik.setSubmitting(false);
      }
    });
  }

  function handleSuccess() {
    window.location.replace(returnUrl);
  }

  function renderForm() {
    switch (action) {
      case 'login':
        return (
          <LoginManager
            onLogin={handleSubmit}
            renderTop={loginStep =>
              loginStep === 'login' ? (
                <SubHeader>
                  {i18n.ft(messages.haveAnAccount) + ' '}
                  <TextButton
                    as={Link}
                    to={RouteHelpers.getPath('users-cm-register')}
                  >
                    {i18n.ft(messages.signUp)}
                  </TextButton>
                </SubHeader>
              ) : null
            }
          />
        );
      case 'enterPhone':
        return <PhonePrompt onSuccess={handleSuccess} />;
      default:
        return null;
    }
  }

  return (
    <PageContainer>
      <ContainerWithLogo>{renderForm()}</ContainerWithLogo>
    </PageContainer>
  );
}

export default CMLogin;
