import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import DonutChart from '../DonutChart';
import { Container, ChartLabel, ChartLevel } from './Styled';

interface ReliabilitySummaryProps {
  /** The number of participants who failed the test. */
  failed: number;

  /** The number of participants who passed the test. */
  passed: number;

  /** The color for the donut chart. */
  color: string;

  /** The age level name for the chart. */
  label: string;

  /** If `true`, the chart label will be displayed inline to the chart. */
  inline?: boolean;
}

const ReliabilitySummary: React.FC<ReliabilitySummaryProps> = ({
  passed,
  failed,
  color,
  label,
  inline
}) => {
  return (
    <Container inline={inline}>
      <DonutChart passed={passed} failed={failed} color={color} />
      <ChartLabel inline={inline}>
        <div>
          {i18n.ft(messages.reliable, { passed, total: passed + failed })}
        </div>
        <ChartLevel color={color}>{label}</ChartLevel>
      </ChartLabel>
    </Container>
  );
};

export default ReliabilitySummary;
