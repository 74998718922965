import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import Spacer from 'components/Spacer';
import { Section } from '../../Styled';
import { Button } from 'semantic-ui-react';
import certificateIcon from 'images/certificate.svg';
import { useTraining } from '../../TrainingContext';
import { TrainingTemplate } from 'types/api/TrainingTemplate';

import {
  Icon,
  Header,
  SubHeader,
  SectionHeader,
  Credit,
  CreditCEU,
  CreditsContainer,
  CreditDescription,
  DownloadLinkBottom,
  DownloadLinkTop
} from './Styled';

interface CertificateHoursProps {
  template: TrainingTemplate;
}

const CertificateHours = ({ template }: CertificateHoursProps) => {
  const learningHours = template.partial_credit
    ? template.hours_per_session
    : template.total_learning_hours;
  const learningHoursMessage = template.partial_credit
    ? messages.learningHours
    : messages.totalLearningHours;

  return (
    <Credit>
      <CreditCEU>{learningHours}</CreditCEU>
      <CreditDescription>{i18n.ft(learningHoursMessage)}</CreditDescription>
    </Credit>
  );
};

interface CertificateCeusProps {
  template: TrainingTemplate;
}

const CertificateCeus = ({ template }: CertificateCeusProps) => {
  const ceusMessage = template.partial_credit
    ? messages.ceus
    : messages.totalCeus;

  function calculateCEUs() {
    const totalLearningHours = template?.total_learning_hours ?? 0;
    const hoursPerSession = parseFloat(template?.hours_per_session ?? '0');

    if (template.partial_credit) {
      return (hoursPerSession / 10.0).toFixed(1);
    } else {
      return (totalLearningHours / 10.0).toFixed(1);
    }
  }

  return (
    <Credit>
      <CreditCEU>{calculateCEUs()}</CreditCEU>
      <CreditDescription>{i18n.ft(ceusMessage)}</CreditDescription>
    </Credit>
  );
};

const CertificateSection: React.FC = () => {
  const { training, template } = useTraining();

  return (
    <Section>
      <SectionHeader>
        <Icon src={certificateIcon} alt={i18n.ft(messages.certificateAlt)} />
        <div>
          <Header>{i18n.ft(messages.certificate)}</Header>
          <SubHeader>{i18n.ft(messages.uponCompletion)}</SubHeader>
        </div>

        {training.has_certificate && (
          <DownloadLinkTop>
            <Button
              as="a"
              href={`/api/v2/trainings/${training.id}/certificate`}
              download
              size="small"
              color="blue"
              content={i18n.ft(messages.download)}
            />
          </DownloadLinkTop>
        )}
      </SectionHeader>

      <Spacer size={32} />
      <CreditsContainer>
        <CertificateHours template={template} />

        {template.earn_ceus && <CertificateCeus template={template} />}
      </CreditsContainer>

      {training.has_certificate && (
        <DownloadLinkBottom>
          <Button
            as="a"
            href={`/api/v2/trainings/${training.id}/certificate`}
            download
            fluid
            color="blue"
            content={i18n.ft(messages.download)}
          />
        </DownloadLinkBottom>
      )}
    </Section>
  );
};

export default CertificateSection;
