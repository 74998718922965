const PATH = 'fe.pages.measure.cycles_review';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Review Observation'
  },
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: 'Confirm what you entered'
  },
  return: {
    key: `${PATH}.return`,
    defaultValue: 'Return to List'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Submit'
  },
  approve: {
    key: `${PATH}.approve`,
    defaultValue: 'Approve Observation'
  },
  submitError: {
    key: `${PATH}.submit_error`,
    defaultValue:
      'Please complete all required fields in order to submit your observation.'
  },
  dateError: {
    key: `${PATH}.date_error`,
    defaultValue:
      'Please select an observation date of today or in the past in order to submit.'
  },
  modal: {
    messageApprove: {
      key: `${PATH}.modal.message_approve`,
      defaultValue: `Are you sure you want to approve for this observation? You can't undo this action`
    },
    buttonApprove: {
      key: `${PATH}.modal.button_approve`,
      defaultValue: 'Yes, Approve'
    },
    errors: {
      editJobId: {
        key: `${PATH}.modal.errors.edit_job_id`,
        defaultValue: 'Click here to edit it'
      },
      jobIdMissing: {
        key: `${PATH}.modal.errors.job_id_missing`,
        defaultValue: 'Job ID is missing'
      }
    }
  }
};

export default messages;
