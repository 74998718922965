import styled from 'styled-components';

export const LogInWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const Label = styled.div`
  font-size: 0.9em;
  margin-right: 10px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  margin-top: 24px;
`;

export const SubHeader = styled.div`
  margin-bottom: 24px;
  text-align: left;
`;
