import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import Spacer from 'components/Spacer';
import { Link } from 'react-router-dom';
import { useMeasure } from 'context/measure';
import { Button, Icon } from 'semantic-ui-react';
import ClassroomExample from './ClassroomExample';
import VisuallyHidden from 'components/VisuallyHidden';

import {
  Section,
  SectionHeader,
  SectionContent
} from 'components/Measure/View/Styled';

import {
  GrowthIcon,
  StrengthIcon
} from 'pages/Measure/View/ExamplesAndNotes/Styled';

interface ClassroomExamplesProps {
  /** If `true`, the edit button will be displayed. */
  showEdit?: boolean;
}

function ClassroomExamples({ showEdit }: ClassroomExamplesProps) {
  const { assessment } = useMeasure();
  const observation = assessment.observation!;
  const editLink = RouteHelpers.getPath('measure-examples-notes', {
    id: assessment.id
  });

  return (
    <Section mt="8px">
      <Flex justify="space-between" align="center">
        <SectionHeader>{i18n.ft(messages.title)}</SectionHeader>

        {showEdit && (
          <Box mr={{ _: '0px', sm: '56px' }}>
            <Button icon color="blue" size="mini" as={Link} to={editLink}>
              <VisuallyHidden>{i18n.ft(messages.editFeedback)}</VisuallyHidden>
              <Icon fitted name="pencil" />
            </Button>
          </Box>
        )}
      </Flex>

      <SectionContent>
        <ClassroomExample
          title={i18n.ft(messages.strengthExample)}
          icon={<StrengthIcon />}
          notes={observation.strength_text}
          dimensionId={observation.selected_strength_dimension_id}
          indicatorId={observation.selected_strength_indicator_id}
          suggestedDimensionId={observation.recommended_strength_dimension_id}
          suggestedIndicatorId={observation.recommended_strength_indicator_id}
        />
        <Spacer size={32} />
        <ClassroomExample
          title={i18n.ft(messages.growthExample)}
          icon={<GrowthIcon />}
          notes={observation.improve_text}
          dimensionId={observation.selected_improve_dimension_id}
          indicatorId={observation.selected_improve_indicator_id}
          suggestedDimensionId={observation.recommended_improve_dimension_id}
          suggestedIndicatorId={observation.recommended_improve_indicator_id}
        />
      </SectionContent>
    </Section>
  );
}

export default ClassroomExamples;
