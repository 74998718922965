import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { head, values } from 'ramda';
import { Training } from 'types/api/Training';

export function formatLocation(training: Training): string {
  const { city, state, country } = training.location_address;

  if (!city || !country || !state) {
    return 'TBD';
  }

  if (country === 'US') {
    return `${city}, ${state}`;
  } else {
    return `${city}, ${country}`;
  }
}

export function formatDates(training: Training): string {
  const startDate = moment(training.start_date).format('MM/DD/YY');
  const endDate = moment(training.end_date).format('MM/DD/YY');

  return `${startDate} - ${endDate}`;
}

export function formatDeliveryMethod(training: Training): string {
  const deliveryMethod =
    training.delivery_method === 'virtual'
      ? i18n.ft(messages.virtual)
      : i18n.ft(messages.inPerson);

  return `${i18n.ft(messages.deliveryMethod)}: ${deliveryMethod}`;
}

export function formatTrainer(training: Training): string {
  const trainer = `${training.trainer.first_name} ${training.trainer.last_name}`;
  return `${i18n.ft(messages.trainer)}: ${trainer}`;
}

export function getTotalParticipants(report: any): number {
  const data = head(values(report));
  return data.total_participants;
}
