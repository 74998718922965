import axios from './axiosConfig';

import {
  TARGET_TYPE,
  ObservationFeedback
} from 'types/api/envScales/ObservationFeedback';

export type GetObservationFeedbackRes = {
  questions_with_answers: ObservationFeedback[];
};

export function getObservationFeedback(
  observationId: number,
  targetType: TARGET_TYPE
) {
  return axios.get<GetObservationFeedbackRes>(
    `/api/v1/observations/${observationId}/observation_feedbacks?target_type=${targetType}`
  );
}

export function answerQuestion(
  observationId: number,
  questionId: number,
  payload: any = {}
) {
  return axios.put(
    `/api/v1/observations/${observationId}/observation_feedbacks/${questionId}`,
    payload
  );
}
