import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { SelectedAccount } from 'types/api/Account';

export type ACCOUNT_AREAS =
  | 'CertificationsDashboard'
  | 'DataCollection'
  | 'ManageOrganization'
  | 'ObservationReports';

export function useGetAccountsByArea(area: ACCOUNT_AREAS) {
  return useQuery({
    queryKey: ['accounts', 'manager', area],
    queryFn: () => getAccountsByArea(area).then(res => res.data),
    retry: false
  });
}

export function getAccountsByArea(area: ACCOUNT_AREAS) {
  return axios.get<SelectedAccount[]>('/api/v2/accounts/manager', {
    params: { area }
  });
}

export function chooseAccount(id: number, area: ACCOUNT_AREAS) {
  return axios.post(`/api/v2/accounts/manager/${id}/choose`, { area });
}
