type VERB =
  | 'BEGINS_PLAYBACK'
  | 'BROWSES'
  | 'COMPLETES'
  | 'FIRST_VIEWS'
  | 'IS_ASSIGNED_TO_COACH'
  | 'MARKS_AS_IMPORTANT';

export const USER_ENGAGEMENT_EVENT_VERBS: Record<VERB, VERB> = {
  BEGINS_PLAYBACK: 'BEGINS_PLAYBACK',
  BROWSES: 'BROWSES',
  COMPLETES: 'COMPLETES',
  FIRST_VIEWS: 'FIRST_VIEWS',
  IS_ASSIGNED_TO_COACH: 'IS_ASSIGNED_TO_COACH',
  MARKS_AS_IMPORTANT: 'MARKS_AS_IMPORTANT'
};
