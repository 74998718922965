import useAsync from 'hooks/useAsync';
import useCurrentUser from 'hooks/useCurrentUser';
import { getPermissions } from 'actions/permissions';
import { MyPermissions } from 'types/api/Permission';
import React, { useEffect, createContext, useContext } from 'react';
import { isNil } from 'ramda';

const defaultValue = {
  subscription_seats: {
    active: []
  },
  subscriptions: [],
  permissions: {
    teachstone_admin: [],
    trainer_dashboard: []
  }
};

export const AuthContext = createContext<MyPermissions>(defaultValue);
AuthContext.displayName = 'AuthContext';

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const currentUser = useCurrentUser();
  const { data: permissions, run, isPending } = useAsync();

  useEffect(() => {
    if (!isNil(currentUser.id)) {
      run(getPermissions());
    }
  }, [run, currentUser.id]);

  if (isPending && !isNil(currentUser.id)) {
    return null;
  }

  return (
    <AuthContext.Provider value={permissions}>{children}</AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export function useActiveSubscriptions() {
  const auth = useAuth();
  return auth.subscription_seats?.active ?? [];
}
