const PATH = 'fe.components.test_preparations.score_report.result_section';

const messages = {
  videoCriteria: {
    key: `${PATH}.video_criteria`,
    defaultValue:
      'In the real test, you’ll need to correctly code at least 80% of the dimensions accross all 5 videos'
  },
  scoreAbove: {
    key: `${PATH}.score_above`,
    defaultValue: 'You scored above'
  },
  scoreBelow: {
    key: `${PATH}.score_below`,
    defaultValue: 'You scored less than'
  },
  ExpertCodes: {
    key: `${PATH}.expert_codes`,
    defaultValue:
      'Review the expert code justification for this video to read detailed explanations of the expert codes.'
  },
  button: {
    key: `${PATH}.button`,
    defaultValue: 'Review PDF'
  },
  pdf: {
    key: `${PATH}.pdf`,
    defaultValue: 'PDF icon'
  }
};

export default messages;
