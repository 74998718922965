import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: auto;
  padding-top: 1em;
  padding-bottom: 2em;

  @media screen and (min-width: 640px) {
    padding-top: 3em;
  }

  @media screen and (min-width: 1536px) {
    padding-top: 10em;
  }
`;

export const BackgroundImg = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-width: 100%;
`;
