import styled from 'styled-components';
import { Form, Input } from 'semantic-ui-react';

export const Container = styled.div`
  margin-bottom: 10px;
`;

export const ContainerTwo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TagContainer = styled.div<{
  tagInside?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;

  ${({ tagInside }) =>
    tagInside &&
    `
    margin-top: -43px;
    z-index: 0;
  `}
`;

export const TagNameContainer = styled.div`
  flex-wrap: wrap;
`;

export const TagCenterName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${p => p.theme.colors['neutral']['700']};
  margin: 0 4px 0 4px;
`;

export const TagSubCenterName = styled.div`
  font-size: 14px;
  color: ${p => p.theme.colors['grey']};
  margin: 0 4px 0 4px;
`;

export const Tag = styled.div`
  border-radius: 5px;
  background-color: ${p => p.theme.colors['neutral']['50']};
  padding: 4px;
  margin: 8px 4px;
  display: flex;
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  margin-top: 1px;
  display: flex;
`;

export const Title = styled.div<{
  required?: boolean;
}>`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 0.9375em;
  color: ${p => p.theme.colors['scaleDarkBlue']};

  &::after {
    ${({ required }) => required && `content: '*';`}
    color: ${p => p.theme.colors['red']};
    margin: -0.2em 0 0 0.2em;
  }
`;

export const SelectDisable = styled(Input)`
  input {
    background: ${p => p.theme.colors['neutral']['200']} !important;
    pointer-events: none;
  }
`;

export const FormInputWithLabel = styled(Form.Input)`
  .ui.search.dropdown > input.search {
    width: 100% !important;
  }

  &.error label {
    color: ${props => props.theme.colors.red} !important;
  }

  &.error > div {
    border-color: ${props => props.theme.colors.red} !important;
  }

  .ui.input.error > input {
    color: ${props => props.theme.colors.red} !important;
  }
`;
