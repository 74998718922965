import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import styled from 'styled-components';
import momentWithZones from 'moment-timezone';
import Typography from 'components/Typography';

const Container = styled.div`
  font-size: 14px;
  margin: 8px 0 16px;
`;

const TimeZone: React.FC = () => {
  const zone = momentWithZones.tz.guess(true);
  const offset = momentWithZones().tz(zone).format('Z');

  return (
    <Container>
      <Typography display="block" content={i18n.ft(messages.localTime)} />
      <Typography display="block">
        {i18n.ft(messages.yourZone)} <strong>{`(GMT${offset}) ${zone}`}</strong>
      </Typography>
    </Container>
  );
};

export default TimeZone;
