const PATH = 'fe.components.learning_resources.sort_dropdown';

const messages = {
  options: {
    newest: {
      key: `${PATH}.fields.newest`,
      defaultValue: 'Newest First'
    },
    alphabetical: {
      key: `${PATH}.fields.alphabetical`,
      defaultValue: 'Alphabetical A-Z'
    },
    mostPopular: {
      key: `${PATH}.fields.most_popular`,
      defaultValue: 'Most Popular'
    }
  },
  sortBy: {
    key: `${PATH}.fields.sort_by`,
    defaultValue: 'Sort by:'
  }
};

export default messages;
