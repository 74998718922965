import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import {
  Wrapper,
  Title,
  ItemWrapper,
  InputWrapper,
  CheckedRadio,
  NotCheckedRadio
} from './Styled';
import { CycleMetaDataValue } from 'types/api/envScales/CycleMetaDataValue';
import { MetaDataCategory } from 'types/api/envScales/MetaDataCategory';
import { ObservationCycle } from 'types/api/envScales/ObservationCycle';
import { SingleValueEditionError } from 'types/api/envScales/CyclesErrors';
import { updateCycleMetaData } from 'actions/envScales/observationCycles';
import { useCyclesErrors } from 'context/CyclesErrorsContext';
import { GenericCheckbox } from 'components/GenericCheckbox';

import { ErrorMessage } from 'components/Measure/View/Styled';

export function getInitialValues(
  category: MetaDataCategory,
  values: CycleMetaDataValue[]
) {
  let initialValues = '';
  category.choices.forEach(choice => {
    const dbValue = values.find(
      value =>
        value.value === choice.t_name &&
        value.meta_data_category_id === category.id
    );
    if (dbValue) {
      initialValues = dbValue.value;
    }
  });
  return initialValues;
}

interface MetaDataSelectOneProps {
  /** Observation ID. */
  observationId: number;

  /** Cycle */
  cycle: ObservationCycle;

  /** Initial meta data values. */
  values: CycleMetaDataValue[];

  /** Meta data category. */
  category: MetaDataCategory;
}

function MetaDataSelectOne({
  observationId,
  cycle,
  values: initialValues,
  category
}: MetaDataSelectOneProps) {
  const [values, setValues] = useState(
    getInitialValues(category, initialValues)
  );
  const { cyclesErrors, updateCycleErrors } = useCyclesErrors();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues(event.target.value);
    const payload = {
      meta_data: {
        meta_data_category_id: category.id,
        values: event.target.value
      }
    };
    validate(event.target.value);
    updateCycleMetaData(observationId, cycle.id, payload);
  };

  function validate(values: any) {
    if (R.isEmpty(cyclesErrors)) {
      return;
    }

    let newCyclesErrors = R.clone(cyclesErrors);

    let checkedError = null;
    if (R.isEmpty(values)) {
      checkedError = i18n.ft(messages.errors.categoryError);
    }
    (
      newCyclesErrors[cycle.cycle_number]
        .meta_data_categories as SingleValueEditionError
    )[category.t_name] = checkedError;

    updateCycleErrors(newCyclesErrors);
  }

  return (
    <Wrapper>
      <Title>{i18n.ft(messages.type, { category: category.name })}</Title>
      <Box mt="12px">{category.description}</Box>

      {!R.isEmpty(cyclesErrors) && (
        <ErrorMessage>
          {
            (
              cyclesErrors[cycle.cycle_number]
                ?.meta_data_categories as SingleValueEditionError
            )[category.t_name]
          }
        </ErrorMessage>
      )}

      <Box mt="32px">
        {category.choices.map(choice => {
          const check = values === choice.t_name;
          return (
            <ItemWrapper key={`select_${choice.id}`}>
              <InputWrapper>
                <Flex align="center">
                  <GenericCheckbox
                    onChange={handleChange}
                    checked={check}
                    type="radio"
                    value={choice.t_name}
                    aria-label={choice.name}
                    name="select_one"
                  >
                    {check ? <CheckedRadio /> : <NotCheckedRadio />}
                  </GenericCheckbox>
                  <Box as="span" ml="16px">
                    {choice.name}
                  </Box>
                </Flex>
              </InputWrapper>
            </ItemWrapper>
          );
        })}
      </Box>
    </Wrapper>
  );
}

export default MetaDataSelectOne;
