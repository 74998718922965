import styled from 'styled-components';
import { AGE_LEVEL_ABBREVIATIONS } from 'types/api/AgeLevel';

import {
  Table as SemanticTable,
  Button as SemanticButton
} from 'semantic-ui-react';

export const Table = styled(SemanticTable)`
  &.ui.table th {
    color: #ffffff;
    background-color: ${props => props.theme.colors.blue};
  }
`;

export const TableHeaderCell = styled(Table.HeaderCell)<{
  $mobile?: boolean;
  $desktop?: boolean;
}>`
  @media screen and (max-width: 850px) {
    display: ${props => (props.$mobile ? 'table-cell' : 'none')};
  }

  @media screen and (min-width: 851px) {
    display: ${props => (props.$desktop ? 'table-cell' : 'none')};
  }
`;

export const TableBodyCell = styled(Table.Cell)<{
  $mobile?: boolean;
  $desktop?: boolean;
}>`
  @media screen and (max-width: 850px) {
    display: ${props => (props.$mobile ? 'table-cell' : 'none')};
  }

  @media screen and (min-width: 851px) {
    display: ${props => (props.$desktop ? 'table-cell' : 'none')};
  }
`;

export const Button = styled(SemanticButton)`
  .ui.buttons > &.ui.button {
    border: 1px solid ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.blue};
    background-color: #ffffff;

    &:hover,
    &:focus {
      color: #333333;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .ui.buttons > &.ui.button.active {
    border: none;
    color: #ffffff;
    background-color: ${props => props.theme.colors.blue};

    &:hover,
    &:focus {
      background-color: #005875;
    }
  }
`;

export const Container = styled.div<{ abbreviation: AGE_LEVEL_ABBREVIATIONS }>`
  padding: 16px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  border-top: 5px solid
    ${({ theme, abbreviation }) =>
      theme.colors.ageLevels[abbreviation.toLowerCase()]};
`;

export const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;

  @media screen and (max-width: 600px) {
    margin-top: 16px;
  }

  @media screen and (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
