import i18n from 'helpers/i18n';
import settings from 'helpers/settings';
import messages from './messages';
import lampIcon from 'images/reliabilityTest/lampIcon.svg';
import { ResourceItemLink, ResourceItemContent } from './Styled';

interface TestPrepResourcesListProps {
  videoLibraryURL: string;
  observerVillageURL: string;
}

function TestPrepResourcesList({
  videoLibraryURL,
  observerVillageURL
}: TestPrepResourcesListProps) {
  const reliabilitySupportURL = `${settings.lookup('REACT_APP_TS_RELIABILITY_SUPPORT')}`;

  return (
    <>
      <div className="ml-12 mt-4 mb-6">
        <ResourceItemContent>
          {i18n.ft(messages.beforeTest)}
        </ResourceItemContent>
      </div>

      <div className="mb-12">
        <div className="grid grid-cols-12 mt-2">
          <div className="col-span-12 md:col-span-3">
            <img src={lampIcon} className="inline mr-6" alt="bullet point" />
            <ResourceItemLink href={reliabilitySupportURL}>
              {i18n.ft(messages.reliabilitySupport)}
            </ResourceItemLink>
          </div>
          <div className="col-span-12 md:col-span-9 ml-2">
            <ResourceItemContent>
              {i18n.ft(messages.reliabilitySupportContent)}
            </ResourceItemContent>
          </div>
        </div>
        {videoLibraryURL ? (
          <div className="grid grid-cols-12 mt-2">
            <div className="col-span-12 md:col-span-3">
              <img src={lampIcon} className="inline mr-6" alt="bullet point" />
              <ResourceItemLink href={videoLibraryURL}>
                {i18n.ft(messages.videoLibrary)}
              </ResourceItemLink>
            </div>
            <div className="col-span-12 md:col-span-9 ml-2">
              <ResourceItemContent>
                {i18n.ft(messages.videoLibraryContent)}
              </ResourceItemContent>
            </div>
          </div>
        ) : null}

        {observerVillageURL ? (
          <div className="grid grid-cols-12 mt-2">
            <div className="col-span-12 md:col-span-3">
              <img src={lampIcon} className="inline mr-6" alt="bullet point" />
              <ResourceItemLink href={observerVillageURL}>
                {i18n.ft(messages.observerVillage)}
              </ResourceItemLink>
            </div>
            <div className="col-span-12 md:col-span-9 ml-2">
              <ResourceItemContent>
                {i18n.ft(messages.observerVillageContent)}
              </ResourceItemContent>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default TestPrepResourcesList;
