import styled from 'styled-components';

export const SessionList = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
`;

export const SessionCard = styled.div`
  width: calc((100% - 12px) / 2);
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #e2e2e3;
  background-color: #fafafb;

  &:nth-of-type(n + 3) {
    margin-top: 12px;
  }

  &:nth-of-type(2n) {
    margin-left: 12px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;

    &:not(:first-of-type) {
      margin-top: 12px;
    }

    &:nth-of-type(2n) {
      margin-left: 0;
    }
  }
`;
