import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { isEmpty } from 'ramda';
import { Button, Icon } from 'semantic-ui-react';
import { AgeLevelTag } from 'components/AgeLevel';
import { CoreTest } from 'types/api/core/Test';
import { getAgeLevel } from 'helpers/ageLevel';
import {
  getLastAttemptNumber,
  getCompletedCoreAttempts,
  formatAgeLevel
} from './utils';
import TextButton from 'components/TextButton';

import {
  ActionsContainer,
  Description,
  Subtitle,
  TagContainer,
  Title,
  DateContainer,
  DateContainerMobile,
  DetailsContainer,
  TestStatus,
  Container,
  ContainerTop,
  ContainerBottom,
  ReportLink,
  EmptyAttempts
} from './Styled';

interface TestCardProps {
  /** Test from Core. */
  test: CoreTest;

  /** If `true`, score report links will be displayed below the card. */
  showReportLinks?: boolean;

  /** If `true`, updates the test name and age level name based on given rules. */
  isReliability?: boolean;
}

const TestCard: React.FC<TestCardProps> = ({
  test,
  showReportLinks,
  isReliability
}) => {
  const today = moment().startOf('day');
  const finishBy = moment(test.finish_by);
  const finishByDate = finishBy.format('L');
  const attemptNumber = getLastAttemptNumber(test);

  const isExpired =
    finishBy.isBefore(today) &&
    test.status !== 'passed' &&
    test.status !== 'failed';

  const completedAttempts = getCompletedCoreAttempts(test);

  const expirationTime = () => {
    if (!finishBy.isValid()) {
      return;
    }
    const endOfDay = finishBy.endOf('day');
    const time = endOfDay.format('hh:mm A');
    const timeZone = endOfDay.format('ZZ') === '-0400' ? 'EDT' : 'EST';
    return `${time} ${timeZone}`;
  };

  function isK3CertifiedTrainer() {
    return (
      test.name.includes('K-3') &&
      test.level === 'Certified Trainer' &&
      isReliability
    );
  }

  function formatName() {
    let testName = test.name;
    const pk3Name = getAgeLevel('PK3').name;

    if (!isReliability) {
      return testName;
    }

    if (test.level === 'Observer') {
      if (test.age_level.abbreviation === 'K-3') {
        testName = testName.replace('Observer', 'CLASS 2nd Edition Observer');
        testName = testName.replace('K-3', pk3Name);
      } else {
        testName = testName.replace('Observer', 'CLASS 2008 Observer');
      }
    } else if (test.level === 'Certified Trainer') {
      const isK3 = test.age_level.abbreviation === 'K-3';
      const trainerAgeLevel = test.age_level_trainer.name;
      const observerAgeLevel = isK3 ? pk3Name : trainerAgeLevel;
      const observerVersion = isK3 ? 'CLASS 2nd Edition' : 'CLASS 2008';
      testName = `Recertification for Certified CLASS 2008 Trainer ${trainerAgeLevel} and ${observerVersion} Observer ${observerAgeLevel}`;
    }

    return testName;
  }

  return (
    <Container>
      <ContainerTop>
        <DetailsContainer>
          <div>
            <Title>{formatName()}</Title>
            <TagContainer>
              <AgeLevelTag abbreviation={test.age_level.abbreviation}>
                {formatAgeLevel(test.age_level.name, isReliability)}
              </AgeLevelTag>
              {isK3CertifiedTrainer() && (
                <AgeLevelTag abbreviation={test.age_level.abbreviation}>
                  {test.age_level.name}
                </AgeLevelTag>
              )}
            </TagContainer>
          </div>

          <DateContainerMobile>
            {i18n.ft(messages.finishAttempts, { date: finishByDate }) +
              ' ' +
              expirationTime()}
          </DateContainerMobile>
        </DetailsContainer>

        <DateContainer>
          <Subtitle>{i18n.ft(messages.attempts)}</Subtitle>
          <Description>
            {i18n.ft(messages.finishBy, { date: finishByDate })}
          </Description>
          <Description>{expirationTime()}</Description>
        </DateContainer>

        {isExpired ? (
          <ActionsContainer>
            <TestStatus>
              <Icon name="time" />
              {i18n.ft(messages.expired)}
            </TestStatus>
          </ActionsContainer>
        ) : (
          <ActionsContainer>
            {test.status === 'not_started' || test.status === 'in_progress' ? (
              <Button fluid as="a" href={test.link} color="blue">
                {i18n.ft(messages.goToAttempt, { number: attemptNumber })}
              </Button>
            ) : null}

            {test.status === 'passed' && (
              <TestStatus passed>
                <Icon color="green" name="check circle" />
                {i18n.ft(messages.passed)}
              </TestStatus>
            )}

            {test.status === 'failed' && (
              <TestStatus failed>
                <Icon color="red" name="exclamation circle" />
                {i18n.ft(messages.failed)}
              </TestStatus>
            )}
          </ActionsContainer>
        )}
      </ContainerTop>

      {showReportLinks && (
        <ContainerBottom>
          <div>
            {completedAttempts.map(attempt => (
              <ReportLink key={attempt.attempt} href={attempt.score_report_url}>
                {i18n.ft(messages.reportNumber, { number: attempt.attempt })}
              </ReportLink>
            ))}
            {isEmpty(completedAttempts) && (
              <EmptyAttempts>{i18n.ft(messages.noAttempts)}</EmptyAttempts>
            )}
          </div>
          <div>
            {(test.status === 'not_started' || test.status === 'in_progress') &&
            !isExpired ? (
              <TextButton
                as="a"
                href={test.instructions_link}
                target="_blank"
                rel="noopener"
              >
                <Icon name="list ol" />
                {i18n.ft(messages.viewInstructions)}
              </TextButton>
            ) : null}
          </div>
        </ContainerBottom>
      )}
    </Container>
  );
};

export default TestCard;
