import moment from 'moment';
import { Training } from 'types/api/Training';
import { hasStarted, lastSessionHasStarted } from 'helpers/training';
import { ParticipantListItemProps } from './ParticipantList';
import { map, filter, prop, any, includes, isEmpty, join } from 'ramda';

import {
  PARTICIPANT_STATUS,
  TrainingParticipant
} from 'types/api/TrainingParticipant';

export function mapParticipantsToOptions(
  participants: TrainingParticipant[]
): ParticipantListItemProps[] {
  return map(participant => {
    return {
      value: participant.id,
      email: participant.email,
      paymentStatus: participant.payment_status,
      status: participant.status,
      name: participant.user && participant.user.name,
      avatar: participant.user && participant.user.avatar
    };
  }, participants);
}

export function withinSevenDays(training: Training): boolean {
  const paymentCutoffDate = moment(training.start_date, 'YYYY-MM-DD').subtract(
    7,
    'days'
  );
  const endOfTheDay = moment().endOf('day');
  return paymentCutoffDate.isBefore(endOfTheDay);
}

export function paymentDisabled(
  participantIds: number[],
  participants: TrainingParticipant[]
): boolean {
  if (isEmpty(participantIds)) {
    return true;
  }

  const pendingParticipants = filter(
    p => p.payment_status === 'payment_pending' && p.status === 'pending',
    participants
  );
  const pendingParticipantIds = map(prop('id'), pendingParticipants);

  return any(participantId => {
    return !includes(participantId, pendingParticipantIds);
  }, participantIds);
}

export function reinviteDeclinedDisabled(
  participantIds: number[],
  participants: TrainingParticipant[]
): boolean {
  if (isEmpty(participantIds)) {
    return true;
  }

  return participants.some(element => {
    return participantIds.includes(element.id) && element.status !== 'declined';
  });
}

export function addCanceledDisabled(
  participantIds: number[],
  participants: TrainingParticipant[]
): boolean {
  if (isEmpty(participantIds)) {
    return true;
  }
  const canceledParticipants = filter(
    p => p.status === 'canceled',
    participants
  );
  const canceledParticipantIds = map(prop('id'), canceledParticipants);

  return any(participantId => {
    return !includes(participantId, canceledParticipantIds);
  }, participantIds);
}

export function editParticipantEmailDisabled(
  participantIds: number[],
  participants: TrainingParticipant[],
  training: Training
): boolean {
  if (isEmpty(participantIds)) {
    return true;
  }

  if (participantIds.length > 1) {
    return true;
  }

  if (hasStarted(training)) {
    return true;
  }

  const participant = participants.find(p => p.id === participantIds[0]);
  if (
    !participant?.status ||
    !['pending', 'canceled'].includes(participant.status)
  ) {
    return true;
  }

  return false;
}

export function cancelDisabled(
  participantIds: number[],
  participants: TrainingParticipant[],
  training: Training
): boolean {
  if (isEmpty(participantIds)) {
    return true;
  }

  if (lastSessionHasStarted(training)) {
    return true;
  }

  const unableToCancelParticipants = filter(p => {
    return (
      includes(p.status, ['completed', 'canceled', 'declined']) ||
      p.payment_status === 'payment_requested'
    );
  }, participants);

  const unableToCancelParticipantIds = map(
    prop('id'),
    unableToCancelParticipants
  );

  return any(participantId => {
    return includes(participantId, unableToCancelParticipantIds);
  }, participantIds);
}

export function exportDisabled(participantIds: number[]): boolean {
  return isEmpty(participantIds);
}

export function exportUrl(
  participantIds: number[],
  training: Training
): string {
  const params = join(
    '&',
    map(param => `p[]=${param}`, participantIds)
  );
  return `/api/v2/trainings/${training.id}/participants/csv?${params}`;
}

export function getTotalByStatus(
  status: PARTICIPANT_STATUS,
  participants: TrainingParticipant[]
): number {
  return filter(p => p.status === status, participants).length;
}

export function getMaxParticipants(training: Training): number {
  return training.training_template.max_participants;
}
