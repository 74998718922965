import styled from 'styled-components';

export const LanguagesWrapper = styled.div`
  margin: 0 1em;
  min-width: 368px;
  position: relative;
`;

export const Language = styled.div`
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  color: ${p => p.theme.colors['white']};
`;

export const Span = styled.div`
  font-weight: bold;
  color: ${p => p.theme.colors['white']};
  margin-right: 1em;
`;
