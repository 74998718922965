import colors from 'styles/colors';
import { find, propEq } from 'ramda';
import { THRESHOLD } from './constants';

type DimensionScore = {
  id: number;
  answer: number;
  dimensionId: number;
};

export function print() {
  window.print();
}

export const RELIABILITY_TEST_UICONF = 52245692;

export function areAllCodesCompleted(
  dimensionsCount: number,
  scoresLength: number
) {
  return scoresLength === dimensionsCount;
}

export function showIncompleteBorder(
  dimensionId: number,
  userScores: DimensionScore[],
  completeCodesError: boolean
) {
  const found = find(propEq('dimensionId', dimensionId))(userScores);

  if (!found && completeCodesError) {
    return '1px solid red';
  }

  return '';
}

export function getChartData(testScore: number) {
  const chartData = [
    {
      value: 100 - testScore,
      fill: colors.white,
      stroke: colors.white
    },
    {
      value: testScore,
      fill:
        testScore >= THRESHOLD
          ? colors.reliability.passVideo
          : colors.reliability.failedAttempt,
      stroke: colors.white
    }
  ];

  return chartData;
}
