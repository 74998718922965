import colors from 'styles/colors';
import styled, { css, keyframes } from 'styled-components';

const variants = {
  default: {
    color: colors.scaleDarkBlue,
    backgroundColor: colors.white,
    borderColor: 'transparent'
  },
  checked: {
    color: colors.white,
    backgroundColor: colors.scaleGreen,
    borderColor: 'transparent'
  },
  suggested: {
    color: colors.scaleDarkBlue,
    backgroundColor: colors.scaleLightGreen,
    borderColor: colors.white
  }
};

const blink = keyframes`
  from {
    border-color: white;
  }

  to {
    border-color: #C6F3DB;
  }
`;

export const Status = styled.div<{
  iconColor?: string;
  iconBackGroundColor?: string;
}>`
  display: inline-block;
  width: 19px;
  height: 19px;
  border-radius: 12px;
  color: ${p => p.iconColor};
  background-color: ${p => p.iconBackGroundColor};
  margin-right: 0.5rem;
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  align-self: center;

  i {
    margin-right: 0;
    margin-top: -5px;
  }

  @media screen and (min-width: 640px) {
    line-height: 20px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 7px;
  grid-template-columns: repeat(7, 1fr);
  width: auto;
  align-items: center;
  justify-items: center;

  @media screen and (min-width: 640px) {
    grid-gap: 32px;
  }
`;

export const ScoreCheckbox = styled.div<{
  checked?: boolean;
  suggested?: boolean;
}>`
  ${p =>
    variants[p.checked ? 'checked' : p.suggested ? 'suggested' : 'default']};
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;

  font-size: 16px;
  line-height: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;

  animation: ${p =>
    p.suggested &&
    css`
      ${blink} 1.5s alternate infinite
    `};

  @media screen and (min-width: 640px) {
    width: 32px;
    height: 32px;
    line-height: 20px;
    box-shadow: 0.1px 1px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const Name = styled.span`
  font-weight: 700;
  color: ${p => p.theme.colors['scaleDarkBlue']};
  padding: 12px 12px;
`;

export const DataGrid = styled.div`
  display: grid;
  grid-gap: 12px;
  align-items: center;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }

  @media screen and (min-width: 1024px) {
    grid-gap: 14px;
  }
`;

export const LinkButton = styled.button`
  color: ${props => props.theme.colors.blue};
  background-color: transparent;
  font-family: inherit;
  cursor: pointer;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const DimensionSection = styled.div`
  color: ${p => p.theme.colors['neutral']['700']};
  background-color: #f4f8fa;
  display: inline-block;
  border-radius: 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 5px;
  margin-bottom: 6px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 16px 50px -41px #364a5e;

  @media screen and (min-width: 640px) {
    line-height: 32px;
    padding: 12px 20px;
    border-radius: 20px;
    margin-bottom: 10px;
    box-shadow: none;
  }
`;

export const AllDimensionError = styled.span`
  color: ${p => p.theme.colors['scaleWarning']};
  text-align: center;
`;

export const Section = styled.section<{ knowledgeTest?: boolean }>`
  padding: 24px 0px;
  background-color: ${p => p.theme.colors.white};

  @media screen and (min-width: 640px) {
    padding: 24px 32px;
  }

  @media screen and (min-width: 768px) {
    padding: 24px 48px;
    margin-bottom: 5px;
  }

  @media screen and (min-width: 1024px) {
    padding: ${p => (p.knowledgeTest ? '2px' : '80px')} 80px;
    margin: 0 48px 5px;
  }
`;

export const MessageBox = styled.div`
  color: ${props => props.theme.colors.scaleDarkBlue};
  background-color: ${props => props.theme.colors.scaleLightSky};
`;

export const ChartContainer = styled.div`
  text-align: -webkit-center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  min-height: 125px;
  position: relative;
`;

export const Score = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.colors.scaleDarkBlue};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
`;

export const HeaderSection = styled.div`
  display: grid;
  justify-content: center;
  margin: 0px auto 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`;

export const PageHeaderWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  padding-left: 48px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (min-width: 768px) {
    margin-top: 64px;
    padding-left: 80px;
    padding-right: 48px;
  }

  @media screen and (min-width: 1280px) {
    padding-right: 80px;
  }
`;
