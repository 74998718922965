import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import DeclineModal from './DeclineModal';
import { Button, Icon } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { Container, Title, Subtitle, Actions, ErrorWrapper } from './Styled';

import {
  PARTICIPANT_STATUS,
  TrainingInvitation
} from 'types/api/TrainingParticipant';

import {
  getTrainingInvitation,
  respondTrainingInvitation
} from 'actions/trainings';

interface InvitationBannerProps {
  /** The training ID. */
  trainingId: number;

  /** Callback fired when the status is changed. */
  onChange: (status: PARTICIPANT_STATUS) => void;
}

const InvitationBanner: React.FC<InvitationBannerProps> = ({
  trainingId,
  onChange
}) => {
  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);
  const [invitation, setInvitation] = useState<TrainingInvitation>();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getTrainingInvitation(trainingId).then(({ data }) => {
      setInvitation(data);
      onChange(data.status);
    });
  }, [trainingId, onChange]);

  function handleAccept() {
    setAccepting(true);
    respondTrainingInvitation(trainingId, 'accept').then(
      ({ data }) => {
        setAccepting(false);
        setInvitation(data);
        onChange(data.status);
      },
      () => setIsError(true)
    );
  }

  function handleDecline(data: TrainingInvitation) {
    setDeclining(false);
    setInvitation(data);
    onChange(data.status);
  }

  function handleDeclineError() {
    setIsError(true);
    setDeclining(false);
  }

  if (!invitation || invitation.status !== 'invited') {
    return null;
  }

  return (
    <Container>
      <DeclineModal
        open={declining}
        trainingId={trainingId}
        afterDecline={handleDecline}
        onError={handleDeclineError}
        onClose={() => setDeclining(false)}
      />
      <div>
        <Title>{i18n.ft(messages.title)}</Title>
        <Subtitle>{i18n.ft(messages.subtitle)}</Subtitle>
      </div>
      <Actions>
        {isError ? (
          <ErrorWrapper>
            <Icon color="red" name="warning sign" />
            <span
              dangerouslySetInnerHTML={{
                __html: i18n.ft(messages.errorMessage)
              }}
            />
          </ErrorWrapper>
        ) : (
          <>
            <Button
              size="small"
              color="green"
              loading={accepting}
              onClick={handleAccept}
              content={i18n.ft(messages.accept)}
              disabled={
                accepting || declining || !invitation.can_accept_training
              }
            />
            <Button
              size="small"
              onClick={() => setDeclining(true)}
              content={i18n.ft(messages.decline)}
              disabled={
                accepting || declining || !invitation.can_decline_training
              }
            />
          </>
        )}
      </Actions>
    </Container>
  );
};

export default InvitationBanner;
