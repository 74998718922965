import i18n from 'helpers/i18n';
import { useMediaQuery } from 'react-responsive';
import { BulletPoint } from './Styled';

interface ModalInstructionItemProps {
  stepNumber: string;
  content: { key: string; defaultValue: string };
}

function ModalInstructionItem({
  stepNumber,
  content
}: ModalInstructionItemProps) {
  const isTablet = useMediaQuery({ minWidth: 768 });

  return (
    <li className="mb-4">
      <div className="flex">
        <BulletPoint isTablet={isTablet}>
          <span
            className={(isTablet ? `` : `px-2`).concat(
              ' text-current w-full font-bold'
            )}
          >
            {stepNumber}
          </span>
        </BulletPoint>
        <span>{i18n.ft(content)}</span>
      </div>
    </li>
  );
}

export default ModalInstructionItem;
