import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, Button, Modal } from 'semantic-ui-react';
import { Formik, Form, TextArea } from 'components/EnhancedForm';
import { requestUpdateAssessment } from 'actions/envScales/assessments';

const validationSchema = yup.object({
  notes: yup.string().max(1000, () => i18n.ft(messages.errors.feedback.max))
});

interface RequestUpdatesModalProps {
  assessmentId: number;
}

function RequestUpdatesModal({ assessmentId }: RequestUpdatesModalProps) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(values: any) {
    setIsSubmitting(true);
    await requestUpdateAssessment(assessmentId, values);
    navigate(RouteHelpers.getPath('measure'), { replace: true });
  }

  return (
    <Modal
      size="small"
      open={isOpen}
      closeOnDimmerClick={false}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={
        <Button fluid color="blue">
          {i18n.ft(messages.requestUpdates)}
        </Button>
      }
    >
      <Modal.Header>
        <Icon name="tasks" />
        <span>{i18n.ft(messages.requestUpdates)}</span>
      </Modal.Header>

      <Modal.Content>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ feedback_text: '' }}
          validationSchema={validationSchema}
        >
          <Form id="requestUpdatesForm">
            <div className="mb-4">{i18n.ft(messages.content)}</div>
            <TextArea
              name="feedback_text"
              label={i18n.ft(messages.fields.feedback)}
            />
          </Form>
        </Formik>
      </Modal.Content>

      <Modal.Actions>
        <Button
          type="button"
          onClick={() => setIsOpen(false)}
          disabled={isSubmitting}
          content={i18n.ft(messages.cancel)}
        />
        <Button
          type="submit"
          color="blue"
          form="requestUpdatesForm"
          disabled={isSubmitting}
          loading={isSubmitting}
          content={i18n.ft(messages.submit)}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default RequestUpdatesModal;
