const PATH = 'fe.components.phone.sms_code_form';

const messages = {
  enterCode: {
    key: `${PATH}.enter_code`,
    defaultValue:
      'Enter the verification code that was sent to your mobile phone'
  },
  resendCode: {
    key: `${PATH}.resend_code`,
    defaultValue: 'Resend Code'
  },
  invalidCode: {
    key: `${PATH}.invalid_code`,
    defaultValue: 'Invalid verification code, please try again'
  },
  verify: {
    key: `${PATH}.verify`,
    defaultValue: 'Verify'
  },
  success: {
    key: `${PATH}.success`,
    defaultValue: 'Successfully sent.'
  }
};

export default messages;
