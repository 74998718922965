import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { useState } from 'react';
import { useMeasure } from 'context/measure';
import { CycleActionsButton } from './CycleActionsButton';
import { createCycle, deleteCycle } from 'actions/envScales/observationCycles';
import ConfirmationModal from 'components/Modals/ConfirmationModal';

const MIN_NUM_OF_CYCLES = 1;
const MAX_NUM_OF_CYCLES = 6;

interface CycleActionsProps {
  cycleId: number;
}

export function CycleActions({ cycleId }: CycleActionsProps) {
  const { assessment } = useMeasure();
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const observation = assessment.observation!;
  const currentCycle = observation.observation_cycles.find(
    cycle => cycle.id === cycleId
  )!;

  const isAddCycleEnabled =
    assessment.observation!.observation_cycles.length < MAX_NUM_OF_CYCLES;

  const isDeleteCycleEnabled =
    assessment.observation!.observation_cycles.length > MIN_NUM_OF_CYCLES;

  function handleAddCycle() {
    setIsAdding(true);

    createCycle(observation.id).then(({ data }) => {
      window.location.href = RouteHelpers.getPath('measure-view-cycle', {
        id: assessment.id,
        cycleId: data.id
      });
    });
  }

  function handleDeleteCycle() {
    setIsDeleting(true);
    const observationCycles = observation.observation_cycles;

    const currentIndex = observationCycles.findIndex(
      cyc => cyc.id === currentCycle.id
    );

    const nextCycleId =
      currentIndex < observationCycles.length - 1
        ? observationCycles[currentIndex + 1].id
        : observationCycles[currentIndex - 1].id;

    deleteCycle(observation.id, currentCycle.id).then(() => {
      window.location.href = RouteHelpers.getPath('measure-view-cycle', {
        id: assessment.id,
        cycleId: nextCycleId
      });
    });
  }

  return (
    <div className="flex flex-col gap-1 -mt-1 mb-8">
      {isAddCycleEnabled && (
        <CycleActionsButton
          icon="plus"
          loading={isAdding}
          onClick={handleAddCycle}
          content={i18n.ft(messages.cycleActions.addCycle)}
        />
      )}

      {isDeleteCycleEnabled && (
        <ConfirmationModal
          title={i18n.ft(messages.cycleActions.deleteModal.title)}
          message={i18n.ft(messages.cycleActions.deleteModal.message, {
            cycle: currentCycle.cycle_number
          })}
          confirmButton={i18n.ft(messages.cycleActions.deleteModal.confirm)}
          icon="archive"
          onConfirm={handleDeleteCycle}
          triggerNode={
            <CycleActionsButton
              icon="trash alternate"
              loading={isDeleting}
              content={i18n.ft(messages.cycleActions.deleteCycle)}
            />
          }
        />
      )}
    </div>
  );
}
