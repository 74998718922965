import React from 'react';

function useSafeDispatch(dispatch: any) {
  const mounted = React.useRef(false);

  React.useLayoutEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return React.useCallback(
    (...args: any[]) => (mounted.current ? dispatch(...args) : void 0),
    [dispatch]
  );
}

type AsyncState<DataType = any, ErrorType = any> = {
  status: 'idle' | 'pending' | 'resolved' | 'rejected';
  data: DataType;
  error: ErrorType;
};

type RunPromise = Promise<any>;

type RunOptions<DataType = any, ErrorType = any> = {
  onSuccess?: (data: DataType) => void;
  onError?: (error: ErrorType) => void;
};

const defaultInitialState: AsyncState = {
  status: 'idle',
  data: null,
  error: null
};

function useAsync<DataType = any, ErrorType = any>(
  initialState: Partial<AsyncState> = {}
) {
  const initialStateRef = React.useRef<AsyncState>({
    ...defaultInitialState,
    ...initialState
  });

  const [{ status, data, error }, setState] = React.useReducer(
    (
      state: AsyncState<DataType, ErrorType>,
      action: Partial<AsyncState<DataType, ErrorType>>
    ) => ({
      ...state,
      ...action
    }),
    initialStateRef.current
  );

  const safeSetState = useSafeDispatch(setState);

  const setData = React.useCallback(
    (data: DataType) => safeSetState({ data, status: 'resolved' }),
    [safeSetState]
  );

  const setError = React.useCallback(
    (error: ErrorType) => safeSetState({ error, status: 'rejected' }),
    [safeSetState]
  );

  const reset = React.useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState]
  );

  const run = React.useCallback(
    (promise: RunPromise, options?: RunOptions<DataType, ErrorType>) => {
      if (!promise || !promise.then) {
        throw new Error(
          'The argument passed to useAsync().run must be a promise.'
        );
      }

      safeSetState({ status: 'pending' });

      promise.then(
        response => {
          setData(response.data);
          options?.onSuccess?.(response.data);
        },
        error => {
          const errorText = error?.response?.data ?? 'API Error';
          setError(errorText);
          options?.onError?.(errorText);
        }
      );
    },
    [safeSetState, setData, setError]
  );

  return {
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',
    isPending: status === 'idle' || status === 'pending',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset
  };
}

export default useAsync;
