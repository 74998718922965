import axios from 'axios';
import { AgeLevelGroup } from 'types/api/AgeLevelGroup';

type GetAgeLevelGroupsResponse = {
  age_level_groups: AgeLevelGroup[];
};

export interface AgeLevelGroupRequest {
  id?: number;
  name: string;
  alt_name?: string;
  code: string;
  slug: string;
  age_level_ids: number[];
}

export type GetAgeLevelGroupResponse = AgeLevelGroup;

export function getAgeLevelGroups(params = {}) {
  return axios.get<GetAgeLevelGroupsResponse>('/api/v2/age_level_groups', {
    params
  });
}

export function getAgeLevelGroup(slug: string) {
  return axios.get<GetAgeLevelGroupResponse>(
    `/api/v2/age_level_groups/${slug}`
  );
}
