import styled from 'styled-components';

export const QuestionRow = styled.div`
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const QuestionText = styled.p`
  margin: 16px 0;
`;

export const RadioInput = styled.input.attrs(_props => ({
  type: 'radio'
}))<{ error: boolean }>`
  appearance: none;

  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;

  background-color: #fff;
  border: 0.5px solid ${p => (p.error ? p.theme.colors.scaleWarning : 'black')};

  &:checked {
    background-color: ${p => p.theme.colors.scaleGreen};
    border: 0;
  }

  &:focus {
    outline: 1px solid ${p => p.theme.colors.scaleGreen};
    outline-offset: 3px;
  }
`;
