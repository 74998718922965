import { isNil } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Loader } from 'semantic-ui-react';
import Typography from 'components/Typography';
import { useEffect, useState } from 'react';
import { getVideo } from 'actions/envScales/observations';
import { Container, AspectRatio, VideoPlayer } from './Styled';

interface KalturaVideoProps {
  observationId: number;
  videoId: string;
}

const KalturaVideo = ({ observationId, videoId }: KalturaVideoProps) => {
  const playerID = `kalturaPlayer_${videoId}`;
  const [video, setVideo] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [allowedToWatch, setAllowedToWatch] = useState(true);

  useEffect(() => {
    getVideo(observationId, videoId)
      .then(({ data }) => {
        setLoading(false);
        setVideo(data);
      })
      .catch(() => {
        setLoading(false);
        setAllowedToWatch(false);
      });
  }, [observationId, videoId]);

  useEffect(() => {
    if (isNil(video)) {
      return;
    }

    // eslint-disable-next-line no-undef
    (window as any).kWidget.embed(playerID, {
      wid: '_966871',
      uiconf_id: '46364641',
      entry_id: video.kaltura_key,
      flashvars: {
        ks: video.view_token
      }
    });
  }, [video, playerID]);

  if (loading) {
    return <Loader active inline="centered" />;
  }

  if (!allowedToWatch) {
    return (
      <Typography
        bold
        color="white"
        content={i18n.ft(messages.notAuthorized)}
      />
    );
  }

  return (
    <Container>
      <AspectRatio />
      <VideoPlayer id={playerID} />
    </Container>
  );
};

export default KalturaVideo;
