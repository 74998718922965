import * as R from 'ramda';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { useCyclesErrors } from 'context/CyclesErrorsContext';

const Wrapper = styled.div<{ completed: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${p =>
    p.completed ? p.theme.colors['scaleGreen'] : '#ffffff'};
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.25);
  color: white;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  @media screen and (min-width: 640px) {
    width: 24px;
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    margin-left: 12px;
  }
`;

interface StatusCircleProps {
  /** If `true`, the circle will be green. */
  completed: boolean;

  /** Element to render. */
  children?: React.ReactNode;
}

export function StatusCircle({ completed, children }: StatusCircleProps) {
  const { cyclesErrors } = useCyclesErrors();

  if (!R.isEmpty(cyclesErrors) && !completed) {
    return (
      <Wrapper completed={false}>
        <Icon color="red" name="exclamation circle" fitted size="large" />
      </Wrapper>
    );
  }

  return (
    <Wrapper completed={completed}>
      {children || <Icon fitted size="tiny" name="check" />}
    </Wrapper>
  );
}
