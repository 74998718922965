import React from 'react';
import usePermissions from 'hooks/usePermissions';
import { OPERATIONS, RESOURCES, SECURITY_ZONES } from 'types/api/Permission';

interface PermissionProps {
  /** The `security zone` of the permission. */
  zone: SECURITY_ZONES;

  /** The `resource` the permission grants access to. */
  resource: RESOURCES;

  /**
   * The allowed operation to the given `resource`.
   * The `manage` operation is always considered by default.
   **/
  operation?: OPERATIONS;

  /** If `true`, the permission will be granted. */
  or?: boolean;

  /** React Node. */
  children: React.ReactNode;
}

const Permission: React.FC<PermissionProps> = ({
  zone,
  resource,
  operation,
  or: orCondition,
  children
}) => {
  const permissions = usePermissions(zone, resource);

  if (
    orCondition ||
    permissions.manage ||
    (operation && permissions[operation])
  ) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return null;
  }
};

export default Permission;
