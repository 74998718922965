import axios from 'axios';
import {
  LearningResource,
  PaginatedLearningResources,
  PaginatedRecommentations
} from 'types/api/LearningResource';

export function getLearningResources(params = {}) {
  return axios.get<PaginatedLearningResources>(`/api/v2/learning_resources/`, {
    params: params
  });
}

export function getLearningResource(id: number) {
  return axios.get<LearningResource>(`/api/v2/learning_resources/${id}`);
}

export function getLastViewedLearningResources() {
  return axios.get<PaginatedLearningResources>(
    `/api/v2/learning_resources/latest_viewed/`
  );
}

export function getLearningResourcesByCategory(params = {}, category: string) {
  return axios.get<PaginatedLearningResources>(
    `/api/v2/learning_resources/by_category?category_id=${category}`,
    {
      params: params
    }
  );
}

export function getLearningResourceCategories() {
  return axios.get(`/api/v2/tags/learning_resource_categories/`);
}

export function getRecommendedLearningResources() {
  return axios.get<PaginatedRecommentations>(`/api/v2/recommendations/`);
}

export function getPlaylistItems(playlistId: number) {
  return axios.get(`/api/v2/playlists/${playlistId}/`);
}

export function completePlaylistTrack(
  playlistId: number,
  learningTrack: number
) {
  return axios.put(
    `/api/v2/playlists/${playlistId}/learning_tracks/${learningTrack}`
  );
}

export function startPlaylistTrack(playlistId: number) {
  return axios.post(`/api/v2/playlists/${playlistId}/start`);
}

export function getClassroomScores(educatorId: number, ageLevel: string) {
  return axios.get(
    `/api/v2/learning_resources/improvements?q=&page=1&per_page=10&age_level=${ageLevel}&account_id=2&educator_id=${educatorId}`
  );
}
