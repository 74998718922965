import messages from './messages';
import i18n from 'helpers/i18n';
import { updateCycleMetaData } from 'actions/envScales/observationCycles';
import { MetaDataCategory } from 'types/api/envScales/MetaDataCategory';
import { SectionWithBorder } from 'components/Measure/View/Styled';
import { useMediaQuery } from 'react-responsive';
import { Box } from 'components/Box';
import { useState } from 'react';
import Typography from 'components/Typography';
import { ObservationCycle } from 'types/api/envScales/ObservationCycle';
import { MetaDataAnswer } from 'types/api/envScales/MetaDataAnswer';
import {
  Title,
  TitleBox,
  LineDivider,
  Grid,
  CheckboxInput,
  CollapseWrapper,
  TextRequired
} from './Styled';
import Question from './Question';
import { Flex } from 'components/Flex';
import {
  addValue,
  FormatChoice,
  getInitialState,
  removeValue,
  updateSelectedFormat,
  updateContentAnswers
} from './utils';
import LabelsBox from './LabelsBox';
import { useCyclesErrors } from 'context/CyclesErrorsContext';
import { ContentAndFormat2ndEditionError } from 'types/api/envScales/CyclesErrors';
import { Icon } from 'semantic-ui-react';
import * as R from 'ramda';

function displayTitle(title: string) {
  if (!title) {
    return '';
  }

  const [text, textInParentheses] = title.split('(');
  return (
    <>
      {text}{' '}
      {textInParentheses && <Typography>({textInParentheses}</Typography>}
    </>
  );
}

interface MetaDataCategoryFormProps {
  categories: MetaDataCategory[];
  cycle: ObservationCycle;
  observationId: number;
}

function MetaDataCategoryForm({
  categories,
  cycle,
  observationId
}: MetaDataCategoryFormProps) {
  const [metaDataAnswers, setMetaDataAnswers] = useState<MetaDataAnswer[]>(
    cycle.meta_data_answers
  );
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const contentCategory = categories.find(
    category => category.t_name === 'content'
  );
  const formatCategory = categories.find(
    category => category.t_name === 'format'
  );
  const choices = contentCategory?.choices;
  const formatChoices = formatCategory?.choices;
  const [metaDataValues, setMetaDataValues] = useState<FormatChoice>(
    getInitialState(choices, cycle.cycle_meta_data_values)
  );

  const { cyclesErrors, updateCycleErrors } = useCyclesErrors();

  function handleRadioInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (
      event.currentTarget.dataset.questionId === undefined ||
      event.currentTarget.dataset.choiceId === undefined ||
      event.currentTarget.dataset.isFirstQuestion === undefined
    ) {
      return;
    }

    const questionId = parseInt(event.currentTarget.dataset.questionId);
    const choiceId = parseInt(event.currentTarget.dataset.choiceId);
    const isFirstQuestion =
      event.currentTarget.dataset.isFirstQuestion === 'true';
    const value = parseInt(event.currentTarget.value);

    const payload = {
      meta_data: {
        meta_data_category_id: contentCategory?.id,
        question_id: questionId,
        answer: value
      }
    };
    const tempMetaDataAnswer: MetaDataAnswer = {
      meta_data_question_id: questionId,
      observation_cycle_id: cycle.id,
      value
    };
    let answers = metaDataAnswers.filter(
      ans =>
        ans.meta_data_question_id !== questionId ||
        cycle.id !== ans.observation_cycle_id
    );
    setMetaDataAnswers([...answers, tempMetaDataAnswer]);

    updateCycleMetaData(observationId, cycle.id, payload).then(({ data }) => {
      setMetaDataAnswers(data.meta_data_answers);
      setMetaDataValues(getInitialState(choices, data.cycle_meta_data_values));
    });

    if (!R.isEmpty(cyclesErrors)) {
      const choice = choices?.find(choice => choice.id === choiceId);
      const choiceName = choice ? choice.t_name : '';
      updateCycleErrors(
        updateContentAnswers(
          cyclesErrors,
          cycle.cycle_number,
          questionId,
          choiceName,
          isFirstQuestion,
          value
        )
      );
    }
  }

  function handleCheckboxInputChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (
      event.currentTarget.dataset.categoryId === undefined ||
      event.currentTarget.dataset.choiceId === undefined ||
      event.currentTarget.dataset.choiceName === undefined
    ) {
      return;
    }

    const categoryId = parseInt(event.currentTarget.dataset.categoryId);
    const choiceId = parseInt(event.currentTarget.dataset.choiceId);
    const choiceName = event.currentTarget.dataset.choiceName;
    const value = event.currentTarget.value;

    let newValues: FormatChoice = {};
    if (event.currentTarget.checked) {
      newValues = addValue(value, choiceId, metaDataValues);
    } else {
      newValues = removeValue(value, choiceId, metaDataValues);
    }
    setMetaDataValues(newValues);

    const payload = {
      meta_data: {
        meta_data_category_id: categoryId,
        meta_data_choice_id: choiceId,
        values: newValues[choiceId].map(item => {
          return { value: item.value, is_primary: false };
        })
      }
    };

    updateCycleMetaData(observationId, cycle.id, payload).then(({ data }) => {
      setMetaDataValues(getInitialState(choices, data.cycle_meta_data_values));
    });

    const hasOneSelected = newValues[choiceId].length > 0;

    if (!R.isEmpty(cyclesErrors)) {
      updateCycleErrors(
        updateSelectedFormat(
          cyclesErrors,
          cycle.cycle_number,
          hasOneSelected,
          choiceName
        )
      );
    }
  }

  return (
    <>
      <div>
        <Title>{contentCategory!.name}</Title>
      </div>

      <Box mt="12px" mb="27px">
        {contentCategory!.description}
      </Box>

      {choices?.map(choice => {
        const firstQuestion = choice.meta_data_questions[0];
        const firstAnswer = metaDataAnswers.find(
          answer =>
            answer.meta_data_question_id === firstQuestion.id &&
            cycle.id === answer.observation_cycle_id
        );

        const isExpanded = firstAnswer && firstAnswer.value > 1;

        const metaDataCategoriesErrors = cyclesErrors[cycle.cycle_number]
          ?.meta_data_categories as ContentAndFormat2ndEditionError;
        const hasError =
          metaDataCategoriesErrors?.content_and_format[choice.t_name];

        let noQuestionsAnswered = false;
        let someQuestionNotAnswered = false;
        if (hasError) {
          noQuestionsAnswered = Object.values(hasError.questions).every(
            question => question !== null
          );
          someQuestionNotAnswered = Object.values(hasError.questions).some(
            question => question !== null
          );
        }

        const hasFormatError =
          hasError !== undefined && hasError.formats !== null;

        return (
          <SectionWithBorder
            mt="12px"
            key={choice.id}
            error={noQuestionsAnswered}
          >
            <Box>
              <TitleBox>
                <Title>
                  {choice.name}
                  {someQuestionNotAnswered && (
                    <TextRequired>
                      <Typography color="red">
                        {i18n.ft(messages.errors.required)}
                        {isDesktop && (
                          <Icon name="warning circle" color="red" />
                        )}
                      </Typography>
                    </TextRequired>
                  )}
                </Title>

                {isDesktop && <LabelsBox />}
              </TitleBox>

              <Question
                question={firstQuestion}
                showLabels={!isDesktop}
                handleRadioInputChange={handleRadioInputChange}
                answer={firstAnswer}
                error={
                  firstQuestion &&
                  hasError !== undefined &&
                  hasError.questions[firstQuestion.id] !== null
                }
                isFirstQuestion
              />

              <CollapseWrapper
                className={isExpanded ? 'max-h-[100rem]' : 'max-h-0'}
              >
                <div className="pb-4">
                  <div>
                    {choice.meta_data_questions.map((question, index) => {
                      const answer = metaDataAnswers.find(
                        answer =>
                          answer.meta_data_question_id === question.id &&
                          cycle.id === answer.observation_cycle_id
                      );
                      const questionHasError =
                        hasError !== undefined &&
                        hasError.questions[question.id] !== null;

                      return (
                        index > 0 && (
                          <Question
                            key={question.id}
                            question={question}
                            showLabels={!isDesktop}
                            handleRadioInputChange={handleRadioInputChange}
                            answer={answer}
                            error={questionHasError}
                            isFirstQuestion={false}
                          />
                        )
                      );
                    })}
                  </div>

                  <div>
                    <LineDivider />

                    <Box mt="35px">
                      <TitleBox>
                        <Title>
                          {displayTitle(formatCategory?.description || '')}

                          {hasFormatError && (
                            <TextRequired>
                              <Typography color="red">
                                {hasError.formats}{' '}
                                <Icon name="warning circle" color="red" />
                              </Typography>
                            </TextRequired>
                          )}
                        </Title>
                      </TitleBox>
                    </Box>

                    <Grid>
                      {formatChoices &&
                        metaDataValues[choice.id] &&
                        formatChoices.map(formatChoice => {
                          const checkBoxInputIdentifier = `${choice.id}_${formatChoice.id}`;
                          const isChecked = !!metaDataValues[choice.id].find(
                            item => item.value === formatChoice.t_name
                          );

                          return (
                            <Flex align="center" key={checkBoxInputIdentifier}>
                              <CheckboxInput
                                type="checkbox"
                                id={checkBoxInputIdentifier}
                                name={checkBoxInputIdentifier}
                                onChange={handleCheckboxInputChange}
                                data-choice-id={choice.id}
                                data-choice-name={choice.t_name}
                                data-category-id={formatCategory.id}
                                value={formatChoice.t_name}
                                checked={isChecked}
                              />
                              <label htmlFor={checkBoxInputIdentifier}>
                                {formatChoice.name}
                              </label>
                            </Flex>
                          );
                        })}
                    </Grid>
                  </div>
                </div>
              </CollapseWrapper>
            </Box>
          </SectionWithBorder>
        );
      })}
    </>
  );
}

export default MetaDataCategoryForm;
