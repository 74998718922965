const PATH = 'fe.components.activate_product_key';

const messages = {
  productKey: {
    key: `${PATH}.product_key`,
    defaultValue: 'Product Key'
  },
  activateProductKey: {
    key: `${PATH}.activate_product_key`,
    defaultValue: 'Activate Product Key'
  },
  activate: {
    key: `${PATH}.activate`,
    defaultValue: 'Activate'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  errors: {
    required: {
      key: `${PATH}.errors.required`,
      defaultValue: 'Please enter a product key'
    },
    ineligible: {
      key: `${PATH}.errors.ineligible`,
      defaultValue:
        'You are ineligible to use this product key. Please contact Customer Support.'
    },
    unknownError: {
      key: `${PATH}.errors.unknown_error`,
      defaultValue:
        'Error activating product key, please contact customer support or try again.'
    },
    noAvailable: {
      key: `${PATH}.errors.no_available`,
      defaultValue:
        'This product key is no longer available. Please contact Customer Support for assistance.'
    }
  }
};

export default messages;
