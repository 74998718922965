import { Wrapper } from './Styled';
import { DatePickerBase } from './DatePickerBase';
import { ReactDatePickerProps } from 'react-datepicker';

function DatePicker(props: ReactDatePickerProps) {
  return (
    <Wrapper>
      <DatePickerBase {...props} />
    </Wrapper>
  );
}

export default DatePicker;
