import i18n from 'helpers/i18n';
import { useEffect } from 'react';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { useRouteParams } from 'hooks/useRouteParams';
import PageHeader from 'components/PageHeader';
import PageLoader from 'components/PageLoader';
import { useMediaQuery } from 'react-responsive';
import MainContent from 'components/MainContent';
import ParticipantSection from './ParticipantSection';
import PageBackground from 'components/PageBackground';
import ResultSection from '../ScoreReport/ResultSection';
import PrintGlobalStyle from 'components/PrintGlobalStyle';
import { getTrainerViewScores } from 'actions/testAttempts';
import DimensionSection from '../ScoreReport/DimensionsSection';
import BottomSection from 'components/ReliabilityTest/BottomSection';
import logoCertification from 'images/reliabilityTest/logoCertification.svg';
import PerformanceReportSection from '../ScoreReport/PerformanceReportSection';
import { TrainerViewTestAttempt } from 'types/api/TestAttempt';
import { DomainReliability } from '../ScoreReport/DimensionsSection/DimensionSection';

type URLParams = {
  id: string;
};

function TrainerViewTestAttempts() {
  const { id } = useRouteParams<URLParams>();
  const { data, run, isPending } = useAsync<TrainerViewTestAttempt>();
  const isTablet = useMediaQuery({ minWidth: 640 });

  useEffect(() => {
    run(getTrainerViewScores(Number(id)));
  }, [run, id]);

  if (isPending) {
    return <PageLoader />;
  }

  return (
    <>
      <PrintGlobalStyle />
      <MainContent maxWidth={1280}>
        <PageHeader
          title={data.title}
          subtitle={isTablet ? i18n.ft(messages.subtitle) : ''}
          image={logoCertification}
          imageAlt={i18n.ft(messages.logoDescription)}
        />
        <PageBackground borderStyle className="PrintBackgroundColor">
          <ParticipantSection
            name={data.participant}
            test={data.title}
            trainingDate={data.training_date}
            result={data.status}
            attempt={data.attempt}
            testCompletion={data.completed_at}
          />
          <ResultSection
            result={data.status}
            testType={data.test_type}
            title={data.title}
            testScore={data.test_score}
            {...(data.categories ? { categories: data.categories } : {})}
            {...(data.two_of_five_per_dimension
              ? { dimensions: data.two_of_five_per_dimension }
              : {})}
            {...(data.three_of_six_per_category
              ? { threeOfSix: data.three_of_six_per_category }
              : {})}
            attemptNumber={data.attempt}
            trainerView
          />
          <PerformanceReportSection scores={data.videos_score || []} />
          {data.test_type !== 'knowledge' ? (
            <DimensionSection
              domains={data.domains as unknown as DomainReliability[]}
              scores={data.videos_score || []}
              testType={'reliability'}
            />
          ) : null}
          <BottomSection
            status={data.status}
            attemptNumber={data.attempt}
            testType={data.test_type}
          />
        </PageBackground>
      </MainContent>
    </>
  );
}

export default TrainerViewTestAttempts;
