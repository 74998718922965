import i18n from 'helpers/i18n';
import messages from './messages';
import RoutesHelper from 'helpers/routes';
import { useNavigate } from 'react-router-dom';
import { createReportConfiguration } from 'actions/envScales/reports';

import ReportConfigurationForm, {
  FormValues,
  ReportConfig
} from 'pages/Reports/ObservationV2';

function CreateReportConfiguration() {
  const navigate = useNavigate();

  const initialValues: FormValues[] = [
    {
      ageLevel: null,
      startDate: null,
      endDate: null,
      nodeIds: []
    }
  ];

  async function handleSubmit(values: ReportConfig) {
    await createReportConfiguration(values);
    navigate(RoutesHelper.getPath('reports-observation'));
  }

  return (
    <ReportConfigurationForm
      title={i18n.ft(messages.createReport)}
      subtitle={i18n.ft(messages.fillSections)}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  );
}

export default CreateReportConfiguration;
