const PATH = 'fe.components.learning_resources.classroom_scores';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: "Based on {{educator}}'s Classroom Scores"
  },
  all: {
    key: `${PATH}.all`,
    defaultValue: 'See All'
  }
};

export default messages;
