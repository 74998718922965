import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import PageLoader from 'components/PageLoader';
import { useQuery } from '@tanstack/react-query';
import { useCurrentAccountByArea } from 'pages/Reports/Observation';
import { CertificationsByStatus } from './CertificationsByStatus';
import { CertificationsByAgeLevel } from './CertificationsByAgeLevel';
import { getCertificationsByStatus } from 'actions/certificationDashboard';

interface ObserversByAgeLevelProps {
  account: number;
}

function useGetCertificationsByStatus() {
  const account = useCurrentAccountByArea('CertificationsDashboard');
  const accountId = account?.id ?? 0;

  return useQuery({
    enabled: Boolean(accountId),
    queryKey: [
      'certificationDashboard',
      'getCertificationsByStatus',
      accountId
    ],
    queryFn: () => getCertificationsByStatus({}).then(res => res.data)
  });
}

export function ObserversByAgeLevel({ account }: ObserversByAgeLevelProps) {
  const [ageLevelId, setAgeLevelId] = useState<number | null>(null);
  const [classVersion, setClassVersion] = useState<string | null>(null);
  const { data, isPending, isSuccess } = useGetCertificationsByStatus();

  if (isPending) {
    return <PageLoader />;
  }

  if (isSuccess && data.count === 0) {
    return (
      <div className="pt-10 pb-8 bg-white rounded-lg shadow-lg text-center">
        <div>{i18n.ft(messages.noObservers)}</div>
        <div>{i18n.ft(messages.noObserversSub)}</div>
        <div className="mt-6">
          <span>{i18n.ft(messages.noObserversLink)}</span>

          <a
            href="https://teachstone.com/measure/"
            className="text-inherit pointer"
          >
            <span className="mx-1 underline font-bold">
              {i18n.ft(messages.noObserversHere)}
            </span>
            <Icon name="external" />
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="grid xl:grid-cols-2 gap-6">
      <CertificationsByAgeLevel
        ageLevelId={ageLevelId}
        classVersion={classVersion}
        onChange={(ageLevelId, classVersion) => {
          setAgeLevelId(ageLevelId);
          setClassVersion(classVersion);
        }}
        account={account}
      />

      <CertificationsByStatus
        ageLevelId={ageLevelId}
        classVersion={classVersion}
        account={account}
        dataGeneral={data}
      />
    </div>
  );
}
