import { Outlet } from 'react-router-dom';
import SubscriptionRoute from 'components/Subscription';

function Measure() {
  return (
    <SubscriptionRoute product="data_collection">
      <Outlet />
    </SubscriptionRoute>
  );
}

export default Measure;
