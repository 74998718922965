import colors from 'styles/colors';
import i18n from 'helpers/i18n';
import messages from './messages';
import { VideoIcon, PdfIcon, PreviewImage } from './Styled';
import { Attachment } from 'types/api/envScales/Attachment';
import axios, { CancelTokenSource } from 'axios';
import { VideoAttachment } from 'types/api/envScales/Observation';
import RouteHelpers from 'helpers/routes';
import { isVideo, isImage } from 'helpers/file';

export type AttachmentWithStatus = Attachment & {
  status?: 'ok' | 'error' | 'uploading' | null;
  cancelTokenSource?: CancelTokenSource;
};

export const STATUS_COLORS = {
  ok: colors.scaleGreen,
  error: colors.scaleWarning,
  uploading: 'white'
};

export const STATUS_TEXT_COLORS = {
  ok: colors.scaleGreen,
  error: colors.scaleWarning,
  uploading: colors.scaleDarkBlue
};

export const STATUS_TEXT = {
  ok: i18n.ft(messages.successfullyUploaded),
  error: i18n.ft(messages.errorOccured),
  uploading: i18n.ft(messages.uploading)
};

export const STATUS_ICONS = {
  ok: 'check',
  error: 'exclamation',
  uploading: 'cloud upload'
};

export function getPreview(attachment: Attachment) {
  if (isImage(attachment.content_type)) {
    return <PreviewImage src={attachment.thumb_url} alt={attachment.name} />;
  }
  if (isVideo(attachment.content_type)) {
    return <VideoIcon />;
  }
  return <PdfIcon />;
}

export function formatUploadingAttachment(file: File): AttachmentWithStatus {
  const thumb_url = isImage(file.type) ? URL.createObjectURL(file) : '';

  return {
    id: 0,
    name: file.name,
    status: 'uploading',
    attachment_url: '',
    thumb_url: thumb_url,
    content_type: file.type,
    cancelTokenSource: axios.CancelToken.source()
  };
}

export function formatSavedVideo(
  video: VideoAttachment,
  assessmentId: number
): AttachmentWithStatus {
  const urlParams = { id: assessmentId, videoId: video.id };
  const attachment_url = RouteHelpers.getPath('measure-view-video', urlParams);

  return {
    id: video.id,
    name: video.name || 'video ' + video.id,
    attachment_url: attachment_url,
    content_type: 'video/*',
    status: 'ok',
    created_at: video.created_at
  };
}
