import React from 'react';
import { ImprovedObservedGeneralCue } from 'types/api/improvement/ImprovedObservedGeneralCue';
import i18n from 'helpers/i18n';
import messages from 'pages/Improvements/EducatorProfile/Reflect/messages';
import { ImprovementObservedFocusCue } from 'types/api/improvement/ImprovementObservedFocusCue';

interface CueBoxProps {
  focusCues?: ImprovementObservedFocusCue[];
  generalCues?: ImprovedObservedGeneralCue[];
  textBlock?: string;
  title: string;
}

function ObservedCueBox({
  focusCues,
  generalCues,
  title,
  textBlock = i18n.ft(messages.noResponseProvided)
}: CueBoxProps) {
  const cues = focusCues ? focusCues : generalCues;

  if (focusCues || generalCues) {
    return (
      <div className="border border-[#808183] rounded-lg p-6">
        <h4 className="!mt-0">{title}</h4>
        <ul>
          {cues?.map((cue: any) => (
            <li key={cue.id} className="list-none">
              <span
                className={
                  'fa-solid fa-sm pr-2 fa-fw ' +
                  (cue.observed ? 'fa-check' : '')
                }
              />
              {cue.description}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="border border-[#808183] rounded-lg p-6">
      <h4 className="!mt-0">Action Plan Cues</h4>
      <p>{textBlock}</p>
    </div>
  );
}

export default ObservedCueBox;
