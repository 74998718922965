import styled from 'styled-components';

export const ModalIcon = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalTitle = styled.div`
  font-weight: bold;
  text-align: center;
  margin-top: 24px;
`;

export const ModalBody = styled.div`
  padding: 16px;
  background-color: #ffffff;
  border: 2px solid #d9d9d9;
`;

export const ModalErrors = styled.div`
  margin-top: 24px;
  padding: 0 32px;
`;

export const ModalActions = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: center;
`;
