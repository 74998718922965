import Title from './Title';
import Banner from './Banner';
import Header from '../Header';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Outlet } from 'react-router-dom';
import ShowResourcesModal from '../ShowResourcesModal';
import BodyModal from '../ShowResourcesModal/BodyModal';
import { toastError, toastSuccess } from 'components/Toast';
import { createRecommendations } from 'actions/recommendations';
import { useCallback, useContext, useEffect, useState } from 'react';
import SelectEducatorBodyModal from '../ShowResourcesModal/SelectEducatorBodyModal';
import { LearningResourcesContext } from 'pages/LearningResources/LearningResourcesContext';

type Recommendations = {
  author_id?: number;
  recipient_id: any;
  resourceable_id: number;
  resourceable_type: string;
};

interface LearningResourcesHeaderProps {
  userId?: number;
}

function LearningResourcesHeader({ userId }: LearningResourcesHeaderProps) {
  const [step, setStep] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const context = useContext(LearningResourcesContext);

  if (!context) {
    throw new Error(
      'LearningResourcesHeader must be used within a Learning Resources Provider'
    );
  }

  const {
    recommendedVideosArray,
    setRecommendedVideosArray,
    selectedTeachersArray,
    setSelectedTeachersArray,
    setIsPending
  } = context;

  const createRecommendationsToSelectedUsers = useCallback(() => {
    setIsPending(true);
    const recommendations: Recommendations[] = [];
    selectedTeachersArray.forEach(teacher => {
      recommendedVideosArray.forEach(video => {
        recommendations.push({
          author_id: userId,
          recipient_id: teacher,
          resourceable_id: video.id,
          resourceable_type: 'learning_resource'
        });
      });
    });

    createRecommendations(recommendations)
      .then(() => {
        toastSuccess({ description: i18n.ft(messages.success) });
        setStep(0);
        setShowConfirmationModal(false);
        setRecommendedVideosArray([]);
        setSelectedTeachersArray([]);
        setIsPending(false);
      })
      .catch(error => {
        toastError(error);
        setStep(1);
        setSelectedTeachersArray([]);
        setIsPending(false);
      });
  }, [
    recommendedVideosArray,
    selectedTeachersArray,
    setIsPending,
    setRecommendedVideosArray,
    setSelectedTeachersArray,
    userId
  ]);

  useEffect(() => {
    if (step === 2) {
      createRecommendationsToSelectedUsers();
    }
  }, [createRecommendationsToSelectedUsers, step]);

  useEffect(() => {
    if (recommendedVideosArray.length === 0) {
      setShowConfirmationModal(false);
    }
  }, [recommendedVideosArray]);

  return (
    <>
      <ShowResourcesModal
        title={
          <Title
            step={step}
            setStep={setStep}
            recommendedVideosCount={recommendedVideosArray.length}
            setShowConfirmationModal={setShowConfirmationModal}
          />
        }
        message={step === 0 ? <BodyModal /> : <SelectEducatorBodyModal />}
        confirmButtonString={
          step === 0 ? i18n.ft(messages.next) : i18n.ft(messages.send)
        }
        isOpen={showConfirmationModal}
        onConfirm={() => setStep(prev => prev + 1)}
        onCancel={() => setShowConfirmationModal(false)}
        onClose={() => setShowConfirmationModal(false)}
        step={step}
      />

      {recommendedVideosArray.length > 0 && (
        <Banner
          recommendedVideosCount={recommendedVideosArray.length}
          setRecommendedVideosArray={setRecommendedVideosArray}
          setShowConfirmationModal={setShowConfirmationModal}
        />
      )}
      <main
        className=" mx-auto my-10 px-4 2xl:px-0"
        style={{ maxWidth: '1360px' }}
      >
        <div className="px-4 md:px-0">
          <Header />
        </div>
        <Outlet />
      </main>
    </>
  );
}

export default LearningResourcesHeader;
