import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import MainContent from 'components/MainContent';
import { useMediaQuery } from 'react-responsive';
import { Button, Loader } from 'semantic-ui-react';
import Carousel from 'components/Carousel/Carousel';
import PageBackground from 'components/PageBackground';
import { getPrepResources } from 'actions/testTemplates';
import exitTest from 'images/reliabilityTest/exitTest.svg';
import TestPrepResourcesList from './TestPrepResourcesList';
import instructionsIcon from 'images/reliabilityTest/instructionsIcon.svg';
import logoCertification from 'images/reliabilityTest/logoCertification.svg';
import {
  StyledButton,
  ActionWrapper,
  SectionActions,
  ContainerSection as Section
} from 'components/Reports/Reliability/View/Styled';

interface ResourcesProps {
  testTemplateId: number;
  title?: string;
  handlePreTestState?: (value: string) => void;
  practiceBeforeRenew?: boolean;
  attemptNumber?: string | null;
  attemptId?: string | null;
}

function Resources({
  testTemplateId,
  title,
  handlePreTestState,
  practiceBeforeRenew,
  attemptNumber,
  attemptId
}: ResourcesProps) {
  const { run, data, isPending } = useAsync();
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    run(getPrepResources(testTemplateId, attemptId ? Number(attemptId) : null));
  }, [run, testTemplateId, attemptId]);

  function acceptResources() {
    if (handlePreTestState) {
      handlePreTestState('instructions');
    } else if (attemptId) {
      return navigate(
        RouteHelpers.getPath('test-attempts', {
          id: attemptId
        })
      );
    } else {
      return navigate(RouteHelpers.getPath('app'));
    }
  }

  function handleClick() {
    return navigate(RouteHelpers.getPath('app'));
  }

  if (isPending) {
    return <Loader active size="large" inline="centered" />;
  }

  return (
    <MainContent maxWidth={1080}>
      <PageHeader
        title={practiceBeforeRenew ? data.certification_name : title}
        subtitle={i18n.ft(messages.subtitle)}
        image={logoCertification}
        imageAlt={i18n.ft(messages.logoDescription)}
      />
      <PageBackground borderStyle>
        <Section
          mt={{ _: '0px', lg: '32px' }}
          mx={{ _: '0px', lg: '32px' }}
          borderTopRadius={isTablet ? '12px' : '0px'}
        >
          <div className="bg-white p-6 rounded-t-xl md:p-12 md:mb-1 lg:mb-1">
            <div className="flex my-5">
              <img className="w-5 md:w-9" src={instructionsIcon} alt="" />
              <span className="text-xl ml-3 font-bold self-center">
                {i18n.ft(messages.resourcesTitle)}
              </span>
            </div>

            <TestPrepResourcesList
              videoLibraryURL={data.video_library_url}
              observerVillageURL={data.observer_village_url}
            />

            {data.coding_videos?.training && (
              <Carousel
                title={i18n.ft(messages.training)}
                videos={data.coding_videos.training}
                ariaLabel={i18n.ft(messages.training)}
                testTemplateId={testTemplateId}
              />
            )}
            {data.coding_videos?.practice && (
              <Carousel
                title={i18n.ft(messages.practice)}
                videos={data.coding_videos.practice}
                ariaLabel={i18n.ft(messages.practice)}
                testTemplateId={testTemplateId}
              />
            )}
          </div>
        </Section>
        <Section
          mb={{ _: '0px', lg: '32px' }}
          mx={{ _: '0px', lg: '32px' }}
          borderBottomRadius={isTablet ? '12px' : '0px'}
        >
          <div className="p-6 lg:m-1">
            <div className="p-6 rounded-b-xl lg:m-1">
              <SectionActions>
                <ActionWrapper>
                  <StyledButton
                    basic
                    fluid
                    content={
                      <div className="cursor-pointer flex items-center">
                        <img
                          src={exitTest}
                          alt={i18n.ft(messages.exitTest)}
                          className="mr-5"
                        />
                        <span className="m-auto whitespace-nowrap">
                          {i18n.ft(messages.exitTest)}
                        </span>
                      </div>
                    }
                    onClick={handleClick}
                  />
                </ActionWrapper>
                <ActionWrapper>
                  {practiceBeforeRenew && !attemptId ? null : (
                    <Button
                      fluid
                      content={
                        attemptNumber
                          ? i18n.ft(messages.attemptNumber, {
                              number: attemptNumber
                            })
                          : i18n.ft(messages.next)
                      }
                      color="blue"
                      className="w-full whitespace-nowrap"
                      onClick={() => acceptResources()}
                    />
                  )}
                </ActionWrapper>
              </SectionActions>
            </div>
          </div>
        </Section>
      </PageBackground>
    </MainContent>
  );
}

export default Resources;
