import { useEffect } from 'react';
import { PageContainer } from './Styled';
import OnboardingManager from 'components/Onboarding';
import { updateUserMeSettings } from 'actions/userMe';

function Onboarding() {
  useEffect(() => {
    updateUserMeSettings({
      user_setting: {
        onboarding_viewed_at: new Date().toUTCString()
      }
    });
  }, []);

  return (
    <PageContainer>
      <OnboardingManager />
    </PageContainer>
  );
}
export default Onboarding;
