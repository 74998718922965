import styled, { css } from 'styled-components';
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';

export const Container = styled.div`
  padding: 16px;
  background-color: #f3f9fc;
  border: 1px solid #e2e2e3;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

export const Checkbox = styled(SemanticCheckbox)`
  display: block !important;
`;

export const Column = styled.div<{ size?: 'small' | 'large' }>`
  ${props =>
    props.size &&
    props.size === 'small' &&
    css`
      width: 20%;
      padding-right: 16px;
    `}

  ${props =>
    props.size &&
    props.size === 'large' &&
    css`
      width: 55%;
      padding-left: 16px;
      padding-right: 16px;

      @media screen and (max-width: 600px) {
        width: auto;
        padding-right: 0;
      }
    `}
`;
