const PATH = 'fe.components.measure.observation_feedback';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Observation Feedback'
  },
  edit: {
    key: `${PATH}.edit`,
    defaultValue: 'Edit observation Feedback'
  }
};

export default messages;
