import RouteHelpers from 'helpers/routes';
import { isNil, isEmpty } from 'ramda';
import { Loader } from 'semantic-ui-react';
import PageLayout from 'components/PageLayout';
import { getTraining, createCart } from 'actions/trainings';
import React, { useEffect, useState } from 'react';
import { Layout, ColumnLeft, ColumnRight } from './Styled';
import { useNavigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import BillingForm from 'components/Trainings/View/CardCheckout/BillingForm';
import OrderSummary from 'components/Trainings/View/CardCheckout/OrderSummary';
import ShippingForm from 'components/Trainings/View/CardCheckout/ShippingForm';
import ShippingMethodForm from 'components/Trainings/View/CardCheckout/ShippingMethodForm';
import PaymentMethodForm from 'components/Trainings/View/CardCheckout/PaymentMethodForm';

import CouponForm from 'components/Trainings/View/CardCheckout/CouponForm';
import { Address as BCAddress } from 'types/BigCommerce/Address';
import { Checkout as BCCheckout } from 'types/BigCommerce/Checkout';
import { Consignment as BCConsignment } from 'types/BigCommerce/Consignment';
import useQuery from 'helpers/useQuery';
import useAsync from 'hooks/useAsync';

type URLParams = {
  id: string;
};

function useParticipants(): number[] {
  const { participants } = useQuery();

  // Must useState so that the createCart effect is not called
  // on every render.
  const [participantsState] = useState(() => {
    if (isNil(participants)) {
      return [];
    } else if (typeof participants === 'string') {
      return [parseInt(participants)];
    } else {
      return participants.map(p => Number(p));
    }
  });

  return participantsState;
}

const CardCheckout: React.FC = () => {
  const participants = useParticipants();
  const navigate = useNavigate();
  const params = useRouteParams<URLParams>();
  const trainingId = parseInt(params.id);
  const [step, setStep] = useState(1);
  const [billingAddress, setBillingAddress] = useState<BCAddress>();
  const [consignment, setConsignment] = useState<BCConsignment>();
  const [checkout, setCheckout] = useState<BCCheckout>();
  const { run: trainingRun, data: training, ...trainingState } = useAsync();
  const { run: cartRun, data: cart, ...cartState } = useAsync();

  // Get and set the training information.
  useEffect(() => {
    trainingRun(getTraining(trainingId));
  }, [trainingId, trainingRun]);

  // Create big commerce cart unless there are no participants.
  useEffect(() => {
    if (isEmpty(participants)) {
      return;
    }

    cartRun(createCart(trainingId, participants));
  }, [trainingId, cartRun, participants]);

  if (isEmpty(participants)) {
    navigate(
      RouteHelpers.getPath('td-trainings-view-details', { id: trainingId }),
      {
        replace: true
      }
    );
  }

  if (trainingState.isPending || cartState.isPending) {
    return (
      <PageLayout>
        <Loader active inline="centered" />
      </PageLayout>
    );
  }

  function handleBilling(address: BCAddress, checkout: BCCheckout) {
    setBillingAddress(address);
    setCheckout(checkout);
  }

  function handleShipping(consignment: BCConsignment, checkout: BCCheckout) {
    setConsignment(consignment);
    setCheckout(checkout);
  }

  function handleShippingMethod(checkout: BCCheckout) {
    setCheckout(checkout);
  }

  function handleCouponCode(checkout: BCCheckout) {
    setCheckout(checkout);
  }

  function handlePayment() {
    navigate(
      RouteHelpers.getPath('td-trainings-view-checkout-completed', {
        id: trainingId
      }),
      {
        state: { email: billingAddress?.email },
        replace: true
      }
    );
  }

  return (
    <PageLayout>
      <Layout>
        <ColumnLeft>
          <BillingForm
            step={1}
            activeStep={step}
            onStepChange={step => setStep(step)}
            trainingId={trainingId}
            afterSubmit={handleBilling}
          />
          <ShippingForm
            step={2}
            activeStep={step}
            onStepChange={step => setStep(step)}
            trainingId={trainingId}
            afterSubmit={handleShipping}
            billingAddress={billingAddress}
          />
          <ShippingMethodForm
            step={3}
            activeStep={step}
            onStepChange={step => setStep(step)}
            trainingId={trainingId}
            consignment={consignment}
            afterSubmit={handleShippingMethod}
          />
          <PaymentMethodForm
            step={4}
            activeStep={step}
            trainingId={trainingId}
            cart={cart}
            checkout={checkout}
            afterSubmit={handlePayment}
          />
        </ColumnLeft>
        <ColumnRight>
          <OrderSummary
            training={training}
            participants={participants}
            cart={cart}
            checkout={checkout}
          />
          <CouponForm trainingId={trainingId} afterSubmit={handleCouponCode} />
        </ColumnRight>
      </Layout>
    </PageLayout>
  );
};

export default CardCheckout;
