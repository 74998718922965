import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'components/Typography';
import styled from 'styled-components';
import i18n from 'helpers/i18n';
import messages from './messages';

interface ResultSummaryProps {
  visible: number;
  total: number;
}

const Wrapper = styled.div`
  margin: 0 0 8px 0;
  display: flex;
  flex-wrap: wrap;
`;

function ResultSummary({ visible, total }: ResultSummaryProps) {
  return (
    <Wrapper>
      <Typography
        content={i18n.ft(messages.resultsSummary.searchResults)}
        bold
        append
        color="blue"
      />
      <Typography
        content={i18n.ft(messages.resultsSummary.showingCount, {
          visible,
          total
        })}
      />
    </Wrapper>
  );
}

ResultSummary.propTypes = {
  total: PropTypes.number.isRequired,
  visible: PropTypes.number.isRequired
};

export default ResultSummary;
