const PATH = 'fe.pages.invitations.view';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Accept Invitation'
  },
  invited: {
    key: `${PATH}.invited`,
    defaultValue: '{{account}} invited you to access {{product}}.'
  },
  lookFor: {
    key: `${PATH}.look_for`,
    defaultValue:
      'After accepting look for the above icon on your dashboard to access the service.'
  },
  accessDesc: {
    key: `${PATH}.access_desc`,
    defaultValue: 'What your organization can see and access:'
  },
  profile: {
    key: `${PATH}.profile`,
    defaultValue: 'Profile Information'
  },
  productActivity: {
    key: `${PATH}.product_activity`,
    defaultValue: '{{product}} Data and Activity'
  },
  accept: {
    key: `${PATH}.accept`,
    defaultValue: 'Accept Invitation'
  }
};

export default messages;
