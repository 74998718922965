import styled from 'styled-components';

export const Percentage = styled.div<{ color: string }>`
  font-size: 36px;
  font-weight: bold;
  color: ${props => props.color};
  width: 100px;
  text-align: center;
  position: absolute;
  top: 67px;
  left: 25px;
`;

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;
