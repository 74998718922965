import moment from 'moment';
import i18nJS from 'i18n-js';
import { map, range } from 'ramda';

// Returns the months of the year for the card expiration month dropdown.
export function expirationMonths() {
  return map(month => {
    const momentDate = moment(month, 'M');

    return { key: month, value: month, text: momentDate.format('MM - MMM') };
  }, range(1, 13));
}

// Returns the next 10 years for the card expiration year dropdown.
export function expirationYears() {
  const currentYear = moment().year();

  return map(year => {
    return { key: year, value: year, text: year };
  }, range(currentYear, currentYear + 10));
}

export function getGrandTotal(checkout) {
  return i18nJS.toCurrency(checkout.grand_total);
}
