const PATH = 'fe.components.trainings.view.participants.cancel_modal';

const messages = {
  areYouSure: {
    key: `${PATH}.are_you_sure`,
    defaultValue:
      'Are you sure you want to mark the selected participant(s) as canceled?'
  },
  dismiss: {
    key: `${PATH}.dismiss`,
    defaultValue: 'Dismiss'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Yes, Cancel Participant(s)'
  }
};

export default messages;
