const PATH = 'fe.components.form_dropdown';

const messages = {
  notProvided: {
    key: `${PATH}.not_provided`,
    defaultValue: 'Not provided'
  }
};

export default messages;
