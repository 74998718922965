import moment from 'moment';
import { Recommendation } from 'types/api/Recommendation';
import { UserEnrollment } from 'types/api/UserEnrollment';
import * as R from 'ramda';

export function getVisibleEnrollments(enrollments: UserEnrollment[]) {
  let visibleEnrollments = [];
  const aMonthAgo = moment().subtract(1, 'month').format('YYYY-MM-DD');

  for (let enrollment of enrollments) {
    if (enrollment.sis_course?.type === 'TotaraCourse') {
      continue;
    }

    if (enrollment.suspended_at) {
      continue;
    }

    if (
      enrollment.completed_at &&
      moment(enrollment.completed_at).isBefore(aMonthAgo)
    ) {
      continue;
    }

    if (moment(enrollment.end_date).isBefore(aMonthAgo)) {
      continue;
    }

    if (enrollment.status === 'available' || visibleEnrollments.length < 15) {
      visibleEnrollments.push(enrollment);
    } else {
      break;
    }
  }

  return visibleEnrollments;
}

export function mapResources(recommendations: Recommendation[]) {
  const MAX_RESOURCES = 3;

  const allResources = recommendations.flatMap(recommendation => {
    const resources = recommendation.recommended_resources.map(resource => ({
      id: resource.id,
      thumbnail: resource.thumbnail,
      title: resource.title,
      user: recommendation.recommended_by.name,
      date: recommendation.created_at,
      account_id: recommendation.account_id
    }));

    return resources;
  });

  return R.take(MAX_RESOURCES, allResources);
}
