import i18n from 'helpers/i18n';
import messages from './messages';
import React, { useState } from 'react';
import useProductKey from './useProductKey';
import { Button, Input } from 'semantic-ui-react';
import { ActivateProductKeyHeader, ButtonBox, Error } from './Styled';

function ActivateProductKey() {
  const pKeyRequest = useProductKey();
  const [productKey, setProductKey] = useState('');
  const [displayForm, setDisplayForm] = useState(false);

  function clearForm() {
    setProductKey('');
    pKeyRequest.reset();
  }

  function togglePKForm() {
    clearForm();
    setDisplayForm(!displayForm);
  }

  function handleSubmit() {
    window.localStorage.removeItem('unified_token');
    pKeyRequest.submit(productKey);
  }

  return (
    <div>
      {displayForm ? (
        <React.Fragment>
          <ActivateProductKeyHeader>
            {i18n.ft(messages.activateProductKey)}
          </ActivateProductKeyHeader>

          <Input
            fluid
            size="small"
            value={productKey}
            placeholder={i18n.ft(messages.productKey)}
            onChange={(e, { value }) => setProductKey(value)}
          />

          {pKeyRequest.isError && <Error>{pKeyRequest.error}</Error>}

          <ButtonBox>
            <Button
              fluid
              size="tiny"
              disabled={pKeyRequest.isLoading}
              onClick={togglePKForm}
              content={i18n.ft(messages.cancel)}
            />
            <Button
              fluid
              size="tiny"
              color="blue"
              loading={pKeyRequest.isLoading}
              disabled={pKeyRequest.isLoading}
              onClick={handleSubmit}
              content={i18n.ft(messages.activate)}
            />
          </ButtonBox>
        </React.Fragment>
      ) : (
        <Button
          fluid
          size="tiny"
          color="blue"
          onClick={togglePKForm}
          content={i18n.ft(messages.activateProductKey)}
        />
      )}
    </div>
  );
}

export default ActivateProductKey;
