import styled from 'styled-components';

export const HeaderContainer = styled.div`
  padding: 16px 0 28px;
`;

export const PolicyContent = styled.div`
  margin: 0 auto;
  max-width: 700px;
`;
