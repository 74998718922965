import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const ColumnLeft = styled.div`
  width: calc(60% - 16px);

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const ColumnRight = styled.div`
  width: 40%;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;
