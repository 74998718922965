const PATH = 'fe.components.tokens.view.payment_request';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Order Summary'
  },
  ageLevels: {
    key: `${PATH}.age_levels`,
    defaultValue: 'Age Levels'
  },
  trainer: {
    key: `${PATH}.trainer`,
    defaultValue: 'Trainer'
  },
  sessions: {
    key: `${PATH}.sessions`,
    defaultValue: 'Sessions'
  },
  quantity: {
    key: `${PATH}.quantity`,
    defaultValue: 'Quantity'
  },
  seeTotal: {
    key: `${PATH}.see_total`,
    defaultValue: 'To see your total and pay, log in or check out as a guest.'
  },
  loginError: {
    key: `${PATH}.login_error`,
    defaultValue: 'Invalid email or password'
  },
  formTitle: {
    key: `${PATH}.form_title`,
    defaultValue: 'Log in to myTeachstone to pay'
  },
  formSubmit: {
    key: `${PATH}.form_submit`,
    defaultValue: 'Log in to pay'
  },
  or: {
    key: `${PATH}.or`,
    defaultValue: 'Or'
  },
  payAsGuest: {
    key: `${PATH}.pay_as_guest`,
    defaultValue: 'Pay as a guest'
  },
  iconAlt: {
    key: `${PATH}.icon_alt`,
    defaultValue: 'My trainings icon'
  }
};

export default messages;
