import { Subscription } from 'types/api/Subscription';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import {
  Wrapper,
  Container,
  Item,
  FirstItem,
  ActionIcon,
  SmallContainer,
  Name
} from './Styled';
import { useState } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import SubscriptionDetails from '../SubscriptionDetails';
import { useMediaQuery } from 'react-responsive';

interface SubscriptionTileProps {
  subscription: Subscription;
}

function SubscriptionTile({ subscription }: SubscriptionTileProps) {
  const [isOpen, setIsOpen] = useState(false);
  const isLessThan1280px = useMediaQuery({ maxWidth: 1280 });

  return (
    <>
      {isLessThan1280px ? (
        <>
          {!isOpen ? (
            <Wrapper>
              <SmallContainer>
                <FirstItem>
                  <ActionIcon
                    aria-label={i18n.ft(messages.seeMore, {
                      name: subscription.name
                    })}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <Icon name="plus circle" color="blue" />
                  </ActionIcon>
                  <Name>
                    <div>{subscription.name}</div>
                    <div>{subscription.product.display_name}</div>
                  </Name>
                </FirstItem>
                <Item>
                  {subscription.users}/{subscription.max_seats}
                </Item>
              </SmallContainer>
            </Wrapper>
          ) : (
            <Wrapper>
              <SmallContainer>
                <FirstItem>
                  <ActionIcon
                    aria-label={i18n.ft(messages.seeLess, {
                      name: subscription.name
                    })}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <Icon name="minus circle" color="blue" />
                  </ActionIcon>
                  <Name>
                    <div>{subscription.name}</div>
                    <div>{subscription.product.display_name}</div>
                  </Name>
                </FirstItem>
                <Item>
                  {subscription.users}/{subscription.max_seats}
                </Item>
              </SmallContainer>
              <SubscriptionDetails subscriptionId={subscription.id} />
            </Wrapper>
          )}
        </>
      ) : (
        <>
          {!isOpen ? (
            <Wrapper>
              <Container>
                <FirstItem>
                  <ActionIcon
                    aria-label={i18n.ft(messages.seeMore, {
                      name: subscription.name
                    })}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <Icon name="plus circle" color="blue" />
                  </ActionIcon>
                  {subscription.name}
                </FirstItem>
                <Item>{subscription.users}</Item>
                <Item>{subscription.product.display_name}</Item>
                <Item>
                  {subscription.end_date
                    ? moment(subscription.end_date).format('MM/DD/YYYY')
                    : ''}
                </Item>
                <Item>{subscription.max_seats}</Item>
              </Container>
            </Wrapper>
          ) : (
            <Wrapper>
              <Container>
                <FirstItem>
                  <ActionIcon
                    aria-label={i18n.ft(messages.seeLess, {
                      name: subscription.name
                    })}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <Icon name="minus circle" color="blue" />
                  </ActionIcon>
                  {subscription.name}
                </FirstItem>
                <Item>{subscription.users}</Item>
                <Item>{subscription.product.display_name}</Item>
                <Item>
                  {subscription.end_date
                    ? moment(subscription.end_date).format('MM/DD/YYYY')
                    : ''}
                </Item>
                <Item>{subscription.max_seats}</Item>
              </Container>
              <SubscriptionDetails subscriptionId={subscription.id} />
            </Wrapper>
          )}
        </>
      )}
    </>
  );
}

export default SubscriptionTile;
