import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import { useEffect, useState, Fragment } from 'react';
import Domain from 'components/Measure/Domain';
import PageLoader from 'components/PageLoader';
import MainContent from 'components/MainContent';
import { Button, Icon } from 'semantic-ui-react';
import useCurrentUser from 'hooks/useCurrentUser';
import InfoBox from 'components/Measure/View/InfoBox';
import PageHeader from 'components/Measure/PageHeader';
import PageBackground from 'components/PageBackground';
import { Formik, Form } from 'components/EnhancedForm';
import { getInitialScores, validateScores } from './utils';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import DeleteModal from 'components/Measure/Summary/DeleteModal';
import { getAssessmentScores } from 'actions/envScales/assessments';
import { AssessmentScore } from 'types/api/envScales/AssessmentScore';
import { clsx } from 'clsx';
import BackToTop from 'components/Measure/BackToTop';

import {
  PageWrapper,
  FooterWrapper,
  ReturnWrapper,
  ErrorWrapper,
  ReviewWrapper,
  ButtonsWrapper
} from './Styled';

import { Assessment } from 'types/api/envScales/Assessment';
import { hasDcAdminRole } from 'components/Measure/Home/CreateButton/utils';
import { useActiveSubscriptions } from 'context/auth';
import FooterNav from '../FooterNav';

interface EnvScalesAssessmentProps {
  /** Environment Scales Assessment. */
  assessment: Assessment;

  /** If `true`, applies changes to work alongisde sidebar navigation. */
  isContainedInSidenav?: boolean;
}

function EnvScalesAssessment({
  assessment,
  isContainedInSidenav
}: EnvScalesAssessmentProps) {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const { run: scoresRun, data: scoreData, ...scoresState } = useAsync();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const subscriptions = useActiveSubscriptions();
  const accountId = currentUser.current_account_id || 0;
  const isDcAdminUser = hasDcAdminRole(subscriptions, accountId);

  useEffect(() => {
    scoresRun(getAssessmentScores(assessment.id));
  }, [assessment.id, scoresRun]);

  function handleSubmit() {
    navigate(RouteHelpers.getPath('measure-summary', { id: assessment.id }));
  }

  function handleDelete() {
    setDeleteModalOpen(false);
    navigate(RouteHelpers.getPath('measure'), { replace: true });
  }

  if (assessment.status === 'completed') {
    return (
      <Navigate
        replace
        to={RouteHelpers.getPath('measure-completed', { id: assessment.id })}
      />
    );
  }

  if (
    assessment &&
    assessment.created_by_guid !== currentUser.guid &&
    assessment.observer_guid !== currentUser.guid &&
    isDcAdminUser &&
    assessment.status !== 'awaiting_approval'
  ) {
    return <Navigate replace to={RouteHelpers.getPath('measure')} />;
  }

  if (scoresState.isPending) {
    return <PageLoader />;
  }

  const Wrapper = isContainedInSidenav ? Fragment : PageWrapper;
  const ContentWrapper = isContainedInSidenav ? Fragment : PageBackground;
  const MainContentWrapper = isContainedInSidenav ? Fragment : MainContent;

  return (
    <MainContentWrapper maxWidth={1280}>
      <Wrapper>
        <DeleteModal
          open={isDeleteModalOpen}
          assessmentId={assessment.id}
          onClose={() => setDeleteModalOpen(false)}
          onSuccess={handleDelete}
        />

        {isContainedInSidenav ? null : (
          <PageHeader
            title={i18n.ft(messages.header)}
            subtitle={i18n.ft(messages.subHeader)}
          />
        )}

        <ContentWrapper>
          {isContainedInSidenav ? null : (
            <InfoBox data={assessment} showButton />
          )}

          <Formik
            onSubmit={handleSubmit}
            validate={validateScores}
            initialValues={getInitialScores(scoreData)}
          >
            {formik => (
              <Form>
                <div
                  className={clsx(
                    'flex flex-col gap-2',
                    !isContainedInSidenav && 'mt-8'
                  )}
                >
                  {scoreData.map((domain: AssessmentScore, idx: number) => {
                    const isFirstSection = idx === 0;

                    return (
                      <Domain
                        key={domain.t_name}
                        name={domain.name}
                        categories={domain.categories}
                        assessmentId={assessment.id}
                        attached={isFirstSection}
                      />
                    );
                  })}
                </div>

                <BackToTop />

                {isContainedInSidenav ? (
                  <FooterNav />
                ) : (
                  <FooterWrapper>
                    <ButtonsWrapper>
                      <ReturnWrapper>
                        <Button
                          as={Link}
                          to="/measure"
                          basic
                          fluid
                          icon="arrow alternate circle left outline"
                          content={i18n.ft(messages.return)}
                        />
                      </ReturnWrapper>

                      <ReviewWrapper>
                        <Button
                          fluid
                          basic
                          type="button"
                          onClick={() => setDeleteModalOpen(true)}
                          content={i18n.ft(messages.delete)}
                        />
                        <Button
                          type="submit"
                          color="blue"
                          fluid
                          content={i18n.ft(messages.review)}
                        />
                      </ReviewWrapper>
                    </ButtonsWrapper>
                    {formik.isValid ? null : (
                      <ErrorWrapper>
                        <Icon name="warning circle" />
                        {' ' + i18n.ft(messages.error)}
                      </ErrorWrapper>
                    )}
                  </FooterWrapper>
                )}
              </Form>
            )}
          </Formik>
        </ContentWrapper>
      </Wrapper>
    </MainContentWrapper>
  );
}

export default EnvScalesAssessment;
