import { getObservers } from 'actions/observers';
import { Assessment } from 'types/api/envScales/Assessment';
import { useFormikContext } from 'formik';
import useAsync from 'hooks/useAsync';
import { useEffect } from 'react';
import { Select } from './Styled';
import messages from './messages';
import i18n from 'helpers/i18n';
import moment from 'moment/moment';

interface ObserversListProps {
  accountId: number;
}

function ObserversList({ accountId }: ObserversListProps) {
  const { values, setFieldValue } = useFormikContext<Assessment>();

  const {
    data: observers,
    run: observersRun,
    isLoading: loadingObservers
  } = useAsync<{ guid: string; name: string }[]>({ data: [] });
  const observersList = observers.map(observer => ({
    key: observer.guid,
    value: observer.guid,
    text: observer.name
  }));

  useEffect(() => {
    if (values.age_level.length && values.taken_at.length) {
      const takenAt = moment(values.taken_at).format('YYYY-MM-DD');
      observersRun(getObservers(accountId, takenAt, values.age_level));
    }
  }, [observersRun, values.taken_at, values.age_level, accountId]);

  useEffect(() => {
    if (observersList.length === 0 && values.observer_guid !== '') {
      setFieldValue('observer_guid', '');
    }
  }, [observersList, setFieldValue, values.observer_guid]);

  return (
    <Select
      name="observer_guid"
      required
      label={i18n.ft(messages.label)}
      placeholder={i18n.ft(messages.placeholder)}
      options={observersList}
      search
      disabled={values.taken_at === '' || values.age_level === ''}
      loading={loadingObservers}
      errorLabel={{ textAlign: 'left' }}
    />
  );
}

export default ObserversList;
