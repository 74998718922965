import { Icon } from 'semantic-ui-react';

interface VideoNavigationProps {
  sliderLength: number;
  currentVideoIndex: number | undefined;
}

function VideoNavigation({
  sliderLength,
  currentVideoIndex
}: VideoNavigationProps) {
  function sliderHasOnePage() {
    return sliderLength <= 1;
  }

  if (sliderHasOnePage()) {
    return null;
  }

  return (
    <div>
      {Array(sliderLength)
        .fill(true)
        .map((_, i) => (
          <Icon
            key={i}
            name="circle"
            size="mini"
            style={{
              color: i === currentVideoIndex ? '#00779E' : '#EBEFF8',
              fontSize: '0.6em'
            }}
            className="mr-3"
          ></Icon>
        ))}
    </div>
  );
}

export default VideoNavigation;
