import { useState } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import CyclesReview from './CyclesReview/CyclesReview';
import InfoBox from 'components/Measure/View/InfoBox';
import RouteHelpers from 'helpers/routes';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import MainContent from 'components/MainContent';
import PageHeader from 'components/Measure/PageHeader';
import PageBackground from 'components/PageBackground';
import ScoreSummary from 'components/Measure/View/ScoreSummary';
import { Icon, Button } from 'semantic-ui-react';
import SubmitModal from 'components/Measure/Summary/SubmitModal';
import DeleteModal from 'components/Measure/Summary/DeleteModal';
import useCurrentUser from 'hooks/useCurrentUser';
import { MeasureProvider, useMeasure } from 'context/measure';
import { PageWrapper, SectionWrapper, ButtonWrapper } from './Styled';
import ConfirmationModal from 'components/Modals/ConfirmationModal';

import {
  requestUpdateAssessment,
  approveAssessment
} from 'actions/envScales/assessments';
import { useActiveSubscriptions } from 'context/auth';
import { hasRole } from '../utils';

type URLParams = {
  id: string;
};

function Summary() {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const { assessment } = useMeasure();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSubmitModalOpen, setSubmitModalOpen] = useState(false);
  const isAwaitingApproval = assessment.status === 'awaiting_approval';
  const isCompleted = assessment.status === 'completed';
  const subscriptions = useActiveSubscriptions();

  const isSupervisor = Boolean(
    currentUser.current_account_id &&
      hasRole(subscriptions, currentUser.current_account_id, 'supervisor')
  );

  if (assessment.status === 'completed') {
    return (
      <Navigate
        replace
        to={RouteHelpers.getPath('measure-completed', { id: assessment.id })}
      />
    );
  }

  if (!isAwaitingApproval) {
    if (
      assessment.created_by_guid !== currentUser.guid &&
      assessment.observer_guid !== currentUser.guid
    ) {
      return <Navigate replace to={RouteHelpers.getPath('measure')} />;
    }
  }

  if (assessment.observation) {
    return (
      <CyclesReview
        assessmentId={assessment.id}
        accountGuid={assessment.account_guid}
      />
    );
  }

  function handleSubmit() {
    setSubmitModalOpen(false);
    navigate(RouteHelpers.getPath('measure-completed', { id: assessment.id }), {
      state: { isJustSubmitted: true },
      replace: true
    });
  }

  function handleDelete() {
    setDeleteModalOpen(false);
    navigate(RouteHelpers.getPath('measure'), { replace: true });
  }

  async function requestUpdates() {
    await requestUpdateAssessment(assessment.id);
    navigate(RouteHelpers.getPath('measure'), { replace: true });
  }

  async function approve() {
    await approveAssessment(assessment.id);
    navigate(RouteHelpers.getPath('measure-completed', { id: assessment.id }), {
      replace: true
    });
  }

  return (
    <MainContent maxWidth={1280}>
      <PageWrapper>
        <DeleteModal
          open={isDeleteModalOpen}
          assessmentId={assessment.id}
          onClose={() => setDeleteModalOpen(false)}
          onSuccess={handleDelete}
        />

        <SubmitModal
          open={isSubmitModalOpen}
          assessmentId={assessment.id}
          onClose={() => setSubmitModalOpen(false)}
          onSuccess={handleSubmit}
        />

        {isAwaitingApproval ? (
          <PageHeader
            title={`${assessment.assessment_template.name} ${i18n.ft(
              messages.approve
            )}`}
          />
        ) : (
          <PageHeader
            title={`${assessment.assessment_template.name} ${i18n.ft(
              messages.review
            )}`}
          />
        )}

        <PageBackground>
          <InfoBox
            data={assessment}
            showButton={!isSupervisor}
            attachToBottom
          />

          <ScoreSummary
            assessmentId={assessment.id}
            infoData={assessment}
            isEditable={!isSupervisor && !isCompleted}
          />

          <SectionWrapper>
            <ButtonWrapper>
              <Button
                fluid
                basic
                as={Link}
                to={RouteHelpers.getPath('measure')}
              >
                <Icon name="arrow alternate circle left outline" />
                {i18n.ft(messages.return)}
              </Button>
            </ButtonWrapper>

            {isAwaitingApproval ? (
              <>
                <ButtonWrapper alignRight>
                  <ConfirmationModal
                    title={i18n.ft(messages.requestUpdates)}
                    message={i18n.ft(messages.modal.messageRequest)}
                    confirmButton={i18n.ft(messages.modal.buttonRequest)}
                    icon="tasks"
                    confirmColor="blue"
                    triggerNode={
                      <Button
                        fluid
                        color="blue"
                        content={i18n.ft(messages.requestUpdates)}
                      />
                    }
                    onConfirm={({ closeModal }) => {
                      requestUpdates();
                      closeModal();
                    }}
                  />
                </ButtonWrapper>

                <ButtonWrapper alignTop>
                  <ConfirmationModal
                    title={i18n.ft(messages.approve)}
                    message={i18n.ft(messages.modal.messageApprove)}
                    confirmButton={i18n.ft(messages.modal.buttonApprove)}
                    icon="tasks"
                    confirmColor="blue"
                    triggerNode={
                      <Button
                        fluid
                        color="green"
                        content={i18n.ft(messages.approve)}
                      />
                    }
                    onConfirm={({ closeModal }) => {
                      approve();
                      closeModal();
                    }}
                  />
                </ButtonWrapper>
              </>
            ) : (
              <>
                <ButtonWrapper alignRight>
                  <Button
                    fluid
                    basic
                    onClick={() => setDeleteModalOpen(true)}
                    content={i18n.ft(messages.delete)}
                  />
                </ButtonWrapper>

                <ButtonWrapper alignTop>
                  <Button
                    fluid
                    color="blue"
                    onClick={() => setSubmitModalOpen(true)}
                    content={i18n.ft(messages.submit)}
                  />
                </ButtonWrapper>
              </>
            )}
          </SectionWrapper>
        </PageBackground>
      </PageWrapper>
    </MainContent>
  );
}

function SummaryWithProvider() {
  const { id } = useRouteParams<URLParams>();
  const assessmentId = parseInt(id);

  return (
    <MeasureProvider expanded assessmentId={assessmentId}>
      <Summary />
    </MeasureProvider>
  );
}

export default SummaryWithProvider;
