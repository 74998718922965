import i18n from 'helpers/i18n';
import messages from './messages';
import { Button } from 'semantic-ui-react';
import { TS_TRAININGS } from 'constants/environmentVariables';

interface ExpiredProps {
  framework: string;
  role: string | null;
}

function Expired({ framework, role }: ExpiredProps) {
  const observerUrl =
    framework === 'class_2008'
      ? `${TS_TRAININGS}?fwp_trainingcertification=class-2008-observation-training`
      : `${TS_TRAININGS}?fwp_trainingcertification=class-2nd-edition-observation-training`;
  const trainingsUrl =
    role === 'trainer'
      ? `${TS_TRAININGS}?fwp_trainingcertification=train-the-trainer-program`
      : observerUrl;

  const trainingType = role === 'trainer' ? 'knowledge' : 'observation';

  return (
    <div className="flex flex-col h-full">
      <p
        className="text-center text-lg"
        dangerouslySetInnerHTML={{
          __html: i18n.ft(messages.expired, { type: trainingType })
        }}
      ></p>
      <div className="grid w-full mt-auto mb-2">
        <Button
          type="button"
          href={trainingsUrl}
          className="mt-5 justify-self-center"
          color="blue"
          target="_blank"
        >
          {i18n.ft(messages.upcomingTrainings)}
        </Button>
      </div>
    </div>
  );
}

export default Expired;
