import styled from 'styled-components';

export const MetaItem = styled.div`
  margin-top: 4px;
`;

export const Image = styled.img`
  display: block;
  max-width: 88px;
  width: 100%;
  margin: 0 auto;
`;

export const Errors = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 16px;
  color: ${props => props.theme.colors.red};
`;

export const LineBreak = styled.hr`
  margin: 16px 0 24px;
`;

export const DividerContainer = styled.div`
  margin: 32px 0;
`;

export const LoginHeader = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 32px;
`;
