import i18n from 'helpers/i18n';
import messages from './messages';
import envScalesLogo from 'images/es_logo.svg';
import { Wrapper, TitleWrapper, Title, Subtitle, LogoImg } from './Styled';

interface PageHeaderProps {
  /** Header Title. */
  title: string;

  /** Header Subtitle. */
  subtitle?: string;

  /** The header can include extra content to the right side. */
  contentRight?: React.ReactNode;

  /** The header icon can be customized. */
  icon?: string;

  /** The header icon alt value can be customized. */
  iconAlt?: string;
}

function PageHeader({
  title,
  subtitle,
  contentRight,
  icon = envScalesLogo,
  iconAlt = i18n.ft(messages.logo)
}: PageHeaderProps) {
  return (
    <Wrapper>
      <TitleWrapper>
        <LogoImg src={icon} alt={iconAlt} />
        <Title>{title}</Title>
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
      </TitleWrapper>

      {contentRight}
    </Wrapper>
  );
}

export default PageHeader;
