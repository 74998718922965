import React from 'react';
import { Root, Circle, Completed } from './Styled';
import { OnboardingSteps } from 'components/Onboarding';
import { labelOnSteps, findIndexOfStep } from 'components/Stepper/utils';

interface StepperButtonProps {
  /**
   * Mark the step as active.
   */
  active: boolean;
  /**
   * Current step name.
   */
  stepName: OnboardingSteps;
  /**
   * All the steps that have been passed and the current.
   */
  checkedSteps: OnboardingSteps[];
  /**
   * All the steps that have been passed and the current.
   */
  setCheckedSteps: React.Dispatch<React.SetStateAction<OnboardingSteps[]>>;
  /**
   * All the steps that have been passed and the current.
   */
  setStep: React.Dispatch<React.SetStateAction<OnboardingSteps>>;
}

function StepperButton({
  active,
  setCheckedSteps,
  stepName,
  checkedSteps,
  setStep
}: StepperButtonProps) {
  const onConfirmedSteps = labelOnSteps(stepName, checkedSteps);
  const indexOfCurrentStep = findIndexOfStep(checkedSteps, stepName);

  function selectStep() {
    let stepsCompleted: OnboardingSteps[] = [];
    for (let i = 0; i <= indexOfCurrentStep; i++) {
      stepsCompleted.push(checkedSteps[i]);
    }
    setCheckedSteps(stepsCompleted);
    setStep(stepName);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter' || event.key === ' ') {
      selectStep();
    }
  }

  return (
    <Root
      onClick={onConfirmedSteps ? selectStep : undefined}
      onKeyDown={onConfirmedSteps ? handleKeyDown : undefined}
    >
      {active ? (
        <Completed aria-label={stepName} tabIndex={0} />
      ) : (
        <Circle aria-label={stepName} tabIndex={onConfirmedSteps ? 0 : -1} />
      )}
    </Root>
  );
}

export default StepperButton;
