const PATH = 'fe.components.dropdowns.languages';

const messages = {
  english: {
    key: `${PATH}.english`,
    defaultValue: 'English'
  },
  spanish: {
    key: `${PATH}.spanish`,
    defaultValue: 'Spanish'
  },
  french: {
    key: `${PATH}.french`,
    defaultValue: 'French'
  },
  mandarin: {
    key: `${PATH}.mandarin`,
    defaultValue: 'Mandarin'
  },
  hebrew: {
    key: `${PATH}.hebrew`,
    defaultValue: 'Hebrew'
  },
  amharic: {
    key: `${PATH}.amharic`,
    defaultValue: 'Amharic'
  },
  other: {
    key: `${PATH}.other_opt`,
    defaultValue: 'Other'
  }
};

export default messages;
