import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { EmailContainer } from './Styled';
import countries from 'i18n-iso-countries';
import Typography from 'components/Typography';

export type BigCommerceAddress = {
  email: string;
  first_name: string;
  last_name: string;
  address1: string;
  address2: string;
  country_code: string;
  state_or_province_code: string;
  city: string;
  postal_code: string;
  phone: string;
};

interface AddressPreviewProps {
  /** Big Commerce address object. */
  address: BigCommerceAddress;

  /** If `true`, the email will be hidden from the preview. */
  skipEmail?: boolean;
}

function formatName(address: BigCommerceAddress): string {
  return `${address.first_name} ${address.last_name}`;
}

function formatCityState(address: BigCommerceAddress): string {
  return `${address.city}, ${address.state_or_province_code} ${address.postal_code}`;
}

const AddressPreview: React.FC<AddressPreviewProps> = ({
  address,
  skipEmail
}) => {
  return (
    <div>
      <Typography bold display="block">
        {formatName(address)}
      </Typography>
      <Typography display="block">{address.address1}</Typography>
      <Typography display="block">{address.address2}</Typography>
      <Typography display="block">{formatCityState(address)}</Typography>
      <Typography display="block">
        {countries.getName(address.country_code, 'en')}
      </Typography>
      <Typography display="block">{address.phone}</Typography>

      {!skipEmail && (
        <EmailContainer>
          <Typography bold display="block">
            {i18n.ft(messages.email)}
          </Typography>
          <Typography display="block">{address.email}</Typography>
        </EmailContainer>
      )}
    </div>
  );
};

export default AddressPreview;
