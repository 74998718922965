import i18n from 'helpers/i18n';
import { useEffect, useRef, useState } from 'react';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import MainContent from 'components/MainContent';
import { useMediaQuery } from 'react-responsive';
import useCurrentUser from 'hooks/useCurrentUser';
import PageBackground from 'components/PageBackground';
import exitTest from 'images/reliabilityTest/exitTest.svg';
import { SecondaryActionWrapper, StyledWhiteButton } from '../Styled';
import instructionsIcon from 'images/reliabilityTest/instructionsIcon.svg';
import logoCertification from 'images/reliabilityTest/logoCertification.svg';
import {
  StyledButton,
  ActionWrapper,
  SectionActions,
  ContainerSection as Section
} from 'components/Reports/Reliability/View/Styled';
import HonorCodeAgreementContent from './HonorCodeAgreementContent';
import { updateUserTestTermsAccepted } from 'actions/userTests';
import InstructionsModal from 'components/Modals/InstructionsModal';

interface HonorCodeAgreementProps {
  title: string;
  testType?: string;
  userTestId: string;
  testAttemptId: number;
  handlePreTestState: (value: string) => void;
}

function HonorCodeAgreement({
  title,
  testType,
  userTestId,
  testAttemptId,
  handlePreTestState
}: HonorCodeAgreementProps) {
  const [honorCodeAccepted, setHonorCodeAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ minWidth: 768 });
  const honorCodeRef = useRef<any>({ current: null });

  useEffect(() => {
    const honorCodeElement = document.getElementById('honor-code');

    if (honorCodeElement) {
      honorCodeRef.current = honorCodeElement;
      honorCodeRef.current.focus();
    }
  }, []);

  function acceptHonorCodeAgreement() {
    if (honorCodeAccepted) {
      let termsAcceptedAt = new Date();
      updateUserTestTermsAccepted(
        currentUser.id,
        parseInt(userTestId),
        termsAcceptedAt.toISOString()
      ).then(() => {
        testType === 'knowledge'
          ? handlePreTestState('knowledge_test')
          : handlePreTestState('reliability_test');
        window.localStorage.removeItem(`pre_test_state_${testAttemptId}`);
      });
    } else {
      setShowModal(true);
    }
  }

  function handleClick() {
    return navigate(RouteHelpers.getPath('app'));
  }

  function back() {
    handlePreTestState('instructions');
  }

  return (
    <MainContent maxWidth={1080}>
      <InstructionsModal
        title={i18n.ft(messages.honorCodeAgreement)}
        message={<p className="text-center">{i18n.ft(messages.warning)}</p>}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        confirmButton={i18n.ft(messages.dismiss)}
        onConfirm={() => setShowModal(false)}
      />

      <PageHeader
        title={title}
        subtitle={
          testType === 'knowledge'
            ? i18n.ft(messages.review)
            : i18n.ft(messages.subtitle)
        }
        image={logoCertification}
        imageAlt={i18n.ft(messages.logoDescription)}
      />
      <PageBackground borderStyle>
        <Section
          mt={{ _: '0px', lg: '32px' }}
          mx={{ _: '0px', lg: '32px' }}
          borderTopRadius={isTablet ? '12px' : '0px'}
        >
          <div className="bg-white p-6 rounded-t-xl md:py-10  md:px-16 md:mb-1 lg:mb-1">
            <div className="flex mb-5">
              <img className="w-5 md:w-9" src={instructionsIcon} alt="" />
              <span
                ref={honorCodeRef}
                className="text-xl ml-3 font-bold self-center"
                tabIndex={0}
                id="honor-code"
              >
                {i18n.ft(messages.honorCodeAgreement)}
              </span>
            </div>
            <HonorCodeAgreementContent setHonorCode={setHonorCodeAccepted} />
          </div>
        </Section>
        <Section
          mb={{ _: '0px', lg: '32px' }}
          mx={{ _: '0px', lg: '32px' }}
          borderBottomRadius={isTablet ? '12px' : '0px'}
        >
          <div className="bg-white p-6 rounded-b-xl lg:p-6 lg:m-1">
            <SectionActions>
              <ActionWrapper>
                <StyledButton
                  basic
                  fluid
                  content={
                    <div className="cursor-pointer flex items-center">
                      <img
                        src={exitTest}
                        alt={i18n.ft(messages.exitTest)}
                        className="mr-5"
                      />
                      <span className="m-auto">
                        {i18n.ft(messages.exitTest)}
                      </span>
                    </div>
                  }
                  onClick={handleClick}
                />
              </ActionWrapper>
              <SecondaryActionWrapper>
                <StyledWhiteButton
                  fluid
                  content={i18n.ft(messages.back)}
                  onClick={back}
                />
              </SecondaryActionWrapper>
              <ActionWrapper>
                {testType === 'knowledge' ? (
                  <StyledWhiteButton
                    fluid
                    content={i18n.ft(messages.next)}
                    className="w-full whitespace-nowrap"
                    onClick={() => acceptHonorCodeAgreement()}
                  />
                ) : (
                  <Button
                    fluid
                    content={i18n.ft(messages.startTest)}
                    color="blue"
                    className="w-full whitespace-nowrap"
                    onClick={() => acceptHonorCodeAgreement()}
                  />
                )}
              </ActionWrapper>
            </SectionActions>
          </div>
        </Section>
      </PageBackground>
    </MainContent>
  );
}

export default HonorCodeAgreement;
