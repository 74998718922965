import {
  Container,
  Wrapper,
  ButtonWrapper,
  Col,
  ColLabel,
  ColValue,
  InfoTitle,
  InfoData
} from './Styled';
import { ObservationCycle } from 'types/api/envScales/ObservationCycle';
import CycleControls from 'components/Measure/View/Cycles/CycleControls';
import { useMediaQuery } from 'react-responsive';
import i18n from 'helpers/i18n';
import messages from './messages';
import {
  Button,
  Icon,
  Dropdown,
  DropdownItem,
  DropdownMenu
} from 'semantic-ui-react';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { Link, useNavigate } from 'react-router-dom';
import RouteHelpers from 'helpers/routes';
import * as R from 'ramda';
import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  returnAssessmentToAwaiting,
  returnAssessmentToInProgress
} from 'actions/envScales/assessments';
import { toastError } from 'components/Toast';
import useAsync from 'hooks/useAsync';

interface InfoProps {
  /** Assessment data. */
  data: any;

  /** If `true`, a button for editing the assessment will be displayed. */
  showButton?: boolean;

  /** If `true`, the box will not have rounded corners at the bottom. */
  attachToBottom?: boolean;

  /** Observation Cycle. */
  cycle?: ObservationCycle;

  extended?: boolean;

  /**If `true` the user is admin */
  isAdmin?: boolean;

  /**If `true` the user is supervisor */
  isSupervisor?: boolean;

  /**If `true` is video observation */
  isVideoObservation?: boolean;

  /**If `true` the observation requires approval */
  requiresApproval?: boolean;
}

function InfoBox({
  data,
  showButton,
  attachToBottom,
  cycle,
  extended,
  isAdmin = false,
  isSupervisor = false,
  isVideoObservation,
  requiresApproval
}: InfoProps) {
  const isLessThan640px = useMediaQuery({ maxWidth: 640 });
  const [scrolled, setScrolled] = useState<boolean>(false);
  const showTeachers = extended && !R.isEmpty(data.teachers);
  const navigate = useNavigate();
  const { run } = useAsync();

  useEffect(() => {
    const listenScrollEvent = () => {
      const minPosition = isLessThan640px ? 180 : 305;
      const maxPosition = isLessThan640px ? 225 : 355;
      if (window.scrollY < minPosition) {
        setScrolled(false);
      } else if (window.scrollY >= maxPosition) {
        // add diff to avoid flickering
        setScrolled(true);
      }
    };

    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, [isLessThan640px]);

  async function onConfirmChange() {
    await run(returnAssessmentToAwaiting(data.id));
    setTimeout(() => {
      navigate(RouteHelpers.getPath('measure'));
    }, 300);
  }

  function onConfirmToInProgress() {
    run(returnAssessmentToInProgress(data.id), {
      onSuccess: () => {
        setTimeout(() => {
          navigate(RouteHelpers.getPath('measure'));
        }, 300);
      },
      onError: (error: any) => {
        toastError({ description: error.errors.status });
      }
    });
  }

  if (R.isNil(data)) {
    return null;
  }

  return (
    <Wrapper scrolled={scrolled} extended={extended}>
      <Container
        attachToBottom={attachToBottom}
        scrolled={scrolled && !extended}
      >
        <Col>
          <ColLabel>
            <InfoTitle>{i18n.ft(messages.observationDate)}</InfoTitle>
            <InfoTitle>{i18n.ft(messages.center_name)}</InfoTitle>
            {extended && <InfoTitle>{i18n.ft(messages.observer)}</InfoTitle>}
            {showTeachers && (
              <InfoTitle>{i18n.ft(messages.teachers)}</InfoTitle>
            )}
          </ColLabel>
          <ColValue>
            <InfoData>
              {data && moment(data.taken_at).format('MM/DD/YYYY')}
            </InfoData>
            <InfoData>{data.center_name}</InfoData>
            {extended && <InfoData>{data.observer_name}</InfoData>}
            {showTeachers && <InfoData>{data.teachers.join(', ')}</InfoData>}
          </ColValue>
        </Col>

        {!isLessThan640px && (
          <Col>
            <ColLabel>
              <InfoTitle>{i18n.ft(messages.classAssessment)}</InfoTitle>
              <InfoTitle>{i18n.ft(messages.classroom)}</InfoTitle>
              <InfoTitle>{i18n.ft(messages.language)}</InfoTitle>
              {data.job_id && <InfoTitle>{i18n.ft(messages.jobId)}</InfoTitle>}
            </ColLabel>
            <ColValue>
              <InfoData>
                {data.age_level === 'PK-3rd'
                  ? i18n.ft(messages.prek3rd)
                  : data.age_level}
              </InfoData>
              <InfoData>{data.classroom_name}</InfoData>
              <InfoData>{data.language_name}</InfoData>
              {data.job_id && <InfoData>{data.job_id}</InfoData>}
            </ColValue>
          </Col>
        )}

        <Col>
          <ButtonWrapper>
            {(showButton ||
              (isAdmin && data.status === 'awaiting_approval')) && (
              <Button
                icon
                color="blue"
                size="mini"
                as={Link}
                to={RouteHelpers.getPath('measure-view-edit', { id: data.id })}
                aria-label={i18n.ft(messages.editObservationAria)}
              >
                <Icon name="pencil" />
              </Button>
            )}
            {(isAdmin || isSupervisor) && data.status === 'completed' && (
              <Dropdown button className="icon" icon="ellipsis horizontal">
                <DropdownMenu direction="left">
                  {!isVideoObservation && !requiresApproval && (
                    <ConfirmationModal
                      title={i18n.ft(messages.modal.titleInProgress)}
                      message={i18n.ft(messages.modal.warningInProgress)}
                      confirmButton={i18n.ft(messages.modal.confirm)}
                      icon="tasks"
                      triggerNode={
                        <DropdownItem>
                          {i18n.ft(messages.modal.titleInProgress)}
                        </DropdownItem>
                      }
                      onConfirm={({ closeModal }) => {
                        onConfirmToInProgress();
                        closeModal();
                      }}
                    />
                  )}
                  {requiresApproval && (
                    <ConfirmationModal
                      title={i18n.ft(messages.modal.title)}
                      message={i18n.ft(messages.modal.warning)}
                      confirmButton={i18n.ft(messages.modal.confirm)}
                      icon="tasks"
                      triggerNode={
                        <DropdownItem>{i18n.ft(messages.return)}</DropdownItem>
                      }
                      onConfirm={onConfirmChange}
                    />
                  )}
                </DropdownMenu>
              </Dropdown>
            )}
          </ButtonWrapper>
        </Col>
      </Container>

      {cycle && <CycleControls assessment={data} scrolled={scrolled} />}
    </Wrapper>
  );
}

export default InfoBox;
