import styled from 'styled-components';

export const Wrapper = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: auto;
  }

  input {
    width: 98px;
    border: 0;
    padding: 0;
    font-family: inherit;

    &:focus {
      outline: none;
    }
  }

  .react-datepicker__day--outside-month {
    color: #86868a;
  }

  .react-datepicker__day--disabled {
    color: #cccccc;
  }
`;
