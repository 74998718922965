import Alert from './Alert';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import Spacer from 'components/Spacer';
import ServiceLink from './ServiceLink';
import React, { useEffect, useRef, useState } from 'react';
import { isEmpty, keys, map } from 'ramda';
import { Loader } from 'semantic-ui-react';
import CertificationCard, {
  CertificationCardProps
} from '../CertificationCard';
import { getMyCertifications } from 'actions/certifications';
import calibrationImageSrc from 'images/dashboard/calibration.svg';
import directoryImageSrc from 'images/dashboard/directory.svg';
import onlineCoursesImageSrc from 'images/dashboard/moodle.svg';
import { toTitleCase, toSnakeCase } from 'helpers/text';
import { useLocation } from 'react-router-dom';
import { bigCommerceURL } from 'constants/environmentVariables';

import {
  Container,
  EmptyMessage,
  GroupContainer,
  GroupName,
  LinksContainer
} from './Styled';

import {
  sortCertifications,
  groupCertifications,
  mapUserCertToCardProps,
  getRenewCount
} from './utils';

const renewalLinksByGroup = {
  observer: `${bigCommerceURL}/observer-recertification-new/`,
  french_observer: `${bigCommerceURL}/recertification-des-observateurs-de-class-en-francais/?showHidden=true`,
  trainer: `${bigCommerceURL}/trainer-recertification-new/`,
  coach: `${bigCommerceURL}/mmci-renewal/?showHidden=true`,
  teacher: '',
  observer_trainer: `${bigCommerceURL}/trainer-recertification-new/`
};

function getGroupLink(groupName: string) {
  const groupNameSnakeCase = toSnakeCase(groupName);

  switch (groupNameSnakeCase) {
    case 'coach':
    case 'observer':
    case 'teacher':
    case 'trainer':
    case 'french_observer':
    case 'observer_trainer':
      return renewalLinksByGroup[groupNameSnakeCase];
    default:
      return '';
  }
}

const CertificationList = () => {
  const { run, data, isPending } = useAsync();
  const location = useLocation();
  const hashInUrl = location?.hash.replace('#', '');
  const certSelected = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    run(getMyCertifications());
  }, [run]);

  if (isPending) {
    return (
      <Container>
        <Loader active inline="centered" />
      </Container>
    );
  }

  if (isEmpty(data.user_certifications)) {
    return (
      <Container>
        <EmptyMessage>{i18n.ft(messages.noCertifications)}</EmptyMessage>
      </Container>
    );
  }

  const certificationList = [
    ...map(mapUserCertToCardProps, data?.user_certifications || [])
  ];

  const certificationsByGroup = groupCertifications(certificationList);
  const certificationGroups = keys(certificationsByGroup);
  const availableToRenew = getRenewCount(certificationList);

  function scrollToCertification() {
    setIsScrolled(true);
    setTimeout(() => {
      const positionY = certSelected.current
        ? certSelected.current.offsetTop
        : 0;
      window.scrollTo({
        top: positionY,
        left: 0,
        behavior: 'smooth'
      });
    }, 200);
  }

  function isMatchCertificateWithHash(certificationProps: any) {
    return (
      !isEmpty(hashInUrl) &&
      certificationProps.dataSource === 'onelogin' &&
      certificationProps.dataSourceID === parseInt(hashInUrl)
    );
  }

  !isEmpty(hashInUrl) && !isScrolled && scrollToCertification();

  function wasTransitioned(certification: CertificationCardProps) {
    return !!certification.transitionId;
  }

  return (
    <Container>
      {availableToRenew > 0 && (
        <React.Fragment>
          <Alert
            header={i18n.ft(messages.renewAlert, { count: availableToRenew })}
          />
          <Spacer size={32} />
        </React.Fragment>
      )}

      {certificationGroups.map(groupName => {
        if (isEmpty(certificationsByGroup[groupName])) {
          return null;
        }

        const certifications = sortCertifications(
          certificationsByGroup[groupName]
        );

        const localeGroupName = messages[groupName]
          ? i18n.ft(messages[groupName])
          : toTitleCase(groupName);

        return (
          <GroupContainer key={groupName}>
            <GroupName>
              {i18n.ft(messages.groupTitle, {
                groupName: localeGroupName
              })}
            </GroupName>

            <div>
              {certifications.map((certificationProps, index) => {
                const selected = isMatchCertificateWithHash(certificationProps);
                return (
                  <div
                    ref={selected ? certSelected : null}
                    key={`certification-card-item-${index}`}
                  >
                    <CertificationCard
                      key={certificationProps.certificationKey}
                      renewalURL={getGroupLink(certificationProps.groupName)}
                      selected={selected}
                      {...certificationProps}
                      transitionedTo2ndEdition={wasTransitioned(
                        certificationProps
                      )}
                    />
                  </div>
                );
              })}
            </div>
          </GroupContainer>
        );
      })}

      <GroupName>{i18n.ft(messages.moreResources)}</GroupName>
      <LinksContainer>
        <ServiceLink
          href={RouteHelpers.getPath('canvas')}
          image={onlineCoursesImageSrc}
          imageAlt={i18n.ft(messages.coursesAlt)}
          content={i18n.ft(messages.coursesTitle)}
        />
        <ServiceLink
          href="https://info.teachstone.com/class-observer-directory-for-observers"
          image={directoryImageSrc}
          imageAlt={i18n.ft(messages.obsDirectoryAlt)}
          content={i18n.ft(messages.obsDirectoryTitle)}
        />
        <ServiceLink
          href={`${bigCommerceURL}/individual-class-calibration/`}
          image={calibrationImageSrc}
          imageAlt={i18n.ft(messages.calibrationsAlt)}
          content={i18n.ft(messages.calibrationsTitle)}
        />
      </LinksContainer>
    </Container>
  );
};

export default CertificationList;
