import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { getStatusLabel } from './utils';
import Typography from 'components/Typography';
import { getAgeLevel } from 'helpers/ageLevel';
import { head, keys, last, range } from 'ramda';
import AttemptTableCell from './AttemptTableCell';
import { AGE_LEVEL_ABBREVIATIONS } from 'types/api/AgeLevel';
import { ReliabilityReport } from 'types/api/ReliabilityReport';

import {
  Container,
  Button,
  Table,
  Header,
  HeaderContainer,
  GroupContainer,
  TableHeaderCell,
  TableBodyCell
} from './Styled';

interface ReportTableProps {
  /** Reliability report data. */
  report: ReliabilityReport;
}

const ReportTable: React.FC<ReportTableProps> = ({ report }) => {
  const [activeLevel, setActiveLevel] = useState<AGE_LEVEL_ABBREVIATIONS>(
    () => head(keys(report))!
  );

  const data = report[activeLevel];
  const multipleAgeLevels = keys(report).length > 1;

  return (
    <Container abbreviation={activeLevel}>
      <HeaderContainer>
        <Header>{i18n.ft(messages.participants)}</Header>

        {multipleAgeLevels && (
          <GroupContainer>
            <Typography append>{i18n.ft(messages.show) + ':'}</Typography>
            <Button.Group>
              {keys(report).map(abbreviation => {
                const ageLevel =
                  abbreviation === 'K-3'
                    ? getAgeLevel('PK3')
                    : getAgeLevel(abbreviation);

                return (
                  <Button
                    key={abbreviation}
                    active={activeLevel === abbreviation}
                    onClick={() => setActiveLevel(abbreviation)}
                  >
                    {ageLevel.name}
                  </Button>
                );
              })}
            </Button.Group>
          </GroupContainer>
        )}
      </HeaderContainer>

      <Table striped unstackable>
        <Table.Header>
          <Table.Row>
            <TableHeaderCell $desktop $mobile>
              {i18n.ft(messages.name)}
            </TableHeaderCell>
            <TableHeaderCell $desktop>
              {i18n.ft(messages.email)}
            </TableHeaderCell>
            <TableHeaderCell $desktop>
              {i18n.ft(messages.status)}
            </TableHeaderCell>
            <TableHeaderCell $desktop>
              {i18n.ft(messages.attemptNumber, { number: 1 })}
            </TableHeaderCell>
            <TableHeaderCell $desktop>
              {i18n.ft(messages.attemptNumber, { number: 2 })}
            </TableHeaderCell>
            <TableHeaderCell $desktop>
              {i18n.ft(messages.attemptNumber, { number: 3 })}
            </TableHeaderCell>
            <TableHeaderCell $mobile>
              {i18n.ft(messages.attempt)}
            </TableHeaderCell>
            <TableHeaderCell $mobile>{i18n.ft(messages.score)}</TableHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.details.map(row => {
            const lastAttempt = last(row.attempts);

            return (
              <Table.Row key={row.guid}>
                <TableBodyCell $desktop $mobile>
                  <Typography bold>{row.name}</Typography>
                </TableBodyCell>
                <TableBodyCell $desktop>{row.email}</TableBodyCell>
                <TableBodyCell $desktop>
                  {getStatusLabel(row.status)}
                </TableBodyCell>

                {row.attempts.map(attempt => (
                  <AttemptTableCell
                    key={`${row.guid}-${attempt.number}`}
                    attempt={attempt}
                    desktop
                  />
                ))}

                {range(0, 3 - row.attempts.length).map(missingAttempt => (
                  <TableBodyCell
                    $desktop
                    key={`${row.guid}-missing-${missingAttempt}`}
                  >
                    {getStatusLabel()}
                  </TableBodyCell>
                ))}

                <TableBodyCell $mobile>
                  {lastAttempt?.number ?? i18n.ft(messages.none)}
                </TableBodyCell>
                <AttemptTableCell score mobile attempt={lastAttempt!} />
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Container>
  );
};

export default ReportTable;
