import colors from 'styles/colors';

export type Video = {
  name: string;
  id: number;
  video_host_id: string;
};

interface ThumbnailVideosProps {
  handleClick: (id: number) => void;
  ariaRoleDescription: string;
  ariaLabel: string;
  video: Video;
}

function VideoThumbnail({
  handleClick,
  ariaRoleDescription,
  ariaLabel,
  video
}: ThumbnailVideosProps) {
  return (
    <div>
      <div
        className="snap-start w-60 h-32 flex flex-shrink-0 cursor-pointer mx-1"
        aria-roledescription={ariaRoleDescription}
        aria-label={ariaLabel}
        tabIndex={0}
        onClick={() => handleClick(video.id)}
      >
        <img
          className="w-full max-h-full max-w-full rounded cursor-pointer focus:scale-105 hover:scale-105"
          src={`https://cdnsecakmi.kaltura.com/p/966871/thumbnail/entry_id/${video.video_host_id}/width/300`}
          alt="Thumbnail"
          style={{
            transition: 'all 0.3s ease-out'
          }}
        />
      </div>
      <div
        className="font-semibold mt-2"
        style={{ color: `${colors.scaleDarkBlue}` }}
      >
        {video.name}
      </div>
    </div>
  );
}

export default VideoThumbnail;
