const PATH = 'fe.components.trainings.view.participants';

const messages = {
  participants: {
    key: `${PATH}.participants`,
    defaultValue: 'Participants'
  },
  payForParticipants: {
    key: `${PATH}.pay_for_participants`,
    defaultValue: 'Pay for Participant(s)'
  },
  requestPayment: {
    key: `${PATH}.request_payment`,
    defaultValue: 'Request Payment'
  },
  managePayment: {
    key: `${PATH}.manage_payment`,
    defaultValue: 'Manage Payment(s)'
  },
  cancelParticipants: {
    key: `${PATH}.cancel_participants`,
    defaultValue: 'Cancel Participant(s)'
  },
  inviteCanceledParticipants: {
    key: `${PATH}.invite_canceled_participant`,
    defaultValue: 'Invite Canceled Participant'
  },
  reinviteDeclinedParticipants: {
    key: `${PATH}.reinvite_declined_participant`,
    defaultValue: 'Reinvite Declined Participants'
  },
  editParticipantEmail: {
    key: `${PATH}.edit_participant_email`,
    defaultValue: 'Edit Participant Email'
  },
  exportParticipants: {
    key: `${PATH}.export_participants`,
    defaultValue: 'Get email list'
  },
  wantTo: {
    key: `${PATH}.want_to`,
    defaultValue: 'I want to...'
  }
};

export default messages;
