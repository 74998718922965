import { ModalHeader } from './Styled';
import React, { useRef, useContext } from 'react';
import nextIcon from 'images/learningResources/right_arrow_icon.svg';
import { Modal, ModalProps, SemanticShorthandContent } from 'semantic-ui-react';
import { LearningResourcesContext } from 'pages/LearningResources/LearningResourcesContext';

interface ConfirmationModalProps extends ModalProps {
  /**Title for the modal */
  title: string | SemanticShorthandContent;

  /**Content message for the modal */
  message: SemanticShorthandContent;

  /**Text associated with the text button to complete the modal action */
  confirmButtonString: string;

  /** Node associate with the trigger of the modal */
  triggerNode?: React.ReactNode;

  /**Function call on cofirmation */
  onConfirm: () => void;

  /**Function call on cancel */
  onCancel: () => void;

  /**true when modal is open */
  isOpen: boolean;

  /** Step of the form */
  step: number;
}

function ShowResourcesModal({
  title,
  message,
  confirmButtonString,
  triggerNode,
  onConfirm,
  onClose,
  isOpen,
  step
}: ConfirmationModalProps) {
  const modalRef = useRef<any>({ current: null });
  const context = useContext(LearningResourcesContext);

  if (!context) {
    throw new Error(
      'LearningResourcesHeader must be used within a Learning Resources Provider'
    );
  }

  const { canSubmit } = context;

  function handleConfirmation() {
    onConfirm();
  }

  const handleOnMount = () => {
    const modalElement = document.getElementById('confirmation-modal');

    if (isOpen && modalElement) {
      modalRef.current = modalElement;

      modalRef.current.focus();
    }
  };

  function getBGButtonColor() {
    if (step === 0) {
      return 'bg-[#0A9CCA]';
    }

    return canSubmit ? 'bg-[#0A9CCA]' : 'bg-[#a5dced]';
  }

  return (
    <Modal
      ref={modalRef}
      id="confirmation-modal"
      tabIndex={0}
      trigger={triggerNode}
      open={isOpen}
      onClose={onClose}
      onMount={handleOnMount}
      closeOnDimmerClick
      size="small"
      className="px-0 !rounded-lg lg:w-[1100px]"
      dimmer={{ style: { backgroundColor: 'rgba(0,0,0,0.63)' } }}
      autoFocus
    >
      <ModalHeader content={title} className="w-full !rounded-lg" />
      <Modal.Content content={message} className="!pb-0" />
      <div
        className="flex bg-white w-full relative"
        style={{
          boxShadow: '0px 0px 13px 0px #00000033'
        }}
      >
        <div className=" my-6 px-6" style={{ width: '1280px' }}>
          <button
            className={`${getBGButtonColor()} !float-right text-white font-bold py-2 px-4 rounded h-fit self-center focus:shadow-outline`}
            onClick={() => handleConfirmation()}
            disabled={step === 0 ? false : !canSubmit}
          >
            <span className="inline">
              {confirmButtonString}
              <img alt="" className="inline ml-2" src={nextIcon} />{' '}
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ShowResourcesModal;
