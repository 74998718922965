import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import RoutesHelper from 'helpers/routes';
import PageLoader from 'components/PageLoader';
import { useNavigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import { UserReportConfiguration } from 'types/api/envScales/UserReportConfiguration';

import {
  getReportConfigurationById,
  updateReportConfiguration
} from 'actions/envScales/reports';

import ReportConfigurationForm, {
  FormValues,
  ReportConfig
} from 'pages/Reports/ObservationV2';

type URLParams = {
  id: string;
};

function EditReportConfiguration() {
  const navigate = useNavigate();
  const params = useRouteParams<URLParams>();
  const configurationId = parseInt(params.id);
  const { run, data, isPending } = useAsync<UserReportConfiguration>();

  useEffect(() => {
    run(getReportConfigurationById(configurationId));
  }, [run, configurationId]);

  if (isPending) {
    return <PageLoader />;
  }

  const initialValues: FormValues[] = [
    {
      ageLevel: data.age_level,
      startDate: parseDate(data.start_date),
      endDate: parseDate(data.end_date),
      nodeIds: data.hierarchy_selections
    }
  ];

  if (data.comparison_config) {
    initialValues.push({
      ageLevel: data.comparison_config.age_level,
      startDate: parseDate(data.comparison_config.start_date),
      endDate: parseDate(data.comparison_config.end_date),
      nodeIds: data.comparison_config.hierarchy_selections
    });
  }

  function parseDate(date: string) {
    const noTimeDate = moment.utc(date).format('YYYY-MM-DD');
    return moment(noTimeDate).toDate();
  }

  async function handleSubmit(values: ReportConfig) {
    await updateReportConfiguration(configurationId, values);
    navigate(RoutesHelper.getPath('reports-observation'));
  }

  return (
    <ReportConfigurationForm
      title={i18n.ft(messages.editReport)}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  );
}

export default EditReportConfiguration;
