const PATH = 'fe.components.onboarding';

const messages = {
  closeButton: {
    key: `${PATH}.close`,
    defaultValue: 'Close'
  }
};

export default messages;
