const PATH = 'fe.components.trainings.view.card_checkout.coupon_form';

const messages = {
  couponCode: {
    key: `${PATH}.coupon_code`,
    defaultValue: 'Coupon Code'
  },
  haveCoupon: {
    key: `${PATH}.have_coupon`,
    defaultValue: 'Have a coupon code?'
  },
  enterCoupon: {
    key: `${PATH}.enter_coupon`,
    defaultValue: 'Enter your coupon code'
  },
  apply: {
    key: `${PATH}.apply`,
    defaultValue: 'Apply'
  },
  isRequired: {
    key: `${PATH}.coupon_required`,
    defaultValue: 'Coupon code is required'
  },
  unableToApply: {
    key: `${PATH}.unable_to_apply`,
    defaultValue: 'Unable to apply coupon code'
  }
};

export default messages;
