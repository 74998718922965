import { Bar } from './Styled';

function BarProgress(props: { idx: number; currentVideoIndex: number }) {
  const { idx, currentVideoIndex } = props;

  if (idx >= 4) {
    return null;
  }

  return (
    <div
      className="w-1/5 align-center items-center align-middle content-center flex"
      style={{ marginTop: '-7px' }}
    >
      <Bar
        className={(idx < currentVideoIndex
          ? `bg-cyan-700`
          : `bg-gray-300`
        ).concat(' text-xs leading-none pb-1 text-center')}
      ></Bar>
    </div>
  );
}

export default BarProgress;
