import styled from 'styled-components';

const size = {
  mobileL: '640px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px'
};

export const device = {
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktopL: `(min-width: ${size.laptopL})`
};

interface CardProps {
  selected?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;

  @media ${device.mobileL} {
    font-size: 30px;
  }
`;

export const Experience = styled.p`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 32px;
  max-width: 500px;

  @media ${device.mobileL} {
    font-size: 20px;
  }
`;

export const SelectApply = styled.p`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: ${p => p.theme.colors['neutral']['500']};
  margin-bottom: 20px;
`;

export const Options = styled.div`
  display: grid;
  grid-gap: 12px;
  max-width: 400px;
`;

export const Card = styled.div<CardProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  border: solid 1px ${p => p.theme.colors['primary']['500']};

  background-color: ${p =>
    p.selected ? p.theme.colors['primary']['50'] : p.theme.colors['white']};
`;

export const CardLogo = styled.img`
  width: 88px;
  height: 88px;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`;

export const CardText = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: ${p => p.theme.colors['neutral']['900']};
`;

export const CardDescription = styled.div`
  font-size: 16px;
  margin-top: 8px;
  color: ${p => p.theme.colors['neutral']['900']};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  margin-top: 48px;
`;
