import styled from 'styled-components';
import { Icon as SemanticIcon } from 'semantic-ui-react';

interface Props {
  /** The diameter of the avatar. */
  diameter: string;

  /** The font size for the initials. */
  fontSize: string;
}

export const AvatarContainer = styled.div<Props>`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 2px solid ${props => props.theme.colors.blue};
  border-radius: 50%;
  overflow: hidden;
  width: ${p => p.diameter};
  height: ${p => p.diameter};
`;

export const Initials = styled.div<Props>`
  color: ${props => props.theme.colors.blue};
  font-size: 14px;
  font-weight: bold;
  font-size: ${p => p.fontSize};
`;

export const Image = styled.img<Props>`
  max-width: calc(${p => p.diameter} - 3px);
  max-height: calc(${p => p.diameter} - 3px);
`;

export const Icon = styled(SemanticIcon)`
  &.icon {
    position: absolute;
    bottom: -2px;
    right: -2px;
  }
`;

export const Container = styled.div<Props>`
  position: relative;
  width: ${p => p.diameter};
  height: ${p => p.diameter};
`;
