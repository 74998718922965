const PATH = 'fe.pages.account.experience';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Experience'
  },
  roles: {
    key: `${PATH}.roles`,
    defaultValue: 'Roles'
  },
  familiarity: {
    key: `${PATH}.familiarity`,
    defaultValue: 'CLASS Familiarity'
  },
  save: {
    key: `${PATH}.save`,
    defaultValue: 'Save Changes'
  }
};

export default messages;
