import i18n from 'helpers/i18n';
import messages from './messages';
import { Wrapper, ScoreCheckbox } from './Styled';
import React, { useEffect, useState } from 'react';
import { GenericCheckbox } from 'components/GenericCheckbox';
import { createUserAnswers, updateUserAnswers } from 'actions/userAnswers';
import {
  createUserAnswers as createUserPreparationAnswers,
  updateUserAnswers as updateUserPreparationAnswers
} from 'actions/testPreparations';
import debounce from 'debounce';

const SCORES = [1, 2, 3, 4, 5, 6, 7];

type UserAnswer = { id: number; answer: number };
type DimensionScore = { id: number; answer: number; dimensionId: number };
interface DimensionScoreInputProps {
  /** ID for the current Reliability Test */
  testAttemptId: string;

  /** Dimension Id */
  dimensionId: number;

  /** Current Coding Video  */
  codingVideoId: number;

  /** User Answer object. Contains ID and value. */
  userAnswer: UserAnswer | null;

  /** Updates the user scores. Useful to enable the submit button once completed */
  addUserScore: (value: DimensionScore) => void;

  /** Updates user scores based on test type */
  testType?: 'reliability' | 'testPreparations';
}

function DimensionScoreInput({
  testAttemptId,
  dimensionId,
  codingVideoId,
  userAnswer,
  addUserScore,
  testType
}: DimensionScoreInputProps) {
  const [currentUserAnswer, setCurrentUserAnswer] = useState<UserAnswer | null>(
    userAnswer
  );

  useEffect(() => {
    setCurrentUserAnswer(userAnswer);
  }, [userAnswer]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const dimensionScore = parseInt(event.target.value);
    let action = currentUserAnswer ? updateUserAnswers : createUserAnswers;

    if (testType === 'testPreparations') {
      action = currentUserAnswer
        ? updateUserPreparationAnswers
        : createUserPreparationAnswers;
    }

    if (dimensionScore !== currentUserAnswer?.answer) {
      action(testAttemptId, {
        coding_video_id: codingVideoId,
        dimension_id: dimensionId,
        answer: dimensionScore,
        id: currentUserAnswer?.id
      }).then(response => {
        setCurrentUserAnswer({ id: response.data.id, answer: dimensionScore });
        addUserScore({
          id: response.data.id,
          answer: dimensionScore,
          dimensionId: dimensionId
        });
      });
    }
  }

  return (
    <Wrapper>
      {SCORES.map(score => (
        <GenericCheckbox
          tabIndex={-1}
          key={score}
          value={score}
          checked={score === currentUserAnswer?.answer}
          onChange={debounce(handleChange, 150)} // TODO: create a unit test to check if debounce is working
          type="checkbox"
          aria-label={i18n.ft(messages.dimensionScore, { value: score })}
        >
          <ScoreCheckbox checked={score === currentUserAnswer?.answer}>
            {score}
          </ScoreCheckbox>
        </GenericCheckbox>
      ))}
    </Wrapper>
  );
}

export default DimensionScoreInput;
