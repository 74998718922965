import i18n from 'helpers/i18n';
import messages from './messages';
import React from 'react';
import { Header } from 'semantic-ui-react';
import EnterForm from 'components/Tokens/View/EnterForm';
import ContainerWithLogo from 'components/ContainerWithLogo';

const Enter: React.FC = () => {
  return (
    <>
      <ContainerWithLogo>
        <Header textAlign="center" content={i18n.ft(messages.instructions)} />
        <EnterForm />
      </ContainerWithLogo>
    </>
  );
};

export default Enter;
