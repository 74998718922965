import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  width: 192px;
  margin: 16px 0 0;
`;

export const SignOutIFrame = styled.iframe`
  width: 0;
  height: 0;
  border: 0;
  border: none;
  display: none;
`;
