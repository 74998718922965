import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import RoutesHelper from 'helpers/routes';
import { Loader } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RecommendationItem } from 'types/api/LearningResource';
import { getHoursMinutes } from 'pages/LearningResources/utils';
import VideoResource from 'components/LearningResources/VideoResource';
import { getRecommendedLearningResources } from 'actions/learningResources';
import useCurrentUser from 'hooks/useCurrentUser';

function Recommended() {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const {
    data: { recommendations },
    run,
    isPending
  } = useAsync({ data: {} });
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    run(getRecommendedLearningResources());
  }, [run]);

  function handleClick(id: number) {
    if (user.roles.includes('lr_coach')) {
      return navigate(
        RoutesHelper.getPath('coach-learning-resources-detail-page', {
          id
        })
      );
    }

    return navigate(
      RoutesHelper.getPath('learning-resources-detail-page', {
        id
      })
    );
  }

  if (isPending) {
    return <Loader active />;
  }

  if (!recommendations || recommendations?.length === 0) {
    return null;
  }

  return (
    <>
      <div className="block md:flex items-center mb-4">
        <h2 className="text-black">{i18n.ft(messages.recommend)}</h2>{' '}
        <a
          className="pl-0 md:pl-4 font-bold underline underline-offset-2 text-base text-[#0A9CCA]"
          href={RoutesHelper.getPath('learning-resources-all')}
        >
          {i18n.ft(messages.all)}
        </a>
      </div>
      <div
        className={`flex gap-3 2xl:grid 2xl:grid-cols-4 pb-8 ${
          isHovered
            ? 'overflow-auto scrollbar-visible'
            : 'overflow-hidden scrollbar-hidden'
        }`}
        onTouchStart={() => {
          setIsHovered(true);
        }}
        onTouchEnd={() => {
          setIsHovered(false);
        }}
      >
        {recommendations?.map(
          (recommendation: RecommendationItem, idx: number) => (
            <VideoResource
              ariaLabel={'Video Thumbnail'}
              ariaRoleDescription={'Slide'}
              handleClick={handleClick}
              video={{
                name: recommendation.resourceable.name,
                id: recommendation.resourceable.id
                  ? recommendation.resourceable.id
                  : idx,
                videoURI: recommendation.resourceable.video_uri
                  ? recommendation.resourceable.video_uri
                  : '1_bkuk1xi7',
                estimatedTime: recommendation.resourceable.estimated_time
                  ? getHoursMinutes(recommendation.resourceable.estimated_time)
                  : '0'
              }}
              key={recommendation.id}
              tags={recommendation.resourceable.tags}
            />
          )
        )}
      </div>
    </>
  );
}

export default Recommended;
