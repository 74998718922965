import { getPreview } from './utils';
import moment from 'moment';
import { Attachment } from 'types/api/envScales/Attachment';
import { AttachmentName, AttachmentDate, PreviewWrapper } from './Styled';
import { Flex } from 'components/Flex';

interface AttachmentItemProps {
  /** attachment name */
  attachment: Attachment;
}

function AttachmentItem({ attachment }: AttachmentItemProps) {
  return (
    <a href={attachment.attachment_url} target="_blank" rel="noreferrer">
      <Flex my="8px">
        <PreviewWrapper>{getPreview(attachment)}</PreviewWrapper>
        <Flex direction="column" justify="center">
          <AttachmentName>{attachment.name}</AttachmentName>
          {attachment.created_at && (
            <AttachmentDate>
              {moment(attachment.created_at).format('MM/DD/YY hh:mm A')}
            </AttachmentDate>
          )}
        </Flex>
      </Flex>
    </a>
  );
}

export default AttachmentItem;
