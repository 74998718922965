const PATH = 'fe.pages.improvements';

const messages = {
  hideCard: {
    key: `${PATH}.hide_card`,
    defaultValue: 'Hide Card'
  }
};

export default messages;
