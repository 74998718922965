import React from 'react';
import { isEmpty } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Container, Input, Button } from './Styled';
import { createTrainingParticipant } from 'actions/trainings';
import { Formik, Form } from 'components/EnhancedForm';

import ErrorModal, {
  useErrors,
  parseAPIErrorsNoKeys
} from 'components/ErrorModal';

const initialValues = {
  email: ''
};

const AddParticipantForm = ({ trainingId, afterSubmit }) => {
  const { errors, errorsVisible, displayErrors, hideErrors } = useErrors();

  function handleSubmit(values, formik) {
    if (isEmpty(values.email)) {
      formik.setSubmitting(false);
      return;
    }

    const params = {
      emails: values.email
    };

    createTrainingParticipant(trainingId, params)
      .then(() => {
        formik.setFieldValue('email', '');
        formik.setFieldTouched('email', false);
        formik.setSubmitting(false);
        afterSubmit();
      })
      .catch(({ response }) => {
        displayErrors(parseAPIErrorsNoKeys(response.data.errors));
        formik.setFieldError('email', i18n.ft(messages.failedToAdd));
        formik.setSubmitting(false);
      });
  }

  function handleChange(formik) {
    return (e, { value }) => {
      let fValue = value.replace(/([\s;:/|]|(,\s))+/g, ', ');
      formik.setFieldValue('email', fValue);
    };
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {({ isSubmitting, ...formik }) => (
        <Form>
          <Container>
            <ErrorModal
              errors={errors}
              open={errorsVisible}
              onClose={hideErrors}
            />
            <Input
              name="email"
              onChange={handleChange(formik)}
              label={`+${i18n.ft(messages.addParticipant)}`}
              aria-label={i18n.ft(messages.addParticipant)}
              placeholder={i18n.ft(messages.example)}
            />
            <Button
              color="blue"
              content={i18n.ft(messages.addToList)}
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              hasErrors={formik.errors.email && formik.touched.email}
            />
          </Container>
        </Form>
      )}
    </Formik>
  );
};

AddParticipantForm.defaultProps = {
  afterSubmit: () => {}
};

export default AddParticipantForm;
