import styled from 'styled-components';

export const CardContainer = styled.div`
  padding: 12px 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.colors['white']};
  border: 1px solid ${p => p.theme.colors['neutral']['200']};
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  &:hover {
    cursor: pointer;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
`;

export const CardTitle = styled.div`
  color: ${props => props.theme.colors['neutral']['700']};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const NoAgeLevels = styled.div`
  font-size: 16px;
  font-style: italic;
  color: ${props => props.theme.colors['neutral']['500']};
  margin-bottom: 8px;
`;

export const CardAgeLevels = styled.div`
  margin-top: auto;
  margin-bottom: -8px;
`;
