import { Outlet } from 'react-router-dom';
import MainContent from 'components/MainContent';

function Trainings() {
  return (
    <MainContent maxWidth={1024}>
      <Outlet />
    </MainContent>
  );
}

export default Trainings;
