import styled from 'styled-components';

/**
 * @deprecated use tailwind classes instead of this
 */
const PageLayout = styled.div`
  padding: 16px;
  font-size: 14px;
`;

export default PageLayout;
