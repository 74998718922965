import { isNil } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { signUpWithToken } from 'actions/tokens';
import UserForm from 'components/forms/UserForm';
import { Header } from 'semantic-ui-react';
import { SubHeader, LinkButton } from './Styled';
import { useSelector } from 'react-redux';

const SignUpForm = ({ userToken, onLogIn, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();
  const locale = useSelector(state => state.locale);

  const initialValues = {
    first_name: '',
    last_name: '',
    password: '',
    password_confirmation: '',
    token: userToken.token,
    email: isNil(userToken.user_id) ? userToken.email : '',
    tos_pp_agreed_at: false
  };

  function handleSubmit(values, formik) {
    setLoading(true);
    setErrors();

    const formValues = { ...values, preferred_language: locale };

    signUpWithToken({ user: formValues })
      .then(({ data }) => {
        setLoading(false);
        onSuccess();
      })
      .catch(({ response }) => {
        // On local environments, the error response might look like:
        // response?.data?.errors?.okta?.[0]
        // However, the format used below matches all of our production envionments.
        if (response?.data?.errors?.user[0]) {
          Object.keys(response?.data?.errors.user[0]).forEach(key => {
            formik.setFieldError(key, response?.data?.errors.user[0][key][0]);
          });
        } else {
          setErrors(i18n.ft(messages.errors.unknown));
        }
        setLoading(false);
      });
  }

  return (
    <>
      <Header textAlign="center" content={i18n.ft(messages.header)} />

      <SubHeader>
        {i18n.ft(messages.haveAnAccount)}{' '}
        <LinkButton type="button" onClick={onLogIn}>
          {i18n.ft(messages.login)}
        </LinkButton>
      </SubHeader>

      <UserForm
        onSuccess={onSuccess}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        loading={loading}
        errors={errors}
      />
    </>
  );
};

SignUpForm.propTypes = {
  /** User token. */
  userToken: PropTypes.object.isRequired,

  /** Callback fired when login link is clicked. */
  onLogIn: PropTypes.func.isRequired,

  /** Callback fired when user successfully creates an account. */
  onSuccess: PropTypes.func.isRequired
};

export default SignUpForm;
