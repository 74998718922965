const PATH = 'fe.components.measure.pagination';

const messages = {
  previous: {
    key: `${PATH}.previous`,
    defaultValue: 'Previous'
  },
  next: {
    key: `${PATH}.next`,
    defaultValue: 'Next'
  }
};

export default messages;
