import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  color: ${p => p.theme.colors['scaleDarkBlue']};
  padding: 12px;
  border-radius: 12px;
  box-shadow: 1px 1px 11px #d7e6ed;
`;

export const GridWrapper = styled.div<{ scores: number }>`
  position: relative;
  isolation: isolate;
  display: grid;
  grid-gap: 48px;

  @media screen and (min-width: 768px) {
    grid-gap: 24px;
    ${p =>
      p.scores > 1
        ? css`
            grid-template-columns: 1fr 1fr;
          `
        : css`
            grid-template-columns: 1fr;
          `}
  }

  @media screen and (min-width: 1024px) {
    ${p =>
      p.scores === 1
        ? css`
            grid-template-columns: 1fr;
          `
        : css`
            grid-template-columns: repeat(${p.scores}, 1fr);
          `}
  }
`;

export const ScoreWrapper = styled.div`
  position: relative;
`;

export const ScoreLine = styled.div`
  margin: 0;
  position: absolute;
  border-radius: 4px;
  left: 16px;
  right: 16px;
  top: -26.5px;
  border-top: 5px solid #f4f8fa;

  @media screen and (min-width: 768px) {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    display: block;
    border: 0;
    border-right: 5px solid #f4f8fa;
    left: -14.5px;
    top: 88px;
    bottom: 8px;
    right: unset;
  }
`;

export const DashedLine = styled.hr`
  display: none;
  margin: 0;
  border: 0;
  border-top: 3px dashed #e9e9e9;
  position: absolute;
  left: 12px;
  right: 12px;
  top: 32px;
  z-index: -1;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const DomainWrapper = styled.div<{ bgColor: string }>`
  padding: 14px 16px;
  background-color: ${p => p.bgColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  gap: 12px;
`;

export const DomainName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  @media screen and (min-width: 768px) {
    width: min-content;
  }

  @media screen and (min-width: 1280px) {
    font-size: 18px;
    width: 120px;
  }
`;

export const DomainScore = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  @media screen and (min-width: 1280px) {
    font-size: 24px;
  }
`;

export const DimensionWrapper = styled.div`
  margin-top: 12px;
  padding: 0 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px 20px;
  align-items: center;
  max-width: 340px;
  margin: 12px auto 0;
`;

export const DimensionScore = styled.span<{ bgColor: string }>`
  padding: 4px 8px;
  border-radius: 12px;
  background-color: ${p => p.bgColor};
  text-align: center;
`;

export const DimensionName = styled.span`
  font-weight: 600;
`;

export const DimensionIcon = styled.span`
  color: #e9e9e9;
`;
