import i18n from 'helpers/i18n';
import messages from './messages';
import infant from 'images/ageLevels/infant.svg';
import toddler from 'images/ageLevels/toddler.svg';
import preK from 'images/ageLevels/prek.svg';
import k from 'images/ageLevels/k.svg';
import k3 from 'images/ageLevels/k3.svg';
import secondary from 'images/ageLevels/secondary.svg';
import upperElementary from 'images/ageLevels/upper.svg';
import pk3 from 'images/ageLevels/pk3.svg';
import { AgeLevel, AGE_LEVEL_ABBREVIATIONS } from 'types/api/AgeLevel';

type AGE_LEVEL_SHADES = 'base' | 100 | 500 | 900;

type AgeLevelMetadata = {
  name: string;
  abbreviation: string;
  image: string;
  colors: {
    base: string;
    100: string;
    500: string;
    900: string;
  };
};

type AgeLevelData = {
  [key: string]: AgeLevelMetadata;
};

const AGE_LEVELS: AgeLevelData = {
  inf: {
    name: i18n.ft(messages.infant),
    abbreviation: 'Inf',
    image: infant,
    colors: {
      base: '#77923B',
      100: '#E9FBC1',
      500: '#77923B',
      900: '#435E08'
    }
  },
  todd: {
    name: i18n.ft(messages.toddler),
    abbreviation: 'Todd',
    image: toddler,
    colors: {
      base: '#8C0B40',
      100: '#FDC4DC',
      500: '#8C0B40',
      900: '#63082E'
    }
  },
  'pre-k': {
    name: i18n.ft(messages.preK),
    abbreviation: 'Pre-K',
    image: preK,
    colors: {
      base: '#0079C1',
      100: '#B3E3FF',
      500: '#0079C1',
      900: '#004166'
    }
  },
  k: {
    name: i18n.ft(messages.k),
    abbreviation: 'K',
    image: k,
    colors: {
      base: '#0079C1',
      100: '#B3E3FF',
      500: '#0079C1',
      900: '#004166'
    }
  },
  'k-3': {
    name: i18n.ft(messages.k3),
    abbreviation: 'K-3',
    image: k3,
    colors: {
      base: '#FFD615',
      100: '#FFF3BD',
      500: '#FFD615',
      900: '#A88900'
    }
  },
  ue: {
    name: i18n.ft(messages.upperElementary),
    abbreviation: 'UE',
    image: upperElementary,
    colors: {
      base: '#006C67',
      100: '#C5FCFB',
      500: '#006C67',
      900: '#003D3A'
    }
  },
  sec: {
    name: i18n.ft(messages.secondary),
    abbreviation: 'Sec',
    image: secondary,
    colors: {
      base: '#F47B20',
      100: '#FED4B3',
      500: '#F47B20',
      900: '#843C06'
    }
  },
  pk3: {
    name: 'Pre-K\u20133rd',
    abbreviation: 'PK3',
    image: pk3,
    colors: {
      base: '',
      100: '#FFF3BD',
      500: '#FFD615',
      900: '#A88900'
    }
  }
};

const NOT_FOUND_AGE_LEVEL = {
  name: 'Age Level Not Found',
  abbreviation: 'Not Found',
  image: '',
  colors: {
    base: 'gray',
    100: 'lightgray',
    500: 'gray',
    900: 'darkgray'
  }
};

export function getAgeLevel(
  abbreviation: AGE_LEVEL_ABBREVIATIONS
): AgeLevelMetadata {
  const value = abbreviation.toLowerCase();

  switch (value) {
    case 'inf':
      return AGE_LEVELS.inf;
    case 'todd':
      return AGE_LEVELS.todd;
    case 'pre-k':
    case 'pk':
      return AGE_LEVELS['pre-k'];
    case 'k':
      return AGE_LEVELS.k;
    case 'k-3':
      return AGE_LEVELS['k-3'];
    case 'ue':
      return AGE_LEVELS.ue;
    case 'sec':
      return AGE_LEVELS.sec;
    case 'pk3':
    case 'pkk3':
      return AGE_LEVELS.pk3;
    default:
      return NOT_FOUND_AGE_LEVEL;
  }
}

export function getGroupBaseColor(ageLevels: AgeLevel[]): string {
  if (ageLevels.length === 1) {
    const ageLevel = getAgeLevel(ageLevels[0].abbreviation);
    return ageLevel.colors.base;
  } else {
    return '#4F3928';
  }
}

export function getShadeColor(
  abbreviation: AGE_LEVEL_ABBREVIATIONS,
  shade: AGE_LEVEL_SHADES
): string {
  const ageLevel = getAgeLevel(abbreviation);
  return ageLevel.colors[shade];
}
