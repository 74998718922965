import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Other imports
import 'styles/tailwind.css';
import 'semantic-ui-less/semantic.less';
import 'styles/styles.scss';
import 'images/favicon.png';

// Node Modules
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Initializers
import * as I18nInitializer from 'initializers/i18n';

// Pages and store configuration
import IndexPage from 'pages';
import store from 'store/config';

// Init Translations
I18nInitializer.init();

// Render Application
const root = createRoot(document.getElementById('app-wrapper'));
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <IndexPage />
    </Provider>
  </QueryClientProvider>
);
