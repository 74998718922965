import atpIcon from 'images/dashboard/atp.svg';
import calibIcon from 'images/dashboard/calibration.svg';
import cbcIcon from 'images/dashboard/cbc.svg';
import certIcon from 'images/dashboard/certification.svg';
import commIcon from 'images/dashboard/community.svg';
import contractAdminIcon from 'images/dashboard/contract_admin.svg';
import mmciIcon from 'images/dashboard/mmci.svg';
import moodleIcon from 'images/dashboard/moodle.svg';
import mtpIcon from 'images/dashboard/mtp.svg';
import myClassAdminIcon from 'images/dashboard/myclass_admin.svg';
import observationsIcon from 'images/dashboard/observations.svg';
import pDLibraryIcon from 'images/dashboard/resource_catalog.svg';
import trainingsIcon from 'images/dashboard/my_trainings.svg';
import vlIcon from 'images/dashboard/video_library.svg';
import myCoachConnectIcon from 'images/dashboard/myCoachConnect.svg';
import envScalesIcon from 'images/dashboard/envScales.svg';
import RouteHelpers from 'helpers/routes';

import I18nHelper from 'helpers/i18n';

const i18n = new I18nHelper('fe.constants.services');

export type SERVICE_KEYS =
  | 'Certification'
  | 'Calibration'
  | 'MMCI'
  | 'MTP'
  | 'VideoLibrary'
  | 'ATP'
  | 'CTP'
  | 'Moodle'
  | 'CBC'
  | 'MyTrainings'
  | 'Observation'
  | 'myCLASSAdmin'
  | 'Community'
  | 'Reports'
  | 'PDLibrary'
  | 'Totara'
  | 'myCoach Connect'
  | 'Environmental Scales';

export function getService(key: SERVICE_KEYS) {
  const services = {
    Certification: {
      name: i18n.t('recert', 'Certification'),
      icon: certIcon
    },
    Calibration: {
      name: i18n.t('calib', 'Calibration'),
      icon: calibIcon
    },
    MMCI: {
      name: i18n.t('mmci', 'Group Coaching'),
      icon: mmciIcon
    },
    MTP: {
      name: i18n.t('mtp', '1-on-1 Video Coaching'),
      icon: mtpIcon
    },
    VideoLibrary: {
      name: i18n.t('vl', 'Video Library'),
      icon: vlIcon
    },
    ATP: {
      name: i18n.t('atp', 'Training Panel'),
      icon: atpIcon
    },
    CTP: {
      name: i18n.t('atp', 'Training Panel'),
      icon: atpIcon
    },
    Moodle: {
      name: i18n.t('my_courses', 'My Courses'),
      icon: moodleIcon
    },
    CBC: {
      name: i18n.t('cbc', 'CLASS-Based Coaching (CBC)'),
      icon: cbcIcon
    },
    MyTrainings: {
      name: i18n.t('trainings', 'My Training History'),
      icon: trainingsIcon
    },
    Observation: {
      name: i18n.t('observations', 'Observation Data'),
      icon: observationsIcon
    },
    myCLASSAdmin: {
      name: i18n.t('myclass_admin', 'myCLASS Admin'),
      icon: myClassAdminIcon
    },
    Community: {
      name: i18n.t('community', 'Community'),
      icon: commIcon
    },
    Reports: {
      name: i18n.t('contract_admin', 'Contract Admin'),
      icon: contractAdminIcon
    },
    PDLibrary: {
      name: i18n.t('pd_library', 'Learning Resources'),
      icon: pDLibraryIcon
    },
    Totara: {
      name: i18n.t('my_courses', 'My Courses'),
      icon: moodleIcon
    },
    'myCoach Connect': {
      name: i18n.t('mycoach_connect', 'myCoach Connect'),
      icon: myCoachConnectIcon
    },
    'Environmental Scales': {
      name: i18n.t('env_scales', 'CLASS Data Collection'),
      icon: envScalesIcon
    }
  };
  return services[key];
}

export function getServiceURL(key: string, preferredLanguage: string) {
  const lang = preferredLanguage || 'en';

  switch (key) {
    case 'MTP':
      return RouteHelpers.getPath('myclass-video_coaching', { id: 1, lang });
    case 'MMCI':
      return RouteHelpers.getPath('myclass-video_coaching', { id: 2, lang });
    case 'CBC':
      return RouteHelpers.getPath('myclass-video_coaching', { id: 3, lang });
    case 'ATP':
    case 'CTP':
      return RouteHelpers.getPath('myclass-training_portal', { lang });
    case 'Calibration':
      return RouteHelpers.getPath('myclass-calibration', { lang });
    case 'Certification':
      return RouteHelpers.getPath('certifications');
    case 'MyTrainings':
      return RouteHelpers.getPath('td-history');
    case 'Observation':
      return RouteHelpers.getPath('myts-observations');
    case 'myCLASSAdmin':
      return RouteHelpers.getPath('myclass-myclass_admin', { lang });
    case 'Community':
      return RouteHelpers.getPath('community');
    case 'Reports':
      return RouteHelpers.getPath('myclass-contract_admin', { lang });
    case 'PDLibrary':
      return RouteHelpers.getPath('myts-resources');
    case 'myCoach Connect':
      return RouteHelpers.getPath('mycoach-connect');
    case 'Environmental Scales':
      return RouteHelpers.getPath('measure');
    default:
      return '';
  }
}
