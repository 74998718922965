const PATH = 'fe.components.dropdowns.class_familiarity';

const messages = {
  none: {
    key: `${PATH}.none`,
    defaultValue: 'Not Familiar'
  },
  little: {
    key: `${PATH}.little`,
    defaultValue: 'Little Familiar'
  },
  very: {
    key: `${PATH}.very`,
    defaultValue: 'Very Familiar'
  }
};

export default messages;
