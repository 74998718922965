import i18n from 'helpers/i18n';
import messages from './messages';
import closeIcon from 'images/learningResources/close_x_icon.svg';
import nextIcon from 'images/learningResources/right_arrow_icon.svg';

interface BannerProps {
  recommendedVideosCount: number;
  setRecommendedVideosArray: (value: any[]) => void;
  setShowConfirmationModal: (value: boolean) => void;
}

function Banner({
  recommendedVideosCount,
  setRecommendedVideosArray,
  setShowConfirmationModal
}: BannerProps) {
  return (
    <div
      className="flex bg-white -top-1 w-full right-0 justify-center px-2 z-10 fixed xl:px-0"
      style={{ boxShadow: '0px 6px 25px 0px #0000003D' }}
    >
      <div className="flex justify-between" style={{ width: '1360px' }}>
        <div className="flex pl-3">
          <div className="text-2xl font-bold leading-7 text-[#3C3F42] my-8 hidden sm:block">
            {`${recommendedVideosCount} ${i18n.ft(messages.selected)}`}
          </div>
          <button
            className="text-[#0A9CCA] bg-white font-bold py-2 px-4 pl-0 md:pl-4 flex flex-wrap self-center"
            onClick={() => setRecommendedVideosArray([])}
          >
            <img alt="" src={closeIcon} className="flex" />{' '}
            <div className="hidden md:flex self-center ml-2">{`${i18n.ft(
              messages.deselect
            )}`}</div>
            <div className="flex md:hidden self-center ml-2">{`${i18n.ft(
              messages.cancel
            )}`}</div>
          </button>
        </div>
        <button
          className="bg-[#0A9CCA] text-white font-bold py-2 px-4 rounded h-fit self-center focus:shadow-outline"
          onClick={() => setShowConfirmationModal(true)}
        >
          {`${i18n.ft(messages.recommend)} (${recommendedVideosCount})`}
          <img alt="" src={nextIcon} className="inline ml-2" />
        </button>
      </div>
    </div>
  );
}

export default Banner;
