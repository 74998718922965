import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Header, Divider, Button } from 'semantic-ui-react';
import { Container, Message, SegmentStyle } from './Styled';
import { useLocationState } from 'hooks/useLocationState';

type Enrollment = {
  course: string;
  endDate: string;
};

type LocationState = {
  courseType: string;
  enrollments: Enrollment[];
};

function TokenConfirmation() {
  const state = useLocationState<LocationState>();
  const isTotara = state.courseType === 'TotaraCourse';
  const altCourseName = i18n.ft(messages.altCourseName);
  const altEndDate = moment().add(8, 'w').format('YYYY-MM-DD');

  const goToPath = () => {
    // Totara doesn't exist anymore so we should return to Dashboard in case there's
    // an old token linked to a Totara course
    return isTotara ? '/' : RouteHelpers.getPath('canvas');
  };

  const goToLabel = () => {
    return isTotara ? messages.totaraGoTo : messages.canvasGoTo;
  };

  const enrollmentMessage = () => {
    return isTotara ? messages.totaraConfirmation : messages.canvasConfirmation;
  };

  return (
    <Container>
      <SegmentStyle>
        <Header>{i18n.ft(messages.title)}</Header>
        <Divider />
        <Message>
          <div>{i18n.ft(messages.enrolledIn)}</div>
          <ul>
            {state.enrollments.map((enrollment, idx) => (
              <li key={idx}>
                {i18n.ft(messages.courseAndDate, {
                  course: enrollment.course ?? altCourseName,
                  date: enrollment.endDate ?? altEndDate
                })}
              </li>
            ))}
          </ul>
          <div>{i18n.ft(enrollmentMessage())}</div>
        </Message>

        <Button primary as="a" href={goToPath()}>
          {i18n.ft(goToLabel())}
        </Button>
      </SegmentStyle>
    </Container>
  );
}

export default TokenConfirmation;
