import React, { useState } from 'react';
import i18n from 'helpers/i18n';
import useStepStatus from '../useStepStatus';
import messages from './messages';
import {
  Container,
  Header,
  HeaderContainer
} from 'components/Trainings/View/CardCheckout/Styled';
import CreditCardForm from 'components/Trainings/View/CardCheckout/CreditCardForm';
import PurchaseOrderForm from 'components/Trainings/View/CardCheckout/PurchaseOrderForm';
import PropTypes from 'prop-types';
import { Checkbox } from 'components/Trainings/View/CardCheckout/ShippingForm/Styled';
import { Formik, Form } from 'components/EnhancedForm';

const initialValues = {
  paymentType: 'creditCard'
};

const PaymentMethodForm = ({
  step,
  activeStep,
  cart,
  checkout,
  trainingId,
  afterSubmit
}) => {
  const status = useStepStatus(step, activeStep);
  const [paymentType, setPaymentType] = useState('creditCard');

  const handleValues = values => {
    setPaymentType(values);
  };

  const renderPaymentOptions = () => {
    return (
      <Formik initialValues={initialValues}>
        {({ values }) => (
          <Form>
            <Checkbox
              radio
              value="creditCard"
              name="paymentType"
              label={i18n.ft(messages.payByCreditCard)}
              onChange={handleValues}
            />
            <Checkbox
              radio
              value="purchaseOrder"
              name="paymentType"
              label={i18n.ft(messages.payByPurchaseOrder)}
              onChange={handleValues}
            />
          </Form>
        )}
      </Formik>
    );
  };

  const renderCreditCardPayment = () => {
    return (
      <CreditCardForm
        trainingId={trainingId}
        checkout={checkout}
        afterSubmit={afterSubmit}
      />
    );
  };

  const renderPurchaseOrderPayment = () => {
    return (
      <PurchaseOrderForm
        trainingId={trainingId}
        checkout={checkout}
        afterSubmit={afterSubmit}
      />
    );
  };

  return (
    <Container>
      <HeaderContainer>
        <Header fitted={status === 'pending'}>
          {i18n.ft(messages.header)}
        </Header>
      </HeaderContainer>

      {status === 'active' && (
        <React.Fragment>
          {renderPaymentOptions()}
          {paymentType === 'creditCard' && renderCreditCardPayment()}
          {paymentType === 'purchaseOrder' && renderPurchaseOrderPayment()}
        </React.Fragment>
      )}
    </Container>
  );
};

PaymentMethodForm.propTypes = {
  /** The training ID. */
  trainingId: PropTypes.number.isRequired,

  /** The BigCommerce cart */
  cart: PropTypes.object,

  /** The BigCommerce checkout */
  checkout: PropTypes.object,

  /** The payment method form step number. */
  step: PropTypes.number.isRequired,

  /** The current active step number. */
  activeStep: PropTypes.number.isRequired,

  /** Callback fired after the payment has been processed. */
  afterSubmit: PropTypes.func.isRequired
};

export default PaymentMethodForm;
