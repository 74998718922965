const PATH = 'fe.components.trainings.view.card_checkout.shipping_method_form';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: '3. Shipping Method'
  },
  edit: {
    key: `${PATH}.edit`,
    defaultValue: 'Edit'
  },
  continue: {
    key: `${PATH}.continue`,
    defaultValue: 'Continue'
  },
  errors: {
    shippingOption: {
      selectOne: {
        key: `${PATH}.errors.shipping_option.select_one`,
        defaultValue: 'You must select one option'
      }
    }
  }
};

export default messages;
