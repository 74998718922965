import React, { useEffect } from 'react';
import { InformationCard } from '../Styled';
import { useState } from 'react';
import i18n from 'helpers/i18n';
import * as Yup from 'yup';
import messages from './messages';
import { Form, Formik, TextArea } from 'components/EnhancedForm';
import Prompt from 'components/Improvements/Prompt';
import { ImprovementCategory } from 'types/api/improvement/ImprovementCategory';
import useAsync from 'hooks/useAsync';
import {
  getImprovementCategories,
  getImprovementFocuses,
  getImprovementPriorities,
  getImprovementPrompts
} from 'actions/improvements';
import { ImprovementPrompt } from 'types/api/improvement/ImprovementPrompt';
import LevelSelection from './LevelSelection';
import { ImprovementPriority } from 'types/api/improvement/ImprovementPriority';
import { ImprovementFocus } from 'types/api/improvement/ImprovementFocus';
import Strategy from 'pages/Improvements/EducatorProfile/Strategy';
import { updateCoachingCycle } from 'actions/improvements';
import { ImprovementCoachingCycle } from 'types/api/improvement/ImprovementCoachingCycle';
import { NoteType } from 'pages/Improvements/types';
import { SubmitButton } from 'components/Trainings/View/CardCheckout/Styled';
import { Button } from 'semantic-ui-react';
import CardHeader from 'pages/Improvements/CardHeader';
import HideOnPrint from 'pages/Improvements/HideOnPrint';
import { NOTES_MAX_CHAR_LENGTH } from 'pages/Improvements/EducatorProfile/utils';

const VALIDATION_SCHEMA = Yup.object({
  improvement_category_id: Yup.string().required(
    i18n.ft(messages.errors.fieldRequired)
  ),
  improvement_priority_id: Yup.string().required(
    i18n.ft(messages.errors.fieldRequired)
  ),
  improvement_focus_id: Yup.string().required(
    i18n.ft(messages.errors.fieldRequired)
  )
});

interface FocusProps {
  coachingCycle: ImprovementCoachingCycle;
  onFocusSelection: () => void;
}

function Focus({ coachingCycle, onFocusSelection }: FocusProps) {
  const selectedArea = coachingCycle.improvement_area;
  const hasFocus: boolean = !!coachingCycle?.improvement_focus?.id;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isFormComplete, setIsFormComplete] = useState(hasFocus);
  const [isEdit, setIsEdit] = useState(false);

  const { run: runCategory, data: categories } =
    useAsync<ImprovementCategory[]>();
  const { run: runPriority, data: priorities } =
    useAsync<ImprovementPriority[]>();
  const { run: runCategoryPrompt, data: categoryPrompts } =
    useAsync<ImprovementPrompt[]>();
  const { run: runFocus, data: focuses } = useAsync<ImprovementFocus[]>();
  const { run: runPriorityPrompt, data: priorityPrompts } =
    useAsync<ImprovementPrompt[]>();
  const { run: runAreasPrompt, data: areasPrompts } =
    useAsync<ImprovementPrompt[]>();

  const initialValues = {
    improvement_area_note: coachingCycle.area_note?.response || '',
    improvement_category_note: coachingCycle.category_note?.response || '',
    improvement_priority_note: coachingCycle.priority_note?.response || '',
    improvement_focus_note: coachingCycle.focus_note?.response || '',
    improvement_category_id: coachingCycle.improvement_category?.id || null,
    improvement_priority_id: coachingCycle.improvement_priority?.id || null,
    improvement_focus_id: coachingCycle.improvement_focus?.id || null
  };

  useEffect(() => {
    runPriority(getImprovementPriorities({ with_deleted: false }));
    runFocus(getImprovementFocuses({ with_deleted: false }));
    runCategory(getImprovementCategories({ with_deleted: false }));

    if (selectedArea?.id) {
      runAreasPrompt(
        getImprovementPrompts({
          with_deleted: false,
          target_type: 'Improvement::ImprovementArea',
          target_id: selectedArea.id
        })
      );
    }

    if (coachingCycle.improvement_category?.id) {
      handleCategorySelection(coachingCycle.improvement_category.id);
    }
    if (coachingCycle.improvement_priority?.id) {
      handlePrioritySelection(coachingCycle.improvement_priority.id);
    }
    setIsFormComplete(hasFocus);
    // eslint-disable-next-line
  }, [
    runCategory,
    runFocus,
    runPriority,
    runAreasPrompt,
    selectedArea,
    coachingCycle
  ]);

  useEffect(() => {
    setIsCollapsed(true);
  }, [coachingCycle, hasFocus]);

  function handleOpenForm() {
    if (!!categories) {
      setIsCollapsed(false);
    }
  }

  const availableCategories = categories?.filter(
    category => category.area.id === Number(selectedArea?.id)
  );

  const findSelectedPriority = (id: number) =>
    priorities?.find(priority => priority.id === id);

  const handleCategorySelection = (value: any) => {
    runCategoryPrompt(
      getImprovementPrompts({
        with_deleted: false,
        target_type: 'Improvement::ImprovementCategory',
        target_id: value
      })
    );
  };

  const handlePrioritySelection = (value: any) => {
    runPriorityPrompt(
      getImprovementPrompts({
        with_deleted: false,
        target_type: 'Improvement::ImprovementPriority',
        target_id: value
      })
    );
  };

  function handleSubmitForm(values: any) {
    const {
      improvement_category_id,
      improvement_priority_id,
      improvement_focus_id
    } = values;
    const payload = {
      improvement_category_id,
      improvement_priority_id,
      improvement_focus_id,
      notes_attributes: [
        {
          id: coachingCycle.area_note?.id,
          note_type: NoteType.AREA_NOTE,
          response: values.improvement_area_note
        },
        {
          id: coachingCycle.category_note?.id,
          note_type: NoteType.CATEGORY_NOTE,
          response: values.improvement_category_note
        },
        {
          id: coachingCycle.priority_note?.id,
          note_type: NoteType.PRIORITY_NOTE,
          response: values.improvement_priority_note
        },
        {
          id: coachingCycle.focus_note?.id,
          note_type: NoteType.FOCUS_NOTE,
          response: values.improvement_focus_note
        }
      ]
    };
    updateCoachingCycle(coachingCycle.id, payload).then(() => {
      onFocusSelection();
    });

    setIsCollapsed(true);
    setIsFormComplete(true);
    setIsEdit(false);
  }

  function handleEdit() {
    setIsEdit(true);
  }

  const additionalNotesStyles = HideOnPrint(
    isFormComplete,
    'mt-4 basis-2/3 grow'
  );

  const additionalStrategyNotesStyles = HideOnPrint(isFormComplete, '');

  const header = (
    <>
      <header>
        <h2>{i18n.ft(messages.subtitle)}</h2>
      </header>
      <h3>{i18n.ft(messages.title)}</h3>
    </>
  );

  if (isCollapsed && !hasFocus) {
    return (
      <InformationCard className="p-8 mb-6 print:hidden">
        {header}
        <p>{i18n.ft(messages.description1)}</p>
        <p>{i18n.ft(messages.description2)}</p>
        <button
          className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold float-right print:hidden"
          onClick={handleOpenForm}
        >
          {i18n.ft(messages.cta)}
        </button>
      </InformationCard>
    );
  }

  if (isCollapsed && hasFocus) {
    const collapsedTitle = `${i18n.ft(
      messages.coachingCycleFocus
    )}: ${coachingCycle?.improvement_focus?.name}`;
    return (
      <InformationCard className="p-8 w-full flex justify-between items-center">
        <h2 className="text-xl items-center">
          <i className="check circle icon text-[#7AA03F]" />
          <span>{collapsedTitle}</span>
        </h2>
        <div className="align-self-baseline print:hidden">
          <button
            className="text-[#017EA7]"
            onClick={() => setIsCollapsed(false)}
          >
            <strong>{i18n.ft(messages.viewFocusDetails)}</strong>
          </button>
        </div>
      </InformationCard>
    );
  }

  return (
    <InformationCard className="p-8 lg:mb-4">
      <CardHeader
        title={i18n.ft(messages.subtitle)}
        subtitle={i18n.ft(messages.title)}
        showCTA={true}
        onClickAction={() => setIsCollapsed(true)}
      />
      <div className="flex justify-between place-items-center">
        <div>
          {i18n.ft(messages.selectAFocusBy)}
          <ul className="list-disc pl-6">
            <li>{i18n.ft(messages.discussEducatorAndChildrenExperience)}</li>
            <li>{i18n.ft(messages.exploringPotentialPrioritiesAndGoals)}</li>
          </ul>
        </div>
        <div className="flex p-4 gap-4 basis-96 rounded-lg bg-[#F4F8FA]">
          <i className="fa-duotone fa-bullseye text-[#0A9CCA] self-center" />
          <div className="self-center">
            <strong>{i18n.ft(messages.currentAreaOfFocus)} </strong>
            {selectedArea?.name}
          </div>
        </div>
      </div>
      <hr className="my-6" />
      <Formik
        initialValues={initialValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmitForm}
      >
        {({ values, setFieldValue, isValid }) => (
          <Form>
            <h4>{i18n.ft(messages.choosingACategory)}</h4>
            <div className="lg:flex lg:gap-x-4">
              <div className="lg:basis-1/2">
                <div>
                  <h4>{coachingCycle.improvement_area?.question}</h4>
                  <LevelSelection
                    name="improvement_category_id"
                    availableSelections={availableCategories}
                    onSelectionClick={value => {
                      handleCategorySelection(value);
                      setFieldValue('improvement_priority_id', null);
                    }}
                    label={i18n.ft(messages.exploreOptions)}
                    disabled={isFormComplete}
                  />
                </div>
                <div className={additionalNotesStyles}>
                  <TextArea
                    name="improvement_area_note"
                    rows={10}
                    maxLength={NOTES_MAX_CHAR_LENGTH}
                    label={i18n.ft(messages.additionalNotes)}
                    className="mt-4 basis-2/3 grow"
                    disabled={isFormComplete && !isEdit}
                  />
                </div>
              </div>
              <div className={HideOnPrint(isFormComplete, 'lg:basis-1/2')}>
                {areasPrompts.length > 0 && (
                  <Prompt
                    prompts={areasPrompts?.map(prompt => prompt.question) || []}
                  />
                )}
              </div>
            </div>
            {values.improvement_category_id && (
              <div className="break-inside-avoid">
                <hr className="my-6" />
                <h4>{i18n.ft(messages.choosingAPriority)}</h4>
                <div className="lg:flex lg:gap-x-4">
                  <div className="lg:basis-1/2 grow">
                    <p>
                      {
                        categories?.find(
                          category =>
                            category.id === values.improvement_category_id
                        )?.question
                      }
                    </p>
                    <LevelSelection
                      name="improvement_priority_id"
                      label={i18n.ft(messages.exploreOptions)}
                      availableSelections={priorities?.filter(
                        priority =>
                          priority.category.id ===
                          Number(values.improvement_category_id)
                      )}
                      onSelectionClick={value => {
                        handlePrioritySelection(value);
                        setFieldValue('improvement_focus_id', null);
                      }}
                      disabled={isFormComplete}
                    />
                    <div className={additionalNotesStyles}>
                      <TextArea
                        name="improvement_category_note"
                        rows={10}
                        maxLength={NOTES_MAX_CHAR_LENGTH}
                        label={i18n.ft(messages.additionalNotes)}
                        disabled={isFormComplete && !isEdit}
                      />
                    </div>
                  </div>
                  {categoryPrompts && categoryPrompts.length !== 0 && (
                    <div
                      className={HideOnPrint(isFormComplete, 'lg:basis-1/2')}
                    >
                      <Prompt
                        prompts={categoryPrompts.map(prompt => prompt.question)}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {values.improvement_priority_id && (
              <div className="break-inside-avoid">
                <h4>
                  {`${findSelectedPriority(values.improvement_priority_id)
                    ?.name}: 
                  ${findSelectedPriority(values.improvement_priority_id)
                    ?.description}`}
                </h4>
                <p>
                  {
                    findSelectedPriority(values.improvement_priority_id)
                      ?.empathy_statement
                  }
                </p>

                <hr className="my-6" />

                <h4>{i18n.ft(messages.choosingAFocus)}</h4>
                <div className="lg:flex lg:gap-x-4">
                  <div className="lg:basis-1/2 grow">
                    <LevelSelection
                      name="improvement_focus_id"
                      availableSelections={focuses?.filter(
                        focus =>
                          focus.priority.id === values.improvement_priority_id
                      )}
                      label={i18n.ft(messages.exploreOptions)}
                      disabled={isFormComplete}
                    />
                    <div className={additionalNotesStyles}>
                      <TextArea
                        name="improvement_priority_note"
                        rows={10}
                        maxLength={NOTES_MAX_CHAR_LENGTH}
                        label={i18n.ft(messages.additionalNotes)}
                        disabled={isFormComplete && !isEdit}
                      />
                    </div>
                  </div>

                  {priorityPrompts?.length > 0 && (
                    <div
                      className={HideOnPrint(isFormComplete, 'lg:basis-1/2')}
                    >
                      <Prompt
                        prompts={priorityPrompts.map(prompt => prompt.question)}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {values.improvement_focus_id && (
              <div className="break-inside-avoid">
                <hr className="my-6" />
                <Strategy
                  selectedFocus={
                    focuses?.find(f => f.id === values.improvement_focus_id) ||
                    ({} as ImprovementFocus)
                  }
                  showPrompts={!isFormComplete}
                />
                <div className={additionalStrategyNotesStyles}>
                  <TextArea
                    name="improvement_focus_note"
                    rows={10}
                    maxLength={NOTES_MAX_CHAR_LENGTH}
                    label={i18n.ft(messages.additionalNotes)}
                    className="flex"
                    disabled={isFormComplete && !isEdit}
                  />
                </div>
              </div>
            )}

            <SubmitButton>
              {isFormComplete && !isEdit ? (
                <div className={HideOnPrint(isFormComplete, '')}>
                  <Button
                    type="button"
                    className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                    color="blue"
                    onClick={handleEdit}
                    disabled={coachingCycle.has_completed}
                    content={i18n.ft(messages.edit)}
                  />
                </div>
              ) : (
                <Button
                  type="submit"
                  className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                  color="blue"
                  content={i18n.ft(messages.submit)}
                />
              )}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </InformationCard>
  );
}

export default Focus;
