import styled from 'styled-components';
import { Form } from 'semantic-ui-react';

const FormRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: ${props => (props.hidden ? 'none' : null)};
`;

const FormInputWithLabel = styled(Form.Input)`
  margin-bottom: 1em !important;
  opacity: 1 !important;

  label {
    opacity: 1 !important;
  }

  &.disabled {
    .radio label {
      opacity: 0.5 !important;
    }
  }

  &.inline {
    display: flex;

    label {
      flex: 1;
      align-self: center;
    }

    input,
    span,
    textarea,
    .input,
    .selection.dropdown {
      width: 255px !important;
      opacity: 1 !important;
    }
  }

  .ui.input > input {
    width: auto !important;
  }

  &.error {
    margin: 0 !important;
  }

  &.error label {
    color: ${props => props.theme.colors.red} !important;
  }

  &.error > div {
    border-color: ${props => props.theme.colors.red} !important;
  }

  .ui.input.error > input {
    color: ${props => props.theme.colors.red} !important;
  }

  .ui.disabled.checkbox {
    opacity: 0.5 !important;
  }
`;

export { FormRowWrapper, FormInputWithLabel };
