const PATH = 'fe.components.improvements.attachments';

const messages = {
  cardTitle: {
    key: `${PATH}.card_title`,
    defaultValue: 'File Uploads'
  },
  uploadFile: {
    key: `${PATH}.upload_file`,
    defaultValue: 'Upload File'
  },
  fileSelected: {
    key: `${PATH}.file_selected`,
    defaultValue: 'File selected'
  },
  preview: {
    key: `${PATH}.preview`,
    defaultValue: 'Preview'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  submitFile: {
    key: `${PATH}.submit_file`,
    defaultValue: 'Submit File'
  },
  previewParagraph1: {
    key: `${PATH}.preview_paragraph_1`,
    defaultValue:
      'You are about to upload this informal observation for {{educatorsName}}.'
  },
  previewParagraph2: {
    key: `${PATH}.preview_paragraph_2`,
    defaultValue:
      'Check that this PDF is the correct one for this educator before submit the upload.'
  },
  fileTooBig: {
    key: `${PATH}.file_too_big`,
    defaultValue: 'File size cannot be greater than {{maxFileSize}} MB'
  },
  wrongFileType: {
    key: `${PATH}.wrong_file_type`,
    defaultValue: 'File must be an Image or PDF'
  },
  confirmDelete: {
    key: `${PATH}.confirm_delete`,
    defaultValue: 'Are you sure you want to delete file "{{filename}}"?'
  }
};

export default messages;
