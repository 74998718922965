import { clsx } from 'clsx';

import {
  ActiveIcon,
  ExpiredIcon,
  SearchIcon,
  TimeIcon,
  UserIcon,
  ArrowDownIcon,
  ArrowRightIcon
} from './utils';

const iconsMap = {
  time: <TimeIcon />,
  search: <SearchIcon />,
  active: <ActiveIcon />,
  expired: <ExpiredIcon />
};

export interface StatusCardProps {
  count: number;
  title: string;
  icon?: keyof typeof iconsMap;
  arrow?: boolean;
  active?: boolean;
  cardColor?: string;
  titleBgColor?: string;
  hasShadow?: boolean;
  value?: string;
  onClick?: (value: string) => void;
}

export function StatusCard({
  icon,
  count,
  title,
  value,
  onClick,
  active = true,
  arrow = true,
  hasShadow = false,
  cardColor = 'bg-[#ebecec]',
  titleBgColor = 'bg-[#3c3f42]'
}: StatusCardProps) {
  const wrapperClasses = clsx('flex flex-col relative min-w-32', {
    'cursor-pointer': Boolean(onClick)
  });

  const titleContainerClasses = clsx(
    active ? titleBgColor : 'bg-[#636568]',
    'py-2 px-2 rounded-t-lg flex items-center gap-2 flex-1 relative',
    { 'border-x-2 border-t-2 border-[#077699]': Boolean(value) && active }
  );

  const cardClases = clsx(
    cardColor,
    'p-6 rounded-b-lg flex items-center justify-center gap-2',
    {
      'shadow-lg': hasShadow,
      'border-x-2 border-b-2 border-[#077699]': Boolean(value) && active
    }
  );

  const countClasses = clsx(
    'text-4xl font-bold',
    active ? 'text-black' : 'text-[#636568]'
  );

  function handleClick() {
    onClick?.(value || '');
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'Space') {
      handleClick();
    }
  }

  return (
    <div
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={onClick ? 0 : -1}
      role={onClick ? 'button' : undefined}
      className={wrapperClasses}
    >
      <div className={titleContainerClasses}>
        {icon ? <span className="shrink-0">{iconsMap[icon]}</span> : null}
        <span className="text-white text-sm font-bold leading-tight">
          {title}
        </span>

        {arrow && (
          <span className="hidden sm:inline-block absolute top-1/2 -right-[15px] -translate-y-1/2">
            <ArrowRightIcon />
          </span>
        )}
      </div>

      <div className={cardClases}>
        <span className={countClasses}>{count}</span>
        <UserIcon />
      </div>

      {arrow && (
        <span className="sm:hidden absolute -bottom-[14px] right-1/2 translate-x-1/2">
          <ArrowDownIcon />
        </span>
      )}
    </div>
  );
}
