interface SearchTitleProps {
  selectedCategoryId: string;
  categories: any;
  learningResourcesLength: number;
}

function SearchTitle({
  selectedCategoryId,
  categories,
  learningResourcesLength
}: SearchTitleProps) {
  function getCategoryName() {
    const categoryMatch = categories.find(
      (category: any) =>
        category.id.toString() === selectedCategoryId.toString()
    );

    // We might use translations in the future
    return categoryMatch ? categoryMatch.description : '';
  }

  if (selectedCategoryId === 'all' || learningResourcesLength === 0) {
    return null;
  }

  return (
    <div className="flex my-4">
      <div className="text-black text-2xl font-bold">{getCategoryName()}</div>
    </div>
  );
}

export default SearchTitle;
