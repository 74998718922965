import i18n from 'helpers/i18n';
import messages from './messages';
import { FormikHelpers } from 'formik';
import moment from 'moment';

/**
 * If the given error looks like a login error, it sets a message on the related input.
 * Returns a boolean indicating if an error was set for a login input.
 */
export function setLoginError(
  error: any,
  formik: FormikHelpers<any>,
  isFormWithPassword: boolean
): boolean {
  const isLoginError = Boolean(error?.error) || Boolean(error?.locked_until);

  if (!isLoginError) {
    return false;
  }

  let errorMessage;
  let time = moment(error?.locked_until).fromNow(true);

  if (isFormWithPassword) {
    errorMessage = Boolean(error?.locked_until)
      ? i18n.ft(messages.tryAgainIn, { time })
      : i18n.ft(messages.incorrectPassword);
    formik.setFieldError('password', errorMessage);
  } else {
    errorMessage = Boolean(error?.locked_until)
      ? i18n.ft(messages.tryAgainIn, { time })
      : error.error;
    formik.setFieldError('code', errorMessage);
  }

  return true;
}
