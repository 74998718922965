const PATH = 'fe.components.language_selector';

const messages = {
  language: {
    key: `${PATH}.language`,
    defaultValue: 'Language'
  },
  english: {
    key: `${PATH}.english`,
    defaultValue: 'English'
  },
  spanish: {
    key: `${PATH}.spanish`,
    defaultValue: 'Spanish'
  },
  french: {
    key: `${PATH}.french`,
    defaultValue: 'French'
  }
};

export default messages;
