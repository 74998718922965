import { InformationCard } from 'pages/Improvements/EducatorProfile/Styled';
import React from 'react';

interface emptyStateProps {
  sectionHeading: string;
  cardTitle: string;
  cardDescription: string;
  buttonText?: string;
  buttonCallback?: () => void;
}

function EmptyStateCard({
  sectionHeading,
  cardTitle,
  cardDescription,
  buttonText = '',
  buttonCallback = () => {}
}: emptyStateProps) {
  function includeButton() {
    return buttonText ? (
      <button
        className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold float-right"
        onClick={buttonCallback}
      >
        {buttonText}
      </button>
    ) : (
      <></>
    );
  }

  return (
    <InformationCard className="p-8 print:hidden">
      <header>
        <h2>{sectionHeading}</h2>
      </header>
      <h3 id="action-plan-form-label">{cardTitle}</h3>
      <p>{cardDescription}</p>
      {includeButton()}
    </InformationCard>
  );
}

export default EmptyStateCard;
