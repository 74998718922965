import i18n from 'helpers/i18n';
import messages from './messages';
import { Flex } from 'components/Flex';
import { Icon } from 'semantic-ui-react';
import { Wrapper, IconWrapper, Dismiss } from './Styled';

interface WarningProps {
  /** list of warnings */
  warningTexts: Array<string>;

  /** show a close button in the warning popup */
  dismissible?: boolean;

  /** choose the max-width size of popup */
  maxWidth?: number;

  /** parent function will be called when close button is clicked */
  onDismiss?: () => void;

  /** when true popup will show floating below the children component */
  floating?: boolean;

  /**
   * When true popup will appear above the children component.
   *  Children component will be visible when popup is closed.
   */
  overlapChildren?: boolean;

  children?: React.ReactNode;
}

function Warning({
  warningTexts,
  dismissible,
  maxWidth,
  onDismiss,
  floating = true,
  overlapChildren = false,
  children
}: WarningProps) {
  function handleDismiss() {
    onDismiss?.();
  }

  function showChildren() {
    return children && (!overlapChildren || warningTexts.length === 0);
  }

  return (
    <div>
      {showChildren() && <div>{children}</div>}

      {warningTexts.length > 0 && (
        <Wrapper
          maxWidth={maxWidth}
          floating={floating}
          overlapChildren={overlapChildren}
          role="dialog"
        >
          {dismissible && (
            <Dismiss
              type="button"
              onClick={handleDismiss}
              aria-label={i18n.ft(messages.dismiss)}
            />
          )}
          {warningTexts.map((text, index, warnings) => (
            <Flex
              direction="row"
              key={text}
              style={{
                marginTop:
                  index + 1 === warnings.length && warnings.length > 1
                    ? '5px'
                    : '0'
              }}
            >
              <IconWrapper>
                <Icon name="exclamation triangle" color="yellow" />
              </IconWrapper>
              <p>{text}</p>
            </Flex>
          ))}
        </Wrapper>
      )}
    </div>
  );
}

export default Warning;
