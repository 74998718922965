import styled from 'styled-components';

export const Score = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.colors.scaleDarkBlue};
  width: 100%;
  text-align: center;
  position: relative;
  top: -135px;
`;

export const VideoSectionIcon = styled.img`
  margin-left: -35px;
  margin-right: 5px;

  @media screen and (min-width: 640px) {
    margin-left: -15px;
  }
`;
