import { LearningTracks, PlaylistRecord } from 'types/LearningResources/types';
import { PlaylistCurrentTrack } from './PlaylistDetailPage';

export function getCurrentLearningResource(
  playlist: PlaylistRecord & PlaylistCurrentTrack,
  nextVideoId?: number
) {
  let currentLearningResource = playlist.learning_tracks.find(
    (track: LearningTracks) => track.id === playlist.current_track_id
  );

  if (!currentLearningResource) {
    if (nextVideoId) {
      currentLearningResource = playlist.learning_tracks.find(
        (track: LearningTracks) => track.learning_resources.id === nextVideoId
      );

      if (currentLearningResource) {
        return currentLearningResource;
      }
    }

    return playlist.learning_tracks[0];
  }

  return currentLearningResource;
}

export function getNextLearningResource(
  playlist: PlaylistRecord & PlaylistCurrentTrack,
  nextResourceId: number
) {
  const nextResource = playlist.learning_tracks.find(
    (track: LearningTracks) => track.id === nextResourceId
  );

  if (nextResource) {
    return nextResource;
  }

  return playlist.learning_tracks[0];
}
