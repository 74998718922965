const PATH = 'fe.components.users.activate_product_key.form';

const messages = {
  productKey: {
    key: `${PATH}.product_key`,
    defaultValue: 'Product Key'
  },
  activateError: {
    key: `${PATH}.activate_error`,
    defaultValue: 'Product key could not be activated'
  },
  activateProductKey: {
    key: `${PATH}.activate_product_key`,
    defaultValue: 'Activate a Product Key'
  },
  productKeyRequired: {
    key: `${PATH}.product_key_required`,
    defaultValue: 'Product Key is required'
  },
  alreadyHaveAccount: {
    key: `${PATH}.already_have_account`,
    defaultValue: 'Already have an account?'
  },
  logIn: {
    key: `${PATH}.log_in`,
    defaultValue: 'Log In'
  },
  createAccount: {
    key: `${PATH}.create_account`,
    defaultValue: 'Create an account to activate your Product Key.'
  },
  mustBeLoggedIn: {
    key: `${PATH}.must_be_logged`,
    defaultValue: 'You must log in to use this token'
  }
};

export default messages;
