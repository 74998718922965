import styled from 'styled-components';

export const DetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr minmax(100px, 1fr) 0.5fr 0.3fr;
  grid-gap: 8px;
  width: 100%;
  margin-top: 30px;
`;

export const SmallDetailContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 1fr) 0.5fr;
  grid-gap: 8px;
  width: 100%;
  margin-top: 30px;
`;

export const HeaderItem = styled.div`
  background-color: #f6f6f6;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
`;

export const FirstHeaderItem = styled.div`
  background-color: #f6f6f6;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 30px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1.5fr minmax(100px, 1fr) 0.5fr 0.3fr;
  grid-gap: 8px;
  width: 100%;
  margin: 5px 0;
`;

export const SmallContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 1fr) 0.5fr;
  grid-gap: 8px;
  width: 100%;
  margin: 5px 0;
`;

export const Item = styled.div`
  padding: 8px;
  background-color: #f9fbff;
  border-bottom: 1px solid #eaeaec;
  border-radius: 10px;
  font-size: 14px;

  @media screen and (min-width: 640px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
`;
export const FirstItem = styled.div`
  padding: 8px;
  margin-left: 30px;
  background-color: #f9fbff;
  border-bottom: 1px solid #eaeaec;
  border-radius: 10px;
  font-size: 14px;

  @media screen and (min-width: 640px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoaderWrapper = styled.div`
  margin: 64px 0 48px;
`;

export const NoUser = styled.div`
  padding: 8px;
  background-color: #f9fbff;
  border-bottom: 1px solid #eaeaec;
  border-radius: 10px;
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;

  @media screen and (min-width: 640px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
`;

export const EmailName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ItemEmail = styled.div`
  display: flex;
  padding: 8px;
  background-color: #f9fbff;
  border-bottom: 1px solid #eaeaec;
  border-radius: 10px;
  font-size: 14px;

  @media screen and (min-width: 640px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
`;
