import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect, useState } from 'react';
import useTimestamp from 'hooks/useTimestamp';
import { useMediaQuery } from 'react-responsive';
import SubmitScoreSection from '../SubmitScoreSection';
import DimensionScoreInput from '../DimensionScoreInput';
import ScreenReaderOnly from 'components/ScreenReaderOnly';
import IncompleteDimensions from '../IncompleteDimensions';
import { SectionCollapse } from 'components/SectionCollapse';
import {
  isInvalidKey,
  getUserAnswer,
  createUserScore,
  getNewUserScore,
  refreshUserScores,
  getIndexUserScores,
  getUserScoreByDimensionId,
  wasArrowkey
} from './utils';
import { areAllCodesCompleted, showIncompleteBorder } from '../utils';
import { createUserAnswers, updateUserAnswers } from 'actions/userAnswers';
import {
  Name,
  DataGrid,
  DimensionSection
} from 'components/ReliabilityTest/Styled';
import {
  createUserAnswers as createUserPreparationAnswers,
  updateUserAnswers as updateUserPreparationAnswers
} from 'actions/testPreparations';

type DimensionScore = {
  id: number;
  answer: number;
  dimensionId: number;
};

type Dimension = {
  id: number;
  name: string;
  abbreviation: string;
  user_answer: { id: number; answer: number } | null;
};

interface EnterCodesSectionProps {
  testPreparationId: string;
  dimensions: Dimension[];
  videoId: string;
  testType: 'reliability' | 'testPreparations';
  setShowConfirmationModal: (value: boolean) => void;
  updateUserScores?: (value: DimensionScore[]) => void;
}

function EnterCodesSection({
  dimensions,
  testPreparationId,
  videoId,
  testType,
  setShowConfirmationModal,
  updateUserScores
}: EnterCodesSectionProps) {
  const [expanded, setExpanded] = useState(false);
  const [dimensionsCount, setDimensionsCount] = useState(dimensions.length);
  const [userScores, setUserScores] = useState<DimensionScore[]>([]);
  const [completeCodesError, setCompleteCodesError] = useState(false);
  const isTablet = useMediaQuery({ minWidth: 768 });
  const [timestamp, refreshTimestamp] = useTimestamp();
  const [selectedUserAnswer, setSelectedUserAnswer] = useState('');

  useEffect(() => {
    if (!dimensions) {
      return;
    }

    let loadPreviousScores: DimensionScore[] = [];
    setDimensionsCount(dimensions.length);
    dimensions.forEach((dimension: Dimension) => {
      if (dimension.user_answer)
        loadPreviousScores.push({
          id: dimension.user_answer.id,
          answer: dimension.user_answer.answer,
          dimensionId: dimension.id
        });
    });
    setUserScores(loadPreviousScores);
  }, [dimensions]);

  function addUserScore(userScoreValue: DimensionScore) {
    let updateUserScores = [...userScores];

    if (createUserScore(updateUserScores, userScoreValue)) {
      updateUserScores.push(userScoreValue);
      setUserScores(prevUserScores => [...prevUserScores, userScoreValue]);
    } else {
      setUserScores(prevUserScores =>
        refreshUserScores(prevUserScores, userScoreValue)
      );
    }

    if (
      updateUserScores.length === dimensionsCount ||
      dimensionsCount - updateUserScores.length > 3
    ) {
      setCompleteCodesError(false);
    }
  }

  function verifySubmission() {
    if (updateUserScores) {
      updateUserScores(userScores);
    }

    if (areAllCodesCompleted(dimensionsCount, userScores.length)) {
      setShowConfirmationModal(true);
    } else {
      setCompleteCodesError(true);
      setExpanded(true);
      refreshTimestamp();
    }
  }

  function setAccessibilityCode(userScore: {
    id: number | null;
    answer: number;
    dimensionId: number;
    keyCode: string;
  }) {
    if (isInvalidKey(userScore.answer, userScore.keyCode)) {
      return;
    }

    const userScoreIndex = getIndexUserScores([...userScores], userScore);
    let action = userScore.id ? updateUserAnswers : createUserAnswers;
    if (testType === 'testPreparations') {
      action = userScore.id
        ? updateUserPreparationAnswers
        : createUserPreparationAnswers;
    }

    if (userScoreIndex !== -1) {
      // No need to do a request (update score) if the answer is the same
      if (userScores[userScoreIndex].answer !== Number(userScore.answer)) {
        action(testPreparationId, {
          coding_video_id: Number(videoId),
          dimension_id: userScore.dimensionId,
          answer: wasArrowkey(userScore.keyCode)
            ? getNewUserScore(
                userScore.keyCode,
                userScores[userScoreIndex].answer
              )
            : userScore.answer,
          id: userScore.id ? userScore.id : undefined
        }).then(response => {
          addUserScore({
            id: response.data.id,
            answer: response.data.answer,
            dimensionId: userScore.dimensionId
          });
          setSelectedUserAnswer(response.data.answer);
        });
      }
    } else {
      action(testPreparationId, {
        coding_video_id: Number(videoId),
        dimension_id: userScore.dimensionId,
        answer: wasArrowkey(userScore.keyCode) ? 1 : userScore.answer
      }).then(response => {
        addUserScore({
          id: response.data.id,
          answer: response.data.answer,
          dimensionId: response.data.dimension_id
        });
        setSelectedUserAnswer(response.data.answer);
      });
    }
  }

  return (
    <>
      <div className="mt-2 lg:mx-8">
        <ScreenReaderOnly
          message={i18n.ft(messages.announcement, {
            value: selectedUserAnswer
          })}
          ariaLive={'polite'}
          id="userScoreAnnounce"
        />
        <SectionCollapse
          key={timestamp}
          title={i18n.ft(messages.enterCodes)}
          subtitle={i18n.ft(messages.sectionDescription)}
          status={
            completeCodesError
              ? 'error'
              : areAllCodesCompleted(dimensionsCount, userScores.length)
                ? 'completed'
                : 'pending'
          }
          defaultOpen={expanded}
        >
          {dimensions.map((dimension: Dimension, idx: number) => {
            return (
              <DimensionSection
                key={idx}
                style={{
                  border:
                    dimensionsCount - userScores.length < 4
                      ? showIncompleteBorder(
                          dimension.id,
                          userScores,
                          completeCodesError
                        )
                      : ''
                }}
              >
                <DataGrid>
                  <Name
                    tabIndex={0}
                    onKeyDown={e =>
                      setAccessibilityCode({
                        id: getUserScoreByDimensionId(
                          [...userScores],
                          dimension.id
                        ),
                        answer: Number(e.key),
                        dimensionId: dimension.id,
                        keyCode: e.code
                      })
                    }
                  >{`${dimension.name} (${dimension.abbreviation})`}</Name>
                  <DimensionScoreInput
                    testAttemptId={testPreparationId}
                    dimensionId={dimension.id}
                    codingVideoId={Number(videoId)}
                    userAnswer={getUserAnswer(userScores, dimension.id)}
                    addUserScore={addUserScore}
                    testType={testType}
                  />
                </DataGrid>
              </DimensionSection>
            );
          })}
        </SectionCollapse>
      </div>

      <SubmitScoreSection
        isTablet={isTablet}
        completeCodesError={completeCodesError}
        verifySubmission={() => verifySubmission()}
        testType={testType}
      />

      {!isTablet && (
        <div className="pt-2 text-center">
          {<IncompleteDimensions completeCodesError={completeCodesError} />}
        </div>
      )}
    </>
  );
}

export default EnterCodesSection;
