import i18n from 'helpers/i18n';
import messages from './messages';
import TextButton from 'components/TextButton';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import { useState } from 'react';
import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import { StatusCircle } from './StatusCircle';
import { Domain } from 'types/api/envScales/Domain';
import { Dimension } from 'types/api/envScales/Dimension';
import { IndicatorScoreInput } from '../IndicatorScoreInput';
import { DimensionScoreInput } from '../DimensionScoreInput';
import { IndicatorScore } from 'types/api/envScales/IndicatorScore';
import {
  StatusWrapper,
  LineBreak,
  Name,
  DataGrid,
  WarningScore
} from './Styled';
import { updateObservationCycleScores } from 'actions/envScales/observationCycleScores';
import { SectionWithBorder } from 'components/Measure/View/Styled';
import type { IndicatorScoreState } from './utils';
import { ObservationCycle } from 'types/api/envScales/ObservationCycle';
import { useCyclesErrors } from 'context/CyclesErrorsContext';
import { Icon } from 'semantic-ui-react';
import * as R from 'ramda';
import Warning from 'components/Measure/Warning';

import {
  mapIndicatorScores,
  getInitialIndicators,
  calculateSuggestions,
  dimensionsHasErrors,
  removeDimensionFromValidations,
  scoreHasErrors,
  addDimensionInValidations,
  getScoreWarnings
} from './utils';

export interface DimensionCardProps {
  /** Observation Cycle. */
  cycle: ObservationCycle;

  /** Initial dimension score. */
  initialScore: number | null;

  /** Initial array of indicator scores. */
  initialIndicators: IndicatorScore[];

  /** Domain. */
  domain: Domain;

  /** Dimension. */
  dimension: Dimension;

  /** If `true`, the card will show all score input controls. */
  isExpanded: boolean;

  /** Callback fired when `isExpanded` changes. */
  onExpandCollapse: (isExpanded: boolean) => void;
}

export function DimensionCard({
  cycle,
  initialScore,
  initialIndicators,
  domain,
  dimension,
  isExpanded,
  onExpandCollapse
}: DimensionCardProps) {
  const [touched, setTouched] = useState(false);
  const [scoreTouched, setScoreTouched] = useState(false);
  const [score, setScore] = useState<number | null>(initialScore);
  const { cyclesErrors, updateCycleErrors } = useCyclesErrors();
  const [indicatorScores, setIndicatorScores] = useState<IndicatorScoreState>(
    getInitialIndicators(initialIndicators)
  );
  const [scoreWarnings, setScoreWarnings] = useState<Array<string>>([]);

  const indicators = mapIndicatorScores(indicatorScores);

  const indicatorsComplete = indicators.length === dimension.indicators.length;
  const suggestions = calculateSuggestions(
    indicatorsComplete,
    indicators.filter(ind => ind.score !== -1)
  );
  const showWarning = scoreTouched && !indicatorsComplete;

  useDebouncedEffect(
    () => {
      if (!touched) {
        return;
      }

      const payload = {
        observation_cycle_score: {
          dimension_id: dimension.id,
          dimension_score: score,
          indicator_scores: mapIndicatorScores(indicatorScores)
        }
      };

      updateObservationCycleScores(cycle.id, payload);
    },
    1000,
    [score, indicatorScores]
  );

  function handleExpandCollapse() {
    onExpandCollapse(!isExpanded);
  }

  function handleScoreChange(value: number | null) {
    setScoreTouched(true);
    setTouched(true);

    if (indicatorsComplete) {
      setScore(value);
      if (!R.isEmpty(cyclesErrors)) {
        updateCycleErrors(
          removeDimensionFromValidations(
            cyclesErrors,
            cycle.cycle_number,
            dimension.t_name
          )
        );
      }

      if (value !== null) {
        setScoreWarnings(getScoreWarnings(value, dimension));
      } else {
        setScoreWarnings([]);
      }
    }

    if (!Boolean(value) && !R.isEmpty(cyclesErrors)) {
      updateCycleErrors(
        addDimensionInValidations(
          cyclesErrors,
          cycle.cycle_number,
          dimension.t_name
        )
      );
    }
  }

  function handleIndicatorChange(indicatorId: number, value: number | null) {
    setIndicatorScores({ ...indicatorScores, [indicatorId]: value });
    setScore(null);
    setTouched(true);
  }

  return (
    <SectionWithBorder mt="8px">
      <Flex
        wrap="wrap"
        justify="space-between"
        onClick={handleExpandCollapse}
        className="cursor-pointer"
      >
        <TextButton color="darkBlue">
          {dimension.name} ({dimension.abbreviation.toUpperCase()})
        </TextButton>
        <StatusWrapper
          hasErrors={dimensionsHasErrors(
            cyclesErrors,
            cycle.cycle_number,
            dimension.t_name
          )}
        >
          <span>{i18n.ft(messages.indicators)}</span>
          <StatusCircle completed={indicatorsComplete} />
          <Box as="span" ml={{ _: '8px', sm: '48px' }}>
            {i18n.ft(messages.score)}
          </Box>
          <StatusCircle completed={Boolean(score)}>{score}</StatusCircle>
        </StatusWrapper>
      </Flex>

      {isExpanded && (
        <>
          <LineBreak />

          <DataGrid>
            {dimension.indicators.map(indicator => {
              return (
                <IndicatorScoreInput
                  key={indicator.id}
                  indicator={indicator}
                  value={indicatorScores[indicator.id]}
                  domain={domain}
                  onChange={handleIndicatorChange}
                  cycleNumber={cycle.cycle_number}
                  dimension={dimension.t_name}
                />
              );
            })}
            {showWarning && (
              <WarningScore role="alert">
                {i18n.ft(messages.indicatorError)}
              </WarningScore>
            )}
          </DataGrid>

          <Box mt={showWarning ? '12px' : '48px'} mb="16px">
            <DataGrid>
              <div>
                <Warning
                  warningTexts={scoreWarnings}
                  maxWidth={290}
                  dismissible
                  onDismiss={() => setScoreWarnings([])}
                  floating={false}
                  overlapChildren={true}
                >
                  <Name>
                    {i18n.ft(messages.score)}
                    {scoreHasErrors(
                      cyclesErrors,
                      cycle.cycle_number,
                      dimension.t_name
                    ) && (
                      <Icon
                        style={{ marginLeft: '5px' }}
                        color="red"
                        name="exclamation circle"
                      />
                    )}
                  </Name>
                </Warning>
              </div>

              <DimensionScoreInput
                onChange={handleScoreChange}
                value={score}
                suggestedScores={suggestions}
              />
            </DataGrid>
          </Box>
        </>
      )}
    </SectionWithBorder>
  );
}
