import {
  getEducatorById,
  getIntake,
  getInitialObservationNote,
  getImprovementAreas,
  getCoachingCycles,
  createGrowthJourney
} from 'actions/improvements';
import { useState, useEffect, SyntheticEvent } from 'react';
import { getAgeLevels } from 'actions/ageLevels';
import { CustomPrintStyle, EducatorDetails, InformationCard } from './Styled';
import Avatar from 'components/Avatar';
import { DropdownProps, Image, Select } from 'semantic-ui-react';
import mapIcon from 'images/improvements/map-location.svg';
import messages from './messages';
import i18n from 'helpers/i18n';
import { getFullName, getInitials } from 'helpers/user';
import useAsync from 'hooks/useAsync';
import PageLoader from 'components/PageLoader';
import Attachments from 'components/Improvements/Attachments';
import { EducatorInformation, Note } from 'pages/Improvements/types';
import { ImprovementGrowthJourney } from 'types/api/improvement/ImprovementGrowthJourney';
import Intake from '../Intake';
import ToDo from './ToDo/ToDo';
import { useRouteParams } from 'hooks/useRouteParams';
import Focus from './Focus';
import { IntakeObject } from '../Intake/utils';
import InitialObservation from './InitialObservation';
import { ImprovementArea } from 'types/api/improvement/ImprovementArea';
import ActionPlan from 'pages/Improvements/EducatorProfile/ActionPlan';
import { ImprovementCoachingCycle } from 'types/api/improvement/ImprovementCoachingCycle';
import Reflect from 'pages/Improvements/EducatorProfile/Reflect/index';
import Observation from './Observation/Observation';
import CompleteCoachingCycle from './CompleteCoachingCycle';
import moment from 'moment';
import PrintGlobalStyle from 'components/PrintGlobalStyle';
import PrintMetadata from 'pages/Improvements/PrintMetadata';
import useCurrentUser from 'hooks/useCurrentUser';

type educatorProfileIdURLParams = {
  id: string;
};

function EducatorProfile() {
  const { id } = useRouteParams<educatorProfileIdURLParams>();
  const currentUser = useCurrentUser();

  const [showIntake, setShowIntake] = useState(false);
  const [showInitialObservation, setShowInitialObservation] = useState(false);

  const [currentCoachingCycle, setCurrentCoachingCycle] =
    useState<ImprovementCoachingCycle | null>(null);

  const { run, data: educator, isPending } = useAsync<EducatorInformation>();
  const {
    run: runGrowthJourney,
    data: growthJourney,
    isPending: isPendingGrowthJourney
  } = useAsync<ImprovementGrowthJourney>();
  const {
    run: runIntake,
    data: intake,
    setData: setIntakeData
  } = useAsync<IntakeObject>();

  const {
    run: runAgeLevel,
    data: ageLevels,
    isLoading
  } = useAsync<{
    age_levels: [{ id: number; name: string; abbreviation: string }];
  }>();
  const { run: runAreaSelection, data: improvementAreas } =
    useAsync<ImprovementArea[]>();
  const {
    run: runObservation,
    data: initialObservationNote,
    isLoading: isLoadingObservation
  } = useAsync<Note>();
  const {
    run: runCoachingCycles,
    data: coachingCycles,
    isLoading: isLoadingCoachingCycle
  } = useAsync<ImprovementCoachingCycle[]>();

  function handleCreateIntake(data: IntakeObject) {
    setIntakeData(data);
    runCoachingCycles(getCoachingCycles(Number(id)));
  }

  useEffect(() => {
    if (educator?.id) {
      runGrowthJourney(createGrowthJourney(educator.id));
    }
  }, [runGrowthJourney, educator?.id]);

  useEffect(() => {
    run(getEducatorById(Number(id)));
    runIntake(getIntake(Number(id)));
    runCoachingCycles(getCoachingCycles(Number(id)));
    runAgeLevel(getAgeLevels());
    runAreaSelection(getImprovementAreas());
    runObservation(getInitialObservationNote(Number(id)));
  }, [
    run,
    id,
    runIntake,
    runAgeLevel,
    runAreaSelection,
    runObservation,
    runCoachingCycles
  ]);

  useEffect(() => {
    if (!currentCoachingCycle) {
      setCurrentCoachingCycle(
        coachingCycles?.filter(coachingCycle => !coachingCycle.has_completed)[0]
      );
    } else {
      setCurrentCoachingCycle(
        coachingCycles?.filter(
          coachingCycle => coachingCycle.id === currentCoachingCycle.id
        )[0]
      );
    }
  }, [setCurrentCoachingCycle, coachingCycles]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    isPending ||
    isLoading ||
    isLoadingObservation ||
    isLoadingCoachingCycle ||
    isPendingGrowthJourney
  ) {
    return (
      <div className="mt-8">
        <PageLoader />
      </div>
    );
  }

  const inACoachingCycle = coachingCycles?.length > 0 && !!intake?.id;
  const inSecondOrLaterCoachingCycle =
    inACoachingCycle && coachingCycles && coachingCycles.length >= 2;

  const handleIntakeToggle = () => {
    setShowIntake(!showIntake);
  };

  const handleInitialObservationToggle = () => {
    setShowInitialObservation(!showInitialObservation);
  };

  const handleCoachingCycleChange = () => {
    runCoachingCycles(getCoachingCycles(Number(id)));
  };

  const handleCurrentCoachingCycleChange = (
    e: SyntheticEvent,
    { value }: DropdownProps
  ) => {
    setCurrentCoachingCycle(
      coachingCycles?.filter(coachingCycle => coachingCycle.id === value)[0]
    );
  };

  const handleCoachingCycleCompletion = () => {
    setCurrentCoachingCycle(null);
    handleCoachingCycleChange();
  };

  const getClassLanguagesList = () => {
    if (intake.dual_language) {
      return (
        <>
          <p className="mb-0">
            <strong>{i18n.ft(messages.dualLanguageQuestion)}</strong>
            {' ' + i18n.ft(messages.yes)}
          </p>
          <ul className="list-disc pb-4 ml-5">
            <li>{intake.languages?.map(lang => lang.name)?.join(', ')}</li>
          </ul>
        </>
      );
    }
    return (
      <p data-testid="dual_language_question">
        <strong>{i18n.ft(messages.dualLanguageQuestion)}</strong>
        {' ' + i18n.ft(messages.no)}
      </p>
    );
  };

  const getDisabilityList = () => {
    if (intake.disabilities) {
      return (
        <>
          <p className="mb-0">
            <strong>{i18n.ft(messages.disabilitiesQuestion)}</strong>
            {' ' + i18n.ft(messages.yes)}
          </p>
          <ul className="list-disc ml-5">
            <li>{intake.disabilities_description}</li>
          </ul>
        </>
      );
    }

    return (
      <p data-testid="disabilities_question">
        <strong>{i18n.ft(messages.disabilitiesQuestion)}</strong>
        {' ' + i18n.ft(messages.no)}
      </p>
    );
  };

  function displayAgeLevels(intake: IntakeObject) {
    const { age_levels } = ageLevels;

    return age_levels
      .filter(al => intake?.age_levels.includes(al.id))
      .map(filteredAgeLevel => filteredAgeLevel.name)
      .join(', ');
  }

  return (
    <>
      <PrintGlobalStyle />
      <CustomPrintStyle />
      <PrintMetadata />
      <div className="container mx-auto lg:grid lg:grid-cols-[minmax(0,20rem)_auto] lg:grid-row-2 lg:gap-4 lg:my-16 lg:px-8">
        <h1 className="max-lg:pt-8">
          {i18n.ft(messages.pageTitle) + ' ' + getFullName({ ...educator })}
        </h1>
        <h2 className="md:order-first">&nbsp;</h2>
        <div className="flex flex-col gap-6 print:break-after-page">
          <EducatorDetails className="rounded-lg p-8 grid gap-8">
            <header className="flex flex-row gap-2 print:flex-col print:gap-6">
              <div className="self-center print:hidden">
                <Avatar
                  size="large"
                  content={getInitials({ ...educator })}
                  src={educator?.avatar}
                ></Avatar>
              </div>
              <span className="grid grid-rows-2 content-center">
                <h2 className="self-center">{getFullName({ ...educator })}</h2>
                <p className="self-center">{i18n.ft(messages.educator)}</p>
              </span>
              <span className="grid grid-rows-2 content-center hidden print:block">
                <h2 className="self-center">{getFullName(currentUser)}</h2>
                <p className="self-center">{i18n.ft(messages.coach)}</p>
              </span>
            </header>
            <div className="truncate w-auto print:hidden">
              <h3>{i18n.ft(messages.email)}</h3>
              <p className="truncate break-all cursor-default hover:overflow-visible hover:whitespace-normal hover:h-auto">
                {educator.email}
              </p>
            </div>
            <div>
              <h3>{i18n.ft(messages.location)}</h3>
              <p>{educator.center}</p>
            </div>
          </EducatorDetails>
          {inACoachingCycle && (
            <InformationCard className="gap-8 p-8">
              <header className="print:block">
                <h2>{i18n.ft(messages.learningSetting)}</h2>
              </header>
              <h3>{educator.classroom}</h3>
              <p>
                <strong>{i18n.ft(messages.children)}:</strong>{' '}
                {intake?.number_of_children}
              </p>
              <p>
                <strong>{i18n.ft(messages.ages)}:</strong>{' '}
                {displayAgeLevels(intake)}
              </p>

              {getClassLanguagesList()}
              {getDisabilityList()}
            </InformationCard>
          )}

          <InformationCard className="gap-8 p-8 mb-6 print:hidden">
            <Attachments
              growthJourneyId={growthJourney.id}
              educatorsName={getFullName({ ...educator })}
            />
          </InformationCard>
        </div>

        <div className="flex flex-col gap-6">
          {!inACoachingCycle && (
            <InformationCard className="rounded-lg p-8 print:hidden">
              <header>
                <h2>{i18n.ft(messages.coachingFoundation)}</h2>
              </header>
              <h3 className="flex gap-2">
                <Image src={mapIcon} alt="map icon" />
                {' ' + i18n.ft(messages.firstCoachingCycle)}
              </h3>
              <p>{i18n.ft(messages.coachingFoundationPartnership)}</p>
              <p>{i18n.ft(messages.coachingFoundationKickoff)}</p>
              <p>{i18n.ft(messages.coachingFoundationObservation)}</p>
            </InformationCard>
          )}

          {(initialObservationNote?.id || showInitialObservation) && (
            <InitialObservation
              educatorId={educator.id}
              notes={initialObservationNote}
              runObservation={runObservation}
              handleInitialObservationToggle={handleInitialObservationToggle}
              hasCompleted={currentCoachingCycle?.has_completed}
            />
          )}

          {intake?.id || showIntake ? (
            <Intake
              intake={{ ...intake, educator_id: Number(id) }}
              handleCreateIntake={handleCreateIntake}
              improvementAreas={improvementAreas}
              firstName={educator.first_name}
              hasCompleted={currentCoachingCycle?.has_completed}
            />
          ) : (
            <ToDo
              handleToggle={handleIntakeToggle}
              educatorFirstName={educator.first_name}
              toggleStartObservation={
                !!initialObservationNote?.id || showInitialObservation
              }
              handleObservationToggle={handleInitialObservationToggle}
            />
          )}

          {currentCoachingCycle && inACoachingCycle && (
            <>
              {inSecondOrLaterCoachingCycle ? (
                <div>
                  <Select
                    placeholder={i18n.ft(messages.selectACoachingCycle)}
                    options={coachingCycles?.map(coachingCycle => ({
                      key: coachingCycle.id,
                      value: coachingCycle.id,
                      text: coachingCycle.completion_date
                        ? `${i18n.ft(messages.cycleCompletedOn)} ${moment(
                            coachingCycle.completion_date
                          ).format('MMMM Do, YYYY')}`
                        : i18n.ft(messages.currentCoachingCycle)
                    }))}
                    value={currentCoachingCycle?.id}
                    onChange={handleCurrentCoachingCycleChange}
                    className="font-bold text-lg !text-inherit"
                  />
                </div>
              ) : (
                <h3
                  className={
                    !currentCoachingCycle.improvement_focus?.id
                      ? 'print:hidden'
                      : ''
                  }
                >
                  {i18n.ft(messages.currentCoachingCycle)}
                </h3>
              )}

              <Focus
                coachingCycle={currentCoachingCycle}
                onFocusSelection={handleCoachingCycleChange}
              />

              <ActionPlan
                coachingCycle={currentCoachingCycle}
                onCoachingCycleChange={handleCoachingCycleChange}
              />

              {currentCoachingCycle.is_action_plan_completed && (
                <Observation
                  coachingCycle={currentCoachingCycle}
                  educator={educator}
                  onSubmit={handleCoachingCycleChange}
                />
              )}

              {currentCoachingCycle.is_observation_completed && (
                <Reflect
                  isObservationComplete={
                    currentCoachingCycle.is_observation_completed
                  }
                  coachingCycle={currentCoachingCycle}
                  onSubmit={handleCoachingCycleChange}
                />
              )}

              {currentCoachingCycle.is_reflection_completed &&
                !currentCoachingCycle.has_completed && (
                  <CompleteCoachingCycle
                    coachingCycle={currentCoachingCycle}
                    onSubmit={handleCoachingCycleCompletion}
                  />
                )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default EducatorProfile;
