import { clsx } from 'clsx';
import { ReactNode } from 'react';
import { isBlank, getSortIcon } from './utils';

type TableHeader = {
  /** The header content. */
  content: ReactNode;

  /** The name of the data field to pull the content from. */
  accessor: string;

  /** Optional className. */
  className?: string;

  /** When `true`, the header column will support sorting.  */
  sortable?: boolean;

  /**Filter component for header */
  filter?: ReactNode;
};

interface TableProps {
  /** Array of TableHeaders. */
  headers: TableHeader[];

  /** Array of data to render with the table. */
  data: any[];

  /** Active field table is being sorted by. */
  sortBy?: string | null;

  /** Active sorting direction. */
  sortDir?: 'asc' | 'desc' | null;

  /** Callback fired when a sortable column is clicked. */
  onSort?: (header: string) => void;
}

export function Table({ headers, data, sortBy, sortDir, onSort }: TableProps) {
  return (
    <table className="w-full">
      <thead>
        <tr className="border-b border-[#6E2262]">
          {headers.map((header, colIdx) => {
            const thClasses = clsx(
              'text-left font-semibold pt-2 pb-3.5 px-5',
              colIdx === 0 && 'pl-2',
              header.className
            );

            const content = header.sortable ? (
              <button
                className="font-sans"
                onClick={() => onSort?.(header.accessor)}
              >
                <span>{header.content}</span>
                {getSortIcon(header.accessor === sortBy, sortDir)}
              </button>
            ) : header.filter ? (
              <div className="flex gap-2">
                {header.content}
                {header.filter}
              </div>
            ) : (
              header.content
            );

            return (
              <th key={header.accessor} className={thClasses}>
                {content}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody className="text-sm">
        {data.map((row, rowIndex) => (
          <tr key={rowIndex} className="even:bg-[#F7F8F9]">
            {headers.map((header, colIdx) => {
              const tdClasses = clsx('px-5 pt-4 pb-3', colIdx === 0 && 'pl-2');

              return (
                <td key={colIdx} className={tdClasses}>
                  {isBlank(row[header.accessor]) ? '--' : row[header.accessor]}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
