import { useEffect } from 'react';

function useDebouncedEffect(
  callback: () => void,
  delay: number,
  deps: any[] = []
) {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line
  }, [delay, ...deps]);
}

export default useDebouncedEffect;
