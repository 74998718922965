import axios from './axiosConfig';
import { ObservationVideo } from 'types/api/envScales/ObservationVideo';

export type GetObservationVideosRes = {
  observation_videos: ObservationVideo[];
  pagination: {
    current_page: number;
    total_count: number;
    total_pages: number;
  };
};

export function getObservationVideos(params = {}) {
  return axios.get<GetObservationVideosRes>('/api/v1/observation_videos', {
    params
  });
}

export type GetObservationVideoSummaryRes = {
  total_submitted: number;
  total_qa: number;
  total_accepted: number;
  total_in_progress: number;
  total_awaiting_approval: number;
  total_completed: number;
};

export function getObservationVideosSummary() {
  return axios.get<GetObservationVideoSummaryRes>(
    '/api/v1/observation_videos/summary'
  );
}

export type GetObservationVideoByIdRes = ObservationVideo;

export function getObservationVideoById(id: number) {
  return axios.get<GetObservationVideoByIdRes>(
    `/api/v1/observation_videos/${id}`
  );
}

export function assignAdminToVideo(id: number) {
  return axios.put(`/api/v1/observation_videos/${id}/review`);
}

export function acceptObservationVideo(id: number, payload: any) {
  return axios.put(`/api/v1/observation_videos/${id}/accept`, payload);
}

export function rejectObservationVideo(id: number, reason: string) {
  return axios.put(`/api/v1/observation_videos/${id}/reject`, { reason });
}

export function acceptVideoAsObserver(id: number, numOfCycles: number) {
  return axios.put(`/api/v1/observation_videos/${id}/accept_as_observer`, {
    number_of_cycles: numOfCycles
  });
}

export function rejectObservationVideoAsObserver(id: number, reason: string) {
  return axios.put(`/api/v1/observation_videos/${id}/reject_as_observer`, {
    reason
  });
}

export function assignObserverToVideo(id: number) {
  return axios.put(`/api/v1/observation_videos/${id}/assign`);
}

export function returnObservationToSubmitted(id: number) {
  return axios.put(`/api/v1/observation_videos/${id}/return_to_submitted`);
}

export function removeObserverFromVideo(id: number) {
  return axios.put(`/api/v1/observation_videos/${id}/remove_observer`);
}

export function removeAssessmentFromVideo(id: number) {
  return axios.put(`/api/v1/observation_videos/${id}/remove_assessment`);
}
