import styled from 'styled-components';

export const Title = styled.div`
  font-weight: 700;
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TextRequired = styled.span`
  margin-left: 0px;
  display: block;

  @media screen and (min-width: 640px) {
    display: inline;
    margin-left: 10px;
    i {
      margin-left: 5px;
    }
  }
`;

export const CollapseWrapper = styled.div`
  overflow: hidden;
  transition: max-height 700ms;
`;

export const QuestionsBox = styled.div``;

export const LineDivider = styled.hr`
  border: 1px solid #ffffff;
`;

export const Grid = styled.div`
  margin-left: 5px;
  margin-top: 32px;
  display: grid;
  grid-gap: 24px;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const CheckedSquare = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: ${p => p.theme.colors['scaleGreen']};
`;

export const NotCheckedSquare = styled(CheckedSquare)`
  background-color: transparent;
  border: 1px solid #000000;
`;

export const CheckboxInput = styled.input.attrs(_props => ({
  type: 'checkbox'
}))`
  appearance: none;

  width: 32px;
  height: 32px;
  background-color: #fff;
  border: 0.5px solid black;
  border-radius: 4px;
  margin-right: 19px;
  cursor: pointer;

  &:checked {
    background-color: ${p => p.theme.colors.scaleGreen};
    border: 0;
  }

  & + label {
    cursor: pointer;
  }

  &:focus {
    outline: 1px solid ${p => p.theme.colors.scaleGreen};
    outline-offset: 3px;
  }
`;

export const Label = styled.input`
  margin-left: 19px;
`;
