const PATH = 'fe.components.measure.view.score_summary';

const messages = {
  many: {
    key: `${PATH}.many_examples`,
    defaultValue: 'Many Examples'
  },
  some: {
    key: `${PATH}.some_examples`,
    defaultValue: 'Some Examples'
  },
  none: {
    key: `${PATH}.no_examples`,
    defaultValue: 'No Examples'
  },
  notApplicable: {
    key: `${PATH}.not_applicable`,
    defaultValue: 'Not Applicable'
  },
  notFound: {
    key: `${PATH}.not_found`,
    defaultValue: 'Not found'
  },
  editScore: {
    key: `${PATH}.edit_score`,
    defaultValue: 'Need to edit score?'
  },
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Environment Scores'
  },
  clickToExpand: {
    key: `${PATH}.click_to_expand`,
    defaultValue: 'Expand to view more details'
  },
  seeLessDetails: {
    key: `${PATH}.see_less_details`,
    defaultValue: 'See less details'
  },
  scoreTotals: {
    key: `${PATH}.score_totals`,
    defaultValue: '{{score}} of {{total}}'
  }
};

export default messages;
