const PATH = 'fe.pages.users.cm_register';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Create a New Account'
  },
  haveAnAccount: {
    key: `${PATH}.have_an_account`,
    defaultValue: 'Already have an account?'
  },
  login: {
    key: `${PATH}.login`,
    defaultValue: 'Log In'
  }
};

export default messages;
