import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  padding-left: 48px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (min-width: 768px) {
    margin-top: 64px;
    padding-left: 80px;
    padding-right: 48px;
  }

  @media screen and (min-width: 1280px) {
    padding-left: 144px;
    padding-right: 80px;
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
`;

export const LogoImg = styled.img`
  position: absolute;
  top: 0;
  left: -4px;
  transform: translateX(-100%);
  width: 32px;

  @media screen and (min-width: 768px) {
    width: 40px;
    left: -16px;
  }

  @media screen and (min-width: 1920px) {
    width: 48px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: ${p => p.theme.colors['neutral']['700']};

  @media screen and (min-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 36px;
    line-height: 40px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;
  color: ${p => p.theme.colors['neutral']['700']};

  @media screen and (min-width: 768px) {
    margin-top: 4px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 18px;
    line-height: 28px;
  }
`;
