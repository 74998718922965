import { Fragment, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild
} from '@headlessui/react';

const slideInStyleProps = {
  enter: 'transition duration-300',
  enterFrom: '-translate-x-full',
  enterTo: 'translate-x-0',
  leave: 'transition duration-300',
  leaveFrom: 'translate-x-0',
  leaveTo: '-translate-x-full'
};

const fadeInStyleProps = {
  enter: 'transition duration-300',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition duration-300',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0'
};

interface ResponsiveNavigationProps {
  /** Children Component. */
  children: ReactNode;

  /** If `true`, the navigation panel is displayed. */
  isOpen: boolean;

  /** Callback fired when the panel requests to be closed. */
  onClose: () => void;
}

export function ResponsiveNavigation({
  children,
  isOpen,
  onClose
}: ResponsiveNavigationProps) {
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  if (isDesktop) {
    return <>{children}</>;
  } else {
    return (
      <Transition show={isOpen} as={Fragment}>
        <Dialog onClose={onClose}>
          <TransitionChild {...fadeInStyleProps} as={Fragment}>
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          </TransitionChild>

          <TransitionChild {...slideInStyleProps} as={Fragment}>
            <DialogPanel className="fixed inset-0 w-64 top-24 overflow-y-auto">
              <div className="bg-white" onClick={onClose}>
                {children}
              </div>
            </DialogPanel>
          </TransitionChild>
        </Dialog>
      </Transition>
    );
  }
}
