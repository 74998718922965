import i18n from 'helpers/i18n';
import messages from './messages';
import { Icon } from 'semantic-ui-react';

interface CategoryResultProps {
  name: string;
  score: number;
}

function CategoryResult({ name, score }: CategoryResultProps) {
  return (
    <div
      className={`grid grid-cols-6 inline h-7 ${
        score > 50 ? 'bg-[#7AA03F]' : 'bg-[#AC213A]'
      } w-full lg:w-4/5 rounded mb-1`}
      tabIndex={0}
    >
      <div className="inline col-span-5 text-left ml-2 text-base my-auto">
        {name}
        <span className="sr-only">{` ${
          score > 50 ? i18n.ft(messages.passed) : i18n.ft(messages.failed)
        }`}</span>
      </div>
      <div className="inline col-span-1 text-right mr-2">
        <Icon
          name={`${score > 50 ? 'check' : 'close'}`}
          size="tiny"
          className="align-middle translate-y-[1px] text-white"
          style={{ margin: 'auto' }}
        />
      </div>
    </div>
  );
}

export default CategoryResult;
