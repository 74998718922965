const PATH = 'fe.components.toast';

const messages = {
  success: {
    title: {
      key: `${PATH}.success.title`,
      defaultValue: 'Success!'
    },
    description: {
      key: `${PATH}.success.description`,
      defaultValue: 'All your changes have been saved.'
    }
  },
  error: {
    title: {
      key: `${PATH}.error.title`,
      defaultValue: 'Error!'
    },
    description: {
      key: `${PATH}.error.description`,
      defaultValue:
        'There was a problem processing your request. Please try again.'
    }
  }
};

export default messages;
