import i18n from 'helpers/i18n';
import messages from './messages';
import { Button } from 'semantic-ui-react';
import pdf from 'images/reliabilityTest/pdf.svg';
import PassMessage from 'components/ReliabilityTest/PassMessage';
import NotPassMessage from 'components/ReliabilityTest/NotPassMessage';
import DoughnutChartPanel from 'components/ReliabilityTest/DoughnutChartPanel';
import {
  THRESHOLD,
  flexColReverse
} from 'components/ReliabilityTest/constants';
import { Section, HeaderSection } from 'components/ReliabilityTest/Styled';

export interface ResultSectionProps {
  title: string;
  testScore: number;
  ExpertCodes: string;
}

function ResultSection({ title, testScore, ExpertCodes }: ResultSectionProps) {
  return (
    <Section className="mb-2 PrintPaddingBottom">
      <HeaderSection>
        {testScore >= THRESHOLD ? (
          <PassMessage title={title} testType="preparation" />
        ) : (
          <NotPassMessage title={title} />
        )}
      </HeaderSection>
      <div className="grid grid-cols-1 gap-2 auto-rows-[1fr] mt-8 items-center justify-center sm:gap-12 md:grid-cols-2 md:gap-2 lg:gap-12">
        <DoughnutChartPanel
          scoreAbove={i18n.ft(messages.scoreAbove)}
          scoreBelow={i18n.ft(messages.scoreBelow)}
          testScore={testScore}
          videoCriteria={i18n.ft(messages.videoCriteria)}
        />

        <div
          className={`mx-auto text-center ${flexColReverse} PrintTopSection`}
        >
          {/* bottom item */}
          <div className="my-2">
            <div className="text-center">
              <Button
                content={i18n.ft(messages.button)}
                color="blue"
                className="px-4 mx-auto mt-4 md:px-16 PrintMessageButton text-xl"
                as="a"
                target={ExpertCodes ? '_blank' : ''}
                style={{ width: '265px', height: '45px', fontSize: '1.25rem' }}
                href={ExpertCodes ? ExpertCodes : ''}
                disabled={ExpertCodes ? false : true}
              />
            </div>
          </div>
          {/* middle item */}
          <div className="mb-auto">{i18n.ft(messages.ExpertCodes)}</div>
          {/*} top item */}
          <div className="mx-4 sm:mx-14 md:mx-0 mb-auto flex flex-nowrap self-center PrintDimensionGrid">
            <div className="flex justify-center sm:mx-16 md:mx-auto lg:mx-0 lg:w-full">
              <img src={pdf} alt={i18n.ft(messages.pdf)} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ResultSection;
