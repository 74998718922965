import { isNil } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import React, { useState } from 'react';
import Typography from 'components/Typography';
import { Icon, Loader } from 'semantic-ui-react';
import { Container, SelectionContainer, Selection, IconLabel } from './Styled';

import Participant, {
  ParticipantProps
} from 'components/Trainings/View/Participant';

interface AttendanceCardProps extends ParticipantProps {
  /** If `null` attendance has not been taken. */
  attended?: boolean;

  /** If `true` attendance cannot be edited. */
  disabled?: boolean;

  /** If `true`, an alert will be displayed on the card. */
  notRegistered?: boolean;

  /** Callback fired when the participant is marked as present. */
  onMarkAsPresent?: (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;

  /** Callback fired when the participant is marked as absent. */
  onMarkAsAbsent?: (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
}

const AttendanceCard: React.FC<AttendanceCardProps> = ({
  attended,
  disabled,
  notRegistered,
  onMarkAsAbsent,
  onMarkAsPresent,
  ...participantProps
}) => {
  const isPresent = !isNil(attended) && attended;
  const isAbsent = !isNil(attended) && !attended;
  const [markingAsAbsent, setMarkingAsAbsent] = useState(false);
  const [markingAsPresent, setMarkingAsPresent] = useState(false);

  function handleKeyDown(present: boolean) {
    return (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        if (present) {
          handleMarkAsPresent();
        } else {
          handleMarkAsAbsent();
        }
      }
    };
  }

  function handleMarkAsPresent() {
    if (disabled || isPresent || markingAsAbsent || markingAsPresent) {
      return;
    }

    if (onMarkAsPresent) {
      setMarkingAsPresent(true);
      onMarkAsPresent(setMarkingAsPresent);
    }
  }

  function handleMarkAsAbsent() {
    if (disabled || isAbsent || markingAsAbsent || markingAsPresent) {
      return;
    }

    if (onMarkAsAbsent) {
      setMarkingAsAbsent(true);
      onMarkAsAbsent(setMarkingAsAbsent);
    }
  }

  return (
    <Container data-qa={participantProps.email} notRegistered={notRegistered}>
      <div>
        {notRegistered ? (
          <Participant
            {...participantProps}
            icon={{
              circular: true,
              inverted: true,
              color: 'red',
              size: 'tiny',
              name: 'warning'
            }}
            status={
              <Typography
                bold
                color="red"
                content={i18n.ft(messages.notRegistered)}
              />
            }
          />
        ) : (
          <Participant {...participantProps} />
        )}
      </div>

      <SelectionContainer>
        <Selection
          disabled={disabled}
          onClick={handleMarkAsPresent}
          onKeyDown={handleKeyDown(true)}
          tabIndex={disabled ? undefined : 0}
          color={isPresent ? 'green' : 'darkGrey'}
        >
          <IconLabel>{i18n.ft(messages.present)}</IconLabel>
          {markingAsPresent ? (
            <Loader active inline />
          ) : (
            <Icon
              fitted
              size="big"
              name={isPresent ? 'check circle' : 'circle outline'}
            />
          )}
        </Selection>
        <Selection
          disabled={disabled}
          color={isAbsent ? 'red' : 'darkGrey'}
          onClick={handleMarkAsAbsent}
          onKeyDown={handleKeyDown(false)}
          tabIndex={disabled ? undefined : 0}
        >
          <IconLabel>{i18n.ft(messages.absent)}</IconLabel>
          {markingAsAbsent ? (
            <Loader active inline />
          ) : (
            <Icon
              fitted
              size="big"
              name={isAbsent ? 'times circle' : 'circle outline'}
            />
          )}
        </Selection>
      </SelectionContainer>
    </Container>
  );
};

export default AttendanceCard;
