import { Checkbox } from 'components/EnhancedForm';
import { BaseFieldProps } from 'components/EnhancedForm/utils';
import { useField } from 'formik';

import { CheckboxProps as BaseProps } from 'semantic-ui-react';

type Selection = { id: number; name: string };

interface LevelSelectionProps extends BaseFieldProps<BaseProps> {
  availableSelections: Selection[];
  label?: string;
  onSelectionClick?: (value: Selection) => void;
}

function LevelSelection({
  name,
  availableSelections,
  label,
  onSelectionClick = () => {},
  disabled
}: LevelSelectionProps) {
  const [field] = useField({ name });

  return (
    <fieldset
      data-testid={name}
      aria-labelledby={name}
      className="flex flex-col gap-4 px-0"
    >
      {label && (
        <legend data-testid="level-selection-legend" className="font-bold my-4">
          {label}
        </legend>
      )}
      {availableSelections?.map(selection => (
        <Checkbox
          radio
          name={name}
          label={selection.name}
          value={selection.id}
          key={selection.id}
          onChange={onSelectionClick}
          disabled={disabled && field.value !== selection.id}
        />
      ))}
    </fieldset>
  );
}

export default LevelSelection;
