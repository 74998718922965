const PATH = 'fe.components.learning_resources.playlist_item';

const messages = {
  resources: {
    key: `${PATH}.resources`,
    defaultValue: 'Resources'
  },
  package: {
    key: `${PATH}.package`,
    defaultValue: 'Package'
  },
  complete: {
    key: `${PATH}.complete`,
    defaultValue: 'complete'
  }
};

export default messages;
