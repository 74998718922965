import useProductKey from 'components/ActivateProductKey/useProductKey';
import { useLocationState } from 'hooks/useLocationState';
import { useEffect, useState } from 'react';
import {
  Button,
  Input,
  InputOnChangeData,
  Loader,
  Message
} from 'semantic-ui-react';
import { isEmpty } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import clsx from 'clsx';
import externalIcon from 'images/external_link_blue.svg';
import TestList from 'components/Certifications/TestList';
import CertificationList from 'components/Certifications/CertificationList';

type LocationState = {
  showRedeemTokenError: boolean;
  showSuccess: boolean;
  tokenType: string;
};

function MyCertifications() {
  const pKeyRequest = useProductKey();
  const [productKey, setProductKey] = useState('');
  const state = useLocationState<LocationState>();
  const [showMessage, setShowMessage] = useState(state?.showSuccess);

  // Used to trigger a reload on certifications when a CGC token is used.
  const [timestamp, setTimestamp] = useState(Date.now());

  const buttonStyle = clsx(
    'flex border border-[#0A9CCA] text-[#0A9CCA] bg-white font-semibold text-nowrap p-2 rounded h-8 items-center justify-center'
  );

  // The location state will change if a CGC token is successfully used.
  useEffect(() => {
    if (state?.showSuccess && pKeyRequest.isSuccess) {
      setProductKey('');
      setShowMessage(true);
      setTimestamp(Date.now());
    }
  }, [state, pKeyRequest.isSuccess]);

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    if (pKeyRequest.isLoading) {
      return;
    }

    if (isEmpty(data.value)) {
      pKeyRequest.reset();
    }

    setProductKey(data.value);
  }

  function handleActivate() {
    window.localStorage.removeItem('unified_token');
    pKeyRequest.submit(productKey);
  }

  function isRecertification(): boolean {
    return state?.tokenType === 'test_template';
  }

  function getStatusMessage(): string {
    if (
      isRecertification() &&
      state?.showSuccess &&
      state?.showRedeemTokenError
    ) {
      return i18n.ft(messages.recertificationFail);
    }

    if (isRecertification()) {
      return i18n.ft(messages.recertificationSuccess);
    }

    return i18n.ft(messages.productKeySuccess);
  }

  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-between mt-10">
        <div>
          <div className="font-bold pb-1">
            {i18n.ft(messages.useProductKey)}
          </div>
          <Input
            action={
              <Button
                onClick={handleActivate}
                loading={pKeyRequest.isLoading}
                disabled={pKeyRequest.isLoading}
                style={{ backgroundColor: '#3C3F42', color: 'white' }}
              >
                {i18n.ft(messages.activate)}
              </Button>
            }
            placeholder={i18n.ft(messages.enterProductKey)}
            onChange={handleChange}
          />
          {pKeyRequest.isError && (
            <div className="text-red-500 font-bold text-sm pt-1">
              {pKeyRequest.error}
            </div>
          )}
        </div>
        <div className="flex gap-2 mt-2">
          <a
            href="https://teachstone.com/class-certifications"
            target="_blank"
            rel="noreferrer"
            className={buttonStyle}
          >
            {i18n.ft(messages.learnMore)}
            <img
              src={externalIcon}
              alt="external"
              className="w-5 h-5 ml-1 pr-1"
            />
          </a>
          <a
            href={RouteHelpers.getPath('community')}
            className={buttonStyle}
            target="_blank"
            rel="noreferrer"
          >
            {i18n.ft(messages.engageCommunity)}
            <img
              src={externalIcon}
              alt="external"
              className="w-5 h-5 ml-1 pr-1"
            />
          </a>
        </div>
      </div>
      <div>
        {showMessage && (
          <div>
            <Message
              positive={!state.showRedeemTokenError}
              negative={state.showRedeemTokenError}
              onDismiss={() => setShowMessage(false)}
            >
              <Message.Header>{getStatusMessage()}</Message.Header>
            </Message>
          </div>
        )}
        {pKeyRequest.isLoading ? <Loader active /> : <TestList />}
        <CertificationList key={timestamp} />
      </div>
    </div>
  );
}

export default MyCertifications;
