const PATH = 'fe.components.my_educators';

const messages = {
  name: {
    key: `${PATH}.name`,
    defaultValue: 'Name'
  },
  total: {
    key: `${PATH}.total`,
    defaultValue: 'Total Recommended Resources'
  },
  educators: {
    key: `${PATH}.educators`,
    defaultValue: 'My Educators'
  },
  view: {
    key: `${PATH}.view`,
    defaultValue: 'View'
  }
};

export default messages;
