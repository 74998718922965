import i18n from 'helpers/i18n';
import messages from './messages';
import { InformationCard } from 'pages/Improvements/EducatorProfile/Styled';
import React from 'react';
import { useState } from 'react';
import { ImprovementCoachingCycle } from 'types/api/improvement/ImprovementCoachingCycle';
import EmptyStateCard from 'pages/Improvements/EducatorProfile/Reflect/EmptyStateCard';
import { Form, Formik, TextArea } from 'components/EnhancedForm';
import { SubmitButton } from 'components/Trainings/View/CardCheckout/Styled';
import { Button } from 'semantic-ui-react';
import StaticPrompt from 'components/Improvements/StaticPrompt/index';
import { updateCoachingCycle } from 'actions/improvements';
import * as Yup from 'yup';
import moment from 'moment';
import { ImprovementScaleRating } from 'types/api/improvement/ImprovementScaleRating';
import * as yup from 'yup';
import RatingSelection from 'pages/Improvements/EducatorProfile/Reflect/RatingSelection';
import { getImprovementStrategies } from 'actions/improvements';
import useAsync from 'hooks/useAsync';
import { ImprovementStrategy } from 'types/api/improvement/ImprovementStrategy';
import ObservedCueBox from 'pages/Improvements/EducatorProfile/Reflect/ObservedCueBox';
import Prompt from 'components/Improvements/Prompt';
import { NoteType } from 'pages/Improvements/types';
import { useEffect } from 'react';
import CardHeader from 'pages/Improvements/CardHeader';
import HideOnPrint from 'pages/Improvements/HideOnPrint';
import { NOTES_MAX_CHAR_LENGTH } from 'pages/Improvements/EducatorProfile/utils';

interface ReflectProps {
  coachingCycle: ImprovementCoachingCycle;
  isObservationComplete: boolean;
  onSubmit?: () => void;
}

type ReflectForm = {
  reflectNote?: string;
  effective_rating?: string;
  impact_rating?: string;
  confidence_rating?: string;
  progress_rating?: string;
};

const effectivenessOptions = [
  { id: 'not_at_all', name: i18n.ft(messages.effectivenessRating1) },
  { id: 'somewhat', name: i18n.ft(messages.effectivenessRating2) },
  { id: 'completely', name: i18n.ft(messages.effectivenessRating3) }
];
const progressOptions = [
  { id: 'not_at_all', name: i18n.ft(messages.progressRating1) },
  { id: 'somewhat', name: i18n.ft(messages.progressRating2) },
  { id: 'completely', name: i18n.ft(messages.progressRating3) }
];
const confidenceOptions = [
  { id: 'not_at_all', name: i18n.ft(messages.confidenceRating1) },
  { id: 'somewhat', name: i18n.ft(messages.confidenceRating2) },
  { id: 'completely', name: i18n.ft(messages.confidenceRating3) }
];
const impactOptions = [
  { id: 'not_at_all', name: i18n.ft(messages.impactRating1) },
  { id: 'somewhat', name: i18n.ft(messages.impactRating2) },
  { id: 'completely', name: i18n.ft(messages.impactRating3) }
];

const VALIDATION_SCHEMA = Yup.object({
  effective_rating: yup
    .string()
    .required(i18n.ft(messages.errors.fieldRequired)),
  impact_rating: yup.string().required(i18n.ft(messages.errors.fieldRequired)),
  confidence_rating: yup
    .string()
    .required(i18n.ft(messages.errors.fieldRequired)),
  progress_rating: yup.string().required(i18n.ft(messages.errors.fieldRequired))
});

function convertFormScaleRatingToScaleRatings(values: any) {
  return Object.keys(values)
    .filter(key => key.includes('_rating'))
    .map(key => {
      return {
        scale_type: key,
        rating: values[key]
      };
    });
}

function convertToReflectForm(
  coachingCycle: ImprovementCoachingCycle
): ReflectForm {
  const scaleRatings = coachingCycle.improvement_scale_ratings;

  return {
    reflectNote: coachingCycle.reflect_note?.response,
    effective_rating: getRating('effective_rating', scaleRatings),
    impact_rating: getRating('impact_rating', scaleRatings),
    confidence_rating: getRating('confidence_rating', scaleRatings),
    progress_rating: getRating('progress_rating', scaleRatings)
  };
}

function getRating(
  scaleType: string,
  scaleRatings: ImprovementScaleRating[] = []
) {
  return scaleRatings.find(isr => {
    return isr.scale_type === scaleType;
  })?.rating;
}

function Reflect({
  coachingCycle,
  isObservationComplete,
  onSubmit = () => {}
}: ReflectProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const [formValues, setFormValues] = useState({} as ReflectForm);
  const [isEdit, setIsEdit] = useState(false);

  const { run: runStrategies, data: strategies } =
    useAsync<ImprovementStrategy[]>();

  useEffect(() => {
    runStrategies(getImprovementStrategies({ with_deleted: false }));
  }, [setFormValues, runStrategies]);

  useEffect(() => {
    setFormValues(convertToReflectForm(coachingCycle));
    setIsComplete(coachingCycle.is_reflection_completed);
    setIsCollapsed(true);
    setIsEdit(false);
  }, [coachingCycle]);

  const completionDate = moment(coachingCycle?.reflect_note?.created_at)
    .format('MMMM D, YYYY')
    .toString();

  const strategiesForSelectedFocus = strategies?.filter(
    strategy =>
      strategy.improvement_focus
        ?.map(focus => focus?.id)
        .includes(coachingCycle.improvement_focus?.id as number)
  );

  const childCues = coachingCycle.improvement_observed_focus_cues?.filter(
    cue => cue.cue_type === 'Child'
  );
  const educatorCues = coachingCycle.improvement_observed_focus_cues?.filter(
    cue => cue.cue_type === 'Educator'
  );

  function handleOpenForm() {
    setIsCollapsed(false);
  }

  function handleEdit() {
    setIsEdit(true);
  }

  function handleSubmit(values: any) {
    const scaleRatings = convertFormScaleRatingToScaleRatings(values);
    const notes = [
      {
        id: coachingCycle.reflect_note?.id,
        note_type: NoteType.REFLECT_NOTE,
        response: values['reflectNote']
      }
    ];

    const payload = {
      notes_attributes: notes,
      improvement_scale_ratings_attributes: isComplete ? [] : scaleRatings
    };

    updateCoachingCycle(coachingCycle.id, payload).then(response => {
      setFormValues(convertToReflectForm(response.data));
      onSubmit();
    });

    setIsCollapsed(true);
    setIsComplete(true);
    setIsEdit(false);
  }

  //hidden state
  if (!isObservationComplete) {
    return <></>;
  }

  //empty state
  if (isCollapsed && !isComplete) {
    return (
      <EmptyStateCard
        sectionHeading={i18n.ft(messages.reflectSectionTitle)}
        cardTitle={i18n.ft(messages.reflectMainTitle)}
        cardDescription={i18n.ft(messages.reflectDescription)}
        buttonText={i18n.ft(messages.startReflection)}
        buttonCallback={handleOpenForm}
      />
    );
  }

  //review state
  if (isCollapsed && isComplete) {
    const collapsedTitle = `${i18n.ft(
      messages.reflectionTitleCollapse
    )}: ${completionDate}`;
    return (
      <InformationCard className="p-8 w-full flex justify-between items-center">
        <h2 className="text-xl items-center">
          <i className="check circle icon text-[#7AA03F]" />
          <span>{collapsedTitle}</span>
        </h2>
        <div className="align-self-baseline print:hidden">
          <button
            className="text-[#017EA7]"
            onClick={() => setIsCollapsed(false)}
          >
            <strong>{i18n.ft(messages.viewReflectDetails)}</strong>
          </button>
        </div>
      </InformationCard>
    );
  }

  return (
    <>
      <InformationCard className="p-8 lg:mb-4">
        <CardHeader
          title={i18n.ft(messages.reflectSectionTitle)}
          subtitle={i18n.ft(messages.reflectMainTitle)}
          showCTA={true}
          onClickAction={() => setIsCollapsed(true)}
        />
        <p>{i18n.ft(messages.reflectDescription)}</p>
        <h3>{i18n.ft(messages.coachingCycleReviewTitle)}</h3>
        <p>{i18n.ft(messages.coachingCycleReviewSubTitle)}</p>
        <h3>{i18n.ft(messages.focusYouPickedTitle)}</h3>
        <p>{coachingCycle.improvement_focus?.name}</p>
        <h3>{i18n.ft(messages.strategiesYouLearnedAboutTitle)}</h3>
        <ul className="list-disc list-inside pl-3 mb-4">
          {strategiesForSelectedFocus.map(strategy => (
            <li key={strategy.id} className="pb-2">
              {strategy.name}
            </li>
          ))}
        </ul>
        <h4>{i18n.ft(messages.cuesYouExemplifiedTitle)}</h4>
        <div className="grid grid-cols-2 gap-4 mb-6">
          <ObservedCueBox
            focusCues={educatorCues}
            title={i18n.ft(messages.educatorCueTitle)}
          />
          <ObservedCueBox
            focusCues={childCues}
            title={i18n.ft(messages.childrenCueTitle)}
          />
          <ObservedCueBox
            textBlock={coachingCycle.additional_cues_note?.response}
            title={i18n.ft(messages.actionPlanCueTitle)}
          />
          <ObservedCueBox
            generalCues={coachingCycle.improvement_observed_general_cues}
            title={i18n.ft(messages.generalCueTitle)}
          />
        </div>
        <div>
          <h4>{i18n.ft(messages.effectiveExampleObservedTitle)}</h4>
          <div className="mb-8 rounded-lg bg-[#F4F8FA] text-[#364A5E] p-4 h-fit">
            {coachingCycle.optional_summary_note?.response ||
              i18n.ft(messages.noResponseProvided)}
          </div>
        </div>
        <hr className="my-6" />
        <h3 className="print:break-before-page">
          {i18n.ft(messages.evaluationSectionTitle)}
        </h3>
        <p>
          {`${i18n.ft(messages.effectivenessTitle)} ${coachingCycle
            .improvement_focus?.name}`}
        </p>
        <p>
          <strong>{i18n.ft(messages.evaluationSectionHelpText)}</strong>
        </p>
        <Formik
          initialValues={formValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="flex flex-column">
                <div className="w-1/2">
                  <TextArea
                    name="reflectNote"
                    rows={10}
                    maxLength={NOTES_MAX_CHAR_LENGTH}
                    placeholder={i18n.ft(messages.educatorResponsePlaceholder)}
                    className="2xl:mt-10 mt-4 basis-2/3 grow"
                    disabled={isComplete && !isEdit}
                  />
                </div>
                <div className={HideOnPrint(isComplete, 'flex-1 w-1/2 ml-6')}>
                  <Prompt
                    prompts={[
                      i18n.ft(messages.evaluationSectionPrompt1),
                      i18n.ft(messages.evaluationSectionPrompt2),
                      i18n.ft(messages.evaluationSectionPrompt3),
                      i18n.ft(messages.evaluationSectionPrompt4),
                      i18n.ft(messages.evaluationSectionPrompt5)
                    ]}
                  />
                </div>
              </div>
              <div>
                <RatingSelection
                  name="effective_rating"
                  label={i18n.ft(messages.effectivenessRatingQuestion, {
                    focus: coachingCycle.improvement_focus?.name
                  })}
                  availableSelections={effectivenessOptions}
                  onSelectionClick={(value: any) => {
                    setFieldValue('effective_rating', value);
                  }}
                  disabled={isComplete}
                />
              </div>
              <div>
                <RatingSelection
                  name="progress_rating"
                  label={i18n.ft(messages.progressRatingQuestion, {
                    focus: coachingCycle.improvement_focus?.name
                  })}
                  availableSelections={progressOptions}
                  onSelectionClick={(value: any) => {
                    setFieldValue('progress_rating', value);
                  }}
                  disabled={isComplete}
                />
              </div>
              <div>
                <RatingSelection
                  name="confidence_rating"
                  label={i18n.ft(messages.confidenceRatingQuestion, {
                    focus: coachingCycle.improvement_focus?.name
                  })}
                  availableSelections={confidenceOptions}
                  onSelectionClick={(value: any) => {
                    setFieldValue('confidence_rating', value);
                  }}
                  disabled={isComplete}
                />
              </div>
              <div>
                <RatingSelection
                  name="impact_rating"
                  label={i18n.ft(messages.impactRatingQuestion)}
                  availableSelections={impactOptions}
                  onSelectionClick={(value: any) => {
                    setFieldValue('impact_rating', value);
                  }}
                  disabled={isComplete}
                />
              </div>
              <hr className="my-6" />
              <div className="flex flex-column gap-2 mb-6">
                <div className="w-1/2">
                  <h4>{i18n.ft(messages.nextStepsTitle)}</h4>
                  <p>{i18n.ft(messages.nextStepsBody)}</p>
                </div>
                <div className="w-1/2">
                  <StaticPrompt
                    title={i18n.ft(messages.staticPromptNextTitle)}
                    prompt={i18n.ft(messages.staticPromptNextBody)}
                  />
                </div>
              </div>
              <SubmitButton>
                {isComplete && !isEdit ? (
                  <div className={HideOnPrint(isComplete, '')}>
                    <Button
                      type="button"
                      className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                      color="blue"
                      onClick={handleEdit}
                      disabled={coachingCycle.has_completed}
                    >
                      Edit
                    </Button>
                  </div>
                ) : (
                  <Button
                    type="submit"
                    className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                    color="blue"
                  >
                    {i18n.ft(messages.completeCoachingCycle)}
                  </Button>
                )}
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </InformationCard>
    </>
  );
}

export default Reflect;
