import i18n from 'helpers/i18n';
import moment from 'moment';
import messages from './messages';
import { Button } from 'semantic-ui-react';
import { bigCommerceURL } from 'constants/environmentVariables';

function ExpiredRenewable({
  expires_at,
  two_week_extension_sku
}: {
  expires_at: string;
  two_week_extension_sku?: string | null;
}) {
  const productUrl = two_week_extension_sku
    ? `${bigCommerceURL}/cart.php?action=add&sku=${two_week_extension_sku}`
    : `${bigCommerceURL}/two-week-extension`;
  return (
    <>
      <p
        className="text-center text-lg"
        dangerouslySetInnerHTML={{
          __html: i18n.ft(messages.expiredRenewable, {
            date: moment(expires_at).format('MMMM D, YYYY')
          })
        }}
      ></p>
      <div className="grid w-full mt-auto mb-2">
        <Button
          type="button"
          href={productUrl}
          className="mt-5 justify-self-center"
          color="blue"
          target="_blank"
        >
          {i18n.ft(messages.purchaseExtension)}
        </Button>
      </div>
    </>
  );
}

export default ExpiredRenewable;
