import i18n from 'helpers/i18n';
import messages from './messages';
import { VideoSectionIcon } from './Styled';
import ResultsByCategory from 'components/KnowledgeTest/ResultsByCategory';
import { SubtitleSection, Section } from 'components/Reports/Reliability/View';
import performanceReportIcon from 'images/reliabilityTest/performanceReportIcon.svg';
import ShowScores from 'components/ReliabilityTest/PerformanceReportSection/ShowScores';

type Category = {
  name: string;
  score: number;
};

interface PerformanceReportProps {
  scores: number[];
  testType?: 'reliability' | 'preparation' | 'knowledge';
  resultsByCategory?: Category[];
  overallScore?: number;
}

function PerformanceReportSection({
  scores,
  testType,
  resultsByCategory,
  overallScore
}: PerformanceReportProps) {
  const knowledgeTest = testType === 'knowledge' ? true : false;

  return (
    <Section
      sm={knowledgeTest}
      className={`${
        testType === 'preparation' ? 'PrintMarginTop' : ''
      } PrintPageBreakAfter PrintMarginY`}
    >
      <SubtitleSection
        className={'PrintMarginY'}
        style={{ padding: `${knowledgeTest ? '24px 40px' : 'inherit'}` }}
      >
        <VideoSectionIcon
          src={performanceReportIcon}
          alt={i18n.ft(messages.title)}
          tabIndex={0}
        />
        {i18n.ft(messages.title)}
      </SubtitleSection>
      <p
        className={`mx-0 mb-16 md:mx-16 ${knowledgeTest ? ' ml-10 mr-10' : ''}`}
        tabIndex={0}
      >
        {i18n.ft(messages.description)}
      </p>
      {testType === 'knowledge' && (
        <ResultsByCategory
          overallScore={overallScore}
          categories={resultsByCategory}
        />
      )}
      {testType !== 'preparation' && (
        <ShowScores scores={scores} subtitle={i18n.ft(messages.videoResults)} />
      )}
    </Section>
  );
}

export default PerformanceReportSection;
