import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  padding: 7px 12px;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;

  &:focus-within {
    border-color: #85b7d9;
  }

  input {
    padding: 0;
    margin: 0;
    border: 0;
    font-family: inherit;
    outline: none;
    width: 100%;
    background: transparent;
    appearance: none;
  }

  input::-webkit-inner-spin-button,
  input::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;

export const InputSeparator = styled.span`
  background-color: #f4f8fa;
  padding: 4px 6px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 16px;
  color: #364a5e;
  font-weight: 700;
  margin: 0 8px;
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
`;

export const LabelContent = styled.span`
  display: block;
`;

export const Optional = styled.span`
  margin-left: 8px;
  font-weight: 400;
  color: #86868a;
`;

export const Required = styled.span`
  margin-left: 8px;
  color: #e71921;
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  color: ${p => p.theme.colors['scaleWarning']};
  padding-top: 8px;
  height: 24px;
`;
