import React from 'react';
import moment from 'moment';
import { isEmpty } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import ShowTestPrepLink from './ShowTestPrepLink';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.5;
  color: ${p => p.theme.colors['neutral']['700']};
`;

const MessageIcon = styled.span`
  margin-right: 4px;
`;

interface StatusMessageProps {
  expired?: boolean;
  endDate?: string;
  renewable?: boolean;
  renewableOn?: string;
  renewableUntil?: string;
  renewableDays?: number;
  timezone?: string;
  renewableTime?: string;
  testPreparationTemplateId?: number;
  currentAttemptId?: number | null;
  currentAttemptNumber?: number | null;
  transitionedTo2ndEdition?: boolean;
}

const StatusMessage: React.FC<StatusMessageProps> = ({
  expired,
  endDate,
  renewable,
  renewableOn,
  renewableUntil,
  renewableDays,
  timezone,
  renewableTime,
  testPreparationTemplateId,
  currentAttemptId,
  currentAttemptNumber,
  transitionedTo2ndEdition
}) => {
  const isLessThan768px = useMediaQuery({ maxWidth: 767 });

  const status = {
    message: '',
    label: i18n.ft(messages.status.active),
    icon: <Icon name="check circle" color="green" />
  };

  const today = moment();
  const expiresOn = moment(endDate);
  const renewOnDate = moment(renewableOn);
  const renewUntilDate = moment(renewableUntil);
  const willRenew = renewOnDate.isValid() && renewOnDate.isAfter(today);
  const wasRenewable =
    renewUntilDate.isValid() && renewUntilDate.isBefore(today);

  if (transitionedTo2ndEdition) {
    status.label = i18n.ft(messages.transitioned);
  } else if (expired) {
    status.label = isLessThan768px
      ? i18n.ft(messages.status.expiredOn, {
          date: expiresOn.format('L')
        })
      : i18n.ft(messages.status.expired);
    status.icon = <Icon name="warning sign" color="red" />;
  } else if (renewable) {
    status.label = isLessThan768px
      ? `${i18n.ft(messages.status.expiresOn, {
          date: expiresOn.format('L')
        })} ${timezone}`
      : i18n.ft(messages.status.aboutToExpire);
    status.icon = <Icon name="warning sign" color="yellow" />;
  } else if (isLessThan768px && expiresOn.isValid()) {
    status.label = `${i18n.ft(messages.status.expiresOn, {
      date: expiresOn.format('L')
    })} ${timezone}`;
  }

  if (transitionedTo2ndEdition) {
    status.message = '';
  } else if (renewable) {
    status.message = i18n.ft(messages.status.daysLeft, {
      count: renewableDays
    });
  } else if (willRenew) {
    status.message = `${i18n.ft(messages.status.renewOn, {
      date: renewOnDate.format('L')
    })} ${renewableTime}`;
  } else if (wasRenewable) {
    status.message = i18n.ft(messages.status.contact);
  }

  return (
    <Container>
      <MessageIcon>{status.icon}</MessageIcon>
      <span>
        {status.label}
        {!isEmpty(status.label) && !isEmpty(status.message) && (
          <>&nbsp;&nbsp;•&nbsp;&nbsp;</>
        )}
        <span dangerouslySetInnerHTML={{ __html: status.message }} />
        <ShowTestPrepLink
          label={status.label}
          message={status.message}
          renewable={renewable}
          testPreparationTemplateId={testPreparationTemplateId}
          currentAttemptId={currentAttemptId}
          currentAttemptNumber={currentAttemptNumber}
          transitionedTo2ndEdition={transitionedTo2ndEdition}
        />
      </span>
    </Container>
  );
};

export default StatusMessage;
