import i18n from 'helpers/i18n';
import messages from "./messages";
import { Select } from 'components/EnhancedForm';

const sortOptions = [
  {
    key: 'id',
    text: `${i18n.ft(messages.sortBy)} ${i18n.ft(messages.options.newest)}`,
    value: 'id'
  },
  {
    key: 'name',
    text: `${i18n.ft(messages.sortBy)} ${i18n.ft(messages.options.alphabetical)}`,
    value: 'name'
  },
  {
    key: 'most_viewed',
    text: `${i18n.ft(messages.sortBy)} ${i18n.ft(messages.options.mostPopular)}`,
    value: 'most_viewed'
  }
];

function SortDropdown({ handleSort }: { handleSort: (value: string) => void }) {
  return (
      <Select
          name="sort"
          options={sortOptions}
          onChange={handleSort}
      />
  );
}

export default SortDropdown;
