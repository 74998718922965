import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const Container = styled.div`
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
`;

export const SubmitButton = styled.div`
  text-align: right;
`;

export const Header = styled.h1<{ fitted?: boolean }>`
  && {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: ${props => (props.fitted ? '0px' : '16px')};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderIcon = styled.span`
  margin-left: 8px;
`;

export const HeaderButton = styled(Button)`
  align-self: flex-start;
`;
