const PATH = 'fe.pages.tokens.view';

const messages = {
  pleaseWait: {
    key: `${PATH}.please_wait`,
    defaultValue: 'Please wait while we process your request.'
  },
  expiredLink: {
    key: `${PATH}.expired_link`,
    defaultValue:
      'This link is no longer valid. If you need help, please reach out to our customer support team by email: <a target="_blank" href="mailto:contact@teachstone.com">contact@teachstone.com</a> or by text: <a target="_blank" href="tel:+18669988352">866.998.8352</a>'
  },
  goBack: {
    key: `${PATH}.go_back`,
    defaultValue: 'Go back to myTeachstone'
  }
};

export default messages;
