import { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { Navigate, Outlet } from 'react-router-dom';
import { hasPermission } from 'actions/improvements';
import useAsync from 'hooks/useAsync';

function Improvements() {
  const { run, isPending, isError } = useAsync();
  useEffect(() => {
    run(hasPermission());
  }, [run]);

  if (isPending) {
    return <Loader active inline="centered" />;
  }

  if (isError) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}

export default Improvements;
