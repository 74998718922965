import moment from 'moment';

export function formatDate(date: Date) {
  return moment(date).format('L');
}

export const NotepadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75 3.5V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C7.5 3.53 7.73 3.5 8 3.5H8.75Z"
      fill="#017EA7"
    />
    <path
      d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H16C16.27 3.5 16.5 3.53 16.75 3.56Z"
      fill="#017EA7"
    />
    <path
      d="M16.75 3.56V5C16.75 5.41 16.41 5.75 16 5.75C15.59 5.75 15.25 5.41 15.25 5V3.5H8.75V5C8.75 5.41 8.41 5.75 8 5.75C7.59 5.75 7.25 5.41 7.25 5V3.56C4.3 3.83 3 5.73 3 8.5V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V8.5C21 5.73 19.7 3.83 16.75 3.56ZM12 16.75H8C7.59 16.75 7.25 16.41 7.25 16C7.25 15.59 7.59 15.25 8 15.25H12C12.41 15.25 12.75 15.59 12.75 16C12.75 16.41 12.41 16.75 12 16.75ZM16 11.75H8C7.59 11.75 7.25 11.41 7.25 11C7.25 10.59 7.59 10.25 8 10.25H16C16.41 10.25 16.75 10.59 16.75 11C16.75 11.41 16.41 11.75 16 11.75Z"
      fill="#017EA7"
    />
  </svg>
);

interface LampIconProps {
  /** Optional className. */
  className?: string;

  /** Icon color */
  color?: string;
}

export const LampIcon = ({ className, color = '#F99808' }: LampIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.2602 22.0004C15.2002 22.0004 15.1302 21.9904 15.0702 21.9704C13.0602 21.4004 10.9502 21.4004 8.94015 21.9704C8.57015 22.0704 8.18015 21.8604 8.08015 21.4904C7.97015 21.1204 8.19015 20.7304 8.56015 20.6304C10.8202 19.9904 13.2002 19.9904 15.4602 20.6304C15.8302 20.7404 16.0502 21.1204 15.9402 21.4904C15.8402 21.8004 15.5602 22.0004 15.2602 22.0004Z"
      fill={color}
    />
    <path
      d="M19.2102 6.35952C18.1702 4.25952 16.1602 2.70952 13.8302 2.19952C11.3902 1.65952 8.89021 2.23952 6.98021 3.77952C5.06021 5.30952 3.97021 7.59952 3.97021 10.0495C3.97021 12.6395 5.52022 15.3495 7.86021 16.9195V17.7495C7.85021 18.0295 7.84021 18.4595 8.18021 18.8095C8.53021 19.1695 9.05021 19.2095 9.46021 19.2095H14.5902C15.1302 19.2095 15.5402 19.0595 15.8202 18.7795C16.2002 18.3895 16.1902 17.8895 16.1802 17.6195V16.9195C19.2802 14.8295 21.2302 10.4195 19.2102 6.35952ZM13.7202 11.6195L12.6502 13.4795C12.5102 13.7195 12.2602 13.8595 12.0002 13.8595C11.8702 13.8595 11.7402 13.8295 11.6302 13.7595C11.2702 13.5495 11.1502 13.0895 11.3502 12.7395L12.2002 11.2595H11.3602C10.8602 11.2595 10.4502 11.0395 10.2302 10.6695C10.0102 10.2895 10.0302 9.82952 10.2802 9.38952L11.3502 7.52952C11.5602 7.16952 12.0202 7.04952 12.3702 7.24952C12.7302 7.45952 12.8502 7.91952 12.6502 8.26952L11.8002 9.74952H12.6402C13.1402 9.74952 13.5502 9.96952 13.7702 10.3395C13.9902 10.7195 13.9702 11.1895 13.7202 11.6195Z"
      fill={color}
    />
  </svg>
);

interface ListItemProps {
  /** List item description. */
  content: string;
}

export function ListItem({ content }: ListItemProps) {
  return (
    <div className="flex items-start gap-5">
      <LampIcon className="shrink-0" />
      <span className="leading-tight">{content}</span>
    </div>
  );
}
