const PATH = 'fe.components.dashboard.professional_development';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'My Professional Development'
  },
  learning: {
    key: `${PATH}.learning`,
    defaultValue: 'Learning Hub'
  },
  learningResources: {
    key: `${PATH}.learning_resources`,
    defaultValue: 'Learning Resources'
  },
  explore: {
    key: `${PATH}.explore`,
    defaultValue: 'Explore'
  },
  status: {
    key: `${PATH}.status`,
    defaultValue: 'Status'
  },
  available: {
    key: `${PATH}.available`,
    defaultValue: 'Available'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed'
  },
  suspended: {
    key: `${PATH}.suspended`,
    defaultValue: 'Suspended'
  },
  upcoming: {
    key: `${PATH}.upcoming`,
    defaultValue: 'Upcoming'
  },
  closed: {
    key: `${PATH}.closed`,
    defaultValue: 'Closed'
  },
  showAll: {
    key: `${PATH}.show_all`,
    defaultValue: 'Show all available courses'
  },
  showLess: {
    key: `${PATH}.show_les`,
    defaultValue: 'Show abbreviated list'
  },
  recommended: {
    key: `${PATH}.recommended_by`,
    defaultValue: 'Recommended By'
  },
  on: {
    key: `${PATH}.on`,
    defaultValue: 'On'
  },
  getCertificates: {
    key: `${PATH}.get_certificates`,
    defaultValue: 'Get Certificates'
  },
  getCertificate: {
    key: `${PATH}.get_certificate`,
    defaultValue: 'Get Certificate'
  }
};

export default messages;
