const PATH = 'fe.components.reports.observation.date_range_picker';

const messages = {
  to: {
    key: `${PATH}.to`,
    defaultValue: 'to'
  }
};

export default messages;
