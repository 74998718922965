import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Select } from 'semantic-ui-react';
import { parseAPIErrorsNoKeys } from 'components/ErrorModal';
import { acceptVideoAsObserver } from 'actions/envScales/observationVideos';

import ConfirmationModal, {
  CallbackProps
} from 'components/Modals/ConfirmationModal';

interface ApproveInProgressModalProps {
  /** Observation Video ID. */
  observationVideoId: number;

  /** Used to determine if modal should open or not. */
  agreeToTerms: boolean;

  /** Callback fired when modal tries to be opened without agreeing to terms. */
  onError: () => void;
}

function ApproveInProgressModal({
  observationVideoId,
  agreeToTerms,
  onError
}: ApproveInProgressModalProps) {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errorContent, setErrorContent] = useState<string[]>([]);
  const [numOfCycles, setNumOfCycles] = useState(2);

  const cycles = [1, 2, 3, 4, 5, 6];
  const cycleOptions = cycles.map(cycle => ({
    key: cycle,
    value: cycle,
    text: i18n.ft(messages.numOfCycles, { count: cycle })
  }));

  async function handleConfirm(modal: CallbackProps) {
    try {
      const { data } = await acceptVideoAsObserver(
        observationVideoId,
        numOfCycles
      );

      navigate(
        RouteHelpers.getPath('measure-view', { id: data.assessment_id })
      );
    } catch (error: any) {
      setIsError(true);
      setErrorContent(parseAPIErrorsNoKeys(error.response.data));
      modal.setLoading(false);
    }
  }

  function handleOpen(setIsOpen: (isOpen: boolean) => void) {
    if (agreeToTerms) {
      setIsOpen(true);
    } else {
      onError();
    }
  }

  return (
    <ConfirmationModal
      icon="clipboard check"
      title={i18n.ft(messages.approveVideo)}
      message={
        <div>
          <div className="font-bold">{i18n.ft(messages.content)}</div>

          <label htmlFor="cycles" className="mt-10 block font-bold">
            {i18n.ft(messages.cyclesLabel)}
          </label>
          <Select
            fluid
            id="cycles"
            className="mt-3"
            value={numOfCycles}
            options={cycleOptions}
            onChange={(_, data) => setNumOfCycles(Number(data.value))}
          />

          {isError && (
            <div className="mt-3 text-red-700">
              {i18n.ft(messages.error) + ' '}
              {errorContent}
            </div>
          )}
        </div>
      }
      confirmColor="blue"
      confirmButton={i18n.ft(messages.enterScores)}
      onConfirm={handleConfirm}
      onOpen={handleOpen}
      triggerNode={
        <Button color="blue" className="col-start-4">
          {i18n.ft(messages.approveVideo)}
        </Button>
      }
    />
  );
}

export default ApproveInProgressModal;
