import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import App from 'pages/App';
import Account from 'pages/Account';
import AccountHome from 'pages/Account/Home';
import AccountPhone from 'pages/Account/Phone';
import AccountPassword from 'pages/Account/Password';
import AccountExperience from 'pages/Account/Experience';

import Certifications from 'pages/Certifications';
import Dashboard from 'pages/dashboard';
import DashboardCopy from 'pages/DashboardCopy';
import Improvements from 'pages/Improvements';
import CoachingRoster from 'pages/Improvements/CoachingRoster';
import EducatorProfile from 'pages/Improvements/EducatorProfile';
import InvitationsView from 'pages/Invitations/View';

import Measure from 'pages/Measure';
import MeasureHome from 'pages/Measure/Home';
import MeasureCreate from 'pages/Measure/Create';
import MeasureView from 'pages/Measure/View';
import MeasureSummary from 'pages/Measure/Summary';
import MeasureCompleted from 'pages/Measure/Completed';
import MeasureObservationVideos from 'pages/Measure/ObservationVideos';
import MeasureObservationVideosView from 'pages/Measure/ObservationVideos/View';

import NotFound from 'pages/NotFound';
import ObservationReport from 'pages/Reports/Observation';
import CreateReportConfiguration from 'pages/Reports/Observation/Create';
import EditReportConfiguration from 'pages/Reports/Observation/View';
import TestReport from 'pages/Reports/Test';
import Organization from 'pages/Organization';
import ScoreReport from 'pages/Testing/Reliability/ScoreReport';
import TestAttempts from 'pages/TestAttempts';
import TestPreparations from 'pages/TestPreparations';
import TestPreparationsScoreReport from 'pages/TestPreparations/ScoreReport';
import TestTemplate from 'pages/TestTemplate';
import TokenConfirmation from 'pages/TokenConfirmation';
import TrainerViewTestAttempts from 'pages/Testing/Reliability/TestAttempts';

import Trainings from 'pages/Trainings';
import TrainingsHome from 'pages/Trainings/Home';
import TrainingsEdit from 'pages/Trainings/Edit';
import TrainingsView from 'pages/Trainings/View';
import TrainingsCheckout from 'pages/Trainings/View/Checkout';
import TrainingsCardCheckout from 'pages/Trainings/View/CardCheckout';
import TrainingsCheckoutCompleted from 'pages/Trainings/View/CheckoutCompleted';
import TrainingsCancellationPolicy from 'pages/Trainings/CancellationPolicy';
import TrainingViewDetails from 'components/Trainings/View/Details';
import TrainingViewParticipants from 'components/Trainings/View/Participants';
import TrainingViewAttendance from 'components/Trainings/View/Attendance';

import TrainingCatalog from 'pages/TrainingCatalog';
import TrainingCatalogHome from 'pages/TrainingCatalog/Home';
import TrainingCatalogCreate from 'pages/TrainingCatalog/Create';

import TrainingHistory from 'pages/TrainingHistory';
import UserChoiceProductKey from 'pages/UserChoiceProductKey';
import LearningPathwayProductKey from 'pages/LearningPathwayProductKey';

import Tokens from 'pages/Tokens';
import TokensView from 'pages/Tokens/View';
import TokensEnter from 'pages/Tokens/Enter';

import Users from 'pages/Users';
import UsersActivateProductKey from 'pages/Users/ActivateProductKey';
import UsersCMLogin from 'pages/Users/CMLogin';
import UsersCMRegister from 'pages/Users/CMRegister';
import UsersForgotPassword from 'pages/Users/ForgotPassword';
import UsersLogin from 'pages/Users/Login';
import UsersLogout from 'pages/Users/Logout';
import UsersSetPassword from 'pages/SetPassword';
import UsersOnboarding from 'pages/Users/Onboarding';

import PageLoader from 'components/PageLoader/PageLoader';
import RecertificationProductKey from 'pages/RecertificationProductKey';
import LearningResources from 'pages/LearningResources';
import CoachLearningResources from 'pages/Coach';

const AdminDashboard = lazy(() => import('pages/Admin'));

export function RoutesConfig() {
  return (
    <Routes>
      <Route
        path="admin/*"
        element={
          <Suspense fallback={<PageLoader />}>
            <AdminDashboard />
          </Suspense>
        }
      />

      <Route path="users" element={<Users />}>
        <Route path="login" element={<UsersLogin />} />
        <Route path="logout" element={<UsersLogout />} />
        <Route path="confirmation" element={<UsersSetPassword />} />
        <Route path="reset-password" element={<UsersSetPassword />} />
        <Route path="forgot-password" element={<UsersForgotPassword />} />
        <Route path="resend-email" element={<UsersForgotPassword />} />
        <Route path="activate-product-key" element={<UsersActivateProductKey />} />
        <Route path="course-merchant/register" element={<UsersCMRegister />} />
        <Route path="course-merchant/login" element={<UsersCMLogin />} />
        <Route path="onboarding" element={<UsersOnboarding />} />
      </Route>

      <Route path="tokens" element={<Tokens />}>
        <Route index element={<TokensEnter />} />
        <Route path=":token" element={<TokensView />} />
      </Route>

      <Route path="/" element={<App />}>
        <Route index element={<Dashboard />} />

        <Route path="account" element={<Account />}>
          <Route index element={<AccountHome />} />
          <Route path="phone" element={<AccountPhone />} />
          <Route path="password" element={<AccountPassword />} />
          <Route path="experience" element={<AccountExperience />} />
        </Route>

        <Route path="dashboard" element={<DashboardCopy />} />
        <Route path="certifications/*" element={<Certifications />} />
        <Route path="coach/learning-resources/*" element={<CoachLearningResources />} />
        <Route path="learning-resources/*" element={<LearningResources />} />

        <Route path="improvement" element={<Improvements />}>
          <Route index element={<CoachingRoster />} />
          <Route path="educator-profile/:id" element={<EducatorProfile />} />
        </Route>

        <Route path="invitations/:token" element={<InvitationsView />} />

        <Route path="measure" element={<Measure />}>
          <Route index element={<MeasureHome />} />
          <Route path="new" element={<MeasureCreate />} />
          <Route path=":id/*" element={<MeasureView />} />
          <Route path=":id/edit" element={<MeasureCreate />} />
          <Route path=":id/completed" element={<MeasureCompleted />} />
          <Route path=":id/summary" element={<MeasureSummary />} />

          <Route path="observation-videos">
            <Route index element={<MeasureObservationVideos />} />
            <Route path=":id" element={<MeasureObservationVideosView />} />
          </Route>
        </Route>

        <Route path="organization/*" element={<Organization />} />
        <Route path="reports/observation">
          <Route index element={<ObservationReport />} />
          <Route path="new" element={<CreateReportConfiguration />} />
          <Route path=":id" element={<EditReportConfiguration />} />
        </Route>
        <Route path="reports/reliability/:trainingId" element={<TestReport />} />
        <Route path="reports/knowledge/:trainingId" element={<TestReport testType="knowledge" />} />

        <Route path="test-attempts/:id">
          <Route index element={<TestAttempts />} />
          <Route path="score-report" element={<ScoreReport />} />
          <Route path="trainer-score-report" element={<TrainerViewTestAttempts />} />
        </Route>

        <Route path="test-preparations/:id">
          <Route index element={<TestPreparations />} />
          <Route path="score-report" element={<TestPreparationsScoreReport />} />
        </Route>

        <Route path="test-template/:id/prep-resources" element={<TestTemplate />} />
        <Route path="token-confirmation" element={<TokenConfirmation />} />

        <Route path="trainings" element={<Trainings />}>
          <Route index element={<TrainingsHome />} />
          <Route path="affiliate-cancellation-policy" element={<TrainingsCancellationPolicy />} />

          <Route path=":id">
            <Route element={<TrainingsView />}>
              <Route index element={<Navigate replace to="details" />} />
              <Route path="details" element={<TrainingViewDetails />} />
              <Route path="participants" element={<TrainingViewParticipants />} />
              <Route path="attendance" element={<TrainingViewAttendance />} />
            </Route>

            <Route path="edit" element={<TrainingsEdit />} />
            <Route path="checkout" element={<TrainingsCheckout />} />
            <Route path="card-checkout" element={<TrainingsCardCheckout />} />
            <Route path="checkout-completed" element={<TrainingsCheckoutCompleted />} />
          </Route>
        </Route>

        <Route path="training-catalog" element={<TrainingCatalog />}>
          <Route index element={<TrainingCatalogHome />} />
          <Route path=":id/new" element={<TrainingCatalogCreate />} />
        </Route>

        <Route path="training-history" element={<TrainingHistory />} />
        <Route path="user-choice-product-key" element={<UserChoiceProductKey />} />
        <Route path="learning-pathway-product-key" element={<LearningPathwayProductKey />} />
        <Route path="recertification-product-key" element={<RecertificationProductKey />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
