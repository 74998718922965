import i18n from 'helpers/i18n';
import messages from './messages';
import FormDropdown, { FormDropdownProps } from 'components/FormDropdown';

export function useClassFamiliarity() {
  const options = [
    { key: 1, value: 1, text: i18n.ft(messages.none) },
    { key: 3, value: 3, text: i18n.ft(messages.little) },
    { key: 5, value: 5, text: i18n.ft(messages.very) }
  ];

  return options;
}

export default function ClassFamiliarity(props: FormDropdownProps) {
  const options = useClassFamiliarity();

  return <FormDropdown {...props} options={options} />;
}
