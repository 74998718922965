import styled from 'styled-components';

export const Bar = styled.div`
  height: 50px;
  background-color: #0a9cca;
  display: flex;
  justify-content: flex-end;
`;

export const AccountSelect = styled.div`
  padding: 5px 40px 5px 0px;

  @media screen and (min-width: 640px) {
    padding: 5px 60px 5px 0px;
  }
`;
