const PATH = 'fe.components.measure.domain.category';

const messages = {
  notApplicable: {
    key: `${PATH}.not_applicable`,
    defaultValue: 'Not Applicable'
  },
  notApplicableShort: {
    key: `${PATH}.not_applicable_short`,
    defaultValue: 'N/A'
  }
};

export default messages;
