import i18n from 'helpers/i18n';
import messages from './messages';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { LanguagesWrapper, Language, Span } from './Styled';
import useLocale from 'hooks/useLocale';
import { update } from 'store/slices/locale';

function LanguageSelector() {
  const locale = useLocale();
  const dispatch = useDispatch();
  const defaultValue = locale || 'en';

  const languageOptions = [
    { text: i18n.ft(messages.english), value: 'en' },
    { text: i18n.ft(messages.spanish), value: 'es' },
    { text: i18n.ft(messages.french), value: 'fr' }
  ];

  function onLanguageSelection(option: any) {
    dispatch(update(option));
  }

  return (
    <LanguagesWrapper>
      <Language>
        <Span>{i18n.ft(messages.language)}</Span>
        <Dropdown
          options={languageOptions}
          defaultValue={defaultValue}
          onChange={(e, data) => onLanguageSelection(data.value)}
        />
      </Language>
    </LanguagesWrapper>
  );
}

export default LanguageSelector;
