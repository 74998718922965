import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Header } from 'semantic-ui-react';
import PageLayout from 'components/PageLayout';
import TemplateList from 'components/TrainingCatalog/TemplateList';

function Home() {
  return (
    <PageLayout>
      <Header as="h1" size="huge">
        {i18n.ft(messages.header)}
        <Header.Subheader>{i18n.ft(messages.subheader)}</Header.Subheader>
      </Header>
      <TemplateList />
    </PageLayout>
  );
}

export default Home;
