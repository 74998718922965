import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import useStepStatus from '../useStepStatus';
import AddressPreview from '../AddressPreview';
import AddressForm, { addressSchema } from '../AddressForm';
import { addBillingAddress } from 'actions/trainings';
import { Formik, Form, Input } from 'components/EnhancedForm';

import {
  Container,
  SubmitButton,
  Header,
  HeaderIcon,
  HeaderButton,
  HeaderContainer
} from 'components/Trainings/View/CardCheckout/Styled';

const validationSchema = yup.object({
  email: yup
    .string()
    .required(() => i18n.ft(messages.errors.email.required))
    .email(() => i18n.ft(messages.errors.email.invalid)),
  ...addressSchema
});

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  address1: '',
  address2: '',
  country_code: '',
  state_or_province_code: '',
  city: '',
  postal_code: '',
  phone: '',
  addressType: 'new'
};

const BillingForm = ({
  trainingId,
  afterSubmit,
  step,
  activeStep,
  onStepChange
}) => {
  const status = useStepStatus(step, activeStep);
  const [loading, setLoading] = useState(false);

  function handleEdit() {
    onStepChange(step);
  }

  function handleSubmit(values) {
    setLoading(true);
    addBillingAddress(trainingId, values).then(({ data }) => {
      setLoading(false);
      onStepChange(step + 1);
      afterSubmit(values, data);
    });
  }

  return (
    <Container>
      <HeaderContainer>
        <Header>
          {i18n.ft(messages.header)}
          {status === 'completed' && (
            <HeaderIcon>
              <Icon name="check circle" color="green" />
            </HeaderIcon>
          )}
        </Header>

        {status === 'completed' && (
          <HeaderButton
            inverted
            size="tiny"
            color="blue"
            onClick={handleEdit}
            content={i18n.ft(messages.edit)}
          />
        )}
      </HeaderContainer>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            {status === 'active' ? (
              <React.Fragment>
                <Input hidden name="addressType" />
                <AddressForm />
                <SubmitButton>
                  <Button
                    color="blue"
                    loading={loading}
                    disabled={loading}
                    type="submit"
                  >
                    {i18n.ft(messages.continue)}
                  </Button>
                </SubmitButton>
              </React.Fragment>
            ) : (
              <AddressPreview address={values} />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

BillingForm.propTypes = {
  /** The training ID. */
  trainingId: PropTypes.number.isRequired,

  /**
   * Callback fired after the form is submitted.
   * Returns the billing address and a Big Commerce checkout object.
   **/
  afterSubmit: PropTypes.func.isRequired,

  /** The billing form step number. */
  step: PropTypes.number.isRequired,

  /** The current active step number. */
  activeStep: PropTypes.number.isRequired,

  /** Callback fired when the step request to be changed. */
  onStepChange: PropTypes.func.isRequired
};

export default BillingForm;
