import * as R from 'ramda';
import { MetaDataCategory } from 'types/api/envScales/MetaDataCategory';
import { Input } from 'components/Measure/Input';
import { updateCycleMetaData } from 'actions/envScales/observationCycles';
import { ObservationCycle } from 'types/api/envScales/ObservationCycle';
import { Assessment } from 'types/api/envScales/Assessment';
import { useCyclesErrors } from 'context/CyclesErrorsContext';
import { SingleValueEditionError } from 'types/api/envScales/CyclesErrors';

import i18n from 'helpers/i18n';
import messages from './messages';

interface MetaDataFieldsProps {
  /**field information */
  fields: MetaDataCategory;

  /**default value of the field */
  defaultValue: string;

  /** Observation Cycle. */
  cycle: ObservationCycle;

  /** CLASS Assessment. */
  assessment: Assessment;

  /** Callback fired when the value changes. */
  onChange?: (value: string) => void;
}

function MetaDataFields({
  fields,
  defaultValue,
  cycle,
  assessment,
  onChange
}: MetaDataFieldsProps) {
  const { cyclesErrors, updateCycleErrors } = useCyclesErrors();

  function handleMetaDataValues(categoryId: number) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const observationId = assessment.observation!.id;
      const payloadMetaData = {
        meta_data: {
          meta_data_category_id: categoryId,
          values: event.target.value
        }
      };
      validate(event.target.value);
      updateCycleMetaData(observationId, cycle.id, payloadMetaData);
      onChange?.(event.target.value);
    };
  }

  function validate(values: any) {
    if (R.isEmpty(cyclesErrors)) {
      return;
    }

    let newCyclesErrors = R.clone(cyclesErrors);

    let checkedError = null;
    if (R.isEmpty(values)) {
      checkedError = `${fields.name} ${i18n.ft(messages.errors.required)}`;
    }
    (
      newCyclesErrors[cycle.cycle_number]
        .meta_data_categories as SingleValueEditionError
    )[fields.t_name] = checkedError;

    updateCycleErrors(newCyclesErrors);
  }

  function fieldError() {
    if (R.isEmpty(cyclesErrors)) {
      return null;
    }
    return (
      cyclesErrors[cycle.cycle_number]
        ?.meta_data_categories as SingleValueEditionError
    )[fields.t_name];
  }

  return (
    <Input
      min="0"
      fluid
      key={fields.t_name}
      required
      type="number"
      name={fields.t_name}
      label={fields.name}
      onChange={handleMetaDataValues(fields.id)}
      defaultValue={defaultValue}
      errorMessage={fieldError()}
    />
  );
}

export default MetaDataFields;
