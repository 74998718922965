const PATH = 'fe.components.tokens.view.login_form';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Log In To Accept'
  },
  haveAnAccount: {
    key: `${PATH}.have_an_account`,
    defaultValue: "Don't have an account?"
  },
  signUp: {
    key: `${PATH}.sign_up`,
    defaultValue: 'Sign Up'
  },
  unknownError: {
    key: `${PATH}.unknown_error`,
    defaultValue: 'An unknown error occurred, please try again later'
  }
};

export default messages;
