import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';

const StyledLoader = styled(Loader)`
  &:after {
    border-color: #767676 transparent transparent !important;
  }
`;

export default StyledLoader;
