const PATH = 'fe.pages.trainings.edit';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Edit Training'
  },
  submitLabel: {
    key: `${PATH}.submit_label`,
    defaultValue: 'Update Training'
  }
};

export default messages;
