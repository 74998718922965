import styled, { css } from 'styled-components';

import {
  addBorderRadius,
  addSpace,
  SpaceProps,
  BorderProps
} from 'components/Box/system';

export const PageWrapper = styled.div`
  color: ${p => p.theme.colors['scaleDarkBlue']};
  margin-top: 32px;
  padding-left: 12px;
  padding-right: 12px;

  @media screen and (min-width: 640px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 72px;
    padding-right: 72px;
  }
`;

export const BackgroundWrapper = styled.div`
  border-radius: 32px;
  margin: 32px 0;
  padding: 24px;
  background-color: #eaecf1;

  @media screen and (min-width: 768px) {
    padding: 48px;
  }

  @media screen and (min-width: 1280px) {
    padding: 80px;
  }
`;

interface SectionProps {
  error?: boolean;
  shadowOnHover?: boolean;
  thinPadding?: boolean;
}

export const Section = styled.section<SpaceProps & BorderProps & SectionProps>`
  ${addSpace};
  ${addBorderRadius};
  padding: 24px 16px;
  background-color: #ffffff;

  ${p =>
    p.error &&
    css`
      border: 1px solid ${p => p.theme.colors.scaleWarning};
    `};

  ${p =>
    p.shadowOnHover &&
    css`
      &:hover {
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
      }
    `};

  @media screen and (min-width: 640px) {
    padding: 24px;
  }

  @media screen and (min-width: 1280px) {
    padding: 48px;

    ${p =>
      p.thinPadding &&
      css`
        padding: 24px 48px;
      `};
  }
`;

export const SectionHeader = styled.h2`
  color: ${p => p.theme.colors['neutral']['700']};
  background-color: #f4f8fa;
  display: inline-block;
  border-radius: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 12px;

  @media screen and (min-width: 640px) {
    font-size: 24px;
    line-height: 32px;
    padding: 12px 20px;
  }
`;

export const SectionHeaderDescription = styled.span`
  margin-left: 20px;
  color: ${p => p.theme.colors.scaleGray};
  display: block;

  @media screen and (min-width: 640px) {
    display: inline-block;
  }
`;

export const SectionContent = styled.div<{ expanded?: boolean }>`
  margin-top: 32px;
  display: ${p => (p.expanded !== undefined && !p.expanded ? 'none' : 'block')};

  @media screen and (min-width: 640px) {
    margin-top: 48px;
    padding: 0 56px 20px;
  }
`;

export const SectionDetail = styled.div`
  padding: 10px 20px;
  box-shadow: 1px 1px 11px #d7e6ed;
  border-radius: 12px;
  color: #364a5e;

  @media screen and (min-width: 640px) {
    padding: 32px 64px;
  }
`;

export const SectionActions = styled.div`
  display: flex;
  flex-direction: column-reverse;

  & > * + * {
    margin-bottom: 16px;
  }

  @media screen and (min-width: 640px) {
    padding: 20px 56px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > * + * {
      margin: 0;
    }
  }

  @media screen and (min-width: 1280px) {
    padding: 0 56px;
  }
`;

export const ActionWrapper = styled.div`
  min-width: auto;

  @media screen and (min-width: 768px) {
    width: 215px;
  }
`;

interface ErrorProps {
  error?: boolean;
}

export const SectionWithBorder = styled.div<SpaceProps & ErrorProps>`
  ${addSpace};
  position: relative;
  padding: 8px 16px;
  border-radius: 7px;
  background-color: #f4f8fa;

  ${p =>
    p.error &&
    css`
      border-top: 1px solid ${p => p.theme.colors.scaleWarning};
      border-bottom: 1px solid ${p => p.theme.colors.scaleWarning};
      border-right: 1px solid ${p => p.theme.colors.scaleWarning};
    `}

  @media screen and (min-width: 768px) {
    border-radius: 9px;
    padding: 12px 48px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border: 3px solid ${p => (p.error ? '#C40040' : '#017ea7')};
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

    @media screen and (min-width: 768px) {
      border-width: 4px;
    }
  }
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  color: ${p => p.theme.colors['scaleWarning']};
  padding-top: 8px;
  height: 24px;
`;

export const CycleViewWrapper = styled.div`
  isolation: isolate;
`;

export const ClickableArea = styled.div`
  cursor: pointer;

  @media screen and (min-width: 640px) {
  }
`;

export const IconExpandCollapse = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: #fff;
  background-color: ${p => p.theme.colors.scaleBlue};
  margin-right: 18px;
  text-align: center;
  vertical-align: middle;

  i {
    margin-right: 0;
  }

  @media screen and (min-width: 640px) {
    line-height: 20px;

    i {
      margin-left: 1px;
    }
  }
`;
