interface TreeNodeTagProps {
  /** Tree node name with hierarchy. */
  name: React.ReactNode;

  /** Callback fired when the remove button is clicked. */
  onRemove: () => void;
}

export function TreeNodeTag({ name, onRemove }: TreeNodeTagProps) {
  const handleRemove = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRemove();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
      onRemove();
    }
  };

  return (
    <div
      tabIndex={-1}
      className="flex items-center gap-2 pl-1.5 pr-1 py-0.5 bg-[#F0E9EF] rounded border border-[#889EBB]"
    >
      <span className="text-sm">{name}</span>

      <button
        type="button"
        onClick={handleRemove}
        onKeyDown={handleKeyDown}
        className="w-5 h-5 flex justify-center items-center"
      >
        <i className="fa-solid fa-times" />
      </button>
    </div>
  );
}
