import i18n from 'helpers/i18n';
import messages from './messages';
import { AllDimensionError } from './Styled';

interface IncompleteDimensionsProps {
  completeCodesError: boolean;
}

function IncompleteDimensions({
  completeCodesError
}: IncompleteDimensionsProps) {
  if (!completeCodesError) {
    return null;
  }

  return (
    <AllDimensionError>
      {i18n.ft(messages.incompleteDimensionsError)}
    </AllDimensionError>
  );
}

export default IncompleteDimensions;
