import { getKnowledgeTestsByTestAttemptId } from 'actions/knowledgeTests';
import useAsync from 'hooks/useAsync';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer
} from 'react';
import { KnowledgeTestSummary } from 'types/api/KnowledgeTest';

const allQuestionsAnswered = (
  answers: KnowledgeTestSummary | undefined
): boolean => {
  if (!answers) {
    return false;
  }
  return answers.categories
    .map(i => i.questions.map(q => q.answered))
    .flat()
    .every(a => a === true);
};

interface ReducerData {
  knowledgeTest: KnowledgeTestSummary | undefined;
  isPending: boolean;
}

interface ReducerState extends ReducerData {
  canSubmit: boolean;
}

const reducer = (
  _state: ReducerState,
  action: { data: ReducerData; type: 'update' }
) => {
  if (action.type === 'update') {
    return {
      canSubmit: allQuestionsAnswered(action.data.knowledgeTest),
      knowledgeTest: action.data.knowledgeTest,
      isPending: action.data.isPending
    };
  }
  throw Error('Unknown action.');
};

export const KnowledgeTestContext = createContext<{
  canSubmit: boolean;
  knowledgeTest: KnowledgeTestSummary | undefined;
  isPending: boolean;
  updater: () => void;
}>({
  canSubmit: false,
  isPending: false,
  knowledgeTest: undefined,
  updater: () => {}
});

interface KnowledgeTestProviderProps {
  id: number;
  children: ReactNode;
}
/**
 * KnowledgeTestProvider will offer the answered state of all
 * the test answers currently saved.
 *
 * It uses a reducer to expose an update function to request
 * fresh data from the backend, to be used after a question is
 * answered and update its state.
 *
 * @param id the test attempt id
 * @returns
 */
export const KnowledgeTestProvider = ({
  id,
  children
}: KnowledgeTestProviderProps) => {
  const [state, dispatch] = useReducer(reducer, {
    knowledgeTest: undefined,
    canSubmit: false,
    isPending: false
  });
  const { data, run, isPending } = useAsync<KnowledgeTestSummary>();
  useEffect(() => {
    run(getKnowledgeTestsByTestAttemptId(id));
  }, [run, id]);

  useEffect(() => {
    dispatch({ type: 'update', data: { knowledgeTest: data, isPending } });
  }, [data, dispatch, isPending]);

  const refreshState = () => {
    run(getKnowledgeTestsByTestAttemptId(id));
  };

  return (
    <KnowledgeTestContext.Provider value={{ ...state, updater: refreshState }}>
      {children}
    </KnowledgeTestContext.Provider>
  );
};

export function useKnowledgeTest() {
  const context = useContext(KnowledgeTestContext);

  if (!context) {
    throw new Error(
      'useKnowledgeTest must be used within a KnowledgeTestProvider'
    );
  }

  return context;
}

export default KnowledgeTestContext;
