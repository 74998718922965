import styled from 'styled-components';
import { Button, Header } from 'semantic-ui-react';

export const CancelModalButton = styled(Button)`
  &&&.ui.button {
    color: ${p => p.theme.colors['scaleDarkGray']};
    background-color: ${p => p.theme.colors['white']};
    border: 0.5px solid ${p => p.theme.colors['scaleDarkGray']};
    border-radius: 4px;
    margin-right: 5px;
    margin-left: 0px;
    margin-bottom: 10px;

    @media screen and (min-width: 640px) {
      margin-bottom: 0px;
    }
  }
`;

export const ModalButton = styled(Button)`
  &&&.ui.button {
    color: white;
    background-color: ${p => p.theme.colors['navy']['500']};
    margin-right: 0px;
    margin-left: 0px;
  }
`;

export const ModalHeader = styled(Header)`
  &&&.header {
    color: ${p => p.theme.colors['scaleDarkBlue']};
    border-bottom: 0px;
    padding: 0px;
  }
`;

export const ModalAction = styled.div`
  padding: 1rem 1rem 3rem;
  display: block;

  @media screen and (min-width: 640px) {
    display: flex;
  }
`;

export const ModalIcon = styled.img`
  margin-top: -2.5rem;
  width: 94px;
`;
