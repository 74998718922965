import i18n from 'helpers/i18n';
import settings from 'helpers/settings';
import messages from './messages';
import { MessageBox } from '../Styled';
import { Button } from 'semantic-ui-react';
import lampIcon from 'images/reliabilityTest/lampIcon.svg';
import attempt1Icon from 'images/reliabilityTest/attempt1.svg';
import attempt2Icon from 'images/reliabilityTest/attempt2.svg';
import attempt3Icon from 'images/reliabilityTest/attempt3.svg';
import messageIcon from 'images/knowledgeTest/messageImage.svg';

interface FailedAttemptMessagesProps {
  attemptNumber: number;
  knowledgeTest: boolean;
  trainerView: boolean;
}

type objectKey = keyof typeof messages;

function FailedAttemptMessages({
  attemptNumber,
  knowledgeTest,
  trainerView
}: FailedAttemptMessagesProps) {
  const reliabilityContactSupportURL = `${settings.lookup('REACT_APP_TS_CONTACT_SUPPORT')}`;
  const reliabilitySupportURL = `${settings.lookup('REACT_APP_TS_RELIABILITY_SUPPORT')}`;
  const regionalTrainingsURL = `${settings.lookup('REACT_APP_TS_TRAININGS')}?fwp_course_type=virtual-training`;

  function displayAttemptMessage() {
    if (knowledgeTest) {
      switch (attemptNumber) {
        case 1:
        case 2:
          return i18n.ft(messages.knowledgeTestSecondMessage);
        default:
          return i18n.ft(messages.knowledgeTestThirdMessage);
      }
    }

    if (attemptNumber < 3) {
      return i18n.ft(messages[`attempt${attemptNumber}` as objectKey]);
    }

    return i18n.ft(messages.attempt3);
  }

  function displayKTButtonMessage() {
    switch (attemptNumber) {
      case 1:
      case 2:
        return i18n.ft(messages.schedule);
      default:
        return i18n.ft(messages.knowledgeTestTrainings);
    }
  }

  function getEmailHref() {
    switch (attemptNumber) {
      case 1:
      case 2:
        const subject = encodeURIComponent(i18n.ft(messages.emailSubject));
        const body = encodeURIComponent(i18n.ft(messages.emailBody));
        return `mailto:contact@teachstone.com?subject=${subject}&body=${body}`;
      default:
        return regionalTrainingsURL;
    }
  }

  function displayButtonMessage() {
    if (attemptNumber < 3) {
      return i18n.ft(messages[`button${attemptNumber}` as objectKey]);
    }

    return i18n.ft(messages.button3);
  }

  function displayAttemptIcon() {
    switch (attemptNumber) {
      case 1:
        return attempt1Icon;
      case 2:
        return attempt2Icon;
      default:
        return attempt3Icon;
    }
  }

  function knowledgeTestIcon() {
    switch (attemptNumber) {
      case 1:
      case 2:
        return messageIcon;
      default:
        return attempt3Icon;
    }
  }

  /* do not display a message for trainer view of the knowledge test */
  if (trainerView && knowledgeTest) {
    return <></>;
  }

  return (
    <MessageBox className="PrintBackgroundColor">
      <div className="flex justify-center mt-8 md:px-16 py-8 px-4">
        <img
          src={knowledgeTest ? knowledgeTestIcon() : displayAttemptIcon()}
          alt={i18n.ft(messages.failedAttemptIcon)}
          className="hidden md:block mr-4"
        />
        <div className="align-middle pt-4">
          <div className="flex">
            <img
              src={lampIcon}
              alt={i18n.ft(messages.failedAttemptIcon)}
              className="self-start mr-4 md:hidden"
            />
            <div className="mb-4 mx-auto md:text-center md:w-2/3">
              {displayAttemptMessage()}
            </div>
          </div>
          <div className="text-center">
            {knowledgeTest ? (
              <Button
                content={displayKTButtonMessage()}
                color="blue"
                className="w-3/6 px-4 mx-auto mt-4 md:px-16 PrintMessageButton"
                as="a"
                href={getEmailHref()}
              />
            ) : (
              <Button
                content={displayButtonMessage()}
                color="blue"
                className="px-4 mx-auto mt-4 md:px-16 PrintMessageButton"
                as="a"
                href={
                  attemptNumber >= 3
                    ? regionalTrainingsURL
                    : attemptNumber === 1
                      ? reliabilitySupportURL
                      : reliabilityContactSupportURL
                }
              />
            )}
          </div>
        </div>
      </div>
    </MessageBox>
  );
}

export default FailedAttemptMessages;
