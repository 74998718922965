import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const NativeInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const InputBase = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: 1px solid;
  border-color: ${p =>
    p.active ? '#4A8264' : p.theme.colors['neutral']['400']};
  background-color: ${p => (p.active ? '#4A8264' : p.theme.colors['white'])};
  color: ${p => (p.active ? '#ffffff' : '#34495e')};

  ${NativeInput}:focus + & {
    /* Defaults to blue color when focus ring color is not defined by the browser. */
    outline: 5px auto blue;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: 4px;
  }
`;

export const RadioInput = styled(InputBase)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const PillInput = styled(InputBase)`
  padding: 0 24px;
  line-height: 20px;
  border-radius: 12px;
`;
