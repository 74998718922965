import {
  useContext,
  useState,
  createContext,
  ReactNode,
  SetStateAction
} from 'react';
import { CyclesErrors } from 'types/api/envScales/CyclesErrors';
import { flattenObject } from 'helpers/objectUtility';
import * as R from 'ramda';

const defaultValue = {};

function hasErrors(data: object) {
  if (!data) {
    return false;
  }

  return Object.values(data).filter((elem: any) => elem).length > 0;
}

function metaDataHasError(cycleData: any) {
  if (!cycleData.meta_data_categories) {
    return false;
  }

  const flattenMetaDataCategories = flattenObject(
    cycleData.meta_data_categories
  );
  const metaDataCategoriesHasErrors = hasErrors(flattenMetaDataCategories);

  return (
    cycleData.cycle_time !== null ||
    cycleData.adults !== null ||
    cycleData.children !== null ||
    metaDataCategoriesHasErrors
  );
}

function scoresHasErrors(cycleData: any) {
  if (!cycleData.dimension_scores) {
    return false;
  }

  const flattenDimensionAndIndicators = flattenObject(
    cycleData.dimension_scores
  );
  const dimensionOrIndicatorHasErrors = hasErrors(
    flattenDimensionAndIndicators
  );
  return dimensionOrIndicatorHasErrors;
}

interface CyclesErrorsValue {
  /** Observation Cycles Errors */
  cyclesErrors: CyclesErrors;

  setCyclesErrors: React.Dispatch<SetStateAction<CyclesErrors>>;

  cycleSubmitTextError: string;

  setCycleSubmitTextError: React.Dispatch<SetStateAction<string>>;

  /** set cyclesErrors */
  updateCycleErrors: (values: CyclesErrors) => void;
}

export const CyclesErrorsContext = createContext<CyclesErrorsValue>(
  {} as CyclesErrorsValue
);
CyclesErrorsContext.displayName = 'CyclesErrorsContext';

interface CyclesErrorsProviderProps {
  children: ReactNode;
}

export function CyclesErrorsProvider({ children }: CyclesErrorsProviderProps) {
  const [cyclesErrors, setCyclesErrors] = useState<CyclesErrors>(defaultValue);
  const [cycleSubmitTextError, setCycleSubmitTextError] = useState('');

  function updateCycleErrors(values: CyclesErrors) {
    setCycleSubmitTextError('');
    recalculateCycleErrors(values);
  }

  function recalculateCycleErrors(cycles: CyclesErrors) {
    const newCycleErrors = R.clone(cycles);

    for (const cycleNumber in newCycleErrors) {
      const newCycle = R.clone(newCycleErrors[cycleNumber]);
      let flattenCycle = flattenObject(newCycle);
      flattenCycle = R.omit(
        ['cycle_has_errors', 'metaDataHasErrors', 'scoresHasErrors'],
        flattenCycle
      );
      newCycle.cycle_has_errors = hasErrors(flattenCycle);
      newCycle.metaDataHasErrors = metaDataHasError(newCycle);
      newCycle.scoresHasErrors = scoresHasErrors(newCycle);
      newCycleErrors[cycleNumber] = newCycle;
    }

    setCyclesErrors(newCycleErrors);
  }

  const value: CyclesErrorsValue = {
    cyclesErrors,
    updateCycleErrors,
    setCyclesErrors,
    cycleSubmitTextError,
    setCycleSubmitTextError
  };

  return (
    <CyclesErrorsContext.Provider value={value}>
      {children}
    </CyclesErrorsContext.Provider>
  );
}

export function useCyclesErrors() {
  const context = useContext(CyclesErrorsContext);

  if (!context) {
    throw new Error(
      'useCyclesErrors must be used within <CyclesErrorsProvider />'
    );
  }

  return context;
}
