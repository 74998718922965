import { clsx } from 'clsx';

interface ListButtonProps {
  /** Button content. */
  children: React.ReactNode;

  /** Left circle color. */
  color: string;

  /** Right side count value. */
  count: number;

  /** Callback fired when the button is clicked. */
  onClick: () => void;

  /** When `true`, the button will be highlighted. */
  active?: boolean;

  /** When `true`, the button will have 0.4 opacity. */
  addOpacity?: boolean;
}

export function ListButton({
  active,
  children,
  color,
  count,
  onClick,
  addOpacity
}: ListButtonProps) {
  const buttonClasses = clsx(
    'px-2 py-1.5 rounded-lg font-bold text-sm flex items-center gap-3 w-full',
    { 'bg-[#e6f5fa]': active, 'opacity-40': addOpacity }
  );

  return (
    <button className={buttonClasses} onClick={onClick}>
      <span className={`${color} h-3 w-3 rounded-full`} />
      <span className="flex flex-1 items-center">{children}</span>
      <span className="ml-auto min-w-4">{count}</span>
    </button>
  );
}
