const PATH = 'fe.components.measure.home.create_button';

const messages = {
  createObservation: {
    key: `${PATH}.create_observation`,
    defaultValue: 'Create Observation'
  },
  selectAccount: {
    key: `${PATH}.select_account`,
    defaultValue: 'Select an account'
  }
};

export default messages;
