const PATH = 'fe.components.measure.time_range_input';

const messages = {
  optional: {
    key: `${PATH}.optional`,
    defaultValue: 'Optional'
  },
  startTime: {
    key: `${PATH}.start_time`,
    defaultValue: 'Start Time'
  },
  endTime: {
    key: `${PATH}.end_time`,
    defaultValue: 'End Time'
  }
};

export default messages;
