import { Form, Formik } from 'formik';
import { InformationCard } from '../Styled';
import { Button } from 'semantic-ui-react';
import { updateCoachingCycle } from 'actions/improvements';
import { ImprovementCoachingCycle } from 'types/api/improvement/ImprovementCoachingCycle';
import messages from './messages';
import i18n from 'helpers/i18n';

interface CompleteCoachingCycleProps {
  coachingCycle: ImprovementCoachingCycle;
  onSubmit: () => void;
}

const CompleteCoachingCycle = ({
  coachingCycle,
  onSubmit
}: CompleteCoachingCycleProps) => {
  const handleSubmit = async () => {
    await updateCoachingCycle(coachingCycle.id, {
      completion_date: new Date()
    });
    onSubmit();
  };

  return (
    <div className="print:hidden">
      <h3>{i18n.ft(messages.nextCoachingCycle)}</h3>
      <InformationCard className="p-8">
        <header>
          <h2>{i18n.ft(messages.nextCoachingCycle)}</h2>
        </header>

        <div className="mb-6">
          <h3 id="complete-coaching-cycle-form-label">
            {i18n.ft(messages.description)}
          </h3>
          <p>{i18n.ft(messages.helperText)}</p>
        </div>

        <Formik initialValues={{}} enableReinitialize onSubmit={handleSubmit}>
          {() => (
            <Form
              aria-labelledby="complete-coaching-cycle-form-label"
              className="flex justify-end"
            >
              <Button
                type="submit"
                className="bg-[#017EA7] text-white py-3 px-6 rounded-lg font-bold"
                color="blue"
                content={i18n.ft(messages.submit)}
              />
            </Form>
          )}
        </Formik>
      </InformationCard>
    </div>
  );
};

export default CompleteCoachingCycle;
