import { useRouteParams } from 'hooks/useRouteParams';
import { MeasureProvider } from 'context/measure';
import ClassAssessment from 'components/Measure/View/ClassAssessment';
import EnvScalesAssessment from 'components/Measure/View/EnvScalesAssessment';

type URLParams = {
  id: string;
};

function View() {
  const { id } = useRouteParams<URLParams>();
  const assessmentId = parseInt(id);

  return (
    <MeasureProvider assessmentId={assessmentId} expanded>
      {value =>
        value.assessment.assessment_template.env_scales ? (
          <EnvScalesAssessment assessment={value.assessment} />
        ) : (
          <ClassAssessment />
        )
      }
    </MeasureProvider>
  );
}

export default View;
