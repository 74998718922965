import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { AccountUser } from 'types/api/CertificationDashboard';

export function getObserverCounts() {
  return axios.get('/api/v2/certification_dashboards/observer_counts');
}

export type StatusForAgeLevelRes = {
  count: number;
  statuses: {
    active: number;
    expired: number;
    expiring_soon: number;
    seeking_initial: number;
  };
};

export function getCertificationsByStatus(params = {}) {
  return axios.get<StatusForAgeLevelRes>(
    '/api/v2/certification_dashboards/status_for_age_level',
    { params }
  );
}

type GetCertificationsByStatusParams = {
  date: string;
  node_ids: number[];
  account_id: number | null | undefined;
  age_level_group_id: number | null | undefined;
  class_version: string | null | undefined;
};

export function useGetCertificationsByStatus(
  params: GetCertificationsByStatusParams
) {
  return useQuery({
    queryKey: ['certification_dashboards', 'status_for_age_level', params],
    queryFn: () => getCertificationsByStatus(params).then(res => res.data),
    enabled: Boolean(params.account_id) && Boolean(params.age_level_group_id),

    initialData: {
      count: 0,
      statuses: {
        active: 0,
        expired: 0,
        expiring_soon: 0,
        seeking_initial: 0
      }
    }
  });
}

export type CertifiedUserReponse = {
  account_users: AccountUser[];
  pagination: { total_pages: number; current_page: number };
};

export function getAccountCertifiedUsers(params = {}) {
  return axios.get<CertifiedUserReponse>(
    `/api/v2/certification_dashboards/certified_users`,
    {
      params
    }
  );
}

type GetAccountCertifiedUsersParams = {
  date: string;
  node_ids: number[];
  status: string | null | undefined;
  account_id: number | null | undefined;
  age_level_group_id: number | null | undefined;
  class_version: string | null | undefined;
  query: string | null | undefined;
};

export function useGetAccountCertifiedUsers(
  params: GetAccountCertifiedUsersParams
) {
  return useQuery({
    queryKey: ['certification_dashboards', 'certified_users', params],
    queryFn: () => getAccountCertifiedUsers(params).then(res => res.data),
    enabled: Boolean(params.account_id)
  });
}

export function getCertificationHierarchy() {
  return axios.get(`/api/v2/certification_dashboards/hierarchy`);
}
