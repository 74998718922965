import i18n from 'helpers/i18n';
import messages from './messages';
import { useField } from 'formik';
import ErrorLabel from './ErrorLabel';
import styled from 'styled-components';
import { FormRowWrapper, FormInputWithLabel } from './Styled';
import { shouldDisplayError, shouldHideInput, BaseFieldProps } from './utils';

import RichTextEditor, {
  RichTextEditorProps as BaseProps
} from 'components/RichTextEditor';

const StyledRTE = styled(RichTextEditor)`
  max-width: 500px;

  &.RichTextEditor--error {
    margin-bottom: 0px;
  }
`;

interface RichTextEditorProps extends BaseFieldProps<BaseProps> {
  /** If `true`, the input control will be disabled. */
  disabled?: boolean;
}

function RTE({ name, validate, className, ...props }: RichTextEditorProps) {
  const [field, meta, helpers] = useField({ name, validate });
  const isError = shouldDisplayError(meta);
  const isHidden = shouldHideInput(props);
  const defaultValue = props.disabled ? i18n.ft(messages.notProvided) : '';

  return (
    <FormRowWrapper className={className} hidden={isHidden}>
      <FormInputWithLabel
        {...field}
        {...props}
        value={meta.value || defaultValue}
        error={isError}
        hasError={isError}
        onChange={helpers.setValue}
        onBlur={() => helpers.setTouched(true)}
      />
      <ErrorLabel active={isError} content={meta.error} />
    </FormRowWrapper>
  );
}

RTE.defaultProps = {
  control: StyledRTE
};

export default RTE;
