import moment from 'moment';
import { sort } from 'ramda';
import { Session } from 'types/api/Session';

/**
 * Returns a list of training sessions sorted by date (asc).
 */
export default function sortByDate(sessions: Session[]): Session[] {
  return sort((sessionA, sessionB) => {
    const momentA = moment(sessionA.starts_at);
    const momentB = moment(sessionB.starts_at);

    if (momentA.isBefore(momentB)) {
      return -1;
    } else if (momentA.isAfter(momentB)) {
      return 1;
    } else {
      return 0;
    }
  }, sessions);
}
