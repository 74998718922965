import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { DimensionCard } from './DimensionCard';
import { AgeLevel } from 'types/api/envScales/AgeLevel';
import { ObservationCycle } from 'types/api/envScales/ObservationCycle';
import { ObservationCycleScore } from 'types/api/envScales/ObservationCycleScore';
import { SectionCollapse } from 'components/SectionCollapse';
import { useCyclesErrors } from 'context/CyclesErrorsContext';

interface CycleScoresFormProp {
  /** Age Level. */
  ageLevel: AgeLevel;

  /** Observation Cycle. */
  cycle: ObservationCycle;

  /** Array of Observation Cycle Scores. */
  cycleScores: ObservationCycleScore[];
}

function getInitialScores(
  dimensionId: number,
  cycleScores: ObservationCycleScore[]
) {
  const dimensionScores = cycleScores.find(
    score => score.dimension_id === dimensionId
  );

  if (dimensionScores) {
    return dimensionScores;
  } else {
    return { dimension_score: null, indicator_scores: [] };
  }
}

function CycleScoresForm({
  cycle,
  cycleScores,
  ageLevel
}: CycleScoresFormProp) {
  const [activeDimension, setActiveDimension] = useState<number>();
  const { cyclesErrors } = useCyclesErrors();

  function handleExpandCollapse(dimensionId: number) {
    return (isExpanded: boolean) => {
      if (isExpanded) {
        setActiveDimension(dimensionId);
      } else {
        setActiveDimension(undefined);
      }
    };
  }

  function isSectionWithErrors() {
    if (!cyclesErrors) {
      return false;
    }

    const cycleErrors = cyclesErrors[cycle.cycle_number];
    if (!cycleErrors) {
      return false;
    }

    return cycleErrors.scoresHasErrors;
  }

  return (
    <SectionCollapse
      className="mt-2"
      title={i18n.ft(messages.enterScores)}
      subtitle={i18n.ft(messages.sectionDescription)}
      status={isSectionWithErrors() ? 'error' : null}
      closeOthersOnOpen
    >
      <div className="sm:px-14 sm:pb-5">
        {ageLevel.domains.map(domain => {
          return domain.dimensions.map(dimension => {
            const initialScores = getInitialScores(dimension.id, cycleScores);

            return (
              <DimensionCard
                key={dimension.id}
                cycle={cycle}
                domain={domain}
                dimension={dimension}
                initialScore={initialScores.dimension_score}
                initialIndicators={initialScores.indicator_scores}
                isExpanded={activeDimension === dimension.id}
                onExpandCollapse={handleExpandCollapse(dimension.id)}
              />
            );
          });
        })}
      </div>
    </SectionCollapse>
  );
}

export default CycleScoresForm;
