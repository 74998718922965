const PATH = 'fe.components.onboarding.simple_onboarding';

const messages = {
  welcome: {
    key: `${PATH}.welcome`,
    defaultValue: 'Welcome to myTeachstone'
  },
  description: {
    key: `${PATH}.welcome_description`,
    defaultValue:
      'The myTeachstone platform helps educators improve outcomes for children by offering subscription access to:'
  },
  onlineCourses: {
    key: `${PATH}.online_courses`,
    defaultValue: 'Online Courses'
  },
  certificationTrainings: {
    key: `${PATH}.certification_trainings`,
    defaultValue: 'Certification Trainings'
  },
  realClassroom: {
    key: `${PATH}.real_classroom`,
    defaultValue: 'Real Classroom Videos'
  },
  coachingPrograms: {
    key: `${PATH}.coaching_programs`,
    defaultValue: 'Coaching Programs'
  },
  dataCollection: {
    key: `${PATH}.data_collection`,
    defaultValue: 'Data Collection and Reporting'
  },
  focus: {
    key: `${PATH}.focus`,
    defaultValue: 'A Focus on Interactions'
  },
  createdToShare: {
    key: `${PATH}.create_to_share`,
    defaultValue:
      'Teachstone was created to share the CLASS® Tool with the world.'
  },
  interact: {
    key: `${PATH}.interact`,
    defaultValue:
      'You interact with children every day. Those interactions matter.'
  },
  classTool: {
    key: `${PATH}.class_tool`,
    defaultValue: `Teachstone’s CLASS® tool helps improve
        those interactions. And, research shows that
        those interactions help children succeed.`
  },
  myTeachstone: {
    key: `${PATH}.my_teachstone`,
    defaultValue: `With myTeachstone you get tools to strengthen
        the interactions that matter the most.`
  },
  next: {
    key: `${PATH}.next`,
    defaultValue: 'Next'
  },
  thankYou: {
    key: `${PATH}.thank_you`,
    defaultValue: 'Thank You!'
  },
  conclusion: {
    key: `${PATH}.conclusion`,
    defaultValue:
      'That’s all for now! Click the finish button below to go to your myTeachstone dashboard and begin exploring the services included in your subscription.'
  },
  questions: {
    key: `${PATH}.questions`,
    defaultValue: `If you have any questions, reach out to us using the “live help” chat button at the bottom-right of the page.`
  },
  finish: {
    key: `${PATH}.finish`,
    defaultValue: `Finish`
  },
  thankYouImage: {
    key: `${PATH}.thank_you_image`,
    defaultValue: `Thank you image`
  },
  welcomeImage: {
    key: `${PATH}.welcome_image`,
    defaultValue: `Welcome to myTeachstone image`
  },
  focusImage: {
    key: `${PATH}.focus_image`,
    defaultValue: `Focus on Interactions image`
  }
};

export default messages;
