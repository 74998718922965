const PATH = 'fe.components.measure.view.cycles.cycle_details_form';

const messages = {
  details: {
    key: `${PATH}.details`,
    defaultValue: 'Cycle Details'
  },
  sectionDescription: {
    key: `${PATH}.section_description`,
    defaultValue: 'Enter cycle information'
  },
  adults: {
    key: `${PATH}.adults`,
    defaultValue: 'Number of Adults'
  },
  children: {
    key: `${PATH}.children`,
    defaultValue: 'Number of Children'
  },
  cycleTime: {
    key: `${PATH}.cycle_time`,
    defaultValue: 'Cycle Time'
  },
  shortCycleTime: {
    key: `${PATH}.short_cycle_time`,
    defaultValue: 'Cycle time less than 8 minutes.'
  },
  longCycleTime: {
    key: `${PATH}.long_cycle_time`,
    defaultValue: 'Cycle time more than 30 minutes.'
  },
  errors: {
    numberRequired: {
      key: `${PATH}.errors.number_required`,
      defaultValue: 'Please enter a number greater than zero.'
    },
    endCycleTime: {
      required: {
        key: `${PATH}.errors.end_cycle_time.required`,
        defaultValue: 'Cycle end time is required.'
      }
    },
    startCycleTime: {
      required: {
        key: `${PATH}.errors.start_cycle_time.required`,
        defaultValue: 'Cycle start time is required.'
      }
    }
  },
  warnings: {
    cycleTime: {
      earlyStart: {
        key: `${PATH}.warnings.cycle_time.early_start`,
        defaultValue: 'Cycle start time before {{time}}.'
      },
      lateStart: {
        key: `${PATH}.warnings.cycle_time.late_start`,
        defaultValue: 'Cycle start time after {{time}}.'
      },
      earlyEnd: {
        key: `${PATH}.warnings.cycle_time.early_end`,
        defaultValue: 'Cycle end time before {{time}}.'
      },
      lateEnd: {
        key: `${PATH}.warnings.cycle_time.late_end`,
        defaultValue: 'Cycle end time after {{time}}.'
      },
      shortCycle: {
        key: `${PATH}.warnings.cycle_time.short_cycle`,
        defaultValue: 'Cycle less than {{time}} minutes.'
      },
      longCycle: {
        key: `${PATH}.warnings.cycle_time.long_cycle`,
        defaultValue: 'Cycle more than {{time}} minutes.'
      }
    },
    adults: {
      maximum: {
        key: `${PATH}.warnings.adults.maximum`,
        defaultValue: 'Number of adults is greater than {{number}}.'
      }
    },
    children: {
      maximum: {
        key: `${PATH}.warnings.children.maximum`,
        defaultValue: 'Number of children is greater than {{number}}.'
      },
      minimum: {
        key: `${PATH}.warnings.children.minimum`,
        defaultValue: 'Number of children less than {{number}}.'
      },
      awakeAsleepDontMatch: {
        key: `${PATH}.warnings.children.awake_asleep_dont_match`,
        defaultValue:
          'Number of children asleep and awake must equal the total number of children in the room.'
      },
      walkersNonwalkersDontMatch: {
        key: `${PATH}.warnings.children.walkers_nonwalkers_dont_match`,
        defaultValue:
          'Number of walkers and non walkers must equal the total number of children in the room.'
      }
    }
  }
};

export default messages;
