const PATH = 'fe.pages.organization.add_members';

const messages = {
  addMember: {
    key: `${PATH}.add_member`,
    defaultValue: 'Add Member'
  },
  backToMembers: {
    key: `${PATH}.back_to_members`,
    defaultValue: 'Back to Members'
  },
  usersAdded: {
    key: `${PATH}.users_added`,
    defaultValue: '{{count}} new member has been added'
  },
  fields: {
    email: {
      key: `${PATH}.fields.email`,
      defaultValue: 'Email'
    },
    emailPlaceholder: {
      key: `${PATH}.fields.email_placeholder`,
      defaultValue: 'Enter email address'
    },
    firstName: {
      key: `${PATH}.fields.first_name`,
      defaultValue: 'First Name'
    },
    firstNamePlaceholder: {
      key: `${PATH}.fields.first_name_placeholder`,
      defaultValue: 'Enter first name'
    },
    lastName: {
      key: `${PATH}.fields.last_name`,
      defaultValue: 'Last Name'
    },
    lastNamePlaceholder: {
      key: `${PATH}.fields.last_name_placeholder`,
      defaultValue: 'Enter last name'
    },
    role: {
      key: `${PATH}.fields.role`,
      defaultValue: 'Role'
    },
    rolePlaceholder: {
      key: `${PATH}.fields.role_placeholder`,
      defaultValue: 'Select role (optional)'
    },
    hierarchy: {
      key: `${PATH}.fields.hierarchy`,
      defaultValue: 'Hierarchy Association'
    },
    hierarchyPlaceholder: {
      key: `${PATH}.fields.hierarchy_placeholder`,
      defaultValue: 'Select hierarchy association for this member'
    }
  },
  errors: {
    email: {
      key: `${PATH}.errors.email`,
      defaultValue: 'You must provide a valid email address'
    },
    required: {
      key: `${PATH}.errors.required`,
      defaultValue: 'This field is required'
    }
  }
};

export default messages;
