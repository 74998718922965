const PATH = 'fe.components.measure.view.side_navigation';

const messages = {
  withEnvironment: {
    key: `${PATH}.with_environment`,
    defaultValue: '{{name}} with Environment'
  },
  details: {
    key: `${PATH}.details`,
    defaultValue: 'Observation Details'
  },
  environment: {
    key: `${PATH}.environment`,
    defaultValue: 'Environment'
  },
  cycle: {
    key: `${PATH}.cycle`,
    defaultValue: 'Cycle {{cycle}}'
  },
  evidence: {
    key: `${PATH}.evidence`,
    defaultValue: 'Evidence'
  },
  summary: {
    key: `${PATH}.summary`,
    defaultValue: 'Summary'
  },
  exitObservation: {
    key: `${PATH}.exit_observation`,
    defaultValue: 'Exit Observation'
  },
  cycleActions: {
    addCycle: {
      key: `${PATH}.cycle_actions.add_cycle`,
      defaultValue: 'Add a cycle'
    },
    deleteCycle: {
      key: `${PATH}.cycle_actions.delete_cycle`,
      defaultValue: 'Delete current cycle'
    },
    deleteModal: {
      title: {
        key: `${PATH}.cycle_actions.delete_modal.title`,
        defaultValue: 'Delete Current Cycle'
      },
      message: {
        key: `${PATH}.cycle_actions.delete_modal.message`,
        defaultValue: 'Are you sure you want to delete cycle {{cycle}}?'
      },
      confirm: {
        key: `${PATH}.cycle_actions.delete_modal.confirm`,
        defaultValue: 'Delete'
      }
    }
  }
};

export default messages;
