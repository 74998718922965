import { useState } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Button, Header } from 'semantic-ui-react';
import MobileForm from 'components/Phone/MobileForm';
import SmsCodeForm from 'components/Phone/SmsCodeForm';
import { updateUserMeSettings } from 'actions/userMe';

import {
  TextButton,
  TextWrapper,
  TermsWrapper,
  SuccessWrapper,
  ButtonsWrapper
} from './Styled';

type PhoneSetupSteps = 'enterPhone' | 'enterCode' | 'setupCompleted';

interface PhonePromptProps {
  /** Callback fired when users complete setting up their phone number or dismiss the prompt. */
  onSuccess: () => void;
}

function PhonePrompt({ onSuccess }: PhonePromptProps) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [step, setStep] = useState<PhoneSetupSteps>('enterPhone');

  function handleSuccess(newPhoneNumber: string) {
    setPhoneNumber(newPhoneNumber);
    setStep('enterCode');
  }

  function handleVerification() {
    setStep('setupCompleted');
  }

  function handleDismiss() {
    onSuccess();
  }

  async function handlePermanentDismiss() {
    await updateUserMeSettings({
      user_setting: {
        dismiss_login_phone_prompt: true
      }
    });
    onSuccess();
  }

  if (step === 'setupCompleted') {
    return (
      <>
        <Header
          size="large"
          textAlign="center"
          content={i18n.ft(messages.success)}
        />
        <TextWrapper>
          <div>{i18n.ft(messages.successSubtitle1, { phoneNumber })}</div>
          <div>{i18n.ft(messages.successSubtitle2)}</div>
        </TextWrapper>

        <SuccessWrapper>
          <Button color="blue" onClick={onSuccess}>
            {i18n.ft(messages.continue)}
          </Button>
        </SuccessWrapper>
      </>
    );
  }

  return (
    <>
      {step === 'enterCode' ? (
        <>
          <Header
            size="large"
            textAlign="center"
            content={i18n.ft(messages.enterCode)}
          />
          <TextWrapper>
            <div>{i18n.ft(messages.enterCodeSubtitle1, { phoneNumber })}</div>
            <div>{i18n.ft(messages.enterCodeSubtitle2)}</div>
          </TextWrapper>
          <SmsCodeForm onSucess={handleVerification} />
        </>
      ) : (
        <>
          <Header
            size="large"
            textAlign="center"
            content={i18n.ft(messages.enterPhone)}
          />
          <TextWrapper>
            {i18n.ft(messages.enterPhoneSubtitle) + ' '}
            <a
              href="https://teachstone.com/support/login/#link-1"
              target="_blank"
              rel="noreferrer"
            >
              {i18n.ft(messages.learnMore)}
            </a>
          </TextWrapper>
          <MobileForm isVerifying={false} onSuccess={handleSuccess} />
        </>
      )}

      <ButtonsWrapper>
        <TextButton onClick={handleDismiss}>
          {i18n.ft(messages.nextTime)}
        </TextButton>
        <TextButton onClick={handlePermanentDismiss}>
          {i18n.ft(messages.dontAskAgain)}
        </TextButton>
      </ButtonsWrapper>

      <TermsWrapper>{i18n.ft(messages.footer)}</TermsWrapper>
    </>
  );
}
export default PhonePrompt;
