import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserMe } from 'types/api/User';

type UserState = Partial<UserMe>;

const initialState: UserState = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    update: (_state, action: PayloadAction<UserState>) => {
      return action.payload;
    },
    updateAvatar: (state, action: PayloadAction<string>) => {
      state.avatar_url = action.payload;
    },
    destroy: () => {
      return {};
    }
  }
});

export const { update, updateAvatar, destroy } = userSlice.actions;
export default userSlice.reducer;
