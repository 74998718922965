import styled from 'styled-components';
import Typography from 'components/Typography';
import { Icon } from 'semantic-ui-react';

export const BoxContainer = styled.div`
  background-color: #f5fbfe;
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
`;

export const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: ${props => (props.display === 'inline' ? 0 : 12)}px !important;
  &.icon.circular {
    line-height: 1.2em !important;
  }
`;

export const NumberBubble = styled.div`
  display: flex;
  align-items: center;
  width: ${props => (props.size === 'small' ? '2.2em' : '100px')};
  height: ${props => (props.size === 'small' ? '2.2em' : '100px')};
  border-radius: 50%;
  background-color: ${props => props.theme.colors[props.color]};
  margin-bottom: ${props => (props.display === 'inline' ? 0 : 6)}px;
`;

NumberBubble.Text = styled(Typography)`
  font-size: ${props => (!props.size ? '48px' : null)};
`;
