import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useMeasure } from 'context/measure';
import { useLocation } from 'react-router-dom';
import RouteHelpers from 'helpers/routes';
import { isEmpty } from 'ramda';
import backIcon from 'images/envScales/backIcon.svg';
import nextIcon from 'images/envScales/nextIcon.svg';

function useFooterNav() {
  let menuList = [];
  let nextPageURL = '';
  let prevPageURL = '';
  const { assessment } = useMeasure();
  const location = useLocation();

  const currentLocation = location.pathname;
  const hasEnvironment = assessment.includes_environments;

  menuList = [
    RouteHelpers.getPath('measure-view-details', {
      id: assessment.id
    })
  ];

  if (hasEnvironment) {
    menuList.push(
      RouteHelpers.getPath('measure-view-environment', {
        id: assessment.id
      })
    );
  }

  assessment.observation?.observation_cycles.forEach(element => {
    menuList.push(
      RouteHelpers.getPath('measure-view-cycle', {
        id: assessment.id,
        cycleId: element.id
      })
    );
  });

  menuList.push(
    RouteHelpers.getPath('measure-examples-notes', {
      id: assessment.id
    })
  );
  menuList.push(
    RouteHelpers.getPath('measure-view-review', {
      id: assessment.id
    })
  );

  const currentIndex = menuList.findIndex(v => v === currentLocation);

  if (currentIndex === menuList.length - 1) {
    prevPageURL = menuList[currentIndex - 1];
    nextPageURL = '';
  } else {
    prevPageURL = menuList[currentIndex - 1];
    nextPageURL = menuList[currentIndex + 1];
  }

  return [prevPageURL, nextPageURL];
}

interface FooterNavProps {
  /** When promise returns `true`, continue with navigation to next page. */
  validateNext?: () => Promise<boolean>;

  /** Custom component to render on right side instead of next link. */
  rightSideElement?: React.ReactNode;
}

function FooterNav({ rightSideElement, validateNext }: FooterNavProps) {
  const navigate = useNavigate();
  const [prevPageURL, nextPageURL] = useFooterNav();

  async function handleNext(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    if (!validateNext) {
      return;
    }

    event.preventDefault();
    const isValid = await validateNext();

    if (isValid) {
      navigate(nextPageURL);
    }
  }

  return (
    <div className="flex bg-white justify-between rounded-b-xl mt-2 px-8 py-6 items-center">
      <Link
        to={prevPageURL}
        className="flex justify-end !text-[#364A5E] font-bold text-center"
      >
        <img src={backIcon} alt={i18n.ft(messages.back)} />
        <div className="pl-4 self-center">{i18n.ft(messages.back)}</div>
      </Link>
      {!isEmpty(nextPageURL) && !rightSideElement && (
        <Link
          to={nextPageURL}
          className="flex justify-end bg-white !text-[#364A5E] font-bold text-center"
          onClick={handleNext}
        >
          <div className="pr-4 self-center m-auto">
            {i18n.ft(messages.next)}
          </div>
          <img src={nextIcon} alt={i18n.ft(messages.next)} />
        </Link>
      )}

      <>{rightSideElement}</>
    </div>
  );
}

export default FooterNav;
