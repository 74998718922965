const PATH = 'fe.components.app.footer';

const messages = {
  support: {
    key: `${PATH}.support`,
    defaultValue: 'Support'
  },
  privacy: {
    key: `${PATH}.privacy`,
    defaultValue: 'Privacy Policy'
  },
  terms: {
    key: `${PATH}.terms`,
    defaultValue: 'Terms of Use'
  },
  accessibility: {
    key: `${PATH}.accessibility`,
    defaultValue: 'Web Accessibility'
  },
  copyright: {
    key: `${PATH}.copyright`,
    defaultValue: '© {{year}} Teachstone Training, LLC'
  }
};

export default messages;
