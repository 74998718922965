const PATH = 'fe.components.measure.observation_scores';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Observation Scores'
  }
};

export default messages;
