const PATH = 'fe.components.trainings.view.participants.request_payment_modal';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Payment Request'
  },
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue:
      'Enter an email address to request payment for the selected participants'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  example: {
    key: `${PATH}.example`,
    defaultValue: 'example@mail.com'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'Invalid email format'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  sendRequest: {
    key: `${PATH}.send_request`,
    defaultValue: 'Send Payment Request'
  },
  success: {
    key: `${PATH}.success`,
    defaultValue: 'Success!'
  },
  emailSent: {
    key: `${PATH}.email_sent`,
    defaultValue:
      'An email has been sent to {{email}} with details for completing the payment process.'
  },
  return: {
    key: `${PATH}.return`,
    defaultValue: 'Return to Training'
  }
};

export default messages;
