const PATH =
  'fe.components.measure.view.examples_and_notes.custom_questions_section';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Observation Questions'
  },
  titleFeedback: {
    key: `${PATH}.title_feedback`,
    defaultValue: 'Observation Feedback'
  },
  textAreaPlaceholder: {
    key: `${PATH}.text_area_placeholder`,
    defaultValue: 'You can type up to {{length}} character max.'
  },
  answered: {
    key: `${PATH}.answered`,
    defaultValue: 'Answered'
  },
  subtitle: {
    questions: {
      key: `${PATH}.subtitle.questions`,
      defaultValue: 'Answer questions asked by your admin'
    },
    dimensions: {
      key: `${PATH}.subtitle.dimension`,
      defaultValue: 'Please provide feedback for each dimension'
    },
    domains: {
      key: `${PATH}.subtitle.domains`,
      defaultValue: 'Please provide feedback for each domain'
    }
  }
};

export default messages;
