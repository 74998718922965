const PATH = 'fe.components.trainings.view.invitation_banner';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'You have been invited to attend this training.'
  },
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue:
      'To confirm your registration, please accept or decline this invitation.'
  },
  accept: {
    key: `${PATH}.accept`,
    defaultValue: 'Accept'
  },
  decline: {
    key: `${PATH}.decline`,
    defaultValue: 'Decline'
  },
  errorMessage: {
    key: `${PATH}.error_message`,
    defaultValue:
      'Something went wrong. Please <a target="_blank" href="https://teachstone.com/support/">contact us.</a>'
  },
  modal: {
    title: {
      key: `${PATH}.modal.title`,
      defaultValue: 'Are you sure you want to decline this training?'
    },
    confirm: {
      key: `${PATH}.modal.confirm`,
      defaultValue: 'Yes, I will not attend'
    },
    cancel: {
      key: `${PATH}.modal.cancel`,
      defaultValue: 'Cancel'
    }
  }
};

export default messages;
