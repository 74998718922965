import { useState } from 'react';
import useQuery from 'helpers/useQuery';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebouncedEffect from 'hooks/useDebouncedEffect';

function getSearchString(params) {
  return queryString.stringify(params, { skipEmptyString: true });
}

export function useQueryState(name, initialValue = '') {
  const query = useQuery();
  const [value, setValue] = useState(query[name] ?? initialValue);
  return [value, setValue];
}

export function useQueryStringUpdate({ query, status, startDate, endDate }) {
  const navigate = useNavigate();
  const location = useLocation();

  const path = `${location.pathname}?${getSearchString({
    query,
    status,
    startDate,
    endDate
  })}`;

  useDebouncedEffect(
    () => {
      navigate(path, { replace: true });
    },
    300,
    [navigate, query, status, startDate, endDate]
  );
}
