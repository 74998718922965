import PassAttemptMessage from './PassAttemptMessage';
import TrainerViewMessage from './TrainerViewMessage';
import FailedAttemptMessages from './FailedAttemptsMessages';

interface DisplayStatusMessageProps {
  result: string;
  trainerView?: boolean;
  attemptNumber: number;
  knowledgeTest?: boolean;
}

/**
 *
 * Displays various messages, links, and buttons based on user rold, test kind, and outcome (pass/fail)
 *
 */
function DisplayStatusMessage({
  result,
  attemptNumber,
  trainerView,
  knowledgeTest
}: DisplayStatusMessageProps) {
  if (trainerView && !knowledgeTest) {
    return <TrainerViewMessage />;
  }

  if (result === 'passed') {
    return <PassAttemptMessage trainerView={!!trainerView} />;
  }

  return (
    <FailedAttemptMessages
      knowledgeTest={Boolean(knowledgeTest)}
      attemptNumber={attemptNumber}
      trainerView={Boolean(trainerView)}
    />
  );
}

export default DisplayStatusMessage;
