import moment from 'moment';
import { filter } from 'ramda';
import { CoreTest } from 'types/api/core/Test';
import { UserTest } from 'types/api/UserTest';

function addEightWeeks(date: string) {
  return moment(date).add(8, 'weeks');
}

function addSixtyDays(date: string) {
  return moment(date).add(60, 'days');
}

const TODAY = moment();

export function filterActiveCoreTests(tests: CoreTest[]) {
  return filter(test => {
    const fourWeeksAfterFinishDate = addEightWeeks(test.finish_by);
    return fourWeeksAfterFinishDate.isSameOrAfter(TODAY);
  }, tests);
}

export function filterRecentTests(tests: UserTest[]) {
  return filter(test => {
    if (test.type === 'reliability') {
      const fourWeeksAfterFinishDate = addEightWeeks(test.expires_at);
      return fourWeeksAfterFinishDate.isSameOrAfter(TODAY);
    }
    /* knowledge tests */
    const sixtyDaysAfterFinishDate = addSixtyDays(test.expires_at);
    return sixtyDaysAfterFinishDate.isSameOrAfter(TODAY);
  }, tests);
}
