import { Root } from './Styled';
import { activeStep } from './utils';
import StepperButton from './StepperButton';

import {
  OnboardingSteps,
  useOnboardingManager
} from 'components/Onboarding/OnboardingManagerContext';

interface StepperProps {
  /** All the steps. */
  steps: OnboardingSteps[];
}

function Stepper({ steps }: StepperProps) {
  const { setStep, checkedSteps, setCheckedSteps } = useOnboardingManager();

  return (
    <Root role="group" tabIndex={0}>
      {steps.map(step => (
        <StepperButton
          key={step}
          active={activeStep(step, checkedSteps)}
          setCheckedSteps={setCheckedSteps}
          stepName={step}
          checkedSteps={checkedSteps}
          setStep={setStep}
        />
      ))}
    </Root>
  );
}

export default Stepper;
