import styled from 'styled-components';
import { Checkbox as CheckboxBase } from 'components/EnhancedForm';

export const Checkbox = styled(CheckboxBase)`
  & > .ui.checkbox > input.hidden {
    display: initial;
  }
`;

export const DualRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const DualRowItem = styled.div`
  width: calc((100% - 16px) / 2);

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

export const ErrorContainer = styled.div`
  margin-top: 16px;
`;
