import { Box } from 'components/Box';
import MainContent from 'components/MainContent';
import PageBackground from 'components/PageBackground';
import {
  LogoImg,
  Subtitle,
  Title,
  TitleWrapper
} from 'components/PageHeader/Styled';
import PageWrapper from 'components/PageWrapper';
import { ContainerSection as Section } from 'components/Reports/Reliability/View/Styled';
import logoCertification from 'images/reliabilityTest/logoCertification.svg';
import { PageHeaderWrapper } from 'components/TestAttempts/Styled';
import messages from '../messages';
import i18n from 'helpers/i18n';
import Footer from './Footer';
import InnerHeader from '../InnerHeader';
import { useMediaQuery } from 'react-responsive';
import classExtensionIcon from 'images/reliability/ClassExtension.png';
import classExtensionMobileIcon from 'images/reliability/ClassExtensionMobile.png';
import Expired from './Expired';
import ExpiredRenewable from './ExpiredRenewable';
import { TestAttempt, TestTemplate, UserTestSlim } from 'types/api/TestAttempt';

interface ExpiredTestProps extends Partial<TestAttempt> {
  attempt: number;
  expires_at: string;
  test_template: TestTemplate;
  title: string;
  two_week_extension_sku?: string | null;
  user_test: UserTestSlim;
}

function ExpiredTest({
  attempt,
  expires_at,
  renewable = false,
  test_template: { class_version },
  title,
  two_week_extension_sku = null,
  user_test: { role }
}: ExpiredTestProps & { renewable?: boolean }) {
  const isTablet = useMediaQuery({ minWidth: 768 });

  return (
    <MainContent maxWidth={1280}>
      <PageWrapper borderStyle>
        <PageHeaderWrapper>
          <TitleWrapper>
            <LogoImg
              src={logoCertification}
              alt={i18n.ft(messages.logoDescription)}
            />
            <Title>{title}</Title>
            <Subtitle>{i18n.ft(messages.subtitle)}</Subtitle>
          </TitleWrapper>
        </PageHeaderWrapper>
        <PageBackground borderStyle>
          <InnerHeader
            attempt={i18n.ft(messages.attempt, { value: attempt })}
            expiresOn={i18n.ft(messages.expiresOn)}
            expiresOnMobile={i18n.ft(messages.expiresOnMobile)}
          />
          <Box>
            <Section
              mt="8px"
              px="48px"
              py="24px"
              mx={{ _: '0px', lg: '32px' }}
              isMobile
              borderTopRadius={isTablet ? '12px' : ''}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 py-[100px]">
                <div className="col-span-5">
                  <img
                    src={
                      renewable ? classExtensionIcon : classExtensionMobileIcon
                    }
                    alt="CLASS test extension"
                    style={{ width: '120px' }}
                    className="mx-auto"
                  />
                </div>

                <div className="col-span-7">
                  {renewable ? (
                    <ExpiredRenewable
                      expires_at={expires_at}
                      two_week_extension_sku={two_week_extension_sku}
                    />
                  ) : (
                    <Expired framework={class_version} role={role} />
                  )}
                </div>
              </div>
            </Section>
          </Box>

          <Footer isTablet={isTablet} />
        </PageBackground>
      </PageWrapper>
    </MainContent>
  );
}

export default ExpiredTest;
