import styled from 'styled-components';

export const RadioInput = styled.input.attrs(_props => ({
  type: 'radio'
}))<{ error: boolean }>`
  appearance: none;

  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;

  background-color: #fff;
  border: 0.5px solid ${p => (p.error ? p.theme.colors.scaleWarning : 'black')};

  &:checked {
    background-color: ${p => p.theme.colors.scaleGreen};
    border: 0;
    outline: 1px solid ${p => p.theme.colors.scaleGreen};
    outline-offset: 3px;
  }

  &:focus {
    outline: 1px solid ${p => p.theme.colors.scaleGreen};
    outline-offset: 3px;
  }
`;

export const CheckboxInput = styled.input.attrs(_props => ({
  type: 'checkbox'
}))`
  appearance: none;
  --size: 32px;
  width: var(--size);
  height: var(--size);
  background-color: #fff;
  border: 0.5px solid black;
  border-radius: 4px;
  cursor: pointer;

  &:checked {
    background-color: ${p => p.theme.colors.scaleGreen};
    border: 0;
  }

  & + label {
    cursor: pointer;
  }

  &:focus {
    outline: 1px solid ${p => p.theme.colors.scaleGreen};
    outline-offset: 3px;
  }
`;
