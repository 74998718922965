const PATH = 'fe.components.trainings.view.card_checkout.billing_form';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: '1. Billing Address'
  },
  edit: {
    key: `${PATH}.edit`,
    defaultValue: 'Edit'
  },
  continue: {
    key: `${PATH}.continue`,
    defaultValue: 'Continue'
  },
  errors: {
    email: {
      required: {
        key: `${PATH}.errors.email.is_required`,
        defaultValue: 'Email address is required'
      },
      invalid: {
        key: `${PATH}.errors.email.is_invalid`,
        defaultValue: 'Invalid email format'
      }
    }
  }
};

export default messages;
