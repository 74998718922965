const PATH = 'fe.components.training_catalog.template_card';

const messages = {
  ageNeutral: {
    key: `${PATH}.age_neutral`,
    defaultValue: 'Age Neutral'
  }
};

export default messages;
