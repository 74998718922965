import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { SubmitButton } from '../Styled';
import { createOrder, submitPurchaseOrder } from 'actions/trainings';
import { getGrandTotal } from 'components/Trainings/View/CardCheckout/CreditCardForm/utils';
import {
  ErrorContainer,
  Checkbox
} from 'components/Trainings/View/CardCheckout/CreditCardForm/Styled';
import Typography from 'components/Typography';
import { isNil } from 'ramda';
import { Link } from 'react-router-dom';
import { Formik, Form, Input } from 'components/EnhancedForm';

const validationSchema = yup.object({
  agreeToPolicy: yup
    .bool()
    .oneOf([true], () => i18n.ft(messages.errors.agreeToPolicy.mustAgree)),
  purchase_order_number: yup
    .string()
    .required(() => i18n.ft(messages.errors.purchaseOrderNumber.required)),
  file: yup
    .mixed()
    .required(() => i18n.ft(messages.errors.file.required))
    .test(
      'fileFormat',
      i18n.ft(messages.errors.file.pdfOnly),
      value => value && value.toLowerCase().endsWith('.pdf')
    )
});

const initialValues = {
  purchase_order_number: '',
  file: undefined,
  agreeToPolicy: false
};

const PurchaseOrderForm = ({ trainingId, afterSubmit, checkout }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState();

  const attachFile = formik => {
    return (event, { value }) => {
      setFile(event.target.files[0]);
      formik.setFieldValue('file', value);
    };
  };

  function handleSubmit(values) {
    setError(null);
    setLoading(true);

    const formData = new FormData();
    formData.append('purchase_order_number', values['purchase_order_number']);
    formData.append('file', file, file.name);

    createOrder(trainingId).then(() => {
      submitPurchaseOrder(trainingId, formData)
        .then(() => {
          setLoading(false);
          afterSubmit();
        })
        .catch(() => {
          setLoading(false);
          setError(i18n.ft(messages.errors.unknown));
        });
    });
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, ...formik }) => (
        <Form>
          <Input
            name="purchase_order_number"
            label={i18n.ft(messages.purchaseOrderNumber)}
          />

          <Input
            name="file"
            label={i18n.ft(messages.purchaseOrderPDF)}
            type="file"
            onChange={attachFile(formik)}
          />

          <Checkbox
            name="agreeToPolicy"
            error={{ textAlign: 'left' }}
            aria-label={i18n.ft(messages.policyLabel)}
            label={
              <label>
                {i18n.ft(messages.policy01) + ' '}
                <Link
                  target="_blank"
                  to="/trainings/affiliate-cancellation-policy"
                >
                  {i18n.ft(messages.policy02) + ' '}
                  <Icon
                    size="small"
                    name="external"
                    aria-label={i18n.ft(messages.opensWindow)}
                  />
                </Link>
              </label>
            }
          />

          <SubmitButton>
            <Button
              color="green"
              loading={loading}
              disabled={loading}
              onClick={submitForm}
            >
              {isNil(checkout)
                ? i18n.ft(messages.finishAndPay)
                : `${i18n.ft(messages.finishAndPay)} ${getGrandTotal(
                    checkout
                  )}`}
            </Button>
          </SubmitButton>

          {error && (
            <ErrorContainer>
              <Typography
                bold
                color="red"
                display="block"
                content={error}
                textAlign="center"
              />
            </ErrorContainer>
          )}
        </Form>
      )}
    </Formik>
  );
};

PurchaseOrderForm.propTypes = {
  /** The training ID. */
  trainingId: PropTypes.number.isRequired,

  /** Callback fired after the payment has been processed. */
  afterSubmit: PropTypes.func.isRequired,

  /** Big Commerce Checkout */
  checkout: PropTypes.object
};

export default PurchaseOrderForm;
