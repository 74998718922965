const PATH =
  'fe.pages.improvements.educator_profile.action_plan.schedule_observation';

const messages = {
  schedule: {
    key: `${PATH}.schedule`,
    defaultValue: 'Schedule'
  },
  observationDate: {
    key: `${PATH}.observation_date`,
    defaultValue: 'Observation Date'
  },
  selectObservationDate: {
    key: `${PATH}.select_observation_date`,
    defaultValue: 'Select Observation Date'
  },
  startTime: {
    key: `${PATH}.start_time`,
    defaultValue: 'Start Time'
  },
  selectTime: {
    key: `${PATH}.select_time`,
    defaultValue: 'Select Time'
  }
};
export default messages;
