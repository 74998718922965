const PATH = 'fe.pages.reports.observation_v2';

const messages = {
  backToReport: {
    key: `${PATH}.back_to_report`,
    defaultValue: 'Back to Report'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  },
  generateReport: {
    key: `${PATH}.generate_report`,
    defaultValue: 'Generate Report'
  },
  addComparison: {
    key: `${PATH}.add_comparison`,
    defaultValue: 'Add Comparison'
  },
  configNumber: {
    key: `${PATH}.config_number`,
    defaultValue: 'Configuration {{count}}'
  }
};

export default messages;
