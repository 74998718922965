const PATH = 'fe.components.trainings.view.navigation';

const messages = {
  details: {
    key: `${PATH}.details`,
    defaultValue: 'Training Details'
  },
  participants: {
    key: `${PATH}.participants`,
    defaultValue: 'Participants'
  },
  takeAttendance: {
    key: `${PATH}.take_attendance`,
    defaultValue: 'Take Attendance'
  },
  viewMaterials: {
    key: `${PATH}.view_materials`,
    defaultValue: 'View Materials'
  }
};

export default messages;
