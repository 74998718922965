import useAsync from 'hooks/useAsync';
import { useLocation } from 'react-router-dom';
import { Assessment } from 'types/api/envScales/Assessment';
import { initialValue, getStatusByPage, isSummaryEnabled } from './utils';
import { getObservationValidations } from 'actions/envScales/observations';
import { ObservationValidations } from 'types/api/envScales/ObservationValidations';

import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode
} from 'react';

const ValidationsContext = createContext(initialValue);
ValidationsContext.displayName = 'ValidationsContext';

interface ValidationsProviderProps {
  /** Children component to render. */
  children: ReactNode;

  /** Assessment data. */
  assessment: Assessment;
}

export function ValidationsProvider({
  children,
  assessment
}: ValidationsProviderProps) {
  const { pathname } = useLocation();
  const observationId = assessment.observation!.id;
  const [showErrorStatus, setShowErrorStatus] = useState(false);

  const {
    run,
    data: validations,
    setData: setValidations
  } = useAsync<ObservationValidations>();

  useEffect(() => {
    run(getObservationValidations(observationId));
  }, [run, pathname, observationId]);

  const statusByPage = getStatusByPage(
    validations,
    assessment,
    showErrorStatus
  );

  async function validate() {
    const { data } = await getObservationValidations(observationId);
    setValidations(data);
    setShowErrorStatus(true);
    return isSummaryEnabled(data, assessment);
  }

  const value = { status: statusByPage, validate };

  return (
    <ValidationsContext.Provider value={value}>
      {children}
    </ValidationsContext.Provider>
  );
}

export function useValidations() {
  const context = useContext(ValidationsContext);

  if (!context) {
    throw new Error(
      'useValidations must be used within an <ValidationsProvider />'
    );
  }

  return context;
}
