const PATH =
  'fe.pages.improvements.educator_profile.educator_details.observations.observation_ratings';

const messages = {
  effectivenessOfStrategiesAndInteractions: {
    key: `${PATH}.effectiveness_of_strategies_and_interactions`,
    defaultValue: 'Coach’s Summary'
  },
  educatorEffectivenessQuestion: {
    key: `${PATH}.educator_effectiveness_question`,
    defaultValue:
      'For each strategy, indicate what you observed. Then indicate how much the interactions you observed supported the focus.'
  },
  notObserved: {
    key: `${PATH}.not_observed`,
    defaultValue: "The educator didn't implement the strategy."
  },
  notAtAll: {
    key: `${PATH}.not_at_all`,
    defaultValue: 'The educator implemented the strategy.'
  },
  somewhat: {
    key: `${PATH}.somewhat`,
    defaultValue:
      'The educator implemented the strategy, and it somewhat worked.'
  },
  completely: {
    key: `${PATH}.completely`,
    defaultValue: 'The educator implemented the strategy, and it worked well.'
  },
  overallRatings: {
    disagree: {
      key: `${PATH}.overall_ratings.disagree`,
      defaultValue: 'Disagree'
    },
    agree: {
      key: `${PATH}.overall_ratings.agree`,
      defaultValue: 'Agree'
    },
    stronglyAgree: {
      key: `${PATH}.overall_ratings.strongly_agree`,
      defaultValue: 'Strongly agree'
    }
  },
  overallObservationRating: {
    key: `${PATH}.overall_observation_rating`,
    defaultValue: 'Overall Support of Focus'
  },
  overallObservationRatingSubtitle: {
    key: `${PATH}.overall_observation_rating_subtitle`,
    defaultValue:
      'Overall, the interactions during the observation supported the focus.'
  },
  optionalSummaryNoteMessage: {
    key: `${PATH}.optional_summary_note_message`,
    defaultValue:
      'Briefly explain your responses above. Indicate whether each child had access to the educator, could participate meaningfully, and benefited from interactions.'
  },
  optionalSummaryNotePlaceholder: {
    key: `${PATH}.optional_summary_note_placeholder`,
    defaultValue:
      'Enter additional reflections, observations, or other relevant notes and context.'
  },
  ratingFieldRequired: {
    key: `${PATH}.rating_field_required`,
    defaultValue: 'Field Required'
  }
};
export default messages;
