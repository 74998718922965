const PATH = 'fe.components.login_manager.login_with_password';

const messages = {
  fields: {
    email: {
      key: `${PATH}.fields.email`,
      defaultValue: 'Email'
    },
    emailPlaceholder: {
      key: `${PATH}.fields.email_placeholder`,
      defaultValue: 'Enter Email'
    },
    phone: {
      key: `${PATH}.fields.phone`,
      defaultValue: 'Phone Number'
    },
    phonePlaceholder: {
      key: `${PATH}.fields.phone_placeholder`,
      defaultValue: 'Enter Phone Number'
    },
    password: {
      key: `${PATH}.fields.password`,
      defaultValue: 'Password'
    },
    passwordPlaceholder: {
      key: `${PATH}.fields.password_placeholder`,
      defaultValue: 'Enter Password'
    }
  },
  errors: {
    email: {
      required: {
        key: `${PATH}.errors.email.required`,
        defaultValue: 'Enter a valid email address'
      }
    },
    phone: {
      required: {
        key: `${PATH}.errors.phone.required`,
        defaultValue: 'Enter a valid phone number'
      }
    },
    password: {
      required: {
        key: `${PATH}.errors.password.required`,
        defaultValue: 'Enter a password'
      }
    }
  },
  login: {
    key: `${PATH}.login`,
    defaultValue: 'Login'
  },
  forgotPassword: {
    key: `${PATH}.forgot_password`,
    defaultValue: 'Forgot Password?'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back to Login Options'
  }
};

export default messages;
