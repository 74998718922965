import i18n from 'helpers/i18n';
import messages from './messages';
import lampIcon from 'images/reliabilityTest/lampIcon.svg';

function KnowledgeTestInstructionsContent() {
  return (
    <div tabIndex={0}>
      <ul className="list-disc ms-6 marker:text-orange-400 text-3xl">
        <li className="mb-4">
          <div className="flex items-start ms-4 text-lg">
            {i18n.ft(messages.attempts)}
          </div>
        </li>
        <li className="mb-4">
          <div className="flex items-start ms-4 text-lg">
            {i18n.ft(messages.pass)}
          </div>
        </li>
        <li className="mb-4">
          <div className="flex items-start ms-4 text-lg">
            {i18n.ft(messages.fail)}
          </div>
        </li>
        <li className="mb-4">
          <div className="flex items-start ms-4 text-lg">
            {i18n.ft(messages.complete)}
          </div>
        </li>
        <li className="mb-4">
          <div className="flex items-start ms-4 text-lg">
            {i18n.ft(messages.provide)}
          </div>
        </li>
      </ul>
      <div className="font-bold text-cyan-700 mt-10 mb-3 text-lg">
        {i18n.ft(messages.recommendations)}
      </div>
      <ul className="mb-5">
        <li className="mb-4">
          <div className="flex items-start text-lg">
            <img src={lampIcon} className="inline mr-4" alt="bullet point" />
            {i18n.ft(messages.test)}
          </div>
        </li>
        <li className="mb-4">
          <div className="flex items-start text-lg">
            <img src={lampIcon} className="inline mr-4" alt="bullet point" />
            {i18n.ft(messages.unsuccessful)}
          </div>
        </li>
      </ul>
    </div>
  );
}

export default KnowledgeTestInstructionsContent;
