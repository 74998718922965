const PATH = 'fe.components.trainings.view.participants.invite_canceled_modal';

const messages = {
  areYouSure: {
    key: `${PATH}.are_you_sure`,
    defaultValue:
      'Are you sure you want to invite theses canceled participant(s) again?'
  },
  dismiss: {
    key: `${PATH}.dismiss`,
    defaultValue: 'Dismiss'
  },
  confirm: {
    key: `${PATH}.confirm`,
    defaultValue: 'Yes, Invite Participant(s)'
  }
};

export default messages;
