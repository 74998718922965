import * as R from 'ramda';

function toTitleCase(text: string): string {
  const textWithSpaces = R.replace('_', ' ', text);
  const textAsArray = R.split(' ', textWithSpaces);
  const textAsArrayCapitalized = R.map(
    t => t.charAt(0).toUpperCase() + t.slice(1),
    textAsArray
  );

  return R.join(' ', textAsArrayCapitalized);
}

export default toTitleCase;
