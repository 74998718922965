import { useEffect } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { getAccountSubscriptions } from 'actions/subscriptions';
import SubscriptionTile from './SubscriptionTile';
import { Subscription } from 'types/api/Subscription';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import { Loader } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import {
  SubscriptionContainer,
  Header,
  HeaderItem,
  FirstHeaderItem,
  List,
  PaginationWrapper,
  Pagination,
  Wrapper,
  NoSubscriptions,
  LoaderWrapper,
  SmallHeader
} from './Styled';

interface SubscriptionsProps {
  accountName: string;
}

function Subscriptions({ accountName }: SubscriptionsProps) {
  const { data: subscriptions, run, isPending } = useAsync();
  const isLessThan1280px = useMediaQuery({ maxWidth: 1280 });

  const { state, setPage } = usePaginatedSearch({
    page: 1,
    per_page: 10
  });

  useEffect(() => {
    run(getAccountSubscriptions(state));
  }, [run, state]);

  return (
    <Wrapper>
      <SubscriptionContainer>
        {isLessThan1280px ? (
          <>
            <SmallHeader>
              <FirstHeaderItem>
                {i18n.ft(messages.subscription)}
              </FirstHeaderItem>
              <HeaderItem>{i18n.ft(messages.usersMaxSeats)}</HeaderItem>
            </SmallHeader>
            {!isPending ? (
              <>
                <List>
                  {subscriptions.subscriptions.length ? (
                    <>
                      {subscriptions.subscriptions.map(
                        (subscription: Subscription, index: number) => (
                          <SubscriptionTile
                            subscription={subscription}
                            key={index}
                          />
                        )
                      )}
                    </>
                  ) : (
                    <NoSubscriptions>
                      {i18n.ft(messages.noSubscriptions, { name: accountName })}
                      <a
                        href={`https://teachstone.com/mycoachconnect`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {i18n.ft(messages.here)}
                      </a>
                    </NoSubscriptions>
                  )}
                </List>
              </>
            ) : (
              <LoaderWrapper>
                <Loader active inline="centered" />
              </LoaderWrapper>
            )}
          </>
        ) : (
          <>
            <Header>
              <FirstHeaderItem>
                {i18n.ft(messages.subscriptionName)}
              </FirstHeaderItem>
              <HeaderItem>{i18n.ft(messages.users)}</HeaderItem>
              <HeaderItem>{i18n.ft(messages.product)}</HeaderItem>
              <HeaderItem>{i18n.ft(messages.endDate)}</HeaderItem>
              <HeaderItem>{i18n.ft(messages.maxSeats)}</HeaderItem>
            </Header>
            {!isPending ? (
              <List>
                {subscriptions.subscriptions.length ? (
                  <>
                    {subscriptions.subscriptions.map(
                      (subscription: Subscription, index: number) => (
                        <SubscriptionTile
                          subscription={subscription}
                          key={index}
                        />
                      )
                    )}
                  </>
                ) : (
                  <NoSubscriptions>
                    {i18n.ft(messages.noSubscriptions, { name: accountName })}
                    <a
                      href={`https://teachstone.com/mycoachconnect`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {i18n.ft(messages.here)}
                    </a>
                  </NoSubscriptions>
                )}
              </List>
            ) : (
              <LoaderWrapper>
                <Loader active inline="centered" />
              </LoaderWrapper>
            )}
          </>
        )}
      </SubscriptionContainer>
      {!isPending && subscriptions.pagination.total_pages !== 0 && (
        <PaginationWrapper>
          <Pagination
            size="small"
            activePage={subscriptions.pagination.current_page}
            totalPages={subscriptions.pagination.total_pages}
            onPageChange={(e: any, data: any) =>
              setPage(Number(data.activePage))
            }
          />
        </PaginationWrapper>
      )}
    </Wrapper>
  );
}

export default Subscriptions;
