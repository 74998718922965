import moment from 'moment';
import { Training } from 'types/api/Training';
import { UserCertification } from 'types/api/UserCertification';

export function getActiveCertifications(certifications: UserCertification[]) {
  let activeCertification: UserCertification[] = [];

  for (let cert of certifications) {
    if (moment(cert.renewable_until) > moment()) {
      const sameName = activeCertification.findIndex(
        actCert => actCert.certification.name === cert.certification.name
      );
      if (
        sameName > -1 &&
        moment(activeCertification[sameName].end_date) < moment(cert.end_date)
      ) {
        activeCertification.splice(sameName, 1);
        activeCertification.push(cert);
      } else {
        activeCertification.push(cert);
      }
    }
  }
  return activeCertification;
}

export function getActiveTrainings(trainings: Training[]) {
  let activeTrainings: Training[] = [];
  const trainingsSort = sortTrainings(trainings);
  const aMonthAgo = moment().subtract(1, 'month').format('YYYY-MM-DD');
  for (let training of trainingsSort) {
    if (!moment(training.end_date).isBefore(aMonthAgo)) {
      activeTrainings.push(training);
    }
  }
  return activeTrainings;
}

function sortTrainings(trainings: Training[]) {
  return trainings.sort(
    (objA, objB) =>
      moment(objA.start_date).valueOf() - moment(objB.start_date).valueOf()
  );
}
