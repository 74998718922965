import styled from 'styled-components';

export const Wrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 44px;
  }
`;

export const Name = styled.span`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: ${p => p.theme.colors['neutral']['700']};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const GridRow = styled.div<{ hasError?: boolean }>`
  display: grid;
  grid-template-columns: 36px auto;
  background-color: #f4f8fa;
  margin-top: 6px;
  margin-left: -16px;
  margin-right: -16px;
  border: ${p => (p.hasError ? '1px solid #AC213A' : null)};

  @media screen and (min-width: 640px) {
    border-radius: 12px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 36px auto repeat(4, 80px);
  }

  @media screen and (min-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const GridCell = styled.div<{ justify?: 'left'; align?: 'top' }>`
  padding: 16px 12px;
  display: flex;
  align-items: ${p => (p.align === 'top' ? 'flex-start' : 'center')};
  justify-content: ${p => (p.justify === 'left' ? 'flex-start' : 'center')};
`;

export const GridCellMobile = styled(GridCell)`
  grid-column: 2;
  display: block;
`;

export const ScoreGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 32px;
  justify-items: start;
`;

export const ScoreCell = styled(GridCell)`
  padding: 0;
`;

export const HeaderGridRow = styled(GridRow)`
  border-radius: 4px;
  margin-top: 0;
  margin-bottom: 12px;
  background-color: unset;
`;

export const HeaderGridCell = styled(GridCell)`
  padding-top: 4px;
  padding-bottom: 4px;
  overflow: hidden;
`;

export const HeaderGridText = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${p => p.theme.colors['neutral']['700']};
`;
