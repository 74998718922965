const PATH = 'fe.components.measure.view.cycle.meta_data_multi_select';

const messages = {
  type: {
    key: `${PATH}.type`,
    defaultValue: '{{category}} Type'
  },
  primary: {
    key: `${PATH}.primary`,
    defaultValue: '{{choice}} primary'
  },
  errors: {
    categoryError: {
      key: `${PATH}.category_error`,
      defaultValue: 'Select the {{categoryName}} observed.'
    },
    primaryError: {
      key: `${PATH}.primary_error`,
      defaultValue: 'Star the primary {{categoryName}} type.'
    }
  }
};

export default messages;
