import * as R from 'ramda';
import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import { toTitleCase } from 'helpers/text';
import { useEffect } from 'react';
import PageLoader from 'components/PageLoader';
import MainContent from 'components/MainContent';
import PageHeader from 'components/Measure/PageHeader';
import PageBackground from 'components/PageBackground';
import {
  Button,
  Icon,
  Modal,
  Header,
  Input,
  Dropdown
} from 'semantic-ui-react';
import centerIcon from 'images/envScales/centerIcon.svg';
import classroomIcon from 'images/envScales/classroomIcon.svg';
import classVideoIcon from 'images/envScales/classVideoIcon.svg';
import doubleCodeIcon from 'images/envScales/doubleCodeVideo.svg';
import StatusButton from 'components/Measure/Home/StatusButton';
import { SelectedAccount } from 'types/api/Account';
import { SettingQuestion } from 'types/api/envScales/SettingQuestion';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import { useActiveSubscriptions } from 'context/auth';
import { hasRole } from 'pages/Measure/utils';
import { useNavigate } from 'react-router-dom';
import Pagination from 'components/Measure/Pagination';
import { useState } from 'react';
import debounce from 'debounce';

import {
  ObservationVideo,
  OBSERVATION_VIDEO_STATUS
} from 'types/api/envScales/ObservationVideo';

import {
  getObservationVideos,
  GetObservationVideosRes,
  getObservationVideosSummary,
  GetObservationVideoSummaryRes,
  assignAdminToVideo,
  assignObserverToVideo,
  returnObservationToSubmitted,
  removeObserverFromVideo,
  removeAssessmentFromVideo
} from 'actions/envScales/observationVideos';

import {
  ListWrapper,
  CardWrapper,
  CardVideoThumb,
  MetaGrid,
  DateMetaGrid,
  FilterWrapper
} from './Styled';
import ConfirmationModal from 'components/Modals/ConfirmationModal';

function formatDate(date: Date) {
  return moment(date).format('L');
}

type FilterType = {
  q: string;
  status: OBSERVATION_VIDEO_STATUS;
  page: number;
  per_page: number;
};

interface VideosHomeProps {
  account: SelectedAccount;
  accountSettings: SettingQuestion;
  initialStatus?: OBSERVATION_VIDEO_STATUS;
}

function VideosHome({
  account,
  accountSettings,
  initialStatus
}: VideosHomeProps) {
  const subscriptions = useActiveSubscriptions();
  const isAccountObserver = Boolean(
    hasRole(subscriptions, account.id, 'observer')
  );
  const isAccountAdmin = Boolean(hasRole(subscriptions, account.id, 'admin'));
  const isSupervisor = Boolean(
    hasRole(subscriptions, account.id, 'supervisor')
  );
  const { run, data, isPending } = useAsync<GetObservationVideosRes>();
  const { run: summaryRun, data: summary } =
    useAsync<GetObservationVideoSummaryRes>();
  const navigate = useNavigate();

  let status: null | OBSERVATION_VIDEO_STATUS;
  if (initialStatus) {
    status = initialStatus;
  } else {
    status = isAccountObserver ? 'in_progress' : 'submitted';
  }

  const hasAwaitingApproval = accountSettings.require_observation_approval;
  const { state, setState, setPage } = usePaginatedSearch<FilterType>({
    q: '',
    status,
    page: 1,
    per_page: 30
  });
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    i18n.ft(messages.modal.errorProcessingRequest)
  );

  function isViewScoresButton(video: ObservationVideo) {
    return ['awaiting_approval', 'completed'].includes(video.status);
  }

  function shouldDisplayLink(video: ObservationVideo) {
    if (isAccountObserver) {
      const statusForObserver = [
        'in_progress',
        'awaiting_approval',
        'completed',
        'accepted'
      ];
      return statusForObserver.includes(video.status);
    }

    if (isAccountAdmin) {
      const statusForAdmin = [
        'submitted',
        'qa',
        'accepted',
        'awaiting_approval',
        'completed'
      ];
      return statusForAdmin.includes(video.status);
    }

    if (isSupervisor) {
      const statusForSupervisor = ['awaiting_approval', 'completed'];
      return statusForSupervisor.includes(video.status);
    }

    return false;
  }

  function isDoubleCoding(video: ObservationVideo) {
    if (!video.double_coding) {
      return false;
    }

    if (isAccountObserver && video.anchor_coding && !video.primary_observer) {
      return false;
    }

    if (isAccountAdmin) {
      return (
        video.status === 'awaiting_approval' || video.status === 'completed'
      );
    }

    if (isAccountObserver) {
      return (
        video.anchor_coding &&
        (video.status === 'accepted' ||
          video.status === 'in_progress' ||
          video.status === 'awaiting_approval' ||
          video.status === 'completed')
      );
    }

    return false;
  }

  function getDoubleCodingLabel(video: ObservationVideo) {
    if (isAccountObserver) {
      return i18n.ft(messages.anchorCoded);
    } else if (video.primary_observer) {
      return i18n.ft(messages.primary);
    } else {
      return i18n.ft(messages.secondary);
    }
  }

  function shouldShowFilter() {
    return (
      isAccountAdmin || (isAccountObserver && state.status !== 'in_progress')
    );
  }

  useEffect(loadVideoHome, [run, state]);

  useEffect(loadSummary, [summaryRun]);

  function loadVideoHome() {
    run(getObservationVideos(state));
  }

  function loadSummary() {
    summaryRun(getObservationVideosSummary());
  }

  const labels = {
    submitted: i18n.ft(messages.submitted),
    qa: i18n.ft(messages.inQA),
    accepted: i18n.ft(messages.accepted),
    in_progress: i18n.ft(messages.inProgress),
    awaiting_approval: i18n.ft(messages.awaitingApproval),
    completed: i18n.ft(messages.completed)
  };

  const countMap = {
    submitted: summary?.total_submitted,
    qa: summary?.total_qa,
    accepted: summary?.total_accepted,
    in_progress: summary?.total_in_progress,
    awaiting_approval: summary?.total_awaiting_approval,
    completed: summary?.total_completed
  };

  function getStatusProps(videoStatus: OBSERVATION_VIDEO_STATUS) {
    return {
      count: countMap[videoStatus],
      label: labels[videoStatus],
      active: videoStatus === state.status,
      onClick: () => setState({ status: videoStatus })
    };
  }

  function handleClick(video: ObservationVideo) {
    const urlParams = { id: video.assessment_id };
    const isCompleted = video.status === 'completed';
    const isAwaitingApproval = video.status === 'awaiting_approval';

    if (isCompleted) {
      navigate(RouteHelpers.getPath('measure-completed', urlParams));
      return;
    }
    if (isAwaitingApproval) {
      const awaitingApprovalRedirectTo =
        isAccountAdmin || isSupervisor
          ? 'measure-summary'
          : 'measure-completed';
      navigate(RouteHelpers.getPath(awaitingApprovalRedirectTo, urlParams));
      return;
    }

    if (isAccountObserver && video.status === 'accepted') {
      reviewVideoAsObserver(video.id);
      return;
    }

    if (isAccountObserver && video.assessment_id !== null) {
      navigate(RouteHelpers.getPath('measure-view', urlParams));
      return;
    }

    if (video.status === 'submitted' && isAccountAdmin) {
      assignSubmittedVideo(video.id);
    } else {
      navigate(
        RouteHelpers.getPath('measure-videos-view', {
          id: video.id
        })
      );
    }
  }

  function assignSubmittedVideo(id: number) {
    assignAdminToVideo(id)
      .then(() => {
        navigate(
          RouteHelpers.getPath('measure-videos-view', {
            id: id
          })
        );
      })
      .catch(({ response }) => {
        setErrorState(response);
      });
  }

  function reviewVideoAsObserver(id: number) {
    assignObserverToVideo(id)
      .then(() => {
        navigate(
          RouteHelpers.getPath('measure-videos-view', {
            id: id
          })
        );
      })
      .catch(({ response }) => {
        setErrorState(response);
      });
  }

  function handleReturnToSubmitted(id: number, closeModal: () => void) {
    returnObservationToSubmitted(id)
      .then(() => {
        setTimeout(loadVideoHome, 600);
        setTimeout(loadSummary, 600);
        closeModal();
      })
      .catch(({ response }) => {
        setErrorState(response);
      });
  }

  function handleSearch(e: any, { value }: any) {
    setState({ q: value });
  }

  function handleRemove(video: ObservationVideo, closeModal: () => void) {
    if (video.assessment_id) {
      removeAssessmentFromVideo(video.id)
        .then(() => {
          setTimeout(loadVideoHome, 600);
          closeModal();
        })
        .catch(({ response }) => {
          setErrorState(response);
        });
    } else {
      removeObserverFromVideo(video.id)
        .then(() => {
          setTimeout(loadVideoHome, 600);
          setTimeout(loadSummary, 600);
          closeModal();
        })
        .catch(({ response }) => {
          setErrorState(response);
        });
    }
  }

  function setErrorState(response: any) {
    if (!R.isEmpty(response.data.errors)) {
      const message =
        response.data.errors[Object.keys(response.data.errors)[0]];
      setErrorMessage(message);
    }
    setOpenModal(true);
  }

  return (
    <MainContent maxWidth={1536}>
      <div className="mt-8 px-3 sm:px-6 lg:px-[72px]">
        <PageHeader
          icon={classVideoIcon}
          iconAlt={i18n.ft(messages.header)}
          title={i18n.ft(messages.header)}
        />
        <PageBackground>
          <div className="text-lg">{i18n.ft(messages.selectStatus)}</div>
          <div className="mt-3 grid grid-cols-3 xl:grid-cols-4 gap-7">
            {!isAccountObserver ? (
              <>
                <StatusButton {...getStatusProps('submitted')} />
                <StatusButton {...getStatusProps('qa')} />
                <StatusButton {...getStatusProps('accepted')} />
              </>
            ) : null}

            <StatusButton {...getStatusProps('in_progress')} />
            {hasAwaitingApproval ? (
              <StatusButton {...getStatusProps('awaiting_approval')} />
            ) : null}
            <StatusButton {...getStatusProps('completed')} />
          </div>

          {shouldShowFilter() ? (
            <FilterWrapper>
              <Input
                fluid
                size="large"
                icon="search"
                iconPosition="left"
                placeholder={i18n.ft(messages.searchPlaceholder)}
                onChange={debounce(handleSearch, 300)}
              />
            </FilterWrapper>
          ) : null}

          <ListWrapper className="mt-12 flex flex-col gap-6">
            {isPending ? (
              <PageLoader />
            ) : (
              <>
                <span className="text-lg">
                  {i18n.ft(messages.countSummary, {
                    current: data.observation_videos.length,
                    total: data.pagination.total_count
                  })}
                </span>

                {data.observation_videos.map(video => (
                  <CardWrapper
                    key={video.id}
                    className="py-8 px-12 rounded-xl flex justify-between gap-10"
                  >
                    <CardVideoThumb>
                      {video.thumbnail_url ? (
                        <img
                          loading="lazy"
                          src={video.thumbnail_url}
                          width="273"
                          height="154"
                          alt={i18n.ft(messages.videosThumbnail)}
                        />
                      ) : (
                        <Icon fitted inverted size="big" name="video" />
                      )}
                    </CardVideoThumb>

                    <div className="flex-1">
                      <MetaGrid className="flex-1">
                        <img
                          className="w-5 self-center"
                          src={centerIcon}
                          alt=""
                        />
                        <div className="font-bold">
                          {i18n.ft(messages.center) + ':'}
                        </div>
                        <div className="col-span-3 xl:col-auto">
                          {video.center_name}
                        </div>

                        <img className="w-5" src={classroomIcon} alt="" />
                        <div className="font-bold">
                          {i18n.ft(messages.classroom) + ':'}
                        </div>
                        <div className="col-span-3 xl:col-auto">
                          {video.classroom_name}
                        </div>

                        <Icon name="world" />
                        <div className="font-bold">
                          {i18n.ft(messages.language) + ':'}
                        </div>
                        <div className="col-span-3 xl:col-auto">
                          {toTitleCase(video.primary_language)}
                        </div>

                        {isDoubleCoding(video) && (
                          <>
                            <img className="w-5" src={doubleCodeIcon} alt="" />
                            <div className="font-bold">
                              {i18n.ft(messages.doubleCode) + ':'}
                            </div>
                            <div className="col-span-3 xl:col-auto">
                              {getDoubleCodingLabel(video)}
                            </div>
                          </>
                        )}
                      </MetaGrid>
                    </div>

                    <div className="flex flex-col">
                      <DateMetaGrid>
                        <Icon name="calendar alternate" />
                        <div className="font-bold">
                          {i18n.ft(messages.capturedOn) + ':'}
                        </div>
                        <div className="col-span-3 xl:col-auto">
                          {formatDate(video.recorded_at)}
                        </div>

                        <Icon name="calendar alternate" />
                        <div className="font-bold">
                          {i18n.ft(messages.submittedOn) + ':'}
                        </div>
                        <div className="col-span-3 xl:col-auto">
                          {formatDate(video.submitted_at)}
                        </div>
                      </DateMetaGrid>

                      {shouldDisplayLink(video) && (
                        <div className="mt-auto">
                          {state.status === 'qa' ? (
                            <Button.Group color="green">
                              <Button onClick={() => handleClick(video)} fluid>
                                {isViewScoresButton(video)
                                  ? i18n.ft(messages.viewScores)
                                  : i18n.ft(messages.review)}
                              </Button>
                              <Dropdown
                                trigger={<></>}
                                floating
                                button
                                icon="dropdown"
                              >
                                <Dropdown.Menu>
                                  <ConfirmationModal
                                    title={i18n.ft(
                                      messages.modal.returnToSubmitted
                                    )}
                                    message={i18n.ft(messages.modal.warning)}
                                    confirmButton={i18n.ft(
                                      messages.modal.confirm
                                    )}
                                    icon="arrow left"
                                    triggerNode={
                                      <Dropdown.Item>
                                        {i18n.ft(
                                          messages.modal.returnToSubmitted
                                        )}
                                      </Dropdown.Item>
                                    }
                                    onConfirm={({ closeModal }) => {
                                      handleReturnToSubmitted(
                                        video.id,
                                        closeModal
                                      );
                                    }}
                                  />
                                </Dropdown.Menu>
                              </Dropdown>
                            </Button.Group>
                          ) : (
                            <Button
                              fluid
                              color="green"
                              onClick={() => handleClick(video)}
                            >
                              {isViewScoresButton(video)
                                ? i18n.ft(messages.viewScores)
                                : i18n.ft(messages.review)}
                            </Button>
                          )}
                        </div>
                      )}
                      {isAccountAdmin && state.status === 'in_progress' && (
                        <div className="mt-auto">
                          <ConfirmationModal
                            title={
                              video.assessment_id
                                ? i18n.ft(messages.removeAssessment)
                                : i18n.ft(messages.removeObserver)
                            }
                            message={
                              video.assessment_id
                                ? i18n.ft(messages.modal.warningAssessment)
                                : i18n.ft(messages.modal.warningObserver)
                            }
                            confirmButton={i18n.ft(
                              messages.modal.confirmRemove
                            )}
                            icon="trash"
                            triggerNode={
                              <Button color="red" fluid>
                                {video.assessment_id
                                  ? i18n.ft(messages.removeAssessment)
                                  : i18n.ft(messages.removeObserver)}
                              </Button>
                            }
                            onConfirm={({ closeModal }) => {
                              handleRemove(video, closeModal);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </CardWrapper>
                ))}
              </>
            )}

            {!isPending && !R.isEmpty(data.observation_videos) ? (
              <Pagination
                currentPage={data.pagination.current_page}
                totalPages={data.pagination.total_pages}
                onPageChange={(e: any, data: any) =>
                  setPage(Number(data.activePage))
                }
              />
            ) : null}

            {!isPending && R.isEmpty(data.observation_videos) ? (
              <div className="mt-12 text-2xl text-center">
                <Icon name="warning circle" color="red" size="large" />
                <div className="mt-4">
                  {i18n.ft(messages.noVideos, { status: labels[state.status] })}
                </div>
              </div>
            ) : null}
          </ListWrapper>
        </PageBackground>
        <Modal onClose={() => setOpenModal(false)} open={openModal}>
          <Header icon="info circle" content={i18n.ft(messages.modal.error)} />
          <Modal.Content>
            <Modal.Description content={errorMessage} />
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => navigate(0)}
              content={i18n.ft(messages.modal.retrieveNewVideo)}
            />
          </Modal.Actions>
        </Modal>
      </div>
    </MainContent>
  );
}

export default VideosHome;
