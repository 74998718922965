import styled from 'styled-components';

export const Section = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 11px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: ${p => p.theme.colors['white']};
`;

export const Wrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 1024px) {
    margin: 0 1.25em;
    flex: 0 1 100%;
  }
`;
