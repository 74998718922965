import styled from 'styled-components';

export const ListWrapper = styled.div`
  color: #364a5e;
`;

export const CardWrapper = styled.div`
  background-color: #f4f8fa;
`;

export const CardVideoThumb = styled.div`
  width: 273px;
  height: 154px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MetaGrid = styled.div`
  font-size: 20px;
  display: grid;
  grid-gap: 20px 16px;
  grid-template-columns: 20px 124px 1fr;
`;

export const DateMetaGrid = styled(MetaGrid)`
  grid-template-columns: 20px 148px 1fr;
`;

export const FilterWrapper = styled.div`
  margin-top: 32px;
  display: grid;
  grid-cols: 1;

  && input {
    border: 0px;
  }
`;
