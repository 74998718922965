const PATH = 'fe.components.reliability_test.expired_test';

const messages = {
  expiredRenewable: {
    key: `${PATH}.expired_renewable`,
    defaultValue:
      'This test recently expired on {{date}}<br />but it‘s not too late to purchase an extension.'
  },
  expired: {
    key: `${PATH}.expired`,
    defaultValue:
      'Unfortunately this test is expired. To become certified <br />you‘ll need to attend {{type}} training.'
  },
  purchaseExtension: {
    key: `${PATH}.purchase_extension`,
    defaultValue: 'Purchase Extension'
  },
  upcomingTrainings: {
    key: `${PATH}.upcoming_trainings`,
    defaultValue: 'Upcoming Trainings'
  }
};

export default messages;
