const PATH = 'fe.components.reports.reliability.charts.overall_chart';

const messages = {
  reliability: {
    header: {
      key: `${PATH}.reliability.header`,
      defaultValue: 'Overall Participant Reliability'
    }
  },
  knowledge: {
    header: {
      key: `${PATH}.knowledge.header`,
      defaultValue: 'Overall Participant Knowledge'
    }
  },
  attemptNumber: {
    key: `${PATH}.attempt_number`,
    defaultValue: 'Attempt {{value}}'
  },
  passed: {
    key: `${PATH}.passed`,
    defaultValue: 'Passed'
  },
  reliable: {
    key: `${PATH}.reliable`,
    defaultValue: 'Reliable'
  },
  tested: {
    key: `${PATH}.tested`,
    defaultValue: 'Tested'
  }
};

export default messages;
