import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import Spacer from 'components/Spacer';
import { FormikHelpers } from 'formik';
import { Button } from 'semantic-ui-react';
import TextButton from 'components/TextButton';
import { sendSmsCodeForLogin } from 'actions/sms';
import { ButtonWrapper, TextButtonWrapper } from './Styled';
import { Formik, Form, PhoneInput } from 'components/EnhancedForm';
import { useLoginManager } from 'components/LoginManager';

const validationSchema = yup.object({
  phone_number: yup
    .string()
    .required(() => i18n.ft(messages.errors.phone.required))
});

interface SendCodeFormProps {
  /** Callback fired when the SMS code is successfully sent. */
  onSuccess: () => void;
}

function SendCodeForm({ onSuccess }: SendCodeFormProps) {
  const { isLoading, run } = useAsync();
  const { setStep, setEmailOrPhone, emailOrPhone } = useLoginManager();

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    run(sendSmsCodeForLogin(values), {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error: any) => {
        formik.setFieldError('phone_number', error.message);
      }
    });
  }

  function handleTypePassword(phone: string) {
    return () => {
      setEmailOrPhone(phone);
      setStep('loginWithPhone');
    };
  }

  function handleBackToLogin(phone: string) {
    return () => {
      setEmailOrPhone(phone);
      setStep('login');
    };
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{ phone_number: emailOrPhone }}
      validateOnBlur={false}
    >
      {formik => (
        <Form>
          <Spacer size={16} />
          <PhoneInput
            name="phone_number"
            label={i18n.ft(messages.fields.phoneNumber)}
          />

          <ButtonWrapper>
            <Button
              autoFocus
              fluid
              size="large"
              color="green"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
              content={i18n.ft(messages.textMe)}
            />
          </ButtonWrapper>

          <TextButtonWrapper>
            <TextButton
              type="button"
              onClick={handleTypePassword(formik.values.phone_number)}
            >
              {i18n.ft(messages.usePassword)}
            </TextButton>
            <Spacer size={16} />
            <TextButton
              type="button"
              onClick={handleBackToLogin(formik.values.phone_number)}
            >
              {i18n.ft(messages.back)}
            </TextButton>
          </TextButtonWrapper>
        </Form>
      )}
    </Formik>
  );
}

export default SendCodeForm;
