import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import useCurrentUser from 'hooks/useCurrentUser';
import { login } from 'actions/users';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { fetchUserMe } from 'actions/userMe';
import { useCallback, useEffect, useState } from 'react';
import PhonePrompt from 'components/Users/PhonePrompt';
import { useLocation, useNavigate } from 'react-router-dom';
import { isSameDomain, isValidURLForRedirect } from './utils';
import ContainerWithLogo from 'components/ContainerWithLogo';
import { useDispatch } from 'react-redux';
import { update } from 'store/slices/user';

import LoginManager, {
  LoginManagerProps,
  setLoginError
} from 'components/LoginManager';

import {
  Header,
  PageContainer,
  ProductWrapper,
  ProductLabel,
  PasswordHeader,
  PasswordBody
} from './Styled';

type LoginSteps = 'login' | 'setupPhone';

function Login() {
  const { run } = useAsync();
  const [user, setUser] = useState();
  const [step, setStep] = useState<LoginSteps>('login');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentUser.id && step === 'login') {
      navigate(RouteHelpers.getPath('app'), { replace: true });
    }

    setIsLoading(false);
  }, [step, currentUser.id, navigate]);

  const finishLogin = useCallback(
    async (user: any) => {
      const userMeRequest = fetchUserMe();

      if (user.return_to) {
        window.location.href = window.location.origin + user.return_to;
        return;
      }

      // Relative redirect in onelogin
      const redirectTo = query.get('redirect_to');

      // Absolute redirect to core, myTS
      const absRedirectPath = query.get('abs_redirect');
      const absRedirect = absRedirectPath
        ? decodeURIComponent(absRedirectPath)
        : null;

      if (redirectTo) {
        const response = await userMeRequest;
        dispatch(update(response.data));
        navigate(decodeURIComponent(redirectTo));
      } else if (
        absRedirect &&
        isValidURLForRedirect(absRedirect) &&
        isSameDomain(absRedirect)
      ) {
        window.location.href = absRedirect;
      } else {
        const response = await userMeRequest;
        dispatch(update(response.data));
        navigate(RouteHelpers.getPath('app'), { replace: true });
      }
    },
    // Optimize render by only creating callback once.
    // None of the suggested dependencies affect this (history, query).
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [dispatch]
  );

  const handleSetPhone = useCallback(() => {
    finishLogin(user);
  }, [user, finishLogin]);

  const handleLogin: LoginManagerProps['onLogin'] = (
    values,
    formik,
    isFormWithPassword
  ) => {
    run(login(values), {
      onSuccess: (user: any) => {
        if (user.login_phone_prompt) {
          setUser(user);
          setStep('setupPhone');
        } else {
          finishLogin(user);
        }
      },
      onError: (error: any) => {
        setLoginError(error, formik, isFormWithPassword);
        formik.setSubmitting(false);
      }
    });
  };

  // This value is set by myCoach Connect mobile app when embedding this
  // page inside myCoach Connect React Native application.
  const isReactNative = (window as any).isReactNative;

  if (isLoading) {
    return null;
  }

  return (
    <PageContainer>
      <ContainerWithLogo>
        {step === 'login' && (
          <LoginManager
            onLogin={handleLogin}
            renderTop={loginStep =>
              loginStep === 'login' ? (
                <div>
                  {isReactNative ? null : (
                    <ProductWrapper>
                      <ProductLabel>
                        {i18n.ft(messages.productKey.label)}
                      </ProductLabel>
                      <Button
                        as={Link}
                        to={RouteHelpers.getPath('users-activate-pk')}
                        size="mini"
                        color="blue"
                        compact
                      >
                        {i18n.ft(messages.productKey.button)}
                      </Button>
                    </ProductWrapper>
                  )}

                  <Header>{i18n.ft(messages.login)}</Header>
                </div>
              ) : null
            }
            renderBottom={loginStep =>
              loginStep === 'login' && !isReactNative ? (
                <div>
                  <PasswordHeader>
                    {i18n.ft(messages.passwordHeader)}
                  </PasswordHeader>
                  <PasswordBody>
                    <span>{i18n.ft(messages.password01) + ' '}</span>
                    <Link to={RouteHelpers.getPath('users-resend-email')}>
                      {i18n.ft(messages.password02)}
                    </Link>
                    <span>{' ' + i18n.ft(messages.password03)}</span>
                  </PasswordBody>
                </div>
              ) : null
            }
          />
        )}

        {step === 'setupPhone' && <PhonePrompt onSuccess={handleSetPhone} />}
      </ContainerWithLogo>
    </PageContainer>
  );
}

export default Login;
