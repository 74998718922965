import * as R from 'ramda';
import { UserCertification } from 'types/api/UserCertification';
import { SubscriptionSeat } from 'types/api/SubscriptionSeat';

export function isUserCertifiedObserver(
  userCertifications: UserCertification[]
) {
  return R.any(user_cert => {
    return (
      user_cert.certification.group_name === 'Observer' &&
      !user_cert.expired &&
      !user_cert.deprecated
    );
  }, userCertifications);
}

export function hasDataEntryRole(seats: SubscriptionSeat[], accountId: number) {
  return seats.find(
    seat =>
      seat.subscription.owner_id === accountId &&
      seat.subscription.owner_type === 'Node' &&
      seat.role?.name === 'data_entry' &&
      seat.role.product?.name === 'data_collection'
  );
}

export function hasDcAdminRole(seats: SubscriptionSeat[], accountId: number) {
  return seats.find(
    seat =>
      seat.subscription.owner_id === accountId &&
      seat.subscription.owner_type === 'Node' &&
      seat.role?.name === 'admin' &&
      seat.role.product?.name === 'data_collection'
  );
}
