const PATH = 'fe.components.reports.observation.averages_by_node_table';

const messages = {
  average: {
    key: `${PATH}.average`,
    defaultValue: 'Average'
  },
  archived: {
    key: `${PATH}.archived`,
    defaultValue: 'Archived'
  }
};

export default messages;
