import { createGlobalStyle } from 'styled-components';

const PrintGlobalStyle = createGlobalStyle`
@media print {
  body {
    -webkit-print-color-adjust: exact;
    margin-left: 80px;
    margin-right: 80px;
    background-color: white!important;
  }

  header, footer {
    display: none;
  }

  .PrintBottomSection,
  .PrintMessageButton,
  .PrintTopSection,
  .embeddedServiceHelpButton {
    display: none!important;
  }

  .PrintLeftTitle {
    justify-content: flex-start;
  }

  .VideoScore {
    top: -95px!important;
  }

  section {
    margin-bottom: 0px!important;
  }

  .PrintPageBreak {
    padding-top: 40px;
    page-break-before: always;
  }

  .PrintPageBreakAfter {
    page-break-after: always;
  }

  .PrintMarginTop {
    margin-top: 50px;
  }

  .PrintMarginY {
    margin-top: 5px!important;
    margin-bottom: 5px!important;
  }

  .PrintMargin {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .PrintPaddingTop {
    padding-top: 0px;
  }

  .PrintPaddingBottom {
    padding-bottom: 0px;
  }

  .PrintMarginLeft {
    margin-left: 15px!important;
  }

  .PrintBackgroundColor {
    background-color: white;
  }

  .PrintDimensionGrid {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
  }

  @page { size: auto;  margin: 0mm; background-color: white;}
}
`;

export default PrintGlobalStyle;
