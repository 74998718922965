const PATH = 'fe.components.learning_resources.dimension_tag_label';

const messages = {
  dimensions: {
    key: `${PATH}.dimensions`,
    defaultValue: 'Dimensions:'
  }
};

export default messages;
