import { ContentType } from 'recharts/types/component/Label';

import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';

type CustomTickProps = {
  x: number;
  y: number;
  payload: {
    value: number;
  };
};

const CustomTick = ({ x, y, payload }: CustomTickProps) => {
  const width = 26;
  const height = 24;
  const posX = x - width;
  const posY = y - height / 2;
  const boxColor = '#f4f8fa';
  const textPosX = '50%';
  const textPosY = 13;
  const textColor = '#364a53';

  return (
    <svg
      x={posX}
      y={posY}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g>
        <rect width={width} height={height} rx="4" fill={boxColor} />
        <text
          x={textPosX}
          y={textPosY}
          fill={textColor}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {payload.value}
        </text>
      </g>
    </svg>
  );
};

type CustomLabelsProps = {
  x: number;
  width: number;
  value: number;
  name: string;
};

const CustomLabels = ({ x, width, value, name }: CustomLabelsProps) => {
  const score = Number(value);
  const textColor = '#364a5e';

  // Average Score
  const maxBarHeight = 315;
  const scorePosY = 390 - (score / 7) * maxBarHeight;
  const scorePosX = x + width / 2;

  // Domain Name Label
  const domTextPosX = x + width / 2;
  const domTextPosY = 384;

  return (
    <>
      {score > 0 && (
        <text
          x={scorePosX}
          y={scorePosY}
          fill={textColor}
          textAnchor="middle"
          fontWeight="700"
          fontSize="20"
        >
          {score.toFixed(2)}
        </text>
      )}

      <g>
        <text
          x={domTextPosX}
          y={domTextPosY}
          fill={textColor}
          textAnchor="middle"
          fontWeight="600"
        >
          {name}
        </text>
      </g>
    </>
  );
};

type GraphData = {
  /** Domain name. */
  name: string;

  /** Domain score average. */
  value: number;

  /** Optional domain color. */
  fill?: string;
};

interface DomainChartProps {
  data: GraphData[];
}

function DomainChart({ data }: DomainChartProps) {
  const height = 400;
  const xAxisColor = '#f4f4f4';
  const bgGridColor = '#e4e4e4';

  return (
    <ResponsiveContainer height={height}>
      <BarChart data={data}>
        <CartesianGrid
          strokeDasharray="8"
          stroke={bgGridColor}
          vertical={false}
        />
        <XAxis
          dataKey="name"
          tick={false}
          tickLine={false}
          stroke={xAxisColor}
        />
        <YAxis
          ticks={[0, 1, 2, 3, 4, 5, 6, 7]}
          tick={CustomTick}
          axisLine={false}
          tickLine={false}
        />
        <Bar dataKey="value" isAnimationActive={false}>
          <LabelList dataKey="value" content={CustomLabels as ContentType} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default DomainChart;
