import styled from 'styled-components';

export const Video = styled.div<{ color?: string }>`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-left: 2px;
  margin-right: 2px;
  border-color: ${p => p.color};
  border-width: 2px;
  align-self: center;

  @media screen and (min-width: 640px) {
    width: 44px;
    height: 44px;
    display: flex;
    font-size: 1.25rem;
    line-height: 1.25rem;
    border-width: 6px;
    justify-self: center;
    align-items: center;
    justify-content: center;
  }
`;

export const FormatIcon = styled.div<{ color?: string }>`
  font-size: 24px;
  color: ${p => p.color};
  text-align: -webkit-center;
  align-self: center;
  border: 10px;
  border-color: black;
`;

export const DimensionScore = styled.div`
  margin-left: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  align-self: center;
  max-width: 50px;

  @media screen and (min-width: 768px) {
    display: flex;
    justify-self: center;
    margin-left: -10px;
    max-width: 64px;
  }
`;

export const Score = styled.div`
  margin-left: -1rem;

  @media print, screen and (min-width: 640px) {
    display: contents;
    margin-left: -0.5rem;
  }
`;
