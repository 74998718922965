import useAsync from 'hooks/useAsync';
import { useEffect, useState } from 'react';
import { getAccountById } from 'actions/reports';
import { AccountHierarchy } from 'types/api/AccountHierarchy';
import { getObservationsCount } from 'actions/envScales/reports';
import { class2EditionAgeLevels, classAgeLevels } from 'constants/ageLevels';
import { UserReportConfiguration } from 'types/api/envScales/UserReportConfiguration';

type NodeMap = {
  [id: number]: string;
};

function mapAccountHierarchy(
  hierarchy: AccountHierarchy[],
  parent?: AccountHierarchy
) {
  let nodesMap: NodeMap = {};

  hierarchy.forEach(node => {
    if (node.type !== 'Classroom') {
      nodesMap[node.id] = node.name;
    } else {
      nodesMap[node.id] = `${node.name} (${parent?.name})`;
    }

    const childMap = mapAccountHierarchy(node.children, node);
    nodesMap = { ...nodesMap, ...childMap };
  });

  return nodesMap;
}

export function useHierarchyNames(accountId: number) {
  const [nodes, setNodes] = useState<NodeMap>({});

  useEffect(() => {
    async function fetchAccount() {
      const { data: account } = await getAccountById(accountId);
      const nodesMap = mapAccountHierarchy(account.hierarchy);
      setNodes(nodesMap);
    }

    fetchAccount();
  }, [accountId]);

  return nodes;
}

export function useAgeLevelName(abbreviation: string) {
  const allAgeLevels = [...classAgeLevels, ...class2EditionAgeLevels];
  const ageLevel = allAgeLevels.find(age => age.value === abbreviation);

  return String(ageLevel?.text ?? '');
}

type ConfigParams = Pick<
  UserReportConfiguration,
  'start_date' | 'end_date' | 'hierarchy_selections' | 'age_level'
>;

export function useConfigTotal(config: ConfigParams | null) {
  const { run, data } = useAsync();

  useEffect(() => {
    if (!config) {
      return;
    }

    const params = {
      start_date: config.start_date,
      end_date: config.end_date,
      node_ids: config.hierarchy_selections,
      age_level: config.age_level
    };

    run(getObservationsCount(params));
  }, [run, config]);

  return data?.total_observations ?? 0;
}
