import { AnswersBox, NumberLabelBox } from './Styled';

function LabelsBox() {
  return (
    <AnswersBox>
      <NumberLabelBox>1</NumberLabelBox>
      <NumberLabelBox>2</NumberLabelBox>
      <NumberLabelBox>3</NumberLabelBox>
    </AnswersBox>
  );
}

export default LabelsBox;
