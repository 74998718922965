import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { useFormikContext } from 'formik';
import { getHierarchy } from 'actions/accounts';
import { useCallback, useEffect, useMemo } from 'react';
import HierarchyDropdown from 'components/Reports/Observation/HierarchyDropdown';

type FormValues = {
  account_id?: number;
  node_id?: number;
};

type Node = {
  id: number;
  name: string;
  type: string;
  children: Node[];
};

type HierarchyData = {
  label: string;
  value: number;
  children: HierarchyData[];
  checked: boolean;
};

function buildHierarchyData(nodes: Node[], nodeId?: number): HierarchyData[] {
  const nonClassroomNodes: HierarchyData[] = [];

  nodes.forEach(node => {
    if (node.type === 'Classroom') {
      return;
    }

    nonClassroomNodes.push({
      checked: nodeId === node.id,
      label: node.name,
      value: node.id,
      children: buildHierarchyData(node.children, nodeId)
    });
  });

  return nonClassroomNodes;
}

export function CenterSelect() {
  const formik = useFormikContext<FormValues>();
  const accountId = formik.values.account_id || 0;
  const nodeId = formik.values.node_id || 0;
  const { run, data } = useAsync({ data: {} });

  useEffect(() => {
    if (!accountId) {
      return;
    }

    run(getHierarchy(accountId));
  }, [run, accountId]);

  const hierarchy = useMemo(
    () => buildHierarchyData(data.hierarchy?.children || [], nodeId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleChange = useCallback((_: any, tags: any) => {
    const nodes = tags.map((node: HierarchyData) => node.value);
    formik.setFieldValue('node_id', nodes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!accountId) {
    return null;
  }

  return (
    <div>
      <label className="font-bold mb-1 inline-block">
        {i18n.ft(messages.center)}
      </label>
      <HierarchyDropdown
        mode="radioSelect"
        data={hierarchy}
        onChange={handleChange}
      />
    </div>
  );
}
