const PATH = 'fe.components.reliability_test.pass_message';

const messages = {
  message: {
    key: `${PATH}.message`,
    defaultValue: `<span style="color: #7AA03F">You passed</span>`
  }
};

export default messages;
