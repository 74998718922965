import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import RoutesHelper from 'helpers/routes';
import { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import MainContent from 'components/MainContent/MainContent';
import { useNavigate, Link } from 'react-router-dom';
import { CollapsibleConfigurationForm } from 'components/Reports/Observation/CollapsibleConfigurationForm';
import { OptionalDate } from 'components/Reports/Observation/DateRangePicker/DateRangePicker';
import { useAgeLevelOption, useCurrentAccountByArea } from '../Observation';
import PageLoader from 'components/PageLoader';

export type FormValues = {
  ageLevel: string | null;
  startDate: OptionalDate;
  endDate: OptionalDate;
  nodeIds: number[];
  classVersion?: number;
};

export type ReportConfig = {
  age_level: string | null;
  start_date: OptionalDate;
  end_date: OptionalDate;
  node_ids: number[];
  comparison_configuration?: ReportConfig;
};

interface ReportConfigurationFormProps {
  title: string;
  subtitle?: string;
  initialValues: FormValues[];
  onSubmit: (configuration: ReportConfig) => void;
}

function ReportConfigurationForm({
  title,
  subtitle,
  initialValues,
  onSubmit
}: ReportConfigurationFormProps) {
  const navigate = useNavigate();
  const [totals, setTotals] = useState<number[]>([0]);
  const [configurations, setConfigurations] =
    useState<FormValues[]>(initialValues);

  const currentAccount = useCurrentAccountByArea('ObservationReports');
  const { ageLevelOptions, accountTemplates } = useAgeLevelOption(
    currentAccount?.guid
  );

  const classTemplateOptions = accountTemplates
    .filter(t => !t.env_scales)
    .map(t => ({
      key: t.id,
      text: t.name,
      value: t.class_version
    }));

  function allTotalsGreaterThanZero() {
    return R.all(t => t > 0, totals);
  }

  function allConfigurationsAreValid() {
    return R.all(
      config =>
        Boolean(
          config.startDate &&
            config.endDate &&
            config.ageLevel &&
            config.nodeIds.length > 0
        ),
      configurations
    );
  }

  const isSaveActive =
    allConfigurationsAreValid() && allTotalsGreaterThanZero();

  function mapFormConfigToAPIKeys(values: FormValues): ReportConfig {
    return {
      start_date: values.startDate,
      end_date: values.endDate,
      age_level: values.ageLevel,
      node_ids: values.nodeIds
    };
  }

  function handleSubmit() {
    const config1 = mapFormConfigToAPIKeys(configurations[0]);

    if (configurations.length === 2) {
      const config2 = mapFormConfigToAPIKeys(configurations[1]);
      onSubmit({ ...config1, comparison_configuration: config2 });
    } else {
      onSubmit(config1);
    }
  }

  function handleConfigChange(idx: number, values: Partial<FormValues>) {
    setConfigurations(configurations => {
      const config = configurations[idx];
      const configs = R.update(idx, { ...config, ...values }, configurations);
      return configs.map(c => ({ ...c, ageLevel: configs[0].ageLevel }));
    });
  }

  function handleTotalChange(idx: number, total: number) {
    setTotals(R.update(idx, total, totals));
  }

  function handleAddComparison() {
    const comparisonConfig: FormValues = {
      startDate: null,
      endDate: null,
      ageLevel: configurations[0].ageLevel,
      nodeIds: []
    };

    setConfigurations(R.append(comparisonConfig, configurations));
    setTotals(R.append(0, totals));
  }

  function handleRemoveComparison(idx: number) {
    setConfigurations(R.remove(idx, 1, configurations));
    setTotals(R.remove(idx, 1, totals));
  }

  const isLoading =
    R.isEmpty(ageLevelOptions) || R.isEmpty(classTemplateOptions);

  return (
    <MainContent maxWidth={1280} className="!mt-10 text-black">
      <div className="mb-8">
        <Link
          className="text-black"
          to={RoutesHelper.getPath('reports-observation')}
        >
          <Icon name="chevron left" size="large" />
          <span className="underline font-bold">
            {i18n.ft(messages.backToReport)}
          </span>
        </Link>
      </div>

      <h1 className="mt-4">{title}</h1>
      <p className="mt-2">{subtitle}</p>

      {isLoading ? (
        <PageLoader />
      ) : (
        configurations.map((config, idx) => {
          const ageLevelMustBeTheSame = {
            ...config,
            ageLevel: configurations[0].ageLevel,
            classVersion: configurations[0].classVersion
          };

          return (
            <CollapsibleConfigurationForm
              key={idx}
              title={i18n.ft(messages.configNumber, { count: idx + 1 })}
              values={ageLevelMustBeTheSame}
              onRemove={() => handleRemoveComparison(idx)}
              onChange={values => handleConfigChange(idx, values)}
              onTotalChange={total => handleTotalChange(idx, total)}
              disableAgeLevel={idx > 0}
              ageLevelOptions={ageLevelOptions}
              classTemplateOptions={classTemplateOptions}
              currentAccountId={currentAccount?.id ?? 0}
              collapseEnabled={configurations.length > 1}
            />
          );
        })
      )}

      <div className="mt-10 mb-20 shadow-xl rounded-[10px]">
        <div className="h-2 bg-[#0A9CCA] rounded-t-[10px]" />
        <div className="py-6 px-7 bg-white rounded-b-[10px] border-x border-b border-[#d2d4d4] flex justify-between">
          <Button inverted color="blue" onClick={() => navigate(-1)}>
            {i18n.ft(messages.back)}
          </Button>

          <div>
            <Button
              inverted
              color="blue"
              disabled={configurations.length > 1}
              onClick={handleAddComparison}
            >
              <Icon name="plus" />
              <span>{i18n.ft(messages.addComparison)}</span>
            </Button>

            <Button
              color="blue"
              disabled={!isSaveActive}
              onClick={handleSubmit}
              className="!ml-6"
            >
              {i18n.ft(messages.generateReport)}
              <Icon name="arrow right" />
            </Button>
          </div>
        </div>
      </div>
    </MainContent>
  );
}

export default ReportConfigurationForm;
