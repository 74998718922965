import { ReactNode } from 'react';
import messages from './messages';
import i18n from 'helpers/i18n';

function Notes({ children }: { children: ReactNode }) {
  return (
    <div className="text-center px-5 py-3">
      <h3>{i18n.ft(messages.notes)}</h3>
      <div className="text-justify mt-3">{children}</div>
    </div>
  );
}

export default Notes;
