import styled, { createGlobalStyle } from 'styled-components';
import CollapsibleCard from 'components/CollapsibleCard/index';
import LevelSelection from 'pages/Improvements/EducatorProfile/Focus/LevelSelection';

export const CustomPrintStyle = createGlobalStyle`
  @media print {
    @page {
      margin-top: 10mm!important;
      margin-bottom: 5mm!important;
    }

    button[type='submit'] {
      display: none !important;
    }

    .field {
      break-inside: avoid;
    }
  }
`;

export const EducatorDetails = styled.div`
  background: white;

  h2 {
    color: var(--teachstone-primary-charcoal-100, #3c3f42);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.00313rem;
  }
  h3 {
    color: var(--teachstone-primary-charcoal-80, #585b5d);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  p {
    color: var(--teachstone-primary-charcoal-100, #3c3f42);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
  }
  header > span > p {
    color: var(--teachstone-primary-charcoal-60, #808183);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }

  @media print {
    border: 1px solid #e5e7eb;
  }
`;

export const InformationCard = styled.div`
  background: white;
  border-radius: 0.5rem;

  header > h2 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #808183;
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  h4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media print {
    border: 1px solid #e5e7eb;
  }
`;

export const CollapsibleStrategyCard = styled(CollapsibleCard)`
  button {
    padding: 0.5rem 0rem 0rem 0rem;
  }

  div {
    padding: 0.5rem 0rem 0rem 0rem;
  }

  button > span {
    background-color: transparent;
    padding: 0;
  }

  span > i {
    color: black;
  }
`;

export const EvaluationSelection = styled(LevelSelection)`
  display: flex;

  flex-direction: row;

  div {
    white-space: nowrap;
    width: 33%;
  }
`;
