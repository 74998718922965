import { AgeLevel } from 'types/api/envScales/AgeLevel';
import { Assessment } from 'types/api/envScales/Assessment';

function getDimensionScores(dimensionId: number, assessment: Assessment) {
  const cycles = assessment.observation!.observation_cycles;

  return cycles.map(cycle => {
    const score = cycle.dimension_scores!.find(
      score => score.dimension_id === dimensionId
    );

    return score?.dimension_score ?? 0;
  });
}

export function getScoresByDimension(
  ageLevel: AgeLevel,
  assessment: Assessment
) {
  return ageLevel.domains.flatMap(domain => {
    return domain.dimensions.map(dimension => {
      const dimensionScores = getDimensionScores(dimension.id, assessment);

      return {
        name: dimension.name,
        t_name: dimension.t_name,
        abbreviation: dimension.abbreviation,
        domain_abbreviation: domain.abbreviation,
        scores: dimensionScores
      };
    });
  });
}
