const PATH = 'fe.pages.reports.test';

const messages = {
  knowledge: {
    header: {
      key: `${PATH}.header`,
      defaultValue: 'Training Knowledge Report'
    }
  },
  reliability: {
    header: {
      key: `${PATH}.header`,
      defaultValue: 'Training Reliability Report'
    }
  }
};

export default messages;
