import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 48px 16px;
  text-align: center;

  @media screen and (min-width: 768px) {
    padding-right: 32px;
    padding-left: 32px;
  }
`;

export const ServiceWrapper = styled.div`
  margin-bottom: 32px;
`;

export const Service = styled.img`
  width: 128px;
  display: block;
  margin: 0 auto;
`;

export const Header = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${p => p.theme.colors['neutral']['900']};
`;

export const SubHeader = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: ${p => p.theme.colors['neutral']['500']};
  margin-top: 16px;
`;

export const Description = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: ${p => p.theme.colors['neutral']['700']};
  margin-top: 4px;
`;

export const Divider = styled.hr`
  border: 0;
  border-bottom: 1px solid ${p => p.theme.colors['neutral']['300']};
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 384px;
`;

export const DetailsWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 28px;
  color: ${p => p.theme.colors['neutral']['700']};
`;

export const DetailsHeader = styled.div`
  margin-bottom: 16px;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  margin-right: 16px;
  color: ${p => p.theme.colors['neutral']['400']};
`;

export const Item = styled.div`
  padding: 8px 24px;
  align-self: flex-start;
  display: flex;
  align-items: baseline;
`;

export const ItemLabel = styled.span`
  text-align: left;
`;

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  margin-top: 32px;
  max-width: 300px;
`;
