import useAsync from 'hooks/useAsync';
import RouteHelpers from 'helpers/routes';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import { KnowledgeTestAttempt } from 'types/api/TestAttempt';
import { useCallback, useEffect, useState } from 'react';
import { getKnowledgeTestById } from 'actions/knowledgeTests';
import KnowledgeTestQuestions from './KnowledgeTestQuestions';
import Instructions from 'components/TestAttempts/Instructions';
import HonorCodeAgreement from 'components/TestAttempts/HonorCodeAgreement';
import { startAttempt } from 'actions/testAttempts';
import { KnowledgeTestProvider } from './KnowledgeTestContext';

interface KnowledgeTestProps {
  id: number;
}

function KnowledgeTest({ id }: KnowledgeTestProps) {
  const { data, run, isPending } = useAsync<KnowledgeTestAttempt>();
  const [preTestState, setPreTestState] = useState(
    () => window.localStorage.getItem(`pre_test_state_${id}`) || 'instructions'
  );

  const navigate = useNavigate();

  useEffect(() => {
    run(getKnowledgeTestById(Number(id)));
  }, [run, id]);

  const handlePreTestState = useCallback(
    (state: string) => {
      window.localStorage.setItem(`pre_test_state_${id}`, state);
      setPreTestState(state);
    },
    [setPreTestState, id]
  );

  if (isPending) {
    return <PageLoader />;
  }

  if (data.completed_at) {
    navigate(
      RouteHelpers.getPath('test-attempts-score-report', {
        id: id
      })
    );
    return null;
  }

  if (data.user_test.terms_accepted_at || preTestState === 'knowledge_test') {
    if (!data.started_at) {
      run(startAttempt(Number(id)));
    }
    return (
      <KnowledgeTestProvider id={id}>
        <KnowledgeTestQuestions {...data} />
      </KnowledgeTestProvider>
    );
  }

  if (preTestState === 'instructions' || preTestState === 'resources') {
    return (
      <Instructions
        title={data.title}
        handlePreTestState={handlePreTestState}
        testType="knowledge"
      />
    );
  }

  return (
    <HonorCodeAgreement
      title={data.title}
      userTestId={data.user_test.id.toString()}
      testAttemptId={id}
      handlePreTestState={handlePreTestState}
      testType="knowledge"
    />
  );
}

export default KnowledgeTest;
