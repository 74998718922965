import styled from 'styled-components';
import teachstoneBackground from 'images/teachstone-bg.jpg';

export const Background = styled.div`
  background-color: #f3f3f3;
  background-image: url(${teachstoneBackground});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageContainer = styled.div`
  width: 100%;
  padding: 16px;
`;
