import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${p => p.theme.colors.white};
  box-shadow: -1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  padding: 8px 5px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 0.5fr 0.3fr;
  grid-gap: 8px;
  width: 100%;
`;

export const SmallContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-gap: 8px;
  width: 100%;
`;

export const Item = styled.div`
  padding: 8px;
  font-size: 14px;

  @media screen and (min-width: 640px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
`;
export const FirstItem = styled.div`
  display: flex;
  padding: 5px;
  font-size: 14px;

  @media screen and (min-width: 640px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ActionIcon = styled.button`
  flex: 0 0 30px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
`;
