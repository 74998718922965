const PATH = 'fe.components.trainings.view.countdown';

const messages = {
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed'
  },
  daysToGo: {
    key: `${PATH}.days_to_go`,
    defaultValue: {
      one: 'Day to go',
      other: 'Days to go'
    }
  }
};

export default messages;
