const PATH = 'fe.pages.account.password';

const messages = {
  changePassword: {
    key: `${PATH}.change_password`,
    defaultValue: 'Change Password'
  },
  save: {
    key: `${PATH}.save`,
    defaultValue: 'Save Password'
  },
  success: {
    key: `${PATH}.success`,
    defaultValue: 'Your password was updated successfully.'
  },
  fields: {
    password: {
      key: `${PATH}.fields.password`,
      defaultValue: 'New Password'
    },
    passwordConfirmation: {
      key: `${PATH}.fields.password_confirmation`,
      defaultValue: 'Re-enter Password'
    }
  },
  errors: {
    password: {
      required: {
        key: `${PATH}.errors.password.required`,
        defaultValue: 'Password is required'
      },
      min: {
        key: `${PATH}.errors.password.min`,
        defaultValue: 'Enter at least 6 characters'
      }
    },
    passwordConfirmation: {
      required: {
        key: `${PATH}.errors.password_confirmation.required`,
        defaultValue: 'Password confirmation is required'
      },
      mustMatch: {
        key: `${PATH}.errors.password_confirmation.must_match`,
        defaultValue: 'Passwords must match'
      }
    }
  }
};

export default messages;
