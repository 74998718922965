const PATH = 'fe.pages.set_password';

const messages = {
  setYourPassword: {
    key: `${PATH}.set_your_password`,
    defaultValue: 'Set your password'
  },
  enterPassword: {
    key: `${PATH}.enter_password`,
    defaultValue: 'Please enter your password twice.'
  },
  mustContain: {
    key: `${PATH}.must_contain`,
    defaultValue: 'Your password must contain at least 6 characters.'
  },
  password: {
    key: `${PATH}.password`,
    defaultValue: 'Password'
  },
  confirmPassword: {
    key: `${PATH}.confirm_password`,
    defaultValue: 'Confirm Password'
  },
  passwordSet: {
    key: `${PATH}.password_set`,
    defaultValue: 'Your password was set!'
  },
  reEnterPassword: {
    key: `${PATH}.re_enter_password`,
    defaultValue: 'Re-enter password'
  },
  setMyPassword: {
    key: `${PATH}.set_my_password`,
    defaultValue: 'Set my password'
  },
  errors: {
    password: {
      required: {
        key: `${PATH}.errors.password.required`,
        defaultValue: 'Password is required'
      },
      minChars: {
        key: `${PATH}.errors.password.min_chars`,
        defaultValue: 'Password must contain at least 6 characters'
      }
    },
    confirmPassword: {
      required: {
        key: `${PATH}.errors.confirm_password.required`,
        defaultValue: 'Password Confirmation is required'
      },
      mustMatch: {
        key: `${PATH}.errors.confirm_password.must_match`,
        defaultValue: 'Passwords must match'
      }
    },
    checkbox: {
      required: {
        key: `${PATH}.errors.checkbox.required`,
        defaultValue:
          'To continue you must agree to the Terms of Service and Privacy Policy.'
      }
    }
  },
  termsOfService: {
    privacy01: {
      key: `${PATH}.terms_service.privacy_01`,
      defaultValue: 'I agree to the Teachstone'
    },
    privacy02: {
      key: `${PATH}.terms_service.privacy_02`,
      defaultValue: 'Terms of Service'
    },
    privacy03: {
      key: `${PATH}.terms_service.privacy_03`,
      defaultValue: 'and'
    },
    privacy04: {
      key: `${PATH}.terms_service.privacy_04`,
      defaultValue: 'Privacy Policy'
    }
  }
};

export default messages;
