const PATH = 'fe.components.measure.home.filters';

const messages = {
  classEnviroment: {
    key: `${PATH}.class_enviroment`,
    defaultValue: 'CLASS Environment'
  },
  ageLevel: {
    key: `${PATH}.age_level`,
    defaultValue: 'Age Level'
  },
  clear: {
    key: `${PATH}.clear`,
    defaultValue: 'Clear Search'
  },
  hierarchy: {
    key: `${PATH}.hierarchy`,
    defaultValue: 'Hierarchy'
  },
  observers: {
    key: `${PATH}.observers`,
    defaultValue: 'Observers'
  }
};

export default messages;
