import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  text-align: center;

  @media screen and (max-width: 550px) {
    display: none;
  }
`;

export const MobileHeader = styled.div`
  display: none;
  margin-bottom: 16px;

  @media screen and (max-width: 550px) {
    display: block;
  }
`;

export const Actions = styled.div`
  margin-top: 8px;

  @media screen and (max-width: 550px) {
    display: none;
  }
`;

export const MobileActions = styled.div`
  margin-top: 16px;
  display: none;

  @media screen and (max-width: 550px) {
    display: block;
    width: 100%;
  }
`;
