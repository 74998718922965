import { AgeLevelTag } from 'components/AgeLevel';
import { AgeLevel } from 'types/api/AgeLevel';
import messages from './messages';
import i18n from 'helpers/i18n';

interface AuthorizedAgeLevelsProps {
  ageLevels?: AgeLevel[];
}

function AuthorizedAgeLevels({ ageLevels }: AuthorizedAgeLevelsProps) {
  if (!ageLevels || ageLevels.length < 1) return <></>;
  const ageLevelTags = ageLevels.map(({ id, abbreviation, name }) => (
    <AgeLevelTag key={id} abbreviation={abbreviation}>
      {name}
    </AgeLevelTag>
  ));
  return (
    <div>
      <div className="mb-1">{i18n.ft(messages.observableAgeLevels)}</div>
      <div>{ageLevelTags}</div>
    </div>
  );
}

export default AuthorizedAgeLevels;
