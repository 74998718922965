import styled from 'styled-components';

export const Container = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin: 8px 0 4px;
  text-transform: uppercase;
`;

export const Status = styled.span`
  color: grey;
  margin-right: 20px;
`;
