import * as R from 'ramda';
import { FormValues } from './utils';
import { useFormikContext } from 'formik';
import { DropdownItemProps } from 'semantic-ui-react';
import { ReactNode, useEffect, useState } from 'react';
import { getCenters, getClassrooms } from 'actions/nodes';
import DropdownItem from 'components/EnhancedForm/SelectWithTag/DropdownItem';

type Node = {
  id: number;
  guid: string;
  name: string;
  external_id: string;
};

type CenterNode = Node & {
  ancestors: Node[];
};

type CenterTags = {
  name: string;
  hierarchy: string;
};

type RenderProps = {
  centers: DropdownItemProps[];
  classrooms: DropdownItemProps[];
  centerTags: CenterTags;
  classroomTag: string;
};

interface CentersAndClassroomsGetterProps {
  accountId: number;
  children: (props: RenderProps) => ReactNode;
  displayExternalIds?: boolean;
}

function formatCenterHierarchy(center: CenterNode) {
  const centers = R.dropLast(1, center.ancestors);

  return centers
    .map(c => c.name)
    .reverse()
    .join(' • ');
}

function formatCenters(centers: CenterNode[], displayExternalIds = false) {
  return centers.map(center => {
    const hierarchy = formatCenterHierarchy(center);

    return {
      key: center.id,
      value: center.guid,
      content: (
        <DropdownItem
          title={center.name}
          titleId={displayExternalIds ? center.external_id : undefined}
          subtitle={hierarchy}
        />
      )
    };
  });
}

function formatClassrooms(classrooms: Node[], displayExternalIds = false) {
  return classrooms.map(classroom => ({
    key: classroom.id,
    value: classroom.guid,
    content: (
      <DropdownItem
        title={classroom.name}
        titleId={displayExternalIds ? classroom.external_id : undefined}
      />
    )
  }));
}

export function CentersAndClassroomsGetter({
  accountId,
  children,
  displayExternalIds = false
}: CentersAndClassroomsGetterProps) {
  const { values } = useFormikContext<FormValues>();
  const [centers, setCenters] = useState<CenterNode[]>([]);
  const [classrooms, setClassrooms] = useState<Node[]>([]);

  const center = centers.find(n => n.guid === values.center_guid);
  const centerId = center?.id ?? 0;
  const classroom = classrooms.find(n => n.guid === values.classroom_guid);

  useEffect(() => {
    getCenters(accountId).then(response => setCenters(response.data));
  }, [accountId]);

  useEffect(() => {
    if (!centerId) {
      setClassrooms([]);
      return;
    }

    getClassrooms(accountId, centerId).then(response =>
      setClassrooms(response.data)
    );
  }, [accountId, centerId]);

  function getCenterTags() {
    let tags: CenterTags = { name: '', hierarchy: '' };

    if (center) {
      const name =
        displayExternalIds && center.external_id
          ? `${center.name} ${center.external_id}`
          : center.name;

      tags.name = name;
      tags.hierarchy = formatCenterHierarchy(center);
    }

    return tags;
  }

  function getClassroomTag() {
    if (classroom) {
      return displayExternalIds && classroom.external_id
        ? `${classroom.name} ${classroom.external_id}`
        : classroom.name;
    } else {
      return '';
    }
  }

  return (
    <>
      {children({
        centers: formatCenters(centers, displayExternalIds),
        classrooms: formatClassrooms(classrooms, displayExternalIds),
        centerTags: getCenterTags(),
        classroomTag: getClassroomTag()
      })}
    </>
  );
}
