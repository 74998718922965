import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { respondTrainingInvitation } from 'actions/trainings';
import { Modal, ModalProps, Button } from 'semantic-ui-react';
import { TrainingInvitation } from 'types/api/TrainingParticipant';

interface DeclineModalProps extends ModalProps {
  /** The training ID. */
  trainingId: number;

  /** Callback fired when the modal request to be closed. */
  onClose: () => void;

  /** Callback fired when there's an error declining. */
  onError: () => void;

  /** Callback fired after the training has been declined. */
  afterDecline: (data: TrainingInvitation) => void;
}

const DeclineModal: React.FC<DeclineModalProps> = ({
  trainingId,
  onError,
  afterDecline,
  ...props
}) => {
  const { run, isLoading } = useAsync();

  function handleDecline() {
    run(respondTrainingInvitation(trainingId, 'decline'), {
      onSuccess: (invitation: any) => afterDecline(invitation),
      onError: () => onError()
    });
  }

  return (
    <Modal size="small" {...props}>
      <Modal.Content>{i18n.ft(messages.modal.title)}</Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleDecline}
          content={i18n.ft(messages.modal.confirm)}
        />
        <Button
          disabled={isLoading}
          onClick={props.onClose}
          content={i18n.ft(messages.modal.cancel)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DeclineModal;
