interface ServiceTileProps {
  serviceKey: string;
  name: string;
  icon: string;
  url: string;
}

function ServiceTile({ serviceKey, name, icon, url }: ServiceTileProps) {
  return (
    <div className="service-tile">
      <a id={`${serviceKey}Tile`} className="tile-url" href={url}>
        <div className="tile">
          <div className="tile-image">
            <img alt={serviceKey} src={icon} className="tile-icon" />
          </div>
          <div className="tile-name">{name}</div>
        </div>
      </a>
    </div>
  );
}

export default ServiceTile;
