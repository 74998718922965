import axios from './axiosConfig';
import { AccountAssessmentTemplate } from 'types/api/envScales/AccountAssessmentTemplate';

type GetAssessmentTemplatesRes = {
  account_assessment_templates: AccountAssessmentTemplate[];
};

export function getAccountAssessmentTemplates(accountGuid: string) {
  return axios.get<GetAssessmentTemplatesRes>(
    `/api/v1/account_assessment_templates`,
    {
      params: { account_guid: accountGuid }
    }
  );
}

type CreateParams = {
  account_guid: string;
  assessment_template_id: number;
};

export function createAccountAssessmentTemplate(params: CreateParams) {
  return axios.post(`/api/v1/account_assessment_templates`, {
    account_assessment_template: params
  });
}

export function deleteAccountAssessmentTemplate(id: number) {
  return axios.delete(`/api/v1/account_assessment_templates/${id}`);
}
