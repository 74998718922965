export function getBackgroundColor(domain: string) {
  switch (domain) {
    // Pre-K
    case 'es':
      return 'bg-[#FEF2E8]';
    case 'co':
      return 'bg-[#FFEAF1]';
    case 'is':
      return 'bg-[#FAEAFE]';
    // Infant
    case 'rc':
      return 'bg-[#FEF2E8]';
    // Toddler
    case 'ebs':
      return 'bg-[#FEF2E8]';
    case 'esl':
      return 'bg-[#FFEAF1]';
    default:
      return 'bg-[#FEF2E8]';
  }
}
