import moment from 'moment';
import useAsync from 'hooks/useAsync';
import { useEffect, useState, useCallback } from 'react';
import { useRouteParams } from 'hooks/useRouteParams';
import PageLoader from 'components/PageLoader';
import { TestAttempt as TestAttemptData } from 'types/api/TestAttempt';
import { getTestAttemptById } from 'actions/testAttempts';
import Resources from 'components/TestAttempts/Resources';
import Instructions from 'components/TestAttempts/Instructions';
import Reliability from 'components/ReliabilityTest/ReliabilityTest';
import HonorCodeAgreement from 'components/TestAttempts/HonorCodeAgreement';
import KnowledgeTest from 'components/KnowledgeTest/KnowledgeTest';
import ExpiredTest from 'components/ReliabilityTest/ExpiredTest';

type URLParams = {
  id: string;
};

function TestAttempt() {
  const { id } = useRouteParams<URLParams>();
  const { data, run, isPending } = useAsync<TestAttemptData>();
  const [preTestState, setPreTestState] = useState(
    () => window.localStorage.getItem(`pre_test_state_${id}`) || 'resources'
  );

  const handlePreTestState = useCallback(
    (state: string) => {
      window.localStorage.setItem(`pre_test_state_${id}`, state);
      setPreTestState(state);
    },
    [setPreTestState, id]
  );

  useEffect(() => {
    run(getTestAttemptById(Number(id)));
  }, [run, id]);

  useEffect(() => {
    if (preTestState === 'resources') {
      handlePreTestState('resources');
    }
  }, [preTestState, handlePreTestState]);

  function isKnowledgeTest() {
    return data.test_template.test_type === 'knowledge';
  }

  if (isPending) {
    return <PageLoader />;
  }

  if (moment().isAfter(data.expires_at)) {
    if (moment(data.expires_at).add(29, 'days').isAfter(moment())) {
      return <ExpiredTest {...data} renewable />;
    }
    return <ExpiredTest {...data} />;
  }

  if (isKnowledgeTest()) {
    return <KnowledgeTest id={parseInt(id)} />;
  }

  if (data.user_test.terms_accepted_at || preTestState === 'reliability_test') {
    return <Reliability id={id} />;
  }

  if (preTestState === 'resources') {
    return (
      <Resources
        title={data.title}
        testTemplateId={data.test_template.id as number}
        handlePreTestState={handlePreTestState}
        attemptId={id}
      />
    );
  }

  if (preTestState === 'instructions') {
    return (
      <Instructions
        title={data.title}
        handlePreTestState={handlePreTestState}
      />
    );
  }

  return (
    <HonorCodeAgreement
      title={data.title}
      userTestId={data.user_test.id.toString()}
      testAttemptId={parseInt(id)}
      handlePreTestState={handlePreTestState}
    />
  );
}

export default TestAttempt;
