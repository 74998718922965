import { useState, useEffect } from 'react';
import { getTraining } from 'actions/trainings';

export function useTraining(trainingId) {
  const [loading, setLoading] = useState(true);
  const [training, setTraining] = useState();

  function fetchTraining() {
    setLoading(true);
    getTraining(trainingId)
      .then(({ data: training }) => {
        setTraining(training);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(fetchTraining, [trainingId]);

  return [training, loading, fetchTraining];
}
