import axios from 'axios';

type Recommendations = {
  author_id?: number;
  recipient_id: any;
  resourceable_id: number;
  resourceable_type: string;
};

export function createRecommendations(recommendations: Recommendations[]) {
  return axios.post(`/api/v2/recommendations`, {
    recommendations: recommendations
  });
}

export function completedRecommended(educatorId: number) {
  return axios.get(
    `/api/v2/learning_resources/watched_recommendations?educator_id=${educatorId}`
  );
}
