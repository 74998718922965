import styled from 'styled-components';

export const Icon = styled.img`
  margin-right: 16px;
  display: inline-block;
  vertical-align: middle;
`;

export const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.blue};
`;

export const SubHeader = styled.div`
  font-size: 14px;
  color: black;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const CreditsContainer = styled.div`
  @media screen and (min-width: 640px) and (max-width: 767px),
    (min-width: 896px) {
    display: flex;
  }
`;

export const Credit = styled.div`
  text-align: center;

  &:nth-child(2) {
    margin-top: 24px;
  }

  @media screen and (min-width: 640px) and (max-width: 767px),
    (min-width: 896px) {
    display: flex;
    align-items: center;
    width: 50%;

    &:nth-child(2) {
      margin-top: 0;
      border-left: 1px solid #707070;
    }

    &:only-child {
      width: 100%;
    }
  }
`;

export const CreditCEU = styled.div`
  color: #34495e;
  line-height: 1;
  font-size: 60px;
  font-weight: bold;

  @media screen and (min-width: 640px) and (max-width: 767px),
    (min-width: 896px) {
    width: 40%;
    transform: translateY(4px);
    text-align: right;
    padding: 0 16px;
  }
`;

export const CreditDescription = styled.div`
  color: #333333;
  font-size: 16px;
  line-height: 1.5;

  @media screen and (min-width: 640px) and (max-width: 767px),
    (min-width: 896px) {
    width: 60%;
    text-align: left;
    padding: 0 16px;
  }
`;

export const DownloadLinkTop = styled.div`
  display: none;
  margin-left: auto;

  @media screen and (min-width: 640px) and (max-width: 767px),
    (min-width: 896px) {
    display: block;
  }
`;

export const DownloadLinkBottom = styled.div`
  margin-top: 24px;

  @media screen and (min-width: 640px) and (max-width: 767px),
    (min-width: 896px) {
    display: none;
  }
`;
