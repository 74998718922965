import { useState, useEffect } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import { Icon } from 'semantic-ui-react';
import { useMeasure } from 'context/measure';
import { getScoresByDimension } from './utils';
import VisuallyHidden from 'components/VisuallyHidden';
import { getAssessment } from 'actions/envScales/assessments';
import useAsync from 'hooks/useAsync';

import {
  WrapperButton,
  CloseButton,
  IconWrapper,
  Table,
  TableCell,
  HeaderCell,
  CollapsedCell,
  CycleLink,
  Abbreviation,
  AbbreviationIcon,
  InstructionsWrapper
} from './Styled';

type DomainColors = {
  [key: string]: string;
};

const colors: DomainColors = {
  es: '#FFF6F0',
  co: '#FFF3F7',
  is: '#FCF2FF',
  ebs: '#FFF6F0',
  esl: '#FCF2FF',
  rc: '#FFF6F0'
};

interface DimensionScore {
  name: string;
  t_name: string;
  abbreviation: string;
  domain_abbreviation: string;
  scores: number[];
}

const initialScores: DimensionScore[] = [];

function ScoresByCycleTable() {
  const { assessment, ageLevel } = useMeasure();
  const assessmentId = assessment!.id;

  const { run: asyncAssessmentRun } = useAsync();

  const [isOpen, setIsOpen] = useState(false);
  const cycles = assessment.observation!.observation_cycles;
  const [scores, setScores] = useState(initialScores);

  const isCompleted =
    assessment.status === 'completed' ||
    assessment.status === 'awaiting_approval';

  useEffect(() => {
    asyncAssessmentRun(getAssessment(assessmentId, { expanded: true }), {
      onSuccess: data => {
        const scores = getScoresByDimension(ageLevel, data);
        setScores(scores);
      }
    });
  }, [asyncAssessmentRun, assessmentId, ageLevel]);

  return (
    <WrapperButton
      isOpen={isOpen}
      as={isOpen ? 'div' : undefined}
      onClick={isOpen ? undefined : () => setIsOpen(true)}
    >
      {!isOpen && (
        <Flex align="center">
          <IconWrapper>
            <Icon fitted color="blue" name="plus circle" />
          </IconWrapper>
          <Box as="span" ml="12px">
            {isCompleted
              ? i18n.ft(messages.seeMore)
              : i18n.ft(messages.reviewAndEdit)}
          </Box>
        </Flex>
      )}

      {isOpen && (
        <div>
          <InstructionsWrapper>
            <CloseButton onClick={() => setIsOpen(false)}>
              <IconWrapper>
                <Icon fitted color="blue" name="minus circle" />
              </IconWrapper>
              <Box as="span" ml="12px">
                {i18n.ft(messages.seeLess)}
              </Box>
            </CloseButton>

            {!isCompleted && <div>({i18n.ft(messages.selectCycle)})</div>}
          </InstructionsWrapper>

          <Table>
            <thead>
              <tr>
                <th></th>
                {cycles.map((cycle, idx) => {
                  const isFirstCell = idx === 0;
                  const isLastCell = idx === cycles.length - 1;
                  const cycleName = 'C' + cycle.cycle_number;
                  const cycleURL = RouteHelpers.getPath('measure-view-cycle', {
                    id: assessment.id,
                    cycleId: cycle.id
                  });

                  return (
                    <HeaderCell
                      as="th"
                      key={cycle.cycle_number}
                      isFirstColumn={isFirstCell}
                      isLastColumn={isLastCell}
                      isFirstRow
                      isCompleted={isCompleted}
                    >
                      {isCompleted ? (
                        cycleName
                      ) : (
                        <CycleLink to={cycleURL}>
                          <VisuallyHidden>
                            {i18n.ft(messages.editCycle, {
                              number: cycle.cycle_number
                            })}
                          </VisuallyHidden>
                          <span aria-hidden>{cycleName}</span>
                        </CycleLink>
                      )}
                    </HeaderCell>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {scores.map((dimension, idx) => {
                const isLastRow = idx === scores.length - 1;

                return (
                  <tr key={dimension.t_name}>
                    <CollapsedCell title={dimension.name}>
                      <Abbreviation
                        bgColor={colors[dimension.domain_abbreviation]}
                      >
                        {dimension.abbreviation.toUpperCase()}
                      </Abbreviation>
                      <AbbreviationIcon>
                        <Icon
                          fitted
                          size="big"
                          name="long arrow alternate right"
                        />
                      </AbbreviationIcon>
                    </CollapsedCell>
                    {dimension.scores.map((score, scoreIdx) => {
                      const isFirst = scoreIdx === 0;
                      const isLast = scoreIdx === dimension.scores.length - 1;

                      return (
                        <TableCell
                          key={scoreIdx}
                          isFirstColumn={isFirst}
                          isLastColumn={isLast}
                          isLastRow={isLastRow}
                        >
                          {score}
                        </TableCell>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </WrapperButton>
  );
}

export default ScoresByCycleTable;
