const PATH = 'fe.components.organization.editable_field';

const messages = {
  save: {
    key: `${PATH}.save`,
    defaultValue: 'Save'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  }
};

export default messages;
