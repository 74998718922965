import { ImprovementStrategy } from 'types/api/improvement/ImprovementStrategy';
import { ImprovementFocus } from 'types/api/improvement/ImprovementFocus';

export function filterByFocusId(
  strategies: ImprovementStrategy[],
  focusId: number
): ImprovementStrategy[] {
  return strategies.filter((strategy: ImprovementStrategy) => {
    if (
      strategy.improvement_focus.find((f: ImprovementFocus) => f.id === focusId)
    )
      return strategy;
    return false;
  });
}
