export type DimensionScore = {
  id: number;
  answer: number;
  dimensionId: number;
};

export function refreshUserScores(
  prevUserScores: DimensionScore[],
  userScoreValue: DimensionScore
) {
  let updateUserScores = prevUserScores.filter(
    userScore => userScore.id !== userScoreValue.id
  );
  updateUserScores.push(userScoreValue);

  return [...updateUserScores];
}

export function createUserScore(
  updateUserScores: DimensionScore[],
  userScoreValue: DimensionScore
) {
  return (
    updateUserScores.findIndex(
      userScore => userScore.id === userScoreValue.id
    ) === -1
  );
}

export function getIndexUserScores(
  updateUserScores: DimensionScore[],
  userScoreValue: {
    id: number | null;
    answer: number;
    dimensionId: number;
  }
) {
  return updateUserScores.findIndex(
    userScore => userScore.id === userScoreValue.id
  );
}

export function getUserScoreByDimensionId(
  updateUserScores: DimensionScore[],
  dimensionId: number
) {
  const index = updateUserScores.findIndex(
    userScore => userScore.dimensionId === dimensionId
  );
  return index === -1 ? null : updateUserScores[index].id;
}

export function getNewUserScore(keyCode: string, currentUserScore: number) {
  if (keyCode === 'ArrowRight' && currentUserScore === 7) {
    return 1;
  }

  if (keyCode === 'ArrowLeft' && currentUserScore === 1) {
    return 7;
  }

  return keyCode === 'ArrowRight' ? currentUserScore + 1 : currentUserScore - 1;
}

export function getUserAnswer(
  userScores: DimensionScore[],
  dimensionId: number
) {
  return userScores.find(answer => answer.dimensionId === dimensionId) || null;
}

export function isInvalidKey(answer: number, keyCode: string) {
  if (
    !(answer >= 1 && answer <= 7) &&
    keyCode !== 'ArrowRight' &&
    keyCode !== 'ArrowLeft'
  ) {
    return true;
  }

  return false;
}

export function wasArrowkey(keyCode: string) {
  if (keyCode === 'ArrowRight' || keyCode === 'ArrowLeft') {
    return true;
  }
  return false;
}
