import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';

interface PromptProps {
  prompts: string[];
  className?: string;
}

function Prompt({ prompts, className }: PromptProps) {
  return (
    <div
      className={`mb-8 rounded-lg bg-[#F4F8FA] text-[#364A5E] p-4 basis-1/3 h-fit 2xl:mt-10 print:break-inside-avoid ${className}`}
    >
      <h3 className="text-base">{i18n.ft(messages.title)}</h3>
      <ul className="list-disc pl-6">
        {prompts.map((prompt, index) => (
          <li key={index}>{prompt}</li>
        ))}
      </ul>
    </div>
  );
}

export default Prompt;
