import { any } from 'ramda';
import { SubscriptionSeat } from 'types/api/SubscriptionSeat';

export function hasProductSubscription(
  seats: SubscriptionSeat[],
  product: string
): boolean {
  return any(subscriptionSeat => {
    return subscriptionSeat.subscription.product.name === product;
  }, seats);
}
