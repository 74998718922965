import styled from 'styled-components';

export const BulletPoint = styled.div<{ isTablet: boolean }>`
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
  background-color: #edeff2;
  border-radius: 9999px;
  align-items: center;
  height: 1.5rem;
  display: flex;
  margin-right: 0.9rem;
  width: ${p => (p.isTablet ? '1.5rem;' : '')};
`;

export const VideoPlayerContainer = styled.div<{ isTablet: boolean }>`
  min-height: ${p => (p.isTablet ? '450px' : '100%')};
  width: 100%;
`;
