const PATH = 'fe.components.trainings.status_filter';

const messages = {
  active: {
    key: `${PATH}.active`,
    defaultValue: 'Active'
  },
  all: {
    key: `${PATH}.all`,
    defaultValue: 'All'
  },
  canceled: {
    key: `${PATH}.canceled`,
    defaultValue: 'Canceled'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed'
  },
  status: {
    key: `${PATH}.status`,
    defaultValue: 'Status'
  }
};

export default messages;
