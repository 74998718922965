import React, { useContext } from 'react';

const TrainingContext = React.createContext();
TrainingContext.displayName = 'TrainingContext';

export const TrainingProvider = ({ value, children }) => {
  return (
    <TrainingContext.Provider value={value}>
      {children}
    </TrainingContext.Provider>
  );
};

export function useTraining() {
  const context = useContext(TrainingContext);

  if (!context) {
    throw new Error('useTraining must be used within a TrainingProvider');
  }

  return context;
}

export default TrainingContext;
