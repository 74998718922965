import { forEach } from 'ramda';
import { useAuth } from 'context/auth';
import { SECURITY_ZONES, RESOURCES } from 'types/api/Permission';

type ResourcePermissions = {
  manage: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
  view: boolean;
};

const defaultPermissions = {
  manage: false,
  create: false,
  update: false,
  delete: false,
  view: false
};

const allPermissions = {
  manage: true,
  create: true,
  update: true,
  delete: true,
  view: true
};

function usePermissions(
  zone: SECURITY_ZONES,
  resource: RESOURCES
): ResourcePermissions {
  let permissions: Partial<ResourcePermissions> = {};
  const auth = useAuth();
  const zonePermissions = auth.permissions?.[zone] || [];

  const resourcePermissions = zonePermissions.filter(
    p => p.resource === resource
  );

  forEach(permission => {
    permissions[permission.operation_name] = true;
  }, resourcePermissions);

  if (permissions.manage) {
    return allPermissions;
  } else {
    return { ...defaultPermissions, ...permissions };
  }
}

export default usePermissions;
