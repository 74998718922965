import styled from 'styled-components';
import checkMarkIcon from 'images/improvements/circle-check.svg';

export const CheckBullets = styled.ul`
  list-style-image: url(${checkMarkIcon});

  li > span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;
  }
`;
