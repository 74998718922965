import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import VisuallyHidden from 'components/VisuallyHidden';
import { TARGET_TYPE } from 'types/api/envScales/ObservationFeedback';

import {
  Section,
  SectionHeader,
  SectionContent,
  SectionDetail
} from 'components/Measure/View/Styled';

import {
  getObservationFeedback,
  GetObservationFeedbackRes
} from 'actions/envScales/observationFeedback';

interface ObservationFeedbackProps {
  /** Assessment ID. */
  assessmentId: number;

  /** Observation ID. */
  observationId: number;

  /** Observation Feedback Target Type. */
  targetType: TARGET_TYPE;

  /** Show edit button */
  showEdit?: boolean;
}

function ObservationFeedback({
  assessmentId,
  observationId,
  targetType,
  showEdit = true
}: ObservationFeedbackProps) {
  const { run, data, isPending } = useAsync<GetObservationFeedbackRes>();
  const editLink = RouteHelpers.getPath('measure-examples-notes', {
    id: assessmentId
  });

  useEffect(() => {
    run(getObservationFeedback(observationId, targetType));
  }, [run, observationId, targetType]);

  if (isPending) {
    return null;
  }

  return (
    <Section className="my-2">
      <div className="flex items-center justify-between">
        <SectionHeader>{i18n.ft(messages.title)}</SectionHeader>

        <div className="sm:mr-14">
          {showEdit && (
            <Button icon color="blue" size="mini" as={Link} to={editLink}>
              <VisuallyHidden>{i18n.ft(messages.edit)}</VisuallyHidden>
              <Icon fitted name="pencil" />
            </Button>
          )}
        </div>
      </div>

      <SectionContent>
        <SectionDetail className="flex flex-col gap-4">
          {data.questions_with_answers.map(feedback => (
            <div key={feedback.question_id}>
              <div className="font-bold">{feedback.question}</div>
              <div>{feedback.answer_notes}</div>
            </div>
          ))}
        </SectionDetail>
      </SectionContent>
    </Section>
  );
}

export default ObservationFeedback;
