import React, { useEffect, useState } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import ReasonForm from './ReasonForm';
import {
  Modal,
  Header,
  Button,
  SemanticICONS,
  SemanticCOLORS,
  ModalContentProps
} from 'semantic-ui-react';

export type CallbackProps = {
  /** Closes the modal. */
  closeModal: () => void;

  /** Sets the modal loading status. */
  setLoading: (value: boolean) => void;

  /** Reason for the action. */
  chosenReason?: string;
};

interface ConfirmationModalProps {
  /** Title for the modal. */
  title: string;

  /** Content message for the modal. */
  message: ModalContentProps['content'];

  /** Text associated with the left button for canceling the modal action. */
  cancelButton?: string;

  /** Text associated with the right button for completing the modal action. */
  confirmButton: string;

  /** List of reasons. */
  reasonsList?: string[];

  /** Color associated with the right button for completing the modal action. */
  confirmColor?: SemanticCOLORS;

  /** Header Icon.  */
  icon: SemanticICONS;

  /** Node associated with the trigger of the modal. */
  triggerNode: React.ReactNode;

  /** Function called when the confirmation is completed. */
  onConfirm: (prop: CallbackProps) => void;

  /** Callback fired when the modal is opened. */
  onOpen?: (setIsOpen: React.Dispatch<React.SetStateAction<boolean>>) => void;

  /** External state setter (optional) */
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;

  /** External state prop to set initial open state */
  isOpen?: boolean;
}

export interface ChangeFormValues {
  reason?: string;
  other_reason?: string;
}

function ConfirmationModal({
  title,
  message,
  cancelButton = i18n.ft(messages.cancel),
  confirmButton,
  reasonsList,
  confirmColor = 'red',
  icon,
  triggerNode,
  onConfirm,
  onOpen,
  setIsOpen: externalSetter,
  isOpen: shouldOpen
}: ConfirmationModalProps) {
  const [isOpen, setIsOpen] = useState(Boolean(shouldOpen));
  const showReasons = reasonsList && reasonsList.length > 0;
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    setIsOpen(Boolean(shouldOpen));
  }, [shouldOpen]);

  const close = () => {
    setIsloading(false);
    setIsOpen(false);
    if (externalSetter) {
      externalSetter(false);
    }
  };

  function handleOpen() {
    if (onOpen && typeof onOpen === 'function') {
      onOpen(setIsOpen);
    } else if (externalSetter) {
      externalSetter(isOpen);
    } else {
      setIsOpen(true);
    }
  }

  function handleConfirmation() {
    setIsloading(true);
    onConfirm({ closeModal: close, setLoading: setIsloading });
  }

  return (
    <Modal
      trigger={triggerNode}
      open={isOpen}
      closeOnDimmerClick
      onClose={close}
      onOpen={handleOpen}
      size="small"
    >
      <Header icon={icon} content={title} />

      <Modal.Content>
        {message}

        {showReasons && (
          <ReasonForm
            reasonsList={reasonsList}
            cancelButton={cancelButton}
            confirmButton={confirmButton}
            confirmColor={confirmColor}
            onConfirm={onConfirm}
            isLoading={isLoading}
            setIsloading={setIsloading}
            close={close}
          />
        )}
      </Modal.Content>

      {!showReasons && (
        <Modal.Actions>
          <Button
            type="button"
            onClick={close}
            content={cancelButton}
            autoFocus={isOpen}
          />
          <Button
            type="button"
            onClick={handleConfirmation}
            content={confirmButton}
            color={confirmColor}
            loading={isLoading}
          />
        </Modal.Actions>
      )}
    </Modal>
  );
}

export default ConfirmationModal;
