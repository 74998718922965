import { Button } from 'semantic-ui-react';
import i18n from 'helpers/i18n';
import messages from './messages';
import welcomeImageSrc from 'images/onboarding/onb_welcome.png';
import focusImageSrc from 'images/onboarding/onb_focus.png';
import thankYouImageSrc from 'images/onboarding/onb_thank_you.png';
import { useOnboardingManager } from '../OnboardingManagerContext';
import {
  Wrapper,
  TitleLabel,
  Description,
  FocusDescription,
  FocusItem,
  Items,
  Item,
  Image,
  ButtonWrapper,
  StepImage
} from './Styled';

function SimpleOnboarding() {
  const { step, setStep, checkedSteps, setCheckedSteps } =
    useOnboardingManager();

  const handleNext = () => {
    const nextStep =
      step === 'welcome' ? 'focusOnInteractions' : 'workPerformed';
    let cpCheck = checkedSteps;
    cpCheck.push(nextStep);
    setStep(nextStep);
    setCheckedSteps(cpCheck);
  };

  const handleFinish = () => {
    window.location.href = window.location.origin;
  };

  const Welcome = () => {
    return (
      <>
        <TitleLabel>{i18n.ft(messages.welcome)}</TitleLabel>
        <Description>{i18n.ft(messages.description)}</Description>
        <Items>
          <Item>{i18n.ft(messages.onlineCourses)}</Item>
          <Item>{i18n.ft(messages.certificationTrainings)}</Item>
          <Item>{i18n.ft(messages.realClassroom)}</Item>
          <Item>{i18n.ft(messages.coachingPrograms)}</Item>
          <Item>{i18n.ft(messages.dataCollection)}</Item>
        </Items>
        <Image>
          <StepImage
            src={welcomeImageSrc}
            alt={i18n.ft(messages.welcomeImage)}
          />
        </Image>
        <ButtonWrapper>
          <Button fluid size="large" color="blue" onClick={handleNext}>
            {i18n.ft(messages.next)}
          </Button>
        </ButtonWrapper>
      </>
    );
  };

  const Focus = () => {
    return (
      <>
        <TitleLabel>{i18n.ft(messages.focus)}</TitleLabel>
        <FocusDescription>
          <FocusItem>{i18n.ft(messages.createdToShare)}</FocusItem>
          <FocusItem>{i18n.ft(messages.interact)}</FocusItem>
          <FocusItem>{i18n.ft(messages.classTool)}</FocusItem>
          <FocusItem>{i18n.ft(messages.myTeachstone)}</FocusItem>
        </FocusDescription>
        <Image>
          <StepImage src={focusImageSrc} alt={i18n.ft(messages.focusImage)} />
        </Image>
        <ButtonWrapper>
          <Button fluid size="large" color="blue" onClick={handleNext}>
            {i18n.ft(messages.next)}
          </Button>
        </ButtonWrapper>
      </>
    );
  };

  const LastStep = () => {
    return (
      <>
        <TitleLabel>{i18n.ft(messages.thankYou)}</TitleLabel>
        <FocusDescription>
          <FocusItem>{i18n.ft(messages.conclusion)}</FocusItem>
          <FocusItem>{i18n.ft(messages.questions)}</FocusItem>
        </FocusDescription>
        <Image>
          <StepImage
            src={thankYouImageSrc}
            alt={i18n.ft(messages.thankYouImage)}
          />
        </Image>
        <ButtonWrapper>
          <Button fluid size="large" color="blue" onClick={handleFinish}>
            {i18n.ft(messages.finish)}
          </Button>
        </ButtonWrapper>
      </>
    );
  };

  return (
    <Wrapper>
      {step === 'welcome' && <Welcome />}
      {step === 'focusOnInteractions' && <Focus />}
      {step === 'onboardingCompleted' && <LastStep />}
    </Wrapper>
  );
}

export default SimpleOnboarding;
