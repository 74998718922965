const PATH = 'fe.components.organization.trainings';

const messages = {
  active: {
    key: `${PATH}.active`,
    defaultValue: 'Active'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed'
  },
  all: {
    key: `${PATH}.all`,
    defaultValue: 'All'
  },
  canceled: {
    key: `${PATH}.canceled`,
    defaultValue: 'Canceled'
  },
  status: {
    key: `${PATH}.status`,
    defaultValue: 'Status'
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search by title, city, trainer name or trainer email'
  },
  searchTrainings: {
    key: `${PATH}.search_trainings`,
    defaultValue: 'Search Trainings'
  },
  startDate: {
    key: `${PATH}.start_date`,
    defaultValue: 'Start Date'
  },
  endDate: {
    key: `${PATH}.end_date`,
    defaultValue: 'End Date'
  },
  searchResults: {
    key: `${PATH}.search_results`,
    defaultValue: 'Search Results: '
  },
  countResults: {
    key: `${PATH}.count_results`,
    defaultValue: '({{current}} out of {{total}} showing)'
  },
  noTrainings: {
    key: `${PATH}.no_trainings`,
    defaultValue:
      'You do not yet have trainings set up for {{name}}. Learn more about our trainings '
  },
  here: {
    key: `${PATH}.here`,
    defaultValue: 'here'
  }
};

export default messages;
