import styled from 'styled-components';

export const TrainingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: 640px) {
    gap: 12px;
  }
`;

export const NoTrainingsMessage = styled.p`
  text-align: center;
  max-width: 768px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const MMCIMessage = styled.div`
  text-align: center;
  margin-top: 32px;

  @media screen and (min-width: 768px) {
    margin-top: 48px;
    margin-bottom: -16px;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: -40px;
  }
`;

/** Summary Components */

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;

  @media screen and (min-width: 640px) {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 0;
    padding-right: 24px;
  }

  @media screen and (min-width: 768px) {
    padding-right: 48px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 48px;
  }

  @media screen and (min-width: 1280px) {
    padding-right: 80px;
    margin-bottom: 56px;
  }
`;

export const SummaryWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
`;

export const Summary = styled.div<{ color: string }>`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${p => p.color};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    width: 120px;
    height: 120px;
  }

  @media screen and (min-width: 1024px) {
    width: 148px;
    height: 148px;
  }
`;

export const SummaryValue = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;

  @media screen and (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 36px;
    line-height: 40px;
  }
`;

export const SummaryLabel = styled.div`
  position: absolute;
  bottom: -4px;
  transform: translateY(100%);
  text-align: center;

  @media screen and (min-width: 768px) {
    position: initial;
    transform: none;
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
