const PATH = 'fe.pages.users';

const messages = {
  login: {
    key: `${PATH}.teachstone_login`,
    defaultValue: 'Teachstone Login'
  }
};

export default messages;
