const PATH = 'fe.pages.reports.observation.view';

const messages = {
  editReport: {
    key: `${PATH}.edit_report`,
    defaultValue: 'Edit Report'
  }
};

export default messages;
