const PATH = 'fe.components.account.image_uploader';

const messages = {
  uploadPhoto: {
    key: `${PATH}.upload_photo`,
    defaultValue: 'Upload a photo'
  },
  positionPhoto: {
    key: `${PATH}.position_and_resize`,
    defaultValue: 'Position and resize your photo'
  },
  choosePhoto: {
    key: `${PATH}.choose_photo`,
    defaultValue: 'Choose a photo for your account'
  },
  chooseFile: {
    key: `${PATH}.choose_file`,
    defaultValue: 'Choose File'
  },
  noFileSelected: {
    key: `${PATH}.no_file_selected`,
    defaultValue: 'No file selected yet.'
  },
  errors: {
    maxFileSize: {
      key: `${PATH}.errors.max_file_size_error`,
      defaultValue: 'File size cannot be greater than 3.00 MB'
    },
    fileType: {
      key: `${PATH}.errors.file_type_error`,
      defaultValue: 'File type must be an image'
    }
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  save: {
    key: `${PATH}.save`,
    defaultValue: 'Save Changes'
  }
};

export default messages;
