import styled from 'styled-components';

export const EmptyObservations = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`;

export const EmptyMessageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`;

export const NoObservationsMessage = styled.div`
  margin-top: 10px;
  font-size: 13px;
  color: ${p => p.theme.colors['neutral']['700']};

  @media screen and (min-width: 768px) {
    font-size: 25px;
  }
`;
