import React, { useState } from 'react';
import { Icon, Button, Popup } from 'semantic-ui-react';
import moment from 'moment';
import StatusUp from 'images/dashboard/status-up.svg';
import messages from './messages';
import i18n from 'helpers/i18n';
import settings from 'helpers/settings';
import RouteHelpers from 'helpers/routes';
import { UserEnrollment } from 'types/api/UserEnrollment';
import { Box } from 'components/Box';
import { Recommendation } from 'types/api/Recommendation';
import { useMediaQuery } from 'react-responsive';
import { getVisibleEnrollments, mapResources } from './utils';

import {
  Wrapper,
  Learning,
  LearningTitle,
  LearningStatus,
  RowName,
  ProDevelopment,
  ProIcon,
  Divider,
  LearningResources,
  ShowList,
  Resources,
  Thumbnail,
  ResourcesList,
  ResourcesCard,
  CardHeader,
  CardRow,
  BoldText,
  SmallTitle,
  LinkName,
  RowRecommendedBy,
  Date,
  ProContainer,
  BtWrapper
} from './Styled';

interface ProfessionalDevProps {
  enrollments: UserEnrollment[];
  learningResources: boolean;
  recommendations: Recommendation[];
}

const MyTeachstone = settings.lookup('REACT_APP_MYTS_BASE_URL');

function ProfessionalDevelopment({
  enrollments,
  learningResources,
  recommendations
}: ProfessionalDevProps) {
  const visibleEnrollments = getVisibleEnrollments(enrollments);
  const showLearningHub = visibleEnrollments.length;
  const [isEnrollmentRowOpen, setIsEnrollmentRowOpen] = useState(false);
  const isLessThan640px = useMediaQuery({ maxWidth: 640 });

  if (!visibleEnrollments.length && !learningResources) {
    return null;
  }

  const resources = mapResources(recommendations);

  return (
    <Wrapper>
      {showLearningHub ? (
        <>
          <ProContainer>
            <ProDevelopment>
              <ProIcon src={StatusUp} alt={i18n.ft(messages.title)} />
              {i18n.ft(messages.title)}
            </ProDevelopment>
            <BtWrapper>
              <Button
                fluid
                color="blue"
                as="a"
                href={RouteHelpers.getPath('td-history')}
              >
                {i18n.ft(messages.getCertificates)}
              </Button>
            </BtWrapper>
          </ProContainer>
          <Learning>
            <LearningTitle
              href={RouteHelpers.getPath('canvas')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.ft(messages.learning)}
              <Box as="span" ml="16px">
                <Icon fitted name="angle right" />
              </Box>
            </LearningTitle>
            <LearningStatus>{i18n.ft(messages.status)}</LearningStatus>

            {visibleEnrollments.map((enrollment, index) => {
              const maxIndex = isEnrollmentRowOpen
                ? visibleEnrollments.length
                : 3;
              return index < maxIndex ? (
                <React.Fragment key={`enrollments-row-${index}`}>
                  <RowName>
                    {enrollment.status === 'available' ? (
                      <a
                        href={RouteHelpers.getPath('canvas-course', {
                          id: enrollment.sis_course.lms_course_id
                        })}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {enrollment.sis_course.name}
                      </a>
                    ) : (
                      <>{enrollment.sis_course.name}</>
                    )}
                  </RowName>
                  <div>
                    {enrollment.status && !enrollment.certificate_id ? (
                      i18n.ft(messages[enrollment.status])
                    ) : (
                      <a
                        download
                        href={`/api/v2/training_certificates/${enrollment.certificate_id}.pdf`}
                      >
                        {i18n.ft(messages.getCertificate)}
                        <Box as="span" ml="4px">
                          <Icon fitted name="angle right" />
                        </Box>{' '}
                      </a>
                    )}
                  </div>
                </React.Fragment>
              ) : null;
            })}
          </Learning>
          {visibleEnrollments.length > 3 ? (
            <ShowList
              onClick={() => {
                setIsEnrollmentRowOpen(!isEnrollmentRowOpen);
              }}
            >
              {isEnrollmentRowOpen
                ? i18n.ft(messages.showLess)
                : i18n.ft(messages.showAll)}
              <Icon fitted name="arrow alternate circle right" color="blue" />
            </ShowList>
          ) : null}
        </>
      ) : null}

      {learningResources ? (
        <>
          {showLearningHub ? (
            <Divider />
          ) : (
            <ProContainer>
              <ProDevelopment>
                <ProIcon src={StatusUp} alt={i18n.ft(messages.title)} />
                {i18n.ft(messages.title)}
              </ProDevelopment>
              <BtWrapper>
                <Button
                  fluid
                  color="blue"
                  as="a"
                  href={RouteHelpers.getPath('td-history')}
                >
                  {i18n.ft(messages.getCertificates)}
                </Button>
              </BtWrapper>
            </ProContainer>
          )}
          {recommendations?.length ? (
            isLessThan640px ? (
              <ResourcesList>
                <LearningTitle href={RouteHelpers.getPath('myts-resources')}>
                  {i18n.ft(messages.learningResources)}
                  <Box as="span" ml="16px">
                    <Icon fitted name="angle right" />
                  </Box>
                </LearningTitle>
                {resources.map((resource, index) => (
                  <ResourcesCard key={index}>
                    <CardHeader>
                      <Thumbnail src={resource.thumbnail} alt="thumbnail" />
                      <LinkName
                        href={`${MyTeachstone}/api/v1/accounts/${resource.account_id}/choose?redirect_path=resources/${resource.id}`}
                      >
                        {resource.title}
                      </LinkName>
                    </CardHeader>
                    <SmallTitle>{i18n.ft(messages.recommended)}</SmallTitle>
                    <CardRow>
                      <BoldText>{resource.user}</BoldText>
                      <Date>{moment(resource.date).format('YYYY-MM-DD')}</Date>
                    </CardRow>
                  </ResourcesCard>
                ))}
              </ResourcesList>
            ) : (
              <Resources>
                <LearningTitle href={RouteHelpers.getPath('myts-resources')}>
                  {i18n.ft(messages.learningResources)}
                  <Box as="span" ml="8px">
                    <Icon fitted name="angle right" />
                  </Box>
                </LearningTitle>
                <BoldText>{i18n.ft(messages.recommended)}</BoldText>
                <BoldText>{i18n.ft(messages.on)}</BoldText>
                {resources.map((resource, index) => (
                  <React.Fragment key={index}>
                    <CardHeader>
                      <Thumbnail src={resource.thumbnail} alt="thumbnail" />
                      <Popup
                        content={resource.title}
                        size="small"
                        trigger={
                          <LinkName
                            href={`${MyTeachstone}/api/v1/accounts/${resource.account_id}/choose?redirect_path=resources/${resource.id}`}
                          >
                            {resource.title}
                          </LinkName>
                        }
                      />
                    </CardHeader>
                    <CardHeader>
                      <RowRecommendedBy>{resource.user}</RowRecommendedBy>
                    </CardHeader>

                    <Date>{moment(resource.date).format('YYYY-MM-DD')}</Date>
                  </React.Fragment>
                ))}
              </Resources>
            )
          ) : (
            <LearningResources>
              <LearningTitle href={RouteHelpers.getPath('myts-resources')}>
                {i18n.ft(messages.learningResources)}
                <Box as="span" ml="16px">
                  <Icon fitted name="angle right" />
                </Box>
              </LearningTitle>
              <BtWrapper>
                <Button
                  fluid
                  color="blue"
                  as="a"
                  href={RouteHelpers.getPath('myts-resources')}
                >
                  {i18n.ft(messages.explore)}
                </Button>
              </BtWrapper>
            </LearningResources>
          )}
        </>
      ) : null}
    </Wrapper>
  );
}

export default ProfessionalDevelopment;
