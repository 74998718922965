import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { Button, Modal, ModalProps, Input } from 'semantic-ui-react';
import { editParticipantEmail } from 'actions/trainings';
import { TrainingParticipant } from 'types/api/TrainingParticipant';
import styled from 'styled-components';

const StyledDiv = styled.div`
  input {
    margin-bottom: 10px !important;
  }

  p {
    color: #a44442;
    font-size: 14px;
  }
`;

interface EditEmailModalProps extends ModalProps {
  /** Callback fired when the modal requests to be closed. */
  onClose: () => void;

  /** Callback fired after the participant email is edited.  */
  afterEdit: () => void;

  /** Participant Id to be edited. */
  participantId: number;

  /** Array of participants present in the training. */
  participants: TrainingParticipant[];

  /** Training ID. */
  trainingId: number;
}

const EditEmailModal = ({
  onClose,
  afterEdit,
  participantId,
  trainingId,
  participants,
  ...props
}: EditEmailModalProps) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [newEmail, setNewEmail] = useState('');

  const participant = participants.find(
    participant => participant.id === participantId
  );

  const handleDismiss = () => {
    setNewEmail('');
    setErrors([]);
    onClose();
  };

  const validateNewEmail = (email: string) => {
    const currentErrors = [];

    const existingEmail = participants.find(
      participant => participant.email === email
    );
    if (existingEmail) {
      currentErrors.push('Email already exists');
    }

    setErrors(currentErrors);
  };

  const handleNewEmailChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    validateNewEmail(value);
    setNewEmail(value);
  };

  const handleEdit = () => {
    if (errors.length > 0) {
      return;
    }

    setLoading(true);
    editParticipantEmail(trainingId, participantId, {
      participant: { email: newEmail }
    })
      .then(() => {
        setLoading(false);
        setNewEmail('');
        afterEdit();
      })
      .catch(({ response }) => {
        setErrors([...errors, ...response.data.errors.participant]);
        setLoading(false);
      });
  };

  return (
    <Modal {...props}>
      <Modal.Header>{`${i18n.ft(
        messages.areYouSure
      )} (${participant?.email})`}</Modal.Header>
      <Modal.Content>
        <StyledDiv>
          <Input
            loading={loading}
            disabled={loading}
            error={errors.length > 0}
            type="text"
            placeholder="New email"
            value={newEmail}
            onChange={handleNewEmailChange}
          />

          {errors.map(error => (
            <p key={error}>{error}</p>
          ))}
        </StyledDiv>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={loading}
          onClick={handleDismiss}
          content={i18n.ft(messages.dismiss)}
        />
        <Button
          color="green"
          loading={loading}
          disabled={loading || errors.length > 0}
          onClick={handleEdit}
          content={i18n.ft(messages.confirm)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EditEmailModal;
