import moment from 'moment';
import RouteHelpers from 'helpers/routes';
import queryString from 'query-string';
import { isEmpty, join, map } from 'ramda';
import { PaymentRequestUserToken } from 'types/api/UserToken';

export function redirecToCheckout(trainingId: number, participants: number[]) {
  const checkoutURL = queryString.stringifyUrl({
    url: RouteHelpers.getPath('td-trainings-view-card-checkout', {
      id: trainingId
    }),
    query: { participants }
  });

  window.location.replace(checkoutURL);
}

export function formatAgeLevels(token: PaymentRequestUserToken): string {
  return join(
    ', ',
    token.age_levels.map(a => a.name)
  );
}

export function formatTrainer(token: PaymentRequestUserToken): string {
  return `${token.trainer.first_name} ${token.trainer.last_name}`;
}

export function formatSessions(token: PaymentRequestUserToken): string {
  if (isEmpty(token.sessions)) {
    return 'TBD';
  }

  const dates = map(session => {
    return moment(session.starts_at).local().format('LL');
  }, token.sessions);

  return join(' / ', dates);
}
