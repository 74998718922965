import colors from 'styles/colors';
import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 11px;
  grid-template-columns: repeat(7, 1fr);
  width: fit-content;
  align-items: center;

  @media screen and (min-width: 640px) {
    grid-gap: 16px;
  }
`;

const variants = {
  default: {
    color: colors.scaleDarkBlue,
    backgroundColor: colors.white,
    borderColor: 'transparent'
  },
  checked: {
    color: colors.white,
    backgroundColor: colors.scaleGreen,
    borderColor: 'transparent'
  },
  suggested: {
    color: colors.scaleDarkBlue,
    backgroundColor: colors.scaleLightGreen,
    borderColor: colors.white
  }
};

const blink = keyframes`
  from {
    border-color: white;
  }

  to {
    border-color: #C6F3DB;
  }
`;

export const ScoreCheckbox = styled.div<{
  checked?: boolean;
  suggested?: boolean;
}>`
  ${p =>
    variants[p.checked ? 'checked' : p.suggested ? 'suggested' : 'default']};
  width: 19px;
  height: 19px;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  box-shadow: 0.1px 1px 4px rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${p =>
    p.suggested &&
    css`
      ${blink} 1.5s alternate infinite
    `};

  @media screen and (min-width: 640px) {
    width: 27px;
    height: 27px;
    font-size: 16px;
    line-height: 20px;
  }
`;
