import styled from 'styled-components';
import { Form } from 'semantic-ui-react';

export const FormRowWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const DescriptionLabel = styled.div`
  font-size: 18px;
  margin: 12px 10px 10px 25px;
`;

export const InputRadioWrapper = styled(Form.Field)<{ checked: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors['white'] : theme.colors['neutral']['100']};
  min-height: 61px;
  height: auto;
  width: 100%;
  margin: 10px;
  padding: 20px 10px;
  border-radius: 11px;
  border: ${({ checked, theme }) =>
    `solid 3px ${
      checked ? theme.colors.blue : theme.colors['neutral']['100']
    } `};

  input[type='radio'] {
    display: inherit;
  }
`;

export const InputRadioWrapperWithDescription = styled(Form.Radio)`
  label {
    font-size: 18px;
    font-weight: bold;
  }
`;
