import { useEffect, useRef, useState } from 'react';
import { isNil, isEmpty } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Dropdown, Icon, Loader } from 'semantic-ui-react';
import MainContent from 'components/MainContent';
import { Button } from 'semantic-ui-react';
import useAsync from 'hooks/useAsync';
import CreateButton from 'components/Measure/Home/CreateButton';
import AssessmentList from 'components/Measure/Home/AssessmentList';
import { useMediaQuery } from 'react-responsive';
import { Input } from 'semantic-ui-react';
import PageHeader from 'components/Measure/PageHeader';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import queryString from 'query-string';
import { SelectedAccount } from 'types/api/Account';
import { useActiveSubscriptions } from 'context/auth';
import { toastError } from 'components/Toast';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { hasRole } from 'pages/Measure/utils';
import { SettingQuestion } from 'types/api/envScales/SettingQuestion';
import useTimestamp from 'hooks/useTimestamp';
import { ENV_SCALES_URL } from 'constants/routes';
import RouteHelpers from 'helpers/routes';
import { initialStartDate, parseDate, OptionalDate } from './utils';
import moment from 'moment';

import {
  searchAssessments,
  getAssessmentsSummary,
  approveMultipleAssessments
} from 'actions/envScales/assessments';

import {
  EmptyObservations,
  NoObservationsMessage,
  EmptyMessageContainer,
  PageBackground,
  ButtonWrapper,
  FilterContainer,
  FiltersTitle,
  FiltersButtons,
  SearchBar,
  ListWrapper,
  PaginationWrapper,
  Pagination,
  LoaderWrapper,
  PageCount,
  FilterButton,
  PageContainer,
  ApproveContainer,
  ButtonSelect,
  FilterSpace
} from './Styled';

import Filters from 'components/Measure/Home/Filters';
import StatusButton from 'components/Measure/Home/StatusButton';
import FiltersCatalog from 'components/Measure/Home/Filters/FiltersCatalog';
import useCurrentUser from 'hooks/useCurrentUser';
import { Link } from 'react-router-dom';

type FilterType = {
  q: string;
  status: string;
  page: number;
  per_page: number;
  age_level?: 'Inf' | 'Todd' | 'Pre-K' | '';
  assessment_template_id?: string;
  observer_guids?: string[];
  node_ids?: number[];
  start_date?: string;
  end_date?: string;
};

type DateFilters = {
  start_date: OptionalDate;
  end_date: OptionalDate;
};

interface HomeProps {
  account: SelectedAccount;
  accountSettings: SettingQuestion;
}

function Home({ account, accountSettings }: HomeProps) {
  const [showFilters, setShowFilters] = useState(false);
  const [select, setSelect] = useState(false);
  const [approveList, setApproveList] = useState<number[]>([]);
  const { run, data, ...dataRequest } = useAsync();

  const {
    run: runAssessmentsSummary,
    data: assessmentsSummaryData,
    ...dataSummaryRequest
  } = useAsync();

  const currentAccountId = account.id;
  const currentUser = useCurrentUser();
  const isTablet = useMediaQuery({ minWidth: 768 });
  const [timestamp, refreshTimestamp] = useTimestamp();
  const filtersSessionStorageKey = getFiltersKey();

  const initialValues = getInitialValues();
  const [query, setQuery] = useState(initialValues.q);
  const [dates, setDates] = useState<DateFilters>({
    start_date: parseDate(initialValues.start_date),
    end_date: parseDate(initialValues.end_date)
  });

  const { state, setState, setPage } =
    usePaginatedSearch<FilterType>(initialValues);

  function getFiltersKey() {
    const userId = currentUser.id;
    const accountId = currentAccountId;
    return `user:${userId}-account:${accountId}-filters`;
  }

  function getInitialValues(): FilterType {
    const sessionState = sessionStorage.getItem(filtersSessionStorageKey);

    if (sessionState) {
      return JSON.parse(sessionState);
    }

    const startDate = initialStartDate(account.school_year_start_month);

    return {
      q: '',
      status: 'open',
      page: 1,
      per_page: 30,
      age_level: '',
      assessment_template_id: '',
      observer_guids: [],
      node_ids: [],
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
      end_date: ''
    };
  }

  const isFirstRender = useRef(true);

  useEffect(() => {
    // Only sets the values in locale storage the 2nd time this useEffect is called.
    const isFirstRenderRef = isFirstRender.current;
    isFirstRender.current = false;

    if (isFirstRenderRef) {
      return;
    }

    sessionStorage.setItem(filtersSessionStorageKey, JSON.stringify(state));
  }, [state, filtersSessionStorageKey]);

  const subscriptions = useActiveSubscriptions();

  const isAccountAdmin = Boolean(
    hasRole(subscriptions, currentAccountId, 'admin')
  );

  const isAccountObserver = Boolean(
    hasRole(subscriptions, currentAccountId, 'observer')
  );

  const isSupervisor = Boolean(
    hasRole(subscriptions, currentAccountId, 'supervisor')
  );

  function statsFor(field: string) {
    if (dataSummaryRequest.isSuccess) {
      return assessmentsSummaryData.assessments_summary[field];
    } else {
      return '';
    }
  }

  const statusText =
    state.status === 'open'
      ? i18n.ft(messages.inProgress)
      : state.status === 'completed'
        ? i18n.ft(messages.completed)
        : i18n.ft(messages.awaiting);

  const noResultsText = isEmpty(state.q)
    ? i18n.ft(messages.noObservations, { filter: statusText })
    : i18n.ft(messages.noResults);

  const assessmentsInCsvCount = statsFor('total_assessments_in_csv');
  const isExportDataButtonDisabled =
    assessmentsInCsvCount <= 0 || isEmpty(assessmentsInCsvCount);

  const downloadCSVUrl = queryString.stringifyUrl({
    url: `${ENV_SCALES_URL}/api/v1/assessments.csv`,
    query: {
      q: state.q,
      status: 'completed',
      age_level: state.age_level,
      start_date: state.start_date,
      end_date: state.end_date,
      assessment_template_id: state.assessment_template_id,
      observer_guids: state.observer_guids,
      node_ids: state.node_ids
    }
  });

  const downloadAll = queryString.stringifyUrl({
    url: `${ENV_SCALES_URL}/api/v1/assessments.csv`,
    query: { status: 'completed' }
  });

  useEffect(loadAssessments, [currentAccountId, run, state]);
  useEffect(loadAssessmentsSummary, [
    currentAccountId,
    runAssessmentsSummary,
    state
  ]);

  function loadAssessments() {
    run(searchAssessments(state));
  }

  function loadAssessmentsSummary() {
    runAssessmentsSummary(getAssessmentsSummary(state));
  }

  function onAfterSelect(id: number) {
    const idInList = approveList.find(cardId => cardId === id);
    if (idInList) {
      const tempList = approveList.filter(obsId => obsId !== id);
      setApproveList(tempList);
    } else {
      setApproveList([...approveList, id]);
    }
  }

  function onFilterStatus(filter: FilterType) {
    let values: Partial<FilterType> = {};

    if (filter.age_level) {
      values.age_level = filter.age_level;
    }

    if (filter.assessment_template_id) {
      values.assessment_template_id = filter.assessment_template_id;
    }

    if (filter.observer_guids) {
      values.observer_guids = filter.observer_guids;
    }

    if (filter.node_ids) {
      values.node_ids = filter.node_ids;
    }

    if (filter.start_date) {
      values.start_date = filter.start_date;
    }

    if (filter.end_date) {
      values.end_date = filter.end_date;
    }

    setState(values);
  }

  function onClearFilters() {
    setState({
      q: '',
      age_level: '',
      assessment_template_id: '',
      observer_guids: [],
      node_ids: [],
      start_date: '',
      end_date: ''
    });

    // Reset manually tracked fields
    setQuery('');
    setDates({ start_date: null, end_date: null });
    refreshTimestamp();
  }

  function cancelApproval() {
    setApproveList([]);
    setSelect(false);
  }

  function clearFilter(filter: string) {
    switch (filter) {
      case 'start_date':
      case 'end_date':
        setDates({ start_date: null, end_date: null });
        refreshTimestamp();
        setState({ start_date: '', end_date: '' });
        break;
      case 'age_level':
        setState({ age_level: '' });
        break;
      case 'assessment_template_id':
        setState({ assessment_template_id: '' });
        break;
      case 'observer_guids':
        setState({ observer_guids: [] });
        break;
      case 'node_ids':
        setState({ node_ids: [] });
        break;
      case 'all':
        onClearFilters();
    }
  }

  function approveAssessments() {
    approveMultipleAssessments(approveList)
      .then(() => {
        cancelApproval();
        loadAssessments();
        setTimeout(loadAssessmentsSummary, 600);
      })
      .catch(({ response }) => {
        toastError({
          description:
            response!.data!.errors!.assessment! || i18n.ft(messages.error)
        });
      });
  }

  const queryRef = useRef<null | NodeJS.Timeout>(null);

  function handleSearch(e: any, { value }: any) {
    setQuery(value);

    if (queryRef.current) {
      clearTimeout(queryRef.current);
    }

    queryRef.current = setTimeout(() => setState({ q: value }), 300);
  }

  const assessmentFilter =
    data?.assessments.filter((a: any) => a.status === state.status) ?? [];

  const isLoading = dataRequest.isLoading;
  const displayJobId = accountSettings.require_job_id;

  return (
    <MainContent maxWidth={1536}>
      <div className="mt-8 px-3 sm:px-6 lg:px-[72px]">
        <PageHeader
          title={i18n.ft(messages.measurement)}
          subtitle={i18n.ft(messages.measurementDesc)}
          contentRight={
            isTablet && (
              <ButtonWrapper>
                <div>
                  <CreateButton account={account} />
                </div>

                {state.status === 'completed' && !isAccountObserver && (
                  <div>
                    <Dropdown
                      fluid
                      button
                      floating
                      className="green"
                      title="export"
                      text={i18n.ft(messages.csv)}
                      style={{ textAlign: 'center' }}
                      disabled={isExportDataButtonDisabled}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as={Link}
                          to={downloadCSVUrl}
                          download
                          disabled={assessmentsInCsvCount === 0}
                          dir="auto"
                        >
                          {i18n.ft(messages.filteredObservations, {
                            numberCount: assessmentsInCsvCount
                          })}
                        </Dropdown.Item>
                        <Dropdown.Item
                          as={Link}
                          to={downloadAll}
                          download
                          dir="auto"
                        >
                          {i18n.ft(messages.allObservations)}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
                {(isAccountAdmin || isSupervisor) && (
                  <div>
                    <Button
                      fluid
                      color="green"
                      as="a"
                      href={RouteHelpers.getPath('reports-observation')}
                      content={i18n.ft(messages.reports)}
                    />
                  </div>
                )}
              </ButtonWrapper>
            )
          }
        />

        {!isTablet && (
          <ButtonWrapper>
            {(isAccountAdmin || isSupervisor) && (
              <div>
                <Button
                  fluid
                  color="green"
                  as="a"
                  href={RouteHelpers.getPath('reports-observation')}
                  content={i18n.ft(messages.reports)}
                />
              </div>
            )}
            {state.status === 'completed' && !isAccountObserver && (
              <div>
                <Dropdown
                  fluid
                  button
                  floating
                  className="green"
                  title="export"
                  text={i18n.ft(messages.csv)}
                  style={{ textAlign: 'center' }}
                  disabled={isExportDataButtonDisabled}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Link}
                      to={downloadCSVUrl}
                      download
                      disabled={assessmentsInCsvCount === 0}
                      dir="auto"
                    >
                      {i18n.ft(messages.filteredObservations, {
                        numberCount: assessmentsInCsvCount
                      })}
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={downloadAll}
                      download
                      dir="auto"
                    >
                      {i18n.ft(messages.allObservations)}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            <div>
              <CreateButton account={account} />
            </div>
          </ButtonWrapper>
        )}

        <PageBackground>
          <FiltersTitle>{i18n.ft(messages.selectObservation)}</FiltersTitle>
          <FilterContainer>
            <FiltersButtons>
              <StatusButton
                active={state.status === 'open'}
                count={statsFor('total_open')}
                label={i18n.ft(messages.inProgress)}
                onClick={() => {
                  setState({ status: 'open' });
                  cancelApproval();
                }}
              />
              {accountSettings.require_observation_approval && (
                <StatusButton
                  active={state.status === 'awaiting_approval'}
                  count={statsFor('total_awaiting_approval')}
                  label={i18n.ft(messages.awaiting)}
                  onClick={() => setState({ status: 'awaiting_approval' })}
                />
              )}
              <StatusButton
                active={state.status === 'completed'}
                count={statsFor('total_completed')}
                label={i18n.ft(messages.completed)}
                onClick={() => {
                  setState({ status: 'completed' });
                  cancelApproval();
                }}
              />
            </FiltersButtons>
            <SearchBar>
              <Input
                fluid
                icon="search"
                iconPosition="left"
                placeholder={i18n.ft(messages.searchPlaceholder)}
                onChange={handleSearch}
                value={query}
              />
              <FilterButton onClick={() => setShowFilters(!showFilters)}>
                <FilterSpace>
                  {i18n.ft(messages.filter)}
                  <Icon
                    name={showFilters ? 'angle double up' : 'angle double down'}
                  />
                </FilterSpace>
              </FilterButton>
            </SearchBar>
          </FilterContainer>
          {showFilters ? (
            <Filters
              key={timestamp}
              onFilterStatus={onFilterStatus}
              account={account}
              state={state}
              onClear={onClearFilters}
              dates={dates}
              isObserver={isAccountObserver}
              onDatesChange={(startDate: OptionalDate, endDate: OptionalDate) =>
                setDates({ start_date: startDate, end_date: endDate })
              }
            />
          ) : (
            <FiltersCatalog
              filters={state}
              account={account}
              onDelete={clearFilter}
            />
          )}

          {isLoading ? (
            <LoaderWrapper>
              <Loader active inline="centered" />
            </LoaderWrapper>
          ) : !isNil(data) && !isEmpty(assessmentFilter) ? (
            <ListWrapper>
              <PageContainer>
                <PageCount>
                  {i18n.ft(messages.countSummary, {
                    current: assessmentFilter.length,
                    total: data.pagination.total_count
                  })}
                </PageCount>
                {select && (
                  <PageCount>
                    {i18n.ft(messages.countSelected, {
                      number: approveList.length
                    })}
                  </PageCount>
                )}
                {state.status === 'awaiting_approval' &&
                  !select &&
                  (isAccountAdmin || isSupervisor) && (
                    <ButtonSelect
                      fluid
                      content={i18n.ft(messages.selectMultiple)}
                      onClick={() => setSelect(!select)}
                    />
                  )}
              </PageContainer>
              {select && (
                <ApproveContainer>
                  <ConfirmationModal
                    title={i18n.ft(messages.approveObservation)}
                    message={i18n.ft(messages.modal.warning)}
                    confirmButton={i18n.ft(messages.modal.yes)}
                    icon="tasks"
                    confirmColor="green"
                    triggerNode={
                      <Button
                        content={i18n.ft(messages.approveObservation)}
                        disabled={approveList.length ? false : true}
                        color="green"
                      />
                    }
                    onConfirm={() => {
                      approveAssessments();
                    }}
                  />
                  <ButtonSelect
                    fluid
                    content={i18n.ft(messages.cancel)}
                    onClick={() => cancelApproval()}
                  />
                </ApproveContainer>
              )}
              <AssessmentList
                assessments={assessmentFilter}
                isAdmin={isAccountAdmin}
                isObserver={isAccountObserver}
                isSupervisor={isSupervisor}
                isSelecting={select}
                onAfterSelect={onAfterSelect}
                selectList={approveList}
                displayJobId={displayJobId}
              />
              <PaginationWrapper>
                <Pagination
                  size="small"
                  siblingRange={isTablet ? 3 : 0}
                  boundaryRange={isTablet ? 3 : 0}
                  activePage={data.pagination.current_page}
                  totalPages={data.pagination.total_pages}
                  ellipsisItem={isTablet ? '..' : null}
                  onPageChange={(e: any, data: any) =>
                    setPage(Number(data.activePage))
                  }
                />
              </PaginationWrapper>
            </ListWrapper>
          ) : (
            <EmptyObservations>
              <Icon size="big" color="red" name="attention" />
              <EmptyMessageContainer>
                <NoObservationsMessage>{noResultsText}</NoObservationsMessage>
              </EmptyMessageContainer>
            </EmptyObservations>
          )}
        </PageBackground>
      </div>
    </MainContent>
  );
}

export default Home;
