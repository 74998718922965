const PATH = 'fe.components.account.phone.mobile_form';

const messages = {
  success: {
    key: `${PATH}.success`,
    defaultValue: 'Successfully sent.'
  },
  sendCode: {
    key: `${PATH}.send_code`,
    defaultValue: 'Send SMS verification code'
  },
  resendCode: {
    key: `${PATH}.resend_code`,
    defaultValue: 'Resend verification code'
  },
  isVerified: {
    key: `${PATH}.is_verified`,
    defaultValue: 'Your mobile phone number is verified'
  },
  verified: {
    key: `${PATH}.verified`,
    defaultValue: 'Verified'
  },
  notVerified: {
    key: `${PATH}.not_verified`,
    defaultValue: 'Please verify your phone number to enable phone login'
  },
  fields: {
    phoneNumber: {
      key: `${PATH}.fields.phone_number`,
      defaultValue: 'Mobile Phone Number'
    }
  },
  errors: {
    phoneNumber: {
      required: {
        key: `${PATH}.errors.phone_number.required`,
        defaultValue: 'Phone number is required'
      },
      invalid: {
        key: `${PATH}.errors.phone_number.invalid`,
        defaultValue:
          'Not a valid phone number. Verify the country code and phone number.'
      }
    }
  },
  remove: {
    key: `${PATH}.remove`,
    defaultValue: 'Remove'
  },
  deletePhoneNumber: {
    title: {
      key: `${PATH}.delete_phone_number.title`,
      defaultValue: 'Remove phone number'
    },
    alertVerified: {
      key: `${PATH}.delete_phone_number.alert_verified`,
      defaultValue: 'Removing your phone number will disable Login by Phone.'
    },
    cancel: {
      key: `${PATH}.delete_phone_number.cancel`,
      defaultValue: 'Cancel'
    },
    deleteAction: {
      key: `${PATH}.delete_phone_number.delete_action`,
      defaultValue: 'Remove'
    },
    alertNotVerified: {
      key: `${PATH}.delete_phone_number.alert_not_verified`,
      defaultValue: 'Are you sure you want to remove your phone number?'
    }
  }
};

export default messages;
