import styled from 'styled-components';

const size = {
  mobileS: '320px',
  mobileL: '640px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px'
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktopL: `(min-width: ${size.laptopL})`
};

export const Wrapper = styled.div<{
  scrolled?: boolean;
  extended?: boolean;
}>`
  border-top-left-radius: ${p => (p.scrolled ? '0' : '12px')};
  border-top-right-radius: ${p => (p.scrolled ? '0' : '12px')};
  position: ${p => (p.extended ? 'relative' : 'sticky')};
  top: 0;
  z-index: 2;
`;

export const Col = styled.div<{ shiftLeft?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;

  @media ${device.tablet} {
    margin-top: 14px;
    margin-bottom: 14px;
  }
`;

export const ColLabel = styled.div``;

export const ColValue = styled.div`
  margin-left: 10px;

  @media ${device.tablet} {
    margin-left: 47px;
  }
`;

export const Container = styled.div<{
  attachToBottom?: boolean;
  scrolled?: boolean;
}>`
  display: flex;
  justify-content: space-around;
  padding: 0 16px;

  background-color: ${p =>
    p.scrolled ? 'rgba(239, 241, 243, 0.97)' : '#F9F9F9'};

  border-top-right-radius: ${p => (!p.scrolled ? '12px' : '0')};
  border-top-left-radius: ${p => (!p.scrolled ? '12px' : '0')};
  border-bottom-right-radius: ${p => (!p.scrolled ? '12px' : '0')};
  border-bottom-left-radius: ${p => (!p.scrolled ? '12px' : '0')};
`;

export const InfoTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${p => p.theme.colors['scaleDarkBlue']};
  white-space: nowrap;

  margin-top: 14px;
  margin-bottom: 14px;

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const InfoData = styled.div`
  font-size: 14px;
  color: ${p => p.theme.colors['scaleDarkBlue']};

  margin-top: 14px;
  margin-bottom: 14px;

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 28px;
  height: 28px;
  margin-top: 14px;

  & > a {
    i {
      width: 1em;
      height: 1em;
    }
  }
`;
