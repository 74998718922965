import i18n from 'helpers/i18n';
import messages from './messages';
import React, { useState } from 'react';
import { reopenTraining } from 'actions/trainings';
import { Modal, ModalProps, Button } from 'semantic-ui-react';
import { Errors } from './Styled';

interface ReopenModalProps extends ModalProps {
  /** The ID of the training. */
  trainingId: number;

  /** Callback fired when the component requests to be closed. */
  onClose: () => void;

  /** Callback fired when the training has been reopened. */
  afterSubmit: () => void;
}

const ReopenModal: React.FC<ReopenModalProps> = ({
  trainingId,
  afterSubmit,
  onClose,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState('');
  const [hasErrors, setHasErrors] = useState(false);

  function onModalClose() {
    setErrors('');
    setHasErrors(false);
    onClose();
  }

  function handleReopen() {
    setSubmitting(true);
    reopenTraining(trainingId)
      .then(() => {
        setSubmitting(false);
        props.onClose && props.onClose();
        afterSubmit();
      })
      .catch(({ response }) => {
        setSubmitting(false);
        setHasErrors(true);
        if (response?.data?.errors) {
          setErrors(response?.data?.errors['training'][0]);
        } else {
          setErrors(i18n.ft(messages.unknown));
        }
      });
  }

  return (
    <Modal {...props}>
      <Modal.Header>{i18n.ft(messages.reopenModal.header)}</Modal.Header>
      <Modal.Content>
        {i18n.ft(messages.reopenModal.content)}
        {errors && <Errors>{errors}</Errors>}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onModalClose} disabled={submitting}>
          {i18n.ft(messages.reopenModal.cancel)}
        </Button>
        <Button
          color="green"
          onClick={handleReopen}
          loading={submitting}
          disabled={hasErrors}
        >
          {i18n.ft(messages.reopenModal.reopen)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ReopenModal;
