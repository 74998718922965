import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { PaginationButton } from './PaginationButton';

interface PaginationProps {
  /** Total number of pages. */
  total: number;

  /** Current page number. */
  page: number;

  /** Callback fired when the page is changed. */
  onPageChange: (page: number) => void;

  /** Optional label for the pagination. */
  label?: string;
}

export function Pagination({
  total,
  page,
  onPageChange,
  label
}: PaginationProps) {
  const [goToPage, setGoToPage] = useState<number | ''>('');

  const handlePrev = () => onPageChange(page - 1);
  const handleNext = () => onPageChange(page + 1);
  const firstPage = 1;
  const lastPage = total;

  function calculatePages() {
    if (total <= 7) {
      return R.range(2, total);
    }

    if (page <= 4) {
      return R.range(2, 6);
    }

    if (page >= total - 3) {
      return R.range(total - 4, total);
    }

    return R.range(page - 1, page + 2);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') {
      return;
    }

    handleGoToPage();
  }

  function handleGoToPage() {
    if (typeof goToPage !== 'number') {
      setGoToPage('');
      return;
    }

    if (goToPage < 1 || goToPage > total) {
      setGoToPage('');
      return;
    }

    onPageChange(goToPage);
    setGoToPage('');
  }

  const pages = calculatePages();

  return (
    <div className="flex gap-1">
      {label && <span className="flex items-center mr-1">{label}</span>}

      <PaginationButton onClick={handlePrev} disabled={page <= 1}>
        <i className="fa-solid fa-chevron-left" />
      </PaginationButton>

      {total >= 1 && (
        <PaginationButton
          active={page === firstPage}
          onClick={() => onPageChange(firstPage)}
        >
          {firstPage}
        </PaginationButton>
      )}

      {total > 7 && page > 4 && (
        <PaginationButton>
          <i className="fa-solid fa-ellipsis" />
        </PaginationButton>
      )}

      {pages.map(pageNumber => (
        <PaginationButton
          key={pageNumber}
          active={page === pageNumber}
          onClick={() => onPageChange(pageNumber)}
        >
          {pageNumber}
        </PaginationButton>
      ))}

      {total > 7 && page < total - 3 && (
        <PaginationButton>
          <i className="fa-solid fa-ellipsis" />
        </PaginationButton>
      )}

      {total > 1 && (
        <PaginationButton
          active={page === lastPage}
          onClick={() => onPageChange(lastPage)}
        >
          {lastPage}
        </PaginationButton>
      )}

      <PaginationButton onClick={handleNext} disabled={page >= total}>
        <i className="fa-solid fa-chevron-right" />
      </PaginationButton>

      <div className="ml-3 flex gap-1 items-center">
        <span>{i18n.ft(messages.goToPage)}</span>
        <input
          value={goToPage}
          onBlur={handleGoToPage}
          onKeyDown={handleKeyDown}
          onChange={e => setGoToPage(Number(e.target.value) || '')}
          className="font-sans h-8 w-16 px-2 border border-[#889EBB] rounded"
        />
      </div>
    </div>
  );
}
