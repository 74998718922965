import i18n from 'helpers/i18n';
import messages from './messages';
import FormDropdown, { FormDropdownProps } from 'components/FormDropdown';

export function useUserJobs() {
  const options = [
    { key: 'teacher', value: 'teacher', text: i18n.ft(messages.teacher) },
    { key: 'observer', value: 'observer', text: i18n.ft(messages.observer) },
    {
      key: 'education_support',
      value: 'education_support',
      text: i18n.ft(messages.support)
    },
    { key: 'other', value: 'other', text: i18n.ft(messages.other) }
  ];

  return options;
}

export default function UserJobs(props: FormDropdownProps) {
  const options = useUserJobs();

  return <FormDropdown {...props} options={options} />;
}
