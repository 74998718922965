import React, { ReactNode } from 'react';
import { Icon } from 'semantic-ui-react';

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';

interface CollapsibleCardProps {
  /** Displayed on the top of the card */
  title?: string;
  children?: ReactNode;
  defaultOpen?: boolean;
  className?: string;
}

/**
 * A card that expands and collapses
 */
function CollapsibleCard({
  title,
  children,
  defaultOpen,
  className
}: CollapsibleCardProps) {
  return (
    <span className={`grid ${className}`}>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <DisclosureButton className="bg-white py-8 mt-4 gap-3 flex items-center justify-between">
              <span className="bg-gray-100 rounded-full py-2 px-4 font-bold text-left">
                {title}
              </span>
              <span className="bg-gray-100 rounded-full px-1 font-bold">
                {
                  <Icon
                    name={`${open ? 'caret up' : 'caret down'}`}
                    size="large"
                    className="text-gray-400"
                    fitted
                  />
                }
              </span>
            </DisclosureButton>
            <DisclosurePanel className="px-4 pt-2 pb-2 bg-white">
              {children}
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </span>
  );
}

export default CollapsibleCard;
