const PATH = 'fe.components.learning_resources.notes_section';

const messages = {
  noticed: {
    key: `${PATH}.noticed`,
    defaultValue: 'Did you Notice?'
  }
};

export default messages;
