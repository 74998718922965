const PATH = 'fe.pages.improvements.educator_profile.initial_observations';

const messages = {
  observationCompleted: {
    key: `${PATH}.observation_complete`,
    defaultValue: 'General Observation Completed'
  },
  viewObservationDetails: {
    key: `${PATH}.view_observation_details`,
    defaultValue: 'View Observation Details'
  },
  observation: {
    key: `${PATH}.observation`,
    defaultValue: 'General Observation'
  },
  observationSheet: {
    key: `${PATH}.observation_sheet`,
    defaultValue: 'General Observation Sheet'
  },
  observationDescription: {
    key: `${PATH}.observation_description`,
    defaultValue:
      'A General Observation can help you to learn a little more about the educator, the children, and the learning setting. Use this sheet to document key details from the observation. Make sure to note areas of strength and examples of effective interactions.'
  },
  generalObservationCues: {
    key: `${PATH}.general_observation_cues`,
    defaultValue: 'General Cues'
  },
  responseLabel: {
    key: `${PATH}.response_label`,
    defaultValue: 'Observation Notes'
  },
  completedAt: {
    key: `${PATH}.completed_at`,
    defaultValue: 'Completed At'
  },
  responsePlaceholder: {
    key: `${PATH}.response_placeholder`,
    defaultValue:
      'Activity/experience:\n' +
      '\n' +
      'Context (time of day, content area, format, number of children):\n' +
      '\n' +
      'Environment (materials, arrangements, displays):\n' +
      '\n' +
      'Educator’s actions and children’s responses:\n'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Submit'
  },
  edit: {
    key: `${PATH}.edit`,
    defaultValue: 'Edit'
  },
  responseFieldRequired: {
    key: `${PATH}.response_field_required`,
    defaultValue: 'Field Required'
  },
  cue1: {
    key: `${PATH}.cue_1`,
    defaultValue: 'The educator checks in with each child.'
  },
  cue2: {
    key: `${PATH}.cue_2`,
    defaultValue: 'Each child is able to access materials and experiences.'
  },
  cue3: {
    key: `${PATH}.cue_3`,
    defaultValue: 'The educator provides children with individualized support.'
  }
};
export default messages;
