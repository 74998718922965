const PATH =
  'fe.pages.testing.reliability.score_report.result_section.pass_attempt_message';

const messages = {
  certificationPage: {
    key: `${PATH}.certification_page`,
    defaultValue:
      'To view and manage all your certifications, including when to recertify and access to your observer cards, visit the <a href="{{url}}">Certifications</a> page.'
  },
  attemptTip: {
    key: `${PATH}.attempt_tip`,
    defaultValue: 'Attempt tip icon'
  }
};

export default messages;
