import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { login } from 'actions/users';
import useAsync from 'hooks/useAsync';
import Typography from 'components/Typography';
import { Button, Divider, Header } from 'semantic-ui-react';
import myTrainings from 'images/dashboard/my_trainings.svg';
import Metadata from 'components/Trainings/View/CardCheckout/OrderSummary/Metadata';
import { PaymentRequestUserToken } from 'types/api/UserToken';
import ContainerWithLogo from 'components/ContainerWithLogo';
import { createCartWithToken } from 'actions/tokens';
import LoginManager, { setLoginError } from 'components/LoginManager';
import { FormikHelpers } from 'formik';

import {
  MetaItem,
  LineBreak,
  Errors,
  Image,
  DividerContainer,
  LoginHeader
} from './Styled';

import {
  formatAgeLevels,
  formatSessions,
  formatTrainer,
  redirecToCheckout
} from './utils';

interface PaymentRequestProps {
  /** Payment request user token. */
  token: PaymentRequestUserToken;
}

const PaymentRequest: React.FC<PaymentRequestProps> = ({ token }) => {
  const { run: loginRun, ...loginStatus } = useAsync();
  const { run: cartRun, ...cartStatus } = useAsync();

  function handleLogin(
    values: any,
    formik: FormikHelpers<any>,
    isFormWithPassword: boolean
  ) {
    loginRun(login(values), {
      onSuccess: () => {
        redirecToCheckout(token.resource_id, token.payload);
      },
      onError: (error: any) => {
        formik.setSubmitting(false);
        setLoginError(error, formik, isFormWithPassword);
      }
    });
  }

  function handlePayAsGuest() {
    cartRun(createCartWithToken({ token: token.token }), {
      onSuccess: (data: any) => {
        window.location.assign(data.urls.checkout_url);
      }
    });
  }

  return (
    <React.Fragment>
      <ContainerWithLogo showLogo={false}>
        <Image alt={i18n.ft(messages.iconAlt)} src={myTrainings} />

        <Header size="huge" textAlign="center">
          {i18n.ft(messages.title)}
        </Header>

        <Typography bold display="block">
          {token.training.title}
        </Typography>

        <MetaItem>
          <Metadata
            label={i18n.ft(messages.ageLevels)}
            value={formatAgeLevels(token)}
          />
        </MetaItem>

        <MetaItem>
          <Metadata
            label={i18n.ft(messages.trainer)}
            value={formatTrainer(token)}
          />
        </MetaItem>

        <MetaItem>
          <Metadata
            label={i18n.ft(messages.sessions)}
            value={formatSessions(token)}
          />
        </MetaItem>

        <MetaItem>
          <Metadata
            label={i18n.ft(messages.quantity)}
            value={token.payload.length}
          />
        </MetaItem>

        <LineBreak />

        <Typography display="block">{i18n.ft(messages.seeTotal)}</Typography>
      </ContainerWithLogo>

      <ContainerWithLogo>
        <LoginManager
          onLogin={handleLogin}
          renderTop={step =>
            step === 'login' ? (
              <>
                {loginStatus.isError && (
                  <Errors>{i18n.ft(messages.loginError)}</Errors>
                )}
                <LoginHeader>{i18n.ft(messages.formTitle)}</LoginHeader>
              </>
            ) : null
          }
          renderBottom={step =>
            step === 'login' ? (
              <>
                <DividerContainer>
                  <Divider horizontal>{i18n.ft(messages.or)}</Divider>
                </DividerContainer>

                <Button
                  fluid
                  size="large"
                  color="blue"
                  onClick={handlePayAsGuest}
                  loading={cartStatus.isLoading || cartStatus.isSuccess}
                  disabled={cartStatus.isLoading || cartStatus.isSuccess}
                  content={i18n.ft(messages.payAsGuest)}
                />
              </>
            ) : null
          }
        />
      </ContainerWithLogo>
    </React.Fragment>
  );
};

export default PaymentRequest;
