const PATH = 'fe.components.users.login.phone_prompt';

const messages = {
  enterPhone: {
    key: `${PATH}.enter_phone_title`,
    defaultValue: 'Enter Phone Number'
  },
  enterPhoneSubtitle: {
    key: `${PATH}.enter_phone_subtitle`,
    defaultValue:
      "Enter your phone number to make logging in easier. We'll text you a code and you can log in without an email address or password."
  },
  enterCode: {
    key: `${PATH}.enter_code_title`,
    defaultValue: 'Enter Verification Code'
  },
  enterCodeSubtitle1: {
    key: `${PATH}.enter_code_subtitle_1`,
    defaultValue: 'We sent a verification code to {{phoneNumber}}.'
  },
  enterCodeSubtitle2: {
    key: `${PATH}.enter_code_subtitle_2`,
    defaultValue: 'Please enter it below.'
  },
  success: {
    key: `${PATH}.success_title`,
    defaultValue: 'Success'
  },
  successSubtitle1: {
    key: `${PATH}.success_subtitle_1`,
    defaultValue: '{{phoneNumber}} has been added to your profile.'
  },
  successSubtitle2: {
    key: `${PATH}.success_subtitle_2`,
    defaultValue: 'You can now login with this phone number.'
  },
  continue: {
    key: `${PATH}.continue`,
    defaultValue: 'Continue'
  },
  nextTime: {
    key: `${PATH}.next_time`,
    defaultValue: 'Do this next time'
  },
  dontAskAgain: {
    key: `${PATH}.dont_ask_again`,
    defaultValue: "Don't ask me again"
  },
  footer: {
    key: `${PATH}.footer`,
    defaultValue:
      'By enrolling you consent to receive automated text messages from Teachstone related to your account. Standard message and data rates may apply.'
  },
  learnMore: {
    key: `${PATH}.learn_more`,
    defaultValue: 'Learn More'
  }
};

export default messages;
