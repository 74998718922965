const PATH = 'fe.pages.certifications.my_certifications';

const messages = {
  useProductKey: {
    key: `${PATH}.use_product_key`,
    defaultValue: 'Use a product key'
  },
  enterProductKey: {
    key: `${PATH}.enter_product_key`,
    defaultValue: 'Enter your product key'
  },
  activate: {
    key: `${PATH}.activate`,
    defaultValue: 'Activate'
  },
  productKeySuccess: {
    key: `${PATH}.product_key_success`,
    defaultValue: 'Success! Your certification has been renewed.'
  },
  recertificationSuccess: {
    key: `${PATH}.recertification_success`,
    defaultValue: 'Success! Access your reliability test below.'
  },
  recertificationFail: {
    key: `${PATH}.recertification_fail`,
    defaultValue: 'Test creation failed. Please contact customer support.'
  },
  learnMore: {
    key: `${PATH}.learn_more`,
    defaultValue: 'Get Certified'
  },
  engageCommunity: {
    key: `${PATH}.engage_community`,
    defaultValue: 'Join the Community'
  }
};

export default messages;
