import { Wrapper, TitleWrapper, Title, Subtitle, LogoImg } from './Styled';

interface PageHeaderProps {
  /** Header Title. */
  title: string;

  /** Header Subtitle. */
  subtitle?: string;

  /** Image src URL. */
  image?: string;

  /** Image alt value. */
  imageAlt?: string;

  /** The header can include extra content to the right side. */
  contentRight?: React.ReactNode;
}

function PageHeader({
  title,
  subtitle,
  image,
  imageAlt = '',
  contentRight
}: PageHeaderProps) {
  return (
    <Wrapper>
      <TitleWrapper>
        {image ? <LogoImg src={image} alt={imageAlt} /> : null}
        <Title tabIndex={0}>{title}</Title>
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
      </TitleWrapper>
      {contentRight}
    </Wrapper>
  );
}

export default PageHeader;
