import { createGlobalStyle } from 'styled-components';

export const CustomPrintStyle = createGlobalStyle`
  @media print {
    body {
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }

    @page {
      size: landscape;
      margin-top: 5mm!important;
      margin-bottom: 5mm!important;
    }
  }
`;
