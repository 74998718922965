import i18n from 'helpers/i18n';
import messages from './messages';
import { useFormikContext, useField } from 'formik';
import { useState, KeyboardEvent, useEffect, useCallback, useRef } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { searchAccountsAutocomplete } from 'actions/accounts';
import { getUserAccounts } from 'actions/users';
import { Training } from 'types/api/Training';
import SelectWithTag from 'components/EnhancedForm/SelectWithTag';

type Account = {
  id: number;
  name: string;
};

interface EditTraining extends Training {
  account_id: number;
}

interface SearchAccountProps {
  /** Select input name. */
  name: string;

  /** Select input label. */
  label: string;

  /** Initial account if there's an existing selection. */
  initialAccount?: Account;

  /** Selected eligible trainer ID. */
  trainerId?: number | null;

  /** Selected trainer type ID. */
  selectedTrainerTypeId?: number | null;
}

function getInitialResults(account?: Account): DropdownItemProps[] {
  if (!account) {
    return [];
  }

  return [{ key: account.id, value: account.id, text: account.name }];
}

function SearchAccount({
  name,
  label,
  initialAccount,
  trainerId,
  selectedTrainerTypeId
}: SearchAccountProps) {
  const [, , { setValue: setAccountId }] = useField(name);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(getInitialResults(initialAccount));
  const [accountName, setAccountName] = useState(initialAccount?.name);
  const isFirstRender = useRef(true);
  const { values, setFieldValue } = useFormikContext<EditTraining>();

  const resetNodeId = useCallback(
    () => setFieldValue('node_id', null),
    [setFieldValue]
  );

  useEffect(() => {
    function getSuggestedTrainerAccount() {
      const EMPLOYEE_TYPE_ID = 2;

      if (values.id && isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      if (trainerId && selectedTrainerTypeId !== EMPLOYEE_TYPE_ID) {
        getUserAccounts(trainerId).then(response => {
          if (response.data.length > 0) {
            const suggestedTrainerAccount = response.data[0];

            setAccountName(suggestedTrainerAccount.account_name);
            setAccountId(suggestedTrainerAccount.account_id);
            resetNodeId();
          }
        });
      } else if (selectedTrainerTypeId === 2) {
        setAccountId(null);
        resetNodeId();
        setAccountName(undefined);
      }
    }

    getSuggestedTrainerAccount();
  }, [trainerId, values.id, setAccountId, resetNodeId, selectedTrainerTypeId]);

  function handleSearchChange(_event: any, data: any) {
    if (data.searchQuery.length >= 3) {
      setLoading(true);
      searchAutocomplete(data.searchQuery);
    } else {
      setLoading(false);
    }
  }

  async function searchAutocomplete(query: string) {
    const { data } = await searchAccountsAutocomplete(query);
    setLoading(false);

    const accounts = data.accounts.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.name
    }));

    setResults(accounts);
  }

  function handleClear() {
    setAccountId(null);
    resetNodeId();
    setAccountName(undefined);
    setLoading(false);
  }

  function handleChange(value: any) {
    const account = results.find(account => account.value === value);
    setAccountName(account?.text as string);
    resetNodeId();
  }

  function onKeyPressed(event: KeyboardEvent<HTMLImageElement>) {
    if (event.key === 'Escape') {
      handleClear();

      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  }

  return (
    <SelectWithTag
      name={name}
      label={label}
      placeholder={i18n.ft(messages.search)}
      options={results}
      loading={loading}
      tagName={accountName}
      tagInside
      onChange={handleChange}
      cleanOption={handleClear}
      onSearchChange={handleSearchChange}
      minCharacters={3}
      selectOnNavigation={false}
      onKeyDown={onKeyPressed}
    />
  );
}

export default SearchAccount;
