const PATH = 'fe.components.reliability_test.confirmation_modal';

const messages = {
  body: {
    reviewMessage: {
      key: `${PATH}.review_message`,
      defaultValue: `Please review your codes before continuing.`
    },
    wontChangeMessage: {
      key: `${PATH}.wont_change_message`,
      defaultValue: `You will not be able to change your codes after submitting`
    }
  }
};

export default messages;
