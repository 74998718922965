const PATH = 'fe.components.login_manager.login_with_code.send_code_form';

const messages = {
  fields: {
    phoneNumber: {
      key: `${PATH}.fields.phone_number`,
      defaultValue: 'Phone Number'
    }
  },
  textMe: {
    key: `${PATH}.text_me`,
    defaultValue: 'Text Me A Code'
  },
  usePassword: {
    key: `${PATH}.use_password`,
    defaultValue: 'I’d Rather Just Type My Password'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back to Login Options'
  },
  errors: {
    phone: {
      required: {
        key: `${PATH}.errors.phone.required`,
        defaultValue: 'Enter a valid phone number'
      }
    }
  }
};

export default messages;
