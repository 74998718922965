import { Outlet } from 'react-router-dom';
import { Background, PageContainer } from './Styled';
import LanguageSelector from 'components/LanguageSelector';

function Tokens() {
  return (
    <Background>
      <PageContainer>
        <LanguageSelector />
        <Outlet />
      </PageContainer>
    </Background>
  );
}

export default Tokens;
