import { useFormikContext } from 'formik';
import { Form as SemanticForm, FormProps } from 'semantic-ui-react';

function Form(props: FormProps) {
  const context = useFormikContext();

  if (!context) {
    throw new Error('<Form /> must be used within <Formik />');
  }

  const { handleReset, handleSubmit } = context;

  return (
    <SemanticForm onReset={handleReset} onSubmit={handleSubmit} {...props} />
  );
}

export default Form;
