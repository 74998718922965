import styled from 'styled-components';

export const PageWrapper = styled.div`
  margin-top: 32px;
  padding-left: 12px;
  padding-right: 12px;

  @media screen and (min-width: 640px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 72px;
    padding-right: 72px;
  }
`;

export const FooterWrapper = styled.div`
  background-color: ${p => p.theme.colors['white']};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-top: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 640px) {
    padding: 24px;
  }

  @media screen and (min-width: 1280px) {
    padding: 24px 48px;
  }
`;

export const ReturnWrapper = styled.div`
  order: 1;
  margin-top: 16px;

  @media screen and (min-width: 768px) {
    order: 0;
    margin: 0;
  }
`;

export const ErrorWrapper = styled.div`
  color: ${p => p.theme.colors['error']['500']};
  text-align: center;
  order: 2;
  margin-top: 16px;

  @media screen and (min-width: 768px) {
    order: 1;
    text-align: right;
  }
`;

export const ReviewWrapper = styled.div`
  display: grid;
  grid-gap: 16px 0;

  @media screen and (min-width: 768px) {
    grid-template-columns: 180px 180px;
    grid-gap: 0 16px;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: 200px 200px;
    grid-gap: 0 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
