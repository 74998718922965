import styled from 'styled-components';

export const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${p => p.theme.colors['primary']['700']};
`;

export const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${p => p.theme.colors['neutral']['200']};
  }

  @media screen and (min-width: 640px) and (max-width: 767px),
    (min-width: 896px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ItemTitle = styled.div`
  color: ${p => p.theme.colors['neutral']['900']};
  font-size: 16px;
  font-weight: 700;

  @media screen and (min-width: 640px) and (max-width: 767px),
    (min-width: 896px) {
    margin-right: 24px;
  }
`;

export const ItemSubtitle = styled.div`
  font-size: 16px;
  color: ${p => p.theme.colors['neutral']['500']};
`;

export const ItemMessage = styled.div`
  font-size: 16px;
  text-align: center;
  color: ${p => p.theme.colors['neutral']['900']};
  background: ${p => p.theme.colors['neutral']['100']};
  padding: 0.5em 2em;
  border-radius: 4px;
`;

export const ItemAction = styled.div`
  width: 100%;
  margin-top: 16px;

  @media screen and (min-width: 640px) and (max-width: 767px),
    (min-width: 896px) {
    margin-top: 0;
    flex-shrink: 0;
    max-width: 205px;
  }
`;
