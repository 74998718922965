// In order to expose additional environment variables as a setting to the
// client, add the variable to the ALLOWED_ENV_VARS list in:
// app/controllers/settings_controller.rb.

const globalRef =
  (typeof globalThis === 'object' && globalThis) ||
  // In IE, globalThis is (probably) not defined; fail through to window.
  (typeof window === 'object' && window) ||
  // Things have already gone very wrong. In this case, SettingSource will be
  // process.env.
  {};

type Setting = string | undefined;

interface Settings {
  [key: string]: Setting;
}

const settingsSource: Settings =
  ('tsolSettings' in globalRef &&
    typeof globalRef.tsolSettings === 'object' &&
    (globalRef.tsolSettings as Settings)) ||
  process.env;

function lookup(setting: string): Setting {
  try {
    return settingsSource[setting];
  } catch (_) {
    console.warn(`Unable to load value for setting: ${setting}`);
  }
}

const SettingsProvider = { lookup };
export default SettingsProvider;
