import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import VideoProgress, { BarProgress } from './VideoProgressBar';
import { VideoPlayerContainer } from './Styled';
import { useRouteParams } from 'hooks/useRouteParams';
import { logVideoPlayStarted } from 'actions/testAttempts';
import { getConfiguration, getDomId } from 'pages/Testing/Reliability/utils';
import { AspectRatio } from 'components/KalturaVideo/Styled';

interface CodingVideosProps {
  coding_videos: {
    dimensions: Dimension[];
    id: number;
    video_host_id: string;
  }[];
  currentVideoId: number;
}

type Dimension = {
  id: number;
  name: string;
  user_answer: number;
};

type Video = {
  dimensions: Dimension[];
  id: number;
  video_host_id: string;
};

type ParamsType = {
  id: string;
};

export default function CodingVideos(props: CodingVideosProps) {
  const { coding_videos, currentVideoId } = props;
  const { id: testAttemptId } = useRouteParams<ParamsType>();
  const currentVideoIndex = coding_videos.findIndex(
    (video: Video) => video.id === currentVideoId
  );

  const currentVideo = coding_videos[currentVideoIndex];
  const isTablet = useMediaQuery({ minWidth: 640 });

  const kalturaPlayerID = getDomId(`${currentVideoId}`);

  /* set up the video player */
  useEffect(() => {
    if (!currentVideo) {
      return;
    }

    /* configure a video player */
    const kalturaPlayer = (window as any).KalturaPlayer?.setup(
      getConfiguration(kalturaPlayerID)
    );

    /* load the video */
    kalturaPlayer.loadMedia({ entryId: currentVideo.video_host_id });

    /* listen for the first play event and log it */
    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.FIRST_PLAYING,
      (e: any) => {
        logVideoPlayStarted({
          testAttemptId: parseInt(testAttemptId),
          codingVideoId: currentVideo.id
        });
      }
    );

    /* clean up */
    return () => {
      const kalturaPlayer = (window as any).KalturaPlayer?.getPlayer(
        kalturaPlayerID
      );
      kalturaPlayer?.destroy();
    };
  }, [currentVideo, kalturaPlayerID, testAttemptId]);

  return (
    <>
      <div className="mx-auto mb-5">
        <div className="flex">
          {coding_videos.map((video: Video, idx: number) => {
            return (
              <div key={`video_${video.id}`} className="contents">
                <VideoProgress id={idx} currentVideoIndex={currentVideoIndex} />

                <BarProgress idx={idx} currentVideoIndex={currentVideoIndex} />
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex justify-center">
        <AspectRatio />
        <VideoPlayerContainer id={kalturaPlayerID} isTablet={isTablet} />
      </div>
    </>
  );
}
