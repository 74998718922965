import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Popup } from 'semantic-ui-react';
import { useActiveSubscriptions } from 'context/auth';
import { isUserCertifiedObserver, hasDataEntryRole } from './utils';
import { getMyCertifications } from 'actions/certifications';
import { getAccountAssessmentTemplates } from 'actions/envScales/accountAssessmentTemplates';
import { AccountAssessmentTemplate } from 'types/api/envScales/AccountAssessmentTemplate';
import { SelectedAccount } from 'types/api/Account';

function buttonWithoutDropdown(disabled: boolean, link = '') {
  return (
    <Popup
      content={i18n.ft(messages.selectAccount)}
      disabled={!disabled}
      trigger={
        <div>
          <Button disabled={disabled} fluid as={Link} to={link} color="green">
            {i18n.ft(messages.createObservation)}
          </Button>
        </div>
      }
    />
  );
}

interface CreateButtonProps {
  account?: SelectedAccount;
}

function CreateButton({ account }: CreateButtonProps) {
  const isButtonDisabled = account === undefined;
  const subscriptions = useActiveSubscriptions();
  const accountGuid = isButtonDisabled ? '' : account.guid;

  const { run, data, ...certificationsReq } = useAsync();

  const {
    run: templatesRun,
    data: templatesData,
    ...templatesReq
  } = useAsync();

  useEffect(() => {
    if (accountGuid !== '') {
      templatesRun(getAccountAssessmentTemplates(accountGuid));
    }
  }, [accountGuid, templatesRun]);

  useEffect(() => {
    run(getMyCertifications());
  }, [run]);

  const hasNoObserverCertifications = (): boolean => {
    return !isUserCertifiedObserver(data.user_certifications);
  };

  if (isButtonDisabled) {
    return buttonWithoutDropdown(isButtonDisabled);
  }

  if (!certificationsReq.isSuccess || !templatesReq.isSuccess) {
    return null;
  }

  if (
    hasNoObserverCertifications() &&
    !hasDataEntryRole(subscriptions, account.id)
  ) {
    return null;
  }

  const nonEnvironmentTemplates =
    templatesData.account_assessment_templates.filter(
      (aat: AccountAssessmentTemplate) => !aat.assessment_template.env_scales
    );

  if (R.isEmpty(nonEnvironmentTemplates)) {
    return null;
  }

  if (nonEnvironmentTemplates.length === 1) {
    const template = nonEnvironmentTemplates[0];
    const buttonLink = RouteHelpers.getPath('measure-create-template', {
      template_id: template.assessment_template.id
    });
    return buttonWithoutDropdown(isButtonDisabled, buttonLink);
  }

  return (
    <Popup
      content={i18n.ft(messages.selectAccount)}
      disabled={!isButtonDisabled}
      trigger={
        <div>
          <Dropdown
            disabled={isButtonDisabled}
            fluid
            button
            floating
            className="green"
            title="assessment-templates"
            text={i18n.ft(messages.createObservation)}
          >
            <Dropdown.Menu>
              {nonEnvironmentTemplates.map((aat: AccountAssessmentTemplate) => {
                const itemLink = RouteHelpers.getPath(
                  'measure-create-template',
                  {
                    template_id: aat.assessment_template.id
                  }
                );

                return (
                  <Dropdown.Item key={aat.id} as={Link} to={itemLink}>
                    {aat.assessment_template.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      }
    />
  );
}

export default CreateButton;
