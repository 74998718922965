import React, { useState, useCallback } from 'react';
import Layout from './Layout';
import ResponsiveContent from 'components/ResponsiveContent';
import Countdown from 'components/Trainings/View/Countdown';
import SummaryBanner from 'components/Trainings/View/SummaryBanner';
import Navigation from 'components/Trainings/View/Navigation';
import { TrainingProvider } from 'components/Trainings/View/TrainingContext';
import { useTraining } from 'components/Trainings/View/utils';
import { Outlet } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import { Header, Loader } from 'semantic-ui-react';
import Location from 'components/Trainings/View/Location';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Permission from 'components/Subscription/Permission';
import InvitationBanner from 'components/Trainings/View/InvitationBanner';
import InvitationStatus from 'components/Trainings/View/InvitationStatus';

const TrainingLocation = styled(Location)`
  margin-top: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

function View() {
  const { id } = useRouteParams();
  const [training, loading, reloadTraining] = useTraining(id);
  const currentUser = useSelector(state => state.user);
  const [status, setStatus] = useState();

  const handleStatusChange = useCallback(newStatus => {
    setStatus(newStatus);
  }, []);

  if (loading) {
    return <Loader active inline="centered" />;
  }

  const userIsTrainer = training.trainer.id === parseInt(currentUser.id);

  return (
    <TrainingProvider
      value={{ training, template: training.training_template, reloadTraining }}
    >
      <Layout>
        <HeaderContainer>
          <Header
            as="h1"
            size="huge"
            content={training.training_template.title}
            subheader={training.training_template.short_description}
          />

          {status && <InvitationStatus status={status} />}
        </HeaderContainer>

        <InvitationBanner
          trainingId={training.id}
          onChange={handleStatusChange}
        />

        <SummaryBanner />

        <Layout.Body>
          <Layout.Sidebar>
            <Permission
              zone="trainer_dashboard"
              resource="trainings"
              or={userIsTrainer}
            >
              <Navigation />
            </Permission>
            <ResponsiveContent
              renderDesktop={
                <React.Fragment>
                  <Countdown />
                  <TrainingLocation />
                </React.Fragment>
              }
            />
          </Layout.Sidebar>

          <Layout.Main>
            <Outlet />
          </Layout.Main>
        </Layout.Body>
      </Layout>
    </TrainingProvider>
  );
}

export default View;
