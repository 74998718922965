import i18n from 'helpers/i18n';
import messages from './messages';
import { Assessment } from 'types/api/envScales/Assessment';
import {
  Wrapper,
  CycleWrapper,
  CircleDescription,
  AddCircleButton,
  WrapperSizer,
  CurrentCircleButton,
  CircleButton,
  FlexWrapper
} from './Styled';
import RouteHelpers from 'helpers/routes';
import { Icon } from 'semantic-ui-react';
import { createCycle } from 'actions/envScales/observationCycles';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useRouteParams } from 'hooks/useRouteParams';
import { CyclesErrors } from 'types/api/envScales/CyclesErrors';
import { useCyclesErrors } from 'context/CyclesErrorsContext';
import * as R from 'ramda';

function cycleHasErrors(cyclesErrors: CyclesErrors, cycleNumber: number) {
  if (R.isEmpty(cyclesErrors)) {
    return false;
  }
  return cyclesErrors[cycleNumber]!.cycle_has_errors;
}

type ParamsType = {
  id: string;
  cycleId: string;
};

interface CycleControlsProps {
  /** CLASS Assessment. */
  assessment: Assessment;

  /** screen has been scrolled? */
  scrolled?: boolean;
}

function CycleControls({ assessment, scrolled }: CycleControlsProps) {
  const navigate = useNavigate();
  const observation = assessment!.observation;
  const cycles = observation?.observation_cycles || [];
  const [loading, setLoading] = useState(false);
  const { cycleId: currentCycleId } = useRouteParams<ParamsType>();
  const { cyclesErrors } = useCyclesErrors();

  async function handleAddCycle() {
    setLoading(true);

    const { data } = await createCycle(observation!.id);

    const urlParams = { id: assessment.id, cycleId: data.id };
    window.location.href = RouteHelpers.getPath(
      'measure-view-cycle',
      urlParams
    );
  }

  function handleGoToCycle(cycleId: number) {
    const urlParams = { id: assessment.id, cycleId };
    navigate(RouteHelpers.getPath('measure-view-cycle', urlParams));
  }

  return (
    <Wrapper scrolled={scrolled}>
      <FlexWrapper direction="column">
        <FlexWrapper direction="row">
          <WrapperSizer cyclesCount={cycles.length}>
            {currentCycleId &&
              cycles.map((cyc, index) => (
                <CycleWrapper scrolled={scrolled} key={cyc.id}>
                  {Number(currentCycleId) === cyc.id && cycles.length > 1 ? (
                    <>
                      <CurrentCircleButton>
                        {cyc.cycle_number}
                      </CurrentCircleButton>
                      <CircleDescription>
                        {i18n.ft(messages.currentCycleLabel)}
                      </CircleDescription>
                    </>
                  ) : (
                    <>
                      <CircleButton
                        unique={cycles.length === 1 ? 1 : 0}
                        $hasErrors={
                          cycleHasErrors(cyclesErrors, cyc.cycle_number) &&
                          cycles.length > 1
                            ? 1
                            : 0
                        }
                        onClick={() => handleGoToCycle(cyc.id)}
                        aria-label={i18n.ft(messages.goToCycleAria, {
                          cycle: cyc.cycle_number
                        })}
                      >
                        {cyc.cycle_number}
                      </CircleButton>
                      {cycles.length === 1 && (
                        <CircleDescription>
                          {i18n.ft(messages.currentCycleLabel)}
                        </CircleDescription>
                      )}
                    </>
                  )}
                </CycleWrapper>
              ))}

            {cycles.length < 6 && (
              <CycleWrapper scrolled={scrolled}>
                <AddCircleButton
                  onClick={handleAddCycle}
                  loading={loading}
                  aria-label={i18n.ft(messages.addCycleLabel)}
                >
                  {!loading && <Icon name="plus" />}
                </AddCircleButton>
                <CircleDescription>
                  {i18n.ft(messages.addCycleLabel)}
                </CircleDescription>
              </CycleWrapper>
            )}
          </WrapperSizer>
        </FlexWrapper>
      </FlexWrapper>
    </Wrapper>
  );
}

export default CycleControls;
