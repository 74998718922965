const PATH = 'fe.pages.trainings';

const messages = {
  trainings: {
    key: `${PATH}.trainings`,
    defaultValue: 'Trainings'
  },
  selectTraining: {
    key: `${PATH}.select_training`,
    defaultValue: 'Select a training below to find additional information.'
  },
  createTraining: {
    key: `${PATH}.create_training`,
    defaultValue: 'Create new training'
  },
  search: {
    key: `${PATH}.search`,
    defaultValue: 'Search Trainings'
  },
  searchBy: {
    key: `${PATH}.search_by`,
    defaultValue: 'Search by title, city, trainer name or email'
  },
  startDate: {
    key: `${PATH}.start_date`,
    defaultValue: 'Start Date'
  },
  endDate: {
    key: `${PATH}.end_date`,
    defaultValue: 'End Date'
  },
  anyDate: {
    key: `${PATH}.any_date`,
    defaultValue: 'Any date'
  }
};

export default messages;
