import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import { Loader } from 'semantic-ui-react';
import { getAssessmentScores } from 'actions/envScales/assessments';
import { getDomainColor, formatScoreData } from './utils';
import { DomainTitle, Totals, Wrapper } from './Styled';
import { SectionHeader } from '../Styled';
import { Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import RouteHelpers from 'helpers/routes';
import { AssessmentScore } from 'types/api/envScales/AssessmentScore';
import { Assessment } from 'types/api/envScales/Assessment';
import Group from './Group';

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';

interface ScoreSummaryProps {
  /** Assessment ID. */
  assessmentId: number;

  /** Assessment data. */
  infoData: Assessment;

  /** If `true`, a button for editing the assessment will be displayed. */
  isEditable: boolean;
}

function ScoreSummary({
  assessmentId,
  infoData,
  isEditable
}: ScoreSummaryProps) {
  const { run, data: scores, isPending } = useAsync<AssessmentScore[]>();

  useEffect(() => {
    run(getAssessmentScores(assessmentId));
  }, [run, assessmentId]);

  if (isPending) {
    return <Loader active inline="centered" />;
  }

  const data = formatScoreData(scores);

  return (
    <div className="mt-2 pb-8 rounded-t-xl bg-white px-5 md:px-14">
      <div className="flex justify-between py-11 items-center">
        <SectionHeader>{i18n.ft(messages.title)}</SectionHeader>
        {infoData && (
          <Totals>
            {i18n.ft(messages.scoreTotals, {
              score: infoData?.total_score,
              total: infoData?.max_total_score_possible
            })}
          </Totals>
        )}
      </div>

      <Wrapper>
        {
          <Disclosure
            as="section"
            defaultOpen={!infoData.includes_environments}
          >
            {({ open }) => {
              return (
                <>
                  {infoData.includes_environments && (
                    <DisclosureButton className="w-full">
                      {!open && (
                        <div className="flex items-center">
                          <span className="text-xl">
                            <Icon fitted color="blue" name="plus circle" />
                          </span>
                          <span className="ml-2">
                            {i18n.ft(messages.clickToExpand)}
                          </span>
                        </div>
                      )}
                      {open && (
                        <div className="flex items-center mb-4">
                          <span className="text-xl">
                            <Icon fitted color="blue" name="minus circle" />
                          </span>
                          <span className="ml-2">
                            {i18n.ft(messages.seeLessDetails)}
                          </span>
                        </div>
                      )}
                    </DisclosureButton>
                  )}

                  <DisclosurePanel>
                    <div>
                      {data.map(domain => {
                        const showNotApplicableGroup = Object.values(
                          domain.groups
                        ).some(group => {
                          return group.some(item => !item.required);
                        });

                        return (
                          <div
                            className="[&:not(:last-child)]:mb-8"
                            key={domain.key}
                          >
                            <DomainTitle
                              background={getDomainColor(domain.key)}
                            >
                              {domain.domainName}
                            </DomainTitle>
                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-2">
                              <Group
                                groupType="many"
                                items={domain.groups.many}
                                domainKey={domain.key}
                              />

                              <Group
                                groupType="some"
                                items={domain.groups.some}
                                domainKey={domain.key}
                              />

                              <Group
                                groupType="none"
                                items={domain.groups.none}
                                domainKey={domain.key}
                              />

                              {showNotApplicableGroup && (
                                <Group
                                  groupType="not_applicable"
                                  items={domain.groups.not_applicable}
                                  domainKey={domain.key}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}

                      {isEditable && (
                        <div className="flex justify-end items-center">
                          <span className="mr-3">
                            {i18n.ft(messages.editScore)}
                          </span>
                          <Button
                            icon
                            color="blue"
                            size="mini"
                            as={Link}
                            to={RouteHelpers.getPath('measure-view', {
                              id: infoData.id
                            })}
                          >
                            <Icon name="pencil" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </DisclosurePanel>
                </>
              );
            }}
          </Disclosure>
        }
      </Wrapper>
    </div>
  );
}

export default ScoreSummary;
