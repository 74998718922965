import styled from 'styled-components';
import Typography from 'components/Typography';
import { Icon } from 'semantic-ui-react';

type FieldParams = { spacing: string; maxWidth: string };

export const Field: any = styled.div<FieldParams>`
  min-width: fit-content;
  margin-top: 4px;
  display: flex;
  align-items: center;
  flex: ${props => (props.spacing === 'normal' ? null : '1 1 auto')};
  margin-right: ${props => (props.spacing === 'normal' ? null : '8px')};

  @media screen and (max-width: 768px) {
    max-width: ${props => props.maxWidth};
  }
`;

Field.Icon = styled(Icon)`
  min-width: 2.2em;
  margin-right: 4px;
  flex-shrink: 0;

  &.circular.blue {
    border-color: ${props => props.theme.colors.blue};
  }
`;

Field.Text = styled(Typography)``;

Field.Group = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;
