import { getService, SERVICE_KEYS, getServiceURL } from 'constants/services';
import {
  Wrapper,
  TitleArea,
  IconArea,
  Icon,
  ServiceImage,
  ServiceImageContainer,
  ServiceName
} from './Styled';
import { ReactNode } from 'react';

interface DashboardTileProps {
  /**Boolean for show or not the tile */
  showTile: boolean;

  /**Service keys constant for this tile */
  services: SERVICE_KEYS[];

  /**All user services availables */
  existingServices: SERVICE_KEYS[];

  /**User preferred language */
  preferredLanguage: string;

  /**Tile icon */
  icon: string;

  /**Tile title */
  title: string;

  /**Boolean whether is a right tile */
  isRightTile?: boolean;

  /**extra content in the tile */
  content?: ReactNode;
}

function DashboardTile({
  showTile,
  services,
  existingServices,
  preferredLanguage,
  icon,
  title,
  isRightTile = true,
  content
}: DashboardTileProps) {
  if (!showTile) {
    return null;
  }

  function includeOnlyExistingServices() {
    let userServicesIncluded: SERVICE_KEYS[] = [];
    for (let serv of existingServices) {
      if (services.includes(serv)) {
        userServicesIncluded.push(serv);
      }
    }
    return userServicesIncluded;
  }

  return (
    <Wrapper isRightTile={isRightTile}>
      <TitleArea>
        <Icon src={icon} alt={title} />
        {title}
      </TitleArea>
      <IconArea>
        {includeOnlyExistingServices().map((service: SERVICE_KEYS, index) => {
          const serviceInfo = getService(service);
          const url = getServiceURL(service, preferredLanguage);
          return (
            <ServiceImageContainer href={url} key={index}>
              <ServiceImage src={serviceInfo.icon} alt="" />
              <ServiceName>{serviceInfo.name}</ServiceName>
            </ServiceImageContainer>
          );
        })}
      </IconArea>
      {content}
    </Wrapper>
  );
}

export default DashboardTile;
