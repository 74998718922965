import styled from 'styled-components';

export const TextWrapper = styled.div`
  padding: 0 16px;
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${p => p.theme.colors['neutral']['700']};
`;

export const SuccessWrapper = styled.div`
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const TextButton = styled.button`
  color: ${p => p.theme.colors['primary']['700']};
  background-color: transparent;
  font-family: inherit;
  font-weight: 700;
  cursor: pointer;
  border: none;
  padding: 0;
  font-size: 16px;
  margin-top: 16px;

  &:hover {
    text-decoration: underline;
  }
`;

export const TermsWrapper = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${p => p.theme.colors['neutral']['500']};
  margin-top: 24px;
`;
