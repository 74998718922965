import styled from 'styled-components';

export const FormRow = styled.div`
  display: grid;
  grid-gap: 32px;
  align-items: start;

  @media screen and (min-width: 640px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const FormColumn = styled.div`
  display: grid;
  grid-gap: 50px;
  align-items: start;
  margin-top: 50px;

  @media screen and (min-width: 640px) {
    grid-template-columns: 1fr 1fr;
    padding-right: 50px;
  }

  @media screen and (min-width: 768px) {
    padding-right: 100px;
  }

  @media screen and (min-width: 1024px) {
    padding-right: 300px;
  }
`;

export const CategoriesGrid = styled.div`
  display: grid;
  grid-gap: 24px;

  @media screen and (min-width: 1024px) {
    grid-gap: 0;
    grid-template-columns: 1fr minmax(64px, 128px) 1fr;
  }
`;

export const VerticalSeparator = styled.div`
  width: 10px;
  border-radius: 10px;
  background-color: #f4f8fa;
  margin: 0 auto;
`;
