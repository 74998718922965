import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 20px;
  background-color: ${p => p.theme.colors.white};
  box-shadow: 1px 1px 11px #e9ecf2;
  border-radius: 20px;
  margin-bottom: 24px;

  @media screen and (min-width: 1024px) {
    max-width: 496px;
  }
`;

export const ManageIcon = styled.img`
  position: relative;
  top: 4px;
`;

export const Title = styled.h2`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  font-size: 24px;
  line-height: 32px;
`;

export const Certifications = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 0.5fr;
  padding: 0 16px;
`;

export const CertTitle = styled.a`
  background-color: #f4f8fa;
  width: fit-content;
  padding: 4px 12px;
  border-radius: 16px;
  font-weight: bold;
  color: inherit;
  position: relative;
  left: -12px;
  height: fit-content;

  &:hover {
    color: ${p => p.theme.colors.blue};
  }
`;

export const Expiration = styled.div`
  padding: 3px;
  font-weight: bold;
`;

export const RowName = styled.a`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Divider = styled.div`
  height: 1px;
  width: auto;
  background-color: ${p => p.theme.colors['neutral']['200']};
  margin: 4px 0;
`;

export const Trainings = styled.div`
  margin-top: 30px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 0.5fr;
  padding: 0 16px;
`;

export const Timezone = styled.div`
  color: #737059;
`;

export const NoTrainings = styled.div`
  color: #737059;
  font-style: italic;
`;

export const SeeMore = styled.div`
  padding: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  padding-right: 16px;
`;
