import i18n from 'helpers/i18n';
import messages from './messages';
import { Section } from '../Styled';
import { Icon } from 'semantic-ui-react';
import React, { useContext } from 'react';
import { addIndex, isEmpty, map } from 'ramda';
import TimeZone from 'components/TimeZone';
import Typography from 'components/Typography';
import TrainingContext from '../TrainingContext';
import { formatSession } from './utils';
import { Session } from 'types/api/Session';
import { SessionCard, SessionList } from './Styled';

const Sessions: React.FC = () => {
  const { training } = useContext(TrainingContext);
  const sessions: Session[] = training.sessions;

  if (isEmpty(sessions)) {
    return null;
  }

  return (
    <Section>
      <Typography bold size="large" color="blue" display="block">
        <Icon name="calendar" />
        {i18n.ft(messages.sessionDates)}
      </Typography>

      <TimeZone />

      <SessionList>
        {addIndex<Session>(map)((session, idx) => {
          return (
            <SessionCard key={session.id}>
              <Typography bold size="large">
                {i18n.ft(messages.sessionNumber, { number: idx + 1 })}
              </Typography>
              <Typography display="block">{formatSession(session)}</Typography>
            </SessionCard>
          );
        }, sessions)}
      </SessionList>
    </Section>
  );
};

export default Sessions;
