import { Icon } from 'semantic-ui-react';
import {
  TrainingDetailContainer,
  TrainingHeader,
  Title,
  Subtitle,
  PillsContainer,
  Pill,
  PillContent
} from './Styled';
import { Training } from 'types/api/Training';
import { AgeLevelGroup, AgeLevelTag } from 'components/AgeLevel';
import { getAgeLevel, getGroupBaseColor } from 'helpers/ageLevel';
import i18n from 'helpers/i18n';
import messages from './messages';
import TrainingDetails from '../TrainingDetails';

import {
  formatDate,
  formatLocation,
  formatParticipants
} from 'components/Trainings/TrainingField/utils';

interface TrainingTileProps {
  training: Training;
}

function TrainingTile({ training }: TrainingTileProps) {
  return (
    <TrainingDetailContainer
      color={getGroupBaseColor(training.age_levels)}
      tabIndex={0}
    >
      <TrainingHeader>
        <div>
          <Title aria-label={training.training_template.title}>
            {training.training_template.title}
          </Title>
          <Subtitle aria-label={training.training_template.short_description}>
            {training.training_template.short_description}
          </Subtitle>
        </div>
        <AgeLevelGroup>
          {training.age_levels.map(ageLevel => {
            const ageLevelName = getAgeLevel(ageLevel.abbreviation).name;

            return (
              <AgeLevelTag
                key={ageLevel.abbreviation}
                abbreviation={ageLevel.abbreviation}
              >
                {ageLevelName}
              </AgeLevelTag>
            );
          })}
        </AgeLevelGroup>
      </TrainingHeader>
      <PillsContainer>
        <Pill>
          <Icon name="calendar alternate outline" color="black" />
          <PillContent>{formatDate(training)}</PillContent>
        </Pill>
        <Pill>
          <Icon name="map marker alternate" color="black" />
          <PillContent>{formatLocation(training)}</PillContent>
        </Pill>
        <Pill>
          <PillContent>
            <Icon name="group" color="black" />
            {formatParticipants(training)}
          </PillContent>
        </Pill>
        <Pill>
          <Icon name="user" color="black" />
          <PillContent>
            {i18n.ft(messages.trainer)} {training.trainer.name}
          </PillContent>
        </Pill>
      </PillsContainer>
      <TrainingDetails training={training} />
    </TrainingDetailContainer>
  );
}

export default TrainingTile;
