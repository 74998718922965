const PATH = 'fe.pages.account';

const messages = {
  documentTitle: {
    key: `${PATH}.document_title`,
    defaultValue: 'Teachstone Account'
  },
  changePhoto: {
    key: `${PATH}.change_profile_photo`,
    defaultValue: 'Change Profile Photo'
  },
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Account Settings'
  },
  profile: {
    key: `${PATH}.profile`,
    defaultValue: 'Profile Information'
  },
  mobileLogin: {
    key: `${PATH}.mobile_login`,
    defaultValue: 'Mobile Phone Login'
  },
  changePassword: {
    key: `${PATH}.change_password`,
    defaultValue: 'Change Password'
  },
  experience: {
    key: `${PATH}.experience_title`,
    defaultValue: 'Experience'
  }
};

export default messages;
