const PATH = 'fe.pages.improvements.educator_profile.focus';

const messages = {
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: 'Focus'
  },
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Check in, and choose a Focus for this Coaching Cycle'
  },
  description1: {
    key: `${PATH}.description1`,
    defaultValue:
      'Check in: Take some time to catch up since the last time you’ve spoken together. Talk together about your week, a recent challenge, or a recent success.'
  },
  description2: {
    key: `${PATH}.description2`,
    defaultValue:
      'Choose a Focus: Start the Coaching Cycle by answering questions that help you choose a Focus. After choosing a Focus, you’ll discuss Strategies and create an Action Plan.'
  },
  cta: {
    key: `${PATH}.cta`,
    defaultValue: 'Start'
  },
  selectAFocusBy: {
    key: `${PATH}.select_a_focus_by`,
    defaultValue: 'Choose a focus by:'
  },
  discussEducatorAndChildrenExperience: {
    key: `${PATH}.discuss_educator_and_children_experience`,
    defaultValue: "Talking about the educator's and children's experiences"
  },
  exploringPotentialPrioritiesAndGoals: {
    key: `${PATH}.exploring_potential_priorities_and_goals`,
    defaultValue: 'Exploring possible priorities and focus options'
  },
  currentAreaOfFocus: {
    key: `${PATH}.current_area_of_focus`,
    defaultValue: 'Current Area:'
  },
  choosingACategory: {
    key: `${PATH}.choosing_a_category`,
    defaultValue: 'Choosing a Focus - Part 1 of 3'
  },
  additionalNotes: {
    key: `${PATH}.additional_notes`,
    defaultValue: 'Discussion Notes'
  },
  choosingAPriority: {
    key: `${PATH}.choosing_a_priority`,
    defaultValue: 'Choosing a Focus - Part 2 of 3'
  },
  choosingAFocus: {
    key: `${PATH}.choosing_a_focus`,
    defaultValue: 'Choosing a Focus - Part 3 of 3'
  },
  exploreOptions: {
    key: `${PATH}.explore_options`,
    defaultValue:
      'Based on our discussion, the children, and the learning setting, which of these options should we explore?'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Submit'
  },
  edit: {
    key: `${PATH}.edit`,
    defaultValue: 'Edit'
  },
  errors: {
    fieldRequired: {
      key: `${PATH}.errors.field_required`,
      defaultValue: 'Field Required'
    }
  },
  viewFocusDetails: {
    key: `${PATH}.view_focus_details`,
    defaultValue: 'View Focus Details'
  },
  coachingCycleFocus: {
    key: `${PATH}.coaching_cycle_focus`,
    defaultValue: 'Coaching Cycle Focus'
  }
};
export default messages;
