import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;

  &:focus-within {
    outline: 2px solid blue;
    outline-offset: 2px;
  }
`;

export const NativeCheckbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;
