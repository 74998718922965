import { map, keys, concat, forEach, replace, toUpper, slice } from 'ramda';

function addKeyToErrors(key: string, errors: string[]): string[] {
  let fKey = replace('_', ' ', key);
  fKey = toUpper(fKey.charAt(0)) + slice(1, fKey.length, fKey);

  return map(error => {
    return `${fKey} ${error}`;
  }, errors);
}

export function parseAPIErrors(errors = {}): string[] {
  let errorList: string[] = [];

  forEach(key => {
    if (Array.isArray(errors[key])) {
      errorList = concat(errorList, addKeyToErrors(key, errors[key]));
    } else {
      errorList = concat(errorList, parseAPIErrors(errors[key]));
    }
  }, keys(errors));

  return errorList;
}

export function parseAPIErrorsNoKeys(errors = {}): string[] {
  let errorList: string[] = [];

  forEach(key => {
    if (Array.isArray(errors[key])) {
      errorList = concat(errorList, errors[key]);
    } else {
      errorList = concat(errorList, parseAPIErrorsNoKeys(errors[key]));
    }
  }, keys(errors));

  return errorList;
}
