import React from 'react';
import { IconProps } from 'semantic-ui-react';
import { Container, AvatarContainer, Initials, Image, Icon } from './Styled';

interface AvatarProps {
  /** Primary content. */
  content: string;

  /** The `src` attribute for the `img` element. */
  src?: string;

  /** The size of the avatar. */
  size?: 'small' | 'medium' | 'large' | 'xLarge';

  /** An avatar can have an icon in the bottom right corner. */
  icon?: IconProps | null;
}

const variants = {
  small: {
    diameter: '28px',
    fontSize: '14px'
  },
  medium: {
    diameter: '40px',
    fontSize: '14px'
  },
  large: {
    diameter: '64px',
    fontSize: '18px'
  },
  xLarge: {
    diameter: '128px',
    fontSize: '36px'
  }
};

const Avatar: React.FC<AvatarProps> = ({
  src,
  icon,
  content,
  size = 'medium'
}) => {
  const props = variants[size];

  return (
    <Container {...props}>
      <AvatarContainer {...props}>
        {src ? (
          <Image src={src} {...props} />
        ) : (
          <Initials {...props}>{content}</Initials>
        )}
      </AvatarContainer>
      {icon && <Icon {...icon} />}
    </Container>
  );
};

export default Avatar;
