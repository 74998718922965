import * as Yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';

export default Yup.object({
  password: Yup.string()
    .min(6, i18n.ft(messages.errors.password.minChars))
    .required(i18n.ft(messages.errors.password.required)),
  password_confirmation: Yup.string()
    .required(i18n.ft(messages.errors.confirmPassword.required))
    .oneOf(
      [Yup.ref('password')],
      i18n.ft(messages.errors.confirmPassword.mustMatch)
    )
});
