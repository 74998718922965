import moment from 'moment';
import { last, sort } from 'ramda';
import { CoreTest } from 'types/api/core/Test';
import { KnowledgeTestAttempt, TestAttempt } from 'types/api/UserTest';
import { getAgeLevel } from 'helpers/ageLevel';

function isEvaluated(test: { status: string }) {
  const { status } = test;
  return /(passed|failed)/i.test(status);
}

export function getLastAttemptNumber(test: CoreTest) {
  // Sorts attemps by attempt number. If there happen to be multiple
  // attemps with the same number (due to bad data), will sort by the attempt id.
  const sortedAttempts = sort((attemptA, attemptB) => {
    if (attemptA.attempt < attemptB.attempt) {
      return -1;
    } else if (attemptA.attempt > attemptB.attempt) {
      return 1;
    } else {
      return attemptA.id - attemptB.id;
    }
  }, test.attempts);

  return last(sortedAttempts)?.attempt || 1;
}

export function getCompletedCoreAttempts(test: CoreTest) {
  return test.attempts.filter(isEvaluated);
}

export function getCompletedAttempts(attempts: TestAttempt[]) {
  return attempts.filter(isEvaluated);
}

export function expirationTime(expiresAt: moment.MomentInput) {
  const finishBy = moment(expiresAt);

  if (!finishBy.isValid()) {
    return;
  }

  const endOfDay = finishBy.endOf('day');
  const time = moment(expiresAt).format('HH:mm');
  const timeZone = endOfDay.format('ZZ') === '-0400' ? 'EDT' : 'EST';
  return `${time} ${timeZone}`;
}

export function formatAgeLevel(ageLevel: string, isReliability = true) {
  if (ageLevel === 'K-3' && !isReliability) {
    return getAgeLevel('PK3').name;
  } else {
    return ageLevel;
  }
}

export function getKnowledgeCompletedAttempts(
  attempts: KnowledgeTestAttempt[]
) {
  return attempts.filter(isEvaluated);
}
