import { FieldMetaProps, FieldValidator } from 'formik';

export type BaseFieldProps<ComponentProps> = ComponentProps & {
  /** Name for the input control. */
  name: string;

  /** Input control value. */
  value?: any;

  /** Input control label. */
  label?: string | React.ReactNode;

  /** Validate a single field value independently. */
  validate?: FieldValidator;

  /** Custom class name. */
  className?: string;

  /** If `true`, the input control will be hidden. */
  hidden?: boolean;
};

export function shouldDisplayError(meta: FieldMetaProps<any>) {
  return meta.touched && Boolean(meta.error);
}

type PropsWithHidden = {
  type?: string;
  hidden?: boolean;
};

export function shouldHideInput(props: PropsWithHidden) {
  return props.hidden || Boolean(props.type && props.type === 'hidden');
}
