import i18n from 'helpers/i18n';
import messages from './messages';
import { useField } from 'formik';
import ErrorLabel from './ErrorLabel';
import styled from 'styled-components';
import Typography from 'components/Typography';
import { FormRowWrapper, FormInputWithLabel } from './Styled';
import { shouldDisplayError, shouldHideInput, BaseFieldProps } from './utils';

import {
  TextArea as BaseTextArea,
  TextAreaProps as BaseProps
} from 'semantic-ui-react';

const StyledTypography = styled(Typography)`
  max-width: 350px;
`;

const StyledTextArea = styled(BaseTextArea)`
  min-height: 90px;
`;

function TextAreaAsParagraph({ value }: { value: string }) {
  return <StyledTypography>{value}</StyledTypography>;
}

interface TextAreaProps extends BaseFieldProps<BaseProps> {
  /** If `true`, the input control will be disabled. */
  disabled?: boolean;
}

function TextArea({ name, validate, className, ...props }: TextAreaProps) {
  const [field, meta] = useField({ name, validate });

  const isError = shouldDisplayError(meta);
  const isHidden = shouldHideInput(props);
  const defaultValue = props.disabled ? i18n.ft(messages.notProvided) : '';

  return (
    <FormRowWrapper className={className} hidden={isHidden}>
      <FormInputWithLabel
        {...field}
        {...props}
        value={field.value || defaultValue}
        error={isError}
        control={props.disabled ? TextAreaAsParagraph : StyledTextArea}
        aria-label={props['aria-label'] || props.label}
      />
      <ErrorLabel active={isError} content={meta.error} />
    </FormRowWrapper>
  );
}

export default TextArea;
