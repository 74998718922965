const PATH = 'fe.components.reports.observation.hierarchy_dropdown';

const messages = {
  search: {
    key: `${PATH}.search`,
    defaultValue: 'Search for an option'
  }
};

export default messages;
