import { useField } from 'formik';
import ErrorLabel from './ErrorLabel';
import { FormRowWrapper, FormInputWithLabel } from './Styled';
import { shouldDisplayError, shouldHideInput, BaseFieldProps } from './utils';

import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseProps
} from 'semantic-ui-react';

interface CheckboxProps extends BaseFieldProps<BaseProps> {
  /** Callback fired when the value changes. */
  onChange?: (value: any) => void;
}

function Checkbox({
  name,
  validate,
  className,
  onChange,
  error = {},
  ...props
}: CheckboxProps) {
  const [, meta, helpers] = useField({ name, validate });
  const isError = shouldDisplayError(meta);
  const isHidden = shouldHideInput(props);

  const handleChange: BaseProps['onChange'] = (_event, data) => {
    const newValue = props.radio ? data.value : data.checked;
    helpers.setValue(newValue);
    onChange?.(newValue);
  };

  return (
    <FormRowWrapper className={className} hidden={isHidden}>
      <FormInputWithLabel
        {...props}
        type={props.radio ? 'radio' : 'checkbox'}
        checked={props.radio ? props.value === meta.value : meta.value}
        onChange={handleChange}
        error={isError}
        aria-label={props['aria-label'] || props.label}
      />
      <ErrorLabel active={isError} content={meta.error} {...error} />
    </FormRowWrapper>
  );
}

Checkbox.defaultProps = {
  inline: true,
  control: BaseCheckbox
};

export default Checkbox;
