import { RELIABILITY_TEST_UICONF } from 'components/ReliabilityTest/utils';
import { KALTURA_PARTNER_ID } from 'constants/videos';

export function getConfiguration(id: string) {
  return {
    targetId: id,
    provider: {
      partnerId: KALTURA_PARTNER_ID,
      uiConfId: RELIABILITY_TEST_UICONF
    },
    playback: {
      autoplay: false
    }
  };
}

export function getDomId(id: string) {
  return `kalturaPlayer_${id}`;
}

export function passesTwoOfFiveRule(list: unknown[]) {
  return list.filter(Boolean).length > 1;
}
