type StepStatus = 'pending' | 'active' | 'completed';

export default function useStepStatus(
  step: number,
  activeStep: number
): StepStatus {
  if (step === activeStep) {
    return 'active';
  } else if (step < activeStep) {
    return 'completed';
  } else {
    return 'pending';
  }
}
