import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { Box } from 'components/Box';
import { Icon } from 'semantic-ui-react';
import { useMeasure } from 'context/measure';
import TextButton from 'components/TextButton';
import { Wrapper, Title, Label, Grid, ButtonSpan } from './Styled';

interface ClassroomExampleProps {
  /** Classroom Example title. */
  title: string;

  /** Classroom Example icon. */
  icon: React.ReactNode;

  /** Selected dimension ID. */
  dimensionId: number;

  /** Selected indicator ID. */
  indicatorId: number;

  /** Suggested dimension ID. */
  suggestedDimensionId: number;

  /** Suggested indicator ID. */
  suggestedIndicatorId: number;

  /** Notes for Classroom Example. */
  notes: string;
}

function ClassroomExample({
  title,
  icon,
  notes,
  dimensionId,
  indicatorId,
  suggestedDimensionId,
  suggestedIndicatorId
}: ClassroomExampleProps) {
  const { ageLevel } = useMeasure();
  const isSuggestedDimension = dimensionId === suggestedDimensionId;
  const isSuggestedIndicator = indicatorId === suggestedIndicatorId;
  const dimensions = ageLevel.domains.flatMap(domain => domain.dimensions);
  const indicators = dimensions.flatMap(dimension => dimension.indicators);
  const dimension = dimensions.find(dimension => dimension.id === dimensionId)!;
  const indicator = indicators.find(indicator => indicator.id === indicatorId)!;

  const maxTextLength = 500;
  const isTextTruncated = notes && notes.length > maxTextLength;
  const [isTextExpanded, setTextExpanded] = useState(false);

  return (
    <Wrapper>
      <Title>
        {title}
        <Box as="span" ml="16px">
          {icon}
        </Box>
      </Title>

      <Grid>
        <div>
          <Label>{i18n.ft(messages.dimension)}</Label>
          <div>
            {dimension?.name}
            {isSuggestedDimension && (
              <>
                &nbsp;&nbsp;
                <Icon size="small" name="star" color="yellow" />
              </>
            )}
          </div>
        </div>
        <div>
          <Label>{i18n.ft(messages.indicator)}</Label>
          <div>
            {indicator?.name}
            {isSuggestedIndicator && (
              <>
                &nbsp;&nbsp;
                <Icon size="small" name="star" color="yellow" />
              </>
            )}
          </div>
        </div>
      </Grid>

      <Box mt="32px" style={{ whiteSpace: 'pre-line' }}>
        {isTextExpanded ? notes : notes?.substring(0, maxTextLength)}
        {isTextTruncated && !isTextExpanded ? '...' : null}
      </Box>

      {isTextTruncated && (
        <Box mt="16px">
          <TextButton
            color="gray"
            onClick={() => setTextExpanded(!isTextExpanded)}
          >
            <ButtonSpan>
              {isTextExpanded
                ? i18n.ft(messages.readLess)
                : i18n.ft(messages.readMore)}
            </ButtonSpan>
          </TextButton>
        </Box>
      )}
    </Wrapper>
  );
}

export default ClassroomExample;
