import * as R from 'ramda';
import clsx from 'clsx';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Icon } from 'semantic-ui-react';
import { Fragment, useState } from 'react';
import { getBackgroundColor } from './utils';
import { OptionalDate } from 'components/Reports/Observation/DateRangePicker';

import { Node, ScoreAverage } from 'actions/envScales/reports';
import { useGetReportAveragesByNode } from 'pages/Reports/Observation/utils';

interface AveragesByNodeProps {
  assessmentTemplateId: number;
  nodeIds: number[];
  ageLevel: string;
  startDate: OptionalDate;
  endDate: OptionalDate;
  target: 'domain' | 'dimension';
  isFetchEnabled: boolean;
  isAllClassrooms: boolean;
}

interface AveragesByNodeTableProps {
  /** API data. */
  props: AveragesByNodeProps;

  /** Determines if we should use domains or dimensions. */
  target: 'domain' | 'dimension';
}

const classes = {
  score: 'text-center border-2',
  header: 'py-8 border-2 border-b-4',
  nodeName: 'px-4 py-6 border-2',
  childName: 'pl-8 pr-4 py-6 border-2'
};

function sortNodesByScore(
  nodes: Node[],
  sortBy: string | null,
  sortDir: string | null,
  target: 'domain' | 'dimension'
) {
  if (!sortBy || !sortDir) {
    return nodes;
  }

  const findByDom = (score: ScoreAverage) =>
    score.domain.abbreviation === sortBy;
  const findByDim = (score: ScoreAverage) =>
    score.dimension!.abbreviation === sortBy;

  return R.sort((n1, n2) => {
    const cat1 = n1.scores.find(target === 'domain' ? findByDom : findByDim);
    const cat2 = n2.scores.find(target === 'domain' ? findByDom : findByDim);
    const value1 = cat1?.value ?? 0;
    const value2 = cat2?.value ?? 0;

    if (sortDir === 'asc') {
      return value1 - value2;
    } else if (sortDir === 'desc') {
      return value2 - value1;
    } else {
      return 0;
    }
  }, nodes);
}

function AveragesByNodeTable({ props, target }: AveragesByNodeTableProps) {
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<string | null>(null);
  const tableReq = useGetReportAveragesByNode(props);

  const headers = tableReq.data?.nodes[0]?.scores ?? [];
  const nodes = sortNodesByScore(
    tableReq.data?.nodes ?? [],
    sortBy,
    sortDir,
    target
  );

  function sortColumn(column: string) {
    if (sortBy !== column) {
      setSortBy(column);
      setSortDir('asc');
    } else if (sortDir === 'asc') {
      setSortBy(column);
      setSortDir('desc');
    } else {
      setSortBy(null);
      setSortDir(null);
    }
  }

  function renderScores(node: Omit<Node, 'children'>) {
    return node.scores.map((score, idx) => {
      const background = getBackgroundColor(score.domain.abbreviation);

      return (
        <td key={idx} className={clsx(classes.score, background)}>
          {score.value.toFixed(2)}
        </td>
      );
    });
  }

  return (
    <div className="mt-20 pl-8 pr-4">
      <table className="w-full ">
        <thead>
          <tr>
            <th className={clsx(classes.header, 'rounded-tl-2xl')}>
              {i18n.ft(messages.average)}
            </th>

            {headers.map((header, idx) => {
              const abbv =
                target === 'domain'
                  ? header.domain.abbreviation
                  : header.dimension!.abbreviation;

              return (
                <th
                  key={idx}
                  onClick={() => sortColumn(abbv)}
                  tabIndex={0}
                  className={clsx(classes.header, 'w-32 hover:cursor-pointer', {
                    'rounded-tr-2xl': idx === headers.length - 1
                  })}
                >
                  <span>{abbv.toUpperCase()}</span>
                  <div className="inline-block">
                    {sortBy === abbv && sortDir === 'asc' ? (
                      <Icon color="grey" name="sort up" />
                    ) : null}
                    {sortBy === abbv && sortDir === 'desc' ? (
                      <Icon color="grey" name="sort down" />
                    ) : null}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {nodes.map(node => {
            const sortedChildren = sortNodesByScore(
              node.children,
              sortBy,
              sortDir,
              target
            );

            return (
              <Fragment key={node.id}>
                <tr className="font-bold">
                  <td className={classes.nodeName}>{node.name}</td>
                  {renderScores(node)}
                </tr>

                {sortedChildren.map(child => (
                  <tr key={child.id}>
                    <td className={classes.childName}>
                      {child.name}
                      {child.archived && (
                        <span className="italic">
                          {` (${i18n.ft(messages.archived)})`}
                        </span>
                      )}
                    </td>
                    {renderScores(child)}
                  </tr>
                ))}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AveragesByNodeTable;
