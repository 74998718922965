const PATH = 'fe.components.trainings.training_field';

const messages = {
  participants: {
    key: `${PATH}.participants`,
    defaultValue: {
      one: '{{count}} participant',
      other: '{{count}} participants'
    }
  },
  trainer: {
    key: `${PATH}.trainer`,
    defaultValue: 'Trainer'
  },
  dateTBD: {
    key: `${PATH}.date_tbd`,
    defaultValue: 'Date TBD'
  },
  locationTBD: {
    key: `${PATH}.location_tbd`,
    defaultValue: 'Location TBD'
  }
};

export default messages;
