import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import PageLayout from 'components/PageLayout';
import { updateTraining } from 'actions/trainings';
import { Header, Loader } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import { useTraining } from 'components/Trainings/View/utils';
import TrainingForm from 'components/TrainingCatalog/Create/TrainingForm';

function Edit() {
  const params = useRouteParams();
  const navigate = useNavigate();
  const [training, loading] = useTraining(params.id);

  function handleSubmit(data, setSubmitting) {
    updateTraining(training.id, data).then(() => {
      setSubmitting(false);
      navigate(
        RouteHelpers.getPath('td-trainings-view-details', { id: training.id }),
        { replace: true }
      );
    });
  }

  function handleCancel() {
    navigate(
      RouteHelpers.getPath('td-trainings-view-details', { id: training.id })
    );
  }

  if (loading) {
    return <Loader active size="large" inline="centered" />;
  }

  return (
    <PageLayout>
      <Header as="h1" size="huge">
        {i18n.ft(messages.header)}
      </Header>
      <TrainingForm
        edit
        training={training}
        template={training.training_template}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        submitLabel={i18n.ft(messages.submitLabel)}
      />
    </PageLayout>
  );
}

export default Edit;
