import { isNil } from 'ramda';
import { useField } from 'formik';
import ErrorLabel from './ErrorLabel';
import { FormRowWrapper, FormInputWithLabel } from './Styled';
import { shouldDisplayError, shouldHideInput, BaseFieldProps } from './utils';
import useCurrentUser from 'hooks/useCurrentUser';

import {
  TimeInput as BaseTimeInput,
  TimeInputProps as BaseProps
} from 'semantic-ui-calendar-react';

interface TimeInputProps extends BaseFieldProps<Partial<BaseProps>> {}

function TimeInput({ name, validate, className, ...props }: TimeInputProps) {
  const [field, meta, helpers] = useField({ name, validate });
  const isError = shouldDisplayError(meta);
  const isHidden = shouldHideInput(props);
  const defaultValue = field.value || '';
  const currentUser = useCurrentUser();

  const handleChange: BaseProps['onChange'] = (_event, data) => {
    helpers.setValue(data.value);
  };

  const handleBlur: BaseProps['onBlur'] = () => {
    helpers.setTouched(true);
  };

  return (
    <FormRowWrapper className={className} hidden={isHidden}>
      <FormInputWithLabel
        {...field}
        {...props}
        value={defaultValue}
        error={isError}
        onChange={handleChange}
        onBlur={handleBlur}
        aria-label={props['aria-label'] || props.label}
        localization={
          currentUser && !isNil(currentUser.preferred_language)
            ? currentUser.preferred_language
            : 'en'
        }
      />
      <ErrorLabel active={isError} content={meta.error} />
    </FormRowWrapper>
  );
}

TimeInput.defaultProps = {
  control: BaseTimeInput,
  closeOnMouseLeave: false,
  hideMobileKeyboard: true,
  timeFormat: 'AMPM',
  iconPosition: 'left',
  animation: 'none',
  duration: 0,
  closable: true,
  pickerStyle: { minWidth: '320px', maxWidth: '400px' }
};

export default TimeInput;
