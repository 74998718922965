import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import { ListButton } from './ListButton';
import { Container } from './Container';
import { PieChart, Pie } from 'recharts';
import useAsync from 'hooks/useAsync';
import PageLoader from 'components/PageLoader';
import { getObserverCounts } from 'actions/certificationDashboard';
import { ClassVersionTag } from 'components/Certifications/ClassVersionTag';

type ColorMap = { [key: string]: { bg: string; tailwindBg: string } };

const colors: ColorMap = {
  all: { bg: '#3C3F42', tailwindBg: 'bg-[#3C3F42]' },
  I: { bg: '#06874C', tailwindBg: 'bg-[#06874C]' },
  T: { bg: '#6AB794', tailwindBg: 'bg-[#6AB794]' },
  IT: { bg: '#06874C', tailwindBg: 'bg-[#06874C]' },
  PK: { bg: '#607EA5', tailwindBg: 'bg-[#607EA5]' },
  'Pre-K / K-3': { bg: '#607EA5', tailwindBg: 'bg-[#607EA5]' },
  PKK: { bg: '#607EA5', tailwindBg: 'bg-[#607EA5]' },
  PKK3: { bg: '#385E8E', tailwindBg: 'bg-[#385E8E]' },
  'PK-3rd': { bg: '#385E8E', tailwindBg: 'bg-[#385E8E]' },
  K3: { bg: '#889EBB', tailwindBg: 'bg-[#889EBB]' },
  UE: { bg: '#E36628', tailwindBg: 'bg-[#E36628]' },
  Sec: { bg: '#E98553', tailwindBg: 'bg-[#E98553]' }
};

type AgeLevelCount = {
  id: number;
  name: string;
  code: string;
  count: number;
  alt_name: string;
  class_version: '2008' | '2nd Edition';
};

type GetObserverCountsRes = {
  age_level_groups: AgeLevelCount[];
  total: number;
};

const RADIAN = Math.PI / 180;
function renderCustomizedLabel({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  addOpacity,
  value
}: any) {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const fillColor = addOpacity ? '#00000040' : '#000000';

  return (
    <text
      x={x}
      y={y}
      fill={fillColor}
      fontSize={12}
      fontWeight={700}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {value}
    </text>
  );
}

interface CertificationsByAgeLevelProps {
  ageLevelId: number | null;
  classVersion: string | null;
  onChange: (ageLevelId: number | null, classVersion: string | null) => void;
  account: number;
}

export function CertificationsByAgeLevel({
  ageLevelId,
  classVersion,
  onChange,
  account
}: CertificationsByAgeLevelProps) {
  const { run, isPending, data: response } = useAsync<GetObserverCountsRes>();
  const isAgeLevelSelected = Boolean(ageLevelId);

  useEffect(() => {
    run(getObserverCounts());
  }, [run, account]);

  const observerCounts = response?.age_level_groups ?? [];
  const countForList = R.sum(observerCounts.map(item => item.count));
  const countForGraph = R.sum(
    observerCounts.map(item =>
      isAgeLevelSelected
        ? ageLevelId === item.id
          ? item.count
          : 0
        : item.count
    )
  );

  const data = observerCounts.map(item => {
    let fillWithOpacity = colors[item.code]
      ? colors[item.code].bg
      : colors.all.bg;

    const addOpacity =
      isAgeLevelSelected &&
      (ageLevelId !== item.id || classVersion !== item.class_version);

    if (addOpacity) {
      // Adds 0.4 opacity to the color when item is not selected.
      fillWithOpacity = fillWithOpacity + '40';
    }

    return {
      ...item,
      addOpacity,
      fill: fillWithOpacity,
      twFill: colors[item.code]
        ? colors[item.code].tailwindBg
        : colors.all.tailwindBg
    };
  });

  if (isPending) {
    return <PageLoader />;
  }

  return (
    <Container title={i18n.ft(messages.total)}>
      {response.total <= 0 ? (
        <span>{i18n.ft(messages.noDataFound)}</span>
      ) : (
        <div className="flex flex-col sm:gap-9 sm:flex-row">
          <div className="relative mx-auto sm:mx-0">
            <PieChart width={250} height={250}>
              <Pie
                isAnimationActive={false}
                data={data}
                dataKey="count"
                cx="50%"
                cy="50%"
                innerRadius={62}
                outerRadius={90}
                fill="#000000"
                label={renderCustomizedLabel}
                labelLine={false}
                startAngle={90}
                endAngle={-270}
                opacity={40}
              />
            </PieChart>

            <div className="inline-flex flex-col items-center justify-center absolute w-[250px] h-[250px] top-0 left-0">
              <div className="font-bold text-4xl">{countForGraph}</div>
              <div className="font-bold text-xs">
                {i18n.ft(messages.observers)}
              </div>
            </div>
          </div>

          <div className="sm:mt-8 sm:w-64 flex flex-col gap-1">
            <ListButton
              color={colors.all.tailwindBg}
              count={countForList}
              onClick={() => onChange(null, null)}
              active={!isAgeLevelSelected}
              addOpacity={isAgeLevelSelected}
            >
              {i18n.ft(messages.allLevels)}
            </ListButton>

            {data.map(item => {
              return (
                <ListButton
                  key={`${item.id}-${item.class_version}`}
                  color={item.twFill}
                  count={item.count}
                  onClick={() => onChange(item.id, item.class_version)}
                  active={
                    ageLevelId === item.id &&
                    classVersion === item.class_version
                  }
                  addOpacity={
                    isAgeLevelSelected &&
                    (ageLevelId !== item.id ||
                      classVersion !== item.class_version)
                  }
                >
                  <>
                    <span>{item.alt_name ? item.alt_name : item.name}</span>
                    <span className="ml-auto">
                      <ClassVersionTag classVersion={item.class_version} />
                    </span>
                  </>
                </ListButton>
              );
            })}
          </div>
        </div>
      )}
    </Container>
  );
}
