import * as R from 'ramda';

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
};

type CSSBreakpoints = keyof typeof breakpoints;

type CSSResponsiveValue = {
  /** Initial value. */
  _: string;
} & {
  [key in CSSBreakpoints]?: string;
};

type CSSValue = string | CSSResponsiveValue;

function generateStyles(name: any, value: any) {
  if (typeof value === 'string') {
    return { [name]: value };
  }

  const styles: any = { [name]: value._ };

  R.forEachObjIndexed((breakpoint, key) => {
    if (value.hasOwnProperty(key)) {
      styles[`@media screen and (min-width: ${breakpoint}px)`] = {
        [name]: value[key]
      };
    }
  }, breakpoints);

  return styles;
}

function composeStyles(config: any, props: any) {
  let styles: any = {};

  R.forEachObjIndexed((name, key) => {
    if (props.hasOwnProperty(key)) {
      const value = props[key];

      if (Array.isArray(name)) {
        name.forEach(n => {
          styles = R.mergeDeepRight(styles, generateStyles(n, value));
        });
      } else {
        styles = R.mergeDeepRight(styles, generateStyles(name, value));
      }
    }
  }, config);

  return styles;
}

export interface SpaceProps {
  /** Margin. */
  m?: CSSValue;

  /** Margin left and right. */
  mx?: CSSValue;

  /** Vertical top and bottom. */
  my?: CSSValue;

  /** Margin top. */
  mt?: CSSValue;

  /** Margin bottom. */
  mb?: CSSValue;

  /** Margin right. */
  mr?: CSSValue;

  /** Margin left. */
  ml?: CSSValue;

  /** Padding. */
  p?: CSSValue;

  /** Padding left and right. */
  px?: CSSValue;

  /** Padding top and bottom. */
  py?: CSSValue;

  /** Padding top. */
  pt?: CSSValue;

  /** Padding bottom. */
  pb?: CSSValue;

  /** Padding right. */
  pr?: CSSValue;

  /** Padding left. */
  pl?: CSSValue;
}

export function addSpace(props: SpaceProps) {
  const config = {
    m: 'margin',
    mt: 'marginTop',
    mb: 'marginBottom',
    ml: 'marginLeft',
    mr: 'marginRight',
    my: ['marginTop', 'marginBottom'],
    mx: ['marginLeft', 'marginRight'],
    p: 'padding',
    pt: 'paddingTop',
    pb: 'paddingBottom',
    pl: 'paddingLeft',
    pr: 'paddingRight',
    py: ['paddingTop', 'paddingBottom'],
    px: ['paddingLeft', 'paddingRight']
  };

  return composeStyles(config, props);
}

export interface BorderProps {
  /** Border radius. */
  borderRadius?: CSSValue;

  /** Border top left and right radius. */
  borderTopRadius?: CSSValue;

  /** Border bottom left and right radius. */
  borderBottomRadius?: CSSValue;

  /** Border top left radius. */
  borderTopLeftRadius?: CSSValue;

  /** Border top right radius. */
  borderTopRightRadius?: CSSValue;

  /** Border bottom left radius. */
  borderBottomLeftRadius?: CSSValue;

  /** Border bottom right radius. */
  borderBottomRightRadius?: CSSValue;
}

export function addBorderRadius(props: BorderProps) {
  const config = {
    borderRadius: 'borderRadius',
    borderTopRadius: ['borderTopLeftRadius', 'borderTopRightRadius'],
    borderBottomRadius: ['borderBottomLeftRadius', 'borderBottomRightRadius'],
    borBottomderRadius: 'borderBottomRadius',
    borderTopLeftRadius: 'borderTopLeftRadius',
    borderTopRightRadius: 'borderTopRightRadius',
    borderBottomLeftRadius: 'borderBottomLeftRadius',
    borderBottomRightRadius: 'borderBottomRightRadius'
  };

  return composeStyles(config, props);
}

export interface ColorProps {
  /** Color. */
  color?: CSSValue;

  /** Background color. */
  bgColor?: CSSValue;
}

export function addColor(props: ColorProps) {
  const config = {
    color: 'color',
    bgColor: 'backgroundColor'
  };

  return composeStyles(config, props);
}

export interface FlexProps {
  /** Flex wrap. */
  wrap?: CSSValue;

  /** Align items. */
  align?: CSSValue;

  /** Justfiy content. */
  justify?: CSSValue;

  /** Flex direction. */
  direction?: CSSValue;
}

export function addFlex(props: FlexProps) {
  const config = {
    align: 'alignItems',
    justify: 'justifyContent',
    direction: 'flexDirection',
    wrap: 'flexWrap'
  };

  return composeStyles(config, props);
}
