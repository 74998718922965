import React from 'react';
import { PieChart, Pie } from 'recharts';
import { Percentage, Container } from './Styled';

interface DonutChartProps {
  /** The number of participants who failed the test. */
  failed: number;

  /** The number of participants who passed the test. */
  passed: number;

  /** The color for the chart. */
  color: string;
}

const DonutChart: React.FC<DonutChartProps> = ({ failed, passed, color }) => {
  const atLeastOneTaken = passed + failed > 0;
  const passedValue = atLeastOneTaken ? passed : 0;
  const failedValue = atLeastOneTaken ? failed : 1;
  const reliability = atLeastOneTaken ? (passed / (passed + failed)) * 100 : 0;

  const chartData = [
    { name: 'reliable', value: passedValue, fill: color, stroke: color },
    { name: 'tested', value: failedValue, fill: '#c3c3c3', stroke: '#c3c3c3' }
  ];

  return (
    <Container>
      <PieChart
        width={150}
        height={150}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <Pie
          data={chartData}
          dataKey="value"
          cx={75}
          cy={75}
          innerRadius={50}
          outerRadius={70}
          startAngle={90}
          endAngle={-270}
          isAnimationActive={false}
        ></Pie>
      </PieChart>
      <Percentage color={color}>{reliability.toFixed(0)}%</Percentage>
    </Container>
  );
};

export default DonutChart;
