import { clsx } from 'clsx';
import { ReactNode, useRef } from 'react';
import { SectionStatus } from './SectionStatus';
import { IconExpand, IconCollapse } from './utils';

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';

export interface SectionCollapseProps {
  /** Section title. */
  title: string;

  /** Section can have an optional subtitle. */
  subtitle?: string;

  /** Status to represent. */
  status?: null | 'pending' | 'completed' | 'error';

  /** When status is completed, a number can be rendered instead of check icon. */
  statusValue?: number;

  /** When `true`, top border radius is applied to the section. */
  attached?: boolean;

  /** React Node. */
  children?: ReactNode;

  /** Custom className. */
  className?: null | string;

  /** Used to expand or collapse the Section upon load. */
  defaultOpen?: boolean;

  /** Closes other SectionCollapse components when opening this one. */
  closeOthersOnOpen?: boolean;
}

export function SectionCollapse({
  attached,
  children,
  className,
  defaultOpen,
  title,
  subtitle,
  status,
  statusValue,
  closeOthersOnOpen
}: SectionCollapseProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  function handleClick() {
    if (!closeOthersOnOpen) {
      return;
    }

    const buttons = document.querySelectorAll<HTMLButtonElement>(
      'button[data-headlessui-state="open"]'
    );

    buttons.forEach(button => {
      if (buttonRef.current !== button) {
        button.click();
      }
    });
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLButtonElement>) {
    if (event.code === 'Enter' || event.code === 'Space') {
      handleClick();
    }
  }

  return (
    <Disclosure as="section" defaultOpen={defaultOpen}>
      {({ open }) => {
        const buttonClasses = clsx(
          'w-full p-4 sm:px-8 sm:py-6 bg-white',
          {
            'border border-[#AC213A]': status === 'error',
            'border-b-0': open,
            'hover:drop-shadow-lg': !open,
            'rounded-t-xl': attached
          },
          className
        );

        const panelClasses = clsx('p-4 sm:px-8 sm:py-6 bg-white', {
          'border border-t-0 border-[#AC213A]': status === 'error'
        });

        return (
          <>
            <DisclosureButton
              ref={buttonRef}
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              className={buttonClasses}
            >
              <div className="flex items-center">
                <div className="px-5 py-3 bg-[#F4F8FA] rounded-2xl inline-flex items-center gap-3 sm:gap-4">
                  <div className="shrink-0">
                    {open ? <IconCollapse /> : <IconExpand />}
                  </div>
                  <span className="font-bold text-[#364A5E] text-lg sm:text-2xl">
                    {title}
                  </span>
                </div>

                {subtitle && (
                  <div className="text-[#75757A] ml-5 hidden lg:block">
                    {subtitle}
                  </div>
                )}

                {status && (
                  <SectionStatus status={status} statusValue={statusValue} />
                )}
              </div>

              {subtitle && (
                <div className="text-[#75757A] text-left text-base px-5 mt-4 lg:hidden">
                  {subtitle}
                </div>
              )}
            </DisclosureButton>
            <DisclosurePanel className={panelClasses} unmount={false}>
              {children}
            </DisclosurePanel>
          </>
        );
      }}
    </Disclosure>
  );
}

export default SectionCollapse;
