import { useEffect } from 'react';
import RoutesHelper from 'helpers/routes';
import useCurrentUser from 'hooks/useCurrentUser';
import useFeatureFlags from 'hooks/useFeatureFlags';
import HomePage from '../LearningResources/HomePage';
import DetailPage from '../LearningResources/DetailPage';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AllResourcesPage from '../LearningResources/AllResourcesPage';
import PlaylistDetailPage from 'pages/LearningResources/Playlist/PlaylistDetailPage';
import LearningResourcesProvider from '../LearningResources/LearningResourcesContext';
import LearningResourcesHeader from 'components/LearningResources/LearningResouresHeader';
import Recommend from './Recommend';

function Coach() {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { isFeatureFlagEnabled } = useFeatureFlags();

  useEffect(() => {
    if (!isFeatureFlagEnabled('learning_resources')) {
      navigate(RoutesHelper.getPath('app'));
    }
  }, [isFeatureFlagEnabled, navigate]);

  useEffect(() => {
    if (!user.roles.includes('lr_coach')) {
      navigate(RoutesHelper.getPath('learning-resources'));
    }
  }, [user, navigate]);

  if (!user.roles.includes('lr_coach')) {
    return null;
  }

  return (
    <LearningResourcesProvider>
      <Routes>
        <Route element={<LearningResourcesHeader userId={user.id} />}>
          <Route path="see-all" element={<AllResourcesPage />} />
          <Route path=":id" element={<DetailPage />} />
          <Route path="playlist/:id" element={<PlaylistDetailPage />} />
          <Route path="recommend" element={<Recommend />} />
          <Route index element={<HomePage />} />
        </Route>
      </Routes>
    </LearningResourcesProvider>
  );
}

export default Coach;
