import { cloneElement, ReactElement } from 'react';

/** Sizes based on tailwindcss font size values. */
type ICON_SIZES = 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl';

const sizes: Record<ICON_SIZES, number> = {
  xs: 12,
  sm: 14,
  base: 16,
  lg: 18,
  xl: 20,
  '2xl': 24,
  '3xl': 30
};

interface IconProps {
  /** Size of the icon. Defaults to `base`. */
  size?: ICON_SIZES;
}

export function createIcon(svgIcon: ReactElement) {
  const icon = ({ size = 'base' }: IconProps) => {
    const iconSize = sizes[size];
    return cloneElement(svgIcon, { width: iconSize, height: iconSize });
  };

  return icon;
}
