import styled from 'styled-components';

export const ErrorMessage = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${p => p.theme.colors['red']};
  margin: -12px 0 16px;
`;

export const InputWrapper = styled.div`
  margin-top: 32px;

  && input {
    max-width: 150px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`;
