const PATH = 'fe.components.learning_resources.playlist';

const messages = {
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed on'
  },
  percentage: {
    key: `${PATH}.percentage`,
    defaultValue: 'completed'
  }
};

export default messages;
