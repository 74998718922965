import i18n from 'helpers/i18n';
import messages from './messages';
import { Section } from '../Styled';
import RouteHelpers from 'helpers/routes';
import { useNavigate } from 'react-router-dom';
import ParticipantList from './ParticipantList';
import TrainingContext from '../TrainingContext';
import { DropdownProps } from 'semantic-ui-react';
import AddParticipantForm from './AddParticipantForm';
import { getTrainingParticipants } from 'actions/trainings';
import Permission from 'components/Subscription/Permission';
import React, { useState, useEffect, useContext } from 'react';
import { Header, HeaderContainer, ActionsDropdown } from './Styled';
import { TrainingParticipant } from 'types/api/TrainingParticipant';
import RequestPaymentModal from './RequestPaymentModal';
import CancelModal from './CancelModal';
import StatusBar from './StatusBar';
import usePermissions from 'hooks/usePermissions';
import useCurrentUser from 'hooks/useCurrentUser';
import queryString from 'query-string';

import {
  mapParticipantsToOptions,
  paymentDisabled,
  cancelDisabled,
  reinviteDeclinedDisabled,
  addCanceledDisabled,
  editParticipantEmailDisabled,
  exportDisabled,
  exportUrl,
  withinSevenDays,
  getMaxParticipants,
  getTotalByStatus
} from './utils';
import InviteCanceledModal from './InviteCanceledModal';
import ReinviteDeclinedModal from './ReinviteDeclinedModal';
import EditEmailModal from './EditEmailModal';

function Participants() {
  const navigate = useNavigate();
  const { training } = useContext(TrainingContext);
  const trainingIsActive = training.status === 'active';
  const [values, setValues] = useState<number[]>([]);
  const [participants, setParticipants] = useState<TrainingParticipant[]>([]);
  const [canceling, setCanceling] = useState(false);
  const [reinvitingDeclined, setReinvitingDeclined] = useState(false);
  const [invitingCanceled, setInvitingCanceled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestingPayment, setRequestingPayment] = useState(false);
  const [editingParticipantEmail, setEditingParticipantEmail] = useState(false);
  const currentUser = useCurrentUser();
  const trainer_id: number = training.trainer.id;
  const userIsTrainer = trainer_id === currentUser.id;
  const trainingPermissions = usePermissions('trainer_dashboard', 'trainings');

  useEffect(loadParticipants, [training.id]);

  function getOptions() {
    const options = [];

    if ((userIsTrainer || trainingPermissions.manage) && trainingIsActive) {
      options.push(
        {
          key: 'pay',
          value: 'pay',
          icon: 'credit card',
          text: i18n.ft(messages.payForParticipants),
          disabled:
            paymentDisabled(values, participants) || withinSevenDays(training)
        },
        {
          key: 'requestPayment',
          value: 'requestPayment',
          icon: 'envelope',
          text: i18n.ft(messages.requestPayment),
          disabled:
            paymentDisabled(values, participants) || withinSevenDays(training)
        }
      );
    }

    if (trainingPermissions.manage && trainingIsActive) {
      options.push(
        {
          key: 'managePayment',
          value: 'managePayment',
          icon: 'money bill alternate outline',
          text: i18n.ft(messages.managePayment),
          disabled: paymentDisabled(values, participants)
        },
        {
          key: 'reinvite_declined',
          value: 'reinviteDeclined',
          icon: 'user plus',
          text: i18n.ft(messages.reinviteDeclinedParticipants),
          disabled: reinviteDeclinedDisabled(values, participants)
        },
        {
          key: 'cancel',
          value: 'cancel',
          icon: 'user cancel',
          text: i18n.ft(messages.cancelParticipants),
          disabled: cancelDisabled(values, participants, training)
        },
        {
          key: 'add_canceled',
          value: 'inviteCanceled',
          icon: 'user plus',
          text: i18n.ft(messages.inviteCanceledParticipants),
          disabled: addCanceledDisabled(values, participants)
        },
        {
          key: 'edit_email',
          value: 'editEmail',
          icon: 'edit',
          text: i18n.ft(messages.editParticipantEmail),
          disabled: editParticipantEmailDisabled(values, participants, training)
        }
      );
    }

    options.push({
      key: 'export',
      value: 'export',
      icon: 'download',
      text: i18n.ft(messages.exportParticipants),
      disabled: exportDisabled(values)
    });

    return options;
  }

  function loadParticipants() {
    setLoading(true);
    getTrainingParticipants(training.id).then(({ data }) => {
      setLoading(false);
      setParticipants(data.training_participants);
    });
  }

  function handleChange(newValues: number[]) {
    setValues(newValues);
  }

  function handlePayment() {
    const checkoutURL = queryString.stringifyUrl({
      url: RouteHelpers.getPath('td-trainings-view-card-checkout', {
        id: training.id
      }),
      query: { participants: values }
    });

    navigate(checkoutURL);
  }

  function handleManagePayment() {
    navigate(
      RouteHelpers.getPath('td-trainings-view-checkout', { id: training.id }),
      {
        state: { participants: values }
      }
    );
  }

  function handleCancel() {
    setCanceling(true);
  }

  function handleAfterCancel() {
    setCanceling(false);
    loadParticipants();
  }

  function handleInviteCanceled() {
    setInvitingCanceled(true);
  }

  function handleAfterReinviteDeclined() {
    setReinvitingDeclined(false);
    loadParticipants();
  }

  function handleAfterInviteCanceled() {
    setInvitingCanceled(false);
    loadParticipants();
  }

  function handleExport() {
    const url = exportUrl(values, training);
    window.open(url);
  }

  function handleRequestPayment() {
    setRequestingPayment(true);
  }

  function handleReinviteDeclined() {
    setReinvitingDeclined(true);
  }

  function handleAfterEditParticipantEmail() {
    setEditingParticipantEmail(false);
    loadParticipants();
  }

  function handleOptionChange(
    _event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) {
    if (data.value === 'cancel') {
      handleCancel();
    } else if (data.value === 'pay') {
      handlePayment();
    } else if (data.value === 'managePayment') {
      handleManagePayment();
    } else if (data.value === 'export') {
      handleExport();
    } else if (data.value === 'requestPayment') {
      handleRequestPayment();
    } else if (data.value === 'inviteCanceled') {
      handleInviteCanceled();
    } else if (data.value === 'reinviteDeclined') {
      handleReinviteDeclined();
    } else if (data.value === 'editEmail') {
      setEditingParticipantEmail(true);
    }
  }

  function addParticipantsForm() {
    const isTrainingReopened = training.reopened_at !== null;

    if (isTrainingReopened) {
      return (
        <Permission
          zone="trainer_dashboard"
          resource="training_participants"
          or={false}
        >
          <AddParticipantForm
            trainingId={training.id}
            afterSubmit={loadParticipants}
          />
        </Permission>
      );
    }

    return (
      <AddParticipantForm
        trainingId={training.id}
        afterSubmit={loadParticipants}
      />
    );
  }

  return (
    <Section>
      <CancelModal
        open={canceling}
        participantIds={values}
        trainingId={training.id}
        afterCancel={handleAfterCancel}
        onClose={() => setCanceling(false)}
      />

      <ReinviteDeclinedModal
        open={reinvitingDeclined}
        participantIds={values}
        trainingId={training.id}
        afterReinviteDeclined={handleAfterReinviteDeclined}
        onClose={() => setReinvitingDeclined(false)}
      />

      <InviteCanceledModal
        open={invitingCanceled}
        participantIds={values}
        trainingId={training.id}
        afterCancel={handleAfterInviteCanceled}
        onClose={() => setInvitingCanceled(false)}
      />

      <RequestPaymentModal
        open={requestingPayment}
        participantIds={values}
        trainingId={training.id}
        onSuccess={loadParticipants}
        onClose={() => setRequestingPayment(false)}
      />

      <EditEmailModal
        open={editingParticipantEmail}
        trainingId={training.id}
        participantId={values[0]}
        participants={participants}
        onClose={() => setEditingParticipantEmail(false)}
        afterEdit={handleAfterEditParticipantEmail}
      />

      <HeaderContainer>
        <Header>{i18n.ft(messages.participants)}</Header>

        <Permission
          zone="trainer_dashboard"
          resource="training_participants"
          or={userIsTrainer}
        >
          <ActionsDropdown
            selection
            value="none"
            selectOnNavigation={false}
            onChange={handleOptionChange}
            placeholder={i18n.ft(messages.wantTo)}
            options={getOptions()}
          />
        </Permission>
      </HeaderContainer>

      {trainingIsActive && addParticipantsForm()}

      {trainingIsActive && (
        <StatusBar
          total={getMaxParticipants(training)}
          invited={getTotalByStatus('invited', participants)}
          registered={getTotalByStatus('registered', participants)}
        />
      )}

      <ParticipantList
        values={values}
        loading={loading}
        onChange={handleChange}
        options={mapParticipantsToOptions(participants)}
      />
    </Section>
  );
}

export default Participants;
