import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import calendar from 'images/reliabilityTest/calendar.svg';
import attemptIcon from 'images/reliabilityTest/attemptIcon.svg';
import videoCamera from 'images/reliabilityTest/videoCameraBlue.svg';

interface InnerHeaderProps {
  attempt: string;
  expiresOn: string;
  expiresOnMobile: string;
  expiresAt: string;
  /* true when users come from test preparations page */
  isResources?: boolean;
}

function InnerHeader({
  attempt,
  expiresOn,
  expiresOnMobile,
  expiresAt,
  isResources
}: InnerHeaderProps) {
  const isTablet = useMediaQuery({ minWidth: 768 });

  return (
    <div className="flex justify-between font-semibold mb-4 lg:mx-8">
      <div className="flex ml-2 md:ml-0" tabIndex={0}>
        <img
          src={isResources ? videoCamera : attemptIcon}
          alt={attempt}
          className="mr-2"
          aria-hidden
        />
        {attempt}
      </div>
      <div
        className={`${
          isResources ? 'hidden ' : ''
        }flex mr-2 md:mr-0 items-center`}
        tabIndex={0}
      >
        <img src={calendar} alt={expiresOn} className="mr-2" aria-hidden />
        <span>{isTablet ? expiresOn : expiresOnMobile}</span>

        <span className="font-normal ml-2">{`${moment(expiresAt).format(
          'MM/DD/YYYY'
        )}`}</span>
      </div>
    </div>
  );
}

export default InnerHeader;
