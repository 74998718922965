import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import { ImprovementStrategy } from 'types/api/improvement/ImprovementStrategy';
import { getImprovementStrategies } from 'actions/improvements';
import { Link } from 'react-router-dom';
import Prompt from 'components/Improvements/Prompt/index';
import { ImprovementPrompt } from 'types/api/improvement/ImprovementPrompt';
import { getImprovementPrompts } from 'actions/improvements';
import React from 'react';
import { CollapsibleStrategyCard } from '../Styled';
import { useState } from 'react';
import { filterByFocusId } from 'pages/Improvements/EducatorProfile/Strategy/utils';
import { ImprovementFocus } from 'types/api/improvement/ImprovementFocus';
import i18n from 'helpers/i18n';
import messages from './messages';
import HideOnPrint from 'pages/Improvements/HideOnPrint';

interface StrategyProps {
  selectedFocus: ImprovementFocus;
  showPrompts?: boolean;
}

function Strategy({ selectedFocus, showPrompts = true }: StrategyProps) {
  const [relatedStrategies, setRelatedStrategies] = useState(
    [] as ImprovementStrategy[]
  );
  const {
    run: runStrategies,
    data: strategies,
    isPending: isPendingStrategies
  } = useAsync<ImprovementStrategy[]>();
  const { run: runPromptsRelatedToStrategy, data: relatedPrompts } =
    useAsync<ImprovementPrompt[]>();

  useEffect(() => {
    if (!isPendingStrategies) {
      const filteredStrategies = filterByFocusId(strategies, selectedFocus.id);

      runPromptsRelatedToStrategy(
        getImprovementPrompts({
          target_type: 'Improvement::ImprovementFocus',
          target_id: selectedFocus.id
        })
      );
      setRelatedStrategies(filteredStrategies);
      return;
    }

    runStrategies(getImprovementStrategies());
  }, [
    runStrategies,
    runPromptsRelatedToStrategy,
    isPendingStrategies,
    setRelatedStrategies,
    selectedFocus,
    strategies
  ]);

  return (
    <>
      <div className="mt-6 mb-8">
        <h3>
          {i18n.ft(messages.sectionTitle)}: {selectedFocus.name}
        </h3>
        <div className="mt-4">
          <h5>{i18n.ft(messages.whyFocusTitle)}</h5>
          <div>{selectedFocus.purpose}</div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex-1 w-64 gap-2">
          <div>
            <h3 className="basis-full !mt-0 font-normal text-lg text-[#3C3F42]">
              {i18n.ft(messages.supportTitle)}
            </h3>
          </div>
          {relatedStrategies?.map(strategy => {
            return (
              <div key={strategy.id}>
                <CollapsibleStrategyCard title={strategy.name}>
                  <div>{strategy.example}</div>
                </CollapsibleStrategyCard>
                <hr className="my-6" />
              </div>
            );
          })}
        </div>
        <div className={HideOnPrint(!showPrompts, 'flex-1 w-32 ml-6')}>
          <Prompt prompts={relatedPrompts?.map(rp => rp.question) || []} />
        </div>
      </div>
      <h3 className="font-normal text-lg text-[#3C3F42] mt-4">
        {i18n.ft(messages.strategiesInActionTitle)}
      </h3>
      <div className="grid grid-cols-4">
        <div className="col-span-2 mr-3">
          <CollapsibleStrategyCard title={i18n.ft(messages.whyStrategyTitle)}>
            <div>{selectedFocus.why}</div>
          </CollapsibleStrategyCard>
        </div>
        <div className="col-span-2 ml-3">
          <CollapsibleStrategyCard title={i18n.ft(messages.howStrategyTitle)}>
            <div>{selectedFocus.how}</div>
          </CollapsibleStrategyCard>
        </div>
      </div>
      <hr className="my-6" />

      {selectedFocus.resource_url && (
        <Link
          target="_blank"
          className="font-semibold"
          to={selectedFocus.resource_url}
        >
          {i18n.ft(messages.link)}
        </Link>
      )}
    </>
  );
}

export default Strategy;
