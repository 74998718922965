export function isValidURLForRedirect(url: string): boolean {
  let urlObj;

  try {
    urlObj = new URL(url);
  } catch {
    return false;
  }

  if (isLocalHost(urlObj)) {
    return true;
  } else {
    return urlObj.protocol === 'https:';
  }
}

export function isSameDomain(url: string): boolean {
  const urlObj = new URL(url);
  return getURLDomain(window.location) === getURLDomain(urlObj);
}

function isLocalHost(url: URL) {
  return url.hostname === 'my.local' || url.hostname === 'localhost';
}

function getURLDomain(url: any) {
  const urlObj = new URL(url);

  // Handle local environments
  if (isLocalHost(urlObj)) {
    return urlObj.hostname;
  }

  // Parse subdomain out and return only the domain
  const firstDotIndex = urlObj.hostname.indexOf('.');
  return urlObj.hostname.substring(firstDotIndex + 1);
}
