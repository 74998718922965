const PATH = 'fe.components.organization.subscriptions.subscription_tile';

const messages = {
  seeMore: {
    key: `${PATH}.see_more`,
    defaultValue: 'See more details about Subscription: {{name}}'
  },
  seeLess: {
    key: `${PATH}.see_less`,
    defaultValue: 'Close details of Subscription {{name}}'
  }
};

export default messages;
