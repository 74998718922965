const PATH = 'fe.pages.organization.home';

const messages = {
  accountInfo: {
    key: `${PATH}.account_information`,
    defaultValue: 'Account Information'
  },
  trainings: {
    key: `${PATH}.trainings`,
    defaultValue: 'Trainings'
  },
  subscriptions: {
    key: `${PATH}.subscriptions`,
    defaultValue: 'Subscriptions'
  },
  manageOrganization: {
    key: `${PATH}.manage_organization`,
    defaultValue: 'Manage Organization'
  },
  selectAccount: {
    key: `${PATH}.select_account`,
    defaultValue: 'Select an account'
  }
};

export default messages;
