const PATH = 'fe.pages.learning_resources.home_page';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Getting Started with Learning Resources'
  },
  all: {
    key: `${PATH}.all`,
    defaultValue: 'See All'
  },
  search: {
    key: `${PATH}.search`,
    defaultValue: 'Search learning resources'
  }
};

export default messages;
