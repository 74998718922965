import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Trainer } from 'types/api/Trainer';
import { Location } from 'types/api/Location';
import { Training } from 'types/api/Training';

type LocationAddress = {
  location_address: Location;
};

export function formatParticipants(training: Training) {
  const count =
    training.status === 'completed'
      ? training.completed_participant_count
      : training.participant_count;
  return i18n.ft(messages.participants as any, { count });
}

export function formatDate(training: Training) {
  if (!training.start_date || !training.end_date) {
    return i18n.ft(messages.dateTBD);
  }

  const start = moment(training.start_date).format('MM/DD/YYYY');
  const end = moment(training.end_date).format('MM/DD/YYYY');

  return `${start} - ${end}`;
}

export function formatLocation({
  location_address: location
}: LocationAddress) {
  if (!location.city) {
    return location.address_1 || i18n.ft(messages.locationTBD);
  }

  if (!location.state) {
    return location.city;
  }

  return `${location.city}, ${location.state}`;
}

export function formatTrainer(trainer: Trainer) {
  if (!trainer) {
    return '';
  }
  const label = i18n.ft(messages.trainer);
  return `${label}: ${trainer.first_name} ${trainer.last_name}`;
}
