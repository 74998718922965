const PATH = 'fe.pages.measure.completed';

const messages = {
  reviewHeader: {
    key: `${PATH}.review_header`,
    defaultValue: 'Review Observation'
  },
  completedHeader: {
    key: `${PATH}.completed_header`,
    defaultValue: 'Completed Observation'
  },
  return: {
    key: `${PATH}.return`,
    defaultValue: 'Return to List'
  },
  submitted01: {
    key: `${PATH}.submitted_01`,
    defaultValue: 'Your observation has been'
  },
  submitted02: {
    key: `${PATH}.submitted_02`,
    defaultValue: 'successfully submitted!'
  },
  pdf: {
    key: `${PATH}.download_pdf`,
    defaultValue: 'Download PDF'
  },
  csv: {
    key: `${PATH}.download_csv`,
    defaultValue: 'Download CSV'
  }
};

export default messages;
