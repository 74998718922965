import { useEffect } from 'react';
import { Message, MessageProps } from 'semantic-ui-react';

type Severity = 'error' | 'warning' | 'info';
interface LoggedMessageProps extends MessageProps {
  /** The message to display. */
  message: string;
  /** The severity of the message. */
  severity: Severity;
  // children?: React.ReactNode;
}

function logWithSeverity(message: string, severity: Severity) {
  switch (severity) {
    case 'error':
      console.error(message);
      break;
    case 'warning':
      console.warn(message);
      break;
    default:
      console.log(message);
  }
}

/**
 * A wrapper for the React Semantic UI Message component that displays a message;
 * this component will log the message to the console if it's an error or warning.
 */
function LoggedMessage({
  message,
  severity = 'info',
  children,
  ...props
}: LoggedMessageProps) {
  useEffect(() => {
    logWithSeverity(message, severity);
  }, [message, severity]);

  return <Message {...props}>{children}</Message>;
}

export default LoggedMessage;
