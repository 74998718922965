import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import i18n from 'helpers/i18n';
import messages from './messages';

import { LeftArrow, RightArrow } from './utils';
import { PaginationWrapper, MeasurePagination, Text } from './Styled';

interface PaginationProps {
  /** current page */
  currentPage: number;

  /** total number of pages */
  totalPages: number;

  /** callback fired when any button of the pagination is pressed */
  onPageChange?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) => void;
}

function Pagination({
  currentPage,
  totalPages,
  onPageChange
}: PaginationProps) {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <PaginationWrapper>
      <MeasurePagination
        size="small"
        siblingRange={isDesktop ? 2 : 0}
        boundaryRange={0}
        firstItem={{ content: <LeftArrow /> }}
        lastItem={{ content: <RightArrow /> }}
        prevItem={{ content: <Text>{i18n.ft(messages.previous)}</Text> }}
        nextItem={{ content: <Text>{i18n.ft(messages.next)}</Text> }}
        activePage={currentPage}
        totalPages={totalPages}
        ellipsisItem={isTablet ? '..' : null}
        onPageChange={onPageChange}
      />
    </PaginationWrapper>
  );
}

export default Pagination;
