import moment from 'moment';
import { isEmpty, last } from 'ramda';
import { Training } from 'types/api/Training';
import { sortByDate } from 'helpers/trainingSession';

/**
 * Returns `true` if the last session date of the training has
 * already started.
 */
export default function lastSessionHasStarted(training: Training): boolean {
  if (isEmpty(training.sessions)) {
    return false;
  }

  const sessionsSortedByDate = sortByDate(training.sessions);
  const today = moment();
  const lastSession = last(sessionsSortedByDate);
  const lastSessionDate = moment(lastSession!.starts_at);

  return today.isSameOrAfter(lastSessionDate.startOf('day'));
}
