import { useEffect } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { UserTest } from 'types/api/UserTest';
import { getTestAttemptById } from 'actions/testAttempts';
import { AgeLevelGroupTag } from 'components/AgeLevel';
import { formatAgeLevel } from './utils';
import { expirationTime, getCompletedAttempts } from './utils';
import { Link } from 'react-router-dom';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import PageLoader from 'components/PageLoader/PageLoader';
import DisplayButtonOrStatus from './DisplayButtonOrStatus';

import {
  Description,
  Subtitle,
  Title,
  TagContainer,
  DateContainer,
  DateContainerMobile,
  DetailsContainer,
  Container,
  ContainerTop,
  ActionsContainer,
  ContainerBottom
} from './Styled';

interface ReliabilityTestCardProps {
  /** Reliability Test from OneLogin. */
  reliabilityTest: UserTest;
}

const ReliabilityTestCard: React.FC<ReliabilityTestCardProps> = ({
  reliabilityTest
}) => {
  const {
    age_level_group: ageLevelGroup,
    current_attempt_id: currentAttemptId,
    test_attempts: testAttempts
  } = reliabilityTest;
  const completedAttempts = getCompletedAttempts(testAttempts);
  const { data, run, isPending } = useAsync();

  useEffect(getTestAttempt, [currentAttemptId, run]);

  function getTestAttempt() {
    run(getTestAttemptById(currentAttemptId));
  }

  if (isPending) {
    return <PageLoader />;
  }

  const subtitle = i18n.ft(messages.availableUntil, {
    available_until: reliabilityTest.expires_at
  });

  return (
    <Container>
      <ContainerTop>
        <DetailsContainer>
          <div>
            <Title>{reliabilityTest.title}</Title>
          </div>

          <DateContainerMobile>{subtitle}</DateContainerMobile>
          <TagContainer>
            {ageLevelGroup.code === 'PKK3' ? (
              <AgeLevelGroupTag
                abbreviation={ageLevelGroup.code}
                className="border-2"
                backgroundColor={'#385e8e'}
              >
                {ageLevelGroup.alt_name}
              </AgeLevelGroupTag>
            ) : (
              <AgeLevelGroupTag abbreviation={ageLevelGroup.code}>
                {formatAgeLevel(ageLevelGroup.name)}
              </AgeLevelGroupTag>
            )}
          </TagContainer>
        </DetailsContainer>

        <DateContainer>
          <Subtitle>{i18n.ft(messages.attempts)}</Subtitle>
          <Description>{subtitle}</Description>
          <Description>
            {expirationTime(reliabilityTest.expires_at)}
          </Description>
        </DateContainer>

        <ActionsContainer>
          <DisplayButtonOrStatus
            status={data.status}
            expiresAt={reliabilityTest.expires_at}
            currentAttemptId={currentAttemptId}
            attemptNumber={data.attempt}
          />
        </ActionsContainer>
      </ContainerTop>
      {completedAttempts.length > 0 && (
        <ContainerBottom>
          <div className="w-fit">
            {completedAttempts.map(attempt => (
              <Link
                key={attempt.id}
                className="pr-10"
                to={RouteHelpers.getPath('test-attempts-score-report', {
                  id: attempt.id
                })}
              >
                {i18n.ft(messages.reportNumber, { number: attempt.attempt })}
              </Link>
            ))}
          </div>
        </ContainerBottom>
      )}
    </Container>
  );
};

export default ReliabilityTestCard;
