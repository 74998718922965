const PATH = 'fe.pages.organization.view_member';

const messages = {
  backToMembers: {
    key: `${PATH}.back_to_members`,
    defaultValue: 'Back to Members'
  },
  lastLogin: {
    key: `${PATH}.last_login`,
    defaultValue: 'Last Login'
  },
  basicInformation: {
    key: `${PATH}.basic_information`,
    defaultValue: 'Basic Information'
  },
  name: {
    key: `${PATH}.name`,
    defaultValue: 'Name'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  role: {
    key: `${PATH}.role`,
    defaultValue: 'Role'
  },
  rolePlaceholder: {
    key: `${PATH}.role_placeholder`,
    defaultValue: 'Select role'
  },
  addedOn: {
    key: `${PATH}.added_on`,
    defaultValue: 'Added On'
  },
  addedBy: {
    key: `${PATH}.added_by`,
    defaultValue: 'Added By'
  },
  hierarchy: {
    key: `${PATH}.hierarchy`,
    defaultValue: 'Hierarchy Association'
  },
  hierarchyPlaceholder: {
    key: `${PATH}.hierarchy_placeholder`,
    defaultValue: 'Select hierarchy association for this member'
  },
  unknown: {
    key: `${PATH}.unknown`,
    defaultValue: 'Unknown'
  }
};

export default messages;
