import us from 'us';
import React from 'react';
import { map, values } from 'ramda';
import FormDropdown from 'components/FormDropdown';

const options = map(
  state => ({ key: state.abbr, value: state.abbr, text: state.name }),
  values(us.states)
);

export function useStates() {
  return options;
}

const States: React.FC = props => {
  return <FormDropdown options={options} {...props} />;
};

export default States;
