const PATH = 'fe.components.trainings.view.details.reliability_report';

const messages = {
  loading: {
    key: `${PATH}.loading`,
    defaultValue: 'Loading report data...'
  },
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Participant Reliability'
  },
  viewReport: {
    key: `${PATH}.view_report`,
    defaultValue: 'View Reliability Report'
  },
  headerKnowledge: {
    key: `${PATH}.header_knowledge`,
    defaultValue: 'Participant Knowledge'
  },
  viewKnowledgeReport: {
    key: `${PATH}.view_knowledge_report`,
    defaultValue: 'View Knowledge Report'
  }
};

export default messages;
