import { ImprovementLanguage } from 'types/api/improvement/ImprovementLanguage';

type Intake = {
  id?: number;
  educator_id: number;
  how_are_you: string;
  about: string;
  experience: string;
  successes: string;
  children_description: string;
  age_levels: number[];
  number_of_children: string;
  disabilities_description: string;
  learning_settings: string;
  learning_goals: string;
  professional_development: string;
};

export type IntakeForm = Intake & {
  languages: number[];
  dual_language: string;
  disabilities: string;
  area_selection?: string;
};

export type IntakeObject = Intake & {
  languages: ImprovementLanguage[];
  dual_language: boolean;
  disabilities: boolean;
  area_selection?: number;
};

export function getFilledForm(data: IntakeObject): IntakeForm {
  return {
    id: data.id,
    educator_id: data.educator_id,
    how_are_you: data.how_are_you,
    about: data.about,
    experience: data.experience,
    successes: data.successes,
    children_description: data.children_description,
    age_levels: data.age_levels,
    number_of_children: data.number_of_children,
    disabilities_description: data.disabilities_description,
    learning_settings: data.learning_settings,
    learning_goals: data.learning_goals,
    professional_development: data.professional_development,
    dual_language: data.dual_language ? 'true' : 'false',
    languages: data.languages?.map(
      improvementLanguages => improvementLanguages.id
    ),
    disabilities: data.disabilities ? 'true' : 'false',
    area_selection: data.area_selection ? data.area_selection.toString() : '1'
  };
}
