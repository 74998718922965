import i18n from 'helpers/i18n';
import messages from './messages';
import { toast } from 'react-toastify';
import { Toast, ToastProps } from './Toast';

function toastSuccess(props: ToastProps = {}) {
  toast(
    <Toast
      title={i18n.ft(messages.success.title)}
      description={i18n.ft(messages.success.description)}
      icon={{ name: 'check circle', color: 'green' }}
      {...props}
    />
  );
}

export default toastSuccess;
