const PATH = 'fe.pages.users.login';

const messages = {
  productKey: {
    label: {
      key: `${PATH}.product_key.label`,
      defaultValue: 'Do you have an activation key?'
    },
    button: {
      key: `${PATH}.product_key.button`,
      defaultValue: 'Activate a product key'
    }
  },
  login: {
    key: `${PATH}.login`,
    defaultValue: 'Login'
  },
  passwordHeader: {
    key: `${PATH}.password_header`,
    defaultValue: "Haven't created a password yet?"
  },
  password01: {
    key: `${PATH}.password_01`,
    defaultValue: 'Set your'
  },
  password02: {
    key: `${PATH}.password_02`,
    defaultValue: 'account password'
  },
  password03: {
    key: `${PATH}.password_03`,
    defaultValue: 'using your email address.'
  }
};

export default messages;
