import React from 'react';
import Toolbar from '../Toolbar';
import { Loader, Dimmer } from 'semantic-ui-react';
import { map, includes, without, append, isEmpty } from 'ramda';
import ParticipantCard, { ParticipantCardProps } from '../ParticipantCard';

export type Item = ParticipantCardProps & {
  /** The training participant id. */
  value: number;
};

interface ParticipantListProps {
  /** Array of Item props e.g. `{ value: '', email: '', name: '' }`  */
  options: Item[];

  /** Array of selected participant ids. */
  values: number[];

  /** Callback fired when the list of selected cards changes. */
  onChange: (values: number[]) => void;

  /** If `true` a loading indicator will be displayed. */
  loading?: boolean;
}

const ParticipantList: React.FC<ParticipantListProps> = ({
  values,
  options,
  onChange,
  loading
}) => {
  function cardIsActive(item: Item): boolean {
    return includes(item.value, values);
  }

  function handleCardClick(item: Item): (active: boolean) => void {
    return (active: boolean) => {
      if (active) {
        onChange(append(item.value, values));
      } else {
        onChange(without([item.value], values));
      }
    };
  }

  function toolbarIsActive(): boolean {
    return !isEmpty(options) && options.length === values.length;
  }

  function toolbarIsIndeterminate(): boolean {
    return (
      !isEmpty(options) && !isEmpty(values) && options.length !== values.length
    );
  }

  function handleToolbarClick() {
    if (toolbarIsActive() || toolbarIsIndeterminate()) {
      onChange([]);
    } else {
      onChange(map(option => option.value, options));
    }
  }

  return (
    <div>
      <Toolbar
        onClick={handleToolbarClick}
        active={toolbarIsActive()}
        indeterminate={toolbarIsIndeterminate()}
        selected={values.length}
      />

      <Loader
        inline="centered"
        style={{ marginTop: '16px' }}
        active={loading && isEmpty(options)}
      />

      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer inverted active={loading}>
          <Loader />
        </Dimmer>

        {map(participant => {
          return (
            <ParticipantCard
              {...participant}
              key={participant.value}
              active={cardIsActive(participant)}
              onChange={handleCardClick(participant)}
            />
          );
        }, options)}
      </Dimmer.Dimmable>
    </div>
  );
};

export default ParticipantList;
