const PATH = 'fe.components.trainings.view.card_checkout.order_summary';

const messages = {
  orderSummary: {
    key: `${PATH}.order_summary`,
    defaultValue: 'Order Summary'
  },
  ageLevels: {
    key: `${PATH}.age_levels`,
    defaultValue: 'Age Levels'
  },
  trainer: {
    key: `${PATH}.trainer`,
    defaultValue: 'Trainer'
  },
  sessions: {
    key: `${PATH}.sessions`,
    defaultValue: 'Sessions'
  },
  quantity: {
    key: `${PATH}.quantity`,
    defaultValue: 'Quantity'
  },
  price: {
    key: `${PATH}.price`,
    defaultValue: 'Price'
  },
  subtotal: {
    key: `${PATH}.subtotal`,
    defaultValue: 'Subtotal'
  },
  discount: {
    key: `${PATH}.discount`,
    defaultValue: 'Discount'
  },
  shipping: {
    key: `${PATH}.shipping`,
    defaultValue: 'Estimated Shipping'
  },
  tax: {
    key: `${PATH}.tax`,
    defaultValue: 'Estimated Sales Tax'
  },
  total: {
    key: `${PATH}.total`,
    defaultValue: 'Grand Total'
  }
};

export default messages;
