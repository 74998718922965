import Spacer from 'components/Spacer';
import { NavButton, NavMenuPanel } from './Styled';
import { CaretUpIcon, CaretDownIcon } from '../icons';
import { Popover, PopoverPanel, PopoverButton } from '@headlessui/react';

export const NavMenu = Popover;

interface NavMenuItemsProps {
  /** The content of the component. */
  children: React.ReactNode;

  /** The position for aligning the content. */
  position?: 'left' | 'right';

  /** Adittional CSS Styles. */
  style?: React.CSSProperties;
}

export function NavMenuItems({ children, ...rest }: NavMenuItemsProps) {
  return (
    <PopoverPanel as={NavMenuPanel} {...rest}>
      {children}
    </PopoverPanel>
  );
}

interface NavMenuButtonProps {
  /** The content of the component. */
  children: React.ReactNode;

  /** Adittional CSS Styles. */
  style?: React.CSSProperties;
}

export function NavMenuButton({ children, ...rest }: NavMenuButtonProps) {
  return (
    <PopoverButton as={NavButton} {...rest}>
      {({ open }) => (
        <>
          {children}
          <Spacer axis="horizontal" size={12} />
          {open ? <CaretUpIcon /> : <CaretDownIcon />}
        </>
      )}
    </PopoverButton>
  );
}
