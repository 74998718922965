import styled from 'styled-components';

export const Errors = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 16px;
  color: ${props => props.theme.colors.red};
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`;

export const LinkWrapper = styled.div`
  margin-top: 32px;
`;

export const Instructions = styled.p`
  font-size: 16px;
  line-height: 24px;
`;
