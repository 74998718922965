import i18n from 'helpers/i18n';
import { useState } from 'react';
import messages from './messages';
import { useField } from 'formik';
import { Icon } from 'semantic-ui-react';
import useDebouncedEffect from 'hooks/useDebouncedEffect';

import {
  TagContainer,
  Container,
  Tag,
  TagName,
  ErrorLabel,
  InputDisable,
  Label,
  Search,
  Optional,
  Required,
  IconButton
} from './Styled';

interface InputProps {
  /** Input name. */
  name: string;

  /** Input label. */
  label: string;

  /** Input Placeholder. */
  placeholder: string;

  /** Maximum number of tags permitted. */
  maxElements: number;

  /** Maximum input length. */
  maxInputLength: number;

  /** onChange handler */
  onTagChange?: (tags: TagElement[]) => void;

  /** onBlur handler */
  onBlur?: (tags: TagElement[]) => void;

  /** If `true`, required `*` will be displayed instead of `(Optional)`. */
  required?: boolean;

  /** Callback fired when the search input changes. */
  fetchOptions?: (query: string) => Promise<{ guid: string; title: string }[]>;
}

export type TextTag = { type: 'text'; value: string };
export type UserTag = { type: 'user'; value: string; guid: string };
export type TagElement = TextTag | UserTag;

function InputWithTag({
  name,
  label,
  placeholder,
  maxElements,
  maxInputLength,
  onTagChange,
  onBlur,
  required,
  fetchOptions
}: InputProps) {
  const [userIdx, setUserIdx] = useState(0);
  const [isError, setIsError] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [options, setOptions] = useState<{ guid: string; title: string }[]>([]);

  useDebouncedEffect(
    () => {
      if (!fetchOptions || inputValue.length < 3) {
        return;
      }

      const getOptions = async (query: string) => {
        setIsLoadingOptions(true);
        const options = await fetchOptions(query);
        setOptions(options);
        setIsLoadingOptions(false);
      };

      getOptions(inputValue);
    },
    300,
    [inputValue, fetchOptions]
  );

  const [field, meta, helpers] = useField({
    name,
    multiple: true
  });

  function enterOrCommaPressed(event: any) {
    return (event.key === 'Enter' || event.key === ',') && !userIdx;
  }

  function handleKeyDown(event: any) {
    if (enterOrCommaPressed(event)) {
      addTextTag(event);
    }
  }

  function handleBlur(event: any) {
    onBlur?.(field.value);
    addTextTag(event);
  }

  function addTextTag(event: any) {
    if (!inputValue) {
      return;
    }

    const value = inputValue;
    setInputValue('');

    if (
      field.value.find(
        (tag: TagElement) =>
          tag.type === 'text' && tag.value.toLowerCase() === value.toLowerCase()
      )
    ) {
      event.preventDefault(); // do not submit on hit enter
      return;
    }

    const tags = [...field.value, { type: 'text', value }];

    if (field.value.length < maxElements) {
      helpers.setValue(tags); // it's cancelling button submit
      onTagChange?.(tags);

      if (!enterOrCommaPressed(event)) {
        event.relatedTarget?.click();
      }
    } else {
      setIsError(true);
    }

    event.preventDefault(); // do not submit on hit enter
  }

  function addUser(guid: string, name: string) {
    if (
      field.value.find(
        (tag: TagElement) => tag.type === 'user' && tag.guid === guid
      )
    ) {
      return;
    }

    const tags = [...field.value, { type: 'user', value: name, guid }];
    helpers.setValue(tags);
    onTagChange?.(tags);
  }

  function removeTag(index: number) {
    const newTags = [...field.value];
    newTags.splice(index, 1);
    helpers.setValue(newTags);
    if (field.value.length <= maxElements) {
      setIsError(false);
    }
    if (onTagChange) {
      onTagChange(newTags);
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    if (value.length <= maxInputLength) {
      setInputValue(event.target.value);
    }
  }

  const isDisabled = field.value.length >= maxElements;
  const hasError = isError || Boolean(meta.error);
  const errorMessage = isError
    ? i18n.ft(messages.error, { label })
    : meta.error;

  return (
    <Container>
      <Label>
        {label}
        {required ? (
          <Required>*</Required>
        ) : (
          <Optional>({i18n.ft(messages.optional)})</Optional>
        )}
      </Label>

      {isDisabled ? (
        <InputDisable name={name} tabIndex={-1} />
      ) : (
        <Search
          fluid
          value={inputValue}
          placeholder={placeholder}
          onSearchChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          results={options}
          minCharacters={3}
          showNoResults={false}
          error={hasError}
          className="w-full"
          loading={isLoadingOptions}
          onResultSelect={(event: any, data: any) => {
            event.preventDefault();
            addUser(data.result.guid, data.result.title);
            setInputValue('');
            setUserIdx(0);
          }}
          onSelectionChange={(event: any, data: any) => {
            event.preventDefault();
            setUserIdx(data.result?.id);
          }}
        />
      )}

      {hasError ? (
        <ErrorLabel aria-errormessage={errorMessage}>{errorMessage}</ErrorLabel>
      ) : null}

      <TagContainer>
        {field.value.map((tag: TagElement, index: number) => (
          <Tag key={index} tabIndex={0} aria-label={tag.value}>
            <IconButton type="button" onClick={() => removeTag(index)}>
              <Icon name="close" aria-label={i18n.ft(messages.closeButton)} />
            </IconButton>
            <TagName aria-label={tag.value}>
              {tag.type === 'text' ? tag.value : `User: ${tag.value}`}
            </TagName>
          </Tag>
        ))}
      </TagContainer>
    </Container>
  );
}

export default InputWithTag;
