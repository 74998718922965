import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import Routes from 'helpers/routes';
import { TestStatus } from './Styled';
import { Button, Icon } from 'semantic-ui-react';

interface DisplayButtonOrStatusProps {
  /** Status of the attempt: ['passed', 'failed', 'available', 'not_started' (Core), 'in_progress'] */
  status: 'passed' | 'failed' | 'available' | 'not_started' | 'in_progress';

  /** Expiration date of the test */
  expiresAt: moment.MomentInput;

  /** Test Attempt Id of the test */
  currentAttemptId: Number;

  /** Normally 1st, 2nd, 3rd but it could be up to 5th */
  attemptNumber: string;
}

function DisplayButtonOrStatus({
  status,
  expiresAt,
  currentAttemptId,
  attemptNumber
}: DisplayButtonOrStatusProps) {
  if (status === 'passed') {
    return (
      <TestStatus passed>
        <Icon color="green" name="check circle" />
        {i18n.ft(messages.passed)}
      </TestStatus>
    );
  }

  if (status === 'failed') {
    return (
      <TestStatus failed>
        <Icon color="red" name="exclamation circle" />
        {i18n.ft(messages.failed)}
      </TestStatus>
    );
  }

  if (moment().isAfter(moment(expiresAt))) {
    return (
      <TestStatus>
        <Icon name="time" />
        {i18n.ft(messages.expired)}
      </TestStatus>
    );
  }

  const handleClick = () => {
    window.location.href = Routes.getPath('test-attempts', {
      id: currentAttemptId
    });
  };

  return (
    <Button fluid color="blue" className="rounded" onClick={handleClick}>
      {i18n.ft(messages.goToAttempt, {
        number: attemptNumber
      })}
    </Button>
  );
}

export default DisplayButtonOrStatus;
