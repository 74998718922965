import i18n from 'helpers/i18n';
import { useState } from 'react';
import messages from './messages';
import { Input } from 'semantic-ui-react';
import Important from 'components/LearningResources/Important';
import LastViewed from 'components/LearningResources/LastViewed';
import Recommended from 'components/LearningResources/Recommended';
import PlaylistItem from 'components/LearningResources/PlaylistItem';
import SearchResults from 'components/LearningResources/SearchResults';
import useCurrentUser from 'hooks/useCurrentUser';
import MyEducators from 'components/MyEducators';

function HomePage() {
  const user = useCurrentUser();
  const [query, setQuery] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');

  function handleChange(e: any) {
    setCurrentQuery(e.target.value);
    if (e.target.value === '') {
      setQuery('');
    }
  }

  if (user.roles.includes('lr_coach')) {
    return <MyEducators />;
  }

  return (
    <>
      <div className="mt-8 mb-16 px-4 md:px-0 md:py-8">
        <div className="w-full ">
          <Input
            fluid
            clearable="true"
            id="search"
            icon="search"
            className="mb-8 max-w-[856px] text-center mx-auto"
            placeholder={i18n.ft(messages.search)}
            onChange={handleChange}
            value={currentQuery}
            onKeyDown={(e: { key: string }) => {
              if (e.key === 'Enter') {
                setQuery(currentQuery);
              }
            }}
          />
        </div>

        {query === '' ? (
          <>
            <Recommended />
            <LastViewed />
            <Important />
          </>
        ) : (
          <SearchResults query={query} />
        )}
      </div>
      {/* We'll need to remove this section once we can show playlist in the mix*/}
      <>
        <div className="block md:flex items-center pb-4">
          <h2 className="text-black">{'Marked as Important'}</h2>{' '}
          <a
            className="pl-0 md:pl-4 font-bold underline underline-offset-2 text-base text-[#0A9CCA]"
            href={'/'}
          >
            {i18n.ft(messages.all)}
          </a>
        </div>
        <div className="flex gap-3 xl:grid xl:grid-cols-4 pb-8 overflow-hidden scrollbar-hidden">
          <PlaylistItem
            percentage={70}
            videoUri={'1_bkuk1xi7'}
            numberOfResources={5}
            packageName="Positive Climate"
          />
        </div>
      </>
    </>
  );
}

export default HomePage;
