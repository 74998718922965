const PATH = 'fe.pages.testing.reliability.score_report';

const messages = {
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: 'Review your performance on your most recent attempt.'
  },
  logoDescription: {
    key: `${PATH}.logo_description`,
    defaultValue: 'Logo Certification'
  }
};

export default messages;
