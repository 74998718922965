import { useEffect } from 'react';
import styled from 'styled-components';
import { Box } from 'components/Box';
import { Icon } from 'semantic-ui-react';
import RouteHelpers from 'helpers/routes';
import messages from './messages';
import i18n from 'helpers/i18n';
import settings from 'helpers/settings';

const Wrapper = styled.div`
  .hl-widget.latest-discussion {
    width: fit-content;
    padding: 5px;
    font-family: inherit;
  }

  .hl-widget.latest-discussion,
  .hl-widget.latest-discussion #login-information-container {
    border-radius: 5px;
  }

  .hl-widget.latest-discussion #login-information-container {
    float: right;
    padding: 5px 10px;
    margin-right: -5px;
    color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .hl-widget.latest-discussion #login-information-container a {
    color: #ddd;
  }

  .hl-widget.latest-discussion #login-information-container .error-message {
    color: #cc0000;
  }

  .hl-widget.latest-discussion .container {
    background-color: white;
    overflow: auto;
    padding: 0 10px;
  }

  .hl-widget.latest-discussion ul {
    padding-left: 0;
  }

  .hl-widget.latest-discussion li {
    list-style-type: none;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: dotted 1px #afb1b3;
    padding: 10px;
  }

  .hl-widget.latest-discussion .item-image-container {
    float: left;
    width: 60px;
    height: 60px;
    padding-top: 2px;
  }

  .hl-widget.latest-discussion .item-title-container {
    margin-top: 2px;
  }

  .hl-widget.latest-discussion .item-title-container a {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
  }

  .hl-widget.latest-discussion .item-by-line-container {
    color: #808080;
    font-style: italic;
    margin-top: 2px;
  }

  .hl-widget.latest-discussion .item-by-line-container span {
    font-size: 14px;
  }

  .hl-widget.latest-discussion .item-posted-in-container {
    color: #808080;
    margin-top: 2px;
  }

  .hl-widget.latest-discussion .item-posted-in-container span {
    font-size: 14px;
  }

  .hl-widget.latest-discussion .item-body-container {
    margin-top: 15px;
    font-size: 14px;
  }

  .hl-widget.latest-discussion .footer-container {
    margin: 0px 10px;
  }

  .hl-widget.latest-discussion .footer-item-more {
    float: left;
  }

  .hl-widget.latest-discussion .footer-item-post {
    float: right;
  }

  .hl-widget.latest-discussion .footer-item-more,
  .hl-widget.latest-discussion .footer-item-post {
    padding-bottom: 10px;
  }

  .hl-widget .error-message {
    color: #cc0000;
    padding: 10px;
  }
`;

export const Title = styled.a`
  background-color: #f4f8fa;
  width: fit-content;
  padding: 4px 12px;
  border-radius: 16px;
  font-weight: bold;
  color: inherit;
  position: relative;
  height: fit-content;

  &:hover {
    color: ${p => p.theme.colors.blue};
  }
`;

export const WrapperWidget = styled.div`
  display: flex;
  flex-direction: column;
`;

function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const community = settings.lookup('REACT_APP_COMMUNITY_BASE_URL');
const hlKey = settings.lookup('REACT_APP_HIGHER_LOGIC_WIDGET_HLIAMKEY');

const CommunityWidget = () => {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src =
      'https://api.connectedcommunity.org/widgetscripts/widgets/hlwidgetcommon.js';
    script1.type = 'text/javascript';

    const script2 = document.createElement('script');
    script2.src =
      'https://api.connectedcommunity.org/widgetscripts/widgets/latestDiscussion.js';
    script2.type = 'text/javascript';

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }, []);

  useEffect(() => {
    async function runWidget() {
      await wait(500);
      (window as any).hl.latestDiscussion('previewWidget', {
        discussionKey: '',
        maxToRetrieve: '3',
        subjectLength: '50',
        contentLength: '104',
        moreUrl: community,
        showLoginStatus: '0',
        loginUrl: `${community}/HigherLogic/Security/LoginBounce.aspx?dialog=1`,
        domainUrl: community,
        cbUseBioBubble: '0',
        includeStaff: '1',
        HLIAMKey: hlKey
      });
    }

    runWidget();
  }, []);

  return (
    <WrapperWidget>
      <Title href={RouteHelpers.getPath('community')}>
        {i18n.ft(messages.community)}
        <Box as="span" ml="16px">
          <Icon fitted name="angle right" />
        </Box>
      </Title>
      <Wrapper>
        <div id="previewWidget"></div>
      </Wrapper>
    </WrapperWidget>
  );
};

export default CommunityWidget;
