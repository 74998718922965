import i18n from 'helpers/i18n';
import messages from './messages';
import ModalInstructionItem from './ModalInstructionItem';
import lampIcon from 'images/reliabilityTest/lampIcon.svg';

function ModalContent() {
  return (
    <div>
      <p>{i18n.ft(messages.threeAttempts)}</p>
      <div className="font-bold text-cyan-700 mt-10 mb-3">
        {i18n.ft(messages.recommendations)}
      </div>
      <ul>
        <li className="mb-4">
          <div className="flex items-start">
            <img src={lampIcon} className="inline mr-4" alt="bullet point" />
            {i18n.ft(messages.useClass)}
          </div>
        </li>
        <li className="mb-4">
          <div className="flex items-start">
            <img src={lampIcon} className="inline mr-4" alt="bullet point" />
            {i18n.ft(messages.code)}
          </div>
        </li>
      </ul>
      <div className="font-bold text-cyan-700 mt-10 mb-3">
        {i18n.ft(messages.videoInstructions)}
      </div>
      <ul className="mb-5">
        <ModalInstructionItem stepNumber="1" content={messages.useComputer} />
        <ModalInstructionItem
          stepNumber="2"
          content={messages.wearHeadphones}
        />
        <ModalInstructionItem stepNumber="3" content={messages.eachVideo} />
        <ModalInstructionItem stepNumber="4" content={messages.watchedOnly} />
        <ModalInstructionItem stepNumber="5" content={messages.watchVideo} />
        <ModalInstructionItem stepNumber="6" content={messages.classManual} />
        <ModalInstructionItem stepNumber="7" content={messages.enterCodes} />
      </ul>
    </div>
  );
}

export default ModalContent;
