const PATH = 'fe.components.trainings.view.details';

const messages = {
  description: {
    key: `${PATH}.description`,
    defaultValue: 'Description:'
  },
  virtualTrainingLink: {
    key: `${PATH}.virtual_training_link`,
    defaultValue: 'Web link for Virtual Training:'
  }
};

export default messages;
