import i18n from 'helpers/i18n';
import settings from 'helpers/settings';
import messages from './messages';
import lampIcon from 'images/reliabilityTest/lampIcon.svg';
import { TEST_OUTCOME } from 'types/api/ReliabilityReport';
import { MessageBox } from 'components/ReliabilityTest/Styled';

interface ShowFirstAttemptMessageProps {
  attemptNumber: number;
  result: TEST_OUTCOME;
}

function ShowFirstAttemptMessage({
  attemptNumber,
  result
}: ShowFirstAttemptMessageProps) {
  const reliabilitySupportURL = `${settings.lookup('REACT_APP_TS_CONTACT_SUPPORT')}`;

  if (attemptNumber === 1 && result === 'failed') {
    return (
      <MessageBox className="PrintBackgroundColor">
        <div className="justify-center mt-2 p-4 md:px-8 PrintMarginBottom PrintPaddingTop PrintPaddingBottom">
          <div className="align-middle PrintPaddingTop">
            <div className="flex">
              <img
                src={lampIcon}
                alt={i18n.ft(messages.attemptTip)}
                className="self-start mr-4"
              />
              <div className="mx-auto PrintMarginBottom">
                <span
                  dangerouslySetInnerHTML={{
                    __html: i18n.ft(messages.scheduleCall, {
                      url: reliabilitySupportURL
                    })
                  }}
                  style={{ marginRight: '5px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </MessageBox>
    );
  }

  return null;
}

export default ShowFirstAttemptMessage;
