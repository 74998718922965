import axios from 'axios';

export function getUserTokens(params = {}) {
  return axios.get('/api/v2/user_tokens', { params });
}

export function updateUserToken(token: string, payload = {}) {
  return axios.put(`/api/v2/user_tokens/${token}`, {
    user_token: payload
  });
}

export function cancelUserToken(token: string) {
  return axios.put(`/api/v2/user_tokens/${token}/cancel`, {
    user_token: token
  });
}

export function getUserToken(token: string) {
  return axios.get(`/api/v2/user_tokens/${token}`);
}

export function archiveToken(token: string) {
  return axios.delete(`/api/v2/user_tokens/${token}`);
}

export function validateToken(token: string) {
  return axios.get(`/api/v2/user_tokens/${token}/validate`);
}

export function respondTokenInvite(token: string, response = {}) {
  return axios.put(`/api/v2/user_tokens/${token}/respond`, {
    response
  });
}
