import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { Icon, Modal, Button, ModalProps } from 'semantic-ui-react';
import { deleteAssessment } from 'actions/envScales/assessments';

interface DeleteModalProps extends ModalProps {
  /** Assessment ID. */
  assessmentId: number;

  /** Callback fired when the assessment is successfully deleted. */
  onSuccess: () => void;

  /** Callback fired when the modal requests to be closed. */
  onClose: () => void;
}

function DeleteModal({ assessmentId, onSuccess, ...props }: DeleteModalProps) {
  const { run, isLoading } = useAsync();

  function handleDelete() {
    run(deleteAssessment(assessmentId), {
      onSuccess
    });
  }

  return (
    <Modal size="small" closeOnDimmerClick={!isLoading} {...props}>
      <Modal.Header>
        <Icon name="trash" /> {i18n.ft(messages.header)}
      </Modal.Header>
      <Modal.Content>{i18n.ft(messages.description)}</Modal.Content>
      <Modal.Actions>
        <Button
          onClick={props.onClose}
          disabled={isLoading}
          content={i18n.ft(messages.cancel)}
        />
        <Button
          color="red"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleDelete}
          content={i18n.ft(messages.remove)}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default DeleteModal;
