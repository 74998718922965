const PATH = 'fe.components.recertification_product_key';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Certifications'
  },
  video: {
    key: `${PATH}.video`,
    defaultValue: 'Video Language'
  },
  select: {
    key: `${PATH}.select`,
    defaultValue:
      'Please select from this list of your certifications that meet the requirements for recertification or for transitioning to a CLASS 2nd Edition age level.'
  },
  certification: {
    key: `${PATH}.certification`,
    defaultValue: 'You’re eligible for these certifications.'
  },
  language: {
    key: `${PATH}.language`,
    defaultValue:
      'The observer reliability test for this class age level has videos available in these languages.'
  },
  recertify: {
    key: `${PATH}.recertify`,
    defaultValue: 'Recertify'
  },
  transition: {
    key: `${PATH}.transition`,
    defaultValue: 'Transition to CLASS 2nd Edition'
  },
  note: {
    key: `${PATH}.note`,
    defaultValue:
      'This certification can be transitioned to CLASS 2nd Edition once the requirements are met.'
  },
  learn: {
    key: `${PATH}.learn`,
    defaultValue: 'Learn more'
  },
  or: {
    key: `${PATH}.or`,
    defaultValue: 'Or'
  },
  info: {
    key: `${PATH}.info`,
    defaultValue:
      'Select a language for the observer reliability test videos available for this age level.'
  }
};

export default messages;
