const PATH = 'fe.pages.improvements.educator_profile.strategy';

const messages = {
  sectionTitle: {
    key: `${PATH}.section_title`,
    defaultValue: 'Focus'
  },
  whyFocusTitle: {
    key: `${PATH}.why_focus_title`,
    defaultValue: 'Why is this important?'
  },
  supportTitle: {
    key: `${PATH}.support_title`,
    defaultValue: 'Key Strategies'
  },
  strategiesInActionTitle: {
    key: `${PATH}.strategies_in_action_title`,
    defaultValue: 'Exploring Key Strategies'
  },
  whyStrategyTitle: {
    key: `${PATH}.why_strategy_title`,
    defaultValue: 'Why do these strategies work?'
  },
  howStrategyTitle: {
    key: `${PATH}.how_strategy_title`,
    defaultValue: 'How can we use these strategies generally?'
  },
  link: {
    key: `${PATH}.link`,
    defaultValue: 'Learn more about using these strategies with children.'
  }
};

export default messages;
