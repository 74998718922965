import styled from 'styled-components';

export const VerificationWrapper = styled.div`
  margin: -8px 0 16px;
`;

export const VerificationStatus = styled.span`
  margin-left: 4px;
  font-style: italic;
  color: ${p => p.theme.colors['neutral']['500']};
`;

export const InputWrapper = styled.div`
  max-width: 350px;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`;
