const PATH = 'fe.components.measure.summary.request_updates_modal';

const messages = {
  requestUpdates: {
    key: `${PATH}.request_updates`,
    defaultValue: 'Request Updates'
  },
  content: {
    key: `${PATH}.content`,
    defaultValue: `Are you sure you want to request updates for this observation? You can't undo this action`
  },
  fields: {
    feedback: {
      key: `${PATH}.fields.feedback`,
      defaultValue: 'Provide feedback to observer for resubmission'
    }
  },
  errors: {
    feedback: {
      max: {
        key: `${PATH}.errors.feedback.max`,
        defaultValue: 'Feedback is too long (maximum is 1000 characters)'
      }
    }
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Yes, Request'
  }
};

export default messages;
