import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import { FormikHelpers } from 'formik';
import { lookupUser } from 'actions/users';
import { Button } from 'semantic-ui-react';
import { useLoginManager } from 'components/LoginManager';
import { Formik, Form, Input } from 'components/EnhancedForm';
import { ButtonWrapper, SignOutIFrame } from './Styled';
import moment from 'moment';

const validationSchema = yup.object({
  search_text: yup
    .string()
    .required(() => i18n.ft(messages.errors.email.required))
});

function LoginLookup() {
  const { run, isLoading } = useAsync();
  const { setStep, setEmailOrPhone, emailOrPhone } = useLoginManager();

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    run(lookupUser(values), {
      onSuccess: (data: any) => {
        const emailOrPhone = data.email_or_phone;
        setEmailOrPhone(emailOrPhone);

        if (emailOrPhone.includes('@')) {
          setStep('loginWithPassword');
        } else {
          setStep('loginWithCode');
        }
      },
      onError: (error: any) => {
        let time = moment(error?.locked_until).fromNow(true);
        let errorMessage = Boolean(error?.locked_until)
          ? i18n.ft(messages.errors.tryAgainIn, { time })
          : error?.errors?.user_lookup?.[0];
        formik.setFieldError('search_text', errorMessage);
      }
    });
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ search_text: emailOrPhone }}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      <Form>
        <Input
          autoFocus
          name="search_text"
          label={i18n.ft(messages.email)}
          placeholder={i18n.ft(messages.emailPlaceholder)}
          errorLabel={{ textAlign: 'left' }}
          autoComplete="username"
        />

        <input
          name="password"
          tabIndex={-1}
          type="password"
          autoComplete="current-password"
          style={{ opacity: 0, height: 0, position: 'absolute' }}
        />

        <ButtonWrapper>
          <Button
            fluid
            size="large"
            color="green"
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            content={i18n.ft(messages.continue)}
          />
        </ButtonWrapper>

        <SignOutIFrame
          title="single logout iframe"
          src={RouteHelpers.getPath('single-logout')}
        />
      </Form>
    </Formik>
  );
}

export default LoginLookup;
