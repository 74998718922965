const PATH = 'fe.components.dashboard.certifications_trainings';

const messages = {
  certifications: {
    key: `${PATH}.certifications`,
    defaultValue: 'Certifications'
  },
  expiration: {
    key: `${PATH}.expiration`,
    defaultValue: 'Expiration'
  },
  trainer: {
    key: `${PATH}.trainer`,
    defaultValue: 'Trainer Dashboard'
  },
  start: {
    key: `${PATH}.start`,
    defaultValue: 'Start'
  },
  seeMore: {
    key: `${PATH}.see_more`,
    defaultValue: 'See more'
  },
  createTraining: {
    key: `${PATH}.create_training`,
    defaultValue: 'Create Training'
  },
  noTrainings: {
    key: `${PATH}.no_trainings`,
    defaultValue: 'No scheduled trainings'
  }
};

export default messages;
