import { map } from 'ramda';
import FormDropdown from 'components/FormDropdown';
import React from 'react';
import { formatTrainerOption } from 'components/TrainingCatalog/Create/utils/trainersUtils';
import { Trainer } from 'types/api/Trainer';

interface EligibleTrainersProps {
  templateId: number;
  trainers: Trainer[];
}

const EligibleTrainers: React.FC<EligibleTrainersProps> = ({
  templateId,
  trainers,
  ...props
}) => {
  const options = map(formatTrainerOption, trainers);

  return <FormDropdown options={options} {...props} />;
};

export default EligibleTrainers;
