import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import Participant from '../../Participant';
import { useMediaQuery } from 'react-responsive';
import Typography from 'components/Typography';
import { getStatusLocalized, getPaymentStatusLocalized } from './utils';

import {
  PARTICIPANT_STATUS,
  PAYMENT_STATUS
} from 'types/api/TrainingParticipant';

import {
  Checkbox,
  Container,
  UserColumn,
  StatusColumn,
  ChecboxColumn
} from './Styled';

export interface ParticipantCardProps {
  /** Email of the participant. */
  email: string;

  /** Name of the participant. */
  name?: string;

  /** Status of participant. */
  status: PARTICIPANT_STATUS;

  /** Status of participant payment. */
  paymentStatus: PAYMENT_STATUS;

  /** Avatar URL of the participant. */
  avatar?: string;

  /** If `true`, the card will have a selected style. */
  active?: boolean;

  /** Callback fired when the card is clicked. */
  onChange?: (active: boolean) => void;
}

const ParticipantCard: React.FC<ParticipantCardProps> = ({
  email,
  name,
  status,
  paymentStatus,
  avatar,
  active,
  onChange
}) => {
  const isLessThan600px = useMediaQuery({ maxWidth: 600 });

  function handleClick() {
    onChange && onChange(!active);
  }

  return (
    <Container active={active} onClick={handleClick}>
      <ChecboxColumn>
        <Checkbox checked={active} />
      </ChecboxColumn>
      <UserColumn>
        <Participant email={email} name={name} avatar={avatar} />
      </UserColumn>
      <StatusColumn>
        {isLessThan600px && (
          <Typography bold append>
            {i18n.ft(messages.paymentStatus)}
          </Typography>
        )}
        {getPaymentStatusLocalized(paymentStatus)}
      </StatusColumn>
      <StatusColumn>
        {isLessThan600px && (
          <Typography bold append>
            {i18n.ft(messages.status)}
          </Typography>
        )}
        {getStatusLocalized(status)}
      </StatusColumn>
    </Container>
  );
};

export default ParticipantCard;
