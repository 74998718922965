import { Icon } from 'semantic-ui-react';
import useAsync from 'hooks/useAsync';
import React, { useEffect, useState } from 'react';
import { getAccountTrainingDetail } from 'actions/trainings';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Training, TRAINING_STATUS } from 'types/api/Training';
import { Session } from 'types/api/Session';
import { Participant } from 'types/api/Participant';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';
import { AGE_LEVEL_ABBREVIATIONS } from 'types/api/AgeLevel';
import { getStatusLabel } from 'components/Reports/Reliability/Table/utils';
import { useMediaQuery } from 'react-responsive';
import ReliabilityToggle from './ReliabilityToggle';
import { keys } from 'ramda';
import { getAgeLevel } from 'helpers/ageLevel';

import {
  Wrapper,
  SeeMoreArea,
  StatusArea,
  ActionArea,
  Info,
  Sessions,
  Participants,
  SessionTitle,
  SessionRow,
  SessionNumber,
  SessionDates,
  Table,
  TableColumn,
  TableHeader,
  LoaderWrapper,
  CardContainer,
  Card,
  CardItem,
  CardTitle,
  CardDetail,
  CardReliability,
  Status
} from './Styled';

interface TrainingDetailsProps {
  /**Training data */
  training: Training;
}

function getReliabilityOptions(participants?: Participant[]) {
  if (!participants || participants.length === 0) {
    return [];
  }
  const firstParticipant = participants[0];
  const relStatusKeys = keys(firstParticipant.reliability_status);

  return relStatusKeys.map(ageLevel => {
    return { id: ageLevel, value: ageLevel, text: ageLevel };
  });
}

function TrainingDetails({ training }: TrainingDetailsProps) {
  const { data: trainingDetail, run, isPending } = useAsync();
  const [isOpen, setIsOpen] = useState(false);
  const [relStatus, setRelStatus] = useState<AGE_LEVEL_ABBREVIATIONS>();
  const isLessThan1280px = useMediaQuery({ maxWidth: 1280 });
  const toggleOptions = getReliabilityOptions(trainingDetail?.participants);

  function getColor(status: TRAINING_STATUS) {
    if (status === 'active') {
      return 'yellow';
    } else if (status === 'completed') {
      return 'green';
    } else if (status === 'canceled') {
      return 'red';
    }
  }

  function formatSession(session: Session): string {
    const fDate = moment(session.starts_at).format('LL');
    const fStartTime = moment(session.starts_at).local().format('LT');
    const fEndTime = moment(session.ends_at).local().format('LT');

    return `${fDate} ${fStartTime} - ${fEndTime}`;
  }

  useEffect(() => {
    if (isOpen) {
      run(getAccountTrainingDetail(training.id), {
        onSuccess: (data: any) => {
          const tOptions = getReliabilityOptions(data.participants);
          const firstOption = tOptions[0];

          if (firstOption) {
            setRelStatus(firstOption.value);
          }
        }
      });
    }
  }, [run, isOpen, training]);

  function handleReliabilityStatus(age: AGE_LEVEL_ABBREVIATIONS) {
    setRelStatus(age);
  }

  const hasReliabilityTest =
    trainingDetail &&
    trainingDetail.participants.length &&
    trainingDetail.participants[0].reliability_status;

  return (
    <Wrapper>
      {isOpen ? (
        <>
          <ActionArea onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
            <SeeMoreArea>
              <Icon name="minus circle" color="blue" />
              {i18n.ft(messages.seeLess)}
            </SeeMoreArea>
            <StatusArea>
              <Icon name="circle" color={getColor(training.status)} />
              {i18n.ft(messages[training.status])}
            </StatusArea>
          </ActionArea>

          <Info>
            {!isPending ? (
              <>
                <Sessions>
                  <SessionTitle>{i18n.ft(messages.sessionInfo)}</SessionTitle>
                  {trainingDetail &&
                    trainingDetail.sessions.map(
                      (session: Session, index: number) => (
                        <SessionRow key={index}>
                          <SessionNumber>
                            {i18n.ft(messages.sessionNumber, {
                              number: index + 1
                            })}
                          </SessionNumber>
                          <SessionDates>{formatSession(session)}</SessionDates>
                        </SessionRow>
                      )
                    )}
                </Sessions>
                <Participants>
                  {isLessThan1280px ? (
                    <CardContainer>
                      {trainingDetail &&
                        trainingDetail.participants.map(
                          (participant: Participant, index: number) => {
                            const reliabilityAgeLevel = keys(
                              participant.reliability_status
                            );
                            return (
                              <Card key={index}>
                                <CardItem>
                                  <CardTitle>
                                    {i18n.ft(messages.participant)}
                                  </CardTitle>
                                  <CardDetail>
                                    {participant.name
                                      ? participant.name
                                      : participant.email}
                                  </CardDetail>
                                </CardItem>
                                <CardItem>
                                  <CardTitle>
                                    {i18n.ft(messages.enrollment)}
                                  </CardTitle>
                                  <CardDetail>
                                    {i18n.ft(
                                      messages[participant.enrollment_status]
                                    )}
                                  </CardDetail>
                                </CardItem>
                                <CardItem>
                                  <CardTitle>
                                    {i18n.ft(messages.attendance)}
                                  </CardTitle>
                                  <CardDetail>
                                    {participant.attendance}/
                                    {trainingDetail.sessions.length}
                                  </CardDetail>
                                </CardItem>
                                {participant.reliability_status && (
                                  <CardItem>
                                    <CardTitle>
                                      {i18n.ft(messages.reliability)}
                                    </CardTitle>
                                    <CardReliability>
                                      {reliabilityAgeLevel.map((age, index) => {
                                        const ageLevel = getAgeLevel(age);

                                        return (
                                          <CardDetail key={index}>
                                            <div>{ageLevel.name}:</div>
                                            <Status>
                                              {getStatusLabel(
                                                participant.reliability_status[
                                                  age
                                                ]
                                              )}
                                            </Status>
                                          </CardDetail>
                                        );
                                      })}
                                    </CardReliability>
                                  </CardItem>
                                )}
                              </Card>
                            );
                          }
                        )}
                    </CardContainer>
                  ) : (
                    <>
                      {hasReliabilityTest && (
                        <ReliabilityToggle
                          onChange={handleReliabilityStatus}
                          value={relStatus}
                          options={toggleOptions}
                        />
                      )}
                      <Table allColumns={hasReliabilityTest}>
                        <TableHeader>
                          {i18n.ft(messages.participantList)}
                        </TableHeader>
                        <TableHeader>
                          {i18n.ft(messages.enrollment)}
                        </TableHeader>
                        <TableHeader>
                          {i18n.ft(messages.attendance)}
                        </TableHeader>
                        {hasReliabilityTest && (
                          <TableHeader>
                            {i18n.ft(messages.reliability)}
                          </TableHeader>
                        )}

                        {trainingDetail &&
                          trainingDetail.participants.map(
                            (participant: Participant, index: number) => {
                              return (
                                <React.Fragment key={index}>
                                  <TableColumn>
                                    {participant.name
                                      ? participant.name
                                      : participant.email}
                                  </TableColumn>
                                  <TableColumn>
                                    {i18n.ft(
                                      messages[participant.enrollment_status]
                                    )}
                                  </TableColumn>
                                  <TableColumn>
                                    {participant.attendance}/
                                    {trainingDetail.sessions.length}
                                  </TableColumn>
                                  {participant.reliability_status && (
                                    <TableColumn>
                                      {relStatus &&
                                        getStatusLabel(
                                          participant.reliability_status[
                                            relStatus
                                          ]
                                        )}
                                    </TableColumn>
                                  )}
                                </React.Fragment>
                              );
                            }
                          )}
                      </Table>
                    </>
                  )}
                </Participants>
              </>
            ) : (
              <LoaderWrapper>
                <Loader active inline="centered" />
              </LoaderWrapper>
            )}
          </Info>
        </>
      ) : (
        <ActionArea onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
          <SeeMoreArea aria-label={i18n.ft(messages.seeMoreAriaLabel)}>
            <Icon name="plus circle" color="blue" />
            {i18n.ft(messages.seeMore)}
          </SeeMoreArea>
          <StatusArea>
            <Icon name="circle" color={getColor(training.status)} />
            {i18n.ft(messages[training.status])}
          </StatusArea>
        </ActionArea>
      )}
    </Wrapper>
  );
}

export default TrainingDetails;
