const PATH = 'fe.components.measure.view.examples_and_notes.attachments';

const messages = {
  acceptedFiles: {
    key: `${PATH}.accepted_files`,
    defaultValue:
      'Accepted files: .jpeg, .png, .tiff, .heic, .pdf, and all common video types. Maximum upload file size: 50 MB. No maximum upload size for videos.'
  },
  attachments: {
    key: `${PATH}.attachments`,
    defaultValue: 'Attachments'
  },
  description: {
    key: `${PATH}.description`,
    defaultValue: 'Attach videos, images or scoresheets'
  },
  dragDrop: {
    key: `${PATH}.drag_drop`,
    defaultValue: 'Drag and drop a file here'
  },
  uploadFile: {
    key: `${PATH}.upload_file`,
    defaultValue: 'Upload a file'
  },
  uploading: {
    key: `${PATH}.uploading`,
    defaultValue: 'Uploading...'
  },
  errorOccured: {
    key: `${PATH}.error_occured`,
    defaultValue: 'Error occured'
  },
  successfullyUploaded: {
    key: `${PATH}.successfully_uploaded`,
    defaultValue: 'Successfully Uploaded'
  },
  fileUpload: {
    key: `${PATH}.file_upload`,
    defaultValue: 'file upload'
  },
  or: {
    key: `${PATH}.or`,
    defaultValue: 'or'
  },
  editAttachments: {
    key: `${PATH}.edit_examples`,
    defaultValue: 'Edit attachments'
  },
  noAttachments: {
    key: `${PATH}.no_attachments`,
    defaultValue: 'No attachments'
  }
};

export default messages;
