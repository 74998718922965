import clsx from 'clsx';
import { Icon } from 'semantic-ui-react';

interface FilterElementProps {
  icon?: string;
  name: string;
  secondName?: string;
  onDelete: () => void;
}

function FilterElement({
  icon,
  name,
  secondName,
  onDelete
}: FilterElementProps) {
  const wrapperClasses = clsx(
    'border border-solid border-[#3C3F42]/50 grid gap-1 mt-2.5 p-2.5 bg-white rounded text-[#757575]',
    icon ? 'grid-cols-[20px_1fr_40px]' : 'grid-cols-[1fr_40px] w-fit'
  );

  return (
    <div className={wrapperClasses}>
      {icon && (
        <div className="flex justify-center w-4">
          <img src={icon} alt={''} />
        </div>
      )}
      {secondName ? (
        <div className="flex justify-center px-1">
          {name}
          <div className="flex bg-[#EDF1F5] rounded-full mx-3 w-fit justify-center px-2">
            -
          </div>
          {secondName}
        </div>
      ) : (
        <div className="flex justify-center px-1">{name}</div>
      )}
      <div className="flex justify-end">
        <button onClick={onDelete}>
          <Icon name="close" />
        </button>
      </div>
    </div>
  );
}

export default FilterElement;
