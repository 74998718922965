import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import { Loader } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { useRouteParams } from 'hooks/useRouteParams';
import { useNavigate } from 'react-router-dom';
import MainContent from 'components/MainContent';
import PageWrapper from 'components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import useCurrentUser from 'hooks/useCurrentUser';
import { Video } from 'components/Carousel/Carousel';
import PageBackground from 'components/PageBackground';
import { AspectRatio } from 'components/KalturaVideo/Styled';
import { LinkButton } from 'components/ReliabilityTest/Styled';
import InnerHeader from 'components/ReliabilityTest/InnerHeader';
import InstructionsModal from 'components/Modals/InstructionsModal';
import { PageHeaderWrapper } from 'components/ReliabilityTest/Styled';
import EnterCodesSection from 'components/ReliabilityTest/EnterCodesSection';
import logoCertification from 'images/reliabilityTest/logoCertification.svg';
import ModalContent from 'components/ReliabilityTest/CodingVideos/ModalContent';
import { VideoPlayerContainer } from 'components/ReliabilityTest/CodingVideos/Styled';
import { ContainerSection as Section } from 'components/Reports/Reliability/View/Styled';
import ConfirmationModal from 'components/ReliabilityTest/ConfirmationModal/ConfirmationModal';
import ConfirmationModalBody from 'components/ReliabilityTest/ConfirmationModal/ConfirmationModalBody';
import Notes from 'components/ReliabilityTest/Notes';
import {
  evaluateTestPreparation,
  getTestPreparations
} from 'actions/testPreparations';
import {
  Title,
  LogoImg,
  Subtitle,
  TitleWrapper
} from 'components/PageHeader/Styled';
import { getConfiguration, getDomId } from 'pages/Testing/Reliability/utils';

type URLParams = {
  id: string;
};

function TestPreparations() {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { id } = useRouteParams<URLParams>();
  const { data, run, isPending } = useAsync();
  const isTablet = useMediaQuery({ minWidth: 768 });
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [codingVideo, setCodingVideo] = useState<Video>();

  const kalturaPlayerID = getDomId(id);

  useEffect(() => {
    run(getTestPreparations(currentUser.id, Number(id)));
  }, [currentUser.id, id, run]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const kalturaPlayer = (window as any).KalturaPlayer.setup(
      getConfiguration(kalturaPlayerID)
    );
    kalturaPlayer?.loadMedia({ entryId: data.coding_video?.video_host_id });
    return;
  }, [data, kalturaPlayerID]);

  useEffect(() => {
    if (data) {
      setCodingVideo(data.coding_video);
    }
  }, [data]);

  function handleSubmit() {
    setShowConfirmationModal(false);

    run(evaluateTestPreparation(Number(id)), {
      onSuccess: () => {
        navigate(
          RouteHelpers.getPath('test-preparations-score-report', {
            id: id
          })
        );
      }
    });
  }

  if (isPending) {
    return <Loader active inline="centered" />;
  }

  return (
    <MainContent maxWidth={1280}>
      <ConfirmationModal
        title={i18n.ft(messages.confirmationModal.title)}
        message={<ConfirmationModalBody />}
        dismissButtonText={i18n.ft(messages.confirmationModal.cancel)}
        confirmButtonText={i18n.ft(messages.confirmationModal.submit)}
        isOpen={showConfirmationModal}
        onConfirm={() => handleSubmit()}
        onCancel={() => setShowConfirmationModal(false)}
        onClose={() => setShowConfirmationModal(false)}
      />
      <InstructionsModal
        title={i18n.ft(messages.modal.instructionsTitle)}
        message={<ModalContent />}
        isOpen={showModal}
        confirmButton={i18n.ft(messages.modal.dismissButton)}
        onConfirm={() => setShowModal(false)}
        onClose={() => setShowModal(false)}
      />
      <PageWrapper borderStyle>
        <PageHeaderWrapper>
          <TitleWrapper>
            <LogoImg
              src={logoCertification}
              alt={i18n.ft(messages.logoDescription)}
            />
            <Title>
              {data.coding_video?.video_type === 'practice'
                ? i18n.ft(messages.practice)
                : i18n.ft(messages.training)}
            </Title>
            <Subtitle>{i18n.ft(messages.subtitle)}</Subtitle>
          </TitleWrapper>
        </PageHeaderWrapper>
        <PageBackground borderStyle>
          <InnerHeader
            attempt={
              data.coding_video?.video_type === 'practice'
                ? i18n.ft(messages.practice)
                : i18n.ft(messages.training)
            }
            expiresOn={i18n.ft(messages.expiresOn)}
            expiresOnMobile={i18n.ft(messages.expiresOnMobile)}
            expiresAt={data.expires_at}
            isResources
          />
          <div className="mb-6 xl:mb-8">
            <Section
              mt="8px"
              px="48px"
              py="24px"
              mx={{ _: '0px', lg: '32px' }}
              isMobile
              borderTopRadius={isTablet ? '12px' : ''}
            >
              <div className="text-3xl font-bold mb-3 mt-1">
                {data.coding_video.name}
              </div>
              <div className="flex justify-center">
                <AspectRatio />
                <VideoPlayerContainer
                  id={kalturaPlayerID}
                  isTablet={isTablet}
                />
              </div>
              <LinkButton
                type="button"
                onClick={() => setShowModal(true)}
                className={`mt-5 font-bold mx-auto w-full ${
                  isTablet ? '' : 'text-start'
                }`}
              >
                {i18n.ft(messages.testInstructions)}
              </LinkButton>
              {codingVideo?.note ? <Notes>{codingVideo.note}</Notes> : null}
            </Section>

            <EnterCodesSection
              dimensions={data.coding_video.dimensions}
              testPreparationId={id}
              videoId={data.coding_video.id}
              testType="testPreparations"
              setShowConfirmationModal={setShowConfirmationModal}
            />
          </div>
        </PageBackground>
      </PageWrapper>
    </MainContent>
  );
}

export default TestPreparations;
