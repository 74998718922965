import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { Modal, ModalProps, Button } from 'semantic-ui-react';
import { submitAssessment } from 'actions/envScales/assessments';
import { useState } from 'react';
import { ServerError } from 'types/api/ServerError';

interface SubmitModalProps extends ModalProps {
  /** Assessment ID. */
  assessmentId: number;

  /** Callback fired when the assessment is successfully submitted. */
  onSuccess: () => void;

  /** Callback fired when there are errors on submit. */
  onErrors?: (error: ServerError) => JSX.Element[];

  /** Callback fired when the modal requests to be closed. */
  onClose: () => void;
}

function SubmitModal({
  assessmentId,
  onSuccess,
  onErrors,
  ...props
}: SubmitModalProps) {
  const [errors, setErrors] = useState<JSX.Element[] | null>();
  const { run, isLoading } = useAsync();

  function handleSubmit() {
    run(submitAssessment(assessmentId), {
      onSuccess,
      onError: error => setErrors(onErrors!(error))
    });
  }

  function handleCancel() {
    setErrors([]);
    props.onClose();
  }

  return (
    <Modal size="small" closeOnDimmerClick={!isLoading} {...props}>
      <Modal.Header>{i18n.ft(messages.header)}</Modal.Header>
      <Modal.Content>
        {i18n.ft(messages.description)}
        {errors && errors.map((error, key) => <div key={key}>{error}</div>)}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={handleCancel}
          disabled={isLoading}
          content={i18n.ft(messages.cancel)}
        />
        <Button
          color="green"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit}
          content={i18n.ft(messages.submit)}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default SubmitModal;
