const PATH = 'fe.components.organization.subscriptions.subscription_details';

const messages = {
  activatedBy: {
    key: `${PATH}.activated_by`,
    defaultValue: 'Activated By'
  },
  activatedOn: {
    key: `${PATH}.activated_on`,
    defaultValue: 'Activated On'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  time: {
    key: `${PATH}.time`,
    defaultValue: 'Time'
  },
  noUsers: {
    key: `${PATH}.no_users`,
    defaultValue: 'Subscription has no users'
  }
};

export default messages;
