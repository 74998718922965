import styled from 'styled-components';

export const ActivateProductKeyHeader = styled.div`
  font-size: 14.25px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 0.75em;
`;

export const ButtonBox = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
`;

export const Error = styled.div`
  font-size: 13.6px;
  font-weight: bold;
  color: ${props => props.theme.colors.red};
`;
