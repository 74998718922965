import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import Typography from 'components/Typography';
import { useMediaQuery } from 'react-responsive';
import { Container, Checkbox, Column } from './Styled';

interface ToolbarProps {
  /** If `true`, the `checkbox` element will be active.  */
  active: boolean;

  /** If `true`, the `checkbox` element will be indeterminate. */
  indeterminate: boolean;

  /** Callback fired when the `checkbox` element is clicked. */
  onClick: () => void;

  /** Number of selected participants. */
  selected: number;
}

const Toolbar: React.FC<ToolbarProps> = ({
  active,
  onClick,
  indeterminate,
  selected
}) => {
  const isAtLeast601px = useMediaQuery({ minWidth: 601 });

  return (
    <Container>
      <Column>
        <Checkbox
          checked={active}
          onClick={onClick}
          indeterminate={indeterminate}
        />
      </Column>
      <Column size="large">
        <Typography bold append content={i18n.ft(messages.participants)} />
        {selected > 0 && (
          <Typography>
            {i18n.ft(messages.selected, { number: selected })}
          </Typography>
        )}
      </Column>
      {isAtLeast601px && (
        <React.Fragment>
          <Column size="small">
            <Typography bold content={i18n.ft(messages.paymentStatus)} />
          </Column>
          <Column size="small">
            <Typography bold content={i18n.ft(messages.status)} />
          </Column>
        </React.Fragment>
      )}
    </Container>
  );
};

export default Toolbar;
