import i18n from 'helpers/i18n';
import messages from './messages';
import { Icon } from 'semantic-ui-react';
import DisplayVideos from './DisplayVideos';
import VideoNavigation from './VideoNavigation';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useId, useState } from 'react';

export type Video = {
  id: number;
  video_host_id: string;
  name: string;
  note?: string; // public-facing note
};

interface CarouselProps {
  title: string;
  videos: Video[];
  ariaLabel?: string;
  testTemplateId: number;
}

function Carousel({ title, videos, ariaLabel, testTemplateId }: CarouselProps) {
  const [sliderLength, setSliderLength] = useState(1);
  const [videoIndex, setVideoIndex] = useState(0);
  const [sliderContainer, setSliderContainer] = useState<HTMLElement | null>(
    null
  );
  const isTablet = useMediaQuery({ minWidth: 640 });
  const pixelsToSlide = isTablet ? 500 : 240;
  const id = useId();

  useEffect(() => {
    const container = document.getElementById(id);
    const slides = isTablet
      ? Math.ceil(videos.length / 2)
      : Math.floor(container!.scrollWidth / pixelsToSlide);
    setSliderLength(slides);
    setSliderContainer(container);
  }, [id, isTablet, videos.length, pixelsToSlide]);

  function previousSlide() {
    sliderContainer!.scrollLeft = sliderContainer!.scrollLeft - pixelsToSlide;

    if (videoIndex > 0) {
      setVideoIndex(prev => prev - 1);
    }
  }

  function nextSlide() {
    sliderContainer!.scrollLeft = sliderContainer!.scrollLeft + pixelsToSlide;

    if (videoIndex < sliderLength - 1) {
      setVideoIndex(prev => prev + 1);
    }
  }

  if (videos.length === 0) {
    return null;
  }

  return (
    <>
      <div className="mt-8 mb-3 py-2 px-3 rounded-lg bg-slate-100 w-fit font-semibold">
        {title}
      </div>
      <div className="flex items-baseline">
        <div
          className="self-center"
          style={{
            color: videoIndex === 0 ? '#EBEFF8' : '#3C3F42'
          }}
        >
          <Icon
            name="angle left"
            size="big"
            className="self-center cursor-pointer"
            onClick={previousSlide}
            aria-label={i18n.ft(messages.previousSlide)}
            tabIndex="0"
          />
        </div>
        <section
          id={id}
          className="mx-0 overflow-hidden scroll-smooth"
          aria-roledescription={i18n.ft(messages.carouselText)}
          aria-label={ariaLabel}
          tabIndex={0}
        >
          <div className="lg:m-1">
            <div className="rounded-b-xl">
              <div className="relative w-full snap-mandatory snap-x flex">
                <DisplayVideos
                  videos={videos}
                  testTemplateId={testTemplateId}
                />
              </div>
            </div>
          </div>
        </section>
        <div
          className="self-center"
          style={{
            color: videoIndex === sliderLength - 1 ? '#EBEFF8' : '#3C3F42'
          }}
        >
          <Icon
            name="angle right"
            size="big"
            className="self-center cursor-pointer"
            onClick={nextSlide}
            aria-label={i18n.ft(messages.nextSlide)}
            tabIndex={0}
          />
        </div>
      </div>
      <div className="text-center">
        <VideoNavigation
          sliderLength={sliderLength}
          currentVideoIndex={videoIndex}
        />
      </div>
    </>
  );
}

export default Carousel;
