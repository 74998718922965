import axios from 'axios';

export function signInWithToken(params = {}) {
  return axios.post('/api/v2/tokens/sign_in', params);
}

export function signUpWithToken(params = {}) {
  return axios.post('/api/v2/tokens/sign_up', params);
}

export function createCartWithToken(params = {}) {
  return axios.post('/api/v2/tokens/create_cart', params);
}
