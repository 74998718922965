import i18n from 'helpers/i18n';
import messages from './messages';
import { useMediaQuery } from 'react-responsive';
import videoCamera from 'images/reliabilityTest/videoCamera.svg';

interface UnwatchedVideoProps {
  id: number;
}

export default function UnwatchedVideo(props: UnwatchedVideoProps) {
  const { id } = props;
  const isTablet = useMediaQuery({ minWidth: 768 });

  return (
    <>
      <div
        className={`${
          isTablet ? 'w-12 h-12' : 'w-10 h-10'
        } bg-white border-2 border-grey-light mx-auto rounded-full flex items-center`}
      >
        <span className="text-gray-300 text-center w-full">
          <img
            src={videoCamera}
            alt={i18n.ft(messages.videoNumber, { value: id + 1 })}
            className="inline"
          />
        </span>
      </div>
      {isTablet ? (
        <div className="text-sm pt-2 whitespace-nowrap">
          {i18n.ft(messages.videoNumber, { value: id + 1 })}
        </div>
      ) : null}
    </>
  );
}
