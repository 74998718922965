/**
 *
 * This component needs to be migrated!!!!
 * to a new generic version component
 *
 */

import {
  ModalButton,
  ModalHeader,
  ModalAction,
  ModalIcon,
  CancelModalButton
} from './Styled';
import { useMediaQuery } from 'react-responsive';
import submitIcon from 'images/reliabilityTest/submitIcon.svg';
import { Modal, ModalProps, SemanticShorthandContent } from 'semantic-ui-react';
import { useRef } from 'react';

interface ConfirmationModalProps extends ModalProps {
  /**Title for the modal */
  title: string | SemanticShorthandContent;

  /**Content message for the modal */
  message: SemanticShorthandContent;

  /**Text associated with the text button to cancel the modal action */
  dismissButtonText: string;

  /**Text associated with the text button to complete the modal action */
  confirmButtonText: string;

  /** Node associate with the trigger of the modal */
  triggerNode?: React.ReactNode;

  /**Function call on cofirmation */
  onConfirm: () => void;

  /**Function call on cancel */
  onCancel: () => void;

  /**true when modal is open */
  isOpen: boolean;

  /**true on KnowledgeTest */
  knowledgeTest?: boolean;

  /**true when the Knowledge Test has all the answers completed */
  isReadyToSubmit?: boolean;
}

function ConfirmationModal({
  title,
  message,
  dismissButtonText,
  confirmButtonText,
  triggerNode,
  onConfirm,
  onCancel,
  onClose,
  isOpen,
  knowledgeTest,
  isReadyToSubmit
}: ConfirmationModalProps) {
  const isTablet = useMediaQuery({ minWidth: 640 });
  const modalRef = useRef<any>({ current: null });

  const close = () => {
    onCancel();
  };

  function handleConfirmation() {
    onConfirm();
  }

  const handleOnMount = () => {
    const modalElement = document.getElementById('confirmation-modal');

    if (isOpen && modalElement) {
      modalRef.current = modalElement;

      modalRef.current.focus();
    }
  };

  return (
    <Modal
      ref={modalRef}
      id="confirmation-modal"
      tabIndex={0}
      trigger={triggerNode}
      open={isOpen}
      onClose={onClose}
      onMount={handleOnMount}
      closeOnDimmerClick
      size="small"
      className={`${knowledgeTest ? 'px-0 ' : 'px-8 '} md:px-24`}
      style={{
        borderRadius: '17px',
        width: !isTablet ? 'inherit' : knowledgeTest ? '942px' : '642px'
      }}
      dimmer={{ style: { backgroundColor: 'rgba(0,0,0,0.63)' } }}
      autoFocus
    >
      <div className="grid place-content-center mb-8">
        <ModalIcon src={submitIcon} className="w-20" />
      </div>
      <ModalHeader content={title} className="w-full" />
      <Modal.Content content={message} />
      <ModalAction $knowledgeTest={knowledgeTest}>
        <CancelModalButton
          type="button"
          onClick={close}
          content={dismissButtonText}
          className="w-full mb-5"
          $knowledgeTest={knowledgeTest}
          tabIndex={0}
        />
        <ModalButton
          onClick={handleConfirmation}
          content={confirmButtonText}
          className="w-full mb-5"
          $isTestIncomplete={knowledgeTest ? !isReadyToSubmit : false}
          disabled={knowledgeTest ? !isReadyToSubmit : false}
          tabIndex={0}
        />
      </ModalAction>
    </Modal>
  );
}

export default ConfirmationModal;
