const PATH = 'fe.components.reports.observation.configuration_card';

const messages = {
  hierarchy: {
    key: `${PATH}.hierarchy`,
    defaultValue: 'Hierarchy'
  },
  assessmentLevel: {
    key: `${PATH}.assessment_level`,
    defaultValue: 'CLASS Assessment Level'
  },
  totalObservations: {
    key: `${PATH}.total_observations`,
    defaultValue: 'Total Observations'
  },
  timeframe: {
    key: `${PATH}.timeframe`,
    defaultValue: 'Timeframe'
  }
};

export default messages;
