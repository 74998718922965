import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Training } from 'types/api/Training';
import Typography from 'components/Typography';
import TrainingField from 'components/Trainings/TrainingField';
import AgeLevel, { AgeLevelGroup } from 'components/AgeLevel';
import { AgeLevelGroup as AgeLevelGroupType } from 'types/api/AgeLevelGroup';

import {
  Container,
  Section,
  Content,
  MobileOnly,
  Flex,
  Header,
  AgeLevelContainer
} from './Styled';

import {
  formatDates,
  formatDeliveryMethod,
  formatLocation,
  formatTrainer,
  getTotalParticipants
} from './utils';

interface SummaryProps {
  /** The training object. */
  training: Training & { age_level_group: AgeLevelGroupType };

  /** Reliability report data. */
  report: any;

  /** (Optional) test type */
  testType?: 'reliability' | 'knowledge';
}

const Summary: React.FC<SummaryProps> = ({ testType, training, report }) => {
  return (
    <Container>
      <Header>
        <Typography bold size="huge" display="block" color="blue">
          {training.training_template.title}
        </Typography>
        <AgeLevelContainer>
          <AgeLevelGroup>
            <AgeLevel
              key={training.age_level_group.code}
              abbreviation={training.age_level_group.code}
              replaceK3
            />
          </AgeLevelGroup>
        </AgeLevelContainer>
      </Header>

      <Flex>
        <Section align="left">
          <MobileOnly>
            <Content top={8} bottom={8}>
              <TrainingField
                spacing="normal"
                icon="calendar"
                text={formatDates(training)}
              />
            </Content>
          </MobileOnly>

          <Content top={8} bottom={8}>
            <Typography bold size="massive" color="blue" append>
              {getTotalParticipants(report)}
            </Typography>
            <Typography size="large">
              {i18n.ft(messages.totalCompleted)}
            </Typography>
          </Content>

          <Content top={8}>
            <Button
              as={Link}
              to={`/trainings/${training.id}/details`}
              color="blue"
              size="tiny"
            >
              {i18n.ft(messages.viewDetails)}
            </Button>
          </Content>
        </Section>

        <Section align="center" onlyDesktop>
          <Section fluid align="left">
            <TrainingField
              spacing="normal"
              icon="calendar"
              text={formatDates(training)}
            />

            <TrainingField
              spacing="normal"
              icon="map marker alternate"
              text={formatLocation(training)}
            />
          </Section>
        </Section>

        <Section align="right" onlyDesktop>
          <TrainingField
            spacing="normal"
            icon="user"
            text={formatTrainer(training)}
          />

          <TrainingField
            spacing="normal"
            icon="users"
            text={formatDeliveryMethod(training)}
          />
        </Section>
      </Flex>
    </Container>
  );
};

export default Summary;
