import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from 'context/auth';
import theme from 'styles/theme';
import I18n from 'i18n-js';
import { ToastContainer } from 'components/Toast';
import useCurrentUser from 'hooks/useCurrentUser';
import useLocale from 'hooks/useLocale';
import { update } from 'store/slices/user';
import { fetchUserMe } from 'actions/userMe';
import { includes } from 'ramda';
import { RoutesConfig } from 'routes';
import { useNewRelic } from 'hooks/useNewRelic';

function IndexPage() {
  useNewRelic();
  const locale = useLocale();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const pathname = window.location.pathname;

  useEffect(() => {
    if (includes(pathname, ['/single_logout'])) {
      setIsLoading(false);
      return;
    }

    fetchUserMe().then(
      response => {
        dispatch(update(response.data));
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, [dispatch, pathname]);

  if (isLoading) {
    return null;
  }

  const lang = currentUser?.preferred_language ?? locale;
  I18n.locale = lang;
  moment.locale(lang);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <RoutesConfig />
        </BrowserRouter>
        <ToastContainer hideProgressBar />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default IndexPage;
