import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import { Link } from 'react-router-dom';
import RoutesHelper from 'helpers/routes';
import useCurrentUser from 'hooks/useCurrentUser';
import { SelectedAccount } from 'types/api/Account';
import { getAccountsByArea } from 'actions/accountManager/accounts';
import { getCurrentAccount } from 'components/Organization/AccountManager/utils';
import { ObserversByAgeLevel } from 'components/Certifications/ObserversByAgeLevel';

function CertificationsHome() {
  const currentUser = useCurrentUser();

  const { data: accounts, run: runAccounts } = useAsync<SelectedAccount[]>({
    data: []
  });

  const AREA = 'CertificationsDashboard';

  const currentAccountId = getCurrentAccount(
    currentUser.current_account_id,
    accounts
  );

  useEffect(() => {
    runAccounts(getAccountsByArea(AREA));
  }, [runAccounts]);

  return (
    <div className="mt-12">
      <div className="pb-3">
        <Link
          to={RoutesHelper.getPath('certifications-observers')}
          className="text-black text-2xl font-semibold hover:text-black"
        >
          {i18n.ft(messages.observer)}
        </Link>
      </div>
      <ObserversByAgeLevel account={currentAccountId} />
    </div>
  );
}

export default CertificationsHome;
