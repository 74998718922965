import i18n from 'helpers/i18n';
import messages from '../messages';
import { ImprovementCoachingCycle } from 'types/api/improvement/ImprovementCoachingCycle';
import { EducatorInformation } from 'pages/Improvements/types';
import { getFullName } from 'helpers/user';
import useCurrentUser from 'hooks/useCurrentUser';
import moment from 'moment';

interface ObservationDetailsProps {
  coachingCycle: ImprovementCoachingCycle;
  educator: EducatorInformation;
}

const ObservationDetails = ({
  coachingCycle,
  educator
}: ObservationDetailsProps) => {
  const currentUser = useCurrentUser();
  const date_of_observation = moment(
    coachingCycle.scheduled_observation_time
  ).format('MMMM DD, YYYY');
  const time_of_observation = moment(
    coachingCycle.scheduled_observation_time
  ).format('LT');

  return (
    <div className="print:break-inside-avoid">
      <h3 className="font-normal">
        {i18n.ft(messages.observationDetails.details)}
      </h3>
      <div className="flex gap-6 mb-6">
        <div className="basis-1/3">
          <h4 className="!mb-2">
            {i18n.ft(messages.observationDetails.participants)}
          </h4>
          <ul>
            <li className="mb-3">
              <i className="fa-solid fa-user mr-3 text-[#888]" />
              {`${educator.first_name} ${educator.last_name} (educator)`}
            </li>
            <li>
              <i className="fa-solid fa-user mr-3 text-[#888]" />
              {`${getFullName(currentUser)} (coach)`}
            </li>
          </ul>
        </div>
        <div className="basis-1/3">
          <h4 className="!mb-2">
            {i18n.ft(messages.observationDetails.observationSchedule)}
          </h4>
          <ul>
            <li className="mb-3">
              <i className="fa-solid fa-calendar-days mr-3 text-[#888]" />
              {date_of_observation}
            </li>
            <li>
              <i className="fa-sharp fa-regular fa-clock mr-3 text-[#888]" />
              {time_of_observation}
            </li>
          </ul>
        </div>
        <div className="basis-1/3">
          <h4 className="!mb-2">
            {i18n.ft(messages.observationDetails.cycleFocus)}
          </h4>
          <ul>
            <li className="mb-3">
              <i className="fa-solid fa-bullseye mr-3 text-[#888]" />
              {coachingCycle.improvement_focus?.name}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ObservationDetails;
