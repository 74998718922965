import { useEffect } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';

const newRelic = (window as any).newrelic;

export function useNewRelic() {
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (!newRelic) {
      return;
    }

    newRelic.setUserId(currentUser?.guid ?? null);
    newRelic.setCustomAttribute('user_email', currentUser?.email ?? null);
    newRelic.setCustomAttribute('user_name', currentUser?.name ?? null);
    newRelic.setCustomAttribute(
      'user_roles',
      currentUser?.roles?.join() ?? null
    );
    newRelic.setCustomAttribute(
      'account_id',
      currentUser?.current_account_id ?? null
    );
  }, [
    currentUser.guid,
    currentUser.email,
    currentUser.name,
    currentUser.roles,
    currentUser.current_account_id
  ]);

  return null;
}
