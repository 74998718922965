const PATH = 'fe.pages.trainings.view.checkout_completed';

const messages = {
  orderConfirmed: {
    key: `${PATH}.order_confirmed`,
    defaultValue: 'Order Confirmed'
  },
  description: {
    key: `${PATH}.description`,
    defaultValue:
      'Your payment was completed. A receipt has been emailed to {{email}}. If you do not receive this email, please contact Teachstone Customer Support.'
  },
  returnToTraining: {
    key: `${PATH}.return_to_training`,
    defaultValue: 'Return to your training'
  }
};

export default messages;
