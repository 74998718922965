import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  margin-bottom: 16px;

  @media screen and (max-width: 700px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const ErrorWrapper = styled.div`
  color: ${p => p.theme.colors['red']};
  text-align: right;

  /* For link inside error message */
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const Subtitle = styled.div`
  font-size: 14px;
`;

export const Actions = styled.div`
  flex-shrink: 0;
  margin-left: 16px;

  @media screen and (max-width: 700px) {
    margin-left: 0;
    margin-top: 16px;
    align-self: flex-end;
  }
`;
