import i18n from 'helpers/i18n';
import messages from './messages';
import { Wrapper, ScoreCheckbox } from './Styled';
import { GenericCheckbox } from 'components/GenericCheckbox';

const SCORES = [1, 2, 3, 4, 5, 6, 7];

interface DimensionScoreInputProps {
  /** Dimension score value. */
  value: number | null;

  /** Callback fired then the dimension score changes. */
  onChange: (value: number | null) => void;

  /** Array of suggested dimension scores. */
  suggestedScores: number[];
}

export function DimensionScoreInput({
  value,
  onChange,
  suggestedScores
}: DimensionScoreInputProps) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const score = parseInt(event.target.value);
      onChange(score);
    } else {
      onChange(null);
    }
  }

  return (
    <Wrapper>
      {SCORES.map(score => (
        <GenericCheckbox
          key={score}
          value={score}
          checked={value === score}
          onChange={handleChange}
          type="checkbox"
          aria-label={i18n.ft(messages.dimensionScore, { value: score })}
        >
          <ScoreCheckbox
            checked={value === score}
            suggested={!value && suggestedScores.includes(score)}
          >
            {score}
          </ScoreCheckbox>
        </GenericCheckbox>
      ))}
    </Wrapper>
  );
}
