import * as R from 'ramda';
import { AssessmentScore } from 'types/api/envScales/AssessmentScore';

export function getInitialScores(scores: AssessmentScore[]) {
  let values: { [key: number]: string | null } = {};

  scores.forEach(domain => {
    domain.categories.forEach(category => {
      category.items.forEach(item => {
        values[item.scale_id] =
          item.score !== null ? item.score.toString() : item.score;
      });
    });
  });

  return values;
}

export function validateScores(values: { [key: number]: string | null }) {
  let errors: { [key: number]: boolean } = {};

  R.forEachObjIndexed((value, key) => {
    if (R.isNil(value)) {
      errors[key] = true;
    }
  }, values);

  return errors;
}
