import * as R from 'ramda';
import { UserMe } from 'types/api/User';

export function pickUserFields(user: UserMe) {
  return R.pickAll<UserMe, Partial<UserMe>>(
    [
      'first_name',
      'last_name',
      'email',
      'state_registry_id',
      'phone',
      'email_notification_preference',
      'preferred_language',
      'street_address_1',
      'street_address_2',
      'country',
      'locality',
      'region',
      'postal_code'
    ],
    user
  );
}
