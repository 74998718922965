const PATH = 'fe.components.dropdowns.email_notifications';

const messages = {
  none: {
    key: `${PATH}.none`,
    defaultValue: 'Never send a summary'
  },
  day: {
    key: `${PATH}.day`,
    defaultValue: 'Send a summary every day'
  },
  week: {
    key: `${PATH}.week`,
    defaultValue: 'Send a summary every week'
  },
  month: {
    key: `${PATH}.month`,
    defaultValue: 'Send a summary every month'
  }
};

export default messages;
