import * as Yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import styled from 'styled-components';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { purchaseTraining } from 'actions/trainings';
import queryString from 'query-string';
import { Formik, Form, Input, Checkbox } from 'components/EnhancedForm';

const Container = styled.div`
  max-width: 400px;
`;

const Label = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const validationSchema = Yup.object({
  payment_source: Yup.string().required(),
  payment_id: Yup.string()
    .nullable()
    .when('payment_source', ([source], schema) =>
      source === 'salesforce'
        ? schema.required(i18n.ft(messages.errors.paymentId.required))
        : schema
    )
});

const CheckoutForm = ({ trainingId, participants, afterSubmit }) => {
  const [loading, setLoading] = useState(false);

  function handleChange(setFieldValue) {
    return value => {
      if (value !== 'salesforce') {
        setFieldValue('payment_id', null);
      }
    };
  }

  function handleSubmit(values) {
    setLoading(true);
    purchaseTraining(trainingId, {
      ...values,
      participant_ids: participants
    }).then(() => {
      setLoading(false);
      afterSubmit();
    });
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{ payment_source: 'cvent', payment_id: null }}
    >
      {({ values, setFieldValue, submitForm }) => (
        <Form>
          <Container>
            <Label>{i18n.ft(messages.paymentMethod)}</Label>
            <div>
              <Checkbox
                radio
                value="cvent"
                name="payment_source"
                label={i18n.ft(messages.orderedInCvent)}
                onChange={handleChange(setFieldValue)}
              />
            </div>

            <div>
              <Checkbox
                radio
                value="salesforce"
                name="payment_source"
                label={i18n.ft(messages.orderedInSalesforce)}
                onChange={handleChange(setFieldValue)}
              />
            </div>

            <div>
              <Checkbox
                radio
                value="bigcommerce"
                name="payment_source"
                label={i18n.ft(messages.payWithCard)}
                onChange={handleChange(setFieldValue)}
              />
            </div>

            {values.payment_source === 'salesforce' && (
              <Input name="payment_id" label={i18n.ft(messages.projectId)} />
            )}

            <Button
              replace
              as={Link}
              to={RouteHelpers.getPath('td-trainings-view-participants', {
                id: trainingId
              })}
              disabled={loading}
              content={i18n.ft(messages.cancel)}
            />

            {values.payment_source === 'bigcommerce' ? (
              <Button
                replace
                as={Link}
                to={queryString.stringifyUrl({
                  url: RouteHelpers.getPath('td-trainings-view-card-checkout', {
                    id: trainingId
                  }),
                  query: { participants }
                })}
                color="green"
                loading={loading}
                disabled={loading}
                content={i18n.ft(messages.continue)}
              />
            ) : (
              <Button
                color="green"
                content={i18n.ft(messages.payNow)}
                onClick={submitForm}
                loading={loading}
                disabled={loading}
              />
            )}
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default CheckoutForm;
