import styled from 'styled-components';
import { Pagination as SemanticPagination } from 'semantic-ui-react';

export const Wrapper = styled.div`
  padding: 10px;

  @media screen and (min-width: 640px) {
    padding: 20px;
  }

  @media screen and (min-width: 1280px) {
    padding: 40px;
  }
`;

export const SubscriptionContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(243, 247, 255, 0.5);
  padding: 10px;
  border-radius: 10px;
  box-shadow: -1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  justify-content: center;

  @media screen and (min-width: 640px) {
    padding: 30px;
  }

  @media screen and (min-width: 1280px) {
    padding: 40px;
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 0.5fr 0.3fr;
  grid-gap: 8px;
  width: 100%;
  margin-bottom: 30px;
`;

export const HeaderItem = styled.div`
  background-color: #eaeff8;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  height: fit-content;
`;

export const FirstHeaderItem = styled.div`
  background-color: #eaeff8;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 30px;
  height: fit-content;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaginationWrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 16px;

  @media screen and (min-width: 640px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    margin-top: 64px;
  }
`;

export const Pagination = styled(SemanticPagination)`
  &.ui.pagination > .item.active {
    font-weight: 700;
    color: ${p => p.theme.colors['white']};
    background-color: ${p => p.theme.colors['primary']['700']};
  }
`;

export const NoSubscriptions = styled.div`
  width: 100%;
  background-color: ${p => p.theme.colors.white};
  box-shadow: -1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  padding: 8px 5px;
  border-radius: 10px;
  text-align: center;
`;

export const LoaderWrapper = styled.div`
  margin: 64px 0 48px;
`;

export const SmallHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-gap: 8px;
  width: 100%;
  margin-bottom: 20px;
`;
