import { useState, SyntheticEvent } from 'react';
import { Loader } from 'semantic-ui-react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Wrapper, LoaderWrapper } from './Styled';
import { ENV_SCALES_URL } from 'constants/routes';

interface VideoPlayerProps {
  observationVideoId: number;
}

function VideoPlayer({ observationVideoId }: VideoPlayerProps) {
  const [loading, setLoading] = useState(true);
  const videoPlayerEndpoint = `${ENV_SCALES_URL}/api/v1/observation_videos/${observationVideoId}/video_player`;

  function handleLoad(event: SyntheticEvent<HTMLIFrameElement>) {
    setLoading(false);

    if (event.currentTarget !== null) {
      event.currentTarget.width = '100%';
      event.currentTarget.height = '100%';
    }
  }

  return (
    <Wrapper className="mt-6 bg-black/50">
      {loading && (
        <LoaderWrapper>
          <Loader active inverted>
            {i18n.ft(messages.loading)}
          </Loader>
        </LoaderWrapper>
      )}

      <iframe
        src={videoPlayerEndpoint}
        title={i18n.ft(messages.frameTitle)}
        allowFullScreen
        width="0px"
        height="0px"
        onLoad={handleLoad}
        scrolling="no"
      />
    </Wrapper>
  );
}

export default VideoPlayer;
