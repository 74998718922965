import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Divider, Container, Button } from 'semantic-ui-react';

interface ActionsProps {
  /** Action type. Used to determine which buttons to include. */
  actionType: 'create' | 'edit';

  /** Name of the resource being viewed or edited. */
  resource?: string;

  /** If `true`, enables actions associated to editing the form. */
  editing?: boolean;

  /** If `true`, enables a button to go back.  */
  showBack?: boolean;

  /** If `true`, enables a button to edit the form. */
  allowEdit?: boolean;

  /** Callback fired when cancel button is clicked. */
  onCancel?: () => void;

  /** Callback fired when edit button is clicked. */
  onEdit?: () => void;

  /** Route for index page associated to the resource. If `null`, the button won't be displayed. */
  pathToIndex?: string | object;

  /** Route for miscellaneous action. If `null`, the button won't be displayed. */
  pathForMisc?: string;

  /** Button title for miscellaneous action. */
  titleForMisc?: string;

  /** Show loading inside of button. */
  loading?: boolean;
}

function Actions(props: ActionsProps) {
  const context = useFormikContext();

  if (!context) {
    throw new Error('<Actions /> must be used within <Formik />');
  }

  const { dirty, isValid, submitForm, resetForm } = context;

  function onCancel() {
    resetForm();
    props.onCancel?.();
  }

  function renderEditActions() {
    return (
      <div>
        {props.actionType === 'edit' ? (
          <Button
            key="cancel"
            type="button"
            icon="times"
            content={i18n.ft(messages.actions.cancel)}
            onClick={onCancel}
            labelPosition="left"
          />
        ) : (
          <React.Fragment>
            {props.pathToIndex && (
              <Button
                as={Link}
                to={props.pathToIndex}
                key="toIndex"
                type="button"
                icon="left chevron"
                content={props.resource}
                labelPosition="left"
              />
            )}
          </React.Fragment>
        )}

        {props.showBack && (
          <Button
            onClick={() => window.history.back()}
            key="back"
            type="button"
            icon="left chevron"
            content={i18n.ft(messages.actions.back)}
            labelPosition="left"
          />
        )}

        <Button
          key="submit"
          type="button"
          icon="check"
          color="green"
          content={
            props.actionType === 'edit'
              ? i18n.ft(messages.actions.save)
              : i18n.ft(messages.actions.create)
          }
          disabled={!dirty || !isValid || props?.loading}
          labelPosition="left"
          onClick={submitForm}
          loading={props.loading}
        />
      </div>
    );
  }

  function renderViewActions() {
    return (
      <div>
        {props.pathToIndex && (
          <Button
            as={Link}
            to={props.pathToIndex}
            key="toIndex"
            type="button"
            icon="left chevron"
            content={props.resource}
            labelPosition="left"
          />
        )}

        {props.showBack && (
          <Button
            onClick={() => window.history.back()}
            key="back"
            type="button"
            icon="left chevron"
            content={i18n.ft(messages.actions.back)}
            labelPosition="left"
          />
        )}

        {props.pathForMisc && props.titleForMisc && (
          <Button
            key="misc"
            type="button"
            icon="calendar outline icon"
            color="blue"
            content={props.titleForMisc}
            as={Link}
            to={props.pathForMisc}
            labelPosition="left"
          />
        )}

        {props.allowEdit && (
          <Button
            key="edit"
            type="button"
            icon="check"
            color="blue"
            content={i18n.ft(messages.actions.edit)}
            onClick={props.onEdit}
            labelPosition="left"
          />
        )}
      </div>
    );
  }

  return (
    <div className="Actions">
      <Divider />
      <Container textAlign="right" fluid>
        {props.editing ? renderEditActions() : renderViewActions()}
      </Container>
    </div>
  );
}

Actions.defaultProps = {
  onCancel: () => {},
  onEdit: () => {},
  allowEdit: true,
  showBack: false
};

export default Actions;
