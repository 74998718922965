import React, { useState } from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import i18n from 'helpers/i18n';
import messages from './messages';

interface RemoveNumberProps {
  /** Callback used to execute a function. */
  onSuccess: () => void;

  /** Boolean used to open the modal. */
  open: boolean;

  /** Callback used to change the boolean that open the alert. */
  handleAlert: () => void;

  /** Boolean used to show different messages. */
  isVerified: boolean;
}

const RemovePhoneModal: React.FC<RemoveNumberProps> = ({
  onSuccess,
  open,
  handleAlert,
  isVerified
}) => {
  const [isOpen, setIsOpen] = useState(open);

  function close() {
    setIsOpen(false);
    handleAlert();
  }

  function confirm() {
    onSuccess();
    close();
  }

  return (
    <Modal open={isOpen} closeOnDimmerClick onClose={close} size="small">
      <Header
        icon="trash"
        content={i18n.ft(messages.deletePhoneNumber.title)}
      />
      <Modal.Content
        content={
          isVerified
            ? i18n.ft(messages.deletePhoneNumber.alertVerified)
            : i18n.ft(messages.deletePhoneNumber.alertNotVerified)
        }
      />
      <Modal.Actions>
        <Button
          onClick={close}
          content={i18n.ft(messages.deletePhoneNumber.cancel)}
        />
        <Button
          onClick={confirm}
          content={i18n.ft(messages.deletePhoneNumber.deleteAction)}
          color="red"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default RemovePhoneModal;
