import styled from 'styled-components';

export const Section: any = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
`;

Section.Top = styled.div<{ p: string }>`
  height: 5px;
  margin: -16px -16px 12px;
  border-radius: 4px 4px 0 0;
  background-color: ${p => p.color};
`;
