import i18n from 'helpers/i18n';
import messages from './messages';
import React, { useContext, useState } from 'react';
import TrainingField from 'components/Trainings/TrainingField';
import {
  formatDate,
  formatLocation,
  formatTrainer
} from 'components/Trainings/TrainingField/utils';
import TrainingContext from '../TrainingContext';
import {
  DetailsContainer,
  ActionButton,
  StyledDropdown,
  EditButton,
  StyledButtonGroup
} from './Styled';
import Countdown from '../Countdown';
import ResponsiveContent from 'components/ResponsiveContent';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Permission from 'components/Subscription/Permission';
import { hasStarted } from 'helpers/training';
import CompleteModal from './CompleteModal';
import ReopenModal from './ReopenModal';
import OrganizationField from '../../OrganizationField';
import { StateProps } from './types';
import RouteHelpers from 'helpers/routes';
import { useNavigate } from 'react-router-dom';

function Details() {
  const { training, reloadTraining } = useContext(TrainingContext);
  const currentUser = useSelector((state: StateProps) => state.user);
  const userIsTrainer = training.trainer.id === Number(currentUser.id);
  const trainingIsActive = training.status === 'active';
  const trainingIsCompleted = training.status === 'completed';
  const [modalOpen, setModalOpen] = useState(false);
  const [reopenModalOpen, setReopenModalOpen] = useState(false);
  const navigate = useNavigate();

  const buttonOptions = () => [
    {
      key: 'edit',
      text: i18n.ft(messages.createDuplicate),
      value: 'edit',
      onClick: () => {
        navigate(
          RouteHelpers.getPath('td-catalog-new', {
            id: training.training_template.id
          }),
          {
            replace: true,
            state: {
              notes_title: training.notes_title,
              notes: training.notes
            }
          }
        );
      }
    },
    {
      key: 'new',
      text: i18n.ft(messages.createNew),
      value: 'new',
      onClick: () => {
        navigate(RouteHelpers.getPath('td-catalog'), {
          replace: true
        });
      }
    }
  ];

  return (
    <DetailsContainer>
      <CompleteModal
        size="small"
        open={modalOpen}
        trainingId={training.id}
        afterSubmit={reloadTraining}
        onClose={() => setModalOpen(false)}
      />

      <ReopenModal
        size="small"
        open={reopenModalOpen}
        trainingId={training.id}
        afterSubmit={reloadTraining}
        onClose={() => setReopenModalOpen(false)}
      />

      <TrainingField
        icon="calendar"
        text={formatDate(training)}
        spacing="normal"
      />
      <TrainingField
        icon="map marker alternate"
        text={formatLocation(training)}
        spacing="normal"
      />
      <TrainingField
        icon="user"
        text={formatTrainer(training.trainer)}
        spacing="normal"
      />

      {training.account?.name && (
        <OrganizationField name={training.account?.name} />
      )}

      <ResponsiveContent
        renderMobile={<Countdown inline />}
        renderDesktop={null}
      />
      <Permission
        zone="trainer_dashboard"
        resource="trainings"
        operation="update"
        or={userIsTrainer}
      >
        {trainingIsActive && (
          <React.Fragment>
            <StyledButtonGroup>
              <EditButton
                fluid
                color="blue"
                size="small"
                forwardedAs={Link}
                to={RouteHelpers.getPath('td-trainings-view-edit', {
                  id: training.id
                })}
                content={i18n.ft(messages.edit)}
              />
              <StyledDropdown
                color="blue"
                className="button icon"
                floating
                options={buttonOptions()}
                trigger={<></>}
              />
            </StyledButtonGroup>
            <ActionButton
              fluid
              color="green"
              size="small"
              onClick={() => setModalOpen(true)}
              disabled={!hasStarted(training)}
              content={i18n.ft(messages.markAsCompleted)}
            />
          </React.Fragment>
        )}
        {trainingIsCompleted && (
          <ActionButton
            fluid
            color="green"
            size="small"
            onClick={() => setReopenModalOpen(true)}
            content={i18n.ft(messages.reopen)}
          />
        )}
      </Permission>
    </DetailsContainer>
  );
}

export default Details;
