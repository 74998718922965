import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import RoutesHelper from 'helpers/routes';
import Avatar from 'components/Avatar';
import { Button } from 'semantic-ui-react';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import MainContent from 'components/MainContent';
import useCurrentUser from 'hooks/useCurrentUser';
import ImageUploader from 'components/Account/ImageUploader';
import { getInitials, getFullName } from 'helpers/user';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { Modal } from 'semantic-ui-react';

import {
  Header,
  Wrapper,
  ContentWrapper,
  Sidebar,
  ProfileWrapper,
  ProfileName,
  NavWrapper,
  NavList,
  NavItem,
  NavLink,
  MainWrapper
} from './Styled';

function AccountPage() {
  useDocumentTitle(i18n.ft(messages.documentTitle));
  const currentUser = useCurrentUser();
  const isMediumScreen = useMediaQuery({ minWidth: 768 });
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <MainContent as="div" maxWidth={1280}>
      <Modal open={isOpen} size="tiny" onClose={closeModal}>
        <Modal.Content>
          <ImageUploader onClose={closeModal} />
        </Modal.Content>
      </Modal>
      <Wrapper>
        <Header>{i18n.ft(messages.header)}</Header>
        <ContentWrapper>
          <Sidebar>
            <ProfileWrapper>
              <Avatar
                src={currentUser.avatar_url}
                content={getInitials(currentUser)}
                size={isMediumScreen ? 'xLarge' : 'large'}
              />
              <ProfileName>{getFullName(currentUser)}</ProfileName>
              <Button
                size="mini"
                type="button"
                onClick={openModal}
                content={i18n.ft(messages.changePhoto)}
              />
            </ProfileWrapper>
            <NavWrapper>
              <NavList>
                <NavItem>
                  <NavLink end to={RoutesHelper.getPath('account')}>
                    {i18n.ft(messages.profile)}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={RoutesHelper.getPath('account-phone')}>
                    {i18n.ft(messages.mobileLogin)}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={RoutesHelper.getPath('account-password')}>
                    {i18n.ft(messages.changePassword)}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={RoutesHelper.getPath('account-experience')}>
                    {i18n.ft(messages.experience)}
                  </NavLink>
                </NavItem>
              </NavList>
            </NavWrapper>
          </Sidebar>
          <MainWrapper>
            <Outlet />
          </MainWrapper>
        </ContentWrapper>
      </Wrapper>
    </MainContent>
  );
}

export default AccountPage;
