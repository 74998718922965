const PATH = 'fe.components.trainings.view.participants.edit_email_modal';

const messages = {
  areYouSure: {
    key: `${PATH}.are_you_sure`,
    defaultValue: 'Are you sure you want to edit the participant email?'
  },
  dismiss: {
    key: `${PATH}.dismiss`,
    defaultValue: 'Dismiss'
  },
  confirm: {
    key: `${PATH}.confirm`,
    defaultValue: 'Yes, edit email'
  }
};

export default messages;
