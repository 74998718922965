import i18n from 'helpers/i18n';
import messages from './messages';
import { useMediaQuery } from 'react-responsive';
import currentVideoIcon from 'images/reliabilityTest/currentVideo.svg';

export default function CurrentVideo(props: { id: number }) {
  const { id } = props;
  const isTablet = useMediaQuery({ minWidth: 768 });

  return (
    <>
      <div
        className={`${
          isTablet ? 'w-12 h-12' : 'w-10 h-10'
        } bg-white border-2 border-cyan-700 mx-auto rounded-full text-lg flex items-center`}
      >
        <span className="text-current text-center w-full">{id + 1}</span>
      </div>
      <div className="text-xs pt-2 text-center">
        <img
          src={currentVideoIcon}
          alt={i18n.ft(messages.currentVideo)}
          className="inline"
        />
      </div>
    </>
  );
}
