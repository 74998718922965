import styled from 'styled-components';

export const PageWrapper = styled.div`
  margin-top: 32px;
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: 640px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 72px;
    padding-right: 72px;
  }
`;

export const Container = styled.div`
  margin: 10px;

  @media screen and (min-width: 640px) {
    margin: 20px;
  }

  @media screen and (min-width: 1024px) {
    margin: 50px;
  }
`;

export const Title = styled.h3`
  margin-bottom: 20px !important;
`;
