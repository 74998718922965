const PATH = 'fe.pages.testing.reliability.score_report.result_section';

const messages = {
  congratulationsMessage: {
    key: `${PATH}.congratulations_message`,
    defaultValue: 'Congratulations, {{value}}!'
  },
  sorryMessage: {
    key: `${PATH}.sorry_message`,
    defaultValue: `We're sorry, {{value}}!`
  },
  passMessage: {
    key: `${PATH}.pass_message`,
    defaultValue: `<span style="color: #7AA03F">You passed</span> the `
  },
  notPassMessage: {
    key: `${PATH}.not_pass_message`,
    defaultValue: `Unfortunately you <span style="color: #AC213A">did not pass</span> the `
  },
  videoCriteria: {
    key: `${PATH}.video_criteria`,
    defaultValue:
      'You must correctly code at least 80% of the dimensions across all five videos.'
  },
  scoreAbove: {
    key: `${PATH}.score_above`,
    defaultValue: 'You scored above'
  },
  scoreBelow: {
    key: `${PATH}.score_below`,
    defaultValue: 'You scored below'
  },
  twoOfFiveMessage: {
    key: `${PATH}.two_of_five_message`,
    defaultValue:
      'For each dimension, you must correctly code at least two of the five videos.'
  },
  twoOfFiveRule: {
    key: `${PATH}.two_of_five_rule`,
    defaultValue: `You met the <span class='font-bold'>2 of 5</span> rule`
  },
  failTwoOfFiveRule: {
    key: `${PATH}.failed_two_of_five_rule`,
    defaultValue: `You didn't meet the <span class='font-bold'>2 of 5</span> rule`
  },
  certificationPage: {
    key: `${PATH}.certification_page`,
    defaultValue:
      'To view and manage all your certifications, including when to recertify and access to your observer cards, visit the <a href="{{url}}">Certifications</a> page.'
  },
  attemptTwoAdditional: {
    key: `${PATH}.attempt_two_additional`,
    defaultValue:
      'Additionally, please review the resources available on the <a href="https://teachstone.com/reliability-support/" target="_blank">Reliability Support</a> page, including the downloadable age level tip sheet PDF before taking Attempt 3.'
  },
  attemptTip: {
    key: `${PATH}.attempt_tip`,
    defaultValue: 'Attempt tip icon'
  },
  nextSteps: {
    key: `${PATH}.next_steps`,
    defaultValue: 'Participant Next Steps'
  },
  suggestions: {
    key: `${PATH}.suggestions`,
    defaultValue:
      'Training participants will be offered the following suggestions following each test attempt:'
  },
  certification: {
    key: `${PATH}.certification`,
    defaultValue: 'Pass: Access their new certification'
  },
  attemptOne: {
    key: `${PATH}.attempt_one`,
    defaultValue: 'Fail, Attempt 1:'
  },
  support: {
    key: `${PATH}.support`,
    defaultValue: 'Review Reliability Support'
  },
  resources: {
    key: `${PATH}.resources`,
    defaultValue: 'resources.'
  },
  attemptTwo: {
    key: `${PATH}.attempt_two`,
    defaultValue: 'Fail, Attempt 2:'
  },
  schedule: {
    key: `${PATH}.schedule`,
    defaultValue: 'Schedule Reliability Support'
  },
  call: {
    key: `${PATH}.call`,
    defaultValue: 'call with Teachstone.'
  },
  attemptThree: {
    key: `${PATH}.attempt_three`,
    defaultValue: 'Fail, Attempt 3: Register for another Observation Training.'
  },
  eachCategory: {
    key: `${PATH}.each_category`,
    defaultValue:
      'You must correctly answer at least 50% of the questions in each category.'
  },
  categoryOverFifty: {
    key: `${PATH}.category_over_fifty`,
    defaultValue: 'Each category over 50%'
  },
  questionsCorrectly: {
    key: `${PATH}.questions_correctly`,
    defaultValue:
      'You must correctly answer at least 80% of all questions correctly.'
  },
  certifications: {
    key: `${PATH}.certifications`,
    defaultValue: 'Certifications'
  },
  observerNote: {
    key: `${PATH}.observer_note`,
    defaultValue: 'Your observer certification has been renewed.'
  },
  observerKnowledgeTestNote: {
    key: `${PATH}.observer_knowledge_test_note`,
    defaultValue:
      'A new trainer certification is now available on the certifications page.'
  },
  trainerFirstEditionNote: {
    key: `${PATH}.trainer_first_edition_note`,
    defaultValue: 'Your observer and trainer certifications have been renewed.'
  },
  trainerSecondEditionNote: {
    key: `${PATH}.trainer_second_edition_note`,
    defaultValue:
      'Your observer certification has been renewed. To renew your trainer certification, please complete the trainer knowledge test that is now available on the certifications page.'
  }
};

export default messages;
