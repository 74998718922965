import i18n from 'helpers/i18n';
import messages from './messages';
import { Icon } from 'semantic-ui-react';
import { Wrapper, DateSpan, OffsetIcon } from './Styled';
import DatePicker from './DatePicker';

export type OptionalDate = Date | null | undefined;

interface DateRangePickerProps {
  /** Start date. */
  startDate: OptionalDate;

  /** End date. */
  endDate: OptionalDate;

  /** Callback fired when any of the dates change. */
  onChange: (value: [OptionalDate, OptionalDate]) => void;
}

function DateRangePicker({
  startDate,
  endDate,
  onChange
}: DateRangePickerProps) {
  const today = new Date();

  return (
    <Wrapper>
      <DatePicker
        selected={startDate}
        placeholderText="MM/DD/YYYY"
        onChange={(date: Date) => onChange([date, endDate])}
        maxDate={endDate || today}
      />

      <DateSpan>{i18n.ft(messages.to)}</DateSpan>

      <DatePicker
        selected={endDate}
        placeholderText="MM/DD/YYYY"
        onChange={(date: Date) => onChange([startDate, date])}
        maxDate={today}
        minDate={startDate}
      />

      <OffsetIcon>
        <Icon fitted name="calendar alternate outline" />
      </OffsetIcon>
    </Wrapper>
  );
}

export default DateRangePicker;
