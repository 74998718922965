import { useEffect } from 'react';
import * as R from 'ramda';
import Sidebar from 'components/Dashboard/Sidebar';
import I18nHelper from 'helpers/i18n';
import { getPossessiveUserFirstName } from 'helpers/user';
import Services from 'components/Dashboard/Services';
import useAsync from 'hooks/useAsync';
import MainContent from 'components/MainContent';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from 'hooks/useCurrentUser';
import { fetchUserMe } from 'actions/userMe';
import { useDispatch } from 'react-redux';
import { update } from 'store/slices/user';
import { getSisEnrollments } from 'actions/users';
import ProfessionalDevelopment from 'components/Dashboard/ProfessionalDevelopment';
import { Flex } from 'components/Flex';

const i18n = new I18nHelper('fe.pages.dashboard');

function Dashboard() {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const userName = getPossessiveUserFirstName(
    currentUser.first_name,
    currentUser.preferred_language
  );
  const { run, data: enrollments, isSuccess } = useAsync();
  let serviceKeys = R.map(R.prop('key'), currentUser.services);
  const showLearningResources = serviceKeys.includes('PDLibrary');

  useEffect(() => {
    fetchUserMe().then(response => {
      dispatch(update(response.data));
    });
  }, [dispatch]);

  useEffect(() => {
    document.title = i18n.t('user_dashboard', '{{user}} Dashboard', {
      name: userName
    });
  }, [userName]);

  useEffect(() => {
    run(getSisEnrollments(currentUser.id));
  }, [run, currentUser.id]);

  if (currentUser.id && !currentUser.onboarding_viewed_at) {
    navigate('/users/onboarding', { replace: true });
  }

  return (
    <MainContent maxWidth={1024}>
      <div className="dashboard-wrapper">
        <div className="body-left">
          <Sidebar user={currentUser} />
        </div>
        <div className="body-right">
          <p className="welcome">
            {i18n.t('user_dashboard', '{{name}} Dashboard', {
              name: getPossessiveUserFirstName(
                currentUser.first_name,
                currentUser.preferred_language
              )
            })}
          </p>
          <div className="body-content">
            {currentUser.is_LR_off_with_teacher_role &&
            R.isEmpty(currentUser.services) ? (
              <div>
                <div>
                  <p>
                    {i18n.t(
                      'services_not_availabe',
                      'You do not have any active services, feel free to check out these free resources available on our '
                    )}
                    <a href="https://teachstone.com/resources/">
                      {i18n.t('public_website', 'public website.')}
                    </a>
                  </p>
                </div>
                <div className="contact">
                  <p>
                    {i18n.t(
                      'contact_message',
                      'If you have questions please reach out to our Customer Support team.'
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <Flex direction="column">
                {isSuccess ? (
                  <ProfessionalDevelopment
                    enrollments={enrollments.user_sis_enrollments}
                    learningResources={showLearningResources}
                    recommendations={[]}
                  />
                ) : null}
                <Services />
              </Flex>
            )}
          </div>
        </div>
      </div>
    </MainContent>
  );
}

export default Dashboard;
