import { useEffect } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Icon } from 'semantic-ui-react';
import useAsync from 'hooks/useAsync';
import AccountManager from 'components/Organization/AccountManager';
import { getCurrentAccount } from 'components/Organization/AccountManager/utils';
import { getAccountsByArea } from 'actions/accountManager/accounts';
import { SelectedAccount } from 'types/api/Account';
import useCurrentUser from 'hooks/useCurrentUser';
import { getSettings } from 'actions/envScales/settings';
import { SettingQuestion } from 'types/api/envScales/SettingQuestion';
import VideosHome from 'components/Measure/Home/VideosHome';
import DefaultHome from 'components/Measure/Home/DefaultHome';
import PageLoader from 'components/PageLoader';

import {
  EmptyObservations,
  NoObservationsMessage,
  EmptyMessageContainer
} from './Styled';

const AREA = 'DataCollection';

type AccountSettingsReq = {
  account_setting: SettingQuestion;
};

function Home() {
  const currentUser = useCurrentUser();
  const {
    data: accounts,
    run: runAccounts,
    isPending: isLoadingAccounts
  } = useAsync<SelectedAccount[]>({ data: [] });
  const currentAccountId = getCurrentAccount(
    currentUser.current_account_id,
    accounts
  );
  const {
    run: runAccountSettings,
    data: accountSettings,
    isPending: isLoadingSettings
  } = useAsync<AccountSettingsReq>();

  const preSelectedAccount: SelectedAccount = {
    id: currentAccountId,
    guid: '',
    name: ''
  };

  const currentAccount = isLoadingAccounts
    ? preSelectedAccount
    : accounts.find(a => a.id === currentAccountId);

  const currentAccountGuid = currentAccount?.guid;

  useEffect(() => {
    runAccounts(getAccountsByArea(AREA));
  }, [runAccounts]);

  useEffect(() => {
    if (!currentAccountGuid) {
      return;
    }

    runAccountSettings(getSettings(currentAccountGuid));
  }, [currentAccountGuid, runAccountSettings]);

  const isAccountSelected = currentAccountId > 0;
  const isVideoAccount = accountSettings?.account_setting.video_enabled;

  return (
    <div>
      <AccountManager
        area={AREA}
        accounts={accounts}
        currentAccountId={currentAccountId}
        isLoadingAccounts={isLoadingAccounts}
      />

      {isLoadingAccounts ? (
        <PageLoader />
      ) : isAccountSelected ? (
        isLoadingSettings ? (
          <PageLoader />
        ) : isVideoAccount ? (
          <VideosHome
            account={currentAccount!}
            accountSettings={accountSettings.account_setting}
          />
        ) : (
          <DefaultHome
            key={currentAccount!.id}
            account={currentAccount!}
            accountSettings={accountSettings.account_setting}
          />
        )
      ) : (
        <EmptyObservations>
          <Icon size="big" color="red" name="attention" />
          <EmptyMessageContainer>
            <NoObservationsMessage>
              {i18n.ft(messages.selectAccount)}
            </NoObservationsMessage>
          </EmptyMessageContainer>
        </EmptyObservations>
      )}
    </div>
  );
}

export default Home;
