export function composeHierarchy(hierarchy: string[][]) {
  if (hierarchy.length === 0) {
    return null;
  }

  const list = hierarchy.map((nodes, idx) => {
    if (nodes.length === 0) {
      return null;
    }

    if (nodes.length === 1) {
      return (
        <span key={idx} className="font-semibold">
          {nodes[0] + (idx !== hierarchy.length - 1 ? ',' : '')}
        </span>
      );
    }

    const lastNode = nodes.at(-1);

    return (
      <span key={idx}>
        {nodes.slice(0, -1).join(' / ') + ' / '}
        <span className="font-semibold">
          {lastNode + (idx !== hierarchy.length - 1 ? ',' : '')}
        </span>
      </span>
    );
  });

  return <span className="flex flex-wrap gap-1">{list}</span>;
}
