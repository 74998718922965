const PATH = 'fe.components.measure.home.videos_home';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'CLASS Video Observations'
  },
  center: {
    key: `${PATH}.center`,
    defaultValue: 'Center'
  },
  classroom: {
    key: `${PATH}.classroom`,
    defaultValue: 'Classroom'
  },
  language: {
    key: `${PATH}.language`,
    defaultValue: 'Language'
  },
  doubleCode: {
    key: `${PATH}.double_code`,
    defaultValue: 'Double Code'
  },
  capturedOn: {
    key: `${PATH}.captured_on`,
    defaultValue: 'Captured On'
  },
  submittedOn: {
    key: `${PATH}.submitted_on`,
    defaultValue: 'Submitted On'
  },
  primary: {
    key: `${PATH}.primary`,
    defaultValue: 'Primary'
  },
  secondary: {
    key: `${PATH}.secondary`,
    defaultValue: 'Secondary'
  },
  anchorCoded: {
    key: `${PATH}.anchor_coded`,
    defaultValue: 'Anchor Coded'
  },
  review: {
    key: `${PATH}.review`,
    defaultValue: 'Review Video'
  },
  viewScores: {
    key: `${PATH}.view_scores`,
    defaultValue: 'View Scores'
  },
  selectStatus: {
    key: `${PATH}.select_status`,
    defaultValue: 'Select Observation Status'
  },
  submitted: {
    key: `${PATH}.submitted`,
    defaultValue: 'Submitted'
  },
  inQA: {
    key: `${PATH}.in_qa`,
    defaultValue: 'In QA'
  },
  accepted: {
    key: `${PATH}.accepted`,
    defaultValue: 'Accepted'
  },
  inProgress: {
    key: `${PATH}.in_progress`,
    defaultValue: 'In-Progress'
  },
  awaitingApproval: {
    key: `${PATH}.awaiting_approval`,
    defaultValue: 'Awaiting Approval'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed'
  },
  noVideos: {
    key: `${PATH}.no_videos`,
    defaultValue: 'There are no "{{status}}" Video Observations'
  },
  countSummary: {
    key: `${PATH}.count_summary`,
    defaultValue: '{{current}} out of {{total}} showing'
  },
  modal: {
    retrieveNewVideo: {
      key: `${PATH}.modal.retrieve_new_video"`,
      defaultValue: 'Retrieve New Video'
    },
    error: {
      key: `${PATH}.modal.error"`,
      defaultValue: 'Error!'
    },
    errorProcessingRequest: {
      key: `${PATH}.modal.error_processing_request"`,
      defaultValue: 'There was an error processing your request'
    },
    returnToSubmitted: {
      key: `${PATH}.modal.return_to_submitted"`,
      defaultValue: 'Return to Submitted'
    },
    warning: {
      key: `${PATH}.modal.warning"`,
      defaultValue:
        'Are you sure you want to return this video to Submitted? The QA assignee and all associated QA data will be removed.'
    },
    warningAssessment: {
      key: `${PATH}.modal.warning_assessment"`,
      defaultValue: 'Are you sure you want to remove the assessment?'
    },
    warningObserver: {
      key: `${PATH}.modal.warning_observer"`,
      defaultValue: 'Are you sure you want to remove the Observer?'
    },
    confirm: {
      key: `${PATH}.modal.confirm"`,
      defaultValue: 'Yes, return video'
    },
    confirmRemove: {
      key: `${PATH}.modal.confirm_remove"`,
      defaultValue: 'Yes, remove'
    }
  },
  videosThumbnail: {
    key: `${PATH}.videos_thumbnail`,
    defaultValue: "Video's Thumbnail"
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Classroom or Center Name'
  },
  removeAssessment: {
    key: `${PATH}.remove_assessment`,
    defaultValue: 'Remove Assessment'
  },
  removeObserver: {
    key: `${PATH}.remove_observer`,
    defaultValue: 'Remove Observer'
  }
};

export default messages;
