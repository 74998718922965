const PATH = 'fe.pages.measure.summary';

const messages = {
  review: {
    key: `${PATH}.review`,
    defaultValue: 'Review Observation'
  },
  logo: {
    key: `${PATH}.logo`,
    defaultValue: 'Environmental Scales logo'
  },
  return: {
    key: `${PATH}.return`,
    defaultValue: 'Return to List'
  },
  delete: {
    key: `${PATH}.delete`,
    defaultValue: 'Delete'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Submit'
  },
  requestUpdates: {
    key: `${PATH}.request_updates`,
    defaultValue: 'Request Updates'
  },
  approve: {
    key: `${PATH}.approve`,
    defaultValue: 'Approve Observation'
  },
  modal: {
    messageRequest: {
      key: `${PATH}.modal.message_request`,
      defaultValue: `Are you sure you want to request updates for this observation? You can't undo this action`
    },
    buttonRequest: {
      key: `${PATH}.modal.button_request`,
      defaultValue: 'Yes, Request'
    },
    messageApprove: {
      key: `${PATH}.modal.message_approve`,
      defaultValue: `Are you sure you want to approve for this observation? You can't undo this action`
    },
    buttonApprove: {
      key: `${PATH}.modal.button_approve`,
      defaultValue: 'Yes, Approve'
    }
  }
};

export default messages;
