import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';

export function getValidationSchema(loginWithPhone?: boolean) {
  const passwordValidation = yup
    .string()
    .required(() => i18n.ft(messages.errors.password.required));

  if (loginWithPhone) {
    return yup.object({
      emailOrPhone: yup
        .string()
        .required(() => i18n.ft(messages.errors.phone.required)),
      password: passwordValidation
    });
  } else {
    return yup.object({
      emailOrPhone: yup
        .string()
        .email(() => i18n.ft(messages.errors.email.required))
        .required(() => i18n.ft(messages.errors.email.required)),
      password: passwordValidation
    });
  }
}
