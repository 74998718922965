const PATH = 'fe.components.organization.home';

const messages = {
  subscriptions: {
    key: `${PATH}.subscriptions`,
    defaultValue: 'Subscriptions'
  },
  trainings: {
    key: `${PATH}.trainings`,
    defaultValue: 'Trainings'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed'
  },
  inSession: {
    key: `${PATH}.in_session`,
    defaultValue: 'In session'
  },
  scheduled: {
    key: `${PATH}.scheduled`,
    defaultValue: 'Scheduled'
  },
  active: {
    key: `${PATH}.active`,
    defaultValue: 'Active Subscriptions'
  },
  activeUsers: {
    key: `${PATH}.active_users`,
    defaultValue: 'Active Subscriptions Users'
  },
  trainingsAriaLabel: {
    key: `${PATH}.trainings_aria_label`,
    defaultValue: 'Trainings summary'
  },
  subscriptionsAriaLabel: {
    key: `${PATH}.subscriptions_aria_label`,
    defaultValue: 'Subscriptions summary'
  },
  reporting: {
    key: `${PATH}.reporting`,
    defaultValue: 'Reporting Settings'
  },
  schoolYear: {
    key: `${PATH}.school_year`,
    defaultValue: 'School year starting month'
  }
};

export default messages;
