import i18n from 'helpers/i18n';
import { InformationCard } from '../../Styled';
import messages from '../messages';
import { Form, Formik } from 'components/EnhancedForm';
import { Button } from 'semantic-ui-react';
import ObservationDetails from '../ObservationDetails';
import { ImprovementCoachingCycle } from 'types/api/improvement/ImprovementCoachingCycle';
import { EducatorInformation } from 'pages/Improvements/types';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import { NOTES_MAX_CHAR_LENGTH } from 'pages/Improvements/EducatorProfile/utils';

import {
  createActionPlanNote,
  updateCoachingCycle
} from 'actions/improvements';
import { TextArea } from 'components/EnhancedForm';
import React, { useState } from 'react';

interface InitialStateProps {
  coachingCycle: ImprovementCoachingCycle;
  educator: EducatorInformation;
  onSubmit: (updatedCoachingCycle: ImprovementCoachingCycle) => void;
}

const InitialState = ({
  coachingCycle,
  educator,
  onSubmit
}: InitialStateProps) => {
  const initialValues = {
    improvement_in_action_note: coachingCycle.in_action_note?.response
  };

  const [noteText, setNoteText] = useState<string | undefined>(
    coachingCycle.in_action_note?.response
  );
  const [touched, setTouched] = useState<{ [valueName: string]: boolean }>({
    improvement_in_action_note: false
  });

  const handleSubmit = async (values: any) => {
    await createActionPlanNote(
      coachingCycle.id,
      values.improvement_in_action_note
    );
    const { data: updatedCoachingCycle } = await updateCoachingCycle(
      coachingCycle.id,
      {
        observation_started: true
      }
    );

    onSubmit(updatedCoachingCycle);
  };

  function handleChange(
    event: React.ChangeEvent<HTMLTextAreaElement>,
    setFieldValue: any
  ) {
    const { name, value } = event.target;

    setFieldValue(name, value, false);
    setTouched({ [name]: true });
    setNoteText(value);
  }

  useDebouncedEffect(
    async () => {
      if (!noteText) {
        return;
      }
      if (!touched['improvement_in_action_note']) {
        return;
      }

      await createActionPlanNote(coachingCycle.id, noteText);
      setTouched({ improvement_in_action_note: false });
    },
    1000,
    [noteText, touched]
  );

  return (
    <InformationCard className="p-8">
      <header className="print:hidden">
        <h2>{i18n.ft(messages.initialState.checkin)}</h2>
        <h3>{i18n.ft(messages.initialState.observationEmptyState)}</h3>
      </header>
      <p className="print:hidden">
        {i18n.ft(messages.initialState.notesDescription)}
      </p>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="print:hidden">
              <TextArea
                name="improvement_in_action_note"
                rows={10}
                maxLength={NOTES_MAX_CHAR_LENGTH}
                placeholder={i18n.ft(messages.responsePlaceholder)}
                onChange={v => handleChange(v, setFieldValue)}
                value={values.improvement_in_action_note}
              />
            </div>

            <ObservationDetails
              coachingCycle={coachingCycle}
              educator={educator}
            />

            <div className="pt-4">
              <Button
                type="submit"
                content={i18n.ft(messages.initialState.submit)}
                color="blue"
                className="float-right"
              />
            </div>
          </Form>
        )}
      </Formik>
    </InformationCard>
  );
};

export default InitialState;
