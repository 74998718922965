import styled, { css } from 'styled-components';
import { Header } from 'semantic-ui-react';

import {
  Input as InputBase,
  DateInput as DateInputBase,
  TimeInput as TimeInputBase,
  Select as SelectBase,
  Checkbox as CheckboxBase,
  RichTextEditor
} from 'components/EnhancedForm';

export const Section = styled.div`
  padding: 16px 16px 10px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
`;

export const SectionTop = styled.div`
  height: 5px;
  margin: -16px -16px 12px;
  border-radius: 4px 4px 0 0;
  background-color: ${p => p.color};
`;

export const SectionHeader = styled(Header)`
  font-size: 16px !important;
`;

export const TemplateBanner = styled.div<{ src?: string }>`
  height: 40vw;
  max-height: 350px;
  margin-bottom: 16px;
  background-color: gray;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const VirtualTrainingLinkInput = styled(InputBase)`
  width: 50%;
  min-width: 480px;

  @media screen and (max-width: 700px) {
    width: 100%;
    min-width: 0;
  }
`;

export const NotesInput = styled(InputBase)`
  width: 50%;
  min-width: 480px;

  @media screen and (max-width: 700px) {
    width: 100%;
    min-width: 0;
  }
`;

export const NotesRichInput = styled(RichTextEditor)`
  width: 100%;

  .RichTextEditor {
    max-width: none;
  }
`;

const FormInput = css`
  width: calc((100% - 32px) / 3);

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const Input = styled(InputBase)`
  ${FormInput}
`;

export const DateInput = styled(DateInputBase)`
  ${FormInput}
`;

export const TimeInput = styled(TimeInputBase)`
  ${FormInput}
`;

export const Select = styled(SelectBase)`
  ${FormInput}
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Checkbox = styled(CheckboxBase)`
  & > .ui.checkbox > input.hidden {
    display: initial;
  }
`;
