import moment from 'moment';
import IconButton from 'components/IconButton';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import {
  HeaderWrapper,
  DateText,
  DateButtons,
  DateWrapper,
  SVGIconButton,
  CaretDownIcon,
  CaretUpIcon
} from './Styled';

export function DatePickerBase(props: ReactDatePickerProps) {
  return (
    <ReactDatePicker
      {...props}
      renderCustomHeader={({
        decreaseMonth,
        increaseMonth,
        increaseYear,
        decreaseYear,
        date
      }) => (
        <HeaderWrapper>
          <IconButton icon="chevron left" onClick={decreaseMonth} />

          <DateWrapper>
            <DateText>{moment(date).format('MMMM YYYY')}</DateText>

            <DateButtons>
              <SVGIconButton onClick={increaseYear}>
                <CaretUpIcon />
              </SVGIconButton>

              <SVGIconButton onClick={decreaseYear}>
                <CaretDownIcon />
              </SVGIconButton>
            </DateButtons>
          </DateWrapper>

          <IconButton icon="chevron right" onClick={increaseMonth} />
        </HeaderWrapper>
      )}
    />
  );
}
