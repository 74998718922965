import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect, useState } from 'react';
import { Icon, Button, Modal } from 'semantic-ui-react';
import useAsync from 'hooks/useAsync';
import { useMeasure } from 'context/measure';
import { getObservationFeedback } from 'actions/envScales/observationFeedback';

interface ReviewModalProps {
  /** Custom className. */
  className?: string;
}

function ReviewModal({ className }: ReviewModalProps) {
  const [isOpen, setIsOpen] = useState(true);
  const { run, data: feedback, isSuccess } = useAsync();
  const { assessment } = useMeasure();
  const observationId = assessment.observation!.id;

  useEffect(() => {
    run(getObservationFeedback(observationId, 'RequestUpdateFeedback'));
  }, [run, observationId]);

  const hasFeedback =
    isSuccess &&
    Boolean(assessment.returned_at) &&
    feedback?.questions_with_answers.length > 0;

  if (!hasFeedback) {
    return null;
  }

  const feedbackText = feedback?.questions_with_answers[0].answer_notes;

  return (
    <Modal
      size="small"
      open={isOpen}
      closeOnDimmerClick={false}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={
        <div className={className}>
          <Button color="green">{i18n.ft(messages.review)}</Button>
        </div>
      }
    >
      <Modal.Header>
        <Icon name="tasks" />
        <span>{i18n.ft(messages.title)}</span>
      </Modal.Header>

      <Modal.Content>
        <div>{feedbackText}</div>
      </Modal.Content>

      <Modal.Actions>
        <Button
          type="button"
          onClick={() => setIsOpen(false)}
          content={i18n.ft(messages.close)}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ReviewModal;
