import { useFormikContext } from 'formik';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect, FocusEvent } from 'react';
import useAsync from 'hooks/useAsync';
import { ImprovementCue } from 'types/api/improvement/ImprovementCues';
import { getImprovementCues } from 'actions/improvements';
import { TextArea } from 'components/EnhancedForm';
import { ImprovementFocus } from 'types/api/improvement/ImprovementFocus';
import Prompt from 'components/Improvements/Prompt';
import {
  setTextWithPlaceholder,
  NOTES_MAX_CHAR_LENGTH
} from 'pages/Improvements/EducatorProfile/utils';

export type UnderstandNoteProps = {
  improvement_additional_cues_note?: string;
  improvement_plan_supports_note?: string;
};

type UnderstandProps = {
  focus: ImprovementFocus;
  disabled?: boolean;
} & UnderstandNoteProps;

function Understand({ disabled, focus }: UnderstandProps) {
  const { run: runCues, data: cues } = useAsync<ImprovementCue[]>();
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    runCues(getImprovementCues({ with_deleted: false }));
  }, [runCues]);

  const childCues = cues?.filter(
    cue => cue.cue_type === 'Child' && cue.improvement_focus?.id === focus?.id
  );
  const educatorCues = cues?.filter(
    cue =>
      cue.cue_type === 'Educator' && cue.improvement_focus?.id === focus?.id
  );

  return (
    <>
      <h2 className="text-[1.125rem] font-normal leading-7 !mb-4">
        {i18n.ft(messages.understand.header)}
      </h2>
      <h3 className="text-[1rem] font-semi-bold leading-6 !mb-0">
        {i18n.ft(messages.understand.observableCuesHeader)}
      </h3>
      <p className="!mt-0">
        {i18n.ft(messages.understand.observableCuesSubtitle)}
      </p>
      <div className="flex gap-6 mb-6">
        <div
          className="border border-[#808183] rounded-lg p-6 basis-1/2 print:break-inside-avoid"
          data-testid="child_cues"
        >
          <h4 className="!mt-0">{i18n.ft(messages.understand.childCues)}</h4>
          <ul className="list-disc ml-6">
            {childCues?.map(cue => <li key={cue.id}>{cue.description}</li>)}
          </ul>
        </div>
        <div
          className="border border-[#808183] rounded-lg p-6 basis-1/2 print:break-inside-avoid"
          data-testid="educator_cues"
        >
          <h4 className="!mt-0">{i18n.ft(messages.understand.educatorCues)}</h4>
          <ul className="list-disc ml-6">
            {educatorCues?.map(cue => <li key={cue.id}>{cue.description}</li>)}
          </ul>
        </div>
      </div>
      <TextArea
        name="improvement_additional_cues_note"
        rows={3}
        maxLength={NOTES_MAX_CHAR_LENGTH}
        label={i18n.ft(messages.understand.additionalCuesNotes)}
        disabled={disabled}
      />
      <div className="lg:flex lg:gap-x-4 justify-between items-stretch">
        <div className="lg:basis-1/2 mt-4">
          <TextArea
            name="improvement_plan_supports_note"
            rows={3}
            maxLength={NOTES_MAX_CHAR_LENGTH}
            label={i18n.ft(messages.understand.additionalSupportInfo)}
            placeholder={i18n.ft(
              messages.understand.additionalSupportInfoPlaceholder
            )}
            disabled={disabled}
            onFocus={(e: FocusEvent<HTMLTextAreaElement>) =>
              setTextWithPlaceholder(e, setFieldValue)
            }
          />
        </div>
        <div className="lg:basis-1/2">
          <Prompt
            prompts={[
              i18n.ft(messages.understand.action_prompt1),
              i18n.ft(messages.understand.action_prompt2),
              i18n.ft(messages.understand.action_prompt3),
              i18n.ft(messages.understand.action_prompt4),
              i18n.ft(messages.understand.action_prompt5)
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default Understand;
