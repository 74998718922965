import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

const StyledButton = styled(Button)<{ active: boolean }>`
  padding: 12px !important;
  background-color: ${({ active, theme }) =>
    active ? theme.colors['blue'] : theme.colors['white']} !important;
  color: ${({ active, theme }) =>
    active ? theme.colors['white'] : theme.colors['neutral']['700']} !important;
`;

interface StatusButtonProps {
  /** Button label. */
  label: string;

  /** When `true`, the button style will change to blue. */
  active?: boolean;

  /** Number of elements associated to the status. */
  count?: number;

  /** Callback fired when the Button is clicked. */
  onClick?: () => void;
}

function StatusButton({ active, label, count, onClick }: StatusButtonProps) {
  return (
    <StyledButton fluid active={active} onClick={onClick}>
      <span className="flex justify-between gap-3 items-center">
        <span>{label}</span>
        <span>{count}</span>
      </span>
    </StyledButton>
  );
}

export default StatusButton;
