import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { isNilOrEmpty, sessionHasData } from './utils';
import { forEach, addIndex, isNil, isEmpty, all, dissoc } from 'ramda';
import * as Yup from 'yup';

type Session = {
  id: number;
  date: string;
  start_time: string;
  end_time: string;
};

function isBeforeTime(timeA: string, timeB: string) {
  const datetimeA = moment(timeA, 'hh:mm A');
  const dateTimeB = moment(timeB, 'hh:mm A');
  return datetimeA.isBefore(dateTimeB);
}

function validateSession(session: Session) {
  let errors: Partial<Session> = {};

  if (isNilOrEmpty(session.date)) {
    errors.date = i18n.ft(messages.errors.date.required);
  }

  if (isNilOrEmpty(session.start_time)) {
    errors.start_time = i18n.ft(messages.errors.startTime.required);
  }

  if (isNilOrEmpty(session.end_time)) {
    errors.end_time = i18n.ft(messages.errors.endTime.required);
  }

  if (isBeforeTime(session.end_time, session.start_time)) {
    errors.end_time = i18n.ft(messages.errors.endTime.afterStart);
  }

  return isEmpty(errors) ? null : errors;
}

function validateTraining(requireTrainer = false) {
  return (training: any) => {
    let errors: any = { sessions: [] };

    addIndex(forEach)((session: any, idx: number) => {
      if (
        (!isNil(training.sessions) && idx < training.sessions.length) ||
        sessionHasData(session)
      ) {
        errors.sessions[idx] = validateSession(session);
      } else {
        errors.sessions[idx] = null;
      }
    }, training.sessions);

    if (all(isNil, errors.sessions)) {
      errors = dissoc('sessions', errors);
    }

    if (isNilOrEmpty(training.trainer_id) && requireTrainer) {
      errors.trainer_id = i18n.ft(messages.errors.trainer.required);
    }

    if (isNilOrEmpty(training.delivery_method)) {
      errors.delivery_method = i18n.ft(messages.errors.deliveryMethod.required);
    }

    if (
      !Yup.string()
        .nullable()
        .url()
        .isValidSync(training.virtual_training_link) ||
      training.virtual_training_link ===
        i18n.ft(messages.virtualTrainingLinkHint)
    ) {
      errors.virtual_training_link = i18n.ft(
        messages.errors.virtualTrainingLink.validUrl
      );
    }

    if (
      !Yup.string()
        .nullable()
        .max(300)
        .isValidSync(training.virtual_training_link)
    ) {
      errors.virtual_training_link = i18n.ft(
        messages.errors.virtualTrainingLink.max
      );
    }

    return errors;
  };
}

export default validateTraining;
