const PATH = 'fe.components.organization.trainings.training_details';

const messages = {
  active: {
    key: `${PATH}.active`,
    defaultValue: 'Active'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed'
  },
  canceled: {
    key: `${PATH}.canceled`,
    defaultValue: 'Canceled'
  },
  seeMore: {
    key: `${PATH}.see_more`,
    defaultValue: 'See more details'
  },
  seeLess: {
    key: `${PATH}.see_less`,
    defaultValue: 'See less details'
  },
  seeMoreAriaLabel: {
    key: `${PATH}.see_more_aria_label`,
    defaultValue:
      'See more details about the training, sessions and participants'
  },
  sessionInfo: {
    key: `${PATH}.session_information`,
    defaultValue: 'Session Information'
  },
  sessionNumber: {
    key: `${PATH}.session_number`,
    defaultValue: 'Session {{number}}: '
  },
  participantList: {
    key: `${PATH}.participant_list`,
    defaultValue: 'Participant List'
  },
  participant: {
    key: `${PATH}.participant`,
    defaultValue: 'Participant'
  },
  enrollment: {
    key: `${PATH}.enrollment`,
    defaultValue: 'Enrollment Status'
  },
  attendance: {
    key: `${PATH}.attendance`,
    defaultValue: 'Attendance'
  },
  reliability: {
    key: `${PATH}.reliability`,
    defaultValue: 'Reliability'
  },
  invited: {
    key: `${PATH}.invited`,
    defaultValue: 'Invited'
  },
  pending: {
    key: `${PATH}.pending`,
    defaultValue: 'Pending'
  },
  registered: {
    key: `${PATH}.registered`,
    defaultValue: 'Registered'
  },
  declined: {
    key: `${PATH}.declined`,
    defaultValue: 'Declined'
  },
  showReliability: {
    key: `${PATH}.show_reliability`,
    defaultValue: 'Show reliability status of {{age}} Age level'
  }
};

export default messages;
