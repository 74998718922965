import styled from 'styled-components';
import FormDropdown from 'components/FormDropdown';
import HierarchyDropdown from 'components/Reports/Observation/HierarchyDropdown';

export const SelectInput = styled(FormDropdown)`
  &.ui.selection.dropdown.disabled {
    background-color: #eaeaea;
    border-color: #a7aaaa;
    opacity: 1;

    .divider.default.text,
    .divider.text {
      color: #808183;
    }
  }
`;

export const HierarchyInput = styled(HierarchyDropdown)`
  &.react-dropdown-tree-select {
    .dropdown {
      .dropdown-trigger.arrow.disabled.bottom:after {
        color: #000000;
      }

      .dropdown-trigger.disabled {
        background-color: #eaeaea;
        border-color: #a7aaaa;
        color: #808183;
      }
    }
  }
`;

interface StepSectionProps {
  /** The step number. */
  step: number;

  /** Optional label when only 1 field is used per row. */
  label?: string;

  /** React node. */
  children: React.ReactNode;
}

export function StepSection({ step, label, children }: StepSectionProps) {
  return (
    <div className="pl-10 pr-14 pt-6 pb-8 border-t border-[#d2d4d4] flex items-center">
      <div className="w-10 h-10 bg-[#3C3F42] rounded-full flex items-center justify-center">
        <span className="text-white font-bold">{step}</span>
      </div>

      <div className="ml-12 flex-1">
        <div>
          {label ? (
            <label className="block mb-1 font-bold text-lg">{label}</label>
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
}
