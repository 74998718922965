import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import { Session } from 'types/api/Session';
import { getTrainingParticipants } from 'actions/trainings';
import { getSessionAttendes } from 'actions/trainingSessions';
import { TrainingAttendee } from 'types/api/SessionAttendance';
import { map, isEmpty, isNil, filter, reject, propEq, find } from 'ramda';
import { TrainingParticipant } from 'types/api/TrainingParticipant';

export function getDefaultSessionId(sessions: Session[]): number | undefined {
  if (isEmpty(sessions)) {
    return;
  } else {
    return sessions[0].id;
  }
}

export function mapSessions(sessions: Session[]) {
  const today = moment();

  return map(
    session => ({
      id: session.id,
      date: moment(session.starts_at).format('LL'),
      startTime: moment(session.starts_at).local().format('LT'),
      endTime: moment(session.ends_at).local().format('LT'),
      disabled: moment(session.starts_at).startOf('day').isAfter(today)
    }),
    sessions
  );
}

export function mapParticipantsAttendance(
  participants: TrainingParticipant[],
  attendance: TrainingAttendee[]
) {
  const paidParticipants = reject(
    propEq('status', 'canceled'),
    reject(
      propEq('status', 'declined'),
      filter(propEq('payment_status', 'paid'), participants)
    )
  );

  return map(participant => {
    const attendee = find(propEq('participant_id', participant.id), attendance);

    return {
      id: participant.id,
      email: participant.email,
      status: participant.status,
      attended: attendee && attendee.attended,
      name: participant.user && participant.user.name,
      avatar: participant.user && participant.user.avatar
    };
  }, paidParticipants);
}

export function useParticipants(
  trainingId: number
): [boolean, TrainingParticipant[], () => void] {
  const [loading, setLoading] = useState(false);
  const [participants, setParticipants] = useState<TrainingParticipant[]>([]);

  const fetchParticipants = useCallback(() => {
    getTrainingParticipants(trainingId).then(({ data }) => {
      setParticipants(data.training_participants);
    });
  }, [trainingId]);

  useEffect(() => {
    setLoading(true);
    fetchParticipants();
    setLoading(false);
  }, [fetchParticipants]);

  return [loading, participants, fetchParticipants];
}

export function useSessionAttendance(
  sessionId?: number
): [
  boolean,
  TrainingAttendee[],
  React.Dispatch<React.SetStateAction<TrainingAttendee[]>>
] {
  const [loading, setLoading] = useState(false);
  const [attendees, setAttendees] = useState<TrainingAttendee[]>([]);

  useEffect(() => {
    if (isNil(sessionId)) {
      return;
    }

    setLoading(true);
    getSessionAttendes(sessionId).then(({ data }) => {
      setAttendees(data.attendees);
      setLoading(false);
    });
  }, [sessionId]);

  return [loading, attendees, setAttendees];
}
