import * as R from 'ramda';
import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import useAsync from 'hooks/useAsync';
import { FormikHelpers } from 'formik';
import { sendSmsCode } from 'actions/sms';
import { Button, Icon } from 'semantic-ui-react';
import useCurrentUser from 'hooks/useCurrentUser';
import { toastError, toastSuccess } from 'components/Toast';
import TextButton from 'components/TextButton';
import RemovePhoneModal from './RemovePhoneModal';

import {
  Formik,
  Form,
  PhoneInput,
  PreventNavigation
} from 'components/EnhancedForm';

import {
  VerificationWrapper,
  VerificationStatus,
  InputWrapper,
  ButtonWrapper
} from './Styled';
import { updateUserMe } from 'actions/userMe';
import { useDispatch } from 'react-redux';
import { update } from 'store/slices/user';

const validationSchema = yup.object({
  phone_number: yup
    .string()
    .required(i18n.ft(messages.errors.phoneNumber.required))
});

interface MobileFormProps {
  /** If `true`, user is currently in the verification step. */
  isVerifying: boolean;

  /** Callback fired after the phone is updated and the sms code is sent. */
  onSuccess: () => void;

  /** Callback fired after the user decided to delete his phone number. */
  removedPhone: () => void;
}

function MobileForm({ isVerifying, onSuccess, removedPhone }: MobileFormProps) {
  const smsRequest = useAsync();
  const currentUser = useCurrentUser();
  const initialPhone = currentUser.login_phone || '';
  const [showAlert, setShowAlert] = useState(false);
  const userMeMutation = useAsync();
  const dispatch = useDispatch();

  const [isInitialNotVerified] = useState(
    !R.isNil(currentUser.login_phone) &&
      R.isNil(currentUser.login_phone_confirmed_at)
  );

  function isVerified(phoneNumber: string) {
    const phoneWithExt =
      phoneNumber.charAt(0) === '+' ? phoneNumber : `+${phoneNumber}`;

    return (
      !R.isNil(currentUser.login_phone_confirmed_at) &&
      currentUser.login_phone === phoneWithExt
    );
  }

  function sendCode() {
    smsRequest.run(sendSmsCode(), {
      onSuccess: () => {
        onSuccess();
        toastSuccess({ description: i18n.ft(messages.success) });
      },
      onError: (error: any) => {
        toastError({ description: error.message });
      }
    });
  }

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    const params = { login_phone: values.phone_number };

    userMeMutation.run(updateUserMe(params), {
      onSuccess: (data: any) => {
        dispatch(update(data));
        sendCode();
      },
      onError: (error: any) => {
        const message =
          error.errors?.user?.login_phone?.[0] ??
          i18n.ft(messages.errors.phoneNumber.invalid);

        if (formik) {
          formik.setFieldError('phone_number', message);
        }
      }
    });
  }

  function removePhoneNumber() {
    userMeMutation.run(updateUserMe({ login_phone: null }), {
      onSuccess: (data: any) => {
        dispatch(update(data));
      }
    });

    if (!isInitialNotVerified) {
      removedPhone();
    }
  }

  function openAlert() {
    setShowAlert(true);
  }

  function closeModal() {
    setShowAlert(false);
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{ phone_number: initialPhone }}
    >
      {({ values, setFieldValue, setFieldTouched }) => (
        <Form>
          <PreventNavigation />
          <InputWrapper>
            <PhoneInput
              name="phone_number"
              label={i18n.ft(messages.fields.phoneNumber)}
            />
          </InputWrapper>

          {isVerified(values.phone_number) && (
            <VerificationWrapper>
              <Icon name="check circle" color="green" />
              <VerificationStatus>
                {i18n.ft(messages.isVerified)}
              </VerificationStatus>
            </VerificationWrapper>
          )}

          {!isVerified(values.phone_number) && isInitialNotVerified && (
            <VerificationWrapper>
              <Icon name="warning circle" color="red" />
              <VerificationStatus>
                {i18n.ft(messages.notVerified)}
              </VerificationStatus>
            </VerificationWrapper>
          )}

          <ButtonWrapper>
            <Button
              type="submit"
              color={isVerifying ? undefined : 'blue'}
              loading={initialPhone === '' && userMeMutation.isLoading}
              disabled={
                isVerified(values.phone_number) || userMeMutation.isLoading
              }
              content={
                isVerified(values.phone_number) ? (
                  i18n.ft(messages.verified)
                ) : isVerifying ? (
                  <>
                    <Icon name="redo" />
                    {i18n.ft(messages.resendCode)}
                  </>
                ) : (
                  i18n.ft(messages.sendCode)
                )
              }
            />
            {initialPhone !== null && initialPhone !== '' && (
              <TextButton type="button" onClick={openAlert}>
                <Icon name="trash" />
                {i18n.ft(messages.remove)}
              </TextButton>
            )}
            {showAlert && (
              <RemovePhoneModal
                onSuccess={() => {
                  setFieldTouched('phone_number', false);
                  setFieldValue('phone_number', '');
                  removePhoneNumber();
                }}
                open={showAlert}
                handleAlert={closeModal}
                isVerified={isVerified(values.phone_number)}
              />
            )}
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
}

export default MobileForm;
