const PATH = 'fe.components.dashboard.reminders';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Actions'
  },
  noActions: {
    key: `${PATH}.no_actions`,
    defaultValue: "Looks like you're all caught up"
  },
  icon: {
    key: `${PATH}.icon`,
    defaultValue: 'icon'
  },
  inviteIcon: {
    key: `${PATH}.invite_icon`,
    defaultValue: 'invite icon'
  },
  trainingIcon: {
    key: `${PATH}.training_icon`,
    defaultValue: 'training icon'
  },
  certificationIcon: {
    key: `${PATH}.certification_icon`,
    defaultValue: 'certification icon'
  },
  testIcon: {
    key: `${PATH}.test_icon`,
    defaultValue: 'test icon'
  },
  reliabilityTestIcon: {
    key: `${PATH}.reliability_test_icon`,
    defaultValue: 'reliability test icon'
  }
};

export default messages;
