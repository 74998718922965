import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import { Link } from 'react-router-dom';
import { useMeasure } from 'context/measure';
import { Button, Icon } from 'semantic-ui-react';
import AttachmentItem from './AttachmentItem';
import VisuallyHidden from 'components/VisuallyHidden';
import { useState, useEffect } from 'react';

import {
  Section,
  SectionHeader,
  SectionContent
} from 'components/Measure/View/Styled';
import { Wrapper } from './Styled';
import useAsync from 'hooks/useAsync';
import { getAttachments } from 'actions/envScales/observations';
import { Attachment } from 'types/api/envScales/Attachment';
import { formatSavedVideo } from './utils';

interface AttachmentViewerProps {
  /** If `true`, the edit button will be displayed. */
  showEdit?: boolean;

  /** If `true`, highlight the section in red indicating there are errors. */
  hasErrors?: boolean;
}

function AttachmentViewer({ showEdit, hasErrors }: AttachmentViewerProps) {
  const { assessment } = useMeasure();
  const observation = assessment.observation!;
  const editLink = RouteHelpers.getPath('measure-examples-notes', {
    id: assessment.id
  });
  const { run } = useAsync();
  const [attachments, setAttachments] = useState<Attachment[]>();

  useEffect(() => {
    run(getAttachments(observation.id), {
      onSuccess: (data: any) => {
        const videoAttachments = observation.video_attachments.map(video =>
          formatSavedVideo(video, assessment.id)
        );
        setAttachments([...data.attachments, ...videoAttachments]);
      }
    });
  }, [run, assessment.id, observation]);

  return (
    <Section mt="8px" error={hasErrors}>
      <Flex justify="space-between" align="center">
        <SectionHeader>{i18n.ft(messages.attachments)}</SectionHeader>

        {showEdit && (
          <Box mr={{ _: '0px', sm: '56px' }}>
            <Button icon color="blue" size="mini" as={Link} to={editLink}>
              <VisuallyHidden>
                {i18n.ft(messages.editAttachments)}
              </VisuallyHidden>
              <Icon fitted name="pencil" />
            </Button>
          </Box>
        )}
      </Flex>

      <SectionContent>
        <Wrapper>
          {attachments?.map(attachment => (
            <AttachmentItem
              attachment={attachment}
              key={`${attachment.id}_${attachment.name}`}
            />
          ))}

          {attachments?.length === 0 && (
            <Flex justify="center">{i18n.ft(messages.noAttachments)}</Flex>
          )}
        </Wrapper>
      </SectionContent>
    </Section>
  );
}

export default AttachmentViewer;
