import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import { getObservationsCount } from 'actions/envScales/reports';
import { OptionalDate } from 'components/Reports/Observation/DateRangePicker';

interface UseReportTotalProps {
  startDate: OptionalDate;
  endDate: OptionalDate;
  ageLevel: string | null;
  nodeIds: number[];
}

export function useReportTotal({
  endDate,
  startDate,
  ageLevel,
  nodeIds
}: UseReportTotalProps) {
  const { run, data } = useAsync();

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }

    const params = {
      start_date: startDate,
      end_date: endDate,
      age_level: ageLevel,
      node_ids: nodeIds
    };

    run(getObservationsCount(params));
  }, [run, endDate, startDate, ageLevel, nodeIds]);

  return data?.total_observations ?? '--';
}
