const PATH = 'fe.pages.measure.home';

const messages = {
  selectAccount: {
    key: `${PATH}.select_account`,
    defaultValue: 'Select an account'
  }
};

export default messages;
