import styled, { css } from 'styled-components';

export const NavButton = styled.button`
  margin: 0 12px;
  padding: 20px 16px;
  border: 0;
  border-radius: 10px;
  color: inherit;
  background-color: transparent;
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    outline: 0;
    color: inherit;
    cursor: pointer;
    background-color: #f5f6f7;
  }
`;

const alignRight = css`
  right: 0;
`;

const alignLeft = css`
  left: 0;
`;

export const NavMenuPanel = styled.div<{ position?: 'left' | 'right' }>`
  z-index: 10;
  width: 305px;
  display: block;
  background-color: white;
  margin: 0 12px;
  padding: 8px 0;
  border-radius: 10px;
  position: absolute;
  bottom: -20px;
  transform: translateY(100%);
  ${p => (p.position === 'right' ? alignRight : alignLeft)}

  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  &:focus {
    outline: 0;
  }
`;

export const NavMenuItem = styled.a`
  display: block;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
  margin: 0;
  border: 0;
  background-color: transparent;
  font-family: inherit;
  width: 100%;
  text-align: left;

  &:hover,
  &:focus {
    outline: 0;
    color: inherit;
    cursor: pointer;
    background-color: #f5f6f7;
  }
`;

export const NavMenuDivider = styled.hr`
  border: 0;
  border-top: 1px solid #dbdbdb;
  width: 48px;
  margin: 8px 16px;
`;
