import { useState } from 'react';
import SendCodeForm from './SendCodeForm';
import VerifyCodeForm from './VerifyCodeForm';

type CodeSteps = 'sendSMS' | 'verifyAndLogin';

function LoginWithCode() {
  const [step, setStep] = useState<CodeSteps>('sendSMS');

  function handleSendCodeSuccess() {
    setStep('verifyAndLogin');
  }

  if (step === 'sendSMS') {
    return <SendCodeForm onSuccess={handleSendCodeSuccess} />;
  } else {
    return <VerifyCodeForm />;
  }
}

export default LoginWithCode;
