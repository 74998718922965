import { ReactComponent as SchoolSolid } from 'images/school-solid.svg';
import { OrganizationWrapper, Circle } from './Styled';
import Typography from 'components/Typography';

interface OrganizationFieldProps {
  name?: string;
}

export default function OrganizationField(props: OrganizationFieldProps) {
  return (
    <OrganizationWrapper>
      <Circle>
        <SchoolSolid width={18} height={18} />
      </Circle>
      <Typography bold>{props.name}</Typography>
    </OrganizationWrapper>
  );
}
