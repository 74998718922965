import { isNil } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import { useState } from 'react';
import { signInWithToken } from 'actions/tokens';
import { parseAPIErrorsNoKeys } from 'components/ErrorModal';
import { Errors, Header, SubHeader } from './Styled';
import LoginManager, { setLoginError } from 'components/LoginManager';
import { UserToken } from 'types/api/UserToken';
import { FormikHelpers } from 'formik';
import TextButton from 'components/TextButton';

interface LoginFormProps {
  /** User token. */
  userToken: UserToken;

  /** Callback fired when the user clicks on `Sign Up`. */
  onSignUp: () => void;

  /** Callback fired when the phone prompt is ready to be shown. */
  onSuccess: () => void;
}

function LoginForm({ userToken, onSignUp, onSuccess }: LoginFormProps) {
  const { run } = useAsync();
  const [errors, setErrors] = useState<string | string[]>();

  function getRedirect(data: any) {
    switch (userToken.type_name) {
      case 'training_invite':
        return RouteHelpers.getPath('td-trainings-view-details', {
          id: data.resource.id
        });
      case 'subscription_invite':
        return RouteHelpers.getPath('user-token-invitation', {
          token: userToken.token
        });
      default:
        return RouteHelpers.getPath('users-login');
    }
  }

  function handleSignIn(data: any) {
    if (data.user.login_phone_prompt) {
      onSuccess();
    } else {
      window.location.replace(getRedirect(data));
    }
  }

  function handleLogin(
    values: any,
    formik: FormikHelpers<any>,
    isFormWithPassword: boolean
  ) {
    const params = {
      user: { token: userToken.token, ...values.user }
    };

    run(signInWithToken(params), {
      onSuccess: (data: any) => {
        formik.setSubmitting(false);
        handleSignIn(data);
      },
      onError: (error: any) => {
        formik.setSubmitting(false);
        const isErrorSet = setLoginError(error, formik, isFormWithPassword);

        if (isErrorSet) {
          return;
        }

        if (error.errors) {
          setErrors(parseAPIErrorsNoKeys(error.errors));
        } else {
          setErrors(i18n.ft(messages.unknownError));
        }
      }
    });
  }

  return (
    <div>
      {errors && <Errors>{errors}</Errors>}

      <LoginManager
        onLogin={handleLogin}
        initialEmailOrPhone={isNil(userToken.user_id) ? '' : userToken.email}
        renderTop={step =>
          step === 'login' ? (
            <>
              <Header>{i18n.ft(messages.header)}</Header>

              <SubHeader>
                {i18n.ft(messages.haveAnAccount) + ' '}
                <TextButton onClick={onSignUp}>
                  {i18n.ft(messages.signUp)}
                </TextButton>
              </SubHeader>
            </>
          ) : null
        }
      />
    </div>
  );
}

export default LoginForm;
