import {
  AssessmentScore,
  CategoryItem
} from 'types/api/envScales/AssessmentScore';

export type ScoreGroups = {
  many: CategoryItem[];
  some: CategoryItem[];
  none: CategoryItem[];
  not_applicable: CategoryItem[];
};

type DomainScores = {
  key: string;
  domainName: string;
  groups: ScoreGroups;
};

export function formatScoreData(domains: AssessmentScore[]): DomainScores[] {
  const formattedData: DomainScores[] = [];

  domains.forEach(domain => {
    const groups: ScoreGroups = {
      many: [],
      some: [],
      none: [],
      not_applicable: []
    };

    domain.categories.forEach(category => {
      category.items.forEach(item => {
        if (item.score === 3) {
          groups.many.push(item);
        } else if (item.score === 2) {
          groups.some.push(item);
        } else if (item.score === 1) {
          groups.none.push(item);
        } else if (item.score === 0) {
          groups.not_applicable.push(item);
        }
      });
    });

    formattedData.push({
      key: domain.t_name,
      domainName: domain.name,
      groups
    });
  });

  return formattedData;
}

export function getDomainColor(domain: string) {
  switch (domain) {
    case 'emotional_support':
      return '#fff7f0';
    case 'classroom_organization':
      return '#fff3f7';
    case 'instructional_support':
      return '#fcf2ff';
    case 'engaged_support_for_learning':
      return '#fcf2ff';
    default:
      return '#fff7f0';
  }
}
