const PATH = 'fe.components.trainings.view.card_checkout.purchase_order_form';

const messages = {
  purchaseOrderNumber: {
    key: `${PATH}.purchase_order_number`,
    defaultValue: 'Purchase Order Number'
  },
  purchaseOrderPDF: {
    key: `${PATH}.purchase_order_pdf`,
    defaultValue: 'Purchase Order PDF'
  },
  policyLabel: {
    key: `${PATH}.policy_label`,
    defaultValue: 'I agree to the Affiliate Cancellation Policy'
  },
  policy01: {
    key: `${PATH}.policy_01`,
    defaultValue: 'I agree to the'
  },
  policy02: {
    key: `${PATH}.policy_02`,
    defaultValue: 'Affiliate Cancellation Policy'
  },
  opensWindow: {
    key: `${PATH}.opens_window`,
    defaultValue: 'Opens new window'
  },
  finishAndPay: {
    key: `${PATH}.finish_and_pay`,
    defaultValue: 'Finish and Pay'
  },
  errors: {
    purchaseOrderNumber: {
      required: {
        key: `${PATH}.errors.purchase_order_number.required`,
        defaultValue: 'Purchase order number is required'
      }
    },
    file: {
      required: {
        key: `${PATH}.errors.file.required`,
        defaultValue: 'Purchase order file is required'
      },
      pdfOnly: {
        key: `${PATH}.errors.file.pdf_only`,
        defaultValue: 'File must be a PDF'
      }
    },
    unknown: {
      key: `${PATH}.errors.unknown`,
      defaultValue: 'An unknown error occurred, please try again later'
    },
    agreeToPolicy: {
      mustAgree: {
        key: `${PATH}.errors.security_code.must_agree`,
        defaultValue:
          'Before you can place your order, you must accept the Affiliate Cancellation Policy'
      }
    }
  }
};

export default messages;
