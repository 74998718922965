const PATH = 'fe.components.trainings.view.details.certificate_section';

const messages = {
  certificate: {
    key: `${PATH}.certificate`,
    defaultValue: 'Certificate of Completion'
  },
  certificateAlt: {
    key: `${PATH}.certificate_alt`,
    defaultValue: 'Certificate icon'
  },
  uponCompletion: {
    key: `${PATH}.upon_completion`,
    defaultValue: 'Upon completion, you will receive'
  },
  download: {
    key: `${PATH}.download`,
    defaultValue: 'Download Certificate'
  },
  learningHours: {
    key: `${PATH}.learning_hours`,
    defaultValue: 'Learning hours earned per session'
  },
  totalLearningHours: {
    key: `${PATH}.total_learning_hours`,
    defaultValue: 'Learning hours earned'
  },
  ceus: {
    key: `${PATH}.ceus`,
    defaultValue: 'CEUs earned per session'
  },
  totalCeus: {
    key: `${PATH}.total_ceus`,
    defaultValue: 'CEUs earned'
  }
};

export default messages;
