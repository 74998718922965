const PATH = 'fe.components.account.phone.sms_code_form';

const messages = {
  enterCode: {
    key: `${PATH}.enter_code`,
    defaultValue:
      'Enter the verification code that was sent to your mobile phone'
  },
  invalidCode: {
    key: `${PATH}.invalid_code`,
    defaultValue: 'Invalid verification code, please try again'
  },
  verify: {
    key: `${PATH}.verify`,
    defaultValue: 'Verify'
  }
};

export default messages;
