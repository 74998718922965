const PATH = 'fe.components.tokens.view.sign_up_form';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Create Account To Accept'
  },
  haveAnAccount: {
    key: `${PATH}.have_an_account`,
    defaultValue: 'Already have an account?'
  },
  login: {
    key: `${PATH}.login`,
    defaultValue: 'Log In'
  },
  errors: {
    email: {
      alreadyInUse: {
        key: `${PATH}.errors.email.already_in_use`,
        defaultValue: 'This account already exists. Please log in'
      }
    },
    unknown: {
      key: `${PATH}.errors.unknown`,
      defaultValue: 'An unknown error occurred, please try again later'
    }
  }
};

export default messages;
