import styled from 'styled-components';

export const CheckboxInput = styled.input.attrs(_props => ({
  type: 'checkbox'
}))`
  appearance: none;
  --size: 32px;
  width: var(--size);
  height: var(--size);
  background-color: #fff;
  border: 0.5px solid black;
  border-radius: 4px;
  cursor: pointer;

  &:checked {
    background-color: ${p => p.theme.colors.scaleGreen};
    border: 0;
  }

  & + label {
    cursor: pointer;
  }

  &:focus {
    outline: 1px solid ${p => p.theme.colors.scaleGreen};
    outline-offset: 3px;
  }
`;
