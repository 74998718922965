const PATH = 'fe.components.training_catalog.create.search_account';

const messages = {
  search: {
    key: `${PATH}.search`,
    defaultValue: 'Search for an organization'
  }
};

export default messages;
