const PATH = 'fe.pages.training_catalog.home';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Select a training template'
  },
  subheader: {
    key: `${PATH}.subheader`,
    defaultValue:
      'Any training for which you are certified to lead appears below. Select the training template for the one you wish to schedule.'
  }
};

export default messages;
