const PATH = 'fe.components.measure.view.cycles.cycle_scores_form';

const messages = {
  enterScores: {
    key: `${PATH}.enter_scores`,
    defaultValue: 'Enter Scores'
  },
  sectionDescription: {
    key: `${PATH}.section_description`,
    defaultValue: 'Select indicator and dimension scores'
  }
};

export default messages;
