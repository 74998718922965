const PATH = 'fe.pages.certifications.standalone_certifications';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Welcome to your CLASS Certifications!'
  },
  subheader: {
    key: `${PATH}.subheader`,
    defaultValue:
      'Demonstrate your knowledge, maintain your CLASS certifications, and access helpful resources.'
  },
  iconAlt: {
    key: `${PATH}.icon_alt`,
    defaultValue: 'Certifications icon'
  },
  useProductKey: {
    key: `${PATH}.use_product_key`,
    defaultValue: 'Use a product key'
  },
  enterProductKey: {
    key: `${PATH}.enter_product_key`,
    defaultValue: 'Enter your product key'
  },
  activateProductKey: {
    key: `${PATH}.activate_product_key`,
    defaultValue: 'Activate Product Key'
  },
  productKeySuccess: {
    key: `${PATH}.product_key_success`,
    defaultValue: 'Success! Your certification has been renewed.'
  },
  recertificationSuccess: {
    key: `${PATH}.recertification_success`,
    defaultValue: 'Success! Access your reliability test below.'
  },
  recertificationFail: {
    key: `${PATH}.recertification_fail`,
    defaultValue: 'Test creation failed. Please contact customer support.'
  },
  learnMore: {
    key: `${PATH}.learn_more`,
    defaultValue: 'Learn More & Get Certified'
  },
  engageCommunity: {
    key: `${PATH}.engage_community`,
    defaultValue: 'Engage in our Community'
  }
};

export default messages;
