import styled from 'styled-components';

export const NumberLabelBox = styled.div`
  width: 32px;
  height: 24px;
  text-align: center;
`;

export const AnswersBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  width: 75%;
  margin: 0 auto;
  font-weight: 700;

  @media screen and (min-width: 1024px) {
    margin: 0;
    width: 204px;
    padding-right: 5px;
  }
`;
