const PATH = 'fe.components.measure.create.input_with_tag';

const messages = {
  closeButton: {
    key: `${PATH}.close_button`,
    defaultValue: 'Close button'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: `You've reached the maximum number of {{label}}`
  },
  optional: {
    key: `${PATH}.optional`,
    defaultValue: `Optional`
  }
};

export default messages;
