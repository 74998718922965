import axios from './axiosConfig';
import { AssessmentTemplate } from 'types/api/envScales/AssessmentTemplate';

type ResponseType = {
  assessment_templates: AssessmentTemplate[];
};

// GET /api/v1/assessment_templates
export function getAssessmentTemplates() {
  return axios.get<ResponseType>('/api/v1/assessment_templates');
}

// GET /api/v2/assessment_templates/:id/age_level/:age_level
export function getAgeLevel(assessmentId: number, ageLevel: string) {
  return axios.get(
    `/api/v1/assessment_templates/${assessmentId}/age_levels/${ageLevel}`
  );
}
