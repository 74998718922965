import { FocusEvent } from 'react';
import { TextArea } from 'components/EnhancedForm';
import { Field, useFormikContext } from 'formik';
import { ImprovementGeneralCue } from 'types/api/improvement/ImprovementGeneralCue';
import messages from '../messages';
import i18n from 'helpers/i18n';
import { ImprovementCue } from 'types/api/improvement/ImprovementCues';
import { ImprovementStrategy } from 'types/api/improvement/ImprovementStrategy';
import { Note } from 'pages/Improvements/types';
import { ImprovedObservedGeneralCue } from 'types/api/improvement/ImprovedObservedGeneralCue';
import { ImprovementObservedFocusCue } from 'types/api/improvement/ImprovementObservedFocusCue';
import HideOnPrint from 'pages/Improvements/HideOnPrint';
import {
  setTextWithPlaceholder,
  NOTES_MAX_CHAR_LENGTH
} from 'pages/Improvements/EducatorProfile/utils';
import { updateGeneralCues, updateFocusCues } from 'actions/improvements';

interface ObservationNotesProps {
  improvementGeneralCues: ImprovementGeneralCue[];
  improvementCues: ImprovementCue[];
  improvementStrategies: ImprovementStrategy[];
  additionalCuesNote?: Note;
  disabled: boolean;
  observedImprovementGeneralCues: ImprovedObservedGeneralCue[];
  observedFocusCues: ImprovementObservedFocusCue[];
  edit?: boolean;
  cycleId: number;
}

const ObservationNotes = ({
  improvementGeneralCues,
  improvementCues,
  improvementStrategies,
  additionalCuesNote,
  disabled,
  observedImprovementGeneralCues,
  observedFocusCues,
  edit = false,
  cycleId
}: ObservationNotesProps) => {
  const educatorsCues = improvementCues?.filter(
    item => item.cue_type === 'Educator'
  );
  const childrenCues = improvementCues?.filter(
    item => item.cue_type === 'Child'
  );

  const strategyList = (
    <>
      {improvementStrategies.map(strategy => (
        <li key={strategy.id}>{strategy.name}</li>
      ))}
    </>
  );

  const { values, setFieldValue, setFieldTouched, handleChange } =
    useFormikContext();

  async function handleTextChangeInForm(event: React.ChangeEvent<any>) {
    handleChange(event);
    await setFieldTouched(event.target.name, true, false);
  }

  async function handleSetFieldValueForCheckboxes(
    name: string,
    updatedFieldValue: any
  ) {
    await setFieldValue(name, updatedFieldValue, false);

    await setFieldTouched(name, true, false);
  }

  function updateObservedFocusCues(
    focusCues: ImprovementCue[],
    observedFocusCues: ImprovementObservedFocusCue[],
    inputCueId: string,
    inputCueChecked: boolean
  ) {
    return focusCues.map(cue => {
      const observed = !!observedFocusCues.find(
        ofc => ofc.improvement_cue_id === cue.id
      )?.observed;
      return {
        improvement_cue_id: cue.id,
        observed: cue.id.toString() === inputCueId ? inputCueChecked : observed
      };
    });
  }

  function updateObservedGeneralCues(
    generalCues: ImprovementGeneralCue[],
    observedGeneralCues: ImprovedObservedGeneralCue[],
    inputCueId: string,
    inputCueChecked: boolean
  ) {
    return generalCues.map(cue => {
      const observed = !!observedGeneralCues.find(
        ogc => ogc.improvement_general_cue_id === cue.id
      )?.observed;
      return {
        improvement_general_cue_id: cue.id,
        observed: cue.id.toString() === inputCueId ? inputCueChecked : observed
      };
    });
  }

  async function handleFocusCueChanges(event: React.ChangeEvent<any>) {
    const { name, value } = event.target;

    const observedCue = {
      improvement_cue_id: value,
      observed: event.target.checked
    };

    updateFocusCues(cycleId, { cues: [observedCue] });

    const formikImprovementCues = (
      values as { improvement_cues: ImprovementObservedFocusCue[] }
    ).improvement_cues;
    const observedCues = updateObservedFocusCues(
      improvementCues,
      formikImprovementCues,
      value,
      event.target.checked
    );

    await handleSetFieldValueForCheckboxes(name, observedCues);
  }

  async function handleGeneralCueChanges(event: React.ChangeEvent<any>) {
    const { name, value } = event.target;

    const observedCue = {
      improvement_general_cue_id: value,
      observed: event.target.checked
    };

    updateGeneralCues(cycleId, { cues: [observedCue] });

    const formikImprovementGeneralCues = (
      values as { improvement_general_cues: ImprovedObservedGeneralCue[] }
    ).improvement_general_cues;
    const observedCues = updateObservedGeneralCues(
      improvementGeneralCues,
      formikImprovementGeneralCues,
      value,
      event.target.checked
    );

    await handleSetFieldValueForCheckboxes(name, observedCues);
  }

  function validateNoteContent(value: string) {
    if (!value || value.length === 0) {
      return i18n.ft(messages.observationNotes.observationNotesValidation);
    }
  }

  return (
    <div>
      <div>
        <h4>{i18n.ft(messages.observationNotes.strategySetHeader)}</h4>
        <ul className="list-disc pl-6 mb-6 font-semibold">{strategyList}</ul>

        <h3>{i18n.ft(messages.observationNotes.observationNotes)}</h3>
        <p>{i18n.ft(messages.observationNotes.observationNotesSubtitle)}</p>
      </div>
      <div className="flex justify-between gap-6 flex-row">
        <div>
          <h4>{i18n.ft(messages.observationNotes.cueChecklist)}</h4>
          <div className="flex flex-col gap-6">
            <div
              role="group"
              className="!border-solid border rounded-md p-4 w-64 border-[#DEDEDF] print:break-inside-avoid"
              aria-labelledby="general-cue-group"
            >
              <h5 id="general-cue-group" className="pb-3">
                {i18n.ft(messages.observationNotes.generalObservationCues)}
              </h5>

              {improvementGeneralCues?.map((cue: ImprovementGeneralCue) => {
                const checked =
                  observedImprovementGeneralCues.find(
                    generalCue =>
                      generalCue.improvement_general_cue_id === cue.id
                  )?.observed || false;

                return (
                  <Checkbox
                    key={cue.id.toString()}
                    name="improvement_general_cues"
                    label={cue.description}
                    value={cue.id.toString()}
                    disabled={disabled}
                    onChange={handleGeneralCueChanges}
                    defaultChecked={checked}
                  />
                );
              })}
            </div>
            <div
              role="group"
              className="!border-solid border rounded-md p-4 w-64 border-[#DEDEDF] print:break-inside-avoid"
              aria-labelledby="general-cue-group"
            >
              <h5 id="general-cue-group" className="pb-3">
                {i18n.ft(
                  messages.observationNotes.improvementObservationEducatorsCues
                )}
              </h5>

              {educatorsCues?.map((cue: ImprovementCue) => {
                const checked =
                  observedFocusCues.find(
                    focusCue => focusCue.improvement_cue_id === cue.id
                  )?.observed || false;

                return (
                  <Checkbox
                    key={cue.id.toString()}
                    name="improvement_cues"
                    label={cue.description}
                    value={cue.id.toString()}
                    disabled={disabled}
                    onChange={handleFocusCueChanges}
                    defaultChecked={checked}
                  />
                );
              })}
            </div>
            <div
              role="group"
              className="!border-solid border rounded-md p-4 w-64 border-[#DEDEDF] print:break-inside-avoid"
              aria-labelledby="general-cue-group"
            >
              <h5 id="general-cue-group" className="pb-3">
                {i18n.ft(
                  messages.observationNotes.improvementObservationChildrensCues
                )}
              </h5>

              {childrenCues?.map((cue: ImprovementCue) => {
                const checked =
                  observedFocusCues.find(
                    focusCue => focusCue.improvement_cue_id === cue.id
                  )?.observed || false;

                return (
                  <Checkbox
                    key={cue.id.toString()}
                    name="improvement_cues"
                    label={cue.description}
                    value={cue.id.toString()}
                    disabled={disabled}
                    onChange={handleFocusCueChanges}
                    defaultChecked={checked}
                  />
                );
              })}
            </div>
            <div
              role="group"
              className="!border-solid border rounded-md p-4 w-64 border-[#DEDEDF]"
              aria-labelledby="general-cue-group"
            >
              <h5 id="general-cue-group" className="pb-3">
                {i18n.ft(
                  messages.observationNotes.additionalCuesFromActionPlan
                )}
              </h5>
              <p>
                {additionalCuesNote
                  ? additionalCuesNote.response
                  : i18n.ft(messages.observationNotes.defaultNoteResponse)}
              </p>
            </div>
          </div>
        </div>

        <div className={HideOnPrint(disabled, 'grow h-full')}>
          <h4>{i18n.ft(messages.observationNotes.observationNotes)}</h4>
          <TextArea
            name="ObservationNote"
            rows={30}
            maxLength={NOTES_MAX_CHAR_LENGTH}
            placeholder={i18n.ft(
              messages.observationNotes.observationNotesPlaceholder
            )}
            validate={(value: string) => validateNoteContent(value)}
            className="mb-0 h-full"
            disabled={disabled && !edit}
            onChange={handleTextChangeInForm}
            onFocus={(e: FocusEvent<HTMLTextAreaElement>) =>
              setTextWithPlaceholder(e, setFieldValue)
            }
          />
        </div>
      </div>
      <div>
        <h4>{i18n.ft(messages.observationNotes.observationSummary)}</h4>
        <p>{i18n.ft(messages.observationNotes.observationSummarySubtitle)}</p>
        <div className="flex gap-4 mb-8">
          <div className="grow h-full">
            <TextArea
              name="SummaryNote"
              rows={10}
              maxLength={NOTES_MAX_CHAR_LENGTH}
              placeholder={i18n.ft(
                messages.observationNotes.observationNotesSummaryPlaceholder
              )}
              validate={(value: string) => validateNoteContent(value)}
              className="mb-0 h-full"
              disabled={disabled && !edit}
              onChange={handleTextChangeInForm}
              onFocus={(e: FocusEvent<HTMLTextAreaElement>) =>
                setTextWithPlaceholder(e, setFieldValue)
              }
            />
          </div>
          <div className="rounded-lg bg-[#F4F8FA] text-[#364A5E] p-4 basis-1/2 h-fit">
            <h3 className="text-base">
              {i18n.ft(messages.observationNotes.strategiesHeader)}
            </h3>
            <ul className="list-disc pl-6">{strategyList}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CheckboxProps {
  name: string;
  value: string;
  label: string;
  disabled: boolean;
  onChange?: any;
  checked?: boolean;
  defaultChecked?: boolean;
}

const Checkbox = ({
  name,
  value,
  label,
  disabled,
  onChange,
  checked,
  defaultChecked
}: CheckboxProps) => {
  return (
    <label className="flex mb-4">
      <Field
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        defaultChecked={defaultChecked}
      />
      <span className="ml-2 text-sm">{label}</span>
    </label>
  );
};

export default ObservationNotes;
