import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

const lgMin = `${1024}px`;

export const SecondaryActionWrapper = styled.div`
  min-width: auto;
  display: flex;
  text-align-last: center;

  @media screen and (min-width: ${lgMin}) {
    margin-left: auto !important;
    margin-right: 15px !important;
    witdh: fit-content;
    min-width: 215px;
  }
`;

export const StyledWhiteButton = styled(Button)`
  border: 0.5px solid #364a5e !important;
  border-radius: 5px;
  box-shadow: none !important;
  background-color: ${({ theme }) => theme.colors['white']} !important;
  color: ${({ theme }) => theme.colors['neutral']['700']} !important;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors['lighterGrey']} !important;
  }

  &:disabled {
    background-color: #dfe5e8 !important;
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }

  @media screen and (min-width: ${lgMin}) {
    max-width: 265px;
    margin-bottom: 0px !important;
  }
`;

export const PageHeaderWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  padding-left: 48px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (min-width: 768px) {
    margin-top: 64px;
    padding-left: 80px;
    padding-right: 48px;
  }

  @media screen and (min-width: 1280px) {
    padding-right: 80px;
  }
`;

export const LinkButton = styled.button`
  color: ${props => props.theme.colors.blue};
  background-color: transparent;
  font-family: inherit;
  cursor: pointer;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;
