const PATH = 'fe.components.knowledge_test.knowledge_test_instructions_content';

const messages = {
  attempts: {
    key: `${PATH}.attempts`,
    defaultValue:
      'You have 3 attempts to pass the test. It consists of 30 single choice and multiple select questions organized into 5 categories.'
  },
  pass: {
    key: `${PATH}.pass`,
    defaultValue:
      'In order to pass, you need to answer at least 80% of the questions correctly as well as at least 3/6 questions per category correctly.'
  },
  fail: {
    key: `${PATH}.fail`,
    defaultValue:
      'If you do not pass after 3 attempts, you will need to attend the CLASS 2nd Edition Train-the-Trainer before scheduling CLASS training.'
  },
  complete: {
    key: `${PATH}.complete`,
    defaultValue:
      'You can complete questions in any order, taking as long as you want until the test expires.'
  },
  recommendations: {
    key: `${PATH}.recommendations`,
    defaultValue: 'Recommendations'
  },
  test: {
    key: `${PATH}.test`,
    defaultValue:
      'This test is open book and open note. Use your CLASS 2nd Edition Field Guide and your materials from the CLASS 2nd Edition Train-the-Trainer while testing.'
  },
  unsuccessful: {
    key: `${PATH}.unsuccessful`,
    defaultValue:
      'If you are unsuccessful on your first attempt, meet with Teachstone Reliability Support prior to your next testing attempt.'
  },
  provide: {
    key: `${PATH}.provide`,
    defaultValue:
      'You must provide an answer to each question before submitting the test.'
  }
};

export default messages;
