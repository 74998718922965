import i18n from 'helpers/i18n';
import messages from './messages';
import { useField } from 'formik';
import ErrorLabel, { ErrorLabelProps } from './ErrorLabel';
import { FormInputProps } from 'semantic-ui-react';
import { FormRowWrapper, FormInputWithLabel } from './Styled';
import { shouldDisplayError, shouldHideInput, BaseFieldProps } from './utils';

interface InputProps extends BaseFieldProps<FormInputProps> {
  /** Default value for empty and disabled text inputs. */
  disabledMessage?: string;

  /** Error label props. */
  errorLabel?: Omit<ErrorLabelProps, 'active'>;
}

function Input({
  name,
  value,
  validate,
  className,
  disabledMessage,
  errorLabel = {},
  ...props
}: InputProps) {
  const fieldHookProps = {
    name,
    value,
    validate,
    type: props.type,
    multiple: props.multiple
  };

  const [field, meta] = useField(fieldHookProps);
  const isError = shouldDisplayError(meta);
  const isHidden = shouldHideInput(props);

  const defaultValue =
    props.disabled && props.type !== 'number' ? disabledMessage : '';

  return (
    <FormRowWrapper className={className} hidden={isHidden}>
      <FormInputWithLabel
        {...field}
        {...props}
        value={value ?? field.value ?? defaultValue}
        error={isError}
        transparent={props.disabled}
        aria-label={props['aria-label'] || props.label}
      />
      <ErrorLabel active={isError} content={meta.error} {...errorLabel} />
    </FormRowWrapper>
  );
}

Input.defaultProps = {
  disabledMessage: i18n.ft(messages.notProvided)
};

export default Input;
