import { CyclesErrors } from 'types/api/envScales/CyclesErrors';
import { ObservationCycle } from 'types/api/envScales/ObservationCycle';
import { MetaDataCategory } from 'types/api/envScales/MetaDataCategory';
import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import moment from 'moment';

export const EIGHT_MINUTES_IN_MILLISECONDS = 8 * 60 * 1000;
export const TEN_MINUTES_IN_MILLISECONDS = 10 * 60 * 1000;
export const TWENTY_MINUTES_IN_MILLISECONDS = 20 * 60 * 1000;
export const THIRTY_MINUTES_IN_MILLISECONDS = 30 * 60 * 1000;

type CycleDetailsStateType = {
  adults: string | number;
  children: string | number;
  start_time: string;
  end_time: string;
};

export type NumericFieldStateType = {
  [t_name: string]: string;
};

export function getInitialState(
  cycle: ObservationCycle
): CycleDetailsStateType {
  const startTime = moment.utc(cycle.start_time).format('HH:mm');
  const endTime = moment.utc(cycle.end_time).format('HH:mm');

  return {
    adults: cycle.adults || '',
    children: cycle.children || '',
    start_time: startTime,
    end_time: endTime
  };
}

export function adultsError(cyclesErrors: CyclesErrors, cycleNumber: number) {
  if (R.isEmpty(cyclesErrors)) {
    return null;
  }

  return cyclesErrors[cycleNumber]!.adults;
}

export function childrenError(cyclesErrors: CyclesErrors, cycleNumber: number) {
  if (R.isEmpty(cyclesErrors)) {
    return null;
  }

  return cyclesErrors[cycleNumber]!.children;
}

export function cycleTimesError(
  cyclesErrors: CyclesErrors,
  cycleNumber: number
) {
  if (R.isEmpty(cyclesErrors)) {
    return null;
  }

  return cyclesErrors[cycleNumber]!.cycle_time;
}

export function getAdultWarnings(adults: number) {
  const maximum = 5;
  let warnings: Array<string> = [];

  if (adults > maximum) {
    warnings.push(
      i18n.ft(messages.warnings.adults.maximum, { number: maximum })
    );
  }

  return warnings;
}

export function getChildrenWarnings(children: number) {
  const maximum = 25;
  const minimum = 3;
  let warnings: Array<string> = [];

  if (children > maximum) {
    warnings.push(
      i18n.ft(messages.warnings.children.maximum, { number: maximum })
    );
  } else if (children < minimum) {
    warnings.push(
      i18n.ft(messages.warnings.children.minimum, { number: minimum })
    );
  }

  return warnings;
}

export function getCycleTimeWarnings(
  takenAt: string,
  startTime: string,
  endTime: string
) {
  let warnings: Array<string> = [];

  const earlyTime = '7:30 am';
  const lateTime = '4 pm';

  const start = moment(`${takenAt} ${startTime}`, 'YYYY-MM-DD hh:mm');
  const end = moment(`${takenAt} ${endTime}`, 'YYYY-MM-DD hh:mm');

  const earlyTimeLimit = moment(`${takenAt} ${earlyTime}`, 'YYYY-MM-DD LT');
  const lateTimeLimit = moment(`${takenAt} ${lateTime}`, 'YYYY-MM-DD LT');

  if (start.isBefore(earlyTimeLimit)) {
    warnings.push(
      i18n.ft(messages.warnings.cycleTime.earlyStart, { time: earlyTime })
    );
  }

  if (start.isAfter(lateTimeLimit)) {
    warnings.push(
      i18n.ft(messages.warnings.cycleTime.lateStart, { time: lateTime })
    );
  }

  if (end.isBefore(earlyTimeLimit)) {
    warnings.push(
      i18n.ft(messages.warnings.cycleTime.earlyEnd, { time: earlyTime })
    );
  }

  if (end.isAfter(lateTimeLimit)) {
    warnings.push(
      i18n.ft(messages.warnings.cycleTime.lateEnd, { time: lateTime })
    );
  }

  const diff = moment
    .utc(`${takenAt} ${endTime}`)
    .diff(moment.utc(`${takenAt} ${startTime}`));

  if (
    diff >= EIGHT_MINUTES_IN_MILLISECONDS &&
    diff < TEN_MINUTES_IN_MILLISECONDS
  ) {
    warnings.push(
      i18n.ft(messages.warnings.cycleTime.shortCycle, { time: '10' })
    );
  } else if (
    diff > TWENTY_MINUTES_IN_MILLISECONDS &&
    diff < THIRTY_MINUTES_IN_MILLISECONDS
  ) {
    warnings.push(
      i18n.ft(messages.warnings.cycleTime.longCycle, { time: '20' })
    );
  }

  return warnings;
}

export function valueForField(currentFieldId: number, cycle: ObservationCycle) {
  return (
    cycle.cycle_meta_data_values.find(
      cmdv => cmdv.meta_data_category_id === currentFieldId
    )?.value ?? ''
  );
}

export function numericFieldsWarnings(
  numericFields: NumericFieldStateType,
  cycleState: CycleDetailsStateType,
  currentField?: MetaDataCategory
) {
  const warnings: string[] = [];

  const asleepChildren = parseInt(numericFields['asleep_children']);
  const awakeChildren = parseInt(numericFields['awake_children']);
  const walkersChildren = parseInt(numericFields['walkers_children']);
  const nonWalkersChildren = parseInt(numericFields['nonwalkers_children']);
  const numberOfChildren = parseInt(cycleState.children as string);

  if (
    !isNaN(asleepChildren) &&
    !isNaN(awakeChildren) &&
    currentField?.t_name === 'asleep_children'
  ) {
    if (asleepChildren + awakeChildren !== numberOfChildren) {
      warnings.push(i18n.ft(messages.warnings.children.awakeAsleepDontMatch));
    }
  }

  if (
    !isNaN(walkersChildren) &&
    !isNaN(nonWalkersChildren) &&
    currentField?.t_name === 'walkers_children'
  ) {
    if (walkersChildren + nonWalkersChildren !== numberOfChildren) {
      warnings.push(
        i18n.ft(messages.warnings.children.walkersNonwalkersDontMatch)
      );
    }
  }

  return warnings;
}
