export function BarsIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.785714 5.13135H21.2143C21.6482 5.13135 22 4.82356 22 4.44385V2.7251C22 2.34538 21.6482 2.0376 21.2143 2.0376H0.785714C0.351754 2.0376 0 2.34538 0 2.7251V4.44385C0 4.82356 0.351754 5.13135 0.785714 5.13135ZM0.785714 12.5456H21.2143C21.6482 12.5456 22 12.2378 22 11.8581V10.1393C22 9.7596 21.6482 9.45181 21.2143 9.45181H0.785714C0.351754 9.45181 0 9.7596 0 10.1393V11.8581C0 12.2378 0.351754 12.5456 0.785714 12.5456ZM0.785714 19.9598H21.2143C21.6482 19.9598 22 19.652 22 19.2723V17.5535C22 17.1738 21.6482 16.866 21.2143 16.866H0.785714C0.351754 16.866 0 17.1738 0 17.5535V19.2723C0 19.652 0.351754 19.9598 0.785714 19.9598Z"
        fill="#364A5E"
      />
    </svg>
  );
}

export function TeachstoneLogo() {
  return (
    <svg
      width="101"
      height="44"
      viewBox="0 0 101 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.3781 0.334588H92.29C92.4258 0.334588 92.5352 0.443909 92.5352 0.579732V4.95919L89.1462 5.46936L89.1296 0.583045C89.1296 0.447222 89.239 0.337901 89.3748 0.337901H89.3781V0.334588Z"
        fill="#79A13F"
      />
      <path
        d="M56.1743 9.78256C56.1743 15.2254 60.2689 19.5121 65.768 19.5121C69.21 19.5121 71.3434 18.2831 73.2714 16.3253C73.3576 16.2424 73.3542 16.1033 73.2714 16.0172L71.4428 14.1653C71.36 14.0792 71.2242 14.0792 71.138 14.1587C69.581 15.5832 68.1201 16.4677 65.8774 16.4677C62.2665 16.4677 59.6527 13.4498 59.6527 9.72956C59.6527 6.00934 62.2665 3.04442 65.8774 3.04442C67.9545 3.04442 69.5346 3.91899 70.9956 5.23746C71.0883 5.32028 71.2275 5.31366 71.307 5.2209L73.1522 3.09743C73.2284 3.00798 73.2217 2.87216 73.1356 2.79265C71.3633 1.14621 69.2829 0 65.9039 0C60.1894 0 56.1743 4.33971 56.1743 9.78256Z"
        fill="#3D4043"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.5596 19.1908C51.4801 19.1908 51.4072 19.1411 51.3741 19.0682L50.0291 16.1894C49.9993 16.1132 49.9231 16.0669 49.8436 16.0669L40.3791 16.0768C40.2996 16.0768 40.2267 16.1265 40.1936 16.1994L38.6995 19.0682C38.6664 19.1411 38.5935 19.1908 38.514 19.1908H35.559C35.4133 19.1908 35.3172 19.0417 35.3735 18.9092L43.5063 0.308089C43.5394 0.235208 43.609 0.188829 43.6918 0.188829H46.501C46.5806 0.188829 46.6534 0.235208 46.6866 0.308089L54.8127 18.9092C54.8724 19.0417 54.773 19.1908 54.6272 19.1908H51.5596ZM44.8546 4.58154L41.0383 13.2278C40.982 13.3603 41.0781 13.5094 41.2238 13.5094L49.0353 13.4763C49.1811 13.4763 49.2771 13.3272 49.2208 13.1947L45.2256 4.58154C45.1561 4.41922 44.9275 4.41922 44.8579 4.58154H44.8546Z"
        fill="#3D4043"
      />
      <path
        d="M6.12859 19.1908H9.17964C9.25915 19.1908 9.3254 19.1245 9.3254 19.045V3.54133C9.3254 3.46182 9.39166 3.39557 9.47116 3.39557H15.1625C15.242 3.39557 15.3082 3.32931 15.3082 3.24981V0.470405C15.3082 0.390898 15.242 0.324643 15.1625 0.324643H0.145761C0.0662551 0.324643 0 0.390898 0 0.470405V3.24981C0 3.32931 0.0662551 3.39557 0.145761 3.39557H5.83707C5.91658 3.39557 5.98283 3.46182 5.98283 3.54133V19.045C5.98283 19.1245 6.04909 19.1908 6.12859 19.1908Z"
        fill="#3D4043"
      />
      <path
        d="M22.2882 3.28956H32.6406C32.7267 3.28956 32.7996 3.21999 32.7996 3.13055V0.483656C32.7996 0.397524 32.73 0.324643 32.6406 0.324643H18.9722C18.886 0.324643 18.8131 0.394211 18.8131 0.483656V19.0318C18.8131 19.1179 18.8827 19.1908 18.9722 19.1908H32.7764C32.8625 19.1908 32.9354 19.1212 32.9354 19.0318V16.3849C32.9354 16.2987 32.8658 16.2259 32.7764 16.2259H22.2882C22.2021 16.2259 22.1292 16.1563 22.1292 16.0669V11.3197C22.1292 11.2335 22.1988 11.1607 22.2882 11.1607H31.4314C31.5176 11.1607 31.5904 11.0911 31.5904 11.0016V8.35476C31.5904 8.26863 31.5209 8.19575 31.4314 8.19575H22.2882C22.2021 8.19575 22.1292 8.12618 22.1292 8.03673V3.44857C22.1292 3.36244 22.1988 3.28956 22.2882 3.28956Z"
        fill="#3D4043"
      />
      <path
        d="M9.14316 30.2852C13.1317 31.2558 15.2088 32.6836 15.2088 35.8374C15.2088 39.3953 12.4327 41.4989 8.47067 41.4989C5.63495 41.4989 2.95825 40.5349 0.718826 38.5836C0.652571 38.5273 0.645945 38.4246 0.702262 38.3584L2.49446 36.2283C2.55078 36.162 2.65016 36.1554 2.71642 36.2117C4.48211 37.7091 6.25775 38.5605 8.55349 38.5605C10.6008 38.5605 11.8961 37.6163 11.8961 36.162C11.8961 34.7872 11.1408 34.0584 7.63917 33.2501C3.62411 32.2795 1.35819 31.0935 1.35819 27.5886C1.35819 24.299 4.05477 22.0894 7.80149 22.0894C10.4948 22.0894 12.6447 22.8911 14.5297 24.3653C14.596 24.4183 14.6092 24.5144 14.5595 24.5839L12.9595 26.8366C12.9098 26.9095 12.8104 26.9228 12.7375 26.8697C11.0811 25.6639 9.42806 25.0245 7.74518 25.0245C5.8039 25.0245 4.67425 26.0217 4.67425 27.2872C4.67425 28.768 5.53557 29.4173 9.14979 30.2786L9.14316 30.2852Z"
        fill="#3D4043"
      />
      <path
        d="M23.5769 41.2272H26.4789C26.6015 41.2272 26.7009 41.1278 26.7009 41.0053V25.6573C26.7009 25.5347 26.8002 25.4353 26.9228 25.4353H32.4584C32.581 25.4353 32.6804 25.3359 32.6804 25.2134V22.5864C32.6804 22.4638 32.581 22.3644 32.4584 22.3644H17.5974C17.4748 22.3644 17.3755 22.4638 17.3755 22.5864V25.2134C17.3755 25.3359 17.4748 25.4353 17.5974 25.4353H23.133C23.2556 25.4353 23.355 25.5347 23.355 25.6573V41.0053C23.355 41.1278 23.4544 41.2272 23.5769 41.2272Z"
        fill="#3D4043"
      />
      <path
        d="M70.8962 22.3611H73.7849C73.8876 22.3611 73.9705 22.4439 73.9705 22.5466V41.0384C73.9705 41.1411 73.8876 41.2239 73.7849 41.2239H71.2871C71.2308 41.2239 71.1745 41.1974 71.1381 41.151L61.1269 28.2346C61.0176 28.0922 60.7923 28.1717 60.7923 28.3505V41.0384C60.7923 41.1411 60.7095 41.2239 60.6068 41.2239H57.7181C57.6154 41.2239 57.5326 41.1411 57.5326 41.0384V22.5466C57.5326 22.4439 57.6154 22.3611 57.7181 22.3611H60.5107C60.5671 22.3611 60.6234 22.3876 60.6598 22.434L70.3761 34.9727C70.4854 35.1152 70.7107 35.0357 70.7107 34.8568V22.5466C70.7107 22.4439 70.7935 22.3611 70.8962 22.3611Z"
        fill="#3D4043"
      />
      <path
        d="M82.3252 25.326H92.6246V25.3227C92.7273 25.3227 92.8101 25.2399 92.8101 25.1372V22.5433C92.8101 22.4406 92.7273 22.3578 92.6246 22.3578H79.0092C78.9065 22.3578 78.8236 22.4406 78.8236 22.5433V41.0384C78.8236 41.1411 78.9065 41.2239 79.0092 41.2239H92.7604C92.8631 41.2239 92.9459 41.1411 92.9459 41.0384V38.4445C92.9459 38.3418 92.8631 38.259 92.7604 38.259H82.3252C82.2225 38.259 82.1397 38.1762 82.1397 38.0735V33.3793C82.1397 33.2766 82.2225 33.1938 82.3252 33.1938H91.4121C91.5148 33.1938 91.5976 33.111 91.5976 33.0083V30.4144C91.5976 30.3117 91.5148 30.2289 91.4121 30.2289H82.3252C82.2225 30.2289 82.1397 30.1461 82.1397 30.0434V25.5115C82.1397 25.4088 82.2225 25.326 82.3252 25.326Z"
        fill="#3D4043"
      />
      <path
        d="M80.2184 9.2227V0.45715C80.2184 0.380957 80.1588 0.321327 80.0826 0.321327H77.0381C76.962 0.321327 76.9023 0.380957 76.9023 0.45715V19.055C76.9023 19.1311 76.962 19.1908 77.0381 19.1908H80.0826C80.1588 19.1908 80.2184 19.1311 80.2184 19.055L80.2648 12.7541C80.2648 12.6878 80.3145 12.6315 80.3807 12.6216L89.0999 11.7172C89.1827 11.7039 89.2556 11.7669 89.2556 11.8497L89.2225 19.0516C89.2225 19.1278 89.2821 19.1875 89.3583 19.1875H92.4027C92.4789 19.1875 92.5385 19.1278 92.5385 19.0516L92.5319 8.25206C92.5319 8.16924 92.459 8.1063 92.3762 8.11955L80.3774 9.35521C80.2946 9.36846 80.2217 9.30552 80.2217 9.2227H80.2184Z"
        fill="#3D4043"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9656 31.8455C33.9656 26.2635 38.4908 21.735 44.0761 21.735C49.6614 21.735 54.1866 26.2635 54.1866 31.8455C54.1866 37.4275 49.6581 41.956 44.0761 41.956C38.4941 41.956 33.9656 37.4275 33.9656 31.8455ZM37.4639 32.0211C37.4639 35.7214 40.4619 38.7195 44.1623 38.7195C47.8626 38.7195 50.8606 35.7214 50.8606 32.0211C50.8606 28.3207 47.8626 25.3227 44.1623 25.3227C40.4619 25.3227 37.4639 28.3207 37.4639 32.0211Z"
        fill="#3D4043"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.0442 41.5916C98.3755 41.4691 98.6041 41.2074 98.6041 40.7833C98.6041 40.2433 98.2066 39.9286 97.5871 39.9286H96.4375V42.5192H97.0107V41.6844H97.4612L98.0144 42.5192H98.6803L98.0475 41.5916H98.0442ZM97.5804 41.1941H97.0073V40.4322H97.5672C97.8488 40.4322 98.0244 40.5614 98.0244 40.8165C98.0244 41.0384 97.8687 41.1941 97.5804 41.1941Z"
        fill="#3D4043"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.6587 41.2239C94.6587 42.7544 95.9043 44 97.4348 44C98.9653 44 100.211 42.7544 100.211 41.2239C100.211 39.6934 98.9653 38.4478 97.4348 38.4478C95.9043 38.4478 94.6587 39.6934 94.6587 41.2239ZM95.1159 41.2272C95.1159 39.9452 96.1561 38.905 97.4348 38.905C98.7168 38.905 99.757 39.9452 99.757 41.2272C99.757 42.5093 98.7135 43.5495 97.4348 43.5495C96.1561 43.5495 95.1159 42.5093 95.1159 41.2272Z"
        fill="#3D4043"
      />
    </svg>
  );
}

export function CaretUpIcon() {
  return (
    <svg
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.92232 11.687H1.07681C0.464315 11.687 0.157587 10.9717 0.590678 10.5534L5.01343 6.2814C5.2819 6.02208 5.71723 6.02208 5.9857 6.2814L10.4085 10.5534C10.8416 10.9717 10.5348 11.687 9.92232 11.687Z"
        fill="#364A5E"
      />
    </svg>
  );
}

export function CaretDownIcon() {
  return (
    <svg
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.07768 5.31251L9.92319 5.31251C10.5357 5.31251 10.8424 6.02781 10.4093 6.44613L5.98657 10.7181C5.7181 10.9774 5.28277 10.9774 5.0143 10.7181L0.591549 6.44613C0.158423 6.02781 0.465186 5.31251 1.07768 5.31251Z"
        fill="#364A5E"
      />
    </svg>
  );
}

export function TimesIcon() {
  return (
    <svg
      width="19"
      height="26"
      viewBox="0 0 19 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4:7911)">
        <path
          d="M12.7306 12.9998L17.8478 7.91813C18.4758 7.29454 18.3187 5.77799 17.3714 4.81496C16.544 3.97379 15.0645 3.90641 14.436 4.53001L9.31877 9.61169L4.20156 4.53001C3.5736 3.90641 1.89461 4.19126 1.26614 4.81485C0.295655 5.77781 0.161785 7.29403 0.789739 7.91813L5.90696 12.9998L0.789739 18.0815C0.161785 18.7051 0.319651 20.3402 1.26702 21.1853C1.89497 21.8088 3.5736 22.0932 4.20156 21.4696L9.31877 16.3879L14.436 21.4696C15.0639 22.0932 16.2164 22.3601 17.3723 21.1853C18.4528 20.087 18.4758 18.7056 17.8478 18.0815L12.7306 12.9998Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4:7911">
          <rect
            width="18"
            height="26"
            fill="white"
            transform="translate(0.318726)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
