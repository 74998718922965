import styled from 'styled-components';
import { getShadeColor } from 'helpers/ageLevelGroup';
import { AGE_LEVEL_GROUP_ABBREVIATIONS } from 'types/api/AgeLevelGroup';

const AgeLevelGroupTag = styled.span<{
  abbreviation: AGE_LEVEL_GROUP_ABBREVIATIONS | undefined;
  backgroundColor?: string;
}>`
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 4px;
  border-color: ${p => p.backgroundColor || 'transparent'};
  color: ${p => p.theme.colors['neutral'][900]};
  background-color: ${p =>
    p.abbreviation ? getShadeColor(p.abbreviation, 100) : 'transparent'};
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-block;
`;

export default AgeLevelGroupTag;
