import { EducatorListData } from 'pages/Improvements/CoachingRoster/CoachingRoster';

export function sortCoachingRosterTable(
  educators: EducatorListData[],
  column: string,
  direction: string | undefined
) {
  if (direction === 'ascending') {
    return educators.slice().sort((a: any, b: any) => {
      const valA = a[column] ? a[column] : '';
      const valB = b[column] ? b[column] : '';

      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    });
  } else if (direction === 'descending') {
    return educators.slice().sort((a: any, b: any) => {
      const valA = a[column] ? a[column] : '';
      const valB = b[column] ? b[column] : '';

      if (valA > valB) return -1;
      if (valA < valB) return 1;
      return 0;
    });
  } else {
    return educators;
  }
}
