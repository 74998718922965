import i18n from 'helpers/i18n';
import messages from './messages';
import styled from 'styled-components';
import { Select, SelectProps } from 'semantic-ui-react';

const StyledSelect = styled(Select)`
  &.dropdown.multiple.selection.disabled {
    padding: 0;
    border: 0;

    .icon {
      display: none;
    }
  }
`;

const ReadOnlyText = styled.span`
  display: inline-block;
  min-width: 224px;
`;

export interface FormDropdownProps extends SelectProps {
  /** If `true`, the dropdown will be rendered as text. */
  readOnly?: boolean;
  search?: boolean;
}

function FormDropdown(props: FormDropdownProps) {
  if (props.readOnly && !props.multiple) {
    const selectedOption = props.options.find(x => x.value === props.value);
    const text = selectedOption
      ? selectedOption.text
      : i18n.ft(messages.notProvided);
    return <ReadOnlyText>{text}</ReadOnlyText>;
  }

  const disabled = (props.readOnly && props.multiple) || props.disabled;

  return <StyledSelect {...props} disabled={disabled} search={props.search} />;
}

export default FormDropdown;
