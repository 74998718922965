import i18n from 'helpers/i18n';
import messages from './messages';
import ScoreInput from '../ScoreInput';
import { Icon } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import { useFormikContext } from 'formik';
import { containsNotApplicable } from './utils';
import { CategoryItem } from 'types/api/envScales/AssessmentScore';

import {
  Wrapper,
  Name,
  GridRow,
  GridCell,
  GridCellMobile,
  ScoreGroup,
  ScoreCell,
  HeaderGridRow,
  HeaderGridCell,
  HeaderGridText
} from './Styled';

export interface CategoryProps {
  /** Category name. */
  name: string;

  /** Category item list. */
  items: CategoryItem[];

  /** Assessment ID. */
  assessmentId: number;
}

type FormScore = {
  [key: number]: string | null;
};

function Category({ name, items, assessmentId }: CategoryProps) {
  const isTablet = useMediaQuery({ minWidth: 768 });
  const formik = useFormikContext<FormScore>();
  const hasNotApplicable = containsNotApplicable(items);

  return (
    <Wrapper>
      <HeaderGridRow>
        <HeaderGridCell justify="left" className="col-span-2">
          <Name>{name}</Name>
        </HeaderGridCell>

        {isTablet && (
          <>
            <HeaderGridCell>
              <HeaderGridText>1</HeaderGridText>
            </HeaderGridCell>
            <HeaderGridCell>
              <HeaderGridText>2</HeaderGridText>
            </HeaderGridCell>
            <HeaderGridCell>
              <HeaderGridText>3</HeaderGridText>
            </HeaderGridCell>
            {hasNotApplicable && (
              <HeaderGridCell>
                <HeaderGridText>
                  {i18n.ft(messages.notApplicableShort)}
                </HeaderGridText>
              </HeaderGridCell>
            )}
          </>
        )}
      </HeaderGridRow>

      {items.map(item => {
        const scaleId = item.scale_id;
        const hasError = Boolean(
          formik.touched[scaleId] && formik.errors[scaleId]
        );

        return (
          <GridRow
            key={item.scale_id}
            role="radiogroup"
            aria-label={item.name}
            hasError={hasError}
          >
            <GridCell align="top">{item.position}</GridCell>

            <GridCell justify="left">
              <span>{item.name}</span>

              {hasError && (
                <span className="ml-4">
                  <Icon fitted name="warning circle" color="red" />
                </span>
              )}
            </GridCell>

            {isTablet ? (
              <>
                <ScoreCell>
                  <ScoreInput
                    assessmentId={assessmentId}
                    scaleId={item.scale_id}
                    value={1}
                    label="1"
                  />
                </ScoreCell>
                <ScoreCell>
                  <ScoreInput
                    assessmentId={assessmentId}
                    scaleId={item.scale_id}
                    value={2}
                    label="2"
                  />
                </ScoreCell>
                <ScoreCell>
                  <ScoreInput
                    assessmentId={assessmentId}
                    scaleId={item.scale_id}
                    value={3}
                    label="3"
                  />
                </ScoreCell>
                {!item.required && (
                  <ScoreCell>
                    <ScoreInput
                      assessmentId={assessmentId}
                      scaleId={item.scale_id}
                      value={0}
                      label="0"
                      ariaLabel={i18n.ft(messages.notApplicable)}
                    />
                  </ScoreCell>
                )}
              </>
            ) : (
              <GridCellMobile justify="left">
                <ScoreGroup>
                  <ScoreInput
                    assessmentId={assessmentId}
                    scaleId={item.scale_id}
                    value={1}
                    label="1"
                  />
                  <ScoreInput
                    assessmentId={assessmentId}
                    scaleId={item.scale_id}
                    value={2}
                    label="2"
                  />
                  <ScoreInput
                    assessmentId={assessmentId}
                    scaleId={item.scale_id}
                    value={3}
                    label="3"
                  />

                  {!item.required && (
                    <ScoreInput
                      assessmentId={assessmentId}
                      scaleId={item.scale_id}
                      value={0}
                      label={i18n.ft(messages.notApplicableShort)}
                    />
                  )}
                </ScoreGroup>
              </GridCellMobile>
            )}
          </GridRow>
        );
      })}
    </Wrapper>
  );
}

export default Category;
