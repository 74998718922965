import { CycleMetaDataValue } from 'types/api/envScales/CycleMetaDataValue';
import { MetaDataChoice } from 'types/api/envScales/MetaDataChoice';
import {
  ContentAndFormat2ndEditionError,
  CyclesErrors
} from 'types/api/envScales/CyclesErrors';
import messages from './messages';
import i18n from 'helpers/i18n';
import * as R from 'ramda';

type OptionValue = {
  value: string;
};

export type FormatChoice = {
  [key: number]: OptionValue[];
};

export function getInitialState(
  contentChoices: MetaDataChoice[] | undefined,
  formatChoices: CycleMetaDataValue[]
) {
  let choices: FormatChoice = {};

  if (!contentChoices) {
    return choices;
  }

  contentChoices.forEach((contentChoice, index) => {
    choices[contentChoice.id] = [];
  });

  formatChoices.forEach((formatChoice, index) => {
    if (choices[formatChoice.meta_data_choice_id]) {
      choices[formatChoice.meta_data_choice_id].push({
        value: formatChoice.value
      });
    }
  });

  return choices;
}

export function addValue(
  value: string,
  choiceId: number,
  metaDataValues: FormatChoice
) {
  let newMetaDataValues = R.clone(metaDataValues);

  const newItem: OptionValue = { value };
  newMetaDataValues[choiceId].push(newItem);

  return newMetaDataValues;
}

export function removeValue(
  value: string,
  choiceId: number,
  metaDataValues: FormatChoice
) {
  let newMetaDataValues = R.clone(metaDataValues);

  newMetaDataValues[choiceId] = newMetaDataValues[choiceId].filter(
    item => item.value !== value
  );

  return newMetaDataValues;
}

export function updateSelectedFormat(
  cyclesErrors: CyclesErrors,
  cycleNumber: number,
  hasOneSelected: boolean,
  choiceName: string
) {
  let newCyclesErrors = R.clone(cyclesErrors);

  const errorMessage = i18n.ft(messages.errors.select_one);

  let error = null;
  if (!hasOneSelected) {
    error = errorMessage;
  }

  (
    newCyclesErrors[cycleNumber]
      ?.meta_data_categories as ContentAndFormat2ndEditionError
  ).content_and_format[choiceName].formats = error;

  return newCyclesErrors;
}

export function updateContentAnswers(
  cyclesErrors: CyclesErrors,
  cycleNumber: number,
  questionId: number,
  choiceName: string,
  isFirstQuestion: boolean,
  value: number
) {
  let newCyclesErrors = R.clone(cyclesErrors);

  (
    newCyclesErrors[cycleNumber]
      ?.meta_data_categories as ContentAndFormat2ndEditionError
  ).content_and_format[choiceName].questions[questionId] = null;

  if (isFirstQuestion && value === 1) {
    const questions = (
      newCyclesErrors[cycleNumber]
        ?.meta_data_categories as ContentAndFormat2ndEditionError
    ).content_and_format[choiceName].questions;
    const questionsIds = Object.keys(questions);
    for (let id of questionsIds) {
      (
        newCyclesErrors[cycleNumber]
          ?.meta_data_categories as ContentAndFormat2ndEditionError
      ).content_and_format[choiceName].questions[parseInt(id)] = null;
    }
    newCyclesErrors = updateSelectedFormat(
      newCyclesErrors,
      cycleNumber,
      true,
      choiceName
    );
  }

  return newCyclesErrors;
}
