import styled from 'styled-components';
import { Button, Pagination as SemanticPagination } from 'semantic-ui-react';

export const TrainigContainer = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 640px) {
    padding: 40px;
  }

  @media screen and (min-width: 1280px) {
    padding: 60px;
  }
`;

export const ActionContainer = styled.div``;

export const DetailContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 800px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 50%;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
`;

export const ButtonStatus = styled(Button)<{ selected: boolean }>`
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors['blue'] : theme.colors['white']} !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25) !important;
  color: ${({ selected, theme }) =>
    selected
      ? theme.colors['white']
      : theme.colors['neutral']['700']} !important;
  margin-bottom: 10px !important;

  @media screen and (min-width: 800px) {
    margin-bottom: 0px;
  }
`;

export const LoaderWrapper = styled.div`
  margin: 64px 0 48px;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldLabel = styled.label`
  font-weight: bold;
  margin-bottom: 4px;
`;

export const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 50px;
  flex-wrap: 'wrap';
  flex-direction: column;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
  }
`;

export const SearchBar = styled.div`
  width: 100%;
  min-width: 200px;

  @media screen and (min-width: 1280px) {
    width: 50%;
  }
`;
export const SearchDates = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (min-width: 1280px) {
    width: 50%;
    padding-left: 20px;
    justify-content: space-around;
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 16px;

  @media screen and (min-width: 640px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    margin-top: 64px;
  }
`;

export const Pagination = styled(SemanticPagination)`
  &.ui.pagination > .item.active {
    font-weight: 700;
    color: ${p => p.theme.colors['white']};
    background-color: ${p => p.theme.colors['primary']['700']};
  }
`;

export const SearchCount = styled.div`
  margin-bottom: 16px;
  padding-left: 8px;
  font-size: 16px;
  line-height: 24px;
  color: ${p => p.theme.colors['neutral']['700']};

  @media screen and (min-width: 640px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const NoTrainings = styled.div`
  width: 100%;
  background-color: #f3f7ff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: -1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
`;
