import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import colors from 'styles/colors';
import { Icon } from 'semantic-ui-react';
import { replace, toUpper } from 'ramda';
import { useMediaQuery } from 'react-responsive';
import { print } from 'components/ReliabilityTest/utils';
import printerIcon from 'images/reliabilityTest/printer.svg';
import { Section } from '../ScoreReport/ResultSection/Styled';
import { Status as StatusIcon } from 'components/Reports/Reliability/View';

interface ParticipantSectionProps {
  name: string;
  test: string;
  trainingDate: string | null;
  result: string;
  attempt: number;
  testCompletion: string;
}

function ParticipantSection({
  name,
  test,
  trainingDate,
  result,
  attempt,
  testCompletion
}: ParticipantSectionProps) {
  const isTablet = useMediaQuery({ minWidth: 640 });
  const capitalizeResult = replace(/^./, toUpper);

  return (
    <Section className="mb-2 PrintTopSection">
      <div className="mx-5 md:mx-0">
        <div className="flex justify-between mb-10">
          <div className="font-bold text-xl md:text-2xl">
            {i18n.ft(messages.participantReportTitle)}
          </div>
          <img
            src={printerIcon}
            alt=""
            className="cursor-pointer"
            onClick={print}
          />
        </div>
        <div className="grid grid-col-6 md:grid-col-12">
          <div className="justify-between md:flex gap-8">
            <div className="col-span-6">
              <div className="mb-3 text-sm md:text-lg">
                <span className="font-bold mr-2">
                  {i18n.ft(messages.name)}{' '}
                </span>
                {name}
              </div>
              <div className="mb-3 text-sm md:text-lg">
                <span className="font-bold mr-2">
                  {i18n.ft(messages.test)}{' '}
                </span>
                {test}
              </div>
              <div className="mb-3 text-sm md:text-lg">
                <span className="font-bold mr-2">
                  {i18n.ft(messages.trainingDate)}{' '}
                </span>
                {moment(trainingDate).format('MM/DD/YYYY')}
              </div>
            </div>
            <div className="col-span-6">
              <div className="mb-3 text-sm md:text-lg flex items-center">
                <span className="font-bold mr-2">
                  {i18n.ft(messages.result) + ' '}
                </span>
                {capitalizeResult(result)}
                <StatusIcon
                  iconColor={colors.white}
                  iconBackGroundColor={
                    result === 'passed'
                      ? colors.reliability.passVideo
                      : colors.reliability.failedAttempt
                  }
                  style={{ marginTop: '0', marginLeft: '10px' }}
                  size={isTablet ? '18px' : '14px'}
                >
                  <Icon
                    name={result === 'passed' ? 'check' : 'close'}
                    size="tiny"
                    className="align-middle"
                    style={{
                      fontSize: isTablet ? '0.5em' : '0.4em',
                      position: 'relative',
                      margin: 'auto'
                    }}
                  />
                </StatusIcon>
              </div>
              <div className="mb-3 text-sm md:text-lg">
                <span className="font-bold mr-2">
                  {i18n.ft(messages.attempt)}{' '}
                </span>
                {attempt}
              </div>
              <div className="mb-3 text-sm md:text-lg">
                <span className="font-bold mr-2">
                  {i18n.ft(messages.testCompletion)}{' '}
                </span>
                {moment(testCompletion).format('MM/DD/YYYY')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ParticipantSection;
