import React, { useContext } from 'react';
import TrainingContext from '../TrainingContext';
import MenuLink from './MenuLink';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Menu, Button } from 'semantic-ui-react';
import { reject, findIndex, map, propEq, find, insert } from 'ramda';
import ResponsiveContent from 'components/ResponsiveContent';
import MobileNav from 'components/MobileNav';
import { useLocation } from 'react-router-dom';
import { hasStarted } from 'helpers/training';
import RouteHelpers from 'helpers/routes';
import { isNil, isEmpty } from 'ramda';

function DesktopNavigation({ params, routes }) {
  return (
    <Menu pointing vertical fluid>
      {map(
        ({ key, text, route, href }) => (
          <MenuLink
            key={key}
            content={text}
            route={route}
            params={params}
            external={href}
          />
        ),
        routes
      )}
    </Menu>
  );
}

function MobileNavigation({ routes, params }) {
  const currentLocation = useLocation();
  const validRoutes = reject(propEq('view', 'desktop'), routes);
  const materialsUrl = find(propEq('key', 'materials'), routes);

  const currentRoute = findIndex(
    route => RouteHelpers.isActiveRoute(route.route, currentLocation.pathname),
    validRoutes
  );

  const left = currentRoute < 1 ? null : validRoutes[currentRoute - 1];
  const right =
    currentRoute < validRoutes.length - 1
      ? validRoutes[currentRoute + 1]
      : null;

  return (
    <div>
      <MobileNav>
        {left && (
          <MobileNav.Left text={left.text} route={left.route} params={params} />
        )}
        {right && (
          <MobileNav.Right
            text={right.text}
            route={right.route}
            params={params}
          />
        )}
      </MobileNav>

      {!isNil(materialsUrl) && !isEmpty(materialsUrl.href) && (
        <Button
          color="blue"
          content={i18n.ft(messages.viewMaterials)}
          fluid
          as="a"
          href={materialsUrl.href}
          target="_blank"
        />
      )}
    </div>
  );
}

function Navigation() {
  let routes = [
    {
      key: 'details',
      text: i18n.ft(messages.details),
      route: 'td-trainings-view-details'
    },
    {
      key: 'participants',
      text: i18n.ft(messages.participants),
      route: 'td-trainings-view-participants'
    }
  ];

  const { training } = useContext(TrainingContext);
  const params = { id: training.id };
  const materialsUrl = training.training_template.training_materials_url;

  if (!training) {
    return null;
  }

  if (hasStarted(training)) {
    routes = insert(
      2,
      {
        key: 'attendance',
        text: i18n.ft(messages.takeAttendance),
        route: 'td-trainings-view-attendance'
      },
      routes
    );
  }

  if (!isNil(materialsUrl) && !isEmpty(materialsUrl)) {
    routes = insert(
      3,
      {
        key: 'materials',
        text: i18n.ft(messages.viewMaterials),
        href: materialsUrl,
        view: 'desktop'
      },
      routes
    );
  }

  return (
    <ResponsiveContent
      renderDesktop={() => (
        <DesktopNavigation routes={routes} params={params} />
      )}
      renderMobile={() => <MobileNavigation routes={routes} params={params} />}
    />
  );
}

export default Navigation;
