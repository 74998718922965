const PATH = 'fe.components.error_modal';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Snap! The following errors have occurred.'
  },
  close: {
    key: `${PATH}.close`,
    defaultValue: 'Close'
  }
};

export default messages;
