import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { FormikHelpers } from 'formik';
import { Button } from 'semantic-ui-react';
import { toastSuccess, toastError } from 'components/Toast';
import {
  Formik,
  Form,
  Input,
  PreventNavigation
} from 'components/EnhancedForm';
import { updatePassword } from 'actions/users';

import {
  Section,
  SectionTitle,
  FieldRow,
  Actions,
  ButtonWrapper
} from './Styled';

const validationSchema = yup.object({
  password: yup
    .string()
    .required(i18n.ft(messages.errors.password.required))
    .min(6, i18n.ft(messages.errors.password.min)),
  password_confirmation: yup
    .string()
    .required(i18n.ft(messages.errors.passwordConfirmation.required))
    .oneOf(
      [yup.ref('password')],
      i18n.ft(messages.errors.passwordConfirmation.mustMatch)
    )
});

function Password() {
  const { run, isLoading } = useAsync();

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    run(updatePassword({ user: { ...values } }), {
      onSuccess: () => {
        formik.resetForm();
        toastSuccess({ description: i18n.ft(messages.success) });
      },
      onError: (error: any) => {
        toastError({ description: error.errors });
      }
    });
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{ password: '', password_confirmation: '' }}
    >
      <Form>
        <Section>
          <SectionTitle>{i18n.ft(messages.changePassword)}</SectionTitle>
          <PreventNavigation />
          <div>
            <FieldRow>
              <Input
                type="password"
                name="password"
                label={i18n.ft(messages.fields.password)}
              />
              <Input
                type="password"
                name="password_confirmation"
                label={i18n.ft(messages.fields.passwordConfirmation)}
              />
            </FieldRow>
          </div>
        </Section>

        <Actions>
          <ButtonWrapper>
            <Button
              fluid
              type="submit"
              color="blue"
              loading={isLoading}
              disabled={isLoading}
            >
              {i18n.ft(messages.save)}
            </Button>
          </ButtonWrapper>
        </Actions>
      </Form>
    </Formik>
  );
}

export default Password;
