import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  width: 192px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 500px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ResendWrapper = styled.div`
  margin-top: 32px;

  @media screen and (min-width: 500px) {
    margin-top: 0;
  }
`;

export const TextButtonWrapper = styled.div`
  margin-top: 16px;

  @media screen and (min-width: 500px) {
    margin-top: 32px;
  }
`;

export const IconWrapper = styled.span`
  margin-right: 8px;
`;
