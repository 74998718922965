const PATH = 'fe.components.recertification_product_key.confirmation_modal';

const messages = {
  certifications: {
    key: `${PATH}.certifications`,
    defaultValue: 'Certifications:'
  },
  language: {
    key: `${PATH}.language`,
    defaultValue: 'Language:'
  }
};

export default messages;
