import i18n from 'helpers/i18n';
import messages from './messages';
import RadioGroup from '../RadioGroup';
import { Button } from 'semantic-ui-react';
import { updateUserMe } from 'actions/userMe';

import {
  useOnboardingManager,
  ClassFamiliaredType
} from '../OnboardingManagerContext';

import {
  Container,
  Title,
  SubTitle,
  ButtonWrapper,
  RadioWrapper
} from './Styled';
import { useEffect } from 'react';
import { isNil } from 'ramda';

function FamiliarWithClass() {
  const {
    setStep,
    checkedSteps,
    setCheckedSteps,
    classFamiliared,
    setClassFamiliared
  } = useOnboardingManager();

  const options = [
    {
      value: 1,
      description: i18n.ft(messages.descNotFamiliar),
      label: i18n.ft(messages.notFamiliar),
      aria: i18n.ft(messages.notFamiliar) + i18n.ft(messages.optionOne)
    },
    {
      value: 3,
      description: i18n.ft(messages.descLittleFamiliar),
      label: i18n.ft(messages.littleFamiliar),
      aria: i18n.ft(messages.littleFamiliar) + i18n.ft(messages.optionTwo)
    },
    {
      value: 5,
      description: i18n.ft(messages.descVeryFamiliar),
      label: i18n.ft(messages.veryFamiliar),
      aria: i18n.ft(messages.veryFamiliar) + i18n.ft(messages.optionThree)
    }
  ];

  useEffect(() => {
    if (!isNil(classFamiliared)) {
      updateUserMe({
        class_experience: classFamiliared
      });
    }
  }, [classFamiliared]);

  function handleClick(famSelected: ClassFamiliaredType) {
    setClassFamiliared(famSelected);
  }

  function handleSubmit() {
    let cpChecker = checkedSteps;
    cpChecker.push('onboardingCompleted');
    setStep('onboardingCompleted');
    setCheckedSteps(cpChecker);
  }

  return (
    <Container>
      <Title>{i18n.ft(messages.title)}</Title>
      <SubTitle>{i18n.ft(messages.pickOne)}</SubTitle>
      <RadioWrapper>
        <RadioGroup
          role="radiogroup"
          name="radioGroup"
          selected={classFamiliared}
          options={options}
          onChange={handleClick}
        />
      </RadioWrapper>
      <ButtonWrapper>
        <Button fluid size="large" color="blue" onClick={handleSubmit}>
          {i18n.ft(messages.next)}
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

export default FamiliarWithClass;
