/**
 * Transform a deep nested object into a flatten object
 *   { a: 1, b: { c: 2, d: { e: 3 } }} => { 'a': 1, 'b.c': 2, 'b.d.e': 3 }
 *
 * @param {Object} obj
 * @return {Object}
 */
export function flattenObject(obj: object, keySeparator = '.') {
  const flattenRecursive = (
    obj: object,
    parentProperty?: string,
    propertyMap: Record<string, unknown> = {}
  ) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty
        ? `${parentProperty}${keySeparator}${key}`
        : key;
      if (value && typeof value === 'object') {
        flattenRecursive(value, property, propertyMap);
      } else {
        propertyMap[property] = value;
      }
    }
    return propertyMap;
  };
  return flattenRecursive(obj);
}
