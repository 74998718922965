const PATH = 'fe.components.reliability_test.top_section';

const messages = {
  attempt: {
    key: `${PATH}.attempt`,
    defaultValue: 'Attempt {{number}}'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed on '
  },
  click: {
    key: `${PATH}.click`,
    defaultValue: 'Click to download this report in PDF'
  }
};

export default messages;
