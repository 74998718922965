import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { useEffect } from 'react';
import PageLayout from 'components/PageLayout';
import {
  getKnowledgeReport,
  getReliabilityReport,
  getTraining
} from 'actions/trainings';
import useCurrentUser from 'hooks/useCurrentUser';
import usePermissions from 'hooks/usePermissions';
import { Header, Loader } from 'semantic-ui-react';
import { Navigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import ReportSummary from 'components/Reports/Reliability/Summary';
import ReportCharts from 'components/Reports/Reliability/Charts';
import ReportTable from 'components/Reports/Reliability/Table';
import MainContent from 'components/MainContent';
import { TestType } from 'types/api/testType';

type URLParams = {
  trainingId: string;
};

interface TestReportProps {
  testType?: TestType;
}

function TestReport({ testType = 'reliability' }: TestReportProps) {
  const params = useRouteParams<URLParams>();
  const trainingId = parseInt(params.trainingId);
  const currentUser = useCurrentUser();
  const permissions = usePermissions('trainer_dashboard', 'trainings');
  const { run, data: training, isPending, isError } = useAsync();
  const { run: reportRun, data: report, ...reportStatus } = useAsync();

  useEffect(() => {
    run(getTraining(trainingId));
    const action =
      testType === 'knowledge' ? getKnowledgeReport : getReliabilityReport;
    reportRun(action(trainingId));
  }, [run, trainingId, reportRun, testType]);

  if (isPending || reportStatus.isPending) {
    return <Loader active inline="centered" />;
  }

  if (
    isError ||
    reportStatus.isError ||
    training.status !== 'completed' ||
    !training.test_type ||
    (!permissions.manage && training.trainer.id !== currentUser.id)
  ) {
    return <Navigate replace to="/trainings" />;
  }

  const header =
    testType === 'knowledge'
      ? messages.knowledge.header
      : messages.reliability.header;

  return (
    <MainContent maxWidth={1024}>
      <PageLayout>
        <Header as="h1" size="huge">
          {i18n.ft(header)}
        </Header>
        <ReportSummary
          training={training}
          report={report}
          testType={testType}
        />
        <ReportCharts report={report} testType={testType} />
        <ReportTable report={report} />
      </PageLayout>
    </MainContent>
  );
}

export default TestReport;
