import PageLoader from 'components/PageLoader';
import { useGetAccountsByArea } from 'actions/accountManager/accounts';
import { Route, Routes, Navigate } from 'react-router-dom';
import CertificationsRoot from 'pages/Certifications/Root';
import CertificationsDashboard from 'pages/Certifications/Dashboard';
import CertificationsObservers from 'pages/Certifications/Observers';
import MyCertifications from 'pages/Certifications/MyCertifications';
import StandaloneCertifications from 'pages/Certifications/StandaloneCertifications';

export default function CertificationRoutes() {
  const { isPending, isError, data } = useGetAccountsByArea(
    'CertificationsDashboard'
  );

  if (isPending) {
    return <PageLoader />;
  }

  if (isError || data.length === 0) {
    return <StandaloneCertifications />;
  }

  return (
    <Routes>
      <Route element={<CertificationsRoot />}>
        <Route index element={<Navigate replace to="organization" />} />
        <Route path="my-certifications" element={<MyCertifications />} />

        <Route path="organization">
          <Route index element={<CertificationsDashboard />} />
          <Route path="observers" element={<CertificationsObservers />} />
        </Route>
      </Route>
    </Routes>
  );
}
