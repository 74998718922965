import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { map, addIndex } from 'ramda';
import { Modal, List, Button, Icon, ModalProps } from 'semantic-ui-react';

import {
  ModalIcon,
  ModalBody,
  ModalTitle,
  ModalErrors,
  ModalActions
} from './Styled';

interface ErrorModalProps extends ModalProps {
  /** Array of errors. */
  errors: string[];

  /** Custom modal title. */
  title?: string;

  /** Callback fired when the component requests to be closed. */
  onClose?: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ title, errors, ...props }) => (
  <Modal {...props}>
    <ModalBody>
      <ModalIcon>
        <Icon size="huge" color="red" name="warning sign" />
      </ModalIcon>

      <ModalTitle>{title}</ModalTitle>
      <ModalErrors>
        <List relaxed size="small">
          {addIndex<string, React.ReactNode>(map)(
            (error, idx) => (
              <List.Item key={idx}>
                <List.Icon
                  size="tiny"
                  color="grey"
                  name="circle"
                  verticalAlign="middle"
                />
                <List.Content>{error}</List.Content>
              </List.Item>
            ),
            errors
          )}
        </List>
      </ModalErrors>

      <ModalActions>
        <Button color="red" onClick={props.onClose}>
          {i18n.ft(messages.close)}
        </Button>
      </ModalActions>
    </ModalBody>
  </Modal>
);

ErrorModal.defaultProps = {
  errors: [],
  size: 'tiny',
  title: i18n.ft(messages.title)
};

export default ErrorModal;
