import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import PropTypes from 'prop-types';
import { StatusContainer, Label, Button } from './Styled';
import { isEmpty } from 'ramda';

import trainingStatus, {
  ACTIVE,
  COMPLETED,
  CANCELED
} from 'constants/trainingStatus';

type StatusFilterParams = {
  value: string;
  onChange: (name: string) => void;
};

type handleClickParams = {
  name: string;
};

type handleClickFn = {
  _: () => void;
};

function StatusFilter({ value, onChange }: StatusFilterParams) {
  function handleClick(_: handleClickFn, { name }: handleClickParams) {
    onChange(name === 'all' ? '' : name);
  }

  return (
    <StatusContainer>
      <Label>{i18n.ft(messages.status)}</Label>
      <Button.Group compact size="small" color="blue">
        <Button
          name="all"
          active={isEmpty(value)}
          onClick={handleClick}
          content={i18n.ft(messages.all)}
        />
        <Button
          name={ACTIVE}
          active={value === ACTIVE}
          onClick={handleClick}
          content={i18n.ft(messages.active)}
        />
        <Button
          name={COMPLETED}
          active={value === COMPLETED}
          onClick={handleClick}
          content={i18n.ft(messages.completed)}
        />
        <Button
          name={CANCELED}
          active={value === CANCELED}
          onClick={handleClick}
          content={i18n.ft(messages.canceled)}
        />
      </Button.Group>
    </StatusContainer>
  );
}

StatusFilter.propTypes = {
  /** The current value for the status selected. */
  value: PropTypes.oneOf(['', ...trainingStatus]),

  /** Callback fired when the status changes. */
  onChange: PropTypes.func.isRequired
};

export default StatusFilter;
