import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import useAsync from 'hooks/useAsync';
import { FormikHelpers } from 'formik';
import { sendSmsCode } from 'actions/sms';
import { Button, Icon } from 'semantic-ui-react';
import useCurrentUser from 'hooks/useCurrentUser';
import { updateUserMe } from 'actions/userMe';

import {
  Formik,
  Form,
  PhoneInput,
  PreventNavigation
} from 'components/EnhancedForm';

import {
  VerificationWrapper,
  VerificationStatus,
  InputWrapper,
  ButtonWrapper
} from './Styled';

interface MobileFormProps {
  /** If `true`, user is currently in the verification step. */
  isVerifying: boolean;

  /** Callback fired after the phone is updated and the sms code is sent. */
  onSuccess: (phone_number: string) => void;
}

function MobileForm({ isVerifying, onSuccess }: MobileFormProps) {
  const phoneNumberRequest = useAsync();
  const smsRequest = useAsync();
  const currentUser = useCurrentUser();
  const initialPhone = currentUser.login_phone || '+1';

  const [loading, setLoading] = useState(false);

  const [isInitialNotVerified] = useState(
    !R.isNil(currentUser.login_phone) &&
      R.isNil(currentUser.login_phone_confirmed_at)
  );

  function isVerified(phoneNumber: string) {
    const phoneWithExt =
      phoneNumber.charAt(0) === '+' ? phoneNumber : `+${phoneNumber}`;

    return (
      !R.isNil(currentUser.login_phone_confirmed_at) &&
      currentUser.login_phone === phoneWithExt
    );
  }

  function sendCode(phoneNumber: string) {
    smsRequest.run(sendSmsCode(), {
      onSuccess: () => onSuccess(phoneNumber)
    });
  }

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    setLoading(true);
    const params = { login_phone: values.phone_number };
    phoneNumberRequest.run(updateUserMe(params), {
      onSuccess: () => {
        sendCode(params.login_phone);
        setLoading(false);
      },
      onError: (error: any) => {
        const message =
          error.errors?.user?.login_phone?.[0] ??
          i18n.ft(messages.errors.phoneNumber.invalid);

        formik.setFieldError('phone_number', message);
        setLoading(false);
      }
    });
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ phone_number: initialPhone }}
    >
      {({ values }) => (
        <Form>
          <PreventNavigation />
          <InputWrapper>
            <PhoneInput
              name="phone_number"
              label={i18n.ft(messages.fields.phoneNumber)}
            />
          </InputWrapper>

          {isVerified(values.phone_number) && (
            <VerificationWrapper>
              <Icon name="check circle" color="green" />
              <VerificationStatus>
                {i18n.ft(messages.isVerified)}
              </VerificationStatus>
            </VerificationWrapper>
          )}

          {!isVerified(values.phone_number) && isInitialNotVerified && (
            <VerificationWrapper>
              <Icon name="warning circle" color="red" />
              <VerificationStatus>
                {i18n.ft(messages.notVerified)}
              </VerificationStatus>
            </VerificationWrapper>
          )}

          <ButtonWrapper>
            <Button
              type="submit"
              color={isVerifying ? undefined : 'blue'}
              loading={loading}
              disabled={isVerified(values.phone_number)}
              content={
                isVerified(values.phone_number) ? (
                  i18n.ft(messages.verified)
                ) : isVerifying ? (
                  <>
                    <Icon name="redo" />
                    {i18n.ft(messages.resendCode)}
                  </>
                ) : (
                  i18n.ft(messages.sendCode)
                )
              }
            />
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
}

export default MobileForm;
