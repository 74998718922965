import axios from 'axios';

export function fetchUserMe() {
  return axios.get(`/api/v2/users/me`);
}

export function updateUserMe(params = {}) {
  return axios.put(`/api/v2/users/me`, params);
}

export function updateUserMeSettings(params = {}) {
  return axios.put(`/api/v2/users/me/settings`, params);
}

export function updateUserMeJobs(params = {}) {
  return axios.put(`/api/v2/users/me/jobs`, params);
}

export function getMyRecommendations() {
  return axios.get(`/api/v2/users/me/recommendations_received`);
}
