import styled from 'styled-components';

export const PageWrapper = styled.div`
  margin-top: 32px;
  padding-left: 12px;
  padding-right: 12px;

  @media screen and (min-width: 640px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 72px;
    padding-right: 72px;
  }
`;

export const SectionWrapper = styled.div`
  background-color: ${p => p.theme.colors['white']};
  border-radius: 0 0 12px 12px;
  margin-top: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: 640px) {
    padding: 24px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1280px) {
    padding: 24px 48px;
  }
`;

export const ButtonWrapper = styled.div<{
  alignRight?: boolean;
  alignTop?: boolean;
}>`
  margin-top: ${p => (p.alignTop ? null : '8px')};

  @media screen and (min-width: 768px) {
    margin-top: 0;
    margin-left: ${p => (p.alignRight ? 'auto' : null)};
    min-width: 128px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  @media screen and (min-width: 1024px) {
    min-width: 200px;
  }
`;
