const PATH = 'fe.pages.improvements.intake';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'How to use this guide'
  },
  description1: {
    key: `${PATH}.description_part1`,
    defaultValue:
      'In this Kickoff Meeting, you’ll begin to connect and build a foundation for your coaching partnership. Use the optional discussion prompts below to guide your conversation, discussing important information about your experiences, the children, and the learning setting. The prompts will also help you discuss what your coaching partnership will look like and what your coaching will focus on.'
  },
  description2: {
    key: `${PATH}.description_part2`,
    defaultValue:
      'After the kickoff meeting you’ll be able to start a coaching cycle.'
  },
  educatorDetails: {
    key: `${PATH}.educator_details`,
    defaultValue: 'Educator Details'
  },
  center: {
    key: `${PATH}.center`,
    defaultValue: 'Center'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  conversationStarters: {
    key: `${PATH}.conversation_starters`,
    defaultValue: 'Educator’s Experiences'
  },
  educatorIntake: {
    key: `${PATH}.educator_intake`,
    defaultValue: 'Children & Learning Setting'
  },
  prioritiesObjectives: {
    key: `${PATH}.priorities_objectives`,
    defaultValue: 'Professional Priorities'
  },
  howAreYou: {
    key: `${PATH}.how_are_you`,
    defaultValue:
      'Ask: What are you most looking forward to with this partnership? What do you hope that we achieve?'
  },
  educatorResponsePlaceholder: {
    key: `${PATH}.educator_response_placeholder`,
    defaultValue: 'Enter in educator response here.'
  },
  educatorKnowAboutYou: {
    key: `${PATH}.educator_know_about_you`,
    defaultValue: 'Let’s learn more about each other.'
  },
  shareYourExperience: {
    key: `${PATH}.share_your_experience`,
    defaultValue:
      'Let’s talk about your previous experiences with coaching and receiving feedback.'
  },
  shareYourSuccess: {
    key: `${PATH}.share_your_success`,
    defaultValue:
      'Let’s talk about our coaching partnership and how we will work together.'
  },
  tellMeAboutYourChildren: {
    key: `${PATH}.tell_me_about_your_children`,
    defaultValue: 'Let’s talk about the children in your learning setting.'
  },
  whatAgeAreYourChildren: {
    key: `${PATH}.what_age_are_your_children`,
    defaultValue: 'Which age groups are in your learning setting?'
  },
  childrenAgePlaceholder: {
    key: `${PATH}.children_age_placeholder`,
    defaultValue: 'Select children’s age group(s)'
  },
  numberOfChildren: {
    key: `${PATH}.number_of_children`,
    defaultValue: 'How many children are in your learning setting?'
  },
  numberOfChildrenPlaceholder: {
    key: `${PATH}.number_of_children_placeholder`,
    defaultValue: 'Enter amount of children'
  },
  dualLanguageLearners: {
    key: `${PATH}.dual_language_learners`,
    defaultValue: 'Are there Dual Language Learners in your learning setting?'
  },
  homeLanguages: {
    key: `${PATH}.home_languages`,
    defaultValue: 'What are their home languages?'
  },
  homeLanguagesPlaceholder: {
    key: `${PATH}.home_languages_placeholder`,
    defaultValue: 'Select Languages'
  },
  childrenWithDisabilities: {
    key: `${PATH}.children_with_disabilities`,
    defaultValue:
      'Are there children with disabilities in your learning setting?'
  },
  yes: {
    key: `${PATH}.yes`,
    defaultValue: 'Yes'
  },
  no: {
    key: `${PATH}.no`,
    defaultValue: 'No'
  },
  disabilitiesDescription: {
    key: `${PATH}.disabilities_description`,
    defaultValue: 'What needs do they have?'
  },
  disabilitiesPlaceholder: {
    key: `${PATH}.disabilities_placeholder`,
    defaultValue: 'Physical accessibility, visual aids'
  },
  learningSettings: {
    key: `${PATH}.learning_settings`,
    defaultValue: 'Let’s discuss the learning setting.'
  },
  learningGoals: {
    key: `${PATH}.learning_goals`,
    defaultValue:
      'Thinking about the children in your learning setting, let’s talk about your priorities for supporting their learning development.'
  },
  professionalDevelopment: {
    key: `${PATH}.professional_development`,
    defaultValue:
      'Thinking about your professional growth, let’s talk about your own priorities and interests.'
  },
  finish: {
    key: `${PATH}.finish`,
    defaultValue: 'Finish Kickoff Meeting'
  },
  edit: {
    key: `${PATH}.edit`,
    defaultValue: 'Edit'
  },
  describeYourselfPrompt: {
    key: `${PATH}.describe_yourself_prompt`,
    defaultValue:
      'What are some parts of your identity that are most important to you? Share about your cultural or ethnic background, your hometown, the languages you speak, and others.'
  },
  whyEducator: {
    key: `${PATH}.why_educator`,
    defaultValue:
      'Why did you become an educator? How long have you been an educator? Which age groups have you taught? In what kinds of educational settings have you taught?'
  },
  lovePrompt: {
    key: `${PATH}.love_prompt`,
    defaultValue: 'What do you love about being an educator?'
  },
  proudOfEducator: {
    key: `${PATH}.proud_of_educator`,
    defaultValue: 'In your role as an educator, what are you most proud of?'
  },
  tenureOfEducatorPrompt: {
    key: `${PATH}.tenure_of_educator_prompt`,
    defaultValue:
      'Describe your previous experiences with coaching. How helpful were your previous coaching experiences? Why?'
  },
  feedbackPrompt: {
    key: `${PATH}.feedback_prompt`,
    defaultValue:
      'Describe a time when you received feedback that you found useful. What made the feedback useful for you?'
  },
  inspiredPrompt: {
    key: `${PATH}.inspired_prompt`,
    defaultValue:
      'When was the last time you felt inspired to learn or try something new? What did you want to learn or try?'
  },
  generalCyclePrompt: {
    key: `${PATH}.general_cycle_prompt`,
    defaultValue: 'Talk about the general coaching process and cycle.'
  },
  frequencyPrompt: {
    key: `${PATH}.frequency_prompt`,
    defaultValue:
      'Discuss how long the coaching partnership will last, how often you may meet, and how you’ll schedule time together.'
  },
  communicationPrompt: {
    key: `${PATH}.communication_prompt`,
    defaultValue:
      'Talk about how and when you’d prefer to communicate and check in (for example, text or email).'
  },
  resourcesPrompt: {
    key: `${PATH}.resources_prompt`,
    defaultValue:
      'Talk about the types of resources the educator finds most helpful: videos, tip sheets, articles, or something else?'
  },
  questionsPrompt: {
    key: `${PATH}.questions_prompt`,
    defaultValue: 'Ask: Do you have any questions about our coaching process?'
  },
  whoAreThey: {
    key: `${PATH}.who_are_they`,
    defaultValue:
      'Share who the children in the learning setting are - what are their personalities like? What do they enjoy? What are their interests?'
  },
  childrenCulturalBackgrounds: {
    key: `${PATH}.children_cultural_backgrounds`,
    defaultValue:
      'What are children’s racial, ethnic and cultural backgrounds? What languages do the children and their families speak?'
  },
  childrenEnjoyment: {
    key: `${PATH}.children_enjoyment`,
    defaultValue:
      'What disabilities or special needs (physical, cognitive, social emotional, or developmental) do children have?'
  },
  routinePrompt: {
    key: `${PATH}.routine_prompt`,
    defaultValue: 'What does your routine or schedule look like?'
  },
  adultsPrompt: {
    key: `${PATH}.adults_prompt`,
    defaultValue:
      'How many adults are typically in the learning setting? What are their roles?'
  },
  languageSettingPrompt: {
    key: `${PATH}.language_setting_prompt`,
    defaultValue:
      'Which languages do you speak in the setting? Are you following a specific language model?'
  },
  curriculumPrompt: {
    key: `${PATH}.curriculum_prompt`,
    defaultValue: 'What kind of curriculum, if any, are you using?'
  },
  materialsPrompt: {
    key: `${PATH}.materials_prompt`,
    defaultValue:
      'Are there any accommodations or adaptive materials for children with disabilities or special needs?'
  },
  anythingElsePrompt: {
    key: `${PATH}.anything_else_prompt`,
    defaultValue:
      'Is there anything else you’d like to share about the learning setting?'
  },
  childrenSayAndDo: {
    key: `${PATH}.children_say_and_do`,
    defaultValue: 'What do you want children to be able to say and do?'
  },
  childrenAreaOfDevelopment: {
    key: `${PATH}.children_area_of_development`,
    defaultValue:
      'What area of children’s development (social-emotional, language, cognitive, physical, approaches to learning) do you want to prioritize? Why?'
  },
  childrenSupport: {
    key: `${PATH}.children_support`,
    defaultValue:
      'Are there any children in particular that you’d like to focus on supporting more? How might you want to support them?'
  },
  classObservation: {
    key: `${PATH}.class_observation`,
    defaultValue:
      'Have you recently been observed with CLASS, or received feedback from a CLASS Observation? Do you have an upcoming CLASS observation? Would you like to talk more about that?'
  },
  challengingBehaviors: {
    key: `${PATH}.challenging_behaviors`,
    defaultValue:
      'Are there any children demonstrating behaviors that are challenging? Describe the behaviors. When do they occur? Would you like support in meeting those children’s needs?'
  },
  engageProfessionalDevelopment: {
    key: `${PATH}.engage_professional_development`,
    defaultValue:
      'Did you recently engage in a professional development experience? Is there something you’d like to integrate more into your learning setting?'
  },
  strategiesExplore: {
    key: `${PATH}.strategies_explore`,
    defaultValue:
      'Are there strategies that you’d like to explore and practice? Why are these important to you?'
  },
  areaSelectionQuestion: {
    key: `${PATH}.area_selection_question`,
    defaultValue: 'Which area should we focus our coaching on?'
  },
  intakeFieldsRequired: {
    key: `${PATH}.intake_fields_required`,
    defaultValue: 'Field Required'
  },
  ageLevelPrompt: {
    key: `${PATH}.age_level_prompt`,
    defaultValue: 'Select an age level'
  },
  languagePrompt: {
    key: `${PATH}.language_prompt`,
    defaultValue: 'Select a language'
  },
  initialCoachingSessionWith: {
    key: `${PATH}.initial_coaching_session_with`,
    defaultValue: 'Kickoff Meeting with {{firstName}}'
  },
  viewSessionDetails: {
    key: `${PATH}.view_session_details`,
    defaultValue: 'View Meeting Details'
  }
};

export default messages;
