import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';

export const StatusContainer = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
`;

export const Button: any = styled(Menu.Item)`
  &.item {
    color: ${props => props.theme.colors.blue} !important;
    font-size: 14px !important;
    font-weight: bold !important;

    @media screen and (max-width: 440px) {
      font-size: 13px !important;
    }
  }
`;

Button.Group = styled(Menu)`
  &.ui.menu {
    margin-bottom: 0;
    border: 0px;

    .item.active {
      color: #ffffff !important;
      background-color: ${props => props.theme.colors.blue} !important;
    }
  }
`;
