import i18n from 'helpers/i18n';
import messages from './messages';
import { keys, map } from 'ramda';
import useAsync from 'hooks/useAsync';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { getColor, getLabel } from './utils';
import Typography from 'components/Typography';
import { Button, Loader } from 'semantic-ui-react';
import ReliabilitySummary from './ReliabilitySummary';
import { getReliabilityReport, getKnowledgeReport } from 'actions/trainings';

import {
  Container,
  Header,
  MobileHeader,
  Actions,
  MobileActions
} from './Styled';

interface ReliabilityReportProps {
  /** Training ID the report belongs to. */
  trainingId: number;
  testType?: 'knowledge' | 'reliability';
}

const ReliabilityReport: React.FC<ReliabilityReportProps> = ({
  trainingId,
  testType = 'reliability'
}) => {
  const { run, data: reportData, isIdle, isLoading } = useAsync();

  useEffect(() => {
    const action =
      testType === 'reliability' ? getReliabilityReport : getKnowledgeReport;
    run(action(trainingId));
  }, [run, testType, trainingId]);

  if (isIdle || isLoading) {
    return (
      <Loader active inline="centered" content={i18n.ft(messages.loading)} />
    );
  }

  return (
    <Container>
      <MobileHeader>
        <Typography bold color="blue" size="large">
          {testType === 'reliability'
            ? i18n.ft(messages.header)
            : i18n.ft(messages.headerKnowledge)}
        </Typography>
      </MobileHeader>

      <Header>
        <Typography bold color="blue" size="large">
          {testType === 'reliability'
            ? i18n.ft(messages.header)
            : i18n.ft(messages.headerKnowledge)}
        </Typography>

        <Actions>
          <Button
            as={Link}
            to={`/reports/${testType}/${trainingId}`}
            color="blue"
            size="tiny"
          >
            {testType === 'reliability'
              ? i18n.ft(messages.viewReport)
              : i18n.ft(messages.viewKnowledgeReport)}
          </Button>
        </Actions>
      </Header>

      {map(dataKey => {
        const data = reportData[dataKey];
        const key = String(dataKey) as any;

        return (
          <ReliabilitySummary
            key={key}
            passed={data.total_passed}
            failed={data.total_taken - data.total_passed}
            color={getColor(key)}
            label={getLabel(key)}
            inline={keys(reportData).length === 1}
          />
        );
      }, keys(reportData))}

      <MobileActions>
        <Button
          as={Link}
          to={`/reports/reliability/${trainingId}`}
          fluid
          color="blue"
          size="tiny"
        >
          {i18n.ft(messages.viewReport)}
        </Button>
      </MobileActions>
    </Container>
  );
};

export default ReliabilityReport;
