import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './mesages';
import { Status } from '../Styled';
import { Box } from 'components/Box';
import { print } from '../utils';
import themeColors from 'styles/colors';
import { Icon } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import printerIcon from 'images/reliabilityTest/printer.svg';
import attemptIcon from 'images/reliabilityTest/attemptIcon.svg';
import {
  ContainerSection as Section,
  SectionActions
} from 'components/Reports/Reliability/View/Styled';

interface TopSectionProps {
  attempt: number;
  completedAt: string;
  videoTitle?: string;
  imgIcon?: string;
  testType?: 'reliability' | 'preparation';
}
function TopSection({
  attempt,
  completedAt,
  imgIcon,
  testType,
  videoTitle
}: TopSectionProps) {
  const isTablet = useMediaQuery({ minWidth: 640 });

  return (
    <Box
      mx={{ _: '0', lg: '48px' }}
      className={`${testType !== 'preparation' ? 'PrintTopSection' : ''}`}
    >
      <Section
        mt="8px"
        px="10px"
        py="10px"
        borderTopRadius={isTablet ? '12px' : ''}
        className="px-5 mb-2"
      >
        <SectionActions>
          <div className="grid grid-cols-12 w-full">
            <div className="self-center col-span-10 md:col-span-3">
              <img
                src={testType === 'preparation' ? imgIcon : attemptIcon}
                alt={''}
                className="mr-2 inline"
              />
              <span tabIndex={0}>
                {testType === 'preparation'
                  ? videoTitle
                  : i18n.ft(messages.attempt, { number: attempt })}
              </span>
            </div>
            <div
              className="PrintTopSection self-center col-span-12 font-bold md:text-center md:col-span-6"
              tabIndex={0}
            >
              <Status
                iconColor={themeColors.white}
                iconBackGroundColor={themeColors.scaleBlue}
                className="mr-2"
              >
                <Icon name="check" size="tiny" className="align-middle" />
              </Status>
              {i18n.ft(messages.completed) + ' '}
              <span className="font-normal">
                {moment(completedAt).format('MM/DD/YYYY')}
              </span>
            </div>
            <div className="PrintTopSection self-center absolute right-0 col-span-2 justify-self-end md:col-span-3 md:static">
              <img
                src={printerIcon}
                alt={''}
                className="mr-2 cursor-pointer"
                onClick={print}
                aria-labelledby="print"
                tabIndex={0}
                role="button"
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.code === 'Space') print();
                }}
              />
              <span className="sr-only" id="print">
                {i18n.ft(messages.click)}
              </span>
            </div>
          </div>
        </SectionActions>
      </Section>
    </Box>
  );
}

export default TopSection;
