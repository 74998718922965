const PATH = 'fe.pages.account.home';

const messages = {
  information: {
    key: `${PATH}.information`,
    defaultValue: 'Personal Information'
  },
  settings: {
    key: `${PATH}.settings`,
    defaultValue: 'Settings'
  },
  address: {
    key: `${PATH}.address`,
    defaultValue: 'Address'
  },
  save: {
    key: `${PATH}.save`,
    defaultValue: 'Save Changes'
  },
  fields: {
    firstName: {
      key: `${PATH}.fields.first_name`,
      defaultValue: 'First Name'
    },
    lastName: {
      key: `${PATH}.fields.last_name`,
      defaultValue: 'Last Name'
    },
    email: {
      key: `${PATH}.fields.email`,
      defaultValue: 'Email'
    },
    stateRegistry: {
      key: `${PATH}.fields.state_registry`,
      defaultValue: 'State Registry ID'
    },
    notifications: {
      key: `${PATH}.fields.notifications`,
      defaultValue: 'Notification Emails'
    },
    language: {
      key: `${PATH}.fields.language`,
      defaultValue: 'Language Preference'
    },
    shipping1: {
      key: `${PATH}.fields.shipping1`,
      defaultValue: 'Shipping Address 1'
    },
    shipping1Placeholder: {
      key: `${PATH}.fields.shipping1_placeholder`,
      defaultValue: 'Street and number'
    },
    shipping2: {
      key: `${PATH}.fields.shipping2`,
      defaultValue: 'Shipping Address 2'
    },
    shipping2Placeholder: {
      key: `${PATH}.fields.shipping2_placeholder`,
      defaultValue: 'Apartment, suite, unit, building, floor, etc.'
    },
    city: {
      key: `${PATH}.fields.city`,
      defaultValue: 'City'
    },
    country: {
      key: `${PATH}.fields.country`,
      defaultValue: 'Country'
    },
    state: {
      key: `${PATH}.fields.state`,
      defaultValue: 'State/Province'
    },
    postalCode: {
      key: `${PATH}.fields.postal_code`,
      defaultValue: 'Postal Code'
    }
  },
  errors: {
    base: {
      key: `${PATH}.errors.base`,
      defaultValue: 'Failed to update account. {{extra}}'
    },
    firstName: {
      required: {
        key: `${PATH}.errors.first_name.required`,
        defaultValue: 'First Name is required'
      }
    },
    lastName: {
      required: {
        key: `${PATH}.errors.last_name.required`,
        defaultValue: 'Last Name is required'
      }
    },
    email: {
      required: {
        key: `${PATH}.errors.email.required`,
        defaultValue: 'Email address is required'
      },
      invalid: {
        key: `${PATH}.errors.email.invalid`,
        defaultValue: 'Invalid email address'
      }
    },
    notifications: {
      required: {
        key: `${PATH}.errors.notifications.required`,
        defaultValue: 'You must select one option'
      }
    },
    language: {
      required: {
        key: `${PATH}.errors.language.required`,
        defaultValue: 'You must select one option'
      }
    }
  }
};

export default messages;
