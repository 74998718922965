import i18n from 'helpers/i18n';
import messages from './messages';

export function useEmailNotifications() {
  const options = [
    {
      key: 'none',
      value: 'none',
      text: i18n.ft(messages.none)
    },
    {
      key: 'day',
      value: 'day',
      text: i18n.ft(messages.day)
    },
    {
      key: 'week',
      value: 'week',
      text: i18n.ft(messages.week)
    },
    {
      key: 'month',
      value: 'month',
      text: i18n.ft(messages.month)
    }
  ];

  return options;
}
