import * as R from 'ramda';
import I18n, { TranslateOptions } from 'i18n-js';

type TranslateArg = {
  key: string;
  defaultValue: string;
};

class I18nHelper {
  scope: string;

  /**
   * Constructor method for I18nHelper Class
   */
  constructor(scope: string) {
    this.scope = scope;
  }

  /**
   * Returns a localized string for the given key.
   */
  t(key: string, defaultValue: string, options: TranslateOptions = {}): string {
    options.defaultValue = defaultValue;
    options.scope = this.scope;

    return I18n.t(key, options);
  }

  /**
   * Returns a localized string for the given key.
   * Can be used without creating an instance of the class.
   */
  static ft(
    { key, defaultValue }: TranslateArg,
    options: TranslateOptions = {}
  ) {
    return I18n.t(key, R.mergeRight({ defaultValue }, options));
  }
}

export default I18nHelper;
