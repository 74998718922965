const PATH = 'fe.components.organization.pagination';

const messages = {
  goToPage: {
    key: `${PATH}.go_to_page`,
    defaultValue: 'Go to page'
  }
};

export default messages;
