const PATH = 'fe.components.trainings.view.card_checkout.credit_card_form';

const messages = {
  finishAndPay: {
    key: `${PATH}.finish_and_pay`,
    defaultValue: 'Finish and Pay'
  },
  cardName: {
    key: `${PATH}.card_name`,
    defaultValue: 'Name on Card'
  },
  cardNumber: {
    key: `${PATH}.card_number`,
    defaultValue: 'Credit Card Number'
  },
  expirationDate: {
    key: `${PATH}.expiration_date`,
    defaultValue: 'Expiration Date'
  },
  expiryMonth: {
    key: `${PATH}.expiry_month`,
    defaultValue: 'Expiration Month'
  },
  expiryYear: {
    key: `${PATH}.expiry_year`,
    defaultValue: 'Expiration Year'
  },
  month: {
    key: `${PATH}.month`,
    defaultValue: 'Month'
  },
  year: {
    key: `${PATH}.year`,
    defaultValue: 'Year'
  },
  securityCode: {
    key: `${PATH}.security_code`,
    defaultValue: 'Security Code'
  },
  policyLabel: {
    key: `${PATH}.policy_label`,
    defaultValue: 'I agree to the Affiliate Cancellation Policy'
  },
  policy01: {
    key: `${PATH}.policy_01`,
    defaultValue: 'I agree to the'
  },
  policy02: {
    key: `${PATH}.policy_02`,
    defaultValue: 'Affiliate Cancellation Policy'
  },
  opensWindow: {
    key: `${PATH}.opens_window`,
    defaultValue: 'Opens new window'
  },
  errors: {
    cardName: {
      required: {
        key: `${PATH}.errors.card_name.required`,
        defaultValue: 'Card Holder Name is required'
      }
    },
    cardNumber: {
      required: {
        key: `${PATH}.errors.card_number.required`,
        defaultValue: 'Credit Card Number is required'
      }
    },
    expiryMonth: {
      required: {
        key: `${PATH}.errors.expiry_month.required`,
        defaultValue: 'Expiration Month is required'
      }
    },
    expiryYear: {
      required: {
        key: `${PATH}.errors.expiry_year.required`,
        defaultValue: 'Expiration Year is required'
      }
    },
    securityCode: {
      required: {
        key: `${PATH}.errors.security_code.required`,
        defaultValue: 'Security Code is required'
      }
    },
    agreeToPolicy: {
      mustAgree: {
        key: `${PATH}.errors.security_code.must_agree`,
        defaultValue:
          'Before you can place your order, you must accept the Affiliate Cancellation Policy'
      }
    },
    unknown: {
      key: `${PATH}.errors.unknown`,
      defaultValue: 'An unknown error occurred, please try again later'
    }
  }
};

export default messages;
