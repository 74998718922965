import RouteHelpers from 'helpers/routes';
import { useMeasure } from 'context/measure';
import { useLocation } from 'react-router-dom';

export function useMeasureLocation() {
  const { pathname } = useLocation();
  const { assessment } = useMeasure();

  const detailsUrl = RouteHelpers.getPath('measure-view-details', {
    id: assessment.id
  });

  const reviewUrl = RouteHelpers.getPath('measure-view-review', {
    id: assessment.id
  });

  return {
    isDetailsPage: pathname === detailsUrl,
    isReviewPage: pathname === reviewUrl
  };
}
