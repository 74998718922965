import styled from 'styled-components';

export const LineBreak = styled.hr`
  border: 0;
  margin: 16px 0;
  border-top: 3px solid #ffffff;

  @media screen and (min-width: 640px) {
    margin: 24px 0;
  }
`;

export const DataGrid = styled.div`
  display: grid;
  grid-gap: 12px;
  align-items: center;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }

  @media screen and (min-width: 1024px) {
    grid-gap: 14px;
  }
`;

export const StatusWrapper = styled.div<{
  hasErrors: boolean;
}>`
  padding: 8px;
  border-radius: 12px;
  background-color: white;
  display: inline-flex;
  align-items: center;

  @media screen and (min-width: 640px) {
    padding: 8px 16px;
  }

  ${({ hasErrors, theme }) =>
    hasErrors &&
    `
    border: 1px solid ${theme.colors['scaleWarning']};
  `};
`;

export const Name = styled.span`
  font-weight: 700;
  color: ${p => p.theme.colors['scaleDarkBlue']};
`;

export const WarningScore = styled.span`
  color: ${p => p.theme.colors['scaleWarning']};
  font-size: 16px;

  @media screen and (min-width: 768px) {
    grid-column-start: 2;
  }
`;
