import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

export const Title = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
`;

export const TextAreaWrapper = styled.div<{ expanded: boolean }>`
  display: ${p => (p.expanded ? 'block' : 'none')};
  margin-bottom: 12px;
`;

export const TextArea = styled.textarea`
  display: block;
  width: 100%;
  box-sizing: border-box;

  border: 0;
  border-radius: 11px;
  padding-left: 31px;
  padding-top: 23px;
`;

export const StatusWrapper = styled.div<{
  hasErrors: boolean;
}>`
  padding: 8px;
  border-radius: 12px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-items: center;

  @media screen and (min-width: 640px) {
    padding: 8px 16px;
  }

  ${({ hasErrors, theme }) =>
    hasErrors &&
    `
    border: 1px solid ${theme.colors['scaleWarning']};
  `};
`;

export const StyledIcon = styled(Icon)`
  margin-left: 8px !important;
  width: 22px !important;
  height: 22px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 3px;
  padding-left: 4px;

  @media screen and (min-width: 640px) {
    padding-top: 4px;
    margin-right: 56px;
    width: 30px !important;
    height: 30px !important;
  }
`;
