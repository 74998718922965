const PATH = 'fe.pages.improvements.educator_profile.complete_coaching_cycle';

const messages = {
  nextCoachingCycle: {
    key: `${PATH}.next_coaching_cycle`,
    defaultValue: 'Next Coaching Cycle'
  },
  description: {
    key: `${PATH}.description`,
    defaultValue: 'Ready to start the next cycle?'
  },
  helperText: {
    key: `${PATH}.helper_text`,
    defaultValue:
      'Click below to mark this cycle as completed. You’ll be able to start a new coaching cycle. You will have the opportunity to continue working on this coaching cycle’s focus or pick a new focus.'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Finish Current Coaching Cycle'
  }
};
export default messages;
