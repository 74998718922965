import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import { useMeasure } from 'context/measure';
import { getObservationCycle } from 'actions/envScales/observationCycles';
import CycleDetailsForm from 'components/Measure/View/Cycles/CycleDetailsForm';
import CycleScoresForm from 'components/Measure/View/Cycles/CycleScoresForm';
import { getObservationCycleScores } from 'actions/envScales/observationCycleScores';
import BackToTop from 'components/Measure/BackToTop';
import FooterNav from 'components/Measure/View/FooterNav';
import { CycleViewWrapper } from 'components/Measure/View/Styled';
import { useRouteParams } from 'hooks/useRouteParams';
import PageLoader from 'components/PageLoader';

type ParamsType = {
  id: string;
  cycleId: string;
};

function CycleView() {
  const { assessment, ageLevel } = useMeasure();
  const { cycleId } = useRouteParams<ParamsType>();
  const { run, data: cycle, isPending } = useAsync();
  const { run: scoresRun, data: cycleScores, ...scoresReq } = useAsync();
  const observationId = assessment.observation!.id;

  useEffect(() => {
    run(getObservationCycle(observationId, parseInt(cycleId)));
    scoresRun(getObservationCycleScores(parseInt(cycleId)));
  }, [run, scoresRun, cycleId, observationId]);

  if (isPending || scoresReq.isPending) {
    return <PageLoader />;
  }

  return (
    <CycleViewWrapper>
      {cycle && (
        <CycleDetailsForm
          ageLevel={ageLevel}
          assessment={assessment}
          cycle={cycle}
        />
      )}

      {cycleScores && (
        <CycleScoresForm
          cycle={cycle}
          cycleScores={cycleScores.observation_cycle_scores}
          ageLevel={ageLevel}
        />
      )}

      <BackToTop />
      <FooterNav />
    </CycleViewWrapper>
  );
}

export default CycleView;
