const PATH = 'fe.components.reports.observation.averages_by_classroom_table';

const messages = {
  classroom: {
    key: `${PATH}.classroom`,
    defaultValue: 'Classroom name'
  },
  date: {
    key: `${PATH}.date`,
    defaultValue: 'Observation Date'
  },
  download: {
    key: `${PATH}.download`,
    defaultValue: 'Download Report'
  }
};

export default messages;
