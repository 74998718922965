const PATH = 'fe.components.certifications.status_tag';

const messages = {
  active: {
    key: `${PATH}.active`,
    defaultValue: 'Active: Current'
  },
  expired: {
    key: `${PATH}.expired`,
    defaultValue: 'Expired'
  },
  expiringSoon: {
    key: `${PATH}.expiring_soon`,
    defaultValue: 'Active: Expiring Soon'
  },
  seekingInitial: {
    key: `${PATH}.seeking_initial`,
    defaultValue: 'Seeking Initial'
  }
};

export default messages;
