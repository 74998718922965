import styled from 'styled-components';

export const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
`;

export const LabelContent = styled.span`
  display: block;
`;

export const Optional = styled.span`
  margin-left: 8px;
  font-weight: 400;
  color: #86868a;
`;

export const Required = styled.span`
  margin-left: 8px;
  color: #e71921;
`;
