const PATH = 'fe.components.measure.observation_videos.recommended_time';

const messages = {
  cycle: {
    key: `${PATH}.cycle`,
    defaultValue: 'Cycle'
  },
  startsAt: {
    key: `${PATH}.starts_at`,
    defaultValue: 'Starts at'
  },
  endsAt: {
    key: `${PATH}.ends_at`,
    defaultValue: 'Ends at'
  }
};

export default messages;
