import { DropdownItemProps } from 'semantic-ui-react';

export const classAgeLevels: DropdownItemProps[] = [
  { key: 'Pre-K', value: 'Pre-K', text: 'Pre-K', class_version: 1 },
  { key: 'Inf', value: 'Inf', text: 'Infant', class_version: 1 },
  { key: 'Todd', value: 'Todd', text: 'Toddler', class_version: 1 }
];

export const class2EditionAgeLevels: DropdownItemProps[] = [
  { key: 'PK-3rd', value: 'PK-3rd', text: 'Pre-K–3rd', class_version: 2 }
];

export const environmentsAgeLevels: DropdownItemProps[] = [
  { key: 'PK-K', value: 'PK-K', text: 'Pre-K/K', class_version: null }
];
