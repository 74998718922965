const PATH =
  'fe.pages.testing.reliability.score_report.performance_report_section';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Performance Report'
  },
  description: {
    key: `${PATH}.description`,
    defaultValue:
      'The CLASS is a complex measurement tool and it often takes extra practice to learn how to view classrooms from the CLASS perspective. Below you will find details of your performance at the domain and dimension level to assist you in identifying areas for improvement.'
  },
  videoResults: {
    key: `${PATH}.video_results`,
    defaultValue: 'Test Results by Video'
  }
};

export default messages;
