const PATH = 'fe.components.measure.view.env_scales_assessment';

const messages = {
  return: {
    key: `${PATH}.return`,
    defaultValue: 'Return to List'
  },
  review: {
    key: `${PATH}.review`,
    defaultValue: 'Review Summary'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'Oops, look like you missed some items'
  },
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Select Scores'
  },
  subHeader: {
    key: `${PATH}.sub_header`,
    defaultValue: 'Choose 1 for “No Examples”, 2 for “Some”, and 3 for “Many”'
  },
  delete: {
    key: `${PATH}.delete`,
    defaultValue: 'Delete'
  }
};

export default messages;
