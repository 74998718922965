import { useState } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import Spacer from 'components/Spacer';
import { FormikHelpers } from 'formik';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getValidationSchema } from './utils';
import TextButton from 'components/TextButton';
import { Button, Icon } from 'semantic-ui-react';
import { useLoginManager } from 'components/LoginManager';
import { Formik, Form, Input, PhoneInput } from 'components/EnhancedForm';

import {
  ButtonsWrapper,
  ButtonWrapper,
  TextButtonWrapper,
  ForgotWrapper
} from './Styled';

interface LoginWithPasswordProps {
  /** If `true`, the form will display phone + password fields. Otherwise email + password. */
  loginWithPhone?: boolean;
}

function LoginWithPassword({ loginWithPhone }: LoginWithPasswordProps) {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const { setStep, setEmailOrPhone, emailOrPhone, submitForm } =
    useLoginManager();

  // The locale is used to update the user's preferred language on login.
  // This is old functionallity, we might want to revisit in the future.
  const locale = useSelector((state: any) => state.locale);

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    let data: any = {
      user: { password: values.password, preferred_language: locale }
    };

    if (loginWithPhone) {
      data.user.login_phone = values.emailOrPhone;
    } else {
      data.user.email = values.emailOrPhone;
    }

    submitForm(data, formik);
  }

  function handleIconClick() {
    setIsPasswordHidden(!isPasswordHidden);
  }

  function handleBackToLogin(emailOrPhone: string) {
    return () => {
      setEmailOrPhone(emailOrPhone);
      setStep('login');
    };
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(loginWithPhone)}
      initialValues={{ emailOrPhone, password: '' }}
      validateOnBlur={false}
    >
      {formik => (
        <Form>
          <Spacer size={16} />

          {loginWithPhone ? (
            <PhoneInput
              name="emailOrPhone"
              label={i18n.ft(messages.fields.phone)}
              placeholder={i18n.ft(messages.fields.phonePlaceholder)}
            />
          ) : (
            <Input
              name="emailOrPhone"
              label={i18n.ft(messages.fields.email)}
              placeholder={i18n.ft(messages.fields.emailPlaceholder)}
              errorLabel={{ textAlign: 'left' }}
              type={'email'}
              autoComplete="email"
            />
          )}

          <Spacer size={8} />
          <Input
            autoFocus
            name="password"
            label={i18n.ft(messages.fields.password)}
            placeholder={i18n.ft(messages.fields.passwordPlaceholder)}
            icon={<Icon link name="eye" onClick={handleIconClick} />}
            type={isPasswordHidden ? 'password' : 'text'}
            errorLabel={{ textAlign: 'left' }}
          />

          <ButtonsWrapper>
            <ButtonWrapper>
              <Button
                fluid
                size="large"
                color="green"
                type="submit"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                content={i18n.ft(messages.login)}
              />
            </ButtonWrapper>

            <ForgotWrapper>
              <TextButton
                as={Link}
                to={RouteHelpers.getPath('users-forgot-password')}
              >
                {i18n.ft(messages.forgotPassword)}
              </TextButton>
            </ForgotWrapper>
          </ButtonsWrapper>

          <TextButtonWrapper>
            <TextButton
              type="button"
              onClick={handleBackToLogin(formik.values.emailOrPhone)}
            >
              {i18n.ft(messages.back)}
            </TextButton>
          </TextButtonWrapper>
        </Form>
      )}
    </Formik>
  );
}

export default LoginWithPassword;
