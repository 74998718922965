import i18n from 'helpers/i18n';
import messages from './messages';
import React from 'react';
import Checkbox from 'components/EnhancedForm/Checkbox';
import validationSchema from './validationSchema';
import { Button } from 'semantic-ui-react';
import { Formik, Form, Input } from 'components/EnhancedForm';

import { Row, Column, Actions, Errors } from './Styled';

interface FormProps {
  /** Object used to send the data loaded by the user. */
  initialValues: any;

  /** Yup Object used to add validations to Validation Schema. */
  validationFields?: any;

  /** Callback fired when user click on the send button. */
  handleSubmit: (values?: any, formik?: any) => void;

  /** Used to know if the page is loading. */
  loading: boolean;

  /** Used to show to the user the errors the he has. */
  errors?: string;

  /** React Node. */
  children?: React.ReactNode;
}

const UserForm: React.FC<FormProps> = ({
  initialValues,
  validationFields,
  handleSubmit,
  loading,
  errors,
  children
}) => {
  function getValidationSchema() {
    return validationFields !== undefined
      ? validationSchema.concat(validationFields)
      : validationSchema;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
    >
      {({ submitForm }) => (
        <Form>
          {errors && <Errors>{errors}</Errors>}

          <Input hidden name="token" />

          {children}

          <Row>
            <Column>
              <Input
                icon="user"
                name="first_name"
                placeholder={i18n.ft(messages.firstName)}
              />
            </Column>
            <Column>
              <Input
                icon="user"
                name="last_name"
                placeholder={i18n.ft(messages.lastName)}
              />
            </Column>
          </Row>

          <Input
            icon="mail"
            name="email"
            placeholder={i18n.ft(messages.email)}
          />

          <Input
            icon="lock"
            type="password"
            name="password"
            placeholder={i18n.ft(messages.password)}
          />

          <Input
            icon="lock"
            type="password"
            name="password_confirmation"
            placeholder={i18n.ft(messages.confirmPassword)}
          />

          <Checkbox
            name="tos_pp_agreed_at"
            error={{ textAlign: 'left' }}
            label={
              <label>
                {i18n.ft(messages.termsOfService.privacy01) + ' '}
                <a
                  target="_blank"
                  href="http://teachstone.com/terms-conditions/"
                  rel="noopener noreferrer"
                >
                  {i18n.ft(messages.termsOfService.privacy02) + ' '}
                </a>
                {i18n.ft(messages.termsOfService.privacy03) + ' '}
                <a
                  target="_blank"
                  href="http://teachstone.com/privacy-policy/"
                  rel="noopener noreferrer"
                >
                  {i18n.ft(messages.termsOfService.privacy04) + ' '}
                </a>
              </label>
            }
          />

          <Actions>
            <Button
              fluid
              type="submit"
              color="green"
              loading={loading}
              disabled={loading}
              onClick={submitForm}
              content={i18n.ft(messages.createAccount)}
            />
          </Actions>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
