const PATH = 'fe.components.measure.observation_videos.video_player';

const messages = {
  loading: {
    key: `${PATH}.loading`,
    defaultValue: 'Loading'
  },
  frameTitle: {
    key: `${PATH}.frame_title`,
    defaultValue: 'Observation Video Player'
  }
};

export default messages;
