import React from 'react';
import WatchedVideo from './WatchedVideo';
import UnwatchedVideo from './UnwatchedVideo';
import CurrentVideo from './CurrentVideo';

export default function VideoProgress(props: {
  id: number;
  currentVideoIndex: number;
}) {
  const { id, currentVideoIndex } = props;
  const currentVideo = currentVideoIndex === id;

  return (
    <div key={id}>
      {currentVideo ? (
        <CurrentVideo id={id} />
      ) : (
        <>
          {id < currentVideoIndex ? (
            <WatchedVideo id={id} />
          ) : (
            <UnwatchedVideo id={id} />
          )}
        </>
      )}
    </div>
  );
}
