import { ChangeEvent } from 'react';
import { MetaDataAnswer } from 'types/api/envScales/MetaDataAnswer';
import { MetaDataQuestion } from 'types/api/envScales/MetaDataQuestion';
import { QuestionRow, QuestionText, RadioInput } from './Styled';
import { AnswersBox } from '../LabelsBox/Styled';
import LabelsBox from '../LabelsBox';

interface QuestionProps {
  question: MetaDataQuestion;
  showLabels: boolean;
  handleRadioInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  answer?: MetaDataAnswer;
  error: boolean;
  isFirstQuestion: boolean;
}

function Question({
  question,
  showLabels,
  handleRadioInputChange,
  answer,
  error,
  isFirstQuestion
}: QuestionProps) {
  const radioGroupName = `${question.question}_${question.meta_data_choice_id}`;

  return (
    <QuestionRow>
      <QuestionText>{question.question}</QuestionText>

      {showLabels && <LabelsBox />}

      <AnswersBox>
        <RadioInput
          name={radioGroupName}
          value="1"
          data-question-id={question.id}
          data-choice-id={question.meta_data_choice_id}
          data-is-first-question={isFirstQuestion}
          onChange={handleRadioInputChange}
          checked={answer?.value === 1}
          aria-label={question.question}
          error={error}
        />
        <RadioInput
          name={radioGroupName}
          value="2"
          data-question-id={question.id}
          data-choice-id={question.meta_data_choice_id}
          data-is-first-question={isFirstQuestion}
          onChange={handleRadioInputChange}
          checked={answer?.value === 2}
          aria-label={question.question}
          error={error}
        />
        <RadioInput
          name={radioGroupName}
          value="3"
          data-question-id={question.id}
          data-choice-id={question.meta_data_choice_id}
          data-is-first-question={isFirstQuestion}
          onChange={handleRadioInputChange}
          checked={answer?.value === 3}
          aria-label={question.question}
          error={error}
        />
      </AnswersBox>
    </QuestionRow>
  );
}

export default Question;
