import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { toastSuccess } from 'components/Toast';
import { TextWrapper, TermsWrapper } from './Styled';
import { Section, SectionTitle } from '../Home/Styled';
import MobileForm from 'components/Account/Phone/MobileForm';
import SmsCodeForm from 'components/Account/Phone/SmsCodeForm';
import { fetchUserMe } from 'actions/userMe';
import { useDispatch } from 'react-redux';
import { update } from 'store/slices/user';

function PhonePage() {
  const dispatch = useDispatch();
  const [isVerifying, setIsVerifying] = useState(false);

  function handleSuccess() {
    setIsVerifying(true);
  }

  function handleVerification() {
    setIsVerifying(false);

    fetchUserMe().then(response => {
      dispatch(update(response.data));
    });

    toastSuccess({ description: i18n.ft(messages.success) });
  }

  function handleRemovedPhoneNumber() {
    setIsVerifying(false);
  }

  return (
    <Section>
      <SectionTitle>{i18n.ft(messages.phoneLogin)}</SectionTitle>
      <TextWrapper>
        {i18n.ft(messages.description) + ' '}
        <a
          href="https://teachstone.com/support/login/#link-1"
          target="_blank"
          rel="noreferrer"
        >
          {i18n.ft(messages.learnMore)}
        </a>
      </TextWrapper>

      <MobileForm
        isVerifying={isVerifying}
        onSuccess={handleSuccess}
        removedPhone={handleRemovedPhoneNumber}
      />
      {isVerifying && <SmsCodeForm onSucess={handleVerification} />}

      <TermsWrapper>{i18n.ft(messages.terms)}</TermsWrapper>
    </Section>
  );
}

export default PhonePage;
