import { THRESHOLD } from '../constants';

export const computeAngle = (value: number, ceiling = 100) =>
  (value * 360) / ceiling;

export const computeBarColor = (value: number) =>
  value < THRESHOLD ? 'black' : 'white';

export const computeBarShadow = (value: number) =>
  value < THRESHOLD ? 0 : 0.7;

export const computeCornerRadius = (value: number, size: number) =>
  value < 10
    ? Math.min(size / Math.pow(3 + 10 - value, 1.75), size / 15)
    : size / 15;

export const computeOpacity = (score: number) =>
  1 / Math.pow((score - 50) / 100, -1);

export const RADIAN = Math.PI / 180;
