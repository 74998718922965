import clsx from 'clsx';
import React from 'react';
import { InformationCard } from 'pages/Improvements/EducatorProfile/Styled';
import { RadioInput } from 'pages/Improvements/Intake/Styled';
import {
  ImprovementStrategyRatings,
  Ratings
} from 'types/api/improvement/ImprovementStrategyRatings';
import { ImprovementStrategySet } from 'types/api/improvement/ImprovementStrategySet';
import { TextArea } from 'components/EnhancedForm';
import i18n from 'helpers/i18n';
import messages from './messages';
import { ErrorMessage, useFormikContext } from 'formik';
import HideOnPrint from 'pages/Improvements/HideOnPrint';
export type ImprovementStrategyRatingAttribute = {
  id?: number;
  rating?: string;
  improvement_strategy_id?: number;
};
export type ObservationRatingsProps = {
  strategySetsData: ImprovementStrategySet[];
  strategyRatingsData: ImprovementStrategyRatings;
  improvement_strategy_ratings_attributes: ImprovementStrategyRatingAttribute[];
  improvement_scale_ratings_attributes: {
    id?: number;
    rating: string;
    scale_type: string;
  }[];
  disabled?: boolean;
  edit?: boolean;
};

const translateRatings: { [ratingInSnakeCase: string]: string } = {
  not_observed: i18n.ft(messages.notObserved),
  not_at_all: i18n.ft(messages.notAtAll),
  somewhat: i18n.ft(messages.somewhat),
  completely: i18n.ft(messages.completely)
};

const translateOverallRatings: { [ratingInSnakeCase: string]: string } = {
  not_at_all: i18n.ft(messages.overallRatings.disagree),
  somewhat: i18n.ft(messages.overallRatings.agree),
  completely: i18n.ft(messages.overallRatings.stronglyAgree)
};

function ObservationRatings({ ...props }: ObservationRatingsProps) {
  const {
    improvement_strategy_ratings_attributes,
    strategyRatingsData,
    strategySetsData,
    disabled,
    edit
  } = props;
  const ratings: Ratings = strategyRatingsData?.ratings || {};

  const sortedRatings: string[] = Object.keys(ratings).sort(function (a, b) {
    return ratings[a] - ratings[b];
  });

  const sortedRatingsForObservationScaleRating = sortedRatings.slice(1);

  const capitalizeRating = (rating: string) => translateRatings[rating] || '';
  const capitalizeOverallRating = (rating: string) =>
    translateOverallRatings[rating] || '';

  const { values, setFieldTouched, handleChange } = useFormikContext();
  const scaleRatings = (
    values as { improvement_scale_ratings_attributes: { rating: string }[] }
  ).improvement_scale_ratings_attributes;

  async function handleChangeInForm(event: React.ChangeEvent<any>) {
    handleChange(event);
    await setFieldTouched(event.target.name, true, false);
  }

  async function handleRadioChangeInForm(event: React.ChangeEvent<any>) {
    await handleChangeInForm(event);
  }
  async function handleTextChangeInForm(event: React.ChangeEvent<any>) {
    await handleChangeInForm(event);
  }

  function validateRadio(value: string) {
    if (value === null) {
      return i18n.ft(messages.ratingFieldRequired);
    }
  }

  return (
    <InformationCard>
      <div className={HideOnPrint(!!disabled, '')}>
        <h3 className="print:break-before-page">
          {i18n.ft(messages.effectivenessOfStrategiesAndInteractions)}
        </h3>
        <span>{i18n.ft(messages.educatorEffectivenessQuestion)}</span>

        {strategySetsData?.map((ss, index) => {
          return (
            <div key={`improvementStrategy-${index}`} className="pt-4">
              {ss.improvement_strategy.name}:
              <div className="grid md:grid-cols-4 py-4 gap-x-2">
                {sortedRatings.map((sortedRating, index2) => {
                  const strategyRating =
                    improvement_strategy_ratings_attributes.find(
                      (scale: ImprovementStrategyRatingAttribute) =>
                        scale.improvement_strategy_id ===
                        ss.improvement_strategy.id
                    );
                  const checked = strategyRating?.rating === sortedRating;
                  const wrapClasses = clsx(
                    'flex justify-self-center w-full rounded-lg p-2 border-2 border-slate-300 mb-2 md:mb-0',
                    { 'bg-slate-200': checked }
                  );

                  return (
                    <div
                      key={`improvementStrategy${index}-sortedRatings-${index2}`}
                      className={wrapClasses}
                    >
                      <label className="flex gap-2 items-center">
                        <RadioInput
                          type="radio"
                          name={`improvement_strategy_ratings_attributes[${index}]['rating']`}
                          value={sortedRating}
                          checked={checked}
                          validate={(v: string) => validateRadio(v)}
                          disabled={disabled}
                          onChange={handleRadioChangeInForm}
                          style={{ inlineSize: '13px' }}
                        />
                        <span>{capitalizeRating(sortedRating)}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="font-bold text-red-600 text-sm">
                <ErrorMessage
                  name={`improvement_strategy_ratings_attributes[${index}]['rating']`}
                />
              </div>
            </div>
          );
        })}
        <hr />
        <div className="pt-4">
          {i18n.ft(messages.overallObservationRating)}
          <br />
          <span>{i18n.ft(messages.overallObservationRatingSubtitle)}</span>
          <div className="flex py-4">
            {sortedRatingsForObservationScaleRating.map(
              (sortedRating, index) => {
                const checked =
                  scaleRatings && scaleRatings[0]['rating'] === sortedRating;
                const wrapClasses = clsx(
                  'flex rounded-lg p-2 mr-2 md:mr-8 border-2 border-slate-300',
                  { 'bg-slate-200': checked }
                );

                return (
                  <div key={`scale-rating-${index}`} className={wrapClasses}>
                    <label className="flex gap-2">
                      <RadioInput
                        type="radio"
                        name="improvement_scale_ratings_attributes[0]['rating']"
                        value={sortedRating}
                        disabled={disabled}
                        onChange={handleRadioChangeInForm}
                        validate={(v: string) => validateRadio(v)}
                        style={{ inlineSize: '18px' }}
                      />
                      {capitalizeOverallRating(sortedRating)}
                    </label>
                  </div>
                );
              }
            )}
          </div>
          <div className="font-bold text-red-600 text-sm">
            <ErrorMessage name="improvement_scale_ratings_attributes[0]['rating']" />
          </div>
        </div>
      </div>
      <TextArea
        rows={8}
        name="OptionalSummaryNote"
        label={i18n.ft(messages.optionalSummaryNoteMessage)}
        placeholder={i18n.ft(messages.optionalSummaryNotePlaceholder)}
        disabled={disabled && !edit}
        onChange={handleTextChangeInForm}
      />
    </InformationCard>
  );
}

export default ObservationRatings;
