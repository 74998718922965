import styled from 'styled-components';
import { Button, Pagination as SemanticPagination } from 'semantic-ui-react';

export const ButtonWrapper = styled.div`
  display: grid;
  grid-gap: 16px 0;
  padding: 0 16px;
  margin-bottom: 16px;
  direction: rtl;

  @media screen and (min-width: 768px) {
    padding: 0;
    margin: 0 0 0 16px;
    grid-template-columns: 216px;
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: 216px 200px 200px;
    grid-gap: 0 16px;
  }
`;

export const EmptyObservations = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`;

export const EmptyMessageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`;

export const NoObservationsMessage = styled.div`
  margin-top: 10px;
  font-size: 13px;
  color: ${p => p.theme.colors['neutral']['700']};

  @media screen and (min-width: 768px) {
    font-size: 25px;
  }
`;

export const PageBackground = styled.div`
  border-radius: 32px;
  margin: 32px 0;
  padding: 24px;
  background-color: #eaecf1;

  @media screen and (min-width: 768px) {
    padding: 48px;
  }

  @media screen and (min-width: 1280px) {
    padding: 40px 80px;
  }
`;

export const FilterContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: 768px) {
    flex-direction: column;
  }

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1536px) {
    grid-gap: 48px;
  }
`;

export const FiltersTitle = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: ${p => p.theme.colors['neutral']['700']};
`;

export const FiltersButtons = styled.div`
  margin-top: 16px;
  display: grid;
  grid-gap: 8px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    margin-top: 16px;
  }

  @media screen and (min-width: 1024px) {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    grid-gap: 6px;
  }
`;

export const FilterButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors['white']} !important;
  color: ${({ theme }) => theme.colors['neutral']['700']} !important;
  display: flex;
  justify-content: space-between;
`;

export const FilterSpace = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonSelect = styled(Button)`
  background-color: ${({ theme }) => theme.colors['white']} !important;

  @media screen and (min-width: 768px) {
    max-width: 250px;
  }
`;

export const LoaderWrapper = styled.div`
  margin: 64px 0 48px;
`;

export const ListWrapper = styled.div`
  margin-top: 24px;

  @media screen and (min-width: 768px) {
    margin-top: 32px;
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 16px;

  @media screen and (min-width: 640px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    margin-top: 64px;
  }
`;

export const PageCount = styled.div`
  padding-left: 8px;
  font-size: 16px;
  line-height: 24px;
  color: ${p => p.theme.colors['neutral']['700']};

  @media screen and (min-width: 640px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const PageContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ApproveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 20px;

  @media screen and (min-width: 640px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const Pagination = styled(SemanticPagination)`
  &.ui.pagination > .item.active {
    font-weight: 700;
    color: ${p => p.theme.colors['white']};
    background-color: ${p => p.theme.colors['primary']['700']};
  }
`;
