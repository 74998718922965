import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'components/EnhancedForm';
import * as Yup from 'yup';
import { Field } from 'formik';
import ScheduleObservation, {
  ScheduleObservationProps
} from './ScheduleObservation/ScheduleObservation';
import { updateCoachingCycle } from 'actions/improvements';
import i18n from 'helpers/i18n';
import messages from './messages';
import { InformationCard } from 'pages/Improvements/EducatorProfile/Styled';
import Understand, { UnderstandNoteProps } from './Understand';
import { Button } from 'semantic-ui-react';
import SupportingStrategies, {
  SupportingStrategiesNoteProps
} from './SupportingStrategies';
import { ImprovementCoachingCycle } from 'types/api/improvement/ImprovementCoachingCycle';
import moment from 'moment/moment';
import { SubmitButton } from 'components/Trainings/View/CardCheckout/Styled';
import { NoteType } from 'pages/Improvements/types';
import CardHeader from 'pages/Improvements/CardHeader';
import HideOnPrint from 'pages/Improvements/HideOnPrint';

interface ActionPlanProps {
  coachingCycle: ImprovementCoachingCycle;
  onCoachingCycleChange: () => void;
}

export type ActionPlanFieldProps = {
  [p: string]: any;
  disabled: boolean;
};

const DATE_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'LT';

const convertWithFormat = (
  coachingCycle: ImprovementCoachingCycle,
  format: string
) => {
  if (coachingCycle?.scheduled_observation_time) {
    return moment(coachingCycle.scheduled_observation_time).format(format);
  }
  return '';
};

function ActionPlan({ coachingCycle, onCoachingCycleChange }: ActionPlanProps) {
  const hasFocus = !!coachingCycle?.improvement_focus?.id;
  const [isCollapsed, setIsCollapsed] = useState(
    !hasFocus || coachingCycle.is_action_plan_completed
  );
  const [isCompleted, setIsCompleted] = useState(
    coachingCycle.is_action_plan_completed
  );
  const [isEdit, setIsEdit] = useState(false);

  const initialValues = {
    improvement_plan_supports_note: coachingCycle.plan_supports_note?.response,
    improvement_additional_cues_note:
      coachingCycle.additional_cues_note?.response,
    improvement_action_plan_note: coachingCycle.action_plan_note?.response,
    date_of_observation: convertWithFormat(coachingCycle, DATE_FORMAT),
    time_of_observation: convertWithFormat(coachingCycle, TIME_FORMAT)
  };
  const VALIDATION_SCHEMA = Yup.object({
    date_of_observation: Yup.string().required(
      i18n.ft(messages.errors.fieldRequired)
    ),
    time_of_observation: Yup.string().required(
      i18n.ft(messages.errors.fieldRequired)
    )
  });

  useEffect(() => {
    const hasFocus = !!coachingCycle?.improvement_focus?.id;
    setIsCompleted(coachingCycle?.is_action_plan_completed);
    setIsCollapsed(!hasFocus || coachingCycle?.is_action_plan_completed);
  }, [coachingCycle]);

  const submitForm = async (
    formProps: ScheduleObservationProps &
      UnderstandNoteProps &
      SupportingStrategiesNoteProps
  ) => {
    const {
      date_of_observation,
      time_of_observation,
      improvement_action_plan_note,
      improvement_additional_cues_note,
      improvement_plan_supports_note
    } = formProps;

    const notes = [
      {
        id: coachingCycle.action_plan_note?.id,
        note_type: NoteType.ACTION_PLAN_NOTE,
        response: improvement_action_plan_note
      },
      {
        id: coachingCycle.additional_cues_note?.id,
        note_type: NoteType.ADDITIONAL_CUES_NOTE,
        response: improvement_additional_cues_note
      },
      {
        id: coachingCycle.plan_supports_note?.id,
        note_type: NoteType.PLAN_SUPPORTS_NOTE,
        response: improvement_plan_supports_note
      }
    ];

    await updateCoachingCycle(coachingCycle.id, {
      scheduled_observation_time: moment(
        `${date_of_observation} ${time_of_observation}`,
        DATE_FORMAT + TIME_FORMAT,
        'en'
      )
        .utc()
        .toString(),
      notes_attributes: notes
    });

    setIsCollapsed(true);
    setIsCompleted(true);
    setIsEdit(false);
    onCoachingCycleChange();
  };

  if (isCollapsed && !hasFocus) {
    return (
      <div className="print:hidden">
        <h3>{i18n.ft(messages.upNext)}</h3>
        <InformationCard className="p-8">
          <CardHeader
            title={i18n.ft(messages.actionPlan)}
            subtitle={i18n.ft(messages.puttingStrategiesToAction)}
            showPrintButton={false}
            showCTA={false}
          />
          <p>{i18n.ft(messages.reviewSelectedStrategies)}</p>
        </InformationCard>
      </div>
    );
  }

  if (isCollapsed && hasFocus) {
    return (
      <InformationCard className="p-8 w-full flex justify-between items-center">
        <h2 className="text-xl items-center">
          <i className="check circle icon text-[#7AA03F]" />
          <span>{i18n.ft(messages.actionPlanCreated)}</span>
        </h2>
        <div className="align-self-baseline print:hidden">
          <button
            className="text-[#017EA7]"
            onClick={() => setIsCollapsed(false)}
          >
            <strong>{i18n.ft(messages.viewActionPlan)}</strong>
          </button>
        </div>
      </InformationCard>
    );
  }

  function handleEdit() {
    setIsEdit(true);
  }

  return (
    <>
      <InformationCard className="p-8">
        <CardHeader
          title={i18n.ft(messages.actionPlan)}
          subtitle={i18n.ft(messages.puttingStrategiesToAction)}
          showCTA={coachingCycle && coachingCycle.is_action_plan_completed}
          onClickAction={() => setIsCollapsed(true)}
        />

        <div className="mb-4">
          <p>
            {i18n.ft(messages.reviewSelectedStrategies)}
            {i18n.ft(messages.prioritizeStrategies)}
          </p>
          <div className="rounded-lg bg-[#F4F8FA] text-[#364A5E] p-6">
            <span className="font-bold">
              {i18n.ft(messages.selectedFocus)}:{' '}
            </span>
            {coachingCycle?.improvement_focus?.name}
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={submitForm}
          enableReinitialize
        >
          {({ values }) => {
            const {
              improvement_plan_supports_note,
              improvement_additional_cues_note,
              improvement_action_plan_note,
              date_of_observation,
              time_of_observation
            } = values;

            const supportingStrategiesProps = {
              improvement_action_plan_note
            };

            const understandProps = {
              improvement_additional_cues_note,
              improvement_plan_supports_note
            };

            const scheduleObservationProps = {
              date_of_observation,
              time_of_observation
            };

            return (
              <Form aria-labelledby="action-plan-form-label">
                <Field
                  component={SupportingStrategies}
                  {...supportingStrategiesProps}
                  focus={coachingCycle?.improvement_focus}
                  disabled={isCompleted && !isEdit}
                />
                <hr className="my-4" />
                <Field
                  component={Understand}
                  {...understandProps}
                  focus={coachingCycle?.improvement_focus}
                  disabled={isCompleted && !isEdit}
                />
                <Field
                  component={ScheduleObservation}
                  {...scheduleObservationProps}
                  disabled={isCompleted && !isEdit}
                />

                <SubmitButton>
                  {coachingCycle.is_action_plan_completed && !isEdit ? (
                    <div className={HideOnPrint(isCompleted, '')}>
                      <Button
                        type="button"
                        className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                        color="blue"
                        onClick={handleEdit}
                        disabled={coachingCycle.has_completed}
                        content={i18n.ft(messages.edit)}
                      />
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                      color="blue"
                      content={i18n.ft(messages.submit)}
                    />
                  )}
                </SubmitButton>
              </Form>
            );
          }}
        </Formik>
      </InformationCard>
    </>
  );
}

export default ActionPlan;
