import { clsx } from 'clsx';

import {
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react';

export type SelectOption = {
  value: string | number | null | undefined;
  text: string;
};

export interface SelectProps {
  /** Current value of the select. */
  value: string | number | null | undefined;

  /** Callback fired when the value changes. */
  onChange(value: string | number | null | undefined): void;

  /** List of options for the select dropdown. */
  options: SelectOption[];

  /** Optional field label. */
  label?: string;

  /** Optional text for placeholder content. */
  placeholder?: string;

  /** When `true`, label indicates field is required. */
  required?: boolean;
}

export function Select({
  value,
  placeholder,
  options,
  onChange,
  label,
  required
}: SelectProps) {
  const option = options.find(option => option.value === value) || null;

  const classes = clsx(
    'font-sans text-black text-left flex items-center gap-2 justify-between p-2 border border-[#889EBB] rounded-lg w-full',
    // focus state
    'data-[focus]:outline-0 data-[focus]:border-[#0A9CCA] data-[focus]:ring-1 data-[focus]:ring-[#0A9CCA]',
    // open state
    'data-[open]:rounded-b-none data-[open]:outline-0 data-[open]:border-b-2 data-[open]:border-[#0A9CCA] data-[open]:ring-1 data-[open]:ring-[#0A9CCA]'
  );

  const handleChange = (option: SelectOption | null) => {
    onChange(option?.value || null);
  };

  const handleClear = (event: React.MouseEvent) => {
    if (!option) {
      return;
    }

    event.preventDefault();
    onChange(null);
  };

  const handleKeyDownClear = (event: React.KeyboardEvent) => {
    if (!option) {
      return;
    }

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onChange(null);
    }
  };

  const buttonText = option?.text || placeholder || '';

  return (
    <Field className="flex flex-col gap-1 font-sans">
      {label && (
        <Label className="font-semibold text-base text-black">
          {label + (required ? '*' : '')}
        </Label>
      )}

      <Listbox by="value" value={option} onChange={handleChange}>
        <ListboxButton className={classes}>
          <span
            className={clsx('truncate text-base', !option && 'text-[#3C3F42]')}
          >
            {buttonText}
          </span>

          <span
            onClick={handleClear}
            onKeyDown={handleKeyDownClear}
            tabIndex={option ? 0 : -1}
            className="p-1 flex items-center"
          >
            {option ? (
              <i className="fa-solid fa-xmark" />
            ) : (
              <i className="fa-solid fa-caret-down" />
            )}
          </span>
        </ListboxButton>

        <ListboxOptions
          anchor="bottom start"
          className="w-[var(--button-width)] bg-white border-x border-b border-[#0A9CCA] ring-1 ring-[#0A9CCA] rounded-b-lg max-h-40 outline-none empty:invisible overflow-auto"
        >
          {options.map((option, idx) => (
            <ListboxOption
              key={option.value}
              value={option}
              className={clsx(
                'font-sans text-black p-2 data-[focus]:bg-[#F7F8F9]',
                idx === options.length - 1 && 'rounded-b-lg'
              )}
            >
              {option.text}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </Field>
  );
}
