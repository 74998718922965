import { Wrapper } from './Styled';
import BaseInput, { Props } from 'react-phone-number-input';

function PhoneInput(props: Props<{}>) {
  return (
    <Wrapper>
      <BaseInput {...props} />
    </Wrapper>
  );
}

export default PhoneInput;
