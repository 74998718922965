const PATH = 'fe.components.onboarding.work_performed';

const messages = {
  work: {
    key: `${PATH}.work`,
    defaultValue: 'What Work Do You Do?'
  },
  experience: {
    key: `${PATH}.experience`,
    defaultValue:
      "We'd like to get to know you a little better so we can customize the experience for you."
  },
  selectApply: {
    key: `${PATH}.select_apply`,
    defaultValue: 'Select all that apply'
  },
  observer: {
    key: `${PATH}.observer`,
    defaultValue: 'I am a classroom observer'
  },
  teacher: {
    key: `${PATH}.teacher`,
    defaultValue: 'I am a teacher'
  },
  teacherDescription: {
    key: `${PATH}.teacher_description`,
    defaultValue: 'Care or education for children'
  },
  supportTeachers: {
    key: `${PATH}.support_teachers`,
    defaultValue: 'I support teachers'
  },
  supportTeachersDescription: {
    key: `${PATH}.support_teachers_description`,
    defaultValue: 'Coach, admin or trainer'
  },
  otherOption: {
    key: `${PATH}.other_option`,
    defaultValue: 'Other'
  },
  otherDescription: {
    key: `${PATH}.other_description`,
    defaultValue: 'Policy maker, researcher, etc.'
  },
  next: {
    key: `${PATH}.next`,
    defaultValue: 'Next'
  },
  observer_image: {
    key: `${PATH}.observer_image`,
    defaultValue: 'Classroom observer image'
  },
  teacher_image: {
    key: `${PATH}.teacher_image`,
    defaultValue: 'Teacher image'
  },
  support_image: {
    key: `${PATH}.support_image`,
    defaultValue: 'Support teacher image'
  },
  other_image: {
    key: `${PATH}.other_image`,
    defaultValue: 'Other image'
  }
};

export default messages;
