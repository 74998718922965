import { isNil, isEmpty } from 'ramda';

export function isRichTextEmpty(text: string) {
  if (isNil(text) || isEmpty(text)) {
    return true;
  }

  const textContent = new DOMParser().parseFromString(text, 'text/html').body
    .textContent;

  return textContent?.trim() === '';
}
