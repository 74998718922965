import React from 'react';
import { map } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import Metadata from './Metadata';
import { Training } from 'types/api/Training';
import { Title, VerticalSpace } from './Styled';
import { Cart } from 'types/BigCommerce/Cart';
import { Checkout } from 'types/BigCommerce/Checkout';

import {
  formatAgeLevels,
  formatTrainer,
  formatSessions,
  getPricePerParticipant,
  getSubtotal,
  getCoupons,
  getShipping,
  getTaxes,
  getGrandTotal,
  toCurrency
} from './utils';

import {
  Header,
  Container
} from 'components/Trainings/View/CardCheckout/Styled';

interface OrderSummaryProps {
  /** Tre training object. */
  training: Training;

  /** The list of participats' IDs */
  participants: number[];

  /** Big Commerce Cart */
  cart: Cart;

  /** Big Commerce Checkout */
  checkout?: Checkout;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  training,
  participants,
  cart,
  checkout
}) => {
  return (
    <Container>
      <Header>{i18n.ft(messages.orderSummary)}</Header>
      <Title>{training.training_template.title}</Title>

      <Metadata
        label={i18n.ft(messages.ageLevels)}
        value={formatAgeLevels(training)}
      />
      <Metadata
        label={i18n.ft(messages.trainer)}
        value={formatTrainer(training)}
      />
      <Metadata
        label={i18n.ft(messages.sessions)}
        value={formatSessions(training)}
      />

      <VerticalSpace />

      <Metadata
        label={i18n.ft(messages.quantity)}
        value={participants.length}
      />
      <Metadata
        label={i18n.ft(messages.price)}
        value={getPricePerParticipant(participants.length, cart)}
      />

      <hr />
      <Metadata
        split
        label={i18n.ft(messages.subtotal)}
        value={getSubtotal(cart, checkout)}
      />

      {map(
        coupon => (
          <Metadata
            split
            label={i18n.ft(messages.discount)}
            value={toCurrency(coupon.discounted_amount * -1)}
          />
        ),
        getCoupons(cart, checkout)
      )}

      <Metadata
        split
        label={i18n.ft(messages.shipping)}
        value={getShipping(checkout)}
      />
      <Metadata
        split
        label={i18n.ft(messages.tax)}
        value={getTaxes(checkout)}
      />

      <hr />
      <Metadata
        split
        size="large"
        label={`${i18n.ft(messages.total)} (${cart.currency.code})`}
        value={getGrandTotal(cart, checkout)}
      />
    </Container>
  );
};

export default OrderSummary;
