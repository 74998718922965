import { Fragment, useEffect, useState } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { PageWrapper } from '../Styled';
import { toastError } from 'components/Toast';
import BackToTop from 'components/Measure/BackToTop';
import MainContent from 'components/MainContent';
import PageHeader from 'components/Measure/PageHeader';
import PageBackground from 'components/PageBackground';
import { Icon, Button } from 'semantic-ui-react';
import RouteHelpers from 'helpers/routes';
import PageLoader from 'components/PageLoader';
import { Link, useNavigate } from 'react-router-dom';
import InfoBox from 'components/Measure/View/InfoBox';
import SubmitModal from 'components/Measure/Summary/SubmitModal';
import ClassroomExamples from 'components/Measure/ClassroomExamples';
import ObservationFeedback from 'components/Measure/ObservationFeedback';
import ObservationScores from 'components/Measure/ObservationScores';
import AttachmentViewer from 'components/Measure/View/ExamplesAndNotes/Attachments/AttachmentViewer';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { ServerError } from 'types/api/ServerError';
import { genericError, jobIdError } from './utils';
import { getSettings, GetSettingsRes } from 'actions/envScales/settings';
import { TARGET_TYPE } from 'types/api/envScales/ObservationFeedback';
import {
  approveAssessment,
  getAssessment
} from 'actions/envScales/assessments';
import RequestUpdatesModal from 'components/Measure/Summary/RequestUpdatesModal';
import ScoreSummary from 'components/Measure/View/ScoreSummary';
import FooterNav from 'components/Measure/View/FooterNav';
import { useValidations } from 'components/Measure/View/ValidationsContext';
import { useDCRoles } from 'pages/Measure/Completed/Completed';

import {
  Section,
  SectionActions,
  ActionWrapper
} from 'components/Measure/View/Styled';

interface CyclesReviewProps {
  /** CLASS Assessment ID */
  assessmentId: number;

  /** Account GUID. */
  accountGuid: string;

  /** If `true`, applies changes to work alongisde sidebar navigation. */
  isContainedInSidenav?: boolean;
}

function CyclesReview({
  assessmentId,
  accountGuid,
  isContainedInSidenav
}: CyclesReviewProps) {
  const navigate = useNavigate();
  const { isDCAdmin } = useDCRoles();

  const {
    run: assessmentRun,
    data: assessment,
    isPending: isAssessmentPending
  } = useAsync();

  const {
    run,
    data: settings,
    isPending: isSettingsPending
  } = useAsync<GetSettingsRes>();

  const [isSubmitModalOpen, setSubmitModalOpen] = useState(false);
  const [attachmentHasErrors, setAttachmentHasErrors] = useState(false);
  const isAwaitingApproval = assessment?.status === 'awaiting_approval';
  const { validate } = useValidations();
  const isFutureDate = new Date(assessment?.taken_at) >= new Date();

  useEffect(() => {
    assessmentRun(getAssessment(assessmentId, { expanded: true }));
  }, [assessmentRun, assessmentId]);

  useEffect(() => {
    run(getSettings(accountGuid));
  }, [run, accountGuid]);

  function handleSubmit() {
    setSubmitModalOpen(false);
    navigate(RouteHelpers.getPath('measure-completed', { id: assessment.id }), {
      replace: true
    });
  }

  async function approve() {
    await approveAssessment(assessment.id);
    navigate(RouteHelpers.getPath('measure-completed', { id: assessment.id }), {
      replace: true
    });
  }

  function handleSubmitErrors(error: ServerError) {
    const errorsComponents = [];

    for (const key in error.errors) {
      const errorObject = error.errors[key];
      if (key === 'job_id') {
        errorsComponents.push(jobIdError(assessment.id));
      } else if (key === 'attachments') {
        setAttachmentHasErrors(true);
        errorsComponents.push(genericError(errorObject[0]));
      } else {
        if (typeof errorObject === 'string') {
          errorsComponents.push(genericError(errorObject));
        } else if (Array.isArray(errorObject)) {
          errorsComponents.push(genericError(errorObject[0]));
        }
      }
    }

    return errorsComponents;
  }

  if (isSettingsPending || isAssessmentPending) {
    return <PageLoader />;
  }

  const { strength_and_growth_enabled: displayClassroomExamples } =
    settings.account_setting;

  function isFeedbackEnabled() {
    const actSettings = settings.account_setting;
    return actSettings.dimension_feedback || actSettings.domain_feedback;
  }

  function getTargetType(): TARGET_TYPE {
    const actSettings = settings.account_setting;

    if (actSettings.dimension_feedback) {
      return 'Dimension';
    } else {
      return 'Domain';
    }
  }

  async function handleSubmitForNewLayout() {
    const shouldOpenSubmitModal = await validate();
    if (shouldOpenSubmitModal && !isFutureDate) {
      setSubmitModalOpen(true);
    } else if (isFutureDate) {
      toastError({
        description: i18n.ft(messages.dateError)
      });
    } else {
      toastError({
        description: i18n.ft(messages.submitError)
      });
    }
  }

  const targetType = getTargetType();
  const displayFeedback = isFeedbackEnabled();
  const observationId = assessment.observation!.id;

  const MainWrapper = isContainedInSidenav ? Fragment : MainContent;
  const Wrapper = isContainedInSidenav ? Fragment : PageWrapper;
  const BackgroundWrapper = isContainedInSidenav ? Fragment : PageBackground;

  return (
    <MainWrapper maxWidth={1280}>
      <Wrapper>
        <SubmitModal
          open={isSubmitModalOpen}
          assessmentId={assessment.id}
          onClose={() => setSubmitModalOpen(false)}
          onErrors={handleSubmitErrors}
          onSuccess={handleSubmit}
        />

        {isContainedInSidenav ? null : (
          <>
            {isAwaitingApproval ? (
              <PageHeader
                title={`${assessment.assessment_template.name} ${i18n.ft(
                  messages.approve
                )}`}
              />
            ) : (
              <PageHeader
                title={`${assessment.assessment_template.name} ${i18n.ft(
                  messages.title
                )}`}
                subtitle={i18n.ft(messages.subtitle)}
              />
            )}
          </>
        )}

        <BackgroundWrapper>
          {isContainedInSidenav ? null : (
            <InfoBox
              data={assessment}
              showButton={false}
              extended
              isAdmin={isDCAdmin}
            />
          )}

          {assessment.includes_environments && (
            <div className={isContainedInSidenav ? '' : 'mt-8 sm:mt-10'}>
              <ScoreSummary
                assessmentId={assessment.id}
                infoData={assessment}
                isEditable={false}
              />
            </div>
          )}

          <ObservationScores fitted={assessment.includes_environments} />
          {displayClassroomExamples && <ClassroomExamples showEdit={false} />}

          {displayFeedback && (
            <ObservationFeedback
              assessmentId={assessment.id}
              observationId={observationId}
              targetType={targetType}
              showEdit={false}
            />
          )}

          <AttachmentViewer showEdit={false} hasErrors={attachmentHasErrors} />

          <BackToTop />

          {isContainedInSidenav ? (
            <FooterNav
              rightSideElement={
                <div className="md:w-52">
                  <Button
                    fluid
                    color="blue"
                    onClick={handleSubmitForNewLayout}
                    content={i18n.ft(messages.submit)}
                  />
                </div>
              }
            />
          ) : (
            <Section mt="8px" borderBottomRadius="12px">
              <SectionActions>
                <ActionWrapper>
                  <Button
                    fluid
                    basic
                    as={Link}
                    to={RouteHelpers.getPath('measure')}
                  >
                    <Icon name="arrow alternate circle left outline" />
                    {i18n.ft(messages.return)}
                  </Button>
                </ActionWrapper>

                {isAwaitingApproval ? (
                  <>
                    <ActionWrapper>
                      <RequestUpdatesModal assessmentId={assessment.id} />
                    </ActionWrapper>

                    <ActionWrapper>
                      <ConfirmationModal
                        title={i18n.ft(messages.approve)}
                        message={i18n.ft(messages.modal.messageApprove)}
                        confirmButton={i18n.ft(messages.modal.buttonApprove)}
                        icon="tasks"
                        confirmColor="blue"
                        triggerNode={
                          <Button
                            fluid
                            color="green"
                            content={i18n.ft(messages.approve)}
                          />
                        }
                        onConfirm={({ closeModal }) => {
                          approve();
                          closeModal();
                        }}
                      />
                    </ActionWrapper>
                  </>
                ) : (
                  <ActionWrapper>
                    <Button
                      fluid
                      color="blue"
                      onClick={() => setSubmitModalOpen(true)}
                      content={i18n.ft(messages.submit)}
                    />
                  </ActionWrapper>
                )}
              </SectionActions>
            </Section>
          )}
        </BackgroundWrapper>
      </Wrapper>
    </MainWrapper>
  );
}

export default CyclesReview;
