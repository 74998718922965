import i18n from 'helpers/i18n';
import colors from 'styles/colors';
import { SubtitleSection, Section } from 'components/Reports/Reliability/View';
import { DimensionScore, Score, Video } from './Styled';
import messages from './messages';
import DimensionResults, {
  DimensionReliability,
  DimensionPreparation
} from './DimensionResults';

export type TestType = 'reliability' | 'preparation';

export type DomainReliability = {
  name: string;
  score: number;
  dimensions: DimensionReliability[];
};

export type DomainPreparation = {
  name: string;
  dimensions: DimensionPreparation[];
};

interface Reliability {
  testType: 'reliability';
  scores: number[];
  domains: DomainReliability[];
}

interface Preparation {
  testType: 'preparation';
  domains: DomainPreparation[];
}

function isReliability(
  domain: DomainReliability | DomainPreparation
): domain is DomainReliability {
  return (domain as DomainReliability).score !== undefined;
}

function DimensionSection(props: Reliability | Preparation) {
  const { domains, testType } = props;
  return (
    <Section className="PrintBackgroundColor">
      <SubtitleSection className="justify-center md:justify-start PrintLeftTitle">
        {i18n.ft(messages.title)}
      </SubtitleSection>
      {testType !== 'preparation' ? (
        <div className="grid grid-cols-12 mx-4">
          <div className="col-span-5"></div>
          <div></div>
          <div className="col-span-6 text-center font-bold md:mb-4 PrintMargin">
            {i18n.ft(messages.videos)}
          </div>
        </div>
      ) : null}
      <div className="grid grid-cols-11 mx-1 text-sm md:text-base">
        <div
          className={`${
            testType === 'preparation' ? 'col-span-8' : 'col-span-5'
          } py-2 font-bold self-center`}
        >
          {i18n.ft(messages.columnTitle)}
        </div>
        {testType !== 'preparation' ? (
          <>
            <div
              className="col-span-1 text-center py-2 md:py-4 text-black border border-white text-sm lg:text-xl"
              style={{
                backgroundColor: colors.scaleSection,
                marginLeft: '-15px'
              }}
            >
              <span className="text-xs font-bold self-center mx-auto md:text-sm">
                <span className="hidden md:inline">
                  {i18n.ft(messages.overall)}{' '}
                </span>
                %
              </span>
            </div>
          </>
        ) : null}

        {testType === 'reliability' ? (
          <div className="contents text-center font-bold py-2">
            <div className="contents">
              {props.scores.map((score: number, idx: number) => {
                return (
                  <Video
                    key={idx}
                    color={
                      score >= 80
                        ? colors.reliability.passVideo
                        : colors.reliability.noPassVideo
                    }
                    className="PrintMarginLeft"
                  >
                    {idx + 1}
                  </Video>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="flex col-span-3 py-2 font-bold self-center justify-center">
            {i18n.ft(messages.reliable)}
          </div>
        )}
      </div>

      {domains.map(
        (domain: DomainReliability | DomainPreparation, idx: number) => {
          return (
            <div key={idx}>
              <div
                className="grid grid-cols-11 mx-1 text-sm md:text-xl"
                style={{ backgroundColor: '#D5E0F3' }}
              >
                <div
                  className={`${
                    testType === 'preparation' ? 'ml-8' : ''
                  } col-span-5 py-2 font-bold pl-2 text-base sm:col-span-5 sm:my-2`}
                >
                  {domain.name}
                </div>
                {isReliability(domain) && (
                  <DimensionScore>
                    <Score>
                      <div className="bg-white grid items-center text-sm h-8 col-span-2 text-center text-black rounded-full font-bold w-14 self-center border border-white sm:ml-0 md:w-16 md:text-base lg:w-20">
                        {domain.score}%
                      </div>
                    </Score>
                  </DimensionScore>
                )}
                <div className="contents text-center font-bold py-2"></div>
              </div>
              {isReliability(domain) ? (
                <DimensionResults dimensions={domain.dimensions} />
              ) : (
                <DimensionResults dimensions={domain.dimensions} />
              )}
            </div>
          );
        }
      )}
      {testType !== 'preparation' ? (
        <div className="mx-1 mt-2 text-center">{i18n.ft(messages.note)}</div>
      ) : null}
    </Section>
  );
}

export default DimensionSection;
