const PATH = 'fe.components.dashboard.community_widget';

const messages = {
  community: {
    key: `${PATH}.community`,
    defaultValue: 'Community'
  }
};

export default messages;
