import { ButtonWrapper, TextWrapper } from './Styled';
import { Icon, SemanticICONS } from 'semantic-ui-react';

type ButtonProps = React.ComponentProps<typeof ButtonWrapper>;

export type SecondaryButtonProps = ButtonProps & {
  /** Text should be display on button. */
  content: string;

  /** Optional icon name to use as button content. */
  icon?: SemanticICONS;

  /** A button can take the width of its container. */
  fluid?: boolean;
};

function SecondaryButton({ content, icon, ...props }: SecondaryButtonProps) {
  return (
    <ButtonWrapper {...props}>
      <TextWrapper>{content}</TextWrapper>
      {icon && <Icon name={icon} />}
    </ButtonWrapper>
  );
}

export default SecondaryButton;
