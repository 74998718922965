import { toast } from 'react-toastify';

interface ToastProps {
  message: string;
}

function Toast({ message }: ToastProps) {
  return (
    <div className="font-sans font-semibold text-base text-white flex items-center gap-2.5">
      <i className="fa-solid fa-circle-check" />
      <span>{message}</span>
    </div>
  );
}

export function toastSuccess(props: ToastProps) {
  toast(<Toast {...props} />, {
    closeButton: false,
    className: '!bg-[#06874C] !min-h-0 !p-4 !rounded-[10px] !border-0',
    bodyClassName: '!p-0'
  });
}
