import axios from 'axios';

export function getSessionAttendes(sessionId: number) {
  return axios.get(`/api/v2/training_sessions/${sessionId}/attendees`);
}

type Attendance = {
  participant_id: number;
  attended: boolean;
};

export function createSessionAttendance(
  sessionId: number,
  attendance: Attendance[]
) {
  return axios.post(`/api/v2/training_sessions/${sessionId}/attendees`, {
    attendance_list: attendance
  });
}
