const PATH = 'fe.components.organization.trainings.training_tile';

const messages = {
  trainer: {
    key: `${PATH}.trainer`,
    defaultValue: 'Trainer: '
  }
};

export default messages;
