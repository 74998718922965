const PATH = 'fe.components.improvements.prompt';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Discussion Prompts'
  }
};

export default messages;
