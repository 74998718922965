import i18n from 'helpers/i18n';
import messages from './messages';
import { toast } from 'react-toastify';
import { CheckboxInput } from './Styled';
import { useCallback, useEffect, useState } from 'react';
import { useRouteParams } from 'hooks/useRouteParams';
import { createUserAnswers, deleteUserAnswers } from 'actions/userAnswers';
import { useKnowledgeTest } from './KnowledgeTestContext';

type AnswerOptions = {
  user_answer_id?: number;
  checked: boolean;
  description: string;
  id: number;
};

interface SingleAnswerProps {
  answer_options: AnswerOptions[];
  category: string | undefined;
  questionId: number;
}

type URLParams = {
  id: string;
};

function MultipleAnswer({
  answer_options: answerOptions,
  category,
  questionId
}: SingleAnswerProps) {
  const { id } = useRouteParams<URLParams>();
  const { updater } = useKnowledgeTest();
  const [currentQuestionState, setCurrentQuestionState] =
    useState(answerOptions);

  useEffect(() => {
    setCurrentQuestionState(answerOptions);
  }, [answerOptions]);

  const isChecked = useCallback(
    (answerId: number) => {
      const answer = currentQuestionState.filter(el => {
        return el.id === answerId;
      });

      if (answer.length === 0) {
        return false;
      }

      return answer[0].checked;
    },
    [currentQuestionState]
  );

  function handleCheckboxInputChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.currentTarget.dataset.answerId === undefined) {
      return;
    }

    const answerId = parseInt(event.currentTarget.dataset.answerId);

    const userAnswer = {
      question_id: questionId,
      question_answer_id: answerId
    };
    /** Selected Answer set (id, checked, description and user_answer_id) */
    const selectedAnswerSet = currentQuestionState.filter(el => {
      return el.id === answerId;
    });
    /** Remove the old Answer set then replace it by the new one (add user_answer_id from the BE) */
    let newAnswerSetState = currentQuestionState.filter(el => {
      return el.id !== answerId;
    });

    if (event.currentTarget.checked) {
      createUserAnswers(id, userAnswer)
        .then(response => {
          /** 1. Create the new Answer set */
          const updateAnswerOptions = {
            checked: true,
            description: selectedAnswerSet[0].description,
            id: selectedAnswerSet[0].id,
            user_answer_id: response.data.id
          };
          /** 2. Add it to the state*/
          newAnswerSetState.push(updateAnswerOptions);
          /** 3. and Update the Current question answer set */
          setCurrentQuestionState(newAnswerSetState);
        })
        .catch(() => {
          toast.error(i18n.ft(messages.error));
        })
        .finally(() => {
          // refresh context
          updater();
        });
    } else if (selectedAnswerSet[0].user_answer_id) {
      deleteUserAnswers(id, selectedAnswerSet[0].user_answer_id)
        .then(_response => {
          /** 1. If the answer was checked previously we need to mark it as
           * unchecked and remove the user_answer_id from the state */
          const updateAnswerOptions = {
            checked: false,
            description: selectedAnswerSet[0].description,
            id: selectedAnswerSet[0].id
          };
          /** 2. Add it to the state */
          newAnswerSetState.push(updateAnswerOptions);
          /** 3. and Update the Current question answer set */
          setCurrentQuestionState(newAnswerSetState);
        })
        .catch(e => {
          toast.error(i18n.ft(messages.error));
        })
        .finally(() => {
          // refresh context
          updater();
        });
    }
  }

  return (
    <div className="pt-4 text-base">
      {answerOptions?.map((answer: any) => {
        return (
          <div key={answer.id} className="grid grid-cols-12 items-center mb-8">
            <div className="pl-2 col-span-1">
              <CheckboxInput
                type="checkbox"
                id={`question_${answer.id}`}
                name={`question_${answer.id}`}
                className="align-middle"
                onChange={handleCheckboxInputChange}
                data-answer-id={answer.id}
                checked={isChecked(answer.id)}
              />
            </div>
            <div className="ml-4 col-span-11 pl-2 md:pl-0 col-span-11">
              <label
                htmlFor={`question_${answer.id}`}
                className="cursor-pointer"
              >
                {
                  <span
                    dangerouslySetInnerHTML={{
                      __html: answer.description
                    }}
                  ></span>
                }
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default MultipleAnswer;
