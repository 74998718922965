import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Flex } from 'components/Flex';
import { Link } from 'react-router-dom';
import Typography from 'components/Typography';

export function genericError(error: string) {
  return (
    <Flex mt="22px" justify="center">
      <Typography color="red">
        <div>{error}</div>
      </Typography>
    </Flex>
  );
}

export function jobIdError(assessmentId: number) {
  return (
    <Flex mt="22px" justify="center">
      <Typography color="red">
        <div>
          {i18n.ft(messages.modal.errors.jobIdMissing)}.&nbsp;
          <Link
            to={RouteHelpers.getPath('measure-view-edit', { id: assessmentId })}
          >
            {i18n.ft(messages.modal.errors.editJobId)}
          </Link>
        </div>
      </Typography>
    </Flex>
  );
}
