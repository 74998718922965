import ErrorLabel from 'components/EnhancedForm/ErrorLabel';
import { BaseFieldProps } from 'components/EnhancedForm/utils';

import { CheckboxProps as BaseProps } from 'semantic-ui-react';
import { useField } from 'formik';
import { shouldDisplayError } from 'components/EnhancedForm/utils';
import React from 'react';
import { RadioInput } from 'pages/Improvements/Intake/Styled';

type Selection = { id: string; name: string };

interface RatingSelectionProps extends BaseFieldProps<BaseProps> {
  availableSelections: Selection[];
  label?: string;
  onSelectionClick?: (value: Selection) => void;
}

function RatingSelection({
  name,
  availableSelections,
  validate,
  label,
  disabled = false
}: RatingSelectionProps) {
  const [, meta] = useField({ name, validate });
  const isError = shouldDisplayError(meta);
  const message = meta.error;

  return (
    <fieldset
      data-testid={name}
      aria-labelledby={name}
      className={`flex flex-row gap-4 px-0`}
    >
      {label && (
        <legend data-testid="level-selection-legend" className="font-bold my-4">
          {label}
          <span className="ml-4">
            <ErrorLabel active={isError} content={message} />
          </span>
        </legend>
      )}
      {availableSelections?.map((selection, index) => (
        <React.Fragment key={`${name}-${index}`}>
          <RadioInput
            className="accent-black mb-4 gap-4 self-center cursor-pointer h-4 w-4"
            id={`${name}-${index}`}
            type="radio"
            name={name}
            value={selection.id}
            checked={selection.id === meta.value}
            disabled={disabled}
          />
          <label
            className="flex-1 self-center mb-4"
            htmlFor={`${name}-${index}`}
          >
            {selection.name}
          </label>
        </React.Fragment>
      ))}
    </fieldset>
  );
}

export default RatingSelection;
