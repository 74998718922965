import { clsx } from 'clsx';

interface ButtonProps {
  /** Button content. */
  children: React.ReactNode;

  /** Additional classes. */
  className?: string;

  /** Callback fired when the button is clicked. */
  onClick?: () => void;
}

export function TextButton({ children, className, ...props }: ButtonProps) {
  const classes = clsx(
    'font-sans font-semibold text-sm py-1.5 text-[#6E2262]',
    // hover state
    'hover:text-[#41193D] hover:underline',
    // additional classes
    className
  );

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
}
