import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Box } from 'components/Box';
import useAsync from 'hooks/useAsync';
import { formatHours } from './utils';
import { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import PageHeader from 'components/PageHeader';
import PageLoader from 'components/PageLoader';
import MainContent from 'components/MainContent';
import PageWrapper from 'components/PageWrapper';
import PageBackground from 'components/PageBackground';
import useCurrentUser from 'hooks/useCurrentUser';
import VisuallyHidden from 'components/VisuallyHidden';
import myTraininHistoryIcon from 'images/myTrainingHistory.svg';
import TrainingCard from 'components/TrainingHistory/TrainingCard';

import {
  TrainingHistory,
  TrainingHistorySummary
} from 'types/api/TrainingHistory';

import {
  getTrainingHistoryByUserId,
  getTrainingHistorySummary
} from 'actions/trainingHistory';

import {
  TrainingList,
  HeaderWrapper,
  NoTrainingsMessage,
  MMCIMessage,
  Summary,
  SummaryLabel,
  SummaryValue,
  SummaryWrapper
} from './Styled';

const colors = {
  hours: '#ebe0ff',
  ceus: '#d7f3ff'
};

function TrainingHistoryPage() {
  const [sortDir, setSortDir] = useState('desc');
  const { id: userId, services, preferred_language = 'en' } = useCurrentUser();
  const { run, data, isPending } = useAsync<TrainingHistory[]>();
  const { run: summaryRun, ...summaryReq } = useAsync<TrainingHistorySummary>();

  useEffect(() => {
    run(getTrainingHistoryByUserId(userId, { sort_dir: sortDir }));
  }, [run, userId, sortDir]);

  useEffect(() => {
    summaryRun(getTrainingHistorySummary(userId));
  }, [summaryRun, userId]);

  if (isPending || summaryReq.isPending) {
    return <PageLoader />;
  }

  return (
    <MainContent maxWidth={1280}>
      <PageWrapper>
        <HeaderWrapper>
          <PageHeader
            image={myTraininHistoryIcon}
            title={i18n.ft(messages.title)}
            subtitle={i18n.ft(messages.subtitle)}
          />

          <SummaryWrapper>
            <Summary color={colors.hours}>
              <SummaryValue>
                {formatHours(summaryReq.data.total_hours)}
              </SummaryValue>
              <SummaryLabel>{i18n.ft(messages.hours)}</SummaryLabel>
            </Summary>
            <Summary color={colors.ceus}>
              <SummaryValue>{summaryReq.data.total_ceus}</SummaryValue>
              <SummaryLabel>{i18n.ft(messages.ceus)}</SummaryLabel>
            </Summary>
          </SummaryWrapper>
        </HeaderWrapper>

        <PageBackground>
          <Box mb={{ _: '24px', xl: '32px' }}>
            <span>{i18n.ft(messages.sortBy) + ':'}</span>
            &nbsp;
            <Dropdown
              inline
              value={sortDir}
              onChange={(_, data) => setSortDir(data.value as string)}
              options={[
                {
                  key: 'recent',
                  value: 'desc',
                  text: i18n.ft(messages.recentFirst)
                },
                {
                  key: 'oldest',
                  value: 'asc',
                  text: i18n.ft(messages.oldestFirst)
                }
              ]}
            />
          </Box>

          <TrainingList>
            {R.isEmpty(data) ? (
              <NoTrainingsMessage>
                {i18n.ft(messages.noTrainings)}
              </NoTrainingsMessage>
            ) : (
              data.map(training => (
                <TrainingCard key={training.id} training={training} />
              ))
            )}
          </TrainingList>

          {R.any(s => s.key === 'MyTrainings', services) ? (
            <MMCIMessage>
              {i18n.ft(messages.lookingMMCI)}
              &nbsp;
              <a
                href={RouteHelpers.getPath('myclass-my_trainings', {
                  lang: preferred_language
                })}
              >
                <VisuallyHidden>{i18n.ft(messages.lookingMMCI)}</VisuallyHidden>
                {i18n.ft(messages.checkHere)}
              </a>
            </MMCIMessage>
          ) : null}
        </PageBackground>
      </PageWrapper>
    </MainContent>
  );
}

export default TrainingHistoryPage;
