import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  maxWidth?: number;
  floating: boolean;
  overlapChildren: boolean;
}>`
  z-index: 1;
  position: ${p => (p.floating ? 'absolute' : 'relative')};
  display: flex;
  flex-direction: column;
  margin-top: ${p => (p.overlapChildren ? '-35px' : '10px')};
  padding: 16px 11px;
  background-color: ${p => p.theme.colors.neutral['100']};
  border-radius: 11px;
  font-size: 14px;

  max-width: ${p => (p.maxWidth ? `${p.maxWidth}px` : 'auto')};
  box-shadow: 5px 5px 4px -2px rgba(0, 0, 0, 0.1);

  ${p =>
    p.floating &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0%;
        left: 15%;
        margin-top: -20px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent
          ${p => p.theme.colors.neutral['100']} transparent;
      }
    `}
`;

export const IconWrapper = styled.div`
  margin-right: 5px;
`;

export const Dismiss = styled.button`
  margin-top: -25px;
  position: absolute;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f6af3d;
  color: white;
  cursor: pointer;
  border: 0;

  &:hover {
    opacity: 0.7;
  }

  &:before {
    content: '×';
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
