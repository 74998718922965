import styled from 'styled-components';

export const ErrorMessage = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${p => p.theme.colors['red']};
  margin: -12px 0 16px;
`;

export const InputWrapper = styled.div`
  margin-top: 32px;

  && input {
    max-width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TextButton = styled.button`
  color: ${p => p.theme.colors['primary']['700']};
  background-color: transparent;
  font-family: inherit;
  font-weight: 700;
  cursor: pointer;
  border: none;
  padding: 0;
  font-size: 16px;
  margin-left: 16px;

  &:hover {
    text-decoration: underline;
  }
`;
