import { map, sort } from 'ramda';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import styled from 'styled-components';
import TemplateCard from './TemplateCard';
import { Loader } from 'semantic-ui-react';
import { getTemplatesCatalog } from 'actions/templates';
import { TrainingTemplate } from 'types/api/TrainingTemplate';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

function sortByTitle(templateA: TrainingTemplate, templateB: TrainingTemplate) {
  if (templateA.title < templateB.title) {
    return -1;
  } else if (templateA.title > templateB.title) {
    return 1;
  } else {
    return 0;
  }
}

function TemplateList() {
  const { run, data, isPending } = useAsync();

  useEffect(() => {
    run(getTemplatesCatalog({ per_page: 10000, status: 'published' }));
  }, [run]);

  if (isPending) {
    return <Loader active size="large" inline="centered" />;
  }

  const sortedTemplates = sort(sortByTitle, data.training_templates);

  return (
    <Wrapper>
      {map(
        template => (
          <TemplateCard key={template.id} template={template} />
        ),
        sortedTemplates
      )}
    </Wrapper>
  );
}

export default TemplateList;
