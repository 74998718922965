const PATH = 'fe.components.users.forgot_password.form';

const messages = {
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  forgotYourPassword: {
    key: `${PATH}.forgot_your_password`,
    defaultValue: 'Forgot your password?'
  },
  enterEmail: {
    key: `${PATH}.enter_email`,
    defaultValue: 'Enter your email'
  },
  backToLogin: {
    key: `${PATH}.back_to_login`,
    defaultValue: 'Go back to login'
  },
  resetInstructions: {
    key: `${PATH}.reset_instructions`,
    defaultValue:
      'Enter your email to receive instructions to reset your password.'
  },
  setInstructions: {
    key: `${PATH}.set_instructions`,
    defaultValue:
      'Enter your email to receive instructions to set your password.'
  },
  confirmation: {
    key: `${PATH}.confirmation`,
    defaultValue:
      'An email has been sent to <span style="font-weight: bold">{{email}}</span>.<br/>Haven\'t received an email?  Try checking your spam folder.'
  },
  send: {
    key: `${PATH}.send`,
    defaultValue: 'Send'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  errors: {
    email: {
      required: {
        key: `${PATH}.errors.email.required`,
        defaultValue: 'Email is required'
      },
      invalid: {
        key: `${PATH}.errors.email.invalid`,
        defaultValue: 'Invalid email format'
      }
    },
    unknown: {
      key: `${PATH}.errors.unknown`,
      defaultValue: 'An unknown error occurred, please try again later'
    }
  }
};

export default messages;
