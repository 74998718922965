import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { TreeSelect } from './Styled';
import { DropdownTreeSelectProps } from 'react-dropdown-tree-select';

export interface HierarchyDropdownProps extends DropdownTreeSelectProps {
  placeholder?: string;
}

function HierarchyDropdown({
  placeholder = i18n.ft(messages.search),
  ...props
}: HierarchyDropdownProps) {
  return <TreeSelect texts={{ placeholder }} {...props} />;
}

/**
 * Need to use `React.memo` because of a bug with react-dropdown-tree-select
 * that would cause the entire list of selected options to be lost if any of the props change.
 *
 * Look at `pages/Reports/Observation/Observation.tsx` for an example on how to pass props.
 */
export default React.memo(HierarchyDropdown);
