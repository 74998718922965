const PATH = 'fe.pages.improvements';

const messages = {
  name: {
    key: `${PATH}.name`,
    defaultValue: 'Educator'
  },
  phase: {
    key: `${PATH}.phase`,
    defaultValue: 'Phase'
  },
  focus: {
    key: `${PATH}.focus`,
    defaultValue: 'Focus'
  },
  lastCycleCompleted: {
    key: `${PATH}.last_cycle_completed`,
    defaultValue: 'Last Cycle Completed'
  },
  lastObservation: {
    key: `${PATH}.last_observation`,
    defaultValue: 'Last Observation'
  },
  nextObservation: {
    key: `${PATH}.next_observation`,
    defaultValue: 'Next Observation'
  },
  cycle: {
    key: `${PATH}.cycle`,
    defaultValue: 'Cycles Completed'
  },
  status: {
    key: `${PATH}.status`,
    defaultValue: 'Last updated on'
  },
  coachingRoster: {
    key: `${PATH}.coaching_roster`,
    defaultValue: 'Coach: {{coachName}}'
  },
  coachingOverview: {
    key: `${PATH}.coaching_overview`,
    defaultValue: 'Coaching Dashboard'
  }
};

export default messages;
