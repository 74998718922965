import { useEffect, useState, useCallback } from 'react';

import {
  Wrapper,
  CropFrame,
  CropCircle,
  CropImage,
  CropOverlay,
  SliderInput
} from './Styled';

const DEFAULTS = {
  minImageWidth: 180,
  minImageHeight: 180,
  imageContainerWidth: 230,
  imageContainerHeight: 230,
  overlayBuffer: (230 - 180) / 2
};

function getNewCoordinates(
  x: number,
  y: number,
  width: number,
  height: number,
  scale: number,
  state: CropToolState
) {
  const { imageContainerWidth, overlayBuffer } = DEFAULTS;

  if (x > overlayBuffer) {
    x = overlayBuffer;
  }

  if (x + width < imageContainerWidth - overlayBuffer) {
    x = imageContainerWidth - overlayBuffer - width;
  }

  if (y > overlayBuffer) {
    y = overlayBuffer;
  }

  if (y + height < imageContainerWidth - overlayBuffer) {
    y = imageContainerWidth - overlayBuffer - height;
  }

  if (x !== state.x || y !== state.y || scale !== state.scale) {
    return { x, y, width, height, scale };
  } else {
    return null;
  }
}

type OnChangeValue = {
  top_left_x: number;
  top_left_y: number;
  zoom: number;
};

type CropToolState = {
  dataURL: string;
  scale: number;
  defaultScale: number;
  minimumScale: number;
  originalHeight: number;
  originalWidth: number;
  height: number;
  width: number;
  mouseDown: boolean;
  mouseDownX: number;
  mouseDownY: number;
  originX: number;
  originY: number;
  x: number;
  y: number;
  loaded: boolean;
};

interface ImageCropToolProps {
  /** HTML File object. */
  file: File;

  /** Callback fired when the image crop values change. */
  onChange: (value: OnChangeValue) => void;
}

function ImageCropTool({ onChange, file }: ImageCropToolProps) {
  const [state, setState] = useState<CropToolState>({
    dataURL: URL.createObjectURL(file),
    scale: 1,
    defaultScale: 1,
    minimumScale: 0,
    originalHeight: 0,
    originalWidth: 0,
    height: 0,
    width: 0,
    mouseDown: false,
    mouseDownX: 0,
    mouseDownY: 0,
    originX: 0,
    originY: 0,
    x: 0,
    y: 0,
    loaded: false
  });

  const setStateWithCallback = useCallback(
    (newState: CropToolState) => {
      setState(newState);
      onChange({
        top_left_x: -1 * newState.x,
        top_left_y: -1 * newState.y,
        zoom: newState.scale
      });
    },
    [onChange]
  );

  useEffect(() => {
    function mouseMove(event: MouseEvent) {
      if (!state.mouseDown) {
        return;
      }

      const x = state.originX + (event.clientX - state.mouseDownX);
      const y = state.originY + (event.clientY - state.mouseDownY);

      const coordinates = getNewCoordinates(
        x,
        y,
        state.width,
        state.height,
        state.scale,
        state
      );

      if (coordinates) {
        setState({ ...state, ...coordinates });
      }
    }

    function mouseUp() {
      setStateWithCallback({
        ...state,
        mouseDown: false,
        originX: state.x,
        originY: state.y
      });
    }

    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('mouseup', mouseUp);

    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('mouseup', mouseUp);
    };
  }, [state, setStateWithCallback]);

  function imageLoaded(event: React.SyntheticEvent<HTMLImageElement, Event>) {
    const { minImageWidth, imageContainerWidth } = DEFAULTS;
    const { width, height } = event.currentTarget;

    const minimumScale = Number(
      (minImageWidth / (width > height ? height : width)).toFixed(4)
    );

    const currentScale = Number(
      (imageContainerWidth / (width > height ? height : width)).toFixed(4)
    );

    setStateWithCallback({
      ...state,
      originalHeight: height,
      originalWidth: width,
      height: Math.ceil(height * currentScale),
      width: Math.ceil(width * currentScale),
      scale: currentScale,
      defaultScale: currentScale,
      minimumScale: minimumScale,
      loaded: true
    });
  }

  function handleMouseDown(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.preventDefault();

    if (state.defaultScale >= 1) {
      return;
    }

    setState({
      ...state,
      mouseDown: true,
      mouseDownX: event.clientX,
      mouseDownY: event.clientY
    });
  }

  function handleMouseUp() {
    setStateWithCallback({
      ...state,
      mouseDown: false,
      originX: state.x,
      originY: state.y
    });
  }

  function updateScale(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLInputElement, MouseEvent>
  ) {
    const scale = Number(event.currentTarget.value);

    const {
      x: prevX,
      y: prevY,
      originalWidth,
      originalHeight,
      width: prevWidth,
      height: prevHeight
    } = state;

    const width = Math.ceil(originalWidth * scale);
    const height = Math.ceil(originalHeight * scale);
    const x = prevX + (prevWidth - width) / 2;
    const y = prevY + (prevHeight - height) / 2;
    const coordinates = getNewCoordinates(x, y, width, height, scale, state);

    if (coordinates) {
      setStateWithCallback({ ...state, ...coordinates });
    }
  }

  const { imageContainerWidth, imageContainerHeight } = DEFAULTS;
  const { x, y, width, height, mouseDown, dataURL, loaded } = state;

  const imgStyle = !loaded
    ? {}
    : {
        transform: `translate3d(${x}px, ${y}px, 0)`,
        WebkitTransform: `translate3d(${x}px, ${y}px, 0)`,
        width: width,
        height: height
      };

  const imgCropFrameStyle = {
    width: imageContainerWidth,
    height: imageContainerHeight
  };

  return (
    <Wrapper>
      <CropFrame style={imgCropFrameStyle}>
        <CropCircle>
          <CropImage
            alt=""
            src={dataURL}
            style={imgStyle}
            onLoad={imageLoaded}
          />
        </CropCircle>

        <CropOverlay
          active={mouseDown}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        />
      </CropFrame>

      <SliderInput
        type="range"
        step="0.0001"
        max="1"
        min={state.minimumScale}
        defaultValue={state.defaultScale}
        onChange={updateScale}
        onClick={updateScale}
        disabled={state.defaultScale >= 1}
      />
    </Wrapper>
  );
}

export default ImageCropTool;
