import styled from 'styled-components';

export const Wrapper = styled.div`
  input {
    padding: 11px 14px;
    font-family: inherit;
    border-radius: 4px;
    border: 1px solid rgba(34, 36, 38, 0.15);

    &:hover {
      cursor: pointer;
      border-color: rgba(34, 36, 38, 0.35);
    }

    &:focus {
      outline: none;
      border-color: #96c8da;
    }
  }

  .react-datepicker__day--outside-month {
    color: #86868a;
  }

  .react-datepicker__day--disabled {
    color: #cccccc;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 12px 0;
`;

export const DateWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const DateButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateText = styled.span`
  font-weight: 700;
  font-size: 14px;
`;

export const SVGIconButton = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  width: 8px;
  height: 8px;
  display: flex;

  svg {
    fill: inherit;
  }

  &:hover {
    cursor: pointer;

    svg {
      fill: ${p => p.theme.colors.blue};
    }
  }
`;

export const CaretDownIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.78037 2.78784L4.59625 5.99452C4.41838 6.15234 4.21295 6.23001 4.00752 6.23001C3.80209 6.23001 3.59717 6.15172 3.44059 5.99514L0.256467 2.78847C0.00480378 2.55987 -0.0638491 2.21415 0.0602592 1.91603C0.184367 1.61791 0.476926 1.42 0.80085 1.42H7.19165C7.51582 1.42 7.80843 1.61493 7.93269 1.91478C8.05695 2.21463 8.01085 2.55987 7.78037 2.78784Z" />
  </svg>
);

export const CaretUpIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.235526 4.89259L3.42183 1.7321C3.60007 1.57654 3.80564 1.5 4.01121 1.5C4.21678 1.5 4.42184 1.57716 4.57852 1.73148L7.76483 4.89198C7.99436 5.11805 8.06315 5.45765 7.93888 5.75321C7.81462 6.04877 7.54347 6.24074 7.19751 6.24074H0.802592C0.478196 6.24074 0.185387 6.04862 0.0610436 5.75309C-0.0633 5.45756 0.00597392 5.11728 0.235526 4.89259Z" />
  </svg>
);
