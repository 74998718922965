const PATH = 'fe.components.certifications.certification_list';

const messages = {
  noCertifications: {
    key: `${PATH}.no_certifications`,
    defaultValue: 'There are no certifications associated to your account.'
  },
  groupTitle: {
    key: `${PATH}.group_title`,
    defaultValue: '{{groupName}} Certifications'
  },
  renewAlert: {
    key: `${PATH}.renew_alert`,
    defaultValue: 'You have {{count}} certifications available to renew.'
  },
  observer: {
    key: `${PATH}.observer`,
    defaultValue: 'Observer'
  },
  coach: {
    key: `${PATH}.coach`,
    defaultValue: 'Coach'
  },
  trainer: {
    key: `${PATH}.trainer`,
    defaultValue: 'Trainer'
  },
  teacher: {
    key: `${PATH}.teacher`,
    defaultValue: 'Teacher'
  },
  observer_trainer: {
    key: `${PATH}.observer_trainer`,
    defaultValue: 'Observer Trainer'
  },
  moreResources: {
    key: `${PATH}.more_resources`,
    defaultValue: 'More Resources'
  },
  coursesTitle: {
    key: `${PATH}.courses_title`,
    defaultValue: 'Explore our Resource Villages'
  },
  coursesAlt: {
    key: `${PATH}.courses_alt`,
    defaultValue: 'My courses icon'
  },
  obsDirectoryTitle: {
    key: `${PATH}.obs_directory_title`,
    defaultValue: 'Join the Observer Directory'
  },
  obsDirectoryAlt: {
    key: `${PATH}.obs_directory_alt`,
    defaultValue: 'Observer directory icon'
  },
  calibrationsTitle: {
    key: `${PATH}.calibrations_title`,
    defaultValue: 'Practice Calibrations'
  },
  calibrationsAlt: {
    key: `${PATH}.calibrations_alt`,
    defaultValue: 'Calibrations icon'
  }
};

export default messages;
