const PATH = 'fe.pages.user_choice_product_key';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Make a Selection'
  },
  message: {
    key: `${PATH}.message`,
    defaultValue:
      'This product has multiple options. Please make your selection(s) carefully. Once a selection is made and progress started, any changes to selections will result in loss of progress.'
  },
  allEnglish: {
    key: `${PATH}.all_english`,
    defaultValue: 'Select All English'
  },
  allSpanish: {
    key: `${PATH}.all_spanish`,
    defaultValue: 'Select All Spanish'
  },
  allFrench: {
    key: `${PATH}.all_french`,
    defaultValue: 'Select All French'
  },
  singleCourses: {
    key: `${PATH}.single_courses`,
    defaultValue:
      "You'll also be enrolled in the following, which do not require a choice:"
  },
  confirm: {
    key: `${PATH}.confirm`,
    defaultValue: 'Confirm Choices'
  }
};

export default messages;
