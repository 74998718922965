import i18n from 'helpers/i18n';
import messages from './messages';
import { Action } from 'types/api/Action';
import { IconWrapper, Icon } from './Styled';
import testIcon from 'images/reminders/test.svg';
import inviteIcon from 'images/reminders/invite.svg';
import trainingIcon from 'images/reminders/training.svg';
import certificationIcon from 'images/reminders/certification.svg';
import reliabilityTest from 'images/reminders/reliability_test.svg';

interface ReminderIconProps {
  action: Action;
}

function ReminderIcon({ action }: ReminderIconProps) {
  switch (action.type) {
    case 'product_invitation':
      return (
        <IconWrapper>
          <Icon src={inviteIcon} alt={i18n.ft(messages.inviteIcon)} />
        </IconWrapper>
      );
    case 'training_invite':
    case 'upcoming_training':
    case 'ongoing_training':
      return (
        <IconWrapper>
          <Icon src={trainingIcon} alt={i18n.ft(messages.trainingIcon)} />
        </IconWrapper>
      );
    case 'active_test':
      return (
        <IconWrapper>
          <Icon src={testIcon} alt={i18n.ft(messages.testIcon)} />
        </IconWrapper>
      );
    case 'reliability':
      return (
        <IconWrapper>
          <Icon
            src={reliabilityTest}
            alt={i18n.ft(messages.reliabilityTestIcon)}
          />
        </IconWrapper>
      );
    case 'knowledge':
      return (
        <IconWrapper>
          <Icon
            src={reliabilityTest}
            alt={i18n.ft(messages.reliabilityTestIcon)}
          />
        </IconWrapper>
      );
    case 'expiring_certification':
      return (
        <IconWrapper>
          <Icon
            src={certificationIcon}
            alt={i18n.ft(messages.certificationIcon)}
          />
        </IconWrapper>
      );
    default:
      return (
        <IconWrapper>
          <Icon src={inviteIcon} alt={i18n.ft(messages.icon)} />
        </IconWrapper>
      );
  }
}

export default ReminderIcon;
