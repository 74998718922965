import React from 'react';
import Typography, { TypographyProps } from 'components/Typography';

export interface ErrorLabelProps extends TypographyProps {
  /** If `true`, the error message will be displayed. */
  active: boolean;
}

function ErrorLabel({ active, ...props }: ErrorLabelProps) {
  if (!active) {
    return null;
  }

  return <Typography {...props} />;
}

ErrorLabel.defaultProps = {
  bold: true,
  color: 'red',
  size: 'small',
  textAlign: 'right'
};

export default ErrorLabel;
