const PATH = 'fe.components.trainings.view.participants.add_participant_form';

const messages = {
  addParticipant: {
    key: `${PATH}.add_participant`,
    defaultValue: 'Add a participant'
  },
  addToList: {
    key: `${PATH}.add_to_list`,
    defaultValue: 'Add to the list'
  },
  example: {
    key: `${PATH}.example`,
    defaultValue: 'example@mail.com, example02@mail.com'
  },
  failedToAdd: {
    key: `${PATH}.failed_to_add`,
    defaultValue: 'Failed to add participant(s)'
  }
};

export default messages;
