import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { FormRowWrapper } from './Styled';
import { shouldDisplayError, shouldHideInput, BaseFieldProps } from './utils';
import { useField } from 'formik';
import ErrorLabel from './ErrorLabel';

const StyledLabel = styled.label`
  opacity: 1 !important;
  margin-bottom: 0.25rem;
  font-weight: bold !important;
  color: rgba(0, 0, 0, 0.87) !important;

  &.error {
    color: ${props => props.theme.colors.red} !important;
  }
`;

const StyledDropdown = styled(Dropdown)`
  background: none !important;
  border-radius: 0.25rem;
  min-width: 19rem !important;

  &.ui.labeled.icon.button {
    padding-left: 40px !important;
  }

  div.divider.text {
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.87) !important;

    &.default {
      color: #bfbfbf !important;
    }
  }

  input {
    opacity: 0.5 !important;
    padding-left: 40px !important;
  }

  i {
    background: none !important;
    opacity: 0.5 !important;
  }

  &.error {
    border-color: #e0b4b4 !important;
    background-color: #fff6f6 !important;

    div.divider.default {
      color: rgb(224, 180, 180) !important;
    }

    i {
      color: #9f3a38;
      opacity: 0.5 !important;
    }
  }

  &:hover:not(.active) {
    border-color: #dededf !important;
  }
`;

interface ComponentProps {
  interval: number;
  placeholder?: string;
}
interface TimePickerProps extends BaseFieldProps<ComponentProps> {}

interface TimeOption {
  key: string;
  text: string;
  value: string;
}

const TimePicker = ({
  className,
  interval,
  label,
  placeholder,
  name,
  validate,
  ...props
}: TimePickerProps) => {
  const [field, meta, helpers] = useField({ name, validate });
  const [timeOptions, setTimeOptions] = useState<TimeOption[]>([]);
  const isError = shouldDisplayError(meta);
  const isHidden = shouldHideInput(props);
  const defaultValue = field.value || '';

  const validateTime = (value: string) => {
    if (value.match(/^[0-9]{1,2}:[0-9]{1,2} (AM|PM)$/)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const options = [];

    for (let hour = 0; hour <= 23; hour++) {
      for (let minute = 0; minute <= 59; minute += interval) {
        const period = hour >= 12 ? 'PM' : 'AM';

        let hourOption = hour > 12 ? hour - 12 : hour;
        if (hour === 0) hourOption = 12;

        const option = `${hourOption.toLocaleString('en-US', {
          minimumIntegerDigits: 2
        })}:${minute.toLocaleString('en-US', {
          minimumIntegerDigits: 2
        })} ${period}`;
        options.push({ key: option, text: option, value: option });
      }
    }

    if (!options.some(option => option.value === field.value)) {
      options.push({ key: field.value, text: field.value, value: field.value });
    }

    setTimeOptions(options);
  }, [interval, field.value]);

  const handleChange = (_event: any, { value }: any) => {
    if (validateTime(value)) {
      helpers.setValue(value);
    }
  };

  const handleAddItem = (_event: any, { value }: any) => {
    if (validateTime(value)) {
      setTimeOptions([...timeOptions, { key: value, text: value, value }]);
      helpers.setValue(value);
    }
  };

  return (
    <FormRowWrapper hidden={isHidden} className={className}>
      <StyledLabel className={isError ? 'error' : ''}>{label}</StyledLabel>
      <StyledDropdown
        button
        className={`icon ${isError ? 'error' : ''}`}
        labeled
        icon="clock"
        options={timeOptions}
        search
        placeholder={placeholder}
        value={defaultValue}
        onChange={handleChange}
        allowAdditions
        onAddItem={handleAddItem}
        selection
      />
      <ErrorLabel active={isError} content={meta.error} />

      <input type="hidden" {...props} {...field} />
    </FormRowWrapper>
  );
};

export default TimePicker;
