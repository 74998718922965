import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import lampIcon from 'images/reliabilityTest/lampIcon.svg';

function PassAttemptMessage({ trainerView = false }) {
  if (trainerView) {
    return <></>;
  }
  return (
    <div className="align-middle pt-4 mr-4">
      <div className="grid grid-col-2 gap-4">
        <div className="flex">
          <img
            src={lampIcon}
            className="self-start inline mr-4"
            alt={i18n.ft(messages.attemptTip)}
          />
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: i18n.ft(messages.certificationPage, {
                  url: RouteHelpers.getPath('certifications')
                })
              }}
              tabIndex={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PassAttemptMessage;
