import moment from 'moment';

export function daysToGo(training) {
  if (!training.start_date) {
    return null;
  }
  return moment(training.start_date).diff(moment(), 'days');
}

export function getThemeColor(daysToGo) {
  if (daysToGo === 0) {
    return 'orange';
  } else if (daysToGo > 0) {
    return 'blue';
  }
  return 'green';
}
