const PATH = 'fe.components.knowledge_test';

const messages = {
  logoDescription: {
    key: `${PATH}.logo_description`,
    defaultValue: 'Certification Logo'
  },
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: 'Select the answer that best applies'
  },
  attempt: {
    key: `${PATH}.attempt`,
    defaultValue: 'Attempt {{value}}'
  },
  expiresOn: {
    key: `${PATH}.expires_on`,
    defaultValue: 'Expires on:'
  },
  expiresOnMobile: {
    key: `${PATH}.expires_on_mobile`,
    defaultValue: 'Exp.'
  },
  testInstructions: {
    key: `${PATH}.test_instructions`,
    defaultValue: 'View Test Instructions'
  },
  modal: {
    instructionsTitle: {
      key: `${PATH}.instructions_title`,
      defaultValue: 'Instructions'
    },
    dismissButton: {
      key: `${PATH}.dismiss_button`,
      defaultValue: 'Dismiss'
    }
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  },
  next: {
    key: `${PATH}.next`,
    defaultValue: 'Next'
  },
  exit: {
    key: `${PATH}.exit`,
    defaultValue: 'Save and Exit'
  },
  watch: {
    key: `${PATH}.watch`,
    defaultValue: 'Watch the video and answer the following question:'
  },
  answerSingleChoice: {
    key: `${PATH}.answer_single_choice`,
    defaultValue: 'Select the best answer:'
  },
  answerMultipleChoice: {
    key: `${PATH}.answer_multiple_choice`,
    defaultValue: 'Select all that apply:'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'Something went wrong. Please try again.'
  },
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Question Status'
  },
  dismiss: {
    key: `${PATH}.dismiss`,
    defaultValue: 'Dismiss'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Submit'
  },
  categoryResults: {
    key: `${PATH}.category_results`,
    defaultValue: 'Test Results by Category'
  },
  category: {
    key: `${PATH}.category`,
    defaultValue: 'Category'
  },
  score: {
    key: `${PATH}.score`,
    defaultValue: 'Score'
  },
  overall: {
    key: `${PATH}.overall`,
    defaultValue: 'Overall'
  },
  menu: {
    key: `${PATH}.menu`,
    defaultValue: 'Open navigation menu'
  },
  sure: {
    key: `${PATH}.sure`,
    defaultValue: "Are you sure you're ready to submit?"
  },
  submitTest: {
    key: `${PATH}.submit_test`,
    defaultValue: 'Submit Test'
  }
};

export default messages;
