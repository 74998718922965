import styled from 'styled-components';

export const PageContainer = styled.div`
  margin: 0 1em;
  position: relative;
`;

export const Header = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 8px;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 640px) {
    flex-direction: row;
  }
`;

export const ProductLabel = styled.div`
  font-size: 14px;
  margin-bottom: 8px;

  @media screen and (min-width: 640px) {
    margin-bottom: 0;
    margin-right: 16px;
  }
`;

export const PasswordHeader = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 32px;
`;

export const PasswordBody = styled.div`
  font-size: 16px;
  line-height: 24px;
`;
