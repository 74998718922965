import { clsx } from 'clsx';
import i18n from 'helpers/i18n';
import messages from './messages';
import RoutesHelper from 'helpers/routes';
import { NavLink } from 'react-router-dom';
import useCurrentUser from 'hooks/useCurrentUser';

function Header() {
  const { roles } = useCurrentUser();

  function isCoach() {
    return roles.includes('lr_coach');
  }

  return (
    <>
      <h1 className="text-4xl text-black">{i18n.ft(messages.title)}</h1>

      <div className="mt-10 border-b-2 border-[#85878A]">
        <NavLink
          end
          to={`${
            isCoach()
              ? RoutesHelper.getPath('coach-learning-resources')
              : RoutesHelper.getPath('learning-resources')
          }`}
          className={({ isActive }) =>
            clsx('text-black hover:text-black', {
              'font-bold border-b-4 border-black': isActive
            })
          }
        >
          {i18n.ft(messages.home)}
        </NavLink>
        {isCoach() && (
          <NavLink
            end
            to={RoutesHelper.getPath('coach-learning-resources-recommend')}
            className={({ isActive }) =>
              clsx('ml-16 text-black hover:text-black', {
                'font-bold border-b-4 border-black': isActive
              })
            }
          >
            {i18n.ft(messages.recommend)}
          </NavLink>
        )}
        <NavLink
          to={
            isCoach()
              ? RoutesHelper.getPath('coach-learning-resources-see-all')
              : RoutesHelper.getPath('learning-resources-all')
          }
          className={({ isActive }) =>
            clsx('ml-16 text-black hover:text-black', {
              'font-bold border-b-4 border-black': isActive
            })
          }
        >
          {i18n.ft(messages.allResources)}
        </NavLink>
      </div>
    </>
  );
}

export default Header;
