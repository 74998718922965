import styled from 'styled-components';

export const Container = styled.div``;

export const CenterTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
  color: ${p => p.theme.colors['neutral']['700']};
`;

export const CenterSubtitle = styled.div`
  font-size: 16px;
  color: ${p => p.theme.colors['grey']};
`;
