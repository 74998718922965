import * as React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import Spacer from 'components/Spacer';
import UserAvatar from 'components/Avatar';
import { getInitials, getShortName } from 'helpers/user';
import useCurrentUser from 'hooks/useCurrentUser';
import { TimesIcon, CaretUpIcon, CaretDownIcon } from '../icons';
import VisuallyHidden from 'components/VisuallyHidden';
import { fadeInAndOutProps, slideInAndOutProps } from './utils';
import RouteHelpers from 'helpers/routes';
import { logout } from 'actions/users';
import { useDispatch } from 'react-redux';
import { destroy } from 'store/slices/user';

import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
  TransitionChild
} from '@headlessui/react';

import {
  DialogWrapper,
  DialogOverlay,
  Wrapper,
  Header,
  FlexCenter,
  AvatarName,
  IconButton,
  List,
  ListItem,
  ListButton,
  FooterList,
  FooterListButton,
  FooterListDivider
} from './Styled';
import useFeatureFlags from 'hooks/useFeatureFlags';

interface SidebarNavProps {
  /** If `true`, the sidebar nav will be displayed. */
  open: boolean;

  /** Callback fired when the sidebar requests to be closed. */
  onClose: () => void;
}

function SidebarNav({ open, onClose }: SidebarNavProps) {
  const currentUser = useCurrentUser();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const dispatch = useDispatch();

  function handleLogout() {
    logout().then(() => {
      dispatch(destroy());
    });
  }

  return (
    <Transition show={open} as={React.Fragment}>
      <Dialog as={DialogWrapper} onClose={() => onClose()}>
        <TransitionChild as={React.Fragment} {...fadeInAndOutProps}>
          <DialogOverlay />
        </TransitionChild>

        <TransitionChild as={React.Fragment} {...slideInAndOutProps}>
          <DialogPanel as={Wrapper}>
            <Header>
              <FlexCenter>
                <UserAvatar
                  src={currentUser.avatar_url}
                  content={getInitials(currentUser)}
                />
                <Spacer axis="horizontal" size={16} />
                <AvatarName>{getShortName(currentUser)}</AvatarName>
              </FlexCenter>
              <FlexCenter>
                <IconButton onClick={onClose}>
                  <TimesIcon />
                  <VisuallyHidden>{i18n.ft(messages.close)}</VisuallyHidden>
                </IconButton>
              </FlexCenter>
            </Header>

            <List>
              {currentUser.menu?.map((menuItem: any, idx: number) =>
                Boolean(menuItem.children) ? (
                  <Disclosure key={idx} as="li">
                    <DisclosureButton as={ListButton}>
                      {({ open }) => (
                        <>
                          <span>{menuItem.label}</span>
                          {open ? <CaretUpIcon /> : <CaretDownIcon />}
                        </>
                      )}
                    </DisclosureButton>
                    <DisclosurePanel>
                      <List>
                        {menuItem.children.map(
                          (child: any, childIdx: number) => (
                            <li key={childIdx}>
                              <ListItem href={child.target_url}>
                                {child.label}
                              </ListItem>
                            </li>
                          )
                        )}
                      </List>
                    </DisclosurePanel>
                  </Disclosure>
                ) : (
                  <li key={idx}>
                    <ListButton as="a" href={menuItem.target_url}>
                      {menuItem.label}
                    </ListButton>
                  </li>
                )
              )}
              {isFeatureFlagEnabled('lr_dashboard_menu') && (
                <Disclosure as="li">
                  <DisclosureButton as={ListButton}>
                    {({ open }) => (
                      <>
                        <span>{i18n.ft(messages.learningResources)}</span>
                        {open ? <CaretUpIcon /> : <CaretDownIcon />}
                      </>
                    )}
                  </DisclosureButton>
                  <DisclosurePanel>
                    <List>
                      <li>
                        <ListItem
                          href={
                            currentUser.roles.includes('lr_coach')
                              ? RouteHelpers.getPath('coach-learning-resources')
                              : RouteHelpers.getPath('learning-resources')
                          }
                        >
                          {i18n.ft(messages.learningResources)}
                        </ListItem>
                      </li>
                    </List>
                  </DisclosurePanel>
                </Disclosure>
              )}
            </List>

            <FooterList>
              <li>
                <FooterListButton id="helpNavItem">
                  {i18n.ft(messages.help)}
                </FooterListButton>
              </li>
              <li>
                <FooterListButton as="a" href={RouteHelpers.getPath('account')}>
                  {i18n.ft(messages.settings)}
                </FooterListButton>
              </li>
              <li>
                <FooterListDivider />
              </li>
              <li>
                <FooterListButton onClick={handleLogout}>
                  {i18n.ft(messages.logout)}
                </FooterListButton>
              </li>
            </FooterList>
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
}

export default SidebarNav;
