import styled from 'styled-components';

interface MainContentProps {
  /** If set, limits the max width of the page to the specified value. */
  maxWidth?: number;
}

const MainContent = styled.main<MainContentProps>`
  margin: 0 auto;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : null)};
  height: 100%;
`;

export default MainContent;
