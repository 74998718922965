import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px 20px;
  box-shadow: 1px 1px 11px #d7e6ed;
  border-radius: 12px;
  color: #364a5e;

  @media screen and (min-width: 640px) {
    padding: 32px 64px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
`;

export const Grid = styled.div`
  margin-top: 32px;
  display: grid;
  grid-gap: 24px;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Label = styled.div`
  background-color: #fff7df;
  padding: 2px 12px;
  font-weight: 600;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 8px;
  margin-left: -12px;
`;

export const ButtonSpan = styled.span`
  font-weight: normal;
`;
