import styled from 'styled-components';
import EnhancedSelect from 'components/EnhancedForm/Select';

export const Select = styled(EnhancedSelect)`
  label {
    margin-bottom: 0 !important;
    color: ${p => p.theme.colors['scaleDarkBlue']} !important;
  }

  .error label {
    color: ${props => props.theme.colors['scaleDarkBlue']} !important;
  }
`;
