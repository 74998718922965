const PATH = 'fe.components.measure.input';

const messages = {
  optional: {
    key: `${PATH}.optional`,
    defaultValue: `Optional`
  }
};

export default messages;
