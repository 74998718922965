import styled from 'styled-components';

import {
  addSpace,
  addColor,
  addBorderRadius,
  SpaceProps,
  ColorProps,
  BorderProps
} from './system';

/**
 * @deprecated use tailwind classes instead of this. e.g. m-1, p-1, flex, flex-col, etc
 */
export const Box = styled.div<SpaceProps & BorderProps & ColorProps>`
  ${addSpace}
  ${addColor}
  ${addBorderRadius}
`;

/**
 * Usage:
 *
 * <Box mt="12px" mb="12px">Hello World!</Box>
 * <Box mt={{ _: '12px', sm: '24px' }}>Responsive Hello World!</Box>
 */

Box.displayName = 'Box';
