export const ClockIcon = () => (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71795 16.9972C10.1158 16.8421 10.5753 17.1811 10.4901 17.5995C10.0001 19.4995 8.90006 19.9995 7.55006 19.9995H6.46006C5.11006 19.9995 4.00006 19.4995 3.52006 17.5895C3.43781 17.1705 3.89508 16.8342 4.29271 16.99C5.14735 17.3248 6.06085 17.4995 7.00006 17.4995C7.94376 17.4995 8.8619 17.3309 9.71795 16.9972Z"
      fill="#017EA7"
    />
    <path
      d="M10.49 2.4C10.5784 2.82271 10.1124 3.1669 9.71061 3.00871C8.87284 2.67891 7.95881 2.5 7.00005 2.5C6.04246 2.5 5.13016 2.68113 4.29169 3.01127C3.89381 3.16793 3.4347 2.82901 3.52005 2.41C4.00005 0.5 5.11005 0 6.46005 0H7.55005C8.90005 0 10 0.5 10.49 2.4Z"
      fill="#017EA7"
    />
    <path
      d="M7 3.5C3.41 3.5 0.5 6.41 0.5 10C0.5 12.1 1.49 13.96 3.03 15.15H3.04C4.14 16 5.51 16.5 7 16.5C8.51 16.5 9.89 15.99 10.99 15.13H11C12.52 13.94 13.5 12.08 13.5 10C13.5 6.41 10.59 3.5 7 3.5ZM8.93 12.38C8.78 12.53 8.59 12.6 8.4 12.6C8.21 12.6 8.02 12.53 7.87 12.38L6.47 10.98C6.33 10.84 6.25 10.65 6.25 10.45V7.66C6.25 7.25 6.59 6.91 7 6.91C7.41 6.91 7.75 7.25 7.75 7.66V10.14L8.93 11.32C9.22 11.61 9.22 12.09 8.93 12.38Z"
      fill="#017EA7"
    />
  </svg>
);
