import styled from 'styled-components';

const AgeLevelGroup = styled.div`
  display: inline-block;
  position: relative;

  .AgeLevel__image {
    display: inline-block !important;
    transition: top 100ms ease 0s;
    background-color: white;
    border: 3px solid white;
    border-radius: 50%;
    position: relative;
    top: 0;

    &:not(:first-of-type) {
      left: -10px;
      margin-right: -10px;
    }

    &:hover {
      top: -4px;
      z-index: 1;
    }
  }
`;

export default AgeLevelGroup;
