import { clsx } from 'clsx';
import { ButtonHTMLAttributes } from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';

interface CycleActionsButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** Button content. */
  content: string;

  /** Semantic UI Icon name. */
  icon: SemanticICONS;

  /** If `true`, button will be disabled and display a loading indicator.  */
  loading?: boolean;
}

export function CycleActionsButton({
  content,
  icon,
  loading,
  ...props
}: CycleActionsButtonProps) {
  const iconClasses = clsx('w-5 h-5 flex items-center justify-center');
  const solidIconClasses = clsx(iconClasses, 'bg-gray-700 rounded-full');

  return (
    <button
      className="px-3 py-2 rounded-lg text-base text-left hover:bg-[#EDEFF2] flex items-center gap-2"
      disabled={loading}
      {...props}
    >
      {loading ? (
        <div className={iconClasses}>
          <Icon fitted loading name="spinner" />
        </div>
      ) : (
        <div className={solidIconClasses}>
          <Icon fitted inverted size="tiny" name={icon} />
        </div>
      )}

      <span>{content}</span>
    </button>
  );
}
