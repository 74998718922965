const PATH = 'fe.components.trainings.training_list';

const messages = {
  showMoreTrainings: {
    key: `${PATH}.show_more_trainings`,
    defaultValue: 'Show More Trainings'
  },
  emptyMessage: {
    key: `${PATH}.empty_message`,
    defaultValue: 'There are no trainings to show.'
  }
};

export default messages;
