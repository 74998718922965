const PATH = 'fe.components.trainings.view.card_checkout.payment_method_form';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: '4. Payment Information'
  },
  payByCreditCard: {
    key: `${PATH}.pay_by_credit_cart`,
    defaultValue: 'Pay by credit card'
  },
  payByPurchaseOrder: {
    key: `${PATH}.pay_by_purchase_order`,
    defaultValue: 'Pay by purchase order'
  }
};

export default messages;
