import styled from 'styled-components';

interface ButtonWrapperProps {
  fluid: boolean;
}

export const ButtonWrapper = styled.button`
  padding: 0.6875em 1.5em 0.6875em;
  border-radius: 5px;
  border: solid 2px ${p => p.theme.colors['blue']};
  color: ${p => p.theme.colors['blue']};
  background-color: ${p => p.theme.colors['white']};
  font-family: inherit;
  font-size: 1em;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1em;
  letter-spacing: normal;
  text-align: center;

  width: ${(p: ButtonWrapperProps) => (p.fluid ? '100%' : 'auto')};

  display: flex;
  justify-content: flex-end;

  &:hover {
    color: ${p => p.theme.colors['blue']};
    background-color: ${p => p.theme.colors['primary']['50']};
    cursor: pointer;
  }
`;

export const TextWrapper = styled.div`
  flex: 2;
  margin: 0;
  padding-right: 1em;
`;
