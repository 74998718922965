import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import PageLayout from 'components/PageLayout';
import { Header, List } from 'semantic-ui-react';
import { HeaderContainer, PolicyContent } from './Styled';

const CancellationPolicy: React.FC = () => {
  return (
    <PageLayout>
      <HeaderContainer>
        <Header size="huge" as="h1" textAlign="center">
          {i18n.ft(messages.mainHeader)}
        </Header>
      </HeaderContainer>
      <PolicyContent>
        <Header as="h2" size="small">
          {i18n.ft(messages.section01)}
        </Header>
        <List bulleted relaxed="very" size="large">
          <List.Item>{i18n.ft(messages.item01)}</List.Item>
          <List.Item>{i18n.ft(messages.item02)}</List.Item>
          <List.Item>{i18n.ft(messages.item03)}</List.Item>
          <List.Item>
            {i18n.ft(messages.item04) + ' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://teachstone.com/support"
            >
              https://teachstone.com/support/.
            </a>
          </List.Item>
          <List.Item>
            {i18n.ft(messages.item05) + ' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://teachstone.com/support"
            >
              https://teachstone.com/support/.
            </a>
          </List.Item>
          <List.Item>{i18n.ft(messages.item06)}</List.Item>
        </List>

        <Header as="h2" size="small">
          {i18n.ft(messages.section02)}
        </Header>
        <List bulleted relaxed="very" size="large">
          <List.Item>{i18n.ft(messages.item07)}</List.Item>
          <List.Item>{i18n.ft(messages.item08)}</List.Item>
          <List.Item>{i18n.ft(messages.item09)}</List.Item>
          <List.Item>{i18n.ft(messages.item10)}</List.Item>
        </List>
      </PolicyContent>
    </PageLayout>
  );
};

export default CancellationPolicy;
