const PATH = 'fe.components.reliability_test.submit_score_section';

const messages = {
  exitTest: {
    key: `${PATH}.exit_test`,
    defaultValue: 'Save and Exit'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Submit'
  }
};

export default messages;
