const keyBase = 'fe.components.kaltura_video';

const messages = {
  notAuthorized: {
    key: `${keyBase}.not_authorized`,
    defaultValue: 'You are not authorized to access this video.'
  }
};

export default messages;
