import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;

  @media screen and (min-width: 640px) {
    padding: 20px;
    flex-direction: row;
  }

  @media screen and (min-width: 1280px) {
    padding: 60px;
    flex-direction: row;
  }
`;

export const SummarySection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 2px solid #e7ecf5;

  @media screen and (min-width: 640px) {
    border-right: 2px solid #e7ecf5;
    border-bottom: 0px;
  }

  @media screen and (min-width: 1280px) {
    border-right: 2px solid #e7ecf5;
    border-bottom: 0px;
  }
`;

export const SettingSection = styled.div`
  display: flex;
  width: 100%;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const SummaryContainer = styled.button`
  width: 98%;
  border: 0;
  cursor: pointer;
  background-color: rgba(243, 247, 255, 0.5);
  padding: 5px;
  box-shadow: -1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  font-family: inherit;

  @media screen and (min-width: 640px) {
    width: 90%;
    padding: 10px;
  }

  @media screen and (min-width: 1280px) {
    width: 80%;
    padding: 20px;
  }
`;

export const SummaryTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7ecf5;
  padding: 5px;
  margin-bottom: 5px;
  &:last-child {
    border-bottom: 0;
  }
`;

export const RowName = styled.div``;

export const RowNumber = styled.div`
  background-color: ${p => p.theme.colors.blue};
  border-radius: 5px;
  width: 40px;
  text-align: center;
  color: ${p => p.theme.colors.white};
  height: fit-content;
  font-weight: 600;
`;

export const LoaderWrapper = styled.div`
  margin: 64px 0 48px;
`;

export const SettingsTitle = styled.div`
  display: flex;
  font-weight: bold;
  margin-bottom: 10px;
  justify-content: start;
  width: 98%;

  @media screen and (min-width: 640px) {
    width: 90%;
  }

  @media screen and (min-width: 1280px) {
    width: 80%;
  }
`;

export const SchoolWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  width: 100%;
  align-items: flex-start;

  @media screen and (min-width: 640px) {
    align-items: flex-end;
    margin: 0 0 30px 0;
  }

  @media screen and (min-width: 1280px) {
    align-items: flex-end;
    margin: 0 0 30px 0;
  }
`;
export const SettingContainer = styled.div`
  width: 98%;
  border: 0;
  background-color: rgba(243, 247, 255, 0.5);
  padding: 10px 10px 15px 10px;
  box-shadow: -1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  font-family: inherit;

  @media screen and (min-width: 640px) {
    width: 90%;
    padding: 10px;
  }

  @media screen and (min-width: 1280px) {
    width: 80%;
    padding: 20px;
  }
`;

export const SchoolContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
