import styled, { css } from 'styled-components';
import EnhancedSelect from 'components/EnhancedForm/Select';
import {
  DateInput as DateInputEnhanced,
  Checkbox as CheckboxEnhanced,
  Input as InputEnhanced
} from 'components/EnhancedForm';

export const PageWrapper = styled.div`
  margin-top: 32px;
  padding-left: 24px;
  padding-right: 24px;

  @media screen and (min-width: 640px) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media screen and (min-width: 768px) {
    padding-left: 72px;
    padding-right: 72px;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 128px;
    padding-right: 128px;
  }
`;

export const FormWrapper = styled.div`
  padding: 24px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  color: ${p => p.theme.colors['scaleDarkBlue']};
  background-color: ${p => p.theme.colors['white']};

  @media screen and (min-width: 768px) {
    padding: 32px;
  }

  @media screen and (min-width: 1280px) {
    padding: 48px 64px;
  }
`;

export const FormFields = styled.div`
  display: grid;
  grid-gap: 12px;

  @media screen and (min-width: 640px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px 32px;
  }

  @media screen and (min-width: 768px) {
    grid-gap: 12px 48px;
  }

  @media screen and (min-width: 1280px) {
    grid-gap: 16px 64px;
  }
`;

export const FormFieldsRow = styled(FormFields)`
  margin-top: 12px;
  @media screen and (min-width: 1280px) {
    margin-top: 16px;
  }
`;

export const CenterWrapper = styled.div`
  grid-column: 1;
`;

export const ButtonWrapper = styled.div<{
  alignRight?: boolean;
  alignTop?: boolean;
}>`
  margin-top: ${p => (p.alignTop ? null : '8px')};

  @media screen and (min-width: 768px) {
    margin-top: 0;
    margin-left: ${p => (p.alignRight ? 'auto' : null)};
    min-width: 128px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  @media screen and (min-width: 1024px) {
    min-width: 200px;
  }
`;

export const SectionFooter = styled.div`
  background-color: ${p => p.theme.colors['white']};
  border-radius: 0 0 18px 18px;
  margin-top: 8px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 12px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 24px 32px;
    margin-bottom: 16px;
  }

  @media screen and (min-width: 1280px) {
    padding: 24px 64px;
    margin-bottom: 24px;
  }
`;

const commonLabels = css`
  label {
    color: ${p => p.theme.colors['scaleDarkBlue']} !important;
  }
`;

const commonLabelErrors = css`
  .error label {
    color: ${props => props.theme.colors['scaleDarkBlue']} !important;
  }
`;

export const Select = styled(EnhancedSelect)<{
  $addVerticalMargin?: boolean;
}>`
  ${p =>
    p.$addVerticalMargin &&
    css`
      margin-top: 10px;
      margin-bottom: 10px;
    `}

  ${commonLabels}
  ${commonLabelErrors}
`;

export const DateInput = styled(DateInputEnhanced)`
  ${commonLabels}
  ${commonLabelErrors}
`;

export const Checkbox = styled(CheckboxEnhanced)`
  ${commonLabels}
`;

export const Input = styled(InputEnhanced)`
  ${commonLabels}
  ${commonLabelErrors}
`;
