const PATH = 'fe.pages.measure.view.examples_and_notes';

const messages = {
  currentSelection: {
    key: `${PATH}.current_selection`,
    defaultValue: 'Current Selection:'
  },
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Observation Feedback'
  },
  strengthExample: {
    key: `${PATH}.strength_example`,
    defaultValue: 'Strength Example'
  },
  strengthExampleDesc: {
    key: `${PATH}.strength_example_desc`,
    defaultValue:
      'Use the strength-based indicator suggestion or select your own.'
  },
  growth: {
    key: `${PATH}.growth`,
    defaultValue: 'Growth'
  },
  growthExample: {
    key: `${PATH}.growth_example`,
    defaultValue: 'Area of Growth Example'
  },
  growthExampleDesc: {
    key: `${PATH}.growth_example_desc`,
    defaultValue: 'Use the suggested indicator or select your own.'
  },
  dimension: {
    key: `${PATH}.dimension`,
    defaultValue: 'Dimension'
  },
  indicator: {
    key: `${PATH}.indicator`,
    defaultValue: 'Indicator'
  },
  textPlaceholder: {
    key: `${PATH}.text_placeholder`,
    defaultValue: 'Enter an example you observed that informed the rating.'
  },
  scoresChangedTitle: {
    key: `${PATH}.scores_changed_title`,
    defaultValue: "The observation's scores changed"
  },
  scoresChangedConfirmation: {
    key: `${PATH}.scores_changed_confirmation`,
    defaultValue: 'Yes, I will review'
  },
  scoresChangedContent: {
    key: `${PATH}.scores_changed_content`,
    defaultValue:
      "The observation's scores changed. Please review your Strengths and Growths example before continuing."
  },
  strength: {
    key: `${PATH}.strength`,
    defaultValue: 'Strength'
  },
  summaryError: {
    key: `${PATH}.summary_error`,
    defaultValue:
      'Please complete all required fields in order to see your observation summary.'
  },
  errors: {
    growthText: {
      required: {
        key: `${PATH}.errors.growth_text.required`,
        defaultValue: 'Growth example required.'
      }
    },
    strengthText: {
      required: {
        key: `${PATH}.errors.strength_text.required`,
        defaultValue: 'Strength example required.'
      }
    },
    missingCyclesInformation: {
      key: `${PATH}.errors.missing_cycles`,
      defaultValue:
        'All cycle scores must be completed before entering Area of Strength and Area for Growth'
    }
  }
};

export default messages;
