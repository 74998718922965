import colors from 'styles/colors';
import { FormatIcon } from './Styled';
import { useMediaQuery } from 'react-responsive';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { Status as StatusIcon } from 'components/Reports/Reliability/View';
import { passesTwoOfFiveRule } from '../../utils';

interface CommonProps {
  name: string;
  abbreviation: string;
}
export interface DimensionReliability extends CommonProps {
  score: number;
  right_answer_per_video: boolean[];
}

export interface DimensionPreparation extends CommonProps {
  reliable: boolean;
}

interface DimensionResultsProps {
  dimensions: DimensionReliability[];
}

interface DimensionResultsPreparationProps {
  dimensions: DimensionPreparation[];
}

function isReliability(
  dimension: DimensionReliability | DimensionPreparation
): dimension is DimensionReliability {
  return (
    (dimension as DimensionReliability).right_answer_per_video !== undefined
  );
}

export default function DimensionResults({
  dimensions
}: DimensionResultsProps | DimensionResultsPreparationProps) {
  const isTablet = useMediaQuery({ minWidth: 640 });

  function getBackgroundColor(right_answer_per_video: boolean[]) {
    return passesTwoOfFiveRule(right_answer_per_video)
      ? colors.reliability.passVideo
      : colors.reliability.failedAttempt;
  }

  function getIcon(right_answer_per_video: boolean[]): SemanticICONS {
    if (passesTwoOfFiveRule(right_answer_per_video)) {
      return 'check';
    }
    return 'close';
  }

  function getColor(dimension: DimensionPreparation) {
    if (!isReliability(dimension)) {
      return dimension.reliable
        ? colors.reliability.passVideo
        : colors.reliability.failedAttempt;
    }
    return colors.reliability.failedAttempt;
  }

  function getName(dimension: DimensionPreparation) {
    if (dimension.reliable) {
      return 'check';
    }
    return 'close';
  }

  if (!dimensions) {
    return null;
  }

  return (
    <>
      {dimensions.map((dimension, idx: number) => {
        return (
          <div key={idx} className="grid grid-cols-11 mx-1 text-sm md:text-xl">
            <div
              className={`${
                isReliability(dimension) ? 'col-span-5' : 'col-span-8'
              } py-2 border border-white pl-2 flex mr-2`}
              style={{ background: colors.scaleSection }}
            >
              <div className="grid grid-cols-7 w-full mr-2">
                {isReliability(dimension) ? (
                  <div className="col-span-1 self-center">
                    <StatusIcon
                      iconColor={colors.white}
                      iconBackGroundColor={getBackgroundColor(
                        dimension.right_answer_per_video || []
                      )}
                      className="!flex !mt-auto !mb-auto"
                      size={isTablet ? '24px' : '15px'}
                    >
                      <Icon
                        name={getIcon(dimension.right_answer_per_video || [])}
                        size="tiny"
                        className="align-middle !m-auto"
                        style={{
                          fontSize: !isTablet ? '0.35em' : '0.5em',
                          top: !isTablet ? 'inherit' : 'inherit',
                          position: !isTablet ? 'relative' : 'inherit',
                          margin: 'auto'
                        }}
                      />
                    </StatusIcon>
                  </div>
                ) : null}
                <div className="col-span-6">
                  <span className="text-sm md:text-base mt-auto mb-auto">
                    <span className="hidden md:inline">{dimension.name}</span>
                    <span className="inline md:hidden ml-6">
                      {dimension.abbreviation.toUpperCase()}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {isReliability(dimension) ? (
              <div
                className="flex col-span-1 text-center py-2 text-black border border-white rounded justify-center	"
                style={{
                  backgroundColor: colors.scaleSection,
                  marginLeft: '-15px'
                }}
              >
                <span className="text-xs self-center sm:text-sm md:text-base">
                  {dimension.score}%
                </span>
              </div>
            ) : null}

            {isReliability(dimension) &&
              dimension.right_answer_per_video.map((result, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex col-span-1 text-center font-bold py-2 border justify-center"
                    style={{ borderColor: colors.scaleSection }}
                  >
                    <div className="self-center">
                      <FormatIcon
                        color={
                          result
                            ? colors.reliability.passVideo
                            : colors.reliability.failedAttempt
                        }
                      >
                        <Icon
                          name={result ? 'check' : 'close'}
                          size={isTablet ? 'small' : 'tiny'}
                          className="align-middle"
                          style={{ fontSize: isTablet ? '0.85em' : '0.7em' }}
                        />
                      </FormatIcon>
                    </div>
                  </div>
                );
              })}

            {!isReliability(dimension) ? (
              <div
                key={idx}
                className="col-span-3 text-center font-bold py-2 border justify-center"
                style={{ borderColor: colors.scaleSection }}
              >
                <div className="self-center">
                  <FormatIcon color={getColor(dimension)}>
                    <Icon
                      name={getName(dimension)}
                      size={isTablet ? 'small' : 'tiny'}
                      className="align-middle"
                      style={{ fontSize: isTablet ? '0.85em' : '0.7em' }}
                    />
                  </FormatIcon>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  );
}
