import { Button } from 'semantic-ui-react';
import i18n from 'helpers/i18n';
import messages from './messages';
import Observer from 'images/observer.svg';
import Teacher from 'images/teacher.svg';
import SupportTeachers from 'images/supporter.svg';
import Other from 'images/other.svg';
import { useOnboardingManager, Jobs } from 'components/Onboarding';
import { updateUserMeJobs } from 'actions/userMe';

import {
  ButtonWrapper,
  Container,
  Title,
  Experience,
  SelectApply,
  Options,
  Card,
  CardLogo,
  CardWrapper,
  CardText,
  CardDescription
} from './Styled';

function WorkPerformed() {
  const { setStep, jobs, setJobs, checkedSteps, setCheckedSteps } =
    useOnboardingManager();

  function optionSelected(option: Jobs) {
    return jobs.includes(option);
  }

  function handleClick(jobSelected: Jobs) {
    handleList(jobSelected);
  }

  function handleKeyDown(
    event: React.KeyboardEvent<HTMLDivElement>,
    jobSelected: Jobs
  ) {
    if (event.key === 'Enter' || event.key === ' ') {
      handleList(jobSelected);
    }
  }

  function handleList(jobSelected: Jobs) {
    if (jobs.includes(jobSelected)) {
      const jobsSelected: Jobs[] = jobs.filter(job => {
        return job !== jobSelected;
      });
      setJobs(jobsSelected);
    } else {
      setJobs([...jobs, jobSelected]);
    }
  }

  async function handleSubmit() {
    await updateUserMeJobs({
      jobs: jobs
    });
    let cpChecker = checkedSteps;
    cpChecker.push('familiarWithClass');
    setStep('familiarWithClass');
    setCheckedSteps(cpChecker);
  }

  return (
    <Container>
      <Title>{i18n.ft(messages.work)}</Title>
      <Experience>{i18n.ft(messages.experience)}</Experience>
      <SelectApply>{i18n.ft(messages.selectApply)}</SelectApply>

      <Options>
        <Card
          selected={optionSelected('observer')}
          onClick={() => {
            handleClick('observer');
          }}
          onKeyDown={event => {
            handleKeyDown(event, 'observer');
          }}
          tabIndex={0}
          role="checkbox"
          aria-checked={optionSelected('observer')}
        >
          <CardLogo src={Observer} alt={i18n.ft(messages.observer_image)} />
          <CardWrapper>
            <CardText>{i18n.ft(messages.observer)}</CardText>
          </CardWrapper>
        </Card>

        <Card
          selected={optionSelected('teacher')}
          onClick={() => {
            handleClick('teacher');
          }}
          onKeyDown={event => {
            handleKeyDown(event, 'teacher');
          }}
          tabIndex={0}
          role="checkbox"
          aria-checked={optionSelected('teacher')}
        >
          <CardLogo src={Teacher} alt={i18n.ft(messages.teacher_image)} />
          <CardWrapper>
            <CardText>{i18n.ft(messages.teacher)}</CardText>
            <CardDescription>
              {i18n.ft(messages.teacherDescription)}
            </CardDescription>
          </CardWrapper>
        </Card>

        <Card
          selected={optionSelected('education_support')}
          onClick={() => {
            handleClick('education_support');
          }}
          onKeyDown={event => {
            handleKeyDown(event, 'education_support');
          }}
          tabIndex={0}
          role="checkbox"
          aria-checked={optionSelected('education_support')}
        >
          <CardLogo
            src={SupportTeachers}
            alt={i18n.ft(messages.support_image)}
          />
          <CardWrapper>
            <CardText>{i18n.ft(messages.supportTeachers)}</CardText>
            <CardDescription>
              {i18n.ft(messages.supportTeachersDescription)}
            </CardDescription>
          </CardWrapper>
        </Card>

        <Card
          selected={optionSelected('other')}
          onClick={() => {
            handleClick('other');
          }}
          onKeyDown={event => {
            handleKeyDown(event, 'other');
          }}
          tabIndex={0}
          role="checkbox"
          aria-checked={optionSelected('other')}
        >
          <CardLogo src={Other} alt={i18n.ft(messages.other_image)} />
          <CardWrapper>
            <CardText>{i18n.ft(messages.otherOption)}</CardText>
            <CardDescription>
              {i18n.ft(messages.otherDescription)}
            </CardDescription>
          </CardWrapper>
        </Card>
      </Options>

      <ButtonWrapper>
        <Button fluid size="large" color="blue" onClick={handleSubmit}>
          {i18n.ft(messages.next)}
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

export default WorkPerformed;
