import * as yup from 'yup';
import { map } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import PropTypes from 'prop-types';
import { Checkbox } from './Styled';
import React, { useState } from 'react';
import useStepStatus from '../useStepStatus';
import { Button, Icon } from 'semantic-ui-react';
import { addShippingOption } from 'actions/trainings';
import { formatShippingOption, selectedShippingOption } from './utils';
import { Formik, Form } from 'components/EnhancedForm';

import {
  Container,
  SubmitButton,
  Header,
  HeaderIcon,
  HeaderButton,
  HeaderContainer
} from 'components/Trainings/View/CardCheckout/Styled';

const validationSchema = yup.object({
  shipping_option_id: yup
    .string()
    .required(() => i18n.ft(messages.errors.shippingOption.selectOne))
});

const initialValues = {
  shipping_option_id: ''
};

const ShippingMethodForm = ({
  trainingId,
  consignment,
  afterSubmit,
  step,
  activeStep,
  onStepChange
}) => {
  const status = useStepStatus(step, activeStep);
  const [loading, setLoading] = useState(false);

  function handleEdit() {
    onStepChange(step);
  }

  function handleSubmit(values) {
    setLoading(true);
    addShippingOption(trainingId, values).then(({ data }) => {
      setLoading(false);
      onStepChange(step + 1);
      afterSubmit(data);
    });
  }

  return (
    <Container>
      <HeaderContainer>
        <Header fitted={status === 'pending'}>
          {i18n.ft(messages.header)}
          {status === 'completed' && (
            <HeaderIcon>
              <Icon name="check circle" color="green" />
            </HeaderIcon>
          )}
        </Header>

        {status === 'completed' && (
          <HeaderButton
            inverted
            size="tiny"
            color="blue"
            onClick={handleEdit}
            content={i18n.ft(messages.edit)}
          />
        )}
      </HeaderContainer>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, submitForm }) => (
          <Form>
            {status === 'active' && (
              <React.Fragment>
                {consignment &&
                  map(
                    shippingOption => (
                      <Checkbox
                        radio
                        key={shippingOption.id}
                        value={shippingOption.id}
                        name="shipping_option_id"
                        label={formatShippingOption(shippingOption)}
                      />
                    ),
                    consignment.available_shipping_options
                  )}

                <SubmitButton>
                  <Button
                    color="blue"
                    loading={loading}
                    disabled={loading}
                    onClick={submitForm}
                    content={i18n.ft(messages.continue)}
                  />
                </SubmitButton>
              </React.Fragment>
            )}
            {status === 'completed' &&
              selectedShippingOption(
                values.shipping_option_id,
                consignment.available_shipping_options
              )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

ShippingMethodForm.propTypes = {
  /** The training ID. */
  trainingId: PropTypes.number.isRequired,

  /** Big Commerce consignment object. Set after the shipping address is submitted. */
  consignment: PropTypes.object,

  /**
   * Callback fired after the form is submitted.
   * Returns a Big Commerce checkout object.
   **/
  afterSubmit: PropTypes.func.isRequired,

  /** The shipping method form step number. */
  step: PropTypes.number.isRequired,

  /** The current active step number. */
  activeStep: PropTypes.number.isRequired,

  /** Callback fired when the step request to be changed. */
  onStepChange: PropTypes.func.isRequired
};

export default ShippingMethodForm;
