const PATH = 'fe.pages.reports.observation.create';

const messages = {
  createReport: {
    key: `${PATH}.create_report`,
    defaultValue: 'Create New Report'
  },
  fillSections: {
    key: `${PATH}.fill_sections`,
    defaultValue:
      'Fill out sections 1-3 in order. For example, sections 1 must be completed in order to access section 2.'
  }
};

export default messages;
