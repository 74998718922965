import { Modal } from 'semantic-ui-react';
import { useFormikContext } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import ClipboardIcon from 'images/clipboard-icon.png';
import ModalIcon from 'images/cohort-modal-image.png';

interface StudentAgreementModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  templateName: string;
}

const StudentAgreementModal = ({
  open,
  setOpen,
  templateName
}: StudentAgreementModalProps) => {
  const { setFieldValue } = useFormikContext();

  function handleAgree() {
    setFieldValue('student_agreement', true);
    closeModal();
  }

  function closeModal() {
    setOpen(false);
  }

  return (
    <Modal open={open}>
      <div className="flex justify-center">
        <img src={ModalIcon} alt="" className="relative bottom-5" />
      </div>

      <Modal.Content>
        <div className="flex flex-col items-center px-1 gap-3">
          <h3 className="m-0 text-2xl text-sky-800">Student Agreement</h3>
          <h3 className="m-0 text-2xl text-sky-800">
            {templateName} from Teachstone Inc.
          </h3>

          <p>
            Congratulations! We are excited to join you on this next step in
            your educational journey. Before getting started with your{' '}
            {templateName}, it is important that you read, understand, and
            accept the following statements carefully. When you have done this,
            you need to agree to them by checking the box to the left and
            signing at the end of the document.
          </p>

          <div className="h-80 md:h-96 overflow-x-hidden overflow-y-auto p-5 bg-blue-50 rounded mb-5">
            <div className="flex flex-start gap-2 items-center mb-5">
              <img src={ClipboardIcon} alt="" />
              <p className="font-bold">Student Responsibilities</p>
            </div>

            <ul>
              <li className="before:content-['•'] before:mr-2 before:text-orange-400 before:text-3xl">
                I understand that the {templateName} is a non-refundable online
                course. The credit card I use to enroll will be charged at the
                time I complete registration for the course. I may withdraw from
                the program on or prior to day 1 of the course for a full
                refund.
              </li>
              <li className="before:content-['•'] before:mr-2 before:text-orange-400 before:text-3xl">
                If I cannot begin on my enrollment start date, I can request to
                postpone my start date for up to six months after my initial
                registered date. I must make my request on or prior to Day 1 of
                the course. After that, I forfeit my registration payment. There
                will be no refund and I must re-register at my own expense.
              </li>
              <li className="before:content-['•'] before:mr-2 before:text-orange-400 before:text-3xl">
                I understand that I must abide by the individual course syllabus
                for completion criteria of the {templateName} and I must
                complete all the three(3) courses in their entirety before
                applying for my CDA credential.
              </li>
              <li className="before:content-['•'] before:mr-2 before:text-orange-400 before:text-3xl">
                I understand that the CDA credential is not automatically
                awarded upon completing {templateName} courses. I will receive
                guidance about completing my application to the Council for
                Professional Recognition during {templateName} coursework. I
                understand that the Council for Professional Recognition will
                award the CDA credential based on my fulfillment of all
                requirements and that Teachstone Inc. does not have authority to
                grant CDA certification.
              </li>
              <li className="before:content-['•'] before:mr-2 before:text-orange-400 before:text-3xl">
                CDA with CEUs o I understand that after completing the CDA with
                CLASS courses, I will receive 12
              </li>
              <li className="before:content-['•'] before:mr-2 before:text-neutral-600 before:text-2xl ml-7">
                Continuing Education Units (CEUs) if I have completed all
                coursework and have received a passing grade of ‘C’ or better as
                outlined in the course syllabus. Teachstone is accredited by the
                International Association for Continuing Education and Training
                (IACET).
              </li>
              <li className="before:content-['•'] before:mr-2 before:text-orange-400 before:text-3xl">
                CDA with College Credits o I understand that after completing
                the {templateName} courses, I will receive 120+ clock
              </li>
              <li className="before:content-['•'] before:mr-2 before:text-neutral-600 before:text-2xl ml-7">
                hours of professional education and instructions to secure my
                college credit transcripts if I have completed all coursework
                and have received apassing grade of ‘C’ or better as outlined in
                the course syllabus.
              </li>
            </ul>

            <div className="flex flex-start gap-2 items-center my-5">
              <img src={ClipboardIcon} alt="" />
              <p className="font-bold">Student Information Sharing Policies</p>
            </div>

            <ul>
              <li className="before:content-['•'] before:mr-2 before:text-orange-400 before:text-3xl">
                I give Teachstone Inc., permission to retain personal and
                educational records deemed necessary to grant CEUs and make
                program improvements, including, but not limited to, name,
                grades/GPA, attendance records, academic progress, status,
                address, phone number, courses completed, and dates of
                completion.
              </li>
              <li className="before:content-['•'] before:mr-2 before:text-orange-400 before:text-3xl">
                I give Teachstone Inc., permission to provide certain personal
                or educational records deemed necessary to the Council for
                Professional Recognition for completion of CDA certification
                including, but not limited to, name, grades/GPA, attendance
                records, academic progress, status, address, phone number,
                courses completed, and dates of completion.
              </li>
              <li className="before:content-['•'] before:mr-2 before:text-orange-400 before:text-3xl">
                I have access to and have read the {templateName} guidelines.
              </li>
              <li className="before:content-['•'] before:mr-2 before:text-orange-400 before:text-3xl">
                I understand that Teachstone Inc., reserves the right to add or
                revise any content offered in {templateName}.
              </li>
              <li className="before:content-['•'] before:mr-2 before:text-orange-400 before:text-3xl">
                By electronically signing this document below, I hereby pledge
                and agree that I will not share the content of this course with
                anyone and that I will not give or receive any aid on the
                completion of any assignments or quizzes in this {templateName}
                program.
              </li>
            </ul>

            <p className="font-bold mt-5">
              By clicking the “I Agree” button I thereby supplying my electronic
              signature, I attest that I have read and understand the above
              information and agree to adhere to all provisions, terms and
              conditions contained in this Student Agreement.
            </p>
          </div>

          <button
            className="bg-sky-800 w-full h-10 rounded text-white font-semibold"
            onClick={handleAgree}
          >
            I agree
          </button>
          <button
            className="w-full h-10 rounded border border-neutral-400 font-semibold"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default StudentAgreementModal;
