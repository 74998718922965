import * as R from 'ramda';
import RouteHelpers from 'helpers/routes';

type SisEnrollment = {
  course_name: string;
  end_date: string;
  course_type: string;
};

type OneCourseResponse = {
  token_type: 'sis_enrollment' | 'learning_pathway' | 'recertification_test';
  course_name: string;
  course_type: string;
  end_date: string;
};

type ManyCoursesResponse = {
  token_type: 'course_bundle';
  sis_enrollments: SisEnrollment[];
};

function getOneCourseState(response: OneCourseResponse) {
  return {
    courseType: response.course_type,
    enrollments: [{ course: response.course_name, endDate: response.end_date }]
  };
}

function getManyCoursesState(response: ManyCoursesResponse) {
  return {
    courseType: getCoursesType(response.sis_enrollments),
    enrollments: response.sis_enrollments.map(enrollment => ({
      course: enrollment.course_name,
      endDate: enrollment.end_date
    }))
  };
}

function getCoursesType(enrollments: SisEnrollment[]) {
  if (
    enrollments.every(enrollment => enrollment.course_type === 'TotaraCourse')
  ) {
    return 'TotaraCourse';
  } else if (
    enrollments.every(enrollment => enrollment.course_type === 'CanvasCourse')
  ) {
    return 'CanvasCourse';
  } else {
    // NOTE: There's no current use case that mix both types.
    return 'MixedCourseTypes';
  }
}

const ONE_COURSE_TOKENS = [
  'sis_enrollment',
  'learning_pathway',
  'recertification_test'
];

const MANY_COURSES_TOKENS = ['course_bundle'];

export type PostActivationData = {
  source: 'internal' | 'external';
  path: string;
  state: any;
};

export function getPostActivationData(data: any): PostActivationData {
  const hasRedirectURL = !R.isNil(data.redirect_url);
  const hasOneCourse = ONE_COURSE_TOKENS.includes(data.token_type);
  const hasManyCourses = MANY_COURSES_TOKENS.includes(data.token_type);
  const isLearningPathway = data.token_type === 'learning_pathway';
  const isIncomplete = data.status === 'incomplete';
  const isTestTemplate = data.token_type === 'test_template';

  const result: PostActivationData = {
    path: '/',
    state: {},
    source: 'internal'
  };

  if (hasRedirectURL) {
    result.path = data.redirect_url;
    result.source = 'external';
  } else if (isTestTemplate && isIncomplete) {
    result.path = RouteHelpers.getPath('recertification-product-key');
    result.state = data;
  } else if (isLearningPathway && isIncomplete) {
    result.path = RouteHelpers.getPath('learning-pathway-product-key');
    result.state = data;
  } else if (hasOneCourse) {
    result.path = RouteHelpers.getPath('token-confirmation');
    result.state = getOneCourseState(data);
  } else if (hasManyCourses && isIncomplete) {
    result.path = RouteHelpers.getPath('user-choice-product-key');
    result.state = data;
  } else if (hasManyCourses) {
    result.path = RouteHelpers.getPath('token-confirmation');
    result.state = getManyCoursesState(data);
  } else {
    result.path = RouteHelpers.getPath('certifications');
    result.state = { showSuccess: true, tokenType: data.token_type };
  }

  return result;
}
