import { Icon } from 'semantic-ui-react';
import { AgeLevel } from 'types/api/envScales/AgeLevel';

export function getDimension(ageLevel: AgeLevel, dimensionId: number) {
  return ageLevel.domains
    .flatMap(domain => domain.dimensions)
    .find(dimension => dimension.id === dimensionId);
}

export function getIndicator(
  ageLevel: AgeLevel,
  dimensionId: number,
  indicatorId: number
) {
  return getDimension(ageLevel, dimensionId)?.indicators?.find(
    indicator => indicator.id === indicatorId
  );
}

export function getDimensions(
  ageLevel: AgeLevel,
  suggestedDimensionId: number
) {
  return ageLevel.domains.flatMap(domain => {
    return domain.dimensions.map(dimension => ({
      key: dimension.id,
      value: dimension.id,
      text: (
        <span>
          {dimension.name}
          {dimension.id === suggestedDimensionId ? (
            <>
              &nbsp;&nbsp;
              <Icon size="small" name="star" color="yellow" />
            </>
          ) : null}
        </span>
      )
    }));
  });
}

export function getIndicators(
  ageLevel: AgeLevel,
  dimensionId: number,
  suggestedIndicatorId: number
) {
  return ageLevel.domains.flatMap(domain => {
    const dimensions = domain.dimensions.filter(
      dimension => dimension.id === dimensionId
    );

    return dimensions.flatMap(dimension => {
      return dimension.indicators.map(indicator => ({
        key: indicator.id,
        value: indicator.id,
        text: (
          <span>
            {indicator.name}
            {indicator.id === suggestedIndicatorId ? (
              <>
                &nbsp;&nbsp;
                <Icon size="small" name="star" color="yellow" />
              </>
            ) : null}
          </span>
        )
      }));
    });
  });
}

export function getFirstIndicator(ageLevel: AgeLevel, dimensionId: number) {
  const allDimensions = ageLevel.domains.flatMap(domain => domain.dimensions);
  const dimension = allDimensions.find(d => d.id === dimensionId);
  const indicator = dimension!.indicators[0];

  return indicator;
}
