import * as Yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';

export default Yup.object({
  first_name: Yup.string().required(
    i18n.ft(messages.errors.firstName.required)
  ),
  last_name: Yup.string().required(i18n.ft(messages.errors.lastName.required)),
  email: Yup.string()
    .email(i18n.ft(messages.errors.email.invalid))
    .required(i18n.ft(messages.errors.email.required)),
  password: Yup.string()
    .min(6, i18n.ft(messages.errors.password.minChars))
    .required(i18n.ft(messages.errors.password.required)),
  password_confirmation: Yup.string()
    .required(i18n.ft(messages.errors.confirmPassword.required))
    .oneOf(
      [Yup.ref('password')],
      i18n.ft(messages.errors.confirmPassword.mustMatch)
    ),
  tos_pp_agreed_at: Yup.bool()
    .required(i18n.ft(messages.errors.checkbox.required))
    .oneOf([true], i18n.ft(messages.errors.checkbox.required))
});
