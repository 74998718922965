import { isEmpty } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import Spacer from 'components/Spacer';
import { Section } from '../../Styled';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useTraining } from '../../TrainingContext';
import { getTrainingCourses } from 'actions/trainings';
import { getCourseAccess, getCourseDuration } from './utils';

import {
  Header,
  Item,
  ItemTitle,
  ItemSubtitle,
  ItemAction,
  ItemMessage
} from './Styled';

const CoursesSection: React.FC = () => {
  const { training, template } = useTraining();
  const { run, data, isSuccess } = useAsync();

  useEffect(() => {
    run(getTrainingCourses(training.id));
  }, [run, training.id]);

  const coursesAvailable = isSuccess && !isEmpty(data.courses);
  const testAvailable = isSuccess && template.reliability_test;

  if (!coursesAvailable && !testAvailable) {
    return null;
  }

  return (
    <Section>
      <Header>{i18n.ft(messages.header)}</Header>

      <Spacer size={16} />
      <div>
        {testAvailable && (
          <Item key="reliabilityTest">
            <div>
              <ItemTitle>{i18n.ft(messages.reliability)}</ItemTitle>
              <ItemSubtitle>
                <Icon name="calendar outline" />
                {i18n.ft(messages.weeksOfAccess)}
              </ItemSubtitle>
            </div>
            <ItemAction>
              {training.participant_status === 'completed' ? (
                <Button
                  as={Link}
                  to={RouteHelpers.getPath('certifications')}
                  fluid
                  color="blue"
                  content={i18n.ft(messages.goToCertifications)}
                />
              ) : (
                <ItemMessage>{i18n.ft(messages.uponCompletion)}</ItemMessage>
              )}
            </ItemAction>
          </Item>
        )}

        {data.courses.map((course: any) => {
          const access = getCourseAccess(course);
          const duration = getCourseDuration(course);

          return (
            <Item key={course.lms_course_id}>
              <div>
                <ItemTitle>{course.name}</ItemTitle>
                {duration && (
                  <ItemSubtitle>
                    <Icon name="calendar outline" />
                    {duration}
                  </ItemSubtitle>
                )}
              </div>
              <ItemAction>
                {access.enabled ? (
                  <Button
                    as="a"
                    href={
                      course.type === 'TotaraCourse'
                        ? '#'
                        : RouteHelpers.getPath('canvas-course', {
                            id: course.lms_course_id
                          })
                    }
                    fluid
                    color="blue"
                    content={access.message}
                  />
                ) : (
                  <ItemMessage>{access.message}</ItemMessage>
                )}
              </ItemAction>
            </Item>
          );
        })}
      </div>
    </Section>
  );
};

export default CoursesSection;
