import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import RoutesHelper from 'helpers/routes';
import { Loader } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllStarred } from 'actions/starred';
import { getHoursMinutes } from 'pages/LearningResources/utils';
import VideoResource from 'components/LearningResources/VideoResource';
import { Tag } from 'types/api/Tag';
import useCurrentUser from 'hooks/useCurrentUser';

type ImportantItem = {
  name: string;
  id: number;
  video_uri: string | undefined;
  estimated_time: number;
  tags?: Tag[];
};

function Important() {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const {
    data: { learning_resources },
    run,
    isPending
  } = useAsync({ data: {} });
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    run(getAllStarred());
  }, [run]);

  function handleClick(id: number) {
    if (user.roles.includes('lr_coach')) {
      return navigate(
        RoutesHelper.getPath('coach-learning-resources-detail-page', {
          id
        })
      );
    }

    return navigate(
      RoutesHelper.getPath('learning-resources-detail-page', {
        id
      })
    );
  }

  if (isPending) {
    return <Loader active />;
  }

  if (!learning_resources || learning_resources?.length === 0) {
    return null;
  }

  return (
    <>
      <div className="block md:flex items-center mb-4">
        <h2 className="text-black">{i18n.ft(messages.title)}</h2>{' '}
        <a
          className="pl-0 md:pl-4 font-bold underline underline-offset-2 text-base text-[#0A9CCA]"
          href={RoutesHelper.getPath('learning-resources-all')}
        >
          {i18n.ft(messages.all)}
        </a>
      </div>
      <div
        className={`flex gap-3 2xl:grid 2xl:grid-cols-4 pb-8 ${
          isHovered
            ? 'overflow-auto scrollbar-visible'
            : 'overflow-hidden scrollbar-hidden'
        }`}
        onTouchStart={() => {
          setIsHovered(true);
        }}
        onTouchEnd={() => {
          setIsHovered(false);
        }}
      >
        {learning_resources?.map((important: ImportantItem, idx: number) => (
          <VideoResource
            ariaLabel={'Video Thumbnail'}
            ariaRoleDescription={'Slide'}
            handleClick={handleClick}
            video={{
              name: important.name,
              id: important.id ? important.id : idx,
              videoURI: important.video_uri
                ? important.video_uri
                : '1_bkuk1xi7',
              estimatedTime: important.estimated_time
                ? getHoursMinutes(important.estimated_time)
                : '0'
            }}
            key={important.id}
            tags={important.tags}
          />
        ))}
      </div>
    </>
  );
}

export default Important;
