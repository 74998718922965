import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const Section = styled.div<{ editMode: boolean }>`
  background-color: ${p => (p.editMode ? '#FFF' : p.theme.colors.scaleSection)};
  border-radius: 11px;
  padding: 25px 40px;
`;

export const TimeInput = styled.input.attrs(_props => ({
  type: 'time'
}))`
  ::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
  ::-webkit-datetime-edit-ampm-field {
    display: none;
  }
  padding: 6px 14px;
  text-align: center;
  width: 140px;
`;

export const AddButton = styled(Button)`
  &.ui.button {
    background-color: #edeff2 !important;
    color: black;

    &:hover {
      background-color: #cacbcd !important;
    }
  }
`;

export const RemoveButton = styled(Button)`
  &.ui.button {
    background-color: inherit !important;
    box-shadow: none !important;
    color: #e71921 !important;

    &:hover {
      box-shadow: 0px 0px 0px 1px #e71921 inset !important;
    }
  }
`;
