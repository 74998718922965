import { map, isEmpty } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Section } from '../Styled';
import TimeZone from 'components/TimeZone';
import Typography from 'components/Typography';
import AttendanceCard from './AttendanceCard';
import SessionSelector from './SessionSelector';
import TrainingContext from '../TrainingContext';
import { Header, Loader } from 'semantic-ui-react';
import React, { useContext, useState } from 'react';
import { createSessionAttendance } from 'actions/trainingSessions';

import {
  mapSessions,
  getDefaultSessionId,
  useParticipants,
  useSessionAttendance,
  mapParticipantsAttendance
} from './utils';

const Attendance: React.FC = () => {
  const { training } = useContext(TrainingContext);
  const [activeSessionId, setActiveSessionId] = useState(
    getDefaultSessionId(training.sessions)
  );
  const [loadingParticipants, participants, fetchParticipants] =
    useParticipants(training.id);
  const [loadingAttendance, attendees, setAttendees] =
    useSessionAttendance(activeSessionId);
  const paidParticipants = mapParticipantsAttendance(participants, attendees);
  const trainingIsCompleted = training.status === 'completed';

  function handleSessionChange(sessionId: number) {
    setActiveSessionId(sessionId);
  }

  function markAttendance(participantId: number, attended: boolean) {
    return (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
      if (!activeSessionId) {
        return;
      }

      createSessionAttendance(activeSessionId, [
        {
          participant_id: participantId,
          attended: attended
        }
      ]).then(({ data }) => {
        setAttendees(data.attendees);
        fetchParticipants();
        setLoading(false);
      });
    };
  }

  return (
    <Section>
      <Header
        content={i18n.ft(messages.takeAttendance)}
        subheader={i18n.ft(messages.selectSession)}
      />

      <TimeZone />

      <SessionSelector
        onChange={handleSessionChange}
        activeSessionId={activeSessionId}
        sessions={mapSessions(training.sessions)}
      />

      {loadingParticipants || loadingAttendance ? (
        <Loader active inline="centered" />
      ) : (
        <div>
          {map(
            participant => (
              <AttendanceCard
                key={participant.id}
                email={participant.email}
                name={participant.name}
                avatar={participant.avatar}
                attended={participant.attended}
                onMarkAsAbsent={markAttendance(participant.id, false)}
                onMarkAsPresent={markAttendance(participant.id, true)}
                disabled={trainingIsCompleted}
                notRegistered={participant.status === 'invited'}
              />
            ),
            paidParticipants
          )}

          {isEmpty(paidParticipants) && (
            <Typography
              bold
              display="block"
              textAlign="center"
              content={i18n.ft(messages.noParticipants)}
            />
          )}
        </div>
      )}
    </Section>
  );
};

export default Attendance;
