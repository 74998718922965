import axios from 'axios';

type getSubscriptionUsersParams = {
  id: number;
  query_params?: string;
};

export function getSubscriptions() {
  return axios.get(`/api/v2/subscriptions`);
}

export function getUserSubscriptions(userId: number) {
  return axios.get(`/api/v2/users/${userId}/subscriptions`);
}

export function getSubscription(id: number) {
  return axios.get(`/api/v2/subscriptions/${id}`);
}

export function getSubscriptionUsers(params: getSubscriptionUsersParams) {
  const { id, query_params = '' } = params;
  return axios.get(`/api/v2/subscriptions/${id}/users${query_params}`);
}

export function getSubscriptionUser(subscriptionId: number, userId: number) {
  return axios.get(`/api/v2/subscriptions/${subscriptionId}/users/${userId}`);
}

export function createSubscriptionUser(subscriptionId: number, params = {}) {
  return axios.post(`/api/v2/subscriptions/${subscriptionId}/users`, params);
}

export function updateSubscriptionUser(
  subscriptionId: number,
  userId: number,
  params = {}
) {
  return axios.put(
    `/api/v2/subscriptions/${subscriptionId}/users/${userId}`,
    params
  );
}

export function deleteSubscriptionUser(subscriptionId: number, userId: number) {
  return axios.delete(
    `/api/v2/subscriptions/${subscriptionId}/users/${userId}`
  );
}

export function deleteSubscription(id: number) {
  return axios.delete(`/api/v2/subscriptions/${id}`);
}

export function getMyCoachConnectTags() {
  return axios.get(`/api/v2/subscriptions/my_coach_connect_tags`);
}

export function createSubscription(subscription = {}) {
  return axios.post('/api/v2/subscriptions', { subscription });
}

export function createSubscriptionInvites(subscriptionId: number, params = {}) {
  return axios.post(`/api/v2/subscriptions/${subscriptionId}/invite`, params);
}

export function createAccountSubscription(
  accountId: number,
  subscription = {}
) {
  return axios.post(`/api/v2/accounts/${accountId}/subscriptions`, {
    subscription
  });
}

export function updateAccountSubscription(
  accountId: number,
  subscriptionId: number,
  subscription = {}
) {
  return axios.put(
    `/api/v2/accounts/${accountId}/subscriptions/${subscriptionId}`,
    {
      subscription
    }
  );
}

/** Account Organization Subscriptions */
export function getAccountSubscriptions(params = {}) {
  return axios.get(`/api/v2/ca/subscriptions`, { params });
}

export function getAccountSubscriptionDetail(id: number) {
  return axios.get(`/api/v2/ca/subscriptions/${id}`);
}
