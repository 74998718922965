const PATH = 'fe.components.knowledge_test.modal';

const messages = {
  attempts: {
    key: `${PATH}.attempts`,
    defaultValue:
      'You have 3 attempts to pass the test. It consists of 30 single choice and multiple select questions organized into 5 categories.'
  },
  pass: {
    key: `${PATH}.pass`,
    defaultValue:
      'In order to pass, you must correctly answer at least 80% of all questions as well as at least 50% of questions from each category.'
  },
  fail: {
    key: `${PATH}.fail`,
    defaultValue:
      'If you do not pass after 3 attempts, you will need to attend the CLASS 2nd Edition Train-the-Trainer before scheduling CLASS training.'
  },
  complete: {
    key: `${PATH}.complete`,
    defaultValue:
      'You can complete questions in any order, taking as long as you want until the test expires. Your answers will automatically save. You must provide an answer to each question before submitting the test.'
  },
  recommendations: {
    key: `${PATH}.recommendations`,
    defaultValue: 'Recommendations:'
  },
  test: {
    key: `${PATH}.test`,
    defaultValue:
      'This test is open book and open note. Use your CLASS 2nd Edition Field Guide and your materials from the CLASS 2nd Edition Train-the-Trainer while testing.'
  },
  unsuccesful: {
    key: `${PATH}.unsuccesful`,
    defaultValue:
      'If you are unsuccessful on your first attempt, meet with Teachstone Reliability Support prior to your next testing attempt.'
  },
  provide: {
    key: `${PATH}.provide`,
    defaultValue:
      'You must provide an answer to each question before submitting the test.'
  },
  instructions: {
    key: `${PATH}.instructions`,
    defaultValue: 'Please provide an answer to all questions before submitting.'
  },
  answered: {
    key: `${PATH}.answered`,
    defaultValue: 'Answered'
  },
  unanswered: {
    key: `${PATH}.unanswered`,
    defaultValue: 'Unanswered'
  },
  click: {
    key: `${PATH}.click`,
    defaultValue: 'Click on a question to return to it:'
  },
  please: {
    key: `${PATH}.please`,
    defaultValue:
      'Please review your answers before continuing. You will not be able to change your answers after submitting.'
  }
};

export default messages;
