import moment from 'moment';

export function initialStartDate(startMonth?: number) {
  if (!startMonth) {
    return null;
  }

  const currentMonth = moment().month();
  const schoolYearStartMonth = startMonth - 1;
  const initialDate = moment().month(schoolYearStartMonth).startOf('month');

  if (currentMonth < schoolYearStartMonth) {
    return initialDate.subtract(1, 'year').toDate();
  } else {
    return initialDate.toDate();
  }
}

export type OptionalDate = Date | null | undefined;

export function parseDate(date: string | undefined): OptionalDate {
  return date ? moment(date).startOf('day').toDate() : null;
}
