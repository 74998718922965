import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';

export const Header = styled.div`
  font-size: 18px;
  font-weight: bold;

  @media screen and (max-width: 440px) {
    margin-bottom: 4px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  @media screen and (max-width: 440px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ActionsDropdown = styled(Dropdown)`
  &.ui.dropdown {
    min-width: 210px;
  }

  &.ui.dropdown > .menu > .item {
    font-size: 14px;
  }
`;
