import i18n from 'helpers/i18n';
import { useEffect, useState } from 'react';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import RoutesHelper from 'helpers/routes';
import { Loader } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import { LearningResource } from 'types/api/LearningResource';
import { getHoursMinutes } from 'pages/LearningResources/utils';
import { getLearningResources } from 'actions/learningResources';
import VideoResource from 'components/LearningResources/VideoResource';
import useCurrentUser from 'hooks/useCurrentUser';

interface SearchResultsProps {
  query: string;
}

function SearchResults({ query }: SearchResultsProps) {
  const DEFAULT_PER_PAGE = 4;
  const navigate = useNavigate();
  const user = useCurrentUser();
  const [isHovered, setIsHovered] = useState(false);
  const { data, run, isPending, setData } = useAsync();
  const { state } = usePaginatedSearch({
    page: 1,
    query: query,
    sort_by: '',
    sort_dir: '',
    per_page: DEFAULT_PER_PAGE
  });

  useEffect(() => {
    run(getLearningResources({ ...state, query: query }));
  }, [run, state, query]);

  useEffect(() => {
    return () => {
      setData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(id: number) {
    if (user.roles.includes('lr_coach')) {
      return navigate(
        RoutesHelper.getPath('coach-learning-resources-detail-page', {
          id
        })
      );
    }

    return navigate(
      RoutesHelper.getPath('learning-resources-detail-page', {
        id
      })
    );
  }

  if (isPending) {
    return <Loader active />;
  }

  if (data.learning_resources?.length === 0) {
    return (
      <div className="mt-16 w-full mx-auto text-center">
        {i18n.ft(messages.noResults)}
      </div>
    );
  }

  return (
    <>
      <div className="block md:flex items-center mb-4">
        <h2 className="text-black">{`${i18n.ft(
          messages.results
        )} "${query}"`}</h2>{' '}
        <a
          className="pl-0 md:pl-4 font-bold underline underline-offset-2 text-base text-[#0A9CCA]"
          href={RoutesHelper.getPath('learning-resources-all')}
        >
          {i18n.ft(messages.all)}
        </a>
      </div>
      <div
        className={`flex gap-3 2xl:grid 2xl:grid-cols-4 pb-8 ${
          isHovered
            ? 'overflow-auto scrollbar-visible'
            : 'overflow-hidden scrollbar-hidden'
        }`}
        onTouchStart={() => {
          setIsHovered(true);
        }}
        onTouchEnd={() => {
          setIsHovered(false);
        }}
      >
        {data.learning_resources?.map(
          (video: LearningResource, idx: number) => (
            <VideoResource
              ariaLabel={'Video Thumbnail'}
              ariaRoleDescription={'Slide'}
              handleClick={handleClick}
              video={{
                name: video.name,
                id: video.id ? video.id : idx,
                videoURI: video.video_uri ? video.video_uri : '1_bkuk1xi7',
                estimatedTime: video.estimated_time
                  ? getHoursMinutes(video.estimated_time)
                  : '0'
              }}
              key={video.id}
              tags={video.tags}
            />
          )
        )}
      </div>
    </>
  );
}

export default SearchResults;
