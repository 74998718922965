import { OnboardingSteps } from 'components/Onboarding';

export function labelOnSteps(label: string, checkedSteps: OnboardingSteps[]) {
  return checkedSteps.filter(e => e === label).length > 0;
}

export function activeStep(label: string, checkedSteps: OnboardingSteps[]) {
  return checkedSteps[checkedSteps.length - 1] === label;
}

export function findIndexOfStep(
  allSteps: OnboardingSteps[],
  label: OnboardingSteps
) {
  return allSteps
    .map(function (e) {
      return e;
    })
    .indexOf(label);
}
