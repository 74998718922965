import { Link, LinkProps } from 'react-router-dom';

interface AssessmentLinkProps extends LinkProps {
  /** If `true`, the component will be rendered as a link. */
  canLink: boolean;
}

function AssessmentLink({
  canLink,
  to,
  children,
  ...props
}: AssessmentLinkProps) {
  return canLink ? (
    <Link to={to} {...props}>
      {children}
    </Link>
  ) : (
    <div className={props.className}>{children}</div>
  );
}

export default AssessmentLink;
