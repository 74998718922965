const PATH = 'fe.pages.dashboard';

const messages = {
  userDashboard: {
    key: `${PATH}.user_dashboard`,
    defaultValue: '{{name}} Dashboard'
  },
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: `Here what's happening in your account today`
  },
  noAvailable: {
    key: `${PATH}.services_not_available`,
    defaultValue:
      'You do not have any active services, feel free to check out these free resources available on our '
  },
  publicWebsite: {
    key: `${PATH}.public_website`,
    defaultValue: 'public website'
  },
  contactMessage: {
    key: `${PATH}.contact_message`,
    defaultValue:
      'If you have questions please reach out to our Customer Support team.'
  },
  tiles: {
    certificationTitle: {
      key: `${PATH}.tiles.certification_title`,
      defaultValue: 'Manage my Certifications & Trainings'
    },
    coachingTitle: {
      key: `${PATH}.tiles.coaching_title`,
      defaultValue: 'Coaching'
    },
    communityTitle: {
      key: `${PATH}.tiles.community_title`,
      defaultValue: 'Engage in CLASS Community'
    },
    trackData: {
      key: `${PATH}.track_data_title`,
      defaultValue: 'Track CLASS Data'
    }
  }
};

export default messages;
