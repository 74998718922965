import styled, { css } from 'styled-components';

interface ContainerProps {
  active?: boolean;
  disabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  padding: 12px 16px 10px;
  border-radius: 4px;
  border: 1px solid #e2e2e3;
  background-color: #fafafb;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.active || props.disabled ? null : '#e2e2e2'};
  }

  &:focus {
    outline: none;
    background-color: #e2e2e2;
  }

  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.blue};
      cursor: default;
    `}

  ${props =>
    props.disabled &&
    css`
      color: rgba(40, 40, 40, 0.3);
      cursor: not-allowed;
      border-style: dashed;
    `}

  &:not(:first-of-type) {
    margin-left: 12px;
  }
`;

export const Date = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const Time = styled.div`
  font-size: 14px;
  line-height: 1;
  margin-top: 4px;
`;
