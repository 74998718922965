import { Reducer, useReducer } from 'react';

enum ActionType {
  Update = 'UPDATE',
  UpdatePage = 'UPDATE_PAGE'
}

interface PaginatedState {
  page: number;
  per_page?: number;
}

type Action<State extends PaginatedState> =
  | { type: ActionType.Update; payload: Partial<State> }
  | { type: ActionType.UpdatePage; page: number };

function reducer<State extends PaginatedState>(
  state: State,
  action: Action<State>
) {
  switch (action.type) {
    case ActionType.Update:
      return { ...state, ...action.payload, page: 1 };
    case ActionType.UpdatePage:
      return { ...state, page: action.page };
    default:
      return state;
  }
}

export function usePaginatedSearch<SearchState extends PaginatedState>(
  initialValues: SearchState
) {
  const [state, dispatch] = useReducer<
    Reducer<SearchState, Action<SearchState>>
  >(reducer, initialValues);

  function setState(newState: Partial<SearchState>) {
    dispatch({ type: ActionType.Update, payload: newState });
  }

  function setPage(newPage: number) {
    dispatch({ type: ActionType.UpdatePage, page: newPage });
  }

  return { state, setState, setPage };
}
