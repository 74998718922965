import styled from 'styled-components';

export const PageContainer = styled.div`
  margin: 0 1em;
  position: relative;
`;

export const SubHeader = styled.div`
  margin-bottom: 32px;
  text-align: center;
`;
