import styled from 'styled-components';
import { Field } from 'formik';

export const RadioInput = styled(Field)`
  width: 1.5rem;
  height: 1.5rem;
  accent-color: #777777;
`;

export const AreaLabel = styled.label`
  border-radius: 6.25rem;
  border-color: transparent;
  border-style: solid;
  border-width: 2px;
  background: var(--gray-4, #ebeff8);
  padding: 1rem 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;

  &:hover {
    border-color: #7aa03f;
  }

  input[type='radio']:disabled + & {
    border-color: transparent;
  }

  input[type='radio']:checked + & {
    background-color: #7aa03f;
    color: white;
  }
`;
