import useDebouncedEffect from 'hooks/useDebouncedEffect';
import {
  createNote,
  updateStrategyRatings,
  updateScaleRatings
} from 'actions/improvements';
import { useFormikContext } from 'formik';

const DEBOUNCE_DELAY = 1000;
const permittedNotes = [
  'ObservationNote',
  'SummaryNote',
  'OptionalSummaryNote'
];

type AutoSaveProps = {
  coachingCycleId: number;
};

function AutoSave({ coachingCycleId }: AutoSaveProps) {
  const { values, setFieldTouched, touched, dirty } = useFormikContext();

  const formikValues: { [f: string]: any } = values as object;
  const formikTouched: { [key: string]: boolean } = touched;

  useDebouncedEffect(
    async () => {
      if (
        !dirty ||
        !formikTouched ||
        Object.values(formikTouched).every(x => !x)
      ) {
        return;
      }

      const payload = Object.keys(touched).reduce(
        (acc: { [k: string]: any }, item) => {
          if (formikTouched[item]) {
            acc[item] = formikValues[item];
          }
          return acc;
        },
        {} as object
      );

      Object.entries(payload).forEach(([fieldName, response]) => {
        if (permittedNotes.includes(fieldName)) {
          createNote(coachingCycleId, fieldName, response);
        }
        if (fieldName === 'improvement_strategy_ratings_attributes') {
          updateStrategyRatings(coachingCycleId, { ratings: response });
        }
        if (fieldName === 'improvement_scale_ratings_attributes') {
          updateScaleRatings(coachingCycleId, { ratings: response });
        }
        if (response.length) {
          setFieldTouched(fieldName, false, true);
        }
      });
    },

    DEBOUNCE_DELAY,
    [values, touched]
  );

  return null;
}

export default AutoSave;
