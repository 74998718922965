import RoutesHelper from 'helpers/routes';
import { useMeasure } from 'context/measure';
import CycleView from 'pages/Measure/View/Cycles/View';
import VideoView from 'pages/Measure/View/Videos/View';
import EditDetailsPage from 'pages/Measure/Create';
import { Navigate, Route, Routes } from 'react-router-dom';
import ExamplesAndNotes from 'pages/Measure/View/ExamplesAndNotes';
import { CyclesErrorsProvider } from 'context/CyclesErrorsContext';
import { SideNavigation } from 'components/Measure/View/SideNavigation';
import EnvironmentsPage from 'components/Measure/View/EnvScalesAssessment';
import SummaryPage from 'pages/Measure/Summary/CyclesReview';
import { ValidationsProvider } from 'components/Measure/View/ValidationsContext';

export function MeasureHome() {
  const { assessment } = useMeasure();
  const firstCycleId = assessment.observation!.observation_cycles[0].id;

  return assessment.includes_environments ? (
    <Navigate
      replace
      to={RoutesHelper.getPath('measure-view-environment', {
        id: assessment.id
      })}
    />
  ) : (
    <Navigate
      replace
      to={RoutesHelper.getPath('measure-view-cycle', {
        id: assessment.id,
        cycleId: firstCycleId
      })}
    />
  );
}

function ClassAssessment() {
  const { assessment } = useMeasure();

  return (
    <ValidationsProvider assessment={assessment}>
      <SideNavigation>
        <Routes>
          <Route index element={<MeasureHome />} />

          <Route
            path="details"
            element={<EditDetailsPage isContainedInSidenav />}
          />

          <Route
            path="environment"
            element={
              <EnvironmentsPage assessment={assessment} isContainedInSidenav />
            }
          />

          <Route
            path="cycles/:cycleId"
            element={
              <CyclesErrorsProvider>
                <CycleView />
              </CyclesErrorsProvider>
            }
          />

          <Route path="videos/:videoId" element={<VideoView />} />
          <Route path="examples-and-notes" element={<ExamplesAndNotes />} />

          <Route
            path="review"
            element={
              <SummaryPage
                assessmentId={assessment.id}
                accountGuid={assessment.account_guid}
                isContainedInSidenav
              />
            }
          />
        </Routes>
      </SideNavigation>
    </ValidationsProvider>
  );
}

export default ClassAssessment;
