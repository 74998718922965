import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Box } from 'components/Box';
import useAsync from 'hooks/useAsync';
import { useEffect, useState, useRef } from 'react';
import { useMeasure } from 'context/measure';
import { Dropdown, Loader } from 'semantic-ui-react';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import FooterNav from 'components/Measure/View/FooterNav';

import {
  getDimension,
  getDimensions,
  getIndicator,
  getIndicators,
  getFirstIndicator
} from './utils';

import {
  SectionWithBorder,
  ErrorMessage
} from 'components/Measure/View/Styled';

import InfoModal from 'components/Modals/InfoModal';
import CustomQuestionsSection from 'components/Measure/View/ExamplesAndNotes/CustomQuestionsSection';

import {
  Grid,
  Title,
  Label,
  TextArea,
  GrowthIcon,
  StrengthIcon
} from './Styled';

import {
  getObservationSuggestions,
  updateObservation
} from 'actions/envScales/observations';

import { getSettings, GetSettingsRes } from 'actions/envScales/settings';
import Attachments from 'components/Measure/View/ExamplesAndNotes/Attachments/Attachments';
import { SectionCollapse } from 'components/SectionCollapse';
import { useValidations } from 'components/Measure/View/ValidationsContext';
import { toastError } from 'components/Toast';

function ExamplesAndNotes() {
  const { assessment, ageLevel } = useMeasure();
  const [touched, setTouched] = useState(false);
  const [modalConfirmed, setModalConfirmed] = useState(false);
  const [strengthDimensionId, setStrengthDimensionId] = useState(-1);
  const [strengthIndicatorId, setStrengthIndicatorId] = useState(-1);
  const [growthDimensionId, setGrowthDimensionId] = useState(-1);
  const [growthIndicatorId, setGrowthIndicatorId] = useState(-1);
  const [scoresChanged, setScoresChanged] = useState(false);
  const [strengthText, setStrengthText] = useState('');
  const [growthText, setGrowthText] = useState('');
  const [errorStrengthText, setErrorStrengthText] = useState(false);
  const [errorGrowthText, setErrorGrowthText] = useState(false);
  const { run, data: suggestions, isPending } = useAsync();
  const observationId = assessment.observation!.id;
  const accountGuid = assessment.account_guid;
  const { validate } = useValidations();

  const {
    run: runAccountSettings,
    data: accountSettings,
    isPending: isPendingAccountSetting
  } = useAsync<GetSettingsRes>();

  const customQuestionsRef: any = useRef();
  const dimensionRef: any = useRef();
  const domainRef: any = useRef();
  const attachmentsRef: any = useRef();

  useEffect(() => {
    run(getObservationSuggestions(observationId), {
      onSuccess: (data: any) => {
        setStrengthDimensionId(data.selected_strength_dimension_id);
        setStrengthIndicatorId(data.selected_strength_indicator_id);
        setGrowthDimensionId(data.selected_improve_dimension_id);
        setGrowthIndicatorId(data.selected_improve_indicator_id);
        setStrengthText(data.strength_text || '');
        setGrowthText(data.improve_text || '');
        setScoresChanged(data.scores_changed || false);
      }
    });
  }, [run, observationId]);

  useEffect(() => {
    if (accountGuid !== null) {
      runAccountSettings(getSettings(accountGuid));
    }
  }, [accountGuid, runAccountSettings]);

  useDebouncedEffect(
    () => {
      if (!touched) {
        return;
      }

      const params = {
        selected_strength_dimension_id: strengthDimensionId,
        selected_strength_indicator_id: strengthIndicatorId,
        selected_improve_dimension_id: growthDimensionId,
        selected_improve_indicator_id: growthIndicatorId,
        strength_text: strengthText,
        improve_text: growthText
      };

      updateObservation(observationId, params);
    },
    1000,
    [
      touched,
      strengthDimensionId,
      strengthIndicatorId,
      growthDimensionId,
      growthIndicatorId,
      strengthText,
      growthText
    ]
  );

  function handleDimensionChange(
    type: 'strength' | 'growth',
    dimensionId: number
  ) {
    const indicator = getFirstIndicator(ageLevel, dimensionId);

    if (type === 'strength') {
      setStrengthDimensionId(dimensionId);
      setStrengthIndicatorId(indicator.id);
    } else {
      setGrowthDimensionId(dimensionId);
      setGrowthIndicatorId(indicator.id);
    }

    setTouched(true);
  }

  function buildInfoModalContent() {
    const strengthDimension = getDimension(ageLevel, strengthDimensionId)?.name;
    const strengthIndicator = getIndicator(
      ageLevel,
      strengthDimensionId,
      strengthIndicatorId
    )?.name;
    const growthDimension = getDimension(ageLevel, growthDimensionId)?.name;
    const growthIndicator = getIndicator(
      ageLevel,
      growthDimensionId,
      growthIndicatorId
    )?.name;

    return (
      <div>
        <p>{i18n.ft(messages.scoresChangedContent)}</p>
        <p>{i18n.ft(messages.currentSelection)}</p>
        <p>
          {i18n.ft(messages.strength) +
            ': ' +
            strengthDimension +
            ' - ' +
            strengthIndicator}
        </p>
        <p>
          {i18n.ft(messages.growth) +
            ': ' +
            growthDimension +
            ' - ' +
            growthIndicator}
        </p>
      </div>
    );
  }

  if (isPending || isPendingAccountSetting) {
    return <Loader active inline="centered" />;
  }

  const {
    use_custom_questions: displayCustomQuestions,
    strength_and_growth_enabled: displayClassroomExamples,
    dimension_feedback: displayDimension,
    domain_feedback: displayDomain
  } = accountSettings.account_setting;

  const showModal =
    displayClassroomExamples &&
    !modalConfirmed &&
    scoresChanged &&
    !!assessment.observation?.selected_improve_dimension_id &&
    !!assessment.observation?.selected_strength_indicator_id;

  const strengthDimensions = getDimensions(
    ageLevel,
    suggestions.recommended_strength_dimension_id
  );
  const growthDimensions = getDimensions(
    ageLevel,
    suggestions.recommended_improve_dimension_id
  );
  const strengthIndicators = getIndicators(
    ageLevel,
    strengthDimensionId,
    suggestions.recommended_strength_indicator_id
  );
  const growthIndicators = getIndicators(
    ageLevel,
    growthDimensionId,
    suggestions.recommended_improve_indicator_id
  );
  const completed = !R.any(R.isEmpty)([
    growthText,
    strengthText,
    growthIndicatorId,
    strengthIndicatorId
  ]);

  const isCompleteCycles = suggestions.cycles_complete;

  function handleModalConfirmed() {
    setModalConfirmed(true);
    updateObservation(observationId, { scores_changed: false });
  }

  function getIsFirstSection() {
    const sections = [
      displayCustomQuestions ? 'questions' : null,
      displayDimension ? 'dimensions' : null,
      displayDomain ? 'domains' : null,
      displayClassroomExamples ? 'classrooms' : null,
      'attachments'
    ].filter(s => s !== null);

    const firstSection = sections[0];

    return {
      questions: firstSection === 'questions',
      dimensions: firstSection === 'dimensions',
      domains: firstSection === 'domains',
      classrooms: firstSection === 'classrooms',
      attachments: firstSection === 'attachments'
    };
  }

  async function handleValidateNext() {
    const shouldTransitionToSummary = await validate();

    if (!shouldTransitionToSummary) {
      toastError({
        description: i18n.ft(messages.summaryError)
      });
    }

    return shouldTransitionToSummary;
  }

  const isFirstSection = getIsFirstSection();

  return (
    <>
      <InfoModal
        title={i18n.ft(messages.scoresChangedTitle)}
        message={buildInfoModalContent()}
        icon="info circle"
        isOpen={showModal}
        confirmButton={i18n.ft(messages.scoresChangedConfirmation)}
        onConfirm={handleModalConfirmed}
      />

      <div className="flex flex-col gap-2">
        {displayCustomQuestions && (
          <CustomQuestionsSection
            observationId={observationId}
            ref={customQuestionsRef}
            attached
          />
        )}

        {displayDimension && (
          <CustomQuestionsSection
            observationId={observationId}
            ref={dimensionRef}
            targetType="dimensions"
            attached={isFirstSection.dimensions}
          />
        )}

        {displayDomain && (
          <CustomQuestionsSection
            observationId={observationId}
            ref={domainRef}
            targetType="domains"
            attached={isFirstSection.domains}
          />
        )}

        {displayClassroomExamples && (
          <SectionCollapse
            title={i18n.ft(messages.header)}
            status={completed ? 'completed' : 'pending'}
            attached={isFirstSection.classrooms}
            closeOthersOnOpen
          >
            <div className="sm:px-14 sm:pb-5">
              {!isCompleteCycles && (
                <div className="text-red-500 py-2">
                  {i18n.ft(messages.errors.missingCyclesInformation)}
                </div>
              )}
              <SectionWithBorder>
                <Title>
                  {i18n.ft(messages.strengthExample)}
                  &nbsp;
                  <StrengthIcon />
                </Title>
                <Box mt="8px">{i18n.ft(messages.strengthExampleDesc)}</Box>
                <Box mt="24px">
                  <Grid>
                    <div>
                      <Label htmlFor="strengthDim">
                        {i18n.ft(messages.dimension)}
                      </Label>
                      <Dropdown
                        id="strengthDim"
                        fluid
                        selection
                        disabled={!isCompleteCycles}
                        options={strengthDimensions}
                        value={strengthDimensionId}
                        onChange={(_e, data) => {
                          const dimensionId = Number(data.value);
                          handleDimensionChange('strength', dimensionId);
                        }}
                      />
                    </div>
                    <div>
                      <Label htmlFor="strengthInd">
                        {i18n.ft(messages.indicator)}
                      </Label>
                      <Dropdown
                        id="strengthInd"
                        fluid
                        selection
                        disabled={!isCompleteCycles}
                        options={strengthIndicators}
                        value={strengthIndicatorId}
                        onChange={(_e, data) => {
                          setStrengthIndicatorId(data.value as number);
                          setTouched(true);
                        }}
                      />
                    </div>
                  </Grid>

                  <Box mt="16px">
                    <TextArea
                      rows={3}
                      placeholder={i18n.ft(messages.textPlaceholder)}
                      aria-label={i18n.ft(messages.textPlaceholder)}
                      disabled={!isCompleteCycles}
                      value={strengthText}
                      onChange={event => {
                        setStrengthText(event.target.value);
                        setTouched(true);
                        setErrorStrengthText(false);
                      }}
                    />
                    {errorStrengthText && (
                      <ErrorMessage>
                        {i18n.ft(messages.errors.growthText.required)}
                      </ErrorMessage>
                    )}
                  </Box>
                </Box>
              </SectionWithBorder>

              <SectionWithBorder mt="24px">
                <Title>
                  {i18n.ft(messages.growthExample)}
                  &nbsp;
                  <GrowthIcon />
                </Title>
                <Box mt="8px">{i18n.ft(messages.growthExampleDesc)}</Box>
                <Box mt="24px">
                  <Grid>
                    <div>
                      <Label htmlFor="growthDim">
                        {i18n.ft(messages.dimension)}
                      </Label>
                      <Dropdown
                        id="growthDim"
                        fluid
                        selection
                        disabled={!isCompleteCycles}
                        options={growthDimensions}
                        value={growthDimensionId}
                        onChange={(_e, data) => {
                          const dimensionId = Number(data.value);
                          handleDimensionChange('growth', dimensionId);
                        }}
                      />
                    </div>
                    <div>
                      <Label htmlFor="growthInd">
                        {i18n.ft(messages.indicator)}
                      </Label>
                      <Dropdown
                        id="growthInd"
                        fluid
                        selection
                        disabled={!isCompleteCycles}
                        options={growthIndicators}
                        value={growthIndicatorId}
                        onChange={(_e, data) => {
                          setGrowthIndicatorId(data.value as number);
                          setTouched(true);
                        }}
                      />
                    </div>
                  </Grid>

                  <Box mt="16px">
                    <TextArea
                      rows={3}
                      placeholder={i18n.ft(messages.textPlaceholder)}
                      aria-label={i18n.ft(messages.textPlaceholder)}
                      value={growthText}
                      disabled={!isCompleteCycles}
                      onChange={event => {
                        setGrowthText(event.target.value);
                        setTouched(true);
                        setErrorGrowthText(false);
                      }}
                    />
                    {errorGrowthText && (
                      <ErrorMessage>
                        {i18n.ft(messages.errors.growthText.required)}
                      </ErrorMessage>
                    )}
                  </Box>
                </Box>
              </SectionWithBorder>
            </div>
          </SectionCollapse>
        )}

        <Attachments
          ref={attachmentsRef}
          assessment={assessment}
          sectionWithRoundedBorder={isFirstSection.attachments}
        />
      </div>

      <FooterNav validateNext={handleValidateNext} />
    </>
  );
}

export default ExamplesAndNotes;
