const PATH =
  'fe.components.measure.view.cycles.cycle_scores_form.dimension_score_input';

const messages = {
  dimensionScore: {
    key: `${PATH}.dimension_score`,
    defaultValue: 'Dimension score {{value}}'
  }
};

export default messages;
