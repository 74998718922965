import { map } from 'ramda';
import React, { useEffect, useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import FormDropdown from 'components/FormDropdown';
import { getImprovementLanguages } from 'actions/improvements';
import { ImprovementLanguage } from 'types/api/improvement/ImprovementLanguage';

export function languageFormatter(option: ImprovementLanguage) {
  return { key: option.id, value: option.id, text: option.name };
}

const ImprovementLanguages: React.FC = props => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<DropdownItemProps[]>([]);

  useEffect(() => {
    setLoading(true);
    getImprovementLanguages().then(({ data }) => {
      setOptions(map(languageFormatter, data));
      setLoading(false);
    });
  }, []);

  return <FormDropdown loading={loading} options={options} {...props} />;
};

export default ImprovementLanguages;
