const PATH = 'fe.components.reliability_test.coding_videos';

const messages = {
  threeAttempts: {
    key: `${PATH}.three_attempts`,
    defaultValue:
      'You have 3 attempts to pass the test. If you do not pass after 3 attempts, you will need to sign up for another observation training and pass reliability before you can code classrooms with the CLASS tool.'
  },
  videoInstructions: {
    key: `${PATH}.video_instructions`,
    defaultValue: 'Video Instructions'
  },
  recommendations: {
    key: `${PATH}.recommendations`,
    defaultValue: 'Recommendations'
  },
  useClass: {
    key: `${PATH}.use_class`,
    defaultValue:
      'Use CLASS score sheets for notetaking to mirror the formal observation process.'
  },
  code: {
    key: `${PATH}.code`,
    defaultValue: 'Code no more than two videos per day.'
  },
  wearHeadphones: {
    key: `${PATH}.wear_headphones`,
    defaultValue: 'Wear headphones to optimize sound quality.'
  },
  useComputer: {
    key: `${PATH}.use_computer`,
    defaultValue: 'Use a computer with a high-speed Internet connection.'
  },
  eachVideo: {
    key: `${PATH}.each_video`,
    defaultValue: 'Each of the 5 testing videos is 15-20 minutes long.'
  },
  watchVideo: {
    key: `${PATH}.watch_video`,
    defaultValue: 'As you watch each video, take notes on your code sheet.'
  },
  watchedOnly: {
    key: `${PATH}.watched_only`,
    defaultValue:
      'Each video should be watched only once in its entirety and coded immediately.'
  },
  classManual: {
    key: `${PATH}.class_manual`,
    defaultValue:
      'Use your CLASS Manual or Field Guide to assign codes for each dimension.'
  },
  enterCodes: {
    key: `${PATH}.enter_codes`,
    defaultValue: 'Enter your codes, review them, and then click Submit.'
  }
};

export default messages;
