const PATH = 'fe.pages.improvements.educator_profile.action_plan';

const messages = {
  upNext: {
    key: `${PATH}.up_next`,
    defaultValue: 'Up Next...'
  },
  actionPlan: {
    key: `${PATH}.action_plan`,
    defaultValue: 'Action Plan'
  },
  puttingStrategiesToAction: {
    key: `${PATH}.putting_strategies_to_action`,
    defaultValue: 'Creating an Action Plan'
  },
  reviewSelectedStrategies: {
    key: `${PATH}.review_selected_strategies`,
    defaultValue:
      'Personalize the strategies to fit your context and create an action plan.'
  },
  prioritizeStrategies: {
    key: `${PATH}.prioritize_strategies`,
    defaultValue:
      ' As you plan, you can think about which strategy or strategies you want to prioritize the most. Use the Planning Prompts to plan how you will use the strategies and support the focus throughout your daily routine.'
  },
  viewActionPlan: {
    key: `${PATH}.view_action_plan`,
    defaultValue: 'View Action Plan'
  },
  actionPlanCreated: {
    key: `${PATH}.action_plan_created`,
    defaultValue: 'Action Plan Created'
  },
  selectedFocus: {
    key: `${PATH}.selected_focus`,
    defaultValue: 'Focus'
  },
  edit: {
    key: `${PATH}.edit`,
    defaultValue: 'Edit'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Submit'
  },
  errors: {
    fieldRequired: {
      key: `${PATH}.field_required`,
      defaultValue: 'Field Required'
    }
  },
  understand: {
    header: {
      key: `${PATH}.understand.header`,
      defaultValue: 'Preparing to Try It Out'
    },
    observableCuesHeader: {
      key: `${PATH}.understand.observable_cues_header`,
      defaultValue: 'Observable Cues'
    },
    observableCuesSubtitle: {
      key: `${PATH}.understand.observable_cues_subtitle`,
      defaultValue:
        'What would supporting the focus look and sound like across different activities and times of day?'
    },
    childCues: {
      key: `${PATH}.understand.child_cues`,
      defaultValue: 'Child Cues'
    },
    educatorCues: {
      key: `${PATH}.understand.educator_cues`,
      defaultValue: 'Educator Cues'
    },
    additionalCuesNotes: {
      key: `${PATH}.understand.additional_cues_notes`,
      defaultValue: 'What other cues, if any, should we look for?'
    },
    additionalSupportInfo: {
      key: `${PATH}.understand.additional_support_info`,
      defaultValue: 'Action Plan Wrap Up'
    },
    additionalSupportInfoPlaceholder: {
      key: `${PATH}.understand.additional_support_info_placeholder`,
      defaultValue:
        'In between today and the observation, I’ll put this plan into action on…\n' +
        'I’ll reflect on my own by…\n' +
        'We’ll check in on…\n'
    },
    action_prompt1: {
      key: `${PATH}.understand.action_prompt_1`,
      defaultValue: 'How are you feeling about this action plan?'
    },
    action_prompt2: {
      key: `${PATH}.understand.action_prompt_2`,
      defaultValue: 'How and when will you try it out?'
    },
    action_prompt3: {
      key: `${PATH}.understand.action_prompt_3`,
      defaultValue: 'How will you reflect on your own?'
    },
    action_prompt4: {
      key: `${PATH}.understand.action_prompt_4`,
      defaultValue: 'When would you like to check in?'
    },
    action_prompt5: {
      key: `${PATH}.understand.action_prompt_5`,
      defaultValue: 'What other support do you need as you try this out?'
    }
  },
  supportingStrategies: {
    title: {
      key: `${PATH}.supporting_strategies.title`,
      defaultValue: 'Key Strategies'
    },
    responseLabel: {
      key: `${PATH}.supporting_strategies.response_label`,
      defaultValue: 'Action Plan'
    },
    responsePlaceholder: {
      key: `${PATH}.supporting_strategies.response_placeholder`,
      defaultValue:
        'Using the strategies throughout the day:\n' +
        '\n' +
        'Planning for a specific context–\n' +
        'Activity/experience:\n' +
        '\n' +
        'Context (time of day, content area, format):\n' +
        '\n' +
        'Environment (materials, arrangements, displays):\n'
    },
    promptsTitle: {
      key: `${PATH}.supporting_strategies.prompts_title`,
      defaultValue: 'Planning Prompts'
    },
    prompt1: {
      key: `${PATH}.supporting_strategies.prompt_1`,
      defaultValue:
        'How can you implement these strategies generally throughout the day?'
    },
    prompt2: {
      key: `${PATH}.supporting_strategies.prompt_2`,
      defaultValue:
        'During which specific context or time of day might you try out these strategies? What experience or activity might you want to plan for?'
    },
    prompt3: {
      key: `${PATH}.supporting_strategies.prompt_3`,
      defaultValue: 'Consider each strategy'
    },
    prompt3SubPrompt1: {
      key: `${PATH}.supporting_strategies.prompt_3_sub_prompt1`,
      defaultValue: 'What will you say and do?'
    },
    prompt3SubPrompt2: {
      key: `${PATH}.supporting_strategies.prompt_3_sub_prompt2`,
      defaultValue: 'How might children respond?'
    },
    prompt4: {
      key: `${PATH}.supporting_strategies.prompt_4`,
      defaultValue: 'Consider the environment'
    },
    prompt4SubPrompt1: {
      key: `${PATH}.supporting_strategies.prompt_4_sub_prompt1`,
      defaultValue:
        'What materials, displays, or arrangements might support interactions?'
    },
    prompt4SubPrompt2: {
      key: `${PATH}.supporting_strategies.prompt_4_sub_prompt2`,
      defaultValue: 'How will you use them?'
    },
    prompt5: {
      key: `${PATH}.supporting_strategies.prompt_5`,
      defaultValue: 'Consider children’s developmental and linguistic abilities'
    },
    prompt5SubPrompt1: {
      key: `${PATH}.supporting_strategies.prompt_5_sub_prompt1`,
      defaultValue:
        'How will you ensure this experience is accessible to each child? (e.g., adaptive materials, home language supports, physical accommodations)'
    },
    prompt5SubPrompt2: {
      key: `${PATH}.supporting_strategies.prompt_5_sub_prompt2`,
      defaultValue:
        'How will you ensure each child benefits? (e.g., additional supports, added challenge)?'
    }
  }
};
export default messages;
