import { useFormikContext } from 'formik';
import { getImprovementStrategies } from 'actions/improvements';
import useAsync from 'hooks/useAsync';
import { useEffect, FocusEvent } from 'react';
import { TextArea } from 'components/EnhancedForm';
import { ImprovementFocus } from 'types/api/improvement/ImprovementFocus';
import { ImprovementStrategy } from 'types/api/improvement/ImprovementStrategy';
import messages from './messages';
import i18n from 'helpers/i18n';
import HideOnPrint from 'pages/Improvements/HideOnPrint';
import { setTextWithPlaceholder } from 'pages/Improvements/EducatorProfile/utils';

export type SupportingStrategiesNoteProps = {
  improvement_action_plan_note?: string;
};

type SupportingStrategiesProps = {
  focus: ImprovementFocus;
  disabled?: boolean;
} & SupportingStrategiesNoteProps;

const SupportingStrategies = ({
  focus,
  disabled = false
}: SupportingStrategiesProps) => {
  const { setFieldValue } = useFormikContext();
  const { run: runStrategies, data: strategies } =
    useAsync<ImprovementStrategy[]>();

  useEffect(() => {
    runStrategies(getImprovementStrategies({ with_deleted: false }));
  }, [runStrategies]);

  const strategiesForSelectedFocus = strategies?.filter(
    strategy =>
      strategy.improvement_focus?.map(focus => focus?.id).includes(focus?.id)
  );

  return (
    <div>
      <h2 className="text-[1.125rem] font-normal leading-7 !mb-4">
        {i18n.ft(messages.supportingStrategies.title)}
      </h2>

      <div className="xl:flex gap-4 justify-between items-stretch">
        <div className="w-full xl:w-1/2">
          <ul className="list-disc list-inside pl-3 mb-4">
            {strategiesForSelectedFocus?.map(strategy => (
              <li key={strategy.id} className="pb-2">
                {strategy.name}
              </li>
            ))}
          </ul>

          <TextArea
            name="improvement_action_plan_note"
            rows={8}
            label={i18n.ft(messages.supportingStrategies.responseLabel)}
            placeholder={i18n.ft(
              messages.supportingStrategies.responsePlaceholder
            )}
            disabled={disabled}
            onFocus={(e: FocusEvent<HTMLTextAreaElement>) =>
              setTextWithPlaceholder(e, setFieldValue)
            }
          />
        </div>

        <div className={HideOnPrint(disabled, 'w-full xl:w-1/2')}>
          <div className="rounded-lg bg-[#F4F8FA] text-[#364A5E] p-6 h-full">
            <h3 className="text-base">
              {i18n.ft(messages.supportingStrategies.promptsTitle)}
            </h3>
            <ul className="list-disc pl-6 font-light">
              <li>{i18n.ft(messages.supportingStrategies.prompt1)}</li>
              <li>{i18n.ft(messages.supportingStrategies.prompt2)}</li>
            </ul>

            <h5 className="pt-4 font-light">
              {i18n.ft(messages.supportingStrategies.prompt3)}
            </h5>
            <ul className="list-disc pl-6 font-light">
              <li>
                {i18n.ft(messages.supportingStrategies.prompt3SubPrompt1)}
              </li>
              <li>
                {i18n.ft(messages.supportingStrategies.prompt3SubPrompt2)}
              </li>
            </ul>

            <h5 className="pt-4 font-light">
              {i18n.ft(messages.supportingStrategies.prompt4)}
            </h5>
            <ul className="list-disc pl-6 font-light">
              <li>
                {i18n.ft(messages.supportingStrategies.prompt4SubPrompt1)}
              </li>
              <li>
                {i18n.ft(messages.supportingStrategies.prompt4SubPrompt2)}
              </li>
            </ul>

            <h5 className="pt-4 font-light">
              {i18n.ft(messages.supportingStrategies.prompt5)}
            </h5>
            <ul className="list-disc pl-6 font-light">
              <li>
                {i18n.ft(messages.supportingStrategies.prompt5SubPrompt1)}
              </li>
              <li>
                {i18n.ft(messages.supportingStrategies.prompt5SubPrompt2)}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportingStrategies;
