const PATH = 'fe.components.forms.user_form';

const messages = {
  firstName: {
    key: `${PATH}.first_name`,
    defaultValue: 'First Name'
  },
  lastName: {
    key: `${PATH}.last_name`,
    defaultValue: 'Last Name'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  password: {
    key: `${PATH}.password`,
    defaultValue: 'Password'
  },
  confirmPassword: {
    key: `${PATH}.confirm_password`,
    defaultValue: 'Confirm Password'
  },
  createAccount: {
    key: `${PATH}.create_account`,
    defaultValue: 'Create Account'
  },
  errors: {
    firstName: {
      required: {
        key: `${PATH}.errors.first_name.required`,
        defaultValue: 'First Name is required'
      }
    },
    lastName: {
      required: {
        key: `${PATH}.errors.last_name.required`,
        defaultValue: 'Last Name is required'
      }
    },
    email: {
      required: {
        key: `${PATH}.errors.email.required`,
        defaultValue: 'Email is required'
      },
      invalid: {
        key: `${PATH}.errors.email.invalid`,
        defaultValue: 'Invalid email format'
      },
      alreadyInUse: {
        key: `${PATH}.errors.email.already_in_use`,
        defaultValue: 'This account already exists. Please log in'
      }
    },
    password: {
      required: {
        key: `${PATH}.errors.password.required`,
        defaultValue: 'Password is required'
      },
      minChars: {
        key: `${PATH}.errors.password.min_chars`,
        defaultValue: 'Password must contain at least 6 characters'
      }
    },
    confirmPassword: {
      required: {
        key: `${PATH}.errors.confirm_password.required`,
        defaultValue: 'Password Confirmation is required'
      },
      mustMatch: {
        key: `${PATH}.errors.confirm_password.must_match`,
        defaultValue: 'Passwords must match'
      }
    },
    unknown: {
      key: `${PATH}.errors.unknown`,
      defaultValue: 'An unknown error occurred, please try again later'
    },
    checkbox: {
      required: {
        key: `${PATH}.errors.checkbox.required`,
        defaultValue:
          'To create an account you must agree to the Terms of Service and Privacy Policy.'
      }
    }
  },
  termsOfService: {
    privacy01: {
      key: `${PATH}.terms_service.privacy_01`,
      defaultValue: 'I agree to the Teachstone'
    },
    privacy02: {
      key: `${PATH}.terms_service.privacy_02`,
      defaultValue: 'Terms of Service'
    },
    privacy03: {
      key: `${PATH}.terms_service.privacy_03`,
      defaultValue: 'and'
    },
    privacy04: {
      key: `${PATH}.terms_service.privacy_04`,
      defaultValue: 'Privacy Policy'
    }
  }
};

export default messages;
