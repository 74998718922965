const PATH = 'fe.components.reports.observation.compare_bar_chart';

const messages = {
  comparisons: {
    key: `${PATH}.comparison`,
    defaultValue: 'Comparisons'
  }
};

export default messages;
