import Category from './Category';
import { Category as CategoryType } from 'types/api/envScales/AssessmentScore';
import { SectionCollapse } from 'components/SectionCollapse';
import { useDomainStatus } from './utils';

interface DomainProps {
  /** Category list.  */
  categories: CategoryType[];

  /** Domain name. */
  name: string;

  /** Assessment ID. */
  assessmentId: number;

  /** If `true`, renders section with top border radius. */
  attached?: boolean;
}

function Domain({ name, categories, assessmentId, attached }: DomainProps) {
  const status = useDomainStatus(categories);

  return (
    <SectionCollapse
      title={name}
      attached={attached}
      status={status}
      closeOthersOnOpen
    >
      <div className="sm:px-14 sm:pb-5">
        {categories.map(category => (
          <Category
            key={category.t_name}
            name={category.name}
            items={category.items}
            assessmentId={assessmentId}
          />
        ))}
      </div>
    </SectionCollapse>
  );
}

export default Domain;
