import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Button, Icon } from 'semantic-ui-react';
import { TRAINING_STATUS } from 'types/api/Training';
import { Container, Status } from './Styled';

interface TrainingStatusProps {
  /** The training status. */
  status: TRAINING_STATUS;

  /** The training ID. */
  trainingId: number;

  /** If `true` a link to download the certificate is displayed */
  certificate?: boolean;
}

const TrainingStatus: React.FC<TrainingStatusProps> = ({
  status,
  trainingId,
  certificate
}) => {
  function getColor(status: TRAINING_STATUS) {
    if (status === 'active') {
      return 'yellow';
    } else if (status === 'completed') {
      return 'green';
    } else if (status === 'canceled') {
      return 'red';
    }
  }

  function handleDownload(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    e.preventDefault();
    window.open(`/api/v2/trainings/${trainingId}/certificate`, '_blank');
  }

  return (
    <Container>
      <Status>
        <Icon name="circle" color={getColor(status)} />
        {i18n.ft(messages[status])}
      </Status>
      {status === 'completed' && certificate && (
        <React.Fragment>
          <Button color="blue" size="mini" onClick={handleDownload}>
            <Icon name="download" /> {i18n.ft(messages.download)}
          </Button>
        </React.Fragment>
      )}
    </Container>
  );
};

export default TrainingStatus;
