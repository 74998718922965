import { getHierarchy } from 'actions/accounts';
import { getAccountAssessmentTemplates } from 'actions/envScales/accountAssessmentTemplates';
import { getAllObservers } from 'actions/observers';
import useAsync from 'hooks/useAsync';
import { useCallback, useEffect, useMemo } from 'react';
import { SelectedAccount } from 'types/api/Account';
import FilterElement from './FilterElement';
import centerIcon from 'images/envScales/centerIcon.svg';
import observerIcon from 'images/envScales/observerIcon.svg';
import templateIcon from 'images/envScales/templateIcon.svg';
import calendarIcon from 'images/envScales/calendarInProgressIcon.svg';
import ageLevelIcon from 'images/envScales/ageLevelIcon.svg';
import { getAgeLevel } from 'helpers/ageLevel';
import { useMediaQuery } from 'react-responsive';
import i18n from 'helpers/i18n';
import messages from './messages';

type FilterType = {
  age_level?: 'Inf' | 'Todd' | 'Pre-K' | '';
  assessment_template_id?: string;
  observer_guids?: string[];
  node_ids?: number[];
  start_date?: string;
  end_date?: string;
};

interface FilterCatalogProps {
  filters: FilterType;
  account?: SelectedAccount;
  onDelete: (filter: string) => void;
}

function FiltersCatalog({ account, filters, onDelete }: FilterCatalogProps) {
  const accountGuid = account === undefined ? '' : account.guid;
  const currentAccountId = account === undefined ? 0 : account.id;
  const { run: templatesRun, data: templatesData, ...templateReq } = useAsync();
  const { run: hierarchyRun, ...hierarchyReq } = useAsync();
  const { run: observersRun, data: observersData } = useAsync();
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const filterListMobile = [];
  const hasFiltersForMobile =
    filters.assessment_template_id !== '' ||
    filters.age_level !== '' ||
    filters.observer_guids?.length ||
    filters.node_ids?.length ||
    filters.start_date !== '' ||
    filters.end_date !== '';

  useEffect(() => {
    if (accountGuid !== '') {
      templatesRun(getAccountAssessmentTemplates(accountGuid));
    }

    hierarchyRun(getHierarchy(currentAccountId));
    observersRun(getAllObservers(currentAccountId));
  }, [currentAccountId, accountGuid, templatesRun, hierarchyRun, observersRun]);

  const templateName =
    templateReq.isSuccess &&
    templatesData &&
    filters.assessment_template_id &&
    templatesData.account_assessment_templates.find(
      (template: any) =>
        template.assessment_template.id === filters.assessment_template_id
    )?.assessment_template.name;

  templateName && filterListMobile.push(templateName!);

  const ageLevelName = filters.age_level && getAgeLevel(filters.age_level).name;
  ageLevelName && filterListMobile.push(ageLevelName!);

  const observers =
    observersData &&
    filters.observer_guids?.map(id => {
      const observer = observersData.find(
        (observer: any) => observer.guid === id
      );
      return observer ? observer.name : '';
    });

  const observersName = observers && observers.join(', ');
  observersName && filterListMobile.push(observersName!);

  const findHierarchyNames = useCallback(
    (data: any[], ids: number[], found: string[]) => {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];
        if (ids?.includes(record.id)) {
          found.push(record.name);
        }
        if (ids.length === found.length) {
          return found;
        }
        if (record.children) {
          findHierarchyNames(record.children, ids, found);
        } else {
          return found;
        }
      }
    },
    []
  );

  const hierarchy = useMemo(() => {
    if (
      hierarchyReq.isSuccess &&
      filters.node_ids?.length &&
      hierarchyReq.data
    ) {
      const found: string[] = [];
      const ids = filters.node_ids;
      const list = hierarchyReq.data.hierarchy.children;
      return findHierarchyNames(list, ids, found);
    }
    return [];
  }, [
    hierarchyReq.isSuccess,
    filters.node_ids,
    hierarchyReq.data,
    findHierarchyNames
  ]);

  const hierarchyName = hierarchy && hierarchy.join(', ');
  hierarchyName && filterListMobile.push(hierarchyName!);
  filters.start_date !== '' && filterListMobile.push(filters.start_date!);
  filters.end_date !== '' && filterListMobile.push(filters.end_date!);

  const numOfFilters = filterListMobile.length;

  return !isTablet ? (
    <div className="flex flex-wrap gap-2">
      {filters.assessment_template_id !== '' && (
        <FilterElement
          icon={templateIcon}
          name={templateName}
          onDelete={() => onDelete('assessment_template_id')}
        />
      )}
      {filters.age_level !== '' && (
        <FilterElement
          icon={ageLevelIcon}
          name={ageLevelName!}
          onDelete={() => onDelete('age_level')}
        />
      )}
      {filters.observer_guids?.length ? (
        <FilterElement
          icon={observerIcon}
          name={observersName!}
          onDelete={() => onDelete('observer_guids')}
        />
      ) : null}
      {filters.node_ids?.length ? (
        <FilterElement
          icon={centerIcon}
          name={hierarchyName!}
          onDelete={() => onDelete('node_ids')}
        />
      ) : null}
      {filters.start_date !== '' && filters.end_date !== '' ? (
        <FilterElement
          icon={calendarIcon}
          name={filters.start_date!}
          secondName={filters.end_date}
          onDelete={() => onDelete('end_date')}
        />
      ) : filters.start_date !== '' ? (
        <FilterElement
          icon={calendarIcon}
          name={filters.start_date!}
          onDelete={() => onDelete('start_date')}
        />
      ) : filters.end_date !== '' ? (
        <FilterElement
          icon={calendarIcon}
          name=""
          secondName={filters.end_date}
          onDelete={() => onDelete('end_date')}
        />
      ) : null}
    </div>
  ) : (
    <div className="flex">
      {hasFiltersForMobile && (
        <FilterElement
          name={`${i18n.ft(messages.filters)} (${numOfFilters})`}
          onDelete={() => onDelete('all')}
        />
      )}
    </div>
  );
}

export default FiltersCatalog;
