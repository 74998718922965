import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 515px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  div.ui.loader {
    position: relative;
    height: 2rem !important;
  }
`;
