import { useState } from 'react';

function createTimestamp() {
  return new Date().getTime();
}

function useTimestamp(): [number, () => void] {
  const [timestamp, setTimestamp] = useState(createTimestamp());

  function refreshTimestamp() {
    setTimestamp(createTimestamp());
  }

  return [timestamp, refreshTimestamp];
}

export default useTimestamp;
