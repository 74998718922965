import { useField } from 'formik';
import ErrorLabel from './ErrorLabel';
import { FormRowWrapper, FormInputWithLabel } from './Styled';
import { shouldDisplayError, shouldHideInput, BaseFieldProps } from './utils';

import {
  Select as BaseSelect,
  SelectProps as BaseProps,
  DropdownItemProps
} from 'semantic-ui-react';

type OptionsRequired = { options: DropdownItemProps[]; control?: undefined };
type ControlRequired = { control: any; options?: undefined };
type SelectFillOptions = OptionsRequired | ControlRequired;
type CustomSelectProp = Omit<BaseProps, 'options' | 'control'> &
  SelectFillOptions;
type SelectProps = BaseFieldProps<CustomSelectProp>;

function Select({
  name,
  validate,
  className,
  onChange,
  errorLabel = {},
  ...props
}: SelectProps) {
  const [field, meta, helpers] = useField({
    name,
    validate,
    multiple: props.multiple
  });

  const handleChange: BaseProps['onChange'] = (_event, data) => {
    helpers.setValue(data.value);
    onChange?.(data.value);
  };

  const handleBlur: BaseProps['onBlur'] = () => {
    helpers.setTouched(true);
  };

  const isError = shouldDisplayError(meta);
  const isHidden = shouldHideInput(props);
  const defaultValue = props.multiple ? [] : null;

  return (
    <FormRowWrapper className={className} hidden={isHidden}>
      <FormInputWithLabel
        {...field}
        {...props}
        value={meta.value || defaultValue}
        onChange={handleChange}
        onBlur={handleBlur}
        error={isError}
        aria-label={props['aria-label'] || props.label}
      />
      <ErrorLabel active={isError} content={meta.error} {...errorLabel} />
    </FormRowWrapper>
  );
}

Select.defaultProps = {
  control: BaseSelect
};

export default Select;
