import i18n from 'helpers/i18n';
import messages from './messages';

function ProgressBar({ percentage = 0 }) {
  return (
    <div className="grid grid-cols-8 gap-2">
      <div className="col-span-5">
        <div className="bg-[#D8D9D9] rounded-xl shadow-sm overflow-hidden mb-4">
          <div className="relative h-4 flex items-center justify-center">
            <div
              className={`absolute top-0 bottom-0 left-0 rounded-lg bg-[#077699]`}
              style={{ width: `${percentage}%` }}
            ></div>
          </div>
        </div>
      </div>

      <div className="col-span-3 text-right">
        <span className="font-bold">
          {`${percentage}% `} {i18n.ft(messages.percentage)}
        </span>
      </div>
    </div>
  );
}

export default ProgressBar;
