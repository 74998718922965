import * as R from 'ramda';
import * as Yup from 'yup';
import { useState } from 'react';
import styled from 'styled-components';
import i18n from 'helpers/i18n';
import messages from './messages';
import useCurrentUser from 'hooks/useCurrentUser';
import cohortRedeemingImage from 'images/cohort-token-redeeming.png';
import cohortMobileRedeemingImage from 'images/cohort-token-redeeming-mobile.png';
import StudentAgreementModal from './StudentAgreementModal';
import RouteHelpers from 'helpers/routes';
import { useLocationState } from 'hooks/useLocationState';
import { Formik, Form, Input, Select } from 'components/EnhancedForm';
import { Icon, Radio } from 'semantic-ui-react';
import {
  LearningPathway,
  learningPathwayFormatter,
  getInitialValues,
  yearOptions,
  monthOptions,
  dayOptions,
  FormValues
} from './utils';
import { useCountries } from 'components/dropdowns/Countries';
import { useStates } from 'components/dropdowns/States';
import { activateProductKey } from 'actions/productKeys';
import { useNavigate } from 'react-router-dom';
import { toastError } from 'components/Toast';

const StyledSelect = styled(Select)`
  .selection.dropdown {
    border-radius: 8px;
  }
`;

interface CohortLearningPathwayState {
  learning_pathway?: LearningPathway;
  available_learning_pathways?: LearningPathway[];
  learning_pathway_template_name: string;
  key: string;
}

const SCHEMA_VALIDATION = Yup.object({
  learning_pathway_id: Yup.number().required('Start Date is required'),
  age_level: Yup.string().required('Age Level is required'),
  birth_date: Yup.object({
    month: Yup.number().required('Month is required'),
    day: Yup.number().required('Day is required'),
    year: Yup.number().required('Year is required')
  }),
  shipping_address: Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    address_1: Yup.string().required('Address 1 is required'),
    address_2: Yup.string().nullable(),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State/Province is required'),
    city: Yup.string().required('City is required'),
    zip: Yup.string().required('Postal Code is required')
  }),
  student_agreement: Yup.boolean()
    .required()
    .test('is-true', 'Required', value => value === true)
});

const LearningPathwayProductKey = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const states = useStates();
  const countries = useCountries();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const {
    learning_pathway,
    available_learning_pathways,
    learning_pathway_template_name,
    key
  } = useLocationState<CohortLearningPathwayState>();

  const isLearningPathwayPresent = learning_pathway !== undefined;
  const learningPathwayOptions = learning_pathway
    ? [learningPathwayFormatter(learning_pathway)]
    : R.map(learningPathwayFormatter, available_learning_pathways || []);

  function learningPathwayEndDate(learning_pathway_id?: number) {
    let learningPathway;

    if (isLearningPathwayPresent) {
      learningPathway = learning_pathway;
    } else {
      learningPathway = R.find(
        R.propEq('id', learning_pathway_id),
        available_learning_pathways || []
      );
    }

    return learningPathway?.end_date;
  }

  function handleSubmit({ birth_date, ...values }: FormValues) {
    const submitValues = {
      ...values,
      key,
      birth_date: `${birth_date?.year}-${birth_date?.month}-${birth_date?.day}`
    };

    activateProductKey(submitValues)
      .then(_response => {
        const state = {
          courseType: 'LearningPathway',
          enrollments: [
            {
              course: learning_pathway_template_name,
              endDate: learningPathwayEndDate(submitValues.learning_pathway_id)
            }
          ]
        };

        navigate(RouteHelpers.getPath('token-confirmation'), { state });
      })
      .catch(({ response }) =>
        toastError({ description: response.data.errors_sentence })
      );
  }

  return (
    <div className="flex justify-center p-5 xl:gap-5">
      <img
        src={cohortRedeemingImage}
        alt={i18n.ft(messages.imgAlts.desktop)}
        className="hidden xl:block"
      />

      <div className="flex flex-col items-start">
        <h2 className="text-neutral-700">{learning_pathway_template_name}</h2>

        {isLearningPathwayPresent && (
          <p className="text-base">
            {i18n.ft(messages.startDateOn)} {learning_pathway.start_date}
          </p>
        )}

        <img
          src={cohortMobileRedeemingImage}
          alt={i18n.ft(messages.imgAlts.mobile)}
          className="xl:hidden mb-2"
        />

        <Formik
          initialValues={getInitialValues(currentUser, learning_pathway)}
          validationSchema={SCHEMA_VALIDATION}
          onSubmit={handleSubmit}
        >
          {({ values, dirty, isValid, setFieldValue }) => (
            <Form>
              <div className="form-section rounded-lg px-5 py-4 bg-white mb-3">
                {!isLearningPathwayPresent && (
                  <StyledSelect
                    name="learning_pathway_id"
                    label={i18n.ft(messages.startDate)}
                    options={learningPathwayOptions}
                    placeholder="Choose a date"
                  />
                )}

                <div className="birth-date-field">
                  <label className="font-bold text-[15px] text-neutral-900">
                    {i18n.ft(messages.dateOfBirth)}
                  </label>
                  <div className="flex flex-wrap md:flex-nowrap md:gap-5">
                    <StyledSelect
                      className="grow md:flex-1"
                      name="birth_date[month]"
                      placeholder={i18n.ft(messages.month)}
                      options={monthOptions}
                      search
                    />

                    <StyledSelect
                      className="grow md:flex-1"
                      name="birth_date[day]"
                      placeholder={i18n.ft(messages.day)}
                      options={dayOptions}
                      search
                    />

                    <StyledSelect
                      className="grow md:flex-1"
                      name="birth_date[year]"
                      placeholder={i18n.ft(messages.year)}
                      options={yearOptions()}
                      search
                    />
                  </div>
                </div>

                <label className="font-bold text-[15px] text-neutral-900">
                  {i18n.ft(messages.classroomAge)}
                </label>
                <div className="form-section age-level-section flex justify-start gap-10">
                  <Radio
                    label={i18n.ft(messages.infantToddler)}
                    name="age_level"
                    value="Infant/Toddler"
                    checked={
                      values.age_level === i18n.ft(messages.infantToddler)
                    }
                    onChange={() =>
                      setFieldValue(
                        'age_level',
                        i18n.ft(messages.infantToddler)
                      )
                    }
                  />

                  <Radio
                    label={i18n.ft(messages.preK)}
                    name="age_level"
                    value="Pre-K"
                    checked={values.age_level === i18n.ft(messages.preK)}
                    onChange={() =>
                      setFieldValue('age_level', i18n.ft(messages.preK))
                    }
                  />
                </div>
              </div>

              <div className="form-section rounded-lg px-5 py-4 bg-white mb-3">
                <h3 className="text-neutral-700">
                  {i18n.ft(messages.shippingAddress)}
                </h3>

                <div className="flex flex-wrap mt-5 md:gap-24">
                  <Input
                    name="shipping_address[first_name]"
                    label={i18n.ft(messages.firstName)}
                    className="grow min-w-[300px]"
                  />

                  <Input
                    name="shipping_address[last_name]"
                    label={i18n.ft(messages.lastName)}
                    className="grow min-w-[300px]"
                  />
                </div>

                <Input
                  name="shipping_address[address_1]"
                  label={i18n.ft(messages.address1)}
                />

                <div className="flex justify-between">
                  <label className="font-bold text-[15px] text-neutral-900">
                    {i18n.ft(messages.address2)}
                  </label>
                  <span className="text-grey">
                    ({i18n.ft(messages.optional)})
                  </span>
                </div>
                <Input name="shipping_address[address_2]" />

                <div className="grid md:grid-cols-2 md:gap-24">
                  <StyledSelect
                    name="shipping_address[country]"
                    label={i18n.ft(messages.country)}
                    options={countries}
                    search
                    upward
                  />

                  {values?.shipping_address?.country === 'US' ? (
                    <StyledSelect
                      name="shipping_address[state]"
                      label={i18n.ft(messages.region)}
                      options={states}
                      search
                      upward
                    />
                  ) : (
                    <Input
                      name="shipping_address[state]"
                      label={i18n.ft(messages.region)}
                    />
                  )}
                </div>

                <div className="grid md:grid-cols-2 md:gap-24">
                  <Input
                    name="shipping_address[city]"
                    label={i18n.ft(messages.locality)}
                  />

                  <Input
                    name="shipping_address[zip]"
                    label={i18n.ft(messages.postalCode)}
                  />
                </div>
              </div>

              <div className="form-section rounded-lg px-5 py-4 bg-white mb-3">
                <div className="flex justify-between flex-col gap-5 md:flex-row md:mb-5">
                  <div
                    className="flex justify-start gap-3 cursor-pointer"
                    onClick={() => setModalOpen(true)}
                  >
                    <div className="flex justify-center items-center rounded text-white bg-orange-500/[.80] h-12 w-12 pb-1 pl-1">
                      <Icon name="paint brush" />
                    </div>

                    <p className="pt-2 text-lg font-semibold">
                      {i18n.ft(messages.signStudentAgreement)}
                    </p>
                  </div>

                  {values.student_agreement && (
                    <div className="flex justify-center pt-2 pr-5">
                      <p>({i18n.ft(messages.successfullySigned)})</p>

                      <Icon
                        size="large"
                        name="check circle"
                        className="text-emerald-700"
                      />
                    </div>
                  )}
                </div>

                <StudentAgreementModal
                  open={modalOpen}
                  setOpen={setModalOpen}
                  templateName={learning_pathway_template_name}
                />

                <div className="flex justify-center md:justify-end">
                  <button
                    className="rounded h-12 bg-sky-800 w-full md:w-64 text-white font-bold disabled:mt-10 disabled:bg-neutral-200 disabled:text-neutral-400"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    {i18n.ft(messages.continue)}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LearningPathwayProductKey;
