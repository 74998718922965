import styled from 'styled-components';

const size = {
  mobileS: '320px',
  mobileL: '640px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px'
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktopL: `(min-width: ${size.laptopL})`
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleLabel = styled.h1`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;

  @media ${device.mobileL} {
    font-size: 30px;
  }
`;

export const Description = styled.div`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 32px;
  max-width: 500px;

  @media ${device.mobileL} {
    font-size: 20px;
  }
`;

export const FocusDescription = styled.div`
  text-align: center;
  max-width: 400px;
  display: grid;
  grid-gap: 18px;
`;

export const FocusItem = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 28px;

  @media ${device.mobileL} {
    font-size: 20px;
  }
`;

export const Items = styled.div`
  display: grid;
  grid-gap: 12px;
`;

export const Item = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  text-align: center;

  @media ${device.mobileL} {
    font-size: 20px;
  }
`;

export const Image = styled.div`
  margin-top: 48px;
`;

export const StepImage = styled.img`
  width: 100%;
  max-width: 200px;

  @media ${device.mobileL} {
    max-width: 300px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  margin-top: 48px;
`;
