import i18n from 'helpers/i18n';
import messages from './messages';
import { toTitleCase } from 'helpers/text';

import {
  PARTICIPANT_STATUS,
  PAYMENT_STATUS
} from 'types/api/TrainingParticipant';

export function getStatusLocalized(status: PARTICIPANT_STATUS): string {
  switch (status) {
    case 'pending':
      return i18n.ft(messages.pending);
    case 'invited':
      return i18n.ft(messages.invited);
    case 'registered':
      return i18n.ft(messages.registered);
    case 'completed':
      return i18n.ft(messages.completed);
    case 'declined':
      return i18n.ft(messages.declined);
    case 'canceled':
      return i18n.ft(messages.canceled);
    default:
      return toTitleCase(status);
  }
}

export function getPaymentStatusLocalized(status: PAYMENT_STATUS): string {
  switch (status) {
    case 'paid':
      return i18n.ft(messages.paid);
    case 'payment_pending':
      return i18n.ft(messages.paymentNeeded);
    case 'payment_requested':
      return i18n.ft(messages.paymentRequested);
    default:
      return toTitleCase(status);
  }
}
