import * as R from 'ramda';
import routes from 'constants/routes';

type ROUTE_NAMES = typeof routes[number]['name'];

type RouteOptions = {
  [key: string]: any;
};

function getPath(name: ROUTE_NAMES, options: RouteOptions = {}) {
  const route = routes.find(r => r.name === name);
  let path = String(R.clone(route?.path));

  for (let prop in options) {
    path = R.replace(`:${prop}`, String(options[prop]), path);
  }

  return path;
}

function isActiveRoute(name: ROUTE_NAMES, routeURL: string) {
  const routeA = getPath(name).split('/');
  const routeB = routeURL.split('/');

  if (routeA.length !== routeB.length) {
    return false;
  }

  for (let idx = 0, length = routeA.length; idx < length; idx++) {
    if (R.test(/^:/, routeA[idx]) && parseInt(routeB[idx]) > 0) {
      continue;
    }
    if (routeA[idx] !== routeB[idx]) {
      return false;
    }
  }

  return true;
}

const RoutesHelper = {
  /**
   * Returns the route path for the given route name
   *
   * @param {String} name
   * @param {Object} options
   * @method getPath
   * @return {String}
   */
  getPath,

  /**
   * Determines whether the route corresponding to the given name
   * matches the url given in the second argument
   */
  isActiveRoute
};

export default RoutesHelper;
