interface TitleProps {
  children: string;
}

function Title({ children }: TitleProps) {
  return (
    <h1 className="flex font-sans font-black text-xl pt-8 pb-6 sm:pb-8 sm:pt-10 sm:text-2xl md:text-3xl lg:text-4xl 2xl:pt-14">
      {children}
    </h1>
  );
}

export default Title;
