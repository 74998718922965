import {
  ModalButton,
  ModalHeader,
  ModalAction,
  ModalIcon,
  CancelModalButton
} from './Styled';
import { useMediaQuery } from 'react-responsive';
import submitIcon from 'images/reliabilityTest/submitIcon.svg';
import {
  Loader,
  Modal,
  ModalProps,
  SemanticShorthandContent
} from 'semantic-ui-react';

interface ConfirmationModalProps extends ModalProps {
  /**Title for the modal */
  title: string | SemanticShorthandContent;

  /**Content message for the modal */
  message: SemanticShorthandContent;

  /**Text associated with the text button to cancel the modal action */
  dismissButtonText: string;

  /**Text associated with the text button to complete the modal action */
  confirmButtonText: string;

  /** Node associate with the trigger of the modal */
  triggerNode?: React.ReactNode;

  /**Function call on cofirmation */
  onConfirm: () => void;

  /**Function call on cancel */
  onCancel: () => void;

  /**true when modal is open */
  isOpen: boolean;

  /**To display at the top of the Modal */
  modalIcon?: string;

  /**To display loading state */
  isLoading?: boolean;
}

function ConfirmationModal({
  title,
  message,
  dismissButtonText,
  confirmButtonText,
  triggerNode,
  onConfirm,
  onCancel,
  onClose,
  isOpen,
  modalIcon,
  isLoading
}: ConfirmationModalProps) {
  const isTablet = useMediaQuery({ minWidth: 640 });

  const close = () => {
    onCancel();
  };

  function handleConfirmation() {
    onConfirm();
  }

  return (
    <Modal
      trigger={triggerNode}
      open={isOpen}
      onClose={onClose}
      closeOnDimmerClick
      size="small"
      className="px-8 md:px-19"
      style={{
        borderRadius: '17px',
        width: !isTablet ? 'inherit' : '642px'
      }}
      dimmer={{ style: { backgroundColor: 'rgba(0,0,0,0.63)' } }}
    >
      <div className="grid place-content-center mb-8">
        <ModalIcon src={modalIcon ? modalIcon : submitIcon} className="w-20" />
      </div>
      <ModalHeader content={title} className="w-full text-center" />
      <Modal.Content content={message} />
      <ModalAction className="grid grid-cols-2 gap-4">
        <CancelModalButton
          type="button"
          onClick={close}
          content={dismissButtonText}
          className="w-full mb-4"
        />
        <ModalButton
          type="button"
          onClick={handleConfirmation}
          content={
            isLoading ? (
              <Loader active size="tiny" inline="centered" />
            ) : (
              confirmButtonText
            )
          }
          className="w-full mb-4"
          disabled={isLoading}
        />
      </ModalAction>
    </Modal>
  );
}

export default ConfirmationModal;
