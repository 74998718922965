const PATH = 'fe.components.trainings.view.attendance.attendance_card';

const messages = {
  present: {
    key: `${PATH}.present`,
    defaultValue: 'Present'
  },
  absent: {
    key: `${PATH}.absent`,
    defaultValue: 'Absent'
  },
  notRegistered: {
    key: `${PATH}.not_registered`,
    defaultValue: 'Not Registered'
  }
};

export default messages;
