const PATH = 'fe.components.learning_resources.important';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Marked as Important'
  },
  all: {
    key: `${PATH}.all`,
    defaultValue: 'See All'
  }
};

export default messages;
