import axios from 'axios';

type UserAnswer = {
  coding_video_id: number;
  dimension_id: number;
  answer: number;
  id?: number;
};

type KnowledgeTestUserAnswer = {
  question_id: number;
  question_answer_id: number;
};

export function createUserAnswers(
  testAttemptId: string,
  userAnswer: UserAnswer | KnowledgeTestUserAnswer
) {
  return axios.post(`/api/v2/test_attempts/${testAttemptId}/user_answers`, {
    user_answer: { ...userAnswer }
  });
}

export function updateUserAnswers(
  testAttemptId: string,
  userAnswer: UserAnswer
) {
  return axios.put(
    `/api/v2/test_attempts/${testAttemptId}/user_answers/${userAnswer.id}`,
    { user_answer: { ...userAnswer } }
  );
}

export function deleteUserAnswers(testAttemptId: string, userAnswerId: number) {
  return axios.delete(
    `/api/v2/test_attempts/${testAttemptId}/user_answers/${userAnswerId}`
  );
}
