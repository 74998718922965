const PATH = 'fe.pages.token_confirmation';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Success!'
  },
  enrolledIn: {
    key: `${PATH}.enrolled_in`,
    defaultValue: 'You are being enrolled in:'
  },
  courseAndDate: {
    key: `${PATH}.course_and_date`,
    defaultValue: '{{course}} until {{date}}'
  },
  totaraConfirmation: {
    key: `${PATH}.confirmation`,
    defaultValue:
      'Once enrollment is complete, you will see your test in My Courses.'
  },
  canvasConfirmation: {
    key: `${PATH}.canvas_confirmation`,
    defaultValue:
      'Once enrollment is complete, you will see your course in Learning Hub.'
  },
  totaraGoTo: {
    key: `${PATH}.go_to`,
    defaultValue: 'Go to My Courses'
  },
  canvasGoTo: {
    key: `${PATH}.canvas_go_to`,
    defaultValue: 'Go to Learning Hub'
  },
  altCourseName: {
    key: `${PATH}.alt_course_name`,
    defaultValue: 'Recertification test'
  }
};

export default messages;
