import { RenewButton } from './Styled';
import { Button } from 'semantic-ui-react';

interface ShowRenewButtonProps {
  renewalURL?: string;
  buttonText: string;
  renewable: boolean | null;
  transitionedTo2ndEdition?: boolean;
}

function ShowRenewButton({
  renewalURL,
  buttonText,
  renewable,
  transitionedTo2ndEdition
}: ShowRenewButtonProps) {
  if (transitionedTo2ndEdition) {
    return null;
  }

  if (renewable)
    return (
      <RenewButton>
        <Button as="a" role="link" href={renewalURL} fluid color="blue">
          {buttonText}
        </Button>
      </RenewButton>
    );

  return null;
}

export default ShowRenewButton;
