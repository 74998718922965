const PATH = 'fe.components.app.header.user_menu';

const messages = {
  settings: {
    key: `${PATH}.settings`,
    defaultValue: 'User Settings'
  },
  logout: {
    key: `${PATH}.logout`,
    defaultValue: 'Log Out'
  }
};

export default messages;
