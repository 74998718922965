import axios from 'axios';

export function getObservers(
  accountId: number,
  takenAt: string,
  ageLevel: string
) {
  return axios.get(
    `/api/v2/accounts/${accountId}/observers?age_level=${ageLevel}&taken_at=${takenAt}`
  );
}

export function getAllObservers(accountId: number) {
  return axios.get(`/api/v2/accounts/${accountId}/observers/all`);
}
