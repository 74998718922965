import { useFormikContext } from 'formik';
import { Category } from 'types/api/envScales/AssessmentScore';

type FormValues = {
  [key: number]: boolean | null;
};

function getScaleIds(categories: Category[]) {
  return categories.flatMap(category =>
    category.items.map(item => item.scale_id)
  );
}

export function useDomainStatus(categories: Category[]) {
  const scaleIds = getScaleIds(categories);
  const formik = useFormikContext<FormValues>();

  const anyScaleWithErrors = scaleIds.some(
    scaleId => formik.touched[scaleId] && formik.errors[scaleId]
  );

  if (anyScaleWithErrors) {
    return 'error';
  }

  const allScalesCompleted = scaleIds.every(scaleId => formik.values[scaleId]);

  if (allScalesCompleted) {
    return 'completed';
  }

  return 'pending';
}
