import i18n from 'helpers/i18n';
import messages from './messages';
import React, { useState } from 'react';
import { Button, Modal, ModalProps } from 'semantic-ui-react';
import { inviteTrainingParticipant } from 'actions/trainings';
import { toastError } from 'components/Toast';

interface ReInviteDeclinedModalProps extends ModalProps {
  /** Callback fired when the modal requests to be closed. */
  onClose: () => void;

  /** Callback fired after the participants are canceled.  */
  afterReinviteDeclined: () => void;

  /** Array of participant Ids to be mark as canceled. */
  participantIds: number[];

  /** Training ID. */
  trainingId: number;
}

const ReinviteDeclinedModal: React.FC<ReInviteDeclinedModalProps> = ({
  trainingId,
  afterReinviteDeclined,
  participantIds,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  function handleReinvite() {
    setLoading(true);
    inviteTrainingParticipant(trainingId, { participant_ids: participantIds })
      .then(() => {
        setLoading(false);
        afterReinviteDeclined();
      })
      .catch(res => {
        const title = res.response.data.title;
        const errors = Object.values(res.response.data.errors).join('\n');

        toastError({ title, description: errors });
        setLoading(false);
      });
  }

  return (
    <Modal {...props}>
      <Modal.Content>{i18n.ft(messages.areYouSure)}</Modal.Content>
      <Modal.Actions>
        <Button
          disabled={loading}
          onClick={props.onClose}
          content={i18n.ft(messages.dismiss)}
        />
        <Button
          color="green"
          loading={loading}
          disabled={loading}
          onClick={handleReinvite}
          content={i18n.ft(messages.confirm)}
        />
      </Modal.Actions>
    </Modal>
  );
};

ReinviteDeclinedModal.defaultProps = {
  size: 'small'
};

export default ReinviteDeclinedModal;
