import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

type GetRolesResponse = {
  roles: { id: number; name: string; zone: string }[];
};

export function getRoles(params = {}) {
  return axios.get<GetRolesResponse>('/api/v2/roles', { params });
}

export function useGetRoles(params = {}) {
  return useQuery({
    queryKey: ['roles', params],
    queryFn: () => getRoles(params).then(res => res.data)
  });
}

export function getUserRoles(id: number) {
  return axios.get(`/api/v2/users/${id}/roles`);
}

export function deleteUserRole(userId: number, roleId: number) {
  return axios.delete(`/api/v2/users/${userId}/roles/${roleId}`);
}

export function addUserRole(userId: number, roleId: number) {
  return axios.post(`/api/v2/users/${userId}/roles`, {
    user_role: {
      user_id: userId,
      role_id: roleId
    }
  });
}
