import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';

const Wrapper = styled.div`
  margin: 64px auto;
`;

/**
 * A wrapper around the Semantic-UI [Loader](https://semantic-ui.com/elements/loader.html) component
 *  (adds vertical margins)
 */
function PageLoader() {
  return (
    <Wrapper>
      <Loader active inline="centered" />
    </Wrapper>
  );
}

export default PageLoader;
