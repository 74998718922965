const PATH = 'fe.components.login_manager.login_lookup';

const messages = {
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email or Phone'
  },
  emailPlaceholder: {
    key: `${PATH}.email_placeholder`,
    defaultValue: 'Enter Email or Phone'
  },
  errors: {
    email: {
      required: {
        key: `${PATH}.errors.email.required`,
        defaultValue: 'Enter your email address or phone number'
      }
    },
    tryAgainIn: {
      key: `${PATH}.errors.try_again_in`,
      defaultValue: 'Too many attempts. Please wait {{time}} to try again.'
    }
  },
  continue: {
    key: `${PATH}.continue`,
    defaultValue: 'Continue'
  }
};

export default messages;
