const PATH = 'fe.pages.account.phone';

const messages = {
  phoneLogin: {
    key: `${PATH}.phone_login`,
    defaultValue: 'Mobile Phone Login'
  },
  description: {
    key: `${PATH}.description`,
    defaultValue:
      'Add your phone so you can log in with a single-use code we text you. You can log in even if you forget your email address or password.'
  },
  terms: {
    key: `${PATH}.terms`,
    defaultValue:
      'By enrolling you consent to receive automated text messages from Teachstone related to your account. Standard message and data rates may apply.'
  },
  learnMore: {
    key: `${PATH}.learn_more`,
    defaultValue: 'Learn More'
  },
  success: {
    key: `${PATH}.success`,
    defaultValue: 'Your phone number was successfully verified.'
  }
};

export default messages;
