import { AGE_LEVEL_GROUP_ABBREVIATIONS } from 'types/api/AgeLevelGroup';

type AGE_LEVEL_GROUP_SHADES = 'base' | 100;

type AgeLevelMetadata = {
  colors: {
    base: string;
    100: string;
  };
};

type AgeLevelGroupData = {
  [key: string]: AgeLevelMetadata;
};

const AGE_LEVEL_GROUPS: AgeLevelGroupData = {
  an: {
    colors: {
      base: 'gray',
      100: 'lightgray'
    }
  },
  i: {
    colors: {
      base: '#77923B',
      100: '#E9FBC1'
    }
  },
  it2: {
    colors: {
      base: '#77923B',
      100: '#E9FBC1'
    }
  },
  itp: {
    colors: {
      base: '#77923B',
      100: '#E9FBC1'
    }
  },
  itpkk3: {
    colors: {
      base: '#77923B',
      100: '#77923B'
    }
  },
  t: {
    colors: {
      base: '#8C0B40',
      100: '#FDC4DC'
    }
  },
  pk: {
    colors: {
      base: '#0079C1',
      100: '#B3E3FF'
    }
  },
  pkk: {
    colors: {
      base: '#0079C1',
      100: '#B3E3FF'
    }
  },
  pkk3: {
    colors: {
      base: '#FFFFFF',
      100: '#FFFFFF'
    }
  },
  k3: {
    colors: {
      base: '#FFD615',
      100: '#FFF3BD'
    }
  },
  ue: {
    colors: {
      base: '#006C67',
      100: '#C5FCFB'
    }
  },
  ue2: {
    colors: {
      base: '#006C67',
      100: '#C5FCFB'
    }
  },
  sec: {
    colors: {
      base: '#F47B20',
      100: '#FED4B3'
    }
  }
};

const NOT_FOUND_AGE_LEVEL = {
  colors: {
    base: 'gray',
    100: 'lightgray'
  }
};

export function getAgeLevelGroup(
  abbreviation: AGE_LEVEL_GROUP_ABBREVIATIONS
): AgeLevelMetadata {
  const value = abbreviation.toLowerCase();

  switch (value) {
    case 'an':
    case 'i':
    case 'it2':
    case 'itp':
    case 'itpkk3':
    case 't':
    case 'pk':
    case 'pkk':
    case 'pkk3':
    case 'k3':
    case 'ue':
    case 'ue2':
    case 'sec':
      return AGE_LEVEL_GROUPS[value];
    default:
      return NOT_FOUND_AGE_LEVEL;
  }
}

export function getShadeColor(
  abbreviation: AGE_LEVEL_GROUP_ABBREVIATIONS,
  shade: AGE_LEVEL_GROUP_SHADES
): string {
  const ageLevelGroup = getAgeLevelGroup(abbreviation);
  return ageLevelGroup.colors[shade];
}
