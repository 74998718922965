const PATH = 'fe.components.learning_resources.video_resource';

const messages = {
  recommend: {
    key: `${PATH}.recommend`,
    defaultValue: 'Recommend'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'Resource already selected'
  }
};

export default messages;
