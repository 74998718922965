const PATH = 'fe.components.trainings.training_card.training_status';

const messages = {
  active: {
    key: `${PATH}.active`,
    defaultValue: 'Active'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed'
  },
  canceled: {
    key: `${PATH}.canceled`,
    defaultValue: 'Canceled'
  },
  download: {
    key: `${PATH}.download`,
    defaultValue: 'Download Certificate'
  }
};

export default messages;
