import styled from 'styled-components';

export const Title = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
`;

export const Label = styled.label`
  background-color: #fff7df;
  padding: 2px 12px;
  font-weight: 600;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 8px;
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 24px;

  @media screen and (min-width: 1024px) {
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-family: inherit;
  padding: 12px 14px;
  display: block;

  &:focus {
    outline: none;
    border-color: #96c8da;
  }
`;

export function StrengthIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3584 7H0.858398C0.44418 7 0.108398 7.33578 0.108398 7.75V15.25C0.108398 15.6642 0.44418 16 0.858398 16H3.3584C3.77262 16 4.1084 15.6642 4.1084 15.25V7.75C4.1084 7.33578 3.77262 7 3.3584 7ZM2.1084 14.75C1.69418 14.75 1.3584 14.4142 1.3584 14C1.3584 13.5858 1.69418 13.25 2.1084 13.25C2.52262 13.25 2.8584 13.5858 2.8584 14C2.8584 14.4142 2.52262 14.75 2.1084 14.75ZM12.1084 2.54538C12.1084 3.87088 11.2968 4.61438 11.0685 5.5H14.2473C15.291 5.5 16.1035 6.36706 16.1084 7.31556C16.111 7.87613 15.8726 8.47959 15.5009 8.85297L15.4975 8.85641C15.8048 9.58569 15.7549 10.6076 15.2066 11.3398C15.4779 12.149 15.2044 13.1431 14.6946 13.676C14.829 14.2259 14.7648 14.6939 14.5025 15.0707C13.8647 15.9871 12.2839 16 10.9471 16L10.8582 16C9.34924 15.9994 8.11427 15.45 7.12196 15.0086C6.6233 14.7867 5.9713 14.5121 5.47662 14.503C5.27224 14.4993 5.1084 14.3325 5.1084 14.1281V7.44775C5.1084 7.34775 5.14846 7.25178 5.21959 7.18147C6.45752 5.95822 6.98984 4.66313 8.00449 3.64675C8.46712 3.18325 8.63537 2.48312 8.79802 1.80606C8.93699 1.22791 9.22768 0 9.8584 0C10.6084 0 12.1084 0.25 12.1084 2.54538Z"
        fill="#017EA7"
      />
    </svg>
  );
}

export function GrowthIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99962 2H0C0 5.86562 3.13379 9 6.99868 9V13.5C6.99868 13.775 7.22364 14 7.49859 14H8.4984C8.77335 14 8.99831 13.775 8.99831 13.5V9C8.99831 5.13438 5.86452 2 1.99962 2ZM13.9974 0C11.3666 0 9.07954 1.45312 7.88289 3.6C8.74835 4.54375 9.38886 5.69063 9.7263 6.9625C13.2475 6.59688 15.997 3.62188 15.997 0H13.9974Z"
        fill="#017EA7"
      />
    </svg>
  );
}
