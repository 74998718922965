import i18n from 'helpers/i18n';
import settings from 'helpers/settings';
import messages from './messages';
import { MessageBox, ListItemContent, ListItem } from './Styled';
import nextStepsIcon from 'images/reliabilityTest/nextSteps.svg';

function TrainerViewMessage() {
  const reliabilitySupport = `${settings.lookup('REACT_APP_TS_RELIABILITY_SUPPORT')}`;

  return (
    <MessageBox className="PrintBackgroundColor PrintMargin">
      <div className="flex justify-center mt-4 p-4 md:px-16 PrintMargin PrintPaddingBottom PrintPaddingTop">
        <div className="align-middle pt-4">
          <div className="flex">
            <img src={nextStepsIcon} alt="" className="self-start mr-4" />
            <span className="font-bold mb-4 PrintMargin">
              {' ' + i18n.ft(messages.nextSteps)}
            </span>
          </div>
          <div className="mb-4 PrintMargin">
            <div className="ml-10">{i18n.ft(messages.suggestions)}</div>
            <ul className="mt-6 list-disc PrintMargin">
              <ListItem className="PrintMargin">
                <ListItemContent>
                  {i18n.ft(messages.certification)}
                </ListItemContent>
              </ListItem>
              <ListItem className="PrintMargin">
                <ListItemContent>
                  {i18n.ft(messages.attemptOne) + ' '}
                  <a className="font-bold" href={reliabilitySupport}>
                    {i18n.ft(messages.support) + ' '}
                  </a>
                  {i18n.ft(messages.resources)}
                </ListItemContent>
              </ListItem>
              <ListItem className="PrintMargin">
                <ListItemContent>
                  {i18n.ft(messages.attemptTwo) + ' '}
                  <a className="font-bold" href={reliabilitySupport}>
                    {i18n.ft(messages.schedule) + ' '}
                  </a>
                  {i18n.ft(messages.call)}
                </ListItemContent>
              </ListItem>
              <ListItem className="PrintMargin">
                <ListItemContent>
                  {i18n.ft(messages.attemptThree)}
                </ListItemContent>
              </ListItem>
            </ul>
          </div>
        </div>
      </div>
    </MessageBox>
  );
}

export default TrainerViewMessage;
