import i18n from 'helpers/i18n';
import messages from './messages';

interface PassMessageProps {
  title: string;
  message?: string;
}

function NotPassMessage({ title, message }: PassMessageProps) {
  return (
    <div className="mx-5 text-center mb-8">
      <span
        dangerouslySetInnerHTML={{
          __html: message ? message : `${i18n.ft(messages.message)}`
        }}
      />
      {` ${title}`}.
    </div>
  );
}

export default NotPassMessage;
