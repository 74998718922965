const PATH = 'fe.components.trainings.view.participants.toolbar';

const messages = {
  participants: {
    key: `${PATH}.participants`,
    defaultValue: 'Participants'
  },
  status: {
    key: `${PATH}.status`,
    defaultValue: 'Registration'
  },
  paymentStatus: {
    key: `${PATH}.payment_status`,
    defaultValue: 'Payment Status'
  },
  selected: {
    key: `${PATH}.selected`,
    defaultValue: '({{number}} selected)'
  }
};

export default messages;
