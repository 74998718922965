const PATH = 'fe.components.phone.mobile_form';

const messages = {
  sendCode: {
    key: `${PATH}.send_code`,
    defaultValue: 'Send SMS verification code'
  },
  resendCode: {
    key: `${PATH}.resend_code`,
    defaultValue: 'Resend verification code'
  },
  isVerified: {
    key: `${PATH}.is_verified`,
    defaultValue: 'Your mobile phone number is verified'
  },
  verified: {
    key: `${PATH}.verified`,
    defaultValue: 'Verified'
  },
  notVerified: {
    key: `${PATH}.not_verified`,
    defaultValue: 'Please verify your phone number to enable phone login'
  },
  fields: {
    phoneNumber: {
      key: `${PATH}.fields.phone_number`,
      defaultValue: 'Mobile Phone Number'
    }
  },
  errors: {
    phoneNumber: {
      required: {
        key: `${PATH}.errors.phone_number.required`,
        defaultValue: 'Phone number is required'
      },
      invalid: {
        key: `${PATH}.errors.phone_number.invalid`,
        defaultValue:
          'Not a valid phone number. Verify the country code and phone number.'
      }
    }
  }
};

export default messages;
