import { CertificationItem } from 'types/api/CertificationItemProductKey';

const ageLevelGroups = [
  'Infant',
  'Toddler',
  'Pre-K + K-3',
  'Pre-K–3rd',
  'Pre-K',
  'K-3',
  'Upper Elementary',
  'Secondary'
];

export const ageLevelsOrder = [
  'CLASS 2008 Infant',
  'CLASS 2008 Toddler',
  'CLASS 2008 Pre-K',
  'CLASS 2nd Edition Pre-K',
  'CLASS 2nd Edition Pre-K–3rd',
  'CLASS 2008 K-3',
  'CLASS 2008 Upper Elementary',
  'CLASS 2008 Secondary'
];

export function formatCertification(selectedCertification: CertificationItem) {
  if (
    Array.isArray(selectedCertification.version) &&
    selectedCertification.multiple_certifications
  ) {
    const certifications = selectedCertification.version.map(
      (_: any, index: number) => {
        return (
          <div key={index}>
            {`${selectedCertification.version[index]} `}
            <span className="font-semibold whitespace-nowrap">
              {selectedCertification.age_level_group[index]}
            </span>
            <div className="text-base" style={{ color: '#5C5C5C' }}>
              {`${selectedCertification.group.split('and')[index]}`}
            </div>
            {index === 0 ? <div>{'+'}</div> : <></>}
          </div>
        );
      }
    );
    return certifications;
  } else {
    return (
      <>
        {`${selectedCertification.version} `}
        <span className="font-semibold whitespace-nowrap">
          {selectedCertification.age_level_group}
        </span>
        <div className="text-base" style={{ color: '#5C5C5C' }}>
          {selectedCertification.group}
        </div>
      </>
    );
  }
}

export function getSingleAgeLevelGroup(name: string) {
  const ageLevelGroup = ageLevelGroups.find(group => name.includes(group));

  if (!ageLevelGroup) {
    return '';
  }

  return ageLevelGroup;
}

/** We can have more than one certification
 * for example: CLASS 2008 Pre-K and CLASS 2nd Edition Pre-K-3rd
 * returns: ['CLASS 2008', 'CLASS 2nd Edition']
 */
export function getVersions(name: string) {
  if (name.includes('and')) {
    const versions = name.split('and');
    versions.forEach((item: string, index: number) => {
      versions[index] = item.replace(getSingleAgeLevelGroup(item), '').trim();
    });

    return versions;
  }

  return name.replace(getSingleAgeLevelGroup(name), '').trim();
}

/** We can have more than one certification
 * for example: CLASS 2008 Pre-K and CLASS 2nd Edition Pre-K-3rd
 * returns: ['Pre-K', 'Pre-K-3rd']
 */
export function getAgeLevelGroup(name: string) {
  if (name.includes('and')) {
    let ageLevelGroupArray: string[] = [];
    name.split('and').forEach((item: string) => {
      const ageLevelGroup = ageLevelGroups.find(ageLevelGroup =>
        item.includes(ageLevelGroup)
      );
      if (ageLevelGroup) ageLevelGroupArray.push(ageLevelGroup);
    });

    return ageLevelGroupArray || '';
  }

  const ageLevelGroup = ageLevelGroups.find(ageLevelGroup =>
    name.includes(ageLevelGroup)
  );

  return ageLevelGroup || '';
}

export function getAgeLevelIndex(certification: CertificationItem): any {
  return ageLevelsOrder.indexOf(certification.name);
}

export function sortByAgeLevelsOrder(
  a: CertificationItem,
  b: CertificationItem
) {
  return getAgeLevelIndex(a) - getAgeLevelIndex(b);
}
