const PATH = 'fe.pages.test_preparations.score_report';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Video Score Report'
  },
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: 'Review your performance on your most recent attempt.'
  },
  logoDescription: {
    key: `${PATH}.logo_description`,
    defaultValue: 'Certification Logo'
  },
  result: {
    key: `${PATH}.result`,
    defaultValue: ' the {{video}} test'
  },
  training: {
    key: `${PATH}.training`,
    defaultValue: ' the training test'
  },
  practice: {
    key: `${PATH}.practice`,
    defaultValue: ' the practice test'
  }
};

export default messages;
