const PATH = 'fe.components.reports.reliability.summary';

const messages = {
  viewDetails: {
    key: `${PATH}.view_details`,
    defaultValue: 'View Training Details'
  },
  totalCompleted: {
    key: `${PATH}.total_completed`,
    defaultValue: 'Participants Completed'
  },
  virtual: {
    key: `${PATH}.virtual`,
    defaultValue: 'Virtual'
  },
  inPerson: {
    key: `${PATH}.in_person`,
    defaultValue: 'In-Person'
  },
  deliveryMethod: {
    key: `${PATH}.delivery_method`,
    defaultValue: 'Delivery Method'
  },
  trainer: {
    key: `${PATH}.trainer`,
    defaultValue: 'Trainer'
  }
};

export default messages;
