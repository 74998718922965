import axios from 'axios';
import settings from 'helpers/settings';

const coreBaseURL = settings.lookup('REACT_APP_MYCLASS_BASE_URL');

const coreAxiosConfig = axios.create({
  baseURL: coreBaseURL,
  withCredentials: true
});

export default coreAxiosConfig;
