import { Table } from 'semantic-ui-react';
import i18n from 'helpers/i18n';
import messages from 'pages/Improvements/CoachingRoster/messages';
import { Link } from 'react-router-dom';
import Routes from 'helpers/routes';
import React, { useState } from 'react';
import { EducatorListData } from 'pages/Improvements/CoachingRoster/CoachingRoster';
import moment from 'moment/moment';
import { map } from 'ramda';
import { Search } from 'semantic-ui-react';
import { sortCoachingRosterTable } from 'pages/Improvements/CoachingRoster/utils';
import { UserMe } from 'types/api/User';
import PrintMenuButton from 'components/PrintMenuButton/index';
import { getFullName } from 'helpers/user';

type CoachingRosterTableProps = {
  currentUser: UserMe;
  educatorList: EducatorListData[];
};
type SortProps = {
  sorted: 'ascending' | 'descending' | undefined;
  onClick: () => void;
};

const formatDate = (date: Date | undefined) =>
  date ? moment(date).format('MM/DD/YY') : null;

function CoachingRosterTable({
  currentUser,
  educatorList
}: CoachingRosterTableProps) {
  const convertedEducators = educatorList.map((educator: any) => {
    return { ...educator, focus: educator.focus?.name };
  });
  const [column, setColumn] = useState('');
  const [tableData, setTableData] = useState(convertedEducators);
  const [filterValue, setFilterValue] = useState('');
  const [direction, setDirection] = useState<
    'ascending' | 'descending' | undefined
  >(undefined);

  function handleSort(columnToSort: string) {
    if (columnToSort === column && direction === 'descending') {
      setTableData(
        sortCoachingRosterTable(tableData, columnToSort, 'ascending')
      );
      setDirection('ascending');
      return;
    }

    if (columnToSort === column && direction === 'ascending') {
      setTableData(filterByName(convertedEducators, filterValue));
      setDirection(undefined);
      setColumn('');
      return;
    }

    setTableData(
      sortCoachingRosterTable(tableData, columnToSort, 'descending')
    );
    setColumn(columnToSort);
    setDirection('descending');
  }

  function sortedProps(currentColumn: string): SortProps {
    return {
      sorted: column === currentColumn ? direction : undefined,
      onClick: () => handleSort(currentColumn)
    };
  }

  function handleSearch(ev: React.SyntheticEvent, { value }: any) {
    setFilterValue(value);
    const filteredList = filterByName(convertedEducators, value);
    setTableData(sortCoachingRosterTable(filteredList, column, direction));
  }

  function filterByName(educators: EducatorListData[], name: string) {
    return educators.filter(e =>
      e.name.toLowerCase().includes(name.toLowerCase())
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center pb-6">
        <h2 className="basis-4/6">
          {i18n.ft(messages.coachingRoster, {
            coachName: getFullName(currentUser)
          })}
        </h2>
        <Search
          className="print:hidden"
          onSearchChange={handleSearch}
          open={false}
        />
        <PrintMenuButton title="Download PDF" icon="fa-solid fa-download" />
      </div>
      <Table sortable celled striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell {...sortedProps('name')}>
              {i18n.ft(messages.name)}
            </Table.HeaderCell>
            <Table.HeaderCell {...sortedProps('phase')}>
              {i18n.ft(messages.phase)}
            </Table.HeaderCell>
            <Table.HeaderCell {...sortedProps('focus')}>
              {i18n.ft(messages.focus)}
            </Table.HeaderCell>
            <Table.HeaderCell {...sortedProps('last_completed')}>
              {i18n.ft(messages.lastCycleCompleted)}
            </Table.HeaderCell>
            <Table.HeaderCell {...sortedProps('next_observation')}>
              {i18n.ft(messages.nextObservation)}
            </Table.HeaderCell>
            <Table.HeaderCell {...sortedProps('last_observation')}>
              {i18n.ft(messages.lastObservation)}
            </Table.HeaderCell>
            <Table.HeaderCell {...sortedProps('cycles_completed')}>
              {i18n.ft(messages.cycle)}
            </Table.HeaderCell>
            <Table.HeaderCell {...sortedProps('last_updated')}>
              {i18n.ft(messages.status)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {map(
            educator => (
              <Table.Row key={educator.id}>
                <Table.Cell className="pl-4 py-3">
                  <Link
                    to={Routes.getPath('improvement-educator-profile', {
                      id: educator.id
                    })}
                  >
                    {educator.name}
                  </Link>
                </Table.Cell>
                <Table.Cell className="py-3">{educator.phase}</Table.Cell>
                <Table.Cell className="py-3 px-4 max-w-[10rem]">
                  {educator.focus}
                </Table.Cell>
                <Table.Cell className="py-3 text-center">
                  {formatDate(educator.last_completed)}
                </Table.Cell>
                <Table.Cell className="py-3 text-center">
                  {formatDate(educator.next_observation)}
                </Table.Cell>
                <Table.Cell className="py-3 text-center">
                  {formatDate(educator.last_observation)}
                </Table.Cell>
                <Table.Cell className="py-3 px-6 text-center">
                  {educator.cycles_completed}
                </Table.Cell>
                <Table.Cell className="py-3 pr-4 text-center">
                  {formatDate(educator.last_updated)}
                </Table.Cell>
              </Table.Row>
            ),
            tableData
          )}
        </Table.Body>
      </Table>
    </div>
  );
}

export default CoachingRosterTable;
