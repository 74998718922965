import { clsx } from 'clsx';

type ColorMap = { [key: string]: { text: string; tailwindBg: string } };

const colors: ColorMap = {
  all: { text: 'text-white', tailwindBg: 'bg-[#3C3F42]' },
  I: { text: 'text-white', tailwindBg: 'bg-[#06874C]' },
  Inf: { text: 'text-white', tailwindBg: 'bg-[#06874C]' },
  T: { text: 'text-black', tailwindBg: 'bg-[#6AB794]' },
  Todd: { text: 'text-black', tailwindBg: 'bg-[#6AB794]' },
  IT: { text: 'text-white', tailwindBg: 'bg-[#06874C]' },
  PK: { text: 'text-white', tailwindBg: 'bg-[#607EA5]' },
  'Pre-K / K-3': { text: 'text-white', tailwindBg: 'bg-[#607EA5]' },
  PKK: { text: 'text-white', tailwindBg: 'bg-[#607EA5]' },
  PKK3: { text: 'text-white', tailwindBg: 'bg-[#607EA5]' },
  'Pre-K': { text: 'text-white', tailwindBg: 'bg-[#607EA5]' },
  'Pre-K-3rd': { text: 'text-white', tailwindBg: 'bg-[#385E8E]' },
  'PK-3rd': { text: 'text-white', tailwindBg: 'bg-[#385E8E]' },
  K3: { text: 'text-black', tailwindBg: 'bg-[#889EBB]' },
  UE: { text: 'text-black', tailwindBg: 'bg-[#E36628]' },
  Sec: { text: 'text-black', tailwindBg: 'bg-[#E98553]' }
};

interface AgeLevelTagProps {
  ageLevel: string;
  code: string;
}

function AgeLevelTag({ ageLevel, code }: AgeLevelTagProps) {
  const color = colors[code]
    ? colors[code].tailwindBg
    : colors['all'].tailwindBg;
  const textColor = colors[code] ? colors[code].text : colors['all'].text;
  const tagClassess = clsx('px-2 rounded text-sm font-bold', color, textColor);

  return <span className={tagClassess}>{ageLevel}</span>;
}

export default AgeLevelTag;
