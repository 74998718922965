import React from 'react';
import { Section } from '../Styled';
import { useTraining } from '../TrainingContext';
import Typography from 'components/Typography';
import i18n from 'helpers/i18n';
import messages from './messages';
import RichTextEditor from 'components/RichTextEditor';
import styled from 'styled-components';
import { isEmpty, isNil } from 'ramda';
import { isRichTextEmpty } from './utils';
import Location from '../Location';
import Sessions from '../Sessions';
import ResponsiveContent from 'components/ResponsiveContent';
import ReliabilityReport from './ReliabilityReport';
import usePermissions from 'hooks/usePermissions';
import useCurrentUser from 'hooks/useCurrentUser';
import CertificateSection from './CertificateSection';
import CoursesSection from './CoursesSection';

const SectionTitle = styled(Typography)`
  margin-bottom: 8px;
`;

function Details() {
  const { training, template } = useTraining();
  const currentUser = useCurrentUser();
  const permissions = usePermissions('trainer_dashboard', 'trainings');

  function displayVirtualTrainingLink() {
    return (
      !isNil(training.virtual_training_link) &&
      !isEmpty(training.virtual_training_link)
    );
  }

  function displayDescription() {
    return !isRichTextEmpty(template.description);
  }

  function displayNotesTitle() {
    return !isNil(training.notes_title) && !isEmpty(training.notes_title);
  }

  function displayNotesDescription() {
    return !isRichTextEmpty(training.notes);
  }

  function displayNotesSection() {
    return displayNotesTitle() || displayNotesDescription();
  }

  function displayTestReport() {
    return (
      training.status === 'completed' &&
      training.test_type &&
      (permissions.manage || training.trainer.id === currentUser.id)
    );
  }

  return (
    <React.Fragment>
      <ResponsiveContent renderMobile={<Location />} renderDesktop={null} />

      {displayTestReport() && (
        <Section>
          <ReliabilityReport
            trainingId={training.id}
            testType={training.test_type}
          />
        </Section>
      )}

      <Sessions />

      {displayVirtualTrainingLink() && (
        <Section>
          <SectionTitle
            bold
            size="large"
            color="blue"
            display="block"
            content={i18n.ft(messages.virtualTrainingLink)}
          />
          <a
            href={training.virtual_training_link}
            rel="noopener noreferrer"
            target="_blank"
          >
            {training.virtual_training_link}
          </a>
        </Section>
      )}

      {displayDescription() && (
        <Section>
          <SectionTitle
            bold
            size="large"
            color="blue"
            display="block"
            content={i18n.ft(messages.description)}
          />
          <RichTextEditor readOnly value={template.description} />
        </Section>
      )}

      {displayNotesSection() && (
        <Section>
          {displayNotesTitle() && (
            <SectionTitle
              bold
              size="large"
              color="blue"
              display="block"
              content={training.notes_title}
            />
          )}
          {displayNotesDescription() && (
            <RichTextEditor readOnly value={training.notes} />
          )}
        </Section>
      )}

      <CoursesSection />
      <CertificateSection />
    </React.Fragment>
  );
}

export default Details;
