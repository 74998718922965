import * as R from 'ramda';
import { Assessment } from 'types/api/envScales/Assessment';
import {
  CycleValidations,
  ObservationValidations
} from 'types/api/envScales/ObservationValidations';

type PAGE_STATUS = 'pending' | 'completed' | 'error' | 'disabled';

type StatusByPage = {
  details: PAGE_STATUS;
  environments: PAGE_STATUS;
  evidence: PAGE_STATUS;
  summary: PAGE_STATUS;
  [key: number]: PAGE_STATUS;
};

type ValidationsContextValue = {
  status: StatusByPage;
  validate: () => Promise<boolean>;
};

export const initialValue: ValidationsContextValue = {
  status: {
    details: 'pending',
    environments: 'pending',
    1: 'pending',
    2: 'pending',
    3: 'pending',
    4: 'pending',
    5: 'pending',
    6: 'pending',
    evidence: 'pending',
    summary: 'disabled'
  },
  validate: () => Promise.resolve(false)
};

type ValidationObject = {
  [key: string]: string | null;
};

function getPageStatus(
  validations: ValidationObject,
  showErrorStatus: boolean
) {
  const errorStatus = showErrorStatus ? 'error' : 'pending';

  const pages = R.keys(validations);
  return pages.some(page => validations[page] !== null)
    ? errorStatus
    : 'completed';
}

function getCycleStatus(
  cycleValidations: CycleValidations,
  showErrorStatus: boolean
) {
  if (!cycleValidations) {
    return 'completed';
  }

  const errorStatus = showErrorStatus ? 'error' : 'pending';
  return cycleValidations.cycle_has_errors ? errorStatus : 'completed';
}

function getSummaryStatus(statusByPage: StatusByPage, assessment: Assessment) {
  const nonSummaryPages = R.omit(['summary'], statusByPage);
  const pages = R.keys(nonSummaryPages);
  const returnStatus =
    assessment.status === 'completed' ? 'completed' : 'pending';

  return pages.every(page => statusByPage[page] === 'completed')
    ? returnStatus
    : 'disabled';
}

export function getStatusByPage(
  validations: ObservationValidations,
  assessment: Assessment,
  showErrorStatus = true
) {
  if (!validations) {
    return initialValue.status;
  }

  const statusByPage = R.clone(initialValue.status);

  const cycles = [1, 2, 3, 4, 5, 6];
  cycles.forEach(cycle => {
    statusByPage[cycle] = getCycleStatus(validations[cycle], showErrorStatus);
  });

  statusByPage.environments = getPageStatus(
    validations.environments,
    showErrorStatus
  );
  statusByPage.evidence = getPageStatus(validations.evidence, showErrorStatus);
  statusByPage.summary = getSummaryStatus(statusByPage, assessment);
  statusByPage.details = getPageStatus(validations.assessment, showErrorStatus);

  return statusByPage;
}

export function isSummaryEnabled(
  validations: ObservationValidations,
  assessment: Assessment
) {
  const statusByPage = getStatusByPage(validations, assessment);
  const summaryStatus = getSummaryStatus(statusByPage, assessment);
  return summaryStatus === 'completed' || summaryStatus === 'pending';
}
