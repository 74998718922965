import * as Yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { forgotPassword } from 'actions/users';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Button, Header } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import Browser from 'helpers/browser';
import { Actions, Errors, Instructions, LinkWrapper } from './Styled';
import { Formik, Form, Input } from 'components/EnhancedForm';

const validationSchema = Yup.object({
  email: Yup.string()
    .email(() => i18n.ft(messages.errors.email.invalid))
    .required(() => i18n.ft(messages.errors.email.required))
});

const initialValues = {
  email: ''
};

const ForgotPasswordForm = props => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [email, setEmail] = useState();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(state => state.user);
  const loginPath = Browser.containsCookie('course_merchant')
    ? RouteHelpers.getPath('users-cm-login')
    : RouteHelpers.getPath('users-login');
  const isForgotPassword =
    window.location.pathname === RouteHelpers.getPath('users-forgot-password');

  function instructions() {
    return isForgotPassword
      ? i18n.ft(messages.resetInstructions)
      : i18n.ft(messages.setInstructions);
  }

  useEffect(() => {
    // Redirect user to dashboard if already logged in
    if (currentUser.id) {
      navigate(RouteHelpers.getPath('app'), { replace: true });
    }
  }, [currentUser.id, navigate]);

  function handleSubmit(values, formik) {
    setLoading(true);

    const user = {
      email: values.email
    };

    forgotPassword({ user })
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setEmail(values.email);
      })
      .catch(({ response }) => {
        setLoading(false);
        setErrors(i18n.ft(messages.errors.unknown));
      });
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form>
        <Header
          textAlign="left"
          content={
            isForgotPassword
              ? i18n.ft(messages.forgotYourPassword)
              : i18n.ft(messages.enterEmail)
          }
        />

        {errors && <Errors>{errors}</Errors>}

        {success ? (
          <React.Fragment>
            <Instructions
              dangerouslySetInnerHTML={{
                __html: i18n.ft(messages.confirmation, {
                  email: email
                })
              }}
            />
            <LinkWrapper>
              <Button
                fluid
                color="green"
                loading={loading}
                disabled={loading}
                as={Link}
                to={loginPath}
                content={i18n.ft(messages.backToLogin)}
              />
            </LinkWrapper>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Instructions
              dangerouslySetInnerHTML={{
                __html: instructions()
              }}
            />

            <Input
              icon="mail"
              name="email"
              placeholder={i18n.ft(messages.email)}
            />

            <Actions>
              <Button as={Link} to={RouteHelpers.getPath('users-login')}>
                {i18n.ft(messages.cancel)}
              </Button>

              <Button
                type="submit"
                color="green"
                loading={loading}
                disabled={loading}
                content={i18n.ft(messages.send)}
              />
            </Actions>
          </React.Fragment>
        )}
      </Form>
    </Formik>
  );
};

export default ForgotPasswordForm;
