const PATH = 'fe.pages.testing.reliability.score_report.dimension_section';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Test Results by Dimension'
  },
  videos: {
    key: `${PATH}.videos`,
    defaultValue: 'Videos'
  },
  columnTitle: {
    key: `${PATH}.column_title`,
    defaultValue: 'Domain/Dimension'
  },
  overall: {
    key: `${PATH}.overall`,
    defaultValue: 'Overall'
  },
  reliable: {
    key: `${PATH}.reliable`,
    defaultValue: 'Reliable'
  },
  note: {
    key: `${PATH}.note`,
    defaultValue:
      'Note: This report has been updated to provide detailed performance data at the dimensions per video level, as a result we no longer share coding trends that were often unclear.'
  }
};

export default messages;
