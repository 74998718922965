import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

export interface TypographyProps {
  /** The color for the text. */
  color?: 'red' | 'blue' | 'green' | 'black' | 'white';

  /** Sets the layout type to inline or block. */
  display?: 'block' | 'inline' | 'inline-block';

  /** The size for the text (based on semantic-ui names). */
  size?:
    | 'mini'
    | 'tiny'
    | 'small'
    | 'medimum'
    | 'large'
    | 'big'
    | 'huge'
    | 'massive';

  /** Sets the horizontal alignment for the text. */
  textAlign?: 'right' | 'left' | 'center' | 'justify';

  /** If `true`, the text will be bold. */
  bold?: boolean;

  /** If `true`, the text will be italic. */
  italic?: boolean;

  /** If `true`, the element will take the full width of the container. */
  fluid?: boolean;

  /** Adds spacing at the end of the text to seperate from inline content. */
  append?: boolean;

  /** Adds spacing at the beginning of the text to separate from inline content. */
  prepend?: boolean;

  /** Transforms all characters to uppercase. */
  uppercase?: boolean;

  /** Shorthand for the content of the text. */
  content?: React.ReactNode;

  /** The content of the text. */
  children?: React.ReactNode;
}

const Wrapper = styled.span<TypographyProps>`
  font-size: ${p => p.size && p.theme.typography.fontSize[p.size]};
  color: ${p => p.color && p.theme.colors[p.color]};
  font-weight: ${p => (p.bold ? 'bold' : 'normal')};
  font-style: ${p => (p.italic ? 'italic' : 'normal')};
  text-align: ${p => p.textAlign};
  display: ${p => p.display};
  margin-left: ${p => (p.prepend ? '0.35em' : '0')};
  margin-right: ${p => (p.append ? '0.35em' : '0')};
  width: ${p => (p.fluid ? '100%' : 'initial')};
  text-transform: ${p => (p.uppercase ? 'uppercase' : null)};
`;

/**
 * @deprecated use tailwind classes instead of this
 */
function Typography({ content, children, ...props }: TypographyProps) {
  return <Wrapper {...props}>{R.isNil(content) ? children : content}</Wrapper>;
}

Typography.defaultProps = {
  display: 'inline',
  size: 'medium',
  textAlign: 'left'
};

export default Typography;
