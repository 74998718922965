import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import MainContent from 'components/MainContent';
import { useMediaQuery } from 'react-responsive';
import PageBackground from 'components/PageBackground';
import exitTest from 'images/reliabilityTest/exitTest.svg';
import { SecondaryActionWrapper, StyledWhiteButton } from '../Styled';
import instructionsIcon from 'images/reliabilityTest/instructionsIcon.svg';
import logoCertification from 'images/reliabilityTest/logoCertification.svg';
import ModalContent from 'components/ReliabilityTest/CodingVideos/ModalContent';
import KnowledgeTestInstructionsContent from 'components/KnowledgeTest/KnowledgeTestInstructionsContent';
import {
  StyledButton,
  ActionWrapper,
  SectionActions,
  ContainerSection as Section
} from 'components/Reports/Reliability/View/Styled';
import { useEffect, useRef } from 'react';

interface InstructionsProps {
  title: string;
  handlePreTestState: (value: string) => void;
  testType?: string;
}

function Instructions({
  title,
  handlePreTestState,
  testType
}: InstructionsProps) {
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ minWidth: 768 });
  const instructionsRef = useRef<any>({ current: null });

  useEffect(() => {
    const instructionsElement = document.getElementById('instructions');

    if (instructionsElement) {
      instructionsRef.current = instructionsElement;
      instructionsRef.current.focus();
    }
  }, []);

  function acceptInstructions() {
    handlePreTestState('honor_code_agreement');
  }

  function handleClick() {
    return navigate(RouteHelpers.getPath('app'));
  }

  function back() {
    handlePreTestState('resources');
  }

  return (
    <MainContent maxWidth={1080}>
      <PageHeader
        title={title}
        subtitle={
          testType === 'knowledge'
            ? i18n.ft(messages.read)
            : i18n.ft(messages.subtitle)
        }
        image={logoCertification}
        imageAlt={i18n.ft(messages.logoDescription)}
      />
      <PageBackground borderStyle>
        <Section
          mt={{ _: '0px', lg: '32px' }}
          mx={{ _: '0px', lg: '32px' }}
          borderTopRadius={isTablet ? '12px' : '0px'}
        >
          <div className="bg-white p-6 rounded-t-xl md:py-10 md:px-16 md:mb-1 lg:mb-1">
            <div className="flex mb-5">
              <img className="w-5 md:w-9" src={instructionsIcon} alt="" />
              <span
                id="instructions"
                ref={instructionsRef}
                className="text-2xl ml-3 font-bold self-center"
                tabIndex={0}
              >
                {i18n.ft(messages.instructions)}
              </span>
            </div>
            {testType === 'knowledge' ? (
              <KnowledgeTestInstructionsContent />
            ) : (
              <ModalContent />
            )}
          </div>
        </Section>
        <Section
          mb={{ _: '0px', lg: '32px' }}
          mx={{ _: '0px', lg: '32px' }}
          borderBottomRadius={isTablet ? '12px' : '0px'}
        >
          <div className="bg-white p-6 rounded-b-xl lg:p-6 lg:m-1">
            <SectionActions>
              <ActionWrapper>
                <StyledButton
                  basic
                  fluid
                  content={
                    <div className="cursor-pointer flex items-center">
                      <img
                        src={exitTest}
                        alt={i18n.ft(messages.exitTest)}
                        className="mr-5"
                      />
                      <span className="m-auto whitespace-nowrap">
                        {i18n.ft(messages.exitTest)}
                      </span>
                    </div>
                  }
                  onClick={handleClick}
                />
              </ActionWrapper>
              <SecondaryActionWrapper>
                {testType !== 'knowledge' ? (
                  <StyledWhiteButton
                    fluid
                    content={i18n.ft(messages.back)}
                    onClick={back}
                  />
                ) : null}
              </SecondaryActionWrapper>
              <ActionWrapper>
                {testType === 'knowledge' ? (
                  <StyledWhiteButton
                    fluid
                    content={i18n.ft(messages.next)}
                    color="blue"
                    className="w-full whitespace-nowrap"
                    onClick={() => acceptInstructions()}
                  />
                ) : (
                  <Button
                    fluid
                    content={i18n.ft(messages.next)}
                    color="blue"
                    className="w-full whitespace-nowrap"
                    onClick={() => acceptInstructions()}
                  />
                )}
              </ActionWrapper>
            </SectionActions>
          </div>
        </Section>
      </PageBackground>
    </MainContent>
  );
}

export default Instructions;
