import styled from 'styled-components';
import { Input as SemInput } from 'semantic-ui-react';

export const HeroBackground = styled.div`
  background: ${props => props.theme.colors.lightBlue};
  overflow: hidden;
`;

export const HeroContainer = styled.div`
  color: #333333;
  padding: 32px 24px;
  position: relative;

  @media screen and (min-width: 800px) {
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 1024px) {
    padding: 32px;
  }

  @media screen and (min-width: 1280px) {
    max-width: 1280px;
    margin: 0 auto;
  }
`;

export const HeroIcon = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 355px;
  transform: translate(-20%, -20%);
  filter: opacity(0.1);
`;

export const HeroMain = styled.div`
  @media screen and (min-width: 800px) {
    width: 60%;
    margin-right: 32px;
  }
`;

export const HeroLinks = styled.div`
  margin-top: 12px;

  @media screen and (min-width: 550px) {
    max-width: 462px;
  }

  @media screen and (min-width: 800px) {
    margin-top: 0;
    flex-grow: 1;
    max-width: unset;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
`;

export const Subtitle = styled.p`
  font-size: 18px;
  margin-bottom: 24px;
`;

export const MobileProductKey = styled.form`
  @media screen and (min-width: 550px) {
    display: none;
  }
`;

export const MobileButton = styled.div`
  margin-top: 12px;

  @media screen and (min-width: 550px) {
    margin-top: -2px;
    margin-left: 8px;
  }
`;

export const MobileInput = styled(SemInput)`
  & > input::placeholder {
    color: #707070 !important;
  }
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 4px;
  display: block;
  text-transform: uppercase;
`;

export const ProductKey = styled.form`
  display: none;

  @media screen and (min-width: 550px) {
    display: block;
  }
`;

export const ProductKeyForm = styled.div`
  display: flex;
`;

export const Input = styled(SemInput)`
  flex-grow: 1;
  margin-right: 12px;
  max-width: 250px;

  & > input::placeholder {
    color: #707070 !important;
  }
`;

export const Error = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.red};
  margin-top: 4px;
  margin-left: 2px;
`;

export const MessageWrapper = styled.div`
  max-width: 1280px;
  margin: 32px auto;
  padding: 0 16px;
  font-size: 16px;

  @media screen and (min-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;
