import axios from 'axios';
import { EducatorInformation, Note } from 'pages/Improvements/types';
import { IntakeForm, IntakeObject } from 'pages/Improvements/Intake/utils';
import { ImprovementLanguage } from 'types/api/improvement/ImprovementLanguage';
import { ImprovementCategory } from 'types/api/improvement/ImprovementCategory';
import { ImprovementPriority } from 'types/api/improvement/ImprovementPriority';
import { ImprovementFocus } from 'types/api/improvement/ImprovementFocus';
import { ImprovementArea } from 'types/api/improvement/ImprovementArea';
import { ImprovementStrategy } from 'types/api/improvement/ImprovementStrategy';
import { ImprovementCue } from 'types/api/improvement/ImprovementCues';
import { ImprovementPrompt } from 'types/api/improvement/ImprovementPrompt';
import { ImprovementGeneralCue } from 'types/api/improvement/ImprovementGeneralCue';
import { ImprovementCoachingCycle } from 'types/api/improvement/ImprovementCoachingCycle';
import { ImprovementStrategySet } from 'types/api/improvement/ImprovementStrategySet';
import { ImprovementStrategyRatings } from 'types/api/improvement/ImprovementStrategyRatings';
import { NoteType } from 'pages/Improvements/types';
import { EducatorListData } from 'pages/Improvements/CoachingRoster/CoachingRoster';
import { ImprovementIndicator } from 'types/api/improvement/ImprovementIndicator';
import { ImprovementGrowthJourney } from 'types/api/improvement/ImprovementGrowthJourney';

export async function getEducators(): Promise<{ data: EducatorListData[] }> {
  return axios.get('/api/v2/informal_observations/educators');
}

export function hasPermission() {
  return axios.get('/api/v2/informal_observations/permit');
}

export function getEducatorById(
  id: number
): Promise<{ data: EducatorInformation }> {
  return axios.get(`/api/v2/informal_observations/educators/${id}`);
}

export function getInitialObservationNote(educatorId: number) {
  return axios.get<Note>(
    `/api/v2/improvement/initial_observations/${educatorId}`
  );
}

export function createObservationNotes(educatorId: number, notes: string) {
  return axios.post<Note>('/api/v2/improvement/initial_observations', {
    educator_id: educatorId,
    notes
  });
}

export function getCoachingCycles(educatorId: number) {
  return axios.get<ImprovementCoachingCycle[]>(
    '/api/v2/improvement/coaching_cycles',
    {
      params: {
        educator_id: educatorId
      }
    }
  );
}

export function updateCoachingCycle(
  coachingCycleId: number,
  coachingCycle = {}
): Promise<{ data: ImprovementCoachingCycle }> {
  return axios.put<ImprovementCoachingCycle>(
    `/api/v2/improvement/coaching_cycles/${coachingCycleId}`,
    coachingCycle
  );
}

export function createIntake(
  intake: IntakeForm
): Promise<{ data: IntakeObject }> {
  return axios.post(`/api/v2/improvement/intakes`, { intake });
}

export function getIntake(educatorId: number): Promise<{ data: IntakeObject }> {
  return axios.get(`/api/v2/improvement/intakes`, {
    params: { educator_id: educatorId }
  });
}

export function getImprovementLanguages(): Promise<{
  data: ImprovementLanguage[];
}> {
  return axios.get(`/api/v2/improvement/languages`);
}

export function getImprovementAreas(): Promise<{
  data: ImprovementArea[];
}> {
  return axios.get('/api/v2/improvement/areas');
}

export function getImprovementCategories(params = {}) {
  return axios.get<ImprovementCategory[]>('/api/v2/improvement/categories', {
    params
  });
}

export function getImprovementPriorities(params = {}) {
  return axios.get<ImprovementPriority[]>('/api/v2/improvement/priorities', {
    params
  });
}

export function getImprovementFocuses(params = {}) {
  return axios.get<ImprovementFocus[]>('/api/v2/improvement/focuses', {
    params
  });
}

export function getImprovementStrategies(params = {}) {
  return axios.get<ImprovementStrategy[]>('/api/v2/improvement/strategies', {
    params
  });
}

export function getImprovementPrompts(params = {}) {
  return axios.get<ImprovementPrompt[]>('/api/v2/improvement/prompts', {
    params
  });
}

export function getImprovementGeneralCues(params = {}) {
  return axios.get<ImprovementGeneralCue[]>(
    '/api/v2/improvement/general_cues',
    {
      params
    }
  );
}

export function getImprovementCategory(id: number, params = {}) {
  return axios.get<ImprovementCategory>(
    `/api/v2/improvement/categories/${id}`,
    {
      params
    }
  );
}

export function getImprovementPriority(id: number, params = {}) {
  return axios.get<ImprovementPriority>(
    `/api/v2/improvement/priorities/${id}`,
    { params }
  );
}

export function getImprovementFocus(id: number, params = {}) {
  return axios.get<ImprovementFocus>(`/api/v2/improvement/focuses/${id}`, {
    params
  });
}

export function getImprovementStrategy(id: number, params = {}) {
  return axios.get<ImprovementStrategy>(
    `/api/v2/improvement/strategies/${id}`,
    { params }
  );
}

export function getImprovementPrompt(id: number, params = {}) {
  return axios.get<ImprovementPrompt>(`/api/v2/improvement/prompts/${id}`, {
    params
  });
}

export function getImprovementCues(params = {}) {
  return axios.get<ImprovementCue[]>('/api/v2/improvement/cues', { params });
}

export function getImprovementCue(id: number, params = {}) {
  return axios.get<ImprovementCue>(`/api/v2/improvement/cues/${id}`, {
    params
  });
}

export function createImprovementCue(cue = {}) {
  return axios.post<ImprovementCue>('/api/v2/improvement/cues', cue);
}

export function updateImprovementCue(id: number, cue = {}) {
  return axios.put<ImprovementCue>(`/api/v2/improvement/cues/${id}`, cue);
}

export function archiveImprovementCue(id: number) {
  return axios.delete<ImprovementCue>(`/api/v2/improvement/cues/${id}`);
}

export function getImprovementGeneralCue(id: number, params = {}) {
  return axios.get<ImprovementGeneralCue>(
    `/api/v2/improvement/general_cues/${id}`,
    { params }
  );
}

export function createImprovementCategory(category = {}) {
  return axios.post<ImprovementCategory>(
    '/api/v2/improvement/categories',
    category
  );
}

export function createImprovementPriority(priority = {}) {
  return axios.post<ImprovementPriority>(
    '/api/v2/improvement/priorities',
    priority
  );
}

export function createImprovementFocus(focus = {}) {
  return axios.post<ImprovementFocus>('/api/v2/improvement/focuses', focus);
}

export function createImprovementStrategy(strategy = {}) {
  return axios.post<ImprovementStrategy>(
    '/api/v2/improvement/strategies',
    strategy
  );
}

export function createImprovementPrompt(prompt = {}) {
  return axios.post<ImprovementPrompt>('/api/v2/improvement/prompts', prompt);
}

export function createImprovementGeneralCue(generalCue = {}) {
  return axios.post<ImprovementGeneralCue>(
    '/api/v2/improvement/general_cues',
    generalCue
  );
}

export function updateImprovementCategory(categoryId: number, category = {}) {
  return axios.put<ImprovementCategory>(
    `/api/v2/improvement/categories/${categoryId}`,
    category
  );
}

export function updateImprovementPriority(priorityId: number, priority = {}) {
  return axios.put<ImprovementPriority>(
    `/api/v2/improvement/priorities/${priorityId}`,
    priority
  );
}

export function updateImprovementFocus(focusId: number, focus = {}) {
  return axios.put<ImprovementFocus>(
    `/api/v2/improvement/focuses/${focusId}`,
    focus
  );
}

export function updateImprovementStrategy(strategyId: number, strategy = {}) {
  return axios.put<ImprovementStrategy>(
    `/api/v2/improvement/strategies/${strategyId}`,
    strategy
  );
}

export function updateImprovementPrompt(promptId: number, prompt = {}) {
  return axios.put<ImprovementPrompt>(
    `/api/v2/improvement/prompts/${promptId}`,
    prompt
  );
}

export function updateImprovementGeneralCue(
  generalCueId: number,
  generalCue = {}
) {
  return axios.put<ImprovementGeneralCue>(
    `/api/v2/improvement/general_cues/${generalCueId}`,
    generalCue
  );
}

export function archiveImprovementCategory(categoryId: number) {
  return axios.delete<ImprovementCategory>(
    `/api/v2/improvement/categories/${categoryId}`
  );
}

export function archiveImprovementPriority(priorityId: number) {
  return axios.delete<ImprovementPriority>(
    `/api/v2/improvement/priorities/${priorityId}`
  );
}

export function archiveImprovementFocus(focusId: number) {
  return axios.delete<ImprovementFocus>(
    `/api/v2/improvement/focuses/${focusId}`
  );
}

export function archiveImprovementStrategy(strategyId: number) {
  return axios.delete<ImprovementStrategy>(
    `/api/v2/improvement/strategies/${strategyId}`
  );
}

export function archiveImprovementPrompt(promptId: number) {
  return axios.delete<ImprovementPrompt>(
    `/api/v2/improvement/prompts/${promptId}`
  );
}

export function archiveImprovementGeneralCue(generalCueId: number) {
  return axios.delete<ImprovementGeneralCue>(
    `/api/v2/improvement/general_cues/${generalCueId}`
  );
}

export function createActionPlanNote(
  coachingCycleId: number,
  response: string
) {
  return axios.post<Note>('/api/v2/improvement/notes', {
    coaching_cycle_id: coachingCycleId,
    note_type: NoteType.IN_ACTION_NOTE,
    response
  });
}

export function createNote(
  coachingCycleId: number,
  noteType: string,
  response: string
) {
  return axios.post<Note>('/api/v2/improvement/notes', {
    coaching_cycle_id: coachingCycleId,
    note_type: noteType,
    response
  });
}

export function getStrategySetsByFocusId(
  improvementFocusId: number
): Promise<{ data: ImprovementStrategySet[] }> {
  return axios.get<ImprovementStrategySet[]>(
    `/api/v2/improvement/strategy_sets`,
    {
      params: {
        improvement_focus_id: improvementFocusId
      }
    }
  );
}

export function getStrategyRatingsByCoachingCycle(
  coachingCycleId: number
): Promise<{ data: ImprovementStrategyRatings }> {
  return axios.get<ImprovementStrategyRatings>(
    `/api/v2/improvement/strategy_ratings`,
    {
      params: {
        coaching_cycle_id: coachingCycleId
      }
    }
  );
}

export function getIndicators(): Promise<{ data: ImprovementIndicator[] }> {
  return axios.get<ImprovementIndicator[]>('/api/v2/improvement/indicators');
}

export function createStrategyRating(
  coachingCycleId: number,
  strategyRatings?: any
): Promise<{ data: ImprovementStrategyRatings }> {
  return axios.post<ImprovementStrategyRatings>(
    `/api/v2/improvement/strategy_ratings`,
    {
      coaching_cycle_id: coachingCycleId,
      strategy_ratings: strategyRatings
    }
  );
}

export function createGrowthJourney(educatorId: number) {
  return axios.post<ImprovementGrowthJourney>(
    '/api/v2/improvement/growth_journeys',
    {
      educator_id: educatorId
    }
  );
}

export function getAttachments(growthJourneyId: number) {
  return axios.get(
    `/api/v2/improvement/growth_journeys/${growthJourneyId}/attachments`
  );
}

export function deleteFile(growthJourneyId: number, attachmentId: number) {
  return axios.delete(
    `/api/v2/improvement/growth_journeys/${growthJourneyId}/attachments/${attachmentId}`
  );
}

export function uploadFile(growthJourneyId: number, payload: {}) {
  return axios.post(
    `/api/v2/improvement/growth_journeys/${growthJourneyId}/attachments`,
    payload
  );
}

export function getAdminAttachments(accountId: number, params = {}) {
  return axios.get(`/api/v2/improvement/accounts/${accountId}/attachments`, {
    params
  });
}

export function deleteAdminFile(accountId: number, attachmentId: number) {
  return axios.delete(
    `/api/v2/improvement/accounts/${accountId}/attachments/${attachmentId}`
  );
}

export function uploadAdminFile(accountId: number, payload: {}) {
  return axios.post(
    `/api/v2/improvement/accounts/${accountId}/attachments`,
    payload
  );
}

export function updateGeneralCues(cycleId: number, params = {}) {
  return axios.put(
    `/api/v2/improvement/cycles/${cycleId}/cues/general_cues`,
    params
  );
}

export function updateFocusCues(cycleId: number, params = {}) {
  return axios.put(
    `/api/v2/improvement/cycles/${cycleId}/cues/focus_cues`,
    params
  );
}

export function updateStrategyRatings(cycleId: number, params = {}) {
  return axios.put(
    `/api/v2/improvement/cycles/${cycleId}/ratings/strategy_ratings`,
    params
  );
}

export function updateScaleRatings(cycleId: number, params = {}) {
  return axios.put(
    `/api/v2/improvement/cycles/${cycleId}/ratings/scale_ratings`,
    params
  );
}
