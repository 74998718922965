const PATH = 'fe.pages.tokens.enter_form';

const messages = {
  useToken: {
    key: `${PATH}.use_token`,
    defaultValue: 'Use Token'
  }
};

export default messages;
