import styled from 'styled-components';

export const Container = styled.div`
  margin: 32px auto;
  max-width: 1280px;
`;

export const ListContainer = styled.div`
  @media screen and (min-width: 768px) {
    margin: 0 16px;
  }

  @media screen and (min-width: 1024px) {
    margin: 0 32px;
  }
`;

export const ListHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${p => p.theme.colors['neutral']['700']};
`;

export const ListDivider = styled.hr`
  margin: 32px 16px 0;
  border: 1px solid ${p => p.theme.colors['neutral']['200']};

  @media screen and (min-width: 1024px) {
    margin-left: 32px;
    margin-right: 32px;
  }
`;

export const HeaderContainer = styled.div`
  margin: 0 0 16px;

  @media screen and (min-width: 640px) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  @media screen and (min-width: 1024px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const FooterContainer = styled.div`
  margin-top: 16px;

  @media screen and (min-width: 640px) {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;
