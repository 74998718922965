import i18n from 'helpers/i18n';
import messages from './messages';
import { Section } from '../Styled';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import React, { useContext } from 'react';
import Typography from 'components/Typography';
import TrainingContext from '../TrainingContext';
import { displayLocation, formatCityAndState, Address } from './utils';

const LocationAddress = styled.div`
  margin-top: 8px;
`;

const AddressLine = styled.div``;

interface LocationProps {
  className?: string;
}

const Location: React.FC<LocationProps> = ({ className }) => {
  const { training } = useContext(TrainingContext);
  const address: Address = training.location_address;

  if (!displayLocation(address)) {
    return null;
  }

  return (
    <Section className={className}>
      <Typography bold size="large" color="blue" display="block">
        <Icon name="map marker alternate" />
        {i18n.ft(messages.title)}
      </Typography>

      <LocationAddress>
        <AddressLine>{address.address_1}</AddressLine>
        <AddressLine>{address.address_2}</AddressLine>
        <AddressLine>{formatCityAndState(address)}</AddressLine>
        <AddressLine>{address.zip}</AddressLine>
        <AddressLine>{address.country}</AddressLine>
      </LocationAddress>
    </Section>
  );
};

export default Location;
