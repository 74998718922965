const PATH =
  'fe.components.trainings.view.details.reliability_report.reliability_summary';

const messages = {
  reliable: {
    key: `${PATH}.reliable`,
    defaultValue: '{{passed}} out of {{total}} reliable'
  }
};

export default messages;
