import styled from 'styled-components';

export const Wrapper = styled.div`
  color: white;
  margin: auto;
  padding-top: 70px;
  text-align: center;
  width: 50%;
  position: relative;
`;

export const HiddenIframe = styled.iframe`
  width: 0;
  height: 0;
  border: 0;
`;
