interface ContainerProps {
  /** The title of the container. */
  title: string;

  /** The content of the contsiner. */
  children: React.ReactNode;
}

export function Container({ title, children }: ContainerProps) {
  return (
    <div className="bg-white rounded-[10px] shadow-xl flex flex-col">
      <div className="text-lg font-semibold px-6 py-4 border-b border-[#8a8c8e]">
        {title}
      </div>
      <div className="px-6 py-4 flex-1">{children}</div>
    </div>
  );
}
