import i18n from 'helpers/i18n';
import messages from './messages';
import { Tag } from 'types/api/Tag';
import { AxiosResponse } from 'axios';
import { getHoursMinutesSeconds } from '../utils';
import { useEffect, useState } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { useRouteParams } from 'hooks/useRouteParams';
import { USER_ENGAGEMENT_EVENT_VERBS } from 'constants/userEngagementEventVerbs';
import { AspectRatio } from 'components/KalturaVideo/Styled';
import { LearningResource } from 'types/api/LearningResource';
import { getLearningResource } from 'actions/learningResources';
import { getConfiguration, getDomId } from 'pages/Testing/Reliability/utils';
import { VideoPlayerContainer } from 'components/ReliabilityTest/CodingVideos/Styled';
import useUserEngagementEvent from 'hooks/useUserEngagementEvent';
import DimensionTagLabel from 'components/LearningResources/DimensionTagLabel';
import NotesSection from 'components/LearningResources/NotesSection';
import SupportingAssets from 'components/LearningResources/SupportingAssets';
import MarkAsImportant from 'components/LearningResources/MarkAsImportant/MarkAsImportant';

type ParamsType = {
  id: string;
};

// TO DO: move to util when BE json is finalized
const findDimensionTag = (tags?: Tag[]): Tag | undefined => {
  let dimensionTag = tags?.find(t => t.group?.name === 'dimension');
  return dimensionTag;
};

function DetailPage() {
  const [learningResource, setLearningResource] = useState<LearningResource>();
  const [playbackHasBegun, setPlaybackHasBegun] = useState(false);
  const [playbackHasEnded, setPlaybackHasEnded] = useState(false);
  const { id: learningResourceID } = useRouteParams<ParamsType>();
  const { id: userId } = useCurrentUser();

  /* upon page view, conditionally transmit the user event */
  useUserEngagementEvent({
    objectId: learningResourceID,
    objectType: 'LearningResourceRecord',
    userId,
    verb: USER_ENGAGEMENT_EVENT_VERBS.FIRST_VIEWS
  });

  const kalturaPlayerID = getDomId(
    `${learningResourceID ? learningResourceID : 1}`
  );

  /* when video playback is initiated, transmit the user event */
  useUserEngagementEvent({
    objectId: learningResourceID,
    objectType: 'LearningResourceRecord',
    userId,
    verb: USER_ENGAGEMENT_EVENT_VERBS.BEGINS_PLAYBACK,
    ready: playbackHasBegun
  });

  /* when video is watched to the end, transmit the user event */
  useUserEngagementEvent({
    objectId: learningResourceID,
    objectType: 'LearningResourceRecord',
    userId,
    verb: USER_ENGAGEMENT_EVENT_VERBS.COMPLETES,
    ready: playbackHasEnded
  });

  /* load data */
  useEffect(() => {
    if (!learningResourceID) {
      return;
    }

    getLearningResource(parseInt(learningResourceID))
      .then((response: AxiosResponse<LearningResource>) => {
        const data: LearningResource = response.data;
        setLearningResource(data);
      })
      .catch(() => {});
  }, [learningResourceID]);

  /* set up the video player */
  useEffect(() => {
    if (!learningResource) {
      return;
    }

    /* configure a video player */
    const kalturaPlayer = (window as any).KalturaPlayer?.setup(
      getConfiguration(kalturaPlayerID)
    );

    /* load the video */
    kalturaPlayer.loadMedia({ entryId: learningResource.video_uri });

    /* listen for the first play event and log it */
    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.FIRST_PLAYING,
      (_e: unknown) => {
        setPlaybackHasBegun(true);
      }
    );

    /* listen for the play back ended event and log it */
    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.PLAYBACK_ENDED,
      (_e: unknown) => {
        setPlaybackHasEnded(true);
      }
    );

    /* clean up */
    return () => {
      const kalturaPlayer = (window as any).KalturaPlayer?.getPlayer(
        kalturaPlayerID
      );
      kalturaPlayer?.destroy();
    };
  }, [kalturaPlayerID, learningResource]);

  if (!learningResource) {
    return <></>;
  }

  return (
    <div className="mt-20 max-w-4xl mb-20 mx-auto">
      <h1 className="px-4 text-xl md:text-3xl md:px-0">
        {learningResource.name}
      </h1>
      <div className="flex justify-center mt-4">
        <AspectRatio />
        <VideoPlayerContainer id={kalturaPlayerID} isTablet={false} />
      </div>
      <div className="mt-4 mx-4 md:mt-0 md:mx-0">
        <div className="bg-white p-4 pb-8">
          <div className="text-base">
            <span className="font-bold text-black">
              {i18n.ft(messages.time)}
            </span>
            <span className="pl-4">
              {getHoursMinutesSeconds(learningResource.estimated_time)}
            </span>
          </div>

          <MarkAsImportant
            learningResourceID={parseInt(learningResourceID)}
            userId={userId}
          />

          <DimensionTagLabel tag={findDimensionTag(learningResource.tags)} />
        </div>
        <div className="bg-white p-4 pb-6 mt-6 md:mt-0">
          <NotesSection notes={learningResource.notes} />
          {learningResource.instructions && (
            <NotesSection
              notes={learningResource.instructions}
              title={i18n.ft(messages.instructions)}
            />
          )}
          {learningResource.indicators && (
            <NotesSection
              notes={learningResource.indicators}
              title={i18n.ft(messages.indicators)}
            />
          )}
          {learningResource.resource_description && (
            <NotesSection
              notes={learningResource.resource_description}
              title={i18n.ft(messages.description)}
            />
          )}
          {learningResource.learning_goals && (
            <NotesSection
              notes={learningResource.learning_goals}
              title={i18n.ft(messages.goals)}
            />
          )}

          {learningResource.assets ? (
            <SupportingAssets assets={learningResource.assets} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default DetailPage;
