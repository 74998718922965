const PATH = 'fe.components.certifications.test_list';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Tests'
  },
  needSupport: {
    key: `${PATH}.need_support`,
    defaultValue: 'Need Support?'
  },
  testExtension: {
    key: `${PATH}.test_extension`,
    defaultValue: 'Test Extension'
  }
};

export default messages;
