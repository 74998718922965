const PATH = 'fe.pages.tokens.enter';

const messages = {
  instructions: {
    key: `${PATH}.instructions`,
    defaultValue: 'Please provide your token'
  }
};

export default messages;
