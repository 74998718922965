import TrainingTile from './TrainingTile';
import useAsync from 'hooks/useAsync';
import { getAccountTrainings } from 'actions/trainings';
import { Training } from 'types/api/Training';
import i18n from 'helpers/i18n';
import messages from './messages';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import { Input, Loader } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import moment from 'moment';
import { isNil } from 'ramda';
import useCurrentUser from 'hooks/useCurrentUser';
import { useMediaQuery } from 'react-responsive';

import {
  TrainigContainer,
  ActionContainer,
  DetailContainer,
  ButtonStatus,
  ButtonsContainer,
  StatusWrapper,
  Title,
  SearchContainer,
  SearchBar,
  SearchDates,
  FieldContainer,
  FieldLabel,
  Pagination,
  PaginationWrapper,
  SearchCount,
  LoaderWrapper,
  NoTrainings
} from './Styled';

interface TrainingProps {
  accountName: string;
}

function Trainings({ accountName }: TrainingProps) {
  const { data, run, isPending } = useAsync();

  const currentUser = useCurrentUser();
  const { state, setState, setPage } = usePaginatedSearch({
    query: '',
    status: 'active',
    start_date: '',
    end_date: '',
    page: 1,
    per_page: 10
  });
  const isLessThan500px = useMediaQuery({ maxWidth: 500 });

  useDebouncedEffect(
    () => {
      run(getAccountTrainings(state));
    },
    300,
    [run, state]
  );

  return (
    <TrainigContainer>
      <ActionContainer>
        <StatusWrapper>
          <Title>{i18n.ft(messages.status)}</Title>
          <ButtonsContainer>
            <ButtonStatus
              fluid
              onClick={() => setState({ status: '' })}
              selected={state.status === ''}
            >
              {i18n.ft(messages.all)}
            </ButtonStatus>
            <ButtonStatus
              fluid
              onClick={() => setState({ status: 'active' })}
              selected={state.status === 'active'}
            >
              {i18n.ft(messages.active)}
            </ButtonStatus>
            <ButtonStatus
              fluid
              onClick={() => setState({ status: 'completed' })}
              selected={state.status === 'completed'}
            >
              {i18n.ft(messages.completed)}
            </ButtonStatus>
            <ButtonStatus
              fluid
              onClick={() => setState({ status: 'canceled' })}
              selected={state.status === 'canceled'}
            >
              {i18n.ft(messages.canceled)}
            </ButtonStatus>
          </ButtonsContainer>
        </StatusWrapper>
        <SearchContainer>
          <SearchBar>
            <FieldContainer>
              <FieldLabel htmlFor="search">
                {i18n.ft(messages.searchTrainings)}
              </FieldLabel>
              <Input
                fluid
                clearable="true"
                id="search"
                icon="search"
                placeholder={i18n.ft(messages.searchPlaceholder)}
                onChange={(e, data) => setState({ query: data.value })}
                value={state.query}
              />
            </FieldContainer>
          </SearchBar>
          <SearchDates>
            <FieldContainer>
              <FieldLabel htmlFor="startDate">
                {i18n.ft(messages.startDate)}
              </FieldLabel>
              <DateInput
                clearable
                id="startDate"
                hideMobileKeyboard
                dateFormat="MM/DD/YYYY"
                maxDate={moment(state.end_date).format('MM/DD/YYYY')}
                popupPosition="bottom left"
                pickerStyle={
                  isLessThan500px ? { maxWidth: '200px', fontSize: '14px' } : {}
                }
                closable
                placeholder={i18n.ft(messages.startDate)}
                onChange={(e, data) =>
                  setState({
                    start_date: data.value
                      ? moment(data.value).format('YYYY-MM-DD')
                      : data.value
                  })
                }
                value={state.start_date}
                localization={
                  currentUser && !isNil(currentUser.preferred_language)
                    ? currentUser.preferred_language
                    : 'en'
                }
              />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel htmlFor="endDate">
                {i18n.ft(messages.endDate)}
              </FieldLabel>
              <DateInput
                clearable
                id="endDate"
                hideMobileKeyboard
                minDate={moment(state.start_date).format('MM/DD/YYYY')}
                dateFormat="MM/DD/YYYY"
                popupPosition="bottom left"
                pickerStyle={
                  isLessThan500px ? { maxWidth: '200px', fontSize: '14px' } : {}
                }
                closable
                placeholder={i18n.ft(messages.endDate)}
                onChange={(e, data) =>
                  setState({
                    end_date: data.value
                      ? moment(data.value).format('YYYY-MM-DD')
                      : data.value
                  })
                }
                value={state.end_date}
                localization={
                  currentUser && !isNil(currentUser.preferred_language)
                    ? currentUser.preferred_language
                    : 'en'
                }
              />
            </FieldContainer>
          </SearchDates>
        </SearchContainer>
      </ActionContainer>
      {!isPending ? (
        <DetailContainer>
          <SearchCount>
            <strong>{i18n.ft(messages.searchResults)}</strong>
            {i18n.ft(messages.countResults, {
              current: data.trainings.length,
              total: data.pagination.total_count
            })}
          </SearchCount>
          <>
            {data.trainings.length ? (
              data.trainings.map((training: Training, index: number) => (
                <TrainingTile key={index} training={training}></TrainingTile>
              ))
            ) : (
              <NoTrainings>
                {i18n.ft(messages.noTrainings, { name: accountName })}
                <a
                  href={`https://teachstone.com/professional-development`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {i18n.ft(messages.here)}
                </a>
              </NoTrainings>
            )}
          </>
          {data.pagination.total_count !== 0 && (
            <PaginationWrapper>
              <Pagination
                size="small"
                activePage={data.pagination.current_page}
                totalPages={data.pagination.total_pages}
                onPageChange={(e: any, data: any) =>
                  setPage(Number(data.activePage))
                }
              />
            </PaginationWrapper>
          )}
        </DetailContainer>
      ) : (
        <LoaderWrapper>
          <Loader active inline="centered" />
        </LoaderWrapper>
      )}
    </TrainigContainer>
  );
}

export default Trainings;
