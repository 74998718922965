import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .ui.button {
    align-self: flex-start;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;

    .ui.button {
      width: 100%;
      margin-bottom: 16px;
    }
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @media screen and (max-width: 448px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

export const SearchField = styled.div`
  margin-right: 24px;
  display: inline-block;
  width: 50%;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

export const DateField = styled.div`
  display: inline-block;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 448px) {
    margin-right: 0;
    margin-bottom: 16px;

    /** Updates to calendar date input. */
    & > .field > .ui.input,
    & > .field > .ui.input > input {
      width: 100%;
    }
  }

  @media screen and (min-width: 449px) and (max-width: 700px) {
    width: calc(50% - 12px);

    /** Updates to calendar date input. */
    & > .field > .ui.input,
    & > .field > .ui.input > input {
      width: 100%;
    }
  }
`;

export const FieldLabel = styled.label`
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
`;
