const PATH = 'fe.components.learning_resources.supporting_assets';

const messages = {
  plan: {
    key: `${PATH}.plan`,
    defaultValue: 'Plan and Expand'
  }
};

export default messages;
