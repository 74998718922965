const PATH = 'fe.components.measure.warning';

const messages = {
  dismiss: {
    key: `${PATH}.dismiss`,
    defaultValue: 'Dismiss warnings'
  }
};

export default messages;
