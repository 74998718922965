import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 32px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    margin-top: 0;
    height: 100%;
  }
`;

export const LeftSide = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-right: 16px;
  padding-left: 16px;

  @media screen and (min-width: 768px) {
    padding-right: 48px;
    padding-left: 48px;
  }

  @media screen and (min-width: 1024px) {
    width: 375px;
    left: 0;
    background-color: #e9ecf2;
    padding-top: 120px;
    height: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  @media screen and (min-width: 768px) {
    padding: 0 48px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 20px;
    padding: 0 20px;
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1024px) {
    height: 100px;
    align-items: start;
  }
`;

export const MidContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 24px;
  }
`;

export const CenterTiles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 2;

  @media screen and (min-width: 1024px) {
    max-width: 965px;
  }
`;

export const RightTiles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
