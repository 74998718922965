const PATH = 'fe.components.login_manager.login_with_code.verify_code_form';

const messages = {
  fields: {
    code: {
      key: `${PATH}.fields.code`,
      defaultValue: '6-Digit Code'
    },
    codePlaceholder: {
      key: `${PATH}.fields.code_placeholder`,
      defaultValue: 'Enter 6-Digit Code'
    }
  },
  errors: {
    code: {
      required: {
        key: `${PATH}.errors.code.required`,
        defaultValue: 'Enter the 6-digit code sent to your mobile phone'
      }
    }
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Submit Code'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back to Login Options'
  },
  resend: {
    key: `${PATH}.resend`,
    defaultValue: 'Resend Code'
  },
  codeSent: {
    key: `${PATH}.code_sent`,
    defaultValue: 'Login code successfully sent'
  }
};

export default messages;
