import { FocusEvent } from 'react';

export const NOTES_MAX_CHAR_LENGTH = 5000;

/*
 * Set the text of a textarea to its placeholder if the user has not entered any text.
 */
export function setTextWithPlaceholder(
  event: FocusEvent<HTMLTextAreaElement>,
  setFieldValue: (field: string, value: string) => void
) {
  if (!event.target.value) {
    setFieldValue(event.target.name, event.target.placeholder);
  }
}
