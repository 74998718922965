import { DateInput, TimeInput } from 'components/EnhancedForm';
import { isNil } from 'ramda';
import useCurrentUser from 'hooks/useCurrentUser';
import i18n from 'helpers/i18n';
import messages from './messages';
import { ActionPlanFieldProps } from 'pages/Improvements/EducatorProfile/ActionPlan/ActionPlan';

export type ScheduleObservationProps = {
  date_of_observation?: string;
  time_of_observation?: string;
};

function ScheduleObservation({ ...props }: ActionPlanFieldProps) {
  const currentUser = useCurrentUser();

  const { disabled } = props;

  return (
    <>
      <h3>{i18n.ft(messages.schedule)}</h3>
      <div className="md:flex gap-8">
        <DateInput
          name="date_of_observation"
          label={i18n.ft(messages.observationDate)}
          placeholder={i18n.ft(messages.selectObservationDate)}
          localization={
            currentUser && !isNil(currentUser.preferred_language)
              ? currentUser.preferred_language
              : 'en'
          }
          disabled={disabled}
          className="basis-96"
        />

        <TimeInput
          name="time_of_observation"
          label={i18n.ft(messages.startTime)}
          placeholder={i18n.ft(messages.selectTime)}
          disabled={disabled}
          className="basis-28"
        />
      </div>
    </>
  );
}

export default ScheduleObservation;
