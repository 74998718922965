const PATH = 'fe.pages.improvements.educator_profile';

const messages = {
  initialCoachingSession: {
    key: `${PATH}.initial_coaching_session`,
    defaultValue: 'Kickoff Meeting with'
  },
  buildARelationship: {
    key: `${PATH}.build_a_relationship`,
    defaultValue:
      'Start building a partnership with the educator by getting to know one another and talking about your experiences and goals'
  },
  talkAboutChildren: {
    key: `${PATH}.talk_about_children`,
    defaultValue:
      'Talk about the children and the learning setting, discussing children’s abilities, strengths, and needs'
  },
  setAFocusArea: {
    key: `${PATH}.set_a_focus_area`,
    defaultValue: 'Set a potential focus area for the first coaching cycle'
  },
  conductGeneralObservation: {
    key: `${PATH}.conduct_general_observation`,
    defaultValue:
      'Conduct an initial General Observation (optional step that educators and coaches may agree to engage in)'
  },
  toDo: {
    key: `${PATH}.to_do`,
    defaultValue: 'To Do'
  },
  youWill: {
    key: `${PATH}.you_will`,
    defaultValue: 'You will'
  },
  startObservation: {
    key: `${PATH}.start_observation`,
    defaultValue: 'Start a Brief Observation (optional)'
  },
  upNext: {
    key: `${PATH}.up_next`,
    defaultValue: 'Up Next...'
  },
  startCoachingRelationship: {
    key: `${PATH}.start_coaching_relationship`,
    defaultValue: 'How would you like to begin?'
  },
  startInitialSession: {
    key: `${PATH}.start_initial_session`,
    defaultValue: 'Start the Kickoff Meeting'
  }
};
export default messages;
