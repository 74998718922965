const PATH = 'fe.helpers.age_level';

const messages = {
  infant: {
    key: `${PATH}.infant`,
    defaultValue: 'Infant'
  },
  toddler: {
    key: `${PATH}.toddler`,
    defaultValue: 'Toddler'
  },
  preK: {
    key: `${PATH}.pre_k`,
    defaultValue: 'Pre-K'
  },
  k: {
    key: `${PATH}.k`,
    defaultValue: 'Kindergarten'
  },
  k3: {
    key: `${PATH}.k_3`,
    defaultValue: 'K-3'
  },
  upperElementary: {
    key: `${PATH}.upper_elementary`,
    defaultValue: 'Upper Elementary'
  },
  secondary: {
    key: `${PATH}.secondary`,
    defaultValue: 'Secondary'
  }
};

export default messages;
