import i18n from 'helpers/i18n';
import settings from 'helpers/settings';
import messages from './messages';
import Spacer from 'components/Spacer';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BarsIcon, TeachstoneLogo } from './icons';
import VisuallyHidden from 'components/VisuallyHidden';
import useCurrentUser from 'hooks/useCurrentUser';
import SidebarNav from './SidebarNav';
import UserMenu from './UserMenu';
import RouteHelpers from 'helpers/routes';

import {
  Wrapper,
  FlexCenter,
  IconButton,
  List,
  ListItem,
  VerticalDivider
} from './Styled';

import {
  NavMenu,
  NavMenuButton,
  NavMenuItems,
  NavMenuItem,
  NavButton
} from './NavMenu';
import useFeatureFlags from 'hooks/useFeatureFlags';

function NewHeader() {
  const currentUser = useCurrentUser();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const canvasPath = settings.lookup('REACT_APP_CANVAS_PATH');

  const target = (url: string) => {
    if (url === canvasPath) {
      return { target: '_blank' };
    }
  };

  return (
    <header>
      <Wrapper>
        <List>
          <FlexCenter as="li">
            <FlexCenter as="a" href="/" aria-label="Teachstone">
              <TeachstoneLogo />
            </FlexCenter>
            {isDesktop ? <Spacer axis="horizontal" size={16} /> : null}
          </FlexCenter>

          <ListItem>
            <NavButton as="a" href="/">
              {i18n.ft(messages.home)}
            </NavButton>
          </ListItem>

          {isDesktop ? (
            <>
              {currentUser.menu?.map((menuItem: any, idx: number) => (
                <ListItem key={idx}>
                  {Boolean(menuItem.children) ? (
                    <NavMenu>
                      <NavMenuButton>{menuItem.label}</NavMenuButton>
                      <NavMenuItems>
                        {menuItem.children.map(
                          (child: any, childIdx: number) => (
                            <NavMenuItem
                              key={childIdx}
                              href={child.target_url}
                              {...target(child.target_url)}
                            >
                              {child.label}
                            </NavMenuItem>
                          )
                        )}
                      </NavMenuItems>
                    </NavMenu>
                  ) : (
                    <NavButton as="a" href={menuItem.target_url}>
                      {menuItem.label}
                    </NavButton>
                  )}
                </ListItem>
              ))}
              {isFeatureFlagEnabled('lr_dashboard_menu') && (
                <ListItem>
                  <NavMenu>
                    <NavMenuButton>
                      {i18n.ft(messages.learningResources)}
                    </NavMenuButton>
                    <NavMenuItems>
                      <NavMenuItem
                        href={
                          currentUser.roles.includes('lr_coach')
                            ? RouteHelpers.getPath('coach-learning-resources')
                            : RouteHelpers.getPath('learning-resources')
                        }
                        target="_blank"
                      >
                        {i18n.ft(messages.learningResources)}
                      </NavMenuItem>
                    </NavMenuItems>
                  </NavMenu>
                </ListItem>
              )}
            </>
          ) : null}
        </List>

        <FlexCenter>
          {isDesktop ? (
            <>
              <ListItem as="div">
                <NavButton id="helpNavItem">{i18n.ft(messages.help)}</NavButton>
              </ListItem>

              <VerticalDivider role="separator" />
            </>
          ) : null}

          <UserMenu />

          {isDesktop ? null : (
            <>
              <Spacer axis="horizontal" size={24} />
              <IconButton onClick={() => setIsOpen(true)}>
                <BarsIcon />
                <VisuallyHidden>{i18n.ft(messages.openNav)}</VisuallyHidden>
              </IconButton>
            </>
          )}
        </FlexCenter>

        <SidebarNav open={isOpen} onClose={() => setIsOpen(false)} />
      </Wrapper>
    </header>
  );
}

export default NewHeader;
