import * as R from 'ramda';
import { getFullName } from 'helpers/user';
import { useEffect } from 'react';
import useCurrentUser from './useCurrentUser';

const Pendo = (window as any).pendo;

function usePendo() {
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (R.isNil(currentUser.id) || R.isNil(currentUser.guid)) {
      return;
    }

    const metadata = {
      apiKey: 'dbfacce6-ec16-4ebd-6a84-17c7e1b6bf54',
      visitor: {
        id: currentUser.guid,
        userId: currentUser.id,
        email: currentUser.email,
        name: getFullName(currentUser),
        // Note from Scott Z: this has been broken for a while since certs moved to onelogin.
        // I'm leaving this element in to avoid having things downstream break, but setting it to null.
        // Previously it would have been false all the time, but that's actually a lie – the user could
        // in fact be a certified observer, but the cert was in a different system we haven't been checking.
        isObserver: null
      }
    };

    if (Pendo.isReady?.()) {
      Pendo.updateOptions(metadata);
    } else {
      Pendo.initialize(metadata);
    }
  }, [currentUser]);
}

export default usePendo;
