/**
 * Actions in this file uses the passthrough in Onelogin to call env-scales
 */

import axios from 'axios';
import { SettingQuestion } from 'types/api/envScales/SettingQuestion';
import { AccountReportSettings } from 'types/api/envScales/AccountReportSettings';
import { useQuery } from '@tanstack/react-query';

type UpdateSettingsRes = {
  account_setting: SettingQuestion;
};

export function updateSettings(accountGuid: string, settings = {}) {
  return axios.put<UpdateSettingsRes>(
    `/api/v2/measurement/settings/accounts/${accountGuid}`,
    {
      account_setting: settings
    }
  );
}

export type GetSettingsRes = {
  account_setting: SettingQuestion;
};

export function getSettings(accountGuid: string) {
  return axios.get(`/api/v2/measurement/settings/accounts/${accountGuid}`);
}

export function useGetSettings(accountGuid: string) {
  return useQuery({
    queryKey: ['measurement', 'settings', 'accounts', accountGuid],
    queryFn: () => getSettings(accountGuid).then(res => res.data),
    enabled: Boolean(accountGuid)
  });
}

export type GetReportSettingsRes = {
  account_report_setting: AccountReportSettings;
};

export function getReportSettings(accountGuid: string) {
  return axios.get(`/api/v2/measurement/settings/reports/${accountGuid}`);
}

export function updateReportSettings(accountGuid: string, settings = {}) {
  return axios.put(`/api/v2/measurement/settings/reports/${accountGuid}`, {
    account_report_setting: settings
  });
}
