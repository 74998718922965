const PATH = 'fe.components.reliability_test';

const messages = {
  dimensionScore: {
    key: `${PATH}.dimension_score`,
    defaultValue: 'Dimension score {{value}}'
  },
  logoDescription: {
    key: `${PATH}.logo_description`,
    defaultValue: 'Certification Logo'
  },
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: 'Watch and code each testing video'
  },
  attempt: {
    key: `${PATH}.attempt`,
    defaultValue: 'Attempt {{value}}'
  },
  expiresOn: {
    key: `${PATH}.expires_on`,
    defaultValue: 'Expires on:'
  },
  expiresOnMobile: {
    key: `${PATH}.expires_on_mobile`,
    defaultValue: 'Exp.'
  },
  testInstructions: {
    key: `${PATH}.test_instructions`,
    defaultValue: 'View Test Instructions'
  },
  testPrepResources: {
    key: `${PATH}.test_prep_resources`,
    defaultValue: 'View test preparation resources'
  },
  modal: {
    instructionsTitle: {
      key: `${PATH}.instructions_title`,
      defaultValue: 'Instructions'
    },
    dismissButton: {
      key: `${PATH}.dismiss_button`,
      defaultValue: 'Dismiss'
    }
  },
  chart: {
    title: {
      key: `${PATH}.chart.title`,
      defaultValue: 'CLASS Score Report: Graphical Representation of Score'
    }
  },
  confirmationModal: {
    title: {
      key: `${PATH}.title`,
      defaultValue: `Are you sure you're ready to submit?`
    },
    submit: {
      key: `${PATH}.submit`,
      defaultValue: `Submit`
    },
    cancel: {
      key: `${PATH}.cancel`,
      defaultValue: `Review codes`
    }
  },
  incompleteDimensionsError: {
    key: `${PATH}.incomplete_dimensions_error`,
    defaultValue: 'Please enter all dimension codes'
  },
  notes: {
    key: `${PATH}.notes`,
    defaultValue: 'Notes'
  }
};

export default messages;
