import styled from 'styled-components';

import {
  Input as SemanticInput,
  Search as SemanticSearch
} from 'semantic-ui-react';

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TagName = styled.div`
  font-size: 14px;
  margin: 0 4px 0 4px;
`;

export const Tag = styled.div`
  border-radius: 5px;
  background-color: ${p => p.theme.colors['neutral']['50']};
  display: flex;
  padding: 4px;
  margin: 8px 4px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
`;

export const ErrorLabel = styled.div`
  font-size: 13.6px;
  font-weight: bold;
  text-align: left;
  color: ${p => p.theme.colors['red']};
`;

export const Optional = styled.span`
  margin-left: 8px;
  font-weight: 400;
  color: #86868a;
`;

export const Required = styled.span`
  margin-left: 4px;
  color: ${p => p.theme.colors['red']};
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
`;

export const InputDisable = styled(SemanticInput)`
  input {
    background: ${p => p.theme.colors['neutral']['200']} !important;
    pointer-events: none;
  }
`;

export const Search = styled(SemanticSearch)<{ error?: boolean }>`
  &.ui.search > .ui.input {
    display: flex;

    input {
      background-color: ${p => p.error && '#fff6f6 !important'};
      border-color: ${p => p.error && '#e0b4b4 !important'};

      ::placeholder {
        color: ${p => p.error && '#e0b4b4'};
      }
    }
  }

  &.ui.search > .results {
    max-height: 250px;
    overflow-y: auto;
  }
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  margin-top: 1px;
`;
