import i18n from 'helpers/i18n';
import messages from './messages';
import format from 'date-fns/format';
import { Icon } from 'semantic-ui-react';
import { getHoursMinutes } from 'pages/LearningResources/utils';
import { PlaylistRecord, LearningTracks } from 'types/LearningResources/types';
import { getNextLearningResource } from 'pages/LearningResources/Playlist/utils';
import { PlaylistCurrentTrack } from 'pages/LearningResources/Playlist/PlaylistDetailPage';
import useUserEngagementEvent from 'hooks/useUserEngagementEvent';
import { USER_ENGAGEMENT_EVENT_VERBS } from 'constants/userEngagementEventVerbs';
import useCurrentUser from 'hooks/useCurrentUser';

interface PlaylistProps {
  playlist: PlaylistRecord & PlaylistCurrentTrack;
  currentVideoId: number;
  setCurrentLearningResource: (playlist: LearningTracks) => void;
}

function Playlist({
  playlist,
  currentVideoId,
  setCurrentLearningResource
}: PlaylistProps) {
  const currentUser = useCurrentUser();

  /* upon page view, conditionally transmit the user event */
  useUserEngagementEvent({
    objectId: currentVideoId,
    objectType: 'LearningResourceRecord',
    userId: currentUser.id,
    verb: USER_ENGAGEMENT_EVENT_VERBS.FIRST_VIEWS
  });

  if (!playlist) {
    return null;
  }

  return (
    <>
      {playlist.learning_tracks.map((track: LearningTracks) => {
        return (
          <div
            className={`bg-white rounded-lg h-fit cursor-pointer mt-4 ${
              currentVideoId === track.learning_resources.id
                ? 'border-2 border-[#077699]'
                : ''
            } `}
            style={{ boxShadow: '0px 22px 40px 0px #0000000D' }}
            onClick={() => {
              setCurrentLearningResource(
                getNextLearningResource(playlist, track.id)
              );
            }}
            key={track.id}
          >
            <div className="grid grid-cols-3 mb-4 sm:mb-0 sm:h-[189px]">
              <div className="col-span-3 sm:col-span-1 relative">
                <img
                  className="w-full max-w-full rounded-s-md object-cover sm:h-[189px]"
                  src={`https://cdnsecakmi.kaltura.com/p/966871/thumbnail/entry_id/${track.learning_resources.video_uri}/width/600`}
                  alt="Thumbnail"
                />
                <div className="mt-2 ml-2 absolute top-0 left-0 bg-white text-black px-2 py-1 rounded text-xs font-semibold">
                  {getHoursMinutes(track.learning_resources.estimated_time)}
                </div>
              </div>

              <div className="col-span-3 sm:col-span-2 self-center">
                <div className="inline-block align-middle">
                  <div className="font-semibold mt-4 sm:mt-0 text-black mx-4 text-lg line-clamp-2 leading-5 pb-4 sm:pb-0">
                    {`${track.learning_resources.name}`}
                  </div>

                  {track.completed_at && (
                    <div className="mt-3 mx-4">
                      <span className="inline-flex items-center rounded-md bg-[#06874C] px-2 py-1 font-semibold text-white text-sm leading-4">
                        <Icon name="check circle outline" />
                        {i18n.ft(messages.completed)}{' '}
                        {' ' +
                          format(new Date(track.completed_at), 'yyyy-MM-dd')}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Playlist;
