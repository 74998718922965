import React from 'react';
import messages from './messages';
import i18n from 'helpers/i18n';
import RouteHelpers from 'helpers/routes';
import { UserCertification } from 'types/api/UserCertification';
import { Training } from 'types/api/Training';
import moment from 'moment';
import momentWithZones from 'moment-timezone';
import { getActiveTrainings, getActiveCertifications } from './utils';
import {
  Wrapper,
  CertTitle,
  ManageIcon,
  Certifications,
  Title,
  Expiration,
  RowName,
  Trainings,
  Divider,
  Timezone,
  ButtonWrapper,
  NoTrainings,
  SeeMore
} from './Styled';
import { Button } from 'semantic-ui-react';

interface CertificationsTrainingsProps {
  certifications: UserCertification[];
  icon: string;
  title: string;
  trainings: Training[];
}

function CertificationsTrainings({
  certifications,
  icon,
  title,
  trainings
}: CertificationsTrainingsProps) {
  const activeCertifications = getActiveCertifications(certifications);
  const activeTrainings = getActiveTrainings(trainings);

  const zone = momentWithZones.tz.guess(true);
  const offset = momentWithZones().tz(zone).format('z');

  if (!activeCertifications.length) {
    return null;
  }

  return (
    <Wrapper>
      <Title>
        <ManageIcon src={icon} />
        {title}
      </Title>
      <Certifications>
        <CertTitle href={RouteHelpers.getPath('certifications')}>
          {i18n.ft(messages.certifications)}
        </CertTitle>
        <Expiration>{i18n.ft(messages.expiration)}</Expiration>
        {activeCertifications.map((certification, index) => (
          <React.Fragment key={`certification-row-${index}`}>
            <RowName href={`/certifications#${certification.id}`}>
              {certification.certification.name}
            </RowName>
            <div>{certification.end_date}</div>
          </React.Fragment>
        ))}
      </Certifications>
      <Divider />
      <Trainings>
        <CertTitle href="/trainings?status=active">
          {i18n.ft(messages.trainer)}
        </CertTitle>
        <Expiration>{i18n.ft(messages.start)}</Expiration>
        {!activeTrainings.length ? (
          <NoTrainings>{i18n.ft(messages.noTrainings)}</NoTrainings>
        ) : (
          <>
            {activeTrainings.map((training, index) => {
              const maxIndex = 3;
              return index < maxIndex ? (
                <React.Fragment key={`training-row-${index}`}>
                  <RowName href={`/trainings/${training.id}/details`}>
                    {training.training_template.title}
                  </RowName>
                  <div>
                    <div>
                      {moment(training.start_date).local().format('YYYY-MM-DD')}
                    </div>
                    <Timezone>{`${moment(training.start_date)
                      .local()
                      .format('LT')} ${offset}`}</Timezone>
                  </div>
                </React.Fragment>
              ) : null;
            })}
          </>
        )}
      </Trainings>
      {activeTrainings.length > 3 ? (
        <SeeMore>
          <a href="/trainings?status=active">{i18n.ft(messages.seeMore)}</a>
        </SeeMore>
      ) : null}
      <ButtonWrapper>
        <Button as="a" color="blue" href={RouteHelpers.getPath('td-catalog')}>
          {i18n.ft(messages.createTraining)}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
}

export default CertificationsTrainings;
