import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';

export const Errors = styled.div`
  font-size: 16px;
  text-align: left;
  margin-top: 20px;
  font-weight: bold;
  color: ${props => props.theme.colors.red};
`;

export const TrainingBanner = styled.div<{ src: string }>`
  flex: 1;
  min-height: 200px;
  background-color: gray;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('${props => props.src}');

  @media screen and (max-width: 700px) {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const DetailsContainer = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 700px) {
    margin-left: 0;
  }
`;

export const EditButton = styled(Button)`
  margin-top: 8px !important;
`;

export const ActionButton = styled(Button)`
  margin-top: 8px !important;
`;

export const StyledDropdown = styled(Dropdown)`
  background-color: #00779e !important;
  color: white !important;
  margin-top: 8px !important;

  &:hover {
    background-color: #005f7e !important;
  }

  .selected.item {
    font-weight: normal !important;

    &:not(:hover) {
      background-color: white !important;
    }
  }
`;

export const StyledButtonGroup = styled(Button.Group)`
  margin-top: auto;
  width: calc(100% - 30px);
`;
