import i18n from 'i18n-js';
import settings from 'helpers/settings';

// Ideally, the PUBLIC_URL will be set to the fully-qualified hostname
// from which OneLogin is served. If not, ONELOGIN_BASE_URL will be used.
// However, if neither setting is available, an empty string will cause the
// browser to look for the file at the same origin as the HTML.
const publicURL =
  settings.lookup('PUBLIC_URL') || settings.lookup('ONELOGIN_BASE_URL') || '';

export function init() {
  // Define i18n on window scope needed for translations
  window.I18n = i18n;

  // Dynamically load translations
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `${publicURL}/js/translations.js`;
  document.body.appendChild(script);
}
