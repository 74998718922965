import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  width: calc((100% - 16px) / 2);

  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Errors = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 16px;
  color: ${props => props.theme.colors.red};
`;
