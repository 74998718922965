import styled from 'styled-components';

import {
  addSpace,
  addFlex,
  addColor,
  addBorderRadius,
  SpaceProps,
  FlexProps,
  ColorProps,
  BorderProps
} from 'components/Box/system';

type Props = SpaceProps & FlexProps & ColorProps & BorderProps;

/**
 * @deprecated use tailwind classes instead of this. e.g. m-1, p-1, flex, flex-col, etc
 */
export const Flex = styled.div<Props>`
  ${addSpace}
  ${addColor}
  ${addBorderRadius}
  ${addFlex}
  display: flex;
`;

Flex.displayName = 'Flex';
