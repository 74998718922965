import { clsx } from 'clsx';
import i18n from 'helpers/i18n';
import messages from './messages';
import { CERTIFICATION_STATUS } from 'types/api/CertificationDashboard';
import { ActiveIcon, ExpiredIcon, ExpiringIcon, SeekIcon } from './utils';

interface StatusTagProps {
  status: CERTIFICATION_STATUS;
}

export function StatusTag({ status }: StatusTagProps) {
  const labels = {
    active: i18n.ft(messages.active),
    expiring_soon: i18n.ft(messages.expiringSoon),
    expired: i18n.ft(messages.expired),
    seeking_initial: i18n.ft(messages.seekingInitial)
  };

  const bgColor = {
    active: 'bg-[#06874C]',
    expiring_soon: 'bg-[#6E2262]',
    expired: 'bg-[#AC213A]',
    seeking_initial: 'bg-[#077699]'
  };

  const icons = {
    active: <ActiveIcon />,
    expiring_soon: <ExpiringIcon />,
    expired: <ExpiredIcon />,
    seeking_initial: <SeekIcon />
  };

  const classNames = clsx(
    'py-1 px-2 rounded text-sm text-white font-bold inline-flex items-center gap-2',
    bgColor[status]
  );

  return (
    <div className={classNames}>
      <i>{icons[status]}</i>
      <span>{labels[status]}</span>
    </div>
  );
}
