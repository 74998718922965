import React, { useState } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import useCurrentUser from 'hooks/useCurrentUser';
import { useNavigate } from 'react-router-dom';
import { Header, Divider } from 'semantic-ui-react';
import { activateProductKey } from 'actions/productKeys';
import { getPostActivationData } from '../../components/ActivateProductKey/utils';
import {
  Container,
  Message,
  SegmentStyle,
  ButtonBox,
  SelectLanguageButton,
  ConfirmButtonStyle,
  CourseListItemStyle,
  CoursesGroupStyle
} from './Styled';

import { Formik, Form, Checkbox } from 'components/EnhancedForm';
import { useLocationState } from 'hooks/useLocationState';

type Course = {
  id: number;
  name: string;
  language: string;
};

type CourseOptions = {
  course_option: Course[];
};

type ManyCoursesOptions = {
  key: string;
  course_options: CourseOptions[];
  courses: Course[];
};

const LANGUAGES: { [key: string]: string } = {
  en: 'English',
  es: 'Spanish',
  fr: 'French'
};

function UserChoiceProductKey() {
  const currentUser = useCurrentUser();
  const state = useLocationState<ManyCoursesOptions>();
  const [formValues, setFormValues] = useState(getInitialValues());
  const languages = getLanguages();
  const navigate = useNavigate();

  function submitForm(values: any) {
    const data = {
      key: state.key,
      sis_course_ids: Object.values(values)
    };

    activateProductKey(data).then((res: any) => {
      const data = getPostActivationData(res.data);
      navigate(data.path, { state: data.state });
    });
  }

  function getLanguages() {
    const languages = state.course_options
      .map(options => options.course_option.map(course => course.language))
      .flat();
    return languages.filter((item, index) => languages.indexOf(item) === index);
  }

  function getButtonTextByLanguage(language: string): string {
    switch (language) {
      case 'English':
        return i18n.ft(messages.allEnglish);
      case 'Spanish':
        return i18n.ft(messages.allSpanish);
      default:
        return i18n.ft(messages.allFrench);
    }
  }

  function getCurrentUserLanguage(): string {
    return LANGUAGES[currentUser.preferred_language] || 'English';
  }

  function getInitialValues(): any {
    const userLanguage = getCurrentUserLanguage();
    const ids = state.course_options
      .map(
        options =>
          options.course_option.find(
            course => course.language === userLanguage
          ) || options.course_option[0]
      )
      .map(option => option.id);

    return Object.assign({}, ids);
  }

  function selectAllByLanguage(language: string) {
    const ids = state.course_options
      .map(options =>
        options.course_option
          .filter(course => course.language === language)
          .map(item => item.id)
      )
      .flat();
    setFormValues(ids);
  }

  return (
    <Container>
      <SegmentStyle>
        <Header>{i18n.ft(messages.header)}</Header>
        <Message>
          <p>{i18n.ft(messages.message)}</p>
          <Formik
            initialValues={formValues}
            onSubmit={submitForm}
            enableReinitialize
          >
            <Form>
              <ButtonBox>
                {languages.map((language, idx) => (
                  <SelectLanguageButton
                    key={idx}
                    primary
                    type="button"
                    onClick={() => selectAllByLanguage(language)}
                    color="blue"
                    size="mini"
                    content={getButtonTextByLanguage(language)}
                  />
                ))}
              </ButtonBox>

              <Divider />

              {state.course_options.map((course_option, idx) => {
                return (
                  <CoursesGroupStyle key={idx}>
                    {course_option.course_option.map(course => (
                      <Checkbox
                        radio
                        key={course.id}
                        name={`${idx}`}
                        label={course.name}
                        value={course.id}
                        disabled={false}
                      />
                    ))}
                    <Divider />
                  </CoursesGroupStyle>
                );
              })}

              {state.courses?.length > 0 && (
                <h4>{i18n.ft(messages.singleCourses)}</h4>
              )}
              <ul>
                {state.courses.map(course => (
                  <CourseListItemStyle key={course.id}>
                    {course.name}
                  </CourseListItemStyle>
                ))}
              </ul>

              <ConfirmButtonStyle type="submit" primary>
                {i18n.ft(messages.confirm)}
              </ConfirmButtonStyle>
            </Form>
          </Formik>
        </Message>
      </SegmentStyle>
    </Container>
  );
}

export default UserChoiceProductKey;
