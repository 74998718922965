import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Card = styled(Link)`
  flex: 1 0 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 150ms ease-in-out;
  background-color: #ffffff;
  border-radius: 6px;
  border-top: 5px solid ${p => p.color};
  color: initial;

  &:hover {
    color: initial;
    cursor: pointer;
    box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.5);
  }

  &.disabled:hover {
    cursor: default;
    box-shadow: none;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Description: any = styled.div`
  display: flex;
  flex-direction: row;
`;

Description.TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
