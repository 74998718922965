const PATH = 'fe.components.measure.back_to_top';

const messages = {
  backToTop: {
    key: `${PATH}.back_to_top`,
    defaultValue: 'Back to top'
  }
};

export default messages;
