const PATH = 'fe.components.trainings.view.details.courses_section';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: "What's included?"
  },
  reliability: {
    key: `${PATH}.reliability`,
    defaultValue: 'Reliability Test'
  },
  weeksOfAccess: {
    key: `${PATH}.weeks_of_access`,
    defaultValue: '8 weeks of access'
  },
  monthsOfAccess: {
    key: `${PATH}.months_of_access`,
    defaultValue: '{{count}} months of access'
  },
  uponStart: {
    key: `${PATH}.upon_start`,
    defaultValue: 'Available upon start'
  },
  uponCompletion: {
    key: `${PATH}.upon_completion`,
    defaultValue: 'Available upon completion'
  },
  uponRegistration: {
    key: `${PATH}.upon_registration`,
    defaultValue: 'Available upon registration'
  },
  accessSuspended: {
    key: `${PATH}.access_suspended`,
    defaultValue: 'Your access has been suspended'
  },
  accessExpired: {
    key: `${PATH}.access_expired`,
    defaultValue: 'Your access has expired'
  },
  goToCourse: {
    key: `${PATH}.go_to_course`,
    defaultValue: 'Go to course'
  },
  goToCertifications: {
    key: `${PATH}.go_to_certifications`,
    defaultValue: 'Go to certifications'
  }
};

export default messages;
