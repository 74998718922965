const PATH = 'fe.pages.users.cm_login';

const messages = {
  signUp: {
    key: `${PATH}.sign_up`,
    defaultValue: 'Sign Up'
  },
  haveAnAccount: {
    key: `${PATH}.have_an_account`,
    defaultValue: "Don't have an account?"
  }
};

export default messages;
