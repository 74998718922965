import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import Spacer from 'components/Spacer';
import MainContent from 'components/MainContent';
import PageLoader from 'components/PageLoader/PageLoader';
import { Button, InputOnChangeData, Message } from 'semantic-ui-react';
import certificationsSVG from 'images/dashboard/certification.svg';
import useProductKey from 'components/ActivateProductKey/useProductKey';
import CertificationList from 'components/Certifications/CertificationList';
import TestList from 'components/Certifications/TestList';
import { useLocationState } from 'hooks/useLocationState';
import SecondaryButton from 'components/SecondaryButton';
import LoggedMessage from 'components/LoggedMessage';

import {
  HeroBackground,
  HeroContainer,
  HeroMain,
  HeroIcon,
  HeroLinks,
  MobileProductKey,
  MobileButton,
  MobileInput,
  ProductKey,
  ProductKeyForm,
  Title,
  Subtitle,
  Label,
  Input,
  Error,
  MessageWrapper
} from './Styled';

type LocationState = {
  showRedeemTokenError: boolean;
  showSuccess: boolean;
  tokenType: string;
};

const Certifications: React.FC = (...props) => {
  const pKeyRequest = useProductKey();
  const [productKey, setProductKey] = useState('');
  const state = useLocationState<LocationState>();
  const [showMessage, setShowMessage] = useState(state?.showSuccess);

  // Used to trigger a reload on certifications when a CGC token is used.
  const [timestamp, setTimestamp] = useState(Date.now());

  // The location state will change if a CGC token is successfully used.
  useEffect(() => {
    if (state?.showSuccess && pKeyRequest.isSuccess) {
      setProductKey('');
      setShowMessage(true);
      setTimestamp(Date.now());
    }
  }, [state, pKeyRequest.isSuccess]);

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    if (pKeyRequest.isLoading) {
      return;
    }

    if (isEmpty(data.value)) {
      pKeyRequest.reset();
    }

    setProductKey(data.value);
  }

  function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  function handleSubmit() {
    window.localStorage.removeItem('unified_token');
    pKeyRequest.submit(productKey);
  }

  function isRecertification(): boolean {
    return state?.tokenType === 'test_template';
  }

  function getStatusMessage(): string {
    if (
      isRecertification() &&
      state?.showSuccess &&
      state?.showRedeemTokenError
    ) {
      return i18n.ft(messages.recertificationFail);
    }

    if (isRecertification()) {
      return i18n.ft(messages.recertificationSuccess);
    }

    return i18n.ft(messages.productKeySuccess);
  }

  return (
    <MainContent>
      <HeroBackground>
        <HeroContainer>
          <HeroIcon alt={i18n.ft(messages.iconAlt)} src={certificationsSVG} />
          <HeroMain>
            <Title>{i18n.ft(messages.header)}</Title>
            <Spacer size={16} />
            <Subtitle>{i18n.ft(messages.subheader)}</Subtitle>

            <ProductKey onSubmit={handleFormSubmit}>
              <Label htmlFor="productKey">
                {i18n.ft(messages.useProductKey)}
              </Label>
              <ProductKeyForm>
                <Input
                  id="productKey"
                  value={productKey}
                  onChange={handleChange}
                  placeholder={i18n.ft(messages.enterProductKey)}
                />
                <Button
                  color="blue"
                  onClick={handleSubmit}
                  loading={pKeyRequest.isLoading}
                  disabled={pKeyRequest.isLoading}
                  content={i18n.ft(messages.activateProductKey)}
                />
              </ProductKeyForm>
              {pKeyRequest.isError && <Error>{pKeyRequest.error}</Error>}
            </ProductKey>

            <MobileProductKey onSubmit={handleFormSubmit}>
              <div>
                <Label htmlFor="productKeyMobile">
                  {i18n.ft(messages.useProductKey)}
                </Label>
                <MobileInput
                  fluid
                  id="productKeyMobile"
                  value={productKey}
                  onChange={handleChange}
                  placeholder={i18n.ft(messages.enterProductKey)}
                />
              </div>
              {pKeyRequest.isError && <Error>{pKeyRequest.error}</Error>}

              <MobileButton>
                <Button
                  fluid
                  color="blue"
                  onClick={handleSubmit}
                  loading={pKeyRequest.isLoading}
                  disabled={pKeyRequest.isLoading}
                  content={i18n.ft(messages.activateProductKey)}
                />
              </MobileButton>
            </MobileProductKey>
          </HeroMain>

          <HeroLinks>
            <SecondaryButton
              fluid
              content={i18n.ft(messages.learnMore)}
              icon="arrow right"
              as="a"
              href="https://teachstone.com/class-certifications"
            />
            <Spacer size={12} />
            <SecondaryButton
              fluid
              content={i18n.ft(messages.engageCommunity)}
              icon="arrow right"
              as="a"
              href={RouteHelpers.getPath('community')}
            />
          </HeroLinks>
        </HeroContainer>
      </HeroBackground>

      {showMessage && (
        <MessageWrapper>
          <LoggedMessage
            positive={!state.showRedeemTokenError}
            negative={state.showRedeemTokenError}
            onDismiss={() => setShowMessage(false)}
            message={getStatusMessage()}
            severity={state.showRedeemTokenError ? 'error' : 'info'}
          >
            <Message.Header>{getStatusMessage()}</Message.Header>
          </LoggedMessage>
        </MessageWrapper>
      )}

      {pKeyRequest.isLoading ? <PageLoader /> : <TestList />}
      <CertificationList key={timestamp} />
    </MainContent>
  );
};

export default Certifications;
