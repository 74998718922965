import i18n from 'helpers/i18n';
import messages from './messages';
import React, { useState } from 'react';
import { Button, Modal, ModalProps } from 'semantic-ui-react';
import { inviteCanceledTrainingParticipants } from 'actions/trainings';

interface InviteCanceledModalProps extends ModalProps {
  /** Callback fired when the modal requests to be closed. */
  onClose: () => void;

  /** Callback fired after the participants are canceled.  */
  afterCancel: () => void;

  /** Array of participant Ids to be mark as canceled. */
  participantIds: number[];

  /** Training ID. */
  trainingId: number;
}

const InviteCanceledModal: React.FC<InviteCanceledModalProps> = ({
  trainingId,
  afterCancel,
  participantIds,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  function handleCancel() {
    setLoading(true);
    inviteCanceledTrainingParticipants(trainingId, {
      participant_ids: participantIds
    })
      .then(() => {
        setLoading(false);
        afterCancel();
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <Modal {...props}>
      <Modal.Content>{i18n.ft(messages.areYouSure)}</Modal.Content>
      <Modal.Actions>
        <Button
          disabled={loading}
          onClick={props.onClose}
          content={i18n.ft(messages.dismiss)}
        />
        <Button
          color="green"
          loading={loading}
          disabled={loading}
          onClick={handleCancel}
          content={i18n.ft(messages.confirm)}
        />
      </Modal.Actions>
    </Modal>
  );
};

InviteCanceledModal.defaultProps = {
  size: 'small'
};

export default InviteCanceledModal;
