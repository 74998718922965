import i18n from 'helpers/i18n';
import messages from './messages';
import Title from 'design-system/Title';
import useCurrentUser from 'hooks/useCurrentUser';
import useFeatureFlags from 'hooks/useFeatureFlags';
import OrganizationHome from 'pages/Organization/Home';
import OrganizationMembers from 'pages/Organization/Members';
import OrganizationAddMembers from 'pages/Organization/AddMembers';
import OrganizationViewMember from 'pages/Organization/ViewMember';
import AccountManager from 'components/Organization/AccountManager';
import { useGetAccountsByArea } from 'actions/accountManager/accounts';
import { getCurrentAccount } from 'components/Organization/AccountManager/utils';
import { Routes, Route } from 'react-router-dom';

function OrganizationSwitch() {
  const flags = useFeatureFlags();

  if (flags.isFeatureFlagEnabled('manage_account_members')) {
    return (
      <Routes>
        <Route path="/" element={<OrganizationRoot />} />
        <Route path="/add-members" element={<OrganizationAddMembers />} />
        <Route path="/members/:memberId" element={<OrganizationViewMember />} />
      </Routes>
    );
  } else {
    return <OrganizationHome />;
  }
}

const AREA = 'ManageOrganization';

function OrganizationRoot() {
  const currentUser = useCurrentUser();
  const { data = [], isLoading } = useGetAccountsByArea(AREA);
  const accountId = getCurrentAccount(currentUser.current_account_id, data);

  return (
    <>
      <AccountManager
        area="ManageOrganization"
        accounts={data}
        currentAccountId={accountId}
        isLoadingAccounts={isLoading}
      />

      <main className="px-6 xl:px-14 text-black font-sans">
        <Title>{i18n.ft(messages.manageOrganization)}</Title>

        <div className="mt-4 border-b border-[#889EBB]">
          <span className="inline-flex items-center gap-1.5 border-b-2 border-[#6E2262] text-[#6E2262]">
            <i className="fa-solid fa-users" />
            <span className="text-base font-semibold">
              {i18n.ft(messages.members)}
            </span>
          </span>
        </div>

        {!accountId ? (
          <div className="mt-8">{i18n.ft(messages.selectAccount)}</div>
        ) : (
          <OrganizationMembers key={accountId} accountId={accountId} />
        )}
      </main>
    </>
  );
}

export default OrganizationSwitch;
