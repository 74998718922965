import axios from 'axios';

interface testPreparationProps {
  coding_video_id: number;
  test_template_id: number;
}

type UserAnswer = {
  coding_video_id: number;
  dimension_id: number;
  answer: number;
  id?: number;
};

export function createTestPreparations(
  userId: number,
  testPreparation: testPreparationProps
) {
  return axios.post(`/api/v2/users/${userId}/test_preparations`, {
    ...testPreparation
  });
}

export function getTestPreparations(userId: number, testPreparationId: number) {
  return axios.get(
    `/api/v2/users/${userId}/test_preparations/${testPreparationId}`
  );
}

export function createUserAnswers(
  testPreparationId: string,
  userAnswer: UserAnswer
) {
  return axios.post(
    `/api/v2/test_preparations/${testPreparationId}/user_answers`,
    {
      user_answer: { ...userAnswer }
    }
  );
}

export function updateUserAnswers(
  testPreparationId: string,
  userAnswer: UserAnswer
) {
  return axios.put(
    `/api/v2/test_preparations/${testPreparationId}/user_answers/${userAnswer.id}`,
    { user_answer: { ...userAnswer } }
  );
}

export function evaluateTestPreparation(testPreparationId: number) {
  return axios.post(
    `/api/v2/test_preparations/${testPreparationId}/evaluate`,
    {}
  );
}

export function getScores(testPreparationId: number) {
  return axios.get(`/api/v2/test_preparations/${testPreparationId}/scores`);
}

export function resetTestPreparation(testPreparationId: number) {
  return axios.post(`/api/v2/test_preparations/${testPreparationId}/reset`);
}
