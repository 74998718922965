import styled from 'styled-components';

export const ResourceItemLink = styled.a`
  color: #326bf1;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
`;

export const ResourceItemContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`;
