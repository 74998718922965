import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
  background-color: ${p => p.theme.colors['white']};
  max-width: 707px;
  margin: 0 auto;
  margin-top: 16px;

  @media screen and (min-width: 640px) {
    padding: 44px;
  }
`;

export const TopWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const IconButton = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  padding: 0;
  color: ${p => p.theme.colors['neutral']['400']};
  position: absolute;
  top: -4px;
  right: -8px;
  cursor: pointer;
`;
