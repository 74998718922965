import React, { useEffect, useState } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { getTemplates } from 'actions/templates';
import TrainingList from 'components/Trainings/TrainingList';
import PageLayout from 'components/PageLayout';
import { Header, Button, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import StatusFilter from 'components/Trainings/StatusFilter';
import Permission from 'components/Subscription/Permission';
import { DateInput } from 'semantic-ui-calendar-react';
import { isEmpty, isNil } from 'ramda';
import { useQueryState, useQueryStringUpdate } from './utils';
import useCurrentUser from 'hooks/useCurrentUser';

import {
  HeaderContainer,
  FieldsContainer,
  SearchField,
  DateField,
  FieldLabel
} from './Styled';

function Home() {
  const [templates, setTemplates] = useState([]);
  const [query, setQuery] = useQueryState('query');
  const [status, setStatus] = useQueryState('status');
  const [startDate, setStartDate] = useQueryState('startDate');
  const [endDate, setEndDate] = useQueryState('endDate');
  const currentUser = useCurrentUser();
  useQueryStringUpdate({ query, status, startDate, endDate });

  useEffect(() => {
    getTemplates({ per_page: 10000 }).then(({ data }) => {
      setTemplates(data.training_templates);
    });
  }, []);

  return (
    <PageLayout>
      <HeaderContainer>
        <Header as="h1" size="huge">
          {i18n.ft(messages.trainings)}
          <Header.Subheader>
            {i18n.ft(messages.selectTraining)}
          </Header.Subheader>
        </Header>

        <Permission
          zone="trainer_dashboard"
          resource="trainings"
          operation="create"
          or={templates.length > 0}
        >
          <Button
            as={Link}
            to={RouteHelpers.getPath('td-catalog')}
            color="green"
          >
            {i18n.ft(messages.createTraining)}
          </Button>
        </Permission>
      </HeaderContainer>

      <StatusFilter value={status} onChange={setStatus} />

      <FieldsContainer>
        <SearchField>
          <FieldLabel htmlFor="search">{i18n.ft(messages.search)}</FieldLabel>
          <Input
            fluid
            id="search"
            value={query}
            onChange={(e, data) => setQuery(data.value)}
            placeholder={i18n.ft(messages.searchBy)}
            icon={
              isEmpty(query)
                ? 'search'
                : { link: true, name: 'times', onClick: () => setQuery('') }
            }
          />
        </SearchField>

        <DateField>
          <FieldLabel htmlFor="startDate">
            {i18n.ft(messages.startDate)}
          </FieldLabel>
          <DateInput
            clearable
            id="startDate"
            value={startDate}
            hideMobileKeyboard
            dateFormat="MM/DD/YYYY"
            popupPosition="bottom left"
            placeholder={i18n.ft(messages.anyDate)}
            onChange={(e, data) => setStartDate(data.value)}
            localization={
              currentUser && !isNil(currentUser.preferred_language)
                ? currentUser.preferred_language
                : 'en'
            }
          />
        </DateField>

        <DateField>
          <FieldLabel htmlFor="endDate">{i18n.ft(messages.endDate)}</FieldLabel>
          <DateInput
            clearable
            id="endDate"
            value={endDate}
            hideMobileKeyboard
            dateFormat="MM/DD/YYYY"
            popupPosition="bottom left"
            placeholder={i18n.ft(messages.anyDate)}
            onChange={(e, data) => setEndDate(data.value)}
            localization={
              currentUser && !isNil(currentUser.preferred_language)
                ? currentUser.preferred_language
                : 'en'
            }
          />
        </DateField>
      </FieldsContainer>

      <TrainingList
        status={status}
        query={query}
        startDate={startDate}
        endDate={endDate}
      />
    </PageLayout>
  );
}

export default Home;
