const PATH = 'fe.components.organization.account_manager';

const messages = {
  selectAccount: {
    key: `${PATH}.select_account`,
    defaultValue: 'Select an account'
  }
};

export default messages;
