import { useEffect } from 'react';
import * as R from 'ramda';
import Sidebar from 'components/Dashboard/Sidebar';
import i18n from 'helpers/i18n';
import { getPossessiveUserFirstName } from 'helpers/user';
import useAsync from 'hooks/useAsync';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from 'hooks/useCurrentUser';
import { fetchUserMe } from 'actions/userMe';
import { getUserCertifications } from 'actions/certifications';
import { useDispatch } from 'react-redux';
import { update } from 'store/slices/user';
import { getSisEnrollments } from 'actions/users';
import ProfessionalDevelopment from 'components/Dashboard/ProfessionalDevelopment';
import DashboardTile from 'components/Dashboard/DashboardTile';
import CommunityIcon from 'images/dashboard/people.svg';
import CoachingIcon from 'images/dashboard/teacher.svg';
import HealthIcon from 'images/dashboard/health.svg';
import CertificationIcon from 'images/dashboard/share.svg';
import { getTrainings } from 'actions/trainings';
import { getMyRecommendations } from 'actions/userMe';

import {
  PageWrapper,
  Title,
  LeftSide,
  MidContainer,
  DashboardContainer,
  RightTiles,
  CenterTiles
} from './Styled';
import messages from './messages';
import { useMediaQuery } from 'react-responsive';
import { SERVICE_KEYS } from 'constants/services';
import CommunityWidget from 'components/Dashboard/CommunityWidget';
import CertificationsTrainings from 'components/Dashboard/CertificationsTrainings';

function DashboardCopy() {
  //constants
  const communityServices: SERVICE_KEYS[] = ['Community'];
  const coachingServices: SERVICE_KEYS[] = ['myCoach Connect', 'MMCI', 'MTP'];
  const trackDataServices: SERVICE_KEYS[] = [
    'Environmental Scales',
    'Observation',
    'Calibration'
  ];

  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const userName = getPossessiveUserFirstName(
    currentUser.first_name,
    currentUser.preferred_language
  );
  const { run, data: enrollments, isSuccess } = useAsync();
  const {
    run: runCert,
    data: certification,
    isPending: isPendingCert
  } = useAsync();
  const {
    run: runTrainer,
    data: trainings,
    isPending: isPendingTrainings
  } = useAsync();
  const {
    run: runRecommendations,
    data: recommendations,
    isPending: isPendingRecommendations
  } = useAsync();
  const isTablet = useMediaQuery({ minWidth: 1024 });
  //keys and checks if the tiles should be display
  let serviceKeys: SERVICE_KEYS[] = currentUser.services.map(
    service => service.key
  );
  const showLearningResources = serviceKeys.includes('PDLibrary');
  const showCommunity = serviceKeys.includes('Community');
  const showCoaching = coachingServices.some(serv =>
    serviceKeys.includes(serv)
  );
  const showTrackData = trackDataServices.some(serv =>
    serviceKeys.includes(serv)
  );

  useEffect(() => {
    fetchUserMe().then(response => {
      dispatch(update(response.data));
    });
  }, [dispatch]);

  useEffect(() => {
    document.title = i18n.ft(messages.userDashboard, {
      name: userName
    });
  }, [userName]);

  useEffect(() => {
    runRecommendations(getMyRecommendations());
  }, [runRecommendations]);

  useEffect(() => {
    run(getSisEnrollments(currentUser.id));
  }, [run, currentUser.id]);

  useEffect(() => {
    runCert(getUserCertifications(currentUser.id));
  }, [runCert, currentUser.id]);

  useEffect(() => {
    runTrainer(getTrainings({ query: currentUser.email, status: 'active' }));
  }, [runTrainer, currentUser.email]);

  if (currentUser.id && !currentUser.onboarding_viewed_at) {
    navigate('/users/onboarding', { replace: true });
  }

  return (
    <PageWrapper>
      {!isTablet ? (
        <Title>
          <h1>{i18n.ft(messages.userDashboard, { name: userName })}</h1>
          <p>{i18n.ft(messages.subtitle)}</p>
        </Title>
      ) : null}
      <LeftSide>
        <Sidebar />
      </LeftSide>
      <DashboardContainer>
        {isTablet ? (
          <Title>
            <h1>{i18n.ft(messages.userDashboard, { name: userName })}</h1>
            <p>{i18n.ft(messages.subtitle)}</p>
          </Title>
        ) : null}

        <MidContainer>
          {R.isEmpty(currentUser.services) ? (
            <div>
              <div>
                <p>
                  {i18n.ft(messages.noAvailable)}
                  <a href="https://teachstone.com/resources/">
                    {i18n.ft(messages.publicWebsite)}
                  </a>
                </p>
              </div>
              <div>
                <p>{i18n.ft(messages.contactMessage)}</p>
              </div>
            </div>
          ) : (
            <>
              <CenterTiles>
                {isSuccess && !isPendingRecommendations ? (
                  <ProfessionalDevelopment
                    enrollments={enrollments.user_sis_enrollments}
                    learningResources={showLearningResources}
                    recommendations={recommendations}
                  />
                ) : null}
                <DashboardTile
                  services={trackDataServices}
                  existingServices={serviceKeys}
                  preferredLanguage={currentUser.preferred_language}
                  title={i18n.ft(messages.tiles.trackData)}
                  icon={HealthIcon}
                  showTile={showTrackData}
                  isRightTile={false}
                />
              </CenterTiles>
              <RightTiles>
                {!isPendingCert && !isPendingTrainings && (
                  <CertificationsTrainings
                    certifications={certification.user_certifications}
                    icon={CertificationIcon}
                    title={i18n.ft(messages.tiles.certificationTitle)}
                    trainings={trainings.trainings}
                  />
                )}
                <DashboardTile
                  showTile={showCoaching}
                  services={coachingServices}
                  existingServices={serviceKeys}
                  preferredLanguage={currentUser.preferred_language}
                  icon={CoachingIcon}
                  title={i18n.ft(messages.tiles.coachingTitle)}
                />
                <DashboardTile
                  showTile={showCommunity}
                  services={communityServices}
                  existingServices={serviceKeys}
                  preferredLanguage={currentUser.preferred_language}
                  icon={CommunityIcon}
                  title={i18n.ft(messages.tiles.communityTitle)}
                  content={<CommunityWidget />}
                />
              </RightTiles>
            </>
          )}
        </MidContainer>
      </DashboardContainer>
    </PageWrapper>
  );
}

export default DashboardCopy;
