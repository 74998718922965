import styled from 'styled-components';

export const DualRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const DualRowItem = styled.div`
  width: calc((100% - 16px) / 2);

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;
