const PATH =
  'fe.components.measure.observation_videos.approve_in_progress_modal';

const messages = {
  numOfCycles: {
    key: `${PATH}.num_of_cycles`,
    defaultValue: '{{count}} Cycles'
  },
  content: {
    key: `${PATH}.content`,
    defaultValue:
      "You've now finished coding and won't be able to return to view the video once you start entering scores."
  },
  cyclesLabel: {
    key: `${PATH}.cycles_label`,
    defaultValue: 'How many cycles are you going to enter?'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'Error:'
  },
  enterScores: {
    key: `${PATH}.enter_scores`,
    defaultValue: 'Enter Scores'
  },
  approveVideo: {
    key: `${PATH}.approve_video`,
    defaultValue: 'Approve Video'
  }
};

export default messages;
