import { PageContainer } from './Styled';
import ContainerWithLogo from 'components/ContainerWithLogo';
import ForgotPasswordForm from 'components/Users/ForgotPassword';

const ForgotPassword: React.FC = () => {
  return (
    <PageContainer>
      <ContainerWithLogo>
        <ForgotPasswordForm />
      </ContainerWithLogo>
    </PageContainer>
  );
};

export default ForgotPassword;
