import * as R from 'ramda';
import { UserMe } from 'types/api/User';

export function getInitials({ first_name = 'A', last_name = 'A' }) {
  const first = first_name.length ? first_name.trim() : 'A';
  const last = last_name.length ? last_name.trim() : 'A';

  return `${first[0].toUpperCase()}${last[0].toUpperCase()}`;
}

export function getFullName({ first_name = '', last_name = '' }) {
  return `${first_name} ${last_name}`;
}

export function getPossessiveUserFirstName(first_name: string, lang: string) {
  if (R.type(first_name) === 'String') {
    first_name = R.trim(first_name);
    if (lang === 'es') {
      return first_name;
    }

    return R.slice(-1, Infinity, R.toLower(first_name)) === 's'
      ? first_name + "'"
      : first_name + "'s";
  }
}

export function getShortName(user: UserMe) {
  const firstNameShorten = user.first_name && user.first_name.split(' ')[0];
  const lastNameShorten = user.last_name && user.last_name[0].toUpperCase();

  return `${firstNameShorten} ${lastNameShorten}.`;
}
