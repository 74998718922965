import i18n from 'helpers/i18n';
import messages from './messages';
import { useMediaQuery } from 'react-responsive';

export default function WatchedVideo(props: { id: number }) {
  const { id } = props;
  const isTablet = useMediaQuery({ minWidth: 768 });

  return (
    <>
      <div
        className={`${
          isTablet ? 'w-12 h-12' : 'w-10 h-10'
        } bg-cyan-700 border-2 border-cyan-700 mx-auto rounded-full flex items-center`}
      >
        <span className="text-white text-center w-full">
          {isTablet ? (
            <div className="bg-cyan-700 rounded-full flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-check"
                width={'24px'}
                height={'24px'}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#FFFFFF"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
            </div>
          ) : (
            <div className="w-10 h-10 bg-cyan-700 mx-auto rounded-full flex items-center">
              <span className="text-current text-center w-full text-lg">
                {id + 1}
              </span>
            </div>
          )}
        </span>
      </div>
      {isTablet ? (
        <div className="text-sm pt-2 whitespace-nowrap">
          {i18n.ft(messages.videoNumber, { value: id + 1 })}
        </div>
      ) : null}
    </>
  );
}
