import styled from 'styled-components';
import { fadeInAndOutStyles, slideInAndOutStyles } from './utils';

export const DialogWrapper = styled.div`
  @media screen and (min-width: 1280px) {
    display: none;
  }
`;

export const DialogOverlay = styled.div`
  ${fadeInAndOutStyles};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Wrapper = styled.div`
  ${slideInAndOutStyles};
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 640px) {
    left: unset;
    width: 320px;
  }
`;

export const Header = styled.div`
  padding: 22px 24px;
  background-color: ${p => p.theme.colors['primary']['700']};
  color: white;
  display: flex;
  justify-content: space-between;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarName = styled.span`
  font-weight: 700;
  color: white;
  font-size: 16px;
  line-height: 24px;
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListButton = styled.button`
  font-family: inherit;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${p => p.theme.colors['neutral']['700']};
  border: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: #f0fbff;
  border-left: 5px solid ${p => p.theme.colors['primary']['700']};

  &:hover,
  &:focus {
    outline: 0;
    cursor: pointer;
    background-color: #d1f4ff;
    color: ${p => p.theme.colors['neutral']['700']};
  }
`;

export const ListItem = styled.a`
  color: inherit;
  display: block;
  padding: 12px 24px;
  padding-left: 48px;

  &:hover,
  &:focus {
    outline: 0;
    cursor: pointer;
    color: inherit;
    background-color: #f5f6f7;
  }
`;

export const FooterList = styled(List)`
  margin-top: auto;
  background-color: ${p => p.theme.colors['primary']['700']};
`;

export const FooterListButton = styled.button`
  display: block;
  padding: 12px 24px;
  color: white;
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  border: 0;
  width: 100%;
  text-align: left;
  background-color: ${p => p.theme.colors['primary']['700']};

  &:hover,
  &:focus {
    outline: 0;
    cursor: pointer;
    color: white;
    background-color: #006c90;
  }
`;

export const FooterListDivider = styled.hr`
  margin: 8px 24px;
  max-width: 96px;
`;
