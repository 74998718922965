import { isNil } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import styled from 'styled-components';
import React, { useState } from 'react';
import PageLayout from 'components/PageLayout';
import { Header, Button, Icon } from 'semantic-ui-react';
import { useLocation, Link } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import PaymentForm from 'components/Trainings/View/Checkout/PaymentForm';

const Description = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
`;

type LocationState = {
  participants?: number[];
};

type LocationParams = {
  id?: number;
};

const Checkout: React.FC = () => {
  const location = useLocation();
  const params = useRouteParams() as LocationParams;
  const state = location.state as LocationState;
  const [paid, setPaid] = useState(false);

  function getNumberOfParticipants(): number {
    if (isNil(state) || isNil(state.participants)) {
      return 0;
    }

    return state.participants.length;
  }

  function handleAfterSubmit() {
    setPaid(true);
  }

  return (
    <PageLayout>
      <Header as="h1" size="huge">
        {i18n.ft(messages.selectPayment)}
        <Header.Subheader>
          {i18n.ft(messages.payingFor, { count: getNumberOfParticipants() })}
        </Header.Subheader>
      </Header>

      {paid ? (
        <React.Fragment>
          <Description>
            <Icon name="check circle" color="green" />
            {i18n.ft(messages.paymentCompleted)}
          </Description>

          <Button
            replace
            as={Link}
            to={`/trainings/${params.id}/participants`}
            content={i18n.ft(messages.returnToTraining)}
          />
        </React.Fragment>
      ) : (
        <PaymentForm
          trainingId={params.id}
          participants={state.participants}
          afterSubmit={handleAfterSubmit}
        />
      )}
    </PageLayout>
  );
};

export default Checkout;
