const PATH = 'fe.components.organization.role_popup';

const messages = {
  seeOnly: {
    key: `${PATH}.see_only`,
    defaultValue: 'See Only:'
  },
  none: {
    key: `${PATH}.none`,
    defaultValue: 'None Assigned'
  },
  primaryAdmin: {
    key: `${PATH}.primary_admin`,
    defaultValue: 'Primary Administrator'
  }
};

export default messages;
