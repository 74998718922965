import { map, range, filter, isNil, isEmpty } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';
import { Icon, Button, Dropdown } from 'semantic-ui-react';
import { getGroupBaseColor } from 'helpers/ageLevel';
import trainingValidation from './trainingValidation';
import StatesDropdown from 'components/dropdowns/States';
import SearchAccount from 'components/TrainingCatalog/Create/SearchAccount';
import CountriesDropdown from 'components/dropdowns/Countries';
import EligibleTrainersDropdown from 'components/dropdowns/EligibleTrainers';
import Permission from 'components/Subscription/Permission';
import usePermissions from 'hooks/usePermissions';
import useCurrentUser from 'hooks/useCurrentUser';
import { Formik, Form, TimeZoneDropdown } from 'components/EnhancedForm';
import { Training } from 'types/api/Training';
import { TrainingTemplate } from 'types/api/TrainingTemplate';
import { Select as EnhancedSelect } from 'components/EnhancedForm';
import { CenterSelect } from 'components/TrainingCatalog/Create/CenterSelect';
import { FormikErrors } from 'formik';
import { getEligibleTrainers } from 'actions/templates';
import { Trainer } from 'types/api/Trainer';

import {
  formatSession,
  sessionIsValid,
  initialEditValues,
  initialCreateValues,
  URLState
} from './utils';

import {
  Section,
  SectionTop,
  SectionHeader,
  TemplateBanner,
  InputRow,
  Input,
  DateInput,
  VirtualTrainingLinkInput,
  NotesInput,
  NotesRichInput,
  Select,
  FormActions,
  Checkbox
} from './Styled';
import TimePicker from 'components/EnhancedForm/TimePicker';
import { useLocationState } from 'hooks/useLocationState';

const VIRTUAL_FORMAT = 'virtual';
const IN_PERSON_FORMAT = 'in_person';

interface TrainingFormProps {
  edit: boolean;
  training: Training;
  template: TrainingTemplate;
  onSubmit: (
    training: Training,
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
    resetForm?: () => void
  ) => void;
  onCancel: () => void;
  submitLabel: string;
}

type SetFieldValue = (
  field: string,
  value: any,
  shouldValidate?: boolean
) => Promise<void | FormikErrors<any>>;
type SubmitForm = () => Promise<void>;

const StyledDropdown = styled(Dropdown)`
  background-color: #107a6a !important;
  color: white !important;

  &:hover {
    background-color: #096859 !important;
  }

  .selected.item {
    font-weight: normal !important;

    &:not(:hover) {
      background-color: white !important;
    }
  }
`;

function TrainingForm({
  edit,
  template,
  training,
  onCancel,
  onSubmit,
  submitLabel
}: TrainingFormProps): JSX.Element {
  const [submitting, setSubmitting] = useState(false);
  const [selectedTrainerTypeId, setSelectedTrainerTypeId] = useState<
    number | undefined
  >();
  const trainingPermissions = usePermissions('trainer_dashboard', 'trainings');
  const currentUser = useCurrentUser();
  const [trainers, setTrainers] = useState<Trainer[]>([]);
  const [trainersIsLoading, setTrainersIsLoading] = useState(false);
  const locationState = useLocationState<URLState>();

  useEffect(() => {
    setTrainersIsLoading(true);
    getEligibleTrainers(template.id).then(({ data }) => {
      setTrainers(data);
      setTrainersIsLoading(false);
    });
  }, [template.id]);

  const handleTrainerSelect = (trainerId: number) => {
    const selectedTrainer = trainers.find(trainer => trainer.id === trainerId);

    if (selectedTrainer) {
      setSelectedTrainerTypeId(selectedTrainer.type_id);
    }
  };

  function handleSubmit({ sessions, ...values }: any, { resetForm }: any) {
    if (submitting) {
      return;
    }

    const trainingSessions = map(
      (session: any) => formatSession(session, values.time_zone),
      filter(sessionIsValid, sessions)
    );

    let training = {
      training_template_id: template.id,
      sessions: trainingSessions,
      ...values
    };

    // trainer is only set when an user can manage trainings
    // otherwise the current user is the trainer
    if (isNil(training.trainer_id) || isEmpty(training.trainer_id)) {
      training.trainer_id = currentUser.id;
    }

    setSubmitting(true);
    onSubmit(training, setSubmitting, resetForm);
  }

  // require trainer validation only if the dropdown is present, when the user can manage trainings
  function trainerRequired() {
    return trainingPermissions.manage || trainingPermissions.create;
  }

  function getInitialValues() {
    return edit
      ? initialEditValues(template, training)
      : initialCreateValues(template, locationState ?? {});
  }

  const isInPerson = ({ delivery_method }: any) =>
    delivery_method === IN_PERSON_FORMAT;

  const submitOptions = (
    setFieldValue: SetFieldValue,
    submitForm: SubmitForm
  ) => [
    {
      key: 'duplicate',
      text: i18n.ft(messages.createDuplicate),
      value: 'duplicate',
      onClick: () => {
        setFieldValue('submit_type', 'duplicate').then(() => submitForm());
      }
    },
    {
      key: 'new',
      text: i18n.ft(messages.createNew),
      value: 'new',
      onClick: () => {
        setFieldValue('submit_type', 'new').then(() => submitForm());
      }
    }
  ];

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={trainingValidation(trainerRequired())}
      initialValues={getInitialValues()}
    >
      {({ values, setFieldValue, isValid, submitForm }) => (
        <Form id="training-form">
          <Section>
            <SectionTop color={getGroupBaseColor(template.age_levels)} />
            <TemplateBanner src={template.banner} />
            <Typography
              bold
              size="huge"
              color="blue"
              content={template.title}
            />
          </Section>

          {edit && <Input hidden name="id" label="id" />}

          <Section>
            <SectionHeader color="blue">
              <Icon name="calendar" />
              <SectionHeader.Content>
                <Typography bold uppercase>
                  {i18n.ft(messages.sessionDates)}
                </Typography>
              </SectionHeader.Content>
            </SectionHeader>

            <TimeZoneDropdown label="Time zone" name="time_zone" />

            {map(
              nSession => {
                return (
                  <InputRow key={nSession}>
                    {edit && (
                      <Input
                        hidden
                        name={`sessions.${nSession}.id`}
                        label="id"
                      />
                    )}
                    <DateInput
                      placeholder="MM/DD/YYYY"
                      name={`sessions.${nSession}.date`}
                      label={i18n.ft(messages.sessionNumber, {
                        number: nSession + 1
                      })}
                    />

                    <TimePicker
                      name={`sessions.${nSession}.start_time`}
                      interval={15}
                      label={i18n.ft(messages.startTime)}
                      placeholder={i18n.ft(messages.startTime)}
                    />

                    <TimePicker
                      name={`sessions.${nSession}.end_time`}
                      interval={15}
                      label={i18n.ft(messages.endTime)}
                      placeholder={i18n.ft(messages.endTime)}
                    />
                  </InputRow>
                );
              },
              range(0, template.number_of_sessions || 0)
            )}
          </Section>

          <Permission
            zone="trainer_dashboard"
            resource="trainings"
            operation="create"
          >
            <Section>
              <SectionHeader color="blue">
                <Icon name="user" />
                <SectionHeader.Content>
                  <Typography bold uppercase>
                    {i18n.ft(messages.selectTrainer)}
                  </Typography>
                </SectionHeader.Content>
              </SectionHeader>

              <div className="grid md:grid-cols-3 md:gap-4">
                <EnhancedSelect
                  search
                  fluid
                  name="trainer_id"
                  label={i18n.ft(messages.eligibleTrainers)}
                  placeholder={i18n.ft(messages.eligibleTrainers)}
                  control={EligibleTrainersDropdown}
                  trainers={trainers}
                  templateId={template.id}
                  loading={trainersIsLoading}
                  searchInput={{
                    'aria-label': i18n.ft(messages.eligibleTrainers)
                  }}
                  onChange={handleTrainerSelect}
                />

                <SearchAccount
                  name="account_id"
                  label={i18n.ft(messages.client_organization)}
                  initialAccount={training?.account}
                  trainerId={values.trainer_id}
                  selectedTrainerTypeId={selectedTrainerTypeId}
                />

                <CenterSelect />
              </div>
            </Section>
          </Permission>

          <Section>
            <SectionHeader color="blue">
              <Icon name="clipboard" />
              <SectionHeader.Content>
                <Typography bold uppercase>
                  {i18n.ft(messages.trainingDeliveryFormat)}
                </Typography>
              </SectionHeader.Content>
            </SectionHeader>
            <div>
              <Checkbox
                radio
                key="virtual"
                value={VIRTUAL_FORMAT}
                name="delivery_method"
                label={i18n.ft(messages.virtualDeliveryMethod)}
              />
            </div>

            <div>
              <Checkbox
                radio
                key="in_person"
                value={IN_PERSON_FORMAT}
                name="delivery_method"
                label={i18n.ft(messages.inPersonDeliveryMethod)}
              />
            </div>
          </Section>

          <Section>
            <SectionHeader color="blue">
              <Icon name="map marker alternate" />
              <SectionHeader.Content>
                <Typography bold uppercase>
                  {i18n.ft(messages.trainingLocation)}
                </Typography>
              </SectionHeader.Content>
            </SectionHeader>

            <InputRow>
              <Input
                name="location_address_1"
                label={i18n.ft(messages.address, { number: 1 })}
                placeholder={i18n.ft(messages.address01)}
                required={isInPerson(values)}
              />
              <Input
                name="location_address_2"
                label={i18n.ft(messages.address, { number: 2 })}
                placeholder={i18n.ft(messages.address02)}
              />
              <Input
                name="location_city"
                label={i18n.ft(messages.city)}
                placeholder={i18n.ft(messages.city)}
                required={isInPerson(values)}
              />
            </InputRow>
            <InputRow>
              <Select
                search
                fluid
                name="location_country"
                label={i18n.ft(messages.country)}
                placeholder={i18n.ft(messages.country)}
                control={CountriesDropdown}
                searchInput={{
                  'aria-label': i18n.ft(messages.country)
                }}
                required={isInPerson(values)}
              />

              {values.location_country === 'US' ? (
                <Select
                  search
                  fluid
                  upward
                  name="location_state"
                  label={i18n.ft(messages.stateProvince)}
                  placeholder={i18n.ft(messages.stateProvince)}
                  control={StatesDropdown}
                  searchInput={{
                    'aria-label': i18n.ft(messages.stateProvince)
                  }}
                  required={isInPerson(values)}
                />
              ) : (
                <Input
                  name="location_state"
                  label={i18n.ft(messages.stateProvince)}
                  placeholder={i18n.ft(messages.stateProvince)}
                  required={isInPerson(values)}
                />
              )}

              <Input
                name="location_zip"
                label={i18n.ft(messages.postalCode)}
                placeholder={i18n.ft(messages.postalCode)}
                required={isInPerson(values)}
              />
            </InputRow>
            <VirtualTrainingLinkInput
              name="virtual_training_link"
              label={i18n.ft(messages.virtualTrainingLink)}
              placeholder={i18n.ft(messages.virtualTrainingLinkHint)}
            />
          </Section>

          <Section>
            <SectionHeader color="blue">
              <Icon name="book" />
              <SectionHeader.Content>
                <Typography bold uppercase>
                  {i18n.ft(messages.additionalInfo)}
                </Typography>
              </SectionHeader.Content>
            </SectionHeader>

            <NotesInput
              name="notes_title"
              label={i18n.ft(messages.notesTitle)}
            />
            <NotesRichInput
              name="notes"
              label={i18n.ft(messages.notesDescription)}
            />
          </Section>

          <FormActions>
            <Button
              type="button"
              onClick={onCancel}
              disabled={submitting}
              content={i18n.ft(messages.cancel)}
            />
            <Button.Group>
              <Button
                type="submit"
                color="green"
                icon={isValid ? null : 'warning sign'}
                disabled={submitting}
                loading={submitting}
                content={submitLabel}
              />

              {!edit && (
                <StyledDropdown
                  className="button icon"
                  floating
                  disabled={submitting}
                  options={submitOptions(setFieldValue, submitForm)}
                  trigger={<></>}
                />
              )}
            </Button.Group>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
}

TrainingForm.propTypes = {
  edit: PropTypes.bool,
  training: PropTypes.object,
  template: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired
};

export default TrainingForm;
