import styled from 'styled-components';

export const Container = styled.div<{ inline?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => (props.inline ? 'row' : 'column')};

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const ChartLabel = styled.div<{ inline?: boolean }>`
  margin-top: ${props => (props.inline ? 0 : '3px')};
  margin-left: ${props => (props.inline ? '8px' : 0)};
  text-align: ${props => (props.inline ? null : 'center')};

  @media screen and (max-width: 550px) {
    margin-top: 3px;
    margin-left: 0;
    text-align: center;
  }
`;

export const ChartLevel = styled.div<{ color: string }>`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.color};
`;
