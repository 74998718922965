import moment from 'moment';
import i18n from 'helpers/i18n';
import { useState } from 'react';
import messages from './messages';
import { Video } from './Carousel';
import RouteHelpers from 'helpers/routes';
import useAsync from 'hooks/useAsync';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from 'hooks/useCurrentUser';
import VideoThumbnail from 'components/VideoThumbnail';
import {
  getTestPreparations,
  resetTestPreparation
} from 'actions/testPreparations';
import { createTestPreparations } from 'actions/testPreparations';
import ConfirmationModal from 'components/ReliabilityTest/ConfirmationModal/ConfirmationModal';

interface DisplayVideosProps {
  videos: Video[];
  testTemplateId: number;
}

function DisplayVideos({ videos, testTemplateId }: DisplayVideosProps) {
  const { run } = useAsync();
  const { data: testPreparationData, run: testPreparationRun } = useAsync();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [testPreparationId, setTestPreparationId] = useState(0);

  function handleClick(id: number) {
    run(
      createTestPreparations(currentUser.id, {
        coding_video_id: id,
        test_template_id: testTemplateId
      }),
      {
        onSuccess: data => {
          setTestPreparationId(data.id);

          if (!data.completed_at)
            navigate(
              RouteHelpers.getPath('test-preparations', {
                id: data.id
              })
            );
          testPreparationRun(
            getTestPreparations(currentUser.id, Number(data.id))
          );
          const oneYearAgo = moment().subtract(1, 'y');
          const completedAt = moment(data.completed_at);

          if (oneYearAgo.diff(completedAt, 'y') === 0) {
            setShowConfirmationModal(true);
          }
        }
      }
    );
  }

  function handleGoToReport() {
    setShowConfirmationModal(false);
    navigate(
      RouteHelpers.getPath('test-preparations-score-report', {
        id: testPreparationId
      })
    );
  }

  function handleTestReset() {
    resetTestPreparation(Number(testPreparationId))
      .then(
        () => {
          setShowConfirmationModal(false);
        },
        () => {
          /* test reset rejection */
          setShowConfirmationModal(false);
        }
      )
      .then(() => {
        navigate(
          RouteHelpers.getPath('test-preparations', {
            id: testPreparationId
          })
        );
      });
  }

  return (
    <>
      <ConfirmationModal
        title={i18n.ft(messages.completedModal.title, {
          name: testPreparationData
            ? testPreparationData.coding_video.video_type
            : ''
        })}
        message=""
        onClose={() => setShowConfirmationModal(false)}
        dismissButtonText={i18n.ft(messages.completedModal.retakeTest)}
        confirmButtonText={i18n.ft(messages.completedModal.viewScoreReport)}
        isOpen={showConfirmationModal}
        onConfirm={handleGoToReport}
        onCancel={handleTestReset}
      />
      {videos.map((video, idx) => {
        return (
          <VideoThumbnail
            ariaLabel={i18n.ft(messages.slideIndex, {
              index: idx,
              total: videos.length
            })}
            ariaRoleDescription={i18n.ft(messages.slide)}
            handleClick={handleClick}
            video={video}
            key={idx}
          />
        );
      })}
    </>
  );
}

export default DisplayVideos;
