import React from 'react';
import { map, keys } from 'ramda';
import isoCountries from 'i18n-iso-countries';
import FormDropdown from 'components/FormDropdown';

// Register locales for browser usage
isoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
isoCountries.registerLocale(require('i18n-iso-countries/langs/es.json'));
isoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

const countries = isoCountries.getNames('en');
const options = map(
  cKey => ({
    key: cKey,
    value: cKey,
    text: countries[cKey]
  }),
  keys(countries)
);

export function useCountries() {
  return options;
}

const Countries: React.FC = props => {
  return <FormDropdown options={options} {...props} />;
};

export default Countries;
