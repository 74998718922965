import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  width: 192px;
  margin-top: 16px;
`;

export const TextButtonWrapper = styled.div`
  margin-top: 32px;
`;
