import styled from 'styled-components';
import { Icon, Progress } from 'semantic-ui-react';

export const Title = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
`;

export const UploadButtonWrapper = styled.div`
  width: 100%;
  max-width: 265px;
  margin-bottom: 24px;
`;

export function UploadIcon() {
  return (
    <svg
      width="88"
      height="62"
      viewBox="0 0 88 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.578 26.9727C74.1354 25.5098 74.448 23.9102 74.448 22.2422C74.448 14.9961 68.6027 9.11719 61.398 9.11719C58.7201 9.11719 56.2188 9.9375 54.1526 11.332C50.3871 4.76953 43.3591 0.367188 35.298 0.367188C23.2812 0.367188 13.548 10.1562 13.548 22.2422C13.548 22.6113 13.5616 22.9805 13.5752 23.3496C5.96273 26.043 0.498047 33.3438 0.498047 41.9297C0.498047 52.7988 9.26602 61.6172 20.073 61.6172H70.098C79.7088 61.6172 87.498 53.7832 87.498 44.1172C87.498 35.6543 81.5168 28.5859 73.578 26.9727ZM53.9759 35.3672H45.0855V50.6797C45.0855 51.8828 44.1068 52.8672 42.9105 52.8672H36.3855C35.1893 52.8672 34.2105 51.8828 34.2105 50.6797V35.3672H25.3202C23.3763 35.3672 22.4112 33.0156 23.7841 31.6348L38.112 17.2246C38.9548 16.377 40.3413 16.377 41.1841 17.2246L55.512 31.6348C56.8849 33.0156 55.9062 35.3672 53.9759 35.3672Z"
        fill="#017EA7"
      />
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.64968 12.503L4.49374 16.6965C4.4937 16.6965 4.49366 16.6966 4.49361 16.6966C4.23266 16.9574 3.80857 16.9574 3.54766 16.6965L1.01641 14.1652C0.755509 13.9043 0.755466 13.4802 1.01629 13.2193C1.01633 13.2192 1.01637 13.2192 1.01641 13.2191L5.20985 9.06322L5.28151 8.99219L5.20985 8.92116L1.01641 4.76523C1.01636 4.76517 1.01631 4.76512 1.01626 4.76507C0.755466 4.50411 0.755518 4.08004 1.01641 3.81915L3.54766 1.2879C3.80856 1.027 4.23263 1.02695 4.49359 1.28774C4.49364 1.28779 4.49369 1.28785 4.49374 1.2879L8.64968 5.48133L8.7207 5.553L8.79173 5.48133L12.9477 1.2879C12.9477 1.28786 12.9477 1.28781 12.9478 1.28777C13.2087 1.02695 13.6328 1.02699 13.8937 1.2879L16.425 3.81915C16.685 4.0792 16.6859 4.50138 16.4277 4.76254L11.5648 8.91615L11.4758 8.99219L11.5648 9.06822L16.4277 13.2218C16.6859 13.483 16.685 13.9052 16.425 14.1652L13.8937 16.6965C13.6329 16.9574 13.2088 16.9574 12.9479 16.6967C12.9478 16.6966 12.9477 16.6965 12.9477 16.6965L8.79173 12.503L8.7207 12.4314L8.64968 12.503Z"
        fill="#364A5E"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}

export const Indicator = styled.div<{ backgroundColor?: string }>`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 9px;
  box-shadow: 0px 6px 12px -3px rgba(0, 0, 0, 0.4);
  background-color: ${p => p.backgroundColor || 'white'};

  @media screen and (min-width: 640px) {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
`;

export const StyledIcon = styled(Icon)`
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 3px
  font-size: 0.8em !important;;

  @media screen and (min-width: 640px) {
    padding-top: 3px;
    width: 20px !important;
    height: 20px !important;
    font-size: 0.8em !important;;
  }
`;

export const TR = styled.tr`
  border: 0;
`;

export const TD = styled.td`
  background-color: ${p => p.theme.colors.scaleSection};
  padding: 8px;

  &:first-child {
    border-right: 3px solid white;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: hidden;
  }

  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }

  button,
  .ui.basic.button {
    box-shadow: none !important;

    &:hover {
      box-shadow: none !important;
      background: none !important;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 6px;
`;

export const PreviewImage = styled.img`
  max-width: 62px;
  max-height: 52px;
  height: auto;
  border-radius: 4px;
`;

export const PreviewWrapper = styled.div`
  width: 60px;
  height: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 10px;
  min-width: 60px;
`;

export const StyledProgress = styled(Progress)<{
  color: string;
}>`
  height: 8px !important;
  margin: 0 !important;

  div {
    height: 8px !important;
    background-color: ${p => p.theme.colors.scaleGreen} !important;
  }
`;

export const AttachmentName = styled.div`
  font-weight: 700;
  font-size: 16px;
  overflow-wrap: anywhere;
  color: ${p => p.theme.colors.scaleDarkBlue};

  @media screen and (min-width: 768px) {
    overflow-wrap: normal;
  }
`;

export const StatusText = styled.div<{ color: string }>`
  font-weight: 700;
  font-size: 14px;
  color: ${p => p.color};
  white-space: nowrap;
`;

export const AttachmentDate = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${p => p.theme.colors.scaleGray};
`;

export const FileDetails = styled.div`
  position: relative;
  background-color: #f4f8fa;
  border-radius: 12px;
  margin: 14px 0px;
  padding: 14px 14px 6px 14px;
`;

export const DeleteButtonWrapper = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  width: 17px;
  height: 17px;
`;

export const DeleteButton = styled.button`
  width: 100%;
  text-align: center;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Errors = styled.p`
  margin-bottom: 12px;
  color: ${p => p.theme.colors.scaleWarning};
`;

export const SectionWithDashedBorder = styled.div`
  padding: 0 14px;
  background: #f4f8fa;
  border: 1px dashed ${p => p.theme.colors['scaleDarkBlue']};
  border-radius: 12px;

  @media screen and (min-width: 768px) {
    padding: 12px 48px;
  }
`;

export function PdfIcon() {
  return (
    <svg
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9444 0.601562H8.05556C2.41667 0.601562 0 3.50156 0 7.85156V22.3516C0 26.7016 2.41667 29.6016 8.05556 29.6016H20.9444C26.5833 29.6016 29 26.7016 29 22.3516V7.85156C29 3.50156 26.5833 0.601562 20.9444 0.601562ZM8.05556 15.4641H14.5C15.1606 15.4641 15.7083 15.9571 15.7083 16.5516C15.7083 17.1461 15.1606 17.6391 14.5 17.6391H8.05556C7.395 17.6391 6.84722 17.1461 6.84722 16.5516C6.84722 15.9571 7.395 15.4641 8.05556 15.4641ZM20.9444 23.4391H8.05556C7.395 23.4391 6.84722 22.9461 6.84722 22.3516C6.84722 21.7571 7.395 21.2641 8.05556 21.2641H20.9444C21.605 21.2641 22.1528 21.7571 22.1528 22.3516C22.1528 22.9461 21.605 23.4391 20.9444 23.4391ZM24.9722 11.1141H21.75C19.3011 11.1141 17.3194 9.33056 17.3194 7.12656V4.22656C17.3194 3.63206 17.8672 3.13906 18.5278 3.13906C19.1883 3.13906 19.7361 3.63206 19.7361 4.22656V7.12656C19.7361 8.12706 20.6383 8.93906 21.75 8.93906H24.9722C25.6328 8.93906 26.1806 9.43206 26.1806 10.0266C26.1806 10.6211 25.6328 11.1141 24.9722 11.1141Z"
        fill="#017EA7"
      />
    </svg>
  );
}

export function VideoIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4105 0.518555H9.18971C3.88138 0.518555 0.716797 3.68314 0.716797 8.99147V21.1977C0.716797 26.5206 3.88138 29.6852 9.18971 29.6852H21.396C26.7043 29.6852 29.8689 26.5206 29.8689 21.2123V8.99147C29.8835 3.68314 26.7189 0.518555 21.4105 0.518555ZM19.1793 17.6248L17.3126 18.704L15.446 19.7831C13.0397 21.1686 11.071 20.0311 11.071 17.2602V15.1019V12.9436C11.071 10.1581 13.0397 9.03522 15.446 10.4206L17.3126 11.4998L19.1793 12.579C21.5855 13.9644 21.5855 16.2394 19.1793 17.6248Z"
        fill="#017EA7"
      />
    </svg>
  );
}

export const Wrapper = styled.div`
  padding: 10px 20px;
  box-shadow: 1px 1px 11px #d7e6ed;
  border-radius: 12px;
  color: #364a5e;

  @media screen and (min-width: 640px) {
    padding: 32px 64px;
  }
`;
