import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserMe } from 'actions/userMe';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { setPassword, resetPassword, getConfirmation } from 'actions/users';
import { Button, Header } from 'semantic-ui-react';
import { Formik, Form, Input } from 'components/EnhancedForm';
import Checkbox from 'components/EnhancedForm/Checkbox';
import ContainerWithLogo from 'components/ContainerWithLogo';
import { Actions, SubHeader, Errors, PageContainer } from './Styled';
import PhonePrompt from 'components/Users/PhonePrompt';
import Browser from 'helpers/browser';
import validationSchema from './validationSchema';
import * as Yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import useLocale from 'hooks/useLocale';
import { update } from 'store/slices/user';
import queryString from 'query-string';

const initialValues = {
  password: '',
  password_confirmation: '',
  tos_pp_agreed_at: false
};

const steps = {
  SET_PASSWORD: 'setPassword',
  SET_PHONE: 'setPhone'
};

function SetPasswordPage() {
  const [errors, setErrors] = useState([]);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps.SET_PASSWORD);

  const locale = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { confirmation_token, reset_password_token } = queryString.parse(
    location.search
  );

  useEffect(() => {
    if (confirmation_token) {
      getConfirmation(confirmation_token).catch(err => {
        const { errors } = err.response.data;
        setErrors(errors.split(', '));
        setRedirectToLogin(true);
      });
    }
  }, [confirmation_token]);

  function parseErrors(errors) {
    let errorsList = [];

    R.forEach(key => {
      R.forEach(error => {
        let errorMsg = `${R.replace('_', ' ', key)} ${error}`;
        errorMsg = errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
        errorsList = R.concat(errorsList, [errorMsg]);
      }, errors[key]);
    }, R.keys(errors));

    return errorsList;
  }

  function setPasswordWithToken(confirmation_token, values) {
    const password = values.password;
    const password_confirmation = values.password_confirmation;
    const tos_pp_agreed_at = values.tos_pp_agreed_at;

    setPassword({
      user: {
        password,
        password_confirmation,
        preferred_language: locale,
        tos_pp_agreed_at
      },
      confirmation_token
    })
      .then(() => {
        setErrors([]);
        setCurrentStep(steps.SET_PHONE);
      })
      .catch(err => {
        const { errors } = err.response.data;
        setErrors(errors.split(', '));
      });
  }

  function resetPasswordWithToken(reset_password_token, values) {
    const password = values.password;
    const password_confirmation = values.password_confirmation;

    resetPassword({
      user: {
        password,
        password_confirmation,
        reset_password_token
      }
    })
      .then(() => {
        setErrors([]);
        setRedirectToLogin(true);
      })
      .catch(err => {
        const errors = parseErrors(err.response.data.errors);
        setErrors(errors);
      });
  }

  function submitForm(values) {
    if (confirmation_token) {
      setPasswordWithToken(confirmation_token, values);
    } else if (reset_password_token) {
      resetPasswordWithToken(reset_password_token, values);
    }
  }

  function getValidationSchema() {
    let checkboxValidation = Yup.object({
      tos_pp_agreed_at: Yup.bool()
        .required(i18n.ft(messages.errors.checkbox.required))
        .oneOf([true], i18n.ft(messages.errors.checkbox.required))
    });

    return reset_password_token !== undefined
      ? validationSchema
      : validationSchema.concat(checkboxValidation);
  }

  function handleSetPhone() {
    fetchUserMe().then(response => {
      dispatch(update(response.data));
      navigate(RouteHelpers.getPath('account'));
    });
  }

  const loginPath = Browser.containsCookie('course_merchant')
    ? RouteHelpers.getPath('users-cm-login')
    : RouteHelpers.getPath('users-login');

  if (redirectToLogin) {
    return (
      <Navigate
        replace
        to={loginPath}
        state={{
          type: errors.length ? 'error' : 'success',
          message: errors.length ? errors : i18n.ft(messages.passwordSet)
        }}
      />
    );
  }

  const PasswordForm = () => {
    return (
      <Formik
        onSubmit={submitForm}
        initialValues={initialValues}
        validationSchema={getValidationSchema()}
      >
        <Form>
          <Header
            textAlign="left"
            content={i18n.ft(messages.setYourPassword)}
          />

          <SubHeader textAlign="left">
            {i18n.ft(messages.enterPassword)}
          </SubHeader>

          <SubHeader textAlign="left">
            {i18n.ft(messages.mustContain)}
          </SubHeader>

          {errors && <Errors>{errors}</Errors>}

          <Input
            type="password"
            name="password"
            placeholder={i18n.ft(messages.password)}
            className={errors.length ? 'error' : ''}
          />

          <Input
            type="password"
            name="password_confirmation"
            placeholder={i18n.ft(messages.reEnterPassword)}
            className={errors.length ? 'error' : ''}
          />

          {R.isNil(reset_password_token) && (
            <Checkbox
              name="tos_pp_agreed_at"
              error={{ textAlign: 'left' }}
              label={
                <label>
                  {i18n.ft(messages.termsOfService.privacy01) + ' '}
                  <a
                    target="_blank"
                    href="http://teachstone.com/terms-conditions/"
                    rel="noopener noreferrer"
                  >
                    {i18n.ft(messages.termsOfService.privacy02) + ' '}
                  </a>
                  {i18n.ft(messages.termsOfService.privacy03) + ' '}
                  <a
                    target="_blank"
                    href="http://teachstone.com/privacy-policy/"
                    rel="noopener noreferrer"
                  >
                    {i18n.ft(messages.termsOfService.privacy04) + ' '}
                  </a>
                </label>
              }
            />
          )}

          <Actions>
            <Button
              fluid
              type="submit"
              color="green"
              content={i18n.ft(messages.setMyPassword)}
            />
          </Actions>
        </Form>
      </Formik>
    );
  };

  return (
    <PageContainer>
      <ContainerWithLogo>
        {currentStep === steps.SET_PASSWORD ? (
          <PasswordForm />
        ) : (
          <PhonePrompt onSuccess={handleSetPhone} />
        )}
      </ContainerWithLogo>
    </PageContainer>
  );
}

export default SetPasswordPage;
