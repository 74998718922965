import { test } from 'ramda';

/*
 * content type examples:
 *   'image/jpeg', 'application/pdf', etc
 */
export function isPDF(contentType?: string) {
  return test(/pdf\/*/g, contentType || '');
}

export function isImage(contentType?: string) {
  return test(/image\/*/g, contentType || '');
}

export function isVideo(contentType?: string) {
  return test(/video\/*/g, contentType || '');
}
