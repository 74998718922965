const PATH = 'fe.components.enhanced_form';

const messages = {
  notProvided: {
    key: `${PATH}.not_provided`,
    defaultValue: 'Not Provided'
  },
  actions: {
    cancel: {
      key: `${PATH}.cancel`,
      defaultValue: 'Cancel'
    },
    edit: {
      key: `${PATH}.edit`,
      defaultValue: 'Edit'
    },
    save: {
      key: `${PATH}.save`,
      defaultValue: 'Save Changes'
    },
    create: {
      key: `${PATH}.create`,
      defaultValue: 'Create'
    },
    back: {
      key: `${PATH}.back`,
      defaultValue: 'Back'
    }
  },
  unsavedChanges: {
    key: `${PATH}.unsaved_changes`,
    defaultValue:
      'You have unsaved changes on this page. Are you sure want to leave and lose your changes?'
  }
};

export default messages;
