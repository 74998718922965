import * as React from 'react';
import { Wrapper, NativeCheckbox } from './Styled';

type HTMLInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'children'
>;

export interface GenericCheckboxProps extends HTMLInputProps {
  /** Checkbox value. */
  checked: boolean;

  /** Callback fires when the value changes. */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  /** Input type */
  type: 'checkbox' | 'radio';

  /** Component to render as the checkbox element. */
  children: React.ReactNode | ((checked: boolean) => React.ReactNode);
}

export function GenericCheckbox({
  children,
  type,
  ...props
}: GenericCheckboxProps) {
  return (
    <Wrapper>
      {typeof children === 'function' ? children(props.checked) : children}
      <NativeCheckbox type={type} {...props} />
    </Wrapper>
  );
}
