import { FormikHelpers } from 'formik';
import { useState, ReactNode } from 'react';
import LoginLookup from './LoginLookup';
import LoginWithCode from './LoginWithCode';
import LoginWithPassword from './LoginWithPassword';
import { LoginSteps, LoginManagerContext } from './LoginManagerContext';

export interface LoginManagerProps {
  /** Initial value for the email or phone input. */
  initialEmailOrPhone?: string;

  /** Renders content above the current login step. */
  renderTop?: (step: LoginSteps) => ReactNode;

  /** Renders content below the current login step. */
  renderBottom?: (step: LoginSteps) => ReactNode;

  /**
   * Callback fired when the form is submitted with login credentials.
   * "values" can contain any combination of: email/password, phone/password or phone/code.
   **/
  onLogin: (
    values: any,
    formik: FormikHelpers<any>,
    isFormWithPassword: boolean
  ) => void;
}

function LoginManager({
  onLogin,
  renderTop,
  renderBottom,
  initialEmailOrPhone = ''
}: LoginManagerProps) {
  const [emailOrPhone, setEmailOrPhone] = useState(initialEmailOrPhone);
  const [step, setStep] = useState<LoginSteps>('login');

  let stepComponent: JSX.Element;

  switch (step) {
    case 'login':
      stepComponent = <LoginLookup />;
      break;
    case 'loginWithPassword':
      stepComponent = <LoginWithPassword />;
      break;
    case 'loginWithPhone':
      stepComponent = <LoginWithPassword loginWithPhone />;
      break;
    case 'loginWithCode':
      stepComponent = <LoginWithCode />;
      break;
    default:
      throw new Error(`Unhandled step type: ${step}`);
  }

  function submitForm(values: any, formik: FormikHelpers<any>) {
    const isFormWithPassword =
      step === 'loginWithPassword' || step === 'loginWithPhone';

    onLogin(values, formik, isFormWithPassword);
  }

  return (
    <LoginManagerContext.Provider
      value={{
        step,
        setStep,
        emailOrPhone,
        setEmailOrPhone,
        submitForm
      }}
    >
      {renderTop?.(step)}
      {stepComponent}
      {renderBottom?.(step)}
    </LoginManagerContext.Provider>
  );
}

export default LoginManager;
