const PATH = 'fe.components.learning_resources.search_results';

const messages = {
  results: {
    key: `${PATH}.results`,
    defaultValue: 'Results for'
  },
  all: {
    key: `${PATH}.all`,
    defaultValue: 'See All'
  },
  noResults: {
    key: `${PATH}.no_results`,
    defaultValue: 'No Results'
  }
};

export default messages;
