import { useField } from 'formik';
import ErrorLabel from './ErrorLabel';
import { FormRowWrapper, FormInputWithLabel } from './Styled';
import { shouldDisplayError, shouldHideInput, BaseFieldProps } from './utils';

import BasePhoneInput, {
  PhoneInputProps as BaseProps
} from 'components/PhoneInput';

interface PhoneInputProps extends BaseFieldProps<{}> {
  /** Defines a string value that labels the current element. */
  'aria-label'?: string;
  /**Defines the value assign to the current element */
  value?: any;
  /**Event: is trigger with every change in the current element */
  onChange?: any;
  /**Defines a string to specify a short hint for the current element  */
  placeholder?: string;
}

function Input({
  name,
  value,
  validate,
  className,
  ...props
}: PhoneInputProps) {
  const fieldHookProps = {
    name,
    value,
    validate
  };

  const [field, meta, helpers] = useField(fieldHookProps);
  const isError = shouldDisplayError(meta);
  const isHidden = shouldHideInput(props);

  const handleChange: BaseProps['onChange'] = (value: any) => {
    helpers.setValue(value || '');
  };

  const handleBlur: BaseProps['onBlur'] = () => {
    helpers.setTouched(true);
  };

  return (
    <FormRowWrapper className={className} hidden={isHidden}>
      <FormInputWithLabel
        {...field}
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        error={isError}
        numberInputProps={{
          name,
          'aria-label': props['aria-label'] || props.label
        }}
      />
      <ErrorLabel active={isError} content={meta.error} textAlign={'left'} />
    </FormRowWrapper>
  );
}

Input.defaultProps = {
  control: BasePhoneInput,

  /** Sets the default country. */
  defaultCountry: 'US',

  /** Set the code not editable */
  countryCallingCodeEditable: false,

  /** Set format to international */
  international: true
};

export default Input;
