import styled from 'styled-components';

const PageWrapper = styled.div<{ borderStyle?: boolean }>`
  color: ${p => p.theme.colors['scaleDarkBlue']};
  margin-top: 32px;
  padding-left: ${p => (p.borderStyle ? '0;' : '12px;')};
  padding-right: ${p => (p.borderStyle ? '0;' : '12px;')};

  @media screen and (min-width: 640px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 72px;
    padding-right: 72px;
  }
`;

export default PageWrapper;
