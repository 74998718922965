import * as R from 'ramda';
import { IndicatorScore } from 'types/api/envScales/IndicatorScore';
import { CyclesErrors } from 'types/api/envScales/CyclesErrors';
import { Dimension } from 'types/api/envScales/Dimension';
import messages from './messages';
import i18n from 'helpers/i18n';

export type IndicatorScoreState = {
  [key: number]: number | null;
};

export type IndicatorScorePayload = {
  indicator_id: number;
  score: number;
};

export function mapIndicatorScores(
  scores: IndicatorScoreState
): IndicatorScorePayload[] {
  let scoresPayload: IndicatorScorePayload[] = [];

  R.forEachObjIndexed((value, key) => {
    if (value !== null) {
      scoresPayload = [...scoresPayload, { indicator_id: key, score: value }];
    }
  }, scores);

  return scoresPayload;
}

export function getInitialIndicators(
  scores: IndicatorScore[]
): IndicatorScoreState {
  let state: IndicatorScoreState = {};

  R.forEach(score => {
    state[score.indicator_id] = score.score;
  }, scores);

  return state;
}

export function calculateSuggestions(
  indicatorsComplete: boolean,
  indicatorScores: IndicatorScorePayload[]
) {
  if (!indicatorsComplete) {
    return [];
  }

  const scoresArray = indicatorScores.map(indScore => {
    switch (indScore.score) {
      case 100:
        return 7;
      case 50:
        return 4;
      default:
        return 1;
    }
  });

  const MIN_SCORE = 1;
  const MAX_SCORE = 7;
  const avg = R.mean(scoresArray);
  const isInteger = avg % 1 === 0;
  let scoreSuggestions: number[] = [];

  if (isInteger) {
    scoreSuggestions = [avg - 1, avg, avg + 1];
  } else {
    scoreSuggestions = [Math.floor(avg), Math.ceil(avg)];
  }

  return scoreSuggestions.filter(s => s >= MIN_SCORE && s <= MAX_SCORE);
}

export function dimensionsHasErrors(
  cyclesErrors: CyclesErrors,
  cycleNumber: number,
  dimension: string
) {
  if (R.isEmpty(cyclesErrors)) {
    return false;
  }

  const hasDimension = R.has(dimension);

  return hasDimension(cyclesErrors[cycleNumber]!.dimension_scores);
}

export function removeDimensionFromValidations(
  cyclesErrors: CyclesErrors,
  cycleNumber: number,
  dimension: string
) {
  let newCyclesErrors = R.clone(cyclesErrors);

  const hasDimension = R.has(dimension);

  if (hasDimension(cyclesErrors[cycleNumber]!.dimension_scores)) {
    delete newCyclesErrors[cycleNumber]!.dimension_scores![dimension];
  }

  return newCyclesErrors;
}

export function addDimensionInValidations(
  cyclesErrors: CyclesErrors,
  cycleNumber: number,
  dimension: string
) {
  let newCyclesErrors = R.clone(cyclesErrors);

  if (newCyclesErrors[cycleNumber]!.dimension_scores === null) {
    newCyclesErrors[cycleNumber]!.dimension_scores = {};
  }

  const hasDimension = R.has(dimension);

  if (!hasDimension(newCyclesErrors[cycleNumber]!.dimension_scores)) {
    newCyclesErrors[cycleNumber]!.dimension_scores![dimension] = {
      error: 'required',
      indicators: {}
    };
  }

  return newCyclesErrors;
}

export function scoreHasErrors(
  cyclesErrors: CyclesErrors,
  cycleNumber: number,
  dimension: string
) {
  if (R.isEmpty(cyclesErrors)) {
    return false;
  }

  const hasDimension =
    cyclesErrors[cycleNumber]?.dimension_scores &&
    dimension in (cyclesErrors[cycleNumber]!.dimension_scores || {});

  if (!hasDimension) {
    return false;
  }

  const dimensionScoreHasError =
    cyclesErrors[cycleNumber]!.dimension_scores![dimension].error;

  return Boolean(dimensionScoreHasError);
}

export function getScoreWarnings(scoreValue: number, dimension: Dimension) {
  let warnings: Array<string> = [];

  const uncommonBelow3 = ['pc', 'ts', 'rsp', 'bm', 'pr', 'ilf'];
  const uncommonAbove4 = ['cd', 'qf'];

  if (scoreValue < 3 && uncommonBelow3.includes(dimension.abbreviation)) {
    warnings.push(
      i18n.ft(messages.warnings.uncommonBelow, {
        number: 3,
        dimension: dimension.name
      })
    );
  } else if (
    scoreValue > 4 &&
    uncommonAbove4.includes(dimension.abbreviation)
  ) {
    warnings.push(
      i18n.ft(messages.warnings.uncommonAbove, {
        number: 4,
        dimension: dimension.name
      })
    );
  } else if (scoreValue > 3 && dimension.abbreviation === 'nc') {
    warnings.push(
      i18n.ft(messages.warnings.uncommonAbove, {
        number: 3,
        dimension: dimension.name
      })
    );
  }

  return warnings;
}
