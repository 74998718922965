import axios from 'axios';

export function sendSmsCode() {
  return axios.post('/api/v2/sms');
}

export function verifyPhone(code: string) {
  return axios.post(`/api/v2/sms/${code}/verify`);
}

export function sendSmsCodeForLogin(params = {}) {
  return axios.post(`/api/v2/sms/login`, params);
}
