import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6%;

  @media screen and (max-width: 394px) {
    justify-content: space-between;
    width: 100%;
  }
`;
