import i18n from 'helpers/i18n';
import messages from './messages';
import { Box } from 'components/Box';
import { Name, Checkbox, CheckboxGrid, NAWrapper, Filler } from './Styled';
import { Domain } from 'types/api/envScales/Domain';
import { Indicator } from 'types/api/envScales/Indicator';
import { GenericCheckbox } from 'components/GenericCheckbox';
import { useCyclesErrors } from 'context/CyclesErrorsContext';
import { Icon } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import {
  indicatorHasError,
  removeIndicatorFromValidations,
  addIndicatorInValidations
} from './utils';
import * as R from 'ramda';

export interface IndicatorScoreProps {
  /** Indicator. */
  indicator: Indicator;

  /** Indicator score value (0, 50, 100). */
  value: number | null;

  /** Domain. */
  domain: Domain;

  /** Callback fired when the indicator score changes. */
  onChange: (indicatorId: number, value: number | null) => void;

  /** Observation Cycle Number. */
  cycleNumber: number;

  /** Dimension t_name. */
  dimension: string;
}

export function IndicatorScoreInput({
  indicator,
  value,
  domain,
  onChange,
  cycleNumber,
  dimension
}: IndicatorScoreProps) {
  const { cyclesErrors, updateCycleErrors } = useCyclesErrors();

  const isDesktop = useMediaQuery({ minWidth: 768 });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      onChange(indicator.id, parseInt(event.target.value));

      if (!R.isEmpty(cyclesErrors)) {
        updateCycleErrors(
          removeIndicatorFromValidations(
            cyclesErrors,
            cycleNumber,
            dimension,
            indicator.t_name
          )
        );
      }
    } else {
      onChange(indicator.id, null);

      if (!R.isEmpty(cyclesErrors)) {
        updateCycleErrors(
          addIndicatorInValidations(
            cyclesErrors,
            cycleNumber,
            dimension,
            indicator.t_name
          )
        );
      }
    }
  }

  function skipMid() {
    return (
      domain.class_version === 2 && indicator.t_name === 'severe_negativity'
    );
  }

  return (
    <>
      <Box mt={{ _: '12px', md: '0' }}>
        <Name>{indicator.name}</Name>
      </Box>
      <CheckboxGrid>
        <GenericCheckbox
          value={0}
          checked={value === 0}
          onChange={handleChange}
          type="checkbox"
          aria-label={`${indicator.name} ${i18n.ft(messages.low)}`}
        >
          <Checkbox checked={value === 0}>{i18n.ft(messages.low)}</Checkbox>
        </GenericCheckbox>

        {skipMid() ? (
          <Filler />
        ) : (
          <GenericCheckbox
            value={50}
            checked={value === 50}
            onChange={handleChange}
            type="checkbox"
            aria-label={`${indicator.name} ${i18n.ft(messages.mid)}`}
          >
            <Checkbox checked={value === 50}>{i18n.ft(messages.mid)}</Checkbox>
          </GenericCheckbox>
        )}

        <GenericCheckbox
          value={100}
          checked={value === 100}
          onChange={handleChange}
          type="checkbox"
          aria-label={`${indicator.name} ${i18n.ft(messages.high)}`}
        >
          <Checkbox checked={value === 100}>{i18n.ft(messages.high)}</Checkbox>
        </GenericCheckbox>

        {indicatorHasError(
          cyclesErrors,
          cycleNumber,
          dimension,
          indicator.t_name
        ) && <Icon color="red" name="exclamation circle" />}

        {!indicator.required && (
          <NAWrapper>
            <GenericCheckbox
              value={-1}
              checked={value === -1}
              onChange={handleChange}
              type="checkbox"
              aria-label={`${indicator.name} ${i18n.ft(
                messages.notApplicable
              )}`}
            >
              <Checkbox checked={value === -1}>
                {isDesktop
                  ? i18n.ft(messages.notApplicableDesktop)
                  : i18n.ft(messages.notApplicable)}
              </Checkbox>
            </GenericCheckbox>
          </NAWrapper>
        )}
      </CheckboxGrid>
    </>
  );
}
