import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';

function buildRequiredField(field) {
  return {
    is: 'new',
    then: schema =>
      schema.required(() => i18n.ft(messages.isRequired, { field }))
  };
}

const validationSchema = {
  /** Used to decide if validation should be skipped. */
  addressType: yup.string().required(),

  /** Address validations. */
  first_name: yup
    .string()
    .when('addressType', buildRequiredField(i18n.ft(messages.firstName))),
  last_name: yup
    .string()
    .when('addressType', buildRequiredField(i18n.ft(messages.lastName))),
  address1: yup
    .string()
    .when('addressType', buildRequiredField(i18n.ft(messages.address1))),
  country_code: yup
    .string()
    .when('addressType', buildRequiredField(i18n.ft(messages.country))),
  state_or_province_code: yup
    .string()
    .when('addressType', buildRequiredField(i18n.ft(messages.state))),
  city: yup
    .string()
    .when('addressType', buildRequiredField(i18n.ft(messages.city))),
  postal_code: yup
    .string()
    .when('addressType', buildRequiredField(i18n.ft(messages.postalCode))),
  phone: yup
    .string()
    .when('addressType', buildRequiredField(i18n.ft(messages.phoneNumber)))
};

export default validationSchema;
