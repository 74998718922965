import styled from 'styled-components';
import { TabList as HUITabList } from '@headlessui/react';

export const GraphSection = styled.div`
  margin-top: 24px;
  background-color: #ffffff;
  border-radius: 12px 12px 0 0;
`;

export const NoSupportText = styled.div`
  text-align: center;
  padding: 96px 0;
`;

export const TabButton = styled.button`
  flex: 1;
  border: 0;
  margin: 0;
  padding: 12px 0;
  font-weight: 700;
  font-family: inherit;
  color: ${p => p.theme.colors['scaleDarkBlue']};
  background-color: ${p => p.theme.colors['white']};

  &.active {
    color: ${p => p.theme.colors['white']};
    background-color: #3c3f42;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const TabList = styled(HUITabList)`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #a7aaaa;
`;

export const NoAccountText = styled.div`
  text-align: center;
`;
