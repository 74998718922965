const PATH = 'fe.pages.training_catalog.create';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Create New Training'
  },
  submitLabel: {
    key: `${PATH}.submit_label`,
    defaultValue: 'Create and Go to Panel'
  }
};

export default messages;
