const PATH = 'fe.components.trainings.view.sessions';

const messages = {
  sessionNumber: {
    key: `${PATH}.session_number`,
    defaultValue: 'Session {{number}}:'
  },
  sessionDates: {
    key: `${PATH}.session_dates`,
    defaultValue: 'Session Dates'
  }
};

export default messages;
