const PATH = 'fe.pages.testing.reliability.test_attempts';

const messages = {
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: 'Review your performance on your most recent attempt.'
  },
  logoDescription: {
    key: `${PATH}.logo_description`,
    defaultValue: 'Logo Certification'
  },
  participantReportTitle: {
    key: `${PATH}.participant_report_title`,
    defaultValue: 'Participant Score Report'
  },
  name: {
    key: `${PATH}.name`,
    defaultValue: 'Name:'
  },
  test: {
    key: `${PATH}.test`,
    defaultValue: 'Test:'
  },
  trainingDate: {
    key: `${PATH}.training_date`,
    defaultValue: 'Training Date:'
  },
  result: {
    key: `${PATH}.result`,
    defaultValue: 'Status:'
  },
  attempt: {
    key: `${PATH}.attempt`,
    defaultValue: 'Attempt:'
  },
  testCompletion: {
    key: `${PATH}.test_completion`,
    defaultValue: 'Test Completion:'
  }
};

export default messages;
