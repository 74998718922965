import i18n from 'helpers/i18n';
import messages from './messages';
import { ContentType } from 'recharts/types/component/Label';

import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';

type CustomTickProps = {
  x: number;
  y: number;
  payload: {
    value: number;
  };
};

const CustomTick = ({ x, y, payload }: CustomTickProps) => {
  const width = 26;
  const height = 24;
  const posX = x - width;
  const posY = y - height / 2;
  const boxColor = '#f4f8fa';
  const textPosX = '50%';
  const textPosY = 13;
  const textColor = '#364a53';

  return (
    <svg
      x={posX}
      y={posY}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g>
        <rect width={width} height={height} rx="4" fill={boxColor} />
        <text
          x={textPosX}
          y={textPosY}
          fill={textColor}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {payload.value}
        </text>
      </g>
    </svg>
  );
};

type CustomLabelsProps = {
  x: number;
  width: number;
  value: number;
  fontSize: number;
};

const CustomLabels = ({ x, width, value, fontSize }: CustomLabelsProps) => {
  const score = Number(value);
  const textColor = '#364a5e';

  // Average Score
  const maxBarHeight = 315;
  const scorePosY = 390 - (score / 7) * maxBarHeight;
  const scorePosX = x + width / 2;

  return (
    <>
      {score > 0 && (
        <text
          x={scorePosX}
          y={scorePosY}
          fill={textColor}
          textAnchor="middle"
          fontWeight="700"
          fontSize={fontSize || '18'}
        >
          {score.toFixed(2)}
        </text>
      )}
    </>
  );
};

type GraphData = {
  /** X-Axis label for bar data. */
  name: string;

  /** Score value (1-7) for bar A (left). */
  valueA: number;

  /** Score value (1-7) for bar B (right). */
  valueB: number;

  /** Main color for bar A. */
  fill: string;
};

interface CompareBarChartProps {
  data: GraphData[];

  /** Array of background colors (tailwind classes) for graph legend. */
  legendColors: string[];
}

const legendItemClasses =
  'text-xs font-bold bg-[#3C3F42] rounded-sm w-4 h-4 flex justify-center text-white';

function CompareBarChart({ data, legendColors }: CompareBarChartProps) {
  const height = 400;
  const bgGridColor = '#e4e4e4';
  const scoreFontSize = data.length > 5 ? '14' : '18';

  return (
    <div>
      <div className="pl-8 pb-2 mb-4 border-b flex items-center gap-1.5">
        <span className="font-bold text-sm">
          {i18n.ft(messages.comparisons) + ':'}
        </span>

        <div className={legendItemClasses}>1</div>
        <div className="flex gap-0.5">
          {legendColors.map(color => (
            <div className={`w-4 h-4 rounded-full ${color}`} />
          ))}
        </div>

        <div className={legendItemClasses}>2</div>
        <div className="flex gap-0.5">
          {legendColors.map(color => (
            <div
              className={`w-4 h-4 rounded-full brightness-125 opacity-90 ${color}`}
            />
          ))}
        </div>
      </div>

      <ResponsiveContainer height={height}>
        <BarChart data={data} barGap={0}>
          <CartesianGrid
            strokeDasharray="8"
            stroke={bgGridColor}
            vertical={false}
          />
          <XAxis
            dataKey="name"
            stroke="#364a5e"
            className="font-semibold text-xl"
          />
          <YAxis
            ticks={[0, 1, 2, 3, 4, 5, 6, 7]}
            tick={CustomTick}
            axisLine={false}
            tickLine={false}
          />
          <Bar dataKey="valueA" isAnimationActive={false}>
            <LabelList
              dataKey="valueA"
              fontSize={scoreFontSize}
              content={CustomLabels as ContentType}
            />
          </Bar>
          <Bar
            dataKey="valueB"
            isAnimationActive={false}
            className="brightness-110 opacity-90"
          >
            <LabelList
              dataKey="valueB"
              fontSize={scoreFontSize}
              content={CustomLabels as ContentType}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CompareBarChart;
