import axios from './axiosConfig';
import { ObservationCycle } from 'types/api/envScales/ObservationCycle';

export function getObservationCycle(id: number, cycleId: number) {
  return axios.get(`/api/v1/observations/${id}/observation_cycles/${cycleId}`);
}

export function updateObservationCycle(
  id: number,
  cycleId: number,
  payload: any = {}
) {
  return axios.put(
    `/api/v1/observations/${id}/observation_cycles/${cycleId}`,
    payload
  );
}

export function updateCycleMetaData(
  observationId: number,
  cycleId: number,
  payload: any = {}
) {
  return axios.put(
    `/api/v1/observations/${observationId}/observation_cycles/${cycleId}/meta_data`,
    payload
  );
}

export function createCycle(observationId: number) {
  return axios.post<ObservationCycle>(
    `/api/v1/observations/${observationId}/observation_cycles`
  );
}

export function deleteCycle(observationId: number, cycleId: number) {
  return axios.delete<ObservationCycle>(
    `/api/v1/observations/${observationId}/observation_cycles/${cycleId}`
  );
}
