// import i18n from 'helpers/i18n';
// import messages from './messages';
// import { Prompt } from 'react-router-dom';
// import { useFormikContext } from 'formik';

/**
 * @deprecated
 * react-router-dom v6 does not offer support for Prompt.
 * Disabling functionality for now until support is restored.
 */
function PreventNavigation() {
  // const { dirty } = useFormikContext();
  // return <Prompt when={dirty} message={i18n.ft(messages.unsavedChanges)} />;
  return null;
}

export default PreventNavigation;
