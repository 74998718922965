import { isEmpty } from 'ramda';
import { useState } from 'react';

function useErrors() {
  const [errors, setErrors] = useState<string[]>([]);
  const [errorsVisible, setErrorsVisible] = useState(false);

  function displayErrors(newErrors: string[]) {
    setErrors(newErrors);
    setErrorsVisible(!isEmpty(newErrors));
  }

  function hideErrors() {
    setErrorsVisible(false);
  }

  return { errors, errorsVisible, displayErrors, hideErrors };
}

export default useErrors;
