const PATH = 'fe.components.test_attempts.instructions';

const messages = {
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: 'Review your performance on your most recent attempt.'
  },
  logoDescription: {
    key: `${PATH}.logo_description`,
    defaultValue: 'Certification Logo'
  },
  instructions: {
    key: `${PATH}.instructions`,
    defaultValue: 'Instructions'
  },
  exitTest: {
    key: `${PATH}.exit_test`,
    defaultValue: 'Exit to Dashboard'
  },
  next: {
    key: `${PATH}.next`,
    defaultValue: 'Next'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  },
  read: {
    key: `${PATH}.read`,
    defaultValue: 'Read instructions then click Next'
  }
};

export default messages;
