import settings from 'helpers/settings';

export const bigCommerceURL =
  settings.lookup('REACT_APP_BIG_COMMERCE_STORE_URL') ||
  'https://store.teachstone.com';

export const TS_TRAININGS =
  settings.lookup('TS_TRAININGS') ||
  'https://teachstone.com/regional-trainings/';

export const TS_KALTURA_VIDEO_PATH =
  settings.lookup('REACT_APP_KALTURA_VIDEO_PATH') ||
  'https://cdnapisec.kaltura.com/p/102/sp/10200/embedIframeJs/uiconf_id/23448251/partner_id/102?autoembed=true&entry_id=';
