import axios from 'axios';
import { TestAttempt, TrainerViewTestAttempt } from 'types/api/TestAttempt';

export function getTestAttempts() {
  return axios.get('/api/v2/test_attempts');
}

export function getTestAttemptById(testAttemptId: number) {
  return axios.get<TestAttempt>(`/api/v2/test_attempts/${testAttemptId}`);
}

export function startAttempt(testAttemptId: number) {
  return axios.post<TestAttempt>(
    `/api/v2/test_attempts/${testAttemptId}/start_attempt`
  );
}

export function nextVideo(testAttemptId: number) {
  return axios.post(`/api/v2/test_attempts/${testAttemptId}/next_video`, {});
}

export function getScores(testAttemptId: number) {
  return axios.get(`/api/v2/test_attempts/${testAttemptId}/scores`, {});
}

export function evaluateTestAttempt(testAttemptId: number) {
  return axios.post(`/api/v2/test_attempts/${testAttemptId}/evaluate`, {});
}

export function getTrainerViewScores(testAttemptId: number) {
  return axios.get<TrainerViewTestAttempt>(
    `/api/v2/test_attempts/${testAttemptId}/trainer_view_scores`
  );
}

export function logVideoPlayStarted({
  testAttemptId,
  codingVideoId
}: {
  testAttemptId: number;
  codingVideoId: number;
}) {
  return axios.put(
    `/api/v2/test_attempts/${testAttemptId}/coding_videos/${codingVideoId}/video_started`
  );
}

export function replaceVideo({
  auditComment,
  testAttemptId,
  codingVideoId
}: {
  auditComment: string;
  testAttemptId: number;
  codingVideoId: number;
}) {
  return axios.put(
    `/api/v2/test_attempts/${testAttemptId}/coding_videos/${codingVideoId}/replace_video`,
    {
      audit_comment: auditComment
    }
  );
}
