import * as React from 'react';

export type OnboardingSteps =
  | 'welcome'
  | 'focusOnInteractions'
  | 'workPerformed'
  | 'familiarWithClass'
  | 'onboardingCompleted';

export type Jobs =
  | 'observer'
  | 'teacher'
  | 'education_support'
  | 'other'
  | null;

export type ClassFamiliaredType = 1 | 3 | 5 | null;

export const OnboardingManagerContext = React.createContext<
  | {
      step: OnboardingSteps;
      setStep: React.Dispatch<React.SetStateAction<OnboardingSteps>>;
      jobs: Jobs[];
      setJobs: React.Dispatch<React.SetStateAction<Jobs[]>>;
      checkedSteps: OnboardingSteps[];
      setCheckedSteps: React.Dispatch<React.SetStateAction<OnboardingSteps[]>>;
      classFamiliared: ClassFamiliaredType;
      setClassFamiliared: React.Dispatch<
        React.SetStateAction<ClassFamiliaredType>
      >;
    }
  | undefined
>(undefined);

export function useOnboardingManager() {
  const context = React.useContext(OnboardingManagerContext);

  if (!context) {
    throw new Error(
      'useOnboardingManager must be used within a OnboardingManagerProvider'
    );
  }

  return context;
}
