import i18n from 'helpers/i18n';
import messages from './messages';
import { RadioInput } from './Styled';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useRouteParams } from 'hooks/useRouteParams';
import { createUserAnswers, deleteUserAnswers } from 'actions/userAnswers';
import { useKnowledgeTest } from './KnowledgeTestContext';

type AnswerOptions = {
  user_answer_id?: number;
  checked: boolean;
  description: string;
  id: number;
};

interface SingleAnswerProps {
  answer_options: AnswerOptions[];
  category: string | undefined;
  questionId: number;
}

type URLParams = {
  id: string;
};

function SingleAnswer({
  answer_options: answerOptions,
  category,
  questionId
}: SingleAnswerProps) {
  const { id } = useRouteParams<URLParams>();
  const { updater } = useKnowledgeTest();
  const [currentQuestionState, setCurrentQuestionState] =
    useState(answerOptions);
  const [previousAnswerId, setPreviousAnswerId] = useState<
    number | undefined
  >();
  const [currentAnswerId, setCurrentAnswerId] = useState<number | undefined>();

  useEffect(() => {
    setCurrentQuestionState(answerOptions);
    const previousAnswerSet = answerOptions.filter(el => {
      return el.user_answer_id !== undefined;
    });
    if (previousAnswerSet.length > 0) {
      setPreviousAnswerId(previousAnswerSet[0].id);
      setCurrentAnswerId(previousAnswerSet[0].id);
    } else {
      setPreviousAnswerId(undefined);
      setCurrentAnswerId(undefined);
    }
  }, [answerOptions]);

  function handleCheckboxInputChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.currentTarget.dataset.answerId === undefined) {
      return;
    }

    const answerId = parseInt(event.currentTarget.dataset.answerId);
    const userAnswer = {
      question_id: questionId,
      question_answer_id: answerId
    };
    /** Selected Answer set (id, checked, description and user_answer_id) */
    const selectedAnswerSet = currentQuestionState.filter(el => {
      return el.id === answerId;
    });

    const previousAnswerSet = currentQuestionState.filter(el => {
      return el.id === previousAnswerId;
    });
    /** Remove the old Answer set then replace it by the new one (add user_answer_id from the BE) */
    let newAnswerSetState = currentQuestionState.filter(el => {
      return el.id !== answerId;
    });

    if (previousAnswerId !== answerId) {
      setPreviousAnswerId(answerId);
      createUserAnswers(id, userAnswer)
        .then(response => {
          /** Create the new Answer set */
          const updateAnswerOptions = {
            checked: true,
            description: selectedAnswerSet[0].description,
            id: selectedAnswerSet[0].id,
            user_answer_id: response.data.id
          };
          /** Add it */
          newAnswerSetState.push(updateAnswerOptions);

          /** and Update the Current question answer set */
          setCurrentQuestionState(newAnswerSetState);
          setCurrentAnswerId(answerId);
        })
        .catch(() => {
          toast.error(i18n.ft(messages.error));
        })
        .finally(() => {
          // refresh context
          updater();
        });

      if (previousAnswerSet?.[0]?.user_answer_id) {
        deleteUserAnswers(id, previousAnswerSet[0].user_answer_id)
          .then(response => {
            /** Because it is single choice, remove the user_answer_id from the state and mark it as unchecked */
            const updateAnswerOptions = {
              checked: false,
              description: previousAnswerSet[0].description,
              id: previousAnswerSet[0].id
            };
            /** Add it */
            newAnswerSetState.push(updateAnswerOptions);
            /** and update */
            setCurrentQuestionState(newAnswerSetState);
          })
          .catch(e => {
            toast.error(i18n.ft(messages.error));
          })
          .finally(() => {
            // refresh context
            updater();
          });
      }
    }
  }

  return (
    <div className="pt-4 text-base">
      {answerOptions &&
        answerOptions?.map((answer: AnswerOptions, idx: number) => {
          return (
            <div
              key={answer.id}
              className="grid grid-cols-12 items-center mb-8"
            >
              <div className="pl-2 col-span-1">
                <RadioInput
                  id={`question_${answer.id}`}
                  name={`question_${questionId}`}
                  className="align-middle"
                  data-answer-id={answer.id}
                  onChange={handleCheckboxInputChange}
                  checked={answer.id === currentAnswerId}
                  error={false}
                />
              </div>
              <div className="ml-4 col-span-11 pl-2 md:pl-0 col-span-11">
                <label
                  htmlFor={`question_${answer.id}`}
                  className=" cursor-pointer"
                >
                  {
                    <span
                      dangerouslySetInnerHTML={{
                        __html: answer.description
                      }}
                    ></span>
                  }
                </label>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default SingleAnswer;
