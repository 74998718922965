import axios from 'axios';
import { Certification } from 'types/api/Certification';
import { Pagination } from 'types/api/Pagination';
import { UserCertification } from 'types/api/UserCertification';

interface GetCertificationsParams {
  only_active?: boolean;
  page: number;
  per_page: number;
  sort_by?: string;
  sort_dir?: 'asc' | 'desc';
  group?: string;
}

export function getCertifications(params: GetCertificationsParams) {
  return axios.get<{
    certifications: Certification[];
    pagination: Pagination;
  }>('/api/v2/certifications', { params });
}

export function createCertification(certification = {}) {
  return axios.post('/api/v2/certifications', { certification });
}

export function getCertification(id: number) {
  return axios.get(`/api/v2/certifications/${id}`);
}

export function updateCertification(id: number, certification = {}) {
  return axios.put(`/api/v2/certifications/${id}`, {
    certification
  });
}

export function archiveCertification(id: number) {
  return axios.delete(`/api/v2/certifications/${id}`);
}

export function getUserCertifications(userId: number) {
  return axios.get(`/api/v2/users/${userId}/certifications`);
}

export function getUserCertification(userId: number, certificationId: number) {
  return axios.get<UserCertification>(
    `/api/v2/users/${userId}/certifications/${certificationId}`
  );
}

export function getObserverCertificationsAgeLevels(userId: number) {
  return axios.get(
    `/api/v2/users/${userId}/certifications/observer_certifications_age_levels`
  );
}

export function addUserCertification(userId: number, userCertification = {}) {
  return axios.post(`/api/v2/users/${userId}/certifications`, {
    user_certification: userCertification
  });
}

export function updateUserCertification(
  userId: number,
  userCertificationId: number,
  userCertification = {}
) {
  return axios.put(
    `/api/v2/users/${userId}/certifications/${userCertificationId}`,
    {
      user_certification: userCertification
    }
  );
}

export function archiveUserCertification(
  userId: number,
  certificationId: number
) {
  return axios.delete(
    `/api/v2/users/${userId}/certifications/${certificationId}`
  );
}

export function getMyCertifications() {
  return axios.get(`/api/v2/users/me/certifications`);
}

export function getCertificationCourses(id: number) {
  return axios.get(`/api/v2/certifications/${id}/courses`);
}

export function createCertificationCourse(id: number, course = {}) {
  return axios.post(`/api/v2/certifications/${id}/courses`, {
    course
  });
}

export function deleteCertificationCourse(certificationId: number, id: number) {
  return axios.delete(
    `/api/v2/certifications/${certificationId}/courses/${id}`
  );
}
