import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Icon } from 'semantic-ui-react';
import { TableBodyCell } from './Styled';
import { getStatusLabel } from './utils';
import Typography from 'components/Typography';
import { ReportDetailPerAttempt } from 'types/api/ReliabilityReport';

interface AttemptTableCellProps {
  /** If `true`, the cell will be displayed on Desktop. */
  desktop?: boolean;

  /** If `true`, the cell will be displayed on Mobile. */
  mobile?: boolean;

  /** If `true`, the attempt score will be displayed instead of the status. */
  score?: boolean;

  /** Reliability report attempt data. */
  attempt: ReportDetailPerAttempt;
}

const AttemptTableCell: React.FC<AttemptTableCellProps> = ({
  attempt,
  score,
  mobile,
  desktop
}) => {
  if (
    attempt.status === 'not_started' ||
    attempt.status === 'in_progress' ||
    attempt.status === 'available'
  ) {
    return (
      <TableBodyCell $desktop={desktop} $mobile={mobile}>
        {score
          ? attempt.score ?? i18n.ft(messages.none)
          : getStatusLabel(attempt.status)}
      </TableBodyCell>
    );
  }

  return (
    <TableBodyCell $desktop={desktop} $mobile={mobile}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={attempt.score_report_url}
      >
        <Typography append>{attempt.score}</Typography>
        {attempt.status === 'passed' ? (
          <Icon name="check circle" color="green" />
        ) : (
          <Icon name="warning sign" color="red" />
        )}
      </a>
    </TableBodyCell>
  );
};

export default AttemptTableCell;
