import React from 'react';
import { keys, map } from 'ramda';
import OverallChart from './OverallChart';
import AttemptsBarChart from './AttemptsBarChart';
import { ReliabilityReport } from 'types/api/ReliabilityReport';
import { ChartsContainer } from './Styled';
import { TestType } from 'types/api/testType';

interface ChartsProps {
  /** Reliability report data. */
  report: ReliabilityReport;

  /** Test type. */
  testType?: TestType;
}

const Charts: React.FC<ChartsProps> = ({
  report,
  testType = 'reliability'
}) => {
  return (
    <div>
      {map(key => {
        const data = report[key];

        const attempt01 = data.total_by_attempt.find(
          attempt => attempt.number === 1
        );

        const attempt02 = data.total_by_attempt.find(
          attempt => attempt.number === 2
        );

        const attempt03 = data.total_by_attempt.find(
          attempt => attempt.number === 3
        );

        return (
          <ChartsContainer key={key}>
            <OverallChart
              attempt01={attempt01?.passed ?? 0}
              attempt02={attempt02?.passed ?? 0}
              attempt03={attempt03?.passed ?? 0}
              totalTaken={data.total_taken}
              abbreviation={key}
              testType={testType}
            />
            <AttemptsBarChart
              attempt01={attempt01 || { passed: 0, total: 0 }}
              attempt02={attempt02 || { passed: 0, total: 0 }}
              attempt03={attempt03 || { passed: 0, total: 0 }}
              abbreviation={key}
              testType={testType}
            />
          </ChartsContainer>
        );
      }, keys(report))}
    </div>
  );
};

export default Charts;
