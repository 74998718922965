import i18n from 'helpers/i18n';
import messages from './messages';
import linkIcon from 'images/link.svg';
import { Asset } from 'types/LearningResources/types';

function AssetLink({ asset }: { asset: Asset }) {
  return (
    <p className="text-base pt-2">
      <img src={linkIcon} alt="link" className="inline-block" />
      <a
        href={asset.url}
        target="_blank"
        rel="noreferrer"
        className="pl-2 font-bold decoration-[#326BF1] underline underline-offset-4"
        style={{ color: '#326BF1' }}
      >
        {asset.name}
      </a>
    </p>
  );
}

function SupportingAssets({ assets }: { assets: Asset[] }) {
  if (!assets || assets.length < 1) return <></>;
  const assetLinks = assets.map(asset => <AssetLink asset={asset} />);

  return (
    <div className="pt-10 text-xl">
      <span className="font-bold text-black">{i18n.ft(messages.plan)}</span>
      {assetLinks}
    </div>
  );
}

export default SupportingAssets;
