const PATH = 'fe.components.modals.confirmation_modal';

const messages = {
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  reason: {
    title: {
      key: `${PATH}.title`,
      defaultValue: 'Reason for Archiving'
    },
    otherReason: {
      key: `${PATH}.other_reason`,
      defaultValue: 'Other Reason'
    }
  },
  errors: {
    required: {
      key: `${PATH}.required`,
      defaultValue: 'Required'
    }
  }
};

export default messages;
