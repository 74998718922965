import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import styled from 'styled-components';
import TextButton from 'components/TextButton';
import { Icon, Sticky } from 'semantic-ui-react';
import useCurrentUser from 'hooks/useCurrentUser';

const Wrapper = styled.div`
  background-color: ${p => p.theme.colors['red']};
  text-align: center;
  padding: 4px 0;
`;

function Masquerade() {
  const currentUser = useCurrentUser();

  if (!currentUser.masquerading_user) {
    return null;
  }

  return (
    <Sticky>
      <Wrapper>
        <TextButton
          color="white"
          as="a"
          href={RouteHelpers.getPath('unMasquerade')}
        >
          <Icon name="times" />
          {i18n.ft(messages.stop)}
        </TextButton>
      </Wrapper>
    </Sticky>
  );
}

export default Masquerade;
