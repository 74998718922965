import styled from 'styled-components';
import { Button, Header } from 'semantic-ui-react';

export const ModalButton = styled(Button)`
  color: white !important;
  background-color: #017ea7 !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
`;

export const ModalHeader = styled(Header)`
  text-align: center;
  border-bottom: 0px !important;
`;

export const ModalAction = styled.div`
  padding: 2rem 1rem;
`;

export const ModalIcon = styled.img`
  margin-top: -1.5rem;
`;
