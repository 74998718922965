import * as R from 'ramda';
import { MetaDataCategory } from 'types/api/envScales/MetaDataCategory';
import { CycleMetaDataValue } from 'types/api/envScales/CycleMetaDataValue';
import {
  ContentAndFormat1stEditionError,
  CyclesErrors
} from 'types/api/envScales/CyclesErrors';

type OptionValue = { isChecked: boolean; isPrimary: boolean };

export type SelectedValues = {
  [key: string]: OptionValue;
};

export function getInitialValues(
  category: MetaDataCategory,
  values: CycleMetaDataValue[]
) {
  let initialValues: SelectedValues = {};

  category.choices.forEach(choice => {
    const dbValue = values.find(
      value =>
        value.value === choice.t_name &&
        value.meta_data_category_id === category.id
    );

    initialValues[choice.t_name] = {
      isChecked: Boolean(dbValue),
      isPrimary: Boolean(dbValue?.is_primary)
    };
  });

  return initialValues;
}

type PayloadValue = { value: string; is_primary: boolean };

export function getPayloadValues(values: SelectedValues) {
  let payloadValues: PayloadValue[] = [];

  R.forEachObjIndexed((value, key) => {
    if (value.isChecked) {
      payloadValues = [
        ...payloadValues,
        { value: String(key), is_primary: value.isPrimary }
      ];
    }
  }, values);

  return payloadValues;
}

export function showMetaDataCategoryErrors(
  cyclesErrors: CyclesErrors,
  categoryName: string,
  cycleNumber: number
) {
  if (!categoryName) {
    return '';
  }

  const categories = cyclesErrors[cycleNumber]
    ?.meta_data_categories as ContentAndFormat1stEditionError;
  if (!categories) {
    return '';
  }

  let errorMessage = '';

  let category = null;
  if (categories.hasOwnProperty(categoryName)) {
    category = categories[categoryName];
  }

  const choiceError = category?.error;
  if (choiceError) {
    errorMessage += choiceError;
  }

  const primaryError = category?.primary_error;
  if (primaryError) {
    errorMessage += ` ${primaryError}`;
  }

  return errorMessage;
}
