import { concat } from 'ramda';
import { useState } from 'react';
import { getTrainings } from 'actions/trainings';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import { TRAINING_STATUS, Training } from 'types/api/Training';

const PER_PAGE = 12;
interface IParams {
  query: string;
  status?: TRAINING_STATUS;
  start_date: string | null;
  end_date: string | null;
}

export function usePaginatedTrainings(
  params: IParams
): [Training[], number, boolean, boolean, () => void] {
  const [loading, setLoading] = useState(false);
  const [trainings, setTrainings] = useState<Training[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const moreToLoad = page < totalPages;

  function loadTrainings() {
    setLoading(true);

    getTrainings({ per_page: PER_PAGE, page, ...params })
      .then(({ data }) => {
        setTrainings(concat(page === 1 ? [] : trainings, data.trainings));
        setTotalPages(data.pagination.total_pages);
        setTotalCount(data.pagination.total_count);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function loadMore() {
    if (!moreToLoad) {
      return;
    }
    setPage(page + 1);
  }

  function resetPagination() {
    setTrainings([]);
    setPage(1);
  }

  useDebouncedEffect(resetPagination, 300, [params]);
  useDebouncedEffect(loadTrainings, 300, [page, params]);

  return [trainings, totalCount, loading, moreToLoad, loadMore];
}
