const PATH = 'fe.components.reports.reliability.table';

const messages = {
  notStarted: {
    key: `${PATH}.not_started`,
    defaultValue: 'Not Started'
  },
  inProgress: {
    key: `${PATH}.in_progress`,
    defaultValue: 'In Progress'
  },
  passed: {
    key: `${PATH}.passed`,
    defaultValue: 'Passed'
  },
  failed: {
    key: `${PATH}.failed`,
    defaultValue: 'Failed'
  },
  none: {
    key: `${PATH}.none`,
    defaultValue: 'None'
  },
  participants: {
    key: `${PATH}.participants`,
    defaultValue: 'Participants'
  },
  show: {
    key: `${PATH}.show`,
    defaultValue: 'Show'
  },
  name: {
    key: `${PATH}.name`,
    defaultValue: 'Name'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  status: {
    key: `${PATH}.status`,
    defaultValue: 'Status'
  },
  attemptNumber: {
    key: `${PATH}.attempt_number`,
    defaultValue: 'Attempt {{number}}'
  },
  attempt: {
    key: `${PATH}.attempt`,
    defaultValue: 'Attempt'
  },
  score: {
    key: `${PATH}.score`,
    defaultValue: 'Score'
  }
};

export default messages;
