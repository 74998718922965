import RouteHelpers from 'helpers/routes';
import { useState } from 'react';
import * as R from 'ramda';
import i18n from 'helpers/i18n';
import UserForm from 'components/forms/UserForm';
import useAsync from 'hooks/useAsync';
import { createCMUser } from 'actions/users';
import TextButton from 'components/TextButton';
import { PageContainer, Header, SubHeader } from './Styled';
import messages from './messages';
import { FormikHelpers } from 'formik';
import PhonePrompt from 'components/Users/PhonePrompt';
import ContainerWithLogo from 'components/ContainerWithLogo';
import { Link } from 'react-router-dom';

type ActionTypes = 'register' | 'enterPhone';

function CMRegister() {
  const createCMUserRequest = useAsync();
  const [action, setAction] = useState<ActionTypes>('register');
  const [returnUrl, setReturnUrl] = useState('');

  const initialValues = {
    first_name: '',
    last_name: '',
    password: '',
    password_confirmation: '',
    email: '',
    tos_pp_agreed_at: false
  };

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    createCMUserRequest.run(createCMUser({ user: values }), {
      onSuccess: (data: any) => {
        const { return_url } = data;
        setAction('enterPhone');
        if (!R.isNil(return_url)) {
          setReturnUrl(return_url);
        } else {
          setReturnUrl(RouteHelpers.getPath('app'));
        }
      },
      onError: (error: any) => {
        Object.keys(error.errors.user[0]).forEach(key => {
          formik.setFieldError(key, error.errors.user[0][key][0]);
        });
      }
    });
  }

  function handlePhoneSuccess() {
    window.location.replace(returnUrl);
  }

  function renderForm() {
    switch (action) {
      case 'register':
        return (
          <>
            <Header>{i18n.ft(messages.header)}</Header>
            <SubHeader>
              {i18n.ft(messages.haveAnAccount) + ' '}
              <TextButton as={Link} to={RouteHelpers.getPath('users-cm-login')}>
                {i18n.ft(messages.login)}
              </TextButton>
            </SubHeader>
            <UserForm
              initialValues={initialValues}
              handleSubmit={handleSubmit}
              loading={createCMUserRequest.isLoading}
            />
          </>
        );
      case 'enterPhone':
        return <PhonePrompt onSuccess={handlePhoneSuccess} />;
      default:
        return null;
    }
  }

  return (
    <PageContainer>
      <ContainerWithLogo>{renderForm()}</ContainerWithLogo>
    </PageContainer>
  );
}

export default CMRegister;
