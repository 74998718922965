const PATH = 'fe.components.carousel';

const messages = {
  previousSlide: {
    key: `${PATH}.previous_slide`,
    defaultValue: 'Previous Slide'
  },
  nextSlide: {
    key: `${PATH}.next_slide`,
    defaultValue: 'Next Slide'
  },
  carouselText: {
    key: `${PATH}.carousel_text`,
    defaultValue: 'Carousel'
  },
  slide: {
    key: `${PATH}.slide`,
    defaultValue: 'slide'
  },
  slideIndex: {
    key: `${PATH}.slide_index`,
    defaultValue: '{{index}} of {{total}}'
  },
  completedModal: {
    title: {
      key: `${PATH}.completed_modal.title`,
      defaultValue: `You already completed this {{name}} video this year`
    },
    viewScoreReport: {
      key: `${PATH}.completed_modal.view_score_report`,
      defaultValue: 'View Score Report'
    },
    retakeTest: {
      key: `${PATH}.completed_modal.retake_test`,
      defaultValue: 'Reset and Retake'
    }
  }
};

export default messages;
