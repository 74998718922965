import React from 'react';
import { useMediaQuery } from 'react-responsive';

type ReactNodeOrFn = React.ReactNode | (() => void);

interface ResponsiveContentProps {
  /** Content to render when screen is 767px or less. */
  renderMobile: ReactNodeOrFn;

  /** Content to render when screen is 768px or more. */
  renderDesktop: ReactNodeOrFn;
}

/**
 * @deprecated use tailwind classes instead of this
 */
function ResponsiveContent({
  renderMobile,
  renderDesktop
}: ResponsiveContentProps) {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <React.Fragment>
      <>
        {isDesktop && renderDesktop
          ? typeof renderDesktop === 'function'
            ? renderDesktop()
            : renderDesktop
          : null}
      </>

      <>
        {isMobile && renderMobile
          ? typeof renderMobile === 'function'
            ? renderMobile()
            : renderMobile
          : null}
      </>
    </React.Fragment>
  );
}

export default ResponsiveContent;
