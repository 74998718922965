type AriaLive = 'off' | 'polite' | 'assertive';

interface ScreenReaderOnlyProps {
  message: string;
  ariaLive: AriaLive;
  id?: string;
}

function ScreenReaderOnly({ message, ariaLive, id }: ScreenReaderOnlyProps) {
  return (
    <div id={id} aria-live={ariaLive} className="sr-only">
      {message}
    </div>
  );
}

export default ScreenReaderOnly;
