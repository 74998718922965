const PATH = 'fe.components.measure.view.info_box';

const messages = {
  observationDate: {
    key: `${PATH}.observation_date`,
    defaultValue: 'Observation Date:'
  },
  classroom: {
    key: `${PATH}.classroom`,
    defaultValue: 'Classroom:'
  },
  classAssessment: {
    key: `${PATH}.class_assessment`,
    defaultValue: 'CLASS Assessment:'
  },
  jobId: {
    key: `${PATH}.job_id`,
    defaultValue: 'Job ID:'
  },
  editObservationAria: {
    key: `${PATH}.edit_observation_aria`,
    defaultValue: 'Edit observation metadata'
  },
  observer: {
    key: `${PATH}.observer`,
    defaultValue: 'Observer'
  },
  teachers: {
    key: `${PATH}.teachers`,
    defaultValue: 'Teacher(s)'
  },
  center_name: {
    key: `${PATH}.center_name`,
    defaultValue: 'Center Name'
  },
  prek3rd: {
    key: `${PATH}.prek_3rd`,
    defaultValue: 'Pre-K–3rd'
  },
  return: {
    key: `${PATH}.return`,
    defaultValue: 'Return Observation to Awaiting approval'
  },
  language: {
    key: `${PATH}.language`,
    defaultValue: 'Language:'
  },
  modal: {
    title: {
      key: `${PATH}.modal.title`,
      defaultValue: 'Return Observation to Awaiting approval'
    },
    warning: {
      key: `${PATH}.modal.warning`,
      defaultValue:
        'Are you sure you want to return this observation to Awaiting approval?'
    },
    confirm: {
      key: `${PATH}.modal.confirm`,
      defaultValue: 'Yes, Return'
    },
    titleInProgress: {
      key: `${PATH}.modal.title_to_in_progress`,
      defaultValue: 'Return Observation to in progress'
    },
    warningInProgress: {
      key: `${PATH}.modal.warning_to_in_progress`,
      defaultValue:
        'Are you sure you want to return this observation to In Progress?'
    }
  }
};

export default messages;
