import { useContext } from 'react';
import iconClose from 'images/learningResources/close_x_icon_black.svg';
import { LearningResourcesContext } from 'pages/LearningResources/LearningResourcesContext';
import { getKalturaURL } from './utils';

function BodyModal() {
  const context = useContext(LearningResourcesContext);

  if (!context) {
    throw new Error(
      'BodyModal must be used within a Learning Resources Provider'
    );
  }

  const { recommendedVideosArray, setRecommendedVideosArray } = context;

  function removeVideo(id: number) {
    setRecommendedVideosArray([
      ...recommendedVideosArray.filter(item => item.id !== id)
    ]);
  }

  return (
    <div className="max-h-[482px] overflow-y-scroll mb-3">
      {recommendedVideosArray.length > 0 &&
        recommendedVideosArray.map(video => (
          <div key={video.id} className="flex">
            <div
              className={`bg-white rounded-lg h-fit cursor-pointer mt-4 border border-[#D8D9D9] w-full`}
              style={{ boxShadow: '0px 22px 40px 0px #0000000D' }}
            >
              <div className="flex mb-4 sm:mb-0 h-[108px]">
                <div className="flex ">
                  <div className=" relative">
                    <img
                      className="w-[150px] rounded-s-md object-cover h-[108px]"
                      src={getKalturaURL(video.videoURI)}
                      alt="Thumbnail"
                    />
                    <div className="mt-2 ml-2 absolute top-0 left-0 bg-white text-black px-2 py-1 rounded text-xs font-semibold">
                      {video.estimatedTime}
                    </div>
                  </div>
                  <div className="inline-block align-middle my-auto">
                    <div className="my-auto font-semibold mt-4 sm:mt-0 text-black mx-4 text-lg line-clamp-2 leading-5 pb-4 sm:pb-0">
                      {`${video.name}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="self-center mt-4 cursor-pointer"
              onClick={() => removeVideo(video.id)}
            >
              <div className="inline-block align-middle">
                <div className="font-semibold mt-4 sm:mt-0 text-black mx-4 text-lg line-clamp-2 leading-5 pb-4 sm:pb-0">
                  <img src={iconClose} alt="" />
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default BodyModal;
