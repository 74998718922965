const PATH = 'fe.pages.certifications.observers';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Observers'
  },
  name: {
    key: `${PATH}.name`,
    defaultValue: 'Name'
  },
  ageLevel: {
    key: `${PATH}.age_level`,
    defaultValue: 'Age Level'
  },
  class2008: {
    key: `${PATH}.class_2008`,
    defaultValue: 'CLASS Version: 2008'
  },
  class2ndEdition: {
    key: `${PATH}.class_2nd_edition`,
    defaultValue: 'CLASS Version: 2nd Edition'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  hierarchy: {
    key: `${PATH}.hierarchy`,
    defaultValue: 'Hierarchy'
  },
  trainingDate: {
    key: `${PATH}.training_date`,
    defaultValue: 'Training Date'
  },
  testStartDate: {
    key: `${PATH}.test_start_date`,
    defaultValue: 'Test Start Date'
  },
  back: {
    key: `${PATH}.back_to_summary`,
    defaultValue: 'Back to Summary'
  },
  seekingInitial: {
    key: `${PATH}.seeking_initial`,
    defaultValue: 'Seeking Initial'
  },
  current: {
    key: `${PATH}.current`,
    defaultValue: 'Active: Current'
  },
  expiringSoon: {
    key: `${PATH}.expiring_soon`,
    defaultValue: 'Active: Expiring Soon'
  },
  expired: {
    key: `${PATH}.expired`,
    defaultValue: 'Expired'
  },
  allStatus: {
    key: `${PATH}.all_statuses`,
    defaultValue: 'All Certification Statuses'
  },
  columns: {
    key: `${PATH}.columns`,
    defaultValue: 'Columns'
  },
  filters: {
    selectAgeLevel: {
      key: `${PATH}.filters.select_age_level`,
      defaultValue: 'Select Age Level'
    },
    organizationLevel: {
      key: `${PATH}.filters.organization_level`,
      defaultValue: 'Organization Level'
    },
    searchPlaceholder: {
      key: `${PATH}.filters.search`,
      defaultValue: 'Search name or email'
    }
  },
  certStatus: {
    key: `${PATH}.cert_status`,
    defaultValue: 'Certification Status'
  },
  certExpiration: {
    key: `${PATH}.cert_expiration`,
    defaultValue: 'Certification Expiration'
  },
  allAgeLevels: {
    key: `${PATH}.all_age_levels`,
    defaultValue: 'All Age Levels'
  },
  noObserversMessage: {
    key: `${PATH}.no_observers_message`,
    defaultValue: `It looks like you don't have any observers with a certification for {{ageLevel}} in {{hierarchy}}`
  },
  exportCsv: {
    key: `${PATH}.export_csv`,
    defaultValue: 'Export CSV'
  },
  futureDateWarning: {
    key: `${PATH}.future_date_warning`,
    defaultValue:
      'You are viewing observation states based on the future date selected'
  }
};

export default messages;
