function formatTwoDigits(n: number) {
  return n.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
}

export function formatHours(learningHours?: string) {
  const hoursAsNumber = parseFloat(learningHours ?? '0.0');
  const hours = Math.trunc(hoursAsNumber);
  const minutes = Math.round((hoursAsNumber - hours) * 60);

  return `${hours}:${formatTwoDigits(minutes)}`;
}

export function hasCEUs(ceus?: string) {
  const ceusAsNumber = parseFloat(ceus ?? '0.0');
  return ceusAsNumber > 0;
}
