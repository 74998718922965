const PATH = 'fe.pages.organization.members';

const messages = {
  addMembers: {
    key: `${PATH}.add_members`,
    defaultValue: 'Add Members'
  },
  name: {
    key: `${PATH}.name`,
    defaultValue: 'Name'
  },
  members: {
    key: `${PATH}.members`,
    defaultValue: 'Members'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  nameAndEmail: {
    key: `${PATH}.name_and_email`,
    defaultValue: 'Name & Email'
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search name or email'
  },
  hierarchyAssociation: {
    key: `${PATH}.hierarchy_association`,
    defaultValue: 'Hierarchy Association'
  },
  role: {
    key: `${PATH}.role`,
    defaultValue: 'Role'
  },
  lastLogin: {
    key: `${PATH}.last_login`,
    defaultValue: 'Last Login'
  },
  memberCount: {
    key: `${PATH}.member_count`,
    defaultValue: 'Member Count: {{membersCount}}'
  },
  totalMembers: {
    key: `${PATH}.total_members`,
    defaultValue: 'Total: {{count}} members'
  },
  filterRoles: {
    key: `${PATH}.filter_roles`,
    defaultValue: 'Filter roles'
  },
  clearRoles: {
    key: `${PATH}.clear_roles`,
    defaultValue: 'Clear selected roles'
  },
  clearNodes: {
    key: `${PATH}.clear_nodes`,
    defaultValue: 'Clear selected hierarchy associations'
  },
  downloadMemberList: {
    key: `${PATH}.download_members_list`,
    defaultValue: 'Download Members List'
  },
  filterHierarchy: {
    key: `${PATH}.filter_hierarchy`,
    defaultValue: 'Filter by Hierarchy'
  },
  saveButton: {
    key: `${PATH}.save_button`,
    defaultValue: 'Save & Close'
  }
};

export default messages;
