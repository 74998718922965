import axios from 'axios';
import { Auditable } from 'types/api/Auditable';
import { TestTemplate } from 'types/api/TestTemplate';
import { Timestamp } from 'types/api/Timestamp';

export function getTestTemplates(type: string, params = {}) {
  return axios.get(`/api/v2/test_templates?test_type=${type}`, { params });
}

export function getTestTemplate(id: number) {
  return axios.get<TestTemplate & Auditable & Timestamp>(
    `/api/v2/test_templates/${id}`
  );
}

export function createTestTemplate(test_template: TestTemplate) {
  return axios.post(`/api/v2/test_templates`, test_template);
}

export function updateTestTemplate(test_template: TestTemplate) {
  return axios.put(`/api/v2/test_templates/${test_template.id}`, test_template);
}

export function getPrepResources(id: number, attempt_id: number | null) {
  return axios.get(
    `/api/v2/test_templates/${id}/prep_resources?attempt_id=${attempt_id}`
  );
}
