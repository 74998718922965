const PATH = 'fe.components.training_history.training_card';

const messages = {
  trainer: {
    key: `${PATH}.trainer`,
    defaultValue: 'Trainer: {{name}}'
  },
  hours: {
    key: `${PATH}.hours`,
    defaultValue: 'Learning Hours'
  },
  ceus: {
    key: `${PATH}.ceus`,
    defaultValue: 'CEUs Earned'
  },
  certificate: {
    key: `${PATH}.certificate`,
    defaultValue: 'Download Certificate'
  },
  downloading: {
    key: `${PATH}.downloading`,
    defaultValue: 'Downloading...'
  },
  onlineCourse: {
    key: `${PATH}.online_course`,
    defaultValue: 'Online Coursework'
  },
  learningPathway: {
    key: `${PATH}.learning_pathway`,
    defaultValue: 'Learning Pathway'
  },
  completedOn: {
    key: `${PATH}.completed_on`,
    defaultValue: 'Completed on'
  },
  to: {
    key: `${PATH}.to`,
    defaultValue: 'to'
  }
};

export default messages;
