import { Button } from 'semantic-ui-react';
import { AGE_LEVEL_ABBREVIATIONS } from 'types/api/AgeLevel';
import { ButtonContainer } from './Styled';
import i18n from 'helpers/i18n';
import messages from './messages';

interface ReliabilityToggleProps {
  /**Age level value if is selected */
  value?: AGE_LEVEL_ABBREVIATIONS;
  /**Callback function */
  onChange: (value: AGE_LEVEL_ABBREVIATIONS) => void;
  /**Options values for toggle */
  options: any[];
}

function ReliabilityToggle({
  value,
  onChange,
  options
}: ReliabilityToggleProps) {
  function handleChange(value: any) {
    onChange(value);
  }

  if (options.length <= 1) {
    return null;
  }

  return (
    <ButtonContainer>
      <Button.Group size="tiny">
        {options.map(option => {
          return (
            <Button
              onClick={() => handleChange(option.value)}
              key={option.id}
              color={option.value === value ? 'blue' : undefined}
              aria-label={i18n.ft(messages.showReliability, {
                age: option.text
              })}
            >
              {option.text}
            </Button>
          );
        })}
      </Button.Group>
    </ButtonContainer>
  );
}
export default ReliabilityToggle;
