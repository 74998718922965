// @params date: string
// @returns string
// @description
// @example formatDate('2024-04-10T14:50:18.516Z') => '2024-04-10'
/**
 * Formats a string date.
 *
 * @param {String} date - String date format.
 * @return {String} - Formatted date.
 * @example
 * formatDate("2024-04-10T14:50:18.516Z"); // Returns: "2024-04-10"
 */
export function formatDate(date?: string) {
  if (!date) {
    return '';
  }
  const newDate = new Date(date);

  const year = newDate.toLocaleString('default', { year: 'numeric' });
  const month = newDate.toLocaleString('default', { month: '2-digit' });
  const day = newDate.toLocaleString('default', { day: '2-digit' });

  return [year, month, day].join('-');
}
