import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';

export const SeparatorWrapper = styled.div`
  text-align: center;
  position: relative;
  margin: 20px 0;
`;

export const SeparatorText = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  padding: 0 20px;
  font-weight: 700;
`;

export const HRSeparator = styled.hr`
  border-top: 2px solid #dfdfdf;
  width: 50%;
  margin: auto;
`;

export const StyledPopup = styled(Popup)`
  background-color: #ebeced !important;
  box-shadow: 0px 10px 32px -4px #364a5e33 !important;
  border: 0 !important;
  border-radius: 11px !important;
  padding-bottom: 0px !important;

  ::before {
    background-color: #ebeced !important;
    border: 0 !important;
  }
`;
