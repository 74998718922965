import moment from 'moment';
import { head, isEmpty } from 'ramda';
import { Training } from 'types/api/Training';
import { sortByDate } from 'helpers/trainingSession';

/**
 * Returns `true` if the first session date of the training is
 * today or before today.
 */
export default function hasStarted(training: Training): boolean {
  if (isEmpty(training.sessions)) {
    return true;
  }

  const sessionsSortedByDate = sortByDate(training.sessions);
  const today = moment();
  const firstSession = head(sessionsSortedByDate);
  const firstSessionDate = moment(firstSession!.starts_at);

  return today.isSameOrAfter(firstSessionDate.startOf('day'));
}
