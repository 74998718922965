import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import { FormikHelpers } from 'formik';
import { pickUserFields } from './utils';
import useAsync from 'hooks/useAsync';
import { useDispatch } from 'react-redux';
import { update } from 'store/slices/user';
import { Button } from 'semantic-ui-react';
import { updateUserMe } from 'actions/userMe';
import useCurrentUser from 'hooks/useCurrentUser';
import { useStates } from 'components/dropdowns/States';
import { toastSuccess, toastError } from 'components/Toast';
import { useCountries } from 'components/dropdowns/Countries';
import { useLanguages } from 'components/dropdowns/Languages';
import { useEmailNotifications } from 'components/dropdowns/EmailNotifications';

import {
  Formik,
  Form,
  Input,
  Select,
  PreventNavigation
} from 'components/EnhancedForm';

import {
  Section,
  SectionTitle,
  FieldRow,
  Actions,
  ButtonWrapper
} from './Styled';

const validationSchema = yup.object({
  first_name: yup
    .string()
    .required(i18n.ft(messages.errors.firstName.required)),
  last_name: yup.string().required(i18n.ft(messages.errors.lastName.required)),
  email: yup
    .string()
    .email(i18n.ft(messages.errors.email.invalid))
    .required(i18n.ft(messages.errors.email.required)),
  email_notification_preference: yup
    .string()
    .nullable()
    .required(i18n.ft(messages.errors.notifications.required)),
  preferred_language: yup
    .string()
    .nullable()
    .required(i18n.ft(messages.errors.language.required)),
  street_address_1: yup.string().nullable(),
  state_registry_id: yup.string().nullable(),
  street_address_2: yup.string().nullable(),
  locality: yup.string().nullable(),
  country: yup.string().nullable(),
  region: yup.string().nullable(),
  postal_code: yup.string().nullable()
});

function AccountPage() {
  const states = useStates();
  const countries = useCountries();
  const languages = useLanguages();
  const notifications = useEmailNotifications();
  const currentUser = useCurrentUser();
  const { run, isLoading } = useAsync();
  const dispatch = useDispatch();

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    run(updateUserMe(values), {
      onSuccess: (data: any) => {
        dispatch(update(data));
        formik.resetForm({ values: values });
        toastSuccess();
      },
      onError: (error: any) => {
        if (error.errors?.user?.email) {
          const message = error.errors.user.email;
          formik.setFieldError('email', message);
          toastError({
            description: i18n.ft(messages.errors.base, { extra: message })
          });
        } else {
          toastError();
        }
      }
    });
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={pickUserFields(currentUser)}
    >
      {formik => (
        <Form>
          <Section>
            <SectionTitle>{i18n.ft(messages.information)}</SectionTitle>
            <PreventNavigation />
            <FieldRow>
              <Input
                name="first_name"
                label={i18n.ft(messages.fields.firstName)}
              />
              <Input
                name="last_name"
                label={i18n.ft(messages.fields.lastName)}
              />
              <Input
                type="email"
                name="email"
                label={i18n.ft(messages.fields.email)}
              />
              <Input
                name="state_registry_id"
                label={i18n.ft(messages.fields.stateRegistry)}
              />
            </FieldRow>
          </Section>

          <Section>
            <SectionTitle>{i18n.ft(messages.settings)}</SectionTitle>

            <FieldRow>
              <Select
                name="email_notification_preference"
                label={i18n.ft(messages.fields.notifications)}
                options={notifications}
              />
              <Select
                name="preferred_language"
                label={i18n.ft(messages.fields.language)}
                options={languages}
              />
            </FieldRow>
          </Section>

          <Section>
            <SectionTitle>{i18n.ft(messages.address)}</SectionTitle>

            <FieldRow>
              <Input
                name="street_address_1"
                label={i18n.ft(messages.fields.shipping1)}
                placeholder={i18n.ft(messages.fields.shipping1Placeholder)}
              />
              <Input
                name="street_address_2"
                label={i18n.ft(messages.fields.shipping2)}
                placeholder={i18n.ft(messages.fields.shipping2Placeholder)}
              />
              <Input name="locality" label={i18n.ft(messages.fields.city)} />

              <Select
                name="country"
                label={i18n.ft(messages.fields.country)}
                options={countries}
                search
                upward
              />

              {formik.values.country === 'US' ? (
                <Select
                  name="region"
                  label={i18n.ft(messages.fields.state)}
                  options={states}
                  search
                  upward
                />
              ) : (
                <Input name="region" label={i18n.ft(messages.fields.state)} />
              )}

              <Input
                name="postal_code"
                label={i18n.ft(messages.fields.postalCode)}
              />
            </FieldRow>
          </Section>

          <Actions>
            <ButtonWrapper>
              <Button
                fluid
                type="submit"
                color="blue"
                loading={isLoading}
                disabled={isLoading}
              >
                {i18n.ft(messages.save)}
              </Button>
            </ButtonWrapper>
          </Actions>
        </Form>
      )}
    </Formik>
  );
}

export default AccountPage;
