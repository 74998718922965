import { createObservationNotes } from 'actions/improvements';
import { InformationCard } from '../Styled';
import messages from './messages';
import i18n from 'helpers/i18n';
import { Form, Formik, TextArea } from 'components/EnhancedForm';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { Note } from 'pages/Improvements/types';
import moment from 'moment';
import { SubmitButton } from 'components/Trainings/View/CardCheckout/Styled';
import CardHeader from 'pages/Improvements/CardHeader';
import { NOTES_MAX_CHAR_LENGTH } from 'pages/Improvements/EducatorProfile/utils';

const VALIDATION_SCHEMA = Yup.object({
  response: Yup.string().required(i18n.ft(messages.responseFieldRequired))
});

interface InitialObservationProps {
  educatorId: number;
  runObservation: any;
  notes?: Note;
  handleInitialObservationToggle: () => void;
  hasCompleted?: boolean;
}

function InitialObservation({
  educatorId,
  runObservation,
  notes,
  handleInitialObservationToggle,
  hasCompleted = false
}: InitialObservationProps) {
  const [isFormComplete, setIsFormComplete] = useState(
    notes?.response !== undefined
  );
  const [collapsed, toggleCollapsed] = useState(isFormComplete);
  const [isEdit, setIsEdit] = useState(false);

  const initialValues = notes || { response: '' };
  const observationDate = moment(notes?.created_at)
    .format('MMMM D, YYYY')
    .toString();

  function handleSubmitForm(values: any) {
    runObservation(createObservationNotes(educatorId, values.response));
    setIsFormComplete(true);
    toggleCollapsed(!collapsed);
  }

  function handleFormSize() {
    toggleCollapsed(true);
    if (!isFormComplete) {
      handleInitialObservationToggle();
    }
  }

  if (collapsed) {
    return (
      <InformationCard className="p-8 flex justify-between">
        <h2 className="text-xl items-center">
          <i className="check circle icon text-[#7AA03F]" />{' '}
          {i18n.ft(messages.observationCompleted)}: {observationDate}
        </h2>
        <button
          className="text-[#017EA7] print:hidden"
          onClick={() => {
            toggleCollapsed(!collapsed);
          }}
        >
          <strong>{i18n.ft(messages.viewObservationDetails)}</strong>
        </button>
      </InformationCard>
    );
  }

  function handleEdit() {
    setIsEdit(true);
  }

  return (
    <InformationCard className="p-8">
      <CardHeader
        title={i18n.ft(messages.observation)}
        subtitle={i18n.ft(messages.observationSheet)}
        showPrintButton={true}
        showCTA={true}
        onClickAction={handleFormSize}
      />

      <div>
        <h4 className="!mb-2">{i18n.ft(messages.completedAt)}</h4>
        <ul>
          <li className="mb-3">
            <i className="fa-solid fa-calendar-days mr-3 text-[#888]" />
            {observationDate}
          </li>
        </ul>
      </div>

      <p className="pt-4">{i18n.ft(messages.observationDescription)}</p>
      <div className="!border-solid border rounded-md p-4 w-fit border-[#DEDEDF]">
        <h4 className="!mb-2 !mt-0">
          {i18n.ft(messages.generalObservationCues)}
        </h4>

        <ul className="!list-disc !list-image-none pl-6">
          <li>{i18n.ft(messages.cue1)}</li>
          <li>{i18n.ft(messages.cue2)}</li>
          <li>{i18n.ft(messages.cue3)}</li>
        </ul>
      </div>
      <div className="pt-4">
        <Formik
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmitForm}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <TextArea
                name="response"
                rows={10}
                maxLength={NOTES_MAX_CHAR_LENGTH}
                label={i18n.ft(messages.responseLabel)}
                placeholder={i18n.ft(messages.responsePlaceholder)}
                disabled={isFormComplete && !isEdit}
              />
              <div className="pt-4">
                <SubmitButton>
                  {isFormComplete && !isEdit ? (
                    <div>
                      <Button
                        type="button"
                        className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                        color="blue"
                        onClick={handleEdit}
                        disabled={hasCompleted}
                        content={i18n.ft(messages.edit)}
                      />
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                      color="blue"
                      content={i18n.ft(messages.submit)}
                    />
                  )}
                </SubmitButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </InformationCard>
  );
}

export default InitialObservation;
