import i18n from 'helpers/i18n';
import messages from './messages';
import { clsx } from 'clsx';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import { useMeasure } from 'context/measure';
import { Icon, Loader } from 'semantic-ui-react';
import ScoresByCycleTable from './ScoresByCycleTable';
import { ObservationAverages } from 'types/api/envScales/Observation';
import { getObservationAverages } from 'actions/envScales/observations';

import {
  Section,
  SectionHeader,
  SectionContent
} from 'components/Measure/View/Styled';

import {
  Wrapper,
  GridWrapper,
  DashedLine,
  ScoreLine,
  ScoreWrapper,
  DomainWrapper,
  DomainName,
  DomainScore,
  DimensionWrapper,
  DimensionName,
  DimensionIcon,
  DimensionScore
} from './Styled';

type DomainColors = {
  [key: string]: string;
};

const colors: DomainColors = {
  es: '#FFF6F0',
  co: '#FFF3F7',
  is: '#FCF2FF',
  ebs: '#FFF6F0',
  esl: '#FCF2FF',
  rc: '#FFF6F0'
};

interface ObservationScoresProps {
  fitted?: boolean;
}

function ObservationScores({ fitted }: ObservationScoresProps) {
  const { assessment } = useMeasure();
  const observationId = assessment.observation!.id;
  const { run, data, isPending } = useAsync();

  useEffect(() => {
    run(getObservationAverages(observationId));
  }, [run, observationId]);

  if (isPending) {
    return <Loader active inline="centered" />;
  }

  const numberOfScores = (data as ObservationAverages).average_scores.length;
  const sectionClasses = clsx({
    'mt-8 sm:mt-10': !fitted,
    'mt-2': assessment.includes_environments
  });

  return (
    <Section
      className={sectionClasses}
      borderTopRadius={assessment.includes_environments ? '0' : '12px'}
    >
      <SectionHeader>{i18n.ft(messages.title)}</SectionHeader>
      <SectionContent>
        <Wrapper>
          <GridWrapper scores={numberOfScores}>
            <DashedLine />
            {(data as ObservationAverages).average_scores.map((domain, idx) => (
              <ScoreWrapper key={domain.abbreviation}>
                {idx !== 0 && <ScoreLine />}

                <DomainWrapper bgColor={colors[domain.abbreviation]}>
                  <DomainName>{domain.name}</DomainName>
                  <DomainScore>{domain.average.toFixed(2)}</DomainScore>
                </DomainWrapper>
                {domain.dimensions.map(dimension => (
                  <DimensionWrapper key={dimension.abbreviation}>
                    <DimensionName title={dimension.name}>
                      {dimension.abbreviation.toLocaleUpperCase()}
                    </DimensionName>
                    <DimensionIcon>
                      <Icon size="big" name="long arrow alternate right" />
                    </DimensionIcon>
                    <DimensionScore bgColor={colors[domain.abbreviation]}>
                      {dimension.average.toFixed(2)}
                    </DimensionScore>
                  </DimensionWrapper>
                ))}
              </ScoreWrapper>
            ))}
          </GridWrapper>
          <ScoresByCycleTable />
        </Wrapper>
      </SectionContent>
    </Section>
  );
}

export default ObservationScores;
