import moment from 'moment';
import i18nJS from 'i18n-js';
import { Training } from 'types/api/Training';
import { map, prop, join, isEmpty, isNil } from 'ramda';
import { Cart } from 'types/BigCommerce/Cart';
import { Checkout } from 'types/BigCommerce/Checkout';
import { Coupon } from 'types/BigCommerce/Coupon';

export function formatTrainer(training: Training): string {
  return `${training.trainer.first_name} ${training.trainer.last_name}`;
}

export function formatAgeLevels(training: Training): string {
  return join(', ', map(prop('name'), training.age_levels));
}

export function formatSessions(training: Training): string {
  if (isEmpty(training.sessions)) {
    return 'TBD';
  }

  const dates = map(session => {
    return moment(session.starts_at).local().format('LL');
  }, training.sessions);

  return join(' / ', dates);
}

export function toCurrency(value?: number): string {
  if (!isNil(value)) {
    return i18nJS.toCurrency(value);
  } else {
    return '--.--';
  }
}

export function getPricePerParticipant(
  numOfParticipants: number,
  cart: Cart
): string {
  const price = cart.base_amount / numOfParticipants;
  return toCurrency(price);
}

export function getSubtotal(cart: Cart, checkout?: Checkout): string {
  if (isNil(checkout)) {
    return toCurrency(cart.base_amount);
  } else {
    return toCurrency(checkout.subtotal_ex_tax);
  }
}

export function getCoupons(cart: Cart, checkout?: Checkout): Coupon[] {
  if (isNil(checkout)) {
    return cart.coupons;
  } else {
    return checkout.coupons;
  }
}

export function getGrandTotal(cart: Cart, checkout?: Checkout): string {
  if (isNil(checkout)) {
    return toCurrency(cart.base_amount);
  } else {
    return toCurrency(checkout.grand_total);
  }
}

export function getShipping(checkout?: Checkout): string {
  if (isNil(checkout)) {
    return toCurrency();
  } else {
    return toCurrency(
      checkout.shipping_cost_total_ex_tax + checkout.handling_cost_total_ex_tax
    );
  }
}

export function getTaxes(checkout?: Checkout): string {
  if (isNil(checkout)) {
    return toCurrency();
  } else {
    return toCurrency(checkout.tax_total);
  }
}
