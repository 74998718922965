const PATH = 'fe.pages.certifications.dashboard';

const messages = {
  observer: {
    key: `${PATH}.observer`,
    defaultValue: 'Observers'
  }
};

export default messages;
