import styled from 'styled-components';
import { Icon, IconProps } from 'semantic-ui-react';

const Wrapper = styled.div`
  font-family: proxima-nova;
  display: flex;
`;

const IconWrapper = styled.div`
  margin-right: 16px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${p => p.theme.colors['neutral']['900']};
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${p => p.theme.colors['neutral']['500']};
`;

export interface ToastProps {
  /** Toast title. */
  title?: string;

  /** Toast description. */
  description?: string;

  /** Toast icon. Uses Semantic UI icon props. */
  icon?: IconProps;
}

/**
 * triggered via `toastSuccess` and `toastError` functions
 *
 * displayed in a shared ToastContainer component
 *
 */
export function Toast({
  title,
  description,
  icon = { name: 'info circle' }
}: ToastProps) {
  return (
    <Wrapper>
      <IconWrapper>
        <Icon fitted {...icon} />
      </IconWrapper>
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </div>
    </Wrapper>
  );
}
