import React from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';
import { Icon } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import RouteHelpers from 'helpers/routes';

const styleLink = Comp => styled(Comp)`
  align-self: ${props => (props.align === 'right' ? 'flex-end' : 'flex-start')};
  text-align: ${props => (props.align === 'right' ? 'right' : 'left')};
  flex: 1;
  margin: auto 0;

  display: flex;
  align-items: center;
`;

const StyledNavLink = styleLink(NavLink);
const StyledPrimitiveLink = styleLink('a');

const Text = styled(Typography)`
  flex: 1;
  line-height: 1em;
`;

function LeftNavItem({ disabled, onClick, route, params, text }) {
  const color = disabled ? 'grey' : 'blue';
  const Wrapper = route ? StyledNavLink : StyledPrimitiveLink;

  return (
    <Wrapper
      onClick={onClick}
      to={route ? RouteHelpers.getPath(route, params) : null}
    >
      <Icon fitted name="chevron left circle" color={color} size="large" />

      <Text prepend bold content={text} color={color} size="small" />
    </Wrapper>
  );
}

function RightNavItem({ disabled, onClick, route, params, text }) {
  const color = disabled ? 'grey' : 'blue';
  const Wrapper = route ? StyledNavLink : StyledPrimitiveLink;

  return (
    <Wrapper
      align="right"
      onClick={onClick}
      to={route ? RouteHelpers.getPath(route, params) : null}
    >
      <Text
        append
        bold
        content={text}
        color={color}
        size="small"
        textAlign="right"
      />
      <Icon fitted name="chevron right circle" color={color} size="large" />
    </Wrapper>
  );
}

const MobileNav = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 12px;
`;

MobileNav.Left = LeftNavItem;
MobileNav.Right = RightNavItem;

export default MobileNav;
