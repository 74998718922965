import { clsx } from 'clsx';

interface TableColumn {
  header: string;
  accessor: string;
}

interface TableProps {
  columns: TableColumn[];
  data: any[];
}

function ObserversTable({ columns, data }: TableProps) {
  return (
    <div className="overflow-x-auto overflow-y-auto max-h-[768px] isolate">
      <table className="min-w-full border-separate border-spacing-0">
        <thead className="sticky top-0 z-10">
          <tr>
            {columns.map((column, index) => {
              const isFirstColumn = index === 0;
              const isLastColumn = index === columns.length - 1;

              const cellClasses = clsx(
                'border-r border-white sticky bg-[#3C3F42] text-white p-3 last:border-0 text-left',
                isFirstColumn ? 'left-0 z-10 rounded-tl-xl' : 'z-0',
                isLastColumn ? 'rounded-tr-xl' : null
              );

              return (
                <th key={index} className={cellClasses}>
                  {column.header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => {
                const isLastRow = rowIndex === data.length - 1;
                const isFirstColumn = colIndex === 0;
                const isLastRowAndFirstColumn = isLastRow && colIndex === 0;
                const isLastRowAndLastColumn =
                  isLastRow && colIndex === columns.length - 1;

                const cellClasses = clsx(
                  'border border-[#8A8C8E] p-3',
                  isFirstColumn ? 'sticky left-0 z-9 border-r-4' : 'z-0',
                  rowIndex % 2 === 0 ? 'bg-white' : 'bg-[#CEEBF4]',
                  isLastRowAndFirstColumn ? 'rounded-bl-xl' : null,
                  isLastRowAndLastColumn ? 'rounded-br-xl' : null
                );

                return (
                  <td key={colIndex} className={cellClasses}>
                    {row[column.accessor] === undefined
                      ? '--'
                      : row[column.accessor]}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ObserversTable;
