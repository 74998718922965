import {
  InputRadioWrapper,
  InputRadioWrapperWithDescription,
  DescriptionLabel,
  FormRowWrapper
} from './Styled';
import { BaseFieldProps } from '../../EnhancedForm/utils';
import { useEffect, useState } from 'react';
import { isNil } from 'ramda';

import { Radio as BaseRadio, RadioProps as BaseProps } from 'semantic-ui-react';

type RadioOptionType = {
  value: string | number;
  label: string;
  description: string;
  aria?: string;
};

interface RadioButtonProps extends BaseFieldProps<BaseProps> {
  /** Callback fired when the value changes. */
  onChange: (value: any) => void;
  /** Group name for radio group */
  name: string;
  /** List of options for the radio group */
  options: RadioOptionType[];
  /** Option selected or null */
  selected: any;
}

function RadioGroup({
  name,
  onChange,
  options,
  selected,
  ...props
}: RadioButtonProps) {
  const [checkValue, setCheckValue] = useState<any>(selected);

  const handleChange: BaseProps['onChange'] = (_event, { value }) => {
    setCheckValue(value);
  };

  useEffect(() => {
    if (!isNil(checkValue)) {
      onChange(checkValue);
    }
  }, [checkValue, onChange]);

  return (
    <FormRowWrapper>
      {options.map((option, index) => (
        <InputRadioWrapper key={index} checked={checkValue === option.value}>
          <InputRadioWrapperWithDescription
            {...props}
            name={name}
            className="radio"
            value={option.value}
            label={option.label}
            checked={checkValue === option.value}
            onChange={handleChange}
            aria-label={option.aria || props['aria-label']}
            aria-checked={checkValue === option.value}
          />
          {checkValue === option.value && (
            <DescriptionLabel>{option.description}</DescriptionLabel>
          )}
        </InputRadioWrapper>
      ))}
    </FormRowWrapper>
  );
}

RadioGroup.defaultProps = {
  inline: true,
  control: BaseRadio
};

export default RadioGroup;
