const PATH = 'fe.pages.trainings.cancellation_policy';

const messages = {
  mainHeader: {
    key: `${PATH}.main_header`,
    defaultValue: 'AFFILIATE CANCELLATION POLICY'
  },
  section01: {
    key: `${PATH}.section_01`,
    defaultValue: 'TRAINER/PARTICIPANT CANCELLATIONS AND TRANSFERS'
  },
  section02: {
    key: `${PATH}.section_02`,
    defaultValue: 'CANCELLATION OF TRAINING BY TEACHSTONE'
  },
  item01: {
    key: `${PATH}.item_01`,
    defaultValue:
      'Once paid, registration and material fees for a training/s conducted by a licensed Teachstone Affiliate CLASS trainer are non-refundable.'
  },
  item02: {
    key: `${PATH}.item_02`,
    defaultValue:
      'Rescheduling or transfer requests must be received prior to the last day of the original training date.'
  },
  item03: {
    key: `${PATH}.item_03`,
    defaultValue:
      'Any participants wishing to transfer or reschedule must attend a future training of the same type and for the same age level as the originally scheduled training.'
  },
  item04: {
    key: `${PATH}.item_04`,
    defaultValue:
      'All rescheduling or transfer requests must be made in writing via the Teachstone Support page'
  },
  item05: {
    key: `${PATH}.item_05`,
    defaultValue:
      'Participant substitutions may be made at any time prior to the last day of the training. The substitution request must be made by the Affiliate CLASS trainer via the Teachstone Support page'
  },
  item06: {
    key: `${PATH}.item_06`,
    defaultValue:
      'The substituting participant must use the materials that were supplied for the original participant.'
  },
  item07: {
    key: `${PATH}.item_07`,
    defaultValue:
      'Teachstone reserves the right to cancel or change a training at any time and for any reason, including but not limited to, registration of participants not authorized by the trainer’s Affiliate CLASS Trainer Agreement, plans to conduct virtual training without having received formal approval from Teachstone to do so, lack of a current signed Affiliate CLASS Trainer Agreement, delinquent payment, or lack of needed materials or equipment.'
  },
  item08: {
    key: `${PATH}.item_08`,
    defaultValue:
      'Teachstone will endeavor to provide notification to the Affiliate CLASS Trainer of cancellation or changes within fifteen (15) days of training, whenever possible. The Affiliate CLASS Trainer will be responsible for providing timely notification to the participants of the canceled training.'
  },
  item09: {
    key: `${PATH}.item_09`,
    defaultValue:
      'In the event of cancellation of a training by Teachstone, Teachstone will work with the Affiliate CLASS Trainer to reschedule the training.'
  },
  item10: {
    key: `${PATH}.item_10`,
    defaultValue:
      'Teachstone is not liable for any direct, or indirect, consequential or special damages that may be incurred due to a cancellation of a scheduled training, including, but not limited to, cancellation penalties for transportation or accommodations.'
  }
};

export default messages;
