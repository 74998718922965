import * as R from 'ramda';
import { Document, Page, pdfjs } from 'react-pdf';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Header, Modal, Icon } from 'semantic-ui-react';
import { getAttachments, uploadFile, deleteFile } from 'actions/improvements';
import { isPDF, isImage } from 'helpers/file';
import useAsync from 'hooks/useAsync';
import messages from './messages';
import i18n from 'helpers/i18n';

interface AttachmentsProps {
  growthJourneyId: number;
  educatorsName: string;
}

function Attachments({ growthJourneyId, educatorsName }: AttachmentsProps) {
  const [uploading, setUploading] = useState<boolean>(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [errors, setErrors] = useState<string[]>([]);
  const [previewData, setPreviewData] = useState<string>('');
  const { run, data } = useAsync();

  useEffect(() => {
    run(getAttachments(growthJourneyId));
  }, [run, growthJourneyId]);

  function handleFileSelect() {
    hiddenFileInput?.current?.click();
  }

  async function handleSubmit() {
    if (!file) {
      return;
    }

    let formData = new FormData();
    formData.append('file', file);
    setUploading(true);

    try {
      await uploadFile(growthJourneyId, formData);
    } finally {
      setUploading(false);
    }

    run(getAttachments(growthJourneyId));
    handleClosePreview();
  }

  function onFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    let fileErrors = [];
    const maxFileSize = 3.0; // Size in megabytes
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    if (file.size / 1024 / 1024 > maxFileSize) {
      fileErrors.push(i18n.ft(messages.fileTooBig, { maxFileSize }));
    }

    if (!isImage(file.type) && !isPDF(file.type)) {
      fileErrors.push(i18n.ft(messages.wrongFileType));
    }

    if (!R.isEmpty(fileErrors)) {
      setErrors(fileErrors);
      return;
    }

    let reader = new FileReader();
    reader.onloadend = () => {
      setFile(file);
      setPreviewData(reader.result as string);
    };
    reader.readAsDataURL(file);
  }

  async function handleDelete(attachmentId: number, filename: string) {
    if (!window.confirm(i18n.ft(messages.confirmDelete, { filename }))) {
      return;
    }
    setUploading(true);
    try {
      await deleteFile(growthJourneyId, attachmentId);
    } finally {
      setUploading(false);
    }
    run(getAttachments(growthJourneyId));
  }

  function getPreview(attachment: { type: string; name: string }) {
    if (isImage(attachment.type)) {
      return (
        <div>
          <img src={previewData} alt={attachment.name} />
          <p className="text-center">
            {i18n.ft(messages.fileSelected)}: {attachment.name}
          </p>
        </div>
      );
    }
    if (isPDF(attachment.type)) {
      return (
        <div className="text-center">
          <p>{i18n.ft(messages.previewParagraph1, { educatorsName })}</p>
          <p>{i18n.ft(messages.previewParagraph2)}</p>
          {previewData && previewData.length && (
            <div className="flex justify-center max-h-[50vh] overflow-scroll">
              <Document file={previewData}>
                <Page pageNumber={1} scale={1.4} />
              </Document>
            </div>
          )}
          {file && (
            <p>
              {i18n.ft(messages.fileSelected)}: {file.name}
            </p>
          )}
        </div>
      );
    }
  }

  function handleClosePreview() {
    hiddenFileInput!.current!.value = '';
    setFile(undefined);
    setPreviewData('');
    setErrors([]);
  }

  return (
    <div>
      <header>
        <h2>{i18n.ft(messages.cardTitle)}</h2>
      </header>

      <div>
        {data &&
          data.map((attachment: any) => (
            <div
              className="flex justify-between bg-blue-50 p-3 rounded-md mb-3"
              key={attachment.id}
            >
              <strong>{attachment.filename}</strong>
              <div className="flex justify-center">
                <div className="grid grid-cols-2">
                  <a
                    download
                    className="text-slate-800 font-semibold"
                    href={`/api/v2/improvement/growth_journeys/${growthJourneyId}/attachments/${attachment.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon name="eye" color="blue" className="cursor-pointer" />
                  </a>
                  <button
                    className={isPDF(attachment.type) ? 'hidden' : ''}
                    disabled={uploading}
                    onClick={() =>
                      handleDelete(attachment.id, attachment.filename)
                    }
                  >
                    <Icon name="trash alternate outline" color="red" />
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>

      <input
        type="file"
        className="hidden"
        accept="image/*,application/pdf,image/heif,image/heic"
        aria-label={i18n.ft(messages.uploadFile)}
        ref={hiddenFileInput}
        onChange={onFileSelect}
      />

      <div className="flex">
        <Button
          className="w-full"
          color="blue"
          onClick={handleFileSelect}
          disabled={uploading}
          loading={uploading}
        >
          {i18n.ft(messages.uploadFile)}
        </Button>
      </div>
      <div>{errors && errors.map(error => error)}</div>

      {file && (
        <Modal
          open={true}
          closeOnDimmerClick
          onClose={handleClosePreview}
          size={isPDF(file.type) ? 'large' : 'small'}
        >
          <Header icon="file" content={i18n.ft(messages.preview)} />
          <Modal.Content content={getPreview(file)} />
          <Modal.Actions>
            <Button
              type="button"
              onClick={handleClosePreview}
              content={i18n.ft(messages.cancel)}
              disabled={uploading}
            />
            <Button
              type="button"
              onClick={handleSubmit}
              content={i18n.ft(messages.submitFile)}
              color="green"
              loading={uploading}
              disabled={uploading}
            />
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}

export default Attachments;
