const PATH = 'fe.components.measure.view.header';

const messages = {
  observationDate: {
    key: `${PATH}.observation_date`,
    defaultValue: 'Observation Date:'
  },
  classroom: {
    key: `${PATH}.classroom`,
    defaultValue: 'Classroom:'
  },
  classAssessment: {
    key: `${PATH}.class_assessment`,
    defaultValue: 'CLASS Assessment:'
  },
  jobId: {
    key: `${PATH}.job_id`,
    defaultValue: 'Job ID:'
  },
  teachers: {
    key: `${PATH}.teachers`,
    defaultValue: 'Teacher(s):'
  },
  center_name: {
    key: `${PATH}.center_name`,
    defaultValue: 'Center Name:'
  },
  language: {
    key: `${PATH}.language`,
    defaultValue: 'Language:'
  },
  codePrimary: {
    key: `${PATH}.code_primary`,
    defaultValue: 'Double Code: Primary'
  },
  codeSecondary: {
    key: `${PATH}.code_secondary`,
    defaultValue: 'Double Code: Secondary'
  },
  prek3rd: {
    key: `${PATH}.prek_3rd`,
    defaultValue: 'Pre-K–3rd'
  }
};

export default messages;
