import i18n from 'helpers/i18n';
import { InformationCard } from '../Styled';
import messages from './messages';
import { Form, Formik } from 'components/EnhancedForm';
import { Button } from 'semantic-ui-react';
import { EducatorInformation } from 'pages/Improvements/types';
import { ImprovementCoachingCycle } from 'types/api/improvement/ImprovementCoachingCycle';
import ObservationDetails from './ObservationDetails/ObservationDetails';
import ObservationNotes from './ObservationNotes';
import { Field } from 'formik';
import InitialState from './InitialState/InitialState';
import {
  getImprovementCues,
  getImprovementGeneralCues,
  getStrategyRatingsByCoachingCycle,
  getStrategySetsByFocusId,
  updateCoachingCycle,
  createNote,
  updateStrategyRatings,
  updateScaleRatings,
  updateGeneralCues,
  updateFocusCues
} from 'actions/improvements';
import React, { useEffect, useState } from 'react';
import useAsync from 'hooks/useAsync';
import { ImprovementGeneralCue } from 'types/api/improvement/ImprovementGeneralCue';
import { ImprovementStrategySet } from 'types/api/improvement/ImprovementStrategySet';
import {
  ImprovementStrategyRating,
  ImprovementStrategyRatings
} from 'types/api/improvement/ImprovementStrategyRatings';
import { ImprovementCue } from 'types/api/improvement/ImprovementCues';
import ObservationRatings from 'pages/Improvements/EducatorProfile/Observation/ObservationRatings';
import PageLoader from 'components/PageLoader';
import {
  ImprovementStrategyRatingAttribute,
  ObservationRatingsProps
} from 'pages/Improvements/EducatorProfile/Observation/ObservationRatings/ObservationRatings';
import moment from 'moment';
import { ImprovementStrategy } from 'types/api/improvement/ImprovementStrategy';
import { ImprovementScaleRating } from 'types/api/improvement/ImprovementScaleRating';
import { SubmitButton } from 'components/Trainings/View/CardCheckout/Styled';
import CardHeader from 'pages/Improvements/CardHeader';
import HideOnPrint from 'pages/Improvements/HideOnPrint';
import AutoSave from 'pages/Improvements/AutoSave';

interface ObservationProps {
  coachingCycle: ImprovementCoachingCycle;
  educator: EducatorInformation;
  onSubmit: () => void;
}

function Observation({ coachingCycle, educator, onSubmit }: ObservationProps) {
  const [observationCoachingCycle, setObservationCoachingCycle] =
    useState<ImprovementCoachingCycle>(coachingCycle);

  const [isCollapsed, setIsCollapsed] = useState(
    observationCoachingCycle.is_observation_completed
  );
  const [isComplete, setIsComplete] = useState(
    observationCoachingCycle.is_observation_completed
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const observedImprovementGeneralCues =
    observationCoachingCycle.improvement_observed_general_cues || [];

  const observedImprovementCues =
    observationCoachingCycle.improvement_observed_focus_cues || [];

  function setStrategyScaleRatings(
    strategySetsData: ImprovementStrategySet[],
    strategyRatings?: ImprovementStrategyRating[]
  ): ImprovementStrategyRatingAttribute[] {
    return strategySetsData.map(iss => {
      const strategyRating = strategyRatings?.find(
        sr => sr.improvement_strategy_id === iss.improvement_strategy.id
      );

      return {
        id: strategyRating?.id,
        improvement_strategy_id: iss.improvement_strategy.id,
        rating: strategyRating?.rating
      };
    });
  }

  const { run, data: generalCues } = useAsync<ImprovementGeneralCue[]>();
  const { run: runCues, data: improvementCues } = useAsync<ImprovementCue[]>();
  const {
    run: runStrategySets,
    data: strategySetsData,
    isPending: isStrategyRatingsPending
  } = useAsync<ImprovementStrategySet[]>();

  const {
    run: runStrategyRatings,
    data: strategyRatingsData,
    isPending: isStrategySetsPending
  } = useAsync<ImprovementStrategyRatings>();

  useEffect(() => {
    runCues(
      getImprovementCues({
        focus_id: observationCoachingCycle.improvement_focus?.id
      })
    );
    run(getImprovementGeneralCues());
  }, [runCues, run, observationCoachingCycle.improvement_focus?.id]);

  useEffect(() => {
    if (strategySetsData && strategyRatingsData) {
      return;
    }

    setIsEdit(false);
    setIsComplete(observationCoachingCycle.is_observation_completed);

    if (observationCoachingCycle.improvement_focus) {
      runStrategySets(
        getStrategySetsByFocusId(observationCoachingCycle.improvement_focus!.id)
      );
      runStrategyRatings(
        getStrategyRatingsByCoachingCycle(observationCoachingCycle.id)
      );
    }
  }, [
    runStrategySets,
    runStrategyRatings,
    strategySetsData,
    strategyRatingsData,
    observationCoachingCycle.id,
    observationCoachingCycle.improvement_focus,
    observationCoachingCycle.is_observation_completed
  ]);

  useEffect(() => {
    setIsCollapsed(true);
    setObservationCoachingCycle(observationCoachingCycle);
  }, [observationCoachingCycle]);

  useEffect(() => {
    setObservationCoachingCycle(coachingCycle);
  }, [coachingCycle, setObservationCoachingCycle]);

  if (!observationCoachingCycle.is_observation_started) {
    return (
      <InitialState
        coachingCycle={observationCoachingCycle}
        educator={educator}
        onSubmit={setObservationCoachingCycle}
      />
    );
  }

  if (isCollapsed && observationCoachingCycle.is_observation_completed) {
    return (
      <InformationCard className="p-8 w-full flex justify-between items-center">
        <h2 className="text-xl items-center">
          <i className="check circle icon text-[#7AA03F]" />
          <span>
            {`${i18n.ft(messages.observationCompleted)}: ${moment(
              observationCoachingCycle.observation_completed_at
            ).format('MMMM Do, YYYY')}`}
          </span>
        </h2>
        <div className="align-self-baseline print:hidden">
          <button
            className="text-[#017EA7]"
            onClick={() => setIsCollapsed(false)}
          >
            <strong>{i18n.ft(messages.viewObservationNotes)}</strong>
          </button>
        </div>
      </InformationCard>
    );
  }

  if (isStrategySetsPending || isStrategyRatingsPending) {
    return (
      <div className="mt-8">
        <PageLoader />
      </div>
    );
  }

  function setObservationScaleRating(scale_ratings?: ImprovementScaleRating[]) {
    const observationScaleRating = scale_ratings?.find(
      x => x.scale_type === 'observation_rating'
    );

    if (!!observationScaleRating) {
      return [observationScaleRating];
    }

    return [
      {
        scale_type: 'observation_rating',
        rating: ''
      }
    ];
  }

  const initialValues = {
    improvement_general_cues: generalCues.map(cue => ({
      id: observedImprovementGeneralCues.find(
        c => c.improvement_general_cue_id === cue.id
      )?.id,
      improvement_general_cue_id: cue.id,
      observed: !!observedImprovementGeneralCues.find(
        c => c.improvement_general_cue_id === cue.id
      )?.observed
    })),

    improvement_cues: improvementCues.map(cue => ({
      id: observedImprovementCues.find(c => c.improvement_cue_id === cue.id)
        ?.id,
      improvement_cue_id: cue.id,
      observed: !!observedImprovementCues.find(
        c => c.improvement_cue_id === cue.id
      )?.observed
    })),

    improvement_strategy_ratings_attributes: setStrategyScaleRatings(
      strategySetsData,
      observationCoachingCycle.improvement_strategy_ratings
    ),
    improvement_scale_ratings_attributes: setObservationScaleRating(
      observationCoachingCycle.improvement_scale_ratings
    ),
    ObservationNote: observationCoachingCycle.observation_note?.response || '',
    SummaryNote: observationCoachingCycle.summary_note?.response || '',
    OptionalSummaryNote:
      observationCoachingCycle.optional_summary_note?.response || ''
  };

  const handleSubmitForm = async (values: any) => {
    setIsSubmitting(true);
    try {
      const note1 = createNote(
        observationCoachingCycle.id,
        'ObservationNote',
        values['ObservationNote']
      );
      const note2 = createNote(
        observationCoachingCycle.id,
        'SummaryNote',
        values['SummaryNote']
      );
      const note3 = createNote(
        observationCoachingCycle.id,
        'OptionalSummaryNote',
        values['OptionalSummaryNote']
      );
      const strategies = updateStrategyRatings(observationCoachingCycle.id, {
        ratings: values.improvement_strategy_ratings_attributes
      });
      const scales = updateScaleRatings(observationCoachingCycle.id, {
        ratings: values.improvement_scale_ratings_attributes
      });
      const focusCues = updateFocusCues(observationCoachingCycle.id, {
        cues: values.improvement_cues
      });
      const generalCues = updateGeneralCues(observationCoachingCycle.id, {
        cues: values.improvement_general_cues
      });
      await Promise.all([
        strategies,
        scales,
        focusCues,
        generalCues,
        note1,
        note2,
        note3
      ]);

      const { data: updatedCoachingCycle } = await updateCoachingCycle(
        observationCoachingCycle.id,
        {
          observation_completed_at: moment.utc().toString()
        }
      );

      setIsEdit(false);
      setObservationCoachingCycle({ ...updatedCoachingCycle });
    } finally {
      setIsSubmitting(false);
    }

    onSubmit();
  };

  function handleEdit() {
    setIsEdit(true);
  }

  const strategyData: ImprovementStrategy[] = strategySetsData.map(
    set => set.improvement_strategy
  );

  return (
    <InformationCard className="p-8">
      <CardHeader
        title={i18n.ft(messages.observation)}
        showSubtitleOnPrint={isComplete}
        showCTA={coachingCycle?.is_observation_completed}
        onClickAction={() => setIsCollapsed(true)}
      />
      <div className={HideOnPrint(isComplete, '')}>
        <h3>{i18n.ft(messages.observationSheet)}</h3>
        <p>{i18n.ft(messages.notesDescription)}</p>

        {observationCoachingCycle?.is_observation_completed &&
          observationCoachingCycle?.in_action_note && (
            <p>{observationCoachingCycle.in_action_note.response}</p>
          )}
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        enableReinitialize
      >
        {({ values }) => {
          const observationNotesProps = {
            disabled: isComplete,
            edit: isEdit,
            improvementGeneralCues: generalCues,
            improvementCues: improvementCues,
            improvementStrategies: strategyData,
            additionalCuesNote: observationCoachingCycle.additional_cues_note,
            observedImprovementGeneralCues: observedImprovementGeneralCues,
            observedFocusCues: observedImprovementCues,
            cycleId: observationCoachingCycle.id
          };

          const observationRatingsProps: ObservationRatingsProps = {
            disabled: isComplete,
            edit: isEdit,
            improvement_scale_ratings_attributes:
              values.improvement_scale_ratings_attributes,
            improvement_strategy_ratings_attributes:
              values.improvement_strategy_ratings_attributes,
            strategyRatingsData: strategyRatingsData,
            strategySetsData: strategySetsData
          };

          return (
            <Form>
              <ObservationDetails
                coachingCycle={observationCoachingCycle}
                educator={educator}
              />
              <div>
                <Field
                  component={ObservationNotes}
                  {...observationNotesProps}
                />
              </div>
              <div>
                <Field
                  name={'observationRating'}
                  component={ObservationRatings}
                  {...observationRatingsProps}
                />
              </div>
              <SubmitButton>
                {observationCoachingCycle.is_observation_completed &&
                !isEdit ? (
                  <div className={HideOnPrint(isComplete, '')}>
                    <Button
                      type="button"
                      className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                      color="blue"
                      onClick={handleEdit}
                      disabled={observationCoachingCycle.has_completed}
                      content={i18n.ft(messages.edit)}
                    />
                  </div>
                ) : (
                  <Button
                    type="submit"
                    className="bg-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
                    color="blue"
                    content={i18n.ft(messages.submit)}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                )}
              </SubmitButton>
              <AutoSave coachingCycleId={observationCoachingCycle.id} />
            </Form>
          );
        }}
      </Formik>
    </InformationCard>
  );
}

export default Observation;
