const PATH = 'fe.components.training_catalog.create.training_form';

const messages = {
  trainingLocation: {
    key: `${PATH}.training_location`,
    defaultValue: 'Training Location'
  },
  trainingDeliveryFormat: {
    key: `${PATH}.training_delivery_format`,
    defaultValue: 'Training Delivery Format'
  },
  virtualDeliveryMethod: {
    key: `${PATH}.virtual_delivery_method`,
    defaultValue: 'Virtual'
  },
  inPersonDeliveryMethod: {
    key: `${PATH}.in_person_delivery_method`,
    defaultValue: 'In-Person'
  },
  selectTrainer: {
    key: `${PATH}.select_trainer`,
    defaultValue: 'Select a Trainer and Organization'
  },
  eligibleTrainers: {
    key: `${PATH}.eligible_trainer`,
    defaultValue: 'Eligible Trainers'
  },
  client_organization: {
    key: `${PATH}.client_organization`,
    defaultValue: 'Client Organization (if applicable)'
  },
  address: {
    key: `${PATH}.address`,
    defaultValue: 'Location Address {{number}}'
  },
  address01: {
    key: `${PATH}.address_01`,
    defaultValue: 'Street and number'
  },
  address02: {
    key: `${PATH}.address_02`,
    defaultValue: 'Apartment, suite, unit, building, floor, etc.'
  },
  city: {
    key: `${PATH}.city`,
    defaultValue: 'City'
  },
  country: {
    key: `${PATH}.country`,
    defaultValue: 'Country'
  },
  stateProvince: {
    key: `${PATH}.state_province`,
    defaultValue: 'State/Province'
  },
  postalCode: {
    key: `${PATH}.postal_code`,
    defaultValue: 'Postal Code'
  },
  virtualTrainingLink: {
    key: `${PATH}.virtual_training_link`,
    defaultValue: 'Web link for Virtual Training'
  },
  virtualTrainingLinkHint: {
    key: `${PATH}.virtual_training_link_hint`,
    defaultValue: 'http://www.yourwebsitehere.com'
  },
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  sessionDates: {
    key: `${PATH}.session_dates`,
    defaultValue: 'Session Dates'
  },
  sessionNumber: {
    key: `${PATH}.session_number`,
    defaultValue: 'Session {{number}}'
  },
  startTime: {
    key: `${PATH}.start_time`,
    defaultValue: 'Start Time'
  },
  endTime: {
    key: `${PATH}.end_time`,
    defaultValue: 'End Time'
  },
  additionalInfo: {
    key: `${PATH}.additional_info`,
    defaultValue: 'Additional Information'
  },
  notesTitle: {
    key: `${PATH}.notes_title`,
    defaultValue: 'Title'
  },
  notesDescription: {
    key: `${PATH}.notes_description`,
    defaultValue: 'Description'
  },
  createDuplicate: {
    key: `${PATH}.create_duplicate`,
    defaultValue: 'Create Duplicate Training'
  },
  createNew: {
    key: `${PATH}.create_new`,
    defaultValue: 'New Training'
  },
  errors: {
    date: {
      required: {
        key: `${PATH}.errors.date.required`,
        defaultValue: 'Date is required'
      }
    },
    deliveryMethod: {
      required: {
        key: `${PATH}.errors.delivery_method.required`,
        defaultValue: 'Training delivery method required'
      }
    },
    trainer: {
      required: {
        key: `${PATH}.errors.trainer.required`,
        defaultValue: 'Trainer is required'
      }
    },
    startTime: {
      required: {
        key: `${PATH}.errors.start_time.required`,
        defaultValue: 'Start time is required'
      }
    },
    endTime: {
      required: {
        key: `${PATH}.errors.end_time.required`,
        defaultValue: 'End time is required'
      },
      afterStart: {
        key: `${PATH}.errors.end_time.after_start`,
        defaultValue: 'End time must be after start time'
      }
    },
    virtualTrainingLink: {
      max: {
        key: `${PATH}.errors.virtual_training_link.max`,
        defaultValue: 'Must be shorter than 300 characters'
      },
      validUrl: {
        key: `${PATH}.errors.virtual_training_link.valid_url`,
        defaultValue: 'Must be a valid URL'
      }
    }
  }
};

export default messages;
