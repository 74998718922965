interface PrintMenuButtonProps {
  title: string;
  icon?: string;
}

function PrintMenuButton({ title, icon }: PrintMenuButtonProps) {
  function handleOnClick() {
    window.print();
  }

  return (
    <button
      type="button"
      className="print:hidden border border-solid px-6 py-4 rounded-md mx-2 font-bold align-middle flex gap-2 border-[#3C3F42]"
      onClick={handleOnClick}
    >
      <i className={icon} />
      {title}
    </button>
  );
}

export default PrintMenuButton;
