import { map } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import FormDropdown, { FormDropdownProps } from 'components/FormDropdown';
import { useState, useEffect } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { defaultFormatter } from 'components/dropdowns/utils';
import { getLanguages } from 'actions/languages';

type LanguagesProps = Omit<FormDropdownProps, 'options'>;

function languagesDropdown({ includeMultilingual = false } = {}) {
  const Languages = (props: LanguagesProps) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<DropdownItemProps[]>([]);

    useEffect(() => {
      setLoading(true);
      getLanguages().then(({ data }) => {
        const languages = includeMultilingual
          ? data.languages
          : data.languages.filter((lang: any) => lang.code !== 'mult');
        const options = map(defaultFormatter, languages);
        setOptions(options);
        setLoading(false);
      });
    }, []);

    return <FormDropdown loading={loading} options={options} {...props} />;
  };

  return Languages;
}

export function useLanguages() {
  const options = [
    { key: 'en', value: 'en', text: i18n.ft(messages.english) },
    { key: 'es', value: 'es', text: i18n.ft(messages.spanish) },
    { key: 'fr', value: 'fr', text: i18n.ft(messages.french) }
  ];

  return options;
}

export function useDCLanguages() {
  const options = [
    { key: 'en', value: 'en', text: i18n.ft(messages.english) },
    { key: 'es', value: 'es', text: i18n.ft(messages.spanish) },
    { key: 'fr', value: 'fr', text: i18n.ft(messages.french) },
    { key: 'zh', value: 'zh', text: i18n.ft(messages.mandarin) },
    { key: 'he', value: 'he', text: i18n.ft(messages.hebrew) },
    { key: 'am', value: 'am', text: i18n.ft(messages.amharic) },
    { key: 'ot', value: 'ot', text: i18n.ft(messages.other) }
  ];

  return options;
}

export default languagesDropdown;
