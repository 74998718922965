import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px 16px;
  margin: 0 auto;
  display: flex;
  color: #34495e;

  @media screen and (min-width: 640px) {
    padding: 32px;
  }

  @media screen and (min-width: 768px) {
    padding: 64px 32px;
    align-items: flex-start;
    gap: 16px;
  }

  @media screen and (min-width: 1024px) {
    padding-top: 128px;
    padding-bottom: 128px;
  }
`;

export const MarkerImg = styled.img`
  display: none;
  width: 100%;
  max-width: 192px;

  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  line-height: 36px;
`;

export const Body = styled.div`
  margin: 24px 0;

  p {
    margin: 0;

    strong {
      font-weight: bold;
    }
  }
`;

export const TextBlue = styled.span`
  color: ${p => p.theme.colors.blue};
`;

export const ContactUs = styled.div`
  margin-top: 32px;

  p {
    margin: 0;
  }

  a {
    color: ${p => p.theme.colors.blue};
    text-decoration: none;
  }
`;
