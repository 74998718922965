import i18n from 'helpers/i18n';
import settings from 'helpers/settings';
import messages from './messages';
import { Button } from 'semantic-ui-react';
import MainContent from 'components/MainContent';
import { Link, useLocation } from 'react-router-dom';
import errorMarkerImg from 'images/error_marker.svg';
import { Wrapper, MarkerImg, Title, TextBlue, Body, ContactUs } from './Styled';

const myCLASSURL = String(settings.lookup('REACT_APP_MYCLASS_BASE_URL'));
const myTeachstoneURL = String(settings.lookup('REACT_APP_MYTS_BASE_URL'));
const communityURL = String(settings.lookup('REACT_APP_COMMUNITY_BASE_URL'));

function PageNotFound() {
  const location = useLocation();

  if (
    location.pathname.includes(myCLASSURL) ||
    location.pathname.includes(myTeachstoneURL) ||
    location.pathname.includes(communityURL)
  ) {
    return null;
  }

  return (
    <MainContent maxWidth={1024}>
      <Wrapper>
        <MarkerImg alt="" src={errorMarkerImg} />

        <div>
          <Title>
            <TextBlue>{i18n.ft(messages.snap)}</TextBlue>
            &nbsp;
            {i18n.ft(messages.capsOff)}
            <br />
            {i18n.ft(messages.cannotDrawn)}
          </Title>

          <Body>
            <p>
              <strong>{i18n.ft(messages.ourApologies)}</strong>
            </p>
            <p>
              {i18n.ft(messages.pageRequested, { page: location.pathname })}
            </p>
            <p>{i18n.ft(messages.mistypedAddress)}</p>
          </Body>

          <Button
            as={Link}
            to="/"
            size="large"
            color="blue"
            content={i18n.ft(messages.goToDashboard)}
          />

          <ContactUs>
            <p>
              {i18n.ft(messages.needHelp) + ' '}
              <a href="https://teachstone.com/contact/?q=/about-teachstone/contact-us">
                {i18n.ft(messages.contactUs)}
              </a>
            </p>
            <p>
              {i18n.ft(messages.emailUs) + ' '}
              <a href="mailto:contact@teachstone.com">contact@teachstone.com</a>
            </p>
          </ContactUs>
        </div>
      </Wrapper>
    </MainContent>
  );
}

export default PageNotFound;
