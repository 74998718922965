import i18n from 'helpers/i18n';
import messages from './messages';
import colors from 'styles/colors';
import { Icon } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import { Status as StatusIcon } from 'components/Reports/Reliability/View';
import { SubtitleSection } from 'components/Reports/Reliability/View/Styled';

type Categories = {
  name: string;
  score: number;
};

interface ResultsByCategoryProps {
  overallScore?: number;
  categories?: Categories[];
}

function ResultsByCategory({
  overallScore,
  categories
}: ResultsByCategoryProps) {
  const isTablet = useMediaQuery({ minWidth: 640 });
  if (!categories) {
    return null;
  }

  return (
    <>
      <SubtitleSection
        className="PrintMarginY justify-center md:justify-start"
        tabIndex={0}
      >
        {i18n.ft(messages.categoryResults)}
      </SubtitleSection>

      <div className="mx-2 md:mx-0">
        <div className="grid grid-cols-11 mx-1 text-sm md:text-xl bg-[#D5E0F3] rounded-t-lg">
          <div className="ml-8 col-span-8 py-2 font-bold pl-2 text-base sm:my-2">
            {i18n.ft(messages.category)}
          </div>
          <div className="col-span-2 ml-8 md:col-span-3 py-2 font-bold pl-2 text-base sm:my-2 justify-self-center">
            {i18n.ft(messages.score)}
          </div>
        </div>

        {categories.map((category, idx: number) => {
          return (
            <div
              key={idx}
              className="grid grid-cols-11 mx-1 text-sm md:text-xl border-b-2 border-slate-100"
            >
              <div className="col-span-8 py-2 border-r-2 border-slate-100 pl-2 flex">
                <StatusIcon
                  iconColor={colors.white}
                  iconBackGroundColor={
                    category.score > 50
                      ? colors.reliability.passVideo
                      : colors.reliability.failedAttempt
                  }
                  className="!flex !mt-auto !mb-auto ml-2"
                  size={isTablet ? '24px' : '15px'}
                >
                  <Icon
                    name={category.score > 50 ? 'check' : 'close'}
                    size="tiny"
                    className="align-middle !m-auto"
                    style={{
                      fontSize: !isTablet ? '0.35em' : '0.5em',
                      top: !isTablet ? 'inherit' : 'inherit',
                      position: !isTablet ? 'relative' : 'inherit',
                      margin: 'auto'
                    }}
                  />
                </StatusIcon>
                <span className="text-sm md:text-base mt-auto mb-auto">
                  <span className="ml-4 inline md:ml-8" tabIndex={0}>
                    {category.name}
                    <span className="sr-only">{`${category.score}%`}</span>
                  </span>
                </span>
              </div>
              <div className="col-span-2 ml-8 md:col-span-3 py-2 font-bold pl-2 text-base sm:my-2 justify-self-center">
                {category.score}%
              </div>
            </div>
          );
        })}

        <div className="grid grid-cols-11 mx-1 text-sm md:text-xl bg-[#F4F8FA] rounded-t-lg">
          <div
            className="ml-8 col-span-8 py-2 font-bold pl-2 text-base sm:my-2"
            tabIndex={0}
          >
            {i18n.ft(messages.overall)}
            <span className="sr-only">{`${overallScore}%`}</span>
          </div>
          <div className="col-span-2 ml-8 md:col-span-3 py-2 font-bold pl-2 text-base sm:my-2 justify-self-center">
            {overallScore}%
          </div>
        </div>
      </div>
    </>
  );
}

export default ResultsByCategory;
