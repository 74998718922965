import React from 'react';
import { min } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';

import {
  Bar,
  BarContainer,
  LegendContainer,
  LegendItem,
  Container
} from './Styled';

const colors = {
  total: '#c3c3c3',
  invited: '#da7e00',
  registered: '#0f7a6a'
};

interface StatusBarProps {
  /** The maximum number of seats for the training. */
  total: number;

  /** The number of participants who status is `invited`. */
  invited: number;

  /** The number of participants who status is `registered`. */
  registered: number;
}

const StatusBar: React.FC<StatusBarProps> = ({
  total,
  invited,
  registered
}) => {
  const registerWidth = min((registered / total) * 100, 100);
  const invitedWidth = min(((registered + invited) / total) * 100, 100);
  const available = total - invited - registered;

  return (
    <Container>
      <BarContainer color={colors.total}>
        <Bar color={colors.invited} width={invitedWidth} />
        <Bar color={colors.registered} width={registerWidth} />
      </BarContainer>
      <LegendContainer>
        <LegendItem color={colors.registered}>
          {i18n.ft(messages.registered, { number: registered })}
        </LegendItem>
        <LegendItem color={colors.invited}>
          {i18n.ft(messages.invited, { number: invited })}
        </LegendItem>
        <LegendItem color={colors.total}>
          {i18n.ft(messages.total, { available, total })}
        </LegendItem>
      </LegendContainer>
    </Container>
  );
};

export default StatusBar;
