const PATH = 'fe.components.app.masquerade';

const messages = {
  stop: {
    key: `${PATH}.stop_masquerade`,
    defaultValue: 'Stop Masquerade'
  }
};

export default messages;
