import i18n from 'helpers/i18n';
import messages from './messages';
import { useRef } from 'react';

import {
  Wrapper,
  InputSeparator,
  Label,
  LabelContent,
  Optional,
  Required,
  ErrorMessage
} from './Styled';

interface TimeRangeInputProps {
  /** Input label. */
  label: string;

  /** An input can be flagged as optional. */
  optional?: boolean;

  /** An input can be flagged as required. */
  required?: boolean;

  /** Array of [startTime, endTime]. */
  value: string[];

  /** Callback fired when start time or end time changes. */
  onChange: (value: string[]) => void;

  /** Error message that is shown underneath the input fields */
  errorMessage?: string | null;

  /** Callback fired when start time or end time loose focus and related element is not part of TimeRangeInput */
  onBlur?: (value: string[]) => void;
}

function TimeRangeInput({
  value,
  onChange,
  label,
  optional,
  required,
  errorMessage,
  onBlur
}: TimeRangeInputProps) {
  const [startTime, endTime] = value;
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);

  function handleStartChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange([event.target.value, endTime]);
  }

  function handleEndChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange([startTime, event.target.value]);
  }

  function handleOnBlur(
    event: React.FocusEvent<HTMLInputElement, Element>,
    times: Array<string>
  ) {
    if (!onBlur) return;
    if (
      event.relatedTarget === startTimeRef.current ||
      event.relatedTarget === endTimeRef.current
    ) {
      return;
    }

    onBlur(times);
  }

  return (
    <div>
      <Label>
        <LabelContent>
          {label}
          {optional && <Optional>({i18n.ft(messages.optional)})</Optional>}
          {required && <Required aria-hidden>*</Required>}
        </LabelContent>
        <Wrapper>
          <input
            type="time"
            ref={startTimeRef}
            value={startTime}
            onChange={handleStartChange}
            aria-label={i18n.ft(messages.startTime)}
            onBlur={e => handleOnBlur(e, [e.target.value, endTime])}
          />
          <InputSeparator>to</InputSeparator>
          <input
            type="time"
            ref={endTimeRef}
            value={endTime}
            onChange={handleEndChange}
            aria-label={i18n.ft(messages.endTime)}
            onBlur={e => handleOnBlur(e, [startTime, e.target.value])}
          />
        </Wrapper>
      </Label>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
}

export default TimeRangeInput;
