import i18n from 'helpers/i18n';
import messages from './messages';
import { ClockIcon } from './utils';
import { CycleTime } from 'types/api/envScales/ObservationVideo';
import { Section, TimeInput, AddButton, RemoveButton } from './Styled';

const MAX_NUMBER_OF_CYCLES = 6;

interface RecommendedTimeProps {
  editMode: boolean;
  cycleTimes: CycleTime[];
  onCycleTimesChange: (newTimes: CycleTime[]) => void;
  title: string;
}

function RecommendedTime({
  editMode,
  cycleTimes,
  onCycleTimesChange,
  title
}: RecommendedTimeProps) {
  const defaultTime = { start: '00:00:00', end: '00:00:00' };
  const times = cycleTimes.length === 0 ? [defaultTime] : cycleTimes;

  const handleAddTime = () => onCycleTimesChange([...times, defaultTime]);

  function handleRemoveTime(index: number) {
    onCycleTimesChange(times.filter((_, currIdx) => currIdx !== index));
  }

  function updateStartTime(index: number, value: string) {
    let newTimes = [...times];
    newTimes[index] = { ...newTimes[index], start: value };
    onCycleTimesChange(newTimes);
  }

  function updateEndTime(index: number, value: string) {
    let newTimes = [...times];
    newTimes[index] = { ...newTimes[index], end: value };
    onCycleTimesChange(newTimes);
  }

  return (
    <Section className="text-lg mt-8" editMode={editMode}>
      <div className="font-bold flex gap-5 items-center">
        {editMode && <ClockIcon />}
        <span>{title}</span>
      </div>
      <div className={`mt-4 ${editMode && 'px-9'}`}>
        {times.map((time, index) => (
          <div
            key={Math.random()}
            className="grid grid-cols-8 gap-4 mt-1 hover:bg-slate-50"
          >
            <div className="font-bold self-center">
              {i18n.ft(messages.cycle)} {index + 1}
            </div>

            <div className="col-span-3 justify-self-end flex items-center">
              <span className="mr-3">{i18n.ft(messages.startsAt)}</span>
              <TimeInput
                required
                step="1"
                className={`rounded ${editMode && 'border'}`}
                defaultValue={time.start}
                readOnly={!editMode}
                onBlur={element => updateStartTime(index, element.target.value)}
              />
            </div>
            <div className="col-span-3 flex items-center">
              <span className="mr-3">{i18n.ft(messages.endsAt)}</span>
              <TimeInput
                required
                step="1"
                className={`rounded ${editMode && 'border'}`}
                defaultValue={time.end}
                readOnly={!editMode}
                onBlur={element => updateEndTime(index, element.target.value)}
              />
            </div>

            {editMode && (
              <div className="self-center justify-self-end">
                {index + 1 === times.length &&
                times.length < MAX_NUMBER_OF_CYCLES ? (
                  <AddButton
                    circular
                    icon="plus"
                    size="mini"
                    onClick={handleAddTime}
                  />
                ) : (
                  <RemoveButton
                    circular
                    icon="trash alternate"
                    size="mini"
                    onClick={() => handleRemoveTime(index)}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </Section>
  );
}

export default RecommendedTime;
