import styled from 'styled-components';

type SpacerSIZES =
  | 4
  | 8
  | 12
  | 16
  | 24
  | 32
  | 48
  | 64
  | 96
  | 128
  | 192
  | 256
  | 384
  | 512
  | 640
  | 768;

interface SpacerProps {
  /** The size for the space. Must be one of predefined sizes. */
  size: SpacerSIZES;

  /** The direction for the desired space. Defaults to `vertical`. */
  axis?: 'horizontal' | 'vertical';
}

function getHeight({ axis, size }: SpacerProps) {
  return axis === 'horizontal' ? 1 : size;
}

function getWidth({ axis, size }: SpacerProps) {
  return axis === 'vertical' ? 1 : size;
}

/**
 * @deprecated use tailwind classes instead of this
 */
const Spacer = styled.span<SpacerProps>`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
`;

Spacer.defaultProps = {
  axis: 'vertical'
};

export default Spacer;
