import styled from 'styled-components';

export const Wrapper = styled.div`
  color: #364a5e;
  background-color: ${p => p.theme.colors.white};
  box-shadow: 1px 1px 11px #e9ecf2;
  border-radius: 20px;
  margin-bottom: 16px;
  padding: 32px 30px;

  @media screen and (min-width: 1024px) {
    max-width: 963px;
  }
`;

export const ProIcon = styled.img`
  position: relative;
  top: 4px;
`;

export const ProDevelopment = styled.h2`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  font-size: 24px;
  line-height: 32px;
`;

export const ProContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 1300px) {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr minmax(min-content, 200px);
  }
`;

export const BtWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 200px;
  }
`;

export const Learning = styled.div`
  margin-top: 30px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
`;

export const LearningContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  grid-gap: 5px;
`;

export const LearningTitle = styled.a`
  background-color: #f4f8fa;
  width: fit-content;
  padding: 4px 10px;
  border-radius: 16px;
  font-weight: bold;
  color: inherit;
  position: relative;
  left: -12px;
  height: fit-content;

  &:hover {
    color: ${p => p.theme.colors.blue};
  }
`;

export const LearningStatus = styled.div`
  padding: 3px;
  font-weight: bold;
`;

export const RowName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Divider = styled.div`
  display: flex;
  border-top: 1px solid #ededed;
  margin-top: 20px;
`;

export const LearningResources = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr minmax(min-content, 200px);
  }
`;

export const ShowList = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 4px;
  color: #364a5e;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.colors.blue};
  }
`;

export const Resources = styled.div`
  margin-top: 30px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: minmax(155px, 60%) minmax(70px, 30%) max-content;
`;

export const Thumbnail = styled.img`
  width: 58px;
  height: 46px;
  object-fit: cover;
  margin-right: 8px;
  border-radius: 4px;
`;

export const ResourcesList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
`;

export const ResourcesCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const RowRecommendedBy = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Date = styled.div`
  color: #80716f;
  display: flex;
  align-items: center;
`;

export const BoldText = styled.div`
  font-weight: bold;
  padding-top: 0px;

  @media screen and (min-width: 640px) {
    padding-top: 4px;
  }
`;

export const LinkName = styled.a`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SmallTitle = styled.div`
  font-size: 14px;
  padding-top: 8px;
  margin-bottom: 4px;
`;
