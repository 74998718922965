const PATH = 'fe.components.test_attempts.honor_code_agreement';

const messages = {
  subtitle: {
    key: `${PATH}.subtitle`,
    defaultValue: 'Review your performance on your most recent attempt.'
  },
  review: {
    key: `${PATH}.review`,
    defaultValue: 'Read this agreement before starting the test'
  },
  logoDescription: {
    key: `${PATH}.logo_description`,
    defaultValue: 'Certification Logo'
  },
  exitTest: {
    key: `${PATH}.exit_test`,
    defaultValue: 'Exit to Dashboard'
  },
  honorCodeAgreement: {
    key: `${PATH}.honor_code_agreement`,
    defaultValue: 'Honor Code Agreement'
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back'
  },
  startTest: {
    key: `${PATH}.start_test`,
    defaultValue: 'Start Test'
  },
  description: {
    key: `${PATH}.description`,
    defaultValue:
      'CLASS Certification ensures that certified observers and trainers have undergone in-depth CLASS training and demonstrated that they are reliable with the CLASS tool by passing the CLASS reliability test. You must recertify annually to maintain your status as a Certified CLASS Observer or Trainer.'
  },
  agree: {
    key: `${PATH}.agree`,
    defaultValue: 'I agree:'
  },
  takeTest: {
    key: `${PATH}.take_test`,
    defaultValue:
      'To take this test on my own without assistance or input from anyone else.'
  },
  priorTaking: {
    key: `${PATH}.prior_taking`,
    defaultValue:
      'Prior to taking the test I did not receive information regarding the test content.'
  },
  afterTaking: {
    key: `${PATH}.after_taking`,
    defaultValue:
      'After taking the test I will not share any portion of the test with any other party.'
  },
  checkbox: {
    key: `${PATH}.checkbox`,
    defaultValue:
      'I acknowledge and agree that any violation of the foregoing or other rules related to the CLASS Certification program may lead to suspension or termination of my certification under the CLASS Certification program.'
  },
  warning: {
    key: `${PATH}.warning`,
    defaultValue: 'You must agree before accessing the test.'
  },
  dismiss: {
    key: `${PATH}.dismiss`,
    defaultValue: 'Dismiss'
  },
  next: {
    key: `${PATH}.next`,
    defaultValue: 'Next'
  }
};

export default messages;
