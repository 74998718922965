const PATH = 'fe.pages.improvements.educator_profile.reflect';

const messages = {
  reflectSectionTitle: {
    key: `${PATH}.section_title`,
    defaultValue: 'Reflect'
  },
  upNext: {
    key: `${PATH}.up_next`,
    defaultValue: 'Up Next...'
  },
  startReflection: {
    key: `${PATH}.start_reflection`,
    defaultValue: 'Start Reflection'
  },
  reflectMainTitle: {
    key: `${PATH}.main_title`,
    defaultValue: 'Reflect on the Coaching Cycle'
  },
  reflectionTitleCollapse: {
    key: `${PATH}.reflection_title_collapse`,
    defaultValue: 'Reflection Completed'
  },
  viewReflectDetails: {
    key: `${PATH}.view_reflect_details`,
    defaultValue: 'Review Reflect Details'
  },
  coachingCycleReviewTitle: {
    key: `${PATH}.coaching_cycle_review_title`,
    defaultValue: 'Coaching Cycle Review'
  },
  coachingCycleReviewSubTitle: {
    key: `${PATH}.coaching_cycle_review_sub_title`,
    defaultValue:
      'Let’s review all the great work you’ve done this coaching cycle.'
  },
  evaluationSectionTitle: {
    key: `${PATH}.evaluation_section_title`,
    defaultValue: 'Coaching Cycle Reflection'
  },
  evaluationSectionHelpText: {
    key: `${PATH}.evaluation_section_help_text`,
    defaultValue:
      'Together, reflect on this coaching cycle, considering all the times the educator tried the strategies (in addition to the observation). Discuss how interactions supported the focus and how children responded to the strategies.\n' +
      '\n' +
      'Use the Discussion Prompts to guide your discussion and your responses to the questions below.\n'
  },
  evaluationSectionPromptTitle: {
    key: `${PATH}.evaluation_section_prompt_title`,
    defaultValue: 'Space for Evaluation Prompts'
  },
  evaluationSectionPrompt1: {
    key: `${PATH}.evaluation_section_prompt_1`,
    defaultValue:
      'In your experiences trying out the Action Plan, what was successful? How do you know?'
  },
  evaluationSectionPrompt2: {
    key: `${PATH}.evaluation_section_prompt_2`,
    defaultValue:
      'Which specific strategies were you able to implement? How did children respond? What did they say and do?'
  },
  evaluationSectionPrompt3: {
    key: `${PATH}.evaluation_section_prompt_3`,
    defaultValue:
      'How did your interactions support the children’s development and learning?'
  },
  evaluationSectionPrompt4: {
    key: `${PATH}.evaluation_section_prompt_4`,
    defaultValue:
      'What adjustments did you have to make to the plan or strategies? What made you decide to make those adjustments?'
  },
  evaluationSectionPrompt5: {
    key: `${PATH}.evaluation_section_prompt_5`,
    defaultValue:
      'Which strategies would you like to continue using? How can you continue using them?'
  },
  effectivenessTitle: {
    key: `${PATH}.effectiveness_title`,
    defaultValue: 'Focus:'
  },
  educatorResponsePlaceholder: {
    key: `${PATH}.educator_response_placeholder`,
    defaultValue: 'Enter educator response'
  },
  effectivenessRatingQuestion: {
    key: `${PATH}.effectiveness_rating_question`,
    defaultValue: 'Overall, how supportive were the strategies in {{focus}}?'
  },
  effectivenessRating1: {
    key: `${PATH}.effectiveness_rating_1`,
    defaultValue: 'Not very supportive'
  },
  effectivenessRating2: {
    key: `${PATH}.effectiveness_rating_2`,
    defaultValue: 'Somewhat supportive'
  },
  effectivenessRating3: {
    key: `${PATH}.effectiveness_rating_3`,
    defaultValue: 'Very supportive'
  },
  progressRatingQuestion: {
    key: `${PATH}.progress_rating_question`,
    defaultValue:
      'How much progress have you made in your own practice toward {{focus}}?'
  },
  progressRating1: {
    key: `${PATH}.progress_rating_1`,
    defaultValue: 'A little progress'
  },
  progressRating2: {
    key: `${PATH}.progress_rating_2`,
    defaultValue: 'Some progress'
  },
  progressRating3: {
    key: `${PATH}.progress_rating_3`,
    defaultValue: 'A lot of progress'
  },
  confidenceRatingQuestion: {
    key: `${PATH}.confidence_rating_question`,
    defaultValue: 'How confident do you now feel about {{focus}}?'
  },
  confidenceRating1: {
    key: `${PATH}.confidence_rating_1`,
    defaultValue: 'Not very confident'
  },
  confidenceRating2: {
    key: `${PATH}.confidence_rating_2`,
    defaultValue: 'Somewhat confident'
  },
  confidenceRating3: {
    key: `${PATH}.confidence_rating_3`,
    defaultValue: 'Very confident'
  },
  impactRatingQuestion: {
    key: `${PATH}.impact_rating_question`,
    defaultValue:
      'Given your reflection on this cycle, what might we do for the next coaching cycle?'
  },
  impactRating1: {
    key: `${PATH}.impact_rating_1`,
    defaultValue: 'Continue using the same strategies and action plan'
  },
  impactRating2: {
    key: `${PATH}.impact_rating_2`,
    defaultValue:
      'Make adjustments to the action plan to support the same focus'
  },
  impactRating3: {
    key: `${PATH}.impact_rating_3`,
    defaultValue: 'Choose a different focus and create a new action plan'
  },
  nextStepsTitle: {
    key: `${PATH}.next_steps_title`,
    defaultValue: 'Next Steps'
  },
  nextStepsBody: {
    key: `${PATH}.next_steps_body`,
    defaultValue:
      'Congratulations on finishing this coaching cycle! After submitting this reflection, we’ll be able to start the next coaching cycle.'
  },
  completeCoachingCycle: {
    key: `${PATH}.complete_coaching_cycle`,
    defaultValue: 'Submit'
  },
  staticPromptNextTitle: {
    key: `${PATH}.static_prompt_next_title`,
    defaultValue: 'Next Coaching Cycle'
  },
  focusYouPickedTitle: {
    key: `${PATH}.focus_you_picked_title`,
    defaultValue: 'Coaching Cycle Focus'
  },
  strategiesYouLearnedAboutTitle: {
    key: `${PATH}.strategies_you_learned_about_title`,
    defaultValue: 'Key Strategies'
  },
  cuesYouExemplifiedTitle: {
    key: `${PATH}.cues_you_exemplified_title`,
    defaultValue: 'Cues Observed'
  },
  educatorCueTitle: {
    key: `${PATH}.educator_cue_title`,
    defaultValue: 'Educator Cues'
  },
  childrenCueTitle: {
    key: `${PATH}.children_cue_title`,
    defaultValue: 'Children Cues'
  },
  actionPlanCueTitle: {
    key: `${PATH}.action_plan_cue_title`,
    defaultValue: 'Action Plan Cues'
  },
  generalCueTitle: {
    key: `${PATH}.general_cue_title`,
    defaultValue: 'General Observation Cues'
  },
  effectiveExampleObservedTitle: {
    key: `${PATH}.effective_example_observed_title`,
    defaultValue: 'Observation Highlights'
  },
  noResponseProvided: {
    key: `${PATH}.no_response_provided`,
    defaultValue: 'No Response Provided'
  },
  staticPromptNextBody: {
    key: `${PATH}.static_prompt_next_body`,
    defaultValue:
      'Consider the children in your learning setting and your priorities when deciding on any adjustments or a new focus. Consider how your current plan went, and whether you want to try it more often or with more children.'
  },
  reflectDescription: {
    key: `${PATH}.reflect_description`,
    defaultValue:
      'Reflect on the action plan, strategies, observation, and the overall coaching cycle. Discuss successes, challenges, and potential adjustments for future coaching cycles.'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Submit'
  },
  errors: {
    fieldRequired: {
      key: `${PATH}.errors.field_required`,
      defaultValue: 'Field Required'
    }
  }
};
export default messages;
