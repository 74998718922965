const PATH = 'fe.components.trainings.view.participants.status_bar';

const messages = {
  registered: {
    key: `${PATH}.registered`,
    defaultValue: 'Registered ({{number}})'
  },
  invited: {
    key: `${PATH}.invited`,
    defaultValue: 'Invited ({{number}})'
  },
  total: {
    key: `${PATH}.total`,
    defaultValue: 'Seats available ({{available}} of {{total}})'
  }
};

export default messages;
