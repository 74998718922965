import styled from 'styled-components';

const size = {
  mobileS: '320px',
  mobileL: '640px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px'
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.tablet})`,
  desktopL: `(min-width: ${size.laptopL})`
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;

  @media ${device.mobileL} {
    font-size: 30px;
  }
`;

export const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: ${p => p.theme.colors['neutral']['500']};
  margin-bottom: 20px;
  margin-top: 16px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  margin-top: 48px;
`;

export const RadioWrapper = styled.div`
  width: 80%;
`;
