const PATH = 'fe.components.reliability_test.doughnut_chart_panel';

const messages = {
  score: {
    key: `${PATH}.score`,
    defaultValue: 'Test Score'
  }
};

export default messages;
