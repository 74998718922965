const PATH =
  'fe.pages.testing.reliability.score_report.result_section.failed_attempts_messages';

const messages = {
  attempt1: {
    key: `${PATH}.attempt1`,
    defaultValue:
      'Please review the resources available on the Reliability Support page, including the downloadable age level tip sheet PDF before taking Attempt 2.'
  },
  attempt2: {
    key: `${PATH}.attempt2`,
    defaultValue:
      'We strongly advise you to contact Reliability Support to schedule a call before beginning your 3rd attempt.'
  },
  attempt3: {
    key: `${PATH}.attempt3`,
    defaultValue:
      'Before you can test again, you will need to attend an Observation Training.'
  },
  failedAttemptIcon: {
    key: `${PATH}.failed_attempt_icon`,
    defaultValue: 'Failed attempt icon'
  },
  button1: {
    key: `${PATH}.button1`,
    defaultValue: 'Reliability Support Resources'
  },
  button2: {
    key: `${PATH}.button2`,
    defaultValue: 'Contact Reliability Support'
  },
  button3: {
    key: `${PATH}.button3`,
    defaultValue: 'Select an Observation Training'
  },
  knowledgeTestNext: {
    key: `${PATH}.knowledge_test_next`,
    defaultValue:
      'To begin your next test attempt, visit the Certifications page.'
  },
  knowledgeTestThirdMessage: {
    key: `${PATH}.knowledge_test_third_message`,
    defaultValue: 'Before you can test again you’ll need to attend a training.'
  },
  certifications: {
    key: `${PATH}.certifications`,
    defaultValue: 'Certifications'
  },
  knowledgeTestSecondMessage: {
    key: `${PATH}.knowledge_test_second_message`,
    defaultValue:
      'Please email to schedule a call with Reliability Support before beginning your next knowledge test attempt'
  },
  training: {
    key: `${PATH}.training`,
    defaultValue: 'Select a Training'
  },
  knowledgeTestTrainings: {
    key: `${PATH}.knowledge_test_trainings`,
    defaultValue: 'Trainings'
  },
  schedule: {
    key: `${PATH}.schedule`,
    defaultValue: 'Schedule via Email'
  },
  emailSubject: {
    key: `${PATH}.email_subject`,
    defaultValue: 'Schedule knowledge test reliability support'
  },
  emailBody: {
    key: `${PATH}.email_body`,
    defaultValue:
      'Could you please help me schedule reliability support for the trainer knowledge test?'
  }
};

export default messages;
