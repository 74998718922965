import { Wrapper } from './Styled';
import useCurrentUser from 'hooks/useCurrentUser';
import { Assessment } from 'types/api/envScales/Assessment';
import AssessmentCard from 'components/Measure/Home/AssessmentCard';

interface AssessmentListProps {
  /** List of assessments. */
  assessments: Assessment[];

  /** Role for admin. */
  isAdmin: boolean;

  /** Role for observer. */
  isObserver: boolean;

  /** Role for supervisor. */
  isSupervisor: boolean;

  /** If true, the user is selecting assessments. */
  isSelecting: boolean;

  /** Function after selection. */
  onAfterSelect: (id: number) => void;

  /** List of selected assessments. */
  selectList: number[];

  /** Displays the Job Id instead of the teacher(s) name. */
  displayJobId?: boolean;
}

function AssessmentList({
  assessments,
  isAdmin,
  isObserver,
  isSupervisor,
  isSelecting,
  onAfterSelect,
  selectList,
  displayJobId
}: AssessmentListProps) {
  const currentUser = useCurrentUser();

  return (
    <Wrapper>
      {assessments.map(assessment => {
        const isOwnAssessment =
          assessment.created_by_guid === currentUser.guid ||
          assessment.observer_guid === currentUser.guid;
        const isSelected = selectList.find(cardId => cardId === assessment.id);

        return (
          <AssessmentCard
            key={assessment.id}
            assessment={assessment}
            isOwnAssessment={isOwnAssessment}
            isAdmin={isAdmin}
            isObserver={isObserver}
            isSupervisor={isSupervisor}
            isSelecting={isSelecting}
            onAfterSelect={onAfterSelect}
            selected={isSelected !== undefined}
            displayJobId={displayJobId}
          />
        );
      })}
    </Wrapper>
  );
}

export default AssessmentList;
