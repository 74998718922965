import { useField } from 'formik';
import { Icon } from 'semantic-ui-react';
import ErrorLabel from 'components/EnhancedForm/ErrorLabel';
import { Select, SelectProps as BaseProps } from 'semantic-ui-react';

import {
  shouldDisplayError,
  BaseFieldProps
} from 'components/EnhancedForm/utils';

import {
  TagContainer,
  Container,
  Tag,
  IconButton,
  TagNameContainer,
  TagCenterName,
  TagSubCenterName,
  ContainerTwo,
  FormInputWithLabel,
  Title,
  SelectDisable
} from './Styled';

interface SelectWithTagProps extends BaseFieldProps<BaseProps> {
  /** Name of the select. */
  name: string;

  /** Label to show of the select. */
  label: string;

  /** Placeholder message to show on the select. */
  placeholder: string;

  /** Options to show on the select. */
  options: any[];

  /** Name to show on the tag under the select. */
  tagName?: string;

  /** Name to show on the tag under the tagName. (Optional) */
  subTagName?: string;

  /** Make field as required. True will show asterisk on label */
  required?: boolean;

  /** If true will show the selected Tag inside input. Only style purposes */
  tagInside?: boolean;

  /** Function when a new option is selected. */
  onChange: (e: any) => void;

  /** Function to clean the selected option. */
  cleanOption: () => void;

  /** Function to custom search. */
  customSearch?: (options: any[], query: string) => any[];
}

function SelectWithTag({
  name,
  label,
  placeholder,
  options,
  tagName,
  subTagName,
  onChange,
  validate,
  cleanOption,
  required,
  tagInside,
  customSearch,
  ...props
}: SelectWithTagProps) {
  const [field, meta, helpers] = useField({
    name,
    validate
  });

  const isError = shouldDisplayError(meta);

  const handleBlur: BaseProps['onBlur'] = () => {
    helpers.setTouched(true);
  };

  const handleChange: BaseProps['onChange'] = (_e, data) => {
    onChange(data.value);
    helpers.setValue(data.value);
  };

  return (
    <Container>
      <FormInputWithLabel error={isError}>
        <ContainerTwo>
          <Title className="label" required={required}>
            {label}
          </Title>
          {tagName ? (
            <SelectDisable />
          ) : (
            <Select
              name={name}
              label={label}
              placeholder={placeholder}
              options={options}
              onBlur={handleBlur}
              error={isError}
              search={customSearch ? customSearch : true}
              onChange={handleChange}
              multiple={field.multiple}
              value={tagName || ''}
              {...props}
            />
          )}

          <ErrorLabel active={isError} content={meta.error} textAlign="left" />
          {tagName && (
            <TagContainer tagInside={tagInside}>
              <Tag aria-label={tagName}>
                {!props.disabled && (
                  <IconButton type="button" onClick={cleanOption}>
                    <Icon name="close" />
                  </IconButton>
                )}

                <TagNameContainer>
                  <TagCenterName aria-label={tagName}>{tagName}</TagCenterName>
                  <TagSubCenterName aria-label={subTagName}>
                    {subTagName}
                  </TagSubCenterName>
                </TagNameContainer>
              </Tag>
            </TagContainer>
          )}
        </ContainerTwo>
      </FormInputWithLabel>
    </Container>
  );
}

export default SelectWithTag;
