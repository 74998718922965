import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
`;

export const Section = styled.div<{
  align: 'left' | 'center' | 'right';
  fluid?: boolean;
  onlyDesktop?: boolean;
}>`
  width: ${props => (props.fluid ? null : 'calc(100% / 3)')};
  height: ${props => (props.fluid ? '100%' : null)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  align-items: ${({ align }) =>
    align === 'left'
      ? 'flex-start'
      : align === 'right'
      ? 'flex-end'
      : 'center'};

  @media screen and (max-width: 850px) {
    width: 100%;
  }

  ${props =>
    props.onlyDesktop &&
    css`
      @media screen and (max-width: 850px) {
        display: none;
      }
    `}
`;

export const Content = styled.div<{ top?: number; bottom?: number }>`
  margin-top: ${props => (props.top ? `${props.top}px` : null)};
  margin-bottom: ${props => (props.bottom ? `${props.bottom}px` : null)};
`;

export const MobileOnly = styled.div`
  width: 100%;

  @media screen and (min-width: 851px) {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const AgeLevelContainer = styled.div`
  margin-left: 8px;
  flex-shrink: 0;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
