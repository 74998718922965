const PATH = 'fe.components.measure.observation_scores.scores_by_cycle_table';

const messages = {
  reviewAndEdit: {
    key: `${PATH}.review_and_edit`,
    defaultValue: 'Review and edit scores'
  },
  seeMore: {
    key: `${PATH}.see_more`,
    defaultValue: 'See more details'
  },
  seeLess: {
    key: `${PATH}.see_less`,
    defaultValue: 'See less details'
  },
  selectCycle: {
    key: `${PATH}.select_cycle`,
    defaultValue: 'Select the cycle you want to edit'
  },
  editCycle: {
    key: `${PATH}.edit_cycle`,
    defaultValue: 'Edit cycle {{number}}'
  }
};

export default messages;
