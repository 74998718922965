import styled, { css } from 'styled-components';

export const Container = styled.div<{ notRegistered?: boolean }>`
  background-color: #fafafb;
  border: 1px solid #e2e2e3;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &:not(:first-of-type) {
    margin-top: 8px;
  }

  border-color: ${props => props.notRegistered && props.theme.colors.red};
  border-width: ${props => props.notRegistered && '2px'};
`;

export const SelectionContainer = styled.div`
  flex-shrink: 0;
  padding-left: 16px;
  margin-left: auto;
`;

export const Selection = styled.div<{ disabled?: boolean; color: string }>`
  padding: 4px;
  display: inline-block;

  &:focus {
    outline: none;
    border-radius: 4px;
    background-color: #e2e2e2;
  }

  &:not(:first-of-type) {
    margin-left: 20px;
  }

  color: ${props => props.theme.colors[props.color]};

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
        border-radius: 4px;
        background-color: #e2e2e2;
      }
    `}
`;

export const IconLabel = styled.div`
  display: inline;
  margin-right: 8px;
  font-weight: bold;
`;
