import { clsx } from 'clsx';
import i18n from 'helpers/i18n';
import { useState } from 'react';
import messages from './messages';
import RoutesHelper from 'helpers/routes';
import useCurrentUser from 'hooks/useCurrentUser';
import { NavLink, Outlet } from 'react-router-dom';
import { useGetAccountsByArea } from 'actions/accountManager/accounts';
import AccountManager from 'components/Organization/AccountManager';
import { getCurrentAccount } from 'components/Organization/AccountManager/utils';

const AREA = 'CertificationsDashboard';

function CertificationsRoot() {
  const currentUser = useCurrentUser();

  const { data: accounts = [], isPending: isLoadingAccounts } =
    useGetAccountsByArea(AREA);

  const isAdmin =
    currentUser.is_account_administrator || currentUser.is_ca_admin;

  const currentAccountId = getCurrentAccount(
    currentUser.current_account_id,
    accounts
  );

  const [, setAccountId] = useState<number>(currentAccountId);

  function isNoDataToDisplay() {
    return !currentAccountId;
  }

  return (
    <div>
      {isAdmin && (
        <AccountManager
          area={AREA}
          accounts={accounts}
          currentAccountId={currentAccountId}
          onChangeAccount={accountId => setAccountId(accountId)}
          isLoadingAccounts={isLoadingAccounts}
        />
      )}
      <main className="max-w-screen-xl mx-auto my-10 px-4">
        <h1 className="text-4xl">{i18n.ft(messages.header)}</h1>

        <div className="mt-10 border-b-2 border-[#85878A]">
          <NavLink
            to={RoutesHelper.getPath('certifications-summary')}
            className={({ isActive }) =>
              clsx('text-black hover:text-black', {
                'font-bold border-b-4 border-black': isActive
              })
            }
          >
            {i18n.ft(messages.organizationCertifications)}
          </NavLink>
          <NavLink
            to={RoutesHelper.getPath('certifications-my-certifications')}
            className={({ isActive }) =>
              clsx('ml-16 text-black hover:text-black', {
                'font-bold border-b-4 border-black': isActive
              })
            }
          >
            {i18n.ft(messages.myCertifications)}
          </NavLink>
        </div>

        {isNoDataToDisplay() ? (
          <div>{i18n.ft(messages.mustSelectAccount)}</div>
        ) : (
          <Outlet />
        )}
      </main>
    </div>
  );
}

export default CertificationsRoot;
