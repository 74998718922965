import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const Container = styled.div`
  margin: 0 16px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid ${p => p.theme.colors['warning']['200']};
  background-color: ${p => p.theme.colors['warning']['50']};
  color: ${p => p.theme.colors['warning']['500']};
  display: flex;
  align-items: center;

  @media screen and (min-width: 1024px) {
    margin: 0 32px;
  }
`;

const Header = styled.div`
  font-weight: bold;
  line-height: 1.5;
  margin-left: 12px;
  color: ${p => p.theme.colors['warning']['900']};
`;

interface AlertProps {
  header: string;
}

const Alert: React.FC<AlertProps> = ({ header }) => {
  return (
    <Container>
      <Icon name="warning sign" size="large" />
      <Header>{header}</Header>
    </Container>
  );
};

export default Alert;
