import { CyclesErrors } from 'types/api/envScales/CyclesErrors';
import * as R from 'ramda';

export function indicatorHasError(
  cyclesErrors: CyclesErrors,
  cycleNumber: number,
  dimension: string,
  indicator: string
) {
  if (R.isEmpty(cyclesErrors)) {
    return false;
  }

  const hasDimension =
    cyclesErrors[cycleNumber]?.dimension_scores &&
    dimension in (cyclesErrors[cycleNumber]!.dimension_scores || {});

  if (!hasDimension) {
    return false;
  }

  const hasIndicator =
    indicator in
    (cyclesErrors[cycleNumber]!.dimension_scores![dimension].indicators || {});

  return hasIndicator;
}

export function removeIndicatorFromValidations(
  cyclesErrors: CyclesErrors,
  cycleNumber: number,
  dimension: string,
  indicator: string
) {
  let newCyclesErrors = R.clone(cyclesErrors);

  const hasDimension =
    newCyclesErrors[cycleNumber]?.dimension_scores &&
    dimension in (newCyclesErrors[cycleNumber]!.dimension_scores || {});

  const hasIndicator =
    hasDimension &&
    indicator in
      (newCyclesErrors[cycleNumber]!.dimension_scores![dimension].indicators ||
        {});

  if (hasIndicator) {
    delete newCyclesErrors[cycleNumber]!.dimension_scores![dimension]
      .indicators[indicator];
  }

  return newCyclesErrors;
}

export function addIndicatorInValidations(
  cyclesErrors: CyclesErrors,
  cycleNumber: number,
  dimension: string,
  indicator: string
) {
  let newCyclesErrors = R.clone(cyclesErrors);
  const errorText = 'required';

  const dontHaveAnyDimensionScores =
    newCyclesErrors[cycleNumber] &&
    !(dimension in (newCyclesErrors[cycleNumber].dimension_scores || {}));

  if (dontHaveAnyDimensionScores) {
    newCyclesErrors[cycleNumber].dimension_scores = {};
    newCyclesErrors[cycleNumber].dimension_scores![dimension] = {
      error: errorText,
      indicators: {}
    };
    newCyclesErrors[cycleNumber].dimension_scores![dimension].indicators[
      indicator
    ] = errorText;
  } else {
    const dontHaveAnyIndicators = !(
      'indicators' in
      (newCyclesErrors[cycleNumber]!.dimension_scores![dimension] || {})
    );

    if (dontHaveAnyIndicators) {
      newCyclesErrors[cycleNumber]!.dimension_scores![dimension].indicators = {
        indicator: errorText
      };
    } else {
      newCyclesErrors[cycleNumber]!.dimension_scores![dimension].indicators[
        indicator
      ] = errorText;
    }
  }

  return newCyclesErrors;
}
