import i18n from 'helpers/i18n';
import messages from './messages';

export default function ConfirmationModalBody() {
  return (
    <div>
      <div>{i18n.ft(messages.body.reviewMessage)}</div>
      <div>{i18n.ft(messages.body.wontChangeMessage)}</div>
    </div>
  );
}
