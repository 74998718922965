import styled from 'styled-components';

export const Actions = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const Section = styled.div`
  padding: 16px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid ${p => p.theme.colors['neutral']['200']};

  /* Inputs add a 16px bottom margin which causes weird miss alignment */
  padding-bottom: 0;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  text-transform: uppercase;
  color: ${p => p.theme.colors['neutral']['500']};
  margin-bottom: 16px;
`;

export const FieldRow = styled.div`
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px 24px;
  }
`;

export const ButtonWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }

  @media screen and (min-width: 768px) {
    &:not(:first-child) {
      margin-top: 0;
      margin-left: 16px;
    }
  }
`;
