import styled from 'styled-components';
import { Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';

const Wrapper = styled.button`
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

type IconSIZES = 'small' | 'large' | 'big';

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Name of the icon. */
  icon: SemanticICONS;

  /** Color of the icon. */
  color?: SemanticCOLORS;

  /** Size of the icon. */
  size?: IconSIZES;
}

/**
 * A wrapper around the Semantic-UI [Icon](https://semantic-ui.com/elements/icon.html) component
 *
 */
function IconButton({ icon, color, size, ...props }: IconButtonProps) {
  return (
    <Wrapper {...props}>
      <Icon fitted color={color} name={icon} size={size} />
    </Wrapper>
  );
}

export default IconButton;
