import { InformationCard } from '../Styled';
import { Image } from 'semantic-ui-react';
import spinArrowsIcon from 'images/improvements/arrows-spin.svg';
import messages from './messages';
import i18n from 'helpers/i18n';
import { CheckBullets } from './Styled';

interface ToDoProps {
  handleToggle: () => void;
  educatorFirstName: string;
  toggleStartObservation: boolean;
  handleObservationToggle: () => void;
}

function ToDo({
  handleToggle,
  educatorFirstName,
  toggleStartObservation,
  handleObservationToggle
}: ToDoProps) {
  return (
    <>
      <InformationCard className="p-8 mb-6 print:hidden">
        <header>
          <h2>{i18n.ft(messages.toDo)}</h2>
        </header>
        <h3 className="flex gap-2 mb-2">
          <Image src={spinArrowsIcon} alt="spin arrows icon" />
          {' ' +
            i18n.ft(messages.initialCoachingSession) +
            ' ' +
            educatorFirstName}
        </h3>
        <hr />
        <h4 className="my-4">{i18n.ft(messages.youWill)}:</h4>
        <CheckBullets className="grid gap-4 ml-6 pb-4" data-testid="to-do-list">
          <li>
            <span>{i18n.ft(messages.buildARelationship)}</span>
          </li>
          <li>
            <span>{i18n.ft(messages.talkAboutChildren)}</span>
          </li>
          <li>
            <span>{i18n.ft(messages.setAFocusArea)}</span>
          </li>
          <li>
            <span>{i18n.ft(messages.conductGeneralObservation)}</span>
          </li>
        </CheckBullets>
        {!toggleStartObservation && (
          <div className="inline">
            <strong>{i18n.ft(messages.startCoachingRelationship)}</strong>
            <br />
            <button
              className="text-[#017EA7] border border-[#017EA7] py-3 px-6 mt-4 mr-4 rounded-lg font-bold"
              onClick={handleObservationToggle}
            >
              {i18n.ft(messages.startObservation)}
            </button>
          </div>
        )}
        <button
          className="bg-[#017EA7] border border-[#017EA7] text-white py-3 px-6 mt-4 rounded-lg font-bold"
          onClick={handleToggle}
        >
          {i18n.ft(messages.startInitialSession)}
        </button>
      </InformationCard>
    </>
  );
}

export default ToDo;
