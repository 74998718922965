import styled from 'styled-components';

export const FilterContainer = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 8px;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: column;
  }

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 16px;
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1536px) {
    grid-column-gap: 48px;
    grid-row-gap: 16px;
  }
`;

export const FiltersDropdowns = styled.div`
  margin-top: 16px;
  display: grid;
  grid-gap: 16px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
    grid-template-columns: 1fr 1fr;
  }
`;

export const LargeFiltersDropdowns = styled.div`
  margin-top: 16px;
  display: grid;
  grid-gap: 16px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
    grid-template-columns: 1fr;
  }
`;

export const FiltersDatePicker = styled.div`
  background-color: ${p => p.theme.colors.white};
  border-radius: 5px;
`;

export const ClearSearchButton = styled.div`
  margin-top: 16px;
  display: grid;
  grid-gap: 16px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;
