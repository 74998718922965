const PATH = 'fe.components.learning_resources.completed_recommended';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Completed Resources'
  },
  all: {
    key: `${PATH}.all`,
    defaultValue: 'See All'
  }
};

export default messages;
