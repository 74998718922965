import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import RoutesHelper from 'helpers/routes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from 'hooks/useCurrentUser';
import VideoResource from '../VideoResource';
import { getClassroomScores } from 'actions/learningResources';
import { Loader } from 'semantic-ui-react';
import { LearningResource } from 'types/api/LearningResource';
import { getHoursMinutes } from 'pages/LearningResources/utils';

interface ClassroomScoresProps {
  selectedEducatorName?: string;
  selectedEducatorId: number;
  ageLevel: string;
}

function ClassroomScores({
  selectedEducatorName,
  selectedEducatorId,
  ageLevel
}: ClassroomScoresProps) {
  const [isHovered, setIsHovered] = useState(false);
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { data, run, isPending } = useAsync();

  useEffect(() => {
    run(getClassroomScores(selectedEducatorId, ageLevel));
  }, [ageLevel, run, selectedEducatorId]);

  function handleClick(id: number) {
    if (user.roles.includes('lr_coach')) {
      return navigate(
        RoutesHelper.getPath('coach-learning-resources-detail-page', {
          id
        })
      );
    }

    return navigate(
      RoutesHelper.getPath('learning-resources-detail-page', {
        id
      })
    );
  }

  if (isPending) {
    return <Loader active />;
  }

  if (data?.resources?.length === 0 || !data) {
    return null;
  }

  return (
    <>
      <div className="block md:flex items-center mb-4">
        <h2 className="text-black">
          {i18n.ft(messages.title, { educator: selectedEducatorName })}
        </h2>{' '}
        <a
          className="pl-0 md:pl-4 font-bold underline underline-offset-2 text-base text-[#0A9CCA]"
          href={RoutesHelper.getPath('learning-resources-all')}
        >
          {i18n.ft(messages.all)}
        </a>
      </div>
      <div
        className={`flex gap-3 2xl:grid 2xl:grid-cols-4 pb-8 ${
          isHovered
            ? 'overflow-auto scrollbar-visible'
            : 'overflow-hidden scrollbar-hidden'
        }`}
        onTouchStart={() => {
          setIsHovered(true);
        }}
        onTouchEnd={() => {
          setIsHovered(false);
        }}
      >
        {data.resources?.map((video: LearningResource, idx: number) => (
          <VideoResource
            ariaLabel={'Video Thumbnail'}
            ariaRoleDescription={'Slide'}
            handleClick={handleClick}
            video={{
              name: video.name,
              id: video.id ? video.id : idx,
              videoURI: video.video_uri ? video.video_uri : '1_bkuk1xi7',
              estimatedTime: video.estimated_time
                ? getHoursMinutes(video.estimated_time)
                : '0'
            }}
            key={video.id}
            tags={video.tags}
          />
        ))}
      </div>
    </>
  );
}

export default ClassroomScores;
