import { css } from 'styled-components';

export const fadeInAndOutStyles = css`
  &.enter {
    transition-property: opacity;
    transition-timing-function: linear;
    transition-duration: 300ms;
  }

  &.enter-from {
    opacity: 0;
  }

  &.enter-to {
    opacity: 100;
  }

  &.leave {
    transition-property: opacity;
    transition-timing-function: linear;
    transition-duration: 300ms;
  }

  &.leave-from {
    opacity: 100;
  }

  &.leave-to {
    opacity: 0;
  }
`;

export const fadeInAndOutProps = {
  enter: 'enter',
  enterFrom: 'enter-from',
  enterTo: 'enter-to',
  leave: 'leave',
  leaveFrom: 'leave-from',
  leaveTo: 'leave-to'
};

export const slideInAndOutStyles = css`
  &.enter {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }

  &.enter-from {
    transform: translateX(100%);
  }

  &.enter-to {
    transform: translateX(0);
  }

  &.leave {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }

  &.leave-from {
    transform: translateX(0);
  }

  &.leave-to {
    transform: translateX(100%);
  }
`;

export const slideInAndOutProps = {
  enter: 'enter',
  enterFrom: 'enter-from',
  enterTo: 'enter-to',
  leave: 'leave',
  leaveFrom: 'leave-from',
  leaveTo: 'leave-to'
};
