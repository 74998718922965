const PATH = 'fe.components.measure.view.cycle.meta_data_category_form';

const messages = {
  errors: {
    required: {
      key: `${PATH}.required`,
      defaultValue: 'Required'
    },
    select_one: {
      key: `${PATH}.select_one`,
      defaultValue: 'Please select at least one'
    }
  }
};

export default messages;
