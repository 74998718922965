const PATH = 'fe.pages.measure.observation_videos.view';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'CLASS Video Observations'
  },
  capturedOn: {
    key: `${PATH}.captured_on`,
    defaultValue: 'Captured On'
  },
  submittedOn: {
    key: `${PATH}.submitted_on`,
    defaultValue: 'Submitted On'
  },
  language: {
    key: `${PATH}.language`,
    defaultValue: 'Language'
  },
  secondaryLanguage: {
    key: `${PATH}.secondary_language`,
    defaultValue: 'Secondary Language'
  },
  notSet: {
    key: `${PATH}.not_set`,
    defaultValue: 'Not Set'
  },
  enrollment: {
    key: `${PATH}.enrollment`,
    defaultValue: 'Enrollment'
  },
  returnToList: {
    key: `${PATH}.return_to_list`,
    defaultValue: 'Return to List'
  },
  rejectVideo: {
    key: `${PATH}.reject_video`,
    defaultValue: 'Reject Video'
  },
  returnVideo: {
    key: `${PATH}.return_video`,
    defaultValue: 'Return Video'
  },
  approveVideo: {
    key: `${PATH}.approve_video`,
    defaultValue: 'Approve Video'
  },
  center: {
    key: `${PATH}.center`,
    defaultValue: 'Center'
  },
  classroom: {
    key: `${PATH}.classroom`,
    defaultValue: 'Classroom'
  },
  instructions: {
    key: `${PATH}.instructions`,
    defaultValue:
      'Please read and acknowledge the observer protocol for conducting a video observation:'
  },
  protocol1: {
    key: `${PATH}.protocol1`,
    defaultValue:
      'I am able to conduct the observation in the primary language in the video.'
  },
  protocol2: {
    key: `${PATH}.protocol2`,
    defaultValue:
      'I do not have a conflict of interest with this center, classroom, staff and children represented in the video.'
  },
  protocol3: {
    key: `${PATH}.protocol3`,
    defaultValue:
      'I may have paused the video observation but did not rewind the video as per the video observation protocol.'
  },
  protocol4: {
    key: `${PATH}.protocol4`,
    defaultValue:
      'I have completed coding all cycles of my observation and I am ready to enter data for the video observation.'
  },
  protocol5: {
    key: `${PATH}.protocol5`,
    defaultValue:
      'I have a completed score sheet for all cycles coded in the video observation.'
  },
  agreeToComply: {
    key: `${PATH}.agree_to_comply`,
    defaultValue:
      'I confirm that I have complied with the observer protocol for conducting video observations.'
  },
  termsError: {
    key: `${PATH}.terms_error`,
    defaultValue: 'Please confirm that you have followed the observer protocol.'
  },
  approveModal: {
    message: {
      key: `${PATH}.approve_modal.message`,
      defaultValue:
        "Are you sure you want approve this video? You can't undo this action."
    },
    submit: {
      key: `${PATH}.approve_modal.submit`,
      defaultValue: 'Yes, Approve'
    }
  },
  rejectModal: {
    message: {
      key: `${PATH}.reject_modal.message`,
      defaultValue:
        'Please provide reason for rejecting this video to provide to iRIS'
    },
    reasonPlaceholder: {
      key: `${PATH}.reject_modal.reason_placeholder`,
      defaultValue: 'Specify the reason for rejecting this video'
    },
    reasonRequired: {
      key: `${PATH}.reject_modal.reason_required`,
      defaultValue: 'Rejection reason is required'
    },
    customReasonTooLong: {
      key: `${PATH}.reject_modal.custom_reason_too_long`,
      defaultValue: 'Reason text too long. Max. 191 characters'
    }
  },
  reasons: {
    cannotHearTeacher: {
      key: `${PATH}.reasons.cannot_hear_teacher`,
      defaultValue: 'Cannot hear teacher(s) well'
    },
    cannotHearChildren: {
      key: `${PATH}.reasons.cannot_hear_children`,
      defaultValue: 'Cannot hear children well'
    },
    cannotSeeTeacher: {
      key: `${PATH}.reasons.cannot_see_teacher`,
      defaultValue: 'Cannot see teacher(s) or children well'
    },
    cameraMoved: {
      key: `${PATH}.reasons.camera_moved`,
      defaultValue: 'Camera got moved / too many distractions from interactions'
    },
    noCodeableCycles: {
      key: `${PATH}.reasons.no_codeable_cycles`,
      defaultValue: 'There are not 2, 10-20 minutes codeable cycles'
    },
    other: {
      key: `${PATH}.reasons.other_reason`,
      defaultValue: 'Other'
    }
  },
  observerReasons: {
    alreadyCoded: {
      key: `${PATH}.observer_reasons.already_coded`,
      defaultValue: 'I already coded this video'
    },
    notFluent: {
      key: `${PATH}.observer_reasons.not_fluent`,
      defaultValue: 'I am not fluent in the primary languages spoken'
    },
    coflictOfInterest: {
      key: `${PATH}.observer_reasons.conflict_of_interest`,
      defaultValue: 'I have a conflict of interest'
    },
    other: {
      key: `${PATH}.reasons.other_reason`,
      defaultValue: 'Other'
    }
  },
  recommendedTimes: {
    header: {
      key: `${PATH}.recommended_times.header`,
      defaultValue: 'Some tips from your Admin'
    },
    title: {
      key: `${PATH}.recommended_times.title`,
      defaultValue: 'Recommended times'
    },
    titleForObservers: {
      key: `${PATH}.recommended_times.title_for_observers`,
      defaultValue: 'Recommended times to observe for each cycle'
    }
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'There was an error processing your request'
  }
};

export default messages;
