const PATH = 'fe.components.reliability_test.bottom_section';

const messages = {
  exitTest: {
    key: `${PATH}.exit_test`,
    defaultValue: 'Return to Dashboard'
  },
  passAction: {
    key: `${PATH}.pass_action`,
    defaultValue: 'View All Certifications'
  },
  reliabilitySupport: {
    key: `${PATH}.reliability_support`,
    defaultValue: 'Reliability Support'
  },
  observationTrainings: {
    key: `${PATH}.observation_trainings`,
    defaultValue: 'Observation Trainings'
  },
  continue: {
    key: `${PATH}.continue`,
    defaultValue: 'Continue'
  },
  reviewVideo: {
    key: `${PATH}.review_video`,
    defaultValue: 'Review Video'
  },
  certifications: {
    key: `${PATH}.certifications`,
    defaultValue: 'View All Certifications'
  }
};

export default messages;
