const PATH = 'fe.components.measure.view.review_modal';

const messages = {
  review: {
    key: `${PATH}.review`,
    defaultValue: 'Review Feedback'
  },
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Review Feedback from your Administrator'
  },
  close: {
    key: `${PATH}.close`,
    defaultValue: 'Close'
  }
};

export default messages;
