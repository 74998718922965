import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import PropTypes from 'prop-types';
import { map, any, isEmpty } from 'ramda';
import TrainingCard from '../TrainingCard';
import ResultSummary from '../ResultSummary';
import React, { useEffect, useState } from 'react';
import trainingStatus from 'constants/trainingStatus';
import { usePaginatedTrainings } from '../utils';
import { Button, Loader } from 'semantic-ui-react';
import { getMyCertificates } from 'actions/certificates';
import { EmptyMessage, Container } from './Styled';
import { TRAINING_STATUS } from 'types/api/Training';

const dateFormat = 'MM/DD/YYYY';
const dateFormatForAPI = 'YYYY-MM-DD';

type Certificate = {
  training_id: number;
  age_level_group_name: string;
  ceus_earned: string;
  description: string;
  display_ceu: false;
  first_session: string;
  hours: string;
  id: number;
  last_session?: string | null;
  trainer_name: string;
  training_title: string;
  user: string;
};

interface TrainingListProps {
  query: string;
  status?: TRAINING_STATUS;
  startDate: string | null;
  endDate: string | null;
}

interface Params {
  query: string;
  status?: TRAINING_STATUS;
  start_date: string | null;
  end_date: string | null;
}

function TrainingList({
  status,
  query,
  startDate,
  endDate
}: TrainingListProps) {
  const params: Params = React.useMemo(
    () => ({
      status,
      query,
      start_date: isEmpty(startDate)
        ? null
        : moment(startDate, dateFormat).format(dateFormatForAPI),
      end_date: isEmpty(endDate)
        ? null
        : moment(endDate, dateFormat).format(dateFormatForAPI)
    }),
    [status, query, startDate, endDate]
  );

  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [trainings, totalCount, loading, moreToLoad, loadMore] =
    usePaginatedTrainings(params);

  useEffect(() => {
    getMyCertificates().then(({ data }) => {
      setCertificates(data.certificates);
    });
  }, []);

  function hasCertificateForTraining(trainingId: number) {
    return any(
      (cert: Certificate) => cert.training_id === trainingId,
      certificates
    );
  }

  const noTrainingsAvailable = trainings.length === 0;

  if (noTrainingsAvailable && loading) {
    return <Loader active size="large" inline="centered" />;
  }

  return (
    <Container>
      <ResultSummary visible={trainings.length} total={totalCount} />

      {noTrainingsAvailable && (
        <EmptyMessage>{i18n.ft(messages.emptyMessage)}</EmptyMessage>
      )}

      {map(
        training => (
          <TrainingCard
            key={training.id}
            training={training}
            certificate={hasCertificateForTraining(training.id)}
          />
        ),
        trainings
      )}

      {(moreToLoad || loading) && (
        <Button
          color="blue"
          loading={loading}
          onClick={loadMore}
          content={i18n.ft(messages.showMoreTrainings)}
        />
      )}
    </Container>
  );
}

TrainingList.propTypes = {
  /** Value to search trainings by. */
  query: PropTypes.string.isRequired,

  /** A training status or an empty string. */
  status: PropTypes.oneOf(['', ...trainingStatus]),

  /** Start date to filter trainings from. */
  startDate: PropTypes.string.isRequired,

  /** End date to filter trainings to. */
  endDate: PropTypes.string.isRequired
};

export default TrainingList;
