const PATH =
  'fe.pages.improvements.educator_profile.educator_details.observations';

const messages = {
  observation: {
    key: `${PATH}.observation`,
    defaultValue: 'Observation'
  },
  responsePlaceholder: {
    key: `${PATH}.response_placeholder`,
    defaultValue:
      'For example - the educator texted on Monday that they tried the plan every day last week. They adjusted the plan and switched to different books based on children’s engagement.'
  },
  responseFieldRequired: {
    key: `${PATH}.response_field_required`,
    defaultValue: 'Field Required'
  },
  submit: {
    key: `${PATH}.submit`,
    defaultValue: 'Finish Observation'
  },
  edit: {
    key: `${PATH}.edit`,
    defaultValue: 'Edit'
  },
  observationSheet: {
    key: `${PATH}.observation_sheet`,
    defaultValue: 'Observation Sheet'
  },
  notesDescription: {
    key: `${PATH}.notes_description`,
    defaultValue:
      'Use this sheet to document key details and insights from the observation related to the focus. Make sure to also note any special circumstances or important considerations. You’ll use your notes to help you reflect and  plan your next steps.'
  },
  observationCompleted: {
    key: `${PATH}.observation_completed`,
    defaultValue: 'Observation Completed'
  },
  viewObservationNotes: {
    key: `${PATH}.view_observation_notes`,
    defaultValue: 'View Observation Notes'
  },
  initialState: {
    submit: {
      key: `${PATH}.initial_state.submit`,
      defaultValue: 'Start Observation'
    },
    checkin: {
      key: `${PATH}.initial_state.checkin`,
      defaultValue: 'Check In'
    },
    notesDescription: {
      key: `${PATH}.initial_state.notes_description`,
      defaultValue:
        'As the educator tries out the plan, check in to see how it’s going. Use this space to note communications the educator shares, including questions, challenges, successes, insights and any adjustments to the plan.'
    },
    observationEmptyState: {
      key: `${PATH}.initial_state.observation_empty_state`,
      defaultValue: 'Action Plan In Progress'
    }
  },
  observationDetails: {
    details: {
      key: `${PATH}.observation_details.details`,
      defaultValue: 'Upcoming Observation Details'
    },
    participants: {
      key: `${PATH}.observation_details.participants`,
      defaultValue: 'Participants'
    },
    observationSchedule: {
      key: `${PATH}.observation_details.observation_schedule`,
      defaultValue: 'Scheduled Date'
    },
    cycleFocus: {
      key: `${PATH}.observation_details.cycle_focus`,
      defaultValue: 'Coaching Cycle Focus'
    }
  },
  observationNotes: {
    strategySetHeader: {
      key: `${PATH}.observation_notes.strategy_set_header`,
      defaultValue: 'Key Strategies'
    },
    observationNotesSubtitle: {
      key: `${PATH}.observation_notes.observation_notes_subtitle`,
      defaultValue:
        'Use this space to document what you see and hear during the observation. Make a check next to the cues you observe. In the text box, note specific examples related to the focus, key strategies, action plan, and cues. Note educator’s actions, children’s responses, and how many examples of each cue you observe. This text box is for your own private notes.'
    },
    observationNotesValidation: {
      key: `${PATH}.observation_notes.observation_notes_validation`,
      defaultValue: 'Observation Note is required'
    },
    cueChecklist: {
      key: `${PATH}.observation_notes.cue_checklist`,
      defaultValue: 'Cues'
    },
    generalObservationCues: {
      key: `${PATH}.observation_notes.general_observation_cues`,
      defaultValue: 'General Cues'
    },
    improvementObservationEducatorsCues: {
      key: `${PATH}.observation_notes.improvement_educators_observation_cues`,
      defaultValue: 'Educator Cues'
    },
    improvementObservationChildrensCues: {
      key: `${PATH}.observation_notes.improvement_childrens_observation_cues`,
      defaultValue: 'Child Cues'
    },
    observationNotes: {
      key: `${PATH}.observation_notes.observation_notes`,
      defaultValue: 'Observation Notes'
    },
    defaultNoteResponse: {
      key: `${PATH}.observation_notes.default_note_response`,
      defaultValue: 'Not Provided'
    },
    observationNotesPlaceholder: {
      key: `${PATH}.observation_notes.observation_notes_placeholder`,
      defaultValue:
        'Activity/experience:\n' +
        '\n' +
        'Context (time of day, content area, format, number of children):\n' +
        '\n' +
        'Environment (materials, arrangements, displays):\n' +
        '\n' +
        'Specific examples of strategies observed: (what educator said/did, what children said/did)\n'
    },
    observationSummary: {
      key: `${PATH}.observation_notes.observation_summary`,
      defaultValue: 'Observation Highlights'
    },
    observationSummaryValidation: {
      key: `${PATH}.observation_notes.observation_summary_validation`,
      defaultValue: 'Summary note is required'
    },
    observationSummarySubtitle: {
      key: `${PATH}.observation_notes.observation_summary_subtitle`,
      defaultValue:
        'In the space below, add examples of the strategies observed and any insights you want to share. Include examples of the educator’s actions and the children’s responses. You can copy and paste examples from your notes above. Include questions you may want to ask the educator to get their perspective during reflection. The notes in the text box below will be shared - you will be able to see and discuss these during your reflection conversation.'
    },
    observationNotesSummaryPlaceholder: {
      key: `${PATH}.observation_notes.observation_notes_summary_placeholder`,
      defaultValue:
        'Examples of the strategies observed (educator’s actions and children’s responses):\n' +
        '\n' +
        'Insights you want to share:\n' +
        '\n' +
        'Questions to prompt reflection:\n'
    },
    additionalCuesFromActionPlan: {
      key: `${PATH}.observation_notes.additional_cues_from_action_plan`,
      defaultValue: 'Additional Cues'
    },
    strategiesHeader: {
      key: `${PATH}.observation_notes.strategies_header`,
      defaultValue: 'Strategies'
    }
  }
};
export default messages;
