import moment from 'moment';
import { map, range, isNil, isEmpty } from 'ramda';
import { Training } from 'types/api/Training';
import { TrainingTemplate } from 'types/api/TrainingTemplate';
import momentTimezone from 'moment-timezone';

type SessionParams = {
  id: number;
  date: string;
  start_time: string;
  end_time: string;
};

export interface URLState {
  notes_title?: string;
  notes?: string;
}

const defaultTimeZone = momentTimezone.tz.guess(true);
const DEFAULT_START_TIME = '08:00 AM';
const DEFAULT_END_TIME = '05:00 PM';

export function isNilOrEmpty(value: any) {
  return isNil(value) || isEmpty(value);
}

export function initialCreateValues(
  template: TrainingTemplate,
  { notes_title, notes }: URLState
) {
  const initialSessions = map(
    () => ({
      date: '',
      start_time: DEFAULT_START_TIME,
      end_time: DEFAULT_END_TIME
    }),
    range(0, template.number_of_sessions || 0)
  );

  return {
    notes_title,
    notes,
    sessions: initialSessions,
    trainer_id: null,
    delivery_method: 'virtual',
    location_country: '',
    time_zone: defaultTimeZone,
    submit_type: 'default'
  };
}

export function initialEditValues(
  template: TrainingTemplate,
  training: Training
) {
  const timeZone = training.time_zone ?? defaultTimeZone;
  const initialSessions = map(
    nSession => {
      const session = training.sessions[nSession];

      if (session) {
        return {
          id: session.id,
          date: moment(session.starts_at).tz(timeZone).format('MM/DD/YYYY'),
          start_time: moment(session.starts_at).tz(timeZone).format('LT'),
          end_time: moment(session.ends_at).tz(timeZone).format('LT')
        };
      } else {
        return { date: '', start_time: '', end_time: '' };
      }
    },
    range(0, template.number_of_sessions || 0)
  );

  return {
    id: training.id,
    sessions: initialSessions,
    trainer_id: training.trainer_id,
    location_address_1: training.location_address.address_1,
    location_address_2: training.location_address.address_2,
    location_city: training.location_address.city,
    location_country: training.location_address.country,
    location_state: training.location_address.state,
    location_zip: training.location_address.zip,
    virtual_training_link: training.virtual_training_link,
    notes_title: training.notes_title,
    notes: training.notes,
    delivery_method: training.delivery_method,
    account_id: training.account?.id,
    node_id: training.node?.id,
    time_zone: training.time_zone ?? defaultTimeZone,
    submit_type: 'default'
  };
}

export function sessionHasData(session: SessionParams) {
  return (
    !isNilOrEmpty(session.date) ||
    !isNilOrEmpty(session.start_time) ||
    !isNilOrEmpty(session.end_time)
  );
}

export function sessionIsValid(session: SessionParams) {
  return (
    !isNilOrEmpty(session) &&
    !isNilOrEmpty(session.date) &&
    !isNilOrEmpty(session.start_time) &&
    !isNilOrEmpty(session.end_time)
  );
}

export function formatSession(session: SessionParams, timeZone?: string) {
  const format = 'MM/DD/YYYY LT';
  const timeZoneToUse = timeZone ?? defaultTimeZone;
  momentTimezone.locale('en');

  // Adding 'en' so that datetimes are always parsed in English.
  // Other languages like 'es' read times in 24hr format, causing the final date to be incorrect.
  const startsAt = momentTimezone.tz(
    `${session.date} ${session.start_time}`,
    format,
    timeZoneToUse
  );
  const endsAt = momentTimezone.tz(
    `${session.date} ${session.end_time}`,
    format,
    timeZoneToUse
  );

  return {
    id: session.id,
    starts_at: startsAt.utc().format(),
    ends_at: endsAt.utc().format()
  };
}
