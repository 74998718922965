import styled from 'styled-components';
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';

export const Container = styled.div<{ active?: boolean }>`
  display: flex;
  padding: 16px;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  border: 1px solid #e2e2e3;
  background-color: ${props => (props.active ? '#e0e0e0' : '#fafafb')};
  position: relative;

  &:hover {
    background-color: ${props => (props.active ? null : '#f2f2f2')};
  }

  &:not(:first-of-type) {
    margin-top: 8px;
  }

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

export const ChecboxColumn = styled.div`
  order: 1;

  @media screen and (max-width: 600px) {
    order: 4;
    position: absolute;
    bottom: 19px;
    right: 16px;
  }
`;

export const UserColumn = styled.div`
  order: 2;
  padding: 0 16px;
  width: 55%;

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 0;
  }
`;

export const StatusColumn = styled.div`
  order: 3;
  width: 20%;
  padding-right: 16px;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 8px;
  }
`;

export const Checkbox = styled(SemanticCheckbox)`
  display: block !important;
`;
