import { clsx } from 'clsx';
import { forwardRef } from 'react';
import { Field, Label, Input as BaseInput } from '@headlessui/react';

export interface InputComponentProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

export const Input = forwardRef<HTMLInputElement, InputComponentProps>(
  ({ label, required, className, error, ...props }, ref) => {
    const classes = clsx(
      'p-2 font-sans text-base text-black placeholder:text-[#3C3F42] border border-[#889EBB] rounded-lg',
      // focus state
      'data-[focus]:outline-0 data-[focus]:border-[#0A9CCA] data-[focus]:ring-1 data-[focus]:ring-[#0A9CCA]',
      // additional classes
      className
    );

    return (
      <Field className="flex flex-col gap-1 font-sans">
        {label && (
          <Label className="font-semibold text-base text-black">
            {label + (required ? '*' : '')}
          </Label>
        )}

        <BaseInput ref={ref} className={classes} {...props} />

        {error && (
          <p className="font-semibold text-sm text-[#AC213A] m-0 px-1">
            {error}
          </p>
        )}
      </Field>
    );
  }
);
