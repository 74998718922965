const PATH = 'fe.components.learning_resources.recommended';

const messages = {
  recommend: {
    key: `${PATH}.recommend`,
    defaultValue: 'Recommended for You'
  },
  all: {
    key: `${PATH}.all`,
    defaultValue: 'See All'
  }
};

export default messages;
