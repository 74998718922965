import axios from './axiosConfig';

export function getObservationCycleScores(cycleId: number) {
  return axios.get(`/api/v1/observation_cycles/${cycleId}/scores`);
}

export function updateObservationCycleScores(
  cycleId: number,
  params: any = {}
) {
  return axios.post(`/api/v1/observation_cycles/${cycleId}/scores`, params);
}
