import { createIcon } from './utils';

export const IconError = createIcon(
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30 15C30 23.2861 23.2837 30 15 30C6.71631 30 0 23.2861 0 15C0 6.71873 6.71631 0 15 0C23.2837 0 30 6.71873 30 15ZM15 18.0242C13.4634 18.0242 12.2177 19.2699 12.2177 20.8065C12.2177 22.343 13.4634 23.5887 15 23.5887C16.5366 23.5887 17.7823 22.343 17.7823 20.8065C17.7823 19.2699 16.5366 18.0242 15 18.0242ZM12.3585 8.02343L12.8072 16.2492C12.8281 16.6342 13.1464 16.9355 13.5319 16.9355H16.4681C16.8536 16.9355 17.1719 16.6342 17.1928 16.2492L17.6415 8.02343C17.6642 7.60766 17.3332 7.25806 16.9168 7.25806H13.0831C12.6668 7.25806 12.3358 7.60766 12.3585 8.02343Z"
      fill="#C50040"
    />
  </svg>
);
