import PrintMenuButton from 'components/PrintMenuButton';
import i18n from 'helpers/i18n';
import messages from './messages';
import HideOnPrint from './HideOnPrint';

interface CardHeaderProps {
  title?: string;
  subtitle?: string;
  showCTA: boolean;
  onClickAction?: () => void;
  showPrintButton?: boolean;
  showSubtitleOnPrint?: boolean;
}

function CardHeader({
  title,
  subtitle,
  showPrintButton = true,
  showSubtitleOnPrint = true,
  showCTA,
  onClickAction
}: CardHeaderProps) {
  return (
    <div className="flex justify-between content-center">
      <div>
        {title && (
          <header className="print:block">
            <h2>{title}</h2>
          </header>
        )}
        {subtitle && (
          <h3
            className={HideOnPrint(
              showSubtitleOnPrint,
              'text-2xl font-semibold pb-2'
            )}
          >
            {subtitle}
          </h3>
        )}
      </div>

      <span className="flex items-center">
        {showPrintButton && (
          <PrintMenuButton title="Download PDF" icon="fa-solid fa-download" />
        )}

        {showCTA && (
          <button
            className="cursor-pointer text-[#017EA7] print:hidden"
            onClick={onClickAction}
          >
            <strong>{i18n.ft(messages.hideCard)}</strong>
          </button>
        )}
      </span>
    </div>
  );
}

export default CardHeader;
