import styled from 'styled-components';
import { AGE_LEVEL_ABBREVIATIONS } from 'types/api/AgeLevel';

export const Container = styled.div<{ abbreviation: AGE_LEVEL_ABBREVIATIONS }>`
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  border-top: 5px solid
    ${({ theme, abbreviation }) =>
      theme.colors.ageLevels[abbreviation.toLowerCase()]};
  display: inline-block;
  max-width: 350px;
  min-width: 250px;
  width: 100%;

  &:not(:last-child) {
    margin-right: 16px;
  }

  @media screen and (max-width: 980px) {
    max-width: none;

    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  position: relative;
`;

export const ChartLabel = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: ${props => props.theme.colors.blue};
  width: 100%;
  text-align: center;
  position: absolute;
  top: 67px;
`;

export const LegendContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const LegendItem = styled.div<{ color: string }>`
  margin: 0 8px;

  &::before {
    content: '';
    background-color: ${props => props.color};
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
  }
`;

export const ChartFooter = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

export const FooterDetail = styled.div`
  margin: 0 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const DetailValue = styled.div`
  color: ${props => props.theme.colors.blue};
  font-weight: bold;
  font-size: 36px;
  line-height: 1;
`;

export const DetailLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #333333;
`;
