import styled from 'styled-components';

export const LinkButton = styled.button`
  color: ${props => props.theme.colors.blue};
  background-color: transparent;
  font-family: inherit;
  cursor: pointer;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const SubHeader = styled.div`
  margin-bottom: 24px;
  text-align: center;
`;
