import React, { useRef } from 'react';
import { ModalButton, ModalHeader, ModalAction, ModalIcon } from './Styled';
import { Modal, ModalProps, SemanticShorthandContent } from 'semantic-ui-react';
import instructionsIcon from 'images/reliabilityTest/instructionsIcon.svg';

interface InstructionsModalProps extends ModalProps {
  /**Title for the modal */
  title: string;

  /**Content message for the modal */
  message: SemanticShorthandContent;

  /**Text associate with the right button for complete the modal action */
  confirmButton: string;

  /**Header Icon  */
  // icon: SemanticICONS;;
  icon?: String;

  /** Node associate with the trigger of the modal */
  triggerNode?: React.ReactNode;

  /**Function call when the confirmation is completed */
  onConfirm: () => void;

  /**true when modal is open */
  isOpen: boolean;
}

function InstructionsModal({
  title,
  message,
  confirmButton,
  triggerNode,
  onConfirm,
  onClose,
  isOpen
}: InstructionsModalProps) {
  const modalRef = useRef<any>({ current: null });

  function handleConfirmation() {
    onConfirm();
  }

  const handleOnMount = () => {
    const modalElement = document.getElementById('information-modal');

    if (isOpen && modalElement) {
      modalRef.current = modalElement;

      modalRef.current.focus();
    }
  };

  return (
    <Modal
      ref={modalRef}
      id="information-modal"
      tabIndex={0}
      trigger={triggerNode}
      open={isOpen}
      onClose={onClose}
      onMount={handleOnMount}
      closeOnDimmerClick
      size="small"
      className="px-8"
      dimmer={{ style: { backgroundColor: 'rgba(0,0,0,0.63)' } }}
      style={{
        borderRadius: '17px'
      }}
      autoFocus
    >
      <div className="grid place-content-center">
        <ModalIcon src={instructionsIcon} className="w-20" />
      </div>
      <ModalHeader content={title} className="w-full" />
      <Modal.Content content={message} />
      <ModalAction>
        <ModalButton
          type="button"
          onClick={handleConfirmation}
          content={confirmButton}
          className="w-full mb-5"
          tabIndex={0}
        />
      </ModalAction>
    </Modal>
  );
}

export default InstructionsModal;
