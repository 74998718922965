import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid ${p => p.theme.colors['neutral']['200']};
  border-bottom: 1px solid ${p => p.theme.colors['neutral']['200']};
  background-color: ${p => p.theme.colors['white']};

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media screen and (min-width: 768px) {
    border-radius: 6px;
    border: 1px solid ${p => p.theme.colors['neutral']['200']};
  }
`;

export const ContainerTop = styled.div`
  padding: 12px 16px;

  @media screen and (min-width: 640px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (min-width: 768px) {
    padding: 16px 24px;
  }
`;

export const ContainerBottom = styled.div`
  padding: 12px 16px;
  border-top: 1px solid ${p => p.theme.colors['neutral']['200']};
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    padding: 16px 24px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: ${p => p.theme.colors['neutral']['900']};
`;

export const TagContainer = styled.div`
  margin-top: 8px;
  margin-bottom: -8px;
`;

export const Subtitle = styled.div`
  font-weight: 600;
  color: ${p => p.theme.colors['neutral']['500']};
  margin-bottom: 4px;
`;

export const Description = styled.div`
  font-weight: 600;
  color: ${p => p.theme.colors['neutral']['900']};
`;

export const ActionsContainer = styled.div`
  margin-top: 8px;

  @media screen and (min-width: 640px) {
    margin-top: 0;
    margin-left: auto;
  }
`;

export const DetailsContainer = styled.div`
  @media screen and (min-width: 768px) {
    width: 48%;
    margin-right: 16px;
  }
`;

export const DateContainerMobile = styled.div`
  margin-top: 8px;
  font-weight: 600;
  color: ${p => p.theme.colors['neutral']['500']};

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const DateContainer = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const TestStatus = styled.div<{ passed?: boolean; failed?: boolean }>`
  padding: 8px 24px;
  display: inline-flex;
  border-radius: 6px;
  font-weight: 600;
  color: ${p => p.theme.colors['neutral']['700']};
  background-color: ${({ passed, failed, theme }) =>
    passed
      ? theme.colors['success']['100']
      : failed
      ? theme.colors['error']['100']
      : theme.colors['neutral']['100']};
`;

export const ReportLink = styled.a`
  line-height: 24px;
  color: ${p => p.theme.colors['primary']['700']};
  font-weight: 700;
  margin-right: 32px;
  outline-offset: 4px;
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }
`;

export const EmptyAttempts = styled.span`
  color: ${p => p.theme.colors['neutral']['500']};
  font-style: italic;
`;
