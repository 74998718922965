import { useCallback, useEffect } from 'react';
import { Tab, Icon } from 'semantic-ui-react';
import { SyntheticEvent, useState } from 'react';
import { NavLink, useLocation, Navigate } from 'react-router-dom';
import messages from './messages';
import i18n from 'helpers/i18n';
import AccountManager from 'components/Organization/AccountManager';
import { getCurrentAccount } from 'components/Organization/AccountManager/utils';
import { getAccountsByArea } from 'actions/accountManager/accounts';
import { SelectedAccount } from 'types/api/Account';
import { PageWrapper, Title, Container } from './Styled';
import useAsync from 'hooks/useAsync';
import { getOrganizationAccount } from 'actions/accounts';
import Trainings from 'components/Organization/Trainings';
import Subscriptions from 'components/Organization/Subscriptions';
import HomeSummary from 'components/Organization/Home/HomeSummary';
import { useMediaQuery } from 'react-responsive';
import PageBackground from 'components/PageBackground';
import { Flex } from 'components/Flex';
import useCurrentUser from 'hooks/useCurrentUser';

const AREA = 'ManageOrganization';

function Home() {
  const location = useLocation();
  const currentUser = useCurrentUser();
  const {
    data: accounts,
    run: runAccounts,
    isPending: isPendingAccounts
  } = useAsync<SelectedAccount[]>({ data: [] });
  const currentAccountId = getCurrentAccount(
    currentUser.current_account_id,
    accounts
  );
  const accountsFetched = accounts && accounts.length > 0;
  const isMobile = useMediaQuery({ minWidth: 640 });

  const { data, run, isPending } = useAsync();

  const loadAccount = useCallback(() => {
    if (currentAccountId > 0 && accountsFetched) {
      run(getOrganizationAccount(currentAccountId));
    }
  }, [run, currentAccountId, accountsFetched]);

  useEffect(() => {
    runAccounts(getAccountsByArea(AREA));
  }, [runAccounts]);

  useEffect(() => {
    loadAccount();
  }, [run, currentAccountId, accounts, loadAccount]);

  function onDataChange() {
    loadAccount();
  }

  const panes = [
    {
      menuItem: {
        as: NavLink,
        content: i18n.ft(messages.accountInfo),
        to: '/organization',
        end: true,
        key: 'organization'
      },
      render: () => (
        <Tab.Pane key="organization">
          <HomeSummary
            onChangeView={onChildrenChange}
            account={data}
            onAccountChange={onDataChange}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        as: NavLink,
        content: i18n.ft(messages.trainings),
        to: '/organization/trainings',
        key: 'training'
      },
      render: () => (
        <Tab.Pane key="training">
          <Trainings accountName={data.name} />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        as: NavLink,
        content: i18n.ft(messages.subscriptions),
        to: '/organization/subscriptions',
        key: 'subscription'
      },
      render: () => (
        <Tab.Pane key="subcription">
          <Subscriptions accountName={data.name} />
        </Tab.Pane>
      )
    }
  ];

  const activePaneIndex = panes.findIndex(
    pane => pane.menuItem.to === location.pathname
  );
  const [tab, setTab] = useState(activePaneIndex);

  function onChangeTab(e: SyntheticEvent, data: any) {
    setTab(data.activeIndex);
  }

  function onChildrenChange(changeTo: string) {
    const activePaneIndex = panes.findIndex(
      pane => pane.menuItem.to === changeTo
    );
    setTab(activePaneIndex);
  }

  if (!isPendingAccounts && (!accounts || accounts.length === 0)) {
    return <Navigate replace to="/" />;
  }

  return (
    <div>
      <AccountManager
        area={AREA}
        accounts={accounts}
        currentAccountId={currentAccountId}
        isLoadingAccounts={isPendingAccounts}
      />
      {accountsFetched && (
        <PageWrapper>
          {!currentAccountId && (
            <Container>
              <Flex justify="center">
                <Title>
                  <Icon name="info circle" color="red" />
                  {i18n.ft(messages.selectAccount)}
                </Title>
              </Flex>
            </Container>
          )}
          {!isPending && data && (
            <Container>
              <Title>
                <Icon name="flag" color="black" />
                {data.name}
              </Title>
              <PageBackground>
                {isMobile ? (
                  <Tab
                    panes={panes}
                    activeIndex={tab}
                    onTabChange={onChangeTab}
                  />
                ) : (
                  <Tab
                    panes={panes}
                    activeIndex={tab}
                    onTabChange={onChangeTab}
                    menu={{ secondary: true, stackable: true }}
                  />
                )}
              </PageBackground>
            </Container>
          )}
        </PageWrapper>
      )}
    </div>
  );
}

export default Home;
