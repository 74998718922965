type Option = {
  id: number;
  lms_course_id?: number;
  name: string;
};

type ProductOption = {
  id: number;
  display_name: string;
};

type SisCourseOption = {
  id: number;
  lms_course_id?: number;
  name: string;
  active: boolean;
};

export function defaultFormatter(option: Option) {
  return { key: option.id, value: option.id, text: option.name };
}

export function sisCourseFormatter(
  sisCourseOption: SisCourseOption,
  nameWithId: boolean = false
) {
  if (!sisCourseOption.active) {
    sisCourseOption.name = `${sisCourseOption.name} ⚠️`;
  }

  if (nameWithId) {
    return idNameFormatter(sisCourseOption);
  } else {
    return defaultFormatter(sisCourseOption);
  }
}

export function idNameFormatter(option: Option) {
  return {
    key: option.id,
    value: option.id,
    text: `${option.lms_course_id} - ${option.name}`
  };
}

export function productFormatter(option: ProductOption) {
  return { key: option.id, value: option.id, text: option.display_name };
}
