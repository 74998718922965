import React from 'react';
import { Container, Date, Time } from './Styled';

export interface SessionCardProps {
  /** The ID of the session. */
  id: number;

  /** The date of the session. */
  date: string;

  /** The start time of the session. */
  startTime: string;

  /** The end time of the session. */
  endTime: string;

  /** If `true` the card will have a selected style. */
  active?: boolean;

  /** If `true` the card will have a disabled style. */
  disabled?: boolean;

  /** Callback fired when the card is clicked. The session ID is included. */
  onClick?: (sessionID: number) => void;
}

const SessionCard: React.FC<SessionCardProps> = ({
  id,
  date,
  startTime,
  endTime,
  active,
  disabled,
  onClick
}) => {
  const isClickable = !active && !disabled && onClick;

  function handleClick() {
    isClickable && onClick && onClick(id);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      handleClick();
    }
  }

  return (
    <Container
      active={active}
      disabled={disabled}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={isClickable ? 0 : undefined}
    >
      <Date>{date}</Date>
      <Time>
        {startTime} - {endTime}
      </Time>
    </Container>
  );
};

export default SessionCard;
