import styled from 'styled-components';
import { getShadeColor } from 'helpers/ageLevel';
import { AGE_LEVEL_ABBREVIATIONS } from 'types/api/AgeLevel';

const AgeLevelTag = styled.span<{ abbreviation: AGE_LEVEL_ABBREVIATIONS }>`
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 4px;
  color: ${p => p.theme.colors['neutral'][900]};
  background-color: ${p => getShadeColor(p.abbreviation, 100)};
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-block;
`;

export default AgeLevelTag;
