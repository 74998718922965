const PATH = 'fe.pages.cohort_learning_pathway_product_key';

const messages = {
  startDateOn: {
    key: `${PATH}.start_date_on`,
    defaultValue: 'Start Date On'
  },
  startDate: {
    key: `${PATH}.start_date`,
    defaultValue: 'Start Date'
  },
  dateOfBirth: {
    key: `${PATH}.date_of_birth`,
    defaultValue: 'Date of Birth'
  },
  classroomAge: {
    key: `${PATH}.classroom_age`,
    defaultValue: 'Classroom Age'
  },
  infantToddler: {
    key: `${PATH}.infant_toddler`,
    defaultValue: 'Infant/Toddler'
  },
  preK: {
    key: `${PATH}.pre_k`,
    defaultValue: 'Pre-K'
  },
  month: {
    key: `${PATH}.month`,
    defaultValue: 'Month'
  },
  day: {
    key: `${PATH}.day`,
    defaultValue: 'Day'
  },
  year: {
    key: `${PATH}.year`,
    defaultValue: 'Year'
  },
  shippingAddress: {
    key: `${PATH}.shipping_address`,
    defaultValue: 'Shipping Address'
  },
  firstName: {
    key: `${PATH}.first_name`,
    defaultValue: 'First Name'
  },
  lastName: {
    key: `${PATH}.last_name`,
    defaultValue: 'Last Name'
  },
  address1: {
    key: `${PATH}.address1`,
    defaultValue: 'Address 1'
  },
  address2: {
    key: `${PATH}.address2`,
    defaultValue: 'Address 2'
  },
  optional: {
    key: `${PATH}.optional`,
    defaultValue: 'Optional'
  },
  country: {
    key: `${PATH}.country`,
    defaultValue: 'Country'
  },
  region: {
    key: `${PATH}.region`,
    defaultValue: 'State/Province'
  },
  locality: {
    key: `${PATH}.locality`,
    defaultValue: 'City'
  },
  postalCode: {
    key: `${PATH}.postal_code`,
    defaultValue: 'Postal Code'
  },
  signStudentAgreement: {
    key: `${PATH}.sign_student_agreement`,
    defaultValue: 'Sign Student Agreement'
  },
  successfullySigned: {
    key: `${PATH}.successfully_signed`,
    defaultValue: 'Successfully Signed'
  },
  continue: {
    key: `${PATH}.continue`,
    defaultValue: 'Continue'
  },
  imgAlts: {
    mobile: {
      key: `${PATH}.img_alts.mobile`,
      defaultValue: 'Two women working on a laptop'
    },
    desktop: {
      key: `${PATH}.img_alts.desktop`,
      defaultValue: 'A woman feeding a baby boy'
    }
  }
};

export default messages;
