import { any, isNil, isEmpty, append, join } from 'ramda';

export type Address = {
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
};

function isNilOrEmpty(value: any): boolean {
  return isNil(value) || isEmpty(value);
}

export function displayLocation(address: Address): boolean {
  return any(
    item => !isNilOrEmpty(item),
    [
      address.address_1,
      address.address_2,
      address.city,
      address.state,
      address.country
    ]
  );
}

export function formatCityAndState(address: Address): string | null {
  let options: (string | undefined)[] = [];

  if (!isNilOrEmpty(address.city)) {
    options = append(address.city, options);
  }

  if (!isNilOrEmpty(address.state)) {
    options = append(address.state, options);
  }

  if (isEmpty(options)) {
    return null;
  } else {
    return join(', ', options);
  }
}
