import axios from 'axios';

export function getUsers(params = {}) {
  return axios.get('/api/v2/users', { params });
}

export function getUser(id: number) {
  return axios.get(`/api/v2/users/${id}`);
}

export function createUser(user = {}) {
  return axios.post('/api/v2/users', { user });
}

export function updateUser(userId: number, user = {}) {
  return axios.put(`/api/v2/users/${userId}`, { user });
}

export function getMyClassUser(userId: number) {
  return axios.get(`/api/v2/users/${userId}/show_myclass`);
}

export function getCommunityUser(userId: number) {
  return axios.get(`/api/v1/community/${userId}`);
}

export function getMyteachstoneUser(userId: number) {
  return axios.get(`/api/v2/users/${userId}/show_myteachstone`);
}

export function login(user = {}) {
  return axios.post('/api/v1/users/sign_in', user);
}

export function logout() {
  return axios.delete('/api/v1/users/sign_out');
}

export function forgotPassword(params = {}) {
  return axios.post('/api/v1/users/password', params);
}

export function updatePassword(params = {}) {
  return axios.put('/api/v1/users/update_password', params);
}

export function setPassword(params = {}) {
  return axios.put('/api/v1/users/confirmation', params);
}

export function resetPassword(params = {}) {
  return axios.put('/api/v1/users/password', params);
}

export function getConfirmation(token = {}) {
  return axios.get('/api/v1/users/confirmation?confirmation_token=' + token);
}

export function lookupUser(params = {}) {
  return axios.post('/api/v2/users/lookup', params);
}

export function resetPasswordById(userId: number) {
  return axios.post(`/api/v2/users/${userId}/trigger_password_reset`);
}

export function uploadAvatar(payload = {}) {
  return axios.post('/api/v1/users/upload_avatar', payload);
}

export function getUserAccounts(id: number) {
  return axios.get(`/api/v2/users/${id}/accounts`);
}

/** Course Merchant */

export function createCMUser(payload = {}) {
  return axios.post('/api/v1/cm/users', payload);
}

export function loginCMUser(payload = {}) {
  return axios.post('/api/v1/cm/users/sign_in', payload);
}

export function getSisEnrollments(userId: number) {
  return axios.get(`/api/v2/users/${userId}/sis_enrollments`);
}

export function getLearningPathwaysEnrollments(userId: number) {
  return axios.get(`/api/v2/users/${userId}/learning_pathway_enrollments`);
}

export function suspendLearningPathwaysEnrollment(
  userId: number,
  learningPathwayId: number,
  reason_id?: number
) {
  return axios.delete(
    `/api/v2/users/${userId}/learning_pathway_enrollments/${learningPathwayId}?reason_id=${reason_id}`
  );
}

export function transferUserToLearningPathway(userId: number, payload = {}) {
  return axios.post(
    `/api/v2/users/${userId}/transfer_learning_pathway`,
    payload
  );
}
