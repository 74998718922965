import React from 'react';
import RouteHelpers from 'helpers/routes';
import { useAuth } from 'context/auth';
import { Navigate } from 'react-router-dom';
import { hasProductSubscription } from './utils';

interface SubscriptionProps {
  product: 'platform' | 'teachstone_admin' | 'data_collection';
  children: React.ReactNode;
}

function Subscription({ product, children }: SubscriptionProps) {
  const auth = useAuth();
  const hasSubscription = hasProductSubscription(
    auth.subscription_seats.active,
    product
  );

  return hasSubscription ? (
    <>{children}</>
  ) : (
    <Navigate replace to={RouteHelpers.getPath('app')} />
  );
}

export default Subscription;
