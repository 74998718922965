const PATH = 'fe.components.reports.reliability.charts.attempts_bar_chart';

const messages = {
  attempt: {
    key: `${PATH}.attempt`,
    defaultValue: 'Attempt {{value}} - {{total}} Tested'
  },
  passed: {
    key: `${PATH}.passed`,
    defaultValue: 'Passed'
  },
  reliable: {
    key: `${PATH}.reliable`,
    defaultValue: 'Reliable'
  },
  failed: {
    key: `${PATH}.failed`,
    defaultValue: 'Failed'
  },
  reliability: {
    header: {
      key: `${PATH}.reliability.header`,
      defaultValue: 'Participant Reliability by Attempt'
    }
  },
  knowledge: {
    header: {
      key: `${PATH}.knowledge.header`,
      defaultValue: 'Participant Knowledge by Attempt'
    }
  },
  tested: {
    key: `${PATH}.tested`,
    defaultValue: 'Tested'
  }
};

export default messages;
