import styled from 'styled-components';
import { Link } from 'react-router-dom';

const UnstyledButton = styled.button`
  font-family: inherit;
  display: block;
  color: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  text-align: left;
  background-color: transparent;
`;

export const WrapperButton = styled(UnstyledButton)<{ isOpen: boolean }>`
  width: 100%;
  margin-top: 24px;
  border-radius: 12px;
  padding: ${p => (p.isOpen ? '0px' : '12px')};
  color: ${p => p.theme.colors['scaleDarkBlue']};
  box-shadow: ${p => !p.isOpen && '1px 1px 11px #d7e6ed'};

  @media screen and (min-width: 768px) {
    padding: 12px;
  }

  button&:hover {
    cursor: pointer;
  }
`;

export const CloseButton = styled(UnstyledButton)`
  display: inline-flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const IconWrapper = styled.span`
  font-size: 16px;

  @media screen and (min-width: 1024px) {
    font-size: 22px;
  }
`;

export const Table = styled.table`
  width: 100%;
  font-size: 14px;
  margin-top: 24px;
  border-collapse: collapse;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    margin-top: 32px;
  }
`;

export const CollapsedCell = styled.td`
  width: 1px;
  white-space: nowrap;
`;

export const Abbreviation = styled.span<{ bgColor: string }>`
  padding: 4px;
  font-weight: 700;
  text-align: center;
  display: inline-block;

  @media screen and (min-width: 640px) {
    padding: 8px 12px;
  }

  @media screen and (min-width: 1024px) {
    width: 72px;
    padding: 8px 0;
    border-radius: 12px;
    background-color: ${p => p.bgColor};
  }
`;

export const AbbreviationIcon = styled.span`
  margin-left: 8px;
  margin-right: 16px;
  color: #e9e9e9;
  display: none;

  @media screen and (min-width: 1024px) {
    display: inline;
  }
`;

type TableCellProps = {
  isFirstRow?: boolean;
  isFirstColumn?: boolean;
  isLastRow?: boolean;
  isLastColumn?: boolean;
};

export const TableCell = styled.td<TableCellProps>`
  padding: 4px;
  text-align: center;
  background-color: #f4f8fa;
  border: 1px solid #e0e0e0;
  border-top: ${p => p.isFirstRow && '0'};
  border-bottom: ${p => p.isLastRow && '0'};
  border-left: ${p => p.isFirstColumn && '0'};
  border-right: ${p => p.isLastColumn && '0'};
  border-top-left-radius: ${p => p.isFirstRow && p.isFirstColumn && '12px'};
  border-top-right-radius: ${p => p.isFirstRow && p.isLastColumn && '12px'};
  border-bottom-left-radius: ${p => p.isLastRow && p.isFirstColumn && '12px'};
  border-bottom-right-radius: ${p => p.isLastRow && p.isLastColumn && '12px'};

  @media screen and (min-width: 640px) {
    padding: 8px 12px;
  }

  @media screen and (min-width: 1024px) {
    padding: 16px 24px;
  }
`;

export const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const HeaderCell = styled(TableCell)<{ isCompleted: boolean }>`
  font-weight: 700;
  background-color: ${p => !p.isCompleted && p.theme.colors['primary']['700']};
  border-color: ${p => !p.isCompleted && p.theme.colors['primary']['700']};

  @media screen and (min-width: 768px) {
    background-color: #f4f8fa;
    border-color: #e0e0e0;
  }

  @media screen and (min-width: 1024px) {
    padding: ${p => !p.isCompleted && '12px 20px'};
  }
`;

export const CycleLink = styled(Link)`
  color: white;

  @media screen and (min-width: 768px) {
    width: 32px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${p => p.theme.colors['primary']['700']};
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.25);
  }

  @media screen and (min-width: 1280px) {
    width: 40px;
    height: 40px;
  }

  &:hover {
    color: white;
  }
`;
