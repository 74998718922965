import moment from 'moment';
import { Session } from 'types/api/Session';

export function formatSession(session: Session): string {
  const fDate = moment(session.starts_at).format('LL');
  const fStartTime = moment(session.starts_at).local().format('LT');
  const fEndTime = moment(session.ends_at).local().format('LT');

  return `${fDate} ${fStartTime} - ${fEndTime}`;
}
