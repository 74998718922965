import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { update } from 'store/slices/user';
import { Button } from 'semantic-ui-react';
import { toastSuccess } from 'components/Toast';
import useCurrentUser from 'hooks/useCurrentUser';
import { useUserJobs } from 'components/dropdowns/UserJobs';
import { updateUserMe, updateUserMeJobs } from 'actions/userMe';
import { Section, SectionTitle, ButtonWrapper } from './Styled';
import { useClassFamiliarity } from 'components/dropdowns/ClassFamiliarity';

import {
  Formik,
  Form,
  Select,
  PreventNavigation
} from 'components/EnhancedForm';

function Experience() {
  const { run, isLoading } = useAsync();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const userJobsOptions = useUserJobs();
  const classFamiliarityOptions = useClassFamiliarity();

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    run(
      Promise.all([
        updateUserMe({ class_experience: values.class_experience }),
        updateUserMeJobs({ jobs: values.jobs })
      ]),
      {
        onSuccess: () => {
          dispatch(update({ ...currentUser, ...values }));
          toastSuccess();
          formik.resetForm({ values });
        }
      }
    );
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        jobs: currentUser.jobs,
        class_experience: currentUser.class_experience
      }}
    >
      <Form>
        <PreventNavigation />

        <Section>
          <SectionTitle>{i18n.ft(messages.title)}</SectionTitle>

          <Select
            multiple
            name="jobs"
            options={userJobsOptions}
            label={i18n.ft(messages.roles)}
          />

          <Select
            name="class_experience"
            options={classFamiliarityOptions}
            label={i18n.ft(messages.familiarity)}
          />
        </Section>

        <ButtonWrapper>
          <Button
            color="blue"
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            content={i18n.ft(messages.save)}
          />
        </ButtonWrapper>
      </Form>
    </Formik>
  );
}

export default Experience;
