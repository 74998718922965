const PATH =
  'fe.components.trainings.view.participants.reinvite_declined_modal';

const messages = {
  areYouSure: {
    key: `${PATH}.are_you_sure`,
    defaultValue:
      'Are you sure you want to invite these declined participant(s) again?'
  },
  dismiss: {
    key: `${PATH}.dismiss`,
    defaultValue: 'Dismiss'
  },
  confirm: {
    key: `${PATH}.confirm`,
    defaultValue: 'Yes, Invite Participant(s)'
  }
};

export default messages;
