import axios from 'axios';

const KalturaAPI = axios.create({
  baseURL: 'https://www.kaltura.com/api_v3/service/uploadtoken/action'
});

export function getUploadToken(clientToken: string) {
  return KalturaAPI.post(`/add?ks=${clientToken}&format=1`);
}

export function uploadVideoWithToken(
  clientToken: string,
  uploadToken: string,
  videoFile: File,
  config = {}
) {
  const data = new FormData();
  data.append('fileData', videoFile);

  // Workaround for tests since msw does not support onUploadProgress events.
  // More info on: https://github.com/mswjs/msw/issues/1101
  const reqConfig = process.env.NODE_ENV === 'test' ? {} : config;

  return KalturaAPI.post(
    `/upload?ks=${clientToken}&uploadTokenId=${uploadToken}&format=1&resume=false&finalChunk=true&resumeAt=-1`,
    data,
    reqConfig
  );
}
