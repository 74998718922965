import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  padding: 4px 14px;
`;

export const DateSpan = styled.span`
  font-weight: 700;
  padding: 4px 8px;
  border-radius: 50%;
  background-color: #f4f8fa;
`;

export const OffsetIcon = styled.div`
  margin-bottom: -2px;
`;
