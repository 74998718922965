import { useEffect } from 'react';
import { logVideoPlayStarted } from 'actions/testAttempts';
import { AspectRatio } from 'components/KalturaVideo/Styled';
import { getConfiguration } from 'pages/Testing/Reliability/utils';
import { VideoPlayerContainer } from '../ReliabilityTest/CodingVideos/Styled';

type ShowKTResourceProps = {
  imageURL?: string | undefined | null;
  isTablet: boolean;
  kalturaPlayerID: string;
  resourceId: number;
  testAttemptId: number;
  videoHostId?: string | undefined | null;
};

function ShowKTResource({
  imageURL,
  isTablet,
  kalturaPlayerID,
  resourceId,
  testAttemptId,
  videoHostId
}: ShowKTResourceProps) {
  /* set up the video player */
  useEffect(() => {
    if (!videoHostId) {
      return;
    }
    /* configure a video player */
    const kalturaPlayer = (window as any).KalturaPlayer?.setup(
      getConfiguration(kalturaPlayerID)
    );

    /* load the video */
    kalturaPlayer.loadMedia({ entryId: videoHostId });

    /* listen for the first play event and log it */
    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.FIRST_PLAYING,
      (e: any) => {
        logVideoPlayStarted({
          testAttemptId,
          codingVideoId: resourceId
        });
      }
    );

    /* clean up */
    return () => {
      const kalturaPlayer = (window as any).KalturaPlayer?.getPlayer(
        kalturaPlayerID
      );
      kalturaPlayer?.destroy();
    };
  }, [testAttemptId, kalturaPlayerID, videoHostId, resourceId]);

  if (videoHostId) {
    return (
      <>
        <AspectRatio />
        <VideoPlayerContainer id={kalturaPlayerID} isTablet={isTablet} />
      </>
    );
  }

  if (imageURL) {
    return <img src={imageURL} alt={''} className="w-full" />;
  }

  return null;
}

export default ShowKTResource;
