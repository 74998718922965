import moment from 'moment';
import { Link } from 'react-router-dom';

export function composeUser(userId: number, name: string, email?: string) {
  return (
    <span>
      <Link
        to={`/organization/members/${userId}`}
        className="font-bold text-black underline hover:text-[#41193D] hover:underline"
      >
        {name}
      </Link>
      {email ? <div>{email}</div> : null}
    </span>
  );
}

export function composeHierarchy(hierarchy: string[][]) {
  if (hierarchy.length === 0) {
    return null;
  }

  const list = hierarchy.map((nodes, idx) => {
    if (nodes.length === 0) {
      return null;
    }

    if (nodes.length === 1) {
      return (
        <span key={idx} className="font-semibold">
          {nodes[0]}
        </span>
      );
    }

    const lastNode = nodes.at(-1);

    return (
      <span key={idx}>
        {nodes.slice(0, -1).join(' / ') + ' / '}
        <span className="font-semibold">{lastNode}</span>
      </span>
    );
  });

  return <span className="flex flex-col gap-1">{list}</span>;
}

export function formatDate(date?: string) {
  if (!date) {
    return '';
  }

  return moment(date).format('MM/DD/YYYY');
}
