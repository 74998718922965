import { useCallback, useEffect, useState } from 'react';
import { Select } from 'semantic-ui-react';
import i18n from 'helpers/i18n';
import { StyledPopup } from './Styled';
import WarningIcon from 'images/recertificationProductKey/warning.svg';
import CloseIcon from 'images/recertificationProductKey/close.svg';
import { CertificationItem } from 'types/api/CertificationItemProductKey';
import messages from './messages';
import { formatCertification, getVersions, getAgeLevelGroup } from './utils';
import * as R from 'ramda';
import { IconInfo } from 'components/icons';

interface ChooseChooseLanguageProps {
  certifications: {
    recertification: CertificationItem[];
    transition: CertificationItem[];
  };
  selectedCertification: CertificationItem;
  updateCertificationLanguage: (language: string) => void;
}

type LanguageOptions = {
  key: string;
  value: string;
  text: string;
};

function ChooseLanguage({
  certifications,
  selectedCertification,
  updateCertificationLanguage
}: ChooseChooseLanguageProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const ageLevelGroup = getAgeLevelGroup(selectedCertification.name);
  const version = getVersions(selectedCertification.name);
  const [languageOptions, setLanguageOptions] = useState<LanguageOptions[]>([]);
  const [certificationLanguagesRaw, setCertificationLanguagesRaw] = useState<
    string[]
  >([]);

  const getCertificationMatch = useCallback(
    (certifications: CertificationItem[]) => {
      return certifications.find(
        cert => cert.test_template_id === selectedCertification.test_template_id
      );
    },
    [selectedCertification]
  );

  useEffect(() => {
    const certificationMatch =
      selectedCertification.type === 'recertification'
        ? getCertificationMatch(certifications.recertification)
        : getCertificationMatch(certifications.transition);

    if (!certificationMatch) {
      return;
    }

    const certificationLanguages = getCertificationLanguages(
      certifications,
      certificationMatch.group,
      certificationMatch.name
    );

    setCertificationLanguagesRaw(certificationLanguages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCertification]);

  useEffect(() => {
    const certificationLanguages = certificationLanguagesRaw.map(language => {
      return {
        key: language,
        value: language,
        text: language
      };
    });
    setLanguageOptions(certificationLanguages);
  }, [certificationLanguagesRaw]);

  function getCertificationLanguages(
    certifications: {
      recertification: CertificationItem[];
      transition: CertificationItem[];
    },
    group: string,
    name: string
  ) {
    let certificationLanguages = [];

    if (selectedCertification.type === 'recertification') {
      certificationLanguages = certifications.recertification.flatMap(
        certification => {
          return getAgeLevelGroupMatch(ageLevelGroup, certification.name) &&
            group === certification.group &&
            R.equals(version, getVersions(certification.name))
            ? certification.language
            : [];
        }
      );
    } else {
      certificationLanguages = certifications.transition.flatMap(
        certification => {
          return getAgeLevelGroupMatch(ageLevelGroup, certification.name) &&
            name === certification.name
            ? certification.language
            : [];
        }
      );
    }

    return certificationLanguages;
  }

  function getAgeLevelGroupMatch(
    ageLevelGroup: string | string[],
    certificationName: string
  ) {
    return R.equals(ageLevelGroup, getAgeLevelGroup(certificationName));
  }

  function showInputwithSingleLanguage() {
    setTimeout(
      () => updateCertificationLanguage(languageOptions[0]?.text),
      300
    );
    return (
      <div className="rounded w-full bg-gray-200 border border-2 p-4 mt-1">
        {languageOptions[0]?.text}
      </div>
    );
  }

  function handleOpen() {
    setIsPopupOpen(true);
  }

  function handleClose() {
    setIsPopupOpen(false);
  }

  return (
    <div className="rounded bg-white p-4 my-4">
      <div className="mt-4 flex items-center">
        <div className="text-xl font-bold mr-4">{i18n.ft(messages.title)}</div>
        <IconInfo />
      </div>
      <div className="mb-4 text-lg">
        {formatCertification(selectedCertification)}
      </div>
      <div className="my-4">
        <div className="flex items-center">
          <div className="text-xl font-bold mr-4">
            {i18n.ft(messages.video)}
          </div>
          <StyledPopup
            size="mini"
            on="click"
            open={isPopupOpen}
            onClose={handleClose}
            onOpen={handleOpen}
            pinned
            content={
              <div className="grid grid-cols-7 gap-1">
                <div className="col-span-1">
                  <img src={WarningIcon} alt="warning" />
                </div>
                <div className="col-span-6">
                  {i18n.ft(messages.info)}
                  <img
                    src={CloseIcon}
                    alt="close"
                    className="float-right cursor-pointer"
                    onClick={handleClose}
                    style={{
                      top: '-60px',
                      position: 'relative',
                      right: '-25px'
                    }}
                  />
                </div>
              </div>
            }
            trigger={
              <div onClick={handleOpen}>
                <IconInfo />
              </div>
            }
          />
        </div>

        {languageOptions.length <= 1 ? (
          showInputwithSingleLanguage()
        ) : (
          <Select
            className="w-full"
            options={languageOptions}
            onChange={(_, data) => {
              if (data.value !== undefined) {
                updateCertificationLanguage(data.value.toString());
              }
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ChooseLanguage;
