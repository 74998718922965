import React from 'react';
import PropTypes from 'prop-types';
import RouteHelpers from 'helpers/routes';
import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

const StyledMenuItem = styled(Menu.Item)`
  font-size: 14px;

  &.active {
    color: #ffffff !important;
    font-weight: bold !important;
    background-color: ${props => props.theme.colors.blue} !important;

    &::after {
      z-index: 1 !important;
      background-color: ${props => props.theme.colors.blue} !important;
      border-color: ${props => props.theme.colors.blue} !important;
    }
  }
`;

function MenuLink({ children, content, route, params, external }) {
  if (external) {
    return (
      <StyledMenuItem className="item" as="a" href={external} target="_blank">
        {content || children}
      </StyledMenuItem>
    );
  }

  return (
    <StyledMenuItem
      className="item"
      as={NavLink}
      to={RouteHelpers.getPath(route, params)}
    >
      {content || children}
    </StyledMenuItem>
  );
}

MenuLink.propTypes = {
  content: PropTypes.node,
  children: PropTypes.node,
  external: PropTypes.string,
  route: PropTypes.string,
  params: PropTypes.object
};

export default MenuLink;
