import styled from 'styled-components';
import { Button, Header } from 'semantic-ui-react';

export const CancelModalButton = styled(Button)<{ $knowledgeTest?: boolean }>`
  color: ${p => p.theme.colors['scaleDarkBlue']} !important;
  background-color: ${p => p.theme.colors['white']}!important;
  border: 1px solid #3c3f42 !important;
  border-radius: 4px;
  margin-right: 5px !important;
  margin-left: 0px !important;
  margin-bottom: 10px !important;

  @media screen and (min-width: 640px) {
    margin-bottom: ${p => (p.$knowledgeTest ? '15px' : '0px')} !important;
  }
`;

export const ModalButton = styled(Button)<{ $isTestIncomplete?: boolean }>`
  color: white !important;
  background-color: ${p => p.theme.colors['scaleBlue']} !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  display: ${p => (p.$isTestIncomplete ? 'none!important' : 'inherit')};
`;

export const ModalHeader = styled(Header)`
  color: ${p => p.theme.colors['scaleDarkBlue']}!important;
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
`;

export const ModalAction = styled.div<{ $knowledgeTest?: boolean }>`
  padding: 2rem 1rem 4rem;
  display: block;

  @media screen and (min-width: 640px) {
    display: ${p => (p.$knowledgeTest ? 'block' : 'flex')};
  }
`;

export const ModalIcon = styled.img`
  margin-top: -1.5rem;
  width: 94px;
`;
