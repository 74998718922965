import React from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';

type SIZES = 'medium' | 'large';

const Container = styled.div<{ split?: boolean; size: SIZES }>`
  display: ${props => (props.split ? 'flex' : 'block')};
  font-size: ${props => props.theme.typography.fontSize[props.size]};
  justify-content: ${props => (props.split ? 'space-between' : 'initial')};
`;

interface MetadataProps {
  /** The content of the label. */
  label: string;

  /** The value for the given label. */
  value: string | number;

  /** If `true`, the label and value will be aligned to the edges. */
  split?: boolean;

  /** The size of the content. */
  size?: SIZES;
}

const Metadata: React.FC<MetadataProps> = ({
  label,
  value,
  split,
  size = 'medium'
}) => {
  return (
    <Container split={split} size={size}>
      <Typography bold>{label + ':'}</Typography>
      <Typography prepend>{value}</Typography>
    </Container>
  );
};

export default Metadata;
