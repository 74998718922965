import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import React, { useEffect, useRef, useState } from 'react';
import { requestPaymentForTraining } from 'actions/trainings';

import {
  InputLabel,
  InputError,
  SuccessContent,
  SuccessActions
} from './Styled';

import {
  Header,
  Button,
  Input,
  InputProps,
  Modal,
  ModalProps,
  Icon
} from 'semantic-ui-react';

interface RequestPaymentModalProps extends ModalProps {
  /** Callback fired when the modal requests to be closed. */
  onClose: () => void;

  /** Callback fired after requesting payment for the participants.  */
  onSuccess: () => void;

  /** Array of participant Ids to request payment for. */
  participantIds: number[];

  /** Training ID. */
  trainingId: number;
}

const RequestPaymentModal: React.FC<RequestPaymentModalProps> = ({
  onSuccess,
  trainingId,
  participantIds,
  ...props
}) => {
  const inputRef = useRef<Input>(null);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const { run, reset, isLoading, isSuccess } = useAsync();

  useEffect(() => {
    if (props.open) {
      inputRef?.current?.focus();
    }
  }, [props.open]);

  function handleSubmit() {
    const isValid = yup.string().required().email().isValidSync(email);

    if (isValid) {
      setError(false);
      run(
        requestPaymentForTraining(trainingId, {
          email,
          participant_ids: participantIds
        }),
        { onSuccess: () => onSuccess() }
      );
    } else {
      setError(true);
    }
  }

  function handleChange(
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputProps
  ) {
    setEmail(data.value);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (isLoading) {
      return;
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  }

  function resetState() {
    reset();
    setEmail('');
    setError(false);
  }

  return (
    <Modal size="small" onUnmount={resetState} {...props}>
      {isSuccess ? (
        <React.Fragment>
          <Modal.Content>
            <SuccessContent>
              <Header icon>
                <Icon name="check circle" color="green" />
                {i18n.ft(messages.success)}
                <Header.Subheader>
                  {i18n.ft(messages.emailSent, { email })}
                </Header.Subheader>
              </Header>

              <SuccessActions>
                <Button
                  onClick={props.onClose}
                  content={i18n.ft(messages.return)}
                />
              </SuccessActions>
            </SuccessContent>
          </Modal.Content>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Modal.Content>
            <Header
              size="small"
              content={i18n.ft(messages.title)}
              subheader={i18n.ft(messages.subtitle)}
            />
            <InputLabel htmlFor="email">{i18n.ft(messages.email)}</InputLabel>
            <Input
              fluid
              id="email"
              value={email}
              error={error}
              ref={inputRef}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder={i18n.ft(messages.example)}
            />
            {error && <InputError>{i18n.ft(messages.error)}</InputError>}
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={isLoading}
              onClick={props.onClose}
              content={i18n.ft(messages.cancel)}
            />
            <Button
              color="green"
              loading={isLoading}
              disabled={isLoading}
              onClick={handleSubmit}
              content={i18n.ft(messages.sendRequest)}
            />
          </Modal.Actions>
        </React.Fragment>
      )}
    </Modal>
  );
};

export default RequestPaymentModal;
