import axios from './axiosConfig';
import { Assessment } from 'types/api/envScales/Assessment';
import { AssessmentScore } from 'types/api/envScales/AssessmentScore';

// GET /api/v1/assessments/:id
type GetParams = {
  expanded?: boolean;
};

export function getAssessment(id: number, params: GetParams = {}) {
  return axios.get<Assessment>(`/api/v1/assessments/${id}`, { params });
}

// GET /api/v1/assessments/:id/scores
export function getAssessmentScores(id: number) {
  return axios.get<AssessmentScore[]>(`/api/v1/assessments/${id}/scores`);
}

// POST /api/v1/assessments/:id/scores
export function updateAssessmentScore(
  id: number,
  params: { scale_id: number; score: 0 | 1 | 2 | 3 }
) {
  return axios.post(`/api/v1/assessments/${id}/scores`, params);
}

// GET /api/v1/assessments
export function getAssessments() {
  return axios.get<Assessment[]>('/api/v1/assessments');
}

// GET search assessments
export function searchAssessments(params = {}) {
  return axios.get<Assessment[]>('/api/v1/assessments', { params });
}

// GET /api/v1/assessments/summary
export function getAssessmentsSummary(params = {}) {
  return axios.get('/api/v1/assessments/summary', { params });
}

// POST /api/v1/assessments
export function createAssessment(assessment = {}) {
  return axios.post('/api/v1/assessments', { assessment });
}

// PUT /api/v1/assessments/:id
export function updateAssessment(assessmentId: number, assessment = {}) {
  return axios.put(`/api/v1/assessments/${assessmentId}`, { assessment });
}

//PUT /api/v1/assessments/:id/submit
export function submitAssessment(assessmentId: number) {
  return axios.put(`api/v1/assessments/${assessmentId}/submit`);
}

//PUT /api/v1/assessments/:id/request_update
export function requestUpdateAssessment(assessmentId: number, payload = {}) {
  return axios.put(
    `api/v1/assessments/${assessmentId}/request_update`,
    payload
  );
}

//PUT /api/v1/assessments/:id/approve
export function approveAssessment(assessmentId: number) {
  return axios.put(`api/v1/assessments/${assessmentId}/approve`);
}

//PUT /api/v1/assessments/:id/return_to_awaiting_approval
export function returnAssessmentToAwaiting(assessmentId: number) {
  return axios.put(
    `api/v1/assessments/${assessmentId}/return_to_awaiting_approval`
  );
}

//PUT /api/v1/assessments/:id/return_to_in_progress
export function returnAssessmentToInProgress(assessmentId: number) {
  return axios.put(`api/v1/assessments/${assessmentId}/return_to_in_progress`);
}

// DELETE /api/v1/assessments/:id
export function deleteAssessment(assessmentId: number) {
  return axios.delete(`/api/v1/assessments/${assessmentId}`);
}

//PUT /api/v1/assessments/approve_multiple
export function approveMultipleAssessments(assessmentIds: number[]) {
  return axios.put(`api/v1/assessments/approve_multiple`, {
    assessment_ids: assessmentIds
  });
}
