const PATH = 'fe.components.trainings.view.card_checkout.address_preview';

const messages = {
  email: {
    key: `${PATH}.email`,
    defaultValue: `Email address`
  }
};

export default messages;
