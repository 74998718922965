const PATH = 'fe.components.reports.observation.collapsible_configuration_form';

const messages = {
  completedObservations: {
    key: `${PATH}.completed_observations`,
    defaultValue: 'Completed observations matching your selections'
  },
  fields: {
    time: {
      key: `${PATH}.fields.time`,
      defaultValue: 'Time selection'
    },
    ageLevel: {
      key: `${PATH}.fields.age_level`,
      defaultValue: 'Age Level'
    },
    ageLevelPlaceholder: {
      key: `${PATH}.fields.age_level_placeholder`,
      defaultValue: 'Select an age level'
    },
    hierarchy: {
      key: `${PATH}.fields.hierarchy`,
      defaultValue: 'Hierarchy'
    },
    classVersion: {
      key: `${PATH}.fields.class_version`,
      defaultValue: 'CLASS Version'
    },
    classVersionPlaceholder: {
      key: `${PATH}.fields.class_version_placeholder`,
      defaultValue: 'Select CLASS version'
    }
  }
};

export default messages;
