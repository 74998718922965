import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import Browser from 'helpers/browser';
import RouteHelpers from 'helpers/routes';
import { Loader } from 'semantic-ui-react';
import Spacer from 'components/Spacer';
import { useNavigate } from 'react-router-dom';
import { Wrapper, HiddenIframe } from './Styled';

function LogoutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      const returnTo = Browser.getCookieValue('return_after_logout') || '';

      if (R.isEmpty(returnTo)) {
        navigate('/users/login', { replace: true });
      } else {
        window.location.href = decodeURIComponent(returnTo);
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate]);

  return (
    <Wrapper>
      <h3>{i18n.ft(messages.loggingOut)}</h3>
      <Spacer size={24} />
      <Loader active size="massive" inline="centered" />

      <HiddenIframe
        title="single logout iframe"
        src={RouteHelpers.getPath('single-logout')}
      />
    </Wrapper>
  );
}

export default LogoutPage;
