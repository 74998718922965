import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Link } from 'react-router-dom';
import { AgeLevelTag } from 'components/AgeLevel';
import { CardContainer, CardAgeLevels, CardTitle, NoAgeLevels } from './Styled';
import { TrainingTemplate } from 'types/api/TrainingTemplate';

interface TemplateCardProps {
  template: TrainingTemplate;
}

function TemplateCard({ template }: TemplateCardProps) {
  return (
    <CardContainer
      as={Link}
      to={RouteHelpers.getPath('td-catalog-new', { id: template.id })}
    >
      <CardTitle title={template.title}>{template.title}</CardTitle>
      <CardAgeLevels>
        {template.age_levels.map(level => (
          <AgeLevelTag
            key={level.abbreviation}
            abbreviation={level.abbreviation}
          >
            {level.name}
          </AgeLevelTag>
        ))}

        {template.age_levels.length === 0 && (
          <NoAgeLevels>{i18n.ft(messages.ageNeutral)}</NoAgeLevels>
        )}
      </CardAgeLevels>
    </CardContainer>
  );
}

export default TemplateCard;
