import { isEmpty } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import queryString from 'query-string';

interface ShowTestPrepLinkProps {
  label: string;
  message: string;
  renewable: boolean | undefined;
  testPreparationTemplateId?: number;
  currentAttemptId?: number | null;
  currentAttemptNumber?: number | null;
  transitionedTo2ndEdition?: boolean;
}

function ShowTestPrepLink({
  label,
  message,
  renewable,
  testPreparationTemplateId,
  currentAttemptId,
  currentAttemptNumber,
  transitionedTo2ndEdition
}: ShowTestPrepLinkProps) {
  if (transitionedTo2ndEdition) {
    return null;
  }

  if (
    !isEmpty(label) &&
    !isEmpty(message) &&
    renewable &&
    testPreparationTemplateId
  ) {
    return (
      <>
        <span className="mx-2">•</span>
        <a
          className="font-bold"
          href={
            currentAttemptId && currentAttemptNumber
              ? queryString.stringifyUrl({
                  url: `${RouteHelpers.getPath('test-template-resources', {
                    id: testPreparationTemplateId
                  })}`,
                  query: {
                    attempt_number: currentAttemptNumber,
                    attempt_id: currentAttemptId
                  }
                })
              : RouteHelpers.getPath('test-template-resources', {
                  id: testPreparationTemplateId
                })
          }
          rel="noopener noreferrer"
        >
          {i18n.ft(messages.practicing)}
        </a>
      </>
    );
  }
  return null;
}

export default ShowTestPrepLink;
