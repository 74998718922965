import { AccountAssessmentTemplate } from 'types/api/envScales/AccountAssessmentTemplate';
import { Observer } from 'types/api/Observer';
type selectOption = {
  key: number;
  value: number | string;
  text: string;
};

export function getTemplatesOptions(templates: any[]) {
  const templateOption: selectOption[] = [];
  if (templates) {
    templates.forEach((element: AccountAssessmentTemplate) => {
      templateOption.push({
        key: element.assessment_template.id,
        value: element.assessment_template.id,
        text: element.assessment_template.name
      });
    });
  }
  return templateOption;
}

export function getObserverOptions(observers: Observer[] = []) {
  if (!observers) {
    return [];
  }

  return observers.map(observer => ({
    key: observer.guid,
    value: observer.guid,
    text: observer.name
  }));
}

type Node = {
  id: number;
  name: string;
  type: string;
  children: Node[];
};

export type Account = {
  hierarchy: Node;
};

type HierarchyData = {
  label: string;
  value: number;
  children: HierarchyData[];
};

export function buildHierarchy(
  nodes: Node[],
  initialValues: number[] = []
): HierarchyData[] {
  return nodes.map(node => ({
    checked: initialValues.includes(node.id),
    label: node.name,
    value: node.id,
    children: buildHierarchy(node.children)
  }));
}
