import React, { useContext } from 'react';
import Typography from 'components/Typography';
import TrainingContext from '../TrainingContext';
import i18n from 'helpers/i18n';
import messages from './messages';
import { daysToGo, getThemeColor } from './utils';
import { COMPLETED } from 'constants/trainingStatus';
import {
  BoxContainer,
  InlineContainer,
  NumberBubble,
  StyledIcon
} from './Styled';
import { isNil } from 'ramda';

function Countdown({ inline }) {
  const { training } = useContext(TrainingContext);

  if (isNil(training.start_date)) {
    return null;
  }

  const days = Math.max(daysToGo(training), 0);

  const Container = inline ? InlineContainer : BoxContainer;

  if (training.status === COMPLETED) {
    return (
      <Container>
        <StyledIcon
          display={inline ? 'inline' : 'block'}
          name="check"
          color="green"
          circular
          inverted
          size={inline ? null : 'huge'}
        />
        <Typography content={i18n.ft(messages.completed)} bold size="small" />
      </Container>
    );
  }

  const size = inline ? 'small' : null;

  return (
    <Container>
      <NumberBubble
        color={getThemeColor(days)}
        size={size}
        display={inline ? 'inline' : 'block'}
      >
        <NumberBubble.Text
          color="white"
          content={days}
          textAlign="center"
          fluid
          size={size}
          bold
        />
      </NumberBubble>
      <Typography
        content={i18n.ft(messages.daysToGo, { count: days })}
        bold
        size="small"
        prepend={inline}
      />
    </Container>
  );
}

export default Countdown;
