import React from 'react';

interface StaticPromptProps {
  prompt: string;
  title: string;
}

function StaticPrompt({ prompt, title }: StaticPromptProps) {
  return (
    <div className="mb-8 rounded-lg bg-[#F4F8FA] text-[#364A5E] p-4 basis-1/3 h-fit">
      <h3 className="text-base">{title}</h3>
      {prompt}
    </div>
  );
}

export default StaticPrompt;
