import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import moment from 'moment';
import useAsync from 'hooks/useAsync';
import i18n from 'helpers/i18n';
import { Flex } from 'components/Flex';
import { Box } from 'components/Box';
import {
  getAttachments,
  uploadAttachment,
  deleteAttachment,
  getKalturaClientToken,
  attachVideo,
  deleteVideo
} from 'actions/envScales/observations';
import {
  getUploadToken,
  uploadVideoWithToken
} from 'actions/envScales/kaltura';
import { isEmpty } from 'ramda';
import { SectionCollapse } from 'components/SectionCollapse';
import { Attachment } from 'types/api/envScales/Attachment';
import { VideoAttachment } from 'types/api/envScales/Observation';
import { Assessment } from 'types/api/envScales/Assessment';
import {
  Indicator,
  DeleteIcon,
  Title,
  UploadIcon,
  UploadButtonWrapper,
  TD,
  TR,
  Table,
  StyledIcon,
  StyledProgress,
  AttachmentName,
  StatusText,
  AttachmentDate,
  FileDetails,
  DeleteButtonWrapper,
  Errors,
  SectionWithDashedBorder,
  DeleteButton,
  PreviewWrapper
} from './Styled';
import { Button } from 'semantic-ui-react';
import ResponsiveContent from 'components/ResponsiveContent';
import messages from './messages';
import { isVideo } from 'helpers/file';
import {
  STATUS_COLORS,
  STATUS_ICONS,
  STATUS_TEXT,
  STATUS_TEXT_COLORS,
  getPreview,
  formatUploadingAttachment,
  formatSavedVideo,
  AttachmentWithStatus
} from './utils';

const MAX_FILE_SIZE = 50.0; // Size in megabytes

interface AttachmentProps {
  assessment: Assessment;
  sectionWithRoundedBorder?: boolean;
}

const Attachments = forwardRef(
  ({ assessment, sectionWithRoundedBorder }: AttachmentProps, ref) => {
    const { observation } = assessment;
    const [errors, setErrors] = useState<Array<String>>([]);
    const [sectionWithErrors, setSectionWithErrors] = useState<boolean | null>(
      null
    );
    const [uploadedAttachments, setUploadedAttachments] = useState<
      Array<AttachmentWithStatus>
    >([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(1);
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const { run } = useAsync();
    const observationId = observation!.id;
    const assessmentId = assessment.id;

    useImperativeHandle(ref, () => ({
      valid() {
        return valid();
      }
    }));

    function valid() {
      const isValid = uploadedAttachments.length > 0;
      setSectionWithErrors(!isValid);
      return isValid;
    }

    useEffect(() => {
      run(getAttachments(observation!.id), {
        onSuccess: (data: any) => {
          const attachments = data.attachments.map((a: Attachment) => {
            return { ...a, status: 'ok' };
          });
          const videoAttachments = observation!.video_attachments.map(
            (video: VideoAttachment) => formatSavedVideo(video, assessmentId)
          );
          setUploadedAttachments([...attachments, ...videoAttachments]);
        }
      });
    }, [run, observation, assessmentId]);

    useEffect(() => {
      if (sectionWithErrors !== null) {
        const isMissingAttachments = uploadedAttachments.length === 0;
        setSectionWithErrors(isMissingAttachments);
      }
    }, [sectionWithErrors, uploadedAttachments]);

    function onFileChoose() {
      hiddenFileInput.current?.click();
    }

    function onFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
      const file = event.currentTarget!.files![0];

      const errors = validateFile(file);
      if (!isEmpty(errors)) {
        setErrors(errors);
      } else {
        handleUpload(file);
      }
    }

    function validateFile(file: File): string[] {
      if (isVideo(file.type)) return [];

      let errors = [];
      if (file.size / 1024 / 1024 > MAX_FILE_SIZE) {
        errors.push('File size cannot be greater than 50.00 MB');
      }
      return errors;
    }

    async function handleVideoUpload(file: File) {
      const attachment: AttachmentWithStatus = formatUploadingAttachment(file);
      const attachments = uploadedAttachments.concat(attachment);
      setUploadedAttachments(attachments);

      const clientTokenResponse = await getKalturaClientToken(observationId);
      const clientToken = clientTokenResponse.data.client_token;
      const uploadTokenResponse = await getUploadToken(clientToken);
      const uploadToken = uploadTokenResponse.data.id;

      try {
        setUploading(true);

        const uploadConfig = {
          onUploadProgress: (event: any) => {
            setProgress(Math.round((event.loaded / event.total) * 100));
          },
          cancelToken: attachment.cancelTokenSource!.token
        };
        await uploadVideoWithToken(
          clientToken,
          uploadToken,
          file,
          uploadConfig
        );
        const response = await attachVideo(observationId, {
          upload_token: uploadToken,
          name: file.name
        });

        const attachments = uploadedAttachments
          .filter(a => a.status !== 'uploading')
          .concat({
            ...attachment,
            ...response.data,
            status: 'ok',
            content_type: file.type
          });

        setUploadedAttachments(attachments);
        setUploading(false);
      } catch (error) {
        cancelUpload(attachment);
      }
    }

    function handleImageAndPdfUpload(file: File) {
      let formData = new FormData();
      if (file) {
        formData.append('file', file);
      }

      setUploading(true);

      const attachment = formatUploadingAttachment(file);
      const attachments = uploadedAttachments.concat(attachment);
      setUploadedAttachments(attachments);

      const uploadConfig = {
        onUploadProgress: (event: any) => {
          setProgress(Math.round((event.loaded / event.total) * 100));
        },
        cancelToken: attachment.cancelTokenSource!.token
      };
      uploadAttachment(observationId, formData, uploadConfig)
        .then(response => {
          const attachments = uploadedAttachments
            .filter(a => a.status !== 'uploading')
            .concat({
              ...response.data,
              status: 'ok',
              content_type: file.type
            });

          setUploadedAttachments(attachments);
          setUploading(false);
        })
        .catch(e => {
          setUploading(false);
        });
    }

    function handleUpload(file: File) {
      if (uploading) {
        return;
      }

      if (isVideo(file.type)) {
        handleVideoUpload(file);
      } else {
        handleImageAndPdfUpload(file);
      }
    }

    function handleFileDrop(e: React.DragEvent<HTMLDivElement>) {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length === 0) return;

      const errors = validateFile(files[0]);
      if (!isEmpty(errors)) {
        setErrors(errors);
      } else {
        handleUpload(files[0]);
      }
    }

    function cancelUpload(attachment: AttachmentWithStatus) {
      setUploading(false);
      if (attachment.cancelTokenSource) {
        attachment.cancelTokenSource.cancel();
      }
      const attachments = uploadedAttachments.filter(
        a => a.status !== 'uploading'
      );
      setUploadedAttachments(attachments);
    }

    function deleteUpload(attachment: AttachmentWithStatus) {
      if (isVideo(attachment.content_type)) {
        deleteVideo(observationId, attachment.id).then(() => {
          const attachments = uploadedAttachments.filter(
            a => !(a.id === attachment.id && isVideo(a.content_type))
          );
          setUploadedAttachments(attachments);
        });
      } else {
        deleteAttachment(observationId, attachment.id).then(() => {
          const attachments = uploadedAttachments.filter(
            a => a.id !== attachment.id
          );
          setUploadedAttachments(attachments);
        });
      }
    }

    function handleDeleteAttachment(attachment: AttachmentWithStatus) {
      if (uploading) {
        cancelUpload(attachment);
      } else {
        deleteUpload(attachment);
      }
    }

    function AttachmentRow(attachment: AttachmentWithStatus) {
      const indicatorColor = attachment.status
        ? STATUS_COLORS[attachment.status]
        : 'white';

      const indicatorIcon = attachment.status
        ? STATUS_ICONS[attachment.status]
        : null;

      const statusText = attachment.status
        ? STATUS_TEXT[attachment.status]
        : null;

      const statusTextColor = attachment.status
        ? STATUS_TEXT_COLORS[attachment.status]
        : 'black';

      return (
        <TR key={attachment.name + attachment.id}>
          <TD>
            <DeleteButton onClick={() => handleDeleteAttachment(attachment)}>
              <DeleteIcon />
            </DeleteButton>
          </TD>
          <TD>
            <a
              href={attachment.attachment_url}
              target="_blank"
              rel="noreferrer"
            >
              <PreviewWrapper>{getPreview(attachment)}</PreviewWrapper>
            </a>
          </TD>
          <TD>
            <Flex direction="column">
              <AttachmentName>{attachment.name}</AttachmentName>
              {attachment.created_at && (
                <AttachmentDate>
                  {moment(attachment.created_at).format('MM/DD/YY hh:mm A')}
                </AttachmentDate>
              )}
            </Flex>
          </TD>
          <TD>
            <Flex direction="column">
              <StatusText color={statusTextColor}>{statusText}</StatusText>
              {attachment.status === 'uploading' && (
                <StyledProgress percent={progress} color="green" />
              )}
            </Flex>
          </TD>
          <TD>
            <Indicator backgroundColor={indicatorColor}>
              <StyledIcon
                name={indicatorIcon}
                role="img"
                aria-label="complete"
                aria-hidden="false"
              />
            </Indicator>
          </TD>
        </TR>
      );
    }

    function AttachmentRowMobile(attachment: AttachmentWithStatus) {
      const indicatorColor = attachment.status
        ? STATUS_COLORS[attachment.status]
        : 'white';

      const indicatorIcon = attachment.status
        ? STATUS_ICONS[attachment.status]
        : null;

      const statusText = attachment.status
        ? STATUS_TEXT[attachment.status]
        : null;

      const statusTextColor = attachment.status
        ? STATUS_TEXT_COLORS[attachment.status]
        : 'black';

      return (
        <FileDetails key={attachment.name + attachment.id}>
          <DeleteButtonWrapper>
            <DeleteButton onClick={() => handleDeleteAttachment(attachment)}>
              <DeleteIcon />
            </DeleteButton>
          </DeleteButtonWrapper>
          <Flex>
            <div>
              <a
                href={attachment.attachment_url}
                target="_blank"
                rel="noreferrer"
              >
                <PreviewWrapper>{getPreview(attachment)}</PreviewWrapper>
              </a>
            </div>
            <div>
              <Flex direction="column">
                <AttachmentName>{attachment.name}</AttachmentName>
                {attachment.created_at && (
                  <AttachmentDate>
                    {moment(attachment.created_at).format('MM/DD/YY hh:mm A')}
                  </AttachmentDate>
                )}
              </Flex>
            </div>
          </Flex>
          <Flex justify="space-between">
            <StatusText color={statusTextColor}>{statusText}</StatusText>
            <Indicator backgroundColor={indicatorColor}>
              <StyledIcon
                name={indicatorIcon}
                role="img"
                aria-label="complete"
                aria-hidden="false"
              />
            </Indicator>
          </Flex>
          <Box mt="8px">
            {attachment.status === 'uploading' && (
              <StyledProgress percent={progress} color="green" />
            )}
          </Box>
        </FileDetails>
      );
    }

    function getSectionStatus() {
      if (sectionWithErrors) {
        return 'error';
      }

      if (uploadedAttachments.length > 0) {
        return 'completed';
      }

      return 'pending';
    }

    return (
      <SectionCollapse
        title={i18n.ft(messages.attachments)}
        subtitle={i18n.ft(messages.description)}
        status={getSectionStatus()}
        statusValue={uploadedAttachments.length}
        attached={sectionWithRoundedBorder}
        closeOthersOnOpen
      >
        <div className="sm:px-14 sm:pb-5">
          <SectionWithDashedBorder
            onDrop={handleFileDrop}
            onDragOver={e => e.preventDefault()}
          >
            <Box mt="24px" mb="24px">
              <Flex justify="center" align="center" direction="column">
                <Box mb="12px">
                  <Title>{i18n.ft(messages.dragDrop)}</Title>
                </Box>
                <Box mb="8px">
                  <UploadIcon />
                </Box>
                <Box mb="12px">
                  <Title>{i18n.ft(messages.or)}</Title>
                </Box>
                <UploadButtonWrapper>
                  <Button fluid color="blue" onClick={onFileChoose}>
                    {i18n.ft(messages.uploadFile)}
                  </Button>
                </UploadButtonWrapper>
                {!!errors.length && <Errors>{errors}</Errors>}
                <p>{i18n.ft(messages.acceptedFiles)}</p>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*,video/*,application/pdf,image/heif,image/heic"
                  aria-label={i18n.ft(messages.fileUpload)}
                  ref={hiddenFileInput}
                  onChange={onFileSelect}
                />
              </Flex>
            </Box>
          </SectionWithDashedBorder>
          <Box mt="24px">
            <ResponsiveContent
              renderDesktop={
                <Table>
                  <tbody>{uploadedAttachments.map(AttachmentRow)}</tbody>
                </Table>
              }
              renderMobile={<>{uploadedAttachments.map(AttachmentRowMobile)}</>}
            />
          </Box>
        </div>
      </SectionCollapse>
    );
  }
);

export default Attachments;
