import i18n from 'helpers/i18n';
import messages from './messages';
import packageIcon from 'images/package.svg';

interface PlaylistItemProps {
  percentage: number;
  numberOfResources: number;
  packageName: string;
  videoUri: string;
}

function PlaylistItem({
  percentage,
  numberOfResources,
  packageName,
  videoUri
}: PlaylistItemProps) {
  return (
    <div
      className="max-w-[303px] mt-4 relative max-w-sm bg-white border border-gray-200 rounded-lg shadow-md"
      style={{ boxShadow: '0px 6px 25px 0px #0000003D' }}
    >
      <div
        className="relative z-10 min-w-[296px] bg-white rounded-lg h-fit cursor-pointer sm:max-h-[296px] md:max-h-[352px] lg:max-h-[315px] xl:max-h-[282px]"
        onClick={() => {}}
      >
        <div className="snap-start flex flex-shrink-0 relative" tabIndex={0}>
          <img
            className="w-full max-h-full max-w-full rounded-t-lg md:max-h-32"
            src={`https://cdnsecakmi.kaltura.com/p/966871/thumbnail/entry_id/${videoUri}/width/600`}
            alt="Thumbnail"
          />
          <div className="mt-2 ml-2 absolute top-0 left-0 bg-white text-black px-2 py-1 rounded text-xs font-semibold">
            {`${numberOfResources} ${i18n.ft(messages.resources)}`}
          </div>
        </div>
      </div>

      <div className="p-4">
        <h3 className="text-lg font-semibold my-2">{packageName}</h3>
        <span className="inline-block bg-[#636568] text-white font-semibold text-sm px-2 py-1 rounded-md mt-2 mb-4">
          <img src={packageIcon} alt="" className="inline" />{' '}
          {`${i18n.ft(messages.package)}`}
        </span>
        <div>
          <div className="bg-[#D8D9D9] rounded-xl shadow-sm overflow-hidden mb-2">
            <div className="relative h-4 flex items-center justify-center">
              <div
                className={`absolute top-0 bottom-0 left-0 rounded-lg bg-[#077699] w-[${percentage}%]`}
              ></div>
            </div>
          </div>

          <div>
            <span className="font-bold">{`${percentage}% ${i18n.ft(
              messages.complete
            )}`}</span>
          </div>
        </div>
      </div>

      <div className="absolute inset-x-0 top-0 flex justify-center">
        <div
          className="w-10/12 h-6 bg-white rounded-t-lg shadow-md -mt-4"
          style={{ boxShadow: '0px -2px 4px 0px #00000040' }}
        ></div>
      </div>

      <div className="absolute inset-x-0 top-0 flex justify-center">
        <div
          className="w-11/12 h-6 bg-white rounded-t-lg shadow-md -mt-2"
          style={{ boxShadow: '0px -2px 4px 0px #00000040' }}
        ></div>
      </div>
    </div>
  );
}

export default PlaylistItem;
