import { Wrapper, Section } from './Styled';
import Reminders from 'components/Dashboard/Reminders';
import ActivateProductKey from 'components/ActivateProductKey';

function Sidebar() {
  return (
    <Wrapper>
      <Section>
        <ActivateProductKey />
      </Section>
      <Section>
        <Reminders />
      </Section>
    </Wrapper>
  );
}

export default Sidebar;
