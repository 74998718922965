import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Formik, Form, Input } from 'components/EnhancedForm';

const EnterForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    token: ''
  };

  function handleSubmit(values) {
    setLoading(true);
    navigate(RouteHelpers.getPath('user-token', { token: values.token }));
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {({ submitForm }) => (
        <Form>
          <Input name="token" />
          <Button
            fluid
            size="large"
            color="green"
            loading={loading}
            disabled={loading}
            onClick={submitForm}
            content={i18n.ft(messages.useToken)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EnterForm;
