import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import styled from 'styled-components';
import PageLayout from 'components/PageLayout';
import { Icon, Header, Button } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import { useLocationState } from 'hooks/useLocationState';

const MessageContainer = styled.div`
  text-align: center;
  margin: 32px 0;
`;

const ButtonContainer = styled.div`
  margin-top: 32px;
`;

type URLParams = {
  id: string;
};

type LocationState = {
  email?: string;
};

const CheckoutCompleted: React.FC = () => {
  const navigate = useNavigate();
  const params = useRouteParams<URLParams>();
  const state = useLocationState<LocationState>();
  const trainingURL = RouteHelpers.getPath('td-trainings-view-participants', {
    id: params.id
  });

  if (!state || !state.email) {
    navigate(trainingURL, { replace: true });
    return null;
  }

  return (
    <PageLayout>
      <MessageContainer>
        <Header as="h1" textAlign="center" icon>
          <Icon color="green" name="check circle" />
          {i18n.ft(messages.orderConfirmed)}
          <Header.Subheader>
            {i18n.ft(messages.description, { email: state.email })}
          </Header.Subheader>
        </Header>

        <ButtonContainer>
          <Button
            replace
            as={Link}
            to={trainingURL}
            size="large"
            color="green"
            content={i18n.ft(messages.returnToTraining)}
          />
        </ButtonContainer>
      </MessageContainer>
    </PageLayout>
  );
};

export default CheckoutCompleted;
