const PATH = 'fe.components.dropdowns.user_jobs';

const messages = {
  teacher: {
    key: `${PATH}.teacher`,
    defaultValue: 'Teacher'
  },
  observer: {
    key: `${PATH}.observer`,
    defaultValue: 'Observer'
  },
  support: {
    key: `${PATH}.support`,
    defaultValue: 'Education Support'
  },
  other: {
    key: `${PATH}.another`,
    defaultValue: 'Other'
  }
};

export default messages;
