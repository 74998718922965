import React from 'react';
import { map } from 'ramda';
import PropTypes from 'prop-types';
import { Card, Description } from './Styled';
import TrainingStatus from './TrainingStatus';
import Typography from 'components/Typography';
import AgeLevel, { AgeLevelGroup } from 'components/AgeLevel';
import TrainingField from 'components/Trainings/TrainingField';
import OrganizationField from '../OrganizationField';
import { Training } from 'types/api/Training';

import {
  formatDate,
  formatLocation,
  formatParticipants,
  formatTrainer
} from 'components/Trainings/TrainingField/utils';
import { getGroupBaseColor } from 'helpers/ageLevel';
import RouteHelpers from 'helpers/routes';

interface TrainingCardProps {
  training: Training;
  certificate: boolean;
}

function TrainingCard({ training, certificate }: TrainingCardProps) {
  const { age_levels: ageLevels, training_template: template } = training;

  return (
    <Card
      color={getGroupBaseColor(ageLevels)}
      to={RouteHelpers.getPath('td-trainings-view-details', {
        id: training.id
      })}
    >
      <Description>
        <Description.TextGroup>
          <Typography content={template.title} fluid color="blue" bold />
          <Typography content={template.short_description} fluid size="small" />
        </Description.TextGroup>
        <AgeLevelGroup>
          {map(
            ageLevel => (
              <AgeLevel
                key={ageLevel.abbreviation}
                abbreviation={ageLevel.abbreviation}
              />
            ),
            ageLevels
          )}
        </AgeLevelGroup>
      </Description>

      <TrainingField.Group>
        <TrainingField icon="calendar" text={formatDate(training)} />
        <TrainingField
          icon="map marker alternate"
          text={formatLocation(training)}
        />
        <TrainingField icon="users" text={formatParticipants(training)} />
        <TrainingField
          maxWidth="fit-content"
          icon="user"
          text={formatTrainer(training.trainer)}
        />
        {training.account?.name && (
          <OrganizationField name={training.account?.name} />
        )}
      </TrainingField.Group>

      <TrainingStatus
        trainingId={training.id}
        status={training.status}
        certificate={certificate}
      />
    </Card>
  );
}

TrainingCard.propTypes = {
  training: PropTypes.shape({
    training_template: PropTypes.object.isRequired,
    trainer: PropTypes.object.isRequired,
    participant_count: PropTypes.number.isRequired
  }).isRequired,

  /** If `true` a link to download a certificate is displayed. */
  certificate: PropTypes.bool
};

export default TrainingCard;
