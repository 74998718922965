import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import useCurrentUser from 'hooks/useCurrentUser';
import { clsx } from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { addUserToAccount } from 'actions/accounts';
import { Input } from 'components/Organization/Input';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { Select, SelectOption } from 'components/Organization/Select';
import { useGetRoles } from 'actions/roles';
import PageLoader from 'components/PageLoader';
import { roleToText } from 'helpers/role';
import { toastSuccess } from 'components/Organization/Toast';

import {
  HierarchyInput,
  useAccountHierarchy
} from 'components/Organization/Hierarchy';

const schema = yup.object({
  email: yup
    .string()
    .email(i18n.ft(messages.errors.email))
    .required(i18n.ft(messages.errors.required)),
  first_name: yup.string().required(i18n.ft(messages.errors.required)),
  last_name: yup.string().required(i18n.ft(messages.errors.required)),
  role_id: yup.number().nullable(),
  node_ids: yup.array(yup.number().required()).required()
});

function parseError(error: any) {
  return error?.response?.data ?? [{}];
}

interface AddMembersProps {
  /** Current account id. */
  accountId: number;

  /** List of roles formatted for select component. */
  roles: SelectOption[];
}

function AddMembers({ accountId, roles }: AddMembersProps) {
  const navigate = useNavigate();
  const hierarchy = useAccountHierarchy(accountId);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { node_ids: [] }
  });

  const { isPending, mutate: addUser } = useMutation({
    mutationFn: addUserToAccount,
    onSuccess: () => {
      navigate('/organization');
      toastSuccess({ message: i18n.ft(messages.usersAdded, { count: 1 }) });
    },
    onError: error => {
      const errors = parseError(error)[0];

      if (errors.email) {
        setError('email', { message: errors.email });
      }

      if (errors.first_name) {
        setError('first_name', { message: errors.first_name });
      }

      if (errors.last_name) {
        setError('last_name', { message: errors.last_name });
      }

      if (errors.account_user) {
        setError('root', { message: errors.account_user });
      }
    }
  });

  const handleFormSubmit = (data: any) => {
    if (isPending) {
      return;
    }

    addUser({ accountId, params: { users: [data] } });
  };

  return (
    <div className="px-4 md:px-6 xl:px-10 max-w-7xl mx-auto">
      <div className="pt-6 md:pb-4 lg:pb-6">
        <Link
          to="/organization"
          className="font-sans font-semibold text-sm text-[#6E2262] hover:text-[#41193D] hover:underline"
        >
          <i className="fa-solid fa-arrow-left no-underline" />
          <span className="ml-2">{i18n.ft(messages.backToMembers)}</span>
        </Link>
      </div>

      <h2 className="font-sans font-bold text-black text-3xl py-4">
        {i18n.ft(messages.addMember)}
      </h2>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <section>
          {errors.root && (
            <div className="font-sans font-semibold text-base text-[#3C3F42] p-4 bg-[#EED3D8] border-x border-t border-[#AC213A] rounded-t-2xl flex items-center gap-2">
              <i className="fa-solid fa-circle-xmark text-[#AC213A]" />
              <span>{errors?.root?.message}</span>
            </div>
          )}
          <div
            className={clsx(
              'bg-white p-6 rounded-2xl',
              errors.root && 'rounded-t-none border-b border-x border-[#AC213A]'
            )}
          >
            <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
              <Input
                {...register('email')}
                required
                label={i18n.ft(messages.fields.email)}
                placeholder={i18n.ft(messages.fields.emailPlaceholder)}
                error={errors.email?.message}
              />
              <Input
                {...register('first_name')}
                required
                label={i18n.ft(messages.fields.firstName)}
                placeholder={i18n.ft(messages.fields.firstNamePlaceholder)}
                error={errors.first_name?.message}
              />
              <Input
                {...register('last_name')}
                required
                label={i18n.ft(messages.fields.lastName)}
                placeholder={i18n.ft(messages.fields.lastNamePlaceholder)}
                error={errors.last_name?.message}
              />
              <Controller
                name="role_id"
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    label={i18n.ft(messages.fields.role)}
                    placeholder={i18n.ft(messages.fields.rolePlaceholder)}
                    onChange={value =>
                      setValue('role_id', value as number | null)
                    }
                    options={roles}
                  />
                )}
              />
              <div className="sm:col-span-2">
                <Controller
                  name="node_ids"
                  control={control}
                  render={({ field }) => (
                    <HierarchyInput
                      {...hierarchy}
                      value={field.value}
                      onChange={value => setValue('node_ids', value)}
                      label={i18n.ft(messages.fields.hierarchy)}
                      placeholder="Select hierarchy association for this member"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </section>

        <div className="py-4 flex justify-end">
          <PrimaryButton type="submit">
            {i18n.ft(messages.addMember)}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
}

function AddMembersController() {
  const currentUser = useCurrentUser();
  const accountId = currentUser.current_account_id ?? 0;

  const { data, isSuccess } = useGetRoles({
    zones: 'account',
    account_id: accountId
  });

  if (!isSuccess) {
    return <PageLoader />;
  }

  const roles = data.roles.map(role => ({
    value: role.id,
    text: roleToText(role.name)
  }));

  return <AddMembers accountId={accountId} roles={roles} />;
}

export default AddMembersController;
