import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import I18n from 'i18n-js';
import { Input } from 'components/EnhancedForm';
import UserForm from 'components/forms/UserForm';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { login } from 'actions/users';
import { activateProductKeyV1 } from 'actions/productKeys';

import {
  getPostActivationData,
  PostActivationData
} from 'components/ActivateProductKey/utils';

import {
  LogInWrapper,
  Label,
  Header,
  SubHeader
} from 'pages/Users/ActivateProductKey/Styled';

interface ProductKeyFormProps {
  /** Callback fired after the user login using a product key. */
  onSuccess: (data: PostActivationData) => void;
}

function setSubmitError(key: string, message: string) {
  if (key !== 'product_key') {
    return message;
  }
  if (message !== 'You must log in to use this token') {
    return message;
  }
  return i18n.ft(messages.mustBeLoggedIn);
}

function ProductKeyForm({ onSuccess }: ProductKeyFormProps) {
  const [redirectAttempts] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');

  const initialValues = {
    product_key: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    tos_pp_agreed_at: ''
  };

  let timer: any = null;

  const redirectAttemptsRef = useRef(redirectAttempts);
  redirectAttemptsRef.current = redirectAttempts;

  function onSubmit(values: any, formik: FormikHelpers<any>) {
    if (loading) {
      return;
    }

    setErrors('');
    setLoading(true);

    const formData = {
      key: values.product_key,
      user: {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation,
        tos_pp_agreed_at: values.tos_pp_agreed_at,
        preferred_language: I18n.currentLocale()
      }
    };

    activateProductKeyV1(formData)
      .then((res: any) => {
        const data = getPostActivationData(res.data);
        attemptRedirect(data, values.email, values.password);
      })
      .catch((err: any) => {
        Object.keys(err.response.data.errors).forEach(key => {
          formik.setFieldError(
            key,
            setSubmitError(key, err.response.data.errors[key][0])
          );
        });

        setLoading(false);
      });
  }

  function attemptRedirect(
    activationData: PostActivationData,
    email: string,
    password: string
  ) {
    // Only try to redirect the user for 2 minutes, which requires 40 attempts
    // since each attempt is triggered every 3 seconds.
    if (redirectAttemptsRef.current >= 40) {
      clearTimeout(timer);
      redirectAttemptsRef.current = 0;
      setLoading(false);
      setErrors(i18n.ft(messages.activateError));
      return;
    }

    redirectAttemptsRef.current = redirectAttemptsRef.current + 1;

    login({ user: { email, password } })
      .then(() => {
        clearTimeout(timer);
        onSuccess(activationData);
      })
      .catch(() => {
        timer = setTimeout(() => {
          attemptRedirect(activationData, email, password);
        }, 3000);
      });
  }

  function validationSchema() {
    return Yup.object({
      product_key: Yup.string().required(i18n.ft(messages.productKeyRequired))
    });
  }

  return (
    <>
      <LogInWrapper>
        <Label>{i18n.ft(messages.alreadyHaveAccount)}</Label>
        <Link
          to={{
            pathname: RouteHelpers.getPath('users-login'),
            search: '?pk-activation'
          }}
        >
          <Button
            as={Link}
            to={RouteHelpers.getPath('users-login')}
            size="mini"
            color="blue"
            compact
          >
            {i18n.ft(messages.logIn)}
          </Button>
        </Link>
      </LogInWrapper>

      <Header>{i18n.ft(messages.activateProductKey)}</Header>

      <SubHeader>{i18n.ft(messages.createAccount)}</SubHeader>

      <UserForm
        initialValues={initialValues}
        validationFields={validationSchema()}
        handleSubmit={onSubmit}
        loading={loading}
        errors={errors}
      >
        <Input
          icon="lock"
          name="product_key"
          placeholder={i18n.ft(messages.productKey)}
        />
      </UserForm>
    </>
  );
}

export default ProductKeyForm;
