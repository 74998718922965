import * as R from 'ramda';

const BrowserHelper = {
  /**
   * Returns true if the supplied cookie exist
   *
   * @param {String} cookie
   * @method containsCookie
   * @return boolean
   */
  containsCookie(cookie: string) {
    const regexp = new RegExp(cookie, 'gi');
    return R.test(regexp, document.cookie);
  },

  getCookieValue(key: string) {
    var value = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
    return value ? value.pop() : '';
  }
};

export default BrowserHelper;
