import styled from 'styled-components';

export const Wrapper = styled.div`
  color: ${p => p.theme.colors['neutral']['700']};
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  &:hover {
    color: ${p => p.theme.colors['neutral']['700']};
  }
`;

export const CardHeader = styled.div<{ completed?: boolean }>`
  padding: 12px 24px;
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid ${p => p.theme.colors['neutral']['100']};

  background-color: ${p =>
    p.completed ? p.theme.colors['primary']['700'] : p.theme.colors['white']};

  color: ${p =>
    p.completed
      ? p.theme.colors['white']
      : p => p.theme.colors['neutral']['700']};

  @media screen and (min-width: 640px) {
    padding: 12px 32px;
  }
`;

export const CardContent = styled.div`
  flex-grow: 1;
  padding: 11px 24px;
  background-color: ${p => p.theme.colors['white']};
  border-radius: 0 0 16px 16px;

  @media screen and (min-width: 640px) {
    padding: 24px 32px 32px;
  }
`;

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-row-gap: 4px;
  grid-column-gap: 24px;
`;

export const CardIcon = styled.img<{ size?: string }>`
  width: ${p => (p.size === 'large' ? '16px' : '14px')};
  height: ${p => (p.size === 'large' ? '16px' : '14px')};
  margin-right: 12px;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexEnd = styled.div`
  display: flex;
  margin-left: auto;
`;

export const TextBold = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
`;

export const MetadataValue = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #75757a;
`;

export const ExportWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-left: auto;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

export const ContentAssessment = styled.div`
  flex-grow: 1;
  background-color: ${p => p.theme.colors['white']};
  padding: 0 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;

  @media screen and (min-width: 640px) {
    padding: 0 32px;
  }
`;

export const Pill = styled.div<{ backgroundColor: string }>`
  display: flex;
  background-color: ${p => p.theme.colors[p.backgroundColor]};
  color: ${p => p.theme.colors.scaleDarkBlue};
  width: 192px;
  height: 32px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
`;

export const AgePill = styled.div<{ backgroundColor: string }>`
  display: flex;
  background-color: ${p => p.theme.colors[p.backgroundColor][100]};
  padding: 0 10px;
  width: max-content;
  height: 24px;
  border-radius: 20px;
  font-size: 14px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
`;

export const CheckedSquare = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${p => p.theme.colors['scaleBlue']};
`;

export const NotCheckedSquare = styled(CheckedSquare)`
  background-color: transparent;
  border: 1px solid #000000;
`;
