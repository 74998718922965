import { clsx } from 'clsx';

interface ClassVersionTagProps {
  classVersion: '2008' | '2nd Edition';
}

const colors: { [key: string]: string } = {
  '2008': 'bg-[#CEEBF4]',
  '2nd Edition': 'bg-[#F4C2A9]'
};

export function ClassVersionTag({ classVersion }: ClassVersionTagProps) {
  const classes = clsx(
    'px-2 py-1 rounded text-xs font-bold',
    colors[classVersion]
  );

  return <span className={classes}>{classVersion}</span>;
}
