import styled, { css } from 'styled-components';
import { Button } from 'semantic-ui-react';
import { Flex } from 'components/Flex';

const circleSize = '41px';
const circleSizeMobile = '26px';

const size = {
  tablet: '768px'
};

export const device = {
  tablet: `(min-width: ${size.tablet})`
};

export const Wrapper = styled.div<{
  scrolled?: boolean;
}>`
  background-color: ${p =>
    p.scrolled ? 'rgba(239, 241, 243, 0.97)' : p.theme.colors['brightGrey']};
  display: flex;
`;

export const WrapperSizer = styled.div<{
  cyclesCount: number;
}>`
  display: flex;
  margin: 0 auto;

  width: 100%;
  @media ${device.tablet} {
    ${({ cyclesCount }) => cyclesCount <= 2 && `width: 40%;`};
    ${({ cyclesCount }) => cyclesCount === 3 && `width: 60%;`};
    ${({ cyclesCount }) => cyclesCount === 4 && `width: 80%;`};
    ${({ cyclesCount }) => cyclesCount >= 5 && `width: 100%;`};
  }
`;

export const CycleWrapper = styled.div<{
  scrolled?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  align-items: center;

  padding-top: ${p => (p.scrolled ? '0' : '40px')};
  padding-bottom: ${p => (p.scrolled ? '15px' : '0')};

  &:not(:first-child) {
    &:before {
      position: relative;
      content: '';
      border-bottom: 2px solid #fff;
      width: 100%;
      top: calc(${circleSizeMobile} / 2);
      left: -50%;
      z-index: 1;

      @media ${device.tablet} {
        top: calc(${circleSize} / 2);
      }
    }
  }
`;

const circleBase = css`
  z-index: 2;

  margin-bottom: 5px !important;
  padding: 0 !important;

  i {
    margin: 0 auto !important;
    font-size: 12px !important;
    color: #fff !important;
    opacity: 1 !important;

    @media ${device.tablet} {
      font-size: 16px !important;
    }
  }

  width: ${circleSizeMobile} !important;
  height: ${circleSizeMobile} !important;

  @media ${device.tablet} {
    width: ${circleSize} !important;
    height: ${circleSize} !important;
  }

  box-shadow: 1px 7px 21px -3px rgba(0, 0, 0, 0.25) !important;
`;

export const BaseCircleButton = styled(Button).attrs(props => ({
  circular: props.circular || true
}))`
  ${circleBase};
`;

export const AddCircleButton = styled(BaseCircleButton)`
  background-color: #fbbd0b !important;
  color: #fff !important;

  &:hover,
  &:focus {
    background-color: rgba(251, 189, 11, 0.75) !important;
    border: 1px solid #fff;
    transition: 0.2s;
  }
`;

export const CurrentCircleButton = styled.button`
  ${circleBase};

  background-color: ${p => p.theme.colors.scaleBlue};
  color: #fff;
  font-family: inherit;
  font-weight: 700;

  cursor: pointer;
  border-radius: 50%;
  border: 0;
`;

export const CircleButton = styled(BaseCircleButton)<{
  /** when only one cycle exist in the observation  */
  unique: boolean;
}>`
  background-color: #fff !important;
  color: ${p => p.theme.colors['scaleDarkBlue']};

  &:hover,
  &:focus {
    background-color: ${p => p.theme.colors['scaleBlue']} !important;
    color: #fff !important;
    transition: 0.2s;
  }

  ${({ unique, theme }) =>
    unique &&
    css`
      background-color: ${theme.colors['scaleBlue']} !important;
      color: #fff !important;

      &:hover,
      &:focus {
        border: 1px solid #fff;
      }
    `};

  font-size: 12px !important;

  @media ${device.tablet} {
    font-size: 16px !important;
  }

  ${({ $hasErrors, theme }) =>
    $hasErrors &&
    css`
      border: 1px solid ${theme.colors['scaleWarning']} !important;
      color: ${theme.colors['scaleWarning']} !important;
    `};
`;

export const CircleDescription = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${p => p.theme.colors['scaleDarkBlue']};
`;

export const CycleNumber = styled(CircleDescription)`
  margin-top: -19px !important;
  margin-bottom: 0;
  font-weight: bold;
`;

export const Notice = styled.div<{
  scrolled?: boolean;
}>`
  background-color: ${p =>
    p.scrolled ? 'rgba(239, 241, 243, 0.97)' : p.theme.colors['brightGrey']};
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: ${p => (!p.scrolled ? '20px' : '-10px')};
  padding-bottom: ${p => (p.scrolled ? '10px' : '0')};
`;

export const FlexWrapper = styled(Flex)`
  width: 100%;
`;
