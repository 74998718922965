import { useMeasure } from 'context/measure';
import PageBackground from 'components/PageBackground';
import KalturaVideo from 'components/KalturaVideo';
import { useRouteParams } from 'hooks/useRouteParams';
import { Flex } from 'components/Flex';

type ParamsType = {
  id: string;
  videoId: string;
};

function VideoView() {
  const { assessment } = useMeasure();
  const { videoId } = useRouteParams<ParamsType>();

  return (
    <PageBackground>
      <Flex justify="center" align="center">
        <KalturaVideo
          observationId={assessment.observation!.id}
          videoId={videoId}
        />
      </Flex>
    </PageBackground>
  );
}

export default VideoView;
