import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import { Loader } from 'semantic-ui-react';
import ResultSection from './ResultSection';
import PageHeader from 'components/PageHeader';
import { getScores } from 'actions/testAttempts';
import MainContent from 'components/MainContent';
import DimensionSection from './DimensionsSection';
import { useRouteParams } from 'hooks/useRouteParams';
import PageBackground from 'components/PageBackground';
import PrintGlobalStyle from 'components/PrintGlobalStyle';
import TopSection from 'components/ReliabilityTest/TopSection';
import PerformanceReportSection from './PerformanceReportSection';
import BottomSection from 'components/ReliabilityTest/BottomSection';
import logoCertification from 'images/reliabilityTest/logoCertification.svg';

type URLParams = {
  id: string;
};

function ScoreReport() {
  const { id } = useRouteParams<URLParams>();
  const { data, run, isPending } = useAsync();

  useEffect(() => {
    run(getScores(Number(id)));
  }, [run, id]);

  if (isPending) {
    return (
      <div className="mt-8">
        <Loader active inline="centered" />
      </div>
    );
  }

  return (
    <>
      <PrintGlobalStyle />
      <MainContent maxWidth={1280}>
        <PageHeader
          title={data.title}
          subtitle={i18n.ft(messages.subtitle)}
          image={logoCertification}
          imageAlt={i18n.ft(messages.logoDescription)}
        />
        <PageBackground borderStyle className="PrintBackgroundColor">
          <TopSection attempt={data.attempt} completedAt={data.completed_at} />
          <ResultSection
            result={data.status}
            title={data.title}
            testScore={data.test_score}
            dimensions={data.two_of_five_per_dimension}
            attemptNumber={data.attempt}
            testType={data.test_type}
            categories={data.test_type === 'knowledge' ? data.categories : null}
            threeOfSix={data.three_of_six_per_category}
            framework={data.framework}
            role={data.role}
          />
          <PerformanceReportSection
            scores={data.videos_score}
            testType={data.test_type}
            resultsByCategory={
              data.test_type === 'knowledge' ? data.categories : null
            }
            overallScore={
              data.test_type === 'knowledge' ? data.test_score : null
            }
          />
          {data.test_type === 'knowledge' ? null : (
            <DimensionSection
              domains={data.domains}
              scores={data.videos_score}
              testType="reliability"
            />
          )}
          <BottomSection
            testType={data.test_type}
            status={data.status}
            attemptNumber={data.attempt}
          />
        </PageBackground>
      </MainContent>
    </>
  );
}

export default ScoreReport;
