import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
  background-color: white;
  border-radius: 4px;
  padding: 12px 16px;
  border: 1px solid ${p => p.theme.colors['neutral']['200']};
  color: ${p => p.theme.colors['primary']['700']};
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    width: calc((100% - 8px) / 2);
    display: inline-flex;
    margin-right: 8px;

    &:nth-child(2) {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    width: calc((100% - 32px) / 3);
    justify-content: center;
    margin-right: 16px;

    &:nth-child(2) {
      margin-right: 16px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Image = styled.img`
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 16px;
`;

interface ServiceLinkProps {
  /** Link text content. */
  content: string;

  /** Image URL for link. */
  image: string;

  /** Alt description for image. */
  imageAlt: string;

  /** Link URL. */
  href: string;
}

const ServiceLink: React.FC<ServiceLinkProps> = ({
  href,
  image,
  imageAlt,
  content
}) => {
  return (
    <Container href={href}>
      <Image src={image} alt={imageAlt} />
      <span>{content}</span>
    </Container>
  );
};

export default ServiceLink;
