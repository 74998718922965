const PATH = 'fe.pages.improvements.educator_profile';

const messages = {
  ages: {
    key: `${PATH}.ages`,
    defaultValue: 'Age(s)'
  },
  location: {
    key: `${PATH}.location`,
    defaultValue: 'Site'
  },
  children: {
    key: `${PATH}.children`,
    defaultValue: 'Children'
  },
  disabilitiesQuestion: {
    key: `${PATH}.disabilities_question`,
    defaultValue: 'Children with Disabilities?'
  },
  dualLanguageQuestion: {
    key: `${PATH}.dual_language_question`,
    defaultValue: 'Dual Language Learners?'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  firstCoachingCycle: {
    key: `${PATH}.first_coaching_cycle`,
    defaultValue: 'First Coaching Cycle'
  },
  coachingFoundation: {
    key: `${PATH}.coaching_foundation`,
    defaultValue: 'Building a Coaching Foundation'
  },
  learningSetting: {
    key: `${PATH}.learning_setting`,
    defaultValue: 'Learning Setting'
  },
  pageTitle: {
    key: `${PATH}.page_title`,
    defaultValue: 'Coaching with'
  },
  educator: {
    key: `${PATH}.educator`,
    defaultValue: 'Educator'
  },
  coach: {
    key: `${PATH}.coach`,
    defaultValue: 'Coach'
  },
  coachingFoundationPartnership: {
    key: `${PATH}.coaching_foundation_partnership`,
    defaultValue:
      'The foundation of successful coaching is the partnership between the coach and the educator, starting with an opportunity for coaches and educators to connect with one another.'
  },
  coachingFoundationKickoff: {
    key: `${PATH}.coaching_foundation_kickoff`,
    defaultValue:
      'A Kickoff Meeting helps coaches and educators begin to connect and build a foundation for coaching. After that, we’ll be able to start the first coaching cycle.'
  },
  coachingFoundationObservation: {
    key: `${PATH}.coaching_foundation_observation`,
    defaultValue:
      'Educators and coaches may agree to engage in an optional step - an initial General Observation. A General Observation can help coaches learn more about the children and learning setting.'
  },
  no: {
    key: `${PATH}.no`,
    defaultValue: 'No'
  },
  yes: {
    key: `${PATH}.yes`,
    defaultValue: 'Yes'
  },
  currentCoachingCycle: {
    key: `${PATH}.current_coaching_cycle`,
    defaultValue: 'Current Cycle'
  },
  selectACoachingCycle: {
    key: `${PATH}.select_a_coaching_cycle`,
    defaultValue: 'Select a Coaching Cycle'
  },
  cycleCompletedOn: {
    key: `${PATH}.cycle_completed_on`,
    defaultValue: 'Cycle Completed On'
  }
};
export default messages;
