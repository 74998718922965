import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SubHeader = styled.div``;

export const Errors = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 16px;
  color: ${props => props.theme.colors.red};
`;

export const PageContainer = styled.div`
  margin: 0 1em;
  position: relative;
`;
