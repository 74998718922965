const PATH = 'fe.components.trainings.view.summary_banner';

const messages = {
  createDuplicate: {
    key: `${PATH}.create_duplicate`,
    defaultValue: 'Create Duplicate Training'
  },
  createNew: {
    key: `${PATH}.create_new`,
    defaultValue: 'New Training'
  },
  edit: {
    key: `${PATH}.details.edit`,
    defaultValue: 'Edit'
  },
  markAsCompleted: {
    key: `${PATH}.details.mark_as_completed`,
    defaultValue: 'Mark as Completed'
  },
  reopen: {
    key: `${PATH}.reopen`,
    defaultValue: 'Reopen'
  },
  unknown: {
    key: `${PATH}.errors.unknown`,
    defaultValue: 'An unknown error occurred, please try again later'
  },
  completeModal: {
    header: {
      key: `${PATH}.complete_modal.header`,
      defaultValue: 'Are you sure you want to complete the training?'
    },
    content: {
      key: `${PATH}.complete_modal.content`,
      defaultValue:
        'This action will prevent further edits. Please check participant attendance is accurate and complete. Participants will receive a certificate of completion based on their attendance.'
    },
    cancel: {
      key: `${PATH}.complete_modal.cancel`,
      defaultValue: 'Cancel'
    },
    complete: {
      key: `${PATH}.complete_modal.complete`,
      defaultValue: 'Yes, Complete Training'
    },
    unknown: {
      key: `${PATH}.errors.unknown`,
      defaultValue: 'An unknown error occurred, please try again later'
    }
  },
  reopenModal: {
    header: {
      key: `${PATH}.reopen_modal.header`,
      defaultValue: 'Are you sure you want to reopen the training?'
    },
    content: {
      key: `${PATH}.reopen_modal.content`,
      defaultValue:
        'This action will change the training to active again. Users will be able to see and interact with this training.'
    },
    cancel: {
      key: `${PATH}.reopen_modal.cancel`,
      defaultValue: 'Cancel'
    },
    reopen: {
      key: `${PATH}.reopen_modal.reopen`,
      defaultValue: 'Yes, Reopen Training'
    }
  }
};

export default messages;
