import i18n from 'helpers/i18n';
import messages from './messages';
import { GroupTitle, ListItem, ScoreGroup } from './Styled';
import {
  CategoryItem,
  ScoreGroupKeys
} from 'types/api/envScales/AssessmentScore';

interface GroupProps {
  groupType: ScoreGroupKeys;
  items: CategoryItem[];
  domainKey: string;
}

function Group({ groupType, items, domainKey }: GroupProps) {
  let i18nKey: 'many' | 'some' | 'none' | 'notApplicable';
  i18nKey = groupType === 'not_applicable' ? 'notApplicable' : groupType;

  return (
    <ScoreGroup>
      <GroupTitle>{i18n.ft(messages[i18nKey])}</GroupTitle>
      {items.length === 0 ? (
        <p className="ml-4">- {i18n.ft(messages.notFound)}</p>
      ) : (
        <ul key={`${domainKey}_${groupType}`}>
          {items.map(item => (
            <ListItem key={`${item.position}`}>
              {item.position}
              <span className="ml-2.5" />
              {item.name}
            </ListItem>
          ))}
        </ul>
      )}
    </ScoreGroup>
  );
}

export default Group;
