import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import exitTest from 'images/reliabilityTest/exitTest.svg';
import IncompleteDimensions from '../IncompleteDimensions';
import {
  StyledButton,
  ActionWrapper,
  SectionActions,
  ContainerSection as Section
} from 'components/Reports/Reliability/View/Styled';

interface SubmitScoreSectionProps {
  isTablet: boolean;
  completeCodesError: boolean;
  verifySubmission: () => void;
  testType?: string;
}

function SubmitScoreSection({
  isTablet,
  completeCodesError,
  verifySubmission,
  testType
}: SubmitScoreSectionProps) {
  const navigate = useNavigate();

  function handleClick() {
    if (testType === 'testPreparations') {
      return navigate(-1);
    }

    return navigate(RouteHelpers.getPath('app'));
  }

  return (
    <Section
      mt="8px"
      px="48px"
      py="24px"
      mx={{ _: '0px', lg: '32px' }}
      borderBottomRadius={isTablet ? '12px' : ''}
    >
      <SectionActions>
        <ActionWrapper>
          <StyledButton
            basic
            fluid
            content={
              <div className="cursor-pointer flex items-center">
                <img
                  src={exitTest}
                  alt={i18n.ft(messages.exitTest)}
                  className="mr-5"
                />
                {i18n.ft(messages.exitTest)}
              </div>
            }
            onClick={handleClick}
          />
        </ActionWrapper>
        {isTablet && (
          <IncompleteDimensions completeCodesError={completeCodesError} />
        )}
        <ActionWrapper>
          <Button
            fluid
            content={i18n.ft(messages.submit)}
            color="blue"
            onClick={verifySubmission}
          />
        </ActionWrapper>
      </SectionActions>
    </Section>
  );
}

export default SubmitScoreSection;
