import axios from 'axios';
import { TestAttempt } from 'types/api/TestAttempt';
import { AuditComment } from 'types/api/AuditComment';

type UserTestDates = {
  available_at: string;
  expires_at: string;
};

type UserManualTest = {
  origin: string;
  test_template_id: number;
};

type ProvisionManualTest = UserTestDates & UserManualTest;

export function getUserTests(userId: number) {
  return axios.get(`/api/v2/users/${userId}/tests`);
}

export function getUserTestsForCurrentUser() {
  return axios.get(`/api/v2/user_tests`);
}

export function getUserTest(userId: number, testId: number) {
  return axios.get(`/api/v2/users/${userId}/tests/${testId}`);
}

export function createUserTest(
  userId: number,
  params: ProvisionManualTest & AuditComment
) {
  return axios.post(`/api/v2/users/${userId}/tests`, {
    ...params
  });
}

export function updateUserTest(
  userId: number,
  testId: number,
  UserTestDates: UserTestDates & AuditComment
) {
  return axios.put(`/api/v2/users/${userId}/tests/${testId}`, UserTestDates);
}

export function getUserTestAttempts(userId: number, testId: number) {
  return axios.get<{ test_attempts: TestAttempt[] }>(
    `/api/v2/users/${userId}/tests/${testId}/attempts`
  );
}

export function updateUserTestTermsAccepted(
  userId: number,
  userTestId: number,
  termsAcceptedAt: string
) {
  return axios.put(`/api/v2/user_tests/${userTestId}`, {
    terms_accepted_at: termsAcceptedAt
  });
}

export function addNewTestAttempt(userTestId: number) {
  return axios.post(`/api/v2/user_tests/${userTestId}/add_attempt`);
}
