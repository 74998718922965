const PATH = 'fe.components.measure.summary.delete_modal';

const messages = {
  cancel: {
    key: `${PATH}.cancel`,
    defaultValue: 'Cancel'
  },
  remove: {
    key: `${PATH}.remove`,
    defaultValue: 'Yes, delete'
  },
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Are you sure you want to delete this observation?'
  },
  description: {
    key: `${PATH}.description`,
    defaultValue: 'All your progress will be lost and cannot be recovered.'
  }
};

export default messages;
