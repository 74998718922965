const PATH = 'fe.components.reliability_test.enter_codes_section';

const messages = {
  enterCodes: {
    key: `${PATH}.enter_codes`,
    defaultValue: 'Enter Codes'
  },
  sectionDescription: {
    key: `${PATH}.section_description`,
    defaultValue: 'Click here to expand this section and enter dimension codes'
  },
  announcement: {
    key: `${PATH}.announcement`,
    defaultValue: 'Selected {{value}} out of 7'
  }
};

export default messages;
