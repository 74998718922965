import { getAgeLevel, getShadeColor } from 'helpers/ageLevel';

type AGE_LEVEL_ABBREVIATIONS = 'Pre-K' | 'Inf' | 'Todd' | 'K-3' | 'Sec' | 'UE';

export function getColor(abbreviation: AGE_LEVEL_ABBREVIATIONS): string {
  return getShadeColor(abbreviation, 'base');
}

export function getLabel(ageLevel: string): string {
  const abbr = ageLevel as AGE_LEVEL_ABBREVIATIONS;

  if (abbr === 'K-3') {
    return getAgeLevel('PK3').name;
  } else {
    return getAgeLevel(abbr).name;
  }
}
