const PATH =
  'fe.components.measure.view.cycles.cycle_scores_form.dimension_card';

const messages = {
  indicators: {
    key: `${PATH}.indicators`,
    defaultValue: 'Indicators'
  },
  score: {
    key: `${PATH}.score`,
    defaultValue: 'Score'
  },
  indicatorError: {
    key: `${PATH}.indicator_error`,
    defaultValue: 'Complete indicator score selection.'
  },
  warnings: {
    uncommonAbove: {
      key: `${PATH}.warnings.uncommon_above`,
      defaultValue: 'Scores above {{number}} are uncommon for {{dimension}}.'
    },
    uncommonBelow: {
      key: `${PATH}.warnings.uncommon_below`,
      defaultValue: 'Scores below {{number}} are uncommon for {{dimension}}.'
    }
  }
};

export default messages;
