const PATH = 'fe.components.measure.page_header';

const messages = {
  logo: {
    key: `${PATH}.logo`,
    defaultValue: 'Environmental Scales logo'
  }
};

export default messages;
