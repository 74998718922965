import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import { useRouteParams } from 'hooks/useRouteParams';
import PageLoader from 'components/PageLoader';
import PageHeader from 'components/PageHeader';
import MainContent from 'components/MainContent';
import toTitleCase from 'helpers/text/toTitleCase';
import { getScores } from 'actions/testPreparations';
import PageBackground from 'components/PageBackground';
import PrintGlobalStyle from 'components/PrintGlobalStyle';
import TopSection from 'components/ReliabilityTest/TopSection';
import BottomSection from 'components/ReliabilityTest/BottomSection';
import videoCameraBlue from 'images/reliabilityTest/videoCameraBlue.svg';
import logoCertification from 'images/reliabilityTest/logoCertification.svg';
import ResultSection from 'components/TestPreparations/ScoreReport/ResultSection';
import DimensionSection from 'pages/Testing/Reliability/ScoreReport/DimensionsSection';
import PerformanceReportSection from 'pages/Testing/Reliability/ScoreReport/PerformanceReportSection';

type URLParams = {
  id: string;
};

function ScoreReport() {
  const { id } = useRouteParams<URLParams>();
  const { data, run, isPending } = useAsync();

  useEffect(() => {
    run(getScores(Number(id)));
  }, [run, id]);

  function getTitle(text: string) {
    const title = toTitleCase(text);

    return title + ' ' + i18n.ft(messages.title);
  }

  if (isPending) {
    return (
      <div className="mt-8">
        <PageLoader />
      </div>
    );
  }

  return (
    <>
      <PrintGlobalStyle />
      <MainContent maxWidth={1280}>
        <PageHeader
          title={getTitle(data.video_type)}
          subtitle={i18n.ft(messages.subtitle)}
          image={logoCertification}
          imageAlt={i18n.ft(messages.logoDescription)}
        />
        <PageBackground borderStyle className="PrintBackgroundColor">
          <TopSection
            testType="preparation"
            imgIcon={videoCameraBlue}
            attempt={data.attempt}
            completedAt={data.completed_at}
            videoTitle={data.video}
          />
          <ResultSection
            title={i18n.ft(
              data.video_type === 'practice'
                ? messages.practice
                : messages.training
            )}
            testScore={data.test_score}
            ExpertCodes={data.expert_code_pdf}
          />
          <PerformanceReportSection scores={[]} testType="preparation" />
          <DimensionSection domains={data.domains} testType="preparation" />
          <BottomSection
            testType={data.test_type}
            attemptNumber={1}
            testAttemptId={data.test_attempt_id}
          />
        </PageBackground>
      </MainContent>
    </>
  );
}

export default ScoreReport;
