import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../Container';
import { StatusCard } from './StatusCard';
import RoutesHelper from 'helpers/routes';
import PageLoader from 'components/PageLoader';

import {
  getCertificationsByStatus,
  StatusForAgeLevelRes
} from 'actions/certificationDashboard';

const initialDefaultData: StatusForAgeLevelRes = {
  count: 0,
  statuses: {
    active: 0,
    expired: 0,
    expiring_soon: 0,
    seeking_initial: 0
  }
};

interface CertificationsByStatusProps {
  ageLevelId: number | null;
  classVersion: string | null;
  account: number;
  dataGeneral: StatusForAgeLevelRes | undefined;
}

export function CertificationsByStatus({
  ageLevelId,
  classVersion,
  account,
  dataGeneral
}: CertificationsByStatusProps) {
  const {
    run: runInternal,
    data: dataInternal,
    isPending
  } = useAsync<StatusForAgeLevelRes>({
    data: initialDefaultData
  });
  let data = initialDefaultData;
  if (ageLevelId) {
    data = dataInternal;
  } else if (dataGeneral) {
    data = dataGeneral;
  }

  useEffect(() => {
    const params = {
      age_level_group_id: ageLevelId,
      class_version: classVersion
    };

    if (ageLevelId) {
      runInternal(getCertificationsByStatus(params));
    }
  }, [runInternal, ageLevelId, classVersion, account]);

  return (
    <Container title={i18n.ft(messages.title)}>
      {ageLevelId && isPending ? (
        <PageLoader />
      ) : (
        <div className="flex flex-col h-full">
          <div className="grid sm:grid-cols-4 gap-4 sm:mt-8">
            <StatusCard
              icon="search"
              count={data.statuses.seeking_initial}
              title={i18n.ft(messages.seekingInitial)}
            />
            <StatusCard
              icon="active"
              count={data.statuses.active}
              title={i18n.ft(messages.current)}
            />
            <StatusCard
              icon="time"
              count={data.statuses.expiring_soon}
              title={i18n.ft(messages.expiringSoon)}
            />
            <StatusCard
              arrow={false}
              icon="expired"
              count={data.statuses.expired}
              title={i18n.ft(messages.expired)}
              titleBgColor="bg-[#ac213a]"
            />
          </div>

          <div className="mt-6 sm:mt-4 xl:mt-auto text-center sm:text-right">
            <Link
              to={RoutesHelper.getPath('certifications-observers')}
              className="font-bold text-black underline"
              state={{ ageLevelId, classVersion }}
            >
              {i18n.ft(messages.viewAll, { count: data.count })}
            </Link>
          </div>
        </div>
      )}
    </Container>
  );
}
