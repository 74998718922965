import styled from 'styled-components';
import PageLayout from 'components/PageLayout';

const Body = styled.div`
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  order: 0;
  width: 200px;
  min-width: 200px;
  margin-right: 56px;

  @media screen and (max-width: 767px) {
    order: 1;
    width: 100%;
    margin-right: 0;
  }
`;

const Main = styled.div`
  order: 1;
  width: 100%;
  min-width: 0;

  @media screen and (max-width: 767px) {
    order: 0;
  }
`;

const Layout = styled(PageLayout)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1024px;
  justify-content: space-between;
`;

Layout.Body = Body;
Layout.Sidebar = Sidebar;
Layout.Main = Main;

export default Layout;
