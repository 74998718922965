const PATH =
  'fe.components.certifications.observers_by_age_level.certifications_by_status';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Certification Status'
  },
  seekingInitial: {
    key: `${PATH}.seeking_initial`,
    defaultValue: 'Seeking Initial'
  },
  current: {
    key: `${PATH}.current`,
    defaultValue: 'Active: Current'
  },
  expiringSoon: {
    key: `${PATH}.expiring_soon`,
    defaultValue: 'Active: Expiring Soon'
  },
  expired: {
    key: `${PATH}.expired`,
    defaultValue: 'Expired'
  },
  viewAll: {
    key: `${PATH}.view_all`,
    defaultValue: 'View All ({{count}})'
  }
};

export default messages;
