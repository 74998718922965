import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const BarContainer = styled.div<{ color: string }>`
  width: 100%;
  height: 30px;
  border-radius: 4px;
  position: relative;
  background: ${props => props.color};
`;

export const Bar = styled.div<{ width: number; color: string }>`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: ${props => props.color};
  width: ${props => `${props.width}%`};
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;

  ${props =>
    props.width === 100 &&
    css`
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    `}

  ${props =>
    props.width < 100 &&
    css`
      &:after {
        content: '';
        width: 2px;
        height: 30px;
        background: white;
        position: absolute;
        top: 0;
        right: 0;
      }
    `}
`;

export const LegendContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const LegendItem = styled.div<{ color: string }>`
  margin-left: 24px;

  &::before {
    content: '';
    background-color: ${props => props.color};
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
  }
`;
