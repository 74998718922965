const PATH = 'fe.components.certifications.observers_by_age_level';

const messages = {
  total: {
    key: `${PATH}.total`,
    defaultValue: 'Total Active'
  },
  observers: {
    key: `${PATH}.observers`,
    defaultValue: 'Observers'
  },
  allLevels: {
    key: `${PATH}.all_levels`,
    defaultValue: 'All Age Levels'
  },
  noDataFound: {
    key: `${PATH}.no_data_found`,
    defaultValue: 'No active observer certifications found.'
  },
  noObservers: {
    key: `${PATH}.no_observers`,
    defaultValue:
      'It looks like you don’t have any observers in your organization yet.'
  },
  noObserversSub: {
    key: `${PATH}.no_observers_sub`,
    defaultValue:
      'Organizations with their own observers are 40% more likely to succeed in implementing CLASS.'
  },
  noObserversLink: {
    key: `${PATH}.no_observers_link`,
    defaultValue: 'Read more about the benefits of having observers'
  },
  noObserversHere: {
    key: `${PATH}.no_observers_here`,
    defaultValue: 'here'
  }
};

export default messages;
