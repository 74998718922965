import axios from 'axios';
import { KnowledgeTestSummary } from 'types/api/KnowledgeTest';
import { KnowledgeTestAttempt } from 'types/api/TestAttempt';

export function getKnowledgeTestById(knowledgeTestId: number) {
  return axios.get<KnowledgeTestAttempt>(
    `/api/v2/test_attempts/${knowledgeTestId}`
  );
}

export function setCurrentQuestion(id: number, questionId: number) {
  return axios.post<KnowledgeTestAttempt>(
    `/api/v2/test_attempts/${id}/set_current_question`,
    {
      current_question_id: questionId
    }
  );
}

export function getKnowledgeTestsByTestAttemptId(testAttemptId: number) {
  return axios.get<KnowledgeTestSummary>(
    `/api/v2/knowledge_tests/${testAttemptId}`
  );
}
