import styled from 'styled-components';
import { AGE_LEVEL_ABBREVIATIONS } from 'types/api/AgeLevel';

export const Container = styled.div<{ abbreviation: AGE_LEVEL_ABBREVIATIONS }>`
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  border-top: 5px solid
    ${({ theme, abbreviation }) =>
      theme.colors.ageLevels[abbreviation.toLowerCase()]};
  min-width: 530px;
  flex-grow: 1;

  @media screen and (max-width: 980px) {
    min-width: 0;
  }
`;

export const ChartContainer = styled.div`
  margin: 16px auto 0;
  max-width: 500px;
`;
