const PATH = 'fe.components.trainings.view.card_checkout.shipping_form';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: '2. Shipping Address'
  },
  edit: {
    key: `${PATH}.edit`,
    defaultValue: 'Edit'
  },
  continue: {
    key: `${PATH}.continue`,
    defaultValue: 'Continue'
  },
  sameAsBilling: {
    key: `${PATH}.same_as_billing`,
    defaultValue: 'Same as billing address'
  },
  newAddress: {
    key: `${PATH}.new_address`,
    defaultValue: 'Enter a new shipping address'
  }
};

export default messages;
