const PATH = 'fe.components.learning_resources.last_viewed';

const messages = {
  viewed: {
    key: `${PATH}.viewed`,
    defaultValue: 'Last Viewed'
  },
  all: {
    key: `${PATH}.all`,
    defaultValue: 'See All'
  }
};

export default messages;
