import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useAgeLevelName, useConfigTotal, useHierarchyNames } from './utils';
import { UserReportConfiguration } from 'types/api/envScales/UserReportConfiguration';
import AgeLevelTag from 'components/Certifications/AgeLevelTag';
import { ClassVersionTag } from 'components/Certifications/ClassVersionTag';

const PeopleIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99998 9.16732C4.43998 9.16732 1.33331 9.94732 1.33331 11.5007V12.6673H10.6666V11.5007C10.6666 9.94732 7.55998 9.16732 5.99998 9.16732ZM2.89331 11.334C3.45331 10.9473 4.80665 10.5007 5.99998 10.5007C7.19331 10.5007 8.54665 10.9473 9.10665 11.334H2.89331ZM5.99998 8.00065C7.28665 8.00065 8.33331 6.95398 8.33331 5.66732C8.33331 4.38065 7.28665 3.33398 5.99998 3.33398C4.71331 3.33398 3.66665 4.38065 3.66665 5.66732C3.66665 6.95398 4.71331 8.00065 5.99998 8.00065ZM5.99998 4.66732C6.55331 4.66732 6.99998 5.11398 6.99998 5.66732C6.99998 6.22065 6.55331 6.66732 5.99998 6.66732C5.44665 6.66732 4.99998 6.22065 4.99998 5.66732C4.99998 5.11398 5.44665 4.66732 5.99998 4.66732ZM10.6933 9.20732C11.4666 9.76732 12 10.514 12 11.5007V12.6673H14.6666V11.5007C14.6666 10.154 12.3333 9.38732 10.6933 9.20732ZM9.99998 8.00065C11.2866 8.00065 12.3333 6.95398 12.3333 5.66732C12.3333 4.38065 11.2866 3.33398 9.99998 3.33398C9.63998 3.33398 9.30665 3.42065 8.99998 3.56732C9.41998 4.16065 9.66665 4.88732 9.66665 5.66732C9.66665 6.44732 9.41998 7.17398 8.99998 7.76732C9.30665 7.91398 9.63998 8.00065 9.99998 8.00065Z"
      fill="#34495e"
    />
  </svg>
);

const SchoolIcon = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00004 2.25L0.583374 5.75L2.91671 7.02167V10.5217L7.00004 12.75L11.0834 10.5217V7.02167L12.25 6.38583V10.4167H13.4167V5.75L7.00004 2.25ZM10.9784 5.75L7.00004 7.92L3.02171 5.75L7.00004 3.58L10.9784 5.75ZM9.91671 9.8275L7.00004 11.42L4.08337 9.8275V7.6575L7.00004 9.25L9.91671 7.6575V9.8275Z"
      fill="#34495e"
    />
  </svg>
);

const ScheduleIcon = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99413 1.66602C3.77413 1.66602 1.16663 4.27935 1.16663 7.49935C1.16663 10.7193 3.77413 13.3327 6.99413 13.3327C10.22 13.3327 12.8333 10.7193 12.8333 7.49935C12.8333 4.27935 10.22 1.66602 6.99413 1.66602ZM6.99996 12.166C4.42163 12.166 2.33329 10.0777 2.33329 7.49935C2.33329 4.92102 4.42163 2.83268 6.99996 2.83268C9.57829 2.83268 11.6666 4.92102 11.6666 7.49935C11.6666 10.0777 9.57829 12.166 6.99996 12.166ZM7.29163 4.58268H6.41663V8.08268L9.47913 9.92018L9.91663 9.20268L7.29163 7.64518V4.58268Z"
      fill="#34495e"
    />
  </svg>
);

export interface ConfigurationCardProps {
  config: UserReportConfiguration;
  cardNumber?: number;
}

export function ConfigurationCard({
  config,
  cardNumber
}: ConfigurationCardProps) {
  const nodesMap = useHierarchyNames(config.account_id);
  const ageLevel = useAgeLevelName(config.age_level);
  const totalObs = useConfigTotal(config);

  const nodeNames = config.hierarchy_selections
    .map(nodeId => nodesMap[nodeId])
    .join(', ');

  function formatDates() {
    const startDate = moment.utc(config.start_date).format('L');
    const endDate = moment.utc(config.end_date).format('L');

    return `${startDate} - ${endDate}`;
  }

  return (
    <div className="border border-[#A7AAAA] rounded-xl flex mb-2">
      <div className="w-6 rounded-l-xl bg-[#3C3F42] border-r border-[#A7AAAA] flex justify-center items-center text-white font-bold">
        {cardNumber && <div>{cardNumber}</div>}
      </div>
      <div className="flex-1 grid grid-cols-3">
        <div className="row-span-2 py-2.5 px-4 border-r border-[#A7AAAA]">
          <div className="text-xs font-semibold flex gap-1">
            <PeopleIcon />
            <span>{i18n.ft(messages.hierarchy)}</span>
          </div>
          <div>{nodeNames}</div>
        </div>

        <div className="py-2.5 px-4 border-b border-r border-[#A7AAAA]">
          <div className="text-xs font-semibold flex gap-1">
            <SchoolIcon />
            <span>{i18n.ft(messages.assessmentLevel)}</span>
          </div>
          <div className="flex items-center gap-1.5">
            <AgeLevelTag ageLevel={ageLevel} code={config.age_level} />
            <ClassVersionTag classVersion={config.class_version} />
          </div>
        </div>

        <div className="row-span-2 bg-[#DBEAF4] py-2.5 px-4 rounded-r-xl flex">
          <div className="text-sm font-bold flex items-center gap-3">
            <span className="text-4xl">{totalObs}</span>
            <span>{i18n.ft(messages.totalObservations)}</span>
          </div>
        </div>

        <div className="py-2.5 px-4 border-r border-[#A7AAAA]">
          <div className="text-xs font-semibold flex gap-1">
            <ScheduleIcon />
            <span>{i18n.ft(messages.timeframe)}</span>
          </div>
          <div className="text-sm font-bold">{formatDates()}</div>
        </div>
      </div>
    </div>
  );
}
