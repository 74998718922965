const PATH = 'fe.pages.certifications.root';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Certifications'
  },
  mustSelectAccount: {
    key: `${PATH}.must_select_account`,
    defaultValue: 'You must select an account.'
  },
  myCertifications: {
    key: `${PATH}.my_certifications`,
    defaultValue: 'My Certifications'
  },
  organizationCertifications: {
    key: `${PATH}.organization_certifications`,
    defaultValue: `Organization's Certifications`
  }
};

export default messages;
