import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import colors from 'styles/colors';
import { head, split } from 'ramda';
import AgeLevel from 'components/AgeLevel';
import Typography from 'components/Typography';
import { Container, ChartContainer } from './Styled';
import { AGE_LEVEL_ABBREVIATIONS } from 'types/api/AgeLevel';
import { Header, LegendContainer, LegendItem } from '../OverallChart/Styled';

import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import getPassLabel from '../utils';
import { TestType } from 'types/api/testType';
import { PassFailLabel } from '../types';

interface AttemptsBarChartProps {
  /** The number of users who completed and passed on the first attempt.  */
  attempt01: { passed: number; total: number };

  /** The number of users who completed and passed on the second attempt.  */
  attempt02: { passed: number; total: number };

  /** The number of users who completed and passed on the third attempt.  */
  attempt03: { passed: number; total: number };

  /** Age level abbreviation. */
  abbreviation: AGE_LEVEL_ABBREVIATIONS;

  /** Test type. */
  testType?: TestType;
}

const AttemptsBarChart: React.FC<AttemptsBarChartProps> = ({
  attempt01,
  attempt02,
  attempt03,
  abbreviation,
  testType = 'reliability'
}) => {
  const data = [
    {
      name: i18n.ft(messages.attempt, { value: 1, total: attempt01.total }),
      reliable: attempt01.passed,
      failed: attempt01.total - attempt01.passed
    },
    {
      name: i18n.ft(messages.attempt, { value: 2, total: attempt02.total }),
      reliable: attempt02.passed,
      failed: attempt02.total - attempt02.passed
    },
    {
      name: i18n.ft(messages.attempt, { value: 3, total: attempt03.total }),
      reliable: attempt03.passed,
      failed: attempt03.total - attempt03.passed
    }
  ];

  const header =
    testType === 'knowledge'
      ? messages.knowledge.header
      : messages.reliability.header;

  const passLabel = getPassLabel(testType);

  return (
    <Container abbreviation={abbreviation}>
      <Header>
        <Typography bold size="huge">
          {i18n.ft(header)}
        </Typography>

        <AgeLevel replaceK3 abbreviation={abbreviation} />
      </Header>

      <ChartContainer>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={data}>
            <XAxis
              dataKey="name"
              tickFormatter={t => String(head(split('-', t)))}
            />
            <YAxis
              label={{
                angle: -90,
                dx: -15,
                position: 'outside',
                value: i18n.ft(messages.tested)
              }}
            />
            <Tooltip
              cursor={{ fill: colors.reliability.highlight, opacity: '35%' }}
              itemStyle={{ color: 'black' }}
              labelStyle={{ fontWeight: 'bold' }}
              formatter={(value: string, name: string) => {
                const valueLabel = name as PassFailLabel;
                return [value, i18n.ft(messages[valueLabel])];
              }}
            />
            <Bar
              stackId="a"
              dataKey="reliable"
              fill={colors.reliability.passed}
              isAnimationActive={false}
            />
            <Bar
              stackId="a"
              dataKey="failed"
              fill={colors.reliability.failed}
              isAnimationActive={false}
            />
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>

      <LegendContainer>
        <LegendItem color={colors.reliability.passed}>
          {i18n.ft(messages[passLabel])}
        </LegendItem>
        <LegendItem color={colors.reliability.failed}>
          {i18n.ft(messages.failed)}
        </LegendItem>
      </LegendContainer>
    </Container>
  );
};

export default AttemptsBarChart;
