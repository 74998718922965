import axios from 'axios';

import {
  TrainingHistory,
  TrainingHistorySummary
} from 'types/api/TrainingHistory';

export function getTrainingHistoryByUserId(userId: number, params = {}) {
  return axios.get<TrainingHistory[]>(
    `/api/v2/users/${userId}/training_history`,
    { params }
  );
}

export function getTrainingHistorySummary(userId: number) {
  return axios.get<TrainingHistorySummary>(
    `/api/v2/users/${userId}/training_history/summary`
  );
}
