import * as yup from 'yup';
import { assoc } from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import PropTypes from 'prop-types';
import { Checkbox } from './Styled';
import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import AddressForm, { addressSchema } from '../AddressForm';
import useStepStatus from '../useStepStatus';
import AddressPreview from '../AddressPreview';
import { addShippingAddress } from 'actions/trainings';
import { Formik, Form } from 'components/EnhancedForm';

import {
  Container,
  SubmitButton,
  Header,
  HeaderIcon,
  HeaderButton,
  HeaderContainer
} from '../Styled';

const validationSchema = yup.object(addressSchema);

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  address1: '',
  address2: '',
  country_code: '',
  state_or_province_code: '',
  city: '',
  postal_code: '',
  phone: '',
  addressType: 'same'
};

const ShippingForm = ({
  trainingId,
  afterSubmit,
  billingAddress,
  step,
  activeStep,
  onStepChange
}) => {
  const status = useStepStatus(step, activeStep);
  const [loading, setLoading] = useState(false);

  function handleEdit() {
    onStepChange(step);
  }

  function handleSubmit(values) {
    let address = values.addressType === 'same' ? billingAddress : values;
    address = assoc('email', billingAddress.email, address);

    setLoading(true);
    addShippingAddress(trainingId, address).then(({ data }) => {
      setLoading(false);
      onStepChange(step + 1);
      afterSubmit(data.consignments[0], data);
    });
  }

  return (
    <Container>
      <HeaderContainer>
        <Header fitted={status === 'pending'}>
          {i18n.ft(messages.header)}
          {status === 'completed' && (
            <HeaderIcon>
              <Icon name="check circle" color="green" />
            </HeaderIcon>
          )}
        </Header>

        {status === 'completed' && (
          <HeaderButton
            inverted
            size="tiny"
            color="blue"
            onClick={handleEdit}
            content={i18n.ft(messages.edit)}
          />
        )}
      </HeaderContainer>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, submitForm }) => (
          <Form>
            {status === 'active' && (
              <React.Fragment>
                <Checkbox
                  radio
                  value="same"
                  name="addressType"
                  label={i18n.ft(messages.sameAsBilling)}
                />
                <Checkbox
                  radio
                  value="new"
                  name="addressType"
                  label={i18n.ft(messages.newAddress)}
                />

                {values.addressType === 'new' && <AddressForm skipEmail />}

                <SubmitButton>
                  <Button
                    color="blue"
                    loading={loading}
                    disabled={loading}
                    onClick={submitForm}
                  >
                    {i18n.ft(messages.continue)}
                  </Button>
                </SubmitButton>
              </React.Fragment>
            )}
            {status === 'completed' && (
              <AddressPreview
                address={
                  values.addressType === 'same' ? billingAddress : values
                }
                skipEmail
              />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

ShippingForm.propTypes = {
  /** The training ID. */
  trainingId: PropTypes.number.isRequired,

  /**
   * Callback fired after the form is submitted.
   * Returns a Big Commerce consignment and a checkout object.
   **/
  afterSubmit: PropTypes.func.isRequired,

  /** Billing address object. Set after the billing address is submitted. */
  billingAddress: PropTypes.object,

  /** The shipping form step number. */
  step: PropTypes.number.isRequired,

  /** The current active step number. */
  activeStep: PropTypes.number.isRequired,

  /** Callback triggered when the step request to be changed. */
  onStepChange: PropTypes.func.isRequired
};

export default ShippingForm;
