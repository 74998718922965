import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { Bar, AccountSelect } from './Styled';
import { defaultFormatter } from 'components/dropdowns/utils';
import FormDropdown from 'components/FormDropdown';
import { chooseAccount } from 'actions/accountManager/accounts';
import { SelectedAccount } from 'types/api/Account';
import { fetchUserMe } from 'actions/userMe';
import { useDispatch } from 'react-redux';
import { update } from 'store/slices/user';

interface AccountManagerProps {
  /** Callback fired when the account selection changes. */
  onChangeAccount?: (accountId: number) => void;

  /** list of all accounts the user has access */
  accounts: SelectedAccount[];

  /** current account */
  currentAccountId: number;

  /** Flag to indicate if fetching accounts is still happening. Used in loading icon  */
  isLoadingAccounts: boolean;

  /** The area of the system in which the component is placed */
  area:
    | 'ObservationReports'
    | 'ManageOrganization'
    | 'DataCollection'
    | 'CertificationsDashboard';
}

function AccountManager({
  onChangeAccount,
  accounts,
  currentAccountId,
  isLoadingAccounts,
  area
}: AccountManagerProps) {
  const [isChoosing, setIsChoosing] = useState(false);
  const dispatch = useDispatch();

  async function handleChange(accountId: number) {
    setIsChoosing(true);
    await chooseAccount(accountId, area);
    const { data } = await fetchUserMe();
    dispatch(update(data));
    onChangeAccount?.(accountId);
    setIsChoosing(false);
  }

  const options = R.map(defaultFormatter, accounts ?? []);

  return (
    <Bar>
      <AccountSelect>
        <FormDropdown
          selectOnBlur={false}
          value={currentAccountId}
          options={options}
          loading={isLoadingAccounts || isChoosing}
          placeholder={i18n.ft(messages.selectAccount)}
          onChange={(_, data) => handleChange(Number(data.value))}
        />
      </AccountSelect>
    </Bar>
  );
}

export default AccountManager;
