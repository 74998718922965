import i18n from 'helpers/i18n';
import messages from './messages';
import colors from 'styles/colors';
import { Icon } from 'semantic-ui-react';
import { THRESHOLD, flexColReverse } from '../constants';
import { getChartData } from 'components/ReliabilityTest/utils';
import Chart from './Chart';
import { Status as StatusIcon } from 'components/Reports/Reliability/View';
import {
  MessageBox,
  Score,
  ChartContainer
} from 'components/ReliabilityTest/Styled';

interface DoughnutChartPaneProps {
  testScore: number;
  scoreAbove: string;
  scoreBelow: string;
  videoCriteria: string;
  knowledgeTest?: boolean;
}

function DoughnutChartPanel({
  testScore,
  scoreAbove,
  scoreBelow,
  videoCriteria,
  knowledgeTest
}: DoughnutChartPaneProps) {
  const chartData = getChartData(testScore);

  return (
    <div className={`mx-auto text-center ${flexColReverse}`}>
      {/* bottom item */}
      <MessageBox className="PrintBackgroundColor mt-5">
        <div className="flex justify-center">
          <div className="align-middle pt-4 pb-4 text-xl" tabIndex={0}>
            {testScore >= THRESHOLD ? scoreAbove : scoreBelow}{' '}
            <span className="font-bold mr-4">{THRESHOLD}%</span>
          </div>
          <div className="min-w-8 self-center">
            <StatusIcon
              iconColor={colors.white}
              iconBackGroundColor={
                testScore >= THRESHOLD
                  ? colors.reliability.passVideo
                  : colors.reliability.failedAttempt
              }
            >
              <Icon
                name={testScore >= THRESHOLD ? 'check' : 'close'}
                size="tiny"
                className="align-middle translate-y-[1px]"
                style={{ margin: 'auto' }}
              />
            </StatusIcon>
          </div>
        </div>
      </MessageBox>
      {/* middle item */}
      <div className={`${knowledgeTest ? '' : 'mt-auto mb-auto'}`} aria-hidden>
        {videoCriteria}
      </div>
      {/* top item */}
      <ChartContainer
        className={`${knowledgeTest ? 'my-auto' : ''}`}
        aria-labelledby="test-score"
        tabIndex={0}
      >
        <Chart data={chartData} />
        <Score>{testScore}%</Score>
        <span className="sr-only" id="test-score">
          {i18n.ft(messages.score)} {testScore}%
        </span>
      </ChartContainer>
    </div>
  );
}

export default DoughnutChartPanel;
