import axios from './axiosConfig';
import { ObservationValidations } from 'types/api/envScales/ObservationValidations';

export function updateObservation(id: number, params = {}) {
  return axios.put(`/api/v1/observations/${id}`, params);
}

export function getObservationSuggestions(id: number) {
  return axios.get(`/api/v1/observations/${id}/suggestions`);
}

export function getObservationValidations(id: number) {
  return axios.get<ObservationValidations>(
    `/api/v1/observations/${id}/validations`
  );
}

export function getObservationAverages(id: number) {
  return axios.get(`/api/v1/observations/${id}/score_averages`);
}

export function uploadAttachment(id: number, payload: FormData, config = {}) {
  return axios.post(`/api/v1/observations/${id}/attachments`, payload, config);
}

export function deleteAttachment(id: number, attachmentId: number) {
  return axios.delete(`/api/v1/observations/${id}/attachments/${attachmentId}`);
}

export function getAttachments(id: number) {
  return axios.get(`/api/v1/observations/${id}/attachments`);
}

export function getKalturaClientToken(observationId: number) {
  return axios.get(`/api/v1/observations/${observationId}/videos/client_token`);
}

export function attachVideo(
  observationId: number,
  payload: { upload_token: string; name: string }
) {
  return axios.post(`/api/v1/observations/${observationId}/videos`, payload);
}

export function deleteVideo(observationId: number, videoId: number) {
  return axios.delete(
    `/api/v1/observations/${observationId}/videos/${videoId}`
  );
}

export function getVideo(observationId: number, videoId: string) {
  return axios.get(`/api/v1/observations/${observationId}/videos/${videoId}`);
}
