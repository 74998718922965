import styled from 'styled-components';

export const InputWrapper = styled.div`
  max-width: 350px;
`;

export const TextWrapper = styled.div`
  margin-top: -8px;
  margin-bottom: 32px;
  max-width: 550px;
  font-size: 16px;
  line-height: 24px;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`;

export const TermsWrapper = styled.div`
  font-size: 14px;
  line-height: 20px;
  max-width: 550px;
  color: ${p => p.theme.colors['neutral']['500']};
  margin-top: 24px;
  margin-bottom: 16px;
`;
