import { useState } from 'react';
import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Box } from 'components/Box';
import { Button } from 'semantic-ui-react';
import { getFullName } from 'helpers/user';
import { formatHours, hasCEUs } from './utils';
import { TrainingHistory } from 'types/api/TrainingHistory';

import {
  Wrapper,
  Title,
  TextDate,
  TextBold,
  Dates,
  DatesMobile,
  DetailsTop,
  DetailsBottom,
  Hours,
  Ceus,
  Circle,
  ButtonWrapper
} from './Styled';

const colors = {
  hours: '#ebe0ff',
  ceus: '#d7f3ff'
};

interface TrainingCardProps {
  /** Training History record. */
  training: TrainingHistory;
}

function TrainingCard({ training }: TrainingCardProps) {
  const [loadingCertificate, setLoadingCertificate] = useState(false);
  const isLearningPathway = ['LearningPathwayEnrollment'].includes(
    training.source_type || ''
  );
  const isCourse = [
    'SisEnrollment',
    'SisCourse',
    'LegacyTotaraCourse'
  ].includes(training.source_type || '');
  const dateContent = isCourse ? (
    <>
      <span>{i18n.ft(messages.completedOn)}</span>
      <TextDate>{moment(training.end_date).format('MM/DD/YY')}</TextDate>
    </>
  ) : (
    <>
      <TextDate>{moment(training.start_date).format('MM/DD/YY')}</TextDate>
      <TextBold>{i18n.ft(messages.to)}</TextBold>
      <TextDate>{moment(training.end_date).format('MM/DD/YY')}</TextDate>
    </>
  );

  const fetchCertificate = () => {
    setLoadingCertificate(true);
    fetch(`/api/v2/training_certificates/${training.certificate?.id}.pdf`)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${training.name} Certificate.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
        setLoadingCertificate(false);
      });
  };

  return (
    <Wrapper>
      <DetailsTop>
        <div>
          <Title>{training.name}</Title>
          <Box mt="8px">
            {isCourse
              ? i18n.ft(messages.onlineCourse)
              : isLearningPathway
                ? i18n.ft(messages.learningPathway)
                : i18n.ft(messages.trainer, {
                    name: getFullName(training.trainer)
                  })}
          </Box>
        </div>

        <Dates>{dateContent}</Dates>
      </DetailsTop>

      <DetailsBottom>
        <Hours>
          {i18n.ft(messages.hours)}
          <Circle color={colors.hours}>
            {formatHours(training.certificate?.hours)}
          </Circle>
        </Hours>

        {hasCEUs(training.certificate?.ceus_earned) && (
          <Ceus>
            {i18n.ft(messages.ceus)}
            <Circle color={colors.ceus}>
              {training.certificate?.ceus_earned}
            </Circle>
          </Ceus>
        )}

        <DatesMobile>{dateContent}</DatesMobile>

        {Boolean(training.certificate) && (
          <ButtonWrapper>
            <Button
              fluid
              color="blue"
              download
              disabled={loadingCertificate}
              onClick={fetchCertificate}
            >
              {loadingCertificate
                ? i18n.ft(messages.downloading)
                : i18n.ft(messages.certificate)}
            </Button>
          </ButtonWrapper>
        )}
      </DetailsBottom>
    </Wrapper>
  );
}

export default TrainingCard;
