const PATH = 'fe.components.app.header.sidebar_nav';

const messages = {
  close: {
    key: `${PATH}.close`,
    defaultValue: 'Close navigation menu'
  },
  help: {
    key: `${PATH}.help`,
    defaultValue: 'Help'
  },
  settings: {
    key: `${PATH}.settings`,
    defaultValue: 'User Settings'
  },
  logout: {
    key: `${PATH}.logout`,
    defaultValue: 'Log Out'
  },
  learningResources: {
    key: `${PATH}.learning_resources`,
    defaultValue: 'Learning Resources 2.0'
  }
};

export default messages;
