import i18n from 'helpers/i18n';
import messages from './messages';
import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import { Icon } from 'semantic-ui-react';
import { GenericCheckbox } from 'components/GenericCheckbox';
import { MetaDataChoice } from 'types/api/envScales/MetaDataChoice';
import { ItemWrapper, CheckedSquare, NotCheckedSquare } from './Styled';

type ValueType = { isChecked: boolean; isPrimary: boolean };

export interface MetaDataOptionProps {
  /** Meta data choice. */
  choice: MetaDataChoice;

  /** Meta data option value. */
  value: ValueType;

  /** Callback fired when the value changes. */
  onChange: (data: { name: string; value: ValueType }) => void;
}

export function MetaDataOption({
  choice,
  value,
  onChange
}: MetaDataOptionProps) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = event.target.checked;

    if (isChecked) {
      onChange({ name: choice.t_name, value: { ...value, isChecked } });
    } else {
      onChange({ name: choice.t_name, value: { isChecked, isPrimary: false } });
    }
  }

  function handlePrimary(event: React.ChangeEvent<HTMLInputElement>) {
    const isPrimary = event.target.checked;

    if (isPrimary) {
      onChange({ name: choice.t_name, value: { isPrimary, isChecked: true } });
    } else {
      onChange({ name: choice.t_name, value: { ...value, isPrimary } });
    }
  }

  return (
    <ItemWrapper>
      <Flex align="center">
        <GenericCheckbox
          aria-label={choice.name}
          onChange={handleChange}
          checked={value.isChecked}
          type="checkbox"
        >
          {value.isChecked ? <CheckedSquare /> : <NotCheckedSquare />}
        </GenericCheckbox>
        <Box as="span" ml="16px">
          {choice.name}
        </Box>
      </Flex>
      <GenericCheckbox
        aria-label={i18n.ft(messages.primary, { choice: choice.name })}
        onChange={handlePrimary}
        checked={value.isPrimary}
        type="checkbox"
      >
        {value.isPrimary ? (
          <Icon fitted size="large" color="yellow" name="star" />
        ) : (
          <Icon fitted size="large" color="grey" name="star outline" />
        )}
      </GenericCheckbox>
    </ItemWrapper>
  );
}
