const PATH = 'fe.components.measure.classroom_examples';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Observation Feedback'
  },
  strengthExample: {
    key: `${PATH}.strength_example`,
    defaultValue: 'Strength Example'
  },
  growthExample: {
    key: `${PATH}.growth_example`,
    defaultValue: 'Area of Growth Example'
  },
  editFeedback: {
    key: `${PATH}.edit_feedback`,
    defaultValue: 'Edit observation feedback'
  },
  dimension: {
    key: `${PATH}.dimension`,
    defaultValue: 'Dimension'
  },
  indicator: {
    key: `${PATH}.indicator`,
    defaultValue: 'Indicator'
  },
  readMore: {
    key: `${PATH}.read_more`,
    defaultValue: 'Read more...'
  },
  readLess: {
    key: `${PATH}.read_less`,
    defaultValue: 'Read less...'
  }
};

export default messages;
