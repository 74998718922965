import styled from 'styled-components';

export const Name = styled.span`
  font-weight: 700;
  color: ${p => p.theme.colors['scaleDarkBlue']};
`;

export const CheckboxGrid = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: fit-content;
`;

export const Checkbox = styled.span<{ checked: boolean }>`
  border-radius: 12px;
  padding: 2px 12px;
  font-size: 14px;
  background-color: ${p =>
    p.checked ? p.theme.colors['scaleGreen'] : p.theme.colors['white']};
  color: ${p =>
    p.checked ? p.theme.colors['white'] : p.theme.colors['neutral']['500']};

  @media screen and (min-width: 640px) {
    padding: 4px 24px;
    font-size: 16px;
  }
`;

export const NAWrapper = styled.div`
  grid-column: span 3 / span 3;

  @media screen and (min-width: 768px) {
    grid-column: auto;
  }
`;

export const Filler = styled.div`
  grid-column: span 1 / span 1;
  width: 100%;
`;
