import styled from 'styled-components';

export const Title = styled.p`
  margin-top: 0;
  margin-bottom: 0.2em;
  color: ${p => p.theme.colors.blue};
  font-weight: bold;
`;

export const Subtitle = styled.p`
  margin-top: 0;
  margin-bottom: 2em;
`;

export const NoFileMessage = styled.p`
  margin-top: 2em;
  margin-bottom: 0;
  text-align: center;
`;

export const FileErrors = styled.div`
  margin-top: 2.5em;
  margin-bottom: 0;
  text-align: center;
`;

export const FileError = styled.p`
  margin: 0;
  padding: 0;
  color: ${p => p.theme.colors.red};
  font-size: 14px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 841px) {
    flex-direction: row;

    & > * {
      min-width: 192px;
    }
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;
