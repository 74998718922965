import styled from 'styled-components';

export const Container = styled.div`
  width: 60%;
  position: relative;
  display: inline-block;
`;

// Defines aspect ratio - in this case 16:9 ~ 56.25%
export const AspectRatio = styled.div`
  margin-top: 56.25%;
`;

export const VideoPlayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  left: 0;
  right: 0;
  bottom: -36px;
  border: solid thin black;
`;
