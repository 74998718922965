import i18n from 'helpers/i18n';
import messages from './messages';
import closeIconBlack from 'images/learningResources/close_x_icon_black.svg';

interface TitleProps {
  step: number;
  setStep: (value: number) => void;
  setShowConfirmationModal: (value: boolean) => void;
  recommendedVideosCount: number;
}

function Title({
  step,
  setStep,
  setShowConfirmationModal,
  recommendedVideosCount
}: TitleProps) {
  return (
    <>
      <div className="flex">
        <div className="text-3xl text-black font-bold w-full">
          {i18n.ft(messages.resources)}
        </div>
        <div
          className="self-center cursor-pointer"
          onClick={() => setShowConfirmationModal(false)}
        >
          <div className="inline-block align-middle">
            <div className="font-semibold mt-4 sm:mt-0 text-black mx-4 text-lg line-clamp-2 leading-5 pb-4 sm:pb-0">
              <img src={closeIconBlack} alt="" />
            </div>
          </div>
        </div>
      </div>
      {step === 0 ? (
        <div className="pt-4 text-black">{`Selected Resources (${recommendedVideosCount})`}</div>
      ) : (
        <div className="pt-4 text-[#0A9CCA] font-bold">
          <span
            className="cursor-pointer"
            onClick={() => setStep(step - 1)}
          >{`< ${i18n.ft(messages.back)}`}</span>
        </div>
      )}
    </>
  );
}

export default Title;
