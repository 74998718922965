import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Locales = 'en' | 'es' | 'fr';

const DEFAULT_LOCALE: Locales = 'en';
const SUPPORTED_LOCALES: Locales[] = ['en', 'es', 'fr'];

function getInitialState(): Locales {
  const language = window.navigator?.language ?? '';

  // Only take first 2 characters from browser language to support
  // variations of the same language. e.g (en-US, en-AU, en-CA defaults to en)
  const browserLanguage = language.substring(0, 2) as any;

  return SUPPORTED_LOCALES.includes(browserLanguage)
    ? (browserLanguage as Locales)
    : DEFAULT_LOCALE;
}

const localeSlice = createSlice({
  name: 'locale',
  initialState: getInitialState,
  reducers: {
    update: (_state, action: PayloadAction<Locales>) => {
      return action.payload;
    }
  }
});

export const { update } = localeSlice.actions;
export default localeSlice.reducer;
