interface Trainer {
  id: number;
  first_name: string;
  last_name: string;
  type_id?: number;
}

interface FormattedTrainerOption {
  key: number;
  value: number;
  text: string;
  type_id?: number;
}

export function formatTrainerOption(trainer: Trainer): FormattedTrainerOption {
  const formattedTrainer: FormattedTrainerOption = {
    key: trainer.id,
    value: trainer.id,
    text: `${trainer.first_name} ${trainer.last_name}`
  };

  if (trainer.type_id !== undefined) {
    formattedTrainer.type_id = trainer.type_id;
  }

  return formattedTrainer;
}
