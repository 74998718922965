import themeColors from 'styles/colors';
import styled from 'styled-components';

const colors = {
  white: themeColors.white,
  blue: themeColors.primary[700],
  darkBlue: themeColors.scaleDarkBlue,
  gray: themeColors.scaleGray
};

const TextButton = styled.button<{ color?: keyof typeof colors }>`
  color: ${p => colors[p.color || 'blue']};
  background-color: transparent;
  font-family: inherit;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border: none;
  padding: 0;
  outline-offset: 4px;
  text-align: left;

  &:hover {
    color: ${p => colors[p.color || 'blue']};
  }
`;

export default TextButton;
