import React, { useContext } from 'react';
import Details from './Details';
import TrainingContext from '../TrainingContext';
import { Section } from '../Styled';
import { Body, TrainingBanner } from './Styled';
import { getGroupBaseColor } from 'helpers/ageLevel';

function SummaryBanner() {
  const { training, template } = useContext(TrainingContext);

  return (
    <Section>
      <Section.Top color={getGroupBaseColor(training.age_levels)} />
      <Body>
        <TrainingBanner src={template.banner} />
        <Details />
      </Body>
    </Section>
  );
}

export default SummaryBanner;
