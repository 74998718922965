const PATH = 'fe.components.onboarding.familiar_with_class';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'How Familiar Are You With CLASS?'
  },
  pickOne: {
    key: `${PATH}.pick_one`,
    defaultValue: 'Pick the one that fits best'
  },
  notFamiliar: {
    key: `${PATH}.not_familiar`,
    defaultValue: 'Not familiar'
  },
  littleFamiliar: {
    key: `${PATH}.little_familiar`,
    defaultValue: 'Little familiar'
  },
  veryFamiliar: {
    key: `${PATH}.very_familiar`,
    defaultValue: 'Very familiar'
  },
  descNotFamiliar: {
    key: `${PATH}.desc_not_familiar`,
    defaultValue: `It's possible I've heard about CLASS but I'm not familiar with what it means, how it works, or the impact it can have on the work I do.`
  },
  descLittleFamiliar: {
    key: `${PATH}.desc_little_familiar`,
    defaultValue:
      'I have had an introduction to CLASS and I know it focuses on interactions, but I do not often think about CLASS or use CLASS concepts in my daily work.'
  },
  descVeryFamiliar: {
    key: `${PATH}.desc_very_familiar`,
    defaultValue:
      'I understand the CLASS tool and can share key concepts and strategies with others. I have had some training on the CLASS tool, and often use concepts in my daily work.'
  },
  next: {
    key: `${PATH}.next`,
    defaultValue: 'Next'
  },
  optionOne: {
    key: `${PATH}.option_one`,
    defaultValue: ' Option 1 of 3'
  },
  optionTwo: {
    key: `${PATH}.option_two`,
    defaultValue: ' Option 2 of 3'
  },
  optionThree: {
    key: `${PATH}.option_three`,
    defaultValue: ' Option 3 of 3'
  }
};

export default messages;
