import styled from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 12px;

  &:nth-child(2n-1) {
    background-color: #f4f8fa;
  }

  @media screen and (min-width: 640px) {
    padding: 8px 24px;
  }
`;

export const Wrapper = styled.div`
  color: #364a5e;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
`;

export const Title = styled.div`
  font-weight: 700;
`;

export const CheckedRadio = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 15px;
  background-color: ${p => p.theme.colors['scaleGreen']};
`;

export const NotCheckedRadio = styled(CheckedRadio)`
  background-color: transparent;
  border: 1px solid #000000;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
