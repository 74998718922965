import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Loader } from 'semantic-ui-react';
import { getHoursMinutes } from '../utils';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { LearningResource } from 'types/api/LearningResource';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import { Formik } from 'components/EnhancedForm';
import SearchTitle from 'components/LearningResources/SearchTitle';
import VideoResource from 'components/LearningResources/VideoResource';
import SortDropdown from 'components/LearningResources/SortDropdown';
import {
  getLearningResources,
  getLearningResourceCategories,
  getLearningResourcesByCategory
} from 'actions/learningResources';
import { useMediaQuery } from 'react-responsive';
import useCurrentUser from 'hooks/useCurrentUser';

function AllResourcesPage() {
  const DEFAULT_PER_PAGE = 4;
  const navigate = useNavigate();
  const user = useCurrentUser();
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const timerRef = useRef<undefined | NodeJS.Timeout>(undefined);
  const loadMoreRef = useRef<HTMLButtonElement>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [categories, setCategories] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState(
    sessionStorage.getItem('lr_selectedCategory') || 'all'
  );
  const [learningResources, setLearningResources] = useState<
    LearningResource[]
  >([]);
  const { state, setState } = usePaginatedSearch({
    page: currentPage,
    query: '',
    sort_by: '',
    sort_dir: '',
    per_page: DEFAULT_PER_PAGE
  });
  const isMobile = useMediaQuery({ minWidth: 640 });

  useEffect(() => {
    getLearningResourceCategories()
      .then(res => {
        if (!res.data.tags) {
          return;
        }
        setCategories([
          { description: i18n.ft(messages.all), id: 'all' },
          ...res.data.tags,
          { description: i18n.ft(messages.watched), id: 'watched' },
          { description: i18n.ft(messages.new), id: 'new' }
        ]);
      })
      .catch(() => {
        setCategories([{ description: i18n.ft(messages.all), id: 'all' }]);
      });
  }, []);

  useEffect(() => {
    if (!timerRef.current) {
      return () => clearTimeout(timerRef.current);
    }
  }, []);

  useEffect(() => {
    getResources(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, state]);

  function handleClick(id: number) {
    if (user.roles.includes('lr_coach')) {
      return navigate(
        RouteHelpers.getPath('coach-learning-resources-detail-page', {
          id
        })
      );
    }

    return navigate(
      RouteHelpers.getPath('learning-resources-detail-page', {
        id
      })
    );
  }

  function loadMore() {
    setIsFetching(true);
    getResources();
    setPerPage(perPage + DEFAULT_PER_PAGE);
  }

  function handleSort(value: string) {
    const direction = value === 'name' ? 'asc' : 'desc';
    setState({
      ...state,
      sort_by: value,
      sort_dir: direction,
      per_page: perPage
    });
  }

  function getResources(refresh = false) {
    const page = refresh ? 1 : currentPage + 1;
    const action =
      selectedCategory === 'all'
        ? getLearningResources
        : getLearningResourcesByCategory;

    action({ ...state, page: page }, selectedCategory)
      .then(res => {
        if (!res.data.learning_resources) {
          return;
        }
        res.data.learning_resources.length === 0
          ? setDisableLoadMore(true)
          : setDisableLoadMore(false);
        setCurrentPage(prevPageNo => prevPageNo + 1);
        refresh
          ? setLearningResources(res.data.learning_resources)
          : setLearningResources([
              ...learningResources,
              ...res.data.learning_resources
            ]);
      })
      .catch(() => {})
      .finally(() => {
        setIsFetching(false);
        if (refresh) {
          setCurrentPage(1);
        }
        if (isMobile) {
          timerRef.current = setTimeout(() => {
            window.scrollTo({
              top: loadMoreRef?.current?.offsetTop,
              behavior: 'smooth'
            });
          }, 300);
        }
      });
  }

  function setCategory(id: string) {
    sessionStorage.setItem('lr_selectedCategory', id);
    setSelectedCategory(id);
    setPerPage(DEFAULT_PER_PAGE);
  }

  if (!learningResources) {
    return <Loader active />;
  }

  return (
    <div className="mt-8 mb-16 px-4 md:px-0" style={{ overflowAnchor: 'auto' }}>
      <div className="float-right mb-6">
        <Formik initialValues={{ sort: 'id' }} onSubmit={() => {}}>
          <SortDropdown handleSort={handleSort} />
        </Formik>
      </div>

      <div
        className="flex sm:justify-center my-10 gap-2 overflow-x-scroll sm:flex-wrap clear-both"
        style={{ scrollbarWidth: 'none' }}
      >
        {categories &&
          R.map(
            (category: any) => (
              <button
                className={`${
                  category.id.toString() === selectedCategory.toString()
                    ? 'bg-[#3C3F42] text-white'
                    : 'bg-[#f3f4f6] text-[#3C3F42]'
                } flex items-center rounded !border p-3 border-[#3C3F42] max-h-10 mb-2`}
                key={category.id}
                onClick={() => setCategory(category.id)}
              >
                <span className=" font-semibold text-base leading-5 text-nowrap">
                  {category.description}
                </span>
              </button>
            ),
            categories
          )}
      </div>

      <SearchTitle
        selectedCategoryId={selectedCategory}
        categories={categories}
        learningResourcesLength={learningResources.length}
      />

      <div className="grid grid-cols-1 gap-6 my-4 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {learningResources?.map((video: LearningResource, idx: number) => (
          <VideoResource
            ariaLabel={'Video Thumbnail'}
            ariaRoleDescription={'Slide'}
            handleClick={handleClick}
            video={{
              name: video.name,
              id: video.id ? video.id : idx,
              videoURI: video.video_uri ? video.video_uri : '1_bkuk1xi7',
              estimatedTime: video.estimated_time
                ? getHoursMinutes(video.estimated_time)
                : '0'
            }}
            key={video.id}
            tags={video.tags}
            showAllResources
          />
        ))}
      </div>
      <div className="flex justify-center mt-8">
        {isFetching ? (
          <Loader active inline="centered" />
        ) : (
          <button
            ref={loadMoreRef}
            className={`${
              disableLoadMore ? 'bg-[#98d0e1]' : 'bg-[#0A9CCA]'
            } text-white font-bold py-2 px-4 rounded focus:shadow-outline`}
            onClick={loadMore}
            disabled={disableLoadMore}
          >
            {i18n.ft(messages.more)}
          </button>
        )}
      </div>
    </div>
  );
}

export default AllResourcesPage;
