import useQuery from 'helpers/useQuery';
import Resources from 'components/TestAttempts/Resources';
import { useRouteParams } from 'hooks/useRouteParams';

type URLParams = {
  id: string;
};

interface AttemptParams {
  attempt_id?: string | null;
  attempt_number?: string | null;
}

function TestTemplate() {
  const { id } = useRouteParams<URLParams>();
  const query = useQuery();
  const params: AttemptParams = { ...query };

  return (
    <Resources
      testTemplateId={Number(id)}
      practiceBeforeRenew
      attemptNumber={params.attempt_number}
      attemptId={params.attempt_id}
    />
  );
}

export default TestTemplate;
