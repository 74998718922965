import styled from 'styled-components';

export const OrganizationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Circle = styled.span`
  color: ${props => props.theme.colors.blue};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.blue};
  border-radius: 50%;
  margin: 4px 4px 4px 0px;
`;
