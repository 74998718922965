import { useState } from 'react';
import SimpleOnboarding from './SimpleOnboarding';
import { Container, TopWrapper, IconButton } from './Styled';
import Stepper from 'components/Stepper';
import i18n from 'helpers/i18n';
import messages from './messages';
import WorkPerformed from './WorkPerformed';
import FamiliarWithClass from './FamiliarWithClass';
import { Icon } from 'semantic-ui-react';
import Spacer from 'components/Spacer';

import {
  OnboardingSteps,
  Jobs,
  ClassFamiliaredType,
  OnboardingManagerContext
} from './OnboardingManagerContext';

function OnboardingManager() {
  const [checkedSteps, setCheckedSteps] = useState<OnboardingSteps[]>([
    'welcome'
  ]);
  const allSteps: OnboardingSteps[] = [
    'welcome',
    'focusOnInteractions',
    'workPerformed',
    'familiarWithClass',
    'onboardingCompleted'
  ];
  const [step, setStep] = useState<OnboardingSteps>('welcome');
  const [jobs, setJobs] = useState<Jobs[]>([]);
  const [classFamiliared, setClassFamiliared] =
    useState<ClassFamiliaredType>(null);

  let stepComponent: JSX.Element;

  function dismiss() {
    window.location.href = window.location.origin;
  }

  switch (step) {
    case 'welcome':
      stepComponent = <SimpleOnboarding />;
      break;
    case 'focusOnInteractions':
      stepComponent = <SimpleOnboarding />;
      break;
    case 'workPerformed':
      stepComponent = <WorkPerformed />;
      break;
    case 'familiarWithClass':
      stepComponent = <FamiliarWithClass />;
      break;
    case 'onboardingCompleted':
      stepComponent = <SimpleOnboarding />;
      break;
    default:
      throw new Error(`Unhandled step type: ${step}`);
  }

  return (
    <OnboardingManagerContext.Provider
      value={{
        step,
        setStep,
        jobs,
        setJobs,
        checkedSteps,
        setCheckedSteps,
        classFamiliared,
        setClassFamiliared
      }}
    >
      <Container>
        <TopWrapper>
          <Stepper steps={allSteps} />

          {step !== 'onboardingCompleted' && (
            <IconButton onClick={dismiss}>
              <Icon
                fitted
                size="large"
                name="close"
                aria-label={i18n.ft(messages.closeButton)}
              />
            </IconButton>
          )}
        </TopWrapper>

        <Spacer size={32} />
        {stepComponent}
      </Container>
    </OnboardingManagerContext.Provider>
  );
}

export default OnboardingManager;
