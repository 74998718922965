import axios from 'axios';
import { USER_ENGAGEMENT_EVENT_VERBS } from 'constants/userEngagementEventVerbs';
import {
  ENGAGEMENT_OBJECT,
  UserEngagementEvent
} from 'types/api/UserEngagementEvent';

interface UserEngagementEventRequest {
  id?: number;
  subjectId: number | string;
  verb: keyof typeof USER_ENGAGEMENT_EVENT_VERBS;
  objectId: number | string;
  objectType: Extract<ENGAGEMENT_OBJECT, 'LearningResourceRecord'>;
}

export function createUserEngagementEvent({
  subjectId: subject_id,
  verb,
  objectId: object_id,
  objectType: object_type
}: UserEngagementEventRequest) {
  return axios.post<UserEngagementEvent>('/api/v2/user_events', {
    subject_id,
    subject_type: 'User',
    verb,
    object_id,
    object_type
  });
}

export function removeResourceAsImportant(learningResourceId: number) {
  return axios.delete(`/api/v2/user_events/${learningResourceId}`);
}
