import useAsync from 'hooks/useAsync';
import { useEffect, useState } from 'react';
import { getOrganizationAccountSummary } from 'actions/accounts';
import { useNavigate } from 'react-router-dom';
import i18n from 'helpers/i18n';
import messages from './messages';

import {
  Wrapper,
  SummarySection,
  SettingSection,
  SummaryContainer,
  Row,
  RowName,
  RowNumber,
  Section,
  SummaryTitle,
  LoaderWrapper,
  SettingContainer,
  SchoolContainer,
  SchoolWrapper,
  SettingsTitle
} from './Styled';
import { Loader, Select } from 'semantic-ui-react';
import moment from 'moment';
import { map, range } from 'ramda';
import { updateOrganizationAccountSettings } from 'actions/accounts';
import { Account } from 'types/api/Account';

interface HomeSummaryProps {
  /**Account information */
  account: Account;

  /**Callback for tab change */
  onChangeView: (url: string) => void;

  /**Callback when the account changed */
  onAccountChange: () => void;
}

function HomeSummary({
  account,
  onChangeView,
  onAccountChange
}: HomeSummaryProps) {
  const { data, run, isPending } = useAsync();
  const [schoolYear, setSchoolYear] = useState(
    account.settings.school_year_start_month || ''
  );
  const navigate = useNavigate();

  useEffect(() => {
    run(getOrganizationAccountSummary());
  }, [run]);

  const showTrainings =
    !isPending &&
    (data.trainings.completed !== 0 ||
      data.trainings.in_session !== 0 ||
      data.trainings.scheduled !== 0);
  const showSubscriptions =
    !isPending &&
    (data.subscriptions.active !== 0 || data.subscriptions.active_users !== 0);

  function months() {
    return map(month => {
      const momentDate = moment(month, 'M');
      return { key: month, value: month, text: momentDate.format('MMMM') };
    }, range(1, 13));
  }

  async function handleChange(value: any) {
    const schoolId = { school_year_start_month: value.value };
    const response = await updateOrganizationAccountSettings(schoolId);
    setSchoolYear(response.data.school_year_start_month);
    onAccountChange();
  }

  return (
    <Wrapper>
      <SummarySection>
        {!isPending ? (
          <>
            {showTrainings && (
              <Section>
                <SummaryTitle>{i18n.ft(messages.trainings)}</SummaryTitle>
                <SummaryContainer
                  onClick={e => {
                    navigate('/organization/trainings');
                    onChangeView('/organization/trainings');
                  }}
                  aria-label={i18n.ft(messages.trainingsAriaLabel)}
                  role="link"
                >
                  <Row>
                    <RowName>{i18n.ft(messages.completed)}</RowName>
                    <RowNumber>{data.trainings.completed}</RowNumber>
                  </Row>
                  <Row>
                    <RowName>{i18n.ft(messages.inSession)}</RowName>
                    <RowNumber>{data.trainings.in_session}</RowNumber>
                  </Row>
                  <Row>
                    <RowName>{i18n.ft(messages.scheduled)}</RowName>
                    <RowNumber>{data.trainings.scheduled}</RowNumber>
                  </Row>
                </SummaryContainer>
              </Section>
            )}
            {showSubscriptions && (
              <Section>
                <SummaryTitle>{i18n.ft(messages.subscriptions)}</SummaryTitle>
                <SummaryContainer
                  onClick={e => {
                    navigate('/organization/subscriptions');
                    onChangeView('/organization/subscriptions');
                  }}
                  aria-label={i18n.ft(messages.subscriptionsAriaLabel)}
                  role="link"
                >
                  <Row>
                    <RowName>{i18n.ft(messages.active)}</RowName>
                    <RowNumber>{data.subscriptions.active}</RowNumber>
                  </Row>
                  <Row>
                    <RowName>{i18n.ft(messages.activeUsers)}</RowName>
                    <RowNumber>{data.subscriptions.active_users}</RowNumber>
                  </Row>
                </SummaryContainer>
              </Section>
            )}
          </>
        ) : (
          <LoaderWrapper>
            <Loader active inline="centered" />
          </LoaderWrapper>
        )}
      </SummarySection>
      <SettingSection>
        <SchoolWrapper>
          <SettingsTitle>{i18n.ft(messages.reporting)}</SettingsTitle>
          <SettingContainer>
            <SchoolContainer>
              <label htmlFor="school">{i18n.ft(messages.schoolYear)}</label>
              <Select
                options={months()}
                id="school"
                onChange={(e, value: any) => handleChange(value)}
                value={schoolYear}
                fluid
              />
            </SchoolContainer>
          </SettingContainer>
        </SchoolWrapper>
      </SettingSection>
    </Wrapper>
  );
}

export default HomeSummary;
