import i18n from 'helpers/i18n';
import messages from './messages';
import * as Yup from 'yup';
import { Select, Input } from 'components/EnhancedForm';
import { Form, Formik, FormikProps } from 'formik';
import { Button } from 'semantic-ui-react';

export interface ChangeFormValues {
  reason?: string;
  other_reason?: string;
}

const VALIDATION_SCHEMA = Yup.object({
  reason: Yup.string().required(i18n.ft(messages.errors.required)),
  other_reason: Yup.string()
    .nullable()
    .when('reason', {
      is: 'Other',
      then: schema => schema.required(i18n.ft(messages.errors.required))
    })
});

function ReasonForm({
  reasonsList,
  onConfirm,
  close,
  isOpen,
  cancelButton,
  confirmButton,
  confirmColor,
  isLoading,
  setIsloading
}: any) {
  function reasonsOptions() {
    return reasonsList
      ? reasonsList.map((value: string, index: number) => ({
          key: index + 1,
          value: value,
          text: value
        }))
      : [];
  }

  function submitForm(values: any) {
    setIsloading(true);
    onConfirm({
      closeModal: close,
      setLoading: setIsloading,
      chosenReason: values.other_reason || values.reason
    });
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={submitForm}
      validationSchema={VALIDATION_SCHEMA}
    >
      {({
        dirty,
        isValid,
        values: { reason }
      }: FormikProps<ChangeFormValues>) => (
        <Form className="max-w-[450px] ml-auto mt-4">
          <Select
            inline
            name="reason"
            label={i18n.ft(messages.reason.title)}
            options={reasonsOptions()}
            required
            placeholder=""
          />

          {reason === 'Other' && (
            <Input
              inline
              name="other_reason"
              label={i18n.ft(messages.reason.otherReason)}
            />
          )}

          <div className="flex mt-2">
            <Button
              type="button"
              className="!ml-auto"
              onClick={close}
              content={cancelButton}
              autoFocus={isOpen}
            />
            <Button
              type="submit"
              className="!ml-10"
              content={confirmButton}
              color={confirmColor}
              disabled={!dirty || !isValid}
              loading={isLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ReasonForm;
