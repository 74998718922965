const PATH = 'fe.components.time_zone';

const messages = {
  localTime: {
    key: `${PATH}.local_time`,
    defaultValue: 'Dates and times are based on your local time zone.'
  },
  yourZone: {
    key: `${PATH}.your_zone`,
    defaultValue: 'Your current time zone is:'
  }
};

export default messages;
