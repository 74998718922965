import styled from 'styled-components';
import { Pagination } from 'semantic-ui-react';

export const PaginationWrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 16px;

  @media screen and (min-width: 640px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    margin-top: 64px;
  }
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
`;

export const MeasurePagination = styled(Pagination)`
  &.ui.pagination > .item {
    font-weight: 700;
    color: ${p => p.theme.colors['scaleDarkBlue']};
    background-color: white;

    box-shadow: 0px 1.40726px 4.22179px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 45px;
    height: 45px;
    justify-content: center;
    ::before {
      background: none;
    }
  }

  &.ui.pagination > .item.active {
    font-weight: 700;
    color: ${p => p.theme.colors['white']};
    background-color: ${p => p.theme.colors['primary']['700']};

    box-shadow: 0px 1.40726px 4.22179px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 45px;
    height: 45px;
    justify-content: center;
    ::before {
      background: none;
    }
  }

  &.ui.pagination {
    width: 100%;
    display: flex; !important;
    column-gap: calc(5% - 20px);
    justify-content: center;
    background-color: inherit;
    border: 0;
    box-shadow: none;
  }

  &.ui.pagination > a[type='firstItem'],
  &.ui.pagination > a[type='lastItem'] {
    margin: 0;
    background-color: inherit;
    box-shadow: none;
  }

  &.ui.pagination > a[type='prevItem'],
  &.ui.pagination > a[type='nextItem'] {
    background-color: white !important;
    padding-left: 57px;
    padding-right: 57px;
    box-shadow: 0px 1.40726px 4.22179px rgb(0 0 0 / 25%);
    border-radius: 3px;
    ::before {
      background: none;
    }
    :hover {
      background: rgba(0, 0, 0, 0.03) !important;
    }
  }
`;
