import styled from 'styled-components';

export const Root = styled.div``;

const BaseCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

export const Circle = styled(BaseCircle)`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.primary['500']};
`;

export const Completed = styled(BaseCircle)`
  background-color: ${props => props.theme.colors.primary['500']};
`;
