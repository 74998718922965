import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { FormikHelpers } from 'formik';
import Spacer from 'components/Spacer';
import TextButton from 'components/TextButton';
import { Button, Icon } from 'semantic-ui-react';
import { sendSmsCodeForLogin } from 'actions/sms';
import { toastError, toastSuccess } from 'components/Toast';
import { Formik, Form, Input } from 'components/EnhancedForm';
import { useLoginManager } from 'components/LoginManager';

import {
  IconWrapper,
  ButtonWrapper,
  ButtonsWrapper,
  ResendWrapper,
  TextButtonWrapper
} from './Styled';

const validationSchema = yup.object({
  code: yup.string().required(() => i18n.ft(messages.errors.code.required))
});

function VerifyCodeForm() {
  const resendRequest = useAsync();
  const { setStep, emailOrPhone, submitForm } = useLoginManager();

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    submitForm({ user: values }, formik);
  }

  function handleResendCode() {
    resendRequest.run(sendSmsCodeForLogin({ phone_number: emailOrPhone }), {
      onSuccess: () => {
        toastSuccess({ description: i18n.ft(messages.codeSent) });
      },
      onError: (error: any) => {
        toastError({ description: error.message });
      }
    });
  }

  function handleBackToLogin() {
    setStep('login');
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{ login_phone: emailOrPhone, code: '' }}
      validateOnBlur={false}
    >
      {formik => (
        <Form>
          <Spacer size={16} />
          <Input name="login_phone" type="hidden" />

          <Input
            autoFocus
            name="code"
            label={i18n.ft(messages.fields.code)}
            placeholder={i18n.ft(messages.fields.codePlaceholder)}
            errorLabel={{ textAlign: 'left' }}
            pattern="[0-9]*"
          />

          <ButtonsWrapper>
            <ButtonWrapper>
              <Button
                fluid
                size="large"
                color="green"
                type="submit"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                content={i18n.ft(messages.submit)}
              />
            </ButtonWrapper>

            <ResendWrapper>
              <TextButton
                type="button"
                onClick={handleResendCode}
                disabled={resendRequest.isLoading}
              >
                {resendRequest.isLoading && (
                  <IconWrapper>
                    <Icon fitted loading name="spinner" />
                  </IconWrapper>
                )}

                {resendRequest.isError && (
                  <IconWrapper>
                    <Icon fitted name="warning sign" color="red" />
                  </IconWrapper>
                )}

                {i18n.ft(messages.resend)}
              </TextButton>
            </ResendWrapper>
          </ButtonsWrapper>

          <TextButtonWrapper>
            <TextButton type="button" onClick={handleBackToLogin}>
              {i18n.ft(messages.back)}
            </TextButton>
          </TextButtonWrapper>
        </Form>
      )}
    </Formik>
  );
}

export default VerifyCodeForm;
