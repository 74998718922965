const PATH = 'fe.components.trainings.view.participants.participant_card';

const messages = {
  status: {
    key: `${PATH}.status`,
    defaultValue: 'Status:'
  },
  paymentStatus: {
    key: `${PATH}.payment_status`,
    defaultValue: 'Payment Status:'
  },
  paymentNeeded: {
    key: `${PATH}.payment_needed`,
    defaultValue: 'Payment Needed'
  },
  paymentRequested: {
    key: `${PATH}.payment_requested`,
    defaultValue: 'Payment Requested'
  },
  paid: {
    key: `${PATH}.paid`,
    defaultValue: 'Paid'
  },
  pending: {
    key: `${PATH}.pending`,
    defaultValue: 'Pending'
  },
  invited: {
    key: `${PATH}.invited`,
    defaultValue: 'Invited'
  },
  registered: {
    key: `${PATH}.registered`,
    defaultValue: 'Registered'
  },
  declined: {
    key: `${PATH}.declined`,
    defaultValue: 'Declined'
  },
  canceled: {
    key: `${PATH}.canceled`,
    defaultValue: 'Canceled'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed'
  }
};

export default messages;
