import * as React from 'react';
import { FormikHelpers } from 'formik';

export type LoginSteps =
  | 'login'
  | 'loginWithCode'
  | 'loginWithPassword'
  | 'loginWithPhone';

export const LoginManagerContext = React.createContext<
  | {
      step: LoginSteps;
      setStep: React.Dispatch<React.SetStateAction<LoginSteps>>;
      emailOrPhone: string;
      setEmailOrPhone: React.Dispatch<React.SetStateAction<string>>;
      submitForm: (values: any, formik: FormikHelpers<any>) => void;
    }
  | undefined
>(undefined);

export function useLoginManager() {
  const context = React.useContext(LoginManagerContext);

  if (!context) {
    throw new Error(
      'useLoginManager must be used within a LoginManagerProvider'
    );
  }

  return context;
}
