import i18n from 'helpers/i18n';
import { Cell, Pie, PieChart } from 'recharts';
import colors from 'styles/colors';
import messages from '../messages';
import { THRESHOLD } from '../constants';
import {
  RADIAN,
  computeAngle,
  computeBarColor,
  computeBarShadow,
  computeCornerRadius,
  computeOpacity
} from './utils';

export interface ChartProps {
  data: {
    fill: string;
    stroke: string;
    value: number;
  }[];
  size?: number;
}

interface BarProps {
  angle: number;
  color: string;
  x: number;
  y: number;
  height: number;
  width: number;
  score: number;
}

const renderBar = ({ x, y, height, width, color, angle, score }: BarProps) => {
  const translateY = -Math.abs(Math.cos(-RADIAN * angle)); // annoyingly necessary shim :/
  return (
    <rect
      x={x}
      y={y}
      rx={width / 2}
      ry={width / 2}
      width={width}
      height={height}
      stroke={color}
      fill={color}
      strokeWidth="1"
      border-radius="50%"
      transform={`rotate(${angle % 90}) translate(0 ${translateY})`}
      style={{
        filter: `drop-shadow( 0.5px 0.5px 0.5px rgba(0, 0, 0, ${computeBarShadow(
          score
        )}))`,
        transformBox: 'fill-box'
      }}
    />
  );
};

const renderBarLabel = (size: number, angle: number, s: number, c: number) => {
  const radius = size / 2;
  const distance = (radius * 4) / 3;
  const px = distance * c;
  const py = -distance * s;
  return (
    <div
      className="absolute bg-transparent w-fit h-fit z-10"
      style={{
        color: colors.scaleDarkBlue,
        fontSize: `${Math.max(size / 10, 12)}px`,
        lineHeight: `${Math.max(size / 10, 12)}px`,
        top: `calc(${radius}px - .25em)`,
        left: `calc(${radius}px - 1em)`,
        transform: `translate(${px}px, ${py}px)`
      }}
    >
      {THRESHOLD}%
    </div>
  );
};

const COLORS = [
  { start: 'transparent', end: 'transparent' },
  { start: '#9bcc4e', end: colors.reliability.passVideo },
  { start: '#d82a49', end: colors.reliability.failedAttempt }
];

/** A custom pie chart for graphically representing a test score */
function Chart({ data, size = 122 }: ChartProps) {
  const [width, height] = [size, size];
  const score = data[1].value || 50; // for styling only
  const ringInnerRadius = size / 3;
  const ringOuterRadius = size / 2;
  const barAngle = (computeAngle(THRESHOLD) + 270) % 360;
  const barPad = (ringOuterRadius - ringInnerRadius) / 8; // ~12% of ring thickness
  const barThickness = size / 40; // nominally ~3px
  const barStartRadius = ringInnerRadius + barPad;
  const barEndRadius = ringOuterRadius - barPad;
  const barLength = barEndRadius - barStartRadius;
  const barColor = computeBarColor(score);

  /* center of chart */
  const cx = width / 2;
  const cy = height / 2;

  const sin = Math.sin(-RADIAN * barAngle);
  const cos = Math.cos(-RADIAN * barAngle);

  /* starting points of rectangle */
  const xc = cx + barStartRadius * sin + barThickness * cos + barLength * sin;
  const yc = cy + barStartRadius * cos - barThickness * sin + barLength * cos;

  return (
    <>
      {renderBarLabel(size, barAngle % 90, sin, cos)}
      <PieChart
        title={i18n.ft(messages.chart.title)}
        width={width}
        height={height}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        style={{
          borderRadius: '50%',
          transform: 'rotate(-90.83deg)'
        }}
      >
        <defs>
          {data.map((_, index) => (
            <linearGradient
              id={`myGradient${index}`}
              key={`myGradient${index}`}
            >
              <stop
                offset="0%"
                stopColor={COLORS[index % COLORS.length].start}
              />
              <stop
                offset="100%"
                stopColor={COLORS[index % COLORS.length].end}
              />
            </linearGradient>
          ))}
          <linearGradient id={`myGradient-pass`}>
            <stop offset="0%" stopColor={COLORS[1].start} />
            <stop offset="100%" stopColor={COLORS[1].end} />
          </linearGradient>
          <linearGradient id={`myGradient-fail`}>
            <stop offset="0%" stopColor={COLORS[2].start} />
            <stop offset="100%" stopColor={COLORS[2].end} />
          </linearGradient>
          <filter id="outerCircle">
            {/* Shadow offset */}
            <feOffset dx="-3" dy="2" />

            {/* Shadow blur */}
            <feGaussianBlur stdDeviation={size / 24} result="offset-blur" />

            {/* Invert drop shadow to make an inset shadow */}
            <feComposite
              operator="out"
              in="SourceGraphic"
              in2="offset-blur"
              result="inverse"
            />

            {/* Cut color inside shadow */}
            <feFlood floodColor="black" floodOpacity=".75" result="color" />
            <feComposite
              operator="in"
              in="color"
              in2="inverse"
              result="shadow"
            />

            {/* Placing shadow over element */}
            <feComposite operator="over" in="shadow" in2="SourceGraphic" />
          </filter>
          <filter id="innerCircle">
            <feDropShadow
              dx={-5}
              dy={5}
              floodOpacity={`${computeOpacity(score)}`}
              floodColor="black"
              stdDeviation={2}
            />
          </filter>
        </defs>

        {/* white background */}
        <Pie
          data={[{ fill: 'white', value: 100, stroke: 'white' }]}
          dataKey="value"
          cx={width / 2}
          cy={height / 2}
          innerRadius={0}
          outerRadius={size / 2 - 1}
          isAnimationActive={false}
          cornerRadius={size / 3}
          filter="url(#outerCircle)"
          rootTabIndex={-1}
        />

        {/* data (ring) */}
        <Pie
          data={data}
          dataKey="value"
          cx={width / 2}
          cy={height / 2}
          innerRadius={size / 3}
          outerRadius={size / 2}
          isAnimationActive={false}
          cornerRadius={computeCornerRadius(score, size)}
          rootTabIndex={-1}
        >
          {data.map((entry, index) => (
            <Cell
              role="cell"
              name={index === 0 ? 'transparent' : 'score bar'}
              key={`cell-${index}`}
              strokeWidth={0}
              fill={
                entry.fill === colors.reliability.failedAttempt
                  ? 'url(#myGradient-fail)'
                  : `url(#myGradient${index})`
              }
            />
          ))}
        </Pie>

        {/* inner circle */}
        <Pie
          data={[{ fill: 'white', value: 100, stroke: 'transparent' }]}
          dataKey="value"
          cx={width / 2}
          cy={height / 2}
          innerRadius={0}
          outerRadius={size / 3}
          isAnimationActive={false}
          cornerRadius={size / 3}
          filter="url(#innerCircle)"
          rootTabIndex={-1}
        />

        {/* Pass/Fail threshold indicator bar */}
        {renderBar({
          angle: barAngle,
          color: barColor,
          height: barLength,
          width: barThickness,
          score,
          x: xc + (barThickness / 2) * cos,
          y: yc + (barThickness / 2) * sin
        })}
      </PieChart>
    </>
  );
}

export default Chart;
