const PATH = 'fe.components.measure.create.observers_list';

const messages = {
  label: {
    key: `${PATH}.label`,
    defaultValue: 'Observer'
  },
  placeholder: {
    key: `${PATH}.placeholder`,
    defaultValue: 'Select an observer'
  }
};

export default messages;
