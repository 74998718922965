const PATH = 'fe.components.organization.subscriptions';

const messages = {
  subscription: {
    key: `${PATH}.subscription`,
    defaultValue: 'Subscription'
  },
  subscriptionName: {
    key: `${PATH}.subscription_name`,
    defaultValue: 'Subscription Name'
  },
  users: {
    key: `${PATH}.users`,
    defaultValue: 'Users'
  },
  usersMaxSeats: {
    key: `${PATH}.users_max_seats`,
    defaultValue: 'Users/Max Seats'
  },
  product: {
    key: `${PATH}.product`,
    defaultValue: 'Product (Type)'
  },
  endDate: {
    key: `${PATH}.end_date`,
    defaultValue: 'End Date'
  },
  maxSeats: {
    key: `${PATH}.max_seats`,
    defaultValue: 'Max Seats'
  },
  noSubscriptions: {
    key: `${PATH}.no_subscriptions`,
    defaultValue:
      'You do not yet have any subscriptions set up for {{name}}. Learn more about our myCoach Connect subscription '
  },
  here: {
    key: `${PATH}.here`,
    defaultValue: 'here'
  }
};

export default messages;
