import * as React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useField } from 'formik';
import { Input as SemanticInput } from 'semantic-ui-react';
import { Label, LabelContent, Optional, Required } from './Styled';
import { ErrorMessage } from 'components/Measure/View/Styled';

// Remove types incompatible with semantic-ui Input
type HTMLInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>;

interface InputProps extends HTMLInputProps {
  /** Input label. */
  label: string;

  /** An input can be flagged as optional. */
  optional?: boolean;

  /** An input can be flagged as required. */
  required?: boolean;

  /** If `true`, the input will take the full width of its container. */
  fluid?: boolean;

  /** Error message that is shown underneath the input fields */
  errorMessage?: string | null;
}

export function Input({
  label,
  optional,
  required,
  errorMessage,
  ...props
}: InputProps) {
  return (
    <div>
      <Label>
        <LabelContent>
          {label}
          {optional && <Optional>({i18n.ft(messages.optional)})</Optional>}
          {required && <Required aria-hidden>*</Required>}
        </LabelContent>
        <SemanticInput aria-required={required} {...props} />
      </Label>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
}

interface ConnectedInputProps extends InputProps {
  /** A ConnectedInput must have a name. */
  name: string;
}

export function ConnectedInput({ name, ...props }: ConnectedInputProps) {
  const [field] = useField(name);
  return <Input {...props} {...field} />;
}
